import React, { useEffect, useState } from "react";
import { DollarTab } from "./DollarTab";
import { FuelSurchargeTab } from "./FuelSurcharge";
import { VolumeTrendsTab } from "./VolumeTrends";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import { Pickedup } from "./Performance/Pickedup";
import { Col, Row } from "react-bootstrap";
import { Delivered } from "./Performance/Delivered";
import { UserDashboardIndex } from "../user-dashboard";
import { useHistory } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import { LinearProgress } from "@material-ui/core";
import SelectPagination from "../widgets/Select/SelectPagination";
const adapter = new LocalStorage("db");
const db = low(adapter);

const localStorageActiveTabKey = "builderActiveTab";

export function CustomerDashboardIndex() {
  const history = useHistory();
  const [loadingCustomer, setloadingCustomer] = useState(false);
  const [loadingLane, setloadingLane] = useState(false);
  const [loadingData, setloadingData] = useState(false);
  const [loadingDataFuelSurcharge, setloadingDataFuelSurcharge] = useState(
    false
  );
  const [loadingDataVolume, setloadingDataVolume] = useState(false);
  const [loadingDataPerformance, setloadingDataPerformance] = useState(false);
  const activeTab = localStorage.getItem(localStorageActiveTabKey);
  const [CustomerList, setCustomerList] = useState([]);
  const [BalanceList, setBalanceList] = useState([]);
  const [FuelSurchargeList, setFuelSurchargeList] = useState([]);
  const [PerformanceList, setPerformanceList] = useState([]);
  const [VolumeList, setVolumeList] = useState([]);
  const [LineList, setLineList] = useState([]);
  const [initFilter, setinitFilter] = useState({
    customerId: [],
    startDate: null,
    endDate: null,
    Lane: "",
    ShipperId: "",
    ConsigneeId: "",
  });
  const [key, setKey] = useState(activeTab ? +activeTab : 0);
  const saveCurrentTab = (_tab) => {
    localStorage.setItem(localStorageActiveTabKey, _tab);
  };
  useEffect(() => {
    GetCustomerList();
    GetBalances(initFilter);
    GetFuelSurcharge(initFilter);
    GetPerformance(initFilter);
    GetVolume(initFilter);
  }, []);

  function GetCustomerList() {
    setloadingCustomer(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/customers`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setCustomerList(objs || []);
        setloadingCustomer(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingCustomer(false);
      });

    //setLoadingPageShow(false);
  }
  function GetLaneList(props) {
    setloadingLane(true);
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/user/lanes?startDate=${props.startDate}&endDate=${props.endDate}${customerId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setLineList(objs || []);
        setloadingLane(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingLane(false);
      });

    //setLoadingPageShow(false);
  }

  function GetBalances(props) {
    setloadingData(true);
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/customer/balances?modeId=${customerId}&startDate=${props.startDate}&endDate=${props.endDate}&shipperId=${props.ShipperId}&consigneeId=${props.ConsigneeId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setBalanceList(objs || []);
        setloadingData(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingData(false);
      });

    //setLoadingPageShow(false);
  }
  function GetFuelSurcharge(props) {
    setloadingDataFuelSurcharge(true);
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/customer/fuelSurcharge?modeId=${customerId}&startDate=${props.startDate}&endDate=${props.endDate}&shipperId=${props.ShipperId}&consigneeId=${props.ConsigneeId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setFuelSurchargeList(objs || []);
        setloadingDataFuelSurcharge(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingDataFuelSurcharge(false);
      });

    //setLoadingPageShow(false);
  }
  function GetVolume(props) {
    setloadingDataVolume(true);
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/customer/volume?modeId=${customerId}&startDate=${props.startDate}&endDate=${props.endDate}&shipperId=${props.ShipperId}&consigneeId=${props.ConsigneeId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setVolumeList(objs || []);
        setloadingDataVolume(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingDataVolume(false);
      });

    //setLoadingPageShow(false);
  }
  function GetPerformance(props) {
    setloadingDataPerformance(true);
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/customer/performance?modeId=${customerId}&startDate=${props.startDate}&endDate=${props.endDate}&shipperId=${props.ShipperId}&consigneeId=${props.ConsigneeId}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setPerformanceList(objs || []);
        setloadingDataPerformance(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingDataPerformance(false);
      });

    //setLoadingPageShow(false);
  }
  return (
    <>
      <div className="flex-row-auto offcanvas-mobile col-xl-12 col-lg-12 col-12">
        <div className="card mb-5">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Customer Dashboard</h3>
            </div>
            <form className="form d-flex align-items-md-center flex-sm-row flex-column flex-grow-1 flex-sm-grow-0">
              {/*begin::Input*/}
              <div className="col-4">
                <div class="form-group">
                  <label className="font-weight-bolder">Customers</label>
                  <SelectPagination
                    name="CustomerId"
                    isMulti
                    id="CustomerId"
                    label="Customers"
                    inputId="react-select-single"
                    placeholder="Search Customer"
                    value={initFilter.customerId}
                    TextFieldProps={{
                      InputLabelProps: {
                        htmlFor: "react-select-single",
                        shrink: true,
                      },
                    }}
                    options={CustomerList.map((suggestion) => ({
                      value: suggestion.Id,
                      label: suggestion.CompanyName,
                    }))}
                    onChange={(e) => {
                      var obj = { ...initFilter };
                      obj.customerId = e === null ? [] : e;
                      GetLaneList(obj);
                      setinitFilter(obj);
                      GetBalances(obj);
                      GetFuelSurcharge(obj);
                      GetPerformance(obj);
                      GetVolume(obj);
                    }}
                  />
                  {loadingCustomer && <LinearProgress />}
                </div>
              </div>
              <div className="col-4">
                <div class="form-group">
                  <label className="font-weight-bolder">Date</label>
                  <RangeDatePicker
                    startDate={
                      initFilter.startDate !== null &&
                      moment(initFilter.startDate).format("YYYY-MM-DD")
                    }
                    endDate={
                      initFilter.endDate !== null &&
                      moment(initFilter.endDate).format("YYYY-MM-DD")
                    }
                    onChange={(startDate, endDate) => {
                      var obj = { ...initFilter };
                      obj.startDate =
                        startDate === null
                          ? null
                          : moment(startDate).format("YYYY-MM-DD");
                      obj.endDate =
                        endDate === null
                          ? null
                          : moment(endDate).format("YYYY-MM-DD");
                      setinitFilter(obj);
                      GetLaneList(obj);
                      GetBalances(obj);
                      GetFuelSurcharge(obj);
                      GetPerformance(obj);
                      GetVolume(obj);
                    }}
                    dateFormat="MM/DD/YYYY"
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    className="my-own-class-name"
                    startWeekDay="monday"
                  />
                </div>
              </div>
              <span className="bullet bullet-ver h-25px d-none d-sm-flex mr-2" />
              <div className="col-3">
                <div class="form-group">
                  <label className="font-weight-bolder">Lane</label>
                  <SelectPagination
                    isSearchable={true}
                    isClearable
                    options={LineList.map((suggestion) => ({
                      value:
                        suggestion.ShipperName + "-" + suggestion.ConsigneeName,
                      label:
                        suggestion.ShipperName +
                        " - " +
                        suggestion.ConsigneeName,
                      ConsigneeId: suggestion.ConsigneeId,
                      ShipperId: suggestion.ShipperId,
                    }))}
                    onChange={(e) => {
                      var obj = { ...initFilter };
                      obj.Lane =
                        e === null ? "" : e?.ShipperName + e?.ConsigneeName;
                      obj.ConsigneeId = e === null ? "" : e?.ConsigneeId;
                      obj.ShipperId = e === null ? "" : e?.ShipperId;
                      GetBalances(obj);
                      GetFuelSurcharge(obj);
                      GetPerformance(obj);
                      GetVolume(obj);
                      setinitFilter(obj);
                    }}
                  />
                  {loadingLane && <LinearProgress />}
                </div>
              </div>
              {/*end::Input*/}

              <button
                type="submit"
                className="btn btn-link-dark font-weight-bold mt-3 mt-sm-0 px-7"
              >
                <span className="svg-icon svg-icon-lg">
                  {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/General/Search.svg*/}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <path
                        d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                  {/*end::Svg Icon*/}
                </span>
              </button>
            </form>
            <ul
              className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
              data-remember-tab="tab_id"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  href={() => false()}
                  className={`nav-link font-size-h5 ${
                    key === 0 ? "active font-weight-bolder" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(0);
                    saveCurrentTab(0);
                  }}
                >
                  $
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={() => false()}
                  className={`nav-link font-size-h5 ${
                    key === 1 ? "active font-weight-bolder" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(1);
                    saveCurrentTab(1);
                  }}
                >
                  Fuel Surcharge
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={() => false()}
                  className={`nav-link font-size-h5 ${
                    key === 2 ? "active font-weight-bolder" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(2);
                    saveCurrentTab(2);
                  }}
                >
                  Volume Trends
                </a>
              </li>
              <li className="nav-item">
                <a
                  href={() => false()}
                  className={`nav-link font-size-h5 ${
                    key === 3 ? "active font-weight-bolder" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => {
                    setKey(3);
                    saveCurrentTab(3);
                  }}
                >
                  Performance
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content pt-3 bg-transparent">
          <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
            <DollarTab
              BalanceList={BalanceList}
              loadingData={loadingData}
              initFilter={initFilter}
              className="card-stretch gutter-b"
            />
          </div>
          <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
            <FuelSurchargeTab
              loadingDataFuelSurcharge={loadingDataFuelSurcharge}
              FuelSurchargeList={FuelSurchargeList}
              initFilter={initFilter}
              className="card-stretch gutter-b"
            />
          </div>
          <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
            <VolumeTrendsTab
              loadingDataVolume={loadingDataVolume}
              VolumeList={VolumeList}
              initFilter={initFilter}
              className="card-stretch gutter-b"
            />
          </div>
          <div className={`tab-pane ${key === 3 ? "active" : ""}`}>
            <Row>
              <Col xl="6">
                <Pickedup
                  loadingDataPerformance={loadingDataPerformance}
                  PerformanceList={PerformanceList}
                  initFilter={initFilter}
                  className="card-stretch gutter-b"
                />
              </Col>
              <Col xl="6">
                <Delivered
                  loadingDataPerformance={loadingDataPerformance}
                  PerformanceList={PerformanceList}
                  initFilter={initFilter}
                  className="card-stretch gutter-b"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <UserDashboardIndex className="card-stretch gutter-b" />
    </>
  );
}
