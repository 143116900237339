import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Notification } from "../../../../Notification";
import { CreatableSelect2 } from "../../../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
const CustomerEditSchema = Yup.object().shape({
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const history = useHistory();

  async function handleSubmited() {
    const shipmentId = window.location.pathname.split("/")[2];
    const CategoriesShipmentNotes = document.getElementById(
      "CategorieShipmentNotes"
    ).value;
    const ShipmentNotes = document.getElementById("ShipmentNotes").value;
    const data = {
      CategoryId: CategoriesShipmentNotes,
      Body: ShipmentNotes,
    };
    var raw = JSON.stringify(data);

    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    await fetch(
      `${window.$apiurl}/transload/shipments/addnote/` + shipmentId,
      requestOptions
    );
    Notification("info", "Note Add seccessfuly for shipment " + shipmentId);
    history.push("/ShipmentsManagement/");
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {() => (
          <>
            <Modal.Body className="overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-12">
                    <CreatableSelect2
                      label="Categories"
                      name="CategorieShipmentNotes"
                      id="CategorieShipmentNotes"
                      options={[
                        {
                          value: "",
                          label: "All",
                        },
                        {
                          value: "4",
                          label: "Exception",
                        },
                        {
                          value: "5",
                          label: "Information",
                        },
                        {
                          value: "6",
                          label: "Bill of Lading",
                        },
                        {
                          value: "7",
                          label: "Dispatch",
                        },
                        {
                          value: "8",
                          label: "Dispatch Confirmation",
                        },
                        {
                          value: "9",
                          label: "Pickup Confirmation",
                        },
                        {
                          value: "10",
                          label: "Load Received",
                        },
                        {
                          value: "11",
                          label: "Devanned",
                        },
                      ]}
                    />
                  </div>
                  <div className="col-lg-12 mt-5">
                    <label>Shipment Note</label>
                    <textarea
                      style={{ width: "100%" }}
                      name="ShipmentNotes"
                      id="ShipmentNotes"
                      label="Shipment Note"
                      rows={4}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmited()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
