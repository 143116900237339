import React from 'react'
import { Button, Form, Card, Col } from "react-bootstrap";

export function ItemAliasItems() {
    return (

        <Card>
            <Card.Body>

                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Button as="input" type="submit" value="Add" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Button as="input" type="submit" value="Import" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Check type="checkbox" label="Check me out" className="mt-3" />
                        </Form.Group>
                    </Form.Row>
                </Form>

                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Alias</th>
                            <th scope="col">Customer</th>
                            <th scope="col">Discription</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td></td>
                            <td> </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>


            </Card.Body>
        </Card>
    )
}
