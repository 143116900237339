import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockManageSuppliers(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(customerId) {
    return await fetch(`${window.$apiurl}/suppliers/${customerId}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/suppliers/${id}/details`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1].Id || 0;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/suppliers", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    let modelData = someData["customer"];
    return fetch(window.$apiurl + "/suppliers/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modelData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/suppliers/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCountries() {
    return await fetch(window.$apiurl + "/countries", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }

  async function GetStates(iso) {
    return await fetch(window.$apiurl + "/states/" + iso, {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }

  async function GetRetailers(id) {
    return await fetch(window.$apiurl + "/suppliers/getviewmodel/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/ManageSuppliers/GetCountries").reply(async () => {
    const obj = await GetCountries();
    return [200, obj];
  });

  mock.onPost("api/ManageSuppliers/GetStates").reply(async (iso) => {
    const isoValue = JSON.parse(iso.data)?.iso;
    const obj = await GetStates(isoValue);
    return [200, obj];
  });

  mock.onPost("api/ManageSuppliers/GetRetailers").reply(async (id) => {
    const isoValue = JSON.parse(id.data)?.id;
    const obj = await GetRetailers(isoValue);
    return [200, obj];
  });

  mock.onPost("api/ManageSuppliers").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Id = 0,
      Name = undefined,
      Code = undefined,
      CompanyName = undefined,
      Address1 = undefined,
      Address2 = undefined,
      City = undefined,
      State = undefined,
      Country = undefined,
      ZipCode = undefined,
      RetailerId = undefined,
      DepartmentId = undefined,
      Phone = undefined,
      Email = undefined,
      ContactId = undefined,
      CustomerId = undefined,
      IsActive = false,
      IsQuickLookup = false,
    } = customer;

    const newCustomer = {
      Id,
      Name,
      Code,
      CompanyName,
      Address1,
      Address2,
      City,
      State,
      Country,
      ZipCode,
      RetailerId,
      DepartmentId,
      Phone,
      Email,
      ContactId,
      CustomerId,
      IsActive,
      IsQuickLookup,
    };
    await PostModel(newCustomer);
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageSuppliers/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filter = queryParams.filter;
    if (filter.CustomerId !== 0 && filter.ParsersId !== 0) {
      customerTableMock = await GetModel(filter.CustomerId);
      delete queryParams.filter.CustomerId;
      delete queryParams.filter.ReLoadTable;
    }
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageSuppliers/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageSuppliers/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ManageSuppliers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageSuppliers\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ManageSuppliers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageSuppliers\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageSuppliers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageSuppliers\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
