import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactExport from "react-data-export";
import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));
export function BillingRatesExport() {

    useEffect(() => {
        fetchUsersAsync();
    }, []);

    const [customersList, setCustomersList] = useState([]);
    const [showProgress, setshowProgress] = useState(false);
    const [dataState, setdataState] = useState(
        <button class="btn btn-secondary text-dark">Export</button>
      );
    const fetchUsersAsync = async () => {
        try {
            setshowProgress(true);
            const result = await axios.get("api/ManageItems/Filter");
            setCustomersList(result.data["customersList"]);
            setshowProgress(false);
        } catch (e) { setshowProgress(false); }
    };
    const fileExport = async () => {
        setshowProgress(true);
        const customerId = document.getElementById("customerId").value;
        try {
            const result = await axios.post("api/transactionbilling/export", {
                data: customerId,
            });
            let jsonToExcel = (
                <ExcelFile
                    filename ={"BillingRates_Cust" + customerId + "_"+ moment().format("DD-MM-YYYY hh:mm:ss")} 
                    element={
                        <button className="btn btn-primary">Export</button>
                    }
                >
                    <ExcelSheet
                        data={result.data}
                        name="Employees"
                        className="btn btn-primary"
                    >
                        <ExcelColumn label="Customer Id" value="CustomerId" />
                        <ExcelColumn label="Unit" value="Unit" />
                        <ExcelColumn label="Unit Description" value="UnitDescription" />
                        <ExcelColumn label="Charge Label" value="ChargeLabel" />
                        <ExcelColumn label="Charge Per Unit" value="ChargePerUnit" />
                        <ExcelColumn label="Notes" value="Notes" />
                        <ExcelColumn label="GL Acc Number" value="GlAcctNum" />
                        <ExcelColumn label="PT Item" value="PtItem" />
                        <ExcelColumn label="PT Item Description" value="PtItemDescription" />
                        <ExcelColumn label="PT Ar Acc" value="PtArAcct" />
                    </ExcelSheet>
                </ExcelFile>
            );
            setdataState(jsonToExcel);
            setshowProgress(false);
        } catch (e) { }
    };



    const classes = useStyles();
    return (
        <div >
            <Paper className={`row ${classes.root}`}>

                <div className="col-lg-4 my-5">
                    <select
                        className="form-control"
                        name="customerId"
                        id="customerId"
                        placeholder="Choose a Customer"
                        onChange={() => {
                            fileExport();
                          }}
                    // TODO: Change this code
                    >
                        <option>Select</option>
                        {customersList.map((item, index) => (
                            <option key={index} value={item.Id}>
                                {item.CompanyName}
                            </option>
                        ))}
                    </select>
                    {showProgress && <LinearProgress />}
                    <small className="form-text text-muted">
                        <b>
                            Choose a Customer</b>
                    </small>
                </div>
                {/* <div className="col-md-6  mb-5 align-self-center">
                    <a className="btn btn-success btn-shadow-hover mr-2" href="#">
                        Export
                </a>
                </div> */}
                <div className="mt-5 col-2">{dataState}</div>
                <div className="mt-5 col-2">
                    {showProgress && <CircularProgress disableShrink />}
                </div>

            </Paper>
        </div>
    );
}
