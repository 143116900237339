import React from 'react'
import { Button, Form, Card, Col, Dropdown, Alert } from "react-bootstrap";

export function BatchItemUpdateItems() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Choose a Customer</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Choose a Customer
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Warehouse</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select Warehouse
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Button as="input" type="submit" value="Export Master" />
                    </Form.Row>
                    <br />
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Import File	 </Form.Label>
                            <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                                <Form.Control type="file" placeholder="Password" />
                            </Card>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword"></Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Alert variant="info " className="w-50">
                                Use the 'Browse...' button to select an item import file
                            <p></p>
                            Then press the 'Import Items' button to read the file.
                        </Alert>
                            <Button as="input" type="submit" value="Import Items" />
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Items To Be Updated</Form.Label>
                            <Alert variant="info " className="w-50">
                                Use the 'Browse...' button to select an item import file
                            <p></p>
                            Then press the 'Import Items' button to read the file.
                        </Alert>
                            <Button as="input" type="submit" value="Import Items" />
                        </Form.Group>
                    </Form.Row>

                    <br />
                    <Form.Group controlId="formGridAddress1">
                        <Form.Label>Data From Import File</Form.Label>
                        <Form.Control as="textarea" rows="5" />
                    </Form.Group>

                    <Form.Group controlId="formGridAddress2">
                        <Form.Label>Details of Locations To Be Created</Form.Label>
                        <Form.Control as="textarea" rows="5" />
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    )
}
