import React, { useEffect, useState } from "react";
import { AssignToOutbound } from "../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/AssignToOutbound";
import $ from "jquery";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Modal } from "react-bootstrap";
import { Notification } from "../../modules/Notification";
import Button from "@material-ui/core/Button";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";
import SelectPagination from "../../../_metronic/_partials/widgets/Select/SelectPagination";
import { CreatableSelect3 } from "../../../_metronic/_partials/controls";

const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export function AssigntoOutbound() {
  const [ShipmentIDStat, setShipmentIDStat] = useState(undefined);
  const [ShipmentsIds, setShipmentsIds] = useState([]);
  const [ShipmentLabelStat, setShipmentLabelStat] = useState(undefined);
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);
  const [showAssignTo, setShowAssignTo] = useState(false);
  const [shipmentId, setShipmentId] = useState("");
  const [smId, setSmId] = useState("");

  useEffect(() => {
    GetShipmentsIds();
    window.$ShipmentsAssigntoOutbound = [];
    window.$ReceiptsAssigntoOutbound = [];
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (ShipmentIDStat !== undefined) {
  //       setShipmentIDDiv(<AssignToOutbound ShipmentID={ShipmentIDStat} />);
  //     } else {
  //       setShipmentIDDiv(<Nodatafound />);
  //     }
  //   }, 1);
  // }, [ShipmentIDStat]);

  async function GetShipmentsIds() {
    return await fetch(`${window.$apiurl}/shipments/getids`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setShipmentsIds(res[1]);
      })
      .catch((err) => {});
  }

  async function PostNewShipment() {
    var label = $("#LabelForShipmentID").val() || "";
    return await fetch(
      `${window.$apiurl}/transload/shipment?label=${label}&orderId=`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        setShipmentNewModelt(false);
        GetShipmentsIds();
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  return (
    <>
      <Helmet>
        <title>eMPower | Assign To Outbound</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Shipment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={async () => {
              try {
                setShowAssignTo(false);
                setSmId("");
                setShipmentId("");
                
                setShipmentNewModelt(false);
                var a = await PostNewShipment();
                setSmId({ value: a?.Id, label: a?.Id });
                setShowAssignTo(true);
                setShipmentId(a?.Id);
              } catch (err) {}
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header font-weight-bold py-0 pt-5">
          <div className="form-group row mb-0">
            <div className="col-lg-4 text-left" />
            <div className="col-lg-1 text-left mt-3"></div>

            <div className="col-lg-2 text-left"></div>
            <div className="col-lg-2 text-left">
              <button
                type="button"
                className="btn btn-light-success font-weight-bolder font-size-sm w-100 mt-3 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  setShipmentNewModelt(true);
                }}
              >
                <i className="fas fa-plus-circle"></i> New
              </button>
            </div>
            <div className="col-lg-3 text-left">
              {ShipmentsIds.length === 0 && <LinearProgress />}
              <div className={`mb-3 mt-3`}>
                <SelectPagination
                  options={ShipmentsIds.map((x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  })}
                  name="id"
                  value={smId}
                  onChange={async (newValue) => {
                    try {
                      var i = newValue?.value;
                      $("#LoadingId").fadeIn();
                      var b = await GetShipmentId(i);
                      setShowAssignTo(false);
                      setShipmentId("");
                      if (i !== undefined) {
                        setSmId(newValue);
                        setShipmentId(i);
                        setShowAssignTo(true);
                      } else {
                        setSmId("");
                        setShipmentId("");
                        setShowAssignTo(false);
                      }
                      setShipmentIDStat(b?.id);
                      setShipmentLabelStat(b?.Label);
                    } catch (err) {}
                  }}
                />

                <button
                  style={{ display: "none" }}
                  id="refreshOneMoveMasterButton"
                  onClick={() => {
                    const id = ShipmentIDStat;
                    setShipmentIDStat(undefined);
                    setTimeout(() => {
                      setShipmentIDStat(id);
                    }, 50);
                  }}
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body AssigntoOutboundId">
          {showAssignTo && shipmentId ? (
            <AssignToOutbound ShipmentID={shipmentId} />
          ) : (
            <Nodatafound />
          )}
        </div>
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-lg-4" />
      <div className="col-lg-1 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-lg-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No data found
        </p>
      </div>
      <div className="col-lg-3" />
    </div>
  );
}

async function DeletShipment(id) {
  return await fetch(`${window.$apiurl}/transload/shipment/${id}`, {
    method: "delete",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      if (statusCode === 200) {
        window.$ShipmentsAssigntoOutbound = window.$ShipmentsAssigntoOutbound?.filter(
          (x) => x.Id !== +id
        );
      }
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async () => {})
    .catch((error) => {
      Notification("success", "Delete Shipment ID : " + id);
      console.error(error);
    });
}

async function GetShipmentId(id) {
  return await fetch(
    `${window.$apiurl}/TransloadShipment/GetShipmentItems?shipmentId=${id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      var a = res[1]?.Shipment;
      return { id, Label: a?.Label };
    })
    .catch((error) => {
      Notification("error", "Shipment ID : " + id + " Not Found");
      console.error(error);
      return undefined;
    });
}
