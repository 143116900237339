import { Notification } from "../../../modules/Notification";

export default async function mockPurchaseOrders(mock) {
  
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/customers`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  async function GetWarehouses(id) {
    return await fetch(`${window.$apiurl}/warehouses/${id}`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  

  async function ImportReceipts(someData) {
    console.log("someData");
    console.log(someData);
    return fetch(`${window.$apiurl}/receipts/import`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        let data = {};
        if (statusCode === 400)
          data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------

  mock.onGet("api/receipts/getcustomers").reply(async () => {
    const obj = await GetCustomers();
    return [200, obj];
  });

  mock.onPost("api/receipts/import").reply(async ({ data }) => {
    const purchaseOrders = JSON.parse(data);
    var result = await ImportReceipts(purchaseOrders);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });

  mock.onGet(/api\/receipts\/getwarehouses\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/receipts\/getwarehouses\/(\d+)/)[1];

    var getWarehouses = await GetWarehouses(id);
    if (!getWarehouses) {
      return [400];
    }
    return [200, getWarehouses];
  });
}
