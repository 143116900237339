import MockUtils from "./mock.utils3";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock = [];

export default async function mockPickUpBillingManagement(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(
    status,
    searchText,
    DateType,
    startDate,
    endDate,
    hasAdditional,
    hasSupplemental,
    pageNumber,
    pageSize,
    sortField,
    sortOrder
  ) {
    return await fetch(
      `${window.$apiurl}/PickupBilling/GetPickups?statusId=${status}&filterDate=${DateType}&startDate=${startDate}&endDate=${endDate}&hasAdditional=${hasAdditional}&hasSupplemental=${hasSupplemental}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&search=${searchText}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetModelInvoice(
    ExceptionsStatus,
    searchText,
    startDate,
    endDate,
    isSent,
    pageNumber,
    pageSize,
    sortField,
    sortOrder
  ) {
    return await fetch(
      `${window.$apiurl}/PickupBilling/invoices?startDate=${startDate}&endDate=${endDate}&isSent=${isSent}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&search=${searchText}&exception=${ExceptionsStatus}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/pallets", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/pallets/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData["customer"]),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/pallets/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/pallets/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res ?? [];
      })
      .catch(() => {
        Notification("error", "GetViewmodel not working");
        return false;
      });
  }

  async function GetCharges(id) {
    return await fetch(window.$apiurl + "/Pickup/GetPickupCharges/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res ?? {};
      })
      .catch(() => {
        Notification("error", "GetViewmodel not working");
        return false;
      });
  }

  async function PutCharges(id, values) {
    return await fetch(window.$apiurl + "/Pickup/PutPickupCharges/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then(async (response) => {
      const statusCode = await response.status;
      if (statusCode === 200) {
        Notification("success", "success");
      }
      return statusCode;
    });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/PickUpBillingManagement/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/PickUpBillingManagement/GetCharges").reply(async (data) => {
    var id = JSON.parse(data.data).customerId;
    const obj = await GetCharges(id);
    return [200, obj];
  });

  mock.onPost("api/PickUpBillingManagement/PutCharges").reply(async (data) => {
    const { values, id } = JSON.parse(data.data);
    const obj = await PutCharges(id, values);
    return [obj];
  });

  mock.onPost("api/PickUpBillingManagement").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    await PostModel(customer);
    customerTableMock.push(customer);
    return [200, { customer: customer }];
  });

  mock.onPost("api/PickUpBillingManagement/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    var model;
    if (window.StatusBillingPickUpManagement !== 52) {
      model = await GetModel(
        window.StatusBillingPickUpManagement,
        queryParams.filter.searchText,
        queryParams.filter.DateRangeType,
        queryParams.filter.startDate,
        queryParams.filter.endDate,
        queryParams.filter.hasAdditional,
        queryParams.filter.hasSupplemental,
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder
      );
      delete queryParams.filter.customerIdApi;
      queryParams.TotalItems = model.Pagination.TotalItemCount;
      customerTableMock = ChangeId(model.Result);
    } else {
      model = await GetModelInvoice(
        window.ExceprionsStatusBillingPickUpManagement,
        queryParams.filter.searchText,
        queryParams.filter.startDate,
        queryParams.filter.endDate,
        window.$pickUpMgtIsSent,
        queryParams.pageNumber,
        queryParams.pageSize,
        queryParams.sortField,
        queryParams.sortOrder
      );
      delete queryParams.filter.customerIdApi;
      queryParams.TotalItems = model.Pagination.TotalItemCount;
      customerTableMock = ChangeId(model.Invoices);
    }
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );

    filterdCustomers.statuses = {Status:model.Status,Exceptions:model.Exceptions} || [] /* model.statuses */;
    return [200, filterdCustomers];
  });

  mock
    .onPost("api/PickUpBillingManagement/deleteCustomers")
    .reply(async (config) => {
      const { ids } = JSON.parse(config.data);
      ids.forEach((id) => {
        const index = customerTableMock.findIndex((el) => el.id === id);
        if (index > -1) {
          customerTableMock.splice(index, 1);
        }
      });
      return [200];
    });

  mock
    .onPost("api/PickUpBillingManagement/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/PickUpBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpBillingManagement\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/PickUpBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpBillingManagement\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/PickUpBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpBillingManagement\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
  }
  return list;
}
