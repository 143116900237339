import {
  Chip,
  TextField,
  Typography,
  MenuItem,
  Paper,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import clsx from "clsx";
import { Formik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { RangeDatePicker } from "react-google-flight-datepicker";
import { default as Select, default as SelectMulti } from "react-select";
import { CheckboxLargeWB } from "../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../../modules/Notification";
import { useCustomersUIContext } from "../CustomersUIContext";
const adapter = new LocalStorage("db");
const db = low(adapter);
var typingTimer;

function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [year, month, day].join("-");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "";
    }
  } else {
    return "";
  }
}
const DateRangeTypeList = [
  { value: "created", label: "Creation date" },
  { value: "dispatched", label: "Dispatch date" },
  { value: "picked-up", label: "Picked Up date" },
  { value: "in-transit", label: "In Transit date" },
  { value: "delivered", label: "Delivery Date" },
];
function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const prepareFilter = (queryParams, values) => {
  const {
    DateRangeType = "created",
    shipmentTypeId = window.$ShipmentManagementModeId,
    ServiceId = window.$ShipmentManagementServiceId,
    serviceLevelId,
    equipmentTypeId,
    equipmentSizeId,
    shipFrom,
    Carrier,
    ReLoadTable,
    isHazardousMaterial,
    Cancled,
    Closed,
    isHot,
    IsHeavy,
    searchText,
    startDate = window.$SmGsearchStartDate !== undefined
      ? window.$SmGsearchStartDate
      : moment()
          .add(-28, "days")
          .format("YYYY-MM-DD"),
    endDate = moment().format("YYYY-MM-DD"),
    multiCustomersGet = window.$ShipmentManagementCustomerId !== ""
      ? [
          {
            value: window.$ShipmentManagementCustomerId,
            label: window.$ShipmentManagementCustomerName,
          },
        ]
      : [],
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.multiCustomersGet = multiCustomersGet;
  filter.shipmentTypeId = shipmentTypeId;
  filter.serviceLevelId = serviceLevelId;
  filter.equipmentTypeId = equipmentTypeId;
  filter.equipmentSizeId = equipmentSizeId;
  filter.shipFrom = shipFrom;
  filter.Carrier = Carrier;
  filter.DateRangeType = DateRangeType;
  filter.ServiceId = ServiceId;
  filter.isHazardousMaterial = isHazardousMaterial;
  filter.IsHeavy = IsHeavy;
  filter.isHot = isHot;
  filter.Cancled = Cancled;
  filter.Closed = Closed;
  filter.searchText = searchText;
  filter.startDate = startDate;
  filter.endDate = endDate;
  filter.ReLoadTable = ReLoadTable;
  filter.filter = window.$ShipmentManagementStatus;
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function CustomersFilter({ listLoading }) {
  const [ShipmentTypes, setShipmentTypes] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [ShipFrom, setShipFrom] = useState([]);
  const [CarrierOptions, setCarrierOptions] = useState([]);
  const [ShipmentTypesOption, setShipmentTypesOption] = useState([]);
  const [
    ShipmentServiceLevelsOption,
    setShipmentServiceLevelsOption,
  ] = useState([]);
  const [ShipmentServicesOption, setShipmentServicesOption] = useState([]);
  const [ShipmentServices, setShipmentServices] = useState([]);
  const [ShipmentServiceLeveles, setShipmentServiceLeveles] = useState([]);
  const [ShipmentEquipmentTypes, setShipmentEquipmentTypes] = useState([]);
  const [
    ShipmentEquipmentTypesOption,
    setShipmentEquipmentTypesOption,
  ] = useState([]);
  const [customerIdItem, setCustomerIdItem] = useState({
    value: window.$ShipmentManagementCustomerId,
    label: window.$ShipmentManagementCustomerName,
  });
  const [ServiceId, setServiceId] = useState({
    value: window.$ShipmentManagementServiceId,
    label: window.$ShipmentManagementServiceName,
  });
  const [
    ShipmentEquipmentSizeOption,
    setShipmentEquipmentSizeOption,
  ] = useState([]);
  // const [multiCustomersGet, getMultiSelectCustomers] = useState([{ value: 112, label: "Meijer, Inc." }]);
  // const [CustomerIds, setCustomerIds] = useState("");
  useEffect(() => {
    fetch(window.$apiurl + "/transportation/shipments/getSmViewModel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setCustomers(res.Customers || []);
        setShipFrom(res.Shippers || []);
        setCarrierOptions(res.Vendors || []);
        setShipmentServices(res.Services);
        setShipmentServicesOption(
          res.Services.map((suggestion) => ({
            value: suggestion.Id,
            label: suggestion.Name,
          }))
        );
        var a = res.Services.filter(
          (a) => String(a.Id) === String(window.$ShipmentManagementServiceId)
        );

        if (a[0].Modes.length > 0) {
          setShipmentTypes(a[0].Modes);
          setShipmentTypesOption(
            a[0].Modes.map((suggestion) => ({
              value: suggestion.Id,
              label: suggestion.Name,
            }))
          );
          var j = a[0].Modes.filter(
            (a) => String(a.Name) === String(window.$ShipmentManagementModeName)
          );
          if (j[0].ServiceLevels.length) {
            setShipmentServiceLeveles(j[0].ServiceLevels);

            setShipmentServiceLevelsOption(
              j[0].ServiceLevels.map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))
            );
          }
        }
      })
      .catch(() => {
        Notification("error", "err");
        return false;
      });
  }, []);
  useEffect(() => {
    fetchGlobalSearchFilter();
  }, []);
  function fetchGlobalSearchFilter() {
    try {
      window.$clearableDate = undefined;
      var shipmentDefaultFilter = db.read().getState().User.DefaultFilter;
      shipmentDefaultFilter = shipmentDefaultFilter?.find(x => x.Flow === "SM");  
      const userDbFilterRm = {
        DefaultCustomerId: shipmentDefaultFilter?.DefaultCustomer?.value,
        DefaultCustomerName: shipmentDefaultFilter?.DefaultCustomer?.label,
  
        DefaultServiceId: shipmentDefaultFilter?.DefaultService?.value,
        DefaultServiceName: shipmentDefaultFilter?.DefaultService?.label,
  
        DefaultModeId: shipmentDefaultFilter?.DefaultMode?.value,
        DefaultModeName: shipmentDefaultFilter?.DefaultMode?.label,
        };
      //Filter User Case
      if (!window.$isGlobalSearch) {
        if (userDbFilterRm.DefaultServiceId !== undefined) {
          window.$ShipmentManagementServiceId = userDbFilterRm.DefaultServiceId;
          window.$ShipmentManagementServiceName =
            userDbFilterRm.DefaultServiceName;
          setServiceId({
            value: window.$ShipmentManagementServiceId,
            label: window.$ShipmentManagementServiceName,
          });
        } else {
          window.$ShipmentManagementServiceId = 6;
          setServiceId({
            value: 6,
            label: "Domestic Transportation",
          });
        }

        if (userDbFilterRm.DefaultModeId !== undefined) {
          window.$ShipmentManagementModeId = userDbFilterRm.DefaultModeId;
          window.$ShipmentManagementModeName = userDbFilterRm.DefaultModeName;
          setmodeId({
            value: window.$ShipmentManagementModeId,
            label: window.$ShipmentManagementModeName,
          });
        }else {
          window.$ShipmentManagementModeId = 3;
          setmodeId({
            value: 3,
            label: "OTR (Over The Road)",
          });
        }
        if (userDbFilterRm.DefaultCustomerId !== undefined) {
          window.$ShipmentManagementCustomerId =
            userDbFilterRm.DefaultCustomerId;
          window.$ShipmentManagementCustomerName =
            userDbFilterRm.DefaultCustomerName;
          setCustomerIdItem({
            value: window.$ShipmentManagementCustomerId,
            label: window.$ShipmentManagementCustomerName,
          });
        } else {
          window.$ShipmentManagementCustomerId = "";
          window.$ShipmentManagementCustomerName = "";
          setCustomerIdItem(null);
        }

        //Global Search Case
      } else {
        setServiceId({
          value: window.$ShipmentManagementServiceId,
          label: window.$ShipmentManagementServiceName,
        });
        setmodeId({
          value: window.$ShipmentManagementModeId,
          label: window.$ShipmentManagementModeName,
        });
        setCustomerIdItem({
          value: window.$ShipmentManagementCustomerId,
          label: window.$ShipmentManagementCustomerName,
        });
      }
    } catch (err) {}
  }
  const suggestions = Customers.map((suggestion) => ({
    value: suggestion.Id,
    label: suggestion.Name,
  }));
  const suggestionsShipper = ShipFrom.map((suggestion) => ({
    value: suggestion.Id,
    label: suggestion.Code + "-" + suggestion.Name,
  }));
  const suggestionsCarriers = CarrierOptions.map((suggestion) => ({
    value: suggestion.Id,
    label: suggestion.Code + "-" + suggestion.CompanyName,
  }));
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      {
        filter: {
          CustomerIds: "?customerId=" + window.$ShipmentManagementCustomerId,
          shipmentTypeId: window.$ShipmentManagementModeId,
          ServiceId: window.$ShipmentManagementServiceId,
          serviceLevelId: 0,
          equipmentTypeId: 0,
          equipmentSizeId: 0,
          searchText: window.$SmGsearchText || "",
          shipFrom: "",
          Carrier: "",
          DateRangeType: "created",
          Filter: window.$ShipmentManagementStatus,
          // isHazardousMaterial: false,
          // isHot: false,
          // IsHeavy: false,
          startDate: startDateValue,
          endDate: endDateValue,
        },
        sortOrder: "desc",
        sortField: "id",
        pageNumber: 1,
        pageSize: 10,
      },
      values
    );
    // if (newQueryParams.filter.shipmentTypeId === 0) {
    //   return;
    // }
    // update list by queryParams
    customersUIProps.setQueryParams(newQueryParams);
  };
  const [modeId, setmodeId] = useState({
    value: window.$ShipmentManagementModeId,
    label: window.$ShipmentManagementModeName,
  });
  const [serviceLevelId, setserviceLevelId] = useState({
    value: null,
    label: "Select",
  });
  //startDate
  const [startDateValue, setStartDateValue] = useState(
    window.$SmGsearchStartDate !== undefined
      ? window.$SmGsearchStartDate
      : new Date()
          .addDays(-28)
          .toISOString()
          .split("T")[0]
  );

  //end Date
  const [endDateValue, setEndDateValue] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [IsCancel, setIsCancel] = useState({ value: 1, label: "Active" });
  const listCanceled = [
    { value: 1, label: "Active" },
    { value: 2, label: "Canceled" },
  ];
  useEffect(() => {
    const value = window.$ShipmentManagementCanceled ;
    if(value === true){
      setIsCancel({ value: 2, label: "Canceled" });
    }
    if(value === false){
      setIsCancel({ value: 1, label: "Active" });
    }
  },[]);
  return (
    <>
      <Formik
        initialValues={{
          multiCustomersGet:
            window.$ShipmentManagementCustomerId !== ""
              ? [
                  {
                    value: window.$ShipmentManagementCustomerId,
                    label: window.$ShipmentManagementCustomerName,
                  },
                ]
              : [],
          CustomerIds: "?customerId=" + window.$ShipmentManagementCustomerId,
          ServiceId: window.$ShipmentManagementServiceId,
          shipmentTypeId: window.$ShipmentManagementModeId,
          serviceLevelId: 0,
          equipmentTypeId: 0,
          equipmentSizeId: 0,
          shipFrom: "",
          Carrier: "",
          DateRangeType: "created",
          Filter: window.$ShipmentManagementStatus,
          searchText: window.$SmGsearchText || "",
          startDate:
            window.$SmGsearchStartDate !== undefined
              ? window.$SmGsearchStartDate
              : moment()
                  .add(-28, "days")
                  .format("MM-DD-YYYY"),
          endDate: moment().format("MM-DD-YYYY"),
          ReLoadTable: 0,
          isHot: false,
          isHeavy: false,
          isHazardousMaterial: false,
          Cancled: window.$ShipmentManagementCanceled,
          Closed: window.$ShipmentManagementClosed,
        }}
        onSubmit={async (values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            id="FilterShipmentManagement"
            className="form form-label-right"
          >
            <input
              type="button"
              className="d-none"
              id="ReLoadTableId"
              onClick={(e) => {
                if (e.target.value === "0")
                  document.getElementById("ReLoadTableId").value = 1;
                else document.getElementById("ReLoadTableId").value = 0;
                setFieldValue("ReLoadTable", e.target.value);
                handleSubmit();
              }}
            />
            <div className="form-group row mb-1">
              <div className="col-xl-3 col-lg-4 col-md-4">
                <p className="mb-2">Search</p>
                <input
                  type="search"
                  className="form-control"
                  name="searchText"
                  id="searchTextTable"
                  placeholder="in all fields"
                  onBlur={handleBlur}
                  value={values.searchText || window.$SmGsearchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    window.$SmGsearchText = e.target.value;
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(function() {
                      handleSubmit();
                    }, 500);
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4">
                {Customers && <p className="mb-2">Customers</p>}
                {Customers && (
                  <SelectMulti
                    id="multiCustomersGet"
                    isMulti
                    value={
                      customerIdItem?.value &&
                      db
                        .read()
                        .getState()
                        .User.DefaultFilter?.find((x) => x.Flow === "SM")
                        ?.DefaultCustomerId !== null
                        ? [customerIdItem]
                        : values.multiCustomersGet
                    }
                    name="multiCustomersGet"
                    options={suggestions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(e) => {
                      var obj = "";
                      var listCustomer = [];
                      if (e?.length === 0) {
                        setFieldValue("multiCustomersGet", []);
                        window.$CustomerListOverview = "";
                        window.$CustomerIdsSm = "?customerId=";
                        window.$ShipmentManagementCustomerId = "";
                        setFieldValue("multiCustomersGet", []);
                        setCustomerIdItem(null);
                      } else if (e !== null) {
                        e.forEach((item, index) => {
                          listCustomer.push(item.label);
                          obj +=
                            (index === 0 ? "?customerId=" : "&customerId=") +
                            item.value;
                        });
                        setCustomerIdItem(obj);
                        window.$CustomerListOverview = listCustomer;
                        window.$CustomerIdsSm = obj;
                        setFieldValue("multiCustomersGet", e);
                      } else {
                        setCustomerIdItem(null);
                        window.$CustomerListOverview = "";
                        window.$CustomerIdsSm = "?customerId=";
                        window.$ShipmentManagementCustomerId = "";
                        setFieldValue("multiCustomersGet", []);
                      }
                      handleSubmit();
                    }}
                  />
                )}
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-xl-0 mt-lg-0 mt-md-0">
                <p className="mb-2">Service</p>
                <Select
                  isClearable
                  name="ServiceId"
                  id="ServiceId"
                  label="Service"
                  inputId="react-select-single"
                  value={ServiceId}
                  defaultValue={ShipmentServicesOption.filter(
                    (a) =>
                      String(a.value) ===
                      String(window.$ShipmentManagementServiceId)
                  )}
                  placeholder="Service"
                  options={ShipmentServicesOption}
                  onChange={(e) => {
                    setServiceId(e || { value: "", label: "Select" });
                    setmodeId({ value: "", label: "Select" });
                    values.IsCustomerFilter = false;
                    window.$ShipmentManagementServiceId = e?.value;
                    window.$ShipmentManagementServiceName = e?.label;
                    setFieldValue("ServiceId", e?.value);
                    setFieldValue("shipmentTypeId", 0);
                    setFieldValue("serviceLevelId", 0);
                    setFieldValue("equipmentTypeId", 0);
                    setFieldValue("equipmentSizeId", 0);
                    window.$ShipmentManagementType = "";
                    window.$ShipmentManagementModeId = "";
                    window.$ShipmentManagementServiceLevelId = "";
                    window.$ShipmentManagementModeName = "";
                    window.$ShipmentManagementStatusName = "All";
                    handleSubmit();
                    var a = ShipmentServices.filter((a) => a.Id === e?.value);
                    if (a[0]?.Modes.length > 0) {
                      setShipmentTypes(a[0].Modes);
                      setShipmentTypesOption(
                        a[0].Modes.map((suggestion) => ({
                          value: suggestion.Id,
                          label: suggestion.Name,
                        }))
                      );
                      setShipmentServiceLevelsOption([]);
                      setShipmentEquipmentSizeOption([]);
                      setShipmentEquipmentTypesOption([]);
                    } else {
                      Notification(
                        "warning",
                        "this Service doesn't have any mode"
                      );
                      setShipmentTypesOption([]);
                      setShipmentServiceLevelsOption([]);
                      setShipmentEquipmentSizeOption([]);
                      setShipmentEquipmentTypesOption([]);
                    }
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-xl-0 mt-lg-4 mt-md-4 ">
                <p className="mb-2">Mode</p>
                <Select
                  name="ModeId"
                  id="ModeId"
                  isClearable
                  label="Mode"
                  value={modeId}
                  inputId="react-select-single"
                  defaultValue={ShipmentTypesOption.filter(
                    (a) =>
                      String(a.value) ===
                      String(window.$ShipmentManagementModeId)
                  )}
                  placeholder="Mode"
                  options={ShipmentTypesOption}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setmodeId({ value: "", label: "Select" });
                      setFieldValue("shipmentTypeId", "");
                      setFieldValue("serviceLevelId", 0);
                      setFieldValue("equipmentTypeId", 0);
                      setFieldValue("equipmentSizeId", 0);
                      window.$ShipmentManagementType = "";
                      window.$ShipmentManagementModeId = "";
                      window.$ShipmentManagementModeName = "";
                      window.$ShipmentManagementServiceLevelId = "";
                      handleSubmit();
                      setShipmentServiceLevelsOption([]);
                      setShipmentEquipmentSizeOption([]);
                      setShipmentEquipmentTypesOption([]);
                    } else {
                      setmodeId(e);
                      setFieldValue("shipmentTypeId", e.value);
                      setFieldValue("serviceLevelId", 0);
                      setFieldValue("equipmentTypeId", 0);
                      setFieldValue("equipmentSizeId", 0);
                      window.$ShipmentManagementType = e.label;
                      window.$ShipmentManagementModeId = e?.value;
                      window.$ShipmentManagementModeName = e?.label;
                      window.$ShipmentManagementServiceLevelId = "";
                      handleSubmit();
                      var a = ShipmentTypes.filter((a) => a.Id === e?.value);
                      if (a[0].ServiceLevels.length > 0) {
                        setShipmentServiceLeveles(a[0].ServiceLevels);

                        setShipmentServiceLevelsOption(
                          a[0].ServiceLevels.map((suggestion) => ({
                            value: suggestion.Id,
                            label: suggestion.Name,
                          }))
                        );
                        setShipmentEquipmentSizeOption([]);
                        setShipmentEquipmentTypesOption([]);
                      } else {
                        Notification(
                          "warning",
                          "this mode doesn't have any Service Level"
                        );
                        setShipmentServiceLevelsOption([]);
                        setShipmentEquipmentSizeOption([]);
                        setShipmentEquipmentTypesOption([]);
                      }
                    }
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-md-4 mt-xl-4 mt-lg-4">
                <p className="mb-2">Service Level</p>
                <Select
                  name="serviceLevelId"
                  id="serviceLevelId"
                  isClearable
                  label="Servic Level"
                  inputId="react-select-single"
                  placeholder="Servic Level"
                  defaultValue={ShipmentTypesOption.filter(
                    (a) => a.value === window.$ShipmentManagementServiceLevelId
                  )}
                  value={serviceLevelId}
                  options={ShipmentServiceLevelsOption}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setserviceLevelId({ value: "", label: "Select" });
                      setFieldValue("serviceLevelId", 0);
                      setFieldValue("equipmentTypeId", 0);
                      setFieldValue("equipmentSizeId", 0);
                      window.$ShipmentManagementServiceLevelId = "";
                      handleSubmit();
                      setShipmentEquipmentSizeOption([]);
                      setShipmentEquipmentTypesOption([]);
                    } else {
                      setserviceLevelId(e);
                      setFieldValue("serviceLevelId", e?.value);
                      setFieldValue("equipmentTypeId", 0);
                      setFieldValue("equipmentSizeId", 0);
                      window.$ShipmentManagementServiceLevelId = e.value;
                      handleSubmit();
                      var a = ShipmentServiceLeveles.filter(
                        (a) => a.Id === e?.value
                      );
                      if (a[0].EquipmentTypes.length > 0) {
                        setShipmentEquipmentTypes(a[0].EquipmentTypes);
                        setShipmentEquipmentTypesOption(
                          a[0].EquipmentTypes.map((suggestion) => ({
                            value: suggestion.Id,
                            label: suggestion.Name,
                          }))
                        );
                        setShipmentEquipmentSizeOption([]);
                      } else {
                        Notification(
                          "warning",
                          "this Service Level doesn't have any Equipment Type"
                        );
                        setShipmentEquipmentSizeOption([]);
                        setShipmentEquipmentTypesOption([]);
                      }
                    }
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
                <p className="mb-2">Equipment Type</p>
                <Select
                  isClearable
                  name="equipmentTypeId"
                  id="equipmentTypeId"
                  label="Equipment Type"
                  inputId="react-select-single"
                  placeholder="Equipment Type"
                  value={
                    ShipmentEquipmentTypesOption.filter(
                      (a) => a.value === values.equipmentTypeId
                    ) || ""
                  }
                  options={ShipmentEquipmentTypesOption}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setFieldValue("equipmentTypeId", e?.value);
                      setFieldValue("equipmentSizeId", 0);
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                      setShipmentEquipmentSizeOption([]);
                    } else {
                      setFieldValue("equipmentTypeId", e?.value);
                      setFieldValue("equipmentSizeId", 0);
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                      var a = ShipmentEquipmentTypes.filter(
                        (a) => a.Id === e?.value
                      );
                      if (a[0].EquipmentSizes.length > 0) {
                        setShipmentEquipmentSizeOption(
                          a[0].EquipmentSizes.map((suggestion) => ({
                            value: suggestion.Id,
                            label: suggestion.Name,
                          }))
                        );
                      } else {
                        Notification(
                          "warning",
                          "this Equipment Type doesn't have any Equipment Size"
                        );
                        setShipmentEquipmentSizeOption([]);
                      }
                    }
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
                <p className="mb-2">Equipment Size</p>
                <Select
                  isClearable
                  name="equipmentSizeId"
                  id="equipmentSizeId"
                  label="Equipment Size"
                  inputId="react-select-single"
                  placeholder="Equipment Size"
                  value={
                    ShipmentEquipmentSizeOption.filter(
                      (a) => a.value === values.equipmentSizeId
                    ) || ""
                  }
                  options={ShipmentEquipmentSizeOption}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setFieldValue("equipmentSizeId", "");
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                    } else {
                      setFieldValue("equipmentSizeId", e?.value);
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                    }
                  }}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
                <p className="mb-2">Ship From</p>
                <Select
                  isClearable
                  name="shipFrom"
                  id="shipFrom"
                  label="Ship From"
                  inputId="react-select-single"
                  placeholder="ship From"
                  options={suggestionsShipper}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setFieldValue("shipFrom", "");
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                    } else {
                      setFieldValue("shipFrom", e?.value);
                      //window.$ShipmentManagementType = e.label;
                      handleSubmit();
                    }
                  }}
                />
              </div>

              <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
                <p className="mb-2">Date Filter</p>
                <Select
                  isClearable
                  name="DateRangeType"
                  id="DateRangeType"
                  label="Date Filter"
                  inputId="react-select-single"
                  placeholder="Date Filter"
                  options={DateRangeTypeList}
                  value={DateRangeTypeList.filter(
                    (a) => String(a.value) === values.DateRangeType
                  )}
                  onChange={(e) => {
                    values.IsCustomerFilter = false;
                    if (e === null) {
                      setFieldValue("DateRangeType", "created");
                      handleSubmit();
                    } else {
                      setFieldValue("DateRangeType", e?.value);
                      handleSubmit();
                    }
                  }}
                />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 mt-4 smDateRange">
                <p>Date Start - End</p>
                <RangeDatePicker
                  startDate={startDateValue}
                  endDate={endDateValue}
                  onChange={(startDate, endDate) => {
                    setStartDateValue(startDate);
                    setEndDateValue(endDate);
                    values.IsCustomerFilter = false;
                    window.$SmGsearchStartDate =
                      formatDate(startDate) + "T12:00";
                    setFieldValue(
                      "startDate",
                      formatDate(startDate) + "T12:00"
                    );
                    setFieldValue("endDate", formatDate(endDate) + "T12:00");
                    handleSubmit();
                  }}
                  dateFormat="MM/DD/YYYY"
                  minDate={new Date(1900, 0, 1)}
                  maxDate={new Date(2100, 0, 1)}
                  startDatePlaceholder="Start Date"
                  endDatePlaceholder="End Date"
                  disabled={false}
                  className="my-own-class-name"
                  startWeekDay="monday"
                />
              </div>

              {window.$ShipmentManagementStatus !== 1 &&
              window.$ShipmentManagementStatus !== 2 ? (
                <div className="col-xl-3 col-lg-4 col-md-4 mt-4">
                  <p className="mb-2">Carrier </p>
                  <Select
                    isClearable
                    name="Carrier"
                    id="Carrier"
                    label="Carrier"
                    value={
                      suggestionsCarriers.filter(
                        (a) => a.value === values.Carrier
                      ) || ""
                    }
                    inputId="react-select-single"
                    placeholder="Carrier"
                    options={suggestionsCarriers}
                    onChange={(e) => {
                      values.IsCustomerFilter = false;
                      if (e === null) {
                        setFieldValue("Carrier", "");
                        //window.$ShipmentManagementType = e.label;
                        handleSubmit();
                      } else {
                        setFieldValue("Carrier", e?.value);
                        //window.$ShipmentManagementType = e.label;
                        handleSubmit();
                      }
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="col-xl-3 col-lg-3 col-md-6 mt-4 checkbox-management">
                <label>Is Canceled</label>
                <Select
                  name="Canceled"
                  onChange={(e) => {
                    let value = e?.value;
                    if (value === 1) {
                      value = false;
                    } else if (value === 2) {
                      value = true;
                    } else {
                      value = null;
                    }
                    window.$ShipmentManagementCanceled = value;
                    if (e !== null) {
                      setIsCancel(e);
                    } else {
                      setIsCancel({ value: "", label: "Both" });
                      window.$ShipmentManagementCanceled = null;
                    }
                    values.IsCustomerFilter = false;
                    setFieldValue("Cancled", value);
                    handleSubmit();
                  }}
                  isClearable
                  options={listCanceled}
                  value={IsCancel}
                />
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12 mt-md-12 checkbox-management">
                <div className="row">
                  <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
                    <CheckboxLargeWB
                      className="font-size-md pr-4"
                      value={values.isHot}
                      text={"Hot"}
                      checked={values.isHot}
                      id="checkboxisHote"
                      onChange={(e) => {
                        values.IsCustomerFilter = false;
                        setFieldValue("isHot", e.target.checked);
                        window.$ShipmentManagementHot = `${e.target.checked}`;
                        handleSubmit();
                      }}
                    />
                    <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                      <i className="fab fa-hotjar text-danger"></i>
                    </span>
                  </div>
                  <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
                    <CheckboxLargeWB
                      className="font-size-md"
                      text={"HazMat"}
                      id="checkboxisHazardousMaterial2"
                      value={values.isHazardousMaterial}
                      checked={values.isHazardousMaterial}
                      onChange={(e) => {
                        values.IsCustomerFilter = false;
                        setFieldValue("isHazardousMaterial", e.target.checked);
                        window.$ShipmentManagementHazmat = `${e.target.checked}`;
                        handleSubmit();
                      }}
                    />
                    <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                      <i className="fas fa-skull-crossbones text-warning"></i>
                    </span>
                  </div>
                  <div className="pr-xl-3 pr-lg-3 pr-md-10 pr-4 d-flex">
                    <CheckboxLargeWB
                      className="font-size-md"
                      text={"Heavy"}
                      id="checkboxIsHeavy"
                      value={values.IsHeavy}
                      checked={values.IsHeavy}
                      onChange={(e) => {
                        values.IsCustomerFilter = false;
                        setFieldValue("IsHeavy", e.target.checked);
                        window.$ShipmentManagementHeavy = `${e.target.checked}`;
                        handleSubmit();
                      }}
                    />
                    <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                      <i className="fas fa-weight-hanging text-info"></i>
                    </span>
                  </div>
                  <div className="d-flex">
                    <CheckboxLargeWB
                      className="font-size-md"
                      text={"Closed"}
                      id="checkboxClosed"
                      value={values.Closed}
                      checked={values.Closed}
                      onChange={(e) => {
                        values.IsCustomerFilter = false;
                        window.$ShipmentManagementClosed = e.target.checked;
                        setFieldValue("Closed", e.target.checked);
                        handleSubmit();
                      }}
                    />
                    <span className="navi-icon" style={{ marginTop: "0.6rem" }}>
                      <i className="fas fa-times-circle text-warning"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="col-xl-2 col-lg-2 col-md-8 mt-md-12 ml-auto"
                style={{ textAlignLast: "right" }}
              >
                <a
                  href={() => false}
                  onClick={(event) => {
                    try {
                      setFieldValue("serviceId", "");
                      window.$ShipmentManagementServiceName = "";
                      window.$CustomerListOverview = "";
                      window.$ShipmentManagementServiceId = "";
                      window.$ShipmentManagementModeName = "";
                      window.$ShipmentManagementModeId = "";
                      window.$ShipmentManagementServiceLevelId = "";
                      setServiceId({ value: "", label: "Select" });
                      setmodeId({ value: "", label: "Select" });
                      setFieldValue("modeId", "");
                      window.$ShipmentManagementModeId = "";
                      setFieldValue("serviceLevelId", "");
                      setserviceLevelId({ value: "", label: "Select" });
                      setFieldValue("equipmentTypeId", "");
                      setFieldValue("equipmentSizeId", "");

                      setFieldValue("searchText", "");
                      window.$SmGsearchText = "";
                      setFieldValue("multiCustomersGet", []);
                      setCustomerIdItem(null);
                      window.$CustomerIdsSm = "?customerId=";

                      setFieldValue("DateRangeType", "created");

                      //Date reset
                      setStartDateValue(null);
                      setEndDateValue(null);
                      //Checkbox reset
                      setFieldValue("isHot", false);
                      setFieldValue("isHeavy", false);
                      setFieldValue("isHazardousMaterial", false);

                      setFieldValue("Cancled", false);
                      setIsCancel({ value: "", label: "Both" });
                      setFieldValue("Closed", false);
                      window.$ShipmentManagementCustomerId = "";
                      window.$clearableDate = true;
                      window.$ShipmentManagementHot = false;
                      window.$ShipmentManagementHeavy = false;
                      window.$ShipmentManagementHazmat = false;
                      window.$ShipmentManagementClosed = false;
                      window.$ShipmentManagementCanceled = false;
                      window.$isGlobalSearch = undefined;
                      handleSubmit();
                    } catch (err) {}
                  }}
                  className="btn  btn-light-info btn-elevate"
                >
                  <i class="fas fa-filter pr-3"></i>
                  Clear Filters
                </a>
              </div>
            </div>
            <div className="form-group row mb-1 mt-3">
              <div className="col-lg-13 bg-light-warning ml-5">
                <small className="pr-3">
                  WORKFLOW:
                  <b> {window.$ShipmentManagementStatusName} Shipments</b>
                </small>
                <small className="pr-3">
                  CUSTOMER:{" "}
                  <b>
                    {window.$CustomerListOverview !== undefined &&
                    window.$CustomerListOverview !== ""
                      ? window.$CustomerListOverview.map((item) => item + " , ")
                      : window.$CustomerListOverview === ""
                      ? "All"
                      : "All"}
                  </b>
                </small>
                <small className="pr-3">
                  SERVICE: <b>{window.$ShipmentManagementServiceName}</b>
                </small>
                <small className="pr-3">
                  MODE: <b>{window.$ShipmentManagementModeName}</b>
                </small>
                <small className="pr-3">
                  HOT: <b>{window.$ShipmentManagementHot}</b>
                </small>
                <small className="pr-3">
                  HAZMAT: <b>{window.$ShipmentManagementHazmat}</b>
                </small>
                <small className="pr-3">
                  Heavy: <b>{window.$ShipmentManagementHeavy}</b>
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
