import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import {
    Row,
    Col
} from "react-bootstrap";
import { Formik } from "formik";
const useStyles = makeStyles(theme => ({
    textDecoration: {
        lineHeight: '2rem',
    },
}));
export function CustomerDialogForm() {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: '',
        age: '',
        multiline: 'Controlled',
        currency: 'EUR',
    });
    const [value, setValue] = React.useState('female');
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    return (
        <div>
            <button
                type="button"
                className="btn btn-success btn-shadow-hover mr-2"
                variant="outlined" onClick={handleClickOpen}
            >
                Services
          </button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Manage Carriers - Services</DialogTitle>
                <DialogContent>

                    <Row>
                        <button
                            type="button"
                            className="btn btn-success btn-shadow-hover mr-2"
                        >
                            Add
          </button>
                        <button
                            type="button"
                            className="btn btn-success btn-shadow-hover mr-2"
                        >
                            Move Up
          </button>
                        <button
                            type="button"
                            className="btn btn-success btn-shadow-hover mr-2"
                        >
                            Move Down
          </button>
                        <Col>
                            <div className="d-flex align-items-center my-5">
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mr-4">
                                    <input type="checkbox" name="" onChange={() => { }} value="1" />
                                    <span></span>
                                </label>

                                <div className="d-flex flex-column flex-grow-1">
                                    Show Inactives
                            </div>
                            </div>
                        </Col>

                        <button
                            type="button"
                            className="btn btn-success btn-shadow-hover mr-2"
                        >
                            Refresh Grid
          </button>
                    </Row>
                    <Formik
                        enableReinitialize={true}
                        onSubmit={(values) => {
                        }}
                    >
                        {({ handleSubmit }) => (
                            <>
                                <div className="row">
                                    <div className="col-md-12 my-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <small class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
                                                    Some transactions do not have an invoice delivered date set.
                                                </small>
                                                <div className="d-flex align-items-center my-5">
                                                    <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mr-4">
                                                        <input type="checkbox" name="" onChange={() => { }} value="1" />
                                                        <span></span>
                                                    </label>

                                                    <div className="d-flex flex-column flex-grow-1">
                                                        <a
                                                            href="#"
                                                            className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                                                        >
                                                            Set transaction's invoice delivered date to today
                                            </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Formik>
                    <Row>
                        <button
                            type="button"
                            className="btn btn-success btn-shadow-hover mr-2"
                        >
                            Delete
          </button>
                    </Row>
                </DialogContent>
                <DialogActions>
                    <button
                        color="primary"
                        onClick={handleClose}
                        type="button"
                        className="btn btn-secondary btn-shadow-hover mr-2"
                    >
                        Cancel
          </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
