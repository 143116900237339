import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import { Notification } from "../../../modules/Notification";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({
  iShipmentsID = [],
  IsClosedFunction,
  IsClosed,
}) {
  const [Table, setTable] = useState([]);
  const [DeletRowId, setDeletRowId] = useState(0);
  const [ShipmentsID, setShipmentsID] = useState(iShipmentsID);
  const [ReloadPalletsList, setReloadPalletsList] = useState(true);

  function ReloadPallets() {
    setReloadPalletsList(false);
    setTimeout(() => {
      setReloadPalletsList(true);
    }, 1);
  }

  useEffect(() => {
    setShipmentsID(iShipmentsID);
  }, [iShipmentsID]);

  function SetTableShipment(row) {
    var table = Table;
    var a = table.findIndex((x) => x?.Id === row?.Id);
    if (a > -1) {
      table[a] = row;
      setTable([]);
      setTimeout(() => {
        setTable(table);
      }, 1);
    } else setTable((x) => [...x, row]);
  }

  function DeleteShipmentRow(id) {
    RemovePallet(id, ShipmentsID[0]).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        var a = Table.filter((x) => x?.Id !== id);
        var b = Table.find((x) => x?.Id === id);
        setTable([]);
        setDeletRowId(0);
        setTimeout(() => {
          setTable(a);
          setDeletRowId(b);
        }, 1);
      } else {
        Notification("error", Message?.toString());
      }
    });
  }

  useEffect(() => {
    if (ShipmentsID.length > 0)
      GetPalletsForShipments(ShipmentsID[0], setTable);
  }, [ShipmentsID]);

  return (
    <>
      {ShipmentsID.map((x, i) => (
        <TableComponent
          list={Table}
          ShipmentID={x}
          DeleteShipmentRow={DeleteShipmentRow}
          key={i}
          ReloadPallets={ReloadPallets}
          IsClosedFunction={IsClosedFunction}
        />
      ))}

      {ShipmentsID.length > 0 && ReloadPalletsList && !IsClosed && (
        <IndexPalletsList
          SetTableShipment={SetTableShipment}
          DeletRowId={DeletRowId}
          ShipmentID={ShipmentsID[0]}
        />
      )}
      {IsClosed && <Nodatafound />}
    </>
  );
}
async function GetPalletsForShipments(id, setTable) {
  return await fetch(`${window.$apiurl}/pallets/getpalletbyshipment/${id}`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      setTable(res[1]?.pallets ?? []);
    });
}
async function RemovePallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/deletepalletfromshipment`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ShipmentId: ShipmentID,
      PalletId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Shipment Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}
