import React from 'react'
import { Button, Form, Card, Dropdown } from "react-bootstrap";

export function CancelAssemblyItems() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>

                        <div class='col-12 mt-5  mb-5'>
                            <div className="d-flex align-items-center">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input type="checkbox" name="" onChange={() => { }} value="1" />
                                    <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                    <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Sort By Customer</a>
                                </div>
                            </div>
                        </div>

                        <div class='col-12'>
                            <Form.Label>Enter or Choose a Transaction #</Form.Label>
                            <Dropdown className="form-control p-0  w-50">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div class='col-12 font-weight-bold mt-5 mb-5 text-primary'>
                            - Or -
                        </div>
                        <div class='col-12'>
                            <Form.Label>Enter or Choose a Reference #</Form.Label>
                            <Dropdown className="form-control p-0 w-50">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-12 mt-5'>
                            <Button as="input" type="submit" value="Select" />
                        </div>

                    </Form.Row>

                </Form>
            </Card.Body>
        </Card>
    )
}
