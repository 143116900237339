import {
  Zoom,
  Typography,
  Switch,
  makeStyles,
  Popover,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export function SimplePopover({
  body = <></>,
  titleButton = "",
  classBtn = "",
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        className={classBtn}
      >
        {titleButton}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography className={classes.typography}>{body}</Typography>
      </Popover>
    </div>
  );
}

export function SimpleZoom({ body = <></>, titleButton = "" }) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  function handleChange() {
    setChecked((prev) => !prev);
  }

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label={titleButton}
      />
      <div className={classes.container + " " + (!checked && " d-none")}>
        <Zoom in={checked}>{body}</Zoom>
      </div>
    </div>
  );
}
