import React from 'react'
import { Button, Form, Card, Dropdown } from "react-bootstrap";
import {Icon } from "@material-ui/core";
import clsx from "clsx";


export function ErrorLog() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">CHOOSE WHICH ITEMS TO DISPLAY</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>

                        <div class=' col-4'>
                            <Form.Label>Choose a Customer	</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </Form.Row>

                    <br />
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary"></h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>

                        <div class='col-6'>
                            <Form.Label>START DATE <Icon className={clsx("fa fa-calendar-alt mt-5 ml-2 text-primary")} style={{fontSize:14}} /></Form.Label>
                            <Form.Control type="date" />
                        </div>

                        <div class='col-6'>
                            <Form.Label>END DATE <Icon className={clsx("fa fa-calendar-alt mt-5 ml-2 text-primary")} style={{fontSize:14}} /></Form.Label>
                            <Form.Control type="date" />
                        </div>

                        <div class='col-6 mt-5'>
                            <Form.Label>Task</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-6 mt-5'>
                            <Form.Label>Error Contains</Form.Label>
                            <Form.Control type="text" placeholder="New Description" />
                        </div>

                        <div class='col-12 mt-5 row'>
                            <div class='col-3'>
                                <Button as="input" type="submit" value="Refresh Grid" />
                            </div>
                            <div class='col-3'>
                                <Button as="input" type="submit" value="Reset Filters" />
                            </div>
                        </div>
                    </Form.Row>


                    <br /><br />
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ID</th>
                                <th scope="col">Date/Time</th>
                                <th scope="col">Task</th>
                                <th scope="col">Subtask</th>
                                <th scope="col">Error Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </Form>
            </Card.Body>
        </Card>
    )
}
