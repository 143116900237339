import { default as JsNative } from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import Draggable from "react-draggable";
import { DetailMangment } from "../../../../../modules/eMPower/pages/PalletsManagmentBeta/customers-table/ModelManagment";
import { Notification } from "../../../../../modules/Notification";
import Item from "./Item";
const adapter = new LocalStorage("db");
const db = low(adapter);

const palletNolane = "zzzz pallets no lane";
export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  iShipmentsID = [],
  setLoading,
  setShipmentsIds,
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);
  const [Search2, setSearch2] = useState("");
  const [ShowModel, setShowModel] = useState(false);
  const [IdSelect, setIdSelect] = useState();
  const [CheckboxListIds, setCheckboxListIds] = useState([]);

  function SelectIDforPallet(id) {
    setIdSelect(id);
    setShowModel(true);
  }

  useEffect(() => {
    GetPalletsFunction();
  }, [iShipmentsID]);

  useEffect(() => {
    if (DeletRowId?.id !== 0) {
      window.IdsExist = window.IdsExist.filter((y) => y !== DeletRowId?.id);
      GetPalletsFunction();
    }
  }, [DeletRowId]);

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }
    setCheckboxListIds([...array]);
  }

  var tableElement, offset;
  function GetTale() {
    var shipID = Pallets.find((x) => x.Id === CheckboxListIds[0])?.ShipID;
    tableElement = JsNative(".TableComponent" + shipID);
    offset = tableElement.offset();

    return shipID;
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      ele.addClass("bg-light-info");
    } else ele.removeClass("bg-light-info");
    ele.addClass("border border-info border-2");
  }

  function handleStop(e) {
    var shipID = GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      PostPallets(CheckboxListIds, shipID);
    }
    ele.removeClass("bg-light-info");
    ele.removeClass("border border-info border-2");
  }

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }
    setCheckboxListIds([...array]);
  }

  async function PostPallets(ItemsId, PalletId) {
    for (var pallet of ItemsId) {
      await PostPallet(pallet, PalletId).then(({ status, Message }) => {
        if (200 === status) {
          Notification("success", "success : " + pallet);
          var a = Pallets.findIndex((y) => pallet === y.Id);
          SetTableShipment(Pallets[a]);
        } else {
          Notification("error", Message?.toString());
        }
      });
    }
    var find = Pallets.filter((x) => ItemsId.indexOf(x.Id) === -1);
    setPallets([]);
    setTimeout(() => {
      setPallets(find);
    }, 1);
    setCheckboxListIds([]);
  }

  async function GetPalletsFunction() {
    var listOrigin = Pallets;
    var list = [];
    var listIdsExist = [];

    setLoading(true);
    setPallets([]);

    await Promise.all(
      (iShipmentsID ?? []).map(async (x) => {
        listIdsExist.push(x?.Id);
        await GetPallets(x?.Id, setShipmentsIds).then((res) => {
          list = list.concat(res);
        });
      })
    );

    window.IdsExist = listIdsExist;
    setPallets(list);
    setLoading(false);
    setShow(true);
  }

  const repeat = [];
  const CheckReapet = (id) => {
    if (repeat.indexOf(id) === -1) {
      repeat.push(id);
      return true;
    } else return false;
  };

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) setPallets((x) => [...x, DeletRowId]);
  }, [DeletRowId]);

  function removeId(id) {
    var a = Pallets.filter((x) => x?.Id !== id);
    setPallets(a ?? []);
  }

  function compare(a, b) {
    if (a.Location < b.Location) {
      return -1;
    }
    if (a.Location > b.Location) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Modal
        size="xl"
        show={ShowModel}
        onHide={() => {
          setShowModel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={IdSelect} setShow={setShowModel.bind()} />
      </Modal>
      <div className="row">
        <div className="col-lg-12">
          <input
            type="text"
            placeholder="Filter by Pallet Number"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) setSearch2(e.target.value);
            }}
            onBlur={(e) => {
              setSearch2(e.target.value);
            }}
          />
        </div>
        <div className={"col-12 my-2 mx-0"}>
          {CheckboxListIds?.length > 0 && (
            <Draggable
              axis="both"
              handle=".handle"
              position={{ x: 0, y: 0 }}
              grid={[1, 1]}
              scale={1}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <div className="handle">
                <button
                  type="button"
                  className={`btn btn-light-danger bg-light-danger text-danger w-100 p-0`}
                  style={{ height: "41px" }}
                >
                  {htmlP(CheckboxListIds, "Pallets Selected")}
                </button>
              </div>
            </Draggable>
          )}
        </div>
        {Pallets?.length > 0 ? (
          <>
            {Show &&
              Pallets.filter(
                (x) =>
                  x?.Id?.toString()?.includes(Search2) &&
                  iShipmentsID.find((y) => y.Id === x.ShipID)
              )
                .sort(compare)
                .map((x, i) => (
                  <>
                    {CheckReapet(x?.Location) && (
                      <>
                        {i !== 0 && (
                          <>
                            <div className="col-12 my-5 py-2" />
                          </>
                        )}
                        <div className="col-12 mb-2 mx-0">
                          <div className="alert alert-secondary pb-0 pt-1">
                            <b>
                              {x?.Location === palletNolane
                                ? ""
                                : "Pallets For"}
                              <b
                                className={`${
                                  x?.Location === palletNolane ? "" : "ml-2"
                                } text-${
                                  x?.Location === palletNolane
                                    ? "warning"
                                    : "primary"
                                } mr-5 pr-5`}
                              >
                                {x?.Location === palletNolane ? "" : x.ShipID}
                                <i
                                  class={`${
                                    x?.Location === palletNolane
                                      ? ""
                                      : "ml-5 pl-5"
                                  } pr-3 fas fa-location-arrow text-${
                                    x?.Location === palletNolane
                                      ? "warning"
                                      : "primary"
                                  }`}
                                />
                                {x.Location.replace("zzzz ", "")}
                              </b>
                              Count :{" "}
                              {
                                Pallets.filter(
                                  (y) =>
                                    y?.Location === x?.Location && y?.Items > 0
                                )?.length
                              }
                            </b>
                          </div>
                        </div>
                      </>
                    )}
                    <Item
                      item={x}
                      key={i}
                      setLoading={setLoading}
                      SetTableShipment={SetTableShipment}
                      removeId={removeId}
                      CheckboxList={CheckboxList}
                      SelectIDforPallet={SelectIDforPallet}
                      setCheckboxListIds={setCheckboxListIds}
                      CheckboxListIds={CheckboxListIds}
                      CheckboxListId={
                        CheckboxListIds.length > 0 &&
                        Pallets.find((x) => x.Id === CheckboxListIds[0])?.ShipID
                      }
                      isWarningColor={x?.Location === palletNolane}
                    />
                  </>
                ))}
          </>
        ) : (
          <Nodatafound />
        )}
      </div>
    </>
  );
}

async function GetPallets(ShipmentID, setShipmentsIds) {
  return await fetch(
    `${window.$apiurl}/Pallets?shipmentId=${ShipmentID}&IsShipped=false&LocationCheck=false`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      (res[1]?.pallets ?? []).forEach((element) => {
        element.ShipID = ShipmentID;
        if (!element.Location) element.Location = palletNolane;
      });

      if (res[1]?.pallets?.length > 0) {
        var listIds = [];
        setShipmentsIds((list) => (listIds = list));
        setShipmentsIds([]);
        setTimeout(() => {
          var index = listIds.findIndex((x) => x?.Id === ShipmentID);
          if (index > -1)
            listIds[index].ConsigneeCompanyName =
              res[1]?.pallets[0]?.ConsigneeComapanyName;
          setShipmentsIds(listIds);
        }, 1);
        document.getElementById("ShipId" + ShipmentID).innerText =
          res[1]?.pallets[0]?.ConsigneeComapanyName;
      }

      return res[1]?.pallets ?? [];
    })
    .catch((error) => {
      console.error(error);
    });
}

function Nodatafound() {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 3000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              No Pallets found
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
function htmlP(list, text) {
  return (
    <>
      {text}
      {list.map((x) => (
        <p type="button" className={`btn btn-danger px-1 py-0 mt-3 ml-1 mr-1`}>
          {x}
        </p>
      ))}
    </>
  );
}

async function PostPallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/addpallettoshipment`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ShipmentId: ShipmentID,
      PalletId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}
