import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import Board from "react-trello";
import { Helmet } from "react-helmet";
import DefaultComponents from "./Active Shipment Drag Component/components";
import "./Style.css";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function UpdateStatus() {
  const [StatusShipments, setStatusShipments] = useState({ lanes: [] });
  const [Customers, setCustomers] = useState([]);
  const [modes, setModes] = useState([]);
  const [services, setServices] = useState([]);
  const [show, setshow] = useState(false);
  const [ShipmentId, setShipmentId] = useState();
  const [StatusId, setStatusId] = useState();
  const [CustomerId, setCustomerId] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [modeId, setModeId] = useState(null);
  const [modeIdValueLBl, setModeValueLabel] = useState({
    value: "",
    label: "Select",
  });
  useEffect(() => {
    fetchCustomersAsync();
  }, []);
  useEffect(() => {
    if (CustomerId !== null && CustomerId !== undefined) {
      if (serviceId !== null && serviceId !== undefined) {
        if (modeId !== null && modeId !== undefined) {
          fetchUsersAsync(CustomerId, serviceId, modeId);
        } else {
          setStatusShipments({ lanes: [] });
        }
      } else {
        setStatusShipments({ lanes: [] });
      }
    } else {
      setStatusShipments({ lanes: [] });
    }
  }, [CustomerId, serviceId, modeId]);
  const fetchUsersAsync = async (customerId, serviceId, modeId) => {
    try {
      return await fetch(
        window.$apiurl +
          `/transportation/shipments/getbystatus?customerId=${customerId}&serviceId=${serviceId}&modeId=${modeId}`,
        {
          method: "get",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          const statusCode = response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          var result = res[1];
          var b = [];
          result.StatusShipments.forEach((element) => {
            var json_data = JSON.stringify(element.Shipments).replace(
              /Id/g,
              "id"
            );
            b.push({
              id: element.Id,
              title: element.Name,
              cards: JSON.parse(json_data),
            });
          });
          setStatusShipments({ lanes: b });
          $(".smooth-dnd-draggable-wrapper").css("pointer-events", "none");
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (e) {}
  };

  const fetchCustomersAsync = async () => {
    try {
      const resultRm = await axios.get("api/ReceiptsManagementNewVersion/GetAllApi");
      console.log(resultRm.data);
      setServices(resultRm.data.Services);
      setCustomers(resultRm.data.Customers);
    } catch (e) {}
  };

  const SaveDone = () => {
    if (CustomerId) fetchUsersAsync(CustomerId);
  };

  return (
    <>
      <Helmet>
        <title>eMPower | Active Shipment</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Card border="secondary">
        <Card.Header>
          <div className="form-group row">
            <div className="col-lg-4 text-black font-weight-bold">
              <label>Customer</label>
              <i
                class="fa fa-star-of-life text-danger icon-l ml-2"
                style={{ fontSize: "8px", position: "absolute" }}
              ></i>
              <CreatableSelect
                isClearable
                onChange={(e) => {
                  if (e?.value === null) setStatusShipments({ lanes: [] });
                  setCustomerId(e?.value);
                }}
                options={Customers.map((x) => {
                  return { value: x?.Id, label: x?.Name };
                })}
                name="CustomerId"
                id="CustomerId"
                className="w-100 mr-2"
              />
              {Customers.length === 0 && <LinearProgress />}
            </div>
            <div className="col-lg-4 text-black font-weight-bold">
              <label>Service</label>
              <i
                class="fa fa-star-of-life text-danger icon-l ml-2"
                style={{ fontSize: "8px", position: "absolute" }}
              ></i>
              <CreatableSelect
                isClearable
                onChange={(newValue) => {
                  setServiceId(newValue?.value);
                  if (newValue !== null) {
                    var id = newValue?.value;
                    setModeValueLabel({
                      value: "",
                      label: "Select",
                    });
                    const modes = services.filter((word) =>
                      String(word.Id).includes(String(id))
                    );
                    if (modes.length === 1) {
                      setModes(modes[0]?.Modes);
                    }
                  }
                }}
                options={services.map((x) => {
                  return { value: x?.Id, label: x?.Name };
                })}
                name="ServiceId"
                id="ServiceId"
                className="w-100 mr-2"
              />
              {services.length === 0 && <LinearProgress />}
            </div>
            <div className="col-lg-4 text-black font-weight-bold">
              <label>Mode</label>
              <i
                class="fa fa-star-of-life text-danger icon-l ml-2"
                style={{ fontSize: "8px", position: "absolute" }}
              ></i>
              <CreatableSelect
                isClearable
                value={modeIdValueLBl}
                onChange={(newValue) => {
                  if (newValue?.value !== null) {
                    setModeId(newValue?.value);
                    setModeValueLabel(newValue);
                  } else {
                    setshow(true);
                  }
                }}
                options={modes.map((x) => {
                  return { value: x?.Id, label: x?.Name };
                })}
                name="ServiceId"
                id="ServiceId"
                className="w-100 mr-2"
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="w-100 m-auto px-1 active-over">
          <Board components={DefaultComponents} data={StatusShipments} />
          <div></div>
        </Card.Body>
      </Card>
      <div className="lg-white"></div>
    </>
  );
}
