/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
export function RevenueAccount(props) {


  useEffect(() => {
    const element = document.getElementById("revenue_account_chart");

    if (!!element === false) {
      return;
    }
    const options = getChartOption(props?.data, props?.NameSelected);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [props.loadingMetrics]);

  function fetchFilterItemChar(Name) {
    try {
      let initFilterEdit = { ...props?.initFilter };
      props.setNameSelected(Name);
      switch (Name) {
        case "Gross Revenue": {
          initFilterEdit.type = "revenue";
          props.getApi(initFilterEdit);
          break;
        }
        case "Gross Margin": {
          initFilterEdit.type = "marginamount";
          props.getApi(initFilterEdit);
          break;
        }
        case "Margin": {
          initFilterEdit.type = "marginpercentage";
          props.getApi(initFilterEdit);
          break;
        }
        case "Volume": {
          initFilterEdit.type = "volume";
          props.getApi(initFilterEdit);
          break;
        }
        case "FSC": {
          initFilterEdit.type = "fsc";
          props.getApi(initFilterEdit);
          break;
        }
        case "Accessorial Charges": {
          initFilterEdit.type = "AssessorialCharges";
          props.getApi(initFilterEdit);
          break;
        }
        default: {
          return "";
        }
      }
      props.setInitFilter(initFilterEdit);
    } catch (err) {
      console.log("err", err);
    }
  }
  return (
    <div className={`card card-custom ${props?.className}`} id="cardMetrics">
      <div className="card-header border-0 mb-10">
        <h3 className="card-title font-weight-bolder text-dark">
          Metrics: {props?.NameSelected}
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-xl-2 col-lg-4 col-md-4">
            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-primary mr-2"></span>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "Gross Revenue" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("Gross Revenue");
                  }}
                >
                  Gross Revenue
                </a>
              </div>
            </div>

            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-success mr-2"></span>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "Gross Margin" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("Gross Margin");
                  }}
                >
                  Gross Margin
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-warning mr-2"></span>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "Margin" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("Margin");
                    //setFirst(_first);
                  }}
                >
                  Margin %
                </a>
              </div>
            </div>

            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-info mr-2"></span>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "Volume" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("Volume");
                  }}
                >
                  Volume
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-danger mr-2"></span>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "FSC" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("FSC");
                  }}
                >
                  FSC
                </a>
              </div>
            </div>
            <div className="d-flex align-items-center mb-7">
              <span class="label label-xl label-dot label-dark mr-2"></span>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href={() => false}
                  className={`${
                    props?.NameSelected === "Accessorial Charges" ? "activeFilter" : ""
                  } text-dark text-hover-primary mb-1 font-size-lg`}
                  onClick={() => {
                    fetchFilterItemChar("Accessorial Charges");
                  }}
                >
                  Accessorial Charges
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-lg-8 col-md-8">
            {props.loadingMetrics ? (
              <h4 className="text-center">Loading...</h4>
            ) : (
              <div
                id="revenue_account_chart"
                className="card-rounded-bottom"
                style={{ height: "100px" }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(list, _NameSelected) {
  var data = [
    {
      name: "Gross Revenue",
      data: list?.Data?.map((a) => a.Revenue),
      color: "#187DE4",
    },
    {
      name: "Gross Margin",
      data: list?.Data?.map((a) => a.MarginPercentage),
      color: "#1BC5BD",
    },
    {
      name: "Margin",
      data: list?.Data?.map((a) => a.Margin),
      color: "#FFA800",
    },
    {
      name: "Volume",
      data: list?.Data?.map((a) => a.Volume),
      color: "#8950FC",
    },
    {
      name: "FSC",
      data: list?.Data?.map((a) => a.Fsc),
      color: "#F64E60",
    },
    {
      name: "Accessorial Charges",
      data: list?.Data?.map((a) => a.AssessorialCharges),
      color: "#7e8299",
    },
  ];
  const options = {
    series: data.filter((a) => a.name === _NameSelected) || [],
    chart: {
      type: "area",
      stacked: false,
      height: 350,
    },
    colors: data.filter((a) => a.color === _NameSelected) || [],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      categories: list?.Data?.map((s) => {
        return `${s?.Month}-${s?.Year}`;
      }),
      title: {
        text: "MM-YYYY",
      },
    },
    yaxis: {
      labels: {
        formatter: function(val) {
          return val?.toFixed(2);
        },
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: false,
      y: {
        formatter: function(val) {
          return val?.toFixed(2);
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
  };
  return options;
}
