// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";




var isexecut= false;
export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditCustomerDialog, openDeleteCustomerDialog,openDetailsCustomerDialog }
) {
  if(window.$GlobalSearchCustomerid!==null && window.$GlobalSearchCustomerid!==undefined && !isexecut){
    isexecut=true;
    setTimeout(() => {
      openEditCustomerDialog(window.$GlobalSearchCustomerid);
    }, 1000);  
  }


  return (
    <div>
      <OverlayTrigger
      overlay={<Tooltip id="products-edit-tooltip"> Customer Activities</Tooltip>}
    >
        <a
        className="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"        
        onClick={() => openDetailsCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-warning">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
          />
        </span>
      </a>
      </OverlayTrigger>
      <> </>
      <OverlayTrigger
      overlay={<Tooltip id="products-edit-tooltip"> Edit Customer</Tooltip>}
    >
        <a
        className="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
        
        onClick={() => openEditCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>   
      </OverlayTrigger>
      <> </>
      <OverlayTrigger
      overlay={<Tooltip id="products-delete-tooltip"> Delete Customer</Tooltip>}
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm ml-2"
        onClick={() => openDeleteCustomerDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a>
      </OverlayTrigger>
        
    </div>
      
     
     
    
  );
}
