import customerTableMock from "./LulusProcessTableMock";
import MockUtils from "./mock.utils";

export default function mockLulusProcess(mock) {
  mock.onPost("api/lulusProcess").reply(({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Order="Elise",
      Po="Lotus",
      Sku="2004",
      Description="116879",
      Size= `Black`,
      Color="Red",
      location="18347",
      Recv="09/30/2017",
      Proc="09/30/2017",
      Pcs="0",
      Adj="3",
      O="1",
      S="3",
      D="3",
      Qty="3",
      Crtns="3",

      NUMBER ="94040",
      VENDOR ="OLIA CLOTHING INC",
      STATUS ="PENDING RECEIPT",
      DELIVERYNOTBEFORE ="4/12/2021 12:00:00 AM",
      DELIVERYNOTAFTER ="4/16/2021 12:00:00 AM",
      DELIVERYASSIGNED ="",
      EXTENSION1 ="",
      EXTENSION2 ="",
      
    } = customer;

    const id = generateUserId();
    const newCustomer = {
      id,
      Order,
      Po,
      Sku,
      Description,
      Size,
      Color,
      location,
      Recv,
      Proc,
      Pcs,
      Adj,
      O,
      S,
      D,
      Qty,
      Crtns,

      NUMBER,
      VENDOR,
      STATUS,
      DELIVERYNOTBEFORE,
      DELIVERYNOTAFTER,
      DELIVERYASSIGNED,
      EXTENSION1,
      EXTENSION2,
    };
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/lulusProcess/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/lulusProcess/deleteCustomers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = customerTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/lulusProcess/updateStatusForCustomers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/lulusProcess\/\d+/).reply(config => {
    const id = config.url.match(/api\/lulusProcess\/(\d+)/)[1];
    const customer = customerTableMock.find(el => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/lulusProcess\/\d+/).reply(config => {
    const id = config.url.match(/api\/lulusProcess\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const index = customerTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/lulusProcess\/\d+/).reply(config => {
    const id = config.url.match(/api\/lulusProcess\/(\d+)/)[1];
    const index = customerTableMock.findIndex(el => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
