import React, { useMemo, useState } from "react";
import { useRemarksUIContext } from "./RemarksUIContext";
import $ from "jquery";
import { Modal, Button } from "react-bootstrap";
import { isEqual } from "lodash";
import { Formik } from "formik";
import { Link } from "react-router-dom";
const prepareFilter = (queryParams, values) => {
  const { ReLoadTableCustomTrRm, search } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.ReLoadTableCustomTrRm = ReLoadTableCustomTrRm;
  filter.search = search;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function RemarksFilter({ id }) {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const [ModelShowingConfirmation, setModelShowingConfirmation] = useState(
    false
  );
  const remarksUIProps = useMemo(() => {
    return {
      setQueryParams: remarksUIContext.setQueryParams,
      openNewRemarkDialog: remarksUIContext.openNewRemarkDialog,
      queryParams: remarksUIContext.queryParams,
    };
  }, [remarksUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(remarksUIProps.queryParams, values);
    if (!isEqual(newQueryParams, remarksUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      remarksUIProps.setQueryParams(newQueryParams);
    }
  };
  function checkIsIds(value1, value2) {
    if (
      value1 !== undefined &&
      value1 !== null &&
      value2 !== undefined && value2 !== null
    ) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          ReLoadTableCustomTrRm: 0,
        }}
        onSubmit={(values) => {
          if (values.ReLoadTableCustomTrRm === "0") {
            values.ReLoadTableCustomTrRm = 1;
          } else {
            values.ReLoadTableCustomTrRm = 0;
          }
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <input
              type="button"
              className="d-none"
              id="ReLoadTableCustomTrRmId"
              value={values.ReLoadTableCustomTrRm}
              onClick={(e) => {
                setFieldValue("ReLoadTableCustomTrRm", e.target.value);
                handleSubmit();
              }}
            />
            <div className="form-filtration">
              <div className="row align-items-center mb-5">
                <div className="col-md-3 margin-bottom-10-mobile">
                  <label>Search</label>
                  <input
                    className="form-control"
                    value={values?.search}
                    placeholder="search by all fields"
                    onChange={(e) => {
                      try {
                        setFieldValue("search", e.target.value);
                        handleSubmit();
                      } catch (err) {}
                    }}
                  />
                </div>
                <div className="col-md-3 text-right mt-md-8 margin-bottom-10-mobile">
                  <Link
                    target="_blank"
                    className="btn btn-primary w-100"
                    to={
                      checkIsIds(window.$ItemsRmId, window.$ItemsCusId)
                        ? `/ReceiptsManagement/ImportItems/${window.$ItemsRmId}/${window.$ItemsCusId}`
                        : `/ReceiptsManagement/ImportItems`
                    }
                  >
                    Import Items
                  </Link>
                </div>
                <div className="col-md-3 text-right mt-md-8 margin-bottom-10-mobile">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={() => {
                      document
                        .getElementById("ReLoadTableCustomTrRmId")
                        .click();
                    }}
                  >
                    Refresh
                  </button>
                </div>

                <div className="col-md-3 text-right mt-md-8 margin-bottom-10-mobile">
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={() => {
                      if (id) remarksUIProps.openNewRemarkDialog();
                      if (!id) setModelShowingConfirmation(true);
                    }}
                  >
                    New
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Modal
        size="lg"
        show={ModelShowingConfirmation}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Save Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Before creating a new line item, you must save the order first <br />
          Click "YES" to save order
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModelShowingConfirmation(false);
            }}
          >
            No
          </Button>

          <Button
            variant="secondary"
            className="btn btn-light btn-elevate ml-5"
            onClick={(e) => {
              $("#AddContainerID").click();
              setModelShowingConfirmation(false);
              setTimeout(() => {
                remarksUIProps.openNewRemarkDialog();
              }, 2000);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
