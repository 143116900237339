/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../layout";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export function OveralTransaction({ className }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Overall Transaction Statu
          </span>
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="col-lg-12">
          <div className="row mt-10">
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                All
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-dark-50 font-weight-bolder font-size-h4 mt-6">
                55
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Failled
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-danger font-size-h4 mt-6">
                17
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Success
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-success font-size-h4 mt-6">
               33
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                In progress
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-primary font-size-h4 mt-6">
                0
              </p>
              {/*end::Desc*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
