import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { CustomerDialogForm } from "./customer-notify-dialog/CustomerDialogForm";
import { useParams } from "react-router-dom";
import {
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Row,
} from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { useSubheader } from "../../../../../_metronic/layout";
export function CustomersCard() {
  const { id } = useParams("id");
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const suhbeader = useSubheader();
  const [title, setTitle] = useState("");
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
    }),
    shallowEqual
  );
  useEffect(() => {
    let _title = id ? "" : "Service Manage Carriers";
    if (productForEdit && id) {
      _title = `'${productForEdit.Sku}'`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
  }, [productForEdit, id]);
  return (
    <Card>
      <CardHeader title="Service Manage Carriers">
        <CardHeaderToolbar>
          <Row>
            <button
              type="button"
              className="btn btn-success btn-shadow-hover mr-2"
              onClick={customersUIProps.newCustomerButtonClick}
            >
              New Service Carrier
            </button>
          </Row>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
      </CardBody>
      <CardFooter></CardFooter>
    </Card>
  );
}
