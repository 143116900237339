/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import low from "lowdb";
import { ProgressBar } from "react-bootstrap";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

var color = [
  "symbol-light-primary",
  "symbol-light-success",
  "symbol-light-warning",
  "symbol-light-info",
  "symbol-light-Receiv",
  "symbol-light-gray-600",
  "symbol-light-Enroute",
  "symbol-light-danger",
];
var colorIcon = [
  "svg-icon-primary",
  "svg-icon-success",
  "svg-icon-warning",
  "svg-icon-info",
  "svg-icon-Receiv",
  "svg-icon-light-gray-600",
  "svg-icon-Enroute",
  "svg-icon-danger",
];

export function DonutDomestics({
  globalShart,
  globalShartCount,
  className,
  ChartLoadingSpinner,
}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_transportation_widget_7_chart");

    if (!element) {
      return;
    }
    const options = getChartOption(layoutProps, globalShartCount);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, globalShartCount]);

  return (
    <div className={`card card-custom ${className}`}>
      {ChartLoadingSpinner && (
        <ProgressBar
          variant="success"
          animated
          now={100}
          style={{ height: "3px", width: "100%" }}
        />
      )}

      <div className="card-header border-0 mb-0 global">
        <h3 className="card-title font-weight-bolder text-dark">Domestic</h3>
      </div>
      <div className="card-body d-flex flex-column pt-0">
        <div className="row">
          <div className="col-xl-7 col-lg-8 col-md-8 align-self-center">
            <div
              id="kt_transportation_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "100px" }}
            ></div>
          </div>
          <div className="col-xl-5 col-lg-4 col-md-4 mt-0">
            {globalShart?.map((item, index) => (
              <div className="d-flex align-items-center mb-10">
                <div className={`symbol symbol-40 ${color[index]} mr-5`}>
                  <span className="symbol-label">
                    <span
                      className={`svg-icon svg-icon-lg ${colorIcon[index]}`}
                    >
                      {item.Count}
                    </span>
                  </span>
                </div>

                <div className="d-flex flex-column font-weight-bold">
                  <a
                    href="#"
                    className="text-dark text-hover-primary mb-1 font-size-lg"
                    onClick={() => {
                      window.GlobalTable = item.Status;
                      setTimeout(() => {
                        var el = document.getElementById(item.Status);
                        window.scroll({
                          top: el?.offsetTop - 120,
                          behavior: "smooth",
                        });
                      }, 50);
                    }}
                  >
                    {item.Status}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, _globalShartCount) {
  const options = {
    series: _globalShartCount,
    colors: [
      "#187DE4",
      "#1BC5BD",
      "#FFA800",
      "#8950FC",
      "#4CA1AF",
      "#7e8299",
      "#EECDA3",
      "#F64E60",
    ],
    chart: {
      type: "donut",
    },
    dataLabels: {
      formatter: function(val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    labels: ["Open", "Appointed", "En Route", "Delivered"],
  };
  return options;
}
