// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";

// Validation schema
const RemarkEditSchema = Yup.object().shape({
});

export function RemarkEditForm({ saveRemark, remark, actionsLoading, onHide }) {
  const [viewModelUnits, setUnitsData] = useState([]);
  useEffect(() => {
    fetchOrderAsync();
}, []);

const fetchOrderAsync = async () => {
    try {
        const result = await axios.get("api/OrderReceiver/GetAllApi");
        setUnitsData(result.data["Units"].Units);
    } catch (e) { }
};
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={remark}
        validationSchema={RemarkEditSchema}
        onSubmit={(values) => saveRemark(values)}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                   <Select name="UnitId" label="UNIT TYPE">
                      <option value="">Select</option>
                      {viewModelUnits.map((item, index) => (
                        <option key={index} value={item.Id}>{item.Name}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-md-6 pt-5 align-self-center">
                    <small>select a unit for which you charge storage and/or handling</small>
                  </div>
                </div>
                <h4 className="card-title font-weight-bolder ">Storage (at receive time)</h4>
                <div className="border p-5 mb-5">
                <div className="form-group row ">
                  <div className="col-lg-6">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="Split period full rate"
                      component={Input}
                      placeholder="Split period full rate"
                      label="Split period full rate"
                    />
                  </div>
                  <div className="col-lg-6 pt-3 align-self-center">
                    <small>leave zero or empty if you do not charge upon
                    receipt; otherwise, the default amount you charge for storage if you receive
                    during the first half of the period
								</small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="Split period Half rate"
                      component={Input}
                      placeholder="Split period Half rate"
                      label="Split period Half rate"
                    />
                  </div>
                  <div className="col-lg-6 pt-3 align-self-center">
                    <small>leave zero or empty if you do not charge upon 
									receipt; otherwise, the default amount you charge for storage if you receive 
									during the second half of the period
								</small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="FREE DAYS"
                      component={Input}
                      placeholder="FREE DAYS"
                      label="FREE DAYS"
                    />
                  </div>
                  <div className="col-lg-6 pt-3 align-self-center">
                    <small>the number of free storage days: for <em>split 
										periods</em> this is how many days to wait before determining inventory; for <em>
										recurring storage</em>, this is how many days to add to the initial receipt 
									date when calculating the charge, which may result in an entire month free
								</small>
                  </div>
                </div>
                </div>
                <h4 className="card-title font-weight-bolder ">Handling (at confirm time)</h4>
                <div className="border p-5">
                <div className="form-group row">
                  <div className="col-lg-6">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="Receiver handling rate"
                      component={Input}
                      placeholder="Receiver handling rate"
                      label="Receiver handling rate"
                    />
                  </div>
                  <div className="col-lg-6 pt-3 align-self-center">
                    <small>the default handling charge for each unit per receiver
								    </small>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="Shipping handling rate"
                      component={Input}
                      placeholder="Shipping handling rate"
                      label="Shipping handling rate"
                    />
                  </div>
                  <div className="col-lg-6 pt-3 align-self-center">
                    <small>the default handling charge for each unit per order
								    </small>
                  </div>
                </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
