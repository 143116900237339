import InsuranceInformationTableMock from "./InsuranceInformationTableMock";
import MockUtils from "./mock.utils";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

async function GetCarrier(id) {
  return await fetch(window.$apiurl + "/carriers/transport/" + id, {
    method: "get",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data.Insurances]);
    })
    .then((res) => {
      return ChangeId(res[1]);
    })
    .catch((error) => {
      console.error(error);
    });
}

async function PostInsuranceCarrier(newRemark) {
  var requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newRemark),
    redirect: 'follow'
  };
  let response = await fetch(window.$apiurl + "/carriers/transport/insurances", requestOptions);
  let data = await response.json()
  return data;
}
async function GetInsur(id) {
  return await fetch(window.$apiurl + "/carriers/transport/insurances/" + id, {
    method: "get",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {

      return res;
    })
    .catch((err) => {
      Notification("error", "err");
      return false;
    });
}
async function DeleteInsurInfo(id) {
  return await fetch(window.$apiurl + "/carriers/transport/insurances/" + id, {
    method: "delete",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      return response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
async function PutContactCarrier(someData, id) {
  const CarrierId = window.location.pathname.split('/')[2];

  someData.CarrierId = CarrierId;
  return fetch(window.$apiurl + "/carriers/transport/insurances/" + id, {
    method: "put",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      return await response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
//--------------------------------------
export default function mockInsuranceInformation(mock) {
  mock.onPost("api/InsuranceInformation").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const urls = remark.carId;
    const {
      CompanyName = "",
      PolicyNumber = "",
      Phone = "",
      InsuranceTypeId = 0,
      Notes = "",
      EffectiveDate = "",
      ExpirationDate = "",
      InsuredAmount = 0,
      InsuredAmountPerPound = 0,
      City = "",
      State = "",
      ZipCode = "",
      Country = "",
      Street1 = "",
      Street2 = "",
      CarrierId = urls,
    } = remark;
    const newRemark = {
      CompanyName,
      PolicyNumber,
      Phone,
      InsuranceTypeId,
      Notes,
      EffectiveDate,
      ExpirationDate,
      InsuredAmount,
      InsuredAmountPerPound,
      City,
      State,
      ZipCode,
      Country,
      Street1,
      Street2,
      CarrierId,
    };
    await PostInsuranceCarrier(newRemark);
    InsuranceInformationTableMock.push(newRemark);
    return [200, { remark: newRemark }];
  });

  mock.onPost(/api\/InsuranceInformationfind\/\d+/).reply(async config => {
    const urls = config.url.split("/");
    const id = urls[2];
    const product = await GetCarrier(id);
    carTableMock = product;
    window.AttachmentCarrierInsurance =product.filter(
      (word) =>
        // String(word.InsuranceType).includes(String("General Liability")) ||
        String(word.InsuranceType).includes(String("Auto Liability Insurance")) ||
        String(word.InsuranceType).includes(String("Cargo Insurance")) 
    ).length;
    // const isGeneralLiability = product.filter(
    //   (word) =>
    //     String(word.InsuranceType).includes(String("General Liability")) &&
    //     word.InsuredAmount >= 1000000
    // ).length;
    const isCargoInsurance = product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("Cargo Insurance")) &&
        word.InsuredAmount >= 100000
    ).length;

    const isAutoLiabilityInsurance = product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("Auto Liability Insurance")) &&
        word.InsuredAmount >= 1000000
    ).length;
    const oneDay = 24 * 60 * 60 * 1000;
    const isExpirationDate = product.filter(
      (word) => {
        return (Math.round(Math.abs((new Date(word.ExpirationDate) - new Date()) / oneDay))<=30) ;
      }
    ).length;

    window.carrierInsuranceExpirationDate = isExpirationDate;
    window.carrierInsuranceTypeAmount = (isCargoInsurance > 0 && isAutoLiabilityInsurance > 0) ? true : false;
  
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdRemarks = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/InsuranceInformation/deleteRemarks").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = InsuranceInformationTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        InsuranceInformationTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/InsuranceInformation\/\d+/).reply(async config => {
    const id = config.url.match(/api\/InsuranceInformation\/(\d+)/)[1];
    const remark = await GetInsur(id);
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/InsuranceInformation\/\d+/).reply(async config => {
    const id = config.url.match(/api\/InsuranceInformation\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    await PutContactCarrier(remark, id);
    const index = InsuranceInformationTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }
    const product = await GetCarrier(id);
    carTableMock = product;
    window.AttachmentCarrierInsurance =product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("General Liability")) ||
        String(word.InsuranceType).includes(String("Auto Liability Insurance")) ||
        String(word.InsuranceType).includes(String("Cargo Insurance")) 
    ).length;
    const isGeneralLiability = product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("General Liability")) &&
        word.InsuredAmount >= 1000000
    ).length;
    const isCargoInsurance = product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("Cargo Insurance")) &&
        word.InsuredAmount >= 100000
    ).length;

    const isAutoLiabilityInsurance = product.filter(
      (word) =>
        String(word.InsuranceType).includes(String("Auto Liability Insurance")) &&
        word.InsuredAmount >= 1000000
    ).length;
    const oneDay = 24 * 60 * 60 * 1000;
    const isExpirationDate = product.filter(
      (word) => {
        return (Math.round(Math.abs((new Date(word.ExpirationDate) - new Date()) / oneDay))<=30) ;
      }
    ).length;

    window.carrierInsuranceExpirationDate = isExpirationDate;
    window.carrierInsuranceTypeAmount = isGeneralLiability > 0 && isCargoInsurance > 0 && isAutoLiabilityInsurance > 0 ? true : false;
    
    InsuranceInformationTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/InsuranceInformation\/\d+/).reply(async config => {
    const id = config.url.match(/api\/InsuranceInformation\/(\d+)/)[1];
    await DeleteInsurInfo(id);
    const index = InsuranceInformationTableMock.findIndex(el => el.id === +id);
    InsuranceInformationTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateRemarkId() {
  const ids = InsuranceInformationTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}