import React, { Component } from "react";
import PropTypes from "prop-types";
import DateColumnFormatter from "./Columns/DateColumnFormatter";
import DateColumnFormatter2 from "./Columns/DateColumnFormatter2";

import {
  MovableCardWrapper,
  CardHeader,
  CardRightContent,
  CardTitle,
  Detail,
  Footer,
} from "../styles/Base";
import Tag from "./Card/Tag";
import DeleteButton from "../widgets/DeleteButton";

class Card extends Component {
  onDelete = (e) => {
    this.props.onDelete();
    e.stopPropagation();
  };

  render() {
    const {
      showDeleteButton,
      style,
      tagStyle,
      onClick,
      onDelete,
      className,
      id,
      title,
      label,
      description,
      tags,
      cardDraggable,
      ShipDate,
      DeliveryDate,
      FromCity,
      ToCity,
      CarrierName,
      Updated,
    } = this.props;

    return (
      <MovableCardWrapper
        data-id={id}
        onClick={onClick}
        style={style}
        className={className}
      >
        <CardHeader>
          <CardTitle draggable={cardDraggable}>{`Shipment ${id}`}</CardTitle>
          <CardRightContent>{label}</CardRightContent>
          {showDeleteButton && <DeleteButton onClick={this.onDelete} />}
        </CardHeader>
        <Detail>
          <span className="d-block">
            <b>Ship Date</b> : {ShipDate && <DateColumnFormatter2 ShipDate={ShipDate} />}
          </span>
          <span className="d-block">
            <b>Delivery Date</b> : {ShipDate && <DateColumnFormatter2 ShipDate={DeliveryDate} />}
          </span>
          <span className="d-block">
            <b>From City</b> :{" " + (ToCity || "")}
          </span>
          <span className="d-block">
            <b>To City</b> :{" " + (FromCity || "")}
          </span>
          <span className="d-block">
            <b>Carrier Name</b> :{" " + (CarrierName || "")}
          </span>
          <span className="d-block">
            <b>Updated</b> : <DateColumnFormatter dateTime={Updated} />
          </span>
        </Detail>
        {tags && tags.length > 0 && (
          <Footer>
            {tags.map((tag) => (
              <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
            ))}
          </Footer>
        )}
      </MovableCardWrapper>
    );
  }
}

Card.propTypes = {
  showDeleteButton: PropTypes.bool,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tagStyle: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.array,
};

Card.defaultProps = {
  showDeleteButton: true,
  onDelete: () => {},
  onClick: () => {},
  style: {},
  tagStyle: {},
  title: "no title",
  description: "",
  label: "",
  tags: [],
  className: "",
};

export default Card;
