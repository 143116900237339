import React from 'react'
import { Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export function PackagingOptions() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <div class='row'>
                        <div class='col-12 mb-5'>
                            <span class="label label-lg label-light-primary  label-inline">

                                To activate this feature for USPS please contact your 3PL Central account manager. <a href="tel:1-888-375-2368">Phone: 1-888-375-2368</a>
                                .<a href="mailto:sales@3plcentral.com"> Email: sales@3plcentral.com </a>


                            </span>

                            <h3 className="card-title mt-3 font-weight-bolder">CARRIER IMPORT/EXPORT</h3>



                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Import from UPS or FedEx</Link>
                            </span>
                            <p className='mt-2'> Import a file received from UPS or FedEx.</p>

                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Change Carrier Import</Link>
                            </span>
                            <p className='mt-2'> Import a file to change the carrier.</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for UPS</Link>
                            </span>
                            <p className='mt-2'> Download a file to your browser containing package information for sending to UPS.</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for FedEx</Link>
                            </span>
                            <p className='mt-2'> Download a file to your browser containing package information for sending to FedEx.</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for Other Carriers</Link>
                            </span>
                            <p className='mt-2'>Download a generic file to your browser containing package information for sending to other carriers.</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for USPS</Link>
                            </span>
                            <p className='mt-2'>Download a file to your browser containing package information for sending to USPS.</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for All Carrierst</Link>
                            </span>
                            <p className='mt-2'> Download a generic file to your browser containing package information for all carriers.LABEL EXPORT</p>
                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Export for Labels</Link>
                            </span>
                            <p className='mt-2'> Download a file to your browser containing information for creating carton labels (such as UCC128 labels).<br></br>OTHER</p>

                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Define Packaging Materials</Link>
                            </span>
                            <p className='mt-2'> Define the different types of packaging materials used in the warehouse. This information is used during the "Pack" operation.</p>





                        </div>

                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}
