import React from 'react'
import { Button, Form, Card, Dropdown, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export function ManageStorageRatesItems() {
    return (

        <Card>
            <Card.Body>

                <Form>
                    <Form.Row>
                        <div class='col-3'>
                            <Form.Label>Customer</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div class='col-3'>
                            <Form.Label>Facility</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div class='col-3'>
                            <Form.Label className="mt-4 mb-4"></Form.Label>
                            <div className="d-flex align-items-center">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input type="checkbox" name="" onChange={() => { }} value="1" />
                                    <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                    <Link to="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Show Item Detail</Link>
                                </div>
                            </div>
                        </div>
                        <div class='col-3'>
                            <Form.Label className="mt-4 mb-4"></Form.Label>
                            <div className="d-flex align-items-center">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input type="checkbox" name="" onChange={() => { }} value="1" />
                                    <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                    <Link to="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Show Cleared Rates</Link>
                                </div>
                            </div>
                        </div>

                        <div class='col-12 mt-5'>
                            <Alert variant="info " className="w-50  mt-5">
                                <b>Rates : </b> MODIFY RATES AT ONCE BY MULTI-SELECTING ENTRIES
                            </Alert>
                        </div>


                    </Form.Row>
                </Form>

                <table class="table mt-5">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Count Mechanism</th>
                            <th scope="col">Count Unit</th>
                            <th scope="col">Rate</th>
                            <th scope="col"># Items Using Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <div class='row'>
                    <div class='col-3'>
                        <Button as="input" type="submit" value="Edit" />
                    </div>

                    <div class='col-3'>
                        <Button as="input" type="submit" value="Clear" />
                    </div>
                </div>

            </Card.Body>
        </Card>
    )
}
