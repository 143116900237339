/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo } from "react";
import { useHtmlClassService } from "../../../layout";
export function PerformanceTransaction({ className }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Transaction Visualization
        </h3>
      </div>
      <div className="card-body d-flex flex-column p-0">
        <div className="row">
          <div className="col-lg-12">
            <div
              id="kt_performance_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "150px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "Success",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "In progress",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Failled",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    colors: ["#b3e8e7", "#3699FF", "#f64e60"],
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    labels: [
      "02/01/2020",
      "03/01/2020",
      "04/01/2020",
      "05/01/2020",
      "06/01/2020",
      "07/01/2020",
      "08/01/2020",
      "09/01/2020",
      "10/01/2020",
    ],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      title: {
        text: "(%)",
      },
      forceNiceScale: false,
      max: 100,
      labels: {
        formatter: (value) => value.toFixed(0) + "%",
      },
    },
    fill: {
      opacity: 1,
    },
  };
  return options;
}
