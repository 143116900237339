import { IconButton } from "@material-ui/core";
import React, { useEffect, useState, Fragment } from "react";
import { Modal, Spinner } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import CreatableSelect from "react-select/creatable";
import {
  CheckboxLarge,
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { NotificationContainer } from "react-notifications";
import { Notification } from "../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

export const SendEmail = ({
  row,
  showModalSend,
  setshowModalSend,
  IsMultu,
  GetWarehousDataPageChanges,
  paginationOptions,
}) => {
  const [EmailAddressList, setEmailAddressList] = useState([]);
  const [ToDataDefeault, setToDataDefeault] = useState([]);
  const [includBol, setincludBol] = useState(true);
  const [ToData, setToData] = useState(null);
  const [includOutboundDispatch, setincludOutboundDispatch] = useState(true);
  const [includShipmentManifest, setincludShipmentManifest] = useState(false);
  const [AttachmentData, setAttachmentData] = useState([]);
  const [AttachementList, setAttachementList] = useState(null);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
  const [BodyData, setBodyData] = useState(
    `<p>Hello,</p><p>Attached are the BOL file for the following shipment.</p><p>Shipment# {${
      IsMultu === true ? row.join(", ") : row.Id
    }}</p><p>Thanks</p><p>Mitco team</p>`
  );
  const [SubjectData, setSubjectData] = useState(
    `Mitco Shipment ID: ${IsMultu === true ? row.join(", ") : row.Id} `
  );
  const [includCCData, setincludCCData] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/shipments/getsendemail/${
        IsMultu === true ? row[0] : row.Id
      }`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        var obj = [];
        var obj2 = [];
        setAttachementList(resultobj.Attachments);
        resultobj.Emails.map((item) => {
          obj.push({ value: item.Email, label: item.Email });
          obj2.push({ Email: item.Email, Name: item.Email });
          return null;
        });
        setToDataDefeault(obj);
        setToData(obj2);
        setEmailAddressList(obj);
      })
      .catch((error) => console.log("error", error));

    fetch(window.$apiurl + "/carriers/transport/getviewmodel", {
      method: "get",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((result) => {
        const objs = JSON.parse(result);
        setAccessLevels(objs.AccessLevels);
        setAttachmentTypes(objs.AttachmentTypes);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const SendEmailfunctions = async () => {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    data = {
      Ids: IsMultu === true ? row : [row.id],
      Emails: ToData,
      Subject: SubjectData,
      Body: BodyData,
      IncludeCc: includCCData,
      Attachments: AttachmentData,
      Bol: includBol,
      OutboundDispatch: includOutboundDispatch,
      ShipmentManifest: includShipmentManifest,
    };

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    url = "/shipmentsmanagement/sendMultiEmail";

    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      Notification(
        "success",
        `Email sent successfully for Shipment ID ${row.Id}`
      );
      if (GetWarehousDataPageChanges) {
        GetWarehousDataPageChanges(paginationOptions);
      }

      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setincludBol(false);
    } else {
      Notification("error", "invalid data");
      if (GetWarehousDataPageChanges) {
        GetWarehousDataPageChanges(paginationOptions);
      }

    }
    setisLoadingSend(false);
    setshowModalSend(false);
  };
  function NextStatus(id, statusId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentIds: [id],
      ShipmentStatus: statusId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("infoClick", result[1].Message);
            SendEmailfunctions();
          } else {
            SendEmailfunctions();
          }
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  async function SaveShipementAattachement(id) {
    if (
      document.getElementById("shipmentAttachmentList").files[0] === undefined
    ) {
      Notification("error", "Please select Attachment");
      return;
    }
    setisLoadingSubmit(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachmentList");
    const AccessLevelId = document.getElementById("shipmentAccessLevelIdList")
      .value;
    const AttachmentTypeId = document.getElementById(
      "shipmentAttachmentTypeIdList"
    ).value;
    formdata.append(
      "File",
      inputElement.files[0],
      inputElement.value.split("\\")[2]
    );
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
    };
    let response = await fetch(
      window.$apiurl +
        `/transload/shipments/addattachment?shipmentId=${id}&accessLevelId=${AccessLevelId}&attachmentTypeId=${AttachmentTypeId}`,
      requestOptions
    );
    let data = await response.json();
    var inputs = document.querySelectorAll(".ShipmentsAttachmentCheckBox");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    setisLoadingSubmit(false);
    getAttachmentData(row?.Id);
    setAttachmentData([]);
  }

  async function getAttachmentData(id) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/shipments/getsendemail/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        setAttachementList(resultobj.Attachments);
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <Modal
      size="xl"
      show={showModalSend}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">Send Email</Modal.Title>
        <IconButton
          className="MuiButtonBase-root MuiIconButton-root jss179"
          aria-label="Close"
          onClick={() => {
            setshowModalSend(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <p className="mb-3">To: </p>
              <CreatableSelect
                isMulti
                name="To"
                id="To"
                value={ToDataDefeault}
                options={EmailAddressList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  var obj = [];
                  var objDefeault = [];
                  if (e) {
                    e.map((item) => {
                      obj.push({
                        Email: item.value,
                        Name: item.value,
                      });
                      objDefeault.push({
                        value: item.value,
                        label: item.value,
                      });
                      return null;
                    });
                  }
                  setToDataDefeault(objDefeault);
                  setToData(obj);
                }}
              />

              <CheckboxLarge
                Size="12px"
                name="includCC"
                text="Include me on CC"
                onChange={(event) => {
                  setincludCCData(event.target.checked);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <p className="mb-3">Subject: </p>
              <input
                name="Subject"
                className="form-control"
                placeholder="Subject"
                value={SubjectData}
                onChange={(event) => {
                  setSubjectData(event.target.value);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <p className="mb-3">Body: </p>

              <CKEditor
                name="Body"
                Id="Body"
                label="Body"
                editor={ClassicEditor}
                config={{
                  removePlugins: [
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                  ],
                }}
                data={BodyData}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  setBodyData(data);
                }}
              />
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12 mt-5">
                  <h4>Attachments</h4>
                </div>
                <div className="col-lg-12 row mt-5">
                  <div className="col-lg-3">
                    <CheckboxLarge
                      Size="12px"
                      name="includBol"
                      text="Include BOL"
                      onChange={(event) => {
                        setincludBol(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <CheckboxLarge
                      Size="12px"
                      name="OutboundDispatch"
                      checked={includOutboundDispatch}
                      text="Include Rate Confirmation"
                      onChange={(event) => {
                        setincludOutboundDispatch(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <CheckboxLarge
                      Size="12px"
                      name="ShipmentManifest"
                      text="Include Shipment Manifest"
                      checked={includShipmentManifest}
                      onChange={(event) => {
                        setincludShipmentManifest(event.target.checked);
                      }}
                    />
                  </div>
                </div>
                {AttachementList?.map((item, index) => {
                  return (
                    <Fragment>
                      <div className="col-lg-12 row mt-5">
                        <div className="col-lg-12 row ml-0">
                          <CheckboxLarge
                            Size="12px"
                            name={`xxxxx${index}`}
                            value={item.Id}
                            className="ShipmentsAttachmentCheckBox"
                            onChange={(event) => {
                              var obj = AttachmentData;
                              obj.push(event.target.value);
                              setAttachmentData(obj);
                            }}
                          />
                          <a
                            className="mt-3"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${item.Path}`}
                          >
                            {item.Name}
                          </a>
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
                <div className="col-lg-12">
                  <div className="row mt-10">
                    <div className="col-lg-4">
                      <p>Add Attachment</p>
                      <input
                        id="shipmentAttachmentList"
                        type="file"
                        name="shipmentAttachmentList"
                        component={Input}
                        placeholder="Add Attachment"
                        label="Add Attachment"
                      />
                    </div>
                    <div className="col-lg-3">
                      <p>Attachment Type</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAttachmentTypeIdList"
                        id="shipmentAttachmentTypeIdList"
                        label="Attachment Type"
                      >
                        {AttachmentTypes.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <p>Access Level</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAccessLevelIdList"
                        id="shipmentAccessLevelIdList"
                        label="Access Level"
                      >
                        {AccessLevels.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-2 mt-8">
                      <button
                        type="button"
                        onClick={async () =>
                          await SaveShipementAattachement(row?.Id)
                        }
                        disabled={isLoadingSubmit}
                        className="btn btn-primary btn-elevate"
                      >
                        Save Attachment
                        {isLoadingSubmit && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => {
              setshowModalSend(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={() => {
              NextStatus(IsMultu === true ? row : row.Id, 102);
            }}
            disabled={isLoadingSend}
            className="btn btn-delete btn-primary"
          >
            Send Email
            {isLoadingSend && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
