import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/ManageWarehouse/customersActions";
import { CustomerEditForm } from "./CustomerEditForm";
import { useCustomersUIContext } from "../CustomersUIContext";
import {CustomerEditDialogHeader} from "./CustomerEditDialogHeader"
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";

const adapter = new LocalStorage("db");
const db = low(adapter);

export function CustomerEditDialog({ id, show, onHide }) {
  // Customers UI Context
  const [Contacts, setContacts] = useState([]);
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Customer by id
    dispatch(actions.fetchCustomer(id));
  }, [id, dispatch]);

  // server request for saving customer
  const saveCustomer = (customer) => {
    if (!id) {
      // server request for creating customer
      dispatch(actions.createCustomer(customer)).then(() => {
        onHide();
        dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      });
    } else {
      // server request for updating customer
      dispatch(actions.updateCustomer(customer)).then(() => {
        onHide();
        dispatch(actions.fetchCustomers(customersUIProps.queryParams));
      });
    }
  };

  async function GetWarehouse(id) {
    return await fetch(`${window.$apiurl}/Warehouses/byId/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", 
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setContacts(res[1]?.Contacts);
      })
      .catch((error) => {
        console.error(error);
      });
  }

   useEffect(() => {
    if(id){
      GetWarehouse(id);
    }
  }, [id]);

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <CustomerEditDialogHeader id={id} onHide={onHide} />
      <CustomerEditForm
        saveCustomer={saveCustomer}
        actionsLoading={actionsLoading}
        customer={customerForEdit || customersUIProps.initCustomer}
        onHide={onHide}
        Contacts={Contacts}
        show={show}
      />
    </Modal>
  );
}
