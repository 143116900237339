import { PopoverContainer, PopoverContent } from "react-popopo";
import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  .comPlainTextContentEditable {
    -webkit-user-modify: read-write-plaintext-only;
    cursor: text;
  }

  .comPlainTextContentEditable--has-placeholder::before {
    content: attr(placeholder);
    opacity: 0.5;
    color: inherit;
    cursor: text;
  }

  .react_trello_dragClass {
    transform: rotate(3deg);
  }

  .react_trello_dragLaneClass {
    transform: rotate(3deg);
  }

  .icon-overflow-menu-horizontal:before {
    content: "\\E91F";
  }
  .icon-lg, .icon-sm {
    color: #798d99;
  }
  .icon-lg {
    height: 32px;
    font-size: 16px;
    line-height: 32px;
    width: 32px;
  }
`;

export const CustomPopoverContainer = styled(PopoverContainer)`
  position: absolute;
  right: 10px;
  flex-flow: column nowrap;
`;

export const CustomPopoverContent = styled(PopoverContent)`
  visibility: hidden;
  margin-top: -5px;
  opacity: 0;
  position: absolute;
  z-index: 10;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease 0ms;
  border-radius: 3px;
  min-width: 7em;
  flex-flow: column nowrap;
  background-color: #fff;
  color: #000;
  padding: 5px;
  left: 50%;
  transform: translateX(-50%);
  ${(props) =>
    props.active &&
    `
    visibility: visible;
    opacity: 1;
    transition-delay: 100ms;
  `} &::before {
    visibility: hidden;
  }
  a {
    color: rgba(255, 255, 255, 0.56);
    padding: 0.5em 1em;
    margin: 0;
    text-decoration: none;
    &:hover {
      background-color: #00bcd4 !important;
      color: #37474f;
    }
  }
`;

export const BoardWrapper = styled.div`
  padding: 0px;
  color: #393939;
  text-align: center;
  flex-direction: row;
  align-items: flex-start;
  background-color: transparent;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 0%) !important;
`;

export const Header = styled.header`
border-top-left-radius: 0.42rem;
border-top-right-radius: 0.42rem;
`;

export const Section = styled.section`
  background-color: #f5f5f5;
  text-align: left;
  border-radius: 4px;
  margin: 5px 3px;
  position: relative;
  padding: 5px;
  display: inline-flex;
  height: 100vh;
  min-width: 19%;
  max-height: 90%;
  flex-direction: column;
`;

export const LaneHeader = styled(Header)`
  margin-bottom: 0px;
  ${(props) =>
    props.editLaneTitle &&
    css`
      padding: 0px;
      line-height: 30px;
    `} ${(props) =>
    !props.editLaneTitle &&
    css`
      padding: 5px 5px;
    `};
`;

export const LaneFooter = styled.div``;

export const ScrollableLane = styled.div`
  flex: 1;
  overflow-y: auto;
  width: 100%;
  overflow-x: hidden;
  align-self: center;
  max-height: 90vh;
  margin-top: 10px;
  flex-direction: column;
  justify-content: space-between;
`;

export const Title = styled.span`
    font-size: 1.2rem;
    font-weight: 500;
    color: #181C32;
    display: inline;
`;

export const RightContent = styled.span`
  width: 30%;
  text-align: right;
  padding-right: 10px;
  font-size: 13px;
  width: 90%;
  text-align: center;
`;
export const CardWrapper = styled.article`
  border-radius: 3px;
  background-color: #fff;
  position: relative;
  padding: 7px;
  cursor: pointer;
  max-width: 250px;
  margin-bottom: 7px;
  width: 100%;
`;

export const MovableCardWrapper = styled(CardWrapper)`
  &:hover {
    background-color: #e1f0ff;
    color: #000;
  }
`;

export const CardHeader = styled(Header)`
  border-bottom: 1px solid #eee;
  padding-bottom: 6px;
  color: #000;
  background-color: transparent;
  text-align: left;
`;

export const CardTitle = styled(Title)`
  font-size: 14px;
  color: #3699ff;
  line-height: 18px;
`;

export const CardRightContent = styled(RightContent)`
  font-size: 10px;
`;

export const Detail = styled.div`
  font-size: 12px;
  color: #4d4d4d;
  white-space: pre-wrap;
`;

export const Footer = styled.div`
  border-top: 1px solid #eee;
  padding-top: 6px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagSpan = styled.span`
  padding: 2px 3px;
  border-radius: 3px;
  margin: 2px 5px;
  font-size: 70%;
`;

export const AddCardLink = styled.a`
  border-radius: 0 0 3px 3px;
  color: #838c91;
  display: block;
  padding: 5px 2px;
  margin-top: 10px;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    //background-color: #cdd2d4;
    color: #4d4d4d;
    text-decoration: underline;
  }
`;

export const LaneTitle = styled.div`
  font-size: 15px;
  width: 268px;
  height: auto;
`;

export const LaneSection = styled.section`
  background-color: #2b6aa3;
  border-radius: 3px;
  margin: 5px;
  position: relative;
  padding: 5px;
  display: inline-flex;
  height: auto;
  flex-direction: column;
`;

export const NewLaneSection = styled(LaneSection)`
  width: 200px;
`;

export const NewLaneButtons = styled.div`
  margin-top: 10px;
`;

export const CardForm = styled.div`
  background-color: #e3e3e3;
`;

export const InlineInput = styled.textarea`
  overflow-x: hidden; /* for Firefox (issue #5) */
  word-wrap: break-word;
  min-height: 28px;
  max-height: 112px; /* optional, but recommended */
  resize: none;
  width: 100%;
  height: 28px;
  font-size: 15px;
  line-height: 20px;
  background-color: transparent;
  box-shadow: none;
  box-sizing: border-box;
  border-radius: 3px;
  border: 0;
  padding: 4px 8px;
  outline: 0;
  ${(props) =>
    props.border &&
    css`
      &:focus {
        box-shadow: inset 0 0 0 2px #0079bf;
      }
    `} &:focus {
    background-color: white;
  }
`;
