import { IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import CreatableSelect from "react-select/creatable";
import { CheckboxLarge } from "../../../../../../_metronic/_partials/controls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

export const SendEmailfromGrid = ({ row, showModalSend, setshowModalSend }) => {
  const [EmailAddressList, setEmailAddressList] = useState([]);
  const [ToDataDefeault, setToDataDefeault] = useState([]);
  const [includBol, setincludBol] = useState(true);
  const [ToData, setToData] = useState(null);
  const [BodyData, setBodyData] = useState("");
  const [SubjectData, setSubjectData] = useState("");
  const [includCCData, setincludCCData] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setSubjectData("Mitco Shipment ID: " + row.Id);
      setBodyData(
        `<p>Hello,</p><p>Attached are the dispatch files for the following shipments.</p><p>Shipment# {${row.Id}}</p><p>Thanks</p><p>Mitco team</p>`
      );
    }, 500);
  }, [row.Id]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/shipments/getsendemail/" + row.Id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        var obj = [];
        var obj2 = [];

        resultobj.Emails.map((item) => {
          obj.push({ value: item.Email, label: item.Email });
          obj2.push({ Email: item.Email, Name: item.Email });
          return null;
        });

        setEmailAddressList(obj);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const SendEmailfunctions = async () => {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    if (window.SendDispatchShipmentsList !== undefined) {
      data = {
        Ids: window.SendDispatchShipmentsList?.map(function(item) {
          return item.id;
        }),
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: [],
        Bol: includBol,
      };
    } else {
      data = {
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: [],
        Bol: includBol,
      };
    }

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    if (window.SendDispatchShipmentsList !== undefined) {
      url = "/shipmentsmanagement/sendMultiEmail";
    } else {
      url = `/shipments/sendEmail/` + row.Id;
    }
    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      Notification(
        "success",
        "Email sent successfuly for Shipment ID " + row.Id
      );
      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setincludBol(false);
    } else {
      Notification("error", "invalid data");
    }
    setisLoadingSend(false);
    setshowModalSend(false);
  };
  return (
    <Modal
      size="xl"
      show={showModalSend}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">Send Email</Modal.Title>
        <IconButton
          className="MuiButtonBase-root MuiIconButton-root jss179"
          aria-label="Close"
          onClick={() => {
            setshowModalSend(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-12">
              <p className="mb-3">To: </p>
              <CreatableSelect
                isMulti
                name="To"
                id="To"
                value={ToDataDefeault}
                options={EmailAddressList}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(e) => {
                  var obj = [];
                  var objDefeault = [];
                  if (e) {
                    e.map((item) => {
                      obj.push({
                        Email: item.value,
                        Name: item.value,
                      });
                      objDefeault.push({
                        value: item.value,
                        label: item.value,
                      });
                      return null;
                    });
                  }
                  setToDataDefeault(objDefeault);
                  setToData(obj);
                }}
              />

              <CheckboxLarge
                Size="12px"
                name="includCC"
                text="Include me on CC"
                onChange={(event) => {
                  setincludCCData(event.target.checked);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <p className="mb-3">Subject: </p>
              <input
                name="Subject"
                className="form-control"
                placeholder="Subject"
                value={SubjectData}
                onChange={(event) => {
                  setSubjectData(event.target.value);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <p className="mb-3">Body: </p>

              <CKEditor
                name="Body"
                Id="Body"
                label="Body"
                editor={ClassicEditor}
                config={{
                  removePlugins: [
                    "Image",
                    "ImageCaption",
                    "ImageStyle",
                    "ImageToolbar",
                    "ImageUpload",
                    "MediaEmbed",
                  ],
                }}
                data={BodyData}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  setBodyData(data);
                }}
              />
            </div>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12 mt-5">
                  <h4>Attachments</h4>
                </div>
                <div className="col-lg-12 row mt-5">
                  <div className="col-lg-3">
                    <CheckboxLarge
                      Size="12px"
                      name="includBol"
                      checked={includBol}
                      text="Include BOL"
                      onChange={(event) => {
                        setincludBol(event.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => {
              setshowModalSend(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={SendEmailfunctions}
            disabled={isLoadingSend}
            className="btn btn-delete btn-primary"
          >
            Send Email
            {isLoadingSend && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
