import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../customers-table/FileViewerStyle.css";
import DetailMangment from "./DetailMangment";
export default function ModelManagmentFirstReporting({ Id,Container, isReporting }) {
    const [Show, setShow] = useState(false);
    return (
      <>
        <Modal
          size="xl"
          show={Show}
          onHide={() => {
            setShow(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <DetailMangment
            Id={Id}
            isReporting={isReporting}
            setShow={setShow.bind()}
          />
        </Modal>
        <button
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
          type="button"
          className={`alert alert-light-info  px-2 py-1 mt-3 text-primary bg-light`}
        >
          {Container}
        </button>
      </>
    );
  }