import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock = [];

export default async function mockPalletsManagment(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(customerIdApi) {
    return await fetch(
      `${window.$apiurl}/Pallets?customerId=${customerIdApi ?? ""}&statusId=${
        window.Status
      }`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].pallets = ChangeId(res[1].pallets);
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/pallets/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].pallet.id = res[1].pallet.Id;
        return res[1].pallet;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/pallets", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/pallets/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData["customer"]),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/pallets/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/pallets/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res ?? [];
      })
      .catch(() => {
        Notification("error", "GetViewmodel not working");
        return false;
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/PalletsManagment/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/PalletsManagment").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    await PostModel(customer);
    customerTableMock.push(customer);
    return [200, { customer: customer }];
  });

  mock.onPost("api/PalletsManagment/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);

    const filter = queryParams.filter;
    var model = await GetModel(filter.customerIdApi);
    delete queryParams.filter.customerIdApi;
    queryParams.filter.id = window.$PalletManagementsearchText;
    customerTableMock = model.pallets;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    filterdCustomers.statuses = model.statuses;
    return [200, filterdCustomers];
  });

  mock.onPost("api/PalletsManagment/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/PalletsManagment/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/PalletsManagment\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PalletsManagment\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/PalletsManagment\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PalletsManagment\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/PalletsManagment\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PalletsManagment\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
