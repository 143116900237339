import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { default as JsNative } from "jquery";
import { Spinner, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import { momentDate } from "../../../../../_metronic/_helpers/ATHelpers";
import { DetailMangment } from "../../../../modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { OneMoveMaster } from "../../../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export default function TableComponent({
  list = [],
  DeleteShipmentRow,
  ShipmentID,
  InTable,
  setDeletRowId,
  ClosedPallet,
  SavePalletInLocation,
  activeBtn,
  IsLocationZone = false,
  RemovePalletInLocation,
  setOSDList,
  RemoveSkuId,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const [ModelShow, setModelShow] = useState(false);
  const classes = useStyles();
  const [Model, setModel] = useState(false);
  const [DetailMangmentShow, setDetailMangmentShow] = useState(false);
  const [OnemoveMasterShow, setOnemoveMasterShow] = useState(0);
  const [ListOnly, setListOnly] = useState([]);
  const [Detail, setDetail] = useState({});
  const [IsClosed, setIsClosed] = useState(false);
  const [isMove, setisMove] = useState(false);
  const [isOpenOSD, setisOpenOSD] = useState(false);

  var tableElement, offset;
  function GetTale() {
    tableElement = JsNative(
      ".collapse.show #LocationElement" + ShipmentID?.ConsigneeId
    );
    offset = tableElement.offset();
  }

  useEffect(() => {
    if (RemoveSkuId) {
      var check = ListOnly.findIndex((x) => x.Id === RemoveSkuId);
      if (check > -1)
        DeleteShipmentRow(ListOnly[check]?.ItemId, ShipmentID?.Id, false).then(
          (e) => {
            if (e)
              setListOnly((x) => {
                var index = x.findIndex((y) => y?.ItemId === x[check]?.ItemId);
                if (index > -1) x.splice(index, 1);

                if (x.length === 0) setisOpenOSD(false);
                return x;
              });
          }
        );
    }
  }, [RemoveSkuId]);

  function handleDrag(e) {
    GetTale();
    setisMove(true);
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];
    if (CheckPosition(offset, page)) {
      tableElement.addClass("bg-light-primary");
    } else tableElement.removeClass("bg-light-primary");
    tableElement.addClass("border border-primary border-2");
  }

  function handleStop(e) {
    GetTale();
    setTimeout(() => {
      setisMove(false);
    }, 1000);
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];
    if (CheckPosition(offset, page)) {
      var locationId = tableElement.attr("data-id");
      setModelShow(locationId);
    }
    tableElement.removeClass("bg-light-primary");
    tableElement.removeClass("border border-primary border-2");
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    setIsClosed(ShipmentID?.IsClosed);
  }, [ShipmentID?.IsClosed]);

  useEffect(() => {
    if (InTable?.id === ShipmentID?.Id && InTable?.number !== 0)
      GetShipmentId(ShipmentID?.Id).then((e) => {
        if (e[0] === 200) {
          setListOnly(e[1]?.pallet?.ItemsList ?? []);
        }
      });
  }, [InTable]);

  useEffect(() => {
    if (ShipmentID?.Id)
      GetShipmentId(ShipmentID?.Id).then((e) => {
        if (e[0] === 200) {
          setListOnly(e[1]?.pallet?.ItemsList ?? []);
          setDetail(e[1]?.pallet ?? {});
        }
      });
  }, []);

  useEffect(() => {
    if (OnemoveMasterShow === false)
      setDeletRowId((x) => {
        return { id: ShipmentID?.Id, number: x.number + 1 };
      });
  }, [OnemoveMasterShow]);

  return (
    <div
      id={"" + ShipmentID?.Id}
      style={{
        zIndex: 1,
      }}
      className={
        "col-xl-4 col-lg-4 col-sm-6 AllTableComponent TableComponent" +
        (!IsClosed ? ShipmentID?.ConsigneeId : "") +
        " " +
        (activeBtn === 1 && !IsClosed ? "d-none" : "")
      }
    >
      <Modal show={ModelShow}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add pallet {ShipmentID?.Id} in Location {ModelShow}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              setModelShow(false);
              SavePalletInLocation(ShipmentID, ModelShow);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={Model}
        onHide={() => {
          setModel(false);
        }}
      >
        <Modal.Header>
          <div className="row modal-title h4 w-100">
            <div className="col-6">
              <b>Pallet ID</b>
              <b className=" p-0 px-3 ml-3">{ShipmentID?.Id}</b>
            </div>
            {!IsLocationZone && (
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-light-danger font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                  style={mystyleTabs}
                  onClick={() => {
                    PosttoClosed(
                      ShipmentID?.Id,
                      setIsClosed,
                      IsClosed,
                      ClosedPallet
                    );
                  }}
                >
                  <i
                    className={`fas fa-${
                      !IsClosed ? "times-circle" : "lock-open"
                    }`}
                  ></i>{" "}
                  {!IsClosed ? "Close Pallet" : "UnClose Pallet"}
                </button>
              </div>
            )}
            {IsLocationZone && (
              <div className="col-3">
                <button
                  type="button"
                  className="btn btn-light-danger font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                  style={mystyleTabs}
                  onClick={() => {
                    RemovePalletInLocation(ShipmentID);
                  }}
                >
                  Remove From this Location
                </button>
              </div>
            )}
            <div className="col-3">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  window
                    .open(
                      "/TransloadPalletManifest/" + ShipmentID?.Id,
                      "_blank"
                    )
                    .focus();
                }}
              >
                <i className={`fas fa-eye`}></i> Pallet Manifest
              </button>
            </div>
          </div>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModel(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>SKU Count : {ListOnly.length}</b>
            {ListOnly.length > 0 ? (
              <Paper className={classes.paper} id={ShipmentID + "Id"}>
                <div className="react-bootstrap-table table-responsive TabelFilter">
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Container Number
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          PO#
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          SKU
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          UPC
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Cartons
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Description
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          DC Location
                        </TableCell>
                        {!IsClosed && (
                          <TableCell className="bg-light text-dark pt-5 pb-5">
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(ListOnly ?? []).map((item, index) => (
                        <TableTrFunction
                          item={item}
                          key={index}
                          DeleteShipmentRow={DeleteShipmentRow}
                          IsClosed={IsClosed}
                          ShipmentID={ShipmentID}
                          setListOnly={setListOnly}
                        />
                      ))}
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {ListOnly.reduce((a, b) => +a + +b.Quantity, 0)}
                          </b>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        {!IsClosed && <TableCell />}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            ) : (
              <Nodatafound PalletID={ShipmentID?.Id} />
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Draggable
        axis="both"
        handle=".handle"
        position={{ x: 0, y: 0 }}
        grid={[1, 1]}
        scale={1}
        onDrag={handleDrag}
        onStop={handleStop}
      >
        <div
          className={
            "shadow-sm p-0 pl-2 pt-5 mb-5 rounded " +
            (!IsClosed ? "bg-white " : "bg-secondary ") +
            (activeBtn === 1 ? "handle" : "")
          }
          style={{
            height: "13vw",
            cursor: "pointer",
            zIndex: 1,
          }}
          onTouchEnd={(e) => setModel(!isMove)}
          onClick={(e) => setModel(!isMove)}
        >
          <b
            className="position-absolute"
            style={{
              fontSize: "1vw",
              left: "0",
            }}
          >
            <i
              className={
                "fas fa-location-arrow mx-1 text-" +
                (ShipmentID?.ConsigneeName === "Scentsy Exception Lane"
                  ? "warning"
                  : "info")
              }
              style={{
                fontSize: "1vw",
              }}
            />
            {ShipmentID?.ConsigneeName ?? "Empty"}
          </b>
          {IsClosed && (
            <b
              className="position-absolute"
              style={{
                right: "0.2vw",
              }}
            >
              <i className="fas fa-lock text-danger mr-2" />
            </b>
          )}
          <i
            className={
              "fas fa-pallet position-absolute text-" +
              (ShipmentID?.ConsigneeName === "Scentsy Exception Lane"
                ? "warning"
                : "info")
            }
            style={{
              fontSize: "7vw",
              opacity: "0.1",
              top: "50%",
              left: "50%",
              transform: "translate(-50% , -50%)",
            }}
          />
          <b
            style={{
              fontSize: "3vw",
              color: "#000000b3",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50% , -50%)",
            }}
          >
            {ShipmentID.Id}
          </b>
          <b
            style={{
              position: "absolute",
              fontSize: "15px",
              fontSize: "1vw",
              bottom: "2vw",
              left: "0.5vw",
            }}
          >
            SKU Count : {ListOnly.length}
          </b>
          <b
            style={{
              position: "absolute",
              fontSize: "15px",
              fontSize: "1vw",
              bottom: "0.5vw",
              left: "0.5vw",
            }}
          >
            Carton Count : {ListOnly.reduce((a, b) => +a + +b.Quantity, 0) ?? 0}
          </b>
          {ShipmentID?.ConsigneeName === "Scentsy Exception Lane" &&
            ListOnly.length > 0 && (
              <b
                className="position-absolute p-3"
                style={{ right: "-25px", bottom: "-27px", zIndex: 2 }}
                onClick={(e) => {
                  e.preventDefault();

                  if (!isOpenOSD) {
                    var a = ListOnly;
                    a.forEach((element) => {
                      element.LeftQuantity = element?.Quantity ?? 0;
                      element.Location = ShipmentID?.ConsigneeName;
                      element.Id = element?.ItemId;
                    });
                    setOSDList(a);
                    setisOpenOSD(true);
                  } else {
                    setOSDList([]);
                    setisOpenOSD(false);
                  }

                  e.stopPropagation();
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();

                  if (!isOpenOSD) {
                    var a = ListOnly;
                    a.forEach((element) => {
                      element.LeftQuantity = element?.Quantity ?? 0;
                      element.Location = ShipmentID?.ConsigneeName;
                      element.Id = element?.ItemId;
                    });
                    setOSDList(a);
                    setisOpenOSD(true);
                  } else {
                    setOSDList([]);
                    setisOpenOSD(false);
                  }

                  e.stopPropagation();
                }}
              >
                <i
                  className={
                    "fas text-success fa-" +
                    (isOpenOSD ? "minus-circle" : "chevron-circle-down")
                  }
                  style={{ fontSize: "25px" }}
                />
              </b>
            )}
        </div>
      </Draggable>
    </div>
  );
}

const TableTrFunction = ({
  item,
  DeleteShipmentRow,
  IsClosed,
  ShipmentID,
  setListOnly,
}) => {
  return (
    <TableRow key={item.Id}>
      <TableCell>{item.ContainerNumber}</TableCell>
      <TableCell>{item.PurchaseOrder}</TableCell>
      <TableCell>{item.Sku}</TableCell>
      <TableCell>{item.Upc}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>{item.Description}</TableCell>
      <TableCell>{item.Location}</TableCell>
      {!IsClosed && (
        <TableCell>
          <input
            value="Delete"
            type="button"
            className="btn btn-danger text-white font-weight-bolder font-size-sm w-100 p-1"
            onClick={() => {
              DeleteShipmentRow(item?.ItemId, ShipmentID?.Id).then((e) => {
                if (e)
                  setListOnly((x) => {
                    var index = x.findIndex((y) => y?.ItemId === item?.ItemId);
                    if (index > -1) x.splice(index, 1);

                    return x;
                  });
              });
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

function percentage(partialValue, totalValue) {
  var a = ((100 * partialValue) / EqSizeToPallet(+totalValue)).toFixed(0);
  if (isNaN(a)) return 0;
  return ((100 * partialValue) / EqSizeToPallet(+totalValue)).toFixed(0);
}

function EqSizeToPallet(number) {
  if (number === 53) return 28;
  else if (number === 28) return 24;
  else if (number === 20) return 12;
}

async function GetShipmentId(id) {
  return await fetch(`${window.$apiurl}/pallets/${id}`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res;
    })
    .catch((error) => {
      Notification("error", "Shipment ID : " + id + " Not Found");
      console.error(error);
      return undefined;
    });
}

function Nodatafound({ PalletID = 0 }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 mt-5 pt-5 bg-light border rounded notItems">
          <div className="container-fluid row pt-5">
            <div className="col-lg-1" />
            <div className="col-lg-3 text-right">
              <i
                className="fas fa-truck"
                style={{ fontSize: "70px", opacity: 0.3 }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <p className="h1 mt-5" style={{ opacity: 0.3 }}>
                Cartons Not Found
              </p>
            </div>
            <div className="col-lg-2" />
            <div className="col-lg-12 my-5" />
          </div>
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

async function PosttoClosed(
  PalletsIDStat,
  setIsClosed,
  IsClosed,
  ClosedPallet
) {
  /* setLoading(true); */
  var number = IsClosed ? 82 : 84;
  return await fetch(`${window.$apiurl}/Pallets/UpdateSingleStatus`, {
    method: "POST",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Id: PalletsIDStat, StatusId: number }),
  }).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      ClosedPallet(PalletsIDStat, IsClosed);
      setIsClosed((x) => !x);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}
