import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

let carriersTableMock;
export default function mockCarriers(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetCarriers(showInactive) {
    return await fetch(
      `${window.$apiurl}/carriers?showInactive=${showInactive}`
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/carriers/${id}`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1].Id;
        res[1].Code = res[1].Carrier;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/carriers", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    let modelData = someData["customer"];
    return fetch(window.$apiurl + "/carriers/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modelData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/carriers/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  mock.onPost("api/carriers").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { Code, SCAC, NeedBill, Description } = customer;
    const newCustomer = {
      Code,
      SCAC,
      NeedBill,
      Description,
    };
    var postModel = await PostModel(newCustomer);
    document.getElementById("ReLoadTableId").click();
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/carriers/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const showInactive = queryParams.filter.ShowInactive;
    carriersTableMock = await GetCarriers(showInactive);
    delete queryParams.filter.ShowInactive;
    delete queryParams.filter.ReLoadTable;
    const filterdCarriers = mockUtils.baseFilter(
      carriersTableMock,
      queryParams
    );
    return [200, filterdCarriers];
  });

  mock.onPost("api/carriers/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const showInactive = queryParams.filter.ShowInactive;
    carriersTableMock = await GetCarriers(showInactive);
    delete queryParams.filter.ShowInactive;
    delete queryParams.filter.ReLoadTable;
    const filterdCarriers = mockUtils.baseFilter(
      carriersTableMock,
      queryParams
    );
    return [200, filterdCarriers];
  });
  
  mock.onPost("api/carriers/deleteCarriers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carriersTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carriersTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/carriers/updateStatusForCarriers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carriersTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/carriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/carriers\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/carriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/carriers\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = carriersTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    carriersTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/carriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/carriers\/(\d+)/)[1];
    const index = carriersTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    carriersTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
