import React, { useMemo } from "react";
import { useRemarksUIContext } from "./RemarksUIContext";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";

export function RemarksFilter() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      setQueryParams: remarksUIContext.setQueryParams,
      openNewRemarkDialog: remarksUIContext.openNewRemarkDialog,
      queryParams: remarksUIContext.queryParams,
    };
  }, [remarksUIContext]);

  return (
    <>
      <Card>
        <CardHeader title="Line Items">
          <CardHeaderToolbar>
          <button
              type="button"
              className="btn btn-success"
              onClick={() => remarksUIProps.openNewRemarkDialog()}>
              Add New Purchase Line Items
            </button>
          </CardHeaderToolbar>
        </CardHeader>
      </Card>
    </>
  );
}
