import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock = [];

export default async function mockNotes(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel({ pageNumber, pageSize, filter }) {
    return await fetch(
      `${
        window.$apiurl
      }/notes/GetNotes?pageNumber=${pageNumber}&pageSize=${pageSize}&containerId=${
        filter?.Containers
      }&accessLevelId=${filter?.AccessLevelId}&categoryId=${
        filter?.CategoryId
      }&search=${filter?.search ?? ""}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          res[1].pallets = ChangeId(res[1].pallets);
          return res[1];
        } else {
          Notification("error", res[1]?.Message ?? "");
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/notes/addNote", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData) {
    return fetch(window.$apiurl + "/notes/PutNoteMultipleReceipt", {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData["customer"]),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/notes/DeleteNoteLinks?id=" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onPost("api/Notes/PutModel").reply(async ({ data }) => {
    var a = JSON.parse(data);
    const obj = await PutModel(a);
    document.getElementById("getNots").click();
    return [200, obj];
  });

  mock.onPost("api/Notes").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    await PostModel(customer);
    customerTableMock.push(customer);
    document.getElementById("ReLoadTableId").click();
    return [200, { customer: customer }];
  });

  mock.onPost("api/Notes/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);

    var model = await GetModel(queryParams);
    customerTableMock = model?.notes ?? [];

    var list = [];
    for (
      var i = 1;
      i <= (queryParams.pageNumber - 1) * queryParams.pageSize;
      i++
    )
      list.push({});
    list.push(...customerTableMock);
    customerTableMock = list;

    queryParams.filter = {};
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    filterdCustomers.totalCount = model?.totalCount ?? 0;
    return [200, filterdCustomers];
  });

  mock.onPost("api/Notes/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Notes/updateStatusForCustomers").reply(async (config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/Notes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Notes\/(\d+)/)[1];
    //const customer = await GetModelId(id);
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/Notes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Notes\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  mock.onDelete(/api\/Notes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Notes\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
