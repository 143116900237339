import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/ManageCustomer/customersActions";
import { useCustomersUIContext } from "../CustomersUIContext";
import { Notification } from "../../../../Notification";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useState } from "react";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
import { propTypes } from "react-spinkit";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ActivityFunction } from "../../../../../../_metronic/_helpers/ATHelpers";

export function CustomerDetailsDialog({ id, show, onHide }) {
  const adapter = new LocalStorage("db");
  const db = low(adapter);

  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const [activitiesData, setActDataCustomer] = useState(null);

  const customersUIProps = useMemo(() => {
    return {
      initCustomer: customersUIContext.initCustomer,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const dispatch = useDispatch();
  const [CustomerId, setCustomerId] = React.useState(false);
  const { actionsLoading, customerForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.customers.actionsLoading,
      customerForEdit: state.customers.customerForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    //shipments/GetSendEmailInvoice/{id}
    if (id != null && id != undefined) {
      setActDataCustomer(null);
      fetch(`${window.$apiurl}/customers/activities/` + id, {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          console.log(data);
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          var a = res[1];
          setActDataCustomer(a || []);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);

  return (
    <div>
      <Modal
        size="xl"
        show={show}
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="row w-100">
            <div className="col-lg-6">
              <h3 className="font-weight-bolder text-dark">
                Customer Activities ID: {id}
              </h3>
            </div>
          </div>
          <IconButton
            
            aria-label="Close"
            onClick={() => {
              //    setModalActivities(false);
              onHide();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="overlay-block">
          <div className={`h-60 card card-custom`}>
            {/* Body */}
            <div className="card-body pt-5 row now-rapTable">
              {activitiesData !== null ? (
                <ActivityFunction
                  Activitys={activitiesData?.Activities || []}
                />
              ) : (
                <div className={`h-60 card card-custom`}>
                  <div className="card-body pt-5 row now-rapTable">
                    <div className="col-xl-12" style={{ textAlign: "center" }}>
                      Loading Data ...
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
