import React, { useMemo, useEffect, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import { Select } from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
  ButtonToolbar,
} from "react-bootstrap";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Export } from "../../../../../../_metronic/_helpers/ATHelpers";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
export function CustomersFilter({ listLoading }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [QueryFilter, setQueryFilter] = useState("");
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  async function fetchMyAPI2(type, entityName) {
    if (QueryFilter) {
      QueryFilter.pageSize = 1000;
      const result = await axios.post(`api/ManageRates/find`, { "queryParams": QueryFilter });
      Export(result.data.entities, type, entityName);
    } else {
      const result = await axios.post(`api/ManageRates/find`, { "queryParams": { "filter": { "CustomerId": "", "BillingTypeId": ""}, "sortOrder": "asc", "sortField": "id", "pageNumber": 1, "pageSize": 100000 } });
      Export(result.data.entities, type, entityName);
    }
  }
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const classes = useStyles();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
      setQueryFilter(newQueryParams);
    }
  };
  const prepareFilter = (queryParams, values) => {
    const { ReLoadTable , CustomerId , BillingTypeId} = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    filter.CustomerId = CustomerId !== "" ? CustomerId : "";
    filter.BillingTypeId = BillingTypeId !== "" ? BillingTypeId : "";
    filter.ReLoadTable = ReLoadTable;
    newQueryParams.filter = filter;
    return newQueryParams;
  };
  //get the list of name
  useEffect(() => {
    fetchAsync();
  }, []);
  const [Name, setName] = useState([]);
  const [showProgress, setshowProgress] = useState(false);
  const fetchAsync = async () => {
    try {
      setshowProgress(true);
      const result = await axios.get("api/ManageRates/GetAllApi");
      setName(result.data["Customers"]);
      setshowProgress(false);
    } catch (e) { }
  };

  return (
    <>
      <Formik
        initialValues={{
          CustomerId: "", // values => All=""/Susspended=0/Active=1/Pending=2
          BillintTypeId: "",// values => All=""/Business=0/Individual=1
          ReLoadTable : 0
        }}
        onSubmit={(values) => {
          fetchAsync();
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <input
                type="button"
                className="d-none"
                id="ReLoadTableId"
                onClick={(e) => {
                  if (e.target.value == "0")
                    document.getElementById("ReLoadTableId").value = 1;
                  else document.getElementById("ReLoadTableId").value = 0;
                  setFieldValue("ReLoadTable", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="form-group row">
              <div className="col-lg-12">
                  <ButtonToolbar className="float-right">
                    <Button style={{ marginRight: '0.8rem'}}
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="outlined" 
                      color="primary"
                      onClick={handleClick}
                    >
                      Export as
                      <Icon className={classes.rightIcon} >save</Icon>

                    </Button>
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("excel", "Manage Rates");
                      }}>
                        <ListItemIcon>
                        <i class="far fa-file-excel"></i>
                        </ListItemIcon>
                        <ListItemText primary="Excel"
                        />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("csv", " Manage Rates")
                      }} >
                        <ListItemIcon>
                        <i class="fas fa-file-csv"></i>
                        </ListItemIcon>
                        <ListItemText primary="CSV"
                        />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("json", "Manage Rates")
                      }}>
                        <ListItemIcon>
                        <i class="far fa-file-code"></i>

                        </ListItemIcon>
                        <ListItemText primary="Json" />
                      </StyledMenuItem>
                    </StyledMenu>
                    {/* <Button variant="primary" type="submit">Search</Button> */}
                  </ButtonToolbar>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Select
                    name="CustomerId"
                    id="CustomerId"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("CustomerId", e.target.value);
                      handleSubmit();
                    }}
                    value={values.CustomerId}
                  >
                    <option value="">Select</option>
                    {Name.map((item, index) => (
                      <option value={item.id}>{item.CompanyName}</option>
                    ))}
                  </Select>
                  {showProgress && <LinearProgress />}
                  <small className="form-text text-muted">
                    <b>Choose</b> a Customer
                </small>
                </div>
                <div className="col-lg-6">
                  <Select
                    name="Name"
                    id="BillingTypeId"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("BillingTypeId", e.target.value);
                      handleSubmit();
                    }}
                    value={values.BillingTypeId}
                  >
                    <option value="">Select </option>
                    <option value="1">Receiving </option>
                    <option value="2">Shipping</option>
                  </Select>
                  <small className="form-text text-muted">
                    <b>Choose</b> a Billing Type
                </small>
                </div>
              </div>
            </form>
          )}
      </Formik>
    </>
  );
}
