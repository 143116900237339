import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ImportOrderItemsComp } from "./ImportOrderItemsComp";

export function ImportOrderItems({ isFromImport }) {
  return (
    <Card>
      <Card.Body>
        <ImportOrderItemsComp />
      </Card.Body>
    </Card>
  );
}
