import MockUtils from "../mock.utils";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import $ from "jquery";
const adapter = new LocalStorage("db");
const db = low(adapter);

let remarkTableMock = [];
export default function mockTransportationItems(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModelsFirst(id) {
    return await fetch(`${window.$apiurl}/transload/receipts/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        let a = 0;
        let b = 0;
        res[1]["LineItems"].forEach((x) => {
          if (x.Cube) {
            if (x.WeightType === "KGS") a += x.Weight;
            if (x.WeightType === "LBS") a += x.Weight * 2.20462;
          }
          if (x.Weight) {
            if (x.CubeType === "CBM") b += x.Cube;
            if (x.CubeType === "CFT") b += x.Cube * 35.3147;
          }
        });
        window.Cube = a.toFixed(2);
        window.Weight = b.toFixed(2);
        window.Cube2 = (a * 35.3147).toFixed(2);
        window.Weight2 = (b * 2.20462).toFixed(2);
        return ChangeId(res[1]["LineItems"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModels(id) {
    return await fetch(`${window.$apiurl}/shipments/transportationItems/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        const result = data;
        return Promise.all([statusCode, result]);
      })
      .then((res) => {
        let a = 0;
        let b = 0;
        let u = 0;
        let p = 0;

        let c = res[1] || [];
        c.forEach((x) => {
          if (x.Weight) {
            if (x.WeightType === "LBS") a += x.Weight * 2.20462;
            else a += x.Weight;
          }
          if (x.Cube) {
            if (x.CubeType === "CFT") b += x.Cube * 35.3147;
            else b += x.Cube;
          }
          if (x.Quantity) u += x.Quantity;
          if (x.Pallets) p += x.Pallets;
        });
        window.$reciept_LineItemList = c;
        $(`[name="TotalCube1"]`).val(b.toFixed(2));
        $(`[name="TotalWeight1"]`).val(a.toFixed(2));
        $(`[name="UnitCounts"]`).val(u.toFixed(2));
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/transload/receipts/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/shipments/transportation/item", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        if (!res.ok) throw res;
        Notification("success", "success");
      })
      .catch((err) => {
        err.json().then((body) => {
          Notification("error", body?.Message);
        });
      });
  }

  async function DeleteModel(id) {
    return fetch(window.$apiurl + "/shipments/transportation/item/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/shipments/transportation/item/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetModel(id) {
    return await fetch(
      window.$apiurl + "/shipments/transportation/item/" + id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //------------------------------------------------------------------------------------

  mock.onGet("api/TransportationItems/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/TransportationItems").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const {
      id,
      ShipmentId,
      PurchaseOrder,
      Sku,
      UnitOfMeasureId,
      UnitCount,
      Description,
      Weight,
      WeightType,
      Cube,
      CubeType,
      OverQuantity,
      Location,
      ShortQuantity,
      DamagedQuantity,
      IsHot,
      IsHazardousMateriel,
      UnNumber,
      PackagingGroup,
      HazardClass,
      PieceDescription,
      EmergencyContactNumber,
      LbsWeight,
      CftCube,
      Width,
      Height,
      FreightClass,
      Length,
      Nmfc
    } = remark;
    const newRemark = {
      id,
      ShipmentId,
      Location,
      PurchaseOrder,
      Sku,
      UnitOfMeasureId,
      UnitCount,
      Description,
      Weight,
      WeightType,
      Cube,
      CubeType,
      Width,
      Height,
      OverQuantity,
      ShortQuantity,
      DamagedQuantity,
      IsHot,
      IsHazardousMateriel,
      UnNumber,
      PackagingGroup,
      HazardClass,
      PieceDescription,
      EmergencyContactNumber,
      LbsWeight,
      CftCube,
      FreightClass,
      Length,
      Nmfc
    };
    await PostModel(remark);
    remarkTableMock.push(newRemark);
    return [200, { remark: newRemark }];
  });

  mock.onPost(/api\/TransportationItemsfind\/\d+/).reply(async (config) => {
    const urls = config.url.split("/");
    const id = urls[2];
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const productRemarks = await GetModels(id);
    const filterdRemarks = mockUtils.baseFilter(productRemarks, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/TransportationItems/deleteRemarks").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/TransportationItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransportationItems\/(\d+)/)[1];
    const remark = await GetModel(id);
    remark.id = id;
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/TransportationItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransportationItems\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    remark.ShipmentId = window.$ShipmentId;
    var putObj = await PutModel(remark, id);
    if (!putObj) {
      return [400];
    }
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/TransportationItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransportationItems\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
