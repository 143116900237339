import React from 'react'
import { Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export function PurchaseOrderReports() {
    return (
        <>
            <Helmet>
                <title>eMPower | Purchase Report</title>
                <meta name="description" content="This is my meta description" />
            </Helmet>
            <Card>
                <Card.Body>
                    <Form>
                        <div class='row'>
                            <div class='col-12 mb-5'>
                                <span class="label label-lg label-light-primary label-inline">
                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="/FindPurchaseOrders">
                                        <h4>
                                            <u>
                                                Find Purchase Orders
                                        </u>
                                        </h4>
                                    </Link>
                                </span>
                                <p className='MuiPaper-root font-size-lg mt-2'>This is a way to find closed Purchase Orders as well as open Purchase Orders.</p>
                            </div>

                            <div class='col-12 mb-5'>
                                <span class="label label-lg label-light-primary label-inline">
                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="/PurchaseOrderReport">
                                        <h4>
                                            <u>
                                                Purchase Order Report
                                        </u>
                                        </h4>
                                    </Link>
                                </span>
                                <p className='MuiPaper-root font-size-lg mt-2'>This is the Purchase Order that may be sent to the supplier.</p>
                            </div>

                            <div class='col-12 mb-5'>
                                <span class="label label-lg label-light-primary label-inline">
                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="/PurchaseOrderProgressReport">
                                        <h4>
                                            <u>
                                                Purchase Order Progress Report
                                        </u>
                                        </h4>
                                    </Link>
                                </span>
                                <p className='MuiPaper-root font-size-lg mt-2'>This report shows the current progress of the Purchase Order including quntities received, remaining and expected dates.</p>
                            </div>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </>
    )
}
