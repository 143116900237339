import { LinearProgress } from "@material-ui/core";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../controls";
import SelectPagination from "../Select/SelectPagination";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function FilterAccount({
  initFilter,
  setInitFilter,
  getMetricsInfo,
  getLanesInfo,
  getReceivablesInfo,
  getMetricsData,
  setNameSelected,
}) {
  const history = useHistory();
  const [isOpened, setIsOpened] = useState(false);

  const DateFilter = [
    { value: "Custom", label: "Custom" },
    { value: "MTD", label: "MTD" },
    { value: "YTD", label: "YTD" },
  ];

  const [loadingCustomer, setloadingCustomer] = useState(false);
  const [loaderFilter, setLoaderFilter] = useState(false);
  const [viewModel, setViewModel] = useState([]);

  function GetCustomerList() {
    setloadingCustomer(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${window.$apiurl}/dashboard/performance/getViewModel`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (result) => {
        var objs = result[1];
        setViewModel(objs || []);
        setloadingCustomer(false);
      })
      .catch((error) => {
        console.log("error", error);
        setloadingCustomer(false);
      });
  }
  useEffect(() => {
    GetCustomerList();
  }, []);

  return (
    <Card>
      <CardHeader title="Account Management Dashboard">
        <CardHeaderToolbar>
          {!isOpened ? (
            <a
              href={() => false}
              className="btn btn-light-primary btn-hover-primary mr-2"
              onClick={() => setIsOpened(true)}
            >
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </a>
          ) : (
            <>
              <a
                href={() => false}
                className="btn btn-light btn-hover-primary mr-2"
                onClick={() => {
                  getMetricsInfo({
                    Department: "",
                    AccountManager: "",
                    Account: initFilter.Account,
                    Mode: "",
                    MTD: "",
                    StartDate: null,
                    EndDate: null,
                  });
                  getLanesInfo({
                    Department: "",
                    AccountManager: "",
                    Account: initFilter.Account,
                    Mode: "",
                    MTD: "",
                    StartDate: null,
                    EndDate: null,
                  });
                  getReceivablesInfo({
                    Department: "",
                    AccountManager: "",
                    Account: initFilter.Account,
                    Mode: "",
                    MTD: "",
                    StartDate: null,
                    EndDate: null,
                  });
                  getMetricsData({
                    type: "revenue",
                    Department: "",
                    AccountManager: "",
                    Account: initFilter.Account,
                    Mode: "",
                    MTD: "",
                    StartDate: null,
                    EndDate: null,
                  });
                  setNameSelected("Gross Revenue");
                  setIsOpened(false);
                }}
              >
                Reset
              </a>
              <button
                disabled={loaderFilter}
                className="btn btn-light-primary btn-hover-primary mr-2"
                onClick={async (e) => {
                  getMetricsInfo(initFilter);
                  getMetricsData(initFilter);
                  getLanesInfo(initFilter);
                  getReceivablesInfo(initFilter);
                  try {
                    document
                      .getElementById("cardMetrics")
                      .scrollIntoView({ behavior: "smooth", block: "center" });
                  } catch (err) {
                    console.log(err);
                  }
                  e.preventDefault();
                }}
              >
                {loaderFilter ? (
                  <Fragment>
                    Loading
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="ml-2"
                    />
                  </Fragment>
                ) : (
                  "Apply filter"
                )}
              </button>
            </>
          )}
          <a
            href={() => false}
            className="btn btn-light-primary btn-hover-primary"
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Export.svg")} />
            </span>
            Export
          </a>
        </CardHeaderToolbar>
      </CardHeader>
      {isOpened && (
        <CardBody>
          <Row>
            <Col xl="3" lg="4" md="4">
              <div class="form-group">
                <SelectPagination
                  isClearable
                  name="Department"
                  id="DepartmentId"
                  label="Department"
                  inputId="react-select-single"
                  placeholder="Select..."
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  onChange={(e) => {
                    let obj = { ...initFilter };
                    obj.Department = e.value;
                    setInitFilter(obj);
                  }}
                  options={viewModel?.Warehouses?.map((a) => {
                    return { label: a.Code + " - " + a.Name, value: a.Id };
                  })}
                />
                {loadingCustomer && <LinearProgress />}
              </div>
            </Col>
            <Col xl="3" lg="4" md="4">
              <div class="form-group">
                <SelectPagination
                  isClearable
                  name="Account Manager"
                  id="AccountManagerID"
                  label="Account Manager"
                  inputId="react-select-single"
                  placeholder="Select..."
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  onChange={(e) => {
                    let obj = { ...initFilter };
                    obj.AccountManager = e.value;
                    setInitFilter(obj);
                  }}
                  options={viewModel?.Users?.map((a) => {
                    return { label: a.Name, value: a.Id };
                  })}
                />
                {loadingCustomer && <LinearProgress />}
              </div>
            </Col>
            <Col xl="3" lg="4" md="4">
              <div class="form-group">
                <SelectPagination
                  name="Account"
                  id="AccountID"
                  label="Account"
                  isDisabled={db?.read()?.getState()?.User?.IsCustomer}
                  inputId="react-select-single"
                  placeholder="Select..."
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  onChange={(e) => {
                    let obj = { ...initFilter };
                    obj.Account = e.value;
                    setInitFilter(obj);
                  }}
                  value={viewModel?.Customers?.filter(
                    (a) => a.Id === initFilter.Account
                  )?.map((a) => {
                    return { label: a.Code + " - " + a.Name, value: a.Id };
                  })}
                  options={viewModel?.Customers?.map((a) => {
                    return { label: a.Code + " - " + a.Name, value: a.Id };
                  })}
                />
                {loadingCustomer && <LinearProgress />}
              </div>
            </Col>
            <Col xl="3" lg="4" md="4">
              <div class="form-group">
                <SelectPagination
                  isClearable
                  name="Mode"
                  id="ModeID"
                  label="Mode"
                  inputId="react-select-single"
                  placeholder="Select..."
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  onChange={(e) => {
                    let obj = { ...initFilter };
                    obj.Mode = e.value;
                    setInitFilter(obj);
                  }}
                  value={viewModel?.Modes?.filter(
                    (a) => a.Id === initFilter.Mode
                  )?.map((a) => {
                    return { label: a.Name, value: a.Id };
                  })}
                  options={viewModel?.Modes?.map((a) => {
                    return { label: a.Name, value: a.Id };
                  })}
                />
                {loadingCustomer && <LinearProgress />}
              </div>
            </Col>
            <Col xl="3" lg="4" md="4">
              <div class="form-group">
                <label>MTD,YTD</label>
                <SelectPagination
                  name="DateFilter"
                  id="DateFilterId"
                  inputId="react-select-single"
                  placeholder="Select..."
                  TextFieldProps={{
                    InputLabelProps: {
                      htmlFor: "react-select-single",
                      shrink: true,
                    },
                  }}
                  value={DateFilter.filter((a) => a.value === initFilter?.MTD)}
                  options={DateFilter}
                  onChange={(e) => {
                    var startOfmonth = moment()
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    var startOfyear = moment()
                      .startOf("year")
                      .format("YYYY-MM-DD");
                    var currentDate = moment().format("YYYY-MM-DD");
                    let obj = { ...initFilter };
                    obj.MTD = e.value;
                    if (e.value === "MTD") {
                      obj.StartDate = startOfmonth;
                      obj.EndDate = currentDate;
                    } else if (e.value === "YTD") {
                      obj.StartDate = startOfyear;
                      obj.EndDate = currentDate;
                    } else {
                      obj.StartDate = null;
                      obj.EndDate = null;
                    }
                    setInitFilter(obj);
                  }}
                />
              </div>
            </Col>
            <Col xl="4" lg="4" md="6">
              <label>Date filter</label>
              <RangeDatePicker
                disabled={initFilter.MTD !== "Custom"}
                startDate={
                  initFilter.StartDate !== null &&
                  moment(initFilter.StartDate).format("YYYY-MM-DD")
                }
                endDate={
                  initFilter.EndDate !== null &&
                  moment(initFilter.EndDate).format("YYYY-MM-DD")
                }
                onChange={(startDate, endDate) => {
                  var obj = { ...initFilter };
                  obj.StartDate =
                    startDate === null
                      ? null
                      : moment(startDate).format("YYYY-MM-DD");
                  obj.EndDate =
                    endDate === null
                      ? null
                      : moment(endDate).format("YYYY-MM-DD");
                  setInitFilter(obj);
                }}
                dateFormat="MM/DD/YYYY"
                startDatePlaceholder="Start Date"
                endDatePlaceholder="End Date"
                className="my-own-class-name"
                startWeekDay="monday"
              />
            </Col>
          </Row>
        </CardBody>
      )}
    </Card>
  );
}
