/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, Switch } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import { Body } from "./Body";
import { Helmet } from "react-helmet";
import { Button , makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    "&:hover": {
      color: "#fff",
    },
  },
  input: {
    display: "none",
  },
}));
export function Layout() {
  const classes = useStyles();
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return (
    <>
      <Helmet>
        <title>eMPower | Welcome</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div id="kt_header" className="header bg-white header-fixed Landing">
            {/*begin::Container*/}
            <div className="container-fluid">
              <div className="container">
                <div className="d-flex align-items-stretch justify-content-between">
                  {/*begin::Left*/}
                  <div className="d-flex align-items-stretch mr-2">
                    {/*begin::Page Title*/}
                    <h2 className="text-dark d-lg-flex align-items-center mr-10 mb-0 display-4 font-weight-bolder">
                      <Link to="/" className="flex-column-auto mt-5 home-page">
                        <img
                          alt="Logo"
                          className="w-25"
                          src={toAbsoluteUrl("/media/bg/bg-logo.png")}
                        />
                      </Link>
                    </h2>
                    {/*end::Page Title*/}
                    {/*begin::Header Menu Wrapper*/}
                    <div
                      className="header-menu-wrapper header-menu-wrapper-left"
                      id="kt_header_menu_wrapper"
                    >
                      {/*begin::Header Menu*/}
                      {/*end::Header Menu*/}
                    </div>
                    {/*end::Header Menu Wrapper*/}
                  </div>
                  {/*end::Left*/}
                  {/*begin::Topbar*/}
                  <div className="topbar col-md-7">
                    <div
                      id="kt_header_menu"
                      className="header-menu header-menu-mobile header-menu-layout-default"
                    >
                      {/*begin::Header Nav*/}
                      <ul className="menu-nav">
                        <li className="menu-item" aria-haspopup="true">
                          <a href="#" className="menu-link bg-transparent">
                            <span className="menu-text font-size-h4">Home</span>
                          </a>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <a
                            href="#"
                            className="menu-link bg-transparent"
                            onClick={() => {
                              setTimeout(() => {
                                var el = document.getElementById("Features");
                                window.scroll({
                                  top: el?.offsetTop - 120,
                                  behavior: "smooth",
                                });
                              });
                            }}
                          >
                            <span className="menu-text font-size-h4">
                              Features
                            </span>
                          </a>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <a
                            href="#"
                            className="menu-link bg-transparent"
                            onClick={() => {
                              setTimeout(() => {
                                var el = document.getElementById("CASESTUDIES");
                                window.scroll({
                                  top: el?.offsetTop - 120,
                                  behavior: "smooth",
                                });
                              });
                            }}
                          >
                            <span className="menu-text font-size-h4">
                              Last News
                            </span>
                          </a>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <a
                            href="#"
                            className="menu-link bg-transparent"
                            onClick={() => {
                              setTimeout(() => {
                                var el = document.getElementById("Pricing");
                                window.scroll({
                                  top: el?.offsetTop - 120,
                                  behavior: "smooth",
                                });
                              });
                            }}
                          >
                            <span className="menu-text font-size-h4">
                              Pricing
                            </span>
                          </a>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <a
                            href="#"
                            className="menu-link bg-transparent"
                            onClick={() => {
                              setTimeout(() => {
                                var el = document.getElementById("Contact");
                                window.scroll({
                                  top: el?.offsetTop - 120,
                                  behavior: "smooth",
                                });
                              });
                            }}
                          >
                            <span className="menu-text font-size-h4">
                              Contact
                            </span>
                          </a>
                        </li>
                        <li className="menu-item" aria-haspopup="true">
                          <Button
                            href="/auth/login"
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                          >
                            Get Started
                          </Button>
                        </li>
                      </ul>
                      {/*end::Header Nav*/}
                    </div>
                  </div>
                  {/*end::Topbar*/}
                </div>
              </div>
            </div>
            {/*end::Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-0 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column flex-center mt-0 mt-lg-0">
              <Switch>
                <Body />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {date} eMPower {window.$BuildVersion}
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
