/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { index } from "./pages/Home/index";
import { Landing } from "./pages/Landing/index";
import { SpecificTabs } from "./SpecificTabs";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function Routes() {
  const [TablsOptionsList, setTablsOptionsList] = useState([]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/menu/tabs`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result || []);
        setTablsOptionsList(data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/Home" component={index} />
      <Route path="/Landing" component={Landing} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout TablsOptionsList={TablsOptionsList}>
          <SpecificTabs TablsOptionsList={TablsOptionsList} />
          <div id={`GlobalPage`}>
            <BasePage />
          </div>
        </Layout>
      )}
    </Switch>
  );
}
