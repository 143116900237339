import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { Helmet } from "react-helmet";

export function CustomersCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <>
    <Helmet>
        <title>eMPower | Manage Mode</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
    <Card>
      <CardHeader title="Manage Modes">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-success btn-shadow-hover mr-2"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Add Mode
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
      </CardBody>
    </Card>
    </>
  );
}
