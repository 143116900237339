//import customerTableMock from "./TransactionBillingShippingTableMock";
import MockUtils from "./mock.utils";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock = [];
export default function mockTransactionBillingShipping(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", "Error Occured");
      return false;
    }
  }

  //Get Trans Billing Shipping
  async function GetTransBillingShipping() {
    return await fetch(
      window.$apiurl +
        "/transactionbilling/" +
        window.customerId +
        "/" +
        window.chargeTypeId +
        "/" +
        window.calculatorId,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        console.log(data);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //let history = useHistory();
  //Get List
  async function GetViewModel() {
    return await fetch(window.$apiurl + "/transactionbilling/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        return false;
      });
  }
  //Post Trans Billing Shipping
  async function PostBillingShipping(someData) {
    return fetch(window.$apiurl + "/transactionbilling", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Put Trans Billing Shipping
  async function PutBillingShipping(someData, id) {
    return fetch(window.$apiurl + "/transactionbilling/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Delete Trans Billing
  async function DeleteTransBilling(id) {
    return await fetch(window.$apiurl + "/transactionbilling/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //API on Get By Id
  //Get {id}
  async function GetTrtansactionSBillingShippingById(id) {
    return await fetch(window.$apiurl + "/transactionbilling/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  //mock on Get for Select
  mock.onGet("api/TransactionBillingShipping/GetAllApi").reply(async () => {
    var listViewModel = {};
    listViewModel["viewModelLabels"] = await GetViewModel();
    listViewModel["viewModelUnits"] = await GetViewModel();
    return [200, listViewModel];
  });
  //Post Data
  mock.onPost("api/TransactionBillingShipping").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      CustomerId = window.customerId.toString(),
      ChargeTypeId = window.chargeTypeId.toString(),
      CalclatorId = window.calculatorId.toString(),
      Label = "",
      Unit = "",
      IsManual = false,
      Rate = "",
      Notes = "",
      ItemId = "",
      Description = "",
      ArAcct = "",
      GlAcctNumber = "",
    } = customer;

    const id = generateUserId();
    const newtransBillingSh = {
      CustomerId,
      ChargeTypeId,
      CalclatorId,
      Label,
      Unit,
      IsManual,
      Rate,
      Notes,
      ItemId,
      Description,
      ArAcct,
      GlAcctNumber,
    };
    var postTransBilling = await PostBillingShipping(newtransBillingSh);
    if (id !== -Infinity) {
      newtransBillingSh.Id = id;
    } else {
      newtransBillingSh.Id = postTransBilling.Id;
    }
    //Manage Data Result
    if (postTransBilling.Code === "200") {
      postTransBilling.id = postTransBilling.Id;
      customerTableMock.push(newtransBillingSh);
      window.$ManageBillingShippingPost = 200;
      window.$ManageBillingShippingPostMessage = postTransBilling.Message;
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newtransBillingSh,
          message: postTransBilling.Message,
          success: true,
        },
      ];
    } else {
      window.$ManageBillingShippingPost = 400;
      window.$ManageBillingShippingPostMessage = postTransBilling.Message;
      return [400, { message: postTransBilling.Message, success: false }];
    }
  });

  //mock on Find
  mock.onPost("api/TransactionBillingShipping/find").reply(async (config) => {
    if (
      window.customerId === undefined ||
      window.chargeTypeId === undefined ||
      window.calculatorId === undefined
    )
      window.location.href = "/billingWizard";
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetTransBillingShipping();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock
    .onPost("api/TransactionBillingShipping/deleteCustomers")
    .reply((config) => {
      const { ids } = JSON.parse(config.data);
      ids.forEach((id) => {
        const index = customerTableMock.findIndex((el) => el.id === id);
        if (index > -1) {
          customerTableMock.splice(index, 1);
        }
      });
      return [200];
    });

  mock
    .onPost("api/TransactionBillingShipping/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/TransactionBillingShipping\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransactionBillingShipping\/(\d+)/)[1];
    var resultById = await GetTrtansactionSBillingShippingById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/TransactionBillingShipping\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TransactionBillingShipping\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutBillingShipping(customer, id);
    if (!putObj) {
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.Id === +id);
    if (index === -1) {
      return [400];
    }
    //Manage Data Result
    if (window.$statusCodePut === 200) {
      window.$ManageTransactionBillingPut = 200;
      customerTableMock[index] = { ...customer };
      document.getElementById("ReLoadTableId").click();
      return [200];
    } else {
      window.$ManageTransactionBillingPut = 400;
      window.$ManageTransactionBillingPutMessage = putObj.Message;
      return [400];
    }
  });

  mock
    .onDelete(/api\/TransactionBillingShipping\/\d+/)
    .reply(async (config) => {
      const id = config.url.match(/api\/TransactionBillingShipping\/(\d+)/)[1];
      const index = customerTableMock.findIndex((el) => el.id === +id);
      var deleteObj = await DeleteTransBilling(id);
      if (!deleteObj) {
        return [400];
      }
      customerTableMock.splice(index, 1);
      return [200];
    });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.Id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
