import React from "react";
import { Button, Form, } from "react-bootstrap";

export function ManageAttachedFiles() {

    return (
        
            <div className="row">
                <div className="col-md-12">
                    <div  className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                        <h3 className="card-title font-weight-bolder ">SELECT TRANSACTION</h3>
                        </div>
                        <div className="card-body">
                        <span>
                            (Order, Receiver, Return or Assembly)
                        </span>
                        <div className=" my-7"></div>
                        <Form>
                            <Form.Group className="col-md-4">
                                <Form.Label>TRANSACTION #</Form.Label>
                                <Form.Control type="text" placeholder="Enter TRANSACTION #" />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="col-md-4">
                                <Form.Label>BATCH #</Form.Label>
                                <Form.Control type="text" placeholder="BATCH #" />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Select
                             </Button>
                        </Form>
                        <div className="separator separator-dashed my-7"></div>
                        <Button variant="primary">
                            Back
                        </Button>
                        </div>
                    </div>

                </div>

            </div>
    );
}


