import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;
export default function mockProducts(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetItems(id, showInactives) {
    return await fetch(
      `${window.$apiurl}/items?customerId=${id}&showInactives=${showInactives}`,
      {
        method: "get",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetItem(id) {
    return (
      await fetch(`/items/${id}`),
      {
        method: "get",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          console.log(res[1]);
          return res[1];
        })
        .catch((error) => {
          console.error(error);
        })
    );
  }

  async function PostItem(someData) {
    return fetch(window.$apiurl + "/items", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutItem(someData, id) {
    return fetch(window.$apiurl + "/items/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCustomers() {
    return await fetch(window.$apiurl + "/customers", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetItemsEdit() {
    var customersId = window.items_customersId;
    return await fetch(
      `${window.$apiurl}/items/getviewmodel?customerId=${customersId}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostManageItems(someData) {
    return fetch(window.$apiurl + "/items/import", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //---------------------------------------------------------------------------------------
  mock.onGet("api/ManageItems/Filter").reply(async () => {
    const obj = {};
    obj["customersList"] = await GetCustomers();
    return [200, obj];
  });

  mock.onPost("api/item/import").reply(async ({ data }) => {
    const itemsList = JSON.parse(data).data;
    await PostManageItems(itemsList);
    return [200, "Done"];
  });

  mock.onGet("api/ManageItems/items").reply(async () => {
    const obj = {};
    const getItemsEdit = await GetItemsEdit();
    obj["Classifications"] = getItemsEdit["Classifications"];
    obj["AllocationMethods"] = getItemsEdit["AllocationMethods"];
    obj["CycleCountTypes"] = getItemsEdit["CycleCountTypes"];
    obj["SerialNumberTracking"] = getItemsEdit["SerialNumberTracking"];
    obj["OutboundMobileSerialization"] =
      getItemsEdit["OutboundMobileSerialization"];
    obj["ExpirationDateTracking"] = getItemsEdit["ExpirationDateTracking"];
    obj["LotNumberTracking"] = getItemsEdit["LotNumberTracking"];
    obj["CostTracking"] = getItemsEdit["CostTracking"];
    obj["Countries"] = getItemsEdit["Countries"];
    obj["NMFC"] = getItemsEdit["Commodities"];
    obj["MaterialFlags"] = getItemsEdit["MaterialFlags"];
    obj["CostTracking"] = getItemsEdit["CostTracking"];
    obj["PackagingGroups"] = getItemsEdit["PackagingGroups"];
    obj["UnitsOfMeasure"] = getItemsEdit["UnitsOfMeasure"];
    obj["CountingMechanisms"] = getItemsEdit["CountingMechanisms"];
    obj["InventoryUnitsOfMeasure"] = getItemsEdit["InventoryUnitsOfMeasure"];
    obj["Items"] = getItemsEdit["Items"];
    obj["Warehouses"] = getItemsEdit["Warehouses"];
    obj["MovableUnits"] = getItemsEdit["MovableUnits"];
    obj["OrderReceiverUom"] = getItemsEdit["OrderReceiverUom"];
    obj["CustomerId"] = window.items_customersId;
    return [200, obj];
  });

  mock.onPost("api/ManageItems").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const {
      Sku = "",
      Description = "",
      SecondaryDescription,
      ClassificationId,
      CommodityId,
      Upc,
      SellPrice,
      ProductCost,
      MinOnHand,
      MaxOnHand,
      StorageTemp,
      ReorderQuantity,
      DaysBetweenCounts,
      SerialNumberRequireScan,
      ExpirationDateRequireScan,
      LotNumberRequireScan,
      HoldThresholdDays,
      OriginCountryId,
      HarmonizedTariffCode,
      MovableUnitZones,
      PackagingUnitZones,
      OverallocatedZones,
      LocationId,
      MixedExpirationDaysThreshold,
      Class,
      ShippingName,
      MaterialId,
      LabelCode,
      Flashpoint,
      PrimaryInventoryUnitOfMeasureId,
      PrimaryInventoryQuantity,
      SecondaryInventoryUnitOfMeasureId,
      SecondaryInventoryQuantity,
      PackagingUnitOfMeasureId,
      PackagingPrimaryUnit,
      PackagingLength,
      PackagingWidth,
      PackagingHeight,
      PackagingTotalArea,
      PackagingTotalVolume,
      PackagingWeight,
      PackagingNetWeight,
      MaterialNotes,
      Qualifiers,
      MovableUnitId,
      MovableUnitLength,
      MovableUnitWidth,
      MovableUnitHeight,
      MovableUnitTie,
      MovableUnitHigh,
      MovableUnitWeight,
      MovableUnitQuantity,
      CountingMechanismId,
      StorageUnitOfMeasureId,
      EachesPerEach,
      StorageRate,
      StorageMinCharge,
      StorageMonthsSkip,
      ItemMovableUnitsStorage,
      RecieveOntoHold = false,
      CycleCountTypeId = 9,
      CustomerId = 6,
      UseLocationFull = false,
      StoreWithItems = false,
      StoreMultipleLots = false,
      HazardousMaterial = false,
      UseSecondaryUom = false,
      AllocationMethodId,
      SerialNumberTrackingId = 14,
      OutboundMobileSerializationId = 19,
      ExpirationDateTrackingId = 22,
      LotNumberTrackingId = 25,
      CostTrackingId = 28,
      MaterialFlagId = 31,
      PackagingGroupId = 33,
      UnitOfMeasureId = 36,
      AssemblyItems,
    } = product;

    const id = generateProductId();
    const newProduct = {
      id,
      Sku,
      SecondaryDescription,
      ClassificationId,
      CommodityId,
      Upc,
      SellPrice,
      ProductCost,
      MinOnHand,
      MaxOnHand,
      StorageTemp,
      ReorderQuantity,
      DaysBetweenCounts,
      SerialNumberRequireScan,
      ExpirationDateRequireScan,
      LotNumberRequireScan,
      HoldThresholdDays,
      OriginCountryId,
      HarmonizedTariffCode,
      MovableUnitZones,
      PackagingUnitZones,
      OverallocatedZones,
      LocationId,
      MixedExpirationDaysThreshold,
      Class,
      ShippingName,
      MaterialId,
      LabelCode,
      Flashpoint,
      PrimaryInventoryUnitOfMeasureId,
      PrimaryInventoryQuantity,
      SecondaryInventoryUnitOfMeasureId,
      SecondaryInventoryQuantity,
      PackagingUnitOfMeasureId,
      PackagingPrimaryUnit,
      PackagingLength,
      PackagingWidth,
      PackagingHeight,
      PackagingTotalArea,
      PackagingTotalVolume,
      PackagingWeight,
      PackagingNetWeight,
      MaterialNotes,
      Qualifiers,
      MovableUnitId,
      MovableUnitLength,
      MovableUnitWidth,
      MovableUnitHeight,
      MovableUnitTie,
      MovableUnitHigh,
      MovableUnitWeight,
      MovableUnitQuantity,
      CountingMechanismId,
      StorageUnitOfMeasureId,
      EachesPerEach,
      StorageRate,
      StorageMinCharge,
      StorageMonthsSkip,
      ItemMovableUnitsStorage,
      Description,
      RecieveOntoHold,
      CycleCountTypeId,
      CustomerId,
      UseLocationFull,
      StoreWithItems,
      StoreMultipleLots,
      HazardousMaterial,
      UseSecondaryUom,
      SerialNumberTrackingId,
      OutboundMobileSerializationId,
      ExpirationDateTrackingId,
      LotNumberTrackingId,
      CostTrackingId,
      MaterialFlagId,
      PackagingGroupId,
      UnitOfMeasureId,
      AssemblyItems,
      AllocationMethodId,
    };
    await PostItem(newProduct);
    carTableMock.push(newProduct);
    return [200, { product: newProduct }];
  });

  mock.onPost("api/ManageItems/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const customersList = await GetCustomers();
    if (!(customersList.length > 0)) {
      return [400];
    }
    const customerIdDefault = queryParams.filter.customerId;
    const showInactives =
      queryParams.filter.showInactives === undefined
        ? false
        : queryParams.filter.showInactives;
    const customerId =
      customerIdDefault === undefined ? customersList[0].Id : customerIdDefault;
    window.items_customersId = customerId;
    carTableMock = await GetItems(customerId, showInactives);
    delete queryParams.filter.customerId;
    delete queryParams.filter.showInactives;
    delete queryParams.filter.ReLoadTable;

    const filterdCustomers = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdCustomers];
  });
  mock.onPost("api/ManageItems/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const customersList = await GetCustomers();
    if (!(customersList.length > 0)) {
      return [400];
    }
    const customerIdDefault = queryParams.filter.customerId;
    const showInactives =
      queryParams.filter.showInactives === undefined
        ? false
        : queryParams.filter.showInactives;
    const customerId =
      customerIdDefault === undefined ? customersList[0].Id : customerIdDefault;
    window.items_customersId = customerId;
    carTableMock = await GetItems(customerId, showInactives);
    delete queryParams.filter.customerId;
    delete queryParams.filter.showInactives;
    delete queryParams.filter.ReLoadTable;

    const filterdCustomers = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdCustomers];
  });
  mock.onPost("api/ManageItems/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageItems/updateStatusForProducts").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageItems\/(\d+)/)[1];
    const product = await GetItem(id);
    product.id = product.Id;
    if (!product) {
      return [400];
    }
    return [200, product];
  });

  mock.onPut(/api\/ManageItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageItems\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    var putObj = await PutItem(product, id);
    if (!putObj) {
      return [400];
    }
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    carTableMock[index] = { ...product };
    return [200];
  });

  mock.onDelete(/api\/ManageItems\/\d+/).reply((config) => {
    const id = config.url.match(/api\/ManageItems\/(\d+)/)[1];
    const index = carTableMock.findIndex((el) => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function generateProductId() {
  const ids = carTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
