import React, { useMemo } from "react";
import { useRemarksUIContext } from "./RemarksUIContext";

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.text = searchText;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function RemarksFilter() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      setQueryParams: remarksUIContext.setQueryParams,
      openNewRemarkDialog: remarksUIContext.openNewRemarkDialog,
      queryParams: remarksUIContext.queryParams,
    };
  }, [remarksUIContext]);


  return (
    <>
      <div className="form-filtration mb-4">
        <div className="row">
          <div className="col-md-4  margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-primary mr-2"
              onClick
            >
              Clear Rate(s)
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick
            >
              Sef Rate(s)
            </button>
          </div>
          <div className="col-md-6 margin-bottom-10-mobile align-self-center">
            <small>Clear or modify multiple rates at once by multi-selecting entries</small>
          </div>
        </div>
      </div>
    </>
  );
}
