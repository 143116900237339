import React, { useEffect, useState } from "react";
import Index from "./AssignToWareHouseFolder/Index";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import {
  LoadingDialog,
  AttachementsComponent,
} from "../../../../_metronic/_partials/controls";
import { Notification } from "../../../modules/Notification";
import Button from "@material-ui/core/Button";
import low from "lowdb";
import { RemarkEditForm } from "../../../modules/eMPower/pages/Transload/FindOrders/product-Receiving/remark-edit-dialog/RemarkEditForm.js";
import Select from "react-select";
import SelectPagination from "../../../../_metronic/_partials/widgets/Select/SelectPagination";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { SendEmail } from "./AssignToWareHouseFolder/SendEmail";

const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export function AssignToWareHousePage() {
  const [ShipmentIDStat, setShipmentIDStat] = useState([]);
  const [WareHouse, setWareHouse] = useState([]);
  const [Containers, setContainers] = useState([]);
  const [ContainerId, setContainerId] = useState([]);
  const [ShipmentsIds, setShipmentsIds] = useState([]);
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);
  const [IsClosed, setIsClosed] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [Pallets, setPallets] = useState([]);
  const [PalletsIds, setPalletsIds] = useState([]);
  const [Search, setSearch] = useState("");
  const [Locations, setLocations] = useState([]);
  const [Location, setLocation] = useState();
  const [PalletsinLocation, setPalletsinLocation] = useState([]);
  const [activeBtn, setactiveBtn] = useState(0);
  const [NewItem, setNewItem] = useState(false);
  const [EditItem, setEditItem] = useState(false);
  const [EditItemFirst, setEditItemFirst] = useState(false);
  const [SendEmailModal, setSendEmailModal] = useState(false);
  const [JsonItem, setJsonItem] = useState({});
  const [SKUsList, setSKUsList] = useState([]);

  const DestinationId = 62905;

  useEffect(() => {
    GetAllLocations(setWareHouse);
    window.openSendEmail = false;
  }, []);

  function setPalletsFun(params) {
    if (params?.Id) {
      var a = Pallets;
      var b = PalletsIds;
      params.ConsigneeName = params.ConsigneeComapanyName;
      a.unshift(params);
      b.push(params);
      setPallets([]);
      setPalletsIds([]);
      setTimeout(() => {
        setPallets(a);
        setPalletsIds(b);
      }, 1);
    }
  }

  function ClosedPallet(id, IsClosed) {
    setPallets((x) => {
      var a = x.findIndex((x) => x?.Id === id);
      if (a > -1) {
        x[a].IsClosed = !IsClosed;
      }
      return x;
    });
  }

  function saveRemark(values) {
    PostModel(values).then((e) => {
      if (e) {
        var a = ContainerId;
        setContainerId([]);
        setTimeout(() => {
          setContainerId(a);
        }, 1);
        OnHide();
      }
    });
  }
  function OnHide() {
    setNewItem(false);
  }

  //Edit Item
  function saveRemarkEdit(values) {
    PutItem(values).then((e) => {
      if (e) {
        var a = ContainerId;
        setContainerId([]);
        setTimeout(() => {
          setContainerId(a);
        }, 1);
        OnHideEdit();
      }
    });
  }
  function OnHideEdit() {
    setEditItem(false);
  }

  function OnHide2() {
    setSendEmailModal(false);
  }

  function SavePalletInLocation(pallet, location) {
    const model = {
      Id: pallet?.Id,
      CustomerId: pallet?.CustomerId,
      ConsigneeId: pallet?.ConsigneeId,
      ShipperId: pallet?.ShipperId,
      WarehouseId: pallet?.WarehouseId,
      LocationId: location,
      CheckCount: true,
    };
    PutModel(model).then((e) => {
      if (e) {
        var palletsinLocation = PalletsinLocation;
        var find = palletsinLocation.findIndex(
          (x) => x?.location?.Id === +location
        );
        if (find > -1) {
          var ids = PalletsIds;
          var index = ids.findIndex((x) => x.Id === pallet?.Id);
          if (index > -1) ids.splice(index, 1);
          if (!palletsinLocation[find].pallets)
            palletsinLocation[find].pallets = [];
          palletsinLocation[find].pallets.unshift(pallet);

          var ids2 = Pallets;
          var index2 = ids2.findIndex((x) => x.Id === pallet?.Id);
          if (index2 > -1) ids2.splice(index2, 1);

          setPalletsinLocation([]);
          setPalletsIds([]);
          setPallets([]);

          setTimeout(() => {
            setPalletsinLocation(palletsinLocation);
            setPalletsIds(ids);
            setPallets(ids2);
          }, 1);
        }
      }
    });
  }

  function RemovePalletInLocation(pallet) {
    const model = {
      Id: pallet?.Id,
      CustomerId: pallet?.CustomerId,
      ConsigneeId: pallet?.ConsigneeId,
      ShipperId: pallet?.ShipperId,
      WarehouseId: pallet?.WarehouseId,
    };
    PutModel(model).then((e) => {
      if (e) {
        var palletsinLocation = PalletsinLocation;
        palletsinLocation.forEach((element) => {
          if (!element.pallets) element.pallets = [];
          var index = element.pallets.findIndex((x) => x.Id === pallet?.Id);
          if (index > -1) element.pallets.splice(index, 1);
        });

        var pallets = Pallets;
        pallets.push(pallet);

        var palletsIds = PalletsIds;
        palletsIds.push(pallet);

        setPalletsinLocation([]);
        setPalletsIds([]);
        setPallets([]);

        setTimeout(() => {
          setPalletsinLocation(palletsinLocation);
          setPalletsIds(palletsIds);
          setPallets(pallets);
        }, 1);
      }
    });
  }

  function GetSKu(id) {
    if (id) GetItem(id, setEditItem, setEditItemFirst);
  }

  return (
    <>
      {SendEmailModal && (
        <SendEmail
          id={SendEmailModal?.Id}
          containerNumber={SendEmailModal?.containerNumber}
          show={SendEmailModal}
          onHide={OnHide2}
        />
      )}

      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="xl" show={NewItem} onHide={OnHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Item in: {window.Container} - {window?.ReceiptId}
          </Modal.Title>
        </Modal.Header>
        <RemarkEditForm
          remark={JsonItem}
          isScencty={true}
          saveRemark={saveRemark}
          onHide={OnHide}
        />
      </Modal>

      {/* <Modal
        size="sm"
        show={EditItemFirst}
        onHide={() => setEditItemFirst(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please Select Sku</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <CreatableSelect3
              name="Sku"
              label="Sku"
              options={SKUsList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Sku,
                };
              })}
              onChange={(e) => {
                var id = e?.value;
                if (id) GetItem(id, setEditItem, setEditItemFirst);
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => setEditItemFirst(false)}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal> */}

      <Modal
        size="xl"
        show={EditItemFirst}
        onHide={() => setEditItemFirst(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Item</Modal.Title>
        </Modal.Header>
        <RemarkEditForm
          remark={
            window.typeQnty === "ShortOnly"
              ? { ShortOnly: true }
              : { DamagedOnly: true }
          }
          isScencty={true}
          saveRemark={saveRemarkEdit}
          onHide={() => setEditItemFirst(false)}
          SKUsList={SKUsList}
          GetSKu={GetSKu}
        />
      </Modal>

      <Modal size="xl" show={EditItem?.Id} onHide={OnHideEdit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Item: {EditItem?.Sku} - {EditItem?.PurchaseOrder}
          </Modal.Title>
        </Modal.Header>
        <RemarkEditForm
          remark={EditItem}
          isScencty={true}
          saveRemark={saveRemarkEdit}
          onHide={OnHideEdit}
        />
      </Modal>

      <LoadingDialog isLoading={Loading} text="Loading ..." />
      <div className="card-header font-weight-bold py-0 px-0">
        <div className="form-group row mb-5">
          <div
            className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5"
            style={{ zIndex: 4 }}
          >
            {WareHouse?.length > 0 ? (
              <SelectPagination
                options={WareHouse.map((x) => {
                  return { value: x?.Id, label: x?.WarehouseName };
                })}
                name="id"
                placeholder="Select Warehouse ..."
                onChange={async (newValue) => {
                  var id = newValue?.value;
                  if (!id) return false;
                  var pallet = WareHouse.find((x) => x?.Id === id)?.Pallets;
                  var locations = WareHouse.find((x) => x?.Id === id)
                    ?.Locations;
                  GetContainers(id, 90, setContainers);
                  setPallets([]);
                  setLocations([]);
                  setTimeout(() => {
                    setPallets(pallet ?? []);
                    setLocations(locations ?? []);
                  }, 1);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Loading ...."
              />
            )}
          </div>
          <div
            className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5"
            style={{ zIndex: 4 }}
          >
            {Containers.length > 0 ? (
              <Select
                isSearchable={true}
                isClearable
                isMulti
                placeholder="Containers ..."
                options={Containers.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.EquipmentRef,
                  };
                })}
                onChange={async (newValue) => {
                  if (!Array.isArray(newValue)) {
                    setContainerId([]);
                    return false;
                  }
                  var list = [];
                  newValue.forEach((x) => {
                    list.push(x?.value);
                  });
                  setContainerId([]);
                  setTimeout(() => {
                    setContainerId(list);
                  }, 1);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Containers ..."
              />
            )}
          </div>
          <div
            className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5"
            style={{ zIndex: 3 }}
          >
            {ContainerId?.length > 0 ? (
              <input
                className="form-control"
                placeholder="Search By Sku"
                onBlur={(e) => {
                  var el1 = document.querySelector(
                    ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
                  );
                  var el2 = el1?.parentElement?.getAttribute("id");
                  setSearch(e.target.value);

                  setTimeout(() => {
                    var el3 = document.getElementById(el2);
                    if (el2 && el3) el3.click();
                  }, 500);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Search By Sku"
              />
            )}
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5">
            <div
              class="btn-group btn-group-toggle w-100"
              data-toggle="buttons"
              onClick={() => setactiveBtn(0)}
            >
              <label
                className={"btn btn-primary " + (activeBtn === 0 && "active")}
              >
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  autocomplete="off"
                  checked=""
                />
                Open
              </label>
              <label
                className={"btn btn-primary " + (activeBtn === 1 && "active")}
                onClick={() => {
                  setactiveBtn();
                  setTimeout(() => {
                    setactiveBtn(1);
                  }, 1);
                }}
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  autocomplete="off"
                />
                <i class="fas fa-lock text-white mr-2"></i> Closed Pallets
              </label>
            </div>
          </div>
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5">
            <div className="row">
              <div className="col p-1 pl-4">
                <button
                  disabled={ContainerId?.length === 0}
                  type="button"
                  className="btn btn-success w-100"
                  onClick={() => {
                    var el1 = document.querySelector(
                      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
                    );
                    window.ReceiptId = el1?.parentElement
                      ?.getAttribute("id")
                      ?.split("id")[1];
                    window.Container = el1?.parentElement?.getAttribute(
                      "data-number"
                    );
                    setJsonItem({
                      ReceiptId: 0,
                      UnitOfMeasureId: 2,
                      DestinationId: DestinationId,
                      OverOnly: true,
                      IsOsdNotification: true,
                    });
                    setNewItem(true);
                  }}
                >
                  New Item
                </button>
              </div>
              <div className="col p-1">
                <button
                  disabled={ContainerId?.length === 0}
                  type="button"
                  className="btn btn-success w-100"
                  onClick={() => {
                    var el1 = document.querySelector(
                      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
                    );
                    window.ReceiptId = el1?.parentElement
                      ?.getAttribute("id")
                      ?.split("id")[1];
                    window.typeQnty = "ShortOnly";
                    setEditItemFirst(true);
                  }}
                >
                  Short
                </button>
              </div>
              <div className="col p-1 pr-4">
                <button
                  disabled={ContainerId?.length === 0}
                  type="button"
                  className="btn btn-success w-100"
                  onClick={() => {
                    var el1 = document.querySelector(
                      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
                    );
                    window.ReceiptId = el1?.parentElement
                      ?.getAttribute("id")
                      ?.split("id")[1];
                    window.typeQnty = "DamagedOnly";
                    setEditItemFirst(true);
                  }}
                >
                  Damaged
                </button>
              </div>
            </div>
          </div>

          <div
            className={
              "col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5 " +
              (activeBtn === 0 ? "" : "d-none")
            }
            style={{ zIndex: 3 }}
          >
            {Pallets.length > 0 ? (
              <Select
                isSearchable={true}
                isClearable
                isMulti
                placeholder="Select Pallet"
                options={Pallets.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Id + " - " + x?.ConsigneeName,
                  };
                })}
                value={PalletsIds.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Id + " - " + x?.ConsigneeName,
                  };
                })}
                onChange={async (newValue) => {
                  if (!Array.isArray(newValue)) {
                    setPalletsIds([]);
                    return false;
                  }
                  var list = [];
                  newValue.forEach((x) => {
                    var a = Pallets.find((y) => y.Id === x?.value);
                    list.push(a);
                  });
                  setPalletsIds(list);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Select Pallet"
              />
            )}
          </div>
          <div
            className={
              "col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-5 " +
              (activeBtn === 1 ? "" : "d-none")
            }
            style={{ zIndex: 3 }}
          >
            {Locations.length > 0 ? (
              <Select
                isSearchable={true}
                isClearable
                isMulti
                placeholder="Select Location"
                options={Locations.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.LocationId,
                  };
                })}
                onChange={async (newValue) => {
                  if (!Array.isArray(newValue)) {
                    setPalletsinLocation([]);
                    return false;
                  }
                  var list = [];
                  for (var x of newValue) {
                    var id = x?.value;
                    var l = Locations.find((x) => x?.Id === id);
                    await GetPalletsByLocation(id).then((x) => {
                      list.push({ pallets: x, location: l });
                    });
                  }
                  setPalletsinLocation(list ?? []);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Select Location"
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body AssigntoOutboundId px-0">
        {ContainerId?.length > 0 ? (
          <>
            <Index
              ContainerId={ContainerId}
              Location={Location}
              PalletsIds={PalletsIds}
              setLoading={setLoading}
              setPalletsFun={setPalletsFun}
              ClosedPallet={ClosedPallet}
              Search={Search}
              SavePalletInLocation={SavePalletInLocation}
              RemovePalletInLocation={RemovePalletInLocation}
              PalletsinLocation={PalletsinLocation}
              activeBtn={activeBtn}
              setSendEmailModal={setSendEmailModal}
              setEditItem={setEditItem}
              setSKUsList={setSKUsList}
            />
            <br />
          </>
        ) : (
          <Nodatafound />
        )}
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-2" />
      <div className="col-3 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No data found
        </p>
      </div>
      <div className="col-3" />
    </div>
  );
}

async function GetAllLocations(setWareHouse) {
  return await fetch(`${window.$apiurl}/Pallets/GetAllPallets`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      setWareHouse(res[1] ?? []);
    })
    .catch((error) => {
      console.error(error);
    });
}

async function GetContainers(warehouseId, customerId, setContainer) {
  try {
    return await fetch(
      `${window.$apiurl}/TransloadReceipts/GetContainers?warehouseId=${warehouseId}&customerId=${customerId}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setContainer(a);
      });
  } catch (error) {}
}

async function GetPalletsByLocation(id) {
  return await fetch(
    `${window.$apiurl}/Pallets/GetPalletsByLocationClosed?locationId=${id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[1]?.length > 0) return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

async function PutModel(someData) {
  return fetch(window.$apiurl + "/Pallets/" + someData?.Id, {
    method: "put",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = statusCode === 200 ? "" : await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        Notification("success", "Pallet In Location Success");
        return true;
      } else {
        Notification("error", res[1]?.Message);
        return false;
      }
    })
    .catch((err) => {
      Notification("error", "Oops");
      return false;
    });
}

async function PostModel(someData) {
  return fetch(window.$apiurl + "/transload/receipts/lineitem", {
    method: "post",
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      return await response;
    })
    .then((res) => {
      if (!res.ok) throw res;
      Notification("success", "success");
      return true;
    })
    .catch((err) => {
      err.json().then((body) => {
        Notification("error", body?.Message);
      });
      return false;
    });
}

async function PutItem(someData) {
  someData.IsOsdNotification = true;
  return fetch(
    window.$apiurl + "/transload/receipts/lineitem?id=" + someData?.Id,
    {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    }
  )
    .then(async (response) => {
      return await response;
    })
    .then((res) => {
      if (!res.ok) throw res;
      Notification("success", "success");
      return true;
    })
    .catch((err) => {
      err.json().then((body) => {
        Notification("error", body?.Message);
      });
      return false;
    });
}

async function GetItem(id, setEditItem, setEditItemFirst) {
  return fetch(
    window.$apiurl + "/transload/receipts/getlineitems?itemId=" + id,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        var el1 = document.querySelector(
          ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
        );
        window.ReceiptId = el1?.parentElement
          ?.getAttribute("id")
          ?.split("id")[1];

        var item = res[1]["LineItems"][0];
        if (window.typeQnty === "ShortOnly")
          item = { ...item, ShortOnly: true };
        else if (window.typeQnty === "DamagedOnly")
          item = { ...item, DamagedOnly: true };
        setEditItem(item);
        setEditItemFirst(false);
      }
    })
    .catch((err) => {
      Notification("error", "Oops");
    });
}
