import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "react-querybuilder/dist/query-builder.scss";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../modules/Notification";
import low from "lowdb";
import { Spinner } from "react-bootstrap";
import { NavLink } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function QueryBuilderSetting() {
  const [ViewModel, setViewModel] = useState([]);
  const [ViewModel2, setViewModel2] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [LoadingSave, setLoadingSave] = useState(false);
  const [filter, setfilter] = useState("");

  useEffect(() => {
    GetModel(setViewModel, setViewModel2, setLoading);
  }, []);

  return (
    <>
      <Helmet>
        <title>eMPower | Assign to Pallet</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>

      <div className="card mb-5">
        <div
          className="card-header font-weight-bold py-0 pt-5"
          style={{ position: "fixed", top: "118px", zIndex: "2", width: "92%" }}
        >
          <div className="row mr-5 mb-5 pb-2">
            <div className="col-5">
              <h2 className="d-inline">Query Builder Settings </h2>
            </div>
            <div className="col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Filter By Name and Label"
                onChange={(e) => {
                  setfilter(e.target.value?.toLowerCase()?.trim());
                }}
              />
            </div>
            <div className="col-1">
              <button
                type="button"
                disabled={ViewModel.length === 0}
                onClick={() => {
                  PostModel(ViewModel2, setLoadingSave);
                }}
                className="btn btn-primary btn-elevate w-100 position-absolute"
              >
                {LoadingSave && (
                  <Spinner
                    id="InboundLoading"
                    animation="grow"
                    role="status"
                    variant="primary"
                    size="lg"
                    style={{
                      width: "30px",
                      height: "30px",
                      position: "absolute",
                      top: "3px",
                      left: "5px",
                    }}
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                Save
              </button>
            </div>
          </div>
        </div>

        <div className="card-body" style={{ marginTop: "35px" }}>
          <SettengsComponent
            ViewModel={ViewModel}
            setViewModel={setViewModel}
            ViewModel2={ViewModel2}
            setViewModel2={setViewModel2}
            Loading={Loading}
            filter={filter}
          />
        </div>
      </div>
    </>
  );
}

function SettengsComponent({
  ViewModel = [],
  setViewModel,
  setViewModel2,
  Loading = false,
  filter = "",
}) {
  return (
    <>
      <div className="row">
        <div className="col-3">
          <div className="h6 btn btn-light-primary w-100 py-1 mb-5">
            <b>Name</b>
          </div>
        </div>

        <div className="col-5">
          <div className="h6 btn btn-light-primary w-100 py-1 mb-5">
            <b>Label</b>
          </div>
        </div>

        <div className="col-2">
          <div className="h6 btn btn-light-primary w-100 py-1 mb-5">
            <b>Select Enabled</b>
          </div>
        </div>

        <div className="col-2">
          <div className="h6 btn btn-light-primary w-100 py-1 mb-5">
            <b>Group By Enabled</b>
          </div>
        </div>

        <div className="col-12 text-center">
          {Loading && (
            <Spinner
              id="InboundLoading"
              animation="grow"
              role="status"
              variant="primary"
              size="lg"
              style={{ width: "200px", height: "200px" }}
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
        </div>

        {ViewModel.filter(
          (x) =>
            x.Label.toLowerCase()?.includes(filter) ||
            x.Name.toLowerCase()?.includes(filter)
        ).map((x, index) => (
          <Fragment key={x?.Name + "" + x?.Id}>
            <div className="col-3 h6 text-primary">
              <b>{x?.Name}</b>
            </div>
            <div className="col-5 mb-5">
              <input
                type="text"
                className="form-control"
                defaultValue={x?.Label}
                onChange={(event) => {
                  ViewModel[index].Label = event.target?.value;
                  setViewModel(ViewModel);

                  setViewModel2([]);
                  setTimeout(() => {
                    setViewModel2(ViewModel);
                  }, 1);
                }}
              />
            </div>
            <div className="col-2 mt-2">
              <div className="d-flex align-items-center">
                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 m-auto">
                  <input
                    type="checkbox"
                    name="IsActive"
                    defaultChecked={x?.SelectEnabled}
                    onChange={(event) => {
                      ViewModel[index].SelectEnabled =
                        event.target?.checked ?? false;
                      setViewModel(ViewModel);

                      setViewModel2([]);
                      setTimeout(() => {
                        setViewModel2(ViewModel);
                      }, 1);
                    }}
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <div className="col-2 mt-2">
              <div className="d-flex align-items-center">
                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 m-auto">
                  <input
                    type="checkbox"
                    name="IsActive"
                    defaultChecked={x?.GroupByEnabled}
                    onChange={(event) => {
                      ViewModel[index].GroupByEnabled =
                        event.target?.checked ?? false;
                      setViewModel(ViewModel);

                      setViewModel2([]);
                      setTimeout(() => {
                        setViewModel2(ViewModel);
                      }, 1);
                    }}
                  />
                  <span></span>
                </label>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
    </>
  );
}

function GetModel(setViewModel, setViewModel2, setLoading) {
  setLoading(true);
  fetch(`${window.$apiurl}/QueryBuilder/GetViewModel`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      setViewModel(res[1]);
      setViewModel2(res[1]);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
}

function PostModel(model, setLoadingSave) {
  setLoadingSave(true);
  fetch(`${window.$apiurl}/QueryBuilder/UpdateSettings`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Settings: model }),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      if (statusCode === 200) {
        Notification("success", "Success");
        setLoadingSave(false);
      }
    })
    .catch((error) => {
      Notification("error", "error");
      setLoadingSave(false);
    });
}
