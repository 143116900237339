// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Input,
  RadioButton,
} from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
// Validation schema
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '2rem'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const classes = useStyles();
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Code: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Code is required"),

      MaterialTypeId: Yup.string()
        .required("Materiel is required"),

      LoadTypeId: Yup.string()
        .required("LoadType is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  const [isActive, setisActive] = useState(false);
  const [isActiveLoadType, setisisActiveLoadType] = useState(false);
  useEffect(() => {
    if (customer.MaterialTypeId !== undefined) {
      setisActive(customer.MaterialTypeId + "");
    }
    if (customer.LoadTypeId !== undefined) {
      setisisActiveLoadType(customer.LoadTypeId + "");
    }
    
  }, [customer]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-5">
                    <div className="code-description mb-4">
                      <Field
                        name="Code"
                        component={Input}
                        placeholder="Code"
                        margin="normal"
                      />
                    </div>
                    <div className="field-description">
                      <Field
                        name="Description"
                        component={Input}
                        placeholder="Description"
                        margin="normal"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <RadioButton name="MaterialTypeId" label="Materiel" row
                      value={isActive}
                      onChange={(e) => {
                        setisActive(e.target.value);
                        setFieldValue("MaterialTypeId", e.target.value);
                      }}
                    >
                      <FormControlLabel
                        value="1"
                        name="MaterialTypeId"
                        control={<Radio color="primary" />}
                        label="Wood"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="2"
                        name="MaterialTypeId"
                        control={<Radio color="primary" />}
                        label="Plastic"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="3"
                        name="MaterialTypeId"
                        control={<Radio color="primary" />}
                        label="Metal"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="4"
                        name="MaterialTypeId"
                        control={<Radio color="primary" />}
                        label="Cardboard"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="5"
                        name="MaterialTypeId"
                        control={<Radio color="primary" />}
                        label="Other"
                        labelPlacement="start"
                      />
                    </RadioButton>
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-lg-12">
                    <FormLabel component="legend">labelPlacement</FormLabel>
                    <RadioButton name="LoadTypeId" label="LoadType" row
                    value={isActiveLoadType}
                    onChange={(e) => {
                      setisisActiveLoadType(e.target.value);
                      setFieldValue("LoadTypeId", e.target.value);
                    }}
                    >
                      <FormControlLabel
                        value="1"
                        control={<Radio color="primary" />}
                        label="Product is stacked on top of this (like a pallet)"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio color="primary" />}
                        label="Product is put inside of this (like a container)"
                        labelPlacement="end"
                      />
                    </RadioButton>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 d-flex">
                    <div className="cost-field mr-4">
                      <Field
                        name="Cost"
                        component={Input}
                        placeholder="COST"
                        margin="normal"
                        type="number"
                      />
                    </div>
                    <div className="rate-description">
                      <Field
                        name="Rate"
                        component={Input}
                        placeholder="RATE"
                        margin="normal"
                        type="number"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Paper className={classes.root}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell
                              align="right"
                              style={{
                                textAlign: 'center'
                              }}>LENGHT</TableCell>

                            <TableCell
                              align="right"
                              style={{
                                textAlign: 'center'
                              }}>WIDTH</TableCell>

                            <TableCell
                              align="right"
                              style={{
                                textAlign: 'center'
                              }}
                            >HEIGHT</TableCell>
                            <TableCell
                              align="right"
                              style={{
                                textAlign: 'center'
                              }}
                            >WEIGHT</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow >
                            <TableCell component="th" scope="row">
                              US/IMPERIAL
                              </TableCell>
                            <TableCell align="right">
                              <Field
                                name="LengthUs"
                                component={Input}
                                placeholder="Lenght"
                                margin="normal"
                                type="number"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Field
                                name="WidthUs"
                                component={Input}
                                placeholder="Width"
                                margin="normal"
                                type="number"
                              />
                            </TableCell>
                            <TableCell align="right"><Field
                              name="HeightUs"
                              component={Input}
                              placeholder="Height"
                              margin="normal"
                              type="number"
                            />
                            </TableCell>
                            <TableCell align="right"><Field
                              name="WeightUs"
                              component={Input}
                              placeholder="Weight"
                              margin="normal"
                              type="number"
                            />
                            </TableCell>
                          </TableRow>
                          {/* Table two */}
                          <TableRow >
                            <TableCell component="th" scope="row">
                              METRIC
                              </TableCell>
                            <TableCell align="right">
                              <Field
                                name="Length"
                                component={Input}
                                placeholder="Lenght"
                                margin="normal"
                                type="number"
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Field
                                name="Width"
                                component={Input}
                                placeholder="Width"
                                margin="normal"
                                type="number"
                              />
                            </TableCell>
                            <TableCell align="right"><Field
                              name="Height"
                              component={Input}
                              placeholder="Height"
                              margin="normal"
                              type="number"
                            />
                            </TableCell>
                            <TableCell align="right"><Field
                              name="Weight"
                              component={Input}
                              placeholder="Weight"
                              margin="normal"
                              type="number"
                            />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Paper>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
