import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(id, actions, customers, description, servername, skipoverallocs, type) {
    return { id, actions, customers, description, servername, skipoverallocs, type };
}

const rows = [
    createData(),
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));

export function ManageConnections() {
    const classes = useStyles();

    return (

        <Paper className={classes.root}>
            <div className="col-lg-3 my-5">
                <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                // TODO: Change this code
                >
                    <option value="">All Customers</option>
                    <option value="0">Customers1</option>
                    <option value="1">Customers2</option>
                </select>
                <small className="form-text text-muted">
                    <b>Filter</b> by Customer
            </small>
            </div>
            <div className="col-lg-8 my-5">
                <span>
                    Warning! When deleting a connection, it is important to reconcile this change with your monthly billing. For more information, contact 3PL Central by email at accounting@3plcentral.com, or call us at 01 888-375-2368.
              </span>
            </div>
            <Table className={`mx-4 col-md-8 mb-5 ${classes.table}`}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Id</StyledTableCell>
                        <StyledTableCell >Actions</StyledTableCell>
                        <StyledTableCell >Customers</StyledTableCell>
                        <StyledTableCell>Description</StyledTableCell>
                        <StyledTableCell >Servername</StyledTableCell>
                        <StyledTableCell >Skipoverallocs</StyledTableCell>
                        <StyledTableCell >Type</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell>{row.id}</StyledTableCell>
                            <StyledTableCell>{row.actions}</StyledTableCell>
                            <StyledTableCell>{row.customers}</StyledTableCell>
                            <StyledTableCell>{row.description}</StyledTableCell>
                            <StyledTableCell>{row.servername}</StyledTableCell>
                            <StyledTableCell>{row.skipoverallocs}</StyledTableCell>
                            <StyledTableCell >{row.type}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}