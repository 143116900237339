// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Dropdown } from "react-bootstrap";
import { DropdownMenu2 } from "../../../../../../_metronic/_partials/dropdowns";
import {
  Input,
  Select,
  RadioButton,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";

  // Validation schema
  const ReceiptEditSchema = Yup.object().shape({
    Rate: Yup.string()
      .required("Rate is required"),
  });
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const classes = useStyles();
  const [value, setValue] = useState('female');

  function handleChange(event) {
    setValue(event.target.value);
  }
  const [viewModelDataLabels, setViewModelLabels] = useState([]);
  const [viewModelDataUnits, setViewModelUnits] = useState([]);
  const [isManual, setisActive] = useState(false);
  useEffect(() => {
    fetchOrderAsync();
  }, []);

  useEffect(() => {
    if (customer.IsManual !== "") {
      var a = customer.IsManual ? "true" : "false";
      setisActive(a);
    }
  }, [customer]);
  const fetchOrderAsync = async () => {
    try {
      const result = await axios.get("api/TransactionBillingShipping/GetAllApi");
      setViewModelLabels(result.data["viewModelLabels"].Labels);
      setViewModelUnits(result.data["viewModelUnits"].Units);
    } catch (e) { }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={ReceiptEditSchema}
        onSubmit={(values) => {
          values.id = values.Id;
          saveCustomer(values);
        }}
      >
        {({ handleSubmit ,setFieldValue}) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="border-0 container pt-3">
                    <h5 className="card-title  text-black">Shipping: Handling Billing Wizard</h5>
                    <p>handling charges, such as by container, sorts, postal fees</p>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Select name="Label" label="Charge Label">
                      <option key ="" value="">Select</option>
                      {viewModelDataLabels.map((item, index) => (
                        <option key={index} value={item.Name}>{item.Name}</option>
                      ))}
                    </Select>
                    <small className="form-text text-muted">
                      (select from list, or type your own)
                  </small>
                  </div>
                  <div className="col-lg-6">
                    <Select name="Unit" label="Unit">
                      <option key ="" value="">Select</option>
                      {viewModelDataUnits.map((item, index) => (
                        <option key={index} value={item.Name}>{item.Name}</option>
                      ))}
                    </Select>
                    <small className="form-text text-muted">
                      (select from list, or type your own)
                  </small>
                  </div>
                  <div className="col-lg-12 mt-5">
                    {/* ZoneName */}
                    <RadioButton name="IsManual" label="Billing Type" row
                    value={isManual}
                    onChange={(e) => {
                      setisActive(e.target.value);
                      setFieldValue("IsManual", e.target.value);
                    }}
                    >
                      <FormControlLabel
                        value="false"
                        name="IsManual"
                        control={<Radio color="primary" />}
                        label="Auto"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="true"
                        name="IsManual"
                        control={<Radio color="primary" />}
                        label="Manual"
                        labelPlacement="start"
                      />
                    </RadioButton>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      type="number"
                      name="Rate"
                      component={Input}
                      placeholder="Charge per unit"
                      label="Charge per unit"
                    />
                    <small className="form-text text-muted">
                      (as a numeric value)
                  </small>
                  </div>

                  <div className="col-lg-4">
                    {/* ENDZIP */}
                    <Field
                      type="Notes"
                      name="Notes"
                      component={Input}
                      placeholder="Notes"
                      label="Notes"
                    />
                  </div>
                  <div className="col-lg-4">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="ItemId"
                      component={Input}
                      placeholder="Item ID"
                      label="Item ID"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="Description"
                      component={Input}
                      placeholder="Item Description"
                      label="Item Description"
                    />
                  </div>

                  <div className="col-lg-4">
                    {/* ENDZIP */}
                    <Field
                      type="text"
                      name="ArAcct"
                      component={Input}
                      placeholder="AR Account"
                      label="AR Account"
                    />
                  </div>
                  <div className="col-lg-4">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="GlAcctNumber"
                      component={Input}
                      placeholder="G.L. Acct Num"
                      label="G.L. Acct Num"
                    />
                    <small className="form-text text-muted">
                    (leave blank to use the Default)
                  </small>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
