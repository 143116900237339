import React from 'react'
import { Button, Form, Card, Dropdown } from "react-bootstrap";

export function ClassicBOLTemplates() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <div class='col-6'>
                            <Form.Label>Choose a Customer</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Barkman Honey</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">BASF</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Canusa Wood Products (c5800)</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">DDP Specialty Electronic</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Delmar International (NY) Inc.</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                    </Form.Row>
                </Form>

                <table class="table mt-5">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ID</th>
                            <th scope="col">Description</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <div class='row'>
                <div class='col-2'>
                        <Button as="input" type="submit" value="Add" />
                    </div>
                    <div class='col-2'>
                        <Button as="input" type="submit" value="Edit" />
                    </div>

                    <div class='col-2'>
                        <Button as="input" type="submit" value="Delete" />
                    </div>
                </div>

            </Card.Body>
        </Card>
    )
}
