import MockUtils from "./mock.utils";
import $ from "jquery";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function ManageRolesTableMock(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetRoles() {
    return await fetch(window.$apiurl + "/roles", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetRole(id) {
    return await fetch(window.$apiurl + "/roles/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostRole(someData) {
    return fetch(window.$apiurl + "/roles", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  async function PutRole(someData, id) {
    // debugger;
    return fetch(window.$apiurl + "/roles/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (result) => {
        if (result.status === 400) {
          Notification("error", "A Role Template can't be updated.");
        } else {
          Notification("success", "Role has been updated successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteManageLocation(id) {
    return await fetch(window.$apiurl + "/roles/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (result) => {
        if (result.status === 400) {
          Notification("error", "A Role Template can't be deleted");
        } else {
          Notification("success", "Success");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetRolesChecbox() {
    return await fetch(window.$apiurl + "/roles/getviewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  // Mock ---------------------------------------------------------------------------------

  //Get list Checkbox
  //api/Roles/GetAllApi
  mock.onGet("api/Roles/GetAllApi").reply(async () => {
    const obj = {};
    obj["GetRolesChecbox"] = await GetRolesChecbox();
    return [200, obj];
  });

  // new role
  mock.onPost("api/manageRole").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Name = "",
      RoleTypeId = 1,
      RolePermissions = [],
      Created = Date.now(),
    } = customer;
    const id = generateUserId();
    const newRole = {
      id,
      Name,
      RoleTypeId,
      RolePermissions,
      Created,
    };
    var postRole = await PostRole(newRole);
    //Manage Data Result
    if (postRole===undefined) {
      document.getElementById("ReLoadTableId").click();
      Notification("success", "Role has been added successfully");
      return [
        200,
        { customer: newRole, message: postRole.Message, success: true },
      ];
    } else {
      if (postRole[1].Message) {
        Notification("error", postRole[1].Message);
        document.getElementById("ReLoadTableId").click();
        return [400, { message: postRole[1].Message, success: false }];
      } else {
        Notification("error", "Error occured");
        document.getElementById("ReLoadTableId").click();
        return [400, { message: postRole[1], success: false }];
      }
    }
  });
  mock.onGet("api/manageRole/Name").reply(async () => {
    const obj = {};
    obj["NameList"] = await GetRoles();
    return [200, obj];
  });
  //get roles list
  mock.onPost("api/manageRole/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetRoles();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/manageRole/deleteCustomer").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/manageRole/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/manageRole\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/manageRole\/(\d+)/)[1];
    const customer = await GetRole(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  //edit
  mock.onPut(/api\/manageRole\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/manageRole\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putRole = {
      Id: customer.Id,
      Name: customer.Name,
      RoleTypeId: customer.RoleTypeId,
      RolePermissions: customer.RolePermissions,
    };
    await PutRole(putRole, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  //delete
  mock.onDelete(/api\/manageRole\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/manageRole\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteManageLocation(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    $("#Id option[value=" + id + "]").remove();
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
