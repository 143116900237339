import React from "react";


export function CustomersFilter({ listLoading }) {
  // Customers UI Context


  return (
    <div className="col-md-12 mb-5">
      <h2 class="text-dark-50 m-0 font-weight-normal">
        3PL SETTINGS
      </h2>
      <div class="pl-2">
        <label class="text-dark-50 m-0 pt-5 font-weight-normal" for="check-print">
          Print ZPL by Default
        </label>
        <input type="checkbox" class="ml-2" name="check-print" id="check-print" />
      </div>
      <h2 class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
        CARRIER SETTINGS
      </h2>
      <span class="text-dark-50 m-0 pt-5 pb-4 font-weight-normal pl-2">
        Click on the 'pencil' to edit the carrier settings
      </span>
    </div>
  );
}