import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Form } from "react-bootstrap";
import { Helmet } from "react-helmet";

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(id, customerName, warehouse, po, customer, created, issued, closed) {
    return { id, customerName, warehouse, po, customer, created, issued, closed };
}

const rows = [
    createData(1, 'PhatMojo, LLc', 'Mitco Global- Downey', 3514, 'PhatMojo, LLc', '2020/06/23 13:05', '', 'no'),
    createData(5, 'EDL TEST', 'Mitco Global- Downey', 2459, 'PhatMojo, LLc', '2020/06/23 13:05', '', 'no'),
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));


export function FindPurchaseOrders({ history }) {
    const classes = useStyles();

    const backToPurchaseOrderReports = () => {
        history.push(`/PurchaseOrderReports`);
    };

    const [value, setValue] = React.useState('open');
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <>
        <Helmet>
        <title>eMPower | Purchase Report</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
        <Paper className={classes.root}>
            <div className="col-lg-12">
                <div className="col-8 my-5">
                    <h3>CHOOSE WHICH "PURCHASE ORDERS" TO DISPLAY</h3>
                </div>
                <div className="my-5">
                    <Table className={`mx-4 col-md-8 mb-5 ${classes.table}`}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>#</StyledTableCell>
                                <StyledTableCell>CUSTOMER NAME</StyledTableCell>
                                <StyledTableCell>WAREHOUSE</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell></StyledTableCell>
                                    <StyledTableCell>{row.customerName}</StyledTableCell>
                                    <StyledTableCell>{row.warehouse}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>

                    <div className="col-lg-8 my-5">
                        <p>Press 'Shift' then 'Left Mouse Button' to select a range.</p>
                        <p>Press 'Ctrl' then 'Left Mouse Button' to select a non-sequential range.</p>
                        <p className="pt-5">After selecting, press 'Refresh'.</p>
                    </div>
                </div>
                <p></p>
            </div>

            <Form className="form form-label-right">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group row">

                            <div className="col-lg-3">
                                Filter by Date Range
                            <TextField
                                    id="date"
                                    type="date"
                                    defaultValue="2021-05-24"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="Begin"
                                />
                            </div>
                            <div className="col-lg-3">
                                <br></br>
                                <TextField
                                    id="date"
                                    type="date"
                                    defaultValue="2021-05-24"
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    label="End"
                                />
                            </div>
                            <div className="col-lg-3">
                                Filter by PO Number
                            <TextField
                                    id="end"
                                    label="End"
                                    className={classes.textField}
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-lg-3">
                                <br></br>
                                <TextField
                                    id="end"
                                    label="End"
                                    className={classes.textField}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-8">
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Filter by Status</FormLabel>
                                    <RadioGroup aria-label="filterStatus" name="filterStatus" value={value} onChange={handleChange}>
                                        <FormControlLabel value="open" control={<Radio />} label="Open" />
                                        <FormControlLabel value="closed" control={<Radio />} label="Closed" />
                                        <FormControlLabel value="both" control={<Radio />} label="Both" />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <button
                                    type="button"
                                    className="btn btn-success btn-shadow-hover mr-2"
                                >
                                    Refresh Grid
                  </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>

            <div className="col-lg-12">
                <Table className={`mx-4 col-md-8 mb-5 mt-5 ${classes.table}`}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell>P.O. #</StyledTableCell>
                            <StyledTableCell>CUSTOMER</StyledTableCell>
                            <StyledTableCell>CREATED</StyledTableCell>
                            <StyledTableCell>ISSUED</StyledTableCell>
                            <StyledTableCell>CLOSED</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(row => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell></StyledTableCell>
                                <StyledTableCell>{row.id}</StyledTableCell>
                                <StyledTableCell>{row.po}</StyledTableCell>
                                <StyledTableCell>{row.customer}</StyledTableCell>
                                <StyledTableCell>{row.created}</StyledTableCell>
                                <StyledTableCell>{row.issued}</StyledTableCell>
                                <StyledTableCell>{row.closed}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <Container className="mb-5">
                    <button
                        type="button"
                        className="btn btn-success btn-shadow-hover mr-2"
                    >
                        PO Report
                  </button>
                    <button
                        type="button"
                        className="btn btn-success btn-shadow-hover mr-2"
                    >
                        PO Progress
                  </button>
                    <button
                        type="button"
                        className="btn btn-success btn-shadow-hover mr-2"
                    >
                        ReOpen PO
                  </button>
                    <button
                        type="button"
                        onClick={backToPurchaseOrderReports}
                        className="btn btn-success btn-shadow-hover mr-2"
                    >
                        Close
                  </button>
                </Container>
            </div>
        </Paper >
        </>
    );
}