import React, { useState } from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";
import { ProductTMSDetail } from "../ManageTMS/product-detail/ProductDetail";
import { ProductMODetail } from "../ManageTMS/master-order-detail/ProductDetail";

export function CapacityBoardPageTab({ history }) {
  const [NewModal, setNewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [MODetail, setMODetail] = useState(false);
  const [CBDetail, setCBDetail] = useState(true);

  const [DeleteModal, setDeleteModal] = useState(false);
  const customersUIEvents = {
    newCustomerButtonClick: () => {
      setNewModal(true);
    },
    openEditCustomerDialog: (id) => {
      setEditModal(id);
    },
    openDeleteCustomerDialog: (id) => {
      setDeleteModal(id);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/TransportManagementSystems/CapacityBoard/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/TransportManagementSystems/CapacityBoard/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/TransportManagementSystems/CapacityBoard/updateStatus");
    },
    openDetailsCustomerDialog: (id) => {
      history.push(`/PalletsManagment/${id}/details`);
    },
  };
  console.log("NewModal", NewModal);
  console.log("EditModal", EditModal);
  console.log("MODetail", MODetail);
  console.log("DeleteModal", DeleteModal);
  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <input
        type="hidden"
        id="openCapacityBoardPage2"
        onClick={(e) => {
          setTimeout(() => {
            setEditModal(false);
            setMODetail(false);
            setCBDetail(true);
          }, 1);
        }}
      />
      <input
        type="hidden"
        id="openMODetailPage2"
        onClick={(e) => {
          setEditModal(false);
          setCBDetail(false);
          setMODetail(e?.target?.value);
        }}
      />
       <input
        type="hidden"
        id="openTMSDetailPage2"
        onClick={(e) => {
          setEditModal(e?.target?.value);
          setCBDetail(false);
          setMODetail(false);
        }}
      />
      <CustomersLoadingDialog />
      {NewModal && (
        <CustomerEditDialog
          show={NewModal}
          onHide={() => {
            setNewModal(false);
          }}
        />
      )}
      {EditModal && (
        <ProductTMSDetail SO={EditModal} setEditModal={setEditModal} />
      )}
      {MODetail && <ProductMODetail MO={MODetail} setMODetail={setMODetail} />}

      {!MODetail && !EditModal && !DeleteModal && !NewModal && (
        <CustomersCard />
      )}

      {DeleteModal && (
        <CustomerDeleteDialog
          show={DeleteModal}
          id={DeleteModal}
          onHide={() => {
            setDeleteModal(false);
          }}
        />
      )}

      {/* <Route path="/PalletsManagment/new">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/:id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/deleteCustomers">
        {({ history, match }) => (
          <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/:id/delete">
        {({ history, match }) => (
          <CustomerDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route>
      <Route path="/PalletsManagment/:id/details">
        {({ history, match }) => (
          <CustomerDetailsDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/PalletsManagment");
            }}
          />
        )}
      </Route> */}
    </CustomersUIProvider>
  );
}
