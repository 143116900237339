
import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
let customerTableMock;
export default function mockMovableUnits(mock) {

  //Status Message Result
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  //Get Movable Units
  async function GetMovableUnitss() {
    return await fetch(window.$apiurl+"/movableunits")
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Get {id}
  async function GetMovableUnitsById(id) {
    return await fetch(window.$apiurl+"/movableunits/" + id, {
      method: "get",
    })
      .then(async (response) => {
        const data = await response.json();
        var result = {
            Code: data.Code,
            Description: data.Description,
            Height: data.Height,
            HeightUs: data.HeightUs,
            Length: data.Length,
            LengthUs: data.LengthUs,
            LoadTypeId: data.LoadTypeId,
            LoadType:data.LoadType,
            MaterialTypeId: data.MaterialTypeId,
            Materiel:data.Materiel,
            Rate: data.Rate,
            Cost : data.Cost,
            Weight: data.Weight,
            WeightUs: data.WeightUs,
            Width: data.Width,
            WidthUs: data.WidthUs,
            id: data.Id
        }
        return result;
      });
  }
  //Post Movable Units
  async function PostMovableUnit(someData) {
    return fetch(window.$apiurl+"/movableunits", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Delete
  async function DeleteMovableUnit(id) {
    return await fetch(window.$apiurl+"/movableunits/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //Put 
  async function PutMovableUnit(someData, id) {
    return fetch(window.$apiurl+"/movableunits/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  mock.onPost("api/MovableUnits").reply(async({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Code = "",
      Description = "",
      MaterialTypeId = "",
      Material = "",
      LoadType = "",
      LoadTypeId = "",
      Cost = "",
      Rate = "",
      Length = "",
      id = 0,
      Width = "",
      Height = "",
      Weight = "",
      LengthUs = "",
      WidthUs = "",
      HeightUs = "",
      WeightUs = "",
    } = customer;

    const Id = generateUserId();
    const newCustomer = {
      Id,
      Code,
      Description,
      MaterialTypeId,
      Material,
      LoadTypeId,
      LoadType,
      Cost,
      Rate,
      Length,
      Width,
      Height,
      Weight,
      LengthUs,
      WidthUs,
      HeightUs,
      WeightUs
    };
    const result = {
      id,
      Code,
      Description,
      Material,
      LoadType,
    };
    var postMovableUnit = await PostMovableUnit(newCustomer);
    switch (newCustomer.MaterialTypeId) {
      case '1':
        result.Material = 'Wood';
        break;
      case '2':
        result.Material = 'Plastic';
        break;

      case '3':
        result.Material = 'Metal';
        break;

      case '4':
        result.Material = 'Cardboard';
        break;

      case '5':
        result.Material = 'Other';
        break;
    }
    switch (newCustomer.LoadTypeId) {
      case '1':
        result.LoadType = 'OnTopOf';
        break;
      case '2':
        result.LoadType = 'InsideOf';
        break;
    }
    result.id = postMovableUnit.Id;
    customerTableMock.push(result);
    return [200, { customer: result }];
  });

  
  mock.onPost("api/MovableUnits/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetMovableUnitss();
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/MovableUnits/deleteCustomers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = customerTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/MovableUnits/updateStatusForCustomers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/MovableUnits\/\d+/).reply(async config => {
    const id = config.url.match(/api\/MovableUnits\/(\d+)/)[1];
    var resultById = await GetMovableUnitsById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/MovableUnits\/\d+/).reply(async config => {
    const id = config.url.match(/api\/MovableUnits\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutMovableUnit(customer, id);
    if (!putObj) {
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/MovableUnits\/\d+/).reply(async config => {
    const id = config.url.match(/api\/MovableUnits\/(\d+)/)[1];
    const index = customerTableMock.findIndex(el => el.id === +id);
    var deleteObj = await DeleteMovableUnit(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}