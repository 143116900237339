/* eslint-disable react-hooks/exhaustive-deps */
// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import {
  IconButton,
  Input,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select from "react-select";
import {
  sortCaret,
  toAbsoluteUrl,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CheckboxLargeWB,
  DenseTableSm,
  LoadingDialog,
} from "../../../../../../_metronic/_partials/controls";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../Notification";
import { Field } from "formik";
import axios from "axios";
import {
  currencyFormat,
  DateTime30GL,
  RequiredHtml,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import moment from "moment";
import { BillToRating, VendorRating } from "../Charges";
import { OrderAttachementsV2 } from "../../Transload/FindOrders/product-edit/OrderAttachements";
import SVG from "react-inlinesvg";
var Spinner2 = require("react-spinkit");
const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export function ServiceOrderLtlDetail({
  setFieldValue,
  values,
  setTab,
  setTextMasterOrder,
  statSHipmentId,
  setCharges,
  setLoaderSo,
  setVendorPayables,
  charges,
  loaderSo,
  vendorPayables,
  ModeSO,
  SO,
  carriers = [],
  getAssignment,
  initProduct,
  setinitProduct,
  ViewModelOptions,
  GetViewModel,
  GetSoData
}) {
  const classes = useStyles();

  const [LoadingPost, setLoadingPost] = useState(false);
  useEffect(() => {
    if (SO) {
      GetItemsTms(SO);
    }
  }, [SO]);

  const Mode = [{ label: "LTL" }, { label: "TL" }, { label: "Intermodal" }].map(
    (Mode) => ({
      value: Mode.label,
      label: Mode.label,
    })
  );
  const [CreateSO, setCreateSO] = useState();
  const [isLinearProgress, setIsLinearProgress] = useState(false);
  const [loaderData, setLoaderData] = React.useState(false);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [modalAddresses, setmodalAddresses] = useState(false);
  const [IsShipperSelected, setIsShipperSelected] = useState(false);
  const [Country, setCountry] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  const [stateId, setStateId] = React.useState({
    value: "",
    label: "Select",
  });
  const [isCollapsedReceiving, setIsCollapsedReceiving] = useState(false);
  const [isCollapsedShipping, setIsCollapsedShipping] = useState(false);
  const [isCollapsedCarrier, setIsCollapsedCarrier] = useState(false);
  const [isCollapsedCommodity, setIsCollapsedCommodity] = useState(false);
  const [isCollapsedInvoicing, setIsCollapsedInvoicing] = useState(false);
  const [isCollapsedAttachments, setIsCollapsedAttachments] = useState(false);

  const [AddressObjectPost, setAddressObjectPost] = useState({
    Name: "",
    Address: "",
    City: "",
    State: "",
    PostalCode: "",
    CountryId: "US",
    Phone: "",
    Fax: "",
    Note: "",
    AddressTypeId: null,
    IsActive: true,
    Charges: [],
    ContactName: "",
    Email: "",
    CustomerId: null,
  });

  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || []);
    } catch (e) {}
  };

  const handleCountryChange = async (event) => {
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
              return null;
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
  };
  //Fetch Dynamic Address
  const [consignee, setConsignee] = useState({
    ConsigneeId: 0,
    ConsigneeAddress: "",
    ConsigneeCity: "",
    ConsigneeState: "",
    ConsigneeZipCode: "",
    ConsigneePhoneNumber: "",
    ConsigneeFax: "",
    ConsigneeContactName: "",
    ConsigneeEmail: "",
    ReceivingNote: "",
  });
  const [shipper, setShipper] = useState({
    ShipperId: 0,
    ShipperAddress: "",
    ShipperCity: "",
    ShipperState: "",
    ShipperZipCode: "",
    ShipperPhoneNumber: "",
    ShipperFax: "",
    ShipperContactName: "",
    ShipperEmail: "",
    ShippingNote: "",
  });
  async function fetchSelectedAddress(id, isShipper) {
    try {
      const result = await GetShipperAddress(id);
      var init = { ...initProduct };

      if (isShipper) {
        setFieldValue("ShipperCity", result?.City);
        setFieldValue("ShipperState", result?.State);
        setFieldValue("ShipperId", result?.Id);
        setFieldValue(
          "ShippingNote",
          values.ShippingNote === "" || values.ShippingNote === null
            ? result?.Note
            : values.ShippingNote
        );
        let currentAddress = { ...shipper };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
          ShippingNote:
            values.ShippingNote === "" || values.ShippingNote === null
              ? result?.Note
              : values.ShippingNote,
        };
        init.ShipperCity = result?.City;
        init.ShipperState = result?.State;
        init.ShipperId = result?.Id;
        //setinitProduct(init);
        await getAssignment(
          {
            CustomerId: values.CustomerId ?? null,
            ShipperCity: result?.City,
            ShipperState: result?.State,
            ConsigneeCity: values.ConsigneeCity,
            ConsigneeState: values.ConsigneeState,
          },
          initProduct,
          true
        );
        setShipper(currentAddress);
      } else {
        setFieldValue("ConsigneeCity", result?.City);
        setFieldValue("ConsigneeState", result?.State);
        setFieldValue("ConsigneeId", result?.Id);
        setFieldValue(
          "ReceivingNote",
          values.ReceivingNote === "" || values.ReceivingNote === null
            ? result?.Note
            : values.ReceivingNote
        );
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
          ReceivingNote:
            values.ReceivingNote === "" || values.ReceivingNote === null
              ? result?.Note
              : values.ReceivingNote,
        };
        init.ConsigneeCity = result?.City;
        init.ConsigneeState = result?.State;
        init.ConsigneeId = result?.Id;
        //setinitProduct(init);
        setConsignee(currentAddress);
        await getAssignment(
          {
            CustomerId: values.CustomerId ?? null,
            ShipperCity: values?.ShipperCity,
            ShipperState: values?.ShipperState,
            ConsigneeCity: values.ConsigneeCity,
            ConsigneeState: values.ConsigneeState,
          },
          initProduct,
          true
        );
      }
    } catch (err) {}
  }
  async function GetShipperAddress(id) {
    return await fetch(window.$apiurl + `/Tms/Address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          return result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function GetAttachmentsViewModel() {
    fetch(window.$apiurl + `/api/shipmentviewmodel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setAttachmentTypes(res[1]?.AttachmentTypes || []);
        setAccessLevels(res[1]?.AccessLevels || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function fetchSelectedAddressOnLoad(id, isShipper) {
    try {
      const result = await GetShipperAddress(id);
      if (isShipper) {
        let currentAddress = { ...shipper };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
        };
        setShipper(currentAddress);
      } else {
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
        };
        setConsignee(currentAddress);
      }
    } catch (err) {}
  }
  useEffect(() => {
    fetchSelectedAddressOnLoad(values.ShipperId, true);
  }, [values.ShipperId]);
  useEffect(() => {
    fetchSelectedAddressOnLoad(values.ConsigneeId, false);
  }, [values.ConsigneeId]);
  //******************************************************* */
  useEffect(() => {
    fetchViewModelAsync();
    GetAttachmentsViewModel();
  }, []);
  function postAddress() {
    setLoadingPost(true);
    let value = { ...AddressObjectPost };
    value.AddressTypeId = IsShipperSelected === true ? 11 : 5;
    return fetch(window.$apiurl + "/address", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },

      body: JSON.stringify(value),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (res) => {
        if (res[0] === 200) {
          Notification("success", "Address has been add successfuly");
          setLoadingPost(false);

          setmodalAddresses(false);
          await GetViewModel();
          if (IsShipperSelected) {
            setFieldValue("ShipperId", res[1].Id);
          } else {
            setFieldValue("ConsigneeId", res[1].Id);
          }

          setAddressObjectPost({
            Name: "",
            Address: "",
            City: "",
            State: "",
            PostalCode: "",
            CountryId: "US",
            Phone: "",
            Fax: "",
            Note: "",
            AddressTypeId: null,
            IsActive: true,
            Charges: [],
            CustomerId: null,
            ContactName: "",
            Email: "",
          });
        } else {
          if (
            res[1].Message !== null &&
            res[1].Message !== undefined &&
            res[1].Message !== ""
          ) {
            Notification("errorClick", res[1].Message);
          } else {
            Notification("errorClick", "Invalid Data");
          }
          setLoadingPost(false);
        }
      })
      .catch((error) => {
        Notification("errorClick", "Invalid Data");
        setLoadingPost(false);
      });
  }

  const [itemsTms, setItemsTms] = React.useState([
    {
      UnitCount: 1,
      Quantity: 1,
      CubeType: "CBM",
      Cube: 1,
      WeightType: "LBS",
      PurchaseOrder: "",
      UnitValue: "in/lbs",
      IsPost: false,
      IsEditMode: false,
      Pieces: 1,
      UnitOfMeasureId: 4,
      ShipmentId: SO,
      WeightMode: "each",
      WeightModeValue: { value: "each", label: "each" },
      Weight: "1",
      Length: "",
      Height: "",
      Width: "",
      PalletTypeId: null,
      FreightClass: "",
      FreightClassValue: { value: "", label: "Select" },
      Nmfc: "",
      IsHazardousMateriel: false,
      StopId: null,
      DeliveryStopId: null,
      StopValue: { value: "", label: "Select" },
      StopValueDelivery: { value: "", label: "Select" },
      UnitOfMeasureValue: { value: 4, label: "Pallet" },
    },
  ]);
  function addClick() {
    setItemsTms((prevState) => [
      ...prevState,
      {
        UnitCount: 1,
        Quantity: 1,
        CubeType: "CBM",
        PurchaseOrder: "",
        Cube: 1,
        WeightType: "LBS",
        IsPost: true,
        IsEditMode: false,
        UnitValue: "in/lbs",
        Pieces: 1,
        UnitOfMeasureId: 4,
        ShipmentId: SO,
        WeightMode: "each",
        WeightModeValue: { value: "each", label: "each" },
        Weight: "1",
        Length: "",
        Height: "",
        Width: "",
        FreightClass: "",
        PalletTypeId: null,
        FreightClassValue: { value: "", label: "Select" },
        Nmfc: "",
        IsHazardousMateriel: false,
        StopId: null,
        DeliveryStopId: null,
        StopValue: { value: "", label: "Select" },
        StopValueDelivery: { value: "", label: "Select" },
        UnitOfMeasureValue: { value: 4, label: "Pallet" },
      },
    ]);
  }
  function removeClick(i) {
    let data = [...itemsTms];
    data.splice(i, 1);
    setItemsTms(data);
  }
  async function PostItemTms(data) {
    if (
      data.Commodity === null ||
      data.Commodity === undefined ||
      data.Commodity === ""
    ) {
      Notification("errorClick", "Commodity is required");
      return false;
    }
    if (
      data.Weight === null ||
      data.Weight === undefined ||
      data.Weight === ""
    ) {
      Notification("errorClick", "Weight is required");
      return false;
    }
    if (
      data.HandlingAmount === null ||
      data.HandlingAmount === undefined ||
      data.HandlingAmount === ""
    ) {
      Notification("errorClick", "Handling Amount is required");
      return false;
    }
    if (
      data.PackagingGroupId === null ||
      data.PackagingGroupId === undefined ||
      data.PackagingGroupId === ""
    ) {
      Notification("errorClick", "Packaging is required");
      return false;
    }
    return fetch(window.$apiurl + `/tms/items`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", `Item has been added successfully`);
        await GetItemsTms(SO);
        return res;
      })
      .catch((error) => {});
  }
  //******************** COST CALCULATION **************************
  const [showResults, setShowResults] = useState(false);
  const [modalPercentage, setModalPercentage] = useState(false);
  const [percentageValue, setPercentageValue] = useState(20);
  const [ratesCalculated, setRatesCalculated] = useState([]);
  const [totalPayable, setTotalPayable] = useState("");
  const [totalReceivable, setTotalReceivable] = useState("");
  const [totalMargin, setTotalMargin] = useState("");
  const [datatable, setdatatable] = useState([]);
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);
  const [showModalRates, setShowRatesModal] = useState(false);
  const [isCollapsedCommodityDrag, setIsCollapsedCommodityDrag] = useState(
    false
  );
  const [ratesRow, setRatesCarrierRow] = useState([]);
  const [allRates, setAllRatesCarrier] = useState([]);
  const [carrierRowSelect, setSelectCarrierRow] = useState(null);
  const [accessorialsList, setAccessorialsList] = useState([]);
  const [carriersHash, setCarriersHash] = useState([]);
  function calculateTotalItems(list, column) {
    try {
      let result = 0;
      if (list !== null && list !== undefined && list?.length > 0) {
        list.forEach((item) => {
          result += item[column];
        });
      }
      return currencyFormat(result);
    } catch (err) {
      console.log(err);
    }
  }
  const columnsItems = [
    {
      dataField: "Id",
      text: "Id",
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "PurchaseOrder",
      text: "Purchase Order",
      sortCaret: sortCaret,
    },
    {
      dataField: "UnitOfMeasure",
      text: "Unit Of Measure",
      sortCaret: sortCaret,
    },
    {
      dataField: "Sku",
      text: "Sku",
      sortCaret: sortCaret,
    },
    {
      dataField: "Upc",
      text: "Upc",
      sortCaret: sortCaret,
    },
    {
      dataField: "Container",
      text: "Container",
      sortCaret: sortCaret,
    },
    {
      dataField: "Description",
      text: "Description",
      sortCaret: sortCaret,
    },
    {
      dataField: "Quantity",
      text: "Quantity",
      sortCaret: sortCaret,
    },
    {
      dataField: "Weight",
      text: "Weight",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.Weight);
      },
    },
    {
      dataField: "Cube",
      text: "Cube",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return currencyFormat(row.Cube);
      },
    },
    {
      dataField: "IsHazmat",
      text: "IsHazmat",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          row.IsHazmat && (
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Hazardous Material</Tooltip>
              }
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/296395.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          )
        );
      },
    },
    {
      dataField: "IsHot",
      text: "IsHot",
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          row.IsHot && (
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/fire.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          )
        );
      },
    },
    {
      dataField: "DamagedQuantity",
      text: "DamagedQuantity",
      sortCaret: sortCaret,
    },
    {
      dataField: "CasePack",
      text: "CasePack",
      sortCaret: sortCaret,
    },
  ];
  const [LtlCarriers, setLtlCarriers] = useState([]);
  useEffect(() => {
    try {
      if (carriers?.length > 0) {
        let hashes = [];
        carriers.map((item) => {
          item.IsChecked = true;
          hashes.push(item.Hash);
          return null;
        });
        setCarriersHash(hashes || []);
        setLtlCarriers(carriers);
      }
    } catch (err) {}
  }, [carriers]);
  function fetchSelectedRates(value) {
    try {
      const ratesList = allRates;
      ratesList.length > 0 &&
        ratesList.map((item) => {
          if (!item.IsChangedPercentage) {
            item.Percentage = value;
            if (item.Name === "DISCOUNT" && item.Rate > 0) {
              item.Payable = item.Rate * -1;
              item.Rate = item.Rate * -1;
            } else {
              item.Payable = item.Rate;
            }
            item.Receivable = item.Payable + (item.Payable * value) / 100;
            item.Margin = item.Receivable - item.Payable;
          }
          return null;
        });

      //Totals
      var totalPayableCalc = calculateTotal(ratesList, false);
      setTotalPayable(totalPayableCalc);

      var totalReceivableCalc = calculateTotal(ratesList, true);
      setTotalReceivable(totalReceivableCalc);

      setTotalMargin(totalReceivableCalc - totalPayableCalc);
      let listRates = ratesList;

      const rates = ratesList.map((item) => {
        return item.Rate;
      });
      var max = Math.max.apply(null, rates);
      const minRate = ratesList.find((x) => x.Rate < 0);
      const maxRate = ratesList.find((x) => x.Rate === max);
      if (minRate !== null && minRate !== undefined) {
        listRates = listRates.filter(
          (x) =>
            x?.Rate !== 0 &&
            x.Rate !== minRate?.Rate &&
            x.Rate !== maxRate?.Rate
        );
        if (maxRate !== null && maxRate !== undefined) {
          listRates.push({
            Name: maxRate.Name,
            Receivable: maxRate.Receivable + minRate.Receivable,
            Payable: maxRate.Payable + minRate.Payable,
            Rate: maxRate.Payable + minRate.Payable,
          });
        }
      }

      listRates.length > 0 &&
        listRates.map((item) => {
          item.Margin = item.Receivable - item.Payable;
          item.Percentage = value;
          return null;
        });
      setRatesCalculated(listRates);
    } catch (err) {}
  }
  async function Select7LQuote(data) {
    return fetch(window.$apiurl + "/Select7LQuote", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          GetSoData(SO);
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth",
          });
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        try {
          const result = res[1];
          if (result[0] === 200) {
            Notification("success", "Success");
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
          }
          setLoaderData(false);
          return res;
        } catch (err) {}
      })
      .catch((err) => {
        console.log(err);
        setLoaderData(false);
      });
  }
  function fetchCamelCaseSpaces(field) {
    try {
      const message = field;
      const result = message.replace(/([A-Z])/g, " $1");
      const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      return finalResult;
    } catch (err) {}
  }
  async function PostGenerate7LRates(data) {
    return fetch(window.$apiurl + "/Get7LAvailableQuotes", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        try {
          const a = res[1];
          if (a?.ModelState) {
            //Destination
            if (a?.ModelState["viewModel.DestinationCity"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(
                  a?.ModelState["viewModel.DestinationCity"][0]
                )
              );
            }
            if (a?.ModelState["viewModel.DestinationState"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(
                  a?.ModelState["viewModel.DestinationState"][0]
                )
              );
            }
            if (a?.ModelState["viewModel.DestinationZipcode"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(
                  a?.ModelState["viewModel.DestinationZipcode"][0]
                )
              );
            }

            //Origin
            if (a?.ModelState["viewModel.OriginCity"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(a?.ModelState["viewModel.OriginCity"][0])
              );
            }
            if (a?.ModelState["viewModel.OriginState"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(a?.ModelState["viewModel.OriginState"][0])
              );
            }
            if (a?.ModelState["viewModel.OriginZipcode"]?.length > 0) {
              Notification(
                "errorClick",
                fetchCamelCaseSpaces(
                  a?.ModelState["viewModel.OriginZipcode"][0]
                )
              );
            }
          } else {
            if (a?.Message) {
              Notification("errorClick", a?.Message);
              setdatatable([]);
            } else {
              a.length > 0 &&
                a.map((item, index) => {
                  item.Id = index + 1;
                  return null;
                });
              setdatatable(a || []);
            }
          }
          return a;
        } catch (err) {}
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function fetchImgCarriers(name) {
    switch (name) {
      case "XPO LOGISTICS": {
        return toAbsoluteUrl("/media/images/carriers/CONWAY64x32.png");
      }
      case "XPO Freight": {
        return toAbsoluteUrl("/media/images/carriers/CONWAY64x32.png");
      }
      case "FORWARD AIR, INC.": {
        return toAbsoluteUrl("/media/images/carriers/FA64x32.png");
      }
      case "Forward Air": {
        return toAbsoluteUrl("/media/images/carriers/FA64x32.png");
      }
      case "CENTRAL TRANSPORT": {
        return toAbsoluteUrl("/media/images/carriers/CTI64x32.png");
      }
      case "OLD DOMINION FREIGHT LINE, INC.": {
        return toAbsoluteUrl("/media/images/carriers/ODFL64x32.png");
      }
      case "YRC FREIGHT": {
        return toAbsoluteUrl("/media/images/carriers/YRC64x32.png");
      }
      case "ROADRUNNER TRANSPORTATION SERVICES INC": {
        return toAbsoluteUrl("/media/images/carriers/RR64x32.png");
      }
      case "Road Runner": {
        return toAbsoluteUrl("/media/images/carriers/RR64x32.png");
      }
      case "YRC - Flashco": {
        return toAbsoluteUrl("/media/images/carriers/YRC64x32.png");
      }
      case "SAIA MOTOR FREIGHT LINE, LLC": {
        return toAbsoluteUrl("/media/images/carriers/SAIA64x32.png");
      }
      case "SAIA - Teragren": {
        return toAbsoluteUrl("/media/images/carriers/SAIA64x32.png");
      }
      case "SAIA": {
        return toAbsoluteUrl("/media/images/carriers/SAIA64x32.png");
      }
      case "REDDAWAY": {
        return toAbsoluteUrl("/media/images/carriers/USFR64x32.png");
      }
      case "USF Reddaway": {
        return toAbsoluteUrl("/media/images/carriers/USFR64x32.png");
      }
      case "Old Dominion": {
        return toAbsoluteUrl("/media/images/carriers/ODFL64x32.png");
      }
      case "ArcBest - Global Med": {
        return toAbsoluteUrl("/media/images/carriers/arcbest_logo.jpg");
      }
      case "ABF FREIGHT SYSTEM, INC.": {
        return toAbsoluteUrl("/media/images/carriers/ABF64x32.png");
      }
      default: {
        return toAbsoluteUrl("/media/images/carriers/delivery.png");
      }
    }
  }
  function calculateTotal(ratesListParam, isReceivable, isItemEditable) {
    let listRates = ratesListParam;
    const rates = ratesListParam.map((item) => {
      return item.Rate;
    });
    var max = Math.max.apply(null, rates);
    const minRate = ratesListParam.find((x) => x.Rate < 0);
    const maxRate = ratesListParam.find((x) => x.Rate === max);
    if (minRate !== null && minRate !== undefined) {
      listRates = listRates.filter(
        (x) => x.Rate !== minRate?.Rate && x.Rate !== maxRate?.Rate
      );
      if (maxRate !== null && maxRate !== undefined) {
        listRates.push({
          Name: maxRate.Name,
          Receivable: maxRate.Receivable + minRate.Receivable,
          Payable: maxRate.Payable + minRate.Payable,
          Rate: maxRate.Payable + minRate.Payable,
        });
      }
    }

    let totalRatesPayable = 0;
    listRates.length > 0 &&
      listRates.map((item) => {
        totalRatesPayable += isReceivable ? item.Receivable : item.Payable;
        return null;
      });
    return totalRatesPayable;
  }
  const columnsLTLRate = [
    {
      dataField: "Carrier",
      text: "Carrier",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <div
              style={{
                display: "inline-grid",
              }}
            >
              <img
                alt="Img Carriers"
                style={{ width: "48px" }}
                src={fetchImgCarriers(row?.Name)}
              />
              <span>{row?.Name}</span>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Number",
      text: "Number",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "RateRemarks",
      text: "Rate Remarks",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Scac",
      text: "SCAC",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "TransitDays",
      text: "Transit Days",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Total",
      text: "Total",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <span>{currencyFormat(row?.Total)}</span>
          </>
        );
      },
    },
    {
      dataField: "Actions",
      text: "Insurance",
      formatter: (cell, row) => {
        return (
          <>
            <button
              type="button"
              class="btn btn-primary m-4"
              onClick={async () => {
                try {
                  const selectObject = {
                    Id: statSHipmentId,
                    Total: row?.Total,
                    Number: row?.Number,
                    Hash: row?.Hash,
                    Rates: row?.Rates,
                    Percentage: percentageValue,
                  };
                  selectObject.Rates.length > 0 &&
                    selectObject.Rates.map((item) => {
                      if (item.Name === "DISCOUNT" && item.Rate > 0) {
                        item.Payable = item.Rate * -1;
                        item.Rate = item.Rate * -1;
                      } else {
                        item.Payable = item.Rate;
                      }
                      item.Payable = item.Rate;
                      item.IsChangedPercentage = false;
                      item.Receivable =
                        item.Payable + (item.Payable * percentageValue) / 100;
                      item.Margin = item.Receivable - item.Payable;
                      item.Percentage = percentageValue;
                      return null;
                    });
                  setAllRatesCarrier(selectObject.Rates);
                  //Totals
                  var totalPayableCalc = calculateTotal(
                    selectObject.Rates,
                    false
                  );
                  setTotalPayable(totalPayableCalc);

                  var totalReceivableCalc = calculateTotal(
                    selectObject.Rates,
                    true
                  );
                  setTotalReceivable(totalReceivableCalc);

                  setTotalMargin(totalReceivableCalc - totalPayableCalc);
                  let listRates = selectObject.Rates;
                  const rates = selectObject.Rates.map((item) => {
                    return item.Rate;
                  });
                  var max = Math.max.apply(null, rates);
                  const minRate = selectObject.Rates.find((x) => x.Rate < 0);
                  const maxRate = selectObject.Rates.find(
                    (x) => x.Rate === max
                  );

                  if (minRate !== null && minRate !== undefined) {
                    listRates = listRates.filter(
                      (x) =>
                        x?.Rate !== 0 &&
                        x?.Rate !== minRate?.Rate &&
                        x?.Rate !== maxRate?.Rate
                    );
                    if (maxRate !== null && maxRate !== undefined) {
                      listRates.push({
                        Name: maxRate.Name,
                        Receivable: maxRate.Receivable + minRate.Receivable,
                        Payable: maxRate.Payable + minRate.Payable,
                        Rate: maxRate.Payable + minRate.Payable,
                      });
                    }
                  }
                  listRates.length > 0 &&
                    listRates.map((item) => {
                      item.Margin = item.Receivable - item.Payable;
                      item.Percentage = percentageValue;
                      return null;
                    });
                  setRatesCarrierRow(row);
                  setSelectCarrierRow(selectObject);
                  setRatesCalculated(listRates);
                  setModalPercentage(true);
                } catch (err) {}
              }}
            >
              <i className="flaticon2-shield pr3"></i>
              Select
            </button>
          </>
        );
      },
    },
  ];
  const expandRowQuotes = {
    renderer: (row) => {
      if (row.Rates.length !== 0) {
        return (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Rate</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.Rates.map((x) => (
                <TableRow>
                  <TableCell>{x.Name}</TableCell>
                  <TableCell>{x.Rate}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        );
      }
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
  };
  //************************************************************ */

  async function GetItemsTms(id) {
    return await fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        const items = a?.Items;
        items?.length > 0 &&
          items.map((item) => {
            item.UnitCount = item.Quantity;
            item.FreightClassValue = {
              value: item.Class === "" ? "" : item.Class,
              label: item.Class === "" ? "Select" : item.Class,
            };
            item.WeightModeValue = {
              value: item.WeightMode === "" ? "each" : item.WeightMode,
              label: item.WeightMode === "" ? "each" : item.WeightMode,
            };
            item.FreightClass = item.Class;
            item.StopValue = { value: item.StopId, label: item.Stop };
            item.StopValueDelivery = {
              value: item.DeliveryStopId,
              label: item.DeliveryStop,
            };
            item.UnitOfMeasureValue = {
              value: item.UnitOfMeasure,
              label: item.UnitOfMeasure,
            };
            item.UnitValue = "in/lbs";
            item.CubeType = "CBM";
            item.WeightType = "LBS";
            item.UnitOfMeasureId = item.UnitOfMeasureId || 4;
            item.Pieces = 1;
            item.ShipmentId = SO;
            item.IsHazardousMateriel = item.IsHazmat;
            return null;
          });
        setItemsTms(items || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PutItemTms(id, data) {
    return fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {});
  }
  async function DeleteItemTms(id) {
    return fetch(window.$apiurl + `/tms/items/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {});
  }
  async function ClassCalculatorFunctionFetch(
    length,
    width,
    height,
    weight,
    index
  ) {
    return await fetch(
      window.$apiurl +
        `/FreightClass/CalculateClass?length=${length}&width=${width}&height=${height}&weight=${weight}
        &isMeter=false`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        let values = [...itemsTms];
        if (a) {
          try {
            values[index].FreightClass = a?.Result;
            values[index].FreightClassValue = {
              value: a?.Result,
              label: a?.Result,
            };
          } catch (err) {}
        } else {
          values[index].FreightClass = 0;
          values[index].FreightClassValue = {
            value: 0,
            label: 0,
          };
        }
        setItemsTms(values);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const suggestionsFreightClass = [
    { label: "0" },
    { label: "50" },
    { label: "55" },
    { label: "60" },
    { label: "70" },
    { label: "77.5" },
    { label: "85" },
    { label: "92.5" },
    { label: "100" },
    { label: "110" },
    { label: "125" },
    { label: "150" },
    { label: "175" },
    { label: "200" },
    { label: "250" },
    { label: "300" },
    { label: "400" },
    { label: "500" },
  ].map((suggestion) => ({
    value: suggestion.label,
    label: suggestion.label,
  }));
  function ItemsTmsComponent(values) {
    return itemsTms.map((el, i) => (
      <div className="card mt-md-9 bg-light" key={i}>
        <div className="card-body">
          <div className="row">
            <div className="col">
              <div className="row">
                <div className="col-lg-6">
                  <span
                    class="SoCommodity label label-info label-inline mr-2 mb-3 p-4"
                    style={{ fontSize: "14px" }}
                  >
                    {el?.IsPost ? "New Item" : `Item #: ${el?.Id}`}
                  </span>
                </div>
                <div className="col-lg-6" style={{ textAlignLast: "right" }}>
                  <button
                    onClick={async () => {
                      if (el?.Id) {
                        await DeleteItemTms(el?.Id);
                        await GetItemsTms(SO);
                      } else {
                        removeClick(i);
                      }
                      Notification("success", "Success");
                    }}
                    type="button"
                    class="btn btn-light-danger mr-2"
                  >
                    <i
                      class="fas fa-times fa-sm"
                      style={{ paddingRight: "initial" }}
                    ></i>
                  </button>
                </div>
              </div>

              <div className="p-2">
                {/* First Row */}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label>PO#</label>
                    <input
                      component={Input}
                      placeholder="PO#"
                      className="form-control"
                      value={el?.PurchaseOrder}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].PurchaseOrder = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                      type="text"
                      name="PurchaseOrder"
                    />
                  </div>
                  {el?.IsPost === true ? (
                    <div className="col-lg-3">
                      <label>Packaging</label>{" "}
                      <span className="text-danger">*</span>
                      <Select
                        name="PackagingGroupId"
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].PackagingGroupId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={ViewModelOptions?.PackagingGroups?.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-3">
                      <label>Packaging</label>{" "}
                      <span className="text-danger">*</span>
                      <Select
                        name="PackagingGroupId"
                        value={ViewModelOptions?.PackagingGroups?.filter(
                          (a) => a.Id === el?.PackagingGroupId
                        ).map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].PackagingGroupId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={ViewModelOptions?.PackagingGroups?.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                      />
                    </div>
                  )}
                  {el?.IsPost === true ? (
                    <div className="col-lg-3">
                      <label>Pallet Types</label>{" "}
                      <span className="text-danger">*</span>
                      <Select
                        name="PalletTypeId"
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].PalletTypeId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={ViewModelOptions?.PalletTypes?.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-3">
                      <label>Pallet Types</label>{" "}
                      <span className="text-danger">*</span>
                      <Select
                        name="PackagingGroupId"
                        value={ViewModelOptions?.PalletTypes?.filter(
                          (a) => a.Id === el?.PalletTypeId
                        ).map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(e) => {
                          try {
                            let values = [...itemsTms];
                            values[i].PalletTypeId = e.value;
                            values[i].IsEditMode = true;
                            setItemsTms(values);
                          } catch (err) {}
                        }}
                        options={ViewModelOptions?.PalletTypes?.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                      />
                    </div>
                  )}
                  <div className="col-lg-3">
                    <label>Handling Amount</label>
                    <span className="text-danger">*</span>
                    <input
                      component={Input}
                      placeholder="Handling"
                      className="form-control"
                      type="text"
                      value={el?.HandlingAmount}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].HandlingAmount = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                      name="HandlingAmount"
                    />
                  </div>

                  <div className="col-lg-3 mt-4">
                    <label>Pieces</label>
                    <input
                      type="number"
                      className="form-control"
                      name="UnitCount"
                      component={Input}
                      placeholder="Unit Count"
                      required={true}
                      value={el?.Quantity}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Quantity = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                      min="0"
                    />
                  </div>
                  <div className="col-lg-3  mt-4">
                    <label>Weight</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      name="Weight"
                      id="WeightId"
                      className="form-control"
                      component={Input}
                      placeholder="Weight"
                      value={el?.Weight}
                      min="0"
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Weight = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                          //Class Calculator
                          if (
                            el?.Length !== "" &&
                            el?.Width !== "" &&
                            el?.Height !== "" &&
                            el?.Weight !== ""
                          ) {
                            ClassCalculatorFunctionFetch(
                              el?.Length,
                              el?.Width,
                              el?.Height,
                              el?.Weight,
                              i
                            );
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>

                  <div className="col-lg-3 mt-4">
                    <label>NMFC</label>
                    <input
                      component={Input}
                      placeholder="NMFC"
                      className="form-control"
                      value={el?.Nmfc}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Nmfc = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                      type="text"
                      name="Nmfc"
                    />
                  </div>

                  <div className="col-lg-3  mt-4">
                    <label>Commodity</label>
                    <span className="text-danger">*</span>
                    <input
                      component={Input}
                      placeholder="Commodity"
                      className="form-control"
                      value={el?.Commodity}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Commodity = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                      type="text"
                      name="Commodity"
                    />
                  </div>
                </div>
                {/* Second Row */}
                <div className="form-group row mb-0">
                  <div className="col-lg-3">
                    <label>Class</label>
                    <span className="text-danger">*</span>
                    <Select
                      name="FreightClass"
                      options={suggestionsFreightClass}
                      value={el?.FreightClassValue}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].FreightClass = e.value;
                          values[i].IsEditMode = true;
                          values[i].FreightClassValue = e;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>Width</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      name="Width"
                      id="WidthId"
                      className="form-control"
                      component={Input}
                      placeholder="Width"
                      value={el?.Width}
                      min="0"
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Width = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                          //Class Calculator
                          if (
                            el?.Length !== "" &&
                            el?.Width !== "" &&
                            el?.Height !== "" &&
                            el?.Weight !== ""
                          ) {
                            ClassCalculatorFunctionFetch(
                              el?.Length,
                              el?.Width,
                              el?.Height,
                              el?.Weight,
                              i
                            );
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>Height</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      name="Height"
                      id="HeightId"
                      className="form-control"
                      component={Input}
                      placeholder="Height"
                      value={el?.Height}
                      min="0"
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Height = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                          //Class Calculator
                          if (
                            el?.Length !== "" &&
                            el?.Width !== "" &&
                            el?.Height !== "" &&
                            el?.Weight !== ""
                          ) {
                            ClassCalculatorFunctionFetch(
                              el?.Length,
                              el?.Width,
                              el?.Height,
                              el?.Weight,
                              i
                            );
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>Length</label>
                    <span className="text-danger">*</span>
                    <input
                      type="number"
                      name="Length"
                      id="LengthId"
                      className="form-control"
                      component={Input}
                      placeholder="Length"
                      value={el?.Length}
                      min="0"
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].Length = e.target.value;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                          //Class Calculator
                          if (
                            el?.Length !== "" &&
                            el?.Width !== "" &&
                            el?.Height !== "" &&
                            el?.Weight !== ""
                          ) {
                            ClassCalculatorFunctionFetch(
                              el?.Length,
                              el?.Width,
                              el?.Height,
                              el?.Weight,
                              i
                            );
                          }
                        } catch (err) {}
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mt-md-12">
                    <CheckboxLargeWB
                      className="font-size-md"
                      text="Is HazMat *"
                      name="IsHazardousMateriel"
                      id="IsHazardousMateriel"
                      checked={el?.IsHazardousMateriel}
                      onChange={(e) => {
                        try {
                          let values = [...itemsTms];
                          values[i].IsHazardousMateriel = e.target.checked;
                          values[i].IsEditMode = true;
                          setItemsTms(values);
                        } catch (err) {}
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  return (
    <>
      <Modal
        size="lg"
        show={CreateSO}
        onHide={setCreateSO}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Baics Info
          </Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setCreateSO(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col">
              <label className="mb-2">Mode</label>
              <Select
                isClearable
                name="Mode"
                id="Mode"
                label="Mode"
                inputId="react-select-single"
                defaultValue={{
                  value: ModeSO,
                  label: ModeSO,
                }}
                options={Mode}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => setCreateSO(false)}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              alert();
              setTextMasterOrder("Back");
              setTab("basic-new");
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={modalAddresses}
        size="lg"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Create new Address
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setmodalAddresses(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <form className="form form-label-right">
            <div className="form-group row">
              <div className={"col-lg-12"}>
                <label>Organisation Name</label>
                <input
                  name="Name"
                  className="form-control"
                  placeholder="Organisation Name"
                  label="Organisation Name"
                  value={AddressObjectPost.Name}
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Name = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  required={true}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-12 px-0">
                <label>
                  Address <RequiredHtml />
                </label>
                <input
                  name="Address"
                  as="textarea"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Address = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>City</label>

                <input
                  name="City"
                  className="form-control"
                  placeholder="City"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.City = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  label="City"
                />
              </div>
              <div className="col-lg-4">
                <label>Choose a state</label>
                <Select
                  name="State"
                  label="State"
                  id="LocationState"
                  options={states}
                  value={stateId}
                  onChange={(e) => {
                    setStateId(e);
                    let value = { ...AddressObjectPost };
                    if (e !== null) {
                      value.State = e?.label;
                    } else {
                      value.State = "";
                    }
                    setAddressObjectPost(value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Zip Code</label>

                <input
                  name="PostalCode"
                  className="form-control"
                  placeholder="Zip Code"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.PostalCode = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  label="Zip Code"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>
                  Country <RequiredHtml />
                </label>
                <CreatableSelect
                  name="CountryId"
                  value={
                    countryIdFetch.value === ""
                      ? { value: "US", label: "United States" }
                      : countryIdFetch
                  }
                  options={Country.map((x) => {
                    return { value: x?.Id, label: x?.Name };
                  })}
                  onChange={(newValue) => {
                    setCountryValue(newValue);
                    handleCountryChange(newValue?.value);
                    let value = { ...AddressObjectPost };
                    if (newValue !== null) {
                      value.CountryId = newValue?.value;
                    } else {
                      value.CountryId = "";
                    }
                    setAddressObjectPost(value);
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Phone</label>

                <input
                  name="Phone"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Phone = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Phone"
                  label="Phone"
                />
              </div>
              <div className="col-lg-4">
                <label>Fax</label>

                <input
                  name="Fax"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Fax = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Fax"
                  label="Fax"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>Contact Name</label>

                <input
                  name="ContactName"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.ContactName = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Contact Name"
                  label="Contact Name"
                />
              </div>
              <div className="col-lg-4">
                <label>Contact Email</label>

                <input
                  name="Email"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Email = e.target.value;
                    setAddressObjectPost(value);
                  }}
                  className="form-control"
                  placeholder="Contact Email"
                  label="Contact Email"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Customer</label>
                <Select
                  name="CustomerId"
                  options={
                    ViewModelOptions?.Customers?.length > 0
                      ? ViewModelOptions?.Customers.map((item) => {
                          return {
                            value: item.Id,
                            label: item.Name,
                          };
                        })
                      : []
                  }
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.CustomerId = e?.value;
                    setAddressObjectPost(value);
                  }}
                  isClearable
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-lg-12 px-0">
                <label>Note</label>
                <input
                  name="Note"
                  as="textarea"
                  className="form-control"
                  onChange={(e) => {
                    let value = { ...AddressObjectPost };
                    value.Note = e.target.value;
                    setAddressObjectPost(value);
                  }}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setmodalAddresses(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              postAddress();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Card>
        <CardHeader
          title={`${
            ViewModelOptions?.Shippers.length > 0 &&
            ViewModelOptions?.Shippers.filter(
              (a) => a.Id === values?.ShipperId
            )[0]?.Name
              ? `Shipping Details | Company Name :  ${
                  ViewModelOptions?.Shippers.filter(
                    (a) => a.Id === values?.ShipperId
                  )[0]?.Name
                }`
              : `Shipping Details | Select a company`
          }`}
        >
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedShipping(isCollapsedShipping ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedShipping ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedShipping ? "d-none" : ""}>
          <div
            className={`col-lg-12 bg-light-${
              values?.IsCanceled
                ? "danger"
                : values?.OnHold
                ? "warning"
                : values.FreightChargeId !== 98
                ? "primary"
                : "info"
            } ${
              values?.IsCanceled
                ? "text-danger"
                : values?.OnHold
                ? "text-warning"
                : values.FreightChargeId !== 98
                ? "text-primary"
                : "text-info"
            } m-0 mb-2 p-0`}
          >
            <small style={{ fontSize: "120%" }} className="pr-3">
              Shipment Status:{" "}
              <b>
                {" "}
                {values?.StatusName === "Dispatched"
                  ? "Out for Pickup"
                  : values?.StatusName}{" "}
                {values?.IsCanceled && "(Canceled)"}
                {values?.OnHold && "(On Hold)"}
              </b>
            </small>
          </div>
          <div className="row mb-5">
            <Col xl="12" lg="12" md="12">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Company Name :<span className="text-danger">*</span>
                  </a>
                  <span className="font-weight-bold">
                    <CreatableSelect
                      className="mt-4"
                      isClearable
                      name="ShipperId"
                      id="ShipperId"
                      label="Company"
                      value={ViewModelOptions?.Shippers.filter(
                        (a) => a.Id === values.ShipperId
                      ).map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          ValueId: a.ValueId,
                        };
                      })}
                      options={ViewModelOptions?.Shippers.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          ValueId: a.ValueId,
                        };
                      })}
                      onChange={async (newValue) => {
                        if (newValue !== null) {
                          if (newValue?.__isNew__) {
                            setmodalAddresses(true);
                            setCountryValue({
                              value: "US",
                              label: "United States",
                            });

                            handleCountryChange("US");
                            var value = {
                              ...AddressObjectPost,
                            };
                            value.Name = newValue.label;
                            setAddressObjectPost(value);
                            setIsShipperSelected(true);
                          } else {
                            setFieldValue("ShipperId", newValue?.value);
                          }
                          if (newValue?.ValueId !== null) {
                            setFieldValue("ShipmentTypeId", 1358);
                          } else {
                            setFieldValue("ShipmentTypeId", 1357);
                          }
                        } else {
                          setFieldValue("ShipperId", null);
                          let values = { ...shipper };
                          values = {
                            ShipperId: 0,
                            ShipperAddress: "",
                            ShipperCity: "",
                            ShipperState: "",
                            ShipperZipCode: "",
                            ShipperPhoneNumber: "",
                            ShipperFax: "",
                            ShipperContactName: "",
                            ShipperEmail: "",
                          };
                          setShipper(values);
                        }
                        if (!newValue?.__isNew__) {
                          await fetchSelectedAddress(
                            newValue !== null ? newValue.value : null,
                            true
                          );
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Street Address :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperAddress"
                      disabled={true}
                      id="ShipperAddress"
                      value={shipper?.ShipperAddress}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    City :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperCity"
                      disabled={true}
                      id="ShipperCity"
                      value={shipper?.ShipperCity}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    State :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperState"
                      disabled={true}
                      id="ShipperState"
                      value={shipper?.ShipperState}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Zip :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperZipCode"
                      id="ShipperZipCode"
                      disabled={true}
                      value={shipper?.ShipperZipCode}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Name :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperContactName"
                      disabled={true}
                      id="ShipperContactName"
                      label="Contact"
                      value={shipper?.ShipperContactName}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Phone :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperPhoneNumber"
                      id="ShipperPhoneNumber"
                      disabled={true}
                      value={shipper?.ShipperPhoneNumber}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Fax :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperFax"
                      id="ShipperFax"
                      disabled={true}
                      value={shipper?.ShipperFax}
                      label="Fax"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Email :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="email"
                      className="form-control mt-4"
                      name="ShipperEmail"
                      disabled={true}
                      id="ShipperEmail"
                      value={shipper?.ShipperEmail}
                      label="Email"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-3">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                  >
                    Req. Ship Date # :<span className="text-danger">*</span>
                  </a>
                  <span className="text-muted font-weight-bold">
                    <TextField
                      id="ReqShipDate"
                      type="date"
                      value={String(
                        moment(values.ReqShipDate).format("YYYY-MM-DD")
                      )}
                      onChange={(e) => {
                        setFieldValue("ReqShipDate", e.target.value);
                        if (
                          values?.ShipmentDate === "" ||
                          values?.ShipmentDate === undefined ||
                          values?.ShipmentDate === null
                        ) {
                          setFieldValue("ShipmentDate", e.target.value);
                        }
                      }}
                      className="form-control mt-4"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    PU # :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ShipperNumber"
                      id="ShipperNumber"
                      value={values.ShipperNumber}
                      onChange={(e) => {
                        setFieldValue("ShipperNumber", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Seal # :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="SealNumber"
                      id="SealNumber"
                      value={values.SealNumber}
                      onChange={(e) => {
                        setFieldValue("SealNumber", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Special Req :
                  </a>
                  <span className="font-weight-bold">
                    <Select
                      isMulti
                      className="basic-multi-select mt-4"
                      classNamePrefix="select"
                      name="ShipperSpecialReq"
                      id="ShipperSpecialReq"
                      value={ViewModelOptions?.SpecialReqs.filter(function(el) {
                        return values?.ShipperSpecialReq?.indexOf(el.Name) >= 0;
                      }).map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      options={ViewModelOptions?.SpecialReqs.map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setFieldValue(
                            "ShipperSpecialReq",
                            newValue.map((a) => a.value)
                          );
                        } else {
                          setFieldValue("ShipperSpecialReq", []);
                        }
                      }}
                      label="Load Board"
                      inputId="react-select-single"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Note :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <textarea
                      className="form-control"
                      id="ShippingNote"
                      rows="3"
                      value={values.ShippingNote}
                      onChange={(e) => {
                        setFieldValue("ShippingNote", e.target.value);
                      }}
                    ></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Row className="pt-5  mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                  >
                    PU APPT Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.PUAppointment}
                      onChange={(newValue) => {
                        setFieldValue("PUAppointment", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="3" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                  >
                    PU APPT to Time :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <input
                      className={`form-control mt-4`}
                      value={
                        values.PUAppointementTo === null ||
                        values.PUAppointementTo === ""
                          ? ""
                          : values.PUAppointementTo.length > 4
                          ? moment(values.PUAppointementTo).format("HH:mm")
                          : values.PUAppointementTo
                      }
                      placeholder="00:00"
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setFieldValue("", "");
                          return;
                        }
                      }}
                      onChange={(e) => {
                        var value = e.target?.value;
                        if (!value || value === "") {
                          setFieldValue("PUAppointementTo", "");
                        }
                        if (value.length === 2) value += ":";
                        if (value.length > 5) value = value[5];
                        if (value.length > 4) {
                          setFieldValue(
                            "PUAppointementTo",
                            moment().format("YYYY-MM-DDT") + value
                          );
                        } else {
                          setFieldValue("PUAppointementTo", value);
                        }

                        //setborder(re.test(value) ? "" : "border-danger");
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="3">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.PUAppointmentEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    name="ShipperDt"
                    text="D/T"
                    id="ShipperDt"
                    checked={values.ShipperDt === true ? true : false}
                    onChange={(event) => {
                      setFieldValue("ShipperDt", event.target.checked);
                    }}
                  />
                </div>
              </div>
            </Col>
            {values.ShipperDt === true ? (
              <>
                <Col xl="4" lg="4" md="4" className="">
                  <div className=" flex-wrap align-items-center pb-5">
                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <a
                        href={() => false}
                        className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                      >
                        Empty Date:
                        <br />
                      </a>
                      <span className="font-weight-bold">
                        <DateTime30GL
                          defaultvalue={values.PUAppointmentDt}
                          onChange={(newValue) => {
                            setFieldValue("PUAppointmentDt", newValue);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
                {/* <Col xl="4" lg="4" md="4" className="">
                     <div className=" flex-wrap align-items-center pb-5">
                       <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                         <a
                           href={() => false}
                           className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                         >
                           Recovery Date :
                           <br />
                         </a>
                         <span className="font-weight-bold">
                           <DateTime30GL
                             defaultvalue={values.PUAppointmentRecoveryDate}
                             onChange={(newValue) => {
                               setFieldValue(
                                 "PUAppointmentRecoveryDate",
                                 newValue
                               );
                             }}
                           />
                         </span>
                       </div>
                     </div>
                   </Col> */}
              </>
            ) : (
              ""
            )}
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Arrival Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ShipperArrivalDate}
                      onChange={(newValue) => {
                        setFieldValue("ShipperArrivalDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ShipperArrivalDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperArrivalDateEnteredDate"
                      id="ShipperArrivalDateEnteredDate"
                      disabled={true}
                      value={
                        values.ShipperArrivalDateEnteredDate === null
                          ? ""
                          : moment(values.ShipperArrivalDateEnteredDate).format(
                              "MM-DD-YYYY, h:mm A"
                            )
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Loaded Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.LoadedDate}
                      disabled={true}
                      onChange={(newValue) => {
                        setFieldValue("LoadedDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="text"
                    className="form-control mt-4"
                    name="LoadedDateEnteredBy"
                    id="LoadedDateEnteredBy"
                    disabled={true}
                    value={values.LoadedDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="LoadedDateEnteredDate"
                      id="LoadedDateEnteredDate"
                      disabled={true}
                      value={
                        values.LoadedDateEnteredDate === null
                          ? ""
                          : moment(values.LoadedDateEnteredDate).format(
                              "MM-DD-YYYY, h:mm A"
                            )
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Departure Date :
                    <br />
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ShipperDepartureDate}
                      onChange={(newValue) => {
                        setFieldValue("ShipperDepartureDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ShipperDepartureDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ShipperDepartureDateEnteredDate"
                      id="ShipperDepartureDateEnteredDate"
                      disabled={true}
                      value={
                        values.ShipperDepartureDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ShipperDepartureDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          title={`${
            ViewModelOptions?.Consignees.length > 0 &&
            ViewModelOptions?.Consignees.filter(
              (a) => a.Id === values?.ConsigneeId
            )[0]?.Name
              ? `Receiving Details | Company Name :  ${
                  ViewModelOptions?.Consignees.filter(
                    (a) => a.Id === values?.ConsigneeId
                  )[0]?.Name
                }`
              : `Receiving Details | Select a company`
          }`}
        >
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedReceiving(isCollapsedReceiving ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedReceiving ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedReceiving ? "d-none" : ""}>
          <div className="row mb-5">
            <Col xl="12" lg="12" md="12">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Company Name :<span className="text-danger">*</span>
                  </a>
                  <span className="font-weight-bold">
                    <CreatableSelect
                      isClearable
                      className="mt-4"
                      name="ConsigneeId"
                      id="ConsigneeId"
                      value={ViewModelOptions?.Consignees.filter(
                        (a) => a.Id === values.ConsigneeId
                      ).map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      options={ViewModelOptions?.Consignees.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                        };
                      })}
                      onChange={async (newValue) => {
                        if (newValue !== null) {
                          if (newValue?.__isNew__) {
                            setmodalAddresses(true);
                            setCountryValue({
                              value: "US",
                              label: "United States",
                            });

                            handleCountryChange("US");
                            var value = {
                              ...AddressObjectPost,
                            };
                            value.Name = newValue.label;
                            setAddressObjectPost(value);
                            setIsShipperSelected(false);
                          } else {
                            setFieldValue("ConsigneeId", newValue?.value);
                          }
                        } else {
                          setFieldValue("ConsigneeId", null);
                          let values = { ...consignee };
                          values = {
                            ConsigneeId: 0,
                            ConsigneeAddress: "",
                            ConsigneeCity: "",
                            ConsigneeState: "",
                            ConsigneeZipCode: "",
                            ConsigneePhoneNumber: "",
                            ConsigneeFax: "",
                            ConsigneeContactName: "",
                            ConsigneeEmail: "",
                          };
                          setConsignee(values);
                        }
                        if (!newValue?.__isNew__) {
                          await fetchSelectedAddress(
                            newValue !== null ? newValue.value : null,
                            false
                          );
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Street Address :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeStreet"
                      id="ConsigneeStreet"
                      disabled={true}
                      label="Street"
                      value={consignee?.ConsigneeAddress}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    City :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeCity"
                      disabled={true}
                      id="ConsigneeCity"
                      value={consignee?.ConsigneeCity}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    State :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeState"
                      id="ConsigneeState"
                      disabled={true}
                      value={consignee?.ConsigneeState}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Zip :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeZipCode"
                      id="ConsigneeZipCode"
                      disabled={true}
                      value={consignee?.ConsigneeZipCode}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Name :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeContactName"
                      disabled={true}
                      value={consignee?.ConsigneeContactName}
                      id="ConsigneeContactName"
                      label="Contact"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Phone :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneePhoneNumber"
                      disabled={true}
                      id="ConsigneePhoneNumber"
                      value={consignee?.ConsigneePhoneNumber}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Fax :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeFax"
                      id="ConsigneeFax"
                      disabled={true}
                      value={consignee?.ConsigneeFax}
                      label="Fax"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Contact Email :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="email"
                      className="form-control mt-4"
                      name="EmailText"
                      id="EmailText"
                      disabled={true}
                      value={consignee?.ConsigneeEmail}
                      label="Email"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 border-right">
              <div className=" flex-wrap align-items-center pb-3">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                  >
                    MABD: <span className="text-danger">*</span>
                  </a>
                  <span className="text-muted font-weight-bold">
                    <TextField
                      id="MustArriveByDate"
                      type="date"
                      value={String(
                        moment(values.MustArriveByDate).format("YYYY-MM-DD")
                      )}
                      onChange={(e) => {
                        setFieldValue("MustArriveByDate", e.target.value);
                      }}
                      className="form-control mt-4"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL # :
                  </a>
                  <span className="text-muted font-weight-bold">
                    {" "}
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="ConsigneeNumber"
                      id="ConsigneeNumber"
                      value={values.ConsigneeNumber}
                      label="PU"
                      onChange={(e) => {
                        setFieldValue("ConsigneeNumber", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Special Req :
                  </a>
                  <span className="font-weight-bold">
                    <Select
                      isMulti
                      className="basic-multi-select mt-4"
                      classNamePrefix="select"
                      name="ConsigneeSpecialReq"
                      id="ConsigneeSpecialReq"
                      value={ViewModelOptions?.SpecialReqs.filter(function(el) {
                        return (
                          values?.ConsigneeSpecialReq?.indexOf(el.Name) >= 0
                        );
                      }).map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      options={ViewModelOptions?.SpecialReqs.map((a) => {
                        return {
                          value: a.Name,
                          label: a.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setFieldValue(
                            "ConsigneeSpecialReq",
                            newValue.map((a) => a.value)
                          );
                        } else {
                          setFieldValue("ConsigneeSpecialReq", []);
                        }
                      }}
                      label="Load Board"
                      inputId="react-select-single"
                    />
                  </span>
                </div>
              </div>
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    ETA:
                  </a>
                  <span className=" font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.EstimatedTimeArrival}
                      onChange={(newValue) => {
                        setFieldValue("EstimatedTimeArrival", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-8 col-md-8 border-right">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Note :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <textarea
                      className="form-control"
                      id="ReceivingNote"
                      rows="3"
                      value={values.ReceivingNote}
                      onChange={(e) => {
                        setFieldValue("ReceivingNote", e.target.value);
                      }}
                    ></textarea>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Row className="pt-5  mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL APPT Date:
                  </a>
                  <span className=" font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.DeliveryAppointmentDate}
                      onChange={(newValue) => {
                        setFieldValue("DeliveryAppointmentDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="3" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    DEL to Time :
                  </a>
                  <span className=" font-weight-bold">
                    <input
                      className={`form-control mt-4`}
                      value={
                        values.DeliveryAppointmentDateTo === null ||
                        values.DeliveryAppointmentDateTo === ""
                          ? ""
                          : values.DeliveryAppointmentDateTo.length > 4
                          ? moment(values.DeliveryAppointmentDateTo).format(
                              "HH:mm"
                            )
                          : values.DeliveryAppointmentDateTo
                      }
                      placeholder="00:00"
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setFieldValue("", "");
                          return;
                        }
                      }}
                      onChange={(e) => {
                        var value = e.target?.value;
                        if (!value || value === "") {
                          setFieldValue("DeliveryAppointmentDateTo", "");
                        }
                        if (value.length === 2) value += ":";
                        if (value.length > 5) value = value[5];
                        if (value.length > 4) {
                          setFieldValue(
                            "DeliveryAppointmentDateTo",
                            moment().format("YYYY-MM-DDT") + value
                          );
                        } else {
                          setFieldValue("DeliveryAppointmentDateTo", value);
                        }
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="3">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.DeliveryAppointmentDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <CheckboxLargeWB
                    className="font-size-md"
                    name="ConsigneeDt"
                    text="D/T"
                    id="ConsigneeDt"
                    checked={values.ConsigneeDt === true ? true : false}
                    onChange={(event) => {
                      setFieldValue("ConsigneeDt", event.target.checked);
                    }}
                  />
                </div>
              </div>
            </Col>
            {values.ConsigneeDt === true ? (
              <>
                <Col xl="4" lg="4" md="4" className="">
                  <div className=" flex-wrap align-items-center pb-5">
                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                      <a
                        href={() => false}
                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                      >
                        Empty Date:
                      </a>
                      <span className=" font-weight-bold">
                        <DateTime30GL
                          defaultvalue={values.DeliveryAppointmentRecoveryDate}
                          onChange={(newValue) => {
                            setFieldValue(
                              "DeliveryAppointmentRecoveryDate",
                              newValue
                            );
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Arrival Date :
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ConsigneeArrivalDate}
                      onChange={(newValue) => {
                        setFieldValue("ConsigneeArrivalDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ConsigneeArrivalDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeArrivalDateEnteredDate"
                      id="ConsigneeArrivalDateEnteredDate"
                      disabled={true}
                      value={
                        values.ConsigneeArrivalDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ConsigneeArrivalDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Departure Date :
                  </a>
                  <span className="font-weight-bold">
                    <DateTime30GL
                      defaultvalue={values.ConsigneeDepartureDate}
                      onChange={(newValue) => {
                        setFieldValue("ConsigneeDepartureDate", newValue);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entered By :
                  </a>
                  <input
                    type="tel"
                    className="form-control mt-4"
                    name="EnteredBy"
                    id="EnteredBy"
                    disabled={true}
                    value={values.ConsigneeDepartureDateEnteredBy}
                    label="Entered By"
                  />
                </div>
              </div>
            </Col>
            <Col xl="4" lg="4" md="4">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    Entry Date/Time :
                  </a>
                  <span className="text-muted font-weight-bold">
                    <input
                      type="tel"
                      className="form-control mt-4"
                      name="ConsigneeDepartureDateEnteredDate"
                      id="ConsigneeDepartureDateEnteredDate"
                      disabled={true}
                      value={
                        values.ConsigneeDepartureDateEnteredDate === null
                          ? ""
                          : moment(
                              values.ConsigneeDepartureDateEnteredDate
                            ).format("MM-DD-YYYY, h:mm A")
                      }
                      label="Entered By"
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row className=" mb-5">
            <Col xl="4" lg="4" md="4" className="">
              <div className=" flex-wrap align-items-center pb-5">
                <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                  <a
                    href={() => false}
                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                  >
                    POD :
                  </a>
                  <span className="font-weight-bold">
                    <input
                      type="text"
                      className="form-control mt-4"
                      name="Pod"
                      id="Pod"
                      value={values?.Pod}
                      label="Pod"
                      onChange={(e) => {
                        setFieldValue("Pod", e.target.value);
                      }}
                    />
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Carrier Selection">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedCarrier(isCollapsedCarrier ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedCarrier ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>{" "}
        </CardHeader>
        <CardBody className={isCollapsedCarrier ? "d-none" : ""}>
          <div>
            <Row>
              <div className="col-xl-12 col-lg-12">
                <div
                  className="label label-lg label-light-info label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  LTL Accessorials
                </div>
                <div className="p-5">
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label className="font-weight-bolder pr-5">ORIG:</label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("APD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "APD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Appointment
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("CSD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "CSD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Construction
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("EXD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "EXD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Exhibition
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("IND");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "IND"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Inside
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("LFD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "LFD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Liftgate
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("MID");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "MID"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Military
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("RSD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "RSD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Residential
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("SCD");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "SCD"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        School
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label className="font-weight-bolder pr-5">DEST:</label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("APO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "APO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Appointment
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("CSO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "CSO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Construction
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("EXO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "EXO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Exhibition
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("INO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "INO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Inside
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("LFO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "LFO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Liftgate
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("MIO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "MIO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Military
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("RSO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "RSO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Residential
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("SCO");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "SCO"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        School
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="checkbox-inline">
                      <label className="font-weight-bolder pr-5">OTHER:</label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("HAZ");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "HAZ"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Hazmat
                      </label>
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          name="Checkboxes2"
                          onChange={(e) => {
                            try {
                              var accessorialList = accessorialsList;
                              if (e.target.checked) {
                                accessorialList.push("SSH");
                              } else {
                                accessorialList = accessorialList.filter(
                                  (x) => x !== "SSH"
                                );
                              }
                              setAccessorialsList(accessorialList);
                            } catch (err) {}
                          }}
                        />
                        <span></span>
                        Single Shipment
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-12 col-lg-12">
                <div
                  className="label label-lg label-light-info label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Carriers
                </div>
              </div>
              <div className="p-5">
                <div className="col-xl-12 col-lg-12">
                  <div class="form-group">
                    <div class="checkbox-inline">
                      {LtlCarriers.length > 0 &&
                        LtlCarriers.map((item, index) => (
                          <label
                            class="checkbox pr-3 mb-md-8"
                            style={{
                              display: "inline-grid",
                              width: "11%",
                            }}
                          >
                            <input
                              type="checkbox"
                              name="Checkboxes2"
                              checked={item.IsChecked}
                              onChange={(e) => {
                                try {
                                  let values = [...LtlCarriers];
                                  var stringfyJson = JSON.stringify(values);
                                  var parseJson = JSON.parse(stringfyJson);
                                  parseJson[index].IsChecked = e.target.checked;
                                  var currentList = carriersHash;
                                  if (e.target.checked) {
                                    currentList.push(item.Hash);
                                  } else {
                                    currentList = currentList.filter(
                                      (x) => x !== item.Hash
                                    );
                                  }
                                  setCarriersHash(currentList);
                                  setLtlCarriers(parseJson);
                                } catch (err) {}
                              }}
                            />
                            <span></span>
                            <div class="symbol-list d-flex flex-wrap">
                              <div class="symbol mr-3">
                                <img
                                  src={fetchImgCarriers(item.Name)}
                                  className="pb-3"
                                  alt={item.Name}
                                />
                              </div>
                            </div>
                            {item.Name}
                          </label>
                        ))}
                    </div>
                  </div>
                </div>
                {/* Button Generate */}
                <div className="col-xl-12 col-lg-12">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      try {
                        if (carriersHash.length === 0) {
                          Notification(
                            "error",
                            "You should select a carrier first."
                          );
                          return false;
                        }
                        setIsSpinnerLoading(true);
                        const postObject = {
                          Id: SO,
                          OriginCity: values?.ShipperAddress,
                          OriginState: values?.ShipperState,
                          OriginZipcode: values?.ShipperZipCode?.split(
                            "-"
                          ).shift(),
                          OriginCountry: "US",
                          DestinationCity: values?.ConsigneeCity,
                          DestinationState: values?.ConsigneeState,
                          DestinationZipcode: values?.ConsigneeZipCode?.split(
                            "-"
                          ).shift(),
                          DestinationCountry: "US",
                          AccessorialsList: accessorialsList,
                          CarrierHashes: carriersHash,
                        };
                        setShowResults(true);
                        await PostGenerate7LRates(postObject);
                        setIsSpinnerLoading(false);
                      } catch (err) {
                        setIsSpinnerLoading(false);
                      }
                    }}
                  >
                    Generate
                  </button>
                </div>
              </div>
            </Row>
            <br />
            {showResults ? (
              <>
                {!isSpinnerLoading ? (
                  <>
                    <BootstrapTable
                      // ref={ n => this.node = n }
                      keyField="Id"
                      data={datatable}
                      columns={columnsLTLRate}
                      pagination={paginationFactory({
                        showTotal: true,
                        sizePerPageList: [
                          { text: "5", value: 5 },
                          { text: "10", value: 10 },
                          {
                            text: "All",
                            value: datatable?.length,
                          },
                        ],
                      })}
                      wrapperClasses="table-responsive stopfont"
                      classes="table table-head-custom table-vertical-center mt-4"
                      bootstrap4
                      bordered={false}
                      expandRow={expandRowQuotes}
                    />

                    <Modal
                      show={modalPercentage}
                      size="lg"
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-lg">
                          Percentage Rate Carrier :{" "}
                          {ratesRow?.Code + " - " + ratesRow?.Name} #:{" "}
                          {ratesRow?.Number}
                        </Modal.Title>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={() => {
                            setModalPercentage(false);
                            setPercentageValue(20);
                            setTotalReceivable("0.00");
                            setTotalPayable("0.00");
                            setTotalMargin("0.00");
                            setLoaderData(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body className="row">
                        <div className="col-lg-12 mb-md-10">
                          <label>Percentage (%)</label>
                          <Field
                            className="form-control"
                            type="number"
                            value={percentageValue}
                            onChange={(e) => {
                              setPercentageValue(e.target.value);
                              fetchSelectedRates(e.target.value);
                            }}
                          />
                        </div>

                        <div className="col-lg-12">
                          <Paper className={classes.paper}>
                            <Table
                              className={`LineItems ${classes.table}`}
                              size="small"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left">Name</TableCell>
                                  <TableCell align="left">Payable</TableCell>
                                  <TableCell align="left">Receivable</TableCell>
                                  <TableCell align="left">Margin</TableCell>
                                  <TableCell align="left">Percentage</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {ratesCalculated?.map((row, index) => (
                                  <TableRow key={row.name}>
                                    <TableCell
                                      align="left"
                                      className={
                                        row.Name === "DISCOUNT"
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {row.Name}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={
                                        row.Name === "DISCOUNT"
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {currencyFormat(row.Payable)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={
                                        row.Name === "DISCOUNT"
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {currencyFormat(row.Receivable)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={
                                        row.Name === "DISCOUNT"
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      {currencyFormat(row.Margin)}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className={
                                        row.Name === "DISCOUNT"
                                          ? "text-danger"
                                          : ""
                                      }
                                    >
                                      <input
                                        disabled={
                                          row.Name === "DISCOUNT" ? true : false
                                        }
                                        type="number"
                                        value={row.Percentage}
                                        className="form-control"
                                        onChange={(e) => {
                                          try {
                                            const values = [...ratesCalculated];
                                            const value = e.target.value;
                                            values[index].Percentage = value;
                                            values[index].Payable = row.Payable;
                                            values[index].Rate = row.Payable;
                                            values[index].Receivable =
                                              row.Payable +
                                              (row.Payable * value) / 100;
                                            values[index].Margin =
                                              row.Receivable - row.Payable;

                                            var totalReceivableCalc = calculateTotal(
                                              values,
                                              true,
                                              true
                                            );
                                            setPercentageValue("");
                                            setTotalReceivable(
                                              totalReceivableCalc
                                            );

                                            setTotalMargin(
                                              totalReceivableCalc - totalPayable
                                            );
                                            setRatesCalculated(values);
                                          } catch (err) {}
                                        }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell align="left">
                                    <span className="font-weight-boldest">
                                      Totals
                                    </span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className="font-weight-boldest">
                                      {currencyFormat(totalPayable)}
                                    </span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className="font-weight-boldest">
                                      {currencyFormat(totalReceivable)}
                                    </span>
                                  </TableCell>
                                  <TableCell align="left">
                                    <span className="font-weight-boldest">
                                      {currencyFormat(totalMargin)}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Paper>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              setModalPercentage(false);
                              setPercentageValue(20);
                              setTotalReceivable("0.00");
                              setTotalPayable("0.00");
                              setTotalMargin("0.00");
                            }}
                            className="btn btn-light btn-elevate"
                          >
                            Close
                          </button>
                          <></>
                          <button
                            type="button"
                            onClick={async () => {
                              try {
                                setLoaderData(true);
                                let selectedObject = carrierRowSelect;
                                selectedObject.Rates = ratesCalculated;
                                selectedObject.AccessorialsList = accessorialsList;
                                selectedObject.Id = SO;
                                await Select7LQuote(selectedObject);
                                //DispatchReport
                                await GetViewModel(statSHipmentId);
                                setModalPercentage(false);
                                setPercentageValue(20);
                                setTotalReceivable("0.00");
                                setTotalPayable("0.00");
                                setTotalMargin("0.00");
                                setLoaderData(false);
                                setLoaderSo(true);
                              } catch (err) {
                                setLoaderData(false);
                              }
                            }}
                            className="btn btn-primary btn-elevate ml-4"
                          >
                            Save Quote
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>

                    <Modal
                      show={showModalRates}
                      size="lg"
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-lg">
                          Rate Carrier :{" "}
                          {ratesRow?.Code + " - " + ratesRow?.Name}
                        </Modal.Title>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={() => setShowRatesModal(false)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body className="row">
                        <DenseTableSm
                          rowsName={[
                            {
                              Name: "Name",
                              Title: "Name",
                            },
                            {
                              Name: "Rate",
                              Title: "Rate",
                            },
                          ]}
                          rows={ratesRow?.Rates || []}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <div>
                          <button
                            type="button"
                            onClick={() => setShowRatesModal(false)}
                            className="btn btn-light btn-elevate"
                          >
                            Close
                          </button>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </>
                ) : (
                  <div className="col-12">
                    <div className="row justify-content-center my-10">
                      <Spinner2 name="folding-cube" color="blue" />
                    </div>
                  </div>
                )}
                <LoadingDialog isLoading={loaderData} />
              </>
            ) : null}
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader title="Commodity Details">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedCommodity(isCollapsedCommodity ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedCommodity ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>{" "}
        </CardHeader>
        <CardBody className={isCollapsedCommodity ? "d-none" : ""}>
          {isLinearProgress && <LinearProgress />}
          <div className="col-xl-12 col-lg-12">
            {ItemsTmsComponent(values)}
            <div className="form-group row mt-5">
              <div className="col">
                <button
                  type="button"
                  onClick={addClick.bind(this)}
                  style={{
                    float: "right",
                    width: "12%",
                  }}
                  className="btn btn-light-success w-40"
                >
                  <i
                    className={`fas fa-plus fa-sm`}
                    style={{
                      paddingRight: "initial",
                    }}
                  ></i>{" "}
                </button>
                <button
                  type="button"
                  onClick={async () => {
                    setIsLinearProgress(true);
                    await GetItemsTms(SO);
                    setIsLinearProgress(false);
                  }}
                  style={{
                    float: "right",
                    width: "12%",
                  }}
                  className="btn btn-light-info w-40 mr-5"
                >
                  <i
                    className={`fas fa-sync-alt fa-sm`}
                    style={{
                      paddingRight: "initial",
                    }}
                  ></i>{" "}
                </button>
                {itemsTms.length > 0 && (
                  <button
                    type="button"
                    id="tmsItemsSave"
                    disabled={
                      !itemsTms.some(
                        (x) => (x?.Id && x.IsEditMode) || x?.IsPost
                      )
                    }
                    class="btn btn-light-primary mr-5"
                    style={{
                      float: "right",
                      width: "12%",
                    }}
                    onClick={async () => {
                      try {
                        itemsTms?.length > 0 &&
                          itemsTms.map(async (el) => {
                            if (el?.Id && el?.IsEditMode) {
                              await PutItemTms(el?.Id, el);
                              Notification(
                                "success",
                                `Item #: ${el?.Id} has been updated successfully`
                              );
                              setTimeout(async () => {
                                await GetItemsTms(SO);
                              }, 500);
                            }
                            if (el?.IsPost) {
                              el.ShipmentId = SO;
                              await PostItemTms(el);
                            }
                          });
                      } catch (err) {}
                    }}
                  >
                    <i
                      class="fas fa-save fa-sm"
                      style={{
                        paddingRight: "initial",
                      }}
                    ></i>
                  </button>
                )}
                <LoadingDialog isLoading={loaderData} />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      {/* Drag and Drop / Items */}
      <Card>
        <CardHeader title="Warehouse Items">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedCommodityDrag(
                    isCollapsedCommodityDrag ? false : true
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedCommodityDrag ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody className={isCollapsedCommodityDrag ? "d-none" : ""}>
          <div className="col-xl-12 col-lg-12">
            <Row className="mb-md-15">
              <Col>
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  bordered={false}
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  id="SPFsList"
                  keyField="Id"
                  noDataIndication={
                    <h4 style={{ textAlign: "center" }} className="text-muted">
                      {"There is no data available at the moment"}.
                    </h4>
                  }
                  data={initProduct?.ShipmentItems}
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPage: 5,
                    sizePerPageList: [
                      { text: "5", value: 5 },
                      { text: "10", value: 10 },
                    ],
                  })}
                  columns={columnsItems}
                ></BootstrapTable>
              </Col>
            </Row>
            <Row>
              <Col>
                <label className="font-weight-boldest">Total Quantity :</label>
                <div
                  className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                  style={{ fontSize: "15px" }}
                >
                  {calculateTotalItems(initProduct?.ShipmentItems, "Quantity")}
                </div>
              </Col>
              <Col>
                {" "}
                <Col>
                  <label className="font-weight-boldest">Total Weight :</label>
                  <div
                    className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    {calculateTotalItems(initProduct?.ShipmentItems, "Weight")}
                  </div>
                </Col>
              </Col>
              <Col>
                {" "}
                <Col>
                  <label className="font-weight-boldest">Total Cube :</label>
                  <div
                    className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    {calculateTotalItems(initProduct?.ShipmentItems, "Cube")}
                  </div>
                </Col>
              </Col>
            </Row>
            <div className="form-group row mt-5">
              <div className="col">
                <LoadingDialog isLoading={loaderData} />
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom-0" title="AP/Invoicing">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedInvoicing(isCollapsedInvoicing ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedInvoicing ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>{" "}
        </CardHeader>
        <CardBody className={isCollapsedInvoicing ? "d-none" : ""}>
          <div className="row mb-md-20">
            <div className="col">
              <h3 className="mb-3">Bill To Rating </h3>
              <BillToRating
                objectTms={values}
                setCharges={setCharges}
                charges={charges}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h3 className="mb-3">Vendor Rating </h3>
              <VendorRating
                setFieldValue={setFieldValue}
                objectTms={values}
                setVendorsPayables={setVendorPayables}
                vendorPayables={vendorPayables}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className="border-bottom-0" title="Attachments">
          <CardHeaderToolbar>
            <button
              className="btn btn-light-primary py-3 pl-md-8 pr-md-8 ml-5"
              onClick={() => {
                try {
                  setIsCollapsedAttachments(
                    isCollapsedAttachments ? false : true
                  );
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {isCollapsedAttachments ? (
                <i class="fas fa-chevron-up" style={{ fontSize: "18px" }}></i>
              ) : (
                <i class="fas fa-chevron-down" style={{ fontSize: "18px" }}></i>
              )}
            </button>
          </CardHeaderToolbar>{" "}
        </CardHeader>
        <CardBody className={isCollapsedAttachments ? "d-none" : ""}>
          {values !== null &&
          AttachmentTypes.length > 0 &&
          AccessLevels.length > 0 ? (
            <OrderAttachementsV2
              id={SO}
              AttachmentList={values.Attachments || []}
              AttachmentTypes={AttachmentTypes}
              AccessLevels={AccessLevels}
              Type="shipment"
            />
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </>
  );
}
