import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { Fragment, useState } from "react";
import { Dropdown, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import { currencyFormat, Export } from "../../../../../../../_metronic/_helpers/ATHelpers";
import { DropdownCustomTogglerPrimary } from "../../../../../../../_metronic/_partials/dropdowns/DropdownCustomToggler";
import { Notification } from "../../../../../Notification";
import { ChargesModel } from "../../../Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
import { OneMoveMaster } from "../../../Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import SaveIcon from "@material-ui/icons/Save";
import { SendEmail } from "../SendEmail";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function MenuIndex(props) {
  async function GetShipmentManifest(link) {
    return await fetch(window.$apiurl + link, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const OneMoveMasterFunction = () => {
    const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);
    return (
      <>
        <Modal
          size="xl"
          show={OneMoveMasterShow}
          onHide={() => {
            setOneMoveMasterShow(false);
          }}
        >
          <OneMoveMaster Show={setOneMoveMasterShow} statSHipmentId={props?.row?.id} />
        </Modal>
      </>
    );
  };
  const SendEmailFunction = () => {
    const [showModalSend, setshowModalSend] = useState(false);
    return (
      <>
        {showModalSend && (
          <SendEmail
            row={props?.row}
            showModalSend={showModalSend}
            setshowModalSend={setshowModalSend}
          />
        )}
        <a
          href={() => false}
          className="navi-link"
          onClick={() => {
           setshowModalSend(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary pr-2">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
            />
          </span>
          <span className="navi-text">Send Documents</span>
        </a>
      </>
    );
  };
  return (
    <Fragment>
      <Dropdown className="dropdown-inline position-unset" alignRight>
        <Dropdown.Toggle
          className="btn btn-icon btn-light-primary btn-hover-primary btn-sm"
          id="dropdown-toggle-top"
          as={DropdownCustomTogglerPrimary}
        >
          <i className="ki ki-bold-more-hor" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
          <ul className="navi navi-hover py-5">
            {/* BOL */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  props.modalActions.setRowItem(props.row?.id);
                  props.modalActions.setBol(true);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary pr-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Devices/profitloss.svg"
                    )}
                  />
                </span>
                <span className="navi-text">BOL</span>
              </a>
            </li>
            {/* Shipment Manifest */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  props.modalActions.setRowItem(props?.row?.id);
                  props.modalActions.setOutboundManifest(true);
                  localStorage.setItem(
                    "SmManifestCustomer",
                    JSON.stringify(props?.row?.CustomerName)
                  );
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-info pr-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Incoming-box.svg"
                    )}
                  />
                </span>
                <span className="navi-text">Shipment Manifest</span>
              </a>
            </li>{" "}
            {/* Rate Confirmation */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  props.modalActions.setRowItem(props?.row?.id);
                  props.modalActions.setModeItem(props?.row?.Mode);
                  props.modalActions.setRateConfirmation(true);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-warning pr-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Outgoing-box.svg"
                    )}
                  />
                </span>
                <span className="navi-text">Rate Confirmation</span>
              </a>
            </li>{" "}
            {/* P & L */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  props.modalActions.setRowItem(props.row?.Id);
                  props.modalActions.setPL(true);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-info pr-2">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Devices/profitloss.svg"
                    )}
                  />
                </span>
                <span className="navi-text">P & L</span>
              </a>
            </li>{" "}
            {/* Export 1 */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={async () => {
                  try {
                    let data = [];
                    const currentList = [];
                    if (props?.row?.CustomerName !== "Lulus") {
                      data = await GetShipmentManifest(
                        `/Shipments/OutboundManifest/${props?.row?.id}`
                      );
                      if (data.ShipItems.length > 0) {
                        data.ShipItems.map((item) => {
                          currentList.push({
                            PO: item.PurchaseOrder,
                            SKU: item.Sku,
                            Description: item.Description,
                            CasePack: item.CasePack,
                            ShipQTY: item.ShipQuantity,
                            ShipUOM: item.ShipUom,
                            Weight_LBS: currencyFormat(item.Weight),
                            Cube_CBM: currencyFormat(item.Cube),
                            ItemQty: item.Pieces,
                            Container: item.EquipmentRef,
                          });
                          return item;
                        });
                        Export(
                          currentList,
                          "excel",
                          `Shipment_Manifest#_${props?.row?.id}`
                        );
                      } else {
                        Notification(
                          "warning",
                          `No Data Items on this Shipment# ${props?.row?.id}`
                        );
                      }
                    } else {
                      data = await GetShipmentManifest(
                        `/pallets/GetPalletByShipment/${props?.row?.id}`
                      );
                      data.pallets?.length > 0 &&
                        data.pallets.map((itemX) => {
                          itemX.ItemsList.length > 0 &&
                            itemX.ItemsList.map((itemY) => {
                              itemY.PalletId = itemX.Id;
                              currentList.push(itemY);
                              return itemY;
                            });
                          return itemX;
                        });
                      if (data.pallets?.length > 0) {
                        Export(
                          currentList,
                          "excel",
                          `Shipment_Manifest#_${props?.row?.id}`
                        );
                      } else {
                        Notification(
                          "warning",
                          `No Data Items on this Shipment# ${props?.row?.id}`
                        );
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-success text-success pr-2">
                  <SaveIcon color="#1BC5BD" />
                </span>
                <span className="navi-text">
                  Export Shipment Manifest (XLSX)
                </span>
              </a>
            </li>
            {/* Export 2 */}
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={async () => {
                  try {
                    let data = [];
                    const currentList = [];
                    if (props?.row?.CustomerName !== "Lulus") {
                      data = await GetShipmentManifest(
                        `/Shipments/OutboundManifest/${props?.row?.id}`
                      );
                      if (data.ShipItems.length > 0) {
                        data.ShipItems.forEach((item) => {
                          currentList.push({
                            PO: item.PurchaseOrder,
                            SKU: item.Sku,
                            Description: item.Description,
                            CasePack: item.CasePack,
                            ShipQTY: item.ShipQuantity,
                            ShipUOM: item.ShipUom,
                            Weight_LBS: currencyFormat(item.Weight),
                            Cube_CBM: currencyFormat(item.Cube),
                            ItemQty: item.Pieces,
                            Container: item.EquipmentRef,
                          });
                        });
                        Export(
                          currentList,
                          "csv",
                          `Shipment_Manifest#_${props?.row?.id}`
                        );
                      } else {
                        Notification(
                          "warning",
                          `No Data Items on this Shipment# ${props?.row?.id}`
                        );
                      }
                    } else {
                      data = await GetShipmentManifest(
                        `/pallets/GetPalletByShipment/${props?.row?.id}`
                      );
                      data.pallets?.length > 0 &&
                        data.pallets.forEach((itemX) => {
                          itemX.ItemsList.length > 0 &&
                            itemX.ItemsList.forEach((itemY) => {
                              itemY.PalletId = itemX.Id;
                              currentList.push(itemY);
                            });
                        });
                      if (data.pallets?.length > 0) {
                        Export(
                          currentList,
                          "csv",
                          `Shipment_Manifest#_${props?.row?.id}`
                        );
                      } else {
                        Notification(
                          "warning",
                          `No Data Items on this Shipment# ${props?.row?.id}`
                        );
                      }
                    }
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-info text-info pr-2">
                  <SaveIcon color="#1BC5BD" />
                </span>
                <span className="navi-text">
                  Export Shipment Manifest (CSV)
                </span>
              </a>
            </li>
            {/* Charges */}
            <li className="navi-item">
              <ChargesModel
                id={props?.row?.id}
                path={"shipments"}
                isDropdown={true}
              />
            </li>
            <li className="navi-item">
              <SendEmailFunction />
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
      <OneMoveMasterFunction />
    </Fragment>
  );
}
