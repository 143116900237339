import React, { useState, useEffect } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

const MenuItem = ({ text, selected, progress, id, Color }) => {
  return (
    <div className={`OrdersClass mx-3 p-0`} id={"id" + id} data-number={text}>
      <div
        className="progress position-relative"
        style={{
          width: "200px",
          height: "36px",
          cursor: "pointer",
          backgroundColor: selected ? "#3699FF44" : "#d6d6e085",
        }}
      >
        <div
          className={`progress-bar progress-bar-striped ${
            selected ? "progress-bar-animated" : ""
          } p-0`}
          role="progressbar"
          style={{
            width: progress + "%",
            backgroundColor: selected ? "#3699FF" : "#d0d0da",
          }}
          aria-valuenow="60"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
        <p
          className={
            "position-absolute w-100 pt-3 " +
            (selected ? "text-white" : "text-primary")
          }
          style={{
            zIndex: 1,
            left: "13%",
            top: "-3px",
          }}
        >
          <i
            className={
              "fas fa-truck " + (selected ? "text-white" : "text-primary")
            }
            style={{
              fontSize: "15px",
            }}
          ></i>
          <strong
            style={{
              fontSize: "14px",
              marginLeft: "5px",
            }}
          >
            {text}
            <small
              style={{
                fontWeight: "bold",
                marginLeft: "18px",
                background: selected ? "#3699FF" : "#d6d6e085",
                borderRadius: "50%",
                height: "24px",
                width: "28px",
                display: "-webkit-inline-box",
                paddingTop: "4px",
                paddingLeft: "4px",
                fontSize: "10px",
              }}
            >
              {progress?.toFixed(0)}%
            </small>
          </strong>
        </p>
      </div>
    </div>
  );
};

const Menu = (list, selected, Color) =>
  list.map((el) => {
    const { EquipmentNumber, progress, wareHouse, Id } = el;
    return (
      <MenuItem
        text={EquipmentNumber ?? Id}
        key={Id}
        selected={selected}
        progress={progress}
        wareHouse={wareHouse}
        id={Id}
        Color={Color}
      />
    );
  });

export function ScrollingMenuReceipt({
  list = [],
  setselectedId,
  Color = "info",
  Title = "",
}) {
  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };

  const ArrowLeft = Arrow({
    text: "",
    className: "fa fa-chevron-circle-left fSize2 text-" + Color,
  });
  const ArrowRight = Arrow({
    text: "",
    className: "fa fa-chevron-circle-right fSize2 text-" + Color,
  });

  const [selected, setselected] = useState();
  const menu = Menu(list, selected, Color);

  useEffect(() => {
    if (list?.length > 0) {
      var a = list[0]?.Id;
      onSelect(a);
    }
  }, [list]);

  const onSelect = (key) => {
    setselectedId(false);
    setTimeout(() => {
      setselectedId(key);
    }, 1);
    setselected(key);
  };

  return (
    <>
      <div className="col-12 alert alert-light row p-0">
        <div className={"col-xl-2 col-lg-2 col-md-2 my-2 p-3 text-" + Color}>
          <b>{Title}</b>
        </div>
        <div className="col-xl-10 col-lg-10 col-md-10 my-2">
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={onSelect}
            scrollToSelected={true}
          />
        </div>
      </div>
    </>
  );
}
