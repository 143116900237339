import React, { useEffect, useState } from "react";
import { Form, Card, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import axios from "axios";
import {
  Input,
  Select
} from "../../../_metronic/_partials/controls";
import {
  useParams
} from "react-router-dom";
import { Notification } from "../../modules/Notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as Yup from "yup";
import TextField from "@material-ui/core/TextField";
import { RemarksUIProviderCustomField } from "./purchaseOrderLineItems/RemarksUIContext";
import { RemarksCustomFields } from "./purchaseOrderLineItems/Remarks";

var eventChange = new Event("change", { bubbles: true });

function eventChangefunction(id) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.dispatchEvent(eventChange);
    }, 500);
}

function SetValuefunction(id, value) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
      element.setAttribute("value", value);
    }, 500);
}
export function EditPurchaseOrder() {
  const { id } = useParams("id");
  const initalizePurchaseOrder = {
    Address1: "",
    Address2: "",
    City: "",
    CompanyName: "",
    Country: "",
    Email: "",
    Phone: "",
    DepartmentId: "",
    IssueDate: "",
    Notes: "",
    State: "",
    ZipCode: "",
    SupplierId: "",
    SupplierName: "",
    Number: ""
  }
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [showProgress, setshowProgress] = useState(false);
  const [showSupplierProgress, setshowSupplierProgress] = useState(false);
  const [showGeneralProgress, setshowGeneralProgress] = useState(true);
  const [purchaseOrder, setPurchaseOrder] = useState(initalizePurchaseOrder);
  const purchaseOrderSchema = Yup.object().shape({
    CompanyName: Yup.string()
      .required("Company name is required"),
    Address1: Yup.string()
      .required("Address 1 is required"),
    City: Yup.string()
      .required("City is required"),
    Country: Yup.string()
      .required("Country is required"),
    ZipCode: Yup.string()
      .required("Zip Code is required")
  });
  useEffect(() => {
    fetchDataAsync();
  }, []);
  useEffect(() => {
    var issueDate = purchaseOrder.IssueDate || false;
    if (issueDate) {
      SetValuefunction("issueDateId", issueDate);
    }
    var supplierId = purchaseOrder.SupplierId || false;
    if (supplierId) {
      SetValuefunction("SupplierId", supplierId);
    }
  }, [purchaseOrder]);

  const fetchDataAsync = async () => {
    try {
      const result = await axios.get(`api/purchaseorders/${id}`);
      console.log("result");
      console.log(result);
      const resultCountries = await axios.get("api/companyinfo/getcountries");
      if (result.status == 200) {
        if (result.data.Country) {
          const resultStates = await axios.get(`api/companyinfo/getstates/${result.data.Country}`);
          if (resultStates.status == 200) {
            if (resultStates.data.length > 0)
              setStates(resultStates.data);
          }
        }
        if (result.data.CustomerId) {
          const resultSuppliers = await axios.get(`api/purchaseorders/suppliers/${result.data.CustomerId}`);
          if (resultSuppliers.status == 200) {
            if (resultSuppliers.data.length > 0)
              setSuppliers(resultSuppliers.data);
          }
        }
        setPurchaseOrder(result.data);
        document.getElementById("State").value = result.data.State;
        setshowGeneralProgress(false);
      }
      if (resultCountries.status == 200) {
        setCountries(resultCountries.data);
      }
    } catch (e) {
      console.log(e);
      setshowGeneralProgress(false);
    }
  };

  const handleCountryChange = async (event) => {
    setshowProgress(true);
    setStates([]);
    if (event.target.value !== "" && event.target.vale !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event.target.value}`);
      if (result.status == 200) {
        if (result.data.length > 0)
          setStates(result.data);
        else
          Notification("warning", "This Country doesn't have any state.");
      }
    }
    setshowProgress(false);
  }

  async function handleChange(event, setFieldValue) {
    setshowSupplierProgress(true);
    try {
      setFieldValue("SupplierId", event.target.value);
      let supplierId = event.target.value;

      setFieldValue("SupplierName", "");
      setFieldValue("CompanyName", "");
      setFieldValue("Address1", "");
      setFieldValue("Address2", "");
      setFieldValue("ZipCode", "");
      setFieldValue("Country", "");
      setFieldValue("State", "");
      setFieldValue("City", "");
      setFieldValue("DepartmentId", "");
      setFieldValue("Phone", "");
      setFieldValue("Email", "");


      if (supplierId) {
        var newSupplier = await axios.get(`api/purchaseorders/suppliers/details/${supplierId}`);
        if (newSupplier.status == 200) {
          setStates([]);
          const result = await axios.get(`api/companyinfo/getstates/${newSupplier.data.Country}`);
          if (result.status == 200) {
            if (result.data.length > 0)
              setStates(result.data);
          }
          setFieldValue("SupplierName", newSupplier.data.Name);
          setFieldValue("CompanyName", newSupplier.data.CompanyName);
          setFieldValue("Address1", newSupplier.data.Address1);
          setFieldValue("Address2", newSupplier.data.Address2);
          setFieldValue("ZipCode", newSupplier.data.ZipCode);
          setFieldValue("Country", newSupplier.data.Country);
          setFieldValue("State", newSupplier.data.State);
          setFieldValue("City", newSupplier.data.City);
          setFieldValue("DepartmentId", newSupplier.data.DepartmentId);
          setFieldValue("Phone", newSupplier.data.Phone);
          setFieldValue("Email", newSupplier.data.Email);
        }
      }
    } catch (e) { }
    setshowSupplierProgress(false);
  }

  const updatePurchaseOrder = async (purchaseOrder) => {
    const result = await axios.put(`api/purchaseorders/${id}`, { purchaseOrder });
    if (result.data.success === true) {
      Notification("success", "Purchase order updated successfully.");
      const resultSuppliers = await axios.get(`api/purchaseorders/suppliers/${purchaseOrder.CustomerId}`);
      if (resultSuppliers.status == 200) {
        if (resultSuppliers.data.length > 0)
          setSuppliers(resultSuppliers.data);
      }
    }
    else {
      const msg = result.data.Message === undefined ? "Something went wrong, please try later or contact the support." : result.data.Message;
      Notification("error", msg);
    }
  };

  function newSupplier(setFieldValue) {
    if (document.getElementById("SupplierId").value) {
      document.getElementById("SupplierId").value = "";
      setFieldValue("SupplierId", null);
      Notification("warning", "You'll create a new supplier");
    }
  }
  return (
    <Card>
      <Card.Header>
        <Form>
          <Form.Row>
            <Form.Group controlId="formGridEmail" className="row pl-4 mb-0">
              <Form.Label className="m-4">Customer</Form.Label>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input type="text" className="form-control" aria-label="Text input with dropdown button" value={purchaseOrder.Customer} disabled />
                    {showGeneralProgress && <LinearProgress color="secondary" />}
                  </div>
                </div>
              </div>
              <Form.Label className="m-4">P.O.#</Form.Label>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input type="text" className="form-control" aria-label="Text input with dropdown button" value={purchaseOrder.Number} disabled />
                    {showGeneralProgress && <LinearProgress color="secondary" />}
                  </div>
                </div>
              </div>
              <Form.Label className="m-4">P.O ID</Form.Label>
              <div className="form-group row">
                <div className="col-lg-12">
                  <div className="input-group">
                    <input type="text" className="form-control" aria-label="Text input with dropdown button" value={id} disabled />
                  </div>
                </div>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={purchaseOrder}
          validationSchema={purchaseOrderSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            updatePurchaseOrder(values);
          }}>
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form.Row>
                <div className='col-12 mb-3'>
                  <h3 className="font-weight-bold text-primary">PO Header</h3>
                  <hr className="font-weight-bold text-primary"></hr>
                </div>
                <div className="col-6">
                  <Form.Label>Issue Date</Form.Label>
                  <TextField
                    id="issueDateId"
                    label=""
                    type="datetime-local"
                    className="w-100"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onBlur={(event) => {
                      setFieldValue("IssueDate", event.target.value);
                    }}
                  />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                </div>
                <div className="col-6">
                  <Form.Label>Notes</Form.Label>
                  <Field className="form-control" type="text" as="textarea" rows="4" name="Notes" placeholder="Write your notes" />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-12 mb-5'>
                  <h5 className="font-weight-bold text-default">Choose Supplier</h5>
                </div>
                <div className='col-12 mb-5'>
                  <Form.Label>Supplier</Form.Label>
                  <select name="SupplierId" id="SupplierId" className="form-control" onChange={(event) => handleChange(event, setFieldValue)}>
                    <option value="">Choose a supplier</option>
                    {suppliers.map((item, index) => (
                      <option key={index} value={item.Id}>{item.CompanyName}</option>
                    ))}
                  </select>
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-12 mb-5'>
                  <h5 className="font-weight-bold text-default">Or Create Supplier</h5>
                </div>

                <div className='col-6'>
                  <Form.Label>Name</Form.Label>
                  <Field name="SupplierName" id="SupplierName" type="text" component={Input} placeholder="Name" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-6'>
                  <Form.Label>Company</Form.Label>
                  <Field name="CompanyName" id="CompanyName" type="text" component={Input} placeholder="Company" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>

                <div className='col-4 mt-3'>
                  <Form.Label>Address1</Form.Label>
                  <Field name="Address1" id="Address1" type="text" component={Input} placeholder="Address1" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>Address2</Form.Label>
                  <Field name="Address2" id="Address2" type="text" component={Input} placeholder="Address2" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>ZIP</Form.Label>
                  <Field name="ZipCode" id="ZipCode" type="text" component={Input} placeholder="ZIP" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>

                <div className='col-4 mt-3'>
                  <Form.Label>Country</Form.Label>
                  <Select className="form-control" name="Country" id="Country" onChange={(event) => {
                    handleCountryChange(event);
                    setFieldValue("Country", event.target.value);
                    newSupplier(setFieldValue);
                  }}>
                    <option key="" value="" >Choose a country</option>
                    {countries.map((item, index) => (
                      <option key={index} value={item.Iso}>{item.Name}</option>
                    ))}
                  </Select>
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>St/Province</Form.Label>
                  <select className="form-control" name="State" id="State" onChange={(event) => {
                    setFieldValue("State", event.target.value);
                    newSupplier(setFieldValue);
                  }}>
                    <option key="" value="" >Choose a state</option>
                    {states.map((item, index) => (
                      <option key={index} value={item.Name}>{item.Name}</option>
                    ))}
                  </select>
                  {showProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>City</Form.Label>
                  <Field name="City" id="City" type="text" component={Input} placeholder="City" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>Dept #</Form.Label>
                  <Field name="DepartmentId" id="DepartmentId" type="text" component={Input} placeholder="Dept #" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>Phone</Form.Label>
                  <Field name="Phone" id="Phone" type="text" component={Input} placeholder="Phone" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-4 mt-3'>
                  <Form.Label>Email</Form.Label>
                  <Field name="Email" id="Email" type="text" component={Input} placeholder="Email" onKeyUp={(event) => { newSupplier(setFieldValue) }} />
                  {showGeneralProgress && <LinearProgress color="secondary" />}
                  {showSupplierProgress && <LinearProgress color="secondary" />}
                </div>
                <div className='col-12 mt-5'>
                  <Button as="input" type="submit" value="Edit" className="btn-success" disabled={showSupplierProgress || showGeneralProgress} readOnly onClick={() => handleSubmit()} />
                </div>
              </Form.Row>
            </>
          )}
        </Formik>
        <div className='col-12 mt-5 mb-3'>
          {/* <h3 className="font-weight-bold text-primary">Line Items</h3> */}
          <hr className="font-weight-bold text-primary"></hr>
        </div>
        {/* <PurchaseLineItems poId={id} /> */}
        <RemarksUIProviderCustomField currentPurchaseOrderId={id}>
          <RemarksCustomFields />
        </RemarksUIProviderCustomField>
      </Card.Body>
    </Card>
  )
}