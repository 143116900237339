import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../modules/Notification";
import { useParams } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {currencyFormat } from "../../../_metronic/_helpers/ATHelpers";

const adapter = new LocalStorage("db");
const db = low(adapter);
function formatDate(date) {
  if (!date) return "";
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const result = [month, day, year].join("/");
  if (result !== "NaN/NaN/NaN") {
    return result;
  } else {
    return "--/--/----";
  }
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj };
  }

  async componentDidMount() {
    await fetch(
      window.$apiurl + "/ShipmentBilling/ShippingProfitLoss/" + this.props.id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", "No data found !");
        }
        const json = await response.json();
        this.setState({ data: json });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const formData = this.state.data;

    //New Date Now
    const start = Date.now();
    const dateNow = formatDate(start);
    console.log("object");
    console.log(formData);
    //minus revenue value
    if (formData.revenuValue < 0) {
      $("#revenuValue").addClass("text-danger");
    }
    return (
      <>
        <Helmet>
          <title>eMPower | PL Form</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="col-md-12">
            <div class="d-flex justify-content-between pt-md-5 flex-column flex-md-row">
              <div class="d-flex flex-column align-items-md-start px-0">
                <Link role="button" class="mb-5 max-w-100px">
                  <span class="svg-icon svg-icon-full">
                    <img
                      src="/media/logos/Mitco.svg"
                      className="w-100"
                      alt="logo mitco"
                    />
                  </span>
                </Link>
                {/* <span class="d-flex flex-column align-items-md-start font-size-h5 font-weight-bold text-muted">
                                <span>Cecilia Chapman, 711-2880 Nulla St, Mankato</span>
                                <span>Mississippi 96522</span>
                            </span> */}
              </div>
            </div>
          </div>
          <div className="card border-0 py-5 bl-form">
            <div className="card-body p-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="d-flex justify-content-center flex-column flex-md-row">
                    <h4 className="display-4 font-weight-boldest mb-10">
                      Profit and Loss Statement
                    </h4>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              Transportation Profit and Loss
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colspan="4"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Bill To:
                            </td>
                            <td colspan="6" className="align-text-top">
                              {formData.BillToAddress?.Name}
                              <br></br>
                              {formData.BillToAddress?.Street} <br></br>
                              {(formData.BillToAddress?.City === undefined
                                ? ""
                                : formData.BillToAddress?.City + " , ") +
                                (formData.BillToAddress?.State === undefined
                                  ? ""
                                  : formData.BillToAddress?.State) +
                                " " +
                                (formData.BillToAddress?.ZipCode === undefined
                                  ? ""
                                  : formData.BillToAddress?.ZipCode)}
                              <br></br>
                              {formData.BillToAddress?.Phone === undefined
                                ? ""
                                : "Phone: " + formData.BillToAddress?.Phone}
                              <br></br>
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="4"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Billing Ref No.:
                            </td>
                            <td colspan="6" className="align-text-top">
                              {formData.BillingRefNumber}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="4"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              P & L Print Date:
                            </td>
                            <td colspan="6" className="align-text-top">
                              {dateNow}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="4"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              ML Shipment Ref. No:
                            </td>
                            <td colspan="6" className="align-text-top">
                              {formData.ShipmentId} [Order:{" "}
                              {formData.OrderId === undefined
                                ? "-"
                                : formData.OrderId}
                              ]
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="4"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Invoice Type:
                            </td>
                            <td colspan="6" className="align-text-top">
                              {formData.InvoiceType}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              Shipment Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Last Ship Date:
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formatDate(formData.LastShipDate)}
                            </td>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Cust. Ref. No.:
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formData.CustomerRefNumber}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Trailer / Container
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formData.TrailerNumber} &nbsp; Seal No.:{" "}
                              {formData.SealNumber === ""
                                ? "-"
                                : formData.SealNumber}
                              &nbsp;
                            </td>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Cust. Ref. No.:Printed by:
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formData.PrintedBy}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Pickup:
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formData.PickUpAddress?.Name}
                              <br></br>
                              {formData.PickUpAddress?.Street}
                              <br></br>
                              {(formData.PickUpAddress?.City === undefined
                                ? ""
                                : formData.PickUpAddress?.City + " , ") +
                                (formData.PickUpAddress?.State === undefined
                                  ? ""
                                  : formData.PickUpAddress?.State) +
                                " " +
                                (formData.PickUpAddress?.ZipCode === undefined
                                  ? ""
                                  : formData.PickUpAddress?.ZipCode)}
                              <br></br>
                              {formData.PickUpAddress?.Phone !== undefined
                                ? formData.PickUpAddress?.Phone
                                : ""}
                            </td>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-right align-text-top"
                            >
                              Deliver to:
                            </td>
                            <td colspan="5" className="align-text-top">
                              {formData.DeliverToAddress?.Name}
                              <br></br>
                              {formData.DeliverToAddress?.Street}
                              <br></br>
                              {(formData.DeliverToAddress?.City === undefined
                                ? ""
                                : formData.DeliverToAddress?.City + " , ") +
                                (formData.DeliverToAddress?.State === undefined
                                  ? ""
                                  : formData.DeliverToAddress?.State) +
                                " " +
                                (formData.DeliverToAddress?.ZipCode ===
                                undefined
                                  ? ""
                                  : formData.DeliverToAddress?.ZipCode)}
                              <br></br>
                              {formData.DeliverToAddress?.Phone === ""
                                ? ""
                                : formData.DeliverToAddress?.Phone}
                              <br></br>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              Rate Charges
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest  align-text-top">
                              Charge Description{" "}
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Invoice Date
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Invoice Number
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Bill Amount
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Payable Amount
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Vendor Name
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Vendor Reference
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Margin
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Margin Percentage %
                            </td>
                          </tr>
                          {formData.RateCharges?.map((item, index) => (
                            <tr>
                              <td className="align-text-top">
                                {item.ChargeDescription}
                              </td>
                              <td className="align-text-top">
                                {formatDate(item.InvoiceDate)}
                              </td>
                              <td className="align-text-top">
                                {item.InvoiceNumber}
                              </td>
                              <td className="align-text-top text-center">
                                ${currencyFormat(item.BillAmount)}
                              </td>
                              <td className="align-text-top text-center">
                                ${currencyFormat(item.PayableAmount)}
                              </td>
                              <td className="align-text-top">
                                {item.VendorName}
                              </td>
                              <td className="align-text-top">
                                {item.VendorReference}
                              </td>
                              <td className="align-text-top text-center">
                                {currencyFormat(item.Margin)}
                              </td>
                              <td className="align-text-top text-center">
                                {item.MarginPercentage}
                              </td>
                            </tr>
                          ))}
                          <tr className="bg-light">
                            <td
                              className="font-weight-boldest align-text-top text-left py-4"
                              colSpan="1"
                            ></td>
                            <td className="align-text-top font-weight-boldest"></td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top"></td>
                            <td
                              className="font-weight-boldest align-text-top text-left"
                              colspan="1"
                            ></td>
                            <td className="align-text-top font-weight-boldest"></td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top"></td>
                          </tr>
                          <tr>
                            <td
                              className="font-weight-boldest align-text-top text-left"
                              colspan="1"
                            ></td>
                            <td className="align-text-top font-weight-boldest"></td>
                            <td
                              class="font-weight-boldest"
                              style={{
                                backgroundColor: "rgb(17, 48, 89)",
                                color: "rgb(255, 255, 255)",
                              }}
                            >
                              GRAND TOTAL
                            </td>
                            <td className="align-text-top font-weight-boldest text-center border-left-0">
                              ${currencyFormat(formData.TotalReceivable)}
                            </td>
                            <td className="align-text-top font-weight-boldest text-center border-left-0">
                              ${currencyFormat(formData.TotalPayable)}
                            </td>
                            <td
                              className="font-weight-boldest align-text-top text-left"
                              colspan="1"
                            ></td>
                            <td
                              class="font-weight-boldest"
                              style={{
                                backgroundColor: "rgb(17, 48, 89)",
                                color: "rgb(255, 255, 255)",
                              }}
                            >
                              REVENUE
                            </td>
                            <td className="align-text-top font-weight-boldest text-center border-left-0">
                              ${currencyFormat(formData.Revenue)}
                            </td>
                            <td className="align-text-top"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Notes */}
                  <div className="row pb-0">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              Notes
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest  align-text-top">
                              Note
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Created By
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Created
                            </td>
                          </tr>
                          {formData.Notes?.length > 0 &&
                            formData.Notes?.map((item, index) => (
                              <tr>
                                <td className="align-text-top">
                                  {item.Message}
                                </td>
                                <td className="align-text-top">
                                  {item.CreatedBy}
                                </td>
                                <td className="align-text-top">
                                  {formatDate(item.Created)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function PLFORM({id}) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden">
      <div className="card-body p-0">
        <div className="container mb-md-5 mt-4">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print PLForm
              </button>
              {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
      </div>
    </div>
  );
}
