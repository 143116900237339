import MockUtils from "./mock.utils3";
import moment from "moment";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;

export default function mockAllOrderBetaTMS(mock) {
  async function GetModel(
    searchText,
    customerId,
    statusId,
    ServiceId,
    ModeId,
    serviceLevelId,
    equipmentTypeId,
    equipmentSizeId,
    shipFrom,
    Carrier,
    DateRangeType,
    startDate,
    endDate,
    isHot,
    isHazardousMaterial,
    IsHeavy,
    ShipmentException,
    Cancled,
    Closed,
    pageNumber,
    pageSize,
    sortField,
    sortOrder,
    srch
  ) {
    if (customerId === undefined) {
      customerId = "?customerId=" + window.$ShipmentManagementCustomerId;
    }
    if (
      window.$ShipmentManagementCustomerId &&
      window.$CustomerIdsSm === "?customerId="
    ) {
      customerId = "?customerId=" + window.$ShipmentManagementCustomerId;
    }
    if (ModeId === undefined) ModeId = window.$ShipmentManagementTMSModeId;
    if (isHot === undefined) isHot = false;
    if (isHazardousMaterial === undefined) isHazardousMaterial = false;
    if (IsHeavy === undefined) IsHeavy = false;
    if (startDate === undefined || startDate === null || startDate === "") {
      startDate =
        window.$SmGsearchStartDate ||
        moment()
          .add(-28, "days")
          .format("YYYY-MM-DD");
    }
    if (endDate === undefined || endDate === null || endDate === "") {
      endDate = moment().format("YYYY-MM-DD");
    }
    if (window.$clearableDate) {
      startDate = "";
      endDate = "";
    }
    if (ShipmentException === undefined) ShipmentException = "";
    if (ServiceId === "" || ServiceId === undefined)
      ServiceId = window.$ShipmentManagementTMSServiceId || "";
    if (serviceLevelId === undefined) serviceLevelId = 0;
    if (equipmentTypeId === undefined) equipmentTypeId = 0;
    if (equipmentSizeId === undefined) equipmentSizeId = 0;
    if (shipFrom === undefined) shipFrom = "";
    if (
      Carrier === undefined ||
      window.$ShipmentManagementTMSStatus === 1 ||
      window.$ShipmentManagementTMSStatus === 2 ||
      window.$ShipmentManagementTMSStatus === 3
    )
      Carrier = "";
    if (DateRangeType === undefined) DateRangeType = "created";
    //exportSearchNameFile
    window.$exportFilterSM =
      "Shipments_Status_" +
      window.$ShipmentManagementTMSStatusName +
      "_Service_" +
      window.$ShipmentManagementTMSServiceName +
      "_Mode_" +
      window.$ShipmentManagementTMSModeName +
      "_Customer_" +
      (window.$CustomerListOverview !== undefined &&
      window.$CustomerListOverview !== ""
        ? window.$CustomerListOverview.map((item) => item + " , ")
        : window.$CustomerListOverview === ""
        ? "All"
        : window.$ShipmentManagementCustomerName) +
      "_StartDate_" +
      startDate +
      "_EndDate_" +
      endDate +
      "_Hot_" +
      window.$ShipmentManagementHot +
      "_Hazmat_" +
      window.$ShipmentManagementHazmat +
      "_IsHeavy_" +
      IsHeavy +
      "_Canceled_" +
      String(Cancled) +
      "_Closed_" +
      String(Closed);
           
    window.exportSMLink = `/transportation/shipments/beta${customerId}&statusId=${statusId}&ServiceId=${ServiceId}&modeId=${ModeId}&serviceLevelId=${serviceLevelId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&shipFromId=${shipFrom}&VendorId=${Carrier}&filterDate=${DateRangeType}&startDate=${startDate}&endDate=${endDate}&isHot=${isHot}&isHazardousMaterial=${isHazardousMaterial}&IsHeavy=${IsHeavy}&exception=${ShipmentException}&IsCancel=${Cancled}&IsClosed=${Closed}&pageNumber=${pageNumber}&pageSize=1000000000&sortField=${sortField}&sortOrder=${sortOrder}&search=${srch}${window.location.href.includes("TransportManagementSystem")?"&category=Transportation":"" }`;
    return await fetch(
      `${window.$apiurl}/transportation/shipments/beta${customerId}&statusId=${statusId}&ServiceId=${ServiceId}&modeId=${ModeId}&serviceLevelId=${serviceLevelId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&shipFromId=${shipFrom}&VendorId=${Carrier}&filterDate=${DateRangeType}&startDate=${startDate}&endDate=${endDate}&isHot=${isHot}&isHazardousMaterial=${isHazardousMaterial}&IsHeavy=${IsHeavy}&exception=${ShipmentException}&IsCancel=${Cancled}&IsClosed=${Closed}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&search=${srch}${window.location.href.includes("TransportManagementSystem")?"&category=Transportation":"" }`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // async function GetModelCount() {
  //   return await fetch(
  //     `${window.$apiurl}/transportation/shipments?customerId=${window.$ShipmentManagementCustomerId}&statusId=${window.$ShipmentManagementStatus}&ServiceId=${window.$ShipmentManagementServiceId}&modeId=${window.$ShipmentManagementModeId}&serviceLevelId=${window.$ShipmentManagementServiceLevelId}`,
  //     {
  //       method: "get",
  //       withCredentials: true,
  //       headers: {
  //         Accept: "application/json, text/plain, */*",
  //         Authorization: `bearer ${db.read().getState().Token}`,
  //         "X-FP-API-KEY": "iphone",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then(async (response) => {
  //       const statusCode = await response.status;
  //       const data = await response.json();
  //       return Promise.all([statusCode, data]);
  //     })
  //     .then((res) => {
  //       return res[1];
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  async function GetViewmodel() {
    return await fetch(
      window.$apiurl + "/transportation/shipments/getviewmodel",
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  //----------------------------------------------------------------
  mock.onGet("api/FindShipments/AllOrderBetaTMS").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });
  // mock.onGet("api/FindShipments/GetAllApiCount").reply(async () => {
  //   const obj = await GetModelCount();
  //   window.$shipmentCount = obj.ShipmentsCount;
  //   window.$ShipmentExceptions = obj.ShipmentExceptions;
  //   return [200, obj];
  // });
  mock.onPost("api/AllOrderBetaTMS").reply(({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Created = "",
      Container = "",
      WHlocation = "",
      Pickuplocation = "",
      Mode = "",
      Vendor = "",
      PickupApt = "",
      PlanDate = "",
      Status = "",
    } = customer;

    const id = generateUserId();
    const newCustomer = {
      Created,
      Container,
      WHlocation,
      Pickuplocation,
      Mode,
      Vendor,
      PickupApt,
      PlanDate,
      Status,
    };
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/AllOrderBetaTMS/find").reply(async (config) => {
   
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const _filter = queryParams;
    window.shipmentCount = undefined;
    var srch = "";
    srch = window.$SmGsearchText || "";
    const gg = await GetModel(
      _filter.filter.searchText,
      window.$CustomerIdsSm,
      window.$ShipmentManagementTMSStatus,
      window.$ShipmentManagementTMSServiceId,
      window.$ShipmentManagementTMSModeId,
      window.$ShipmentManagementServiceLevelId,
      queryParams.filter.equipmentTypeId,
      queryParams.filter.equipmentSizeId,
      queryParams.filter.shipFrom,
      queryParams.filter.Carrier,
      queryParams.filter.DateRangeType,
      window.$SmGsearchStartDate,
      queryParams.filter.endDate,
      queryParams.filter.isHot,
      queryParams.filter.isHazardousMaterial,
      queryParams.filter.IsHeavy,
      window.$ShipmentManagementexception,
      window.$ShipmentManagementCanceled,
      window.$ShipmentManagementClosed,
      queryParams.pageNumber,
      queryParams.pageSize,
      queryParams.sortField,
      queryParams.sortOrder,
      srch
    );
    window.$dataNewShipments = gg.Shipments;
    customerTableMock = ChangeId(gg.Shipments);
    window.shipmentCount = gg.ShipmentsCount;
    window.ShipmentStatus = gg.ShipmentStatus;
    _filter.TotalItems = gg.Pagination.TotalItemCount;

    delete _filter.filter.ReLoadTable;
    delete _filter.filter.searchText;
    delete _filter.filter.shipmentTypeId;
    delete _filter.filter.ServiceId;
    delete _filter.filter.equipmentTypeId;
    delete _filter.filter.equipmentSizeId;
    delete _filter.filter.shipFrom;
    delete _filter.filter.DateRangeType;
    delete _filter.filter.serviceLevelId;
    delete _filter.filter.isHot;
    delete _filter.filter.isHazardousMaterial;
    delete _filter.filter.IsHeavy;
    delete _filter.filter.startDate;
    delete _filter.filter.endDate;
    delete _filter.filter.Filter;
    delete _filter.filter.statusId;
    delete _filter.filter.Cancled;
    const filterdCustomers = mockUtils.baseFilter(customerTableMock, _filter);
    return [200, filterdCustomers];
  });

  mock.onPost("api/AllOrderBetaTMS/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/AllOrderBetaTMS/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/AllOrderBeta\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrderBeta\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/AllOrderBeta\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrderBeta\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/AllOrderBeta\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrderBeta\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
  }
  return list;
}
