import React, { useEffect, useState } from "react";
import Item from "./Item";
import Draggable from "react-draggable";
import { Spinner } from "react-bootstrap";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import { default as JsNative } from "jquery";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  Shipment,
  ReloadPallets,
  setSearch,
  Search,
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);

  const [CheckboxListIds, setCheckboxListIds] = useState([]);
  const [Search2, setSearch2] = useState("");

  useEffect(() => {
    if (Shipment.ShipmentId) GetPalletsFunction(Search);
  }, [Shipment.ShipmentId]);

  async function GetPalletsFunction(search) {
    const pallets = await GetItems(Shipment, search);

    if (pallets?.length > 0) {
      pallets.forEach((x) => {
        x.search =
          x?.PurchaseOrder +
          "|" +
          x?.UnitCount +
          "|" +
          x?.Size +
          "|" +
          x?.Color +
          "|" +
          x?.Description +
          "|";
      });

      var b = pallets?.sort(sortBy((o) => o?.PurchaseOrder));

      var list = [];
      var poString = "";
      b.forEach((x) => {
        if (poString !== x?.PurchaseOrder) {
          list.push({ ...x, PoBar: true });
          poString = x?.PurchaseOrder;
        }
        list.push({ ...x });
      });

      setPallets([]);
      setTimeout(() => {
        setPallets(list);
      }, 1);

      setTimeout(() => {
        setShow(true);
      }, 100);
    }
  }

  const sortBy = (fn) => {
    const cmp = (a, b) => -(a < b) || +(a > b);
    return (a, b) => cmp(fn(a), fn(b));
  };

  JsNative(document).scroll(function() {
    var element = document.getElementById("TableComponent");
    var rect = element?.getBoundingClientRect();
    if (rect && 150 > rect.bottom) {
      JsNative("#TablePalletFixed").fadeIn();
    } else {
      JsNative("#TablePalletFixed").fadeOut();
    }
  });

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) GetPalletsFunction(Search);
  }, [DeletRowId]);

  var tableElement = JsNative(".TableComponent");
  var offset = tableElement.offset();

  function handleDrag(e) {
    const style = "background: #E5EAEE !important";
    if (offset.top < e.pageY && e.pageY < offset.top + tableElement.height()) {
      tableElement.find("th").attr("style", style);
    } else {
      tableElement.find("th").removeAttr("style");
    }
  }

  function handleStop(e) {
    if (offset.top < e.pageY && e.pageY < offset.top + tableElement.height()) {
      PostPallets(CheckboxListIds, Shipment.ShipmentId);
      tableElement.find("th").removeAttr("style");
    }
  }

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }

    setCheckboxListIds([...array]);
  }

  async function PostPallets(ItemsId, PalletId) {
    return await fetch(`${window.$apiurl}/transload/receipt/shipitems`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Items: ItemsId,
        ShipmentId: PalletId,
      }),
    }).then(async (response) => {
      if (response.status === 200) {
        Notification("success", "success : " + ItemsId.join(", "));
        ItemsId.forEach((x) => {
          var a = Pallets.findIndex((y) => x === y.Id);
          SetTableShipment(Pallets[a], Pallets[a].UnitCount);
        });
        setCheckboxListIds([]);
        ReloadPallets();
      } else {
        var msg = response.status !== 200 ? await response.json() : "";
        Notification("error", msg?.toString());
      }
    });
  }

  function POChaekced(id, bool) {
    var list = [];
    Pallets.forEach((x) => {
      if (+x?.PurchaseOrder === +id && +x?.Id !== 0) list.push(+x?.Id);
    });

    if (bool) {
      setCheckboxListIds((x) => [...x, ...list]);
    } else {
      var b = CheckboxListIds;
      list.forEach((x) => {
        b = b.filter((y) => +y !== +x);
      });
      setCheckboxListIds(b);
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-xl-2 col-lg-2 col-md-6 ml-auto my-5">
          <input
            type="text"
            placeholder="Filter by all fields"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) setSearch2(e.target.value);
            }}
            onBlur={(e) => {
              setSearch2(e.target.value);
            }}
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 my-5">
          <input
            type="text"
            id="ToLocationId"
            value={Search}
            placeholder="Search by PO#"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) GetPalletsFunction(e.target.value);
            }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onBlur={(e) => {
              GetPalletsFunction(e.target.value);
              setSearch(e.target.value);
              setCheckboxListIds([]);
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className={"col-12 my-2 "}>
          {CheckboxListIds?.length > 0 && (
            <Draggable
              axis="y"
              handle=".handle"
              position={{ x: 0, y: 0 }}
              grid={[1, 1]}
              scale={1}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <div className="handle">
                <button
                  type="button"
                  className={`btn btn-light-danger bg-light-danger text-danger w-100 p-0`}
                  style={{ height: "41px" }}
                >
                  {htmlP(CheckboxListIds, "Items Selected")}
                </button>
              </div>
            </Draggable>
          )}
        </div>
        <div className={"col-12 my-2 row " + (!Show ? "d-none" : "")}>
          {Pallets?.length > 0 ? (
            <>
              <div className="col-xl-6 col-lg-6 col-md-12 my-2">
                <div className="btn btn-light-primary bg-primary text-white w-100 py-3 mt-3">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      {htmlP2("Sku")}
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1">
                      {htmlP2("Qty")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Size")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Color")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Description")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 my-2">
                <div className="btn btn-light-primary bg-primary text-white w-100 py-3 mt-3">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-5">
                      {htmlP2("Sku")}
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1">
                      {htmlP2("Qty")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Size")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Color")}
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-2">
                      {htmlP2("Description")}
                    </div>
                  </div>
                </div>
              </div>
              {Pallets.filter((x) => x?.search?.includes(Search2)).map(
                (x, i) => (
                  <Item
                    item={x}
                    key={i}
                    SetTableShipment={SetTableShipment}
                    ShipmentID={Shipment.ShipmentId}
                    CheckboxList={CheckboxList}
                    CheckboxListIds={CheckboxListIds}
                    POChaekced={POChaekced}
                  />
                )
              )}
            </>
          ) : (
            <Nodatafound />
          )}
        </div>
      </div>
    </>
  );
}

function Check(Shipment, params) {
  return Shipment[params] ?? "";
}
function CheckList(Shipment) {
  if (
    Check(Shipment, "CustomerId") === "" ||
    Check(Shipment, "ConsigneeId") === ""
  )
    return false;
  return true;
}

async function GetItems(Shipment, search) {
  if (!CheckList(Shipment)) return false;
  return await fetch(
    `${window.$apiurl}/transloadShipment/getItems?customerId=${Check(
      Shipment,
      "CustomerId"
    )}&consigneeId=${Check(Shipment, "ConsigneeId")}&search=${search ?? ""}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

function Nodatafound() {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 7000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              No Items found
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

function htmlP(list, text) {
  return (
    <>
      {text}
      {list.map((x) => (
        <p type="button" className={`btn btn-danger px-1 py-0 mt-3 ml-1 mr-1`}>
          {x}
        </p>
      ))}
    </>
  );
}
function htmlP2(text) {
  return (
    <>
      <b className={` px-1 py-0 mt-3 w-100`} style={{ fontSize: "11px" }}>
        {text}
      </b>
    </>
  );
}
