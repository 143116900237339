import React, { useState } from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomerDeleteDialog } from "./customer-delete-dialog/CustomerDeleteDialog";
import { CustomersDeleteDialog } from "./customers-delete-dialog/CustomersDeleteDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";

export function ShipmentsManagmentBetaPage({ history }) {
  const [NewModal, setNewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [SendEmailModal, setSendEmailModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);

  const customersUIEvents = {
    newCustomerButtonClick: () => {
      history.push("/ShipmentsManagement/new");
    },
    openEditCustomerDialog: (id) => {
      setEditModal(id);
      //history.push(`/ShipmentsManagement/${id}/edit`);
    },
    openDeleteCustomerDialog: (id) => {
      setSendEmailModal(id);
      //history.push(`/ShipmentsManagement/${id}/SendEmail`);
    },
    openDeleteCustomersDialog: () => {
      history.push(`/ShipmentsManagement/deleteCustomers`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/ShipmentsManagement/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/ShipmentsManagement/updateStatus");
    },
  };

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />

      {EditModal && (
        <CustomerEditDialog
          show={EditModal}
          id={EditModal}
          onHide={() => {
            setEditModal(false);
          }}
        />
      )}
{SendEmailModal && (
        <CustomerDeleteDialog
          show={SendEmailModal}
          id={SendEmailModal}
          onHide={() => {
            setSendEmailModal(false);
          }}
        />
      )}
      {!EditModal && <CustomersCard />}
      {/* <Route path="/ShipmentsManagement/new">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            onHide={() => {
              history.push("/ShipmentsManagement");
            }}
          />
        )}
      </Route>
      <Route path="/ShipmentsManagement/:id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/ShipmentsManagement");
            }}
          />
        )}
      </Route>
      <Route path="/ShipmentsManagement/deleteCustomers">
        {({ history, match }) => (
          <CustomersDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/ShipmentsManagement");
            }}
          />
        )}
      </Route> */}
      

      {/* <Route path="/ShipmentsManagement/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/ShipmentsManagement");
            }}
          />
        )}
      </Route>
      <Route path="/ShipmentsManagement/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/ShipmentsManagement");
            }}
          />
        )}
      </Route> */}
    </CustomersUIProvider>
  );
}
