/**
 * Empower Data Logistics App
 */
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import * as _redux from "./redux";
import moment from "moment";
import store, { persistor } from "./redux/store";
import App from "./app/App";
import "./index.scss";
import "./_metronic/_assets/plugins/keenthemes-icons/font/ki.css";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  MetronicLayoutProvider,
  MetronicSplashScreenProvider,
  MetronicSubheaderProvider,
} from "./_metronic/layout";
import { MetronicI18nProvider } from "./_metronic/i18n";

const { PUBLIC_URL } = process.env;

_redux.mockAxios(axios);
_redux.setupAxios(axios, store);
window.shipmentEditCommingFrom = "";
window.receiptEditCommingFrom = "";
window.TMSEditSOCommingFrom = "";
window.activeTab = "";
window.$isFromGlobalSearch = false;
window.$apiurl = process.env.REACT_APP_APIURL;
window.$GoogleMapsKey = process.env.REACT_APP_GoogleMapsKey;

window.$BuildVersion = process.env.REACT_APP_VERSION;
window.$timezone = "08:00";
window.$ShipmentManagementStatus = "";
window.$ShipmentManagementTMSStatus = "";
window.$ShipmentManagementStatusName = "All";
window.$ShipmentManagementTMSStatusName = "All";
window.$ShipmentManagementType = "";
window.$ShipmentManagementTMSType = "";
window.$ShipmentManagementexception = "";
window.$ShipmentManagementServiceId = 6;
window.$ShipmentManagementTMSServiceId = 6;
window.$ReceiptManagementServiceId = 19;
window.$ShipmentManagementServiceName = "Domestic Transportation";
window.$ShipmentManagementTMSServiceName = "Domestic Transportation";
window.$ReceiptManagementServiceName = "Drayage / Transload";
window.$ShipmentManagementModeId = 3;
window.$ShipmentManagementTMSModeId = 1;
window.$ReceiptManagementModeId = 24;
window.$ShipmentManagementModeName = "OTR (Over The Road)";
window.$ShipmentManagementTMSModeName = "LTL";
window.$ReceiptManagementModeName = "Import";
window.$ReceiptManagementCustomerName = "";
window.$ReceiptManagementCustomerId = "";
window.$CustomerIdsSm = "?customerId=";
window.$ShipmentManagementServiceLevelId = "";
window.$SmGsearchText = "";
window.$ShipmentManagementServiceLevelName = "";
window.$ShipmentManagementCustomerId = "";
window.$ShipmentManagementCustomerName = "";
window.$ShipmentManagementHot = "false";
window.$ShipmentManagementHazmat = "false";
window.$ShipmentManagementHeavy = "false";
window.$ShipmentManagementCanceled = false;
window.$ShipmentManagementClosed = false;
window.AllPosStatus = "";
window.$SmGsearchStartDate = moment()
  .add(-28, "days")
  .format("YYYY-MM-DD");
try {
  document.onkeydown = function(evt) {
    evt = evt || window.event;
    if (evt.ctrlKey && evt.keyCode === 81) {
      document.querySelector(".searchClickEvent").click();
      document.getElementById("globalSearchInput").focus();
    }
  };
} catch (err) {}

//VV
window.$VVExceptions = "";
window.$RefreshTMSLoadBoaed = false;
//RM
window.$RmStatusName = "All";
window.$RmModeName = "Import";
window.$RmServiceName = "Drayage / Transload";
window.$RmHot = "false";
window.$RmHazmat = "false";
window.$RmCanceled = false;
//******************************/
window.$ShipmentAsideMenuClick = "";
window.$RefrichshipmentStatus = "";
window.$QuotesManagmentStatu = "";
window.shipmentCount = "";
window.$ReceiptsManagmentExceptions = "";
//////////////PickUp Management//////////////////
window.$PickUpManagementStatus = "";
window.$RefrichPickUpStatus = "";
window.$pickUpMgtType = "PICKUP";
window.$pickUpMgtIsSent = false;
window.StatusBillingPickUpManagement = 50;
window.ExceprionsStatusBillingPickUpManagement = "";
////////////palletManagement//////
window.$PalletManagementsearchText = "";
////////////CMSManagement//////
window.$CMSManagementsearchText = "";
//////////pickUpManagement///////////
window.$PickUpsManagementsearchText = "";
//window.shipmentExceptions = "";
window.NotifyRMEmptycontainer = false;
window.reportingGraph = {};
window.ShipmentStatus = "";
window.VendorListCapasityLB = [];
ReactDOM.render(
  <MetronicI18nProvider>
    <MetronicLayoutProvider>
      <MetronicSubheaderProvider>
        <MetronicSplashScreenProvider>
          <App store={store} persistor={persistor} basename={PUBLIC_URL} />
        </MetronicSplashScreenProvider>
      </MetronicSubheaderProvider>
    </MetronicLayoutProvider>
  </MetronicI18nProvider>,
  document.getElementById("root")
);
