import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Notification } from "../../modules/Notification";
import { ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";

export function ImportReceiptsPage() {
    const useStyles = makeStyles(theme => ({
        root: {
            width: "100%",
        },
        paper: {
            marginTop: theme.spacing(3),
            width: "100%",
            overflowX: "auto",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        row: {
            height: 50,
        },
        rowBody: {
            height: 50,
            fontWeight: "bold",
        },
        button: {
            margin: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
    }));
    const classes = useStyles();
    const [showProgress, setshowProgress] = useState(false);
    const [rowsState, setRowsState] = useState([]);
    const [rowsStatBool, setRowsStatBool] = useState(false);
    const [rowsEmpty, setRowsEmpty] = useState(true);
    const [customersList, setCustomersList] = useState([]);
    const [warehousesList, setWarehousesList] = useState([]);
    const [importInProgress, setImportInProgress] = useState(false);

    useEffect(() => {
        fetchCustomersAsync();
    }, []);

    const fetchCustomersAsync = async () => {
        try {
            const result = await axios.get("api/purchaseorders/getcustomers");
            if (result.status === 200) {
                setCustomersList(result.data);
            }
        } catch (e) { }
    };

    const handleCustomerChange = async (event) => {
        setshowProgress(true);
        setWarehousesList([]);
        if (event.target.value !== "" && event.target.vale !== null) {
            const result = await axios.get(`api/receipts/getwarehouses/${event.target.value}`);
            if (result.status === 200) {
                if (result.data.length > 0)
                    setWarehousesList(result.data);
                else
                    Notification("error", "This Customes doesn't have access to any warehouse.");
            }
        }
        setshowProgress(false);
    }

    const fileHandlerOnclick = () => {
        if (rowsEmpty) {
            Notification("error", "Please select a valid file to import.");
            setRowsStatBool(false);
            return false;
        }
        setRowsStatBool(true);
    };

    const fileClear = (event) => {
        event.target.value = "";
    }

    const fileHandler = (event) => {
        setRowsStatBool(false);
        setRowsState([]);
        setRowsEmpty(false);
        let fileObj = event.target.files[0];
        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                setRowsEmpty(true);
                return false;
            } else {
                if (resp.rows.length < 1) {
                    setRowsEmpty(true);
                    return false;
                }
                var a = resp.rows[1].indexOf("Ref #");
                var b = resp.rows[1].indexOf("PO #");
                var c = resp.rows[1].indexOf("ShipCarrier");
                var d = resp.rows[1].indexOf("Notes");
                var e = resp.rows[1].indexOf("SKU");
                var f = resp.rows[1].indexOf("Quantity");
                var g = resp.rows[1].indexOf("Lot#");
                var h = resp.rows[1].indexOf("Serial#");
                var i = resp.rows[1].indexOf("Expiration Date");
                var j = resp.rows[1].indexOf("LocationField1");
                var k = resp.rows[1].indexOf("LocationField2");
                var l = resp.rows[1].indexOf("LocationField3");
                var m = resp.rows[1].indexOf("LocationField4");
                var n = resp.rows[1].indexOf("Cost");
                var o = resp.rows[1].indexOf("Var UoM Avg");

                if (a === -1 || b === -1 || c === -1 || d === -1 || e === -1 || f === -1 || g === -1 || h === -1 ||
                    i === -1 || j === -1 || k === -1 || l === -1 || m === -1 || n === -1 || o === -1) {
                    setRowsEmpty(true);
                    return false;
                }

                let rows = [];
                resp.rows.slice(2, resp.rows.length).forEach((element, index) => {
                    var empty = 1;
                    var error = "";
                    if (element[a] === undefined ||
                        element[e] === undefined ||
                        element[f] === undefined) {
                        empty = 0;
                        error = "Please check the required fields*."
                    }
                    if (TryParseInt(element[f]) <= 0) {
                        empty = 0;
                        error = "Quantity must be a non-negative number";
                    }
                    if (element[n] !== "" && element[n] !== undefined && TryParseFloat(element[n]) <= 0) {
                        empty = 0;
                        error = "Cost must be a non-negative decimal";
                    }
                    rows.push(
                        createData(empty, index + 1, element[a], element[b], element[c], element[d], element[e], element[f],
                            element[g], element[h], getJsDateFromExcel(element[i]), element[j], element[k], element[l],
                            element[m], element[n], element[o], error)
                    );
                });
                setRowsState(rows);
                setRowsEmpty(false);
            }
        });
    };

    const fileUploadeOnclick = async () => {
        setImportInProgress(true);
        const customerId = document.getElementById("CustomerId").value;
        const warehouseId = document.getElementById("WarehouseId").value;
        if (customerId && warehouseId) {
            if (rowsStatBool) {
                if (rowsState.length > 0) {
                    var result = await axios.post("api/receipts/import", { customerId: parseInt(customerId), warehouseId: parseInt(warehouseId), receipts: rowsState });
                    if (result.data.success === true) {
                        Notification("success", "Import done successfully.");
                    }
                    else {
                        const msg = result.data.Message === undefined ? "Something went wrong, please try later or contact the support." : result.data.Message;
                        Notification("error", msg);
                    }
                }
                else {
                    Notification("error", "Please select a valid file to import.");
                }
            }
            else {
                Notification("error", "Please select a valid file to import.");
            }
        }
        else {
            Notification("error", "Please select a customer and a warehouse.");
        }
        setImportInProgress(false);
    };

    function createData(
        Empty,
        Index,
        ReferenceNumber,
        PoNumber,
        ShipCarrier,
        Notes,
        Sku,
        Quantity,
        LotNumber,
        SerialNumber,
        ExpirationDate,
        LocationField1,
        LocationField2,
        LocationField3,
        LocationField4,
        Cost,
        Uom,
        Error
    ) {
        return {
            Empty,
            Index,
            ReferenceNumber: ReferenceNumber + "",
            PoNumber,
            ShipCarrier,
            Notes,
            Sku,
            Quantity,
            LotNumber: LotNumber + "",
            SerialNumber: SerialNumber + "",
            ExpirationDate,
            LocationField1,
            LocationField2,
            LocationField3,
            LocationField4,
            Cost,
            Uom: Uom + "",
            Error
        };
    }

    function getJsDateFromExcel(serial) {
        if (serial === undefined || serial === "" || serial === null)
            return "";
        try {
            var utc_days = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;
            var date_info = new Date(utc_value * 1000);
            const date = new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
            return ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear();
        }
        catch (error) {
            return "";
        }
    };

    function TryParseInt(str) {
        var retValue = 0;
        if (typeof str === 'number')
            return str;
        if (str !== null && str !== undefined) {
            if (str.length > 0) {
                if (!isNaN(str)) {
                    retValue = parseInt(str);
                }
            }
        }
        return retValue;
    }

    function TryParseFloat(str) {
        var retValue = 0;
        if (typeof str === 'number')
            return str;
        if (str !== null && str !== undefined) {
            if (str.length > 0) {
                if (!isNaN(str)) {
                    retValue = parseFloat(str);
                }
            }
        }
        return retValue;
    }
    return (
        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <div className="col-4">
                            <Form.Label>Choose a Customer</Form.Label>
                            <select className="form-control" name="CustomerId" id="CustomerId" onChange={handleCustomerChange.bind(this)} onClick={() => { }}>
                                <option key="" value="" >Choose a customer</option>
                                {customersList.map((item, index) => (
                                    <option key={index} value={item.id}>{item.CompanyName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-4">
                            <Form.Label>Choose a warehouse</Form.Label>
                            <select className="form-control" name="WarehouseId" id="WarehouseId" onClick={() => { }}>
                                <option key="" value="" >Choose a warehouse</option>
                                {warehousesList.map((item, index) => (
                                    <option key={index} value={item.Id}>{item.Name}</option>
                                ))}
                            </select>
                                 {showProgress && <LinearProgress />}
                        </div>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Import </Form.Label>
                            <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                                <Form.Control
                                    type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={fileHandler.bind(this)}
                                    onClick={fileClear.bind(this)}
                                />
                            </Card>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label className="text-dark font-weight-bold">IMPORT FILE</Form.Label>
                            <div className="MuiPaper-root font-size-lg mt-2">
                                <p>Use the 'Browse...' button to select a receipts import file.</p>
                            </div>
                            <div className="MuiPaper-root font-size-lg mt-2">
                                <p>Then press the 'Import' button to read the file.</p>
                            </div>

                            <Button as="input" type="submit" color="secondary" readOnly value="Import"
                                className="btn btn-primary btn-shadow-hover mr-2"
                                onClick={() => {
                                    fileHandlerOnclick();
                                }}
                            />
                        </Form.Group>
                    </Form.Row>

                    {rowsStatBool && (
                        <Form.Row className="mt-5 mb-5 pb-5">
                            <Paper className={classes.paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className="bg-dark">
                                        <TableRow className={classes.row}>
                                            <TableCell className="text-white">Is Valid</TableCell>
                                            <TableCell className="text-white">Line</TableCell>
                                            <TableCell className="text-white">Ref #*</TableCell>
                                            <TableCell className="text-white">PO #</TableCell>
                                            <TableCell className="text-white">Ship Carrier</TableCell>
                                            <TableCell className="text-white">Notes</TableCell>
                                            <TableCell className="text-white">SKU*</TableCell>
                                            <TableCell className="text-white">Quantity*</TableCell>
                                            <TableCell className="text-white">Lot #</TableCell>
                                            <TableCell className="text-white">Serial #</TableCell>
                                            <TableCell className="text-white">Expiration Date</TableCell>
                                            <TableCell className="text-white">Location Field 1</TableCell>
                                            <TableCell className="text-white">Location Field 2</TableCell>
                                            <TableCell className="text-white">Location Field 3</TableCell>
                                            <TableCell className="text-white">Location Field 4</TableCell>
                                            <TableCell className="text-white">Cost</TableCell>
                                            <TableCell className="text-white">Var UoM Avg</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsState.map((row) => (
                                            <TableRow className={classes.rowBody} key={row.Index}>
                                                <TableCell>
                                                    {row.Empty === 1 && (
                                                        <i data-toggle="tooltip" title="Valid" className="fa fa-check text-success icon-xxl"></i>
                                                    )}
                                                    {row.Empty === 0 && (
                                                        <i data-toggle="tooltip" title={row.Error} className="fa fa-times-circle text-danger icon-xxl"></i>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.Index}</TableCell>
                                                <TableCell>{row.ReferenceNumber}</TableCell>
                                                <TableCell>{row.PoNumber}</TableCell>
                                                <TableCell>{row.ShipCarrier}</TableCell>
                                                <TableCell>{row.Notes}</TableCell>
                                                <TableCell>{row.Sku}</TableCell>
                                                <TableCell>{row.Quantity}</TableCell>
                                                <TableCell>{row.LotNumber}</TableCell>
                                                <TableCell>{row.SerialNumber}</TableCell>
                                                <TableCell>{row.ExpirationDate}</TableCell>
                                                <TableCell>{row.LocationField1}</TableCell>
                                                <TableCell>{row.LocationField2}</TableCell>
                                                <TableCell>{row.LocationField3}</TableCell>
                                                <TableCell>{row.LocationField4}</TableCell>
                                                <TableCell>{row.Cost}</TableCell>
                                                <TableCell>{row.Uom}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <div className="text-center col-12"> <b>{rowsState.length > 0 ? rowsState.length + " receipt(s)" : "No receipt was imported"}.</b></div>
                        </Form.Row>
                    )}{" "}

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label className="text-dark font-weight-bold">Create</Form.Label>
                            <div className="MuiPaper-root font-size-lg mt-2">
                                <p>Use the 'Browse...' button to select a receipts import file.</p>
                            </div>
                            <div className="MuiPaper-root font-size-lg mt-2">
                                <p>Press the 'Create' button to create the new receipts.</p>
                            </div>

                            <Button as="input" value={importInProgress ? "Importing..." : "Create"} type="submit" readOnly disabled={importInProgress} className="btn btn-success btn-shadow-hover mr-2"
                                onClick={() => {
                                    fileUploadeOnclick();
                                }} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card >
    )
}
