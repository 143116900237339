import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import { Notification } from "../../../modules/Notification";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({
  iShipmentsID = [],
  IsClosedFunction,
  IsClosed,
}) {
  const [Table, setTable] = useState([]);
  const [DeletRowId, setDeletRowId] = useState(0);
  const [ShipmentsID, setShipmentsID] = useState(iShipmentsID);
  const [ReloadPalletsList, setReloadPalletsList] = useState(true);
  const [Search, setSearch] = useState("");
  const [params, setparams] = useState();

  function ReloadPallets() {
    setReloadPalletsList(false);
    setTimeout(() => {
      setReloadPalletsList(true);
    }, 1);
  }

  useEffect(() => {
    setShipmentsID(iShipmentsID);
  }, [iShipmentsID]);

  function SetTableShipment(row, Quantity) {
    var table = Table;
    var a = table.findIndex((x) => x?.Id === row?.Id);
    if (a > -1) {
      table[a].Quantity += Quantity;
      setTable([]);
      setTimeout(() => {
        setTable(table);
      }, 1);
    } else {
      row.Quantity = Quantity;
      setTable((x) => [...x, row]);
    }
  }

  function DeleteShipmentRow(id, IdShip) {
    RemovePallet(IdShip).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        var a = Table.filter((x) => x?.Id !== id);
        var b = Table.find((x) => x?.Id === id);
        setTable([]);
        setDeletRowId(0);
        setTimeout(() => {
          setTable(a);
          setDeletRowId(b);
        }, 1);
      } else {
        Notification("error", Message?.toString());
      }
    });
  }

  useEffect(() => {
    if (params?.Items?.length > 0) setTable(params?.Items);
  }, [params?.Items]);

  function GetParms(params) {
    setparams(params);
  }

  return (
    <>
      {ShipmentsID.map((x, i) => (
        <TableComponent
          list={Table}
          ShipmentID={x}
          DeleteShipmentRow={DeleteShipmentRow}
          key={i}
          ReloadPallets={ReloadPallets}
          IsClosedFunction={IsClosedFunction}
          GetParms={GetParms}
          isClosed={IsClosed}
        />
      ))}

      {ShipmentsID.length > 0 &&
        ReloadPalletsList &&
        !IsClosed &&
        params?.ShipmentId && (
          <IndexPalletsList
            SetTableShipment={SetTableShipment}
            DeletRowId={DeletRowId}
            Shipment={params}
            ReloadPallets={ReloadPallets}
            setSearch={setSearch}
            Search={Search}
          />
        )}
      {IsClosed && <Nodatafound />}
    </>
  );
}
async function GetPalletsForShipments(id, setTable) {
  return await fetch(`${window.$apiurl}/pallets/getpalletbyshipment/${id}`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      setTable(res[1]?.pallets ?? []);
    });
}
async function RemovePallet(id) {
  return await fetch(
    `${window.$apiurl}/TransloadWarehouse/DeleteShipItem/${id}`,
    {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Shipment Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}
