import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import Swal from "sweetalert2";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import { Notification } from "../../../../../../Notification";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditRemarkDialog, openDeleteRemarkDialog, AcceptRejeted }
) => (
  <>
    <OverlayTrigger
      overlay={
        <Tooltip id="customers-edit-tooltip">
          Edit item
          {/* {window.$ReceiptsManagmentStatu === 23 ||
                 window.$statusIdIsClosed === 23
                   ? "This PO is Closed."
                   : "Edit item"} */}
        </Tooltip>
      }
    >
      <a
        //  style={
        //    window.$ReceiptsManagmentStatu === 23 ? { opacity: "0.6" } : ""
        //  }
        className={`btn btn-icon btn-light btn-hover-primary btn-sm mx-3`}
        onClick={() => {
          try {
            //  if (
            //    window.$ReceiptsManagmentStatu === 23 ||
            //    window.$statusIdIsClosed === 23
            //  ) {
            //    Notification("error", "This PO is Closed.");
            //    return false;
            //  } else {
            openEditRemarkDialog(row.id);
            //  }
          } catch (err) {}
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl(
              "/media/svg/icons/Communication/Write.svg"
            )}
          />
        </span>
      </a>
    </OverlayTrigger>

    <> </>
    <OverlayTrigger
      overlay={
        <Tooltip id="customers-delete-tooltip">Delete item</Tooltip>
      }
    >
      <a
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteRemarkDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
          />
        </span>
      </a>
    </OverlayTrigger>
    {
      row.ChangedModeServiceLevel === true && (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="customers-delete-tooltip">Accept Requested Mode/Service Level</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You want to accept customer mode/service level",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, accept it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    AcceptRejeted(row.ReceiptId,true,row.PurchaseOrder);
                  }
                })
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-success">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>
            </a>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={
              <Tooltip id="customers-delete-tooltip">Reject Requested Mode/Service Level</Tooltip>
            }
          >
            <a
              className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
              onClick={() => {
                Swal.fire({
                  title: 'Are you sure?',
                  text: "You want to reject customer mode/service level",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Yes, reject it!'
                }).then((result) => {
                  if (result.isConfirmed) {
                    AcceptRejeted(row.ReceiptId,false,row.PurchaseOrder);
                  }
                })
              }}
            >
              <span className="svg-icon svg-icon-md svg-icon-danger">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Code/Stop.svg")}
                />
              </span>
            </a>
          </OverlayTrigger>
        </>
      )
    }
  </>
);
