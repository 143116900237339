import AssignmentsTableMock from "./AssignmentsTableMock";
import MockUtils from "./mock.utils";

export default function mockAssignments(mock) {
  mock.onPost("api/Assignments").reply(({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      So = "",
      Customer = "",
      Pu = "",
      Del = "",
      Cust = "",
      Bid = "",
    } = customer;

    const id = generateUserId();
    const newCustomer = {
      id,
      So,
      Customer,
      Pu,
      Del,
      Cust,
      Bid,
    };
    AssignmentsTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/Assignments/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdCustomers = mockUtils.baseFilter(
      AssignmentsTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/Assignments/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = AssignmentsTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        AssignmentsTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Assignments/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    AssignmentsTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/Assignments\/\d+/).reply((config) => {
    const id = config.url.match(/api\/Assignments\/(\d+)/)[1];
    const customer = AssignmentsTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/Assignments\/\d+/).reply((config) => {
    const id = config.url.match(/api\/Assignments\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const index = AssignmentsTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    AssignmentsTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/Assignments\/\d+/).reply((config) => {
    const id = config.url.match(/api\/Assignments\/(\d+)/)[1];
    const index = AssignmentsTableMock.findIndex((el) => el.id === +id);
    AssignmentsTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = AssignmentsTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
