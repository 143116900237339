import React, { useEffect, useState } from "react";
import $ from "jquery";
import Select from "react-select";
import LinearProgress from "@material-ui/core/LinearProgress";
import chroma from "chroma-js";

export function CreatableSelect3({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  Loading = true,
  onChangefirst = true,
  required,
  ...props
}) {
  const {
    valueSelect,
    options,
    onChange,
    name,
    setFieldValue,
    ColorBool = false,
  } = props;
  const [state, setstate] = useState(0);
  const [valueSelect2, setvalueSelect2] = useState("");
  const [LoadingBool, setLoadingBool] = useState(true);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  const dot = (color = "#ccc") => ({
    alignItems: "center",
    display: "flex",

    ":before": {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: "block",
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });

  const colourStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      try {
        const color = chroma(data?.color);
        return {
          ...styles,
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? data?.color
            : isFocused
            ? color.alpha(0.1).css()
            : null,
          color: isDisabled
            ? "#ccc"
            : isSelected
            ? chroma.contrast(color, "white") > 2
              ? "white"
              : "black"
            : data.color,
          cursor: isDisabled ? "not-allowed" : "default",

          ":active": {
            ...styles[":active"],
            backgroundColor:
              !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
          },
        };
      } catch (error) {}
    },
    input: (styles) => ({ ...styles, ...dot() }),
    placeholder: (styles) => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  useEffect(() => {
    setTimeout(() => {
      setLoadingBool(false);
    }, 5000);
  }, [LoadingBool]);

  useEffect(() => {
    if (
      valueSelect &&
      valueSelect > 0 &&
      valueSelect !== valueSelect2 &&
      options.length > 0
    ) {
      var b = options.find((x) => x.value === +valueSelect);
      $(`[name=${name}`).val(valueSelect);
      setstate(
        b || {
          value: null,
          label: "Select...",
        }
      );
      setTimeout(() => {
        if (onChange && typeof onChange === "function" && onChangefirst) {
          onChange(b);
        }
      }, 100);
      setvalueSelect2(valueSelect);
    }
  }, [valueSelect, options]);

  return (
    <>
      {label && (
        <label>
          {label} {required && <RequiredHtml />}
        </label>
      )}
      <Select
        isSearchable={true}
        isClearable
        styles={ColorBool ? colourStyles : {}}
        {...props}
        onChange={async (newValue) => {
          var stop = false;
          if (onChange)
            await onChange(newValue)?.then((e) => {
              if (e === false) stop = true;
            });
          if (stop) return false;

          var a = options.find((x) => x?.value === +newValue?.value);
          setstate(a);
          if (setFieldValue) setFieldValue(name, +newValue?.value);
          $(`[name=${name}`).val(+newValue?.value);
        }}
        value={state}
      />
      {Loading && options?.length === 0 && LoadingBool && <LinearProgress />}
    </>
  );
}
