import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../modules/Notification";
import MockUtils from "./mock.utils";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockPickUpManagement(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetUsersData(PickupType, isClosed, Location) {
    window.PickUpManagement_StatusCount = undefined;

    return await fetch(
      window.$apiurl +
        `/Pickup/List?statusId=${
          window.$PickUpManagementStatus
        }&type=${window.$pickUpMgtType}&isClosed=${isClosed ||
          false}&pickupLocationId=${Location || ""}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        window.PickUpManagement_Vendors = res[1]?.Vendors ?? [];
        window.PickUpManagement_Locations = res[1]?.Locations ?? [];
        window.PickUpManagement_Vendors = res[1]?.Vendors ?? [];
        window.PickUpManagement_StatusCount = res[1]?.StatusCount ?? [];
        return ChangeId(res[1]?.Pickups);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCustomerProfileRating(someData) {
    return fetch(window.$apiurl + "/Pickup/Create", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutUser(someData, id) {
    return fetch(window.$apiurl + "/trips/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/Pickup/DeletePickup/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = (await response.json()) || {};
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400) {
          Notification("error", `${res[1].Message}`);
          return false;
        } else if (res[0] === 200) {
          return StatusMessage(res);
        }
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //Get View Model
  async function GetVendors() {
    return await fetch(window.$apiurl + "/vendors", {
      method: "GET",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Vendors;
      })
      .catch((err) => {
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/PickUpManagement/GetAllApi").reply(async () => {
    const result = await GetVendors();
    return [200, result];
  });
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  }
  mock.onPost("api/PickUpManagement").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const start = Date.now();
    const dateNow = formatDate(start);
    const {
      TrailerNumber = "",
      VendorId = "",
      PickUpDate = "",
      ReceiveDate = "",
      DevanDate = "",
      Description = "",
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      TrailerNumber,
      VendorId,
      Description,
      PickUpDate,
      ReceiveDate,
      DevanDate,
    };
    var postCustomerProfile = await PostCustomerProfileRating(newCustomer);
    if (postCustomerProfile.Id != undefined) {
      Notification("success", "Trip has been added successfully");
      postCustomerProfile.id = postCustomerProfile.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newCustomer,
          message: postCustomerProfile.Message,
          success: true,
        },
      ];
    } else {
      if (postCustomerProfile.Message) {
        Notification("error", postCustomerProfile.Message);
        window.$tripsMngStatus = 400;
        window.$tripMngLoading = false;
      } else {
        Notification("success", "Trip has been added successfully");
        document.getElementById("ReLoadTableId").click();
        return [
          200,
          {
            customer: newCustomer,
            message: postCustomerProfile.Message,
            success: true,
          },
        ];
      }
    }
  });

  mock.onPost("api/PickUpManagement/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    var filter = queryParams;
    filter.filter.searchText = window.$PickUpsManagementsearchText;
    filter.filter.receiptIdSearch = window.$PickUpsManagementsearchReceiptId;
    filter.filter.receiptIdValue =
      window.$PickUpsManagementsearchReceiptIdValue;
    customerTableMock = await GetUsersData(
      filter.filter.PickupType,
      filter.filter.isClosed,
      filter.filter.Location
    );
    //search all fields
    if (
      filter.filter.searchText !== null &&
      filter.filter.searchText !== "" &&
      filter.filter.searchText !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (a) =>
          String(a.id)
            .toLowerCase()
            .trim()
            .includes(String(filter.filter.searchText || "").toLowerCase()) ||
          String(a.VendorPhone)
            .toUpperCase()
            .trim()
            .includes(
              String(filter.filter.searchText)
                .toUpperCase()
                .trim()
            ) ||
          String(a.Type)
            .toUpperCase()
            .trim()
            .includes(
              String(filter.filter.searchText)
                .toUpperCase()
                .trim()
            ) ||
          String(a.Location)
            .toUpperCase()
            .trim()
            .includes(
              String(filter.filter.searchText)
                .toUpperCase()
                .trim()
            ) ||
          String(a.Vendor)
            .toUpperCase()
            .trim()
            .includes(
              String(filter.filter.searchText)
                .toUpperCase()
                .trim()
            ) ||
          String(a.PoVendor)
            .toUpperCase()
            .trim()
            .includes(
              String(filter.filter.searchText)
                .toUpperCase()
                .trim()
            )
      );
    }
    //search PO
    if (
      filter.filter.receiptIdSearch !== null &&
      filter.filter.receiptIdSearch !== "" &&
      filter.filter.receiptIdSearch !== undefined
    ) {
      const tempList = [];
      customerTableMock.map((item) => {
        item.ReceiptPos.length > 0 &&
          item.ReceiptPos.map((x) => {
            if (x.PO === filter.filter.receiptIdSearch) {
              tempList.push(item);
            }
          });
      });
      customerTableMock = tempList;
    }
    //search Receipt ID
    if (
      filter.filter.receiptIdValue !== null &&
      filter.filter.receiptIdValue !== "" &&
      filter.filter.receiptIdValue !== undefined
    ) {
      const tempList = [];
      customerTableMock.map((item) => {
        item.ReceiptPos.length > 0 &&
          item.ReceiptPos.map((x) => {
            if (x.ReceiptId === parseInt(filter.filter.receiptIdValue)) {
              tempList.push(item);
            }
          });
      });
      customerTableMock = tempList;
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.PickupType;
    delete queryParams.filter.isClosed;
    delete queryParams.filter.Location;
    delete queryParams.filter.searchText;
    delete queryParams.filter.receiptIdSearch;
    delete queryParams.filter.receiptIdValue;
    window.$pickUpMgmtList = customerTableMock;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/PickUpManagement/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/PickUpManagement/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  async function GetUser(id) {
    return await fetch(window.$apiurl + "/Pickup/Details/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet(/api\/PickUpManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpManagement\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/PickUpManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpManagement\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    await PutUser(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    Notification("success", "Trip has been updated successfully");
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  mock.onDelete(/api\/PickUpManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PickUpManagement\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
