// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import clsx from 'clsx';
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
// Validation schema
const RemarkEditSchema = Yup.object().shape({
  text: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Text is required"),
  type: Yup.number().required("Type is required"),
  dueDate: Yup.mixed()
    .nullable(false)
    .required("Due date is required"),
});
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
export function RemarkEditForm({ saveRemark, remark, actionsLoading, onHide }) {
  const classes = useStyles();
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={remark}
        validationSchema={RemarkEditSchema}
        onSubmit={(values) => saveRemark(values)}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row mb-0">
                  <div className="col-md-2 align-self-center">
                  <label className="mt-4">CHARGE LABEL</label>
                  </div>
                  <div className="col-lg-5">
                    <TextField
                      id="outlined-dense"
                      label=""
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-md-2 align-self-center">
                  <label className="mt-4">UNIT</label>
                  </div>
                  <div className="col-lg-5">
                    <TextField
                      id="outlined-dense"
                      label=""
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-md-2 align-self-center">
                  <label className="mt-4">CHARGE PER UNIT</label>
                  </div>
                  <div className="col-lg-5">
                    <TextField
                      id="outlined-dense"
                      label=""
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                      helperText="(as a numeric value)"
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-md-2 align-self-center">
                  <label className="mt-4">DEFAULT QUANTITY</label>
                  </div>
                  <div className="col-lg-5">
                    <TextField
                      id="outlined-dense"
                      label=""
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                      helperText="(enter number to avoid having to edit the charge on the system-proposed bills)"
                    />
                  </div>
                </div>
                <div className="form-group row mb-0">
                  <div className="col-md-2 align-self-center">
                  <label className="mt-4">G.L ACCT NUM</label>
                  </div>
                  <div className="col-lg-5">
                    <TextField
                      id="outlined-dense"
                      label=""
                      className={clsx(classes.textField, classes.dense)}
                      margin="dense"
                      variant="outlined"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
