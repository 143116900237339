import MockUtils from "./mock.utils";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let remarkTableMock;
export default function mockReceiptCustomFields(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", "Error Occured");
      return false;
    }
  }
  //Get All Receipts Items
  async function GetOrderCustomFields(id) {
    return await fetch(window.$apiurl + "/orders/CustomFields/" + id, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        console.log(data);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Post Trans Billing Shipping
  async function PostOrderCustomField(id, someData) {
    return fetch(window.$apiurl + "/orders/CustomField/" + id, {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Delete Receipt Item
  async function DeleteOrderCustomField(id) {
    return await fetch(window.$apiurl + "/orders/CustomField/" + id, {
      method: "delete",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetOrderCustomField(ReceiptId) {
    return await fetch(window.$apiurl + "/orders/CustomField/" + ReceiptId, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function PutOrderCustomField(someData, itemId) {
    return fetch(window.$apiurl + "/orders/CustomField/" + itemId, {
      method: "put",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //----------------------------------Mock--------------------------------------
  mock.onPost("api/OrderCustomFields").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const { Name = "", Value = "" } = remark;
    const id = generateRemarkId();
    const newcustomField = {
      id,
      Name,
      Value,
    };
    var postCustomField = await PostOrderCustomField(
      remark.ReceiptId,
      newcustomField
    );
    if (postCustomField.Code == "200") {
      postCustomField.id = postCustomField.Id;
      remarkTableMock.push(newcustomField);
      window.$ManageCustomField = 200;
      window.$ManageCustomFieldMessage = postCustomField.Message;
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          remark: newcustomField,
          message: postCustomField.Message,
          success: true,
        },
      ];
    } else {
      window.$ManageCustomField = 400;
      window.$ManageCustomFieldMessage = postCustomField.Message;
      return [400, { remark: postCustomField.Message, success: false }];
    }
  });

  //mock on Post
  mock.onPost(/api\/OrderCustomFieldsfind\/\d+/).reply(async (config) => {
    const mockUtils = new MockUtils();
    const urls = config.url.split("/");
    const id = urls[2];
    const { queryParams } = JSON.parse(config.data);
    remarkTableMock = await GetOrderCustomFields(id);
    delete queryParams.filter.ReLoadTable;
    const filterdRemarks = mockUtils.baseFilter(remarkTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/OrderCustomFields/deleteReceipts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/OrderCustomFields\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/OrderCustomFields\/(\d+)/)[1];
    const remark = await GetOrderCustomField(id);
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/OrderCustomFields\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/OrderCustomFields\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    var postOrder = await PutOrderCustomField(remark, id);
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/OrderCustomFields\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/OrderCustomFields\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteOrderCustomField(id);
    if (!deleteObj) {
      return [400];
    }
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function generateRemarkId() {
  const ids = remarkTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
