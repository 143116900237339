/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
//Date Range Picker Google
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
//////
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import low from "lowdb";
import {
  makeStyles,
  useTheme,
  withStyles,
  emphasize,
} from "@material-ui/core/styles";
import Menu2 from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import {
  ProgressBar,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Alert,
  Accordion,
  ButtonToolbar,
} from "react-bootstrap";
import { PerformanceLulusFashion } from "./PerformanceLulusFashion";
import { PerformanceLulusFashionSecond } from "./PerformanceLulusFashionSecond";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { toAbsoluteUrl } from "../../../_helpers";
import SVG from "react-inlinesvg";
import { Export } from "../../../../_metronic/_helpers/ATHelpers";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CloseIcon from "@material-ui/icons/Close";
import { currencyFormat } from "../../../_helpers/ATHelpers";
import ModelManagmentFirstDashboard from "../../../../app/modules/eMPower/pages/ReceiptsManagementNewVersion/customers-table/Shipment/ModelManagmentFirstDashboard";
import { ModelManagmentFirstDashboardSm } from "../../../../app/modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import moment from "moment";
import Chart2 from "../../../../app/modules/eMPower/pages/PerformanceGraph/ChartZoomBar";
const adapter = new LocalStorage("db");
const db = low(adapter);

var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return moment(d).format(format);
};
var getDateName = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "dddd, MMMM DD, YYYY";
  return moment(d).format(format);
};
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu2
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

function createData(name, calories, fat) {
  return { name, calories, fat };
}
const rows = [
  createData("Cupcake", 305, 3.7),
  createData("Donut", 452, 25.0),
  createData("Eclair", 262, 16.0),
  createData("Frozen yoghurt", 159, 6.0),
  createData("Gingerbread", 356, 16.0),
  createData("Honeycomb", 408, 3.2),
  createData("Ice cream sandwich", 237, 9.0),
  createData("Jelly Bean", 375, 0.0),
  createData("KitKat", 518, 26.0),
  createData("Lollipop", 392, 0.2),
  createData("Marshmallow", 318, 0),
  createData("Nougat", 360, 19.0),
  createData("Oreo", 437, 18.0),
].sort((a, b) => (a.calories < b.calories ? -1 : 1));

export function PerformanceLulus({ className, customerId, SearchBy }) {
  const [showTransload, setShowTransload] = React.useState(false);
  const [performancetavselected, setperformancetavselected] = React.useState(
    ""
  );
  const [showInBound, setShowInBound] = React.useState(false);
  const [showOutBound, setShowOutBound] = React.useState(false);
  const [value, onChange] = useState([
    moment()
      .add(-7, "days")
      .format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [valuesTab, setvaluesTab] = React.useState(0);
  const [valuesTabParent, setvaluesTabParent] = React.useState(0);
  const [showContainerPort, setShowContainerPort] = React.useState(false);
  const [
    TransloadPerformanceReportLoadingSpinner2,
    setTransloadPerformanceReportLoadingSpinner2,
  ] = React.useState(false);
  const [ContainersDetails, setContainersDetails] = useState([]);
  const [PerformanceReportData, setPerformanceReportData] = useState([]);
  const [PerformanceReportGraphData, setPerformanceReportGraphData] = useState([]);
  const [PerformanceReportDetails, setPerformanceReportDetails] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChange(event, newValue) {
    setvaluesTab(newValue);
  }
  function handleChangeParent(event, newValue) {
    setvaluesTabParent(newValue);
  }
  const [pageContainer, setPageContainer] = React.useState(0);
  const [rowsPerPageContainer, setRowsPerPageContainer] = React.useState(10);

  //Use State Google Picker Date Range
  const [startDateValue, setStartDate] = React.useState(
    new Date()
      .addDays(-7)
      .toISOString()
      .split("T")[0]
  );
  const [endDateValue, setEndDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  function GetPerformanceLulusDetails(Bucket) {
    setPerformanceReportDetails([]);
    var startDate = moment(startDateValue).format("YYYY-MM-DD");
    var endDate = moment(endDateValue).format("YYYY-MM-DD");
    var SearchText = document.getElementById("searchGlobalText").value;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/DashboardLulus/PerformanceReportDetails?keyword=${SearchBy}${SearchText}&startDate=${startDate}&endDate=${endDate}&category=${Bucket}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        var obj = JSON.parse(result);
        setPerformanceReportDetails(obj);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    setTransloadPerformanceReportLoadingSpinner2(true);
    var startDate = moment(startDateValue).format("YYYY-MM-DD");
    var endDate = moment(endDateValue).format("YYYY-MM-DD");
    var SearchText = document.getElementById("searchGlobalText").value;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${
        window.$apiurl
      }/DashboardLulus/PerformanceReport?customerId=${2271}&keyword=${SearchBy}${SearchText}&startDate=${startDate}&endDate=${endDate}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setTransloadPerformanceReportLoadingSpinner2(false);
        var obj = JSON.parse(result);
        setPerformanceReportData(obj.result || []);
        setPerformanceReportGraphData(obj?.performanceGraph?.Reporting?.Data || []);
      })
      .catch((error) => console.log("error", error));
  }, []);
  const [
    pageInboundContainerDetails,
    setPageInboundContainerDetails,
  ] = React.useState(0);
  const [
    rowsPerPageInboundContainerDetails,
    setRowsPerPageInboundContainerDetails,
  ] = React.useState(10);

  const [
    pageOutboundContainerDetails,
    setPageOutboundContainerDetails,
  ] = React.useState(0);
  const [
    rowsPerPageOutboundContainerDetails,
    setRowsPerPageOutboundContainerDetails,
  ] = React.useState(10);
  var totalALLContainers0 = PerformanceReportData.filter(
    (a) => a.Bucket === "Planned to Received"
  )?.reduce((a, b) => a + (b["Quantity"] || 0), 0);
  var totalALLContainers1 = PerformanceReportData.filter(
    (a) => a.Bucket === "Received to Ready"
  )?.reduce((a, b) => a + (b["Quantity"] || 0), 0);
  var totalALLContainers2 = PerformanceReportData.filter(
    (a) => a.Bucket === "Ready to Processed"
  )?.reduce((a, b) => a + (b["Quantity"] || 0), 0);
  var totalALLContainers3 = PerformanceReportData.filter(
    (a) => a.Bucket === "Processed to Shipped"
  )?.reduce((a, b) => a + (b["Quantity"] || 0), 0);
  var totalALLContainers4 = PerformanceReportData.filter(
    (a) => a.Bucket === "Received to Shipped"
  )?.reduce((a, b) => a + (b["Quantity"] || 0), 0);

  totalALLContainers0 = Number.isNaN(totalALLContainers0)
    ? 0
    : totalALLContainers0;

  totalALLContainers1 = Number.isNaN(totalALLContainers1)
    ? 0
    : totalALLContainers1;

  totalALLContainers2 = Number.isNaN(totalALLContainers2)
    ? 0
    : totalALLContainers2;

  totalALLContainers3 = Number.isNaN(totalALLContainers3)
    ? 0
    : totalALLContainers3;

  totalALLContainers4 = Number.isNaN(totalALLContainers4)
    ? 0
    : totalALLContainers4;

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  function handleChangePageContainer(event, newPage) {
    setPageContainer(newPage);
  }

  function handleChangeRowsPerPageContainer(event) {
    setRowsPerPageContainer(parseInt(event.target.value, 10));
  }

  function handleChangePageInboundContainerDetails(event, newPage) {
    setPageInboundContainerDetails(newPage);
  }

  function handleChangeRowsPerPageInboundContainerDetails(event) {
    setRowsPerPageInboundContainerDetails(parseInt(event.target.value, 10));
  }
  function handleChangePageOutboundContainerDetails(event, newPage) {
    setPageOutboundContainerDetails(newPage);
  }

  function handleChangeRowsPerPageOutboundContainerDetails(event) {
    setRowsPerPageOutboundContainerDetails(parseInt(event.target.value, 10));
  }
  const classes = useStyles();
  return (
    <>
      <Modal show={showContainerPort} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-light font-weight-bold">
                        Line
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Item #
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        PO #
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Description
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        SKU
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Weight
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Cube
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Size
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Color
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Whs
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Qty Recv
                      </TableCell>
                      <TableCell className="bg-light font-weight-bold">
                        Qty Shpd
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ContainersDetails?.slice(
                      pageContainer * rowsPerPageContainer,
                      pageContainer * rowsPerPageContainer +
                        rowsPerPageContainer
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>{item.Line}</TableCell>
                        <TableCell>{item.Item}</TableCell>
                        <TableCell>{item.Po}</TableCell>
                        <TableCell>{item.Description}</TableCell>
                        <TableCell>{item.Sku}</TableCell>
                        <TableCell>{currencyFormat(item.Weight)}</TableCell>
                        <TableCell>{currencyFormat(item.Cube)}</TableCell>
                        <TableCell>{item.Size}</TableCell>
                        <TableCell>{item.Color}</TableCell>
                        <TableCell>{item.Whs}</TableCell>
                        <TableCell>{item.QtyRecv}</TableCell>
                        <TableCell>{item.QtyShpd}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell className="bg-light">
                        <strong>Totals</strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["Weight"] || 0),
                            0
                          ).toFixed(2) || 0}{" "}
                          KGS
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["Cube"] || 0),
                            0
                          ).toFixed(2) || 0}{" "}
                          CBM
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light"></TableCell>
                      <TableCell className="bg-light">
                        <strong>
                          {ContainersDetails.reduce(
                            (a, b) => a + (b["QtyRecv"] || 0),
                            0
                          ).toFixed(2) || 0}
                        </strong>
                      </TableCell>
                      <TableCell className="bg-light"></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
                {ContainersDetails?.length > 0 ? (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={ContainersDetails?.length}
                    rowsPerPage={rowsPerPageContainer}
                    page={pageContainer}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageContainer}
                    onChangeRowsPerPage={handleChangeRowsPerPageContainer}
                    ActionsComponent={TablePaginationActions}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowContainerPort(false);
              setPageContainer(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showTransload} size="xl">
        <Modal.Header>
          <Modal.Title>
            Performance Detail: <strong>{performancetavselected}</strong>
          </Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm"
              onClick={handleClick}
            >
              Export as
              <Icon className={classes.rightIcon}>save</Icon>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  Export(
                    PerformanceReportDetails,
                    "excel",
                    `Performance Detail: ${performancetavselected} _`
                  );
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  Export(
                    PerformanceReportDetails,
                    "csv",
                    `Performance Detail: ${performancetavselected} _`
                  );
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  Export(
                    PerformanceReportDetails,
                    "json",
                    `Performance Detail: ${performancetavselected} _`
                  );
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>

          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setPage(0);
              setShowTransload(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Container</TableCell>
                      <TableCell
                        className="bg-light"
                        style={
                          performancetavselected !== "Planned to Received"
                            ? { display: "none" }
                            : { display: "table-cell" }
                        }
                      >
                        Planned
                      </TableCell>
                      <TableCell
                        className="bg-light"
                        style={
                          performancetavselected === "Planned to Received" ||
                          performancetavselected === "Received to Ready"
                            ? { display: "table-cell" }
                            : { display: "none" }
                        }
                      >
                        Received
                      </TableCell>
                      <TableCell
                        style={
                          performancetavselected !== "Received to Ready"
                            ? { display: "none" }
                            : { display: "table-cell" }
                        }
                        className="bg-light"
                      >
                        Ready to Process
                      </TableCell>
                      <TableCell
                        className="bg-light"
                        style={
                          performancetavselected !== "Ready to Processed"
                            ? { display: "none" }
                            : { display: "table-cell" }
                        }
                      >
                        Qc Date
                      </TableCell>
                      <TableCell
                        className="bg-light"
                        style={
                          performancetavselected === "Ready to Processed" ||
                          performancetavselected === "Processed to Shipped"
                            ? { display: "table-cell" }
                            : { display: "none" }
                        }
                      >
                        Processed
                      </TableCell>
                      <TableCell
                        className="bg-light"
                        style={
                          performancetavselected !== "Processed to Shipped"
                            ? { display: "none" }
                            : { display: "table-cell" }
                        }
                      >
                        Shipped
                      </TableCell>
                      <TableCell className="bg-light">TransloadTime</TableCell>
                      <TableCell className="bg-light">Customer Note</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportDetails.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {
                            <ModelManagmentFirstDashboard
                              container={item.Container}
                              receiptId={item.Id}
                              isReporting={true}
                            />
                          }
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected !== "Planned to Received"
                              ? { display: "none" }
                              : { display: "table-cell" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.PlannedDate)}
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected === "Planned to Received" ||
                            performancetavselected === "Received to Ready"
                              ? { display: "table-cell" }
                              : { display: "none" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.Received)}
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected !== "Received to Ready"
                              ? { display: "none" }
                              : { display: "table-cell" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.processed)}
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected !== "Ready to Processed"
                              ? { display: "none" }
                              : { display: "table-cell" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.QCDate)}
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected === "Ready to Processed" ||
                            performancetavselected === "Processed to Shipped"
                              ? { display: "table-cell" }
                              : { display: "none" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.ProcessedDate)}
                        </TableCell>
                        <TableCell
                          style={
                            performancetavselected !== "Processed to Shipped"
                              ? { display: "none" }
                              : { display: "table-cell" }
                          }
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {getDateName(item.ShippedDate)}
                        </TableCell>
                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {item.TransloadTime}
                        </TableCell>

                        <TableCell
                          className={`${
                            item.TransloadTime.includes("More Than")
                              ? "bg-danger-o-50"
                              : ""
                          }`}
                        >
                          {item.CustomerNote !== null &&
                          item.CustomerNote !== "" &&
                          item.CustomerNote !== undefined ? (
                            <OverlayTrigger
                              overlay={
                                <Tooltip
                                  style={{ pointerEvents: "none" }}
                                  id="products-delete-tooltip"
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: item.CustomerNote,
                                    }}
                                  />
                                </Tooltip>
                              }
                            >
                              <a className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1">
                                <span className="svg-icon svg-icon-md svg-icon-warning">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/General/Clipboard.svg"
                                    )}
                                  />
                                </span>
                              </a>
                            </OverlayTrigger>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={PerformanceReportDetails?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowTransload(false);
              setPage(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showInBound} size="xl">
        <Modal.Header>
          <Modal.Title>Inbound Container Details</Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm"
              onClick={handleClick}
            >
              Export as
              <Icon className={classes.rightIcon}>save</Icon>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "excel", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "csv", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionInboundDetails.map((item) => {
                    var onj = {
                      Container: item.Container,
                      Received: item.Received,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "json", `Inbound Container Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>

          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setShowInBound(false);
              setPageInboundContainerDetails(0);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Container</TableCell>
                      <TableCell className="bg-light">Received</TableCell>
                      <TableCell className="bg-light">Released</TableCell>
                      <TableCell className="bg-light">Devanned</TableCell>
                      <TableCell className="bg-light">Cube (CBM)</TableCell>
                      <TableCell className="bg-light">Weight (KGS)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportData[
                      performancetavselected
                    ]?.DimensionInboundDetails?.slice(
                      pageInboundContainerDetails *
                        rowsPerPageInboundContainerDetails,
                      pageInboundContainerDetails *
                        rowsPerPageInboundContainerDetails +
                        rowsPerPageInboundContainerDetails
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>
                          <ModelManagmentFirstDashboard
                            container={item.Container}
                            receiptId={item.Receipt}
                            isReporting={true}
                          />
                        </TableCell>
                        <TableCell>{getDateName(item.Received)}</TableCell>
                        <TableCell>{getDateName(item.ReleaseDate)}</TableCell>
                        <TableCell>{getDateName(item.DevanDate)}</TableCell>
                        <TableCell>
                          {item.Cube != null
                            ? currencyFormat(item.Cube)
                            : "0.00"}
                        </TableCell>
                        <TableCell>
                          {item.Weight != null
                            ? currencyFormat(item.Weight)
                            : "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportData[performancetavselected]
                  ?.DimensionInboundDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={
                      PerformanceReportData[performancetavselected]
                        ?.DimensionInboundDetails?.length
                    }
                    rowsPerPage={rowsPerPageInboundContainerDetails}
                    page={pageInboundContainerDetails}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageInboundContainerDetails}
                    onChangeRowsPerPage={
                      handleChangeRowsPerPageInboundContainerDetails
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowInBound(false);
              setPageInboundContainerDetails(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showOutBound} size="xl">
        <Modal.Header>
          <Modal.Title>Outbound Trailer Details</Modal.Title>
          <ButtonToolbar
            style={{ right: "79px", position: "absolute" }}
            className="btn-text-primary"
            className={classes.button}
          >
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              className="btn btn-outline-success btn-sm"
              onClick={handleClick}
            >
              Export as
              <Icon className={classes.rightIcon}>save</Icon>
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Consignee: item?.Consignee,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Shipped: item.Shipped,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "excel", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-excel"></i>
                </ListItemIcon>
                <ListItemText primary="Excel" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Consignee: item?.Consignee,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Shipped: item.Shipped,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "csv", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="fas fa-file-csv"></i>
                </ListItemIcon>
                <ListItemText primary="CSV" />
              </StyledMenuItem>
              <StyledMenuItem
                onClick={(event) => {
                  var data = [];
                  PerformanceReportData[
                    performancetavselected
                  ].DimensionOutboundDetails.map((item) => {
                    var onj = {
                      Shipment: item.Shipment,
                      Trailer: item.Trailer,
                      Consignee: item?.Consignee,
                      Released: item.ReleaseDate,
                      Devanned: item.DevanDate,
                      Loaded: item.Loaded,
                      Shipped: item.Shipped,
                      "Cube (CBM)": item.Cube,
                      "Weight (KGS)": item.Weight,
                    };
                    data.push(onj);
                  });
                  Export(data, "json", `Outbound Trailer Details _`);
                }}
              >
                <ListItemIcon>
                  <i className="far fa-file-code"></i>
                </ListItemIcon>
                <ListItemText primary="Json" />
              </StyledMenuItem>
            </StyledMenu>
          </ButtonToolbar>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setShowOutBound(false);
              setPageOutboundContainerDetails(0);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="col-lg-12 mt-5">
              <div className="react-bootstrap-table table-responsive">
                <Table className={classes.table} size="normal">
                  <TableHead className="mb-4">
                    <TableRow>
                      <TableCell className="bg-light">Shipment</TableCell>
                      <TableCell className="bg-light">Trailer</TableCell>
                      <TableCell className="bg-light">Consignee</TableCell>
                      <TableCell className="bg-light">Released</TableCell>
                      <TableCell className="bg-light">Devanned</TableCell>
                      <TableCell className="bg-light">Loaded</TableCell>
                      <TableCell className="bg-light">Delivered</TableCell>
                      <TableCell className="bg-light">Cube (CBM)</TableCell>
                      <TableCell className="bg-light">Weight (KGS)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PerformanceReportData[
                      performancetavselected
                    ]?.DimensionOutboundDetails?.slice(
                      pageOutboundContainerDetails *
                        rowsPerPageOutboundContainerDetails,
                      pageOutboundContainerDetails *
                        rowsPerPageOutboundContainerDetails +
                        rowsPerPageOutboundContainerDetails
                    ).map((item, index) => (
                      <TableRow>
                        <TableCell>{item.Shipment}</TableCell>
                        <TableCell>
                          <ModelManagmentFirstDashboardSm
                            trailer={item.Trailer}
                            id={item.Shipment}
                            isDashboard={true}
                          />
                        </TableCell>
                        <TableCell>{item?.Consignee}</TableCell>
                        <TableCell>{getDateName(item.ReleaseDate)}</TableCell>
                        <TableCell>{getDateName(item.DevanDate)}</TableCell>
                        <TableCell>{getDateName(item.Loaded)}</TableCell>
                        <TableCell>{getDateName(item.Delivred)}</TableCell>
                        <TableCell>
                          {item.Cube != null
                            ? currencyFormat(item.Cube)
                            : "0.00"}
                        </TableCell>
                        <TableCell>
                          {item.Weight != null
                            ? currencyFormat(item.Weight)
                            : "0.00"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {PerformanceReportData[performancetavselected]
                  ?.DimensionOutboundDetails && (
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    colSpan={3}
                    count={
                      PerformanceReportData[performancetavselected]
                        ?.DimensionOutboundDetails?.length
                    }
                    rowsPerPage={rowsPerPageOutboundContainerDetails}
                    page={pageOutboundContainerDetails}
                    SelectProps={{
                      inputProps: { "aria-label": "Rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePageOutboundContainerDetails}
                    onChangeRowsPerPage={
                      handleChangeRowsPerPageOutboundContainerDetails
                    }
                    ActionsComponent={TablePaginationActions}
                  />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setShowOutBound(false);
              setPageOutboundContainerDetails(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className={`card card-custom ${className}`}>
        {TransloadPerformanceReportLoadingSpinner2 && (
          <ProgressBar
            variant="success"
            animated
            now={100}
            style={{ height: "3px", width: "100%" }}
          />
        )}
        <div className="col-md-12 mt-5">
          <Tabs
            value={valuesTabParent}
            onChange={handleChangeParent}
            variant="scrollable"
            indicatorColor="primary"
            textColor="primary"
            className="p-0"
          >
            <Tab
              key={0}
              className="p-0"
              label={
                <React.Fragment>
                  <span
                    className="font-weight-boldest"
                    style={{
                      fontSize: 14,
                      paddingRight: "3rem",
                      textTransform: "initial",
                    }}
                  >
                    Performance Report
                  </span>
                </React.Fragment>
              }
            />
            <Tab
              key={1}
              className="p-0"
              label={
                <React.Fragment>
                  <span
                    className="font-weight-boldest"
                    style={{
                      fontSize: 14,
                      paddingRight: "3rem",
                      textTransform: "initial",
                    }}
                  >
                    Performance Report Graph
                  </span>
                </React.Fragment>
              }
            />
          </Tabs>
          {valuesTabParent === 0 && (
            <div className="card-body d-flex flex-column">
              <div className="form-group row">
                <div className="col-md-10">
                  <RangeDatePicker
                    startDate={startDateValue}
                    endDate={endDateValue}
                    onChange={(startDate, endDate) => {
                      setStartDate(startDate);
                      setEndDate(endDate);
                      onChange(startDate, endDate);
                    }}
                    dateFormat="MM/DD/YYYY"
                    minDate={new Date(1900, 0, 1)}
                    maxDate={new Date(2100, 0, 1)}
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    disabled={false}
                    className="my-own-class-name"
                    startWeekDay="monday"
                  />
                </div>
                <div className="col-md-2 mt-4">
                  <button
                    className="btn btn-primary font-weight-bolder font-size-sm"
                    id="SearchPerformanceReport"
                    onClick={() => {
                      setTransloadPerformanceReportLoadingSpinner2(true);
                      var startDate = moment(startDateValue).format(
                        "YYYY-MM-DD"
                      );
                      var endDate = moment(endDateValue).format("YYYY-MM-DD");
                      var SearchText = document.getElementById(
                        "searchGlobalText"
                      ).value;
                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `bearer ${db.read().getState().Token}`
                      );
                      myHeaders.append("Content-Type", "application/json");
                      myHeaders.append("X-FP-API-KEY", "iphone");
                      var requestOptions = {
                        method: "GET",
                        withCredentials: true,
                        headers: myHeaders,
                        redirect: "follow",
                      };
                      fetch(
                        `${
                          window.$apiurl
                        }/DashboardLulus/PerformanceReport?customerId=${2271}&keyword=${SearchBy}${SearchText}&startDate=${startDate}&endDate=${endDate}`,
                        requestOptions
                      )
                        .then((response) => response.text())
                        .then((result) => {
                          setTransloadPerformanceReportLoadingSpinner2(false);
                          var obj = JSON.parse(result);
                          setPerformanceReportData(obj || []);
                        })
                        .catch((error) => console.log("error", error));
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
              <div className="form-group row mb-5 mt-4">
                <div className="col-md-12 mt-5">
                  <Tabs
                    value={valuesTab}
                    onChange={handleChange}
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="primary"
                    className="p-0"
                  >
                    {PerformanceReportData.map((items, indexs) => {
                      return (
                        <Tab
                          key={indexs}
                          className="p-0"
                          label={
                            <React.Fragment>
                              <span
                                className="font-weight-boldest"
                                style={{
                                  fontSize: 14,
                                  paddingRight: "3rem",
                                  textTransform: "initial",
                                }}
                              >
                                {items.Bucket}
                              </span>
                            </React.Fragment>
                          }
                        />
                      );
                    })}
                  </Tabs>
                </div>
              </div>
              {PerformanceReportData.map((items, indexs) => {
                return (
                  <>
                    {valuesTab === indexs && (
                      <div className="form-group row mb-5">
                        <div className="col-md-12 pr-0 mb-7 text-right">
                          <div className="react-bootstrap-table table-responsive">
                            <Table className={classes.table} size="normal">
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    className="font-weight-bold px-1"
                                    colSpan="3"
                                  >
                                    <strong>ALL Pos</strong>
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="">Category</TableCell>
                                  <TableCell className="">Quantity</TableCell>
                                  <TableCell className="">Percent</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {items.Data.map((item, index) => (
                                  <TableRow>
                                    <TableCell>{item.Category}</TableCell>
                                    <TableCell>{item.Quantity}</TableCell>
                                    <TableCell>
                                      {item.Quantity !== undefined
                                        ? currencyFormat(
                                            (item.Quantity /
                                              items.Data.reduce(
                                                (a, b) =>
                                                  a + (b["Quantity"] || 0),
                                                0
                                              )) *
                                              100
                                          )
                                        : "0.00"}
                                      %
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell className="bg-light">
                                    <strong>Total</strong>
                                  </TableCell>
                                  <TableCell className="bg-light">
                                    <strong>
                                      {items.Data.reduce(
                                        (a, b) => a + (b["Quantity"] || 0),
                                        0
                                      )}
                                    </strong>
                                  </TableCell>
                                  <TableCell className="bg-light">
                                    <strong>
                                      {items.Data.reduce(
                                        (a, b) => a + (b["Quantity"] || 0),
                                        0
                                      ) === 0
                                        ? "0"
                                        : "100"}
                                      %
                                    </strong>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                          <button
                            className="btn btn-primary font-weight-bolder font-size-sm mt-5"
                            onClick={(e) => {
                              GetPerformanceLulusDetails(items.Bucket);
                              setShowTransload(true);
                              setperformancetavselected(items.Bucket);
                            }}
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          )}
          {valuesTabParent === 1 && (
            <div className="card-body d-flex flex-column">
              <div className="form-group row"><h4>Today Performance Graph</h4></div>
              <Chart2
                props={PerformanceReportGraphData || []}
                isReporting={false}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
