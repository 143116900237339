import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Link } from "react-router-dom";

export function ErrorPage404() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg5.jpg")})`
        }}
      >
        <div className="container d-flex flex-row-fluid flex-column justify-content-md-center p-12">
          <h1 className="error-title font-weight-boldest text-info mt-10 mt-md-0 mb-12">404</h1>
          <p className="font-weight-boldest display-4">Page Not Found </p>
          <p className="font-size-h3">We can't seem to find the page <br></br>you're looking for.
          </p>
          <div className="col-md-6 text-center mt-10">
            <Link to="/Home" class="btn btn-light-info font-weight-bold font-size-h3 px-20">Go Home</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
