export default [
  {
    id: 1,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "24",
    Color: "BLACK GALAXY (BLACK)",
    location: "CHICO",
    Recv: "8/7/2020",
    Proc: "",
    Pcs: "3",
    Adj: "3",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "3",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 2,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "24",
    Color: "BLACK GALAXY (BLACK)",
    location: "EASTON",
    Recv: "8/7/2020",
    Proc: "3/10/2021",
    Pcs: "0",
    Adj: "0",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "0",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021",
    DELIVERYNOTAFTER: "4/16/2021",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 3,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "24",
    Color: "BLACK GALAXY (BLACK)",
    location: "VC",
    Recv: "8/7/2020 7:22:57 AM",
    Proc: "",
    Pcs: "4",
    Adj: "4",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "4",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 4,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "25",
    Color: "BLACK GALAXY (BLACK)",
    location: "CHICO",
    Recv: "8/7/2020",
    Proc: "",
    Pcs: "16",
    Adj: "16",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "16",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 5,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "25",
    Color: "BLACK GALAXY (BLACK)",
    location: "EASTON",
    Recv: "8/7/2020",
    Proc: "",
    Pcs: "10",
    Adj: "10",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "10",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 6,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "25",
    Color: "BLACK GALAXY (BLACK)",
    location: "VC",
    Recv: "8/7/2020",
    Proc: "",
    Pcs: "5",
    Adj: "5",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "5",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 7,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "26",
    Color: "BLACK GALAXY (BLACK)",
    location: "CHICO",
    Recv: "8/7/2020 7:22:57 AM",
    Proc: "",
    Pcs: "16",
    Adj: "16",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "16",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 8,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "26",
    Color: "BLACK GALAXY (BLACK)",
    location: "EASTON",
    Recv: "8/7/2020 7:22:57 AM",
    Proc: "3/10/2021 6:43:54 AM	",
    Pcs: "10",
    Adj: "10",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "10",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
  {
    id: 9,
    Order: "284496",
    Po: "69791",
    Sku: "4836862",
    Description: "828202",
    Size: "26",
    Color: "BLACK GALAXY (BLACK)",
    location: "VC",
    Recv: "8/7/2020 7:22:57 AM",
    Proc: "",
    Pcs: "5",
    Adj: "5",
    O: "0",
    S: "0",
    D: "0",
    Qty: "0",
    Crtns: "5",

    NUMBER: "94040",
    VENDOR: "OLIA CLOTHING INC",
    STATUS: "PENDING RECEIPT",
    DELIVERYNOTBEFORE: "4/12/2021 12:00:00 AM",
    DELIVERYNOTAFTER: "4/16/2021 12:00:00 AM",
    DELIVERYASSIGNED: "",
    EXTENSION1: "",
    EXTENSION2: "",
  },
];
