import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default async function mockTransactionBilling(mock) {
  async function GetTransactionBilling(Id) {
    return await fetch(
      `${window.$apiurl}/transactionbilling/getviewmodel/${Id}`,
      {
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetBillingRates(id) {
    return await fetch(window.$apiurl + "/billingrates/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onPost("api/transactionbilling/export").reply(async ({ data }) => {
    const customerId = JSON.parse(data).data;
    const getManageLocations = await GetBillingRates(customerId);
    return [200, getManageLocations];
  });

  mock.onGet(/api\/transactionbilling\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/transactionbilling\/(\d+)/)[1];
    const obj = await GetTransactionBilling(id);
    if (!obj) {
      return [400];
    }
    return [200, obj];
  });

  function ChangeId(list) {
    for (var item in list) {
      list[item].id = list[item].Id;
      delete list[item].Id;
    }
    return list;
  }
}
