import MockUtils from "../mock.utilsDynamic";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageAddress(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(id) {
    return await fetch(`${window.$apiurl}/address?typeId=${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]["Addresses"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1]["Address"];
        a.id = a.Id;
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(`${window.$apiurl}/address`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(`${window.$apiurl}/address/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(`${window.$apiurl}/address/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewModel() {
    return await fetch(`${window.$apiurl}/address/getviewmodel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet("api/ManageAddress/GetAllApi").reply(async () => {
    const obj = await GetViewModel();
    return [200, obj];
  });

  mock.onPost("api/ManageAddress").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Code = undefined,
      Name = undefined,
      Address = undefined,
      City = undefined,
      State = undefined,
      PostalCode = undefined,
      CountryId = undefined,
      Phone = undefined,
      Fax = undefined,
      WebPage = undefined,
      Note = undefined,
      AddressTypeId = undefined,
      IsActive = false,
      Charges = [],
      CustomerId = undefined,
    } = customer;
    const newCustomer = {
      Code,
      Name,
      Address,
      City,
      State,
      PostalCode,
      CountryId,
      Phone,
      Fax,
      WebPage,
      Note,
      AddressTypeId,
      IsActive,
      Charges,
      CustomerId
    };
    if (
      newCustomer.CountryId === "UNITED STATES" ||
      newCustomer.CountryId === null
    ) {
      newCustomer.CountryId = "US";
    }
    await PostModel(newCustomer);
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageAddress/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    var id = queryParams.filter.typeId || 0;
    customerTableMock = await GetModel(id);
    if (
      queryParams.filter.Code !== "" &&
      queryParams.filter.Code !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Code).includes(String(queryParams.filter.Code)) ||
          String(word.Name)
            .toLowerCase()
            .includes(String(queryParams.filter.Code).toLowerCase()) ||
          String(word.State).includes(String(queryParams.filter.Code)) ||
          String(word.Type)
            .toLowerCase()
            .includes(String(queryParams.filter.Code)) ||
          String(word.id).includes(String(queryParams.filter.Code))
      );
    }
    //Filter by OrganisationName
    if (
      queryParams.filter.OrganisationName !== "" &&
      queryParams.filter.OrganisationName !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Name)
            .toLowerCase()
            .includes(String(queryParams.filter.OrganisationName).toLowerCase())
      );
    }
    //Filter by Customer
    if (
      queryParams.filter.customer !== "" &&
      queryParams.filter.customer !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.CustomerId)
            .toLowerCase()
            .includes(String(queryParams.filter.customer).toLowerCase())
      );
    }

    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.typeId;
    delete queryParams.filter.customer;
    delete queryParams.filter.OrganisationName;
    delete queryParams.filter.Code;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageAddress/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    var id = queryParams.filter.typeId || 0;
    customerTableMock = await GetModel(id);
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.typeId;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageAddress/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageAddress/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageAddress\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageAddress\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ManageAddress\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageAddress\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    if(customer.CountryId === "UNITED STATES"){
      customer.CountryId = "US";
    }
    if (customer.CountryId === null) customer.CountryId = "US";
    var putObj = await PutModel(customer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageAddress\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageAddress\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
