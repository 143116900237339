import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockManageWarehouse(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetWarehouses() {
    return await fetch(window.$apiurl + "/warehouses", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostWarehouse(someData) {
    return fetch(window.$apiurl + "/warehouses", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutWarehouse(someData, id) {
    let userDate = someData["customer"];
    return fetch(window.$apiurl + "/warehouses/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDate),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteWarehouse(id) {
    return await fetch(window.$apiurl + "/warehouses/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetTimeZoneInfo() {
    return await fetch(window.$apiurl + "/timezones", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetCountries() {
    return await fetch(window.$apiurl + "/countries", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/Warehouses/GetAllApi").reply(async () => {
    const obj = {};
    obj["TimeZoneInfo"] = await GetTimeZoneInfo();
    obj["Countries"] = await GetCountries();
    return [200, obj];
  });

  mock.onGet("api/Warehouses/GetWarehouses").reply(async () => {
    const obj = {};
    obj["GetWarehouses"] = await GetWarehouses();
    return [200, obj];
  });

  mock.onPost("api/ManageWarehouse").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Name = "",
      Code = "",
      Email = "",
      PhoneNumber = "",
      TimeZone = "",
      UpsNumber = "",
      FedExNumber = "",
      FuelSurcharge = "",
      FreightRate = "",
      Fax = "",
      NumberPerLocation = 1,
      Address1 = "",
      Address2 = "",
      ZipCode = "",
      Country = "",
      City = "",
      State = "",
      IsMetric = true,
      Status = 1,
      ExternalId=null,
      IsActive = 1,
      Contacts = [],
    } = customer;

    const newCustomer = {
      Name,
      Code,
      Email,
      PhoneNumber,
      IsMetric,
      TimeZone,
      UpsNumber,
      FedExNumber,
      FuelSurcharge,
      FreightRate,
      Fax,
      NumberPerLocation,
      Address1,
      Address2,
      ZipCode,
      Country,
      City,
      State,
      Status,
      ExternalId,
      IsActive,
      Contacts,
    };
    var postWarehouse = await PostWarehouse(newCustomer);
    if (postWarehouse.Code === "003") {
      Notification("error", postWarehouse.Message);
    } else if (postWarehouse.Code === "004") {
      Notification("error", postWarehouse.Message);
    } else {
      Notification("success", postWarehouse.Message);
    }
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageWarehouse/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetWarehouses();
    //Filter
    if (
      queryParams.filter.Name !== "" &&
      queryParams.filter.Name !== undefined
    ) {
      const filterSearchText = queryParams.filter.Name.toLowerCase();
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Name.toLowerCase()).includes(String(filterSearchText)) ||
          String(word.Address1.toLowerCase()).includes(
            String(filterSearchText)
          ) ||
          String(word.City.toLowerCase()).includes(String(filterSearchText)) ||
          String(word.State.toLowerCase()).includes(String(filterSearchText)) ||
          String(word.id).includes(String(queryParams.filter.Name))
      );
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.Name;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageWarehouse/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetWarehouses();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });
  mock.onPost("api/ManageWarehouse/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageWarehouse/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ManageWarehouse\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageWarehouse\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ManageWarehouse\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageWarehouse\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutWarehouse(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageWarehouse\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageWarehouse\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteWarehouse(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
