import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import {
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

export function CustomersCard() {
  const classes = useStyles();
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Freeze Transactions">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
        <div className="col-md-12 mb-5 mt-5">
          <form className={classes.container} noValidate>
            <label>New Freeze Date</label>
            <TextField
              id="date"
              type="date"
              defaultValue="2021-05-24"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </form>
        </div>
        <br></br>
        <ol>
          <li><p>Check the box(es) for each warehouse for which you wish to freeze transactions.</p></li>
          <li><p>Enter a freeze date. Purely as a caution and a reminder, dates listed above indicate oldest creation dates for the different open transaction types. Once the period is frozen, you can still close (confirm) open transactions, but they will be closed in the next period, not the current period.</p></li>
          <li><p>Push the "Freeze" button.</p></li>
        </ol>
      </CardBody>
      <CardFooter>
        <button
          type="button"
          className="btn btn-success btn-shadow-hover mr-2"
          onClick="{customersUIProps.newCustomerButtonClick}"
        >
          Freeze
          </button>
      </CardFooter>
    </Card>
  );
}
