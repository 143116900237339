//import carTableMock from "./carTableMock";
import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

export default function mockFindOrders(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  ///List of order
  mock.onGet("api/Warehouses/GetWarehouses").reply(async () => {
    const obj = {};
    obj["GetWarehouses"] = await GetOrders();
    return [200, obj];
  });

  async function GetOrders() {
    return await fetch(window.$apiurl + "/orders", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Orders/GetCustomersForOptions", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetPurchaceOrders() {
    return await fetch(window.$apiurl + "/purchaseorders", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetCountry() {
    return await fetch(window.$apiurl + "/Customers/getviewmodel", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetUnitOfMeasure() {
    return await fetch(window.$apiurl + "/Orders/GetUnitOfMeasure", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetRetailers() {
    return await fetch(window.$apiurl + "/Orders/GetRetailers", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetWarhouses() {
    return await fetch(window.$apiurl + "/Orders/GetWarehousesForOptions", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //GetCarriers
  async function GetCarriers() {
    return await fetch(window.$apiurl + "/Orders/GetCarriers", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PostManageLocations(someData) {
    return fetch("/orders/import", {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function PostOrder(someData) {
    return fetch(window.$apiurl + "/Orders", {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PutOrder(someData, id) {
    // let userDate = someData["customer"];
    // console.log("userDate")
    // console.log(userDate);
    return fetch(window.$apiurl + "/Orders/" + id, {
      method: "put",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function DeleteOrder(id) {
    return await fetch(window.$apiurl + "/Orders/" + id, {
      method: "delete",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function GetOrder(id) {
    return await fetch(window.$apiurl + "/Orders/" + id, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //---------------------------------------------------------------------

  mock.onGet("api/FindOrder/GetAllApi").reply(async () => {
    var customers = {};
    customers["customersList"] = await GetCustomers();
    customers["PurchaseOrder"] = await GetPurchaceOrders();
    customers["warhousesList"] = await GetWarhouses();
    customers["country"] = await GetCountry();
    customers["carriersList"] = await GetCarriers();
    customers["unitOfMeasure"] = await GetUnitOfMeasure();
    customers["retailers"] = await GetRetailers();

    // customers["skuList"] = await GetSkuList();
    // customers["primaryUnitList"] = await GetPrimaryUnitList();
    return [200, customers];
  });

  // mock.onPost("api/FindOrders").reply(async ({ data }) => {
  //   const { customer } = JSON.parse(data);
  //   const {
  //     CustomerId = 0,
  //     Customer="",
  //     Warehouse="",
  //     WarehouseId = 0,
  //     ReferenceNumber = ""
  //   } = customer;

  //   const id = generateUserId();
  //   const newCustomer = {
  //     id,
  //     Warehouse,
  //     Customer,
  //     CustomerId,
  //     WarehouseId,
  //     ReferenceNumber,
  //   };
  //   var postOrder = await PostOrder(newCustomer);
  //   postOrder.id=postOrder.Id;
  //   customerTableMock.push(postOrder);
  //   return [200, { customer: postOrder }];
  // });

  mock.onPost("api/FindOrders").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const { CustomerId = 0, WarehouseId = 0, ReferenceNumber = "" } = product;

    const id = generateProductId();
    const newProduct = {
      id,
      CustomerId,
      WarehouseId,
      ReferenceNumber,
    };
    var postOrder = await PostOrder(newProduct);

    postOrder.id = postOrder.Id;
    carTableMock.push(postOrder);
    return [200, { product: newProduct }];
  });
  mock.onPost("api/FindOrders/find").reply(async (config) => {
    carTableMock = await GetOrders();
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredProducts = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredProducts];
  });

  mock.onPost("api/orders/import").reply(async ({ data }) => {
    const locatioList = JSON.parse(data).data;
    var postManageLocations = await PostManageLocations(locatioList);
    return [200, "Done"];
  });

  mock.onPost("api/FindOrders/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/FindOrders/updateStatusForProducts").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/FindOrders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrders\/(\d+)/)[1];
    const product = await GetOrder(id);
    if (!product) {
      return [400];
    }

    return [200, product];
  });

  mock.onPut(/api\/FindOrders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrders\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    var postOrder = await PutOrder(product, id);
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...product };
    return [200];
  });

  mock.onDelete(/api\/FindOrders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrders\/(\d+)/)[1];
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (index === -1) return [400];
    var deleteUser = await DeleteOrder(id);
    if (!deleteUser) return [400];
    carTableMock.splice(index, 1);
    return [200];
  });
}

function generateProductId() {
  const ids = carTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
