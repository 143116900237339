import MockUtils from "./mock.utils4";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageCustomer(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Customers", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCustomer(someData) {
    return fetch(window.$apiurl + "/customers/new", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutCustomer(someData, id) {
    return fetch(window.$apiurl + "/Customers/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusPut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteCustomer(id) {
    return await fetch(window.$apiurl + "/Customers/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCountry() {
    return await fetch(window.$apiurl + "/Customers/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetCustomer(id) {
    return await fetch(window.$apiurl + "/Customers/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetWarhouses() {
    return await fetch(window.$apiurl + "/Orders/GetWarehousesForOptions", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/ManageCustomer/GetAllApi").reply(async () => {
    const obj = {};
    obj["country"] = await GetCountry();
    obj["warhousesList"] = await GetWarhouses();
    return [200, obj];
  });

  mock.onPost("/api/ManageCustomer").reply(async ({ data }) => {
    const message = "";
    const { customer } = JSON.parse(data);
    const {
      CompanyName = "",
      PhoneNumber = "",
      ExternalId = "",
      Fax = "",
      Website = "",
      Email = "",
      Country = "",
      State = "",
      Address1 = "",
      Address2 = "",
      City = "",
      PetroleumZoneId = "",
      FuelSurchargeMethodId = 0,
      MethodFuelSurchargeName = "",
      ZoneName = "",
      PostalCode = "",
      ContactName = "",
      ContactPhone = "",
      ContactEmail = "",
      LocationOptionId = 1,
      MovableUnitOptionId = 1,
      SupplierOptionId = 1,
      InvoicingOptionId = 1,
      CarrierInfoOptionId = 1,
      CreateInvoicesOptionId = 1,
      FuelSurchargePercentage = 0,
      TurnTimes = 3,
      UspNumber = "",
      fedExNumber = "",
      CarrierPostalCode = "",
      AccountingName = "",
      InterchangeId = "",
      IdQualifier = "",
      EwmsInterchangeId = "",
      EwmsIdQualifier = "",
      SpsTradingPartnerId = "",
      LowCube = "85",
      ManufacturingId = "",
      VicsBillLadingId = "",
      AutoAllocate = false,
      SplitMovableUnit = false,
      SuggestedPutWay = false,
      ReceiveAgaintAsn = false,
      UseBlindReceipt = false,
      DefaultQuantity = false,
      FileImportTracking = false,
      AllocateOrderManually = false,
      AutomaticallyTrackBy = false,
      RequireScac = false,
      ConnectionUpdatesTracking = false,
      RequireOrderClosed = false,
      ScannerApplication = false,
      RequireTrackingNumber = false,
      FullMovableUnits = false,
      LocationIdentifierReport = false,
      EnableCustomFields = false,
      AllowPurchaseOrder = false,
      EnableDashboard = false,
      InvoiceConfirmation = false,
      UseTransactionBillNumber = false,
      DisplayVendorId = false,
      IsActive = true,
      RequireCarrier = false,
      RequireLoadNumber = false,
      RequireLadingNumber = false,
      RequirePurchaseOrder = false,
      PackageCount = false,
      OneFileAtLeast = false,
      TotalPackageVolume = false,
      TotalPackageWeight = false,
      WarehousesAccess,
      WeightLimit = null,
      Contacts = [],
      CustomerServiceLevels = [],
      Status = 0,
      POManage = false,
      PrimaryWarehouseId,
      BillingAddressId,
      Sales = "",
      CreditLimit = 0,
      Balance = 0,
      LineHaulDefaultPercentage = 0,
      FuelPercentage = 0,
      InboundInvoiceOptionId = null,
      InboundInvoiceGrpOptionId = null,
      NetTermId = null,
      IsPodRequired = false,
      InboundManifestHasUpc = false,
      AvgDaysToRelease = 0,
      AvgDaysToPickupOn = 0,
      AvgDaysToDevan = 0,
      AvgDaysToPickup = 0,
      AvgDaysToReleaseHot = 0,
      AvgDaysToPickupOnHot = 0,
      AvgDaysToDevanHot = 0,
      AvgDaysToPickupHot = 0,
      DefaultModeId = 0,
      DefaultServiceId = 0,
      DefaultServiceLevelId = 0,
      
      TransitDays = 0,
      IsOnHold = false,
      AvgDaysToDischarge = 0,
      AvgDaysToAppointment = 0,
      AvgDaysToReceived = 0,
      AvgDaysToHotFlag = 0,
      
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      CompanyName,
      PhoneNumber,
      ExternalId,
      Fax,
      Website,
      Email,
      Country,
      State,
      Address1,
      Address2,
      City,
      PostalCode,
      FuelSurchargeMethodId,
      MethodFuelSurchargeName,
      PetroleumZoneId,
      ZoneName,
      ContactName,
      ContactPhone,
      ContactEmail,
      CustomerServiceLevels,
      LocationOptionId,
      MovableUnitOptionId,
      SupplierOptionId,
      InvoicingOptionId,
      CarrierInfoOptionId,
      CreateInvoicesOptionId,
      FuelSurchargePercentage,
      TurnTimes,
      UspNumber,
      fedExNumber,
      CarrierPostalCode,
      AccountingName,
      InterchangeId,
      IdQualifier,
      EwmsInterchangeId,
      EwmsIdQualifier,
      SpsTradingPartnerId,
      DefaultModeId,
      DefaultServiceId,
      DefaultServiceLevelId,
      LowCube,
      VicsBillLadingId,
      ManufacturingId,
      AutoAllocate,
      SplitMovableUnit,
      SuggestedPutWay,
      ReceiveAgaintAsn,
      UseBlindReceipt,
      DefaultQuantity,
      FileImportTracking,
      AllocateOrderManually,
      AutomaticallyTrackBy,
      RequireScac,
      ConnectionUpdatesTracking,
      RequireOrderClosed,
      ScannerApplication,
      RequireTrackingNumber,
      FullMovableUnits,
      LocationIdentifierReport,
      EnableCustomFields,
      AllowPurchaseOrder,
      EnableDashboard,
      InvoiceConfirmation,
      UseTransactionBillNumber,
      DisplayVendorId,
      IsActive,
      RequireCarrier,
      RequireLoadNumber,
      RequireLadingNumber,
      RequirePurchaseOrder,
      PackageCount,
      OneFileAtLeast,
      TotalPackageVolume,
      TotalPackageWeight,
      WarehousesAccess,
      Contacts,
      Status,
      WeightLimit,
      POManage,
      PrimaryWarehouseId,
      BillingAddressId,
      Sales,
      CreditLimit,
      Balance,
      LineHaulDefaultPercentage,
      FuelPercentage,
      InboundInvoiceOptionId,
      InboundInvoiceGrpOptionId,
      NetTermId,
      IsPodRequired,
      InboundManifestHasUpc,
      AvgDaysToRelease,
      AvgDaysToPickupOn,
      AvgDaysToDevan,
      AvgDaysToPickup,
      AvgDaysToReleaseHot,
      AvgDaysToPickupOnHot,
      AvgDaysToDevanHot,
      AvgDaysToPickupHot,
      IsOnHold,
      AvgDaysToDischarge ,
      AvgDaysToAppointment,
      AvgDaysToReceived,
      AvgDaysToHotFlag,
    };
    var postOrder = await PostCustomer(newCustomer);
    if (postOrder.Code === "200") {
      postOrder.id = postOrder.Id;
      customerTableMock.push(newCustomer);
      window.$ManageCustomerPost = 200;
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        { customer: newCustomer, message: "postOrder.Message", success: true },
      ];
    } else {
      window.$ManageCustomerPost = 400;
      window.$ManageCustomerPostMessage = postOrder.Message;
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        { customer: newCustomer, message: postOrder.Message, success: false },
      ];
    }
  });

  mock.onPost("/api/ManageCustomer/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    if (
      window.$GlobalSearchCustomerid !== null &&
      window.$GlobalSearchCustomerid !== undefined
    ) {
      queryParams.filter.id = window.$GlobalSearchCustomerid;
    }
    customerTableMock = await GetCustomers();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });
  mock.onPost("/api/ManageCustomer/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetCustomers();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });
  mock.onPost("/api/ManageCustomer/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageCustomer/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageCustomer\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomer\/(\d+)/)[1];
    const customer = await GetCustomer(id);
    if (!customer) {
      return [400];
    }
    customer.Website = customer.WebSite;
    return [200, customer];
  });

  mock.onPut(/api\/ManageCustomer\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomer\/(\d+)/)[1];
    const data = JSON.parse(config.data);
    const { customer } = data;
    await PutCustomer(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  mock.onDelete(/api\/ManageCustomer\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomer\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) return [400];
    var deleteObj = await DeleteCustomer(id);
    if (!deleteObj) return [400];
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
