import { IconButton } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import CloseIcon from "@material-ui/icons/Close";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import Select from "react-select";
import { useRemarksUIContext } from "./RemarksUIContext";
const adapter = new LocalStorage("db");
const db = low(adapter);

const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.text = searchText;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function RemarksFilter() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      setQueryParams: remarksUIContext.setQueryParams,
      openNewRemarkDialog: remarksUIContext.openNewRemarkDialog,
      queryParams: remarksUIContext.queryParams,
    };
  }, [remarksUIContext]);
  const [ShowConst, setShowConst] = useState(false);
  const suggestions2 = [{ label: "in./lbs." }, { label: "cm./kg." }].map(
    (suggestion2) => ({
      value: suggestion2.label,
      label: suggestion2.label,
    })
  );
  async function ClassCalculatorFunctionFetch() {
    return await fetch(
      window.$apiurl +
        `/FreightClass/CalculateClass?length=${lengthValue}&width=${widthValue}&height=${heightValue}&weight=${weightValue}
            &isMeter=${isMeter}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setFreightClasses(a?.Classes);
        setFreightClassValue(a?.Result);
        setCubicValueF(a?.CubicFeet.toFixed(2));
        setCubicValueM(a?.CubicMeter.toFixed(2));
        setPcfValue(a?.Density.toFixed(2));
        setFreightClassValue(a?.Result);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const [freightClassValue, setFreightClassValue] = React.useState(0);
  const [freightClasses, setFreightClasses] = React.useState([]);

  const [lengthValue, setLengthValue] = React.useState("");
  const [widthValue, setWidthValue] = React.useState("");
  const [heightValue, setHeightValue] = React.useState("");
  const [weightValue, setWeightValue] = React.useState("");
  const [cubicValueM, setCubicValueM] = React.useState(0);
  const [pcfValue, setPcfValue] = React.useState(0);
  const [cubicValueF, setCubicValueF] = React.useState(0);
  const [IsLbsWeight, setIsLbsWeight] = React.useState(true);
  const [IsFeet, setIsFeet] = React.useState(false);
  const [isMeter, setIsMeter] = React.useState(false);
  const [unitWeight, setUnitWeight] = React.useState({
    value: "in./lbs.",
    label: "in./lbs.",
  });

  useEffect(() => {
    if (
      lengthValue !== "" &&
      heightValue !== "" &&
      widthValue !== "" &&
      weightValue !== ""
    ) {
      ClassCalculatorFunctionFetch();
    } else {
      setFreightClassValue(0);
    }
  }, [
    lengthValue,
    widthValue,
    heightValue,
    weightValue,
    isMeter,
  ]);
  return (
    <>

      <Modal
        size="lg"
        show={ShowConst}
        onHide={() => {
          setShowConst(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Class Calculator</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={() => {
              setShowConst(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className="col-xl-9 col-lg-9 col-md-12">
              <Form>
                <Row>
                  <div className="col-xl-12 col-lg-12">
                    <InputGroup className="mb-3">
                      <div className="col-xl-8 pl-0">
                        <label>Weight</label>
                        <FormControl
                          placeholder=""
                          className="rounded-0"
                          aria-label=""
                          type="number"
                          value={weightValue}
                          aria-describedby="basic-addon1"
                          onChange={(e) => {
                            setWeightValue(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-xl-4 pl-0">
                        <label>Units</label>
                        <Select
                          isClearable
                          name="Units"
                          id="UnitsId"
                          label="in/lbs"
                          value={unitWeight}
                          onChange={async (e) => {
                            try {
                              if (e?.value === "in./lbs.") {
                                setIsMeter(false);
                              } else {
                                setIsMeter(true);
                              }
                              setUnitWeight(e);
                            } catch (err) {}
                          }}
                          className="rounded-0"
                          inputId="react-select-single"
                          placeholder=""
                          options={suggestions2}
                        />
                      </div>
                    </InputGroup>
                  </div>
                  <div className="col-xl-12 col-lg-12">
                    <Form.Group controlId="Width">
                      <InputGroup>
                        <div className="col-xl-4 pl-0">
                          <label>Length</label>
                          <Form.Control
                            type="number"
                            label="Length"
                            className="rounded-0"
                            aria-describedby="inputGroupPrepend"
                            value={lengthValue}
                            onChange={(e) => {
                              setLengthValue(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-xl-4 pl-0">
                          <label>Width</label>
                          <Form.Control
                            type="number"
                            label="Width"
                            className="rounded-0"
                            aria-describedby="inputGroupPrepend"
                            value={widthValue}
                            onChange={(e) => {
                              setWidthValue(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-xl-4 pl-0">
                          <label>Height</label>
                          <Form.Control
                            type="number"
                            label="Height"
                            className="rounded-0"
                            aria-describedby="inputGroupPrepend"
                            value={heightValue}
                            onChange={(e) => {
                              setHeightValue(e.target.value);
                            }}
                          />
                        </div>
{/*                         
                        <div className="col-xl-3 pl-0">
                          <label>Units</label>
                          <Select
                            isClearable
                            name="Units"
                            id="UnitsId"
                            label="in/lbs"
                            value={unitLengthValue}
                            className="rounded-0"
                            inputId="react-select-single"
                            placeholder=""
                            onChange={(e) => {
                              try {
                                if (e?.value === "ft.") {
                                  setIsFeet(true);
                                  setIsMeter(false);
                                  setUnitLength({
                                    value: "lbs.",
                                    label: "lbs.",
                                  });
                                  setIsLbsWeight(true);
                                } else if(e?.value === "cm.") {
                                  setUnitLength({ value: "kg", label: "kg" });
                                  setIsLbsWeight(false);
                                  setIsFeet(false);
                                  setIsMeter(true);
                                }else{
                                  setUnitLength({
                                    value: "lbs.",
                                    label: "lbs.",
                                  });
                                  setIsLbsWeight(true);
                                  setIsFeet(false);
                                  setIsMeter(false);
                                }
                                setUnitLength(e);
                              } catch (err) {}
                            }}
                            options={suggestions}
                          />
                        </div> */}
                      </InputGroup>
                    </Form.Group>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <label>Cubic Feet</label>
                    <h1>{cubicValueF}</h1>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <label>Cubic Meters</label>
                    <h1>{cubicValueM}</h1>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <label>PCF</label>
                    <h1>{pcfValue}</h1>
                  </div>
                  <div className="col-xl-3 col-lg-3">
                    <label>Freight Class</label>
                    <h1 className="display-4 font-weight-boldest">
                      {freightClassValue}
                    </h1>
                  </div>
                </Row>
              </Form>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-12">
              <Table responsive>
                <thead>
                  <tr className="border-bottom">
                    <th className="font-weight-boldest p-4">Density</th>
                    <th className="font-weight-boldest p-4">Class</th>
                  </tr>
                </thead>
                <tbody>
                  {freightClasses?.length > 0 &&
                    freightClasses
                      .sort((a, b) =>
                        a.EstimatedClass.localeCompare(freightClassValue)
                      )
                      .slice(0, 5)
                      .map((item) => (
                        <tr className="border-bottom">
                          <td
                            className={
                              freightClassValue === item.EstimatedClass
                                ? "font-weight-boldest p-4"
                                : "p-4"
                            }
                          >
                            {" "}
                            &gt;= {item.GreaterThanOrEqual}
                          </td>
                          <td
                            className={
                              freightClassValue === item.EstimatedClass
                                ? "font-weight-boldest p-4"
                                : "p-4"
                            }
                          >
                            {item.EstimatedClass}
                          </td>
                        </tr>
                      ))}
                </tbody>
              </Table>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light btn-elevate"
            onClick={() => {
              setWeightValue("");
              setLengthValue("");
              setWidthValue("");
              setHeightValue("");
              setCubicValueF(0);
              setCubicValueM(0);
              setPcfValue(0);
              setUnitWeight({ value: "in./lbs.", label: "in./lbs." });
              setShowConst(false);
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="form-filtration">
        <div className="row align-items-center">
          <div className="col-md-7 text-right margin-bottom-10-mobile" />
          <div className="col-xl-3 col-md-3 text-right my-5">
            {window.$ShipmentManagementModeName === "LTL" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => {
                  setShowConst(true);
                }}
              >
                <i className="fas fa-th"></i>
                Class Calculator
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-2 text-right margin-bottom-10-mobile my-5">
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={() => remarksUIProps.openNewRemarkDialog()}
            >
              New
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
