import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Card, CardBody, CardHeader } from "../../controls";

export function HistoricalLanes({ LansList, loadingLanes }) {
  const [LansListFilter, setLansListFilter] = useState(LansList || []);
  useEffect(() => {
    setLansListFilter(LansList);
  }, [LansList]);

  const columns = [
    {
      dataField: "from",
      text: "From",
      sort: true,
      formatter: (cell, row) => {
        return row?.ShipperCity + " / " + row?.ShipperState;
      },
    },
    {
      dataField: "to",
      text: "To",
      sort: true,
      formatter: (cell, row) => {
        return row?.ConsigneeCity + " / " + row?.ConsigneeState;
      },
    },
    {
      dataField: "Count",
      text: "SHIPMENTS",
    },
  ];
  return (
    <>
      <Card className="card-stretch">
        <CardHeader title="Historical Lanes"></CardHeader>
        <CardBody>
          <div className="mb-10">
            <Form>
              <Row>
                <Col>
                  <Form.Label>Historical Lanes</Form.Label>
                  <Form.Control
                    placeholder="Historical Lanes"
                    type="search"
                    onChange={(e) => {
                      var datainput = e.target.value;
                      setLansListFilter(
                        [...LansList].filter(
                          (a) =>
                            String(a.ShipperCity + " / " + a.ShipperState)
                              .toLocaleLowerCase()
                              .trim()
                              .includes(
                                String(datainput)
                                  .toLocaleLowerCase()
                                  .trim()
                              ) ||
                            String(a.ConsigneeCity + " / " + a.ConsigneeState)
                              .toLocaleLowerCase()
                              .trim()
                              .includes(
                                String(datainput)
                                  .toLocaleLowerCase()
                                  .trim()
                              )
                        )
                      );
                    }}
                  />
                </Col>
              </Row>
            </Form>
          </div>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={LansListFilter || []}
            columns={columns}
            pagination={paginationFactory({
              showTotal: true,
              sizePerPageList: [
                { text: "5", value: 5 },
                { text: "10", value: 10 },
                { text: "25", value: 25 },
                {
                  text: "All",
                  value: LansList?.length,
                },
              ],
            })}
            noDataIndication={
              loadingLanes ? (
                <h4 className="text-muted my-5" style={{ textAlign: "center" }}>
                  Loading ...
                </h4>
              ) : (
                <h4 className="text-muted my-5" style={{ textAlign: "center" }}>
                  There is no data available at the moment.
                </h4>
              )
            }
          />
        </CardBody>
      </Card>
    </>
  );
}
