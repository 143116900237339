import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  required,
  ClassLabel,
  ...props
}) {
  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  return (
    <>
      {label && (
        <label className={ClassLabel}>
          {label} {required && <RequiredHtml />}
          {span && (
            <small className="alert alert-warning text-dark p-0 pl-2 pr-2">
              {" "}
              {span}
            </small>
          )}
        </label>
      )}
      <input
        type={type}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        {...field}
        {...props}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
