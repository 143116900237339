// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import {
  Input,
  Select,
} from "../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export function RemarkEditForm({ saveRemark, remark, actionsLoading, onHide }) {
  // Validation schema
  // const ReceiptEditSchema = Yup.object().shape({
  //   Name: Yup.string()
  //     .required("Name is required"),
  // });
  const useStyles2 = makeStyles(theme => ({
    container: {
      display: "flex",
      flexWrap: "wrap"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  }));
  const classes2 = useStyles2();
  const [skuList, setskuList] = useState([]);
  //  const [primaryList, setprimaryList] = useState([]);
  const [skuData, setskuData] = useState({ Description: '' });
  const [retilerList, setretilerList] = useState([]);
  const [wareHousesList, setwareHousesList] = useState([]);
  useEffect(() => {
    fetchOrderAsync();
  }, []);
  useEffect(() => {
    if (remark.CanceledDate !== undefined && remark.CanceledDate !== null && remark.CanceledDate !== "") {
      document.getElementById("CanceledDate").value = remark.CanceledDate;
    }
    if (remark.ExcpectedDate !== undefined && remark.ExcpectedDate !== null && remark.ExcpectedDate !== "") {
      document.getElementById("ExcpectedDate").value = remark.ExcpectedDate;
    }
    if (remark.Description !== undefined && remark.Description !== null && remark.Description !== "") {
      setTimeout(function () {
        document.getElementById("DescriptionItem").value = remark.Description;
      }, 1000);
    }

  }, [remark]);
  // useEffect(() => {
  //   if(remark.Description != undefined && remark.Description != null&& remark.Description != ""){
  //     setTimeout(function(){         
  //       document.getElementById("DescriptionItem").value = remark.Description; 
  //   }, 1000);
  //   }

  //   if(remark.PrimaryUnit != undefined && remark.PrimaryUnit != null&& remark.PrimaryUnit != ""){
  //     setTimeout(function(){         
  //       document.getElementById("PrimaryUnitId").value = remark.PrimaryUnit; 
  //   }, 1000);
  //   }
  // }, [remark]);
  const fetchOrderAsync = async () => {
    try {
      const result = await axios.get("api/orderLineItems/GetAllApi");
      setskuList(result.data["skuList"]);
      const result2 = await axios.get("api/purchaseorders/getRetiler");
      console.log("result2");
      console.log(result2.data["RetilersList"]);
      console.log(result.data["warhousesList"]);
      if (result2.status === 200) {
        console.log("result2.data");
        console.log(result2.data["RetilersList"]);
        setretilerList(result2.data["RetilersList"]);
        setwareHousesList(result2.data["warhousesList"]);
      }
    } catch (e) { }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={remark}
        //validationSchema={}
        onSubmit={(values) => {
          saveRemark(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-6 mt-3">
                    <Select name="ItemId" label="ItemId"
                      onChange={(event) => {
                        setFieldValue("ItemId", event.target.value);

                        skuList.filter(function (element) {
                          if (element.Id === event.target.value) {
                            setskuData({
                              Description: element.Description,
                            });
                          }
                        });
                      }}
                    >
                      <option>Select</option>
                      {skuList.map((item, index) => (
                        <option value={item.Id}>{item.Sku}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-6 mt-3">
                    <Field
                      name="Description"
                      id="DescriptionItem"
                      component={Input}
                      placeholder="Description"
                      label="Description"
                      disabled="disabled"
                      value={skuData.Description}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mt-3">
                    <Select name="QualifierId" label="QualifierId">
                      <option>Select</option>
                      {retilerList?.map((item, index) => (
                        <option value={item.id}>{item.Name}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-6 mt-3">
                    <Field
                      name="Quantity"
                      component={Input}
                      placeholder="Quantity"
                      label="Quantity"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mt-3">
                    <Select name="WarehouseId" label="Warehouse*">
                      <option>Select</option>
                      {wareHousesList.map((item, index) => (
                        <option value={item.Id}>{item.Name}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-6 mt-3">
                    <Field
                      name="Notes"
                      component={Input}
                      placeholder="Notes"
                      label="Notes"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-6 mt-3">
                    <TextField
                      id="ExcpectedDate"
                      name="ExcpectedDate"
                      label="Excpected Date"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      className={classes2.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(event) => {
                        setFieldValue("ExcpectedDate", event.target.value);
                      }}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      id="CanceledDate"
                      name="CanceledDate"
                      label="Canceled Date"
                      type="datetime-local"
                      defaultValue="2017-05-24T10:30"
                      className={classes2.textField}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={(event) => {
                        setFieldValue("CanceledDate", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
