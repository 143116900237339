import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./ScrollingMenuApp.css";
import $ from "jquery";

const MenuItem = ({ text, selected, label }) => {
  return (
    <div
      className={`ScrollingMenu-item Shipment ${
        selected ? "active" : ""
      } N${text}`}
    >
      {"Shipment " + text}{" "}
      {label !== null && (
        <span class={`badge badge-${selected ? "light" : "info"} py-1`}>
          {label}
        </span>
      )}
    </div>
  );
};

const Menu = (list, selected) =>
  list.map((el) => {
    const { name, label } = el;
    return (
      <MenuItem text={name} key={name} selected={selected} label={label} />
    );
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: "",
  className: "fa fa-chevron-circle-left fSize",
});
const ArrowRight = Arrow({
  text: "",
  className: "fa fa-chevron-circle-right fSize",
});

export function ScrollingMenu({ list, id = "" }) {
  var resArr = [];
  list.forEach(function(item) {
    var i = resArr.findIndex((x) => x.name === item?.name);
    if (i <= -1) {
      resArr.push(item);
    }
  });

  const [selected, setselected] = useState();
  const menu = Menu(resArr, selected);
  const onSelect = (key) => {
    $("#TabsBtn" + key).click();
    setTimeout(() => {
      $(".N" + key).addClass("active");
    }, 50);
    setselected(key);
  };
  return (
    <div
      className="bg-light form-control p-1 pl-5 pr-5 ScrollingMenuApp mt-1"
      id={id}
    >
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        onSelect={onSelect}
        scrollToSelected={true}
      />
    </div>
  );
}
