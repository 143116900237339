import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import UpdateShipmentStatus from "../../../../../../pages/shipment/UpdateShipmentStatus";

const ProductStatusCssClasses = ["success", "info", "success"];
export const StatusColumnFormatter = (cellContent, row) => {
  return <StatusColumnFormatter2 row={row} />;
};

const StatusColumnFormatter2 = ({ row }) => {
  const [show, setshow] = useState(false);
  if (row.StatusId === 20 || row.StatusId === null) {
    return (
      <span className={`label label-lg label-light-danger label-inline`}>
        New
      </span>
    );
  }
  const close = () => {
    setshow(false);
    //document.getElementById("ReLoadTableId").click();
  };
  const SaveDone = () => {
    setshow(false);
    //document.getElementById("ReLoadTableId").click();
  };
  const shipmentStatus = window.ShipmentStatus || [];
  return (
    <div>
      <Modal size="xl" show={show}>
        <UpdateShipmentStatus
          Close={() => close}
          ShipmentId={row.id}
          StatusId={row.ParentStatusId - 1}
          SaveDone={SaveDone}
        />
      </Modal>
      <span
        className={`label label-lg label-light-${
          ProductStatusCssClasses[row.StatusId]
        } label-inline`}
        //style={{ cursor: "pointer" }}
        //onClick={() => setshow(true)}
      >
        {row.Status}
      </span>
    </div>
  );
};
