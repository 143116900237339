import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
const fadeImages = [
  "/media/bg/bg-home5.jpg",
  "/media/bg/bg-home2.jpg",
  "/media/bg/home-bg5.jpg",
  "/media/bg/bg-home4.jpg"
];
const properties = {
  infinite: true,
  indicators: false,
  arrows: false,
  autoPlayInterval:300,
  transitionDuration:1000,
  duration:3000,
  autoPlayDirection: "rtl",
  autoPlay: true,
  fadeOutAnimation: true,
  mouseTrackingEnabled: true,
  disableAutoPlayOnAction: true,
  onChange: (oldIndex, newIndex) => {
    console.log(`slide transition from ${oldIndex} to ${newIndex}`);
  }
}
export function Body() {
  return (
    <div className="container-fluid px-0">
      <Fade  {...properties}>
        <div className="each-slide" style={{ width: "100%" }} >
          <div className="container-fluid bg-home" style={{ 'backgroundImage': `url(${toAbsoluteUrl(fadeImages[0])})`, objectFit: "cover", width: "100%" }}>

            <div className="d-flex justify-content-between align-items-center  border-white py-16">
              <Link to="/" className="flex-column-auto mt-5 d-none">
                <img
                  alt="Logo"
                  className="max-h-50px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
            </div>

            <div className="d-flex align-items-stretch text-center flex-column py-40 my-5">

              <h1 className="text-white font-weight-bolder mb-13 display-1">Welcome To eMPower</h1>
            </div>
          </div>
        </div>
        <div className="each-slide" style={{ width: "100%" }}>
          <div className="container-fluid bg-home" style={{ 'backgroundImage': `url(${toAbsoluteUrl(fadeImages[1])})`, objectFit: "cover", width: "100%" }}>

            <div className="d-flex justify-content-between align-items-center  border-white py-16">
              <Link to="/" className="flex-column-auto mt-5 d-none">
                <img
                  alt="Logo"
                  className="max-h-50px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
            </div>

            <div className="d-flex align-items-stretch text-center flex-column py-40 my-5">

              <h1 className="text-white font-weight-bolder mb-13 display-1">Welcome To eMPower</h1>
            </div>
          </div>
        </div>
        <div className="each-slide" style={{ width: "100%" }}>
          <div className="container-fluid bg-home" style={{ 'backgroundImage': `url(${toAbsoluteUrl(fadeImages[2])})`, objectFit: "cover", width: "100%" }}>

            <div className="d-flex justify-content-between align-items-center  border-white py-16">
              <Link to="/" className="flex-column-auto mt-5 d-none">
                <img
                  alt="Logo"
                  className="max-h-50px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
            </div>

            <div className="d-flex align-items-stretch text-center flex-column py-40 my-5">

              <h1 className="text-white font-weight-bolder mb-13 display-1">Welcome To eMPower</h1>
            </div>
          </div>
        </div>
        <div className="each-slide" style={{ width: "100%" }}>
          <div className="container-fluid bg-home" style={{ 'backgroundImage': `url(${toAbsoluteUrl(fadeImages[3])})`, objectFit: "cover", width: "100%" }}>

            <div className="d-flex justify-content-between align-items-center  border-white py-16">
              <Link to="/" className="flex-column-auto mt-5 d-none">
                <img
                  alt="Logo"
                  className="max-h-50px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
            </div>

            <div className="d-flex align-items-stretch text-center flex-column py-40 my-5">

              <h1 className="text-white font-weight-bolder mb-13 display-1">Welcome To eMPower</h1>
            </div>
          </div>
        </div>
      </Fade>
      <div className="container py-8">
        <div className="row">
          <div className="col-lg-4">

            <div className="card card-custom wave wave-animate-slow wave-primary  mb-8 mb-lg-0">
              <div className="card-body zoomout">
                <div className="text-center p-5">

                  <div className="mr-6 mb-3">
                    <span className="svg-icon svg-icon-primary svg-icon-4x">
                      <SVG src={toAbsoluteUrl("/media/svg/home/distributed.svg")} />
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    <Link to="/dashboard/">
                      <Button
                        variant="primary"
                        onClick={() => {
                          window.monuType = "DISTRIBUTION";
                        }}
                      >
                        Go DISTRIBUTION
                </Button>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">

            <div className="card card-custom wave wave-animate-slow wave-danger  mb-8 mb-lg-0">
              <div className="card-body zoomout">
                <div className="text-center p-5">

                  <div className="mr-6 mb-3">
                    <span className="svg-icon svg-icon-danger svg-icon-4x">
                      <SVG src={toAbsoluteUrl("/media/svg/home/loader.svg")} />
                    </span>
                  </div>

                  <div className="d-flex flex-column">
                    {/* <Link to="/transload/dashboard"> */}
                    <Link to={`${db.read().getState().User.IsCustomer === true &&db.read().getState().User.Customers[0]?.Name=== "Lulus" ?"/Dashboards/LulusDashboard":"/CustomerDashboard/GlobalDashboard"}`}>
                      <Button
                        variant="danger"
                        onClick={() => {
                          window.monuType = "TRANSLOAD";
                        }}
                      >
                        Go TRANSLOAD
              </Button>
                    </Link>
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className="col-lg-4">

            <div className="card card-custom wave wave-animate-slow wave-success  mb-8 mb-lg-0">
              <div className="card-body zoomout">
                <div className="text-center p-5">

                  <div className="mr-6 mb-3">
                    <span className="svg-icon svg-icon-success svg-icon-4x">
                      <SVG src={toAbsoluteUrl("/media/svg/home/delivery-truck.svg")} />
                    </span>
                  </div>
                  <div className="d-flex flex-column">
                    <Link to="/TransportManagementSystems">
                      <Button
                        variant="success"
                        onClick={() => {
                          window.monuType = "TRANSPORTATION";
                        }}
                      >
                        Go TRANSPORTATION
              </Button>
                    </Link>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
