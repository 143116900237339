/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import { useHtmlClassService } from "../../../layout";

export function Volume({ className }) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById("kt_performance_widget_12_chart");

        if (!element) {
            return;
        }

        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);

    return (
        <div className={`card card-custom ${className}`}>
            <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">
                    Volume
                </h3>
            </div>
            <div className="card-body d-flex flex-column">
                <div className="col-12">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                        <div
                            id="kt_performance_widget_12_chart"
                            className="card-rounded-bottom"
                            style={{ height: "180px" }}
                        ></div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-12 col-12 mx-auto mb-5">
                        <div className="card border-dashed border">
                            <div className="card-body align-self-center py-3">
                                <h5 className="text-info mb-2 text-center font-weight-bold">Revenue</h5>
                                <h1 className="text-dakr-75 font-weight-bolder text-center">$63,240.00</h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-10 col-lg-10 col-md-12 col-12 mx-auto">
                        <div className="card border-dashed border">
                            <div className="card-body align-self-center py-3">
                                <h5 className="text-success mb-2 text-center font-weight-bold">Margin</h5>
                                <h1 className="text-dakr-75 font-weight-bolder text-center">$12,600.00</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getChartOption(layoutProps) {
    const options = {
        series: [20],
        colors: ["#F1416C"],
        chart: {
            type: 'radialBar',

        },
        plotOptions: {
            radialBar: {
                borderRadius: 10,
                startAngle: -90,
                endAngle: 90,
                track: {
                    background: "#FBC6D3",
                    strokeWidth: '97%',
                    margin: 5, // margin is in pixels
                },
            }
        },

        grid: {
            padding: {
                top: -20
            }
        },
        labels: ['Margin'],
    };
    return options;
}
