import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import { Modal } from "react-bootstrap";
import { default as JsNative } from "jquery";
import Checkbox from "@material-ui/core/Checkbox";
import { Notification } from "../../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Item({
  item,
  SetTableShipment,
  removeId,
  CheckboxList,
  SelectIDforPallet,
  CheckboxListId,
  setCheckboxListIds,
  setLoading,
  CheckboxListIds,
  isWarningColor = false,
}) {
  const [ModelShow, setModelShow] = useState(false);
  const [stateItem, setstateItem] = useState(item);
  const [IsChecked, setIsChecked] = useState(false);

  var tableElement, offset;
  function GetTale() {
    tableElement = JsNative(".TableComponent" + stateItem?.ShipID);
    offset = tableElement.offset();
  }

  useEffect(() => {
    setstateItem(item);
  }, [item]);

  useEffect(() => {
    if (stateItem?.Id) {
      var check = CheckboxListIds.find((x) => x === stateItem?.Id);
      if (check) setIsChecked(true);
      else setIsChecked(false);
    }
  }, [CheckboxListIds]);

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      ele.addClass("bg-light-info");
    } else ele.removeClass("bg-light-info");
    ele.addClass("border border-info border-2");
  }

  function handleStop(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      setModelShow(true);
    }
    ele.removeClass("bg-light-info");
    ele.removeClass("border border-info border-2");
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleSave() {
    setLoading(true);
    PostPallet(stateItem?.Id, stateItem?.ShipID).then(({ status, Message }) => {
      if (200 === status) {
        setCheckboxListIds([]);
        Notification("success", "success : " + stateItem?.Id);
        SetTableShipment(stateItem);
        removeId(stateItem?.Id);
        setLoading(false);
      } else {
        Notification("error", Message?.toString());
      }
    });
  }

  return (
    <>
      {stateItem?.Items > 0 && (
        <div
          className={"col-xl-4 col-lg-4 col-sm-4 my-1 "}
          style={{
            zIndex: 2,
          }}
        >
          <Modal show={ModelShow}>
            <Modal.Header closeButton>
              <Modal.Title>Pallet {stateItem?.Id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure to add this Pallet
              <b className="text-primary ml-1">({stateItem?.Id})</b> to Shipment
              <b className="text-primary ml-1">({stateItem?.ShipID})</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                className="btn btn-light btn-elevate mr-2"
                onClick={() => {
                  setModelShow(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="btn btn-primary btn-elevate"
                onClick={() => {
                  setModelShow(false);
                  handleSave();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Draggable
            axis="both"
            handle=".handle"
            position={{ x: 0, y: 0 }}
            grid={[1, 1]}
            scale={1}
            onDrag={handleDrag}
            onStop={handleStop}
          >
            <div className={stateItem?.Items > 0 && "handle"}>
              <div
                className={"shadow-sm p-0 pl-2 pt-5 mb-5 rounded bg-white"}
                style={{
                  height: isWarningColor ? "6.5vw" : "5.5vw",
                  maxHeight: "130px",
                  cursor: "pointer",
                }}
              >
                <b
                  className={`position-absolute text-${
                    isWarningColor ? "warning" : "info"
                  }`}
                  style={{
                    top: "0.5vw",
                    fontSize: "1vw",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className={`fas fa-chevron-circle-right text-${
                      isWarningColor ? "warning" : "info"
                    } mr-1`}
                    style={{
                      fontSize: "0.8vw",
                    }}
                  ></i>
                  ID : {stateItem?.Id}
                </b>
                <b
                  className={`position-absolute pt-2 text-${
                    isWarningColor ? "warning" : "info"
                  }`}
                  style={{
                    top: "1.5vw",
                    fontSize: "1vw",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className={`fas fa-chevron-circle-right text-${
                      isWarningColor ? "warning" : "info"
                    } mr-1`}
                    style={{
                      fontSize: "0.8vw",
                    }}
                  ></i>
                  Unit Count : {stateItem?.Unitcount}
                </b>
                <b
                  className="position-absolute pt-2"
                  style={{
                    top: "3vw",
                    fontSize: "1vw",
                    fontWeight: "bold",
                  }}
                >
                  <i
                    className={`fas fa-chevron-circle-right text-${
                      isWarningColor ? "warning" : "info"
                    } mr-1`}
                    style={{
                      fontSize: "0.8vw",
                    }}
                  ></i>
                  Items : {stateItem?.Items}
                </b>
                {isWarningColor && (
                  <b
                    className="position-absolute pt-2"
                    style={{
                      top: "4.5vw",
                      fontSize: "0.8vw",
                      fontWeight: "bold",
                    }}
                  >
                    <i
                      className={`fas fa-chevron-circle-right text-warning mr-1`}
                      style={{
                        fontSize: "0.8vw",
                      }}
                    ></i>
                    Consignee : {stateItem?.ConsigneeName}
                  </b>
                )}
                <i
                  className={`fas fa-pallet position-absolute text-${
                    isWarningColor ? "warning" : "info"
                  }`}
                  style={{
                    fontSize: "5vw",
                    opacity: "0.1",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                ></i>
                <b
                  className="position-absolute pt-2"
                  style={{
                    top: "-15px",
                    right: "-7px",
                    fontSize: "1vw",
                    fontWeight: "bold",
                  }}
                >
                  <Checkbox
                    checked={IsChecked}
                    disabled={
                      CheckboxListId && CheckboxListId !== stateItem?.ShipID
                    }
                    onChange={(e) => {
                      setIsChecked(e.target.checked);
                      CheckboxList(e.target.checked, stateItem?.Id);
                    }}
                    onTouchStart={(e) => {
                      CheckboxList(!IsChecked, stateItem?.Id);
                      setIsChecked(!IsChecked);
                    }}
                    inputProps={{
                      "aria-label": "uncontrolled-checkbox",
                    }}
                  />
                </b>
              </div>
            </div>
          </Draggable>
        </div>
      )}
    </>
  );
}

function htmlP(text, text2) {
  return (
    <>
      <p
        type="button"
        className={`btn btn-primary px-1 py-0 mt-3 ml-5 ml-md-1 mr-1`}
        style={{ fontSize: "0.7vw" }}
      >
        {text}
      </p>
      {text2}
    </>
  );
}

async function PostPallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/addpallettoshipment`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ShipmentId: ShipmentID,
      PalletId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}
