import React, { useState } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import "./ScrollingMenuApp.css";
import $ from "jquery";

const MenuItem = ({ text, selected, wareHouse, id }) => {
  return (
    <div
      className={`ScrollingMenu-item POFilter Recipt ${
        selected ? "active" : ""
      }`}
      id={"ReceiptId" + id}
      data-filter={text}
    >
      {wareHouse && (
        <i
          className={`fas fa-warehouse mr-2 ${selected ? "text-white" : ""}`}
        ></i>
      )}
      {text}
    </div>
  );
};

const Menu = (list, selected) =>
  list.map((el) => {
    const { name, wareHouse, id } = el;
    return (
      <MenuItem
        text={name}
        key={name}
        selected={selected}
        wareHouse={wareHouse}
        id={id}
      />
    );
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({
  text: "",
  className: "fa fa-chevron-circle-left fSize",
});
const ArrowRight = Arrow({
  text: "",
  className: "fa fa-chevron-circle-right fSize",
});

export function ScrollingMenuReceipt({ list }) {
  const [selected, setselected] = useState();
  const menu = Menu(list, selected);
  const onSelect = (key) => {
    const a = list.find((x) => x.name === key);
    $("#Receipt" + a?.id).click();
    setselected(key);
  };
  return (
    <div className="bg-light form-control p-1 pl-5 pr-5 ScrollingMenuApp mt-1">
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={selected}
        onSelect={onSelect}
        scrollToSelected={true}
      />
    </div>
  );
}
