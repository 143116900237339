import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { Component, useEffect, useState } from "react";
import { useRef } from "react";
import { Spinner } from "react-bootstrap";
import PrintProvider, { NoPrint, Print } from "react-easy-print";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../../Notification";
import "./label.css";
const adapter = new LocalStorage("db");
const db = low(adapter);
class ManifestLabel extends Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
      Statistiques: [],
      LocationItems: [],
    };
    this.state = { data: obj };
  }
  async componentDidMount() {
    await fetch(window.$apiurl + "/pallets/" + this.props.id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const statusCode = response.status;
      if (statusCode !== 200) {
        Notification("error", "No data found !");
      }
      const json = await response.json();
      this.setState({ data: json?.pallet });
    });
  }
  render() {
    const formData = this.state.data;
    if (this?.state?.data?.ItemsList?.length > 0) {
      return (
        <div className="col-lg-4 mx-auto text-center ml-3">
          {this?.state?.data?.ItemsList?.map((item, index) => (
            <div className="text-center ticket article page-break border-bottom-0">
              <h1
                style={{
                  fontSize: "3rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                {formData?.ConsigneeName}
              </h1>
              <QRCode
                value={`Pallet ID : ${this.props.id}`}
                size={200}
                fgColor="#113059"
                className="mb-7"
              />
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                {this.props.id}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                CTN:{formData.Unitcount}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                PO#:{item?.PurchaseOrder}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                SKU:{item?.Sku}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                Container#:{item?.ContainerNumber}
              </h1>
              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                }}
              >
                {formData?.CustomerName}
              </h1>

              <h1
                style={{
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "#113059",
                  whiteSpace: "pre-wrap",
                }}
              >
                {formData?.ShipperName === undefined ||
                formData?.ShipperName === null ||
                formData?.ShipperName === ""
                  ? formData?.Location
                  : formData?.ShipperName}
              </h1>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="col-lg-4 mx-auto text-center ml-3 article page-break border-bottom-0">
          <div className="text-center ticket">
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#113059",
              }}
            >
              {formData?.ConsigneeName}
            </h1>
            <QRCode
              value={`Pallet ID : ${this.props.id}`}
              size={270}
              fgColor="#113059"
              className="mb-7"
            />
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#113059",
              }}
            >
              {this.props.id}
            </h1>
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#113059",
              }}
            >
              CTN:{formData.Unitcount}
            </h1>
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#113059",
              }}
            >
              {formData?.CustomerName}
            </h1>
            <h1
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
                color: "#113059",
                whiteSpace: "pre-wrap",
              }}
            >
              {formData?.ShipperName === undefined ||
              formData?.ShipperName === null ||
              formData?.ShipperName === ""
                ? formData?.Location
                : formData?.ShipperName}
            </h1>
          </div>
        </div>
      );
    }
  }
}

export function PalletManifestLabel({ id }) {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <PrintProvider>
      <NoPrint>
        <button
          className="btn btn-primary btn-hovrer-primary"
          onClick={handlePrint}
        >
          Print label
        </button>
        <div className="mb-5 pb-10">
          <Print printOnly={false} single={true} name="bar">
            <ManifestLabel id={id} ref={componentRef} />
          </Print>
        </div>
      </NoPrint>
    </PrintProvider>
  );
}
