import React, { useEffect } from "react";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";
import { momentDate, PostMomentDate } from "../../../_helpers/ATHelpers";

function twoDigit(number) {
  var twodigit = number >= 10 ? number : "0" + number.toString();
  return twodigit;
}

export function DateTimeSimple({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  required,
  ...props
}) {
  const { valueSelect, name, setFieldValue } = props;
  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  useEffect(() => {
    if (valueSelect) {
      try {
        let a = new Date(momentDate(valueSelect));
        let b = `${a.getFullYear()}-${twoDigit(a.getMonth() + 1)}-${twoDigit(
          a.getDate()
        )}T${twoDigit(a.getHours())}:${twoDigit(a.getMinutes())}`;
        $(`#${name}DateId`).val(b);
      } catch (err) {
        console.log(err);
      }
    }
  }, [valueSelect]);

  return (
    <>
      {label && (
        <label>
          {label} {required && <RequiredHtml />}
        </label>
      )}
      <TextField
        id={name + "DateId"}
        className="w-100"
        type="datetime-local"
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
        onBlur={(e) => {
          if (setFieldValue)
            setFieldValue(name, PostMomentDate(e.target.value));
        }}
      />
    </>
  );
}
