import React from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export function Notification(type, message) {
  switch (type) {
    case "info": {
      NotificationManager.info(message);
      break;
    }
    case "success": {
      NotificationManager.success(message);
      break;
    }
    case "successQuick": {
      NotificationManager.success(message, "", 1100);
      break;
    }
    case "warning": {
      NotificationManager.warning(message);
      break;
    }
    case "error": {
      NotificationManager.error(message);
      break;
    }
    case "infoClick": {
      NotificationManager.info(
        message,
        "Click to Close",
        900000,
        () => {
          NotificationManager.remove(message);
        },
        true
      );
      break;
    }
    case "errorClick": {
      NotificationManager.error(
        message,
        "Click to Close",
        900000,
        () => {
          NotificationManager.remove(message);
        },
        true
      );
      break;
    }
  }

  return <NotificationContainer />;
}
