import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
const prepareFilter = (queryParams, values) => {
  const { ReLoadTable, typeId, searchText, IsActive = true } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.typeId = typeId !== "0" ? typeId : undefined;
  filter.Name = searchText;
  filter.IsActive = IsActive;
  filter.ReLoadTable = ReLoadTable;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          status: "", // values => All=""/Susspended=0/Active=1/Pending=2
          type: "", // values => All=""/Business=0/Individual=1
          searchText: "",
          IsActive: true,
          ReLoadTable: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <input
                type="button"
                className="d-none"
                id="ReLoadTableId"
                onClick={(e) => {
                  if (e.target.value == "0")
                    document.getElementById("ReLoadTableId").value = 1;
                  else document.getElementById("ReLoadTableId").value = 0;
                  setFieldValue("ReLoadTable", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="col-lg-8">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-lg-4">
                <div className="d-flex align-items-center mt-4">
                  <span className="bg-success align-self-stretch"></span>
                  <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                    <input
                      type="checkbox"
                      name="IsActive"
                      checked={values.IsActive}
                      onChange={(e) => {
                        setFieldValue("IsActive", e.target.checked);
                        handleSubmit();
                      }}
                    />
                    <span></span>
                  </label>
                  <div className="d-flex flex-column flex-grow-1">
                    <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                      IsActive
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
