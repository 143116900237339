import React from 'react'
import { Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export function Rating() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <div class='row'>
                        <div class='col-12 mb-5'>
                            <br />
                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/CustomerProfileRating">Customer Profile Rating</Link>
                            </span>
                            <br />
                            <br />
                            <br />
                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/CarrierProfileRating">Carrier Profile Rating</Link>
                            </span>
                        </div>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}
