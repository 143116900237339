import MockUtils from "../mock.utils";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageUOM(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  //Get all  UOM
  async function GetAllUOM() {
    return await fetch(window.$apiurl + "/unitofmeasure", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //Delete Contact
  async function DeleteUOM(id) {
    return await fetch(`${window.$apiurl}/unitofmeasure/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  //Post Contact
  async function PostUOM(someData) {
    return fetch(window.$apiurl + "/unitofmeasure", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        window.$statusPostContact = statusCode;
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  //Put
  async function PutUOM(someData, id) {
    return fetch(window.$apiurl + "/unitofmeasure/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  mock.onPost("api/ManageUOM").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { Name = "", Description = "" } = customer;

    const id = generateUserId();
    const newUOM = {
      id,
      Name,
      Description,
    };
    var postUOM = await PostUOM(newUOM);
    //Manage Data Result
    if (postUOM.Id != undefined) {
      Notification("success", "Unit of measure has been added successfully");
      postUOM.id = postUOM.Id;
      customerTableMock.push(newUOM);
      return [
        200,
        { customer: newUOM, message: postUOM.Message, success: true },
      ];
    } else {
      if (postUOM.Message) {
        Notification("error", postUOM.Message);
      } else {
        Notification("error", "Error occured");
      }
      return [400, { message: postUOM.Message, success: false }];
    }
  });
  mock.onPost("api/ManageUOM/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllUOM();
    //Filter
    if (
      queryParams.filter.Name !== "" &&
      queryParams.filter.Name !== undefined
    ) {
      const filterSearchText = queryParams.filter.Name.toLowerCase();
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Name.toLowerCase()).includes(String(filterSearchText)) ||
          String(word.Description.toLowerCase()).includes(
            String(filterSearchText)
          ) ||
          String(word.id).includes(String(queryParams.filter.Name))
      );
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.Name;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageUOM/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllUOM();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageUOM/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageUOM/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageUOM\/\d+/).reply((config) => {
    const id = config.url.match(/api\/ManageUOM\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/ManageUOM\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageUOM\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putUom = await PutUOM(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === id);
    if (!index) {
      Notification("error", "Error Occured");
      return [400];
    }
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    Notification("success", "success");
    return [200];
  });

  mock.onDelete(/api\/ManageUOM\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageUOM\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteUOM(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
