import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "../../customers-table/FileViewerStyle.css";
import DetailMangment from "./DetailMangment";
export default function ModelManagmentFirstDashboard({
    container,
    receiptId,
    isReporting,
    isDashboard,
    isLulusDashboardException,
  }) {
    const [Show, setShow] = useState(false);
    return (
      <>
        <Modal
          size="xl"
          show={Show}
          onHide={() => {
            setShow(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <DetailMangment
            Id={receiptId}
            isReporting={isReporting}
            isDashboard={true}
            setShow={setShow.bind()}
            isLulusDashboardException={isLulusDashboardException}
          />
        </Modal>
        <input
          onClick={() => setShow(true)}
          type="button"
          className={`alert alert-light-info px-2 py-1 mt-3 text-primary bg-light`}
          value={container === "N/A" ? `${container} (LTL)` : `${container}`}
        />
      </>
    );
  }
  