import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockEmpowerCMS(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel() {
    return await fetch(`/api/CMSNewUpdates/GetNewUpdates`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`/api/CMSNewUpdates/GetDetailNewUpdate/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1]?.Id;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    const inputElement1 = document.getElementById("MainImageInput");
    const inputElement2 = document.getElementById("ThumbnailImageInput");
    var formData = new FormData();
    if(inputElement1.files[0] !== undefined){
      formData.append(
        "File",
        inputElement1.files[0],
        inputElement1.value.split("\\")[2]
      );    }
    if(inputElement2.files[0] !== undefined){
      formData.append(
        "File2",
        inputElement2.files[0],
        inputElement2.value.split("\\")[2]
      );
    }
    if(someData.Title !== ""){
      window.$falseModalCms = false;
      formData.append("Title", someData.Title);
    }else{
      Notification("error","Title is required");
      window.$falseModalCms = true;
      return false;
    }
    if(someData.SubTitle !== ""){
      formData.append("SubTitle", someData.SubTitle);
    }
    if(someData.TeaserText !== ""){
      formData.append("TeaserText", someData.TeaserText.toString());
    }
    if(someData.Body !== ""){
      formData.append("Body", someData.Body.toString());
    }
    if(someData.CategoryId !== ""){
      window.$falseModalCms = false;
      formData.append("CategoryId", someData.CategoryId);
    }else{
      Notification("error","Category is required");
      window.$falseModalCms = true;
      return false;
    }
    if(someData.Published !== false ){
      formData.append("Published", someData.Published);
    }
    return fetch("/api/CMSNewUpdates/PostNewUpdate", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: formData,
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    const inputElement1 = document.getElementById("MainImageInput");
    const inputElement2 = document.getElementById("ThumbnailImageInput");
    var formData = new FormData();
    if(inputElement1.files[0] !== undefined){
      formData.append(
        "File",
        inputElement1.files[0],
        inputElement1.value.split("\\")[2]
      );    }
    if(inputElement2.files[0] !== undefined){
      formData.append(
        "File2",
        inputElement2.files[0],
        inputElement2.value.split("\\")[2]
      );
    }
    if(someData.Title !== ""){
      window.$falseModalCms = false;
      formData.append("Title", someData.Title);
    }else{
      Notification("error","Title is required");
      window.$falseModalCms = true;
      return false;
    }
    if(someData.SubTitle !== ""){
      formData.append("SubTitle", someData.SubTitle);
    }
    if(someData.TeaserText !== ""){
      formData.append("TeaserText", someData.TeaserText.toString());
    }
    if(someData.Body !== ""){
      formData.append("Body", someData.Body.toString());
    }
    if(someData.CategoryId !== ""){
      window.$falseModalCms = false;
      formData.append("CategoryId", someData.CategoryId);
    }else{
      Notification("error","Category is required");
      window.$falseModalCms = true;
      return false;
    }
    if(someData.Published !== false ){
      formData.append("Published", someData.Published);
    }
    return fetch("/api/CMSNewUpdates/PutNewUpdate/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: formData,
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch("/api/CMSNewUpdates/DeleteNewUpdate/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/EmpowerCMS/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/EmpowerCMS/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/EmpowerCMS").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Title = "",
      SubTitle = "",
      TeaserText = "",
      Body = "",
      Published = false,
      CategoryId = "",
      MainImg = null
    } = customer;

    const newCustomer = {
      Title,
      MainImg,
      SubTitle,
      TeaserText,
      Body,
      Published,
      CategoryId
    };
    await PostModel(newCustomer);
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/EmpowerCMS/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetModel();
    console.log(queryParams.filter)
    delete queryParams.filter.ReloadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/EmpowerCMS/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/EmpowerCMS/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/EmpowerCMS\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/EmpowerCMS\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/EmpowerCMS\/\d+/).reply(async (config) => {
    alert();
    const id = config.url.match(/api\/EmpowerCMS\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    console.log(customer);
    console.log(id);

    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/EmpowerCMS\/\d+/).reply(async (config) => {
    alert()
    const id = config.url.match(/api\/EmpowerCMS\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
