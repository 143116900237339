import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SelectMulti from "react-select";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip, ButtonToolbar } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Button,
  ListItemIcon,
  LinearProgress,
  ListItemText,
  Icon,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { ModelManagmentFirstDashboardSm } from "../NewShipmentsBeta/customers-table/ModelShipmentManagment";
import ModelManagmentFirstReporting from "../ReceiptsManagementNewVersion/customers-table/Shipment/ModelManagmentFirstReporting";
import { emphasize, withStyles, makeStyles } from "@material-ui/core";
import Chart2 from "../PerformanceGraph/ChartZoomBar";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  toAbsoluteUrl,
  sortCaret,
  headerSortingClasses,
  SimpleZoom,
} from "../../../../../_metronic/_helpers";
import Menu2 from "@material-ui/core/Menu";
import Select from "react-select";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import {
  NotesComponentDashboard,
  CheckboxLargeWB,
} from "../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../../app/modules/Notification";
//Date Range Picker Google
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import {
  containsHTML,
  stripHtml,
} from "../../../../../_metronic/_helpers/ATHelpers";
//////
var Spinner = require("react-spinkit");
const adapter = new LocalStorage("db");
const db = low(adapter);

//End Loading animation
//export Button
export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu2
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 13,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
var champFiltring = "";

//End Export Button
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

//Function Get max min date form data
function GetDateFilterIfIsFistTime(isMin, Data) {
  if (Data === null) {
    return "";
  }
  var dateType = "";
  if (Data?.Columns?.length > 0) {
    Data.Columns.map((item, index) => {
      if (item.includes("Date") && dateType === "") {
        dateType = item;
      }
      return null;
    });
  }
  var dateArrayfromData = Data?.Data?.map((item) =>
    item[dateType] !== null &&
    item[dateType] !== "" &&
    item[dateType] !== undefined
      ? new Date(moment(item[dateType]).format("YYYY/MM/DD"))
      : null
  );

  if (dateArrayfromData?.filter((a) => a !== null)?.length === 0) {
    return "";
  }
  var latest = new Date(Math.min.apply(null, dateArrayfromData));
  var earliest = new Date(Math.max.apply(null, dateArrayfromData));
  if (isMin) {
    if (Object.prototype.toString.call(latest) === "[object Date]") {
      // it is a date
      if (isNaN(latest)) {
        // d.getTime() or d.valueOf() will also work
        return "";
      } else {
        return moment(latest).format("YYYY-MM-DD");
      }
    } else {
      return "";
    }
  } else {
    if (Object.prototype.toString.call(earliest) === "[object Date]") {
      // it is a date
      if (isNaN(earliest)) {
        // d.getTime() or d.valueOf() will also work
        return "";
      } else {
        return moment(earliest).format("YYYY-MM-DD");
      }
    } else {
      return "";
    }
  }
}
//filer Function
function filterByValue(array, string, searchBy) {
  if (searchBy !== null && searchBy !== "" && searchBy !== undefined) {
    return array.filter((o) =>
      String(o[searchBy])
        .toLowerCase()
        .includes(string.trim().toLowerCase())
    );
  } else {
    return array.filter((o) =>
      Object.keys(o).some((k) =>
        String(o[k])
          .toLowerCase()
          .includes(string.trim().toLowerCase())
      )
    );
  }
}
//filer Function
function filterByValueExact(array, string, searchBy) {
  if (searchBy !== null && searchBy !== "" && searchBy !== undefined) {
    return array.filter(
      (o) => String(o[searchBy]).toLowerCase() === string.trim().toLowerCase()
    );
  } else {
    return array.filter((o) =>
      Object.keys(o).some(
        (k) => String(o[k]).toLowerCase() === string.trim().toLowerCase()
      )
    );
  }
}
//End Filter Function

//HTML render Function
function checkDateFromat(item, x) {
  if (
    moment(
      item !== null ? String(item).slice(0, 10) : "",
      "YYYY-MM-DD",
      true
    ).isValid()
  ) {
    if (x === "AppointedDate") {
      if (String(item).slice(11, 19) === "00:00:00") {
        return moment(item).format("dddd, MMM DD, YYYY");
      } else {
        return moment(item).format("dddd, MMM DD, YYYY hh:mm:ss A");
      }
    } else {
      return moment(item).format("dddd, MMM DD, YYYY");
    }
  } else return item;
}
export function GetTransloadDays(data) {
  if (data >= 0) {
    return data;
  }
}
function GetIsHoteIcone() {
  return (
    <OverlayTrigger overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}>
      <a className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/fire.svg")} />
        </span>
      </a>
    </OverlayTrigger>
  );
}
function DownLoadFile(url, fileName) {
  var xmlhttp = new XMLHttpRequest();

  xmlhttp.onreadystatechange = function() {
    if (xmlhttp.readyState == XMLHttpRequest.DONE) {
      // XMLHttpRequest.DONE == 4
      if (xmlhttp.status == 200) {
        FileSaver(url, fileName);
      } else {
        Notification("error", "file not found");
      }
    }
  };

  xmlhttp.open("GET", url, true);
  xmlhttp.send();
}
export function GetPhotoFileIcone(params) {
  if (params === null || params === "") return "";
  if (
    window.$apiurl
      .trim()
      .toLocaleLowerCase()
      .includes("dev".trim().toLocaleLowerCase())
  ) {
    return (
      <>
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Download</Tooltip>}
        >
          <a
            className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
            onClick={() => {
              var url = `https://dev-orders.empowerdatalogistics.com${params}`;
              const fileName = url.replace(/^.*[\\\/]/, "");
              DownLoadFile(url, fileName);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-success">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Files/DownloadedFile.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      </>
    );
  } else if (
    window.$apiurl
      .trim()
      .toLocaleLowerCase()
      .includes("staging".trim().toLocaleLowerCase())
  ) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Download</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
          onClick={() => {
            var url = `https://staging-orders.empowerdatalogistics.com${params}`;
            const fileName = url.replace(/^.*[\\\/]/, "");
            DownLoadFile(url, fileName);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Files/DownloadedFile.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
    );
  } else {
    return (
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Download</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
          onClick={() => {
            var url = `https://orders.empowerdatalogistics.com${params}`;
            const fileName = url.replace(/^.*[\\\/]/, "");
            DownLoadFile(url, fileName);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Files/DownloadedFile.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
    );
  }
}
export function GetSlaIcone(text) {
  if (text === null || text === "") return "";
  if (
    text.trim().toLocaleLowerCase() === "success".trim().toLocaleLowerCase()
  ) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip
            style={{ pointerEvents: "none" }}
            id="products-delete-tooltip"
          >
            {text}
          </Tooltip>
        }
      >
        <a className="btn btn-icon btn-light btn-hover-success btn-sm ml-1">
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/success-green-check-mark.svg"
              )}
            />
          </span>
        </a>
      </OverlayTrigger>
    );
  } else if (
    text.trim().toLocaleLowerCase() === "Failure".trim().toLocaleLowerCase()
  ) {
    return (
      <OverlayTrigger
        overlay={
          <Tooltip
            style={{ pointerEvents: "none" }}
            id="products-delete-tooltip"
          >
            {text}
          </Tooltip>
        }
      >
        <a className="btn btn-icon btn-light btn-hover-danger btn-sm ml-1">
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/red-x-line.svg"
              )}
            />
          </span>
        </a>
      </OverlayTrigger>
    );
  }
}
export function GetIsNoteIcone(Text) {
  if (Text === null || Text === "") return "";

  return (
    <OverlayTrigger
      overlay={
        <Tooltip style={{ pointerEvents: "none" }} id="products-delete-tooltip">
          {Text}
        </Tooltip>
      }
    >
      <a className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1">
        <span className="svg-icon svg-icon-md svg-icon-warning">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
        </span>
      </a>
    </OverlayTrigger>
  );
}
function returncsv(values) {
  var json = values;
  var fields = Object.keys(json[0]);
  var replacer = function(key, value) {
    return value === null ? "" : value;
  };
  var csv = json.map(function(row) {
    return fields
      .map(function(fieldName) {
        return JSON.stringify(row[fieldName], replacer);
      })
      .join(",");
  });
  csv.unshift(fields.join(",")); // add header column
  csv = csv.join("\r\n");
  return csv;
}
//End HTML render Function
var reportTypeSelected = undefined;
export function ReportingPortViewPage() {
  const rowClasses = (row) => (row.RowColor !== null ? row.RowColor : "");
  /////////start Get Params From link///////////
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("type");
  /////////End Get Params From link///////////
  db.read();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [LoadingPageShow, setLoadingPageShow] = React.useState(false);
  const [DateFilterOptions, setDateFilterOptions] = useState([]);
  const [FealdsFilterOptions, setFealdsFilterOptions] = useState([]);
  const [DateFilter, setDateFilter] = useState(null);
  const [SearchByFilter, setSearchByFilter] = useState(null);
  const [SearchByService, setSearchByService] = useState(null);
  const [SearchByWareHouse, setSearchByWareHouse] = useState(null);
  const [SearchByMode, setSearchByMode] = useState(null);
  const [SearchByWeek, setSearchByWeek] = useState(null);
  const [Customers, setCustomers] = useState([]);
  const [ReportingData, setReportingData] = useState(null);
  const [ReportingDataFilter, setReportingDataFilter] = useState([]);
  const [ReportingType, setReportingType] = useState([]);
  const [ReportingTypeFiltred, setReportingTypeFiltred] = useState([]);
  const [ReportingWarehouseOption, setReportingWarehouseOption] = useState([]);
  const [ReportingWeekOption, setReportingWeekOption] = useState([]);
  const [ReportingCustomerOption, setReportingCustomerOption] = useState([]);
  const [ReportingServiceOption, setReportingServiceOption] = useState([]);
  const [ReportingModeOption, setReportingModeOption] = useState([]);
  const [menuIsOpen, setmenuIsOpen] = useState(false);
  const [ReportingTypeId, setReportingTypeId] = useState({
    value: myParam,
    label: myParam,
  });
  const [FilterReportData, setFilterReportData] = useState({
    Customers: [],
    StartDate:
      ReportingTypeId.value !== "PortView" &&
      ReportingTypeId.value !== null &&
      ReportingTypeId.value !== "DCArrivals"
        ? db.read().getState()[reportTypeSelected] !== undefined
          ? db.read().getState()[reportTypeSelected]?.endDate !== null &&
            db.read().getState()[reportTypeSelected]?.endDate !== ""
            ? moment(db.read().getState()[reportTypeSelected].startDate).format(
                "YYYY-MM-DD"
              )
            : ""
          : GetDateFilterIfIsFistTime(true, ReportingData)
        : "",
    EndDate:
      ReportingTypeId.value !== "PortView" &&
      ReportingTypeId.value !== null &&
      ReportingTypeId.value !== "DCArrivals"
        ? db.read().getState()[reportTypeSelected] !== undefined
          ? db.read().getState()[reportTypeSelected]?.endDate !== null &&
            db.read().getState()[reportTypeSelected]?.endDate !== ""
            ? moment(db.read().getState()[reportTypeSelected].endDate).format(
                "YYYY-MM-DD"
              )
            : ""
          : GetDateFilterIfIsFistTime(false, ReportingData)
        : "",
    SearchText: "",
    DateFilterType: "",
    SearchBy: "",
    Service: "",
    WareHouse: "",
    Mode: "",
    Week: "",
  });

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/generic/GetOptionFromSettings/ReportingSearchFields`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        champFiltring = data.Value;
      })
      .catch((error) => console.log("error", error));
  }, []);
  useEffect(() => {
    if (db.getState().User.IsCustomer) {
      let values = { ...FilterReportData };
      var customer = [];
      db.getState().User.Customers.map((item) => {
        customer.push({ value: item.Id, label: item.Name });
      });
      values.Customers = customer;
      setFilterReportData(values);
    }
  }, []);

  const history = useHistory();
  //export Button ////////
  const classes = useStyles();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    document.activeElement.blur();
    setAnchorEl(null);
  }
  function onInputChange(options, { action }) {
    if (action === "menu-close") {
      setmenuIsOpen(false);
      document.activeElement.blur();
    }
  }

  function openMenu() {
    setmenuIsOpen(true);
  }
  function closeMenu() {
    setmenuIsOpen(false);
  }
  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }
  function dateconvertFroeExport(datetime) {
    if (datetime === null || datetime === "" || datetime === undefined) {
      return "";
    }
    if (isValidDate(datetime)) {
      return datetime;
    }
    var formatedDate = moment(datetime).format("DD-MM-YYYY");
    var timezone = new Date().getTimezoneOffset();
    if (timezone < 0) {
      timezone = timezone * -1;
    }
    var DateObject = new Date(formatedDate);
    var GetTime = new Date(DateObject.getTime());
    return new Date(GetTime + timezone * 60000);
  }
  async function fetchMyAPI2(type, entityName) {
    var dataformated = JSON.parse(JSON.stringify(ReportingDataFilter));
    dataformated.forEach(function(v) {
      delete v.key;
      delete v.Key;
      delete v.Id;
    });
    dataformated.map((a) => {
      if (a.TransloadDays !== undefined) {
        if (a.TransloadDays < 0) {
          a.TransloadDays = "";
        }
      }
    });
    dataformated.map((o) => {
      Object.keys(o).map((k) => {
        if (containsHTML(o[k])) {
          o[k] = stripHtml(o[k]);
        }
        if (
          moment(
            o[k] !== null ? String(o[k]).slice(0, 10) : "",
            "YYYY-MM-DD",
            true
          ).isValid()
        ) {
          var timezone = new Date().getTimezoneOffset();
          if (timezone < 0) {
            timezone = timezone * -1;
          }
          var Gettime = new Date(o[k].split("T")[0]).getTime();
          var data = new Date(Gettime + timezone * 60000);
          o[k] = data;
        }
      });
    });
    var date = new Date(Date.now());
    var dateformat =
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "" +
      date.getDate() +
      "" +
      date.getFullYear() +
      "" +
      date.getHours() +
      "" +
      date.getMinutes() +
      "" +
      date.getSeconds();
    if (type === "excel") {
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";
      const ws = XLSX.utils.json_to_sheet(dataformated, {
        cellDates: true,
        dateNF: "dddd, MMMM DD, YYYY",
      });
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, entityName + "_" + dateformat + fileExtension);
    } else if (type === "csv") {
      const data2 = returncsv(dataformated);
      const fileType = "text/csv;charset=utf-8";
      const fileExtension = ".csv";
      const data = new Blob([data2], { type: fileType });
      FileSaver.saveAs(data, entityName + "_" + dateformat + fileExtension);
    } else if (type === "json") {
      const fileType = "application/json";
      const fileExtension = ".json";
      const data = new Blob([JSON.stringify(dataformated)], { type: fileType });
      FileSaver.saveAs(data, entityName + "_" + dateformat + fileExtension);
    }
    // Export(dataformated, type, entityName);
  }
  //End Export Button

  useEffect(() => {
    setReportingTypeId({ value: myParam, label: myParam });
    reportTypeSelected = myParam;
    let values = { ...FilterReportData };
    values.SearchBy = "";
    values.SearchText = "";
    if (db.getState().User.IsCustomer) {
      var customer = [];
      db.getState().User.Customers.map((item) => {
        customer.push({ value: item.Id, label: item.Name });
      });
      values.Customers = customer;
    }
    setFilterReportData(values);
    setSearchByFilter(null);
    setSearchByService(null);
    setSearchByMode(null);
    setSearchByWareHouse(null);
    setSearchByWeek(null);
    GlobalDashboardGetData();
  }, [myParam]);

  useEffect(() => {
    var data = ReportingData?.Data || [];
    var startDate = moment(FilterReportData.StartDate).format("MM/DD/YYYY");
    var endDate = moment(FilterReportData.EndDate).format("MM/DD/YYYY");
    if (
      FilterReportData.SearchText !== "" &&
      FilterReportData.SearchText !== null &&
      !String(ReportingTypeId?.value).includes("Graph") &&
      FilterReportData.SearchText !== undefined
    ) {
      data = filterByValue(
        data,
        FilterReportData.SearchText,
        FilterReportData.SearchBy
      );
    }
    if (
      FilterReportData.WareHouse !== "" &&
      FilterReportData.WareHouse !== null &&
      !String(ReportingTypeId?.value).includes("Graph") &&
      FilterReportData.WareHouse !== undefined
    ) {
      data = filterByValueExact(data, FilterReportData.WareHouse, "Warehouse");
    }
    if (
      FilterReportData.Service !== "" &&
      FilterReportData.Service !== null &&
      !String(ReportingTypeId?.value).includes("Graph") &&
      FilterReportData.Service !== undefined
    ) {
      data = filterByValueExact(data, FilterReportData.Service, "Service");
    }
    if (
      FilterReportData.Mode !== "" &&
      FilterReportData.Mode !== null &&
      FilterReportData.Mode !== undefined
    ) {
      data = filterByValueExact(data, FilterReportData.Mode, "Mode");
    }
    if (
      FilterReportData.Week !== "" &&
      FilterReportData.Week !== null &&
      FilterReportData.Week !== undefined
    ) {
      data = filterByValueExact(data, FilterReportData.Week, "Week");
    }
    if (
      FilterReportData.Customers.length > 0 &&
      !String(ReportingTypeId?.value).includes("Graph") &&
      db.getState().User.IsCustomer === false
    ) {
      var filtered = [];
      for (var arr in data) {
        for (var filter in FilterReportData.Customers) {
          if (data[arr].Customer === FilterReportData.Customers[filter].label) {
            filtered.push(data[arr]);
          }
        }
      }
      data = filtered;
    }
    if (
      ReportingTypeId?.value === "PortView" ||
      ReportingTypeId?.value === "DCArrivals"
    ) {
      if (
        FilterReportData.StartDate !== null &&
        FilterReportData.StartDate !== "" &&
        FilterReportData.EndDate !== null &&
        FilterReportData.EndDate !== ""
      ) {
        data = data.filter((d) => {
          var time = moment(d?.OrderDate).format("MM/DD/YYYY");
          return (
            new Date(startDate) <= new Date(time) &&
            new Date(time) <= new Date(endDate)
          );
        });
      }
    } else if (String(ReportingTypeId?.value).includes("Graph")) {
      if (
        FilterReportData.StartDate !== "" &&
        FilterReportData.EndDate !== "" &&
        FilterReportData.DateFilterType !== null &&
        FilterReportData.DateFilterType !== undefined &&
        FilterReportData.DateFilterType !== ""
      ) {
        data = data.filter((d) => {
          var dateObjectfilter = "";
          if (ReportingData?.Data.length > 0) {
            dateObjectfilter = Object.keys(ReportingData?.Data[0])[0];
          }
          var time = moment(d[dateObjectfilter]).format("MM/DD/YYYY");
          return (
            new Date(startDate) <= new Date(time) &&
            new Date(time) <= new Date(endDate)
          );
        });
      }
    } else {
      if (
        FilterReportData.StartDate !== "" &&
        FilterReportData.EndDate !== "" &&
        FilterReportData.DateFilterType !== null &&
        FilterReportData.DateFilterType !== undefined &&
        FilterReportData.DateFilterType !== ""
      ) {
        data = data.filter((d) => {
          var time = moment(d[FilterReportData.DateFilterType]).format(
            "MM/DD/YYYY"
          );
          return (
            new Date(startDate) <= new Date(time) &&
            new Date(time) <= new Date(endDate)
          );
        });
      }
    }

    setReportingDataFilter(data);
  }, [FilterReportData]);

  /////////GetData Function ///////////
  function GlobalDashboardGetData() {
    setReportingData(null);
    setReportingDataFilter([]);
    setLoadingPageShow(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/customers`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((result) => {
        var obj = result[1];
        setCustomers(obj || []);
      })
      .catch((error) => console.log("error", error));
    fetch(`${window.$apiurl}/reporting/report?reportName=`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          history.push("/error/401");
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((result) => {
        var obj = result[1];
        var datashow = [];
        datashow = obj.Reporting.Data;
        setReportingType(datashow);
        setReportingTypeFiltred(obj.Reporting.Data);
      })
      .catch((error) => console.log("error", error));
    if (myParam !== null) {
      var link = "";
      if (db.getState().User.IsCustomer) {
        var customer = db
          .getState()
          .User.Customers.map((item) => item.Name + ",")[0];
        customer = customer.substring(0, customer.length - 1);
        link = `${window.$apiurl}/reporting/report?reportName=${myParam}&customer=${customer}`;
      } else {
        link = `${window.$apiurl}/reporting/report?reportName=${myParam}`;
      }
      fetch(`${link}`, requestOptions)
        .then(async (response) => {
          const statusCode = await response.status;
          if (statusCode === 401) {
            history.push("/error/401");
          }
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((result) => {
          try {
            var obj = { ...result[1] };
            setLoadingPageShow(false);
            if (obj.Reporting.Name === "PortView") {
              obj.Reporting.Columns.push("Actions");
            }
            var dateType = [];
            obj.Reporting.Columns.map((item, index) => {
              if (item.includes("Date")) {
                dateType.push({ value: item, label: item });
              }
            });
            setDateFilterOptions(dateType || []);

            var notDateType = [];
            obj.Reporting.Columns.map((item, index) => {
              const arraychampFiltring = champFiltring.split(",");
              arraychampFiltring.map((itm) => {
                if (item.toLowerCase() === itm.toLowerCase()) {
                  notDateType.push({ value: item, label: item });
                }
              });
            });
            setFealdsFilterOptions(notDateType);

            setReportingData(obj?.Reporting || []);
            setReportingDataFilter(obj?.Reporting.Data || []);
          } catch (error) {}
        })
        .catch((error) => console.log("error", error));
    } else {
      setReportingData(null);
      setReportingDataFilter([]);
      setLoadingPageShow(false);
    }
  }

  function ActionsReportingView({ row, isReporting }) {
    const [idHot, setIdHot] = useState();
    const [postPoList, postPoHotList] = useState([]);
    const [getPoListHotItems, getPoListHot] = useState([]);
    const [getPoListItems, getPoList] = useState([]);
    async function PostPOHotList() {
      return fetch(window.$apiurl + "/receiptsManagement/MarkUnMarkAsHot", {
        method: "post",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postPoList),
      })
        .then(async (response) => {
          const statusCode = response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          return res[1];
        })
        .catch((error) => {
          console.error(error);
        });
    }
    async function GetPoListAsync(id) {
      return await fetch(
        `${window.$apiurl}/receiptsManagement/getPoNumbersByContainer?container=` +
          id,
        {
          method: "get",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
        }
      )
        .then(async (response) => {
          const statusCode = response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          var a = res[1];
          const obj = [];
          const objTrue = [];
          {
            a.map((item) => {
              obj.push({ value: item.PoNumber, label: item.PoNumber });
              if (item.IsHot) {
                objTrue.push({ value: item.PoNumber, label: item.PoNumber });
              }
            });
          }
          const objIdsPO = [];
          const objPostGet = {
            Container: id,
            PoIds: [],
            IsHot: true,
          };
          {
            a.map((item) => {
              objIdsPO.push(item.PoNumber);
            });
          }
          objPostGet.PoIds.push(objIdsPO);
          postPoHotList(objPostGet);
          getPoList(obj);
          getPoListHot(objTrue);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    const [ModalHot, setModalHot] = useState(false);
    const [ModalNote, setModalNotes] = useState(false);
    const [NoteCategories, setNoteCategories] = useState([]);
    return (
      <>
        {/* PO HOT */}
        <Modal
          size="lg"
          show={ModalHot}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title>
              Mark / Unmark as Hot PO Container : {row?.Container}
            </Modal.Title>
            <IconButton
              aria-label="Close"
              onClick={(e) => {
                setModalHot(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-7">
                <label>Container PO's :</label>
                <SelectMulti
                  id="PoList"
                  isMulti
                  value={getPoListHotItems}
                  name="CustomerId"
                  options={getPoListItems}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    const objIdsPO = [];
                    const obj = {
                      Ids: [],
                      isHot: "true",
                      isHaz: "false",
                    };
                    if (e !== null) {
                      {
                        e.map((item, index) => {
                          objIdsPO.push(item.value);
                        });
                      }
                      obj.Ids.push({
                        Id: row?.Id,
                        POIds: objIdsPO,
                      });
                      postPoHotList(obj);
                      getPoListHot(e);
                    } else {
                      obj.Ids.push({
                        Id: row?.Id,
                        POIds: [],
                      });
                      postPoHotList(obj);
                      getPoListHot([]);
                    }
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light btn-elevate"
              onClick={(e) => {
                setModalHot(false);
              }}
            >
              Close
            </button>
            <button
              className="btn btn-primary btn-elevate"
              onClick={async (e) => {
                await PostPOHotList();
                Notification(
                  "success",
                  `PO's Receipt ID ${row?.Id} has been marked / unmarked successfully`
                );
                setModalHot(false);
                GlobalDashboardGetData();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
        <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">New Note</Tooltip>}
        >
          <a
            title="New Note"
            className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1"
            onClick={() => {
              setIdHot(row?.Container);
              setModalNotes(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-warning">
              <i class="far fa-sticky-note"></i>
            </span>
          </a>
        </OverlayTrigger>
        {/* PO NOTE */}
        <Modal
          size="lg"
          show={ModalNote}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Notes Container ID : {row?.Container}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NotesComponentDashboard
              id={row?.Container}
              get={"/transload/receipts/getNotesByContainer/" + row?.Container}
              post={"/transload/receipts/addNoteByContainer"}
              put={"/transload/receipts/addNoteByContainer"}
              Categories={NoteCategories}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light btn-elevate"
              onClick={(e) => {
                setModalNotes(false);
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <OverlayTrigger
          overlay={<Tooltip id="products-delete-tooltip">Mark as Hot</Tooltip>}
        >
          <a
            title="New Note"
            className="btn btn-icon btn-light btn-hover-danger btn-sm ml-1"
            onClick={(e) => {
              setModalHot(true);
              GetPoListAsync(row?.Container);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i class="fab fa-hotjar"></i>
            </span>
          </a>
        </OverlayTrigger>
      </>
    );
  }
  ///////// End GetData Function ///////////

  const [columns, setcolumns] = useState(false);
  useEffect(() => {
    const storage = JSON.parse(localStorage.getItem("ColumnVisibility_RPPV"));
    setcolumns(
      ReportingData?.Columns?.map((x) => {
        let hidden = false;

        if (Array.isArray(storage))
          hidden =
            storage.find((y) => y?.dataField + y?.text === x + x)?.hidden ??
            false;

        return {
          text: x,
          dataField: x,
          sort: true,
          hidden: x === "RowColor" || x === "Key" ? true : hidden,
          sortCaret: sortCaret,
          headerSortingClasses,
          formatter: (cell, row) => {
            if (x === "Notes" || x === "CustomerNote") {
              return GetIsNoteIcone(row[x]);
            } else if (x === "Hot") {
              if (row[x] === true) return GetIsHoteIcone();
            } else if (x === "SLA") {
              return GetSlaIcone(row[x]);
            } else if (x === "PhotoFile") {
              return GetPhotoFileIcone(row[x]);
            } else if (
              String(x)
                .trim()
                .toLocaleLowerCase()
                .includes(
                  String("Days")
                    .trim()
                    .toLocaleLowerCase()
                )
            ) {
              return GetTransloadDays(row[x]);
            } else if (x === "Container") {
              if (row.ContainerId !== undefined) {
                return (
                  <ModelManagmentFirstReporting
                    Id={row.ContainerId}
                    Container={row.Container}
                    isReporting={true}
                  />
                );
              } else if (row["Receive#"] !== undefined) {
                return (
                  <ModelManagmentFirstReporting
                    Id={row["Receive#"]}
                    Container={row.Container}
                    isReporting={true}
                  />
                );
              } else if (row.ReceiptId !== undefined) {
                return (
                  <ModelManagmentFirstReporting
                    Id={row.ReceiptId}
                    Container={row.Container}
                    isReporting={true}
                  />
                );
              }
            } else if (x === "PO") {
              if (row.ContainerId !== undefined) {
                return (
                  <ModelManagmentFirstReporting
                    Id={row.ContainerId}
                    Container={row.PO}
                    isReporting={true}
                  />
                );
              } else if (row.ReceiptId !== undefined) {
                return (
                  <ModelManagmentFirstReporting
                    Id={row.ReceiptId}
                    Container={row.PO}
                    isReporting={true}
                  />
                );
              }
            } else if (x === "Trailer") {
              return (
                <ModelManagmentFirstDashboardSm
                  trailer={row.Trailer}
                  id={row.Shipment}
                  isDashboard={true}
                />
              );
            } else if (x === "Actions") {
              return <ActionsReportingView row={row} />;
            } else {
              return checkDateFromat(row[x], x);
            }
          },
        };
      })
    );
  }, [ReportingData?.Columns]);

  return (
    <>
      {/* {LoadingPageShow && (
        <Portal node={document && document.getElementById("layout-portal")}>
          <div className={cssClasses.join(" ")}>
            
          </div>
        </Portal>
      )} */}
      <Helmet>
        <title>eMPower | Global </title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <div className="Dashboard-Transportation">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-custom card-stretch gutter-b">
              <div className="card-body d-flex flex-column">
                <div className="row">
                  <h3>
                    {myParam === null ? "Various Report" : myParam + " Report"}
                  </h3>
                  {ReportingTypeId?.value !== null &&
                  ReportingTypeId?.value !== undefined &&
                  ReportingTypeId?.value !== "" ? (
                    <button
                      disabled={LoadingPageShow}
                      style={{
                        position: "absolute",
                        right: "163px",
                        height: "32px",
                        paddingTop: "5px",
                        marginTop: "8px",
                        width: "104px",
                      }}
                      className="btn btn-primary btn-elevate"
                      onClick={async (e) => {
                        setLoadingPageShow(true);
                        var myHeaders = new Headers();
                        myHeaders.append(
                          "Authorization",
                          `bearer ${db.getState().Token}`
                        );
                        myHeaders.append("Content-Type", "application/json");
                        myHeaders.append("X-FP-API-KEY", "iphone");
                        var requestOptions = {
                          method: "GET",
                          withCredentials: true,
                          headers: myHeaders,
                          redirect: "follow",
                        };
                        var link = "";
                        if (db.getState().User.IsCustomer) {
                          var customer = db
                            .getState()
                            .User.Customers.map((item) => item.Name + ",")[0];
                          customer = customer.substring(0, customer.length - 1);

                          link = `${window.$apiurl}/reporting/report?reportName=${ReportingTypeId?.value}&customer=${customer}`;
                        } else {
                          link = `${window.$apiurl}/reporting/report?reportName=${ReportingTypeId?.value}`;
                        }
                        fetch(`${link}`, requestOptions)
                          .then(async (response) => {
                            const statusCode = await response.status;
                            if (statusCode === 401) {
                              history.push("/error/401");
                            }
                            const data = await response.json();
                            return Promise.all([statusCode, data]);
                          })
                          .then((result) => {
                            var obj = { ...result[1] };
                            setLoadingPageShow(false);
                            setReportingData(null);
                            setReportingDataFilter([]);
                            if (ReportingTypeId.label === "PortView") {
                              obj.Reporting.Columns.push("Actions");
                            }
                            var dateType = [];
                            obj.Reporting.Columns.map((item, index) => {
                              if (item.includes("Date")) {
                                dateType.push({
                                  value: item,
                                  label: item,
                                });
                              }
                            });
                            setDateFilterOptions(dateType || []);
                            //wareHouse
                            var datewareHouse = [];
                            obj.Reporting.Data.map((item, index) => {
                              if (
                                item.Warehouse !== null &&
                                item.Warehouse !== undefined
                              ) {
                                if (
                                  !datewareHouse.some(
                                    (i) => i.value === item.Warehouse
                                  )
                                ) {
                                  datewareHouse.push({
                                    value: item.Warehouse,
                                    label: item.Warehouse,
                                  });
                                }
                              }
                            });
                            setReportingWarehouseOption(
                              datewareHouse.filter(onlyUnique)
                            );
                            //endWareHouse
                            //Week
                            var dateWeek = [];
                            obj.Reporting.Data.map((item, index) => {
                              if (
                                item.Week !== null &&
                                item.Week !== undefined
                              ) {
                                if (
                                  !dateWeek.some((i) =>
                                    String(i.value === String(item.Week))
                                  )
                                ) {
                                  dateWeek.push({
                                    value: String(item.Week),
                                    label: String(item.Week),
                                  });
                                }
                              }
                            });
                            setReportingWeekOption(dateWeek.filter(onlyUnique));
                            //endWeek
                            //Customers
                            var dateCustomer = [];
                            var dateCustomerSelected = [];
                            obj.Reporting.Data.map((item, index) => {
                              if (
                                item.Customer !== null &&
                                item.Customer !== undefined
                              ) {
                                if (
                                  !dateCustomer.some(
                                    (i) => i.value === item.Customer
                                  )
                                ) {
                                  dateCustomer.push({
                                    value: item.Customer,
                                    label: item.Customer,
                                  });
                                }
                              }
                            });
                            setReportingCustomerOption(
                              dateCustomer.filter(onlyUnique)
                            );

                            if (
                              dateCustomer.filter(onlyUnique).length === 1 &&
                              db.getState().User.IsCustomer === false
                            ) {
                              dateCustomerSelected = dateCustomer.filter(
                                onlyUnique
                              );
                            }
                            //EndCustomerd
                            //Service
                            var dateService = [];
                            obj.Reporting.Data.map((item, index) => {
                              if (
                                item.Service !== null &&
                                item.Service !== undefined
                              ) {
                                if (
                                  !dateService.some(
                                    (i) => i.value === item.Service
                                  )
                                ) {
                                  dateService.push({
                                    value: item.Service,
                                    label: item.Service,
                                  });
                                }
                              }
                            });
                            setReportingServiceOption(
                              dateService.filter(onlyUnique)
                            );
                            //endService
                            //Mode
                            var dateMode = [];
                            obj.Reporting.Data.map((item, index) => {
                              if (
                                item.Mode !== null &&
                                item.Mode !== undefined
                              ) {
                                if (
                                  !dateMode.some((i) => i.value === item.Mode)
                                ) {
                                  dateMode.push({
                                    value: item.Mode,
                                    label: item.Mode,
                                  });
                                }
                              }
                            });
                            setReportingModeOption(dateMode.filter(onlyUnique));
                            //endMode
                            var notDateType = [];
                            obj.Reporting.Columns.map((item, index) => {
                              const arraychampFiltring = champFiltring.split(
                                ","
                              );
                              arraychampFiltring.map((itm) => {
                                if (item.toLowerCase() === itm.toLowerCase()) {
                                  notDateType.push({
                                    value: item,
                                    label: item,
                                  });
                                }
                              });
                            });
                            setFealdsFilterOptions(notDateType);
                            setReportingData(obj.Reporting || []);
                            setReportingDataFilter(obj.Reporting.Data || []);
                            if (db.getState().User.IsCustomer) {
                              var customer = [];
                              db.getState().User.Customers.map((item) => {
                                customer.push({
                                  value: item.Id,
                                  label: item.Name,
                                });
                              });
                              setFilterReportData({
                                Customers: customer,
                                StartDate:
                                  ReportingTypeId?.value !== "PortView" &&
                                  ReportingTypeId?.value !== "DCArrivals"
                                    ? db.read().getState()[
                                        reportTypeSelected
                                      ] !== undefined
                                      ? db.read().getState()[reportTypeSelected]
                                          ?.endDate !== null &&
                                        db.read().getState()[reportTypeSelected]
                                          ?.endDate !== ""
                                        ? moment(
                                            db.read().getState()[
                                              reportTypeSelected
                                            ].startDate
                                          ).format("YYYY-MM-DD")
                                        : ""
                                      : GetDateFilterIfIsFistTime(
                                          true,
                                          obj.Reporting
                                        )
                                    : "",
                                EndDate:
                                  ReportingTypeId?.value !== "PortView" &&
                                  ReportingTypeId?.value !== "DCArrivals"
                                    ? db.read().getState()[
                                        reportTypeSelected
                                      ] !== undefined
                                      ? db.read().getState()[reportTypeSelected]
                                          ?.endDate !== null &&
                                        db.read().getState()[reportTypeSelected]
                                          ?.endDate !== ""
                                        ? moment(
                                            db.read().getState()[
                                              reportTypeSelected
                                            ].endDate
                                          ).format("YYYY-MM-DD")
                                        : ""
                                      : GetDateFilterIfIsFistTime(
                                          false,
                                          obj.Reporting
                                        )
                                    : "",
                                SearchText: "",
                                SearchBy: "",
                                Service: "",
                                Mode: "",
                                WareHouse: "",
                                Week: "",

                                DateFilterType: dateType[0]?.value,
                              });
                              setDateFilter(dateType[0]);
                            } else {
                              setFilterReportData({
                                Customers: dateCustomerSelected,
                                StartDate:
                                  ReportingTypeId?.value !== "PortView" &&
                                  ReportingTypeId?.value !== "DCArrivals"
                                    ? db.read().getState()[
                                        reportTypeSelected
                                      ] !== undefined
                                      ? db.read().getState()[reportTypeSelected]
                                          ?.endDate !== null &&
                                        db.read().getState()[reportTypeSelected]
                                          ?.endDate !== ""
                                        ? moment(
                                            db.read().getState()[
                                              reportTypeSelected
                                            ].startDate
                                          ).format("YYYY-MM-DD")
                                        : ""
                                      : GetDateFilterIfIsFistTime(
                                          true,
                                          obj.Reporting
                                        )
                                    : "",
                                EndDate:
                                  ReportingTypeId?.value !== "PortView" &&
                                  ReportingTypeId?.value !== "DCArrivals"
                                    ? db.read().getState()[
                                        reportTypeSelected
                                      ] !== undefined
                                      ? db.read().getState()[reportTypeSelected]
                                          ?.endDate !== null &&
                                        db.read().getState()[reportTypeSelected]
                                          ?.endDate !== ""
                                        ? moment(
                                            db.read().getState()[
                                              reportTypeSelected
                                            ].endDate
                                          ).format("YYYY-MM-DD")
                                        : ""
                                      : GetDateFilterIfIsFistTime(
                                          false,
                                          obj.Reporting
                                        )
                                    : "",
                                SearchText: "",
                                Service: "",
                                Mode: "",
                                WareHouse: "",
                                Week: "",

                                DateFilterType: dateType[0]?.value,
                              });
                              setDateFilter(dateType[0]);
                            }
                            setSearchByFilter(null);
                            setSearchByService(null);
                            setSearchByMode(null);
                            setSearchByWareHouse(null);
                            setSearchByWeek(null);
                          })
                          .catch((error) => console.log("error", error));
                      }}
                    >
                      {LoadingPageShow === true ? (
                        <div className="row" style={{ margin: "0" }}>
                          Refresh <div class="spinner spinner-sm ml-2"></div>
                        </div>
                      ) : (
                        <>
                          REFRESH<i class="flaticon2-reload icon-nm ml-2"></i>
                        </>
                      )}
                    </button>
                  ) : (
                    ""
                  )}

                  <ButtonToolbar
                    className="btn-text-primary"
                  >
                    <Button
                      style={{ right: "2%", position: "absolute" }}
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="outlined"
                      color="primary"
                      onClick={handleClick}
                    >
                      Export as
                      <Icon className={classes.rightIcon}>save</Icon>
                    </Button>
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <StyledMenuItem
                        onClick={(event) => {
                          var name = `Report_${
                            ReportingTypeId.value
                          }_Customer_${FilterReportData.Customers.map(
                            (item) => item.label
                          )}_StartDate_${FilterReportData.StartDate}_EndDate_${
                            FilterReportData.EndDate
                          }`;
                          fetchMyAPI2(
                            "excel",
                            name.length > 160 ? name.slice(0, 160) : name
                          );
                        }}
                      >
                        <ListItemIcon>
                          <i className="far fa-file-excel"></i>
                        </ListItemIcon>
                        <ListItemText primary="Excel" />
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={(event) => {
                          var name = `Report_${
                            ReportingTypeId.value
                          }_Customer_${FilterReportData.Customers.map(
                            (item) => item.label
                          )}_StartDate_${FilterReportData.StartDate}_EndDate_${
                            FilterReportData.EndDate
                          }`;
                          fetchMyAPI2(
                            "csv",
                            name.length > 160 ? name.slice(0, 160) : name
                          );
                        }}
                      >
                        <ListItemIcon>
                          <i className="fas fa-file-csv"></i>
                        </ListItemIcon>
                        <ListItemText primary="CSV" />
                      </StyledMenuItem>
                      <StyledMenuItem
                        onClick={(event) => {
                          var name = `Report_${
                            ReportingTypeId.value
                          }_Customer_${FilterReportData.Customers.map(
                            (item) => item.label
                          )}_StartDate_${FilterReportData.StartDate}_EndDate_${
                            FilterReportData.EndDate
                          }`;
                          fetchMyAPI2(
                            "json",
                            name.length > 160 ? name.slice(0, 160) : name
                          );
                        }}
                      >
                        <ListItemIcon>
                          <i className="far fa-file-code"></i>
                        </ListItemIcon>
                        <ListItemText primary="Json" />
                      </StyledMenuItem>
                    </StyledMenu>
                  </ButtonToolbar>
                </div>
                <div className="row">
                  {db.getState().User.IsCustomer ? (
                    <>
                      <div className="col-lg-2 mt-5">
                        <p>Customers</p>
                        <SelectMulti
                          id="CustomerId"
                          isMulti
                          isDisabled={db.getState().User.IsCustomer}
                          value={FilterReportData.Customers}
                          name="CustomerId"
                          options={Customers.map((suggestion) => ({
                            value: suggestion.Id,
                            label: suggestion.CompanyName,
                          }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            var listCustomer = [];
                            let values = { ...FilterReportData };
                            if (e?.length === 0) {
                              values.Customers = [];
                            } else if (e !== null) {
                              e.map((item, index) => {
                                listCustomer.push(item.label);
                              });
                              values.Customers = e;
                            } else {
                              values.Customers = [];
                            }
                            setFilterReportData(values);
                          }}
                        />
                      </div>

                      <div className="col-lg-2 mt-5">
                        <p>Report Type</p>
                        <Select
                          name="ReportType"
                          isSearchable={true}
                          isDisabled={LoadingPageShow}
                          value={
                            ReportingTypeId?.value !== null &&
                            ReportingTypeId?.value !== undefined &&
                            ReportingTypeId?.value !== ""
                              ? ReportingTypeId
                              : myParam !== null
                              ? { value: myParam, label: myParam }
                              : ""
                          }
                          onFocus={openMenu}
                          onInputChange={onInputChange}
                          onBlur={closeMenu}
                          menuIsOpen={menuIsOpen}
                          options={ReportingType?.map((x) => {
                            return {
                              value: x.Name,
                              label: x?.Name,
                            };
                          })}
                          onChange={(newValue) => {
                            if (
                              newValue?.value !== null &&
                              newValue?.value !== "" &&
                              newValue?.value !== undefined
                            ) {
                              reportTypeSelected = newValue?.value;
                              setReportingTypeId({
                                value: newValue?.value,
                                label: newValue?.value,
                              });
                              setLoadingPageShow(true);
                              var myHeaders = new Headers();
                              myHeaders.append(
                                "Authorization",
                                `bearer ${db.getState().Token}`
                              );
                              myHeaders.append(
                                "Content-Type",
                                "application/json"
                              );
                              myHeaders.append("X-FP-API-KEY", "iphone");
                              var requestOptions = {
                                method: "GET",
                                withCredentials: true,
                                headers: myHeaders,
                                redirect: "follow",
                              };
                              var link = "";
                              if (db.getState().User.IsCustomer) {
                                var customer = db
                                  .getState()
                                  .User.Customers.map(
                                    (item) => item.Name + ","
                                  )[0];
                                customer = customer.substring(
                                  0,
                                  customer.length - 1
                                );

                                link = `${window.$apiurl}/reporting/report?reportName=${newValue?.value}&customer=${customer}`;
                              } else {
                                link = `${window.$apiurl}/reporting/report?reportName=${newValue?.value}`;
                              }
                              fetch(`${link}`, requestOptions)
                                .then(async (response) => {
                                  const statusCode = await response.status;
                                  if (statusCode === 401) {
                                    history.push("/error/401");
                                  }
                                  const data = await response.json();
                                  return Promise.all([statusCode, data]);
                                })
                                .then((result) => {
                                  var obj = { ...result[1] };
                                  setLoadingPageShow(false);
                                  setReportingData(null);
                                  setReportingDataFilter([]);
                                  if (newValue.label === "PortView") {
                                    obj.Reporting.Columns.push("Actions");
                                  }
                                  var dateType = [];
                                  obj.Reporting.Columns.map((item, index) => {
                                    if (item.includes("Date")) {
                                      dateType.push({
                                        value: item,
                                        label: item,
                                      });
                                    }
                                  });
                                  setDateFilterOptions(dateType || []);
                                  //wareHouse
                                  var datewareHouse = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Warehouse !== null &&
                                      item.Warehouse !== undefined
                                    ) {
                                      if (
                                        !datewareHouse.some(
                                          (i) => i.value === item.Warehouse
                                        )
                                      ) {
                                        datewareHouse.push({
                                          value: item.Warehouse,
                                          label: item.Warehouse,
                                        });
                                      }
                                    }
                                  });
                                  setReportingWarehouseOption(
                                    datewareHouse.filter(onlyUnique)
                                  );
                                  //endWareHouse
                                  //Week
                                  var dateWeek = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Week !== null &&
                                      item.Week !== undefined
                                    ) {
                                      if (
                                        !dateWeek.some(
                                          (i) =>
                                            String(i.value) ===
                                            String(item.Week)
                                        )
                                      ) {
                                        dateWeek.push({
                                          value: String(item.Week),
                                          label: String(item.Week),
                                        });
                                      }
                                    }
                                  });
                                  setReportingWeekOption(
                                    dateWeek.filter(onlyUnique)
                                  );
                                  //endWeek
                                  //Customers
                                  var dateCustomer = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Customer !== null &&
                                      item.Customer !== undefined
                                    ) {
                                      if (
                                        !dateCustomer.some(
                                          (i) => i.value === item.Customer
                                        )
                                      ) {
                                        dateCustomer.push({
                                          value: item.Customer,
                                          label: item.Customer,
                                        });
                                      }
                                    }
                                  });
                                  setReportingCustomerOption(
                                    dateCustomer.filter(onlyUnique)
                                  );
                                  //EndCustomerd
                                  //Service
                                  var dateService = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Service !== null &&
                                      item.Service !== undefined
                                    ) {
                                      if (
                                        !dateService.some(
                                          (i) => i.value === item.Service
                                        )
                                      ) {
                                        dateService.push({
                                          value: item.Service,
                                          label: item.Service,
                                        });
                                      }
                                    }
                                  });
                                  setReportingServiceOption(
                                    dateService.filter(onlyUnique)
                                  );
                                  //endService
                                  //Mode
                                  var dateMode = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Mode !== null &&
                                      item.Mode !== undefined
                                    ) {
                                      if (
                                        !dateMode.some(
                                          (i) => i.value === item.Mode
                                        )
                                      ) {
                                        dateMode.push({
                                          value: item.Mode,
                                          label: item.Mode,
                                        });
                                      }
                                    }
                                  });
                                  setReportingModeOption(
                                    dateMode.filter(onlyUnique)
                                  );
                                  //endService
                                  var notDateType = [];
                                  obj.Reporting.Columns.map((item, index) => {
                                    const arraychampFiltring = champFiltring.split(
                                      ","
                                    );
                                    arraychampFiltring.map((itm) => {
                                      if (
                                        item.toLowerCase() === itm.toLowerCase()
                                      ) {
                                        notDateType.push({
                                          value: item,
                                          label: item,
                                        });
                                      }
                                    });
                                  });
                                  setFealdsFilterOptions(notDateType);
                                  setReportingData(obj.Reporting || []);
                                  setReportingDataFilter(
                                    obj.Reporting.Data || []
                                  );
                                  if (db.getState().User.IsCustomer) {
                                    var customer = [];
                                    db.getState().User.Customers.map((item) => {
                                      customer.push({
                                        value: item.Id,
                                        label: item.Name,
                                      });
                                    });
                                    setFilterReportData({
                                      Customers: customer,
                                      StartDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].startDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                true,
                                                obj.Reporting
                                              )
                                          : // .add(14, "days")
                                            // .format("YYYY-MM-DD")
                                            "",
                                      EndDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].endDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                false,
                                                obj.Reporting
                                              )
                                          : "",
                                      SearchText: "",
                                      SearchBy: "",
                                      Service: "",
                                      Mode: "",
                                      WareHouse: "",
                                      Week: "",

                                      DateFilterType: dateType[0]?.value,
                                    });
                                    setDateFilter(dateType[0]);
                                  } else {
                                    setFilterReportData({
                                      Customers: [],
                                      StartDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].startDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                true,
                                                obj.Reporting
                                              )
                                          : "",
                                      EndDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].endDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                false,
                                                obj.Reporting
                                              )
                                          : "",
                                      SearchText: "",
                                      SearchBy: "",
                                      Service: "",
                                      Mode: "",
                                      WareHouse: "",
                                      Week: "",

                                      DateFilterType: dateType[0]?.value,
                                    });
                                    setDateFilter(dateType[0]);
                                  }
                                })
                                .catch((error) => console.log("error", error));
                            } else {
                              setReportingTypeId(null);
                              reportTypeSelected = undefined;
                            }
                            setSearchByFilter(null);
                            setSearchByService(null);
                            setSearchByMode(null);
                            setSearchByWareHouse(null);
                            setSearchByWeek(null);
                          }}
                        />
                        {ReportingType?.length === 0 && <LinearProgress />}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="col-lg-2 mt-5">
                        <p>Report Type</p>
                        <Select
                          name="ReportType"
                          isSearchable={true}
                          isDisabled={LoadingPageShow}
                          value={
                            ReportingTypeId?.value !== null &&
                            ReportingTypeId?.value !== undefined &&
                            ReportingTypeId?.value !== ""
                              ? ReportingTypeId
                              : myParam !== null
                              ? { value: myParam, label: myParam }
                              : ""
                          }
                          onFocus={openMenu}
                          onInputChange={onInputChange}
                          onBlur={closeMenu}
                          menuIsOpen={menuIsOpen}
                          options={ReportingType?.map((x) => {
                            return {
                              value: x.Name,
                              label: x?.Name,
                            };
                          })}
                          onChange={(newValue) => {
                            var typeSelected = newValue?.value;
                            if (
                              newValue?.value !== null &&
                              newValue?.value !== "" &&
                              newValue?.value !== undefined
                            ) {
                              reportTypeSelected = newValue?.value;
                              setReportingTypeId({
                                value: newValue?.value,
                                label: newValue?.value,
                              });
                              setLoadingPageShow(true);
                              var myHeaders = new Headers();
                              myHeaders.append(
                                "Authorization",
                                `bearer ${db.getState().Token}`
                              );
                              myHeaders.append(
                                "Content-Type",
                                "application/json"
                              );
                              myHeaders.append("X-FP-API-KEY", "iphone");
                              var requestOptions = {
                                method: "GET",
                                withCredentials: true,
                                headers: myHeaders,
                                redirect: "follow",
                              };
                              var link = "";
                              if (db.getState().User.IsCustomer) {
                                var customer = db
                                  .getState()
                                  .User.Customers.map(
                                    (item) => item.Name + ","
                                  )[0];
                                customer = customer.substring(
                                  0,
                                  customer.length - 1
                                );

                                link = `${window.$apiurl}/reporting/report?reportName=${newValue?.value}&customer=${customer}`;
                              } else {
                                link = `${window.$apiurl}/reporting/report?reportName=${newValue?.value}`;
                              }
                              fetch(`${link}`, requestOptions)
                                .then(async (response) => {
                                  const statusCode = await response.status;
                                  if (statusCode === 401) {
                                    history.push("/error/401");
                                  }
                                  const data = await response.json();
                                  return Promise.all([statusCode, data]);
                                })
                                .then((result) => {
                                  var obj = { ...result[1] };
                                  setLoadingPageShow(false);
                                  setReportingData(null);
                                  setReportingDataFilter([]);
                                  if (newValue.label === "PortView") {
                                    obj.Reporting.Columns.push("Actions");
                                  }
                                  var dateType = [];
                                  obj.Reporting.Columns.map((item, index) => {
                                    if (item.includes("Date")) {
                                      dateType.push({
                                        value: item,
                                        label: item,
                                      });
                                    }
                                  });
                                  setDateFilterOptions(dateType || []);
                                  //wareHouse
                                  var datewareHouse = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Warehouse !== null &&
                                      item.Warehouse !== undefined
                                    ) {
                                      if (
                                        !datewareHouse.some(
                                          (i) => i.value === item.Warehouse
                                        )
                                      ) {
                                        datewareHouse.push({
                                          value: item.Warehouse,
                                          label: item.Warehouse,
                                        });
                                      }
                                    }
                                  });
                                  setReportingWarehouseOption(
                                    datewareHouse.filter(onlyUnique)
                                  );
                                  //endWareHouse
                                  //Week
                                  var dateWeek = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Week !== null &&
                                      item.Week !== undefined
                                    ) {
                                      if (
                                        !dateWeek.some(
                                          (i) =>
                                            String(i.value) ===
                                            String(item.Week)
                                        )
                                      ) {
                                        dateWeek.push({
                                          value: String(item.Week),
                                          label: String(item.Week),
                                        });
                                      }
                                    }
                                  });
                                  setReportingWeekOption(
                                    dateWeek.filter(onlyUnique)
                                  );
                                  //endWeek
                                  //Customers
                                  var dateCustomer = [];
                                  var dateCustomerSelected = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Customer !== null &&
                                      item.Customer !== undefined
                                    ) {
                                      if (
                                        !dateCustomer.some(
                                          (i) => i.value === item.Customer
                                        )
                                      ) {
                                        dateCustomer.push({
                                          value: item.Customer,
                                          label: item.Customer,
                                        });
                                      }
                                    }
                                  });
                                  setReportingCustomerOption(
                                    dateCustomer.filter(onlyUnique)
                                  );

                                  if (
                                    dateCustomer.filter(onlyUnique).length ===
                                      1 &&
                                    db.getState().User.IsCustomer === false
                                  ) {
                                    dateCustomerSelected = dateCustomer.filter(
                                      onlyUnique
                                    );
                                  }
                                  //EndCustomerd
                                  //Service
                                  var dateService = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Service !== null &&
                                      item.Service !== undefined
                                    ) {
                                      if (
                                        !dateService.some(
                                          (i) => i.value === item.Service
                                        )
                                      ) {
                                        dateService.push({
                                          value: item.Service,
                                          label: item.Service,
                                        });
                                      }
                                    }
                                  });
                                  setReportingServiceOption(
                                    dateService.filter(onlyUnique)
                                  );
                                  //endService
                                  //Mode
                                  var dateMode = [];
                                  obj.Reporting.Data.map((item, index) => {
                                    if (
                                      item.Mode !== null &&
                                      item.Mode !== undefined
                                    ) {
                                      if (
                                        !dateMode.some(
                                          (i) => i.value === item.Mode
                                        )
                                      ) {
                                        dateMode.push({
                                          value: item.Mode,
                                          label: item.Mode,
                                        });
                                      }
                                    }
                                  });
                                  setReportingModeOption(
                                    dateMode.filter(onlyUnique)
                                  );
                                  //endMode
                                  var notDateType = [];
                                  obj.Reporting.Columns.map((item, index) => {
                                    const arraychampFiltring = champFiltring.split(
                                      ","
                                    );
                                    arraychampFiltring.map((itm) => {
                                      if (
                                        item.toLowerCase() === itm.toLowerCase()
                                      ) {
                                        notDateType.push({
                                          value: item,
                                          label: item,
                                        });
                                      }
                                    });
                                  });
                                  setFealdsFilterOptions(notDateType);
                                  setReportingData(obj.Reporting || []);
                                  setReportingDataFilter(
                                    obj.Reporting.Data || []
                                  );
                                  if (db.getState().User.IsCustomer) {
                                    var customer = [];
                                    db.getState().User.Customers.map((item) => {
                                      customer.push({
                                        value: item.Id,
                                        label: item.Name,
                                      });
                                    });
                                    setFilterReportData({
                                      Customers: customer,
                                      StartDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].startDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                true,
                                                obj.Reporting
                                              )
                                          : "",
                                      EndDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].endDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : moment().format("YYYY-MM-DD")
                                          : "",
                                      SearchText: "",
                                      SearchBy: "",
                                      Service: "",
                                      Mode: "",
                                      WareHouse: "",
                                      Week: "",

                                      DateFilterType: dateType[0]?.value,
                                    });
                                    setDateFilter(dateType[0]);
                                  } else {
                                    setFilterReportData({
                                      Customers: dateCustomerSelected,
                                      StartDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].startDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                true,
                                                obj.Reporting
                                              )
                                          : "",
                                      EndDate:
                                        newValue?.value !== "PortView" &&
                                        newValue?.value !== "DCArrivals"
                                          ? db.read().getState()[
                                              reportTypeSelected
                                            ] !== undefined
                                            ? db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== null &&
                                              db.read().getState()[
                                                reportTypeSelected
                                              ]?.endDate !== ""
                                              ? moment(
                                                  db.read().getState()[
                                                    reportTypeSelected
                                                  ].endDate
                                                ).format("YYYY-MM-DD")
                                              : ""
                                            : GetDateFilterIfIsFistTime(
                                                false,
                                                obj.Reporting
                                              )
                                          : "",
                                      SearchText: "",
                                      Service: "",
                                      Mode: "",
                                      WareHouse: "",
                                      Week: "",

                                      DateFilterType: dateType[0]?.value,
                                    });
                                    setDateFilter(dateType[0]);
                                  }
                                  setSearchByFilter(null);
                                  setSearchByService(null);
                                  setSearchByMode(null);
                                  setSearchByWareHouse(null);
                                  setSearchByWeek(null);
                                })
                                .catch((error) => console.log("error", error));
                            } else {
                              setReportingTypeId(null);
                              reportTypeSelected = undefined;
                            }
                          }}
                        />
                        {ReportingType?.length === 0 && <LinearProgress />}
                      </div>
                      {ReportingCustomerOption.length > 0 ? (
                        <div className="col-lg-2 mt-5">
                          <p>Customers</p>
                          <SelectMulti
                            id="CustomerId"
                            isMulti
                            isDisabled={
                              db.getState().User.IsCustomer ||
                              ReportingCustomerOption.length === 1
                            }
                            value={FilterReportData.Customers}
                            name="CustomerId"
                            options={ReportingCustomerOption}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(e) => {
                              var listCustomer = [];
                              let values = { ...FilterReportData };
                              if (e?.length === 0) {
                                values.Customers = [];
                              } else if (e !== null) {
                                e.map((item, index) => {
                                  listCustomer.push(item.label);
                                });
                                values.Customers = e;
                              } else {
                                values.Customers = [];
                              }
                              setFilterReportData(values);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {ReportingTypeId.value !== null &&
                  ReportingTypeId.value !== "DCArrivals" &&
                  DateFilterOptions.length > 0 &&
                  !String(ReportingTypeId?.value).includes("Graph") &&
                  ReportingTypeId.value !== "PortView" ? (
                    <div className="col-lg-2 mt-5">
                      <p>Date Filter</p>
                      <Select
                        name="DateRangeType"
                        id="DateRangeType"
                        label="Date Filter"
                        inputId="react-select-single"
                        placeholder="Date Filter"
                        options={DateFilterOptions}
                        value={DateFilter}
                        onChange={(e) => {
                          setDateFilter(e);
                          let values = { ...FilterReportData };
                          values.DateFilterType = e?.value;
                          setFilterReportData(values);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {DateFilterOptions.length > 0 ? (
                    <div className="col-lg-3 mt-5">
                      <span className="mb-2 marginInlineStartLabel">
                        Date Start - End
                      </span>
                      <RangeDatePicker
                        startDate={FilterReportData.StartDate}
                        endDate={FilterReportData.EndDate}
                        onChange={(startDate, endDate) => {
                          let values = { ...FilterReportData };
                          values.StartDate = dateconvertFroeExport(startDate);
                          const userDefaultDates = {
                            startDate: dateconvertFroeExport(startDate),
                            endDate: dateconvertFroeExport(endDate),
                          };
                          db.set(
                            `${ReportingTypeId.value}`,
                            userDefaultDates
                          ).write();
                          values.EndDate = dateconvertFroeExport(endDate);
                          setFilterReportData(values);
                        }}
                        dateFormat="MM/DD/YYYY"
                        minDate={new Date(1900, 0, 1)}
                        maxDate={new Date(2100, 0, 1)}
                        startDatePlaceholder="Start Date"
                        endDatePlaceholder="End Date"
                        disabled={false}
                        className="my-own-class-name"
                        startWeekDay="monday"
                      />
                    </div>
                  ) : (
                    ""
                  )}

                  {!String(ReportingTypeId?.value).includes("Graph") ? (
                    <>
                      <div
                        className={`${
                          ReportingTypeId.value !== null &&
                          ReportingTypeId.value !== "DCArrivals" &&
                          ReportingTypeId.value !== "PortView"
                            ? ReportingCustomerOption.length > 0 &&
                              db.getState().User.IsCustomer === false
                              ? "col-lg-1"
                              : "col-lg-2"
                            : "col-lg-2"
                        } mt-5`}
                      >
                        <p>Search By</p>
                        <Select
                          isClearable
                          name="SearchBy"
                          id="SearchBy"
                          label="Search By"
                          inputId="react-select-single"
                          placeholder="Search By"
                          options={FealdsFilterOptions}
                          value={SearchByFilter}
                          onChange={(e) => {
                            setSearchByFilter(e);
                            let values = { ...FilterReportData };
                            values.SearchBy = e?.value;
                            setFilterReportData(values);
                          }}
                        />
                      </div>
                      <div
                        className={`${
                          ReportingTypeId.value !== null &&
                          ReportingTypeId.value !== "DCArrivals" &&
                          ReportingTypeId.value !== "PortView"
                            ? "col-lg-2"
                            : "col-lg-3"
                        } mt-5`}
                      >
                        <p>Search</p>
                        <input
                          type="text"
                          className="form-control"
                          name="searchText"
                          value={FilterReportData.SearchText}
                          id="searchGlobalText"
                          onChange={(e) => {
                            let values = { ...FilterReportData };
                            values.SearchText = e.target.value;
                            setFilterReportData(values);
                          }}
                          placeholder="Search in All Fields"
                          label="Date"
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {ReportingWarehouseOption.length > 0 ? (
                    <div className="col-md-4">
                      <p>WareHouse</p>
                      <Select
                        isClearable
                        name="WareHouse"
                        id="WareHouse"
                        label="WareHouse"
                        inputId="react-select-single"
                        placeholder="WareHouse"
                        options={ReportingWarehouseOption}
                        value={SearchByWareHouse}
                        onChange={(e) => {
                          setSearchByWareHouse(e);
                          let values = { ...FilterReportData };
                          values.WareHouse = e?.value;
                          setFilterReportData(values);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {ReportingServiceOption.length > 0 ? (
                    <div className="col-md-4">
                      <p>Service</p>
                      <Select
                        isClearable
                        name="Service"
                        id="Service"
                        label="Service"
                        inputId="react-select-single"
                        placeholder="Service"
                        options={ReportingServiceOption}
                        value={SearchByService}
                        onChange={(e) => {
                          setSearchByService(e);
                          let values = { ...FilterReportData };
                          values.Service = e?.value;
                          setFilterReportData(values);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {ReportingModeOption.length > 0 ? (
                    <div className="col-md-4">
                      <p>Mode</p>
                      <Select
                        isClearable
                        name="Mode"
                        id="Mode"
                        label="Mode"
                        inputId="react-select-single"
                        placeholder="Mode"
                        options={ReportingModeOption}
                        value={SearchByMode}
                        onChange={(e) => {
                          setSearchByMode(e);
                          let values = { ...FilterReportData };
                          values.Mode = e?.value;
                          setFilterReportData(values);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {ReportingWeekOption.length > 0 ? (
                    <div className="col-md-4">
                      <p>Week</p>
                      <Select
                        isClearable
                        name="Week"
                        id="Week"
                        label="Week"
                        inputId="react-select-single"
                        placeholder="Week"
                        options={ReportingWeekOption}
                        value={SearchByWeek}
                        onChange={(e) => {
                          setSearchByWeek(e);
                          let values = { ...FilterReportData };
                          values.Week = e?.value;
                          setFilterReportData(values);
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="form-group row">
                  
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-10" id="Open">
          <div className="col-lg-12 col-xxl-12">
            <div className="card card-custom px-4">
              <div className="card-header border-0 py-5">
                {LoadingPageShow === true ? (
                  <div
                    className="col-lg-12 col-xxl-12 row mt-2"
                    style={{ left: "44%" }}
                  >
                    <Spinner name="folding-cube" color="blue" />
                  </div>
                ) : ReportingData === null ? (
                  <div className="col-lg-12 col-xxl-12">
                    <h4 className="mt-3" style={{ textAlign: "center" }}>
                      Please select Report
                    </h4>
                  </div>
                ) : (
                  <>
                    <h3 className="card-title align-items-start flex-column  mb-5">
                      <span className="card-label font-weight-bolder text-dark mb-5">
                        {ReportingData.Name}
                      </span>
                      {!String(ReportingTypeId?.value).includes("Graph") ? (
                        <SimpleZoom
                          titleButton={
                            <>
                              Column visibility{" "}
                              <i class="fas fa-columns text-white ml-5" />
                            </>
                          }
                          body={
                            <div className="row">
                              {(columns || []).map((x) => (
                                <div className="col-md-3">
                                  <CheckboxLargeWB
                                    className="font-size-md"
                                    text={x?.text}
                                    checked={!x?.hidden}
                                    onChange={() => {
                                      let a = columns;
                                      a.forEach((y) => {
                                        if (
                                          y?.dataField + y?.text ===
                                          x?.dataField + x?.text
                                        ) {
                                          y.hidden = !y.hidden;
                                        }
                                      });
                                      setcolumns([]);
                                      setTimeout(() => {
                                        setcolumns(a);
                                        localStorage.setItem(
                                          "ColumnVisibility_RPPV",
                                          JSON.stringify(a)
                                        );
                                      }, 1);
                                    }}
                                  />
                                </div>
                              ))}
                              {columns?.length === 0 && (
                                <Spinner
                                  id="InboundLoading"
                                  animation="grow"
                                  role="status"
                                  variant="primary"
                                >
                                  <span className="sr-only">Loading...</span>
                                </Spinner>
                              )}
                            </div>
                          }
                        />
                      ) : (
                        ""
                      )}
                    </h3>
                    {String(ReportingTypeId?.value).includes("Graph") ? (
                      <div className="col-lg-12 col-xxl-12">
                        <Chart2
                          props={ReportingDataFilter}
                          isReporting={true}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-12 col-xxl-12">
                        {columns &&
                          columns?.length > 0 &&
                          ReportingData?.Columns &&
                          ReportingData !== null &&
                          ReportingData?.Columns?.length > 0 && (
                            <>
                              {ReportingData.Name === "ALL Po’s Report" ? (
                                <BootstrapTable
                                  keyField={"Key"}
                                  noDataIndication={
                                    <h4 style={{ textAlign: "center" }}>
                                      There is no data available at the moment.
                                    </h4>
                                  }
                                  data={[...ReportingDataFilter].map(
                                    (item, index) => {
                                      var data = item;
                                      data.key = index;
                                      return data;
                                    }
                                  )}
                                  columns={columns}
                                  rowClasses={rowClasses}
                                  defaultSorted={[
                                    { dataField: "Aging", order: "desc" },
                                  ]}
                                  pagination={paginationFactory({
                                    showTotal: true,
                                    sizePerPage:
                                      ReportingDataFilter.length <= 100
                                        ? ReportingDataFilter.length
                                        : 10,
                                    sizePerPageList: [
                                      { text: "5", value: 5 },
                                      { text: "10", value: 10 },
                                      { text: "25", value: 25 },
                                      {
                                        text: "All",
                                        value: ReportingDataFilter.length,
                                      },
                                    ],
                                  })}
                                  wrapperClasses="table-responsive reporting"
                                  classes="table table-head-custom table-vertical-center"
                                  bootstrap4
                                  bordered={false}
                                />
                              ) : (
                                <BootstrapTable
                                  keyField={"Key"}
                                  noDataIndication={
                                    <h4 style={{ textAlign: "center" }}>
                                      There is no data available at the moment.
                                    </h4>
                                  }
                                  data={ReportingDataFilter.map(
                                    (item, index) => {
                                      var data = item;
                                      data.key = index;
                                      return data;
                                    }
                                  )}
                                  columns={columns}
                                  rowClasses={rowClasses}
                                  pagination={paginationFactory({
                                    showTotal: true,
                                    sizePerPage:
                                      ReportingDataFilter.length <= 100
                                        ? ReportingDataFilter.length
                                        : 10,
                                    sizePerPageList: [
                                      { text: "5", value: 5 },
                                      { text: "10", value: 10 },
                                      { text: "25", value: 25 },
                                      {
                                        text: "All",
                                        value: ReportingDataFilter.length,
                                      },
                                    ],
                                  })}
                                  wrapperClasses="table-responsive reporting"
                                  classes="table table-head-custom table-vertical-center"
                                  bootstrap4
                                  bordered={false}
                                />
                              )}
                            </>
                          )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
