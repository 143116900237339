import React from 'react'
import { Form, Card, Col, Dropdown } from "react-bootstrap";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 200px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));
export function ReceiptImportPreferencesPage() {
    const classes = useStyles();
    return (
        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <div title="Manage Roles">
                                <div className="mb-4">
                                    <button
                                        type="button"
                                        className="btn btn-success btn-shadow-hover mr-2"
                                    >
                                        Save and Exit
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-shadow-hover mr-2"
                                    >
                                        Exit
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <Form.Label>Customer</Form.Label>
                                <Dropdown className="form-control p-0">
                                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                        Choose a Customer
                            </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Epic Drops, LLC</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Loud Bags Inc</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Ornative Inc</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">PANACHE INTERNATIONAL LLC (c5937)</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">PhatMojo, LLC</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Pro-Spin Sports</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Reliable of Milwaukee</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">X Chair LLC</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <br></br>
                    <Form.Row>
                        <div class="col bg-light-secondary px-4 py-4 rounded-xl ">
                            <div className="form-group">
                                <label class="text-primary font-weight-bold font-size-h5 mt-2">IMPORT PREFERENCES</label>
                                <hr></hr>
                                <div className="col-lg-12">
                                    <span>On Error</span>
                                    <div className="col-lg-6"><Form.Check custom type='radio' id='custom-radio-contact1' name='Addr3Source' value="0" label='Cancel the import process' /></div>
                                    <div className="col-lg-6"><Form.Check custom type='radio' id='custom-radio-contact2' name='Addr3Source' value="1" label='Continue importing and skip ASNs that have errors' /></div>
                                </div>
                            </div>
                        </div>
                    </Form.Row>

                    <br></br>
                    <Form.Row>
                        <div class="col bg-light-secondary px-4 py-4 rounded-xl ">
                            <div className="form-group">
                                <label class="text-primary font-weight-bold font-size-h5 mt-2">ORDER IMPORT FORMATS (DEFAULT)</label>
                                <hr></hr>
                                <div className="col-lg-12 mt-2">
                                    <div className="col-lg-6"><Form.Check custom type='checkbox' id='custom-checkbox-contact1' name='Addr3Source' value="1" label='WMS Tab-delimited Rcvr' /></div>
                                    <div className="col-lg-6"><Form.Check custom type='checkbox' id='custom-checkbox-contact2' name='Addr3Source' value="2" label='EDI (943)' /></div>
                                    <div className="col-lg-6"><Form.Check custom type='checkbox' id='custom-checkbox-contact3' name='Addr3Source' value="3" label='XML (943)' /></div>
                                    <div className="col-lg-6"><Form.Check custom type='checkbox' id='custom-checkbox-contact4' name='Addr3Source' value="4" label='WMS Header Based Rcvr (txt-tab)' /></div>
                                </div>
                            </div>
                        </div>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
