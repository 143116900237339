import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

export default class SlideLanding extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      fade: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div>
        <Slider {...settings}>
          <div className="text-center px-10 px-lg-10 tns-item tns-slide-active">
            <img
              src="/media/bg/welcome.jpg"
              class="card-rounded shadow w-100"
              alt=""
            />
          </div>
          <div className="text-center px-10 px-lg-10 tns-item tns-slide-active">
            <img
              src="/media/bg/lulus.png"
              class="card-rounded shadow w-100"
              alt=""
            />
          </div>
          <div className="text-center px-10 px-lg-10 tns-item tns-slide-active">
            <img
              src="/media/bg/tms.png"
              class="card-rounded shadow w-100"
              alt=""
            />
          </div>
        </Slider>
      </div>
    );
  }
}
