import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/eMPower/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/eMPower/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/eMPower/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/eMPower/_redux/specifications/specificationsSlice";
import { ReceivingsSlice } from "../app/modules/eMPower/_redux/Transload/TransloadReceiving/remarksSlice";
import { ContactInformation } from "../app/modules/eMPower/_redux/ContactInformation/remarksSlice";
import { CarrierAttachment } from "../app/modules/eMPower/_redux/Attachments/remarksSlice";
import { InsuranceInformation } from "../app/modules/eMPower/_redux/InsuranceInformation/remarksSlice";
import { ReceiptsManagmentSlice } from "../app/modules/eMPower/_redux/ReceiptsManagementNewVersion/customersSlice";
import { PalletsManagmentSlice } from "../app/modules/eMPower/_redux/PalletsManagment/customersSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  Receiving: ReceivingsSlice.reducer,
  ContactInformation: ContactInformation.reducer,
  CarrierAttachment: CarrierAttachment.reducer,
  InsuranceInformation: InsuranceInformation.reducer,
  ReceiptsManagment: ReceiptsManagmentSlice.reducer,
  PalletsManagment: PalletsManagmentSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
