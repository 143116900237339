import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import { Notification } from "../../../modules/Notification";
import { ScrollingMenuReceipt } from "./ScrollingMenuReceipt";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({
  iShipmentsID = [],
  IsClosedFunction,
  IsClosed,
  OrdersIds,
}) {
  const [Table, setTable] = useState([
    {
      ShipID: 812014,
      PurchaseOrder: "4500093380",
      Sku: "44067",
      Description: "SCE PSW WILDLIFE",
      UnitCount: 50,
      Weight: 282.67,
      Cube: 2.13,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCEMER004",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 50,
      OnHand: 0,
      Shipment: "817410,817413",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093381",
      Sku: "44067",
      Description: "SCE PSW WILDLIFE",
      UnitCount: 64,
      Weight: 361.82,
      Cube: 2.72,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCELEX003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 64,
      OnHand: 0,
      Shipment: "817426,817424",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812019,
      PurchaseOrder: "4500093382",
      Sku: "44067",
      Description: "SCE PSW WILDLIFE",
      UnitCount: 65,
      Weight: 367.47,
      Cube: 2.77,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy KY",
      LocationCode: "SCECOP003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 65,
      OnHand: 0,
      Shipment: "817433",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812019,
      PurchaseOrder: "4500093383",
      Sku: "44067",
      Description: "SCE PSW WILDLIFE",
      UnitCount: 70,
      Weight: 395.74,
      Cube: 2.98,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy KY",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 70,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093380",
      Sku: "66352",
      Description: "SCE PSW GATSBY",
      UnitCount: 25,
      Weight: 141.34,
      Cube: 1.06,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCEMER004",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 25,
      OnHand: 0,
      Shipment: "817410",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093381",
      Sku: "66352",
      Description: "SCE PSW GATSBY",
      UnitCount: 31,
      Weight: 175.26,
      Cube: 1.32,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCELEX003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 31,
      OnHand: 0,
      Shipment: "817424",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093382",
      Sku: "66352",
      Description: "SCE PSW GATSBY",
      UnitCount: 32,
      Weight: 180.91,
      Cube: 1.36,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCECOP003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 32,
      OnHand: 0,
      Shipment: "817433",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093383",
      Sku: "66352",
      Description: "SCE PSW GATSBY",
      UnitCount: 34,
      Weight: 192.22,
      Cube: 1.45,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 34,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093380",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 307,
      Weight: 1735.6,
      Cube: 13.07,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCEMER004",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 307,
      OnHand: 0,
      Shipment: "817410,817411",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093381",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 397,
      Weight: 2244.41,
      Cube: 16.9,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCELEX003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 397,
      OnHand: 0,
      Shipment: "817423,817424,817425",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093382",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 399,
      Weight: 2255.71,
      Cube: 16.99,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCECOP003",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 399,
      OnHand: 0,
      Shipment: "817433,817434",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812014,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 149,
      Weight: 842.36,
      Cube: 6.34,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy ID",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },

    {
      ShipID: 812018,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 210,
      Weight: 3.55,
      Cube: 2.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy SC",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812018,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 140,
      Weight: 33,
      Cube: 21.5,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy SC",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812018,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 110,
      Weight: 45.55,
      Cube: 25.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy SC",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812018,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 210,
      Weight: 12.55,
      Cube: 23.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy SC",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },

    {
      ShipID: 812017,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 210,
      Weight: 12.55,
      Cube: 23.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy TX",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812017,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 60,
      Weight: 6.55,
      Cube: 7.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy TX",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
    {
      ShipID: 812017,
      PurchaseOrder: "4500093383",
      Sku: "70419",
      Description: "PSW GILDED LEAVES MINI WARMER US/CA",
      UnitCount: 300,
      Weight: 5.55,
      Cube: 5.2,
      IsHazardousMaterial: null,
      IsHot: false,
      Pieces: 12,
      ShortQuantity: null,
      OverQuantity: null,
      DamagedQuantity: null,
      Pallet: null,
      Location: "Scentsy TX",
      LocationCode: "SCEROC001",
      UPC: null,
      AstrayQuantity: null,
      PoExpectedDate: null,
      BuyerId: null,
      Department: null,
      ReferenceNumber: "WEEK 31",
      ThirdPartyName: null,
      ThirdPartyQuantity: null,
      Shipped: 149,
      OnHand: 0,
      Shipment: "817597",
      Pallets: "",
      PoEta: null,
      ReceivedDate: null,
      ProcessedDate: null,
      Color: null,
      Size: null,
      ProductType: null,
      ReceivedQuantity: null,
    },
  ]);
  const [DeletRowId, setDeletRowId] = useState(0);
  const [ShipmentsID, setShipmentsID] = useState(iShipmentsID);
  const [ReloadPalletsList, setReloadPalletsList] = useState(true);
  const [selectedShip, setselectedShip] = useState(false);
  const [Search, setSearch] = useState("");

  function ReloadPallets() {
    setReloadPalletsList(false);
    setTimeout(() => {
      setReloadPalletsList(true);
    }, 1);
  }

  useEffect(() => {
    console.log(iShipmentsID);
    setShipmentsID(iShipmentsID);
  }, [iShipmentsID]);

  useEffect(() => {
    console.log(Table);
  }, [Table]);

  function SetTableShipment(row, Quantity) {
    var table = Table;
    var a = table.findIndex((x) => x?.Id === row?.Id);
    if (a > -1) {
      table[a].Quantity += Quantity;
      setTable([]);
      setTimeout(() => {
        setTable(table);
      }, 1);
    } else {
      row.Quantity = Quantity;
      setShipmentsID((x) => {
        var a = x.find((y) => y.Id === row.ShipID);
        if (a) a.Progress += 10;
        return x;
      });
      setTable((x) => [...x, row]);
    }
  }

  function DeleteShipmentRow(id, IdShip) {
    RemovePallet(IdShip).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        var a = Table.filter((x) => x?.Id !== id);
        var b = Table.find((x) => x?.Id === id);
        setTable([]);
        setDeletRowId(0);
        setTimeout(() => {
          setTable(a);
          setDeletRowId("orderId=" + b?.TransloadOrderId);
        }, 1);
      } else {
        Notification("error", Message?.toString());
      }
    });
  }

  return (
    <>
      <ShipmentFun
        Table={Table}
        ShipmentsID={ShipmentsID}
        setselectedShip={setselectedShip}
      />

      {ShipmentsID.length > 0 && (
        <IndexPalletsList
          SetTableShipment={SetTableShipment}
          DeletRowId={DeletRowId}
          ReloadPallets={ReloadPallets}
          setSearch={setSearch}
          Search={Search}
          OrdersIds={ShipmentsID}
          selectedShip={selectedShip}
          iShipmentsID={iShipmentsID}
        />
      )}
      {IsClosed ? <Nodatafound /> : <></>}
    </>
  );
}

async function RemovePallet(id) {
  return await fetch(
    `${window.$apiurl}/TransloadWarehouse/DeleteShipItem/${id}`,
    {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Shipment Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}

function ShipmentFun({ Table = [], ShipmentsID, setselectedShip }) {
  const [selectedId, setselectedId] = useState(false);
  const [Pallets2, setPallets2] = useState([]);
  const [ScrollList, setScrollList] = useState([]);

  /* useEffect(() => {
    var list = [];
    ShipmentsID.forEach((x) => {
      list.push({ Id: x });
    });
    setScrollList(list);
  }, [ShipmentsID]); */

  useEffect(() => {
    setselectedShip(selectedId);
  }, [selectedId]);

  return (
    <div>
      {/* <ScrollingMenuReceipt
        list={ScrollList}
        setselectedId={setselectedId}
        Title="Orders"
      />
      <br /> */}

      <div className="row">
        <div className="col-12">
          <div className="alert alert-info" role="alert">
            <b>Locations</b>
          </div>
        </div>

        {ShipmentsID.map((x, i) => (
          <TableComponent
            list={Table}
            ShipmentID={x}
            hidden={+selectedId !== +x}
            selectedShip={+selectedId}
          />
        ))}
      </div>
    </div>
  );
}

/* function IndexPalletsListFun({
  selectedShip,
  SetTableShipment,
  DeletRowId,
  Shipment,
  ReloadPallets,
  setSearch,
  Search,
  ShipmentsID,
  ReloadPalletsList,
}) {
  const [selectedId, setselectedId] = useState(false);
  const [Pallets2, setPallets2] = useState([]);
  const [ScrollList, setScrollList] = useState([]);

  return (
    <div>
       {selectedShip}
     {
        <IndexPalletsList
          SetTableShipment={SetTableShipment}
          DeletRowId={DeletRowId}
          Shipment={Shipment}
          ReloadPallets={ReloadPallets}
          setSearch={setSearch}
          Search={Search}
          OrdersIds={ShipmentsID}
          ReloadPalletsList={ReloadPalletsList}
        />
      } 
    </div>
  );
} */
