import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../modules/Notification";
import { CheckboxLargeWB } from "../../../_metronic/_partials/controls";
import { useParams } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "--/--/----";
  }
}
function tConvert(time) {
  if (time === null || time === undefined || time === "") {
    return "";
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj };
  }
  async componentDidMount() {
    await fetch(
      window.$apiurl + "/ShipmentBilling/Outbound/report/" + this.props.id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", "No data found !");
        }
        const json = await response.json();
        this.setState({ data: json });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const formData = this.state.data;
    console.log("object");
    console.log(formData);

    return (
      <>
        <Helmet>
          <title>eMPower | LTL Form</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-5 bl-form border-0">
            <div className="card-body p-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-10">
                    <div className="col-md-8 px-0 mr-auto">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="border-0">
                              <b className="h6">{formData.Title}</b>
                              <br></br>
                              {formData.From?.Name}
                              <br></br>
                              {formData.From?.Address1 === null
                                ? " "
                                : formData.From?.Address1}
                              {(formData.From?.Address2 === null) |
                              (formData.From?.Address2 === null)
                                ? " "
                                : formData.From?.Address2}
                              <br></br>
                              {formData.From?.City === null
                                ? " "
                                : formData.From?.City}
                              ,{" "}
                              {formData.From?.State === null
                                ? " "
                                : formData.From?.State}{" "}
                              {formData.From?.ZipCode === null
                                ? " "
                                : formData.From?.ZipCode}
                              <br></br>
                              {formData.From?.Phone === null
                                ? " "
                                : formData.From?.Phone}
                              <br></br>
                              {formData.From?.Reference === null
                                ? " "
                                : formData.From?.Reference}
                              <br></br>
                            </td>
                            <td className="border-0 text-left">
                              <Link to="#" class="mb-5 max-w-100px">
                                <span class="svg-icon svg-icon-full">
                                  <img
                                    src="/media/logos/Mitco.svg"
                                    className="w-75"
                                    alt="Logo Mitco"
                                  />
                                </span>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">PickUp Date</th>
                            <th className="text-center">Delivery Date</th>
                            <th className="text-center">Shipment Id</th>
                            <th className="text-center">Shipment Ref </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formatDate(formData.PickUpDate)}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formatDate(formData.DeliveryDate)}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.ShipmentId}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.ShipmentRef}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">Service</th>
                            <th className="text-center">Mode</th>
                            <th className="text-center">Service Level</th>
                            <th className="text-center">Equipment Size</th>
                            <th className="text-center">Equipment Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Service}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Mode}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.ServiceLevel}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.EquipmentSize}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.EquipmentType}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12">
                      <div className="row">
                        <table className="w-50">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                PICKUP Information
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                Name:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={formData.PickUp?.Address?.Name}
                                  className=" MuiInputBase-input MuiInput-input w-75"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Address:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={
                                    (formData.PickUp?.Address?.Address1 === null
                                      ? " "
                                      : formData.PickUp?.Address?.Address1) +
                                    " " +
                                    ((formData.PickUp?.Address?.Address2 ===
                                      null) |
                                    (formData.PickUp?.Address?.Address2 ===
                                      null)
                                      ? " "
                                      : formData.PickUp?.Address?.Address2) +
                                    "  " +
                                    (formData.PickUp?.Address?.City === null
                                      ? " "
                                      : formData.PickUp?.Address?.City) +
                                    "  " +
                                    (formData.PickUp?.Address?.State === null
                                      ? " "
                                      : formData.PickUp?.Address?.State) +
                                    "  " +
                                    (formData.PickUp?.Address?.ZipCode === null
                                      ? " "
                                      : formData.PickUp?.Address?.ZipCode)
                                  }
                                  className=" MuiInputBase-input MuiInput-input w-75"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Contact:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={formData.PickUp?.Contact}
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Phone:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={formData.PickUp?.Phone}
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Pick Up Time From-To:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={tConvert(
                                    formData?.PickUpReadyTime +
                                      " - " +
                                      formData?.PickUpCloseTIme
                                  )}
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            {(formData?.ReceivingNote !== null &&
                              formData?.ReceivingNote !== "") ||
                            (formData?.ShippingNote !== null &&
                              formData?.ShippingNote !== "") ? (
                              <tr>
                                <td>Pickup Notes: {formData?.ShippingNote}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>Pickup #: {formData?.ShipperNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                DELIVERY Information
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                Name:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={formData.Delivery?.Name}
                                  className=" MuiInputBase-input MuiInput-input w-75"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Address:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={
                                    (formData.Delivery?.Address?.Address1 ===
                                    null
                                      ? " "
                                      : formData.Delivery?.Address?.Address1) +
                                    " " +
                                    ((formData.Delivery?.Address?.Address2 ===
                                      null) |
                                    (formData.Delivery?.Address?.Address2 ===
                                      null)
                                      ? " "
                                      : formData.Delivery?.Address?.Address2) +
                                    "  " +
                                    (formData.Delivery?.Address?.City === null
                                      ? " "
                                      : formData.Delivery?.Address?.City) +
                                    "  " +
                                    (formData.Delivery?.Address?.State === null
                                      ? " "
                                      : formData.Delivery?.Address?.State) +
                                    "  " +
                                    (formData.Delivery?.Address?.ZipCode ===
                                    null
                                      ? " "
                                      : formData.Delivery?.Address?.ZipCode)
                                  }
                                  className=" MuiInputBase-input MuiInput-input w-75"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Contact:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={
                                    (formData.Delivery?.Contact === undefined) |
                                    (formData.Delivery?.Contact === null)
                                      ? " "
                                      : formData.Delivery?.Contact
                                  }
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Phone:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={
                                    (formData.Delivery?.Phone === undefined) |
                                    (formData.Delivery?.Phone === null)
                                      ? " "
                                      : formData.Delivery?.Phone
                                  }
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Delivery Time From-To:{" "}
                                <input
                                  readOnly
                                  type="text"
                                  name="codamount"
                                  value={tConvert(
                                    formData?.DeliveryReadyTime +
                                      " - " +
                                      formData?.DeliveryCloseTIme
                                  )}
                                  className=" MuiInputBase-input MuiInput-input"
                                />
                              </td>
                            </tr>
                            {(formData?.ReceivingNote !== null &&
                              formData?.ReceivingNote !== "") ||
                            (formData?.ShippingNote !== null &&
                              formData?.ShippingNote !== "") ? (
                              <tr>
                                <td>
                                  Delivery Notes: {formData?.ReceivingNote}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>Delivery #: {formData?.ConsigneeNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Items */}
                  <div class="d-flex justify-content-center flex-column flex-md-row">
                    <h4 class="font-weight-boldest my-7">Shipment Items</h4>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">PCS</th>
                            <th className="text-center">Package Type</th>
                            {/* <th className="text-center">Pieces</th> */}
                            <th className="text-center">HAZMAT</th>
                            {/* <th className="text-center">List of Items</th> */}
                            <th className="text-center">Total Weight (LBS)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.handlingUnit}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.PackageType}
                            </td>
                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.Pieces}</td> */}
                            <td className="font-weight-boldest align-text-top text-center">
                              <CheckboxLargeWB
                                checked={
                                  formData.Items?.HasMat !== null &&
                                  formData.Items?.HasMat === true
                                    ? true
                                    : false
                                }
                                label=""
                                text=""
                                name="PALLET_SLIP1"
                              />
                            </td>
                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.ListOfItems}</td> */}
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.TotalLBSWeight !== 0
                                ? formData.Items?.TotalLBSWeight
                                : (formData.Items?.TotalWeight).toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Vendors */}
                  <div class="d-flex justify-content-center flex-column flex-md-row">
                    <h4 class="font-weight-boldest my-7">Payables</h4>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">Vendor Name</th>
                            <th className="text-center">Vendor Reference</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Rate</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.Vendors?.length > 0 &&
                            formData.Vendors?.map(
                              (item, index) =>
                                item.Payables?.length > 0 &&
                                item.Payables?.map((itemX, index) => (
                                  <tr>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item.VendorAddress?.CompanyName}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item.VendorNumber}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Name}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Rate.toFixed(2)}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Quantity}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Total.toFixed(2)}
                                    </td>
                                  </tr>
                                ))
                            )}
                          {formData.Vendors?.length > 0 &&
                            formData.Vendors?.map((item, index) => (
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td
                                  className="font-weight-boldest"
                                  style={{
                                    backgroundColor: "#113059",
                                    color: "#fff",
                                  }}
                                >
                                  GRAND TOTAL
                                </td>
                                <td>
                                  <input
                                    readOnly
                                    value={"$" + item.Total?.toFixed(2)}
                                    type="text"
                                    name="codamount"
                                    className="MuiInputBase-input MuiInput-input w-100 font-weight-boldest align-text-top text-center"
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12">
                      <div className="row">
                        <table className="w-50">
                          <tbody className="border-0">
                            <tr>
                              <td className="border-0">
                                <b>PICKUP INSTRUCTIONS:</b>
                                <br></br>{" "}
                                <input
                                  readOnly
                                  type="text"
                                  value={formData.PickUpInstructions}
                                  name="codamount"
                                  className=" MuiInputBase-input MuiInput-input w-75"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50">
                          <tbody className="border-0">
                            <tr>
                              <td className="pb-10 border-0" colspan="6">
                                <b>DELIVERY INSTRUCTIONS</b>
                                <br></br>
                                {formData.DeliveryInstructions}
                                <br></br>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top border-0 text-center h6">
                              Mitco Ltd. must be notified of detention 1⁄2 hour
                              before it starts and documentation within 48
                              hours. BOL must have time in and out and
                              facilities signature.
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top border-0 pb-5">
                              <b>CARRIER COST:</b> ${formData.CarrierCost}{" "}
                              Accepted:________________________________
                              Date:____________________
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top text-center border-0 font-weight-bold">
                              Phone: (253) 245-2102 | Fax: (253) 891-0601
                              <br></br>
                              Please sign and return via email to ensure payment
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top text-center border-0 font-weight-bold">
                              <small>
                                Please call (253) 245-2102 immediately with any
                                questions, concerns, or problems!<br></br>
                                Send Invoicing to: Mitco Limited Logistics. |
                                2302 B Street NW #101 | Auburn, WA. 98001
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function LTLFORM({ id }) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden border-0">
      <div className="card-body p-0">
        <div className="container mb-md-5 mt-4">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print LTL Form
              </button>
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
      </div>
    </div>
  );
}
