import React, { useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LoadingDialog } from "../../../../../../_metronic/_partials/controls";

export function CustomersLoadingDialog() {
  // Customers Redux state
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.listLoading }),
    shallowEqual
  );
  // if (isLoading === false) {
  //   if (window.$isFromGlobalSearch) {
  //     if (document.getElementsByClassName("inputShipmentId")[0] !== undefined) {
  //       setTimeout(() => {
  //         document.getElementsByClassName("inputShipmentId")[0].click();
  //       }, 1000);
  //       setTimeout(() => {
  //         window.$isFromGlobalSearch = false;
  //       }, 2000);
  //     }
  //   }
  // }
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading]);
  return (
    <LoadingDialog isLoading={isLoading} text="Loading ..." />
  );
}
