import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";



const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));
export function ManageRatesSelect() {
    const [id, setId] = useState(0);
    const [billingId, setBillingId] = useState(0);
    const [customersList, setCustomersList] = useState([]);
    const [showProgress, setshowProgress] = useState(false);

    useEffect(() => {
        fetchUsersAsync();
      
    }, []);
    const fetchUsersAsync = async () => {
        try {
            setshowProgress(true);
            const result = await axios.get("api/ManageItems/Filter");
            setCustomersList(result.data["customersList"]);
            setshowProgress(false);
        } catch (e) { 
            setshowProgress(false);
        }
    };
    const classes = useStyles();
    const SelectOnclick = () => {
        setId(0);
        let CustomerId = document.getElementById("CustomerId").value;
        let billingId = document.getElementById("BillingTypeId").value;
        if (CustomerId) {
            setId(CustomerId);
        }
        if (billingId) {
            setBillingId(billingId);
        }
    }
    return (
<div >
        <Paper className={`row ${classes.root}`}>
            <div className="col-lg-4 my-5">
                    <select
                        className="form-control"
                        name="status"
                        placeholder="Choose a Customer"
                        id="CustomerId">
                        {customersList.map((item, index) => (
                            <option key={index} value={item.Id}>
                                {item.CompanyName}
                            </option>
                        ))}
                    </select>
                {showProgress && <LinearProgress />}
                <small className="form-text text-muted">
                    <b>Choose a Customer</b>
                </small>
            </div>
            <div className="col-lg-4 my-5">
                    <select
                        className="form-control"
                        name="BillingTypeId"
                        placeholder="Choose a Billing Type"
                        id="BillingTypeId"
                    >
                        <option value="1">Receiving </option>
                        <option value="2">Shipping</option>
                    </select>
                <small className="form-text text-muted">
                    <b>Choose a Billing Type</b>
                </small>
            </div>
            <div className="col-md-4  mb-5 align-self-center">
                <a className="btn btn-success btn-shadow-hover mr-2" href={`/ManageRates/${id}/${billingId}`} onClick={() => {
                                SelectOnclick();
                            }}>
                    Select
                </a>
            </div>
        </Paper>
         </div>
    );
}
