/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState, Fragment } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CheckboxLargeWB,
  ModalProgressBar,
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import { Dropdown } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import { ServiceOrderFtlDetail } from "./ServiceOrderFtlDetail";
import { ServiceOrderLtlDetail } from "./ServiceOrderLtlDetail";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Formik } from "formik";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Notification } from "../../../../Notification";
import { ActivityFunction } from "../../../../../../_metronic/_helpers/ATHelpers";
import moment from "moment";
import { IconButton, TextField, Typography } from "@material-ui/core";
import { DropdownCustomTo } from "../../../../../../_metronic/_partials/dropdowns";
import DynamicReport from "../../../../../pages/DynamicReport/DynamicReport";
import { BLFORM } from "../../../../../pages/BL/BLFORM";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CreatableSelect from "react-select/creatable";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DRAYAGEFORM } from "../../../../../pages/Drayage/DRAYAGEFORM";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton";
import { TmsNotesGroup } from "./TmsNotes";
import { ClickFun } from "../../../../../SpecificTabs";
import { BlFormMulti } from "../../../../../pages/BL/BlFormMulti";
import { RateConfirmationBeta } from "../../../../../pages/Drayage/RateConfirmationBeta";
import {
  ChargesOriAddSup,
  NigativePositiveStop,
} from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function ProductTMSDetail({ history, SO, setEditModal, setMo }) {
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, productForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
    }),
    shallowEqual
  );
  const [ViewModelOptions, setViewModelOptions] = useState(null);
  const [showBol, setBol] = useState(false);
  const [showBolMulti, setBolMulti] = useState(false);
  const [showDispatchReport, setRateConfirmation] = useState(false);
  const [showDispatchReportMulti, setRateConfirmationMulti] = useState(false);
  const [LoadingPost, setLoadingPCost] = useState(false);
  const [ModalActivities, setModalActivities] = useState(false);
  const [ShowDeleteSOModal, setShowDeleteSOModal] = useState(false);
  const [ShowCancelSOModal, setShowCancelSOModal] = useState(false);
  const [charges, setCharges] = useState([]);
  const [vendorPayables, setVendorPayables] = useState([]);
  const [showModalSend, setshowModalSend] = useState(false);
  const [IsCollapsedBasic, setIsCollapsedBasic] = useState(false);
  const [EmailAddressList, setEmailAddressList] = useState([]);
  const [ToDataDefeault, setToDataDefeault] = useState([]);
  const [SubjectData, setSubjectData] = useState(null);
  const [includCCData, setincludCCData] = useState(false);
  const [ShowLoadBidsModal, setShowLoadBidsModal] = useState(false);
  const [includBol, setincludBol] = useState(false);
  const [includOutboundDispatch, setincludOutboundDispatch] = useState(true);
  const [includShipmentManifest, setincludShipmentManifest] = useState(false);
  const [BodyData, setBodyData] = useState("");
  const [AttachmentData, setAttachmentData] = useState([]);
  const [AttachementList, setAttachementList] = useState(null);
  const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);
  const [ToData, setToData] = useState(null);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [modalNotes, setmodalNotes] = useState(false);
  const [loaderSo, setLoaderSo] = useState(false);
  const [ReloadCalc, setReloadCalc] = useState(0);
  const [LoadingDuplicateSo, setLoadingDuplicateSo] = useState(false);
  const [initProduct, setinitProduct] = useState({
    id: undefined,
    So: "",
    MO: "",
    Customer: "",
    PU: "",
    Mode: "",
    Del: "",
    Vendors: [],
  });

  //Multi MO

  const [inputList, setInputList] = useState([null]);

  function handleChangeProccess(i, event) {
    let values = [...inputList];
    values[i] = event?.value === undefined ? "" : event?.value;
    setInputList(values);
  }
  const handleAddClick = () => {
    setInputList([...inputList, null]);
  };
  //End Multi MO
  const [loaderSoStop, setLoaderSoStop] = useState(false);
  function GetSoData(SO, isloaderSo) {
    if (SO === undefined) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/ServiceOrders/${SO}`, requestOptions)
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then((result) => {
        var obj = JSON.parse(result);
        window.AutoRating = obj?.AutoRating;
        if (obj?.Shipment?.Vendors?.length === 0) {
          obj.Shipment.Vendors.push({
            EmptyLocation: "",
            FreightChargeId: 98,
            TruckNumber: "",
            ItemVendorId: null,
            DriverPhone: "",
            DriverName: "",
            ContactId: "",
            Created: "",
            EnteredBy: "",
            CarrierId: "",
            VendorId: "",
          });
        }
        setinitProduct(obj?.Shipment);
        if (isloaderSo) {
          setLoaderSoStop(false);
        }
        setInputList(
          obj?.Shipment?.OrderIds?.length > 0 ? obj?.Shipment?.OrderIds : [null]
        );

        setLoaderSo(false);
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    GetSoData(SO);
    fetchUsersAsync();
  }, [SO]);

  function GetViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/GetViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setViewModelOptions(data);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetViewModel();
  }, []);
  function CheckStops(params) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tms/checkstops/${params}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification(
              "infoClick",
              <span dangerouslySetInnerHTML={{ __html: result[1].Message }} />
            );
          }
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetMilageFunctions(
    ShipperState,
    ShipperCity,
    ConsigneeState,
    ConsigneeCity,
    shipmentData
  ) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/tracking/getdistance?fromcity=${ShipperCity}&fromstate=${ShipperState}&destCity=${ConsigneeCity}&destState=${ConsigneeState}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        var _initProduct = { ...shipmentData };
        _initProduct.TotalMiles = data;
        setinitProduct(_initProduct);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    if (
      initProduct?.ShipperState !== null &&
      initProduct?.ShipperState !== "" &&
      initProduct?.ShipperState !== undefined &&
      initProduct?.ShipperCity !== null &&
      initProduct?.ShipperCity !== "" &&
      initProduct?.ShipperCity !== undefined &&
      initProduct?.ConsigneeState !== null &&
      initProduct?.ConsigneeState !== "" &&
      initProduct?.ConsigneeState !== undefined &&
      initProduct?.ConsigneeCity !== null &&
      initProduct?.ConsigneeCity !== "" &&
      initProduct?.ConsigneeCity !== undefined
    ) {
      GetMilageFunctions(
        initProduct?.ShipperState,
        initProduct?.ShipperCity,
        initProduct?.ConsigneeState,
        initProduct?.ConsigneeCity,
        initProduct
      );
    } else {
      var _initProduct = { ...initProduct };
      if (
        (initProduct?.ShipperState === null ||
          initProduct?.ShipperState === "" ||
          initProduct?.ShipperState === undefined ||
          initProduct?.ShipperCity === null ||
          initProduct?.ShipperCity === "" ||
          initProduct?.ShipperCity === undefined) &&
        initProduct?.ConsigneeState !== null &&
        initProduct?.ConsigneeState !== "" &&
        initProduct?.ConsigneeState !== undefined &&
        initProduct?.ConsigneeCity !== null &&
        initProduct?.ConsigneeCity !== "" &&
        initProduct?.ConsigneeCity !== undefined
      ) {
        _initProduct.TotalMiles = "Shipping address not found";
        setinitProduct(_initProduct);
      } else if (
        (initProduct?.ConsigneeState === null ||
          initProduct?.ConsigneeState === "" ||
          initProduct?.ConsigneeState === undefined ||
          initProduct?.ConsigneeCity === null ||
          initProduct?.ConsigneeCity === "" ||
          initProduct?.ConsigneeCity === undefined) &&
        initProduct?.ShipperState !== null &&
        initProduct?.ShipperState !== "" &&
        initProduct?.ShipperState !== undefined &&
        initProduct?.ShipperCity !== null &&
        initProduct?.ShipperCity !== "" &&
        initProduct?.ShipperCity !== undefined
      ) {
        _initProduct.TotalMiles = "Receiving address not found";
        setinitProduct(_initProduct);
      } else {
        _initProduct.TotalMiles = "Shipping and receiving addresses not found";
        setinitProduct(_initProduct);
      }
    }
  }, [
    initProduct?.ShipperCity,
    initProduct?.ShipperState,
    initProduct?.ConsigneeCity,
    initProduct?.ConsigneeState,
  ]);

  function Save(values) {
    //Start Validation
    let hasError = false;
    var valuesClone = JSON.parse(JSON.stringify(values));
    //chack basic info

    if (valuesClone.OnHold) {
      Notification("errorClick", "SO is On Hold");
      return;
    }

    if (
      valuesClone.WarehouseId === null ||
      valuesClone.WarehouseId === "" ||
      valuesClone.WarehouseId === undefined
    ) {
      Notification("errorClick", "Branch is required");
      hasError = true;
    }

    if (
      valuesClone.ShipmentTypeId === null ||
      valuesClone.ShipmentTypeId === "" ||
      valuesClone.ShipmentTypeId === undefined
    ) {
      Notification("errorClick", "Shipment Type is required");
      hasError = true;
    }

    if (
      valuesClone.ModeName !== "Drayage" &&
      (valuesClone.ShipmentDate === null ||
        valuesClone.ShipmentDate === "" ||
        valuesClone.ShipmentDate === undefined)
    ) {
      Notification("errorClick", "Shipment Date is required");
      hasError = true;
    }

    if (
      valuesClone.ModeId === null ||
      valuesClone.ModeId === "" ||
      valuesClone.ModeId === undefined
    ) {
      Notification("errorClick", "Mode is required");
      hasError = true;
    }

    if (
      (valuesClone.ModeName === "OTR (Over The Road)" ||
        valuesClone.ModeName === "Reefer FTL" ||
        valuesClone.ModeName === "Flatbed") &&
      (valuesClone.StatusName === "" || valuesClone.StatusName === "New") &&
      (valuesClone.MaxBuy === null ||
        valuesClone.MaxBuy === "" ||
        valuesClone.MaxBuy === undefined)
    ) {
      Notification("errorClick", "Max Buy is required");
      hasError = true;
    }

    //check shipper
    if (
      valuesClone.ShipperId === null ||
      valuesClone.ShipperId === undefined ||
      valuesClone.ShipperId === "" ||
      valuesClone.ShipperId === 0 ||
      valuesClone.ShipperId === "0"
    ) {
      Notification("errorClick", "Shipping Company is required");
      hasError = true;
    }

    if (
      valuesClone.ModeName !== "Drayage" &&
      (valuesClone.ReqShipDate === null ||
        valuesClone.ReqShipDate === undefined ||
        valuesClone.ReqShipDate === "")
    ) {
      Notification("errorClick", "Req. Ship Date is required");
      hasError = true;
    }

    //check consignee
    if (
      valuesClone.ConsigneeId === null ||
      valuesClone.ConsigneeId === undefined ||
      valuesClone.ConsigneeId === "" ||
      valuesClone.ConsigneeId === 0 ||
      valuesClone.ConsigneeId === "0"
    ) {
      Notification("errorClick", "Receiving Company is required");
      hasError = true;
    }

    if (
      valuesClone.ModeName !== "Drayage" &&
      (valuesClone.MustArriveByDate === null ||
        valuesClone.MustArriveByDate === undefined ||
        valuesClone.MustArriveByDate === "")
    ) {
      Notification("errorClick", "Must Arrive By Date (MABD) is required");
      hasError = true;
    }

    //Order Assignments Check

    if (
      valuesClone.CapacityAssigneeId === null ||
      valuesClone.CapacityAssigneeId === undefined ||
      valuesClone.CapacityAssigneeId === ""
    ) {
      Notification("errorClick", "Capacity Assignment is required");
      hasError = true;
    }
    if (
      valuesClone.CapacityOperationId === null ||
      valuesClone.CapacityOperationId === undefined ||
      valuesClone.CapacityOperationId === ""
    ) {
      Notification("errorClick", "Capacity Operations is required");
      hasError = true;
    }
    if (
      valuesClone.CommercialAssigneeId === null ||
      valuesClone.CommercialAssigneeId === undefined ||
      valuesClone.CommercialAssigneeId === ""
    ) {
      Notification("errorClick", "Commercial Assignment is required");
      hasError = true;
    }
    if (
      valuesClone.CommercialOperationId === null ||
      valuesClone.CommercialOperationId === undefined ||
      valuesClone.CommercialOperationId === ""
    ) {
      Notification("errorClick", "Commercial Operations is required");
      hasError = true;
    }

    if (
      document.getElementsByClassName("SoCommodity").length === 0 &&
      valuesClone.ShipmentTypeId === 1357
    ) {
      Notification("errorClick", "Minimum 1 commodity required");
      hasError = true;
    }
    //End Validation
    if (hasError) {
      return false;
    }

    var chargeslist = charges;
    if (NigativePositiveStop(chargeslist)) return false;
    chargeslist = ChargesOriAddSup(chargeslist);
    valuesClone.OrderIds = inputList;
    valuesClone.Charges = Array.isArray(chargeslist) ? chargeslist : [];
    /* if (valuesClone?.Vendors?.length > 0) {
      valuesClone.Vendors[0].Payables = Array.isArray(vendorPayables)
        ? vendorPayables
        : [];
    } */
    //inputList
    if (valuesClone.Vendors.length === 1) {
      if (valuesClone.Vendors[0].CarrierId === "") {
        valuesClone.Vendors = [];
      }
    }

    setLoadingPCost(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(valuesClone);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/ServiceOrders/${SO}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        try {
          if (result[0] === 200) {
            var buttnRefresh = document.getElementById("refreshTrackingTMS");
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              if (document.getElementById("tmsItemsSave")) {
                document.getElementById("tmsItemsSave").click();
              }

              Notification(
                "success",
                "Service Order has been Updated successfully"
              );
              Notification(
                "infoClick",
                <span
                  dangerouslySetInnerHTML={{
                    __html: result[1].Message,
                  }}
                />
              );
              GetSoData(SO);
              var buttnRefresh = document.getElementById("refreshTrackingTMS");
              if (buttnRefresh) {
                buttnRefresh.click();
              }
              getAttachmentData();
            } else {
              if (document.getElementById("tmsItemsSave")) {
                document.getElementById("tmsItemsSave").click();
              }
              //Stops Items one click
              try {
                const list = document.querySelectorAll(".postStop");
                list.forEach((x) => {
                  if (!x.classList.contains("disabled")) {
                    x.click();
                  }
                });
              } catch (err) {
                console.log(err);
              }
              Notification(
                "success",
                "Service Order has been Updated successfully"
              );
              CheckStops(SO);
              GetSoData(SO);
              if (buttnRefresh) {
                buttnRefresh.click();
              }
              getAttachmentData();
            }
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
          }
          setLoadingPCost(false);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPCost(false);
      });
  }
  const saveProduct = (values) => {
    if (
      values.ModeName === "Intermodal Rail (COFC)" &&
      values.ShipperDepartureDate !== null &&
      values.ShipperDepartureDate !== "" &&
      (values.Equipment === "" || values.Equipment === null)
    ) {
      Notification(
        "errorClick",
        "Trailer # required when entering departure date/time"
      );
      return;
    }
    if (
      values?.IsTeam === true &&
      values.Vendors[0]?.IsTeam === false &&
      values.Vendors[0]?.VendorId !== initProduct.Vendors[0]?.VendorId
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "you want to not assign the put the carrier as team !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Save(values);
        }
      });
    } else {
      Save(values);
    }
  };
  const btnRef = useRef();
  //Send Email
  async function SaveShipementAattachement() {
    if (
      document.getElementById("shipmentAttachmentList").files[0] === undefined
    ) {
      Notification("error", "Please select Attachment");
      return;
    }
    setisLoadingSubmit(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachmentList");
    const AccessLevelId = document.getElementById("shipmentAccessLevelIdList")
      .value;
    const AttachmentTypeId = document.getElementById(
      "shipmentAttachmentTypeIdList"
    ).value;
    formdata.append(
      "File",
      inputElement.files[0],
      inputElement.value.split("\\")[2]
    );
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
    };
    let response = await fetch(
      `${window.$apiurl}/transload/shipments/addattachment?shipmentId=` +
        SO +
        "&accessLevelId=" +
        AccessLevelId +
        "&attachmentTypeId=" +
        AttachmentTypeId,
      requestOptions
    );
    await response.json();
    var inputs = document.querySelectorAll(".ShipmentsAttachmentCheckBox");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    setisLoadingSubmit(false);
    getAttachmentData();
    setAttachmentData([]);
  }
  async function getAttachmentData() {
    if (SO === undefined) {
      return;
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/shipments/getsendemail/" + SO, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        setAttachementList(resultobj.Attachments);
      })
      .catch((error) => console.log("error", error));
  }

  const deleteProduct = async () => {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    if (window.SendDispatchShipmentsList !== undefined) {
      data = {
        Ids: window.SendDispatchShipmentsList?.map(function(item) {
          return item.id;
        }),
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: includBol,
        OutboundDispatch: includOutboundDispatch,
        ShipmentManifest: includShipmentManifest,
      };
    } else {
      data = {
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: includBol,
        OutboundDispatch: includOutboundDispatch,
        ShipmentManifest: includShipmentManifest,
      };
    }

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    if (window.SendDispatchShipmentsList !== undefined) {
      url = "/shipmentsmanagement/sendMultiEmail";
    } else {
      url = `/shipments/sendEmail/` + SO;
    }
    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      Notification("success", "Email sent successfuly for Shipment ID " + SO);
      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setincludBol(false);
      setincludOutboundDispatch(false);
      setincludShipmentManifest(false);
      setAttachmentData([]);
    } else {
      Notification("error", "invalid data");
    }
    setisLoadingSend(false);
    setshowModalSend(false);
  };

  const fetchUsersAsync = async () => {
    try {
      if (SO !== null && SO !== undefined) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var requestOptions = {
          method: "GET",
          withCredentials: true,
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(window.$apiurl + "/shipments/getsendemail/" + SO, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const resultobj = JSON.parse(result);
            var obj = [];
            var obj2 = [];

            resultobj.Emails.map((item) => {
              obj.push({ value: item.Email, label: item.Email });
              obj2.push({ Email: item.Email, Name: item.Email });
              return null;
            });

            setToDataDefeault(obj);
            setToData(obj2);
            setEmailAddressList(obj);
            setAttachementList(resultobj.Attachments);
          })
          .catch((error) => console.log("error", error));

        fetch(window.$apiurl + "/carriers/transport/getviewmodel", {
          method: "get",
          headers: {
            Authorization: `bearer ${db.read().getState().Token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.text())
          .then((result) => {
            const objs = JSON.parse(result);
            setAccessLevels(objs?.AccessLevels || []);
            setAttachmentTypes(objs?.AttachmentTypes || []);
          })
          .catch((error) => console.log("error", error));
      }
    } catch (e) {}
  };
  const [assignment, setAssignment] = useState({
    CapacityAssigneeId: null,
    CapacityAssigneeIsGroup: false,
    CapacityOperationId: null,
    CapacityOperationIsGroup: false,
    CommercialAssigneeId: null,
    CommercialAssigneeIsGroup: false,
    CommercialOperationId: null,
    CommercialOperationIsGroup: false,
  });
  useEffect(() => {
    let currentValueAssignment = { ...assignment };
    if (initProduct?.CapacityAssigneeId) {
      currentValueAssignment.CapacityAssigneeId =
        initProduct?.CapacityAssigneeId;
    }
    if (initProduct?.CapacityAssigneeIsGroup) {
      currentValueAssignment.CapacityAssigneeIsGroup =
        initProduct?.CapacityAssigneeIsGroup;
    }

    if (initProduct?.CapacityOperationId) {
      currentValueAssignment.CapacityOperationId =
        initProduct?.CapacityOperationId;
    }
    if (initProduct?.CapacityOperationIsGroup) {
      currentValueAssignment.CapacityOperationIsGroup =
        initProduct?.CapacityOperationIsGroup;
    }

    if (initProduct?.CommercialAssigneeId) {
      currentValueAssignment.CommercialAssigneeId =
        initProduct?.CommercialAssigneeId;
    }
    if (initProduct?.CommercialAssigneeIsGroup) {
      currentValueAssignment.CommercialAssigneeIsGroup =
        initProduct?.CommercialAssigneeIsGroup;
    }

    if (initProduct?.CommercialOperationId) {
      currentValueAssignment.CommercialOperationId =
        initProduct?.CommercialOperationId;
    }
    if (initProduct?.CommercialOperationIsGroup) {
      currentValueAssignment.CommercialOperationIsGroup =
        initProduct?.CommercialOperationIsGroup;
    }
    setAssignment(currentValueAssignment);
    if (
      initProduct?.CustomerId !== null &&
      initProduct?.CustomerId !== undefined &&
      initProduct?.CustomerId !== ""
    ) {
      getAssignment(initProduct, currentValueAssignment, false);
    }
  }, [initProduct]);
  useEffect(() => {
    console.log("useEffectassignment", assignment);
    setAssignment(assignment);
  }, [assignment]);

  async function getAssignment(currentValue, oldValue, isCustomer) {
    if (
      currentValue?.CustomerId !== null &&
      currentValue?.CustomerId !== undefined
    ) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${window.$apiurl}/tms/customer/${currentValue?.CustomerId}?shippingCity=${currentValue?.ShipperCity}&shippingstate=${currentValue?.ShipperState}&receivingCity=${currentValue?.ConsigneeCity}&receivingState=${currentValue?.ConsigneeState}&ModeId=${currentValue.ModeId}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var obj = JSON.parse(result);
          if (isCustomer) {
            if (
              (obj.CapacityAssigneeId !== null &&
                obj.CapacityAssigneeId !== oldValue?.CapacityAssigneeId) ||
              (obj.CapacityOperationId !== null &&
                obj.CapacityOperationId !== oldValue?.CapacityOperationId) ||
              (obj.CommercialAssigneeId !== null &&
                obj.CommercialAssigneeId !== oldValue?.CommercialAssigneeId) ||
              (obj.CommercialOperationId !== null &&
                obj.CommercialOperationId !== oldValue?.CommercialOperationId)
            ) {
              Swal.fire({
                title: "Are you sure?",
                text: "You want to override the assignments ?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Change it!",
                cancelButtonText: "No",
              }).then((result) => {
                if (result.isConfirmed) {
                  let NewObj = {};
                  if (
                    obj.CapacityAssigneeId !== null &&
                    obj.CapacityAssigneeId !== oldValue?.CapacityAssigneeId
                  ) {
                    NewObj.CapacityAssigneeId = obj.CapacityAssigneeId;
                    NewObj.CapacityAssigneeIsGroup =
                      obj.CapacityAssigneeIsGroup;
                  } else {
                    NewObj.CapacityAssigneeId = initProduct.CapacityAssigneeId;
                    NewObj.CapacityAssigneeIsGroup =
                      initProduct.CapacityAssigneeIsGroup;
                  }
                  if (
                    obj.CapacityOperationId !== null &&
                    obj.CapacityOperationId !== oldValue?.CapacityOperationId
                  ) {
                    NewObj.CapacityOperationId = obj.CapacityOperationId;
                    NewObj.CapacityOperationIsGroup =
                      obj.CapacityOperationIsGroup;
                  } else {
                    NewObj.CapacityOperationId =
                      initProduct.CapacityOperationId;
                    NewObj.CapacityOperationIsGroup =
                      initProduct.CapacityOperationIsGroup;
                  }
                  if (
                    obj.CommercialAssigneeId !== null &&
                    obj.CommercialAssigneeId !== oldValue?.CommercialAssigneeId
                  ) {
                    NewObj.CommercialAssigneeId = obj.CommercialAssigneeId;
                    NewObj.CommercialAssigneeIsGroup =
                      obj.CommercialAssigneeIsGroup;
                  } else {
                    NewObj.CommercialAssigneeId =
                      initProduct.CommercialAssigneeId;
                    NewObj.CommercialAssigneeIsGroup =
                      initProduct.CommercialAssigneeIsGroup;
                  }
                  if (
                    obj.CommercialOperationId !== null &&
                    obj.CommercialOperationId !==
                      oldValue?.CommercialOperationId
                  ) {
                    NewObj.CommercialOperationId = obj.CommercialOperationId;
                    NewObj.CommercialOperationIsGroup =
                      obj.CommercialOperationIsGroup;
                  } else {
                    NewObj.CommercialOperationId =
                      initProduct.CommercialOperationId;
                    NewObj.CommercialOperationIsGroup =
                      initProduct.CommercialOperationIsGroup;
                  }
                  setAssignment(NewObj);
                }
              });
            }
          } else {
            if (
              oldValue?.CapacityAssigneeId === null ||
              oldValue?.CapacityAssigneeId === undefined ||
              oldValue?.CapacityAssigneeId === "" ||
              oldValue?.CapacityAssigneeId === "null"
            ) {
              oldValue.CapacityAssigneeId = obj?.CapacityAssigneeId;
              oldValue.CapacityAssigneeIsGroup = obj?.CapacityAssigneeIsGroup;

            }
            if (
              oldValue?.CapacityOperationId === null ||
              oldValue?.CapacityOperationId === undefined ||
              oldValue?.CapacityOperationId === "" ||
              oldValue?.CapacityOperationId === "null"
            ) {
              oldValue.CapacityOperationId = obj?.CapacityOperationId;
              oldValue.CapacityOperationIsGroup = obj?.CapacityOperationIsGroup;

            }
            if (
              oldValue?.CommercialAssigneeId === null ||
              oldValue?.CommercialAssigneeId === undefined ||
              oldValue?.CommercialAssigneeId === "" ||
              oldValue?.CommercialAssigneeId === "null"
            ) {
              oldValue.CommercialAssigneeId = obj?.CommercialAssigneeId;
              oldValue.CommercialAssigneeIsGroup =
                obj?.CommercialAssigneeIsGroup;
            }
       
            if (
              oldValue?.CommercialOperationId === null ||
              oldValue?.CommercialOperationId === undefined ||
              oldValue?.CommercialOperationId === "" ||
              oldValue?.CommercialOperationId === "null"
            ) {
              oldValue.CommercialOperationId = obj?.CommercialOperationId;
              oldValue.CommercialOperationIsGroup =
              obj?.CommercialOperationIsGroup;
            }
       
            let NewObj = {
              CapacityAssigneeId: oldValue.CapacityAssigneeId,
              CapacityAssigneeIsGroup: oldValue.CapacityAssigneeIsGroup,
              CapacityOperationId: oldValue.CapacityOperationId,
              CapacityOperationIsGroup: oldValue.CapacityOperationIsGroup,
              CommercialAssigneeId: oldValue.CommercialAssigneeId,
              CommercialAssigneeIsGroup: oldValue.CommercialAssigneeIsGroup,
              CommercialOperationId: oldValue.CommercialOperationId,
              CommercialOperationIsGroup: oldValue.CommercialOperationIsGroup,
            };
            setAssignment(NewObj);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }

  async function NextStatusCancel() {
    const noteValue = document.getElementById("valueNoteCancel").value;

    if (noteValue === "" || noteValue === undefined || noteValue === null) {
      Notification("error", "please fill cencled note");
      return;
    }

    await fetch(`${window.$apiurl}/transload/shipments/addnote/${SO}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: `{"body":"${noteValue}","CategoryId":12,"accessLevelId":5}`,
    })
      .then((response) => {
        if (response.status !== 200) {
          Notification("error", "Note Note Add");
        }
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch(`${window.$apiurl}/ShipmentActions/updateSingleStatus`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: `{"Id":${SO},"IsCancel":true}`,
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("infoClick", result[1].Message);
          } else {
            if (result[0] === 200) {
              Notification("success", "Cancel SO is successful");
            }
            GetSoData(SO);
          }
        } else {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "invalid request");
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    document.getElementById("valueNoteCancel").value = "";
    setShowCancelSOModal(false);

    return;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initProduct}
      onSubmit={(values) => {
        values.CapacityAssigneeId = assignment.CapacityAssigneeId;
        values.CapacityAssigneeIsGroup = assignment.CapacityAssigneeIsGroup;
        values.CapacityOperationId = assignment.CapacityOperationId;
        values.CapacityOperationIsGroup = assignment.CapacityOperationIsGroup;
        values.CommercialAssigneeId = assignment.CommercialAssigneeId;
        values.CommercialAssigneeIsGroup = assignment.CommercialAssigneeIsGroup;
        values.CommercialOperationId = assignment.CommercialOperationId;
        values.CommercialOperationIsGroup =
          assignment.CommercialOperationIsGroup;
        saveProduct(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        function EmailTypeChangedFuction(params) {
          switch (params) {
            case "BOL":
              setBodyData(
                `<p>Hello,</p><p>Please find Mitco BOL attached, thank you!</p>`
              );
              setSubjectData(`Mitco BOL Notice: SO # ${SO}`);
              setincludBol(true);
              setincludOutboundDispatch(false);
              setincludShipmentManifest(false);
              break;
            case "Rate Confirmation":
              setincludBol(false);
              setincludOutboundDispatch(true);
              setincludShipmentManifest(false);
              if (values?.Vendors.length > 0) {
                setBodyData(
                  `<p>Hello ${
                    values?.Vendors[0]?.CarrierId === "" ||
                    values?.Vendors[0]?.CarrierId === undefined
                      ? ""
                      : ViewModelOptions?.Vendors?.filter(
                          (a) => a.Id === values?.Vendors[0]?.VendorId
                        )[0]?.Name
                  },</p><p>Please find Mitco Rate Confirmation attached for Order # ${SO}. Please reply back with confirmation of receipt and acceptance, thank you!</p>`
                );
              }

              setSubjectData(`Mitco Rate Confirmation: SO # ${SO}`);
              break;
            case "Tracking Request":
              setincludBol(false);
              setincludOutboundDispatch(false);
              setincludShipmentManifest(false);
              if (values.Vendors.length > 0) {
                var myHeaders = new Headers();
                myHeaders.append(
                  "Authorization",
                  `bearer ${db.read().getState().Token}`
                );

                var requestOptions = {
                  method: "GET",
                  headers: myHeaders,
                  redirect: "follow",
                };

                fetch(
                  `${window.$apiurl}/tms/getemail/${SO}?vendorId=${values.Vendors[0]?.Id}`,
                  requestOptions
                )
                  .then((response) => response.text())
                  .then((result) => {
                    let dataemail = JSON.parse(result);
                    setToDataDefeault(dataemail?.Emails || []);
                    setAttachementList(dataemail?.Attachments || []);
                    setBodyData(
                      `<p>Hello ${
                        ViewModelOptions?.Vendors?.filter(
                          (a) => a.Id === values?.Vendors[0]?.VendorId
                        )[0].Name
                      },</p><p>Please reply back with the latest update for the below shipment. Please be sure to include:</p><p><br data-cke-filler="true"></p><p>Current location</p><p>Current ETA</p><p>If delayed, details &amp; cause of delay</p><p><br data-cke-filler="true"></p><p><strong>Shipper</strong>: ${
                        dataemail?.Shipment?.FromCity !== null
                          ? dataemail?.Shipment?.FromCity
                          : ""
                      }/${
                        dataemail?.Shipment?.FromState !== null
                          ? dataemail?.Shipment?.FromState
                          : ""
                      }</p><p><strong>Receiver</strong>: ${
                        dataemail?.Shipment?.ToCity !== null
                          ? dataemail?.Shipment?.ToCity
                          : ""
                      }/${
                        dataemail?.Shipment?.ToState !== null
                          ? dataemail?.Shipment?.ToState
                          : ""
                      }</p><p><strong>Order #</strong>: ${SO}</p><p><br data-cke-filler="true"></p><p>Thank you!</p>`
                    );
                    setshowModalSend(true);
                  })
                  .catch((error) => console.log("error", error));
              }

              setSubjectData(`Mitco Tracking Request: SO # ${SO}`);
              break;
            default:
              setBodyData("");
              setSubjectData("");
              break;
          }
        }
        function duplicateSo() {
          setLoadingDuplicateSo(true);
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `bearer ${db.read().getState().Token}`
          );
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            Id: SO,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(`${window.$apiurl}/tms/ServiceOrders/Duplicate`, requestOptions)
            .then(async (response) => {
              const statusCode = await response.status;
              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json().then((data) => [statusCode, data]);
              } else {
                return response
                  .text()
                  .then((text) => [statusCode, { Message: "" }]);
              }
            })
            .then((result) => {
              if (result[0] === 200) {
                Notification(
                  "success",
                  `Service Order (${SO}) has been Duplicated successfully`
                );
                ClickFun(
                  "",
                  "/TransportManagementSystems/SoDetail/",
                  result[1].Id
                );
              } else {
                if (
                  result[1].Message !== null &&
                  result[1].Message !== undefined &&
                  result[1].Message !== ""
                ) {
                  Notification("errorClick", result[1].Message);
                } else {
                  Notification("errorClick", "Invalid Data");
                }
              }
              setLoadingDuplicateSo(false);
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
        return (
          <>
            <DynamicReport
              setShowModal={setBol}
              showModal={showBol}
              id={SO}
              title={`BOL Shipment ID#: ${SO}`}
              reportContent={<BLFORM id={SO} />}
            />
            <DynamicReport
              setShowModal={setRateConfirmation}
              showModal={showDispatchReport}
              id={SO}
              title={`Rate Confirmation Shipment ID#: ${SO}`}
              reportContent={<DRAYAGEFORM id={SO} />}
            />
            <DynamicReport
              setShowModal={setBolMulti}
              showModal={showBolMulti}
              id={SO}
              title={`BOL Multi Shipment ID#: ${SO}`}
              reportContent={<BlFormMulti id={SO} />}
            />
            <DynamicReport
              setShowModal={setRateConfirmationMulti}
              showModal={showDispatchReportMulti}
              id={SO}
              title={`Rate Confirmation Multi Shipment ID#: ${SO}`}
              reportContent={<RateConfirmationBeta id={SO} />}
            />
            <Modal
              size="lg"
              show={ShowCancelSOModal}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title>
                  Are you sure that you want to{" "}
                  {initProduct.IsCanceled === true ? "UnCancel" : "Cancel"} this
                  Shipment ?
                </Modal.Title>
                <IconButton
                  aria-label="Close"
                  onClick={(e) => {
                    setShowCancelSOModal(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Modal.Header>
              <Modal.Body>
                <label>
                  {initProduct.IsCanceled === true ? "UnCancel" : "Cancel"}{" "}
                  Confirmation Note
                </label>
                <textarea
                  className="form-control mb-5"
                  rows="4"
                  id="valueNoteCancel"
                ></textarea>
              </Modal.Body>
              <Modal.Footer>
                <button
                  variant="secondary"
                  className="btn btn-danger btn-elevate"
                  onClick={(e) => {
                    setShowCancelSOModal(false);
                  }}
                >
                  No
                </button>

                <button
                  variant="secondary"
                  className="btn btn-light btn-elevate ml-5"
                  onClick={async (e) => {
                    await NextStatusCancel();
                  }}
                >
                  Yes
                </button>
              </Modal.Footer>
            </Modal>
            <Modal
              show={ShowDeleteSOModal}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Service Order Delete
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span>
                  Are you sure to permanently delete this Service Order?
                </span>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setShowDeleteSOModal(false);
                    }}
                    className="btn btn-light btn-elevate mr-3"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="button"
                    onClick={() => {
                      return fetch(
                        window.$apiurl + `/Tms/ServiceOrders/${SO}`,
                        {
                          method: "delete",
                          withCredentials: true,
                          headers: {
                            Accept: "application/json, text/plain, */*",
                            Authorization: `bearer ${
                              db.read().getState().Token
                            }`,
                            "X-FP-API-KEY": "iphone",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType = response.headers.get(
                            "content-type"
                          );
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            Notification(
                              "success",
                              "Service Order has been Deleted successfully"
                            );
                            setShowDeleteSOModal(false);
                            if (
                              window.TMSEditSOCommingFrom === "CapacityBoard"
                            ) {
                              document
                                .getElementById("openCapacityBoardPage2")
                                .click();
                            } else {
                              document.getElementById("LoadBoardGrid").click();
                            }

                            //history.push(`/TransportManagementSystems`);
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function(k) {
                                  modelState[k].forEach((element) => {
                                    Notification("errorClick", element);
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Notification("errorClick", result[1].Message);
                            } else {
                              Notification("errorClick", "Bad Request");
                            }
                          }
                        })
                        .catch((error) => {});
                    }}
                    className="btn btn-delete btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <Modal
              size="xl"
              show={ModalActivities}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <div className="row w-100">
                  <div className="col-lg-6">
                    <h3 className="font-weight-bolder text-dark">
                      SO Activities ID: {values?.Id}
                    </h3>
                  </div>
                </div>
                <IconButton
                  className="MuiButtonBase-root MuiIconButton-root jss179"
                  aria-label="Close"
                  onClick={() => {
                    setModalActivities(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Modal.Header>
              <Modal.Body className="overlay-block">
                <ActivityFunction Activitys={values?.Activities || []} />
              </Modal.Body>
            </Modal>
            <Modal
              size="xl"
              show={showModalSend}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header>
                <Modal.Title id="example-modal-sizes-title-lg">
                  Shipment Send
                </Modal.Title>
                <IconButton
                  className="MuiButtonBase-root MuiIconButton-root jss179"
                  aria-label="Close"
                  onClick={() => {
                    setshowModalSend(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Modal.Header>
              <Modal.Body>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="mb-3">Email Type: </p>
                      <Select
                        options={[
                          { label: "BOL", value: "BOL" },
                          {
                            label: "Rate Confirmation",
                            value: "Rate Confirmation",
                          },
                          {
                            label: "Tracking Request",
                            value: "Tracking Request",
                          },
                        ]}
                        isClearable
                        isSearchable
                        onChange={(e) => {
                          EmailTypeChangedFuction(e !== null ? e.value : null);
                        }}
                      ></Select>
                    </div>
                    <div className="col-lg-12 mt-5">
                      <p className="mb-3">To: </p>
                      {EmailAddressList && (
                        <CreatableSelect
                          isMulti
                          name="To"
                          id="To"
                          value={ToDataDefeault}
                          options={EmailAddressList}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(e) => {
                            var obj = [];
                            var objDefeault = [];
                            if (e) {
                              e.map((item) => {
                                obj.push({
                                  Email: item.value,
                                  Name: item.value,
                                });
                                objDefeault.push({
                                  value: item.value,
                                  label: item.value,
                                });
                                return null;
                              });
                            }
                            setToDataDefeault(objDefeault);
                            setToData(obj);
                          }}
                        />
                      )}

                      <CheckboxLarge
                        Size="12px"
                        name="includCC"
                        text="Include me on CC"
                        onChange={(event) => {
                          setincludCCData(event.target.checked);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 mt-5">
                      <p className="mb-3">Subject: </p>
                      <input
                        name="Subject"
                        className="form-control"
                        placeholder="Subject"
                        value={SubjectData}
                        onChange={(event) => {
                          setSubjectData(event.target.value);
                        }}
                      />
                    </div>
                    <div className="col-lg-12 mt-5">
                      <p className="mb-3">Body: </p>

                      <CKEditor
                        name="Body"
                        Id="Body"
                        label="Body"
                        editor={ClassicEditor}
                        config={{
                          removePlugins: [
                            "Image",
                            "ImageCaption",
                            "ImageStyle",
                            "ImageToolbar",
                            "ImageUpload",
                            "MediaEmbed",
                          ],
                        }}
                        data={BodyData}
                        onBlur={(event, editor) => {
                          const data = editor.getData();
                          setBodyData(data);
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 mt-5">
                          <h4>Attachments</h4>
                        </div>

                        {AttachementList?.map((item, index) => {
                          return (
                            <div className="col-lg-12 row mt-5">
                              <div className="col-lg-12 row ml-0">
                                <CheckboxLarge
                                  Size="12px"
                                  name={`xxxxx${index}`}
                                  value={item.Id}
                                  className="ShipmentsAttachmentCheckBox"
                                  onChange={(event) => {
                                    var obj = AttachmentData;
                                    obj.push(event.target.value);
                                    setAttachmentData(obj);
                                  }}
                                />
                                <a
                                  className="mt-3"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${item.Path}`}
                                >
                                  {item.Name}
                                </a>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="row mt-10">
                        <div className="col-lg-4">
                          <p>Add Attachment</p>
                          <input
                            id="shipmentAttachmentList"
                            type="file"
                            name="shipmentAttachmentList"
                            //component={Input}
                            placeholder="Add Attachment"
                            label="Add Attachment"
                          />
                        </div>
                        <div className="col-lg-3">
                          <p>Attachment Type</p>
                          <select
                            className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                            name="shipmentAttachmentTypeIdList"
                            id="shipmentAttachmentTypeIdList"
                            label="Attachment Type"
                          >
                            {AttachmentTypes.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-3">
                          <p>Access Level</p>
                          <select
                            className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                            name="shipmentAccessLevelIdList"
                            id="shipmentAccessLevelIdList"
                            label="Access Level"
                          >
                            {AccessLevels.map((item, index) => (
                              <option key={index} value={item.Id}>
                                {item.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-2 mt-8">
                          <button
                            type="button"
                            onClick={SaveShipementAattachement}
                            disabled={isLoadingSubmit}
                            className="btn btn-primary btn-elevate"
                          >
                            Save Attachment
                            {isLoadingSubmit && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setshowModalSend(false);
                    }}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="button"
                    onClick={deleteProduct}
                    disabled={isLoadingSend}
                    className="btn btn-delete btn-primary"
                  >
                    Send Email
                    {isLoadingSend && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </div>
              </Modal.Footer>
            </Modal>
            <button
              className={`btn btn-icon btn-primary btn-hover-primary btnCollapseLeft ${
                IsCollapsedBasic ? "mb-5 collapsedClass" : ""
              }`}
              onClick={() => {
                try {
                  setIsCollapsedBasic(IsCollapsedBasic ? false : true);
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              {IsCollapsedBasic ? (
                <i
                  class="fas fa-chevron-right"
                  style={{ fontSize: "13px" }}
                ></i>
              ) : (
                <i class="fas fa-chevron-left" style={{ fontSize: "13px" }}></i>
              )}
            </button>
            <TmsNotesGroup
              values={values}
              id={SO}
              modalNotes={modalNotes}
              setmodalNotes={setmodalNotes}
            />
            <div className="row">
              <div
                className={`${
                  IsCollapsedBasic ? "d-none fade" : "col-xl-3"
                } col-lg-3 col-md-4 fade show`}
              >
                <div className={`row ${IsCollapsedBasic ? "widthBasic" : ""}`}>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <Card className="bg-transparent shadow-none header-detail mb-2">
                      <CardHeader>
                        <CardHeaderToolbar className="pl-0 align-content-top mt-0">
                          {!IsCollapsedBasic && (
                            <Fragment>
                              <button
                                type="submit"
                                disabled={LoadingPost}
                                className="btn btn-primary ml-2"
                                onClick={handleSubmit}
                              >
                                Save
                                {LoadingPost && (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                )}
                              </button>
                              <button
                                className="btn btn-warning ml-2"
                                onClick={() => {
                                  setModalActivities(true);
                                }}
                              >
                                Activities
                              </button>
                              <Dropdown
                                className="dropdown-inline position-unset pl-3"
                                alignLeft
                              >
                                <Dropdown.Toggle
                                  className="btn btn-sm btn-icon btn-bg-light"
                                  variant="transparent"
                                  id="dropdown-toggle-top"
                                  as={DropdownCustomTo}
                                >
                                  <i className="fas fa-cogs text-warning" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
                                  <ul className="navi navi-hover py-5">
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setmodalNotes(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-sticky-note text-primary"></i>
                                        </span>
                                        <span className="navi-text text-primary">
                                          Notes
                                        </span>
                                      </a>
                                    </li>
                                    {/* <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setRateConfirmation(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-file-invoice text-info"></i>
                                        </span>
                                        <span className="navi-text text-info">
                                          Rate Confirmation
                                        </span>
                                      </a>
                                    </li> */}
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setRateConfirmationMulti(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-file-invoice text-info"></i>
                                        </span>
                                        <span className="navi-text text-info">
                                          Rate Confirmation
                                        </span>
                                      </a>
                                    </li>
                                    {/* <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setBol(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-file-alt text-warning"></i>
                                        </span>
                                        <span className="navi-text text-warning">
                                          BOL
                                        </span>
                                      </a>
                                    </li> */}
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setBolMulti(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-file-alt text-warning"></i>
                                        </span>
                                        <span className="navi-text text-warning">
                                          BOL
                                        </span>
                                      </a>
                                    </li>
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setshowModalSend(true);
                                          setincludShipmentManifest(false);
                                          setincludOutboundDispatch(false);
                                          setincludBol(false);
                                          setBodyData("");
                                          setSubjectData("");
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-envelope text-success"></i>
                                        </span>
                                        <span className="navi-text text-success">
                                          Send Email
                                        </span>
                                      </a>
                                    </li>
                                    <li className="navi-item">
                                      <a
                                        className="navi-link "
                                        style={{
                                          pointerEvents:
                                            LoadingDuplicateSo && "none",
                                          cursor:
                                            LoadingDuplicateSo && "default",
                                        }}
                                        onClick={() => {
                                          duplicateSo();
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-clone text-dark"></i>
                                        </span>
                                        <span className="navi-text text-dark">
                                          Duplicate SO
                                        </span>
                                      </a>
                                    </li>
                                    {values?.StatusName !== "New" && (
                                      <li className="navi-item">
                                        <a
                                          className="navi-link"
                                          onClick={() => {
                                            setShowLoadBidsModal(true);
                                          }}
                                        >
                                          <span className="navi-icon">
                                            <i className="fa fa-gavel text-primary"></i>
                                          </span>
                                          <span className="navi-text text-primary">
                                            Load Bids
                                          </span>
                                        </a>
                                      </li>
                                    )}
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          Swal.fire({
                                            title: "Are you sure?",
                                            text: `you want to ${
                                              initProduct.OnHold === true
                                                ? "Unmake on hold"
                                                : "Make on hold"
                                            } This SO`,
                                            icon: "warning",
                                            showCancelButton: true,
                                            confirmButtonColor: "#3085d6",
                                            cancelButtonColor: "#d33",
                                            confirmButtonText: `Yes, ${
                                              initProduct.OnHold === true
                                                ? "Unmake on hold"
                                                : "make on hold"
                                            } it!`,
                                          }).then((result) => {
                                            if (result.isConfirmed) {
                                              fetch(
                                                `${window.$apiurl}/tms/ServiceOrders/MakeOnHold`,
                                                {
                                                  method: "post",
                                                  withCredentials: true,
                                                  headers: {
                                                    Accept:
                                                      "application/json, text/plain, */*",
                                                    Authorization: `bearer ${
                                                      db.read().getState().Token
                                                    }`,
                                                    "X-FP-API-KEY": "iphone",
                                                    "Content-Type":
                                                      "application/json",
                                                  },
                                                  body: `{
                                          "Id": ${SO},
                                          "OnHold": ${!initProduct.OnHold}
                                        }`,
                                                }
                                              )
                                                .then((response) => {
                                                  if (response.status !== 200) {
                                                    Notification(
                                                      "error",
                                                      "error"
                                                    );
                                                  } else {
                                                    GetSoData(SO);
                                                    Notification(
                                                      "success",
                                                      `SO is ${
                                                        initProduct.OnHold ===
                                                        true
                                                          ? "UnHold"
                                                          : "Hold"
                                                      } successfully`
                                                    );
                                                  }
                                                })
                                                .catch((error) => {
                                                  console.error(error);
                                                });
                                            }
                                          });
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-times-circle text-warning"></i>
                                        </span>
                                        <span className="navi-text text-warning">
                                          {initProduct.OnHold === true
                                            ? "Unmake on hold"
                                            : "Make on hold"}
                                        </span>
                                      </a>
                                    </li>
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setShowCancelSOModal(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-times-circle text-danger"></i>
                                        </span>
                                        <span className="navi-text text-danger">
                                          {initProduct.IsCanceled === true
                                            ? "UnCancel"
                                            : "Cancel"}
                                        </span>
                                      </a>
                                    </li>
                                    <li className="navi-item">
                                      <a
                                        className="navi-link"
                                        onClick={() => {
                                          setShowDeleteSOModal(true);
                                        }}
                                      >
                                        <span className="navi-icon">
                                          <i className="fas fa-trash-alt text-danger"></i>
                                        </span>
                                        <span className="navi-text text-danger">
                                          Delete
                                        </span>
                                      </a>
                                    </li>
                                  </ul>
                                </Dropdown.Menu>
                              </Dropdown>
                            </Fragment>
                          )}
                        </CardHeaderToolbar>
                      </CardHeader>
                    </Card>
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <Card className={`${IsCollapsedBasic ? "d-none" : ""}`}>
                      <CardHeader
                        className="border-bottom-0 headerTitleExpand"
                        title={!IsCollapsedBasic ? "Basic Info" : ""}
                      ></CardHeader>
                      <CardBody>
                        <div className={IsCollapsedBasic ? "d-none" : ""}>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                SO #
                              </a>
                              <a className="text-parimary font-weight-bold">
                                <input
                                  type="text"
                                  className="form-control "
                                  name="MOText"
                                  id="SOText"
                                  value={SO}
                                  disabled
                                />
                              </a>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              {inputList !== undefined &&
                                inputList.map((x, i) => (
                                  <>
                                    <a
                                      href={() => false}
                                      onClick={() => {
                                        setMo(inputList[0]);
                                      }}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                    >
                                      MO #
                                    </a>
                                    <div className="form-group">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className={`col-11 pl-0`}>
                                          <Select
                                            isSearchable={true}
                                            isClearable
                                            id="Proccess"
                                            label="Process#"
                                            name="Process"
                                            isOptionDisabled={(option) =>
                                              option.disabled === "yes"
                                            }
                                            value={ViewModelOptions?.MasterOrders?.filter(
                                              (a) => a === x
                                            ).map((suggestion) => ({
                                              value: suggestion,
                                              label: suggestion,
                                            }))}
                                            options={ViewModelOptions?.MasterOrders?.map(
                                              (suggestion) => {
                                                if (
                                                  inputList.filter(
                                                    (a) =>
                                                      String(a) ===
                                                      String(suggestion)
                                                  ).length > 0
                                                ) {
                                                  return {
                                                    value: suggestion,
                                                    label: suggestion,
                                                    disabled: "yes",
                                                  };
                                                } else {
                                                  return {
                                                    value: suggestion,
                                                    label: suggestion,
                                                    disabled: "no",
                                                  };
                                                }
                                              }
                                            )}
                                            onChange={handleChangeProccess.bind(
                                              this,
                                              i
                                            )}
                                          />
                                        </div>

                                        <div className="">
                                          {i === 0 ? (
                                            <button
                                              type="button"
                                              className="btn btn-light-success w-40"
                                              onClick={() => handleAddClick()}
                                            >
                                              <i
                                                className="fas fa-plus fa-sm"
                                                style={{
                                                  paddingRight: "initial",
                                                }}
                                              ></i>
                                            </button>
                                          ) : (
                                            <button
                                              type="button"
                                              className="btn btn-light-danger"
                                              onClick={() => {
                                                const list = [...inputList];
                                                list.splice(i, 1);
                                                setInputList(list);
                                              }}
                                            >
                                              <i
                                                className="fas fa-trash"
                                                style={{
                                                  paddingRight: "initial",
                                                }}
                                              ></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Cust Ref #
                              </a>
                              <span className="text-muted font-weight-bold">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="CustomerReferenceNumber"
                                  id="CustomerReferenceNumber"
                                  value={values.CustomerReferenceNumber}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "CustomerReferenceNumber",
                                      e.target.value
                                    );
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Customer
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  name="CustomerId"
                                  value={ViewModelOptions?.Customers?.filter(
                                    (a) => a.Id === values.CustomerId
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Code + "-" + a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.Customers.map(
                                    (a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Code + "-" + a.Name,
                                      };
                                    }
                                  )}
                                  onChange={async (e) => {
                                    if (e !== null) {
                                      setFieldValue("CustomerId", e.value);
                                      setFieldValue(
                                        "BillToId",
                                        ViewModelOptions?.Customers?.filter(
                                          (a) => a.Id === e.value
                                        )[0]?.BillToId
                                      );
                                    } else {
                                      setFieldValue("CustomerId", null);
                                      setFieldValue("BillToId", null);
                                    }
                                    await getAssignment(
                                      {
                                        CustomerId: e?.value ?? null,
                                        ShipperCity: values.ShipperCity,
                                        ShipperState: values.ShipperState,
                                        ConsigneeCity: values.ConsigneeCity,
                                        ConsigneeState: values.ConsigneeState,
                                        ModeId: values.ModeId,
                                      },
                                      initProduct,
                                      true
                                    );
                                    setReloadCalc((y) => y + 1);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Bill To
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  value={ViewModelOptions?.GenericAddresses?.filter(
                                    (a) => a.Id === values.BillToId
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.GenericAddresses.map(
                                    (a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    }
                                  )}
                                  onChange={(e) => {
                                    if (e !== null) {
                                      setFieldValue("BillToId", e.value);
                                    } else {
                                      setFieldValue("BillToId", null);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Branch <span className="text-danger">*</span>
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  value={ViewModelOptions?.Warehouses?.filter(
                                    (a) => a.Id === values.WarehouseId
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.Warehouses.map(
                                    (a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    }
                                  )}
                                  onChange={(e) => {
                                    if (e !== null) {
                                      setFieldValue("WarehouseId", e.value);
                                      setFieldValue("WarehouseName", e.label);
                                    } else {
                                      setFieldValue("WarehouseId", null);
                                      setFieldValue("WarehouseName", "");
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Freight Charge
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  value={ViewModelOptions?.FreightCharges?.filter(
                                    (a) => a.Id === values.FreightChargeId
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.FreightCharges?.map(
                                    (a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    }
                                  )}
                                  onChange={(e) => {
                                    if (e !== null) {
                                      setFieldValue("FreightChargeId", e.value);
                                    } else {
                                      setFieldValue("FreightChargeId", null);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Shipment Type{" "}
                                <span className="text-danger">*</span>
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  isClearable
                                  value={ViewModelOptions?.ShipmentTypes?.filter(
                                    (a) =>
                                      String(a.Id) ===
                                      String(values.ShipmentTypeId)
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.ShipmentTypes?.map(
                                    (a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    }
                                  )}
                                  onChange={(e) => {
                                    if (e !== null) {
                                      setFieldValue("ShipmentTypeId", e.value);
                                    } else {
                                      setFieldValue("ShipmentTypeId", null);
                                    }
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Shipment Date{" "}
                                <span className="text-danger">*</span>
                              </a>
                              <span className="text-muted font-weight-bold">
                                <TextField
                                  id="ShipmentDate"
                                  type="date"
                                  value={String(
                                    moment(values.ShipmentDate).format(
                                      "YYYY-MM-DD"
                                    )
                                  )}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "ShipmentDate",
                                      e.target.value
                                    );
                                  }}
                                  className="form-control mt-3"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Mode <span className="text-danger">*</span>
                              </a>
                              <span className="font-weight-bold">
                                <Select
                                  name="Mode"
                                  id="Mode"
                                  label="Mode"
                                  inputId="react-select-single"
                                  value={ViewModelOptions?.Modes?.filter(
                                    (a) => a.Id === values.ModeId
                                  ).map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  options={ViewModelOptions?.Modes.map((a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  })}
                                  onChange={(e) => {
                                    setFieldValue("ModeId", e.value);
                                    setFieldValue("ModeName", e.label);
                                  }}
                                />
                              </span>
                            </div>
                          </div>
                          <div className="d-flex flex-wrap align-items-center pb-5">
                            <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                              <a
                                href={() => false}
                                className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                              >
                                Total Miles
                              </a>
                              <span className="text-muted font-weight-bold">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="TotalMiles"
                                  id="TotalMiles"
                                  disabled={true}
                                  value={values.TotalMiles}
                                />
                              </span>
                            </div>
                          </div>
                          {values.ModeName !== "Intermodal Rail (COFC)" &&
                            values.ModeName !== "Drayage" && (
                              <div className="d-flex flex-wrap align-items-center pb-5">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                  <span className="font-weight-bold">
                                    <CheckboxLargeWB
                                      className="font-size-md"
                                      name="IsTeam"
                                      text="Team"
                                      id="IsTeam"
                                      checked={
                                        values?.IsTeam === true ? true : false
                                      }
                                      onChange={(event) => {
                                        setFieldValue(
                                          "IsTeam",
                                          event.target.checked
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </CardBody>
                    </Card>
                  </div>

                  <div
                    className={`${
                      IsCollapsedBasic ? "d-none" : ""
                    } col-xl-12 col-lg-12 col-md-12`}
                  >
                    <Card>
                      <CardHeader
                        className="border-bottom-0"
                        title="Vendor Details"
                      >
                        <div className="separator separator-dashed my-3"></div>
                      </CardHeader>
                      <CardBody className="p-0">
                        {values?.Vendors?.map((items, indexs) => {
                          return (
                            <div className="card card-custom gutter-b bg-light mb-5">
                              <div class="card-header bg-light py-1">
                                <div class="card-title">
                                  <h4
                                    class="card-label text-truncate opacity-0"
                                    style={{ maxWidth: "150px" }}
                                  >
                                    Vendor Name
                                  </h4>
                                </div>
                                <div class="card-toolbar">
                                  <button
                                    type="button"
                                    id={`vendorUp${values?.Id ?? ""}${indexs}`}
                                    className={`btn btn-icon btn-sm btn-light-primary mr-1`}
                                    onClick={() => {
                                      var ele = document.getElementById(
                                        `vendor${values?.Id ?? ""}${indexs}`
                                      );
                                      if (ele)
                                        ele.scrollIntoView({
                                          behavior: "smooth",
                                          block: "start",
                                        });
                                    }}
                                  >
                                    <i class="fas fa-chevron-down" />
                                  </button>
                                  <button
                                    type="button"
                                    class={`btn btn-icon btn-sm mr-1 btn-light-${
                                      indexs === 0 ? "success" : "danger"
                                    } mr-2 btn-sm`}
                                    onClick={() => {
                                      var i = indexs + 1;
                                      if (indexs === 0) {
                                        var array = [
                                          ...values.Vendors,
                                          {
                                            EmptyLocation: "",
                                            FreightChargeId: 98,
                                            TruckNumber: "",
                                            DriverPhone: "",
                                            DriverName: "",
                                            ItemVendorId: null,
                                            ContactId: "",
                                            Created: "",
                                            EnteredBy: "",
                                            CarrierId: "",
                                            VendorId: "",
                                          },
                                        ];
                                        setFieldValue(`Vendors`, array);
                                      } else {
                                        var _arrayobj = JSON.parse(
                                          JSON.stringify(values.Vendors)
                                        ); // 2nd parameter means remove one item only
                                        _arrayobj.splice(indexs, 1);
                                        setFieldValue(`Vendors`, _arrayobj);
                                      }
                                    }}
                                  >
                                    <i
                                      class={`fas ${
                                        indexs === 0 ? "fa-plus" : "fa-times"
                                      } fa-sm`}
                                      style={{ paddingRight: "initial" }}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                              <div className="card-body py-0">
                                <div className="d-flex flex-wrap align-items-center pb-5">
                                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                    >
                                      Vendor
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isClearable
                                        name="Mode"
                                        id={`Vendors[${indexs}].VendorId`}
                                        isDisabled={values.ModeName === "LTL"}
                                        label={`Vendors[${indexs}].VendorId`}
                                        inputId="react-select-single"
                                        isOptionDisabled={(option) =>
                                          option.disabled === "yes"
                                        }
                                        value={ViewModelOptions?.Vendors?.filter(
                                          (a) =>
                                            a.Id ===
                                            values?.Vendors[indexs]?.VendorId
                                        ).map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.Vendors?.filter(
                                          (a) => a.IsActive
                                        )?.map((suggestion) => {
                                          if (!suggestion.IsActive) {
                                            return {
                                              value: suggestion.Id,
                                              label: suggestion.Name,
                                              disabled: "yes",
                                            };
                                          } else {
                                            return {
                                              value: suggestion.Id,
                                              label: suggestion.Name,
                                              disabled: "no",
                                            };
                                          }
                                        })}
                                        onChange={(e) => {
                                          if (e !== null) {
                                            setFieldValue(
                                              `Vendors[${indexs}].VendorId`,
                                              e.value
                                            );
                                            try {
                                              const currentVendor = ViewModelOptions?.Vendors?.find(
                                                (x) => x.Id === e.value
                                              );
                                              setFieldValue(
                                                `Vendors[${indexs}].CarrierId`,
                                                currentVendor?.CarrierId
                                              );
                                              setFieldValue(
                                                `Vendors[${indexs}].VendorName`,
                                                currentVendor?.Name
                                              );
                                            } catch (err) {}
                                          } else {
                                            setFieldValue(
                                              `Vendors[${indexs}].VendorId`,
                                              ""
                                            );
                                            setFieldValue(
                                              `Vendors[${indexs}].CarrierId`,
                                              ""
                                            );
                                          }
                                          console.log(
                                            "values.Id",
                                            values?.Vendors[indexs]?.Id
                                          );
                                          setFieldValue(
                                            `Vendors[${indexs}].ItemVendorId`,
                                            values?.Vendors[indexs]?.Id ===
                                              undefined
                                              ? null
                                              : values?.Vendors[indexs]?.Id
                                          );
                                          setReloadCalc((y) => y + 1);
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-center pb-5">
                                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                    >
                                      Booked By
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Booked By"
                                        id="BookedBy"
                                        label="Booked By"
                                        value={
                                          values?.Vendors?.length > 0
                                            ? values?.Vendors[indexs]?.EnteredBy
                                            : ""
                                        }
                                        disabled
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-center pb-5">
                                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                    >
                                      Booked Date
                                    </a>
                                    <span className="text-muted font-weight-bold">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="Booked By"
                                        id="BookedBy"
                                        label="Booked By"
                                        value={
                                          values?.Vendors[indexs]?.Created !==
                                            null &&
                                          values?.Vendors[indexs]?.Created !==
                                            "" &&
                                          values?.Vendors[indexs]?.Created !==
                                            undefined
                                            ? moment(
                                                values?.Vendors[indexs]?.Created
                                              ).format("YYYY-MM-DD hh:mm A")
                                            : ""
                                        }
                                        disabled
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap align-items-center pb-5">
                                  <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                    <a
                                      href={() => false}
                                      className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                    >
                                      Freight Charge
                                    </a>
                                    <span className="font-weight-bold">
                                      <Select
                                        isClearable
                                        name="FreightChargeId"
                                        id={`Vendors[${indexs}].FreightChargeId`}
                                        label={`Vendors[${indexs}].FreightChargeId`}
                                        inputId="react-select-single"
                                        value={ViewModelOptions?.FreightCharges?.filter(
                                          (a) =>
                                            a.Id ===
                                            values?.Vendors[indexs]
                                              ?.FreightChargeId
                                        )?.map((a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        })}
                                        options={ViewModelOptions?.FreightCharges?.map(
                                          (a) => {
                                            return {
                                              value: a.Id,
                                              label: a.Name,
                                            };
                                          }
                                        )}
                                        onChange={(e) => {
                                          if (e !== null) {
                                            setFieldValue(
                                              `Vendors[${indexs}].FreightChargeId`,
                                              e.value
                                            );
                                          } else {
                                            setFieldValue(
                                              `Vendors[${indexs}].FreightChargeId`,
                                              null
                                            );
                                          }
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                                {values.ModeName !==
                                  "Intermodal Rail (COFC)" && (
                                  <>
                                    <div className="d-flex flex-wrap align-items-center pb-5">
                                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                        >
                                          Dispatcher
                                        </a>
                                        <span className="font-weight-bold">
                                          <Select
                                            isClearable
                                            name="Mode"
                                            id={`Vendors[${indexs}].ContactId`}
                                            label={`Vendors[${indexs}].ContactId`}
                                            inputId="react-select-single"
                                            value={ViewModelOptions?.Vendors?.filter(
                                              (a) =>
                                                a.Id ===
                                                values?.Vendors[indexs]
                                                  ?.VendorId
                                            )[0]
                                              ?.Contacts?.filter(
                                                (a) =>
                                                  a.Id ===
                                                  values?.Vendors[indexs]
                                                    ?.ContactId
                                              )
                                              .map((a) => {
                                                return {
                                                  value: a.Id,
                                                  label: a.Name,
                                                };
                                              })}
                                            options={ViewModelOptions?.Vendors?.filter(
                                              (a) =>
                                                a.Id ===
                                                values?.Vendors[indexs]
                                                  ?.VendorId
                                            )[0]
                                              ?.Contacts?.filter(
                                                (a) => a.IsDispatcher === true
                                              )
                                              .map((a) => {
                                                return {
                                                  value: a.Id,
                                                  label: a.Name,
                                                };
                                              })}
                                            onChange={(e) => {
                                              if (e !== null) {
                                                setFieldValue(
                                                  `Vendors[${indexs}].ContactId`,
                                                  e.value
                                                );
                                              } else {
                                                setFieldValue(
                                                  `Vendors[${indexs}].ContactId`,
                                                  null
                                                );
                                              }
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                    {values.ModeName !== "Drayage" && (
                                      <>
                                        <div className="d-flex flex-wrap align-items-center pb-5">
                                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                            <a
                                              href={() => false}
                                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                            >
                                              Driver Name
                                            </a>
                                            <span className="text-muted font-weight-bold">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={`Vendors[${indexs}].DriverName`}
                                                id={`Vendors[${indexs}].DriverName`}
                                                label="Driver Name"
                                                value={
                                                  values?.Vendors[indexs]
                                                    ?.DriverName
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `Vendors[${indexs}].DriverName`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center pb-5">
                                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                            <a
                                              href={() => false}
                                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                            >
                                              Driver Phone
                                            </a>
                                            <span className="text-muted font-weight-bold">
                                              <input
                                                type="text"
                                                className="form-control"
                                                name={`Vendors[${indexs}].DriverPhone`}
                                                id={`Vendors[${indexs}].DriverPhone`}
                                                label="Driver Phone"
                                                value={
                                                  values?.Vendors[indexs]
                                                    ?.DriverPhone
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `Vendors[${indexs}].DriverPhone`,
                                                    e.target.value
                                                  );
                                                }}
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                                {values.ModeName === "OTR (Over The Road)" && (
                                  <div className="d-flex flex-wrap align-items-center pb-5">
                                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                      >
                                        Tractor #
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control"
                                          name={`Vendors[${indexs}].TruckNumber`}
                                          id={`Vendors[${indexs}].TruckNumber`}
                                          value={
                                            values.Vendors[indexs]?.TruckNumber
                                          }
                                          onChange={(e) => {
                                            setFieldValue(
                                              `Vendors[${indexs}].TruckNumber`,
                                              e.target.value
                                            );
                                          }}
                                          label="Tractor"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {values.ModeName !== "Intermodal Rail (COFC)" &&
                                  values.ModeName !== "Drayage" && (
                                    <div className="d-flex flex-wrap align-items-center pb-5">
                                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                        >
                                          Empty Loc
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name={`Vendors[${indexs}].EmptyLocation`}
                                            id={`Vendors[${indexs}].EmptyLocation`}
                                            label="Loc"
                                            value={
                                              values?.Vendors[indexs]
                                                ?.EmptyLocation
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                `Vendors[${indexs}].EmptyLocation`,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {indexs === 0 ? (
                                  <>
                                    {values.ModeName ===
                                    "Intermodal Rail (COFC)" ? (
                                      <div className="d-flex flex-wrap align-items-center pb-5">
                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                          >
                                            Trailer #
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control"
                                              name="Equipment"
                                              id="Equipment"
                                              value={values.Equipment}
                                              onChange={(e) => {
                                                setFieldValue(
                                                  "Equipment",
                                                  e.target.value
                                                );
                                              }}
                                              label="Trailer"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="d-flex flex-wrap align-items-center pb-5">
                                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <a
                                          href={() => false}
                                          className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                        >
                                          PRO #
                                        </a>
                                        <span className="text-muted font-weight-bold">
                                          <input
                                            type="text"
                                            className="form-control"
                                            name="ProNumber"
                                            id="ProNumber"
                                            value={values.ProNumber}
                                            onChange={(e) => {
                                              setFieldValue(
                                                "ProNumber",
                                                e.target.value
                                              );
                                            }}
                                            label="Pro Number"
                                          />
                                        </span>
                                      </div>
                                    </div>

                                    <div className="d-flex flex-wrap align-items-center pb-5">
                                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <CheckboxLargeWB
                                          className="font-size-md"
                                          name="MarkEmpty"
                                          text="Mark OFP"
                                          id="MarkEmpty"
                                          checked={
                                            values?.MarkEmpty === true
                                              ? true
                                              : false
                                          }
                                          onChange={(event) => {
                                            setFieldValue(
                                              "MarkEmpty",
                                              event.target.checked
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex flex-wrap align-items-center pb-5">
                                      <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                        <CheckboxLargeWB
                                          className="font-size-md"
                                          name="VendorConfirmed"
                                          text="Confirmed"
                                          id="VendorConfirmed"
                                          checked={
                                            values?.VendorConfirmed === true
                                              ? true
                                              : false
                                          }
                                          onChange={(event) => {
                                            setFieldValue(
                                              "VendorConfirmed",
                                              event.target.checked
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {values.ModeName ===
                                    "Intermodal Rail (COFC)" ? (
                                      <div className="d-flex flex-wrap align-items-center pb-5">
                                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                          <a
                                            href={() => false}
                                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                          >
                                            Trailer #
                                          </a>
                                          <span className="text-muted font-weight-bold">
                                            <input
                                              type="text"
                                              className="form-control"
                                              name={`values.Vendors[${indexs}].Equipment`}
                                              id={`values.Vendors[${indexs}].Equipment`}
                                              value={
                                                values.Vendors[indexs].Equipment
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `values.Vendors[${indexs}].Equipment`,
                                                  e.target.value
                                                );
                                              }}
                                              label="Trailer"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </CardBody>
                    </Card>
                  </div>
                  <div
                    className={`${
                      IsCollapsedBasic ? "d-none" : ""
                    } col-xl-12 col-lg-12 col-md-12`}
                  >
                    <Card>
                      <CardHeader
                        className="border-bottom-0"
                        title="Order Assignments"
                      >
                        <div className="separator separator-dashed my-3"></div>
                      </CardHeader>
                      <CardBody>
                        <div className="d-flex flex-wrap align-items-center pb-5">
                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                            >
                              Capacity Assignment{" "}
                              <span className="text-danger">*</span>
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isClearable
                                name="CapacityAssigneeId"
                                id="CapacityAssigneeId"
                                inputId="react-select-single"
                                value={ViewModelOptions?.Users?.filter(
                                  (a) =>
                                    a.Id === assignment?.CapacityAssigneeId &&
                                    a.IsGroup ===
                                      (assignment?.CapacityAssigneeIsGroup ===
                                      null
                                        ? false
                                        : assignment?.CapacityAssigneeIsGroup)
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={ViewModelOptions?.Users?.map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                    IsGroup: a.IsGroup,
                                  };
                                })}
                                onChange={(e) => {
                                  let valueAssigment = { ...assignment };
                                  if (e !== null) {
                                    setFieldValue(
                                      "CapacityAssigneeId",
                                      e.value
                                    );
                                    valueAssigment.CapacityAssigneeId =
                                      e?.value;
                                  } else {
                                    setFieldValue("CapacityAssigneeId", null);
                                    valueAssigment.CapacityAssigneeId = null;
                                  }
                                  setFieldValue(
                                    "CapacityAssigneeIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                  valueAssigment.CapacityAssigneeIsGroup =
                                    e !== null ? e.IsGroup : null;
                                  setAssignment(valueAssigment);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center pb-5">
                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                            >
                              Capacity Operations{" "}
                              <span className="text-danger">*</span>
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isClearable
                                name="CapacityOperationId"
                                id="CapacityOperationId"
                                inputId="react-select-single"
                                value={ViewModelOptions?.Users?.filter(
                                  (a) =>
                                    a.Id === assignment?.CapacityOperationId &&
                                    a.IsGroup ===
                                      (assignment?.CapacityOperationIsGroup ===
                                      null
                                        ? false
                                        : assignment?.CapacityOperationIsGroup)
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={ViewModelOptions?.Users?.map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                    IsGroup: a.IsGroup,
                                  };
                                })}
                                onChange={(e) => {
                                  let valueAssigment = { ...assignment };
                                  if (e !== null) {
                                    setFieldValue(
                                      "CapacityOperationId",
                                      e.value
                                    );
                                    valueAssigment.CapacityOperationId =
                                      e?.value;
                                  } else {
                                    setFieldValue("CapacityOperationId", null);
                                    valueAssigment.CapacityOperationId = null;
                                  }
                                  setFieldValue(
                                    "CapacityOperationIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                  valueAssigment.CapacityOperationIsGroup =
                                    e !== null ? e.IsGroup : null;
                                  setAssignment(valueAssigment);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center pb-5">
                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                            >
                              Commercial Assignment{" "}
                              <span className="text-danger">*</span>
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isClearable
                                name="CommercialAssigneeId"
                                id="CommercialAssigneeId"
                                inputId="react-select-single"
                                value={ViewModelOptions?.Users?.filter(
                                  (a) =>
                                    a.Id === assignment?.CommercialAssigneeId &&
                                    a.IsGroup ===
                                      (assignment?.CommercialAssigneeIsGroup ===
                                      null
                                        ? false
                                        : assignment?.CommercialAssigneeIsGroup)
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={ViewModelOptions?.Users?.map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                    IsGroup: a.IsGroup,
                                  };
                                })}
                                onChange={(e) => {
                                  let valueAssigment = { ...assignment };
                                  if (e !== null) {
                                    setFieldValue(
                                      "CommercialAssigneeId",
                                      e.value
                                    );
                                    valueAssigment.CommercialAssigneeId =
                                      e?.value;
                                  } else {
                                    setFieldValue("CommercialAssigneeId", null);
                                    valueAssigment.CommercialAssigneeId = null;
                                  }
                                  setFieldValue(
                                    "CommercialAssigneeIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                  valueAssigment.CommercialAssigneeIsGroup =
                                    e !== null ? e.IsGroup : null;
                                  setAssignment(valueAssigment);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-wrap align-items-center pb-5">
                          <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                            >
                              Commercial Operations{" "}
                              <span className="text-danger">*</span>
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isClearable
                                name="CommercialOperationId"
                                id="CommercialOperationId"
                                inputId="react-select-single"
                                value={ViewModelOptions?.Users?.filter(
                                  (a) =>
                                    a.Id ===
                                      assignment?.CommercialOperationId &&
                                    a.IsGroup ===
                                      (assignment?.CommercialOperationIsGroup ===
                                      null
                                        ? false
                                        : assignment?.CommercialOperationIsGroup)
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={ViewModelOptions?.Users?.map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                    IsGroup: a.IsGroup,
                                  };
                                })}
                                onChange={(e) => {
                                  let valueAssigment = { ...assignment };
                                  if (e !== null) {
                                    setFieldValue(
                                      "CommercialOperationId",
                                      e.value
                                    );
                                    valueAssigment.CommercialOperationId =
                                      e?.value;
                                  } else {
                                    setFieldValue(
                                      "CommercialOperationId",
                                      null
                                    );
                                    valueAssigment.CommercialOperationId = null;
                                  }
                                  setFieldValue(
                                    "CommercialOperationIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                  valueAssigment.CommercialOperationIsGroup =
                                    e !== null ? e.IsGroup : null;
                                  setAssignment(valueAssigment);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  IsCollapsedBasic
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-md-8"
                } col-lg-9  btn-fade`}
              >
                <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                  <OverlayTrigger
                    placement="left"
                    overlay={<Tooltip id="layout-tooltip">Back</Tooltip>}
                  >
                    <li className="nav-item mb-2" data-placement="left">
                      <button
                        type="button"
                        onClick={() => {
                          if (
                            String(window.activeTab)
                              .toLocaleLowerCase()
                              .includes("CapacityBoard".toLocaleLowerCase())
                          ) {
                            var ele = document.getElementById(
                              "openCapacityBoardPage2"
                            );
                            ele.value = inputList[0];
                            ele.click();
                          } else if (
                            String(window.activeTab)
                              .toLocaleLowerCase()
                              .includes("TrackingBoard".toLocaleLowerCase())
                          ) {
                            var ele2 = document.getElementById(
                              "openMODetailPage3"
                            );
                            ele2.value = inputList[0];
                            ele2.click();
                          } else {
                            if (inputList.length > 0) {
                              if (
                                inputList[0] !== null &&
                                inputList[0] !== "" &&
                                inputList[0] !== undefined
                              ) {
                                if (
                                  String(window.activeTab)
                                    .toLocaleLowerCase()
                                    .includes(
                                      "CapacityBoard".toLocaleLowerCase()
                                    )
                                ) {
                                  var ele1 = document.getElementById(
                                    "openMODetailPage2"
                                  );
                                  ele1.value = inputList[0];
                                  ele1.click();
                                } else if (
                                  String(window.activeTab)
                                    .toLocaleLowerCase()
                                    .includes(
                                      "TrackingBoard".toLocaleLowerCase()
                                    )
                                ) {
                                  var ele3 = document.getElementById(
                                    "openTMSDetailPage3"
                                  );
                                  ele3.value = inputList[0];
                                  ele3.click();
                                } else {
                                  var ele4 = document.getElementById(
                                    "openMODetailPage"
                                  );
                                  if (ele4) {
                                    ele4.value = inputList[0];
                                    ele4.click();
                                  }
                                }
                              } else {
                                setEditModal(false);
                              }
                            } else {
                              setEditModal(false);
                            }
                          }
                        }}
                        className="btn btn-sm btn-icon btn-bg-light"
                      >
                        <i className="fa fa-arrow-left"></i>
                      </button>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="documentations-tooltip">Save</Tooltip>
                    }
                  >
                    <li className="nav-item mb-2" data-placement="left">
                      <button
                        type="submit"
                        disabled={LoadingPost}
                        className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                        onClick={handleSubmit}
                      >
                        <i class="fas fa-save"></i>
                        {LoadingPost === true ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </button>
                    </li>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="documentations-tooltip">Activities</Tooltip>
                    }
                  >
                    <li className="nav-item mb-2" data-placement="left">
                      <button
                        className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                        onClick={() => {
                          setModalActivities(true);
                        }}
                      >
                        <i class="fas fa-history"></i>
                      </button>
                    </li>
                  </OverlayTrigger>

                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="layout-tooltip">More Options</Tooltip>
                    }
                  >
                    <li className="nav-item mb-2">
                      <Dropdown
                        className="dropdown-inline position-unset pt-2"
                        alignLeft
                        drop={"left"}
                      >
                        <Dropdown.Toggle
                          className="btn btn-icon btn-light-warning btn-hover-warning btn-sm"
                          id="dropdown-toggle-top"
                          as={DropdownCustomTo}
                        >
                          <i className="fas fa-cogs text-warning" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-left topFixedMenu">
                          <ul className="navi navi-hover py-5">
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setmodalNotes(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-sticky-note text-primary"></i>
                                </span>
                                <span className="navi-text text-primary">
                                  Notes
                                </span>
                              </a>
                            </li>
                            {/* <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setRateConfirmation(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-file-invoice text-info"></i>
                                </span>
                                <span className="navi-text text-info">
                                  Rate Confirmation
                                </span>
                              </a>
                            </li> */}
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setRateConfirmationMulti(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-file-invoice text-info"></i>
                                </span>
                                <span className="navi-text text-info">
                                  Rate Confirmation
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setBolMulti(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-file-alt text-warning"></i>
                                </span>
                                <span className="navi-text text-warning">
                                  BOL
                                </span>
                              </a>
                            </li>
                            {/* <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setBol(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-file-alt text-warning"></i>
                                </span>
                                <span className="navi-text text-warning">
                                  BOL
                                </span>
                              </a>
                            </li> */}
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setshowModalSend(true);
                                  setincludShipmentManifest(false);
                                  setincludOutboundDispatch(false);
                                  setincludBol(false);
                                  setBodyData("");
                                  setSubjectData("");
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-envelope text-success"></i>
                                </span>
                                <span className="navi-text text-success">
                                  Send Email
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                className="navi-link "
                                style={{
                                  pointerEvents: LoadingDuplicateSo && "none",
                                  cursor: LoadingDuplicateSo && "default",
                                }}
                                onClick={() => {
                                  duplicateSo();
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-clone text-dark"></i>
                                </span>
                                <span className="navi-text text-dark">
                                  Duplicate SO
                                </span>
                              </a>
                            </li>
                            {values?.StatusName !== "New" && (
                              <li className="navi-item">
                                <a
                                  className="navi-link"
                                  onClick={() => {
                                    setShowLoadBidsModal(true);
                                  }}
                                >
                                  <span className="navi-icon">
                                    <i className="fa fa-gavel text-primary"></i>
                                  </span>
                                  <span className="navi-text text-primary">
                                    Load Bids
                                  </span>
                                </a>
                              </li>
                            )}
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: `you want to ${
                                      initProduct.OnHold === true
                                        ? "Unmake on hold"
                                        : "Make on hold"
                                    } This SO`,
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: `Yes, ${
                                      initProduct.OnHold === true
                                        ? "Unmake on hold"
                                        : "Make on hold"
                                    } it!`,
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      fetch(
                                        `${window.$apiurl}/tms/ServiceOrders/MakeOnHold`,
                                        {
                                          method: "post",
                                          withCredentials: true,
                                          headers: {
                                            Accept:
                                              "application/json, text/plain, */*",
                                            Authorization: `bearer ${
                                              db.read().getState().Token
                                            }`,
                                            "X-FP-API-KEY": "iphone",
                                            "Content-Type": "application/json",
                                          },
                                          body: `{
                                          "Id": ${SO},
                                          "OnHold": ${!initProduct.OnHold}
                                        }`,
                                        }
                                      )
                                        .then((response) => {
                                          if (response.status !== 200) {
                                            Notification("error", "error");
                                          } else {
                                            GetSoData(SO);
                                            Notification(
                                              "success",
                                              `SO is ${
                                                initProduct.OnHold === true
                                                  ? "Unmake on hold"
                                                  : "Make on hold"
                                              } successfully`
                                            );
                                          }
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                        });
                                    }
                                  });
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-times-circle text-warning"></i>
                                </span>
                                <span className="navi-text text-warning">
                                  {initProduct.OnHold === true
                                    ? "Unmake on hold"
                                    : "Make on hold"}
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setShowCancelSOModal(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-times-circle text-danger"></i>
                                </span>
                                <span className="navi-text text-danger">
                                  {initProduct.IsCanceled === true
                                    ? "UnCancel"
                                    : "Cancel"}
                                </span>
                              </a>
                            </li>
                            <li className="navi-item">
                              <a
                                className="navi-link"
                                onClick={() => {
                                  setShowDeleteSOModal(true);
                                }}
                              >
                                <span className="navi-icon">
                                  <i className="fas fa-trash-alt text-danger"></i>
                                </span>
                                <span className="navi-text text-danger">
                                  Delete
                                </span>
                              </a>
                            </li>
                          </ul>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </OverlayTrigger>
                </ul>
                <button
                  className={`btn btn-icon btn-primary btn-hover-primary btnCollapseLeft ${
                    IsCollapsedBasic ? "mb-5 collapsedClass" : ""
                  }`}
                  onClick={() => {
                    try {
                      setIsCollapsedBasic(IsCollapsedBasic ? false : true);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {IsCollapsedBasic ? (
                    <i
                      class="fas fa-chevron-right"
                      style={{ fontSize: "13px" }}
                    ></i>
                  ) : (
                    <i
                      class="fas fa-chevron-left"
                      style={{ fontSize: "13px" }}
                    ></i>
                  )}
                </button>
                <Card className="bg-transparent mb-0 shadow-none Service-order">
                  {actionsLoading && <ModalProgressBar />}
                  <CardBody className="pt-0 px-0">
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                      <li className="nav-item">
                        <a
                          className={`nav-link font-weight-bolder font-size-h6  active`}
                          data-toggle="tab"
                          role="tab"
                          aria-selected={"basic".toString()}
                        >
                          Service Order
                        </a>
                      </li>
                    </ul>
                    <div className="mt-md-7">
                      {values.ModeName !== null &&
                      values.ModeName !== undefined &&
                      values.ModeName !== "" ? (
                        <>
                          {values.ModeName === "LTL" ||
                          values.ModeName === "Reefer LTL" ? (
                            <ServiceOrderLtlDetail
                              setFieldValue={setFieldValue}
                              values={values}
                              carriers={ViewModelOptions?.LtlCarriers}
                              setVendorPayables={setVendorPayables}
                              vendorPayables={vendorPayables}
                              setLoaderSo={setLoaderSo}
                              setCharges={setCharges}
                              charges={charges}
                              ReloadCalc={ReloadCalc}
                              loaderSo={loaderSo}
                              actionsLoading={actionsLoading}
                              product={productForEdit || initProduct}
                              btnRef={btnRef}
                              ModeSO={values.ModeName}
                              SO={SO}
                              saveProduct={saveProduct}
                              getAssignment={getAssignment}
                              initProduct={initProduct}
                              setinitProduct={setinitProduct}
                              ViewModelOptions={ViewModelOptions}
                              GetViewModel={GetViewModel}
                              GetSoData={GetSoData}
                            />
                          ) : (
                            <ServiceOrderFtlDetail
                              loaderSoStop={loaderSoStop}
                              setLoaderSoStop={setLoaderSoStop}
                              setFieldValue={setFieldValue}
                              setVendorPayables={setVendorPayables}
                              vendorPayables={vendorPayables}
                              setCharges={setCharges}
                              charges={charges}
                              ReloadCalc={ReloadCalc}
                              values={values}
                              actionsLoading={actionsLoading}
                              product={productForEdit || initProduct}
                              btnRef={btnRef}
                              SO={SO}
                              saveProduct={saveProduct}
                              getAssignment={getAssignment}
                              initProduct={initProduct}
                              setinitProduct={setinitProduct}
                              ViewModelOptions={ViewModelOptions}
                              GetViewModel={GetViewModel}
                              GetSoData={GetSoData}
                              setShowLoadBidsModal={setShowLoadBidsModal}
                              ShowLoadBidsModal={ShowLoadBidsModal}
                            />
                          )}
                        </>
                      ) : (
                        <Fragment>
                          <div
                            className="card mb-md-8"
                            style={{ height: "300px" }}
                          >
                            <div className="card-body">
                              <Typography variant="Error Occured">
                                {values.ModeName == null ||
                                values.ModeName === undefined ||
                                values.ModeName === "" ? (
                                  <Fragment>
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                  </Fragment>
                                ) : (
                                  "h1"
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className="card mb-md-8"
                            style={{ height: "300px" }}
                          >
                            <div className="card-body">
                              <Typography variant="">
                                {values.ModeName == null ||
                                values.ModeName === undefined ||
                                values.ModeName === "" ? (
                                  <Fragment>
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                  </Fragment>
                                ) : (
                                  "h1"
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className="card mb-md-8"
                            style={{ height: "300px" }}
                          >
                            <div className="card-body">
                              <Typography variant="">
                                {values.ModeName == null ||
                                values.ModeName === undefined ||
                                values.ModeName === "" ? (
                                  <Fragment>
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                  </Fragment>
                                ) : (
                                  "h1"
                                )}
                              </Typography>
                            </div>
                          </div>
                          <div
                            className="card mb-md-8"
                            style={{ height: "300px" }}
                          >
                            <div className="card-body">
                              <Typography variant="">
                                {values.ModeName == null ||
                                values.ModeName === undefined ||
                                values.ModeName === "" ? (
                                  <Fragment>
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                    <Skeleton
                                      animation="wave"
                                      width="100%"
                                      className="mb-5"
                                    />
                                  </Fragment>
                                ) : (
                                  "h1"
                                )}
                              </Typography>
                            </div>
                          </div>
                        </Fragment>
                      )}
                    </div>
                  </CardBody>
                </Card>
                <Card
                  className="bg-transparent mt-0 shadow-none header-detail"
                  style={{ float: "right" }}
                >
                  <CardHeader>
                    <CardHeaderToolbar className="pl-0 align-content-end">
                      <button
                        type="submit"
                        disabled={LoadingPost}
                        className="btn btn-primary ml-2"
                        onClick={handleSubmit}
                      >
                        Save
                        {LoadingPost && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                      <button
                        className="btn btn-warning ml-2"
                        onClick={() => {
                          setModalActivities(true);
                        }}
                      >
                        Activities
                      </button>
                    </CardHeaderToolbar>
                  </CardHeader>
                </Card>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}
