// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal, Form, Alert, Accordion, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import { SvgIcon, Grid, Typography, Icon } from "@material-ui/core";
import clsx from "clsx";
import axios from "axios";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {

  const [customersList, setcustomersList] = useState([]);
  const [wareHousesList, setwareHousesList] = useState([]);

  useEffect(() => {
    fetchOrderAsync();
  }, []);
  const fetchOrderAsync = async () => {
    try {
      const result = await axios.get("api/FindOrder/GetAllApi");
      setcustomersList(result.data["customersList"]);
      setwareHousesList(result.data["warhousesList"]);
    } catch (e) { }
  };

  return (
    <>
      <Accordion defaultActiveKey="0">
        <Formik
          enableReinitialize={true}
          initialValues={customer}
          validationSchema={CustomerEditSchema}
          onSubmit={(values) => {
            saveCustomer(values);
          }}
        >
          {({ handleSubmit }) => (
            <>
              <Modal.Body>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}

                <Form className="form form-label-right">

                  <Accordion.Toggle as={Button} className="w-100 text-left p-0 position-relative text-decoration-none" variant="link" eventKey="0">
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">ORDERS BASICS</h5>
                    </Alert>
                    <Icon className={clsx("fa fa-chevron-down position-absolute text-white")} style={{ fontSize: 17, right: '1rem', top: '20%' }} />
                  </Accordion.Toggle>



                  <Accordion.Collapse eventKey="0">

                    <div>
                      <div className="card">
                        <h6 className="p-5 text-primary">ORDER INFORMATION</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className="col-lg-4">
                            <Select name="CustomerId" label="Cell Carrier">
                                <option>Select</option>
                                {customersList.map((item, index) => (
                                  <option value={item.Id}>{item.Name}</option>
                                ))}
                              </Select>
                            </div>
                            <div className="col-lg-4">
                            <Select name="WarehouseId" label="Cell Carrier">
                                <option>Select</option>
                                {wareHousesList.map((item, index) => (
                                  <option value={item.Id}>{item.Name}</option>
                                ))}
                              </Select>
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="ReferenceNumber"
                                component={Input}
                                placeholder="Reference Number"
                                label="Reference Number"
                              />
                            </div>
                          </div>


                        </div>
                      </div>

                    </div>

                  </Accordion.Collapse>


                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
              </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
              </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Accordion>
    </>
  );
}






