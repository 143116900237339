import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Select from "react-select";
import { Notification } from "../../modules/Notification";
import { ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
import LinearProgress from "@material-ui/core/LinearProgress";
import { matchPath } from "react-router";
import CreatableSelect from "react-select/creatable";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function ImportUsersPage() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(3),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    row: {
      height: 50,
    },
    rowBody: {
      height: 50,
      fontWeight: "bold",
    },
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
  }));
  const classes = useStyles();
  const [rowsState, setRowsState] = useState([]);
  const [rowsStatBool, setRowsStatBool] = useState(false);
  const [rowsEmpty, setRowsEmpty] = useState(true);
  const [importInProgress, setImportInProgress] = useState(false);
  const [showProgress, setshowProgress] = useState(false);
  const [loading, setLoading] = useState(false);

  const fileHandlerOnclick = () => {
    if (rowsEmpty) {
      Notification("error", "Please select a valid file to import.");
      setRowsStatBool(false);
      return false;
    }
    setRowsStatBool(true);
  };

  const fileClear = (event) => {
    event.target.value = "";
  };

  const fileHandler = (event) => {
    setRowsStatBool(false);
    setRowsState([]);
    setRowsEmpty(false);
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setRowsEmpty(true);
        return false;
      } else {
        if (resp.rows.length < 1) {
          setRowsEmpty(true);
          return false;
        }
        var a = resp.rows[0].indexOf("Name");
        var b = resp.rows[0].indexOf("AccountName*");
        var c = resp.rows[0].indexOf("Email*");
        var d = resp.rows[0].indexOf("Password*");
        var e = resp.rows[0].indexOf("PhoneNumber");
        var f = resp.rows[0].indexOf("CellCarrier*");
        var g = resp.rows[0].indexOf("UserAccess*");
        var h = resp.rows[0].indexOf("Role*");
        var i = resp.rows[0].indexOf("ClassicViewAccess*");
        var j = resp.rows[0].indexOf("IsActive*");
        if (
          a === -1 ||
          b === -1 ||
          c === -1 ||
          d === -1 ||
          e === -1 ||
          f === -1 ||
          g === -1 ||
          h === -1 ||
          i === -1 ||
          j === -1
        ) {
          setRowsEmpty(true);
          return false;
        }

        let rows = [];
        resp.rows.slice(1, resp.rows.length).forEach((element, index) => {
          var empty = 1;
          var error = "";
          if (
            element[b] === undefined ||
            element[c] === undefined ||
            element[d] === undefined ||
            element[e] === undefined ||
            element[f] === undefined ||
            element[g] === undefined ||
            element[h] === undefined ||
            element[i] === undefined ||
            element[j] === undefined 
          ) {
            empty = 0;
            error = "Please check the required fields*.";
          }

          if (element[b] === "" || element[b] === undefined) {
            empty = 0;
            error = "Account Name is required";
          }
          if (element[c] === "" || element[c] === undefined) {
            empty = 0;
            error = "Email is required";
          }
          if (element[d] === "" || element[d] === undefined) {
            empty = 0;
            error = "Password is required";
          }
          if (element[h] === "" || element[h] === undefined) {
            empty = 0;
            error = "Role is required";
          }
          if (element[f] === "" || element[f] === undefined) {
            empty = 0;
            error = "Cell Carrier is required";
          }
          if (element[g] === "" || element[g] === undefined) {
            empty = 0;
            error = "User Access is required";
          }
          if (element[j] === "" || element[j] === undefined) {
            empty = 0;
            error = "Is Active is required";
          }
          if (element[a] !== undefined)
            rows.push(
              createData(
                empty,
                index + 1,
                element[a],
                element[b],
                element[c],
                element[d],
                element[e],
                element[f],
                element[g],
                element[h],
                element[i],
                element[j],
                error
              )
            );
        });
        setRowsState(rows.reverse());
        setRowsEmpty(false);
      }
    });
  };

  const fileUploadeOnclick = async () => {
    setImportInProgress(true);
    setLoading(true);
    if (rowsStatBool) {
      if (rowsState.length > 0) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var requestOptions = {
          method: "post",
          withCredentials: true,
          headers: myHeaders,
          body: JSON.stringify({
            usersList: rowsState,
          }),
        };
        fetch(
          `${window.$apiurl}/users/ImportUsers`,
          requestOptions
        )
          .then(async (response) => {
            const statusCode = response.status;
            let data = {};
            if (statusCode === 200) {
              Notification("success", "Import done successfully.");
            } else {
              data = await response.json();
              const msg =
                data?.Message === undefined
                  ? "Something went wrong, please try later or contact the support."
                  : data.Message;
              Notification("error", msg);
            }
          })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Notification("error", "Please select a valid file to emport.");
      }
    } else {
      Notification("error", "Please select a valid file to import.");
    }
    setLoading(false);
    setImportInProgress(false);
  };

  function createData(
    Empty,
    Index,
    Name,
    AccountName,
    Email,
    Password,
    PhoneNumber,
    CellCarrier,
    UserAccess,
    Role,
    ClassicViewAccess,
    IsActive,
    Error
  ) {
    return {
      Empty,
      Index,
      Name,
      AccountName,
      Email,
      Password,
      PhoneNumber,
      CellCarrier,
      UserAccess,
      Role,
      ClassicViewAccess,
      IsActive,
      Error,
    };
  }

  function TryParseInt(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }

  function TryParseFloat(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseFloat(str);
        }
      }
    }
    return retValue;
  }
  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Import </Form.Label>
              <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                <Form.Control
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={fileHandler.bind(this)}
                  onClick={fileClear.bind(this)}
                />
              </Card>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="text-dark font-weight-bold">
                IMPORT FILE
              </Form.Label>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>
                  Use the 'Browse...' button to select a Users import file.{" "}
                  <a
                    href="https://staging-orders.empowerdatalogistics.com/Uploads/ImportTemplates/Import Users Template.xlsx"
                    download
                    target="_blank"
                  >
                    Download Template
                  </a>
                </p>
              </div>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>Then press the 'Import' button to read the file.</p>
              </div>

              <Button
                as="input"
                type="submit"
                color="secondary"
                readOnly
                value="Import"
                className="btn btn-primary btn-shadow-hover mr-2"
                onClick={() => {
                  fileHandlerOnclick();
                }}
              />
            </Form.Group>
          </Form.Row>
          {rowsStatBool && (
            <Form.Row className="mt-5 mb-5 pb-5">
              <Paper className={classes.paper}>
                <Table className={classes.table} size="small">
                  <TableHead className="bg-dark">
                    <TableRow className={classes.row}>
                      <TableCell className="text-white">
                        <b>Is Valid</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Name</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Account Name*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Email*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Password*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Phone Number*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Cell Carrier*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>User Access*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Role*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Classic View Access*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Is Active*</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsState.map((row) => (
                      <TableRow className={classes.rowBody} key={row.Index}>
                        <TableCell>
                          {row.Empty === 1 && (
                            <i
                              data-toggle="tooltip"
                              title="Valid"
                              className="fa fa-check text-success icon-xxl"
                            ></i>
                          )}
                          {row.Empty === 0 && (
                            <i
                              data-toggle="tooltip"
                              title={row.Error}
                              className="fa fa-times-circle text-danger icon-xxl"
                            ></i>
                          )}
                        </TableCell>
                        <TableCell>{row.Name}</TableCell>
                        <TableCell>{row.AccountName}</TableCell>
                        <TableCell>{row.Email}</TableCell>
                        <TableCell>{row.Password}</TableCell>
                        <TableCell>{row.PhoneNumber}</TableCell>
                        <TableCell>{row.CellCarrier}</TableCell>
                        <TableCell>{row.UserAccess}</TableCell>
                        <TableCell>{row.Role}</TableCell>
                        <TableCell>{row.ClassicViewAccess}</TableCell>
                        <TableCell>{row.IsActive}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div className="text-center col-12">
                {" "}
                <b>
                  {rowsState.length > 0
                    ? rowsState.length + " Items(s)"
                    : "No item was imported"}
                  .
                </b>
              </div>
            </Form.Row>
          )}{" "}
          <Form.Row>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="text-dark font-weight-bold">
                Create
              </Form.Label>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>
                  Use the 'Browse...' button to select a Users import
                  file.
                </p>
              </div>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>Press the 'Create' button to create the Users.</p>
              </div>

              <button
                type="button"
                style={{ width: "10.5%" }}
                onClick={() => {
                  fileUploadeOnclick();
                }}
                disabled={importInProgress}
                className="btn btn-success btn-shadow-hover mr-2"
              >
                <span>{importInProgress ? "Importing..." : "Create"}</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              {/* <Button
                as="input"
                value={importInProgress ? "Importing..." : "Create"}
                type="submit"
                readOnly
                disabled={importInProgress}
                className="btn btn-success btn-shadow-hover mr-2"
                onClick={() => {
                  fileUploadeOnclick();
                }}
              /> */}
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
