import React from 'react'
import { Button, Form, Card, Col, Dropdown} from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';

export function PurchaseOrderProgressReport({ history }) {
    const useStyles = makeStyles(theme => ({
        button: {
            margin: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
    }));
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const backToPurchaseOrderReports = () => {
        history.push(`/PurchaseOrderReports`);
    };

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <div className="col-lg-4">
                                <Form.Label>Choose a Purchase Order ID</Form.Label>
                                <div class="input-group">
                                    <input type="text" class="form-control" aria-label="Text input with dropdown button" />
                                </div>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <div className="col-lg-4">
                                <i>- or -</i>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <div className="col-lg-4">
                                <Form.Label>Choose a Purchase Order Number</Form.Label>
                                <div class="input-group">
                                    <input type="text" class="form-control" aria-label="Text input with dropdown button" />
                                </div>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <div className="col-lg-4">
                                <Form.Label>Report Definition</Form.Label>
                                <Dropdown className="form-control p-0">
                                    <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                        Default
                            </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form.Group>
                    </Form.Row>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <div className="col-lg-4 pt-5">
                                <Button variant="contained" color="secondary" className="btn btn-success btn-shadow-hover mr-2" href="#">
                                    Run Report
                            </Button>
                                <Button variant="contained" color="secondary" onClick={backToPurchaseOrderReports} className="btn btn-success btn-shadow-hover mr-2">
                                    Close
                            </Button>
                            </div>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card >
    )
}
