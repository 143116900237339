import React, { useMemo, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { CustomersGrouping } from "../customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "../CustomersUIContext";
import * as Yup from "yup";
import {
  Input,
  Select,
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Notification } from "../../../../Notification";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  Name: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Service is required"),
});
function createData(name, lenght, Lenght, width) {
  return { name, lenght, width, Lenght, width };
}
const eventChange = new Event("change", { bubbles: true });
function eventChangefunction(id) {
  const element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.dispatchEvent(eventChange);
    }, 500);
}
function SetValuefunction(id, value) {
  const element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
    }, 500);
}
const rows = [createData("US/IMPERIAL"), createData("METRIC")];

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const [externalservices, setexternalservices] = useState([]);
  const [Carrier, setCarrier] = useState([]);
  useEffect(() => {
    fetchUsersAsync();
  }, []);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get(
        "api/ServiceManageCarriers/externalservices"
      );
      setexternalservices(result.data["externalservices"] || []);
      setCarrier(result.data["Carrier"] || []);

      const serviceManageCarriers = window.ServiceManageCarriers || false;
      if (serviceManageCarriers) {
        SetValuefunction("CarrierId", serviceManageCarriers);
        eventChangefunction("CarrierId");
      }
    } catch (e) {}
  };
  useEffect(() => {
    if (customer.IsActive === true) {
      document.getElementById("IsActiveId").checked = true;
    }
  }, [customer]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          if (+values.CarrierId === 0) {
            Notification("error", "Carrier required is empty");
            return false;
          }
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Select name="CarrierId" id="CarrierId" label="Carrier">
                      <option value="0">Select</option>
                      {Carrier.map((item, index) => (
                        <option value={item.Id}>{item.Carrier}</option>
                      ))}
                    </Select>
                    {Carrier.length === 0 && <LinearProgress />}
                  </div>
                  <div className="col-lg-6">
                    {customersUIProps.ids.length > 0 && <CustomersGrouping />}
                    <Field
                      name="Name"
                      component={Input}
                      placeholder="Service"
                      label="Service Name"
                      required={true}
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <Select name="ExternalServiceId" label="External Service">
                      <option>Select</option>
                      {externalservices.map((item, index) => (
                        <option value={item.Id}>{item.Name}</option>
                      ))}
                    </Select>
                    {externalservices.length === 0 && <LinearProgress />}
                  </div>

                  <div className="col-lg-6 mt-5 pt-2">
                    <CheckboxLarge
                      label=" "
                      text="inactive"
                      name="IsActive"
                      id="IsActiveId"
                      onChange={(event) => {
                        setFieldValue("IsActive", event.target.checked);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
