// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { Select ,   DatePickerField , CheckboxLargeWB} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from "@material-ui/core/LinearProgress";
import { Notification } from "../../../../Notification";
import $ from "jquery";
import TextField from "@material-ui/core/TextField";

// Validation schema
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '2rem'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  col4Check :{
    placeSelf: 'center',
    paddingTop: '1.5rem'
  }
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const classes = useStyles();
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      // Code: Yup.string()
      //   .min(3, "Minimum 3 symbols")
      //   .max(50, "Maximum 50 symbols")
      //   .required("Code is required"),

      // CustomerId: Yup.string()
      //   .required("Customer is required"),

      // BillingTypeId: Yup.string()
      //   .required("BillingType is required"),

      // Rate: Yup.string()
      //   .required("Rate is required"),

      // Name: Yup.string()
      //   .min(3, "Minimum 3 symbols")
      //   .max(50, "Maximum 50 symbols")
      //   .required("Name is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  useEffect(() => {
    fetchAsync();
  }, []);
  useEffect(() => {
    if(customer.DateStart){
      $("#StartDate").val(customer.DateStart);
    }
    if(customer.DateEnd){
      $("#EndDate").val(customer.DateEnd);
    }
    if(customer.IsQuickAdd){
        $("#IsQuickAdd").attr("checked",true);
    }else{
      $("#IsQuickAdd").attr("checked",false);
    }
  }, [customer]);
  const [customersList, setCustomers] = useState([]);
  const [showProgress, setshowProgress] = useState(false);
  const fetchAsync = async () => {
    try {
      setshowProgress(true);
      const result = await axios.get("api/ManageRates/GetAllApi");
      setCustomers(result.data["Customers"]);
      setshowProgress(false);
    } catch (e) { }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          if (
            +values.Code === "" ||
            values.Code === undefined ||
            +values.CustomerId === 0 ||
            values.CustomerId === undefined ||
            +values.BillingTypeId === 0 ||
            values.BillingTypeId === undefined ||
            +values.Rate === "" ||
            values.Rate === undefined ||
            +values.Name === "" ||
            values.Name === undefined
          ) {
            Notification("error", "Fields * is required");
            return false;
          }
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue ,handleBlur}) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Select
                      name="CustomerId"
                      label="Customer"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setFieldValue("CustomerId", e.target.value);
                      }}
                    >
                      <option value="">Select</option>
                      {customersList.map((item, index) => (
                        <option value={item.id}>{item.CompanyName}</option>
                      ))}
                    </Select>
                    {showProgress && <LinearProgress />}
                  </div>
                  <div className="col-lg-4">
                      <Select
                        label="Billing Type"
                        name="BillingTypeId"
                        component={Input}
                        placeholder="BillingType"
                        margin="normal"
                      >
                        <option value="">Select</option>
                        <option value="1">Receiving </option>
                        <option value="2">Shipping</option>
                      </Select>
                  </div>
                  <div className={"col-lg-4 " + classes.col4Check}>
                    <CheckboxLargeWB
                      className="font-size-md"
                      text="IsQuickAdd"
                      name="IsQuickAdd"
                      id="IsQuickAdd"
                      onChange={(event) => {
                        setFieldValue("IsQuickAdd", event.target.checked);
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="Rate"
                      component={Input}
                      type="number"
                      placeholder="Rate"
                      label="Rate"
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="Name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-6">
                    <TextField
                        id="StartDate"
                        name="DateStart"
                        label="Date Start"
                        type="datetime-local"
                        defaultValue=""
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event) => {
                          setFieldValue("DateStart", event.target.value);
                        }}
                  />
                  </div>
                  <div className="col-lg-6">
                  <TextField
                        id="EndDate"
                        name="DateEnd"
                        label="Date End"
                        type="datetime-local"
                        defaultValue=""
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true
                        }}
                        onChange={(event) => {
                          setFieldValue("DateEnd", event.target.value);
                        }}
                  />
                    </div>
                </div>
                
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Field
                      name="Code"
                      component={Input}
                      placeholder="Code"
                      label="Code"
                    />
                  </div>
                  <div className="col-lg-6">
                    <Field
                      name="Description"
                      component={Input}
                      placeholder="Description"
                      label="Description"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
