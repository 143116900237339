import React, { useEffect, useState } from "react";
import QrReader from "react-qr-scanner";
import { Modal } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { Helmet } from "react-helmet";
import { Notification } from "../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

const qrHtml = (text, font, color) => (
  <>
    <i
      className={`fas fa-${font} position-absolute text-${color}`}
      style={{
        fontSize: "30px",
        fontSize: "19vw",
        opacity: "0.2",
        top: "40%",
        left: "51%",
        transform: "translate(-50% , -50%)",
      }}
    />
    <h1 style={{ marginTop: "45vh" }}>{text}</h1>
  </>
);

export function PoToLocationV2() {
  const [ModelShow, setModelShow] = useState(false);
  const [Open1, setOpen1] = useState(false);
  const [Open2, setOpen2] = useState(false);
  const [then1, setthen1] = useState(false);
  const [then2, setthen2] = useState(false);

  function Reload() {
    setOpen1(false);
    setOpen2(false);
    setthen1(false);
    setthen2(false);
    setText1(qrHtml("Click here to Scan PO", "qrcode", "info"));
    setText2(qrHtml("Click here to Scan Location", "qrcode", "info"));
  }

  const [Text1, setText1] = useState(
    qrHtml("Click here to Scan PO", "qrcode", "info")
  );
  const [Text2, setText2] = useState(
    qrHtml("Click here to Scan Location", "qrcode", "info")
  );

  function handleScan(data, num) {
    if (num === 1 && data) {
      setOpen1(false);
      var text = JSON.parse(data.text);
      setthen1({ Id: text?.id, Name: text?.Name });
      setText1(qrHtml("PO # : " + text?.id, "check", "success"));
    }
    if (num === 2 && data) {
      setOpen2(false);
      var text = JSON.parse(data.text);
      setthen2({ Id: text?.id, Name: text?.Name });
      setText2(qrHtml("Location : " + text?.Name, "check", "success"));
    }
  }

  useEffect(() => {
    if (then1 && then2) setModelShow(true);
  }, [then1, then2]);

  function handleError(err) {
    console.error(err);
  }
  const previewStyle = {
    height: "53vh",
  };

  return (
    <>
      <Helmet>
        <title>eMPower | Purchase order ToLocation</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Modal size="lg" show={ModelShow}>
        <Modal.Body>
          <div className="h5 pt-5 mt-5 text-center">
            You are placing this PO #: {then1?.Name} in this location:{" "}
            {then2.Name}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={() => PostPotoLocation(then1?.Id, then2?.Id, setModelShow)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="card p-5">
        <div className="card-header font-weight-bold ">
          <h4>Purchase order ToLocation</h4>
          <i
            className={`fas fa-sync position-absolute`}
            style={{
              fontSize: "50px",
              top: "25px",
              right: "40px",
              cursor: "pointer",
            }}
            onClick={() => Reload()}
          />
        </div>
        <div className="card-body AssigntoOutboundId p-5">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div
                className="shadow-sm p-3 mb-5 bg-white rounded text-center border"
                onClick={() => setOpen1((x) => !x)}
                style={{
                  height: "56vh",
                  cursor: "pointer",
                }}
              >
                {Open1 ? (
                  <QrReader
                    delay={100}
                    style={previewStyle}
                    onError={handleError}
                    onScan={(e) => handleScan(e, 1)}
                  />
                ) : (
                  Text1
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div
                className="shadow-sm p-3 mb-5 bg-white rounded text-center border"
                onClick={() => setOpen2((x) => !x)}
                style={{
                  height: "56vh",
                  cursor: "pointer",
                }}
              >
                {Open2 ? (
                  <QrReader
                    delay={100}
                    style={previewStyle}
                    onError={handleError}
                    onScan={(e) => handleScan(e, 2)}
                  />
                ) : (
                  Text2
                )}
              </div>
            </div>

            <div className="col-12 text-center my-5"></div>
          </div>
        </div>
      </div>
    </>
  );
}

async function PostPotoLocation(ReceiptId, LocationId, setModelShow) {
  return await fetch(
    `${window.$apiurl}/receiptLocation/update?receiptId=${ReceiptId}&locationId=${LocationId}`,
    {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      setModelShow(false);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}
