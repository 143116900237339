import React, { useCallback, useEffect, useState } from "react";
import { ProductsPage } from "./modules/eMPower/pages/ManageTMS/ProductsPage";
import { CapacityBoardPageTab } from "./modules/eMPower/pages/CapacityBoard/CustomersPageTab";
import { TrackingLoadBoardPage } from "./modules/eMPower/pages/ManageTMS/TrackingLoadBaord/TrackingLoadBoard";
import { ReportingPortViewPage } from "./modules/eMPower/pages/ReportingPortView/ReportingPortView";
import { ShipmentsManagmentBetaPage } from "./modules/eMPower/pages/NewShipmentsBeta/CustomersPageTab";
import { OrderCreation } from "./modules/eMPower/pages/ManageTMS/Order-Creation/OrderCreation";
import { ProductTMSDetail } from "./modules/eMPower/pages/ManageTMS/product-detail/ProductDetail";
import { ProductMODetail } from "./modules/eMPower/pages/ManageTMS/master-order-detail/ProductDetail";
import { Dropdown } from "react-bootstrap";
import { DropdownCustomTo } from "../_metronic/_partials/dropdowns";
import { TmsWarehouseManagementPage } from "./modules/eMPower/pages/ManageTMS/TmsWarehouseManagement/TmsWarehouseManagement";
import { Link, useHistory } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "./modules/Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

const ListPagesForTabs = [
  "/Reporting/Report",
  "/TransportManagementSystems",
  /*  "/ShipmentsManagement", */
  "/TransportManagementSystems/CapacityBoard",
  "/TMS/TrackingBoard",
  "/TransportManagementSystems/0/OrderCreation",
  "/TransportManagementSystems/SoDetail/",
  /* "/WarehouseManagement", */
];

var listLocationHref = ["Global"];
export function SpecificTabs({ TablsOptionsList }) {
  const history = useHistory();

  useEffect(() => {
    GlobalInSo();
  }, []);

  function GlobalInSo() {
    if (
      window.location.pathname.includes("TransportManagementSystems/SoDetail/")
    ) {
      var idSo = document.querySelector(`.tabsGlobal.text-white`);
      history.push("/CustomerDashboard/GlobalDashboard");
      idSo.click();
    }
  }

  const [Selected, setSelected] = useState(
    ListPagesForTabs.some((v) => window.location.href.includes(v))
      ? window.location.pathname
      : "Global"
  );
  const [Tabs, setTabs] = useState(
    ListPagesForTabs.some((v) => window.location.href.includes(v))
      ? [...listLocationHref, window.location.pathname]
      : listLocationHref
  );

  useEffect(() => {
    window.activeTab = ListPagesForTabs.some((v) =>
      window.location.href.includes(v)
    )
      ? window.location.pathname
      : "Global";

    var gl = document.getElementById("GlobalTab");
    if (gl) gl.setAttribute("data-link", window.location.pathname);
  }, []);

  window.TabsIsExist = ListPagesForTabs;
  window.ExistUrl = window.location.pathname;
  useEffect(() => {
    var ele = document.querySelector(".tabsGlobal.text-white");
    if (ele && ele.innerText === "Global") {
      var gl = document.getElementById("GlobalTab");
      if (gl) gl.setAttribute("data-link", window.location.pathname);
    }

    if (!ListPagesForTabs.some((v) => window.location.href.includes(v))) {
      onSelectTab("Global");
    }
  }, [window.location.href]);

  const onclick = useCallback(() => {
    var createTabe = window.CreateTabe;
    if (
      !CheckIsRepeatTab(createTabe) &&
      window.TabsIsExist.includes(createTabe)
    ) {
      /* if (window.TabsLength >= 5) {
        Notification(
          "warning",
          "You have reached the limit of tab numbers : 5"
        );
        return;
      } */
      //Check id
      if (window.TabId) createTabe += "" + window.TabId;
      setTabs((x) => [...x, createTabe]);
      onSelectTab(createTabe);
    } else if (CheckIsRepeatTab(createTabe)) {
      if (window.TabId) createTabe += "" + window.TabId;
      onSelectTab(createTabe);
    } else {
      var gl = document.getElementById("GlobalTab");
      if (gl) gl.setAttribute("data-link", createTabe);
      window.ExistUrl = createTabe;
      onSelectTab("Global");
    }

    window.TabId = undefined;
    window.scrollTo(0, 0);
  }, [setTabs]);

  /*  useEffect(() => {
    window.TabsLength = Tabs.length;
  }, [Tabs]); */

  function SpaceBetweenChar(txt) {
    try {
      var a = txt.match(/[A-Z][a-z]+/g)?.join(" ");
      if (!a) return txt;
      else return a;
    } catch (error) {}
  }

  function CheckIsRepeatTab(txt) {
    if (window.TabId) txt += "" + window.TabId;

    txt = txt === "/TransportManagementSystems" ? "Load Board" : txt;
    var textRe = SpaceBetweenChar(txt?.split("/").pop());

    var listEles = document.querySelectorAll(".tabsGlobal");
    for (var i of listEles) {
      if (textRe === i.innerText) return true;
    }
    return false;
  }

  function onSelectTab(tab) {
    var gl = document.getElementById("GlobalTab");
    window.activeTab = tab;
    var l = tab === "Global" ? gl.getAttribute("data-link") : tab;
    if (!String(window.ExistUrl).includes("/General/Search")) {
      window.history.pushState(undefined, undefined, l);
    }
    setSelected(tab);
  }

  const RemoveTab = useCallback(
    (tab) => {
      var tabActive = document.querySelector(".tabsGlobal.text-white");
      var tabsGlobal = document.querySelector(`.tabsGlobal[data-tab="${tab}"]`);

      if (
        tabActive.getAttribute("data-tab") ===
        tabsGlobal.getAttribute("data-tab")
      ) {
        var last = tabsGlobal.previousElementSibling;
        last.click();
      }

      setTabs((x) => {
        return x.filter((x) => x !== tab);
      });
    },
    [setTabs]
  );

  useEffect(() => {
    document.getElementById("GlobalPage").style.display =
      Selected === "Global" ? "block" : "none";
  }, [Selected]);

  return (
    <div className="subheader-position">
      <div className="position-tap d-flex flex-wrap">
        <div className="overflow-tab">
          <button
            id="CreateTabe"
            type="button"
            className={"btn mx-1 btn-primary d-none"}
            onClick={() => onclick()}
          >
            +
          </button>
          <button
            id="ShowGlobalTab"
            type="button"
            className={"btn mx-1 btn-primary d-none"}
            onClick={() => {
              onSelectTab("Global");
            }}
          >
            +
          </button>
          {Tabs.map((tab, i) => (
            <button
              key={i}
              id={tab === "Global" && "GlobalTab"}
              data-tab={tab}
              type="button"
              className={
                "mr-2 rounded-top position-relative tabsGlobal text-" +
                (Selected === tab
                  ? "white p-3 border-top-0 border-right-0 border-left-0"
                  : "primary py-3 px-5 border-top-0 border-right-0 border-left-0")
              }
              style={{
                background: Selected === tab ? "rgb(54,153,255)" : "#E1F0FF",
                fontWeight: "bold",
                borderBottom: "4px solid #3699FF",
              }}
              onClick={() => onSelectTab(tab)}
            >
              {tab === "/TransportManagementSystems"
                ? "Load Board"
                : tab.includes("/TransportManagementSystems/SoDetail/")
                ? tab.split("/")[tab.split("/").length - 1]
                : SpaceBetweenChar(tab?.split("/").pop())}

              {tab !== "Global" && (
                <i
                  className={
                    "fas fa-times-circle  pl-2 " +
                    (Selected === tab ? "text-white" : "text-primary")
                  }
                  style={{ top: "-9px", right: "-10px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    RemoveTab(tab);
                    e.stopPropagation();
                  }}
                />
              )}
            </button>
          ))}
        </div>
        {!db.read().getState().User.IsCustomer && (
          <Dropdown
            className="dropdown-inline position-unset px-3 align-self-center"
            alignLeft
          >
            <Dropdown.Toggle
              className="btn btn-sm btn-icon btn-bg-light"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTo}
            >
              <i className="fas fa-plus text-dark"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-5">
                {TablsOptionsList?.map((item, index) => {
                  return (
                    <li className="navi-item" key={index}>
                      <Link
                        className="navi-link"
                        data-link={item.link}
                        to={JSON.parse(item.Options).url}
                        onClick={(e) => {
                          var url = JSON.parse(item.Options).url;
                          window.CreateTabe = url;
                          document.getElementById("CreateTabe").click();
                          if (CheckIsExist(url)) {
                            e.preventDefault();
                            return false;
                          }
                        }}
                      >
                        <span className="navi-text text-primary">
                          {item.Name}
                        </span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
      <div className="pt-10">
        {/* <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/ShipmentsManagement"}
          Component={<ShipmentsManagmentBetaPage />}
        /> */}
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/TransportManagementSystems/CapacityBoard"}
          Component={<CapacityBoardPageTab />}
        />
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/TransportManagementSystems"}
          Component={<ProductsPage />}
        />
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/Reporting/Report"}
          Component={<ReportingPortViewPage />}
        />
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/WarehouseManagement"}
          Component={<TmsWarehouseManagementPage />}
        />
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/TMS/TrackingBoard"}
          Component={<TrackingLoadBoardPage />}
        />
        <CheckComponent
          Tabs={Tabs}
          Selected={Selected}
          Name={"/TransportManagementSystems/0/OrderCreation"}
          Component={<OrderCreation MO={0} Mode={0} />}
        />
      </div>
      {/* SO Tabs */}
      {Tabs.filter((x) =>
        x.includes("/TransportManagementSystems/SoDetail/")
      ).map((x) => (
        <CheckComponent
          key={x}
          Tabs={Tabs}
          Selected={Selected}
          Name={x}
          Component={<ProductTMSDetailTab Selected={Selected} />}
          isId={true}
        />
      ))}
    </div>
  );
}

function CheckComponent({
  Tabs,
  Selected,
  Name,
  Component = <></>,
  isId = false,
}) {
  const [BoolTabs, setBoolTabs] = useState(false);
  const [Show, setShow] = useState(false);

  useEffect(() => {
    var isTabs = false;
    var isShow = false;

    if (isId) {
      isTabs = IncludesList(Tabs, Name);
      isShow = Selected.includes(Name);
    } else {
      isTabs = Tabs.includes(Name);
      isShow = Selected === Name;
    }

    setShow(isShow);
    setBoolTabs(isTabs);
  }, [Selected]);

  return (
    <>{BoolTabs && <div className={`${!Show && "d-none"}`}>{Component}</div>}</>
  );
}

function IncludesList(Tabs = [], Name) {
  var a = false;
  Tabs.forEach((element) => {
    if (element.includes(Name)) a = true;
  });
  return a;
}

function ProductTMSDetailTab({ Selected }) {
  const [Mo, setMo] = useState();
  const [So, setSo] = useState();
  useEffect(() => {
    try {
      var a = Selected.split("/")[Selected.split("/").length - 1];
      if (a) setSo(+a);
    } catch (error) {}
  }, []);

  return (
    <div>
      {So && (
        <>
          {Mo ? (
            <ProductMODetail MO={Mo} setMo={setMo} />
          ) : (
            <ProductTMSDetail SO={So} setMo={setMo} />
          )}
        </>
      )}
    </div>
  );
}

const urlTMS = [
  "Global",
  "/TransportManagementSystems",
  "/Reporting/Report",
  "/TransportManagementSystems/CapacityBoard",
  "/TMS/TrackingBoard",
  /*  "/ShipmentsManagement", */
  "/TMS/TrackingBoard",
  "/TransportManagementSystems/SoDetail",
];

export function CheckUrlTMS() {
  var a = false;
  urlTMS.forEach((element) => {
    if (window.activeTab.includes(element)) a = true;
  });
  return a;
}

const CheckIsExist = (url) => {
  if (window.TabsIsExist.includes(url)) {
    return true;
  } else return false;
};

export function ClickFun(e, url, id) {
  window.CreateTabe = url;
  window.TabId = id;
  document.getElementById("CreateTabe").click();

  if (CheckIsExist(url)) {
    e.preventDefault();
    return false;
  }
}
