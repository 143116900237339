import React from "react";
import { Notification } from "../../../../../Notification";

export function MoveColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `label label-lg label-light-primary label-inline w-25 mr-2`;
  };
  const OnClickActive = (num) => {
    fetch(
      `${window.$apiurl}/api/CarrierServices/Move?id=${row.id}&moveUp=${num}`
    )
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        document.getElementById("ReLoadTableId").click();
        Notification("success", "success");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      <span
        className={getLabelCssClasses()}
        style={{ cursor: "pointer" }}
        onClick={() => OnClickActive("true")}
      >
        <i class="fas fa-chevron-up text-primary"></i>
      </span>

      <span
        className={getLabelCssClasses()}
        style={{ cursor: "pointer" }}
        onClick={() => OnClickActive("false")}
      >
        <i class="fas fa-chevron-down text-primary"></i>
      </span>
    </>
  );
}
