import React, { useEffect, useState } from "react";
import { Modal, Form, Card, Alert, Accordion, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import * as Yup from "yup";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  Input,
  Select,
} from "../../../_metronic/_partials/controls";
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: '15px',
    fontWeight: theme.typography.fontWeightRegular,
  },
  fieldGroup: {
    border: '1px solid lightGray',
    padding: '1em'
  },
  h6Text: {
    color: 'gray'
  },
  headerAcc: {
    backgroundColor: '#3699FF',
    color: '#ffffff',
    borderRadius: '1rem'
  },
  svgIcon: {
    color: '#ffffff',
    fontSize: '22px !important'
  }
}));
export function EditCarrierServices() {
  const classes = useStyles();

  return (
    <Card>
      <Card.Body>
        <div className={classes.root}>
          <ExpansionPanelDetails>
            <Typography>
              <div className="row">
                <div className="col-lg-12">
                  <h6 className={classes.h6Text}>Edit Carrier Services</h6>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={classes.fieldGroup}>
                        <div className="row">
                          <div className="col-lg-10">
                            <TextField
                              label="Carrier"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              InputProps={{}}
                            />
                          </div>
                        </div>
                        <hr></hr>
                        <div className="row">
                          <div className="col-lg-10 mb-5">
                            <TextField
                              label="Service"
                              className={classes.textField}
                              margin="normal"
                              variant="outlined"
                              InputProps={{}}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-10">
                            <div className="d-flex align-items-center mb-5">
                              <span className="bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input type="checkbox" name="" onChange={() => { }} value="1" />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Inactive</p>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-button mb-4 mt-4">
                <button
                  type="button"
                  className="btn btn-success btn-shadow-hover mr-2"
                >
                  Save
                  </button>
                <button
                  type="button"
                  className="btn btn-success btn-shadow-hover mr-2"
                >
                  Cancel
                  </button>
              </div>
            </Typography>
          </ExpansionPanelDetails>
        </div>
      </Card.Body>
    </Card >
  )
}
