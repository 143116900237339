import React from "react";
import { Notification } from "../../../../../Notification";

export function StatusColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["success", "danger"];
  const CustomerStatusTitles = ["Active", "Inactive"];

  var isActive = row.Active ? 0 : 1;

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${CustomerStatusCssClasses[isActive]} label-inline`;
  };
  const OnClickActive = (event) => {
    const a = event.target.textContent === "Active" ? false : true;
    fetch(`${window.$apiurl}/Carriers/Activate?id=${row.id}&status=${a}`)
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        document.getElementById("ReLoadTableId").click();
        Notification("success", "success");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <span
      className={getLabelCssClasses()}
      onClick={OnClickActive}
      style={{ cursor: "pointer" }}
    >
      {CustomerStatusTitles[isActive]}
    </span>
  );
}
