import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import QueryBuilder, { formatQuery } from "react-querybuilder";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { ButtonToolbar } from "react-bootstrap";
import { QueryTable } from "./QueryTable";
import "react-querybuilder/dist/query-builder.scss";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../modules/Notification";
import low from "lowdb";
import { default as SelectMulti } from "react-select";
import { Spinner } from "react-bootstrap";
import { Button, Icon, ListItemIcon, ListItemText } from "@material-ui/core";
import { Export } from "../../../_metronic/_helpers/ATHelpers";
import {
  StyledMenu,
  StyledMenuItem,
  useStyles,
} from "../../modules/eMPower/pages/ReportingPortView/ReportingPortView";
import { NavLink } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function QueryBuilderComponent() {
  const [fields, setFields] = useState([]);
  const [ReportingData, setReportingData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [selected, setselected] = useState([]);
  const [OrderbyFields, setOrderbyFields] = useState([]);

  const [query, setQuery] = useState({
    combinator: "and",
    rules: [{ field: "ContainerId", operator: "=", value: "" }],
  });

  useEffect(() => {
    GetModel(setFields);
  }, []);

  return (
    <>
      <Helmet>
        <title>eMPower | Assign to Pallet</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>

      <div className="card mb-5">
        <div className="card-header font-weight-bold py-0 pt-5 pb-3">
          <h2 className="d-inline">Query Builder</h2>
          <ExportComponent ReportingData={ReportingData} />
        </div>

        <div className="card-body">
          <div className="mb-5">
            <label>Select Fields</label>
            <SelectMulti
              id="multiCustomersGet"
              isMulti
              name="multiCustomersGet"
              options={fields.map((x) => {
                return {
                  value: x.Name,
                  label: x?.Label,
                };
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(e) => {
                if (!e || e?.length === 0) {
                  setOrderbyFields([]);
                  return false;
                }
                /* ar list = [];
                e.forEach((item) => {
                  list.push(item);
                }); */
                setselected(e);
              }}
            />
          </div>

          {fields?.length > 0 && (
            <>
              <label>Where</label>
              <QueryBuilder
                fields={fields}
                query={query}
                onQueryChange={(q) => {
                  setQuery(q);
                }}
                translations={translations}
                controlClassnames={controlClassnames}
              />
            </>
          )}
          <div className="w-100 text-right">
            <button
              type="button"
              disabled={Loading}
              onClick={() => {
                var querySql = formatQuery(query, "sql");
                var obj = {
                  SelectFields: selected.map((item) => {
                    return item.value;
                  }),
                  GroupByFields: [],
                  OrderByFields: OrderbyFields,
                  WhereQuery: querySql,
                };
                PostModel(obj, setReportingData, setLoading);
              }}
              className="btn btn-success btn-elevate w-25 my-5 h5"
            >
              {Loading && (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  className="mr-5"
                />
              )}
              Build
            </button>
          </div>
          {ReportingData.length > 0 && (
            <QueryTable ReportingData={ReportingData} selected={selected} />
          )}

          {ReportingData.length === 0 && !Loading && (
            <>
              <Nodatafound />
            </>
          )}
        </div>
      </div>
    </>
  );
}

const translations = {
  addRule: {
    label: (
      <>
        <i className="fas fa-plus-circle mr-1" />
        Rule
      </>
    ),
  },
  addGroup: {
    label: (
      <>
        <i className="fas fa-plus-circle mr-1" />
        Group
      </>
    ),
  },
  removeRule: {
    label: <i className="fas fa-trash" />,
    title: "Remove rule",
  },
};

const controlClassnames = {
  ruleGroup: "bg-light-light border border-muted",
  addGroup: "btn btn-secondary mt-2 py-1 h5",
  addRule: "btn btn-primary mt-2 py-1 h5",
  cloneGroup: "btn btn-secondary btn-sm",
  cloneRule: "btn btn-secondary btn-sm",
  removeGroup: "btn btn-danger btn-sm",
  removeRule: "btn btn-danger mt-2 py-1 h5",
  combinators: "form-control w-25",
  fields: "form-control w-25",
  operators: "form-control w-25",
  value: "form-control",
};

function GetModel(setFields) {
  fetch(`${window.$apiurl}/QueryBuilder/GetViewModel`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      var list = res[1] ?? [];

      list = list.filter((x) => x.SelectEnabled);

      list.forEach((element) => {
        element.name = element.Name;
        element.label = element.Label;
      });

      setFields(list);
    })
    .catch((error) => {
      console.error(error);
    });
}

function PostModel(model, setReportingData, setLoading) {
  setReportingData([]);
  setLoading(true);
  fetch(`${window.$apiurl}/QueryBuilder/GetResult`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(model),
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        setReportingData(res[1]);
      } else {
        var msg = res[1]?.Message;
        if (msg) Notification("error", msg);
      }
      setLoading(false);
    })
    .catch((error) => {
      Notification("error", "error");
    });
}

function Nodatafound() {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 my-5 pt-5">
          <div className="container-fluid pt-5 text-center">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
            <p className="h1 ml-5 mt-5 d-inline" style={{ opacity: 0.3 }}>
              Data Not Found
            </p>
          </div>
        </div>
      ) : (
        <div className="col-lg-12 text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

function ExportComponent({ ReportingData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const classes = useStyles();

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    document.activeElement.blur();
    setAnchorEl(null);
  }

  return (
    <>
      <ButtonToolbar
        className="btn-text-primary position-absolute w-100"
        style={{ top: "16px", right: "0" }}
      >
        <Button
          style={{ right: "2%", position: "absolute" }}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="outlined"
          color="primary"
          onClick={handleClick}
        >
          Export as
          <Icon className={classes.rightIcon}>save</Icon>
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem
            onClick={() => {
              try {
                Export(ReportingData, "excel", `Query Builder`);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            <ListItemIcon>
              <i className="far fa-file-excel"></i>
            </ListItemIcon>
            <ListItemText primary="Excel" />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              try {
                Export(ReportingData, "csv", `Query Builder`);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            <ListItemIcon>
              <i className="fas fa-file-csv"></i>
            </ListItemIcon>
            <ListItemText primary="CSV" />
          </StyledMenuItem>
          <StyledMenuItem
            onClick={() => {
              try {
                Export(ReportingData, "json", `Query Builder`);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            <ListItemIcon>
              <i className="far fa-file-code"></i>
            </ListItemIcon>
            <ListItemText primary="Json" />
          </StyledMenuItem>
        </StyledMenu>
      </ButtonToolbar>
    </>
  );
}
