import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import { Modal } from "react-bootstrap";
import { default as JsNative } from "jquery";
import Checkbox from "@material-ui/core/Checkbox";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Item({
  item,
  SetTableShipment,
  removeId,
  CheckboxList,
  index,
  ShipmentID,
  selectedShip,
  SelectIDforPallet,
  CheckboxListId,
}) {
  const [ModelShow, setModelShow] = useState(false);
  const [stateItem, setstateItem] = useState(item);

  var tableElement, offset;
  function GetTale() {
    tableElement = JsNative(".TableComponent");
    /* offset = tableElement.offset(); */
  }

  useEffect(() => {
    setstateItem(item);
  }, [item]);

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    for (var i in tableElement) {
      var ele = tableElement.eq(i)?.find(".shadow-sm");
      var o = tableElement.eq(i).offset();
      if (CheckPosition(o, page)) {
        ele.addClass("bg-light-info");
      } else ele.removeClass("bg-light-info");
      ele.addClass("border border-info border-2");
    }
  }

  function handleStop(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      setModelShow(true);
    }
    ele.removeClass("bg-light-info");
    ele.removeClass("border border-info border-2");
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleSave() {
    /*  PostPallet(stateItem?.Id, ShipmentID).then(({ status, Message }) => {
      if (200 === status) { */
    Notification("success", "success : " + stateItem?.Id);
    SetTableShipment(stateItem);
    removeId(stateItem?.Id);
    /*   } else {
        Notification("error", Message?.toString());
      }
    }); */
  }

  return (
    <>
      {stateItem?.Items > 0 && (
        <div className={"col-xl-6 col-lg-12 col-sm-12 my-2 "}>
          <Modal show={ModelShow}>
            <Modal.Header closeButton>
              <Modal.Title>Pallet {stateItem?.Id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure to add this Pallet
              <b className="text-primary ml-1">({stateItem?.Id})</b> to Shipment
              <b className="text-primary ml-1">({stateItem?.ShipID})</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="light"
                className="btn btn-light btn-elevate mr-2"
                onClick={() => {
                  setModelShow(false);
                }}
              >
                Close
              </Button>
              <Button
                variant="primary"
                className="btn btn-primary btn-elevate"
                onClick={() => {
                  setModelShow(false);
                  handleSave();
                }}
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Draggable
            axis="both"
            handle=".handle"
            position={{ x: 0, y: 0 }}
            grid={[1, 1]}
            scale={1}
            onDrag={handleDrag}
            onStop={handleStop}
          >
            <div className={stateItem?.Items > 0 && "handle"}>
              <button
                type="button"
                className="btn btn-light-primary bg-light-primary text-primary w-100 p-0"
                style={{ height: "41px" }}
                onDoubleClick={() => {
                  if (
                    document.querySelector(
                      ".TableComponent" /*  + stateItem?.ShipID */
                    )
                  )
                    setModelShow(true);
                }}
              >
                <Checkbox
                  disabled={
                    CheckboxListId && CheckboxListId !== stateItem?.ShipID
                  }
                  onChange={(e) => {
                    CheckboxList(e.target.checked, stateItem?.Id);
                  }}
                  inputProps={{
                    "aria-label": "uncontrolled-checkbox",
                  }}
                />
                {CheckboxListId}
                {htmlP("PO", stateItem?.Id)} {/* //stateItem?.Id */}
                {htmlP("Unit Count", stateItem?.UnitCount)}
                {htmlP("Items", stateItem?.Items)}
                {htmlP("Weight", stateItem?.Weight?.toFixed(2))}
                {htmlP("Cube", stateItem?.Cube?.toFixed(2))}
                <button
                  type="button"
                  className={`bg-light-primary border-0 ml-3`}
                  onClick={() => {
                    SelectIDforPallet(stateItem?.Id);
                  }}
                >
                  <i
                    class="fas fa-info-circle"
                    style={{ fontSize: "23px", marginTop: "-5px" }}
                  ></i>
                </button>
              </button>
            </div>
          </Draggable>
        </div>
      )}
    </>
  );
}

function htmlP(text, text2) {
  return (
    <>
      <p type="button" className={`btn btn-primary px-1 py-0 mt-3 ml-5 mr-1`}>
        {text}
      </p>
      {text2}
    </>
  );
}

async function PostPallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/addpallettoshipment`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ShipmentId: ShipmentID,
      PalletId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}
