import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import { Modal } from "react-bootstrap";
import { Notification } from "../../../../modules/Notification";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import Checkbox from "@material-ui/core/Checkbox";
import { default as JsNative } from "jquery";
import low from "lowdb";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Item({
  item,
  SetTableShipment,
  ShipmentID,
  CheckboxList,
  CheckboxListIds,
  POChaekced,
}) {
  const [ModelShow, setModelShow] = useState(false);
  const [stateItem, setstateItem] = useState();
  const [Quantity, setQuantity] = useState(0);
  const [Color, setColor] = useState("primary");

  const [Checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(CheckboxListIds.includes(item?.Id));
  }, [CheckboxListIds]);

  var tableElement = JsNative(".TableComponent");
  var offset = tableElement.offset();

  useEffect(() => {
    if (item?.PoBar) item.Id = 0;
    setQuantity(item?.LeftQuantity);
    setstateItem(item);
    if (
      item?.DamagedQuantity > 0 ||
      item?.OverQuantity > 0 ||
      item?.ShortQuantity > 0
    )
      setColor("warning");
    else if (item.LeftQuantity === 0) setColor("dark");
  }, [item]);

  function handleDrag(e) {
    const style = "background: #E5EAEE !important";
    if (offset.top < e.pageY && e.pageY < offset.top + tableElement.height())
      tableElement.find("th").attr("style", style);
    else {
      tableElement.find("th").removeAttr("style");
    }
  }

  function handleStop(e) {
    if (offset.top < e.pageY && e.pageY < offset.top + tableElement.height()) {
      setModelShow(true);
      tableElement.find("th").removeAttr("style");
    }
  }

  function handleSave() {
    PostPallet(stateItem?.Id, ShipmentID, +Quantity).then(
      ({ status, Message, Id }) => {
        if (200 === status && Id) {
          Notification("success", "success : " + stateItem?.Id);
          var thisItem = stateItem;
          thisItem.LeftQuantity -= +Quantity;
          thisItem.IdShip = Id;
          setstateItem();
          SetTableShipment(thisItem, +Quantity);
          setTimeout(() => {
            setstateItem(thisItem);
            setQuantity(item.LeftQuantity);
          }, 1);
        } else {
          Notification("error", Message?.toString());
        }

        if (item.LeftQuantity === 0) setColor("dark");
      }
    );
  }

  function htmlP2(text2) {
    return (
      <>
        <b style={{ fontSize: "11px" }}>{text2}</b>
      </>
    );
  }

  return (
    <>
      <>
        <Modal show={ModelShow}>
          <Modal.Header closeButton>
            <Modal.Title>Item {stateItem?.Container}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-lg-4 text-left">
                <label>
                  Maximum Quantity
                  {Color === "warning" && (
                    <span>
                      <br />
                      (Includes
                      {stateItem?.OverQuantity > 0 && (
                        <b className="text-danger ml-2">O</b>
                      )}
                      {stateItem?.ShortQuantity > 0 && (
                        <b className="text-danger ml-2">S</b>
                      )}
                      {stateItem?.DamagedQuantity > 0 && (
                        <b className="text-danger ml-2">D</b>
                      )}
                      )
                    </span>
                  )}
                </label>
              </div>
              <div className="col-lg-8">
                <input
                  className="form-control text-center"
                  value={stateItem?.LeftQuantity}
                  disabled
                />
              </div>
              <div className="col-lg-4 mt-5 text-left">
                <label className="pt-3">Quantity to ship</label>
              </div>
              <div className="col-lg-8 mt-5">
                <input
                  id="QuantitytoshipId"
                  min="0"
                  className="form-control text-center"
                  defaultValue={Quantity}
                  type="number"
                  onChange={(e) => {
                    var value = e.target?.value ?? "";
                    if (value !== "" && value > 0) {
                      setQuantity(value);
                    }
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              className="btn btn-light btn-elevate mr-2"
              onClick={() => {
                setModelShow(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary btn-elevate"
              onClick={() => {
                setModelShow(false);
                handleSave();
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
        {stateItem?.PoBar ? (
          <>
            <div className="col-12 mt-5 mb-2">
              <br />

              <button
                type="button"
                className={`btn btn-light-secondary text-dark w-100 p-0 `}
              >
                <Checkbox
                  value="checkedC"
                  onChange={(e) => {
                    POChaekced(stateItem?.PurchaseOrder, e.target.checked);
                  }}
                  inputProps={{
                    "aria-label": "uncontrolled-checkbox",
                  }}
                />
                <b>Purchase Order : {stateItem?.PurchaseOrder}</b>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="col-xl-6 col-lg-6 col-md-12 my-2">
              <Draggable
                axis="y"
                handle=".handle"
                position={{ x: 0, y: 0 }}
                grid={[1, 1]}
                scale={1}
                onDrag={handleDrag}
                onStop={handleStop}
              >
                <div className={Color !== "dark" && "handle"}>
                  <button
                    disabled={Color === "dark"}
                    type="button"
                    className={`btn btn-light-${Color} bg-light-${Color} text-${Color} w-100 p-0`}
                    onDoubleClick={() => {
                      setModelShow(true);
                    }}
                  >
                    <div className="row px-3 pt-3 pb-0">
                      <div
                        className={`col-xl-3 col-lg-3 col-md-3 Class${stateItem?.PurchaseOrder}`}
                        style={{ marginTop: "-8px" }}
                      >
                        <Checkbox
                          checked={Checked}
                          disabled={Color === "dark"}
                          onChange={(e) => {
                            CheckboxList(e.target.checked, stateItem?.Id);
                          }}
                          inputProps={{
                            "aria-label": "uncontrolled-checkbox",
                          }}
                        />
                        {htmlP2(stateItem?.Sku ?? "(Sku Not Found)")}
                      </div>
                      <div className="col-xl-1 col-lg-1 col-md-1">
                        {htmlP2(stateItem?.LeftQuantity)}
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        {htmlP2(stateItem?.Size)}
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4">
                        {htmlP2(stateItem?.Color)}
                      </div>
                      <div className="col-xl-2 col-lg-2 col-md-2">
                        <OverlayTrigger
                          overlay={<Tooltip>{stateItem?.Description}</Tooltip>}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Info-circle.svg"
                            )}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </button>
                </div>
              </Draggable>
            </div>
          </>
        )}
      </>
    </>
  );
}

async function PostPallet(ItemId, PalletId, UnitCount) {
  return await fetch(`${window.$apiurl}/transload/shipment/item`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ReceiptItemId: ItemId,
      ShipmentId: PalletId,
      Quantity: UnitCount,
    }),
  }).then(async (response) => {
    var msg = await response.json();
    var a = {
      status: response.status,
      Message: msg?.Message ?? "",
      Id: msg?.Id,
    };
    return a;
  });
}
