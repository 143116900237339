/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useState, useEffect } from "react";
import { Link, Switch } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/login/login-1.scss";
import { Body } from "./Body";
import { Helmet } from "react-helmet";

export function Layout() {
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear())


  useEffect(() => {
    getYear();
  }, [])
  return (
    <>
      <Helmet>
        <title>eMPower | Welcome</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/demo-7.jpg")})`,
            }}
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between ">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 home-page">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl("/media/logos/logo-letter-1.png")}
                />
              </Link>
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <p className="font-weight-lighter text-white opacity-80">
                  Provides full integration of activity, status and costing
                  allowing our customers to make critical transportation
                  decisions faster, because they have access to the right
                  information, at the right time regardless of where the data
                  originates.
                </p>
              </div>
              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none d-lg-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                  &copy; {date} eMPower{" "}
                  <a
                    href={`/ReleaseNotes/${window.$BuildVersion}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    className="font-weight-bolder text-white"
                  >
                    {window.$BuildVersion}
                  </a>
                </div>
                <div className="d-flex">
                  <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link>
                </div>
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-0 overflow-hidden">
            {/* begin::Content body */}
            <div className="d-flex flex-column flex-center mt-0 mt-lg-0">
              <Switch>
                <Body />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-lg-none d-flex flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {date} eMPower{" "}
                <a
                  href={`/ReleaseNotes/${window.$BuildVersion}`}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="font-weight-bolder text-muted"
                >
                  {window.$BuildVersion}
                </a>
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div>
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
