import React, { useState, useEffect } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";

const MenuItem = ({ text, selected, wareHouse, id, Color }) => {
  return (
    <div
      className={`btn ${selected ? "OrdersClass btn-" + Color : ""} mx-3`}
      id={"id" + id}
    >
      {text}
    </div>
  );
};

const Menu = (list, selected, Color) =>
  list.map((el) => {
    const { EquipmentNumber, wareHouse, Id } = el;
    return (
      <MenuItem
        text={EquipmentNumber ?? Id}
        key={Id}
        selected={selected}
        wareHouse={wareHouse}
        id={Id}
        Color={Color}
      />
    );
  });

export function ScrollingMenuReceipt({
  list = [],
  setselectedId,
  Color = "info",
  Title = "",
}) {
  const Arrow = ({ text, className }) => {
    return <div className={className}>{text}</div>;
  };

  const ArrowLeft = Arrow({
    text: "",
    className: "fa fa-chevron-circle-left fSize2 text-" + Color,
  });
  const ArrowRight = Arrow({
    text: "",
    className: "fa fa-chevron-circle-right fSize2 text-" + Color,
  });

  const [selected, setselected] = useState();
  const menu = Menu(list, selected, Color);

  useEffect(() => {
    if (list?.length > 0) {
      var a = list[0]?.Id;
      onSelect(a);
    }
  }, [list]);

  const onSelect = (key) => {
    setselectedId(false);
    setTimeout(() => {
      setselectedId(key);
    }, 1);
    setselected(key);
  };

  return (
    <>
      <div className="alert alert-light row p-0">
        <div className={"col-xl-2 col-lg-2 col-md-2 my-2 p-3 text-" + Color}>
          <b>{Title}</b>
        </div>
        <div className="col-xl-10 col-lg-10 col-md-10 my-2">
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={onSelect}
            scrollToSelected={true}
          />
        </div>
      </div>
    </>
  );
}
