import moment from "moment";
import React, { useEffect, useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  sortCaret, toAbsoluteUrl
} from "../../../../../../../_metronic/_helpers";
import "./Style.css";
import {
  momentDate, momentDateOnly
} from "../../../../../../../_metronic/_helpers/ATHelpers";
import * as actions from "../../../../_redux/Transload/TransloadReceiving/remarksActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import { useRemarksUIContext } from "./RemarksUIContext";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../../Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);

export function RemarksTable() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      ids: remarksUIContext.ids,
      setIds: remarksUIContext.setIds,
      queryParams: remarksUIContext.queryParams,
      setQueryParams: remarksUIContext.setQueryParams,
      productId: remarksUIContext.productId,
      openEditRemarkDialog: remarksUIContext.openEditRemarkDialog,
      openDeleteRemarkDialog: remarksUIContext.openDeleteRemarkDialog,
    };
  }, [remarksUIContext]);

  var getDate = function(d) {
    if (d === null || d === "" || d === undefined) return "";
    var format = "MM/DD/YYYY, hh:mm A";
    if (
      moment(d)
        .startOf("day")
        .valueOf() === moment(d).valueOf()
    ) {
      format = "MM/DD/YYYY";
    }
    return moment(d).format(format);
  };

  // Getting curret state of products list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.Receiving }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    remarksUIProps.setIds([]);
    dispatch(
      actions.fetchRemarks(remarksUIProps.queryParams, remarksUIProps.productId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarksUIProps.queryParams, dispatch, remarksUIProps.productId]);


  const AcceptRejeted = (Id,Accept,POs) => {
    const values = {
      "Id": Id,
      "Accept": Accept,
      "POs": [POs],
    }
    fetch(window.$apiurl + `/receiptsManagement/acceptRejectModeServiceLevel`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
      },
      Allow: "PUT",
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((data) => [statusCode, data]);
        }
      })
      .then((data) => {
        if (data[0] === 200) {
          Notification("success", "Successfully Updated");
          dispatch(
            actions.fetchRemarks(remarksUIProps.queryParams, remarksUIProps.productId)
          );
        }
        else if (data[0] === 400 && data[1].Message) {
          Notification("error", data[1].Message);
        }
        else {
          Notification("error", "Something went wrong");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns = [
    {
      dataField: "id",
      text: "Ln",
      sort: true,
      formatter: (cell, row) => {
        return row?.IsRtvConfirmed === true ? (
          <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">RTV</Tooltip>}
          >
            <a href={() => false} className={`btn btn-light-danger px-2 py-1 mt-3 ml-1`}>
              {row.id}
            </a>
          </OverlayTrigger>
        ) : (
          row.id
        );
      },
      sortCaret: sortCaret,
    },
    {
      dataField: "PurchaseOrder",
      text: "P.O.",
      sort: true,
    },
    {
      dataField: "Upc",
      text: "Upc",
      sort: true,
    },
    {
      dataField: "Sku",
      text: "SkU",
      sort: false,
    },
    {
      dataField: "Pieces",
      text: "Case pack",
      sort: true,
    },
    {
      dataField: "UnitCount",
      text: "Unit Count",
      sort: false,
    },
    {
      dataField: "Color",
      text: "Color",
      hidden: window.$ItemsCusId === 2271 ? false : true,
      sort: false,
    },
    {
      dataField: "Size",
      text: "Size",
      hidden: window.$ItemsCusId === 2271 ? false : true,
      sort: false,
    },
    {
      dataField: "ProductType",
      text: "ProductType",
      hidden: window.$ItemsCusId === 2271 ? false : true,
      sort: false,
    },
    {
      dataField: "ReceivedQuantity",
      text: "Cartons Count",
      hidden: window.$ItemsCusId === 2271 ? false : true,
      sort: false,
    },
    {
      dataField: "PiecesField",
      text: "Pieces",
      sort: false,
      formatter: (cell, row) => {
        return <>{row?.UnitCount * row?.Pieces}</>;
      },
    },
    {
      dataField: "UnitOfMeasure",
      text: "UOM",
      sort: false,
    },
    {
      dataField: "Pallets",
      text: "Pallets ",
      sort: false,
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
    },
    {
      dataField: "Destination",
      text: "DC Location",
      sort: true,
    },
    {
      dataField: "Weight",
      text: "Weight",
      sort: false,
      formatter: (cell, row) => {
        return <>{row?.Weight?.toFixed(2)}</>;
      },
    },
    {
      dataField: "WeightType",
      text: "Type",
      sort: false,
    },
    {
      dataField: "Cube",
      text: "Cube",
      sort: true,
      formatter: (cell, row) => {
        return <>{row?.Cube?.toFixed(2)}</>;
      },
    },
    {
      dataField: "CubeType",
      text: "Type",
      sort: false,
    },
    {
      dataField: "Shipped",
      text: "Shipped",
      sort: false,
    },
    {
      dataField: "OverQuantity",
      text: "Ovr",
      sort: false,
    },
    {
      dataField: "ShortQuantity",
      text: "Shrt",
      sort: false,
    },
    {
      dataField: "AstrayQuantity",
      text: "Astray",
      sort: false,
    },
    {
      dataField: "ReceivedDate",
      text: "Received Date",
      sort: false,
      formatter: (cell, row) => {
        return <>{momentDateOnly(row.ReceivedDate)}</>;
      },
    },
    {
      dataField: "ProcessedDate",
      text: "Processed Date",
      sort: false,
      formatter: (cell, row) => {
        return <>{momentDateOnly(row.ProcessedDate)}</>;
      },
    },
    {
      dataField: "PoEta",
      text: "Po Eta",
      sort: false,
    },
    {
      dataField: "BuyerId",
      text: "Buyer Id",
      sort: false,
    },
    {
      dataField: "Department",
      text: "Department",
      sort: false,
    },
    {
      dataField: "PoEta",
      text: "Delivery ETA",
      sort: false,
    },
    {
      dataField: "PoExpectedDate",
      text: "RDD",
      sort: false,
      formatter: (cell, row) => {
        return (
          <>
            <span class="label text-primary p-1 m-0 updated">
              {momentDate(getDate(row?.PoExpectedDate))}
            </span>
          </>
        );
      },
    },
    {
      dataField: "ModeName",
      text: "Mode",
      sort: false,
    },
    {
      dataField: "ServiceLevelName",
      text: "Service Level",
      sort: false,
    },
    {
      dataField: "RequiredModeName",
      text: "Requested Mode",
      sort: false,
    },
    {
      dataField: "RequiredServiceLevelName",
      text: "Requested Service Level",
      sort: false,
    },
    {
      dataField: "ThirdPartyQuantity",
      text: "DropShip Quantity",
      sort: false,
    },
    {
      dataField: "ThirdPartyName",
      text: "DropShip Name",
      sort: false,
    },
    {
      dataField: "ReferenceNumber",
      text: "Reference N.",
      sort: false,
    },
    {
      dataField: "IsHot",
      text: "ALERTS",
      sort: true,
      formatter: (cell, row) => {
        return (
          <>
            {row.IsHot && (
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
              >
                <a className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/fire.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.IsHazardousMateriel && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    Item contains Hazardous materials
                  </Tooltip>
                }
              >
                <a className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/296395.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
          </>
        );
      },
    },
    {
      dataField: "DamagedQuantity",
      text: "Dmg",
      sort: false,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditRemarkDialog: remarksUIProps.openEditRemarkDialog,
        openDeleteRemarkDialog: remarksUIProps.openDeleteRemarkDialog,
        AcceptRejeted : AcceptRejeted,
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  try {
    const pageDropDownSelect = document.getElementById("pageDropDown");
    if (pageDropDownSelect) {
      pageDropDownSelect.onclick = function() {
        return false;
      };
    }
  } catch (err) {}
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center tableItemsRm"
        bordered={false}
        bootstrap4
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        pagination={paginationFactory({
          showTotal: true,
          sizePerPageList: [
            { text: "5", value: 5 },
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            {
              text: "All",
              value: entities?.length,
            },
          ],
        })}
      />
    </>
  );
}
