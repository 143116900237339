import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Select from "react-select";
import { Notification } from "../../modules/Notification";
import { ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
import LinearProgress from "@material-ui/core/LinearProgress";
import { matchPath } from "react-router";
import CreatableSelect from "react-select/creatable";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function ImportReceiptItems(history) {
  let receiptId;
  let customerId;
  const match = matchPath(history.location.pathname, {
    path: "/ReceiptsManagement/ImportItems/:id/:cs",
  });
  try {
    if (match.params) {
      receiptId = match.params?.id;
      customerId = match.params?.cs;
    }
  } catch (err) {
    console.log(err);
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(3),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    row: {
      height: 50,
    },
    rowBody: {
      height: 50,
      fontWeight: "bold",
    },
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
  }));
  const classes = useStyles();
  const [rowsState, setRowsState] = useState([]);
  const [rowsStatBool, setRowsStatBool] = useState(false);
  const [rowsEmpty, setRowsEmpty] = useState(true);
  const [receiptsList, setReceiptsList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [importInProgress, setImportInProgress] = useState(false);
  const [showProgress, setshowProgress] = useState(false);
  const [ReceiptIdValue, setReceiptId] = useState({
    value: "",
    label: "Select",
  });
  const [CustomerIdValue, setCustomerId] = useState({
    value: "",
    label: "Select",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCustomersAsync();
  }, []);
  const fetchCustomersAsync = async () => {
    try {
      const result = await axios.get("api/purchaseorders/getcustomers");
      if (result.status === 200) {
        setCustomersList(result.data);

        //fetchData Ids Selected
        var listCustomers = result.data;
        if (customerId) {
          var customer = listCustomers?.find(
            (x) => x.id === parseInt(customerId)
          );
          setCustomerId({ value: customer.id, label: customer.CompanyName });
          handleCustomerChange(customerId);
        }
        if (receiptId) {
          setReceiptId({ value: receiptId, label: receiptId });
        }
      }
    } catch (e) {}
  };
  const handleCustomerChange = async (event) => {
    setshowProgress(true);
    setReceiptsList([]);
    if (event !== "" && event !== null) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      await fetch(
        `${window.$apiurl}/receiptsmanagement/getreceiptsIds?CustomerId=${event}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var obj = JSON.parse(result);
          var list = obj.map((suggestion) => ({
            value: suggestion,
            label: suggestion,
          }));
          setReceiptsList(list);
        })
        .catch((error) => console.log("error", error));
    }
    setshowProgress(false);
  };
  const fileHandlerOnclick = () => {
    if (rowsEmpty) {
      Notification("error", "Please select a valid file to import.");
      setRowsStatBool(false);
      return false;
    }
    setRowsStatBool(true);
  };

  const fileClear = (event) => {
    event.target.value = "";
  };

  const fileHandler = (event) => {
    setRowsStatBool(false);
    setRowsState([]);
    setRowsEmpty(false);
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setRowsEmpty(true);
        return false;
      } else {
        if (resp.rows.length < 1) {
          setRowsEmpty(true);
          return false;
        }
        var a = resp.rows[0].indexOf("PurchaseOrder*");
        var b = resp.rows[0].indexOf("Sku");
        var c = resp.rows[0].indexOf("CasePack*");
        var d = resp.rows[0].indexOf("UnitOfMeasure*");
        var e = resp.rows[0].indexOf("UnitCount*");
        var f = resp.rows[0].indexOf("Weight*");
        var g = resp.rows[0].indexOf("WeightType*");
        var h = resp.rows[0].indexOf("Cube*");
        var i = resp.rows[0].indexOf("CubeType*");
        var j = resp.rows[0].indexOf("Description");
        var k = resp.rows[0].indexOf("OverQuantity");
        var l = resp.rows[0].indexOf("ShortQuantity");
        var m = resp.rows[0].indexOf("DamagedQuantity");
        var n = resp.rows[0].indexOf("Pallets");
        var o = resp.rows[0].indexOf("IsHot");
        var p = resp.rows[0].indexOf("IsHazMat");
        var q = resp.rows[0].indexOf("Location");
        var r = resp.rows[0].indexOf("UpcNumber");
        if (
          a === -1 ||
          b === -1 ||
          c === -1 ||
          d === -1 ||
          e === -1 ||
          f === -1 ||
          g === -1 ||
          h === -1 ||
          i === -1 ||
          j === -1 ||
          k === -1 ||
          l === -1 ||
          m === -1 ||
          n === -1 ||
          o === -1 ||
          p === -1 ||
          q === -1 ||
          r === -1
        ) {
          setRowsEmpty(true);
          return false;
        }

        let rows = [];
        resp.rows.slice(1, resp.rows.length).forEach((element, index) => {
          var empty = 1;
          var error = "";
          if (
            element[a] === undefined ||
            element[c] === undefined ||
            element[d] === undefined ||
            element[e] === undefined ||
            element[f] === undefined ||
            element[g] === undefined ||
            element[h] === undefined ||
            element[i] === undefined
          ) {
            empty = 0;
            error = "Please check the required fields*.";
          }
          if (TryParseInt(element[c]) < 0) {
            empty = 0;
            error = "Case pack must be a valid number";
          }
          if (TryParseInt(element[e]) < 0) {
            empty = 0;
            error = "Unit count must be a valid number";
          }
          if (TryParseFloat(element[f]) < 0) {
            empty = 0;
            error = "Unit count must be a valid number";
          }
          if (TryParseFloat(element[h]) < 0) {
            empty = 0;
            error = "Unit count must be a valid number";
          }
          if (element[c] === "" || element[c] === undefined) {
            empty = 0;
            error = "Case Pack is required";
          }
          if (element[e] === "" || element[e] === undefined) {
            empty = 0;
            error = "Unit Count is required";
          }
          if (element[f] === "" || element[f] === undefined) {
            empty = 0;
            error = "Weight is required";
          }
          if (element[h] === "" || element[h] === undefined) {
            empty = 0;
            error = "Cube is required";
          }
          if (
            element[k] !== "" &&
            element[k] !== undefined &&
            TryParseInt(element[k]) <= 0
          ) {
            empty = 0;
            error = "Over Quantity must be a valid number";
          }
          if (
            element[l] !== "" &&
            element[l] !== undefined &&
            TryParseInt(element[l]) <= 0
          ) {
            empty = 0;
            error = "Short Quantity must be a valid number";
          }
          if (
            element[m] !== "" &&
            element[m] !== undefined &&
            TryParseInt(element[m]) <= 0
          ) {
            empty = 0;
            error = "Damaged Quantity must be a valid number";
          }
          if (
            element[n] !== "" &&
            element[n] !== undefined &&
            TryParseInt(element[n]) <= 0
          ) {
            empty = 0;
            error = "Pallets must be a valid number";
          }
          if (element[a] !== undefined)
            rows.push(
              createData(
                empty,
                index + 1,
                element[a],
                element[b],
                element[c],
                element[d],
                element[e],
                element[f],
                element[g],
                element[h],
                element[i],
                element[j],
                element[k],
                element[l],
                element[m],
                element[n],
                element[o],
                element[p],
                element[q],
                element[r],
                error
              )
            );
        });
        setRowsState(rows.reverse());
        setRowsEmpty(false);
      }
    });
  };

  const fileUploadeOnclick = async () => {
    setImportInProgress(true);
    setLoading(true);
    const receiptId = ReceiptIdValue.value;
    if (receiptId) {
      if (rowsStatBool) {
        if (rowsState.length > 0) {
          var myHeaders = new Headers();
          myHeaders.append(
            "Authorization",
            `bearer ${db.read().getState().Token}`
          );
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("X-FP-API-KEY", "iphone");
          var requestOptions = {
            method: "post",
            withCredentials: true,
            headers: myHeaders,
            body: JSON.stringify({
              ReceiptId: receiptId,
              Items: rowsState,
              CustomerId: CustomerIdValue?.value,
            }),
          };
          fetch(
            `${window.$apiurl}/transload/receipts/importlineitem`,
            requestOptions
          )
            .then(async (response) => {
              const statusCode = response.status;
              let data = {};
              if (statusCode === 200) {
                Notification("success", "Import done successfully.");
              } else {
                data = await response.json();
                const msg =
                  data?.Message === undefined
                    ? "Something went wrong, please try later or contact the support."
                    : data.Message;
                Notification("error", msg);
              }
            })
            .then((res) => {
              return res;
            })
            .catch((err) => {
              console.log(err);
            });

          // var result = await axios.post("api/purchaseorders/import", { customerId: customerId, purchaseOrders: rowsState });
          // if (result.data.success === true) {
          //     Notification("success", "Import done successfully.");
          // }
          // else {
          //     const msg = result.data.Message === undefined ? "Something went wrong, please try later or contact the support." : result.data.Message;
          //     Notification("error", msg);
          // }
        } else {
          Notification("error", "Please select a valid file to emport.");
        }
      } else {
        Notification("error", "Please select a valid file to import.");
      }
    } else {
      Notification("error", "Please select a receipt.");
    }
    setLoading(false);
    setImportInProgress(false);
  };

  function createData(
    Empty,
    Index,
    PurchaseOrder,
    Sku,
    Pieces,
    UnitOfMeasure,
    UnitCount,
    Weight,
    WeightType,
    Cube,
    CubeType,
    Description,
    OverQuantity,
    ShortQuantity,
    DamagedQuantity,
    Pallets,
    IsHot,
    IsHazMat,
    Location,
    UpcNumber,
    Error
  ) {
    return {
      Empty,
      Index,
      PurchaseOrder,
      Sku,
      Pieces,
      UnitOfMeasure,
      UnitCount,
      Weight,
      WeightType,
      Cube,
      CubeType,
      Description,
      OverQuantity,
      ShortQuantity,
      DamagedQuantity,
      Pallets,
      IsHot,
      IsHazMat,
      Location,
      UpcNumber,
      Error,
    };
  }

  function TryParseInt(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }

  function TryParseFloat(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseFloat(str);
        }
      }
    }
    return retValue;
  }
  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Row>
            <div className="col-lg-4 mb-4">
              <Form.Label>Choose a Customer</Form.Label>
              <CreatableSelect
                isClearable
                value={CustomerIdValue}
                onChange={(e) => {
                  if (e === null) {
                    setCustomerId({
                      value: "",
                      label: "Select",
                    });
                  } else {
                    handleCustomerChange(e?.value);
                    setCustomerId(e);
                  }
                }}
                options={customersList.map((x) => {
                  return { value: x?.id, label: x?.CompanyName };
                })}
                name="CustomerId"
                id="CustomerId"
                className="w-100 mr-2"
              />
              {/* <select
                className="form-control"
                name="CustomerId"
                id="CustomerId"
                onChange={handleCustomerChange.bind(this)}
                onClick={() => {}}
              >
                <option key="" value="">
                  Choose a customer
                </option>
                {customersList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.CompanyName}
                  </option>
                ))}
              </select> */}
            </div>
            <div className="col-lg-4 mb-4">
              {/* <Form.Label>Choose a Receipt</Form.Label>
                            <select className="form-control" name="ReceiptId" id="ReceiptId" onClick={() => { }}>
                                <option key="" value="" >Choose a receipt</option>
                                {receiptsList.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}
                            </select> */}
              <p className="mb-2">Receipt Id</p>
              <CreatableSelect
                isClearable
                value={ReceiptIdValue}
                onChange={(e) => {
                  if (e === null) {
                    setReceiptId({
                      value: "",
                      label: "Select",
                    });
                  } else {
                    setReceiptId(e);
                  }
                }}
                options={receiptsList}
                name="ReceiptId"
                id="ReceiptId"
                className="w-100 mr-2"
              />
              {showProgress && <LinearProgress />}
            </div>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Import </Form.Label>
              <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                <Form.Control
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={fileHandler.bind(this)}
                  onClick={fileClear.bind(this)}
                />
              </Card>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="text-dark font-weight-bold">
                IMPORT FILE
              </Form.Label>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>
                  Use the 'Browse...' button to select a receipt items import
                  file.{" "}
                  <a
                    href="https://orders.empowerdatalogistics.com/Uploads/ImportTemplates/Import Receipt Items Template.xlsx"
                    download
                    target="_blank"
                  >
                    Download Template
                  </a>
                </p>
              </div>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>Then press the 'Import' button to read the file.</p>
              </div>

              <Button
                as="input"
                type="submit"
                color="secondary"
                readOnly
                value="Import"
                className="btn btn-primary btn-shadow-hover mr-2"
                onClick={() => {
                  fileHandlerOnclick();
                }}
              />
            </Form.Group>
          </Form.Row>
          {rowsStatBool && (
            <Form.Row className="mt-5 mb-5 pb-5">
              <Paper className={classes.paper}>
                <Table className={classes.table} size="small">
                  <TableHead className="bg-dark">
                    <TableRow className={classes.row}>
                      <TableCell className="text-white">
                        <b>Is Valid</b>
                      </TableCell>
                      {/* <TableCell className="text-white">
                        <b>Line</b>
                      </TableCell> */}
                      <TableCell className="text-white">
                        <b>Purchase Order*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Sku</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Case Pack*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Unit Of Measure*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Unit Count*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Weight*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Weight Type*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Cube*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Cube Type*</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Description</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Ovr Qty</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Short Qty</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Damaged Qty</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Pallets</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Is Hot</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Is HazMat</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Location</b>
                      </TableCell>
                      <TableCell className="text-white">
                        <b>Upc Number</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsState.map((row) => (
                      <TableRow className={classes.rowBody} key={row.Index}>
                        <TableCell>
                          {row.Empty === 1 && (
                            <i
                              data-toggle="tooltip"
                              title="Valid"
                              className="fa fa-check text-success icon-xxl"
                            ></i>
                          )}
                          {row.Empty === 0 && (
                            <i
                              data-toggle="tooltip"
                              title={row.Error}
                              className="fa fa-times-circle text-danger icon-xxl"
                            ></i>
                          )}
                        </TableCell>
                        {/* <TableCell>{row.Index}</TableCell> */}
                        <TableCell>{row.PurchaseOrder}</TableCell>
                        <TableCell>{row.Sku}</TableCell>
                        <TableCell>{row.Pieces}</TableCell>
                        <TableCell>{row.UnitOfMeasure}</TableCell>
                        <TableCell>{row.UnitCount}</TableCell>
                        <TableCell>{row.Weight}</TableCell>
                        <TableCell>{row.WeightType}</TableCell>
                        <TableCell>{row.Cube}</TableCell>
                        <TableCell>{row.CubeType}</TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>{row.OverQuantity}</TableCell>
                        <TableCell>{row.ShortQuantity}</TableCell>
                        <TableCell>{row.DamagedQuantity}</TableCell>
                        <TableCell>{row.Pallets}</TableCell>
                        <TableCell>{row.IsHot}</TableCell>
                        <TableCell>{row.IsHazMat}</TableCell>
                        <TableCell>{row.Location}</TableCell>
                        <TableCell>{row.UpcNumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <div className="text-center col-12">
                {" "}
                <b>
                  {rowsState.length > 0
                    ? rowsState.length + " Items(s)"
                    : "No item was imported"}
                  .
                </b>
              </div>
            </Form.Row>
          )}{" "}
          <Form.Row>
            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label className="text-dark font-weight-bold">
                Create
              </Form.Label>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>
                  Use the 'Browse...' button to select a receipt items import
                  file.
                </p>
              </div>
              <div className="MuiPaper-root font-size-lg mt-2">
                <p>Press the 'Create' button to create the receipt items.</p>
              </div>

              <button
                type="button"
                onClick={() => {
                  fileUploadeOnclick();
                }}
                disabled={importInProgress}
                className="btn btn-success btn-shadow-hover mr-2"
              >
                <span>{importInProgress ? "Importing..." : "Create"}</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white"></span>
                )}
              </button>
              {/* <Button
                as="input"
                value={importInProgress ? "Importing..." : "Create"}
                type="submit"
                readOnly
                disabled={importInProgress}
                className="btn btn-success btn-shadow-hover mr-2"
                onClick={() => {
                  fileUploadeOnclick();
                }}
              /> */}
            </Form.Group>
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
