import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { Spinner } from "react-bootstrap";
import TableRow from "@material-ui/core/TableRow";
import { AssignToOutboundHeaderForm } from "../../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/AssignToOutboundHeaderForm";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function TableComponent({
  list = [],
  DeleteShipmentRow,
  ShipmentID,
  ReloadPallets,
  IsClosedFunction,
  GetParms,
  isClosed,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const classes = useStyles();

  return (
    <div className={"TableComponent"}>
      <div className="alert alert-info" role="alert">
        <b>Shipment ID</b>
        <b className=" p-0 px-3 ml-3">{ShipmentID}</b>
      </div>
      <AssignToOutboundHeader
        ShipmentID={ShipmentID}
        ReloadPallets={ReloadPallets}
        IsClosedFunction={IsClosedFunction}
        GetParms={GetParms}
      />
      <br />
      <br />
      <b>Items Count : {list.length}</b>
      {list.length > 0 ? (
        <Paper className={classes.paper} id={ShipmentID + "Id"}>
          <div className="react-bootstrap-table table-responsive TabelFilter">
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    PO#
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    UPC
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    SKU
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    Cartons
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    Description
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    Color
                  </TableCell>
                  <TableCell className="bg-light text-dark pt-5 pb-5">
                    Size
                  </TableCell>
                  {!isClosed && (
                    <TableCell className="bg-light text-dark pt-5 pb-5">
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {(list ?? []).map((item, index) => (
                  <TableTrFunction
                    item={item}
                    key={index}
                    DeleteShipmentRow={DeleteShipmentRow}
                    isClosed={isClosed}
                  />
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell>
                    <b>Total</b>
                  </TableCell>

                  <TableCell>
                    <b>{list.reduce((a, b) => +a + +b.Quantity, 0)}</b>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  {!isClosed && <TableCell />}
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      ) : (
        <Nodatafound PalletID={ShipmentID} />
      )}{" "}
    </div>
  );
}

const TableTrFunction = ({ item, DeleteShipmentRow, isClosed }) => {
  return (
    <TableRow key={item.Id}>
      <TableCell>{item.IdShip}</TableCell>
      <TableCell>{item.Upc}</TableCell>
      <TableCell>{item.Sku}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>{item.Description}</TableCell>
      <TableCell>{item.Color}</TableCell>
      <TableCell>{item.Size}</TableCell>
      {!isClosed && (
        <TableCell>
          <input
            value="Delete"
            type="button"
            className="btn btn-danger text-white font-weight-bolder font-size-sm w-100 p-1"
            onClick={() => {
              DeleteShipmentRow(item.Id, item.IdShip);
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

function AssignToOutboundHeader({
  ShipmentID,
  ReloadPallets,
  IsClosedFunction,
  GetParms,
}) {
  const [Consignees, setConsignees] = useState([]);
  const [Vendors, setVendors] = useState([]);
  const [Shippers, setShippers] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [Services, setServices] = useState([]);
  const [statSHipmentId, setstatSHipmentId] = useState([]);
  const [statSHipment, setstatSHipment] = useState([]);
  const [LineItems, setLineItems] = useState([]);
  const [FreightCharges, setFreightCharges] = useState([]);
  const [fetchContainersAsync, setfetchContainersAsync] = useState([]);
  const [Warehouse, setWarehouse] = useState([]);
  const [TrackingDevices, setTrackingDevices] = useState([]);

  useEffect(() => {
    GetShipmentId(ShipmentID).then((e) => {
      if (e?.length > 0 && e[0] === 200) {
        setConsignees(e[1]?.Consignees ?? []);
        setCustomers(e[1]?.Customers ?? []);
        setVendors(e[1]?.Vendors ?? []);
        setShippers(e[1]?.Shippers ?? []);
        setServices(e[1]?.Services ?? []);
        setTrackingDevices(e[1]?.TrackingDevices ?? []);
        setFreightCharges(e[1]?.FreightCharges ?? []);

        e[1].Shipment.Id = ShipmentID;
        IsClosedFunction(e[1]?.Shipment?.IsClosed);
        setstatSHipment([e[1]?.Shipment] ?? []);

        (e[1]?.Items ?? []).forEach((element) => {
          element.IdShip = element.Id;
          element.Id = element.ItemId;
        });

        GetParms({
          ShipmentId: ShipmentID,
          IsClosed: e[1]?.Shipment?.IsClosed,
          ConsigneeId: e[1]?.Shipment?.ConsigneeId,
          CustomerId: e[1]?.Shipment?.CustomerId,
          Items: e[1]?.Items,
        });
      }
    });
  }, []);

  return (
    <AssignToOutboundHeaderForm
      Consigneesf={Consignees}
      Vendorsf={Vendors}
      Shippersf={Shippers}
      Customersf={Customers}
      Servicesf={Services}
      SHipmentId={ShipmentID}
      SHipment={statSHipment}
      setstatSHipment={setstatSHipment}
      setLineItems={setLineItems}
      LineItems={LineItems}
      TrackingDevicesf={TrackingDevices}
      FreightChargesf={FreightCharges}
      fetchContainersAsync={fetchContainersAsync}
      Warehouse={Warehouse}
      TableShow={false}
      ReloadPallets={ReloadPallets}
    />
  );
}

async function GetShipmentId(id) {
  return await fetch(
    `${window.$apiurl}/TransloadShipment/GetShipmentItems?shipmentId=${id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res;
    })
    .catch((error) => {
      Notification("error", "Shipment ID : " + id + " Not Found");
      console.error(error);
      return undefined;
    });
}

function Nodatafound({ PalletID }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 mt-5 pt-5 bg-light border rounded notItems">
          <div className="container-fluid row pt-5">
            <div className="col-lg-3" />
            <div className="col-lg-1 text-right">
              <i
                className="fas fa-pallet"
                style={{ fontSize: "70px", opacity: 0.3 }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <p className="h1 mt-5" style={{ opacity: 0.3 }}>
                Drop Items In this Shipment {PalletID}
              </p>
            </div>
            <div className="col-lg-2" />
            <div className="col-lg-12 my-5" />
          </div>
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
