/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ModalProgressBar,
  Input,
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import { Formik, Form, Field } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CreatableSelect from "react-select/creatable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Notification } from "../../../../Notification";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./tableStyle.css";
import $ from "jquery";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function ShippingBulkEmail({ rows, show ,onHide}) {
  const [ToData, setToData] = useState(null);
  const [SubjectData, setSubjectData] = useState(`Loaded alerts for carriers: ${rows.map(item=>item).join(", ")}`);
  const [includCCData, setincludCCData] = useState(false);
  const [BodyData, setBodyData] = useState(message);
  const [isLoadingSend, setisLoadingSend] = useState(false);
  const [isShowModal, setisShowModal] = useState(false);
  const [CustomerEmailsList, setCustomerEmailsList] = useState([]);
  const [CustomerEmailsListDefeault, setCustomerEmailsListDefeault] = useState([]);
  const [AttachmentData, setAttachmentData] = useState([]);

  const [dataTableCarriers, setDataTableCarriers] = useState([]);

  // Products Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.products.actionsLoading }),
    shallowEqual
  );
  function formatDate(date) {
    if (date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
  
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
  
      const result = [month, day, year].join("/");
      if (result !== "NaN/NaN/NaN") {
        return result;
      } else {
        return "--/--/----";
      }
    } else {
      return "";
    }
  }
  useEffect(() => {
    //shipments/GetSendEmailInvoice/{id}
      var paramsIds = "";
      var paramsCust = "";
      rows.map((item,index) => {
          paramsIds += (index === 0 ? `?ids=` : `&ids=`) + item;
      });
      fetch(`${window.$apiurl}/GetSendEmailCarriersLoaded` + paramsIds, {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          var a = res[1];
          var obj = [];
          var obj2 = [];
          if(a.IsTheSameCarrier){
            a.Emails.map((item, index) => {
              obj.push({ value: item.Email, label: item.Email });
              obj2.push({ Email: item.Email, Name: item.Email });
            });
            setCustomerEmailsList(obj);
            setCustomerEmailsListDefeault(obj);
            setToData(obj2);
          }else{
            Notification("error","Those Shipments doesn't have the same Carrier !");
            onHide();
          }
        })
        .catch((error) => {
          console.error(error);
        });
      
      const listShipments =  window.$dataNewShipments;
      try{
        if(listShipments){
          const listTable = [];
          rows.length > 0 &&
            rows.map((item) => {
              const currentShipment = listShipments.find((x) => x.Id == item);
              listTable.push({
                Container : currentShipment?.EquipmentNumber,
                Customer : currentShipment?.CustomerName,
                PickUpLocation : currentShipment?.OriginCompanyName,
                Carrier : currentShipment?.CarrierName,
                LoadDate : formatDate(currentShipment?.StatusLoadedFromOurWarehouseDate),
                Id : currentShipment.Id
              })
            });
            setDataTableCarriers(listTable || [])
        }
      }catch(err) {}
  }, [rows])


  // if !id we should close modal

  useEffect(() => {
    setisShowModal(show);
  }, [show]);
  // useEffect(() => {
  //   if (id !== undefined) {
  //     setSubjectData("Mitco Invoice ID: " + id);
  //   }
  // }, [id]);

  // looking for loading/dispatch
  useEffect(() => { }, [isLoading, dispatch]);

  const deleteProduct = async () => {
    // server request for deleting product by id
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    setisLoadingSend(true);

    const data = {
      Emails: ToData,
      Subject: SubjectData,
      Body: BodyData ?? message,
      IncludeCc: includCCData,
      Ids:rows.map(item=>item.Id),
      Attachments : AttachmentData
    };
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    let response = await fetch(
      `${window.$apiurl}/billing/sendMultiOutboundEmail`,
      requestOptions
    );
    if (response.status === 200) {
      Notification("success", "Email sent successfuly for Invoice IDs " + rows.map(item=>item).join(","));
      // setToData(null);
      // setSubjectData(null);
      // setBodyData(null);
      // setincludCCData(false);
      setAttachmentData([]);
      setisLoadingSend(false);
      onHide();
    } else {
      Notification("error", "invalid data");
      setAttachmentData([]);
      setisLoadingSend(false);
    }
  };

  ///----------------------
  var result = dataTableCarriers.map(function(item) {
    return `
      <tr style="border: 1px solid #000000;padding : 8px;font-size: 12px;">
        <td style="border: 1px solid #000000;padding : 8px;">${item.Container}</td>
        <td style="border: 1px solid #000000;padding : 8px;">LOADED</td>
        <td style="border: 1px solid #000000;padding : 8px;">${item.LoadDate}</td>
        <td style="border: 1px solid #000000;padding : 8px;">${item.Customer}</td>
        <td style="border: 1px solid #000000;padding : 8px;">${item.Carrier}</td>
        <td style="border: 1px solid #000000;padding : 8px;">${item.Id}</td>
      </tr>
     `;});
  var message = `
      <p>
        Good morning, <br/>
        We show the following loads are available for pickup at the ${dataTableCarriers.length >
          0 &&
          dataTableCarriers[0]
            ?.PickUpLocation}, please confirm your schedule for pickup.
      </p>
    <figure class="table">
      <table style="border: 1px solid #000000;padding : 8px;">
        <tbody sstyle="border: 1px solid #000000;padding : 8px;">
          <tr style="border: 1px solid #000000;padding : 8px;font-size: 12px;">
            <td style="border: 1px solid #000000;padding : 8px;">CONTAINER</td>
            <td style="border: 1px solid #000000;padding : 8px;">STATUS</td>
            <td style="border: 1px solid #000000;padding : 8px;">LD DATE</td>
            <td style="border: 1px solid #000000;padding : 8px;">ACCOUNT</td>
            <td style="border: 1px solid #000000;padding : 8px;">DRAYMAN</td>
            <td style="border: 1px solid #000000;padding : 8px;">eMP Load</td>
          </tr>
          ${result}
        </tbody>
      </table>
    </figure>
`;
  return (
    <Modal
      show={isShowModal}
      onHide={() => {
        //setShow(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
      size="xl"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Shipment Status `Loaded` Send
        </Modal.Title>
        <IconButton
          
          aria-label="Close"
          onClick={() => {
            onHide();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <span>Shipment Documents is Sending...</span>}
        <Formik>
          <Form className="form form-label-right Send-shipment">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-3">To: </p>
                  <CreatableSelect
                    isMulti
                    name="To"
                    id="To"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={CustomerEmailsList}
                    value={CustomerEmailsListDefeault}
                    onChange={(e) => {
                      var obj = [];
                      var objDefeault = [];
                      if (e) {
                        e.forEach((item, index) => {
                          obj.push({ Email: item.label, Name: item.value });
                          objDefeault.push({
                            value: item.value,
                            label: item.value,
                          });
                        });
                      }
                      setToData(obj);
                      setCustomerEmailsListDefeault(objDefeault);
                    }}
                  />
                  <CheckboxLarge
                    Size="12px"
                    name="includCC"
                    text="Include me on CC"
                    onChange={(event) => {
                      setincludCCData(event.target.checked);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Subject: </p>
                  <Field
                    name="Subject"
                    component={Input}
                    placeholder="Subject"
                    value={SubjectData}
                    onChange={(event) => {
                      setSubjectData(event.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Body: </p>

                  <CKEditor
                    name="Body"
                    Id="Body"
                    label="Body"
                    editor={ClassicEditor}
                    config={{
                      removePlugins: [
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                    data={message}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      var html = `<div>${data}</div>`;
                      var element = $(html);
                      var div = element.find(".table table tr td");
                      div.css("border", "1px solid black");
                      div.css("font-size", "12px");
                      div.css("padding", "8px");
                      html = element.html();
                      console.log("html");
                      console.log(html);
                      setBodyData(html);
                    }}
                  />
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={() => {
              onHide();
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteProduct}
            disabled={isLoadingSend}
            className="btn btn-delete btn-primary"
          >
            Send Email
            {isLoadingSend && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
