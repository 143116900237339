import React from 'react';

export function CustomerNotifsDetails() {

    return (

        <div className="form-group row">
            <div className="col-md-12 my-5">

                <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                Enter email recipients as semicolon-separated list of email addresses, such as <span className=" bg-light-primary  checkbox-lg checkbox-light-primary">"harry@james.com;freddie@hubbard.com".</span> 
                       </p>
                       <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                       Enter FTP URL as <span className=" bg-light-primary  checkbox-lg checkbox-light-primary">"ftp://user:password@host:port/path"</span>, where user, password, port, path are optional.
                       </p>
                       <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                       Enter Web Service address as <span className=" bg-light-primary  checkbox-lg checkbox-light-primary">"http://user:password@host:port/path"</span>, same optionals as ftp url.
                       </p>
                       <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                       A blank recipient list, FTP URL or Web Service indicates nothing is to be sent.
                       </p>
            </div>
        </div>
    );
}