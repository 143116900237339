import React from "react";

export function RefrigeratedColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["primary", "danger"];
  const CustomerStatusTitles = ["true", "false"];

  var isActive = row.Refrigerated === true ? 0 : 1;
  return (
    <span className={`text-${CustomerStatusCssClasses[isActive]}`}>
      <span className={`label label-dot label-${CustomerStatusCssClasses[isActive]} mr-2`}></span>
      {CustomerStatusTitles[isActive]}
    </span>
  );
}
