import { Button } from "@material-ui/core";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";
import SelectPagination from "../../../../_metronic/_partials/widgets/Select/SelectPagination";
import { CustomerEditForm } from "../../../modules/eMPower/pages/PalletsManagmentBeta/customer-edit-dialog/CustomerEditForm";
import { Notification } from "../../../modules/Notification";
import Index from "./AssignToWareHouse/Index";

const adapter = new LocalStorage("db");
const db = low(adapter);
const mystyleTabs = {
  fontSize: "13px",
};

export function AssignToWareHouse() {
  const [ShipmentIDStat, setShipmentIDStat] = useState([]);
  const [WareHouse, setWareHouse] = useState([]);
  const [warehouseId, setwarehouseId] = useState();
  const [LinePath, setLinePath] = useState({});
  const [Locations, setLocations] = useState([]);
  const [Pallets, setPallets] = useState([]);
  const [PalletId, setPalletId] = useState([]);
  const [LocationId, setLocationId] = useState();
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);
  const [IsClosed, setIsClosed] = useState(false);
  const [OrdersIds, setOrdersIds] = useState([]);
  const [NewPalletShow, setNewPalletShow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [ContainersSelect, setContainersSelect] = useState([]);

  useEffect(() => {
    GetAllLocations(setWareHouse);
    window.PalletValues = {};
  }, []);

  async function PostNewShipment() {
    var label = $("#LabelForShipmentID").val() || "";
    return await fetch(
      `${window.$apiurl}/transload/shipment?label=${label}&orderId=`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        setShipmentNewModelt(false);
        /* GetShipmentsIds(); */
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function onHide() {
    setNewPalletShow(false);
  }

  function saveCustomer(values) {
    window.PalletValues = values;
    PostModel(values, setPallets, onHide, setPalletId, setIsClosed);
  }
  return (
    <>
      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Pallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Pallet"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={async () => {
              setShipmentNewModelt(false);
              var a = await PostNewShipment();
              setShipmentIDStat([]);
              setTimeout(() => {
                if (a?.Id) setShipmentIDStat([a?.Id]);
              }, 1);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
      <LoadingDialog isLoading={Loading} text="Loading ..." />
      <div className="card-header font-weight-bold py-0 px-0">
        <div className="form-group row mb-5">
          <Modal show={NewPalletShow} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>New Pallet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CustomerEditForm
                saveCustomer={saveCustomer}
                onHide={onHide}
                customer={{
                  CustomerId: 90,
                  WarehouseId: warehouseId,
                  LocationId: LocationId,
                  ConsigneeId: window.PalletValues?.ConsigneeId,
                }}
                defaultValue={true}
              />
            </Modal.Body>
          </Modal>

          <div className="col-lg-2 text-left">
            {LocationId && (
              <button
                type="button"
                className="btn btn-light-success font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  setNewPalletShow(true);
                }}
              >
                <i className="fas fa-plus-circle"></i> New
              </button>
            )}
          </div>

          <div className="col-lg-2 text-left">
            {PalletId?.length > 0 && (
              <button
                type="button"
                className="btn btn-light-danger font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={(e) => {
                  setLoading(true);
                  PosttoClosed(
                    PalletId[0]?.Id,
                    setIsClosed,
                    IsClosed,
                    setPallets
                  ).then(() => {
                    setLoading(false);
                  });
                }}
              >
                <i className="fas fa-times-circle"></i>
                {IsClosed ? "UnClose" : "Close"}
              </button>
            )}
          </div>
          <div className="col-lg-2 text-left">
            {PalletId?.length > 0 && (
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={(e) => {
                  window
                    .open(
                      "/TransloadPalletManifest/" + Pallets[0]?.Id,
                      "_blank"
                    )
                    .focus();
                }}
              >
                <i className="fas fa-eye"></i> View Manifest
              </button>
            )}
          </div>

          <div className="col-lg-2 text-left">
            {WareHouse?.length > 0 ? (
              <SelectPagination
                options={WareHouse.map((x) => {
                  return { value: x?.Id, label: x?.WarehouseName };
                })}
                name="id"
                placeholder="Select Warehouse ..."
                onChange={async (newValue) => {
                  var locations = WareHouse.find(
                    (x) => x?.Id === newValue?.value
                  )?.Locations;
                  setwarehouseId(newValue?.value);
                  setLocations([]);
                  if (locations) {
                    setTimeout(() => {
                      setLocations(locations);
                    }, 1);
                  }
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Loading ...."
              />
            )}
          </div>
          <div className="col-lg-2 text-left">
            {Locations?.length > 0 ? (
              <SelectPagination
                options={Locations.map((x) => {
                  return { value: x?.Id, label: x?.LocationId };
                })}
                name="id"
                placeholder="Select Loaction ..."
                onChange={async (newValue) => {
                  setPallets([]);
                  setPalletId([]);
                  if (newValue?.value)
                    GetPalletsByLocation(newValue?.value).then((x) =>
                      setPallets(x ?? [])
                    );

                  if (newValue?.value) setLocationId(newValue?.value);
                  else setLocationId();
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Select Loaction ..."
              />
            )}
          </div>
          <div className="col-lg-2 text-left">
            {Locations?.length > 0 && Pallets?.length > 0 ? (
              <SelectPagination
                options={Pallets.map((x) => {
                  return { value: x?.Id, label: x?.Id };
                })}
                value={{
                  value: PalletId[0]?.Id,
                  label: PalletId[0]?.Id ?? "Select Pallet",
                }}
                name="id"
                placeholder="Select Pallet ..."
                onChange={async (newValue) => {
                  var a = Pallets.find((x) => x.Id === newValue?.value);
                  setIsClosed(a?.Status === "Closed");
                  setPalletId([]);
                  setTimeout(() => {
                    if (a) setPalletId([a]);
                  }, 1);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Select Pallet ..."
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body AssigntoOutboundId px-0">
        {PalletId.length === 0 && <Nodatafound />}
        {PalletId.length > 0 && (
          <Index
            iShipmentsID={PalletId}
            LinePath={LinePath}
            setPalletsFun={setPallets}
            OrdersIds={OrdersIds}
            Locations={Locations}
            IsClosed={IsClosed}
            setLoading={setLoading}
            setContainersSelect={setContainersSelect}
            ContainersSelect={ContainersSelect}
          />
        )}
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-lg-4" />
      <div className="col-lg-1 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-lg-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No data found
        </p>
      </div>
      <div className="col-lg-3" />
      <div className="col-lg-12 my-5" />
      <div className="col-lg-12 my-5" />
    </div>
  );
}

async function PosttoClosed(PalletsIDStat, setIsClosed, IsClosed, setPallets) {
  var number = IsClosed ? 82 : 84;
  return await fetch(`${window.$apiurl}/Pallets/UpdateSingleStatus`, {
    method: "POST",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Id: PalletsIDStat, StatusId: number }),
  }).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      setPallets((x) => {
        var index = x.findIndex((y) => y.Id === PalletsIDStat);
        if (index > -1) x[index].Status = number === 84 ? "Closed" : "Progress";
        return x;
      });
      setIsClosed((x) => !x);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}

async function GetAllLocations(setWareHouse) {
  return await fetch(`${window.$apiurl}/Locations/GetAllLocations`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      setWareHouse(res[1] ?? []);
    })
    .catch((error) => {
      console.error(error);
    });
}

async function GetPalletsByLocation(id) {
  return await fetch(
    `${window.$apiurl}/Pallets/GetPalletsByLocation?locationId=${id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[1]?.length > 0) return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

async function PostModel(
  someData,
  setPalletsFun,
  onHide,
  setPalletId,
  setIsClosed
) {
  return fetch(window.$apiurl + "/pallets", {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        setPalletsFun((x) => {
          x = x ?? [];
          x.unshift(res[1].palletVm);
          window.list = x;
          return x;
        });
        setPalletsFun([]);
        setPalletId([]);
        setIsClosed(false);
        setTimeout(() => {
          setPalletsFun(window.list);
          setPalletId([res[1].palletVm]);
        }, 1);
        onHide();
        Notification("success", "Pallet is Created Success");
      }
    })
    .catch((err) => {
      Notification("error", "Oops");
    });
}
