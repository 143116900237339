import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import clsx from "clsx";
import FileSaver from "file-saver";
import GoogleMapReact from "google-map-react";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import PDFViewer from "pdf-viewer-reactjs";
import React, { Fragment, useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Card,
  Col,
  Dropdown,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tab,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import FileViewer from "react-file-viewer";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";
import Select, { components, PlaceholderProps } from "react-select";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  ActivityFunction,
  CopyTextToClipboardGlobal,
  currencyFormat,
  GetBaseData,
  GetCarrierCheckedInData,
  MenuIdsCopy,
  momentDate,
  momentDateOnly,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import {
  CheckboxLargeWB,
  DenseTableSm,
} from "../../../../../../_metronic/_partials/controls";
import { DropdownCustomTogglerYellow } from "../../../../../../_metronic/_partials/dropdowns/DropdownCustomToggler";
import { Notification } from "../../../../../modules/Notification";
import { OneMoveMaster } from "../../../../eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import "../../ReceiptsManagementNewVersion/customers-table/FileViewerStyle.css";
import { RemarkEditDialog } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/RemarkEditDialog";
import { ContainersUIProvider } from "../../Transload/FindOrders/product-Containers/RemarksUIContext";
import { OrderAttachementsV2 } from "../../Transload/FindOrders/product-edit/OrderAttachements";
import MenuIndex from "./Menu/Index";

const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
function formatDate(date) {
  if (date === null || date === undefined || date === "") {
    return "";
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
}
function IsValid(name, str) {
  try {
    if (
      str?.includes("null") ||
      str?.includes("undefined") ||
      str?.includes("Invalid date")
    )
      str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row my-2">
        <div className="col-xl-5 col-lg-6 col-md-6 col-6 font-weight-bold text-dark text-left">
          {name}
        </div>
        <div className="col-xl-7 col-lg-6 col-md-6 col-6 text-primary">
          : {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}
function IsValidColumn(name, str) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row mb-5">
        <div className="col-lg-12 col-6 font-weight-bold text-dark text-center">
          {name}
        </div>
        <div className="col-lg-12 col-6 text-primary text-center">
          {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}

function tConvert(time) {
  if (time === undefined || time === null || time === "") {
    return "";
  } else {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
}
export function ModelManagmentFirst({ row, isReporting, isTms }) {
  const [Show, setShow] = useState(false);
  function CopyComponent() {
    return (
      <MenuIdsCopy
        Content={
          <Fragment>
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  CopyTextToClipboardGlobal(row.id || row?.Id);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-success pr-2">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
                <span className="navi-text">Shipment ID#</span>
              </a>
            </li>
            <li className="navi-item">
              <a
                href={() => false}
                className="navi-link"
                onClick={() => {
                  CopyTextToClipboardGlobal(row?.EquipmentNumber);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary pr-2">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
                <span className="navi-text">Trailer #</span>
              </a>
            </li>
          </Fragment>
        }
      />
    );
  }
  return (
    <Fragment>
      <Modal
        size="xl"
        show={Show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={row?.id} setShow={setShow.bind()} isTms={isTms} />
      </Modal>

      <CopyComponent />
      <></>
      <>
        {row?.IsCancelled || row?.OsdConfirmed ? (
          <OverlayTrigger
            overlay={
              <Tooltip id="products-edit-tooltip">
                {row?.IsCancelled === true
                  ? "Cancled"
                  : row?.OsdConfirmed === true
                  ? "OSD Confirmed"
                  : ""}
              </Tooltip>
            }
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                setShow(true);
              }}
              className={`inputShipmentId  alert ${
                row?.IsCancelled === true
                  ? "alert-danger"
                  : row?.OsdConfirmed === true
                  ? "alert-warning"
                  : "alert-light-info"
              } ${
                isReporting === true ? "pt-0 pb-0 mt-0  mb-0" : "px-2 py-1 mt-3"
              } ${
                row?.IsCancelled === true
                  ? "text-black"
                  : row?.OsdConfirmed === true
                  ? "text-black"
                  : "text-primary bg-light"
              }`}
            >
              {isReporting ? row.Trailer : row?.id}
            </Link>
          </OverlayTrigger>
        ) : (
          <Link
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
            }}
            className={`inputShipmentId  alert ${
              row?.IsCancelled === true
                ? "alert-danger"
                : row?.OsdConfirmed === true
                ? "alert-warning"
                : "alert-light-info"
            } ${
              isReporting === true ? "pt-0 pb-0 mt-0  mb-0" : "px-2 py-1 mt-3"
            } ${
              row?.IsCancelled === true
                ? "text-black"
                : row?.OsdConfirmed === true
                ? "text-black"
                : "text-primary bg-light"
            }`}
          >
            {isReporting ? row.Trailer : row?.id}
          </Link>
        )}
      </>
    </Fragment>
  );
}

export function ModelManagmentFirstDashboardSm({ trailer, id, isDashboard }) {
  const [Show, setShow] = useState(false);
  return (
    <>
      <Modal
        size="xl"
        show={Show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment
          isDashboard={isDashboard}
          Id={id}
          setShow={setShow.bind()}
        />
      </Modal>
      <input
        onClick={() => setShow(true)}
        type="button"
        className={`alert alert-light-info px-2 py-1 mt-3 text-primary bg-light`}
        value={trailer}
      />
    </>
  );
}
export function DetailMangment({ Id, setShow, isDashboard, isTms }) {
  const classes = useStyles();
  const [Detail, setDetail] = useState();
  const [Status, setStatus] = useState([]);
  const [VendorsList, setVendorsList] = useState();
  const [Notes, setNotesList] = useState([]);
  const [Attachments, setAttachmentsList] = useState();
  const [DetailTrakingInfo, setDetailTrakingInfo] = useState([]);
  const [DetailTrakingGeometryInfo, setDetailTrakingGeometryInfo] = useState(
    []
  );
  const [DetailActivityRatesInfo, setDetailActivityRatesInfo] = useState(null);
  const [DetailShipmentInfo, setDetailShipmentInfo] = useState({});
  const [ShipmentDriversInfo, setShipmentDriversInfo] = useState(null);
  async function getCarrierInfodata() {
    let tt = await GetCarrierCheckedInData(Id);
    console.log("tt", tt);
    setShipmentDriversInfo(tt);
  }
  useEffect(() => {
    getCarrierInfodata();
  }, [Id]);

  useEffect(() => {
    var listi = [];
    document.querySelectorAll(".shipmentStatus").forEach((element) => {
      if (element.innerText !== "Other" && element.innerText !== "Delivered")
        listi.push(element.innerText);
    });
    setStatus(listi);
  }, []);

  const renderMarkers = (map, maps) => {
    var data = DetailTrakingGeometryInfo.map((item) => {
      return {
        lat: Number(item.Lat),
        lng: Number(item.Lng),
        name: item.Address,
        Type: item.TraceType,
      };
    });
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();

    calculateAndDisplayRoute(directionsService, directionsRenderer);

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      window.gMap = map;
      for (var i = 0; i < data.length; i++) {
        if (data[i].Type === "MANUAL") {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png",
            },
          });
        } else {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
            },
          });
        }
      }
      for (var j = 0, parts = [], max = 22 - 1; j < data.length; j = j + max) {
        parts.push(data.slice(j, j + max + 1));
      }

      for (var k = 0; k < parts.length; k++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          destination: data[data.length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        directionsService.route(service_options, (response, status) => {
          if (status !== "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer);
          directionsRenderer.setMap(map);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
          });
          directionsRenderer.setDirections(response);
        });
      }
    }
    directionsRenderer.setMap(map);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  }));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  const [Result, setResult] = useState(null);
  useEffect(() => {
    GetModel();
  }, []);
  const [contactModal, setContactModal] = useState(false);
  const [contactsVendor, setContactsVendor] = useState([]);
  const [vendorIdContact, setVendorIdContact] = useState(0);

  useEffect(() => {
    try {
      const obj = [];
      if (Detail?.Shipment?.Vendors?.length > 0) {
        Detail.Shipment.Vendors.forEach((itemX) => {
          itemX.VendorName =
            itemX.VendorAddress?.CompanyName +
            " - " +
            itemX.VendorAddress?.Code;
          itemX.ContactName = (
            <a
              href={() => false}
              className="btn btn-light-primary btn-elevate py-1"
              onClick={() => {
                setVendorIdContact(itemX.Id);
                setContactsVendor(itemX.Contacts || []);
                setContactModal(true);
              }}
            >
              Show
            </a>
          );
          obj.push(itemX);
        });
        setVendorsList(obj);
      }
    } catch (err) {
      console.log(err);
    }
  }, [Detail?.Shipment]);

  function CheckStatus(Name) {
    try {
      return $(`.ShipmentStatus:contains("${Name}")`)?.length > 0;
    } catch (error) {
      return false;
    }
  }
  //OSD
  const [shortQuantity, setShortQuantity] = useState("");
  const [damagedQuantity, setDamagedQuantity] = useState("");
  const [overQuantity, setOverQuantity] = useState("");
  const [modalOsdEdit, showModalEditOsd] = useState(false);
  const [saveEditOsd, setSaveOsd] = useState(false);
  const [rowOsd, setRowOsd] = useState(null);

  async function PutOsd() {
    const someData = {
      OverQuantity: overQuantity ?? 0,
      ShortQuantity: shortQuantity ?? 0,
      DamagedQuantity: damagedQuantity ?? 0,
    };
    return fetch(window.$apiurl + "/Shipments/Osd/" + rowOsd?.Id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {});
  }
  const [showAttachment, setShowAttachment] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true);
  useEffect(() => {
    if (!showAttachment) {
      setFilePath("");
      setFileType("");
    }
  }, [showAttachment]);
  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  const [type, setFileType] = useState("");
  const [filePath, setFilePath] = useState("");
  return (
    <>
      <Modal.Header>
        <div className="row">
          <div className="col-xl-6 col-lg-5 col-md-12 col-12">
            {IsValid("Order Number", Detail?.Shipment?.OrderId)}
          </div>
          <div className="col-xl-6 col-lg-5 col-md-12 col-12">
            {IsValid(
              "Shipment Number",
              Detail?.Shipment?.Id +
                (Detail?.Shipment?.Equipment
                  ? " - " + Detail?.Shipment?.Equipment
                  : "")
            )}
          </div>

          <div className="col-xl-6 col-lg-5 col-md-12 col-12">
            {IsValid("Invoice No", Detail?.Shipment?.InvoiceNumber)}
          </div>
          <div className="col-xl-6 col-lg-5 col-md-12 col-12">
            {IsValid("Invoice Date", momentDate(Detail?.Shipment?.InvoiceDate))}
          </div>
        </div>
        <IconButton
          aria-label="Close"
          onClick={() => {
            setShow(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="0"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Shipment Basic</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="form-group row word-break">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Customer
                  </div>
                  <br />
                  {IsValid(
                    "Customer Code",
                    Detail?.Shipment?.CustomerAddress?.Code
                  )}
                  {IsValid(
                    "Customer Name",
                    Detail?.Shipment?.CustomerAddress?.CompanyName
                  )}
                  {IsValid(
                    "Customer Address",
                    Detail?.Shipment?.CustomerAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.CustomerAddress?.City +
                        " , " +
                        Detail?.Shipment?.CustomerAddress?.State +
                        " , " +
                        Detail?.Shipment?.CustomerAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Customer Ref No ",
                      Detail?.Shipment?.CustomerNumber
                    )}
                  </div>
                  {/* <div>
                    {IsValid("Customer Ref No 2", Detail?.CustomerReference2)}
                  </div> */}
                </div>

                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Bill To
                  </div>
                  <br />
                  {IsValid(
                    "Bill To Code",
                    Detail?.Shipment?.BillToAddress?.Code
                  )}
                  {IsValid(
                    "Bill To Name",
                    Detail?.Shipment?.BillToAddress?.CompanyName
                  )}
                  {IsValid(
                    "Bill To Address1",
                    Detail?.Shipment?.BillToAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.BillToAddress?.City +
                        " , " +
                        Detail?.Shipment?.BillToAddress?.State +
                        " , " +
                        Detail?.Shipment?.BillToAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Billing Ref No ",
                      Detail?.Shipment?.BillingNumber
                    )}
                  </div>
                  {/* <div>
                    {IsValid("Billing Ref No 2", Detail?.BillingReference2)}
                  </div> */}
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Pickup Location
                  </div>
                  <br />
                  {IsValid(
                    "Pickup Location Code",
                    Detail?.Shipment?.ShipperAddress?.Code
                  )}
                  {IsValid(
                    "Pickup Location Name",
                    Detail?.Shipment?.ShipperAddress?.CompanyName
                  )}
                  {IsValid(
                    "Pickup Location Address1",
                    Detail?.Shipment?.ShipperAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.ShipperAddress?.City +
                        " , " +
                        Detail?.Shipment?.ShipperAddress?.State +
                        " , " +
                        Detail?.Shipment?.ShipperAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid("Shipper Ref No", Detail?.Shipment?.ShipperNumber)}
                  </div>
                  <div>
                    {IsValid(
                      "Order Created",
                      momentDate(Detail?.Shipment?.CreatedAt)
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Ship to Location
                  </div>
                  <br />
                  {IsValid(
                    "Ship to Location Code",
                    Detail?.Shipment?.ConsigneeAddress?.Code
                  )}
                  {IsValid(
                    "Ship to Location Name",
                    Detail?.Shipment?.ConsigneeAddress?.CompanyName
                  )}
                  <div>
                    {IsValid(
                      "Ship to Location Address1",
                      Detail?.Shipment?.ConsigneeAddress?.Address
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.ConsigneeAddress?.City +
                        " , " +
                        Detail?.Shipment?.ConsigneeAddress?.State +
                        " , " +
                        Detail?.Shipment?.ConsigneeAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Consignee Ref No",
                      Detail?.Shipment?.ConsigneeNumber
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Dates
                  </div>
                </div>
                <div className="col-lg-6 mt-5">
                  {Status?.length > 0 &&
                    Status.map((x) => (
                      <>
                        {IsValid(
                          x,
                          momentDate(statusBuild(x, Detail?.Shipment))
                        )}
                      </>
                    ))}
                  {IsValid(
                    "Delivered",
                    momentDate(Detail?.Shipment?.StatusDeliveredDate)
                  )}
                  {/* {isTms === true
                    ? IsValid(
                        "Planned",
                        momentDate(Detail?.Shipment?.StatusPlannedDate)
                      )
                    : ""} */}
                  {isTms === true
                    ? IsValid(
                        "Shipping Arrival Date",
                        momentDate(Detail?.Shipment?.ShippingArrivalDate)
                      )
                    : ""}
                  {isTms === true
                    ? IsValid(
                        "Trailler Drop Date",
                        momentDate(Detail?.Shipment?.TraillerDropDate)
                      )
                    : ""}

                  {isTms === true
                    ? IsValid(
                        "Checked In Date",
                        momentDate(Detail?.Shipment?.StatusCheckedInDate)
                      )
                    : ""}
                  {/* {isTms === true
                    ? IsValid(
                        "Notify Trucker",
                        momentDate(Detail?.Shipment?.StatusNotifyTrucker)
                      )
                    : ""} */}

                  {IsValid(
                    "Requested Pick Up Date",
                    momentDate(Detail?.Shipment?.RequestedPickUpDate)
                  )}
                  {IsValid(
                    "Last Ship Date",
                    momentDate(Detail?.Shipment?.LastShipDate)
                  )}
                  {IsValid(
                    "P/U Appointment",
                    momentDate(Detail?.Shipment?.PuAppointment)
                  )}
                  {IsValid(
                    "P/U Appointment Start",
                    moment(
                      momentDate(Detail?.Shipment?.PuAppointment),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.PuAppointment),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "P/U Appointment To",
                    moment(
                      momentDate(Detail?.Shipment?.PuAppointmentTo),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.PuAppointmentTo),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                </div>
                <div className="col-lg-6 col-md-12 mt-5">
                  {IsValid(
                    "Appointment Date",
                    momentDate(Detail?.Shipment?.AppointmentDate)
                  )}
                  {IsValid(
                    "Appointment Date Start",
                    moment(
                      momentDate(Detail?.Shipment?.AppointmentDate),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.AppointmentDate),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "Appointment Date To",
                    moment(
                      momentDate(Detail?.Shipment?.AppointmentTo),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.AppointmentTo),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "E.T.A",
                    momentDate(Detail?.Shipment?.EstimatedArrivalDate)
                  )}
                  {IsValid("RDD", momentDateOnly(Detail?.Shipment?.Rdd))}
                </div>
                <div className="col-lg-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Basics
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 mt-5">
                  {IsValid("Commodity Id", Detail?.Shipment?.CommodityId)}
                  {IsValid("Entered By", Detail?.Shipment?.EnteredBy)}
                  {IsValid(
                    "Order Created",
                    momentDate(Detail?.Shipment?.CreatedAt)
                  )}
                  {IsValid("Order No", Detail?.Shipment?.OrderId)}
                  {IsValid("Weight", currencyFormat(Detail?.Shipment?.Weight))}
                  {IsValid("Cube", currencyFormat(Detail?.Shipment?.Cube))}
                  {IsValid("Pieces", Detail?.Shipment?.Pieces)}
                </div>

                <div className="col-lg-6 col-md-12 mt-5">
                  {IsValid("Service", Detail?.Shipment?.ServiceName)}
                  {IsValid("Mode", Detail?.Shipment?.ModeName)}
                  {IsValid("Service Level", Detail?.Shipment?.ServiceLevelName)}
                  {IsValid(
                    "Equipment Type",
                    Detail?.Shipment?.EquipmentTypeName
                  )}
                  {IsValid(
                    "Equipment Size",
                    Detail?.Shipment?.EquipmentSizeName
                  )}
                  {IsValid("Load Type", Detail?.Shipment?.PalletType)}
                  {Detail?.Shipment?.PalletType === "Palletized" && (
                    <>
                      {IsValid("Pallet Type", Detail?.Shipment?.PalletLoadType)}
                      {IsValid("Pallet Count", Detail?.Shipment?.PalletCount)}
                    </>
                  )}

                  {IsValid("Tracking Device", Detail?.Shipment?.DeviceName)}
                  {IsValid("Freight Charge", Detail?.Shipment?.FreightCharge)}
                </div>
                {Detail?.ServiceName === "Drayage" && (
                  <>
                    <div className="col-lg-12 mt-5">
                      <div
                        className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                        style={{ fontSize: "15px" }}
                      >
                        Oder Details - Drayage
                      </div>
                    </div>
                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Mode Name", Detail?.ModeName)}
                      {IsValidColumn("Vessel", Detail?.Vessel)}
                      {IsValidColumn("Seal Number", Detail?.SealNumber)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                      {IsValidColumn("Voyage Number", Detail?.VesselVoyage)}
                      {IsValidColumn("Cartons", Detail?.Cartons)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                      {IsValidColumn("ETA", Detail?.Eta)}
                      {IsValidColumn("Weight", Detail?.TotalWeight1)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Number", Detail?.EquipmentRef)}
                      {IsValidColumn("MBL", Detail?.MasterBill)}
                      {IsValidColumn(
                        "Cube",
                        currencyFormat(Detail?.TotalCube1)
                      )}
                    </div>
                  </>
                )}

                {Detail?.ServiceName === "Domestic Transportation" && (
                  <div className="col-lg-12 form-group row">
                    <div className="col-lg-12 mt-5">
                      <div
                        className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                        style={{ fontSize: "15px" }}
                      >
                        Order Details - LTL
                      </div>
                    </div>
                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Mode Name", Detail?.ModeName)}{" "}
                      {IsValidColumn("Cartons", Detail?.Cartons)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                      {IsValidColumn("Pallets", Detail?.Pallets)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                      {IsValidColumn("Weight", Detail?.TotalWeight1)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn(
                        "Equipment Number",
                        Detail?.EquipmentTypeId
                      )}
                      {IsValidColumn(
                        "Cube",
                        currencyFormat(Detail?.TotalCube1)
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="5"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">TRACKING</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse className="mb-5" eventKey="5">
              <div className="row">
                <div className="col-lg-6 ">
                  <div className={`card card-custom  card-stretch gutter-b`}>
                    {/* Header */}
                    <div className="card-header align-items-center border-0">
                      <h3 className="card-title align-items-start flex-column">
                        <span class="font-weight-bolder text-dark text-uppercase">
                          BASIC DETAILS
                        </span>
                      </h3>
                    </div>

                    {/* Body */}
                    <div className="card-body pt-10">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Trailer Number
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment?.Equipment}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Seal Number
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment?.SealNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Customer
                              </a>
                              <span className="text-primary">
                                {
                                  DetailShipmentInfo?.Shipment?.CustomerAddress
                                    ?.CompanyName
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Carrier
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment !== null
                                  ? DetailShipmentInfo?.Shipment?.Vendors
                                      ?.length > 0 &&
                                    DetailShipmentInfo?.Shipment?.Vendors[0]
                                      ?.VendorAddress?.CompanyName
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href={() => false}
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Current Location
                          </a>
                          <span className="text-primary">
                            {Result?.CurrentEvent +
                              " - " +
                              Result?.CurrentCity +
                              ", " +
                              Result?.CurrentState +
                              " - " +
                              formatDate(Result?.CurrentDate) +
                              ", " +
                              tConvert(Result?.CurrentTime)}
                          </span>
                        </div>
                      </div>
                      <Row>
                        <Col lg="6">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Shipment Status
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment?.StatusName}
                              </span>
                            </div>
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href={() => false}
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Service Level
                              </a>
                              <span className="text-primary">
                                {Detail?.Shipment?.ServiceLevelName}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="card card-custom  card-stretch gutter-b">
                    <div class="card-header align-items-center border-0">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="font-weight-bolder text-dark text-uppercase">
                          SHIPMENT OVERVIEW
                        </span>
                      </h3>
                    </div>
                    <div class="card-body pt-10">
                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href={() => false}
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Ship From
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment?.ShipperAddress?.Code}
                            {"-"}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.CompanyName
                            }
                            :{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.Address
                            }
                            ,{" "}
                            {DetailShipmentInfo?.Shipment?.ShipperAddress?.City}
                            ,{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.State
                            }{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.ZipCode
                            }
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href={() => false}
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Ship To
                          </a>
                          <span className="text-primary">
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.Code
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.CompanyName !== null
                              ? "-"
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.CompanyName
                            }
                            {DetailShipmentInfo?.DestinationInformation
                              ?.Address !== null
                              ? ": "
                              : ""}
                            {
                              DetailShipmentInfo?.DestinationInformation
                                ?.Address
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.City !== null
                              ? ", "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.City
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.State !== null
                              ? ", "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.State
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.ZipCode !== null
                              ? " "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.ZipCode
                            }
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href={() => false}
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Pickup Estimated Date
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment
                              ?.StatusInTransitDate !== null
                              ? new Date(
                                  DetailShipmentInfo?.Shipment?.StatusInTransitDate
                                ).toLocaleString()
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href={() => false}
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Delivery Estimated Date
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment
                              ?.EstimatedArrivalDate !== null
                              ? new Date(
                                  DetailShipmentInfo?.Shipment?.EstimatedArrivalDate
                                ).toLocaleString()
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="6"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">TRACKING DETAILS</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse className="mb-5" eventKey="6">
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Map View">
                  <div className="row">
                    <div className="col-lg-8 " style={{ height: "500px" }}>
                      {DetailTrakingGeometryInfo ? (
                        DetailTrakingGeometryInfo.length !== 0 ? (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: window.$GoogleMapsKey,
                            }}
                            defaultCenter={{
                              lat: 37.923984,
                              lng: -97.539071,
                            }}
                            defaultZoom={4}
                            onGoogleApiLoaded={({ map, maps }) =>
                              renderMarkers(map, maps)
                            }
                          ></GoogleMapReact>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-4 trucking-scroll">
                      <div
                        className={`card card-custom`}
                        style={{ height: "500px" }}
                      >
                        {/* Header */}
                        <div className="card-header align-items-center border-bottom">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">
                              TRACKING
                            </span>
                          </h3>
                        </div>
                        {/* Body */}
                        <div className="card-body py-5">
                          <div className="timeline timeline-5 my-5">
                            <div className="timeline-item align-items-start">
                              <div className="timeline-badge">
                                <i className="fa fa-genderless text-warning icon-xxl"></i>
                              </div>

                              <div className="timeline-content d-block">
                                <span className="mr-4 font-weight-bolder text-dark">
                                  {
                                    DetailShipmentInfo?.Shipment?.ShipperAddress
                                      ?.City
                                  }
                                  ,{" "}
                                  {
                                    DetailShipmentInfo?.Shipment?.ShipperAddress
                                      ?.State
                                  }
                                </span>
                                <div className="timeline-content text-primary my-3 mx-0">
                                  Start Tracking:{" "}
                                  {DetailShipmentInfo?.Shipment
                                    ?.StatusInTransitDate !== null
                                    ? new Date(
                                        DetailShipmentInfo?.Shipment?.StatusInTransitDate
                                      ).toLocaleString()
                                    : ""}
                                </div>
                              </div>
                            </div>
                            {DetailTrakingInfo
                              ? DetailTrakingInfo.map((item, index) => (
                                  <div
                                    key={index}
                                    className="timeline-item align-items-start"
                                  >
                                    <div className="timeline-badge">
                                      <i
                                        className={
                                          item.TraceType === "MANUAL"
                                            ? "fa fa-genderless text-warning icon-xxl"
                                            : "fa fa-genderless text-success icon-xxl"
                                        }
                                      ></i>
                                    </div>

                                    <div className="timeline-content d-block">
                                      <span className="mr-4 font-weight-bolder text-dark">
                                        {item.LocationCity},{" "}
                                        {item.LocationState}
                                      </span>
                                      <div className="timeline-content text-primary my-3 mx-0">
                                        {item.EventName}:{" "}
                                        {item.TraceDate !== null
                                          ? formatDate(item.TraceDate) +
                                            ", " +
                                            tConvert(item.TraceTime)
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Table View">
                  <Table className={`mt-5 ${classes.table}`}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-light">Trace Type</TableCell>
                        <TableCell className="bg-light">Trace Date</TableCell>
                        <TableCell className="bg-light">Location</TableCell>
                        <TableCell className="bg-light">Event Name</TableCell>
                        <TableCell className="bg-light">Eta Date</TableCell>
                        <TableCell className="bg-light">Created By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {DetailTrakingInfo !== undefined &&
                        DetailTrakingInfo.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.TraceType}
                            </TableCell>
                            <TableCell>
                              {item.TraceDate !== null
                                ? formatDate(item.TraceDate) +
                                  ", " +
                                  tConvert(item.TraceTime)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {item.LocationCity +
                                " " +
                                item.LocationState +
                                ", " +
                                item.LocationCountry}
                            </TableCell>
                            <TableCell>{item.EventName}</TableCell>
                            <TableCell>
                              {item.EtaDate !== null
                                ? formatDate(item.EtaDate) +
                                  ", " +
                                  tConvert(item.EtaTime)
                                : ""}
                            </TableCell>
                            <TableCell>{item.CreatedBy}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Tab>
              </Tabs>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="12"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Shipment Items</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="12">
              <div className="form-group">
                <div className="col-lg-12 mt-5">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead className="mb-4">
                        <TableRow>
                          <TableCell className="bg-light">
                            Purchase Order
                          </TableCell>
                          <TableCell className="bg-light">
                            Unit Of Measure
                          </TableCell>
                          <TableCell className="bg-light">Location</TableCell>
                          <TableCell className="bg-light">Sku</TableCell>
                          <TableCell className="bg-light">UPC</TableCell>
                          <TableCell className="bg-light">Container</TableCell>
                          <TableCell className="bg-light">
                            Description
                          </TableCell>
                          <TableCell className="bg-light">UnitCount</TableCell>
                          <TableCell className="bg-light">Weight</TableCell>
                          <TableCell className="bg-light">Cube</TableCell>
                          <TableCell className="bg-light">IsHazmat</TableCell>
                          <TableCell className="bg-light">IsHot</TableCell>
                          <TableCell className="bg-light">
                            ShortQuantity
                          </TableCell>
                          <TableCell className="bg-light">
                            OverQuantity
                          </TableCell>
                          <TableCell className="bg-light">
                            DamagedQuantity
                          </TableCell>

                          <TableCell className="bg-light">
                            Ship Short Qte.
                          </TableCell>
                          <TableCell className="bg-light">
                            Ship Over Qte.
                          </TableCell>
                          <TableCell className="bg-light">
                            Ship Damaged Qte.
                          </TableCell>
                          <TableCell className="bg-light">Case Pack</TableCell>
                          {/* {isDashboard && ( */}
                          <TableCell className="bg-light">Actions</TableCell>
                          {/*  )} */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Detail?.Shipment?.ShipmentItems.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((item) => (
                          <TableRow>
                            <TableCell>{item.PurchaseOrder}</TableCell>
                            <TableCell>{item.UnitOfMeasure}</TableCell>
                            <TableCell>
                              {
                                <>
                                  {item.Location}
                                  {item.LocationCode && (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="products-edit-tooltip">
                                          {item.LocationCode}
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href={() => false}
                                        className="btn btn-icon btn-light btn-hover-light btn-sm"
                                      >
                                        <span className="svg-icon svg-icon-md svg-icon-info">
                                          <SVG
                                            src={toAbsoluteUrl(
                                              "/media/svg/icons/Code/Info-circle.svg"
                                            )}
                                          />
                                        </span>
                                      </a>
                                    </OverlayTrigger>
                                  )}
                                </>
                              }
                            </TableCell>
                            <TableCell>{item.Sku}</TableCell>
                            <TableCell>{item.Upc}</TableCell>
                            <TableCell>{item.Container}</TableCell>
                            <TableCell>{item.Description}</TableCell>
                            <TableCell>{item.Quantity}</TableCell>
                            <TableCell>{currencyFormat(item.Weight)}</TableCell>
                            <TableCell>{currencyFormat(item.Cube)}</TableCell>
                            <TableCell>
                              {" "}
                              {item.IsHazmat && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="products-edit-tooltip">
                                      Hazardous Material
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href={() => false}
                                    className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/296395.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.IsHot && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="products-edit-tooltip">
                                      Hot
                                    </Tooltip>
                                  }
                                >
                                  <a
                                    href={() => false}
                                    className="btn btn-icon btn-light btn-hover-light btn-sm mr-2"
                                  >
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/fire.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </TableCell>
                            <TableCell>{item.ShortQuantity}</TableCell>
                            <TableCell>{item.OverQuantity}</TableCell>
                            <TableCell>{item.DamagedQuantity}</TableCell>
                            <TableCell>{item.ShipmentShortQuantity}</TableCell>
                            <TableCell>{item.ShipmentOverQuantity}</TableCell>
                            <TableCell>
                              {item.ShipmentDamagedQuantity}
                            </TableCell>
                            <TableCell>{item.CasePack}</TableCell>

                            {/*     {isDashboard && ( */}
                            <TableCell>
                              <a
                                href={() => false}
                                title="Edit OSD"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={() => {
                                  showModalEditOsd(true);
                                  setRowOsd(item);
                                  setSaveOsd(false);
                                  setOverQuantity(item.ShipmentOverQuantity);
                                  setShortQuantity(item.ShipmentShortQuantity);
                                  setDamagedQuantity(
                                    item.ShipmentDamagedQuantity
                                  );
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  />
                                </span>
                              </a>
                              <> </>
                            </TableCell>
                            {/*     )} */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Modal
                      size="xl"
                      show={modalOsdEdit}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <Modal.Title>
                          Edit OSD Item / PO# : {rowOsd?.PurchaseOrder}
                        </Modal.Title>
                        <IconButton
                          aria-label="Close"
                          onClick={() => {
                            showModalEditOsd(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>Over Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Over Quantity"
                              min="0"
                              defaultValue={overQuantity}
                              onChange={(e) => {
                                setOverQuantity(e.target.value);
                              }}
                            />
                          </div>

                          <div className="col-lg-6">
                            <label>Short Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Short Quantity"
                              min="0"
                              defaultValue={shortQuantity}
                              onChange={(e) => {
                                setShortQuantity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>Damaged Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Damaged Quantity"
                              min="0"
                              defaultValue={damagedQuantity}
                              onChange={(e) => {
                                setDamagedQuantity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-light btn-elevate"
                          onClick={(e) => {
                            showModalEditOsd(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          disabled={saveEditOsd}
                          className={`btn btn-primary btn-elevate ${saveEditOsd &&
                            `spinner spinner-right spinner-white pr-15`}`}
                          onClick={async (e) => {
                            try {
                              setSaveOsd(true);
                              await PutOsd(rowOsd.Id);
                              showModalEditOsd(false);
                              await GetModel();
                              Notification("success", "Success");
                              setSaveOsd(false);
                            } catch (err) {}
                          }}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Modal>

                    {Detail?.Shipment?.ShipmentItems && (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={Detail?.Shipment?.ShipmentItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Rows per page" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="1"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Transportation Items</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div className="form-group row">
                <div className="col-lg-12 mt-5">
                  <DenseTableSm
                    rowsName={[
                      { Name: "PurchaseOrder", Title: "Purchase Order" },
                      { Name: "UnitOfMeasure", Title: "Unit Of Measure" },
                      { Name: "MiffId", Title: "Miff Id" },
                      { Name: "Sku", Title: "Sku" },
                      { Name: "Description", Title: "Description" },
                      { Name: "Quantity", Title: "UnitCount" },
                      { Name: "Weight", Title: "Weight" },
                      { Name: "Cube", Title: "Cube" },
                      {
                        Name: "IsHazmat",
                        Title: "Is Hazardous Material",
                      },
                      {
                        Name: "IsHot",
                        Title: "IsHot",
                      },
                      { Name: "ShortQuantity", Title: "ShortQuantity" },
                      { Name: "OverQuantity", Title: "OverQuantity" },
                      { Name: "DamagedQuantity", Title: "DamagedQuantity" },
                    ]}
                    rows={Detail?.Shipment?.TransportationItems || []}
                  />
                </div>
              </div>
            </Accordion.Collapse>
            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="10"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">Vendors</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="10">
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12 mt-5">
                        <DenseTableSm
                          rowsName={[
                            { Name: "Id", Title: "Id" },
                            { Name: "VendorName", Title: "Vendor" },
                            {
                              Name: "FreightChargeName",
                              Title: "Freight Charge",
                            },
                            { Name: "ContactName", Title: "Contacts" },
                            { Name: "VendorNumber", Title: "Vendor Number" },
                            { Name: "DriverName", Title: "Driver Name" },
                            { Name: "DriverPhone", Title: "Driver Phone" },
                            { Name: "TruckNumber", Title: "Truck Number" },
                            {
                              Name: "VendorInstructions",
                              Title: "Vendor Instructions",
                            },
                            { Name: "Description", Title: "Description" },
                            { Name: "Rate", Title: "Rate" },
                          ]}
                          rows={VendorsList || []}
                        />
                      </div>
                    </div>
                    <Modal show={contactModal} size="xl">
                      <Modal.Header>
                        <Modal.Title>
                          Vendor #: {vendorIdContact} Contacts
                        </Modal.Title>
                        <IconButton
                          aria-label="Close"
                          onClick={(e) => {
                            setContactModal(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group row">
                          <div className="col-lg-12 mt-5">
                            <DenseTableSm
                              rowsName={[
                                { Name: "ContactId", Title: "Id" },
                                { Name: "ContactName", Title: "Name" },
                                { Name: "Email", Title: "Email" },
                                { Name: "PhoneNumber", Title: "Phone Number" },
                                { Name: "Type", Title: "Type" },
                              ]}
                              rows={contactsVendor || []}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          onClick={() => {
                            setContactModal(false);
                          }}
                          className="btn btn-light btn-elevate w-25"
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </>
                </Accordion.Collapse>
              </div>
            )}
            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="2"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">Rates</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`h-60 card card-custom`}>
                        {/* Body */}
                        <div className="card-body pt-5 row">
                          <Table className={classes.table} size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Charge Code</TableCell>
                                <TableCell>Charge Description</TableCell>
                                <TableCell>Freight Charge</TableCell>
                                <TableCell>Bill Amount</TableCell>
                                <TableCell>Payable Amount</TableCell>
                                <TableCell>Margin</TableCell>
                                <TableCell>Margin%</TableCell>
                                <TableCell>Invoice#</TableCell>
                                <TableCell>Invoice Date</TableCell>
                                <TableCell>Vendor Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Detail?.Shipment?.CalculatedCharges.map(
                                (row) => (
                                  <TableRow>
                                    <TableCell>{row.Code}</TableCell>
                                    <TableCell>
                                      {row.ChargeDescription}
                                    </TableCell>
                                    <TableCell>
                                      {row?.FreightChargeName ?? "-"}
                                    </TableCell>
                                    <TableCell>{row.BillAmount}</TableCell>
                                    <TableCell>{row.PayableAmount}</TableCell>
                                    <TableCell>{row.Margin}</TableCell>
                                    <TableCell>
                                      {row.MarginPercentage}
                                    </TableCell>
                                    <TableCell>{row.InvoiceNumber}</TableCell>
                                    <TableCell>
                                      {row.InvoiceDate !== null
                                        ? new Date(
                                            row.InvoiceDate
                                          ).toLocaleString()
                                        : ""}
                                    </TableCell>
                                    <TableCell>{row.VendorName}</TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell />
                                <TableCell>
                                  <strong>Totals</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalReceivable}
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalPayable}
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  <strong>{Detail?.Shipment?.Revenue}</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalReceivable === 0
                                      ? 0
                                      : (
                                          (Detail?.Shipment?.Revenue /
                                            Detail?.Shipment?.TotalReceivable) *
                                          100
                                        ).toFixed(2)}
                                  </strong>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            )}
            {!isDashboard && (
              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="600"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">Drivers Details</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
            )}

            <Accordion.Collapse className="mb-5" eventKey="600">
              <div className="form-group row word-break">
                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Driver Check In
                  </div>
                  <br />
                  {IsValid(
                    "Driver Name: ",
                    ShipmentDriversInfo?.CheckIn?.DriverName
                  )}
                  {IsValid(
                    "Phone Number: ",
                    ShipmentDriversInfo?.CheckIn?.PhoneNumber
                  )}
                  {IsValid(
                    "Company Name",
                    ShipmentDriversInfo?.CheckIn?.CompanyName
                  )}
                  <div>
                    {IsValid(
                      "Pickup Number",
                      ShipmentDriversInfo?.CheckIn?.PickupNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Pickup App Date",
                      ShipmentDriversInfo?.CheckIn?.PickupAppDate
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal Dropping Trailer Number",
                      ShipmentDriversInfo?.CheckIn?.SealDroppingTrailerNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal Dropping Number",
                      ShipmentDriversInfo?.CheckIn?.SealDroppingNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal PickingUp Trailer Number",
                      ShipmentDriversInfo?.CheckIn?.SealPickingUpTrailerNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal PickingUp Number",
                      ShipmentDriversInfo?.CheckIn?.SealPickingUpNumber
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Driver Check Out
                  </div>
                  <br />
                  {IsValid(
                    "Driver Name: ",
                    ShipmentDriversInfo?.CheckOut?.DriverName
                  )}
                  {IsValid(
                    "Phone Number: ",
                    ShipmentDriversInfo?.CheckOut?.PhoneNumber
                  )}
                  {IsValid(
                    "Company Name",
                    ShipmentDriversInfo?.CheckOut?.CompanyName
                  )}
                  <div>
                    {IsValid(
                      "Pickup Number",
                      ShipmentDriversInfo?.CheckOut?.PickupNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Pickup App Date",
                      ShipmentDriversInfo?.CheckOut?.PickupAppDate
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal Dropping Trailer Number",
                      ShipmentDriversInfo?.CheckOut?.SealDroppingTrailerNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal Dropping Number",
                      ShipmentDriversInfo?.CheckOut?.SealDroppingNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal PickingUp Trailer Number",
                      ShipmentDriversInfo?.CheckOut?.SealPickingUpTrailerNumber
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Seal PickingUp Number",
                      ShipmentDriversInfo?.CheckOut?.SealPickingUpNumber
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="3"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Notes</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <>
                <div className="form-group row">
                  <div className="col-lg-12">
                    {isDashboard === true ? (
                      <DenseTableSm
                        rowsName={[
                          { Name: "Id", Title: "#" },
                          { Name: "Body", Title: "Body" },
                          { Name: "UserEmail", Title: "User Email" },
                          { Name: "CreatedBy", Title: "Created By" },
                          { Name: "CategoryName", Title: "Category" },
                          { Name: "CreatedAt", Title: "Created" },
                        ]}
                        rows={
                          Notes?.filter((word) => word.AccessLevelId === 4) ||
                          []
                        }
                      />
                    ) : (
                      <div className={classes.root}>
                        <Paper className={classes.paper}>
                          <Table
                            className={`LineItems ${classes.table}`}
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Body</TableCell>
                                <TableCell align="left">UserEmail</TableCell>
                                <TableCell align="left">CreatedBy</TableCell>
                                <TableCell align="left">CategoryName</TableCell>
                                <TableCell align="left">CreatedAt</TableCell>
                                <TableCell align="left">Access Level</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Notes?.map((row) => (
                                <TableRow key={row.name}>
                                  <TableCell align="left">{row.Id}</TableCell>
                                  <TableCell align="left">{row.Body}</TableCell>
                                  <TableCell align="left">
                                    {row.UserEmail}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CreatedBy}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CategoryName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CreatedAt}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.AccessLevelId === 5
                                      ? "Private"
                                      : row.AccessLevelId === 4
                                      ? "Public"
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="4"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Attachments</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <>
                <div className="form-group row">
                  <div className="col-lg-12 mt-5">
                    {/* Header */}
                    <div className="card-header border-bottom">
                      <h4 className="card-title font-weight-bolder text-dark">
                        Shipments Attachments
                      </h4>
                    </div>
                    {isDashboard === true ? (
                      <OrderAttachementsV2
                        id={Id}
                        isDashboard={true}
                        row={Detail?.Shipment}
                        AttachmentList={Detail?.Shipment?.Attachments}
                        Type="shipment"
                      />
                    ) : (
                      Attachments?.map((x, index) => (
                        <CardFunction
                          x={x}
                          key={index}
                          index={index}
                          id={Detail?.Shipment?.Id}
                        />
                      ))
                    )}
                    {}
                  </div>
                </div>

                {/* Modal Attachment Viewer */}
                <Modal
                  size="xl"
                  show={showAttachment}
                  onHide={() => {
                    setShowAttachment(false);
                  }}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header>
                    <Modal.Title>
                      Attachment Viewer for SM #: {Detail?.Shipment?.Id}
                    </Modal.Title>
                    <IconButton
                      aria-label="Close"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <div className="form-group row mb-5">
                        <div className="col-lg-8"></div>
                        <div
                          className="col-lg-4"
                          style={{ textAlignLast: "right" }}
                        >
                          <button
                            type="submit"
                            disabled={disabledDownload}
                            className="btn btn-primary btn-elevate"
                            onClick={() => {
                              const fileName = filePath.replace(
                                /^.*[\\\/]/,
                                ""
                              );
                              FileSaver(filePath, fileName);
                            }}
                          >
                            Download
                          </button>{" "}
                        </div>
                      </div>
                      {type === "pdf" ? (
                        <div className="form-group row" key={Math.random()}>
                          <div
                            className="col-lg-12 pdfViewer"
                            key={Math.random()}
                          >
                            <PDFViewer
                              document={{
                                url: filePath,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-group row" key={Math.random()}>
                          <div className="col-lg-12" key={Math.random()}>
                            <FileViewer
                              fileType={type}
                              filePath={filePath}
                              onError={onError}
                              className="w-100"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                      className="btn btn-light btn-elevate w-25"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </Accordion.Collapse>

            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="7"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">ACTIVITIES</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse className="mb-5" eventKey="7">
                  {DetailActivityRatesInfo !== null ? (
                    <ActivityFunction
                      Activitys={DetailActivityRatesInfo || []}
                    />
                  ) : (
                    <div className={`h-60 card card-custom`}>
                      <div className="card-body pt-5 row now-rapTable">
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          Loading Data ...
                        </div>
                      </div>
                    </div>
                  )}
                </Accordion.Collapse>
              </div>
            )}
          </>
        </Accordion>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          className="btn btn-light btn-elevate w-25"
        >
          Close
        </button>
      </Modal.Footer>
    </>
  );

  async function GetModel() {
    await fetch(`${window.$apiurl}/transload/shipment/` + Id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setDetail(a);
        setDetailShipmentInfo(a);
        setAttachmentsList(a.Shipment.Attachments);
        var b = a.Shipment.Notes;
        b.forEach((x) => {
          x.CreatedAt = momentDate(x?.CreatedAt);
        });
        setNotesList(b);
      })
      .catch((error) => {
        console.error(error);
      });
    setDetailActivityRatesInfo(null);
    fetch(window.$apiurl + `/tracking/${Id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setResult(obj);
        setDetailTrakingInfo(obj.Tracking);
        setDetailTrakingGeometryInfo(obj.Polyline);

        obj.Activities.sort(function(a, b) {
          return new Date(b.Created) - new Date(a.Created);
        });
        obj.Activities.forEach((x, i) => {
          x.id = i;
        });
        setDetailActivityRatesInfo(obj?.Activities || []);
      })
      .catch((error) => console.log("error", error));
  }

  function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) return "";
    return basename.slice(pos + 1);
  }
  async function SecureFileFetch(receiptId, attachmentId) {
    return await fetch(
      window.$apiurl +
        `/generic/attachment/secureFile/${receiptId}/${attachmentId}?isReceipt=false&isQuote=false`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        try {
          const data = res[1];
          const fileType = getExtension(data.AttachmentFile);
          setFileType(fileType.toLowerCase());
          setFilePath(data.AttachmentFile);
          setDisabledDownload(false);
        } catch (err) {}
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function CardFunction({ x, index, New, id }) {
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <p className="mb-2 d-inline card-subtitle text-primary ">
              {new Date(x?.Created).toLocaleString()}
            </p>
            <Card.Text className="mt-5 ml-5">
              <b className="text-primary mr-5 ">Download :</b>
              <b>
                <a
                  href={() => false}
                  onClick={async () => {
                    try {
                      setShowAttachment(true);
                      await SecureFileFetch(id, x.Id);
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                  role="button"
                  className="btn btn-light-primary btn-elevate py-1"
                >
                  {x?.FileName}
                </a>
              </b>

              {/* Detail Attachments */}
              <div className={"row mt-md-7"}>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Attachment Type :</b>{" "}
                  <b> {x?.Type}</b>
                </div>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Access Level :</b>{" "}
                  <b> {x?.AccessLevel}</b>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
}
export const ModelManagment = ({ row, ...props }) => {
  const [Show, setShow] = useState(false);
  const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);

  const [ShowRemarks, setShowRemarks] = useState(false);
  useEffect(() => {
    window.ReceiptId = row?.ReceiptId;
    window.ReceiptManagmetShow = true;
  }, [row?.ReceiptId]);
  function Remarks() {
    return (
      <>
        <ContainersUIProvider
          showing={ShowRemarks}
          setshowing={setShowRemarks}
          id={row?.ReceiptId}
        >
          <RemarkEditDialog />
        </ContainersUIProvider>
      </>
    );
  }

  function SimplePopover() {
    return (
      <Fragment>
        <div className="d-flex">
          <Dropdown className="dropdown-inline position-unset pr-4" alignRight>
            <Dropdown.Toggle
              className="btn btn-icon btn-light-warning btn-hover-warning btn-sm"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomTogglerYellow}
            >
              <i className="fas fa-cogs text-warning" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
              <ul className="navi navi-hover py-5">
                <li className="navi-item">
                  <a
                    href="javascript(0)"
                    className="navi-link"
                    onClick={(e) => {
                      e.preventDefault();
                      setShow(true);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-file-alt text-primary"></i>
                    </span>
                    <span className="navi-text">Detail</span>
                  </a>
                </li>
                <li className="navi-item">
                  <a
                    href="javascript(0)"
                    className="navi-link"
                    onClick={(e) => {
                      window.shipmentEditCommingFrom = "ShipmentsManagement";
                      e.preventDefault();
                      setOneMoveMasterShow(true);
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-pen text-success"></i>
                    </span>
                    <span className="navi-text">Edit</span>
                  </a>
                </li>
                <div className="dropdown-divider"></div>
                <ChangeStatus ReceiptId={row?.id} row={row} />
              </ul>
            </Dropdown.Menu>
          </Dropdown>
          <MenuIndex row={row} modalActions={props?.modalActions} />
        </div>
      </Fragment>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={Show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={row?.id} setShow={setShow.bind()} />
      </Modal>
      {ShowRemarks && <Remarks />}
      <Modal
        size="xl"
        show={OneMoveMasterShow}
        onHide={() => {
          setOneMoveMasterShow(false);
        }}
      >
        <OneMoveMaster Show={setOneMoveMasterShow} statSHipmentId={row.id} />
      </Modal>
      <SimplePopover />
    </>
  );
};

function ChangeStatus({ ReceiptId, row }) {
  const [ModelShowing, setModelShowing] = useState(false);
  const [RollBackLoadingPost, setRollBackLoadingPost] = useState(false);
  const [ModelTitle, setModelTitle] = useState("");
  const [ModelText, setModelText] = useState("");
  const [NotesText, setNotesText] = useState("");
  const [BackStatusText, setBackStatusText] = useState("");
  const [StatusListOptions, setStatusListOptions] = useState([]);
  const [GlobalStatusListOptions, setGlobalStatusListOptions] = useState([]);
  const [StatusSelectedRolledBack, setStatusSelectedRolledBack] = useState(
    null
  );
  const [ReceiptVendorList, setReceiptVendorList] = useState([]);
  const [ReceiptVendorRemoved, setReceiptVendorRemoved] = useState([]);
  const [ModelShowingConfirmation, setModelShowingConfirmation] = useState(
    false
  );
  const [
    CancelModelShowingConfirmation,
    setModelShowingConfirmationCancel,
  ] = useState(false);
  const [
    CancelModelShowingConfirmationOsd,
    setModelShowingConfirmationCancelOsd,
  ] = useState(false);
  const Placeholder = (props: PlaceholderProps<ColourOption>) => {
    return <components.Placeholder {...props} />;
  };
  async function GetListOfPreviewsStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/Shipments/GetShipmentVendors/${ReceiptId}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setReceiptVendorList(data);
      })
      .catch((error) => console.log("error", error));
    var statusList = JSON.parse(JSON.stringify(window.ShipmentStatus));
    setGlobalStatusListOptions(window.ShipmentStatus || []);

    var list = statusList.map((a) => String(a.StatusId));

    console.log("list", list);
    const index = list.indexOf(String(window.$ShipmentManagementStatus));
    if (index > -1) {
      statusList.splice(index, statusList.length);
      setStatusListOptions(statusList);
    }
  }
  const [OdsShipmentSelectedId, setOdsShipmentSelectedId] = useState(null);
  async function NextStatus(text) {
    if (text === `"IsCancel":true`) {
      if (NotesText === "") {
        Notification("error", "please fill cencled note");
        return;
      } else {
        await fetch(
          `${window.$apiurl}/transload/shipments/addnote/${ReceiptId}`,
          {
            method: "post",
            withCredentials: true,
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `bearer ${db.read().getState().Token}`,
              "X-FP-API-KEY": "iphone",
              "Content-Type": "application/json",
            },
            body: `{"body":"${NotesText}","CategoryId":12,"accessLevelId":5}`,
          }
        )
          .then((response) => {
            if (response.status !== 200) {
              Notification("error", "Note Note Add");
            }
          })
          .catch((error) => {
            console.error(error);
          });
        await fetch(`${window.$apiurl}/ShipmentActions/updateSingleStatus`, {
          method: "post",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
          body: `{"Id":${ReceiptId},${text}}`,
        })
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("infoClick", result[1].Message);
              } else {
                if (result[0] === 200) {
                  if (text.indexOf("IsNext") > -1)
                    Notification("success", "Next Status is successful");
                  else if (text.indexOf("IsRollBack") > -1)
                    Notification("success", "Roll Back Status is successful");
                  else if (text.indexOf("IsCancel") > -1)
                    Notification("success", "Cancel Order is successful");
                }
                document.getElementById("ReLoadTableId").click();
              }
            } else {
              if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("errorClick", result[1].Message);
              } else {
                Notification("errorClick", "invalid request");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
        setNotesText("");
        setModelShowingConfirmationCancel(false);
      }
    } else {
      await fetch(`${window.$apiurl}/ShipmentActions/updateSingleStatus`, {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: `{"Id":${ReceiptId},${text}}`,
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (text.indexOf("IsNext") > -1)
              Notification("success", "Next Status is successful");
            else if (text.indexOf("IsRollBack") > -1)
              Notification("success", "Roll Back Status is successful");
            else if (text.indexOf("IsCancel") > -1)
              Notification("success", "Cancel Order is successful");

            document.getElementById("ReLoadTableId").click();
          } else {
            if (result[1]?.Message !== undefined) {
              Notification("errorClick", result[1]?.Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setModelShowingConfirmationCancel(false);
      return;
    }
  }

  function Model() {
    return (
      <Modal
        size="sm"
        show={ModelShowing}
        onHide={() => {
          setModelShowing(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>{ModelTitle}</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setModelShowing(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>{ModelTitle} Confirm</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setModelShowing(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => {
              NextStatus(`"${ModelText}":true`);
              setModelShowing(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={ModelShowingConfirmation}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>Roll Back Confirmation</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setStatusSelectedRolledBack(null);
              setReceiptVendorRemoved([]);
              setModelShowingConfirmation(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <label>Select Status:</label>
              <Select
                isSearchable={true}
                isClearable
                components={{ Placeholder }}
                placeholder={"Auto Roll Back"}
                options={StatusListOptions.map((a) => {
                  return { value: a.StatusId, label: a.Name };
                })}
                onChange={(e) => {
                  if (e !== null) {
                    setStatusSelectedRolledBack(e);
                  } else {
                    setStatusSelectedRolledBack(null);
                  }
                }}
              />
              {GlobalStatusListOptions.filter((a) => a.Name === "Assigned")[0]
                ?.Position >
              GlobalStatusListOptions.filter(
                (a) => a.Name === StatusSelectedRolledBack?.label
              )[0]?.Position ? (
                <>
                  <label className="mt-5">Select Vendors to remove</label>
                  {ReceiptVendorList.map((item, index) => (
                    <CheckboxLargeWB
                      className="font-size-md"
                      name={`Trucker${index}`}
                      text={item.VendorAddress.CompanyName}
                      onChange={(e) => {
                        var list = [];
                        list = ReceiptVendorRemoved;
                        if (e.target.checked) {
                          list.push(item.VendorId);
                          setReceiptVendorRemoved(list || []);
                        } else {
                          var index = list.indexOf(item.VendorId);
                          setReceiptVendorRemoved(
                            list.slice(index, index) || []
                          );
                        }
                      }}
                    />
                  ))}
                </>
              ) : (
                ""
              )}
              <br></br>
              <br></br>
              <label>Roll Back Note:</label>
              <textarea
                className="form-control mb-5"
                rows="4"
                id="BackStatusNotes"
                onBlur={(e) => setBackStatusText(e.target.value)}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            disabled={RollBackLoadingPost}
            onClick={(e) => {
              setModelShowingConfirmation(false);
            }}
          >
            Cancel
          </button>
          <button
            className="ml-3 btn btn-primary"
            onClick={(e) => {
              if (
                BackStatusText === null ||
                BackStatusText === "" ||
                BackStatusText === undefined
              ) {
                Notification("error", "please fill note");
                return;
              }
              setRollBackLoadingPost(true);
              row = {
                Id: ReceiptId,
                StatusId:
                  StatusSelectedRolledBack === null
                    ? null
                    : StatusSelectedRolledBack.value,
                VendorsIds: ReceiptVendorRemoved,
                KeepChanges: false,
                Note: BackStatusText,
              };
              fetch(`${window.$apiurl}/ShipmentActions/RollBack`, {
                method: "post",
                withCredentials: true,
                headers: {
                  Accept: "application/json, text/plain, */*",
                  Authorization: `bearer ${db.read().getState().Token}`,
                  "X-FP-API-KEY": "iphone",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(row),
              })
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("infoClick", result[1].Message);
                      setModelShowingConfirmation(false);
                    } else {
                      setModelShowingConfirmation(false);
                      document
                        .getElementById("refreshShipmentsException")
                        .click();
                      Notification("success", "Roll Back Status is successful");
                      document.getElementById("ReLoadTableId").click();
                    }
                    setRollBackLoadingPost(false);
                  } else {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("errorClick", result[1].Message);
                      setModelShowingConfirmation(false);
                    } else {
                      Notification("errorClick", "invalid request");
                    }
                    setRollBackLoadingPost(false);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setRollBackLoadingPost(false);
                });
            }}
          >
            Confirm
            {RollBackLoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        id="ConfirmCancelModal"
        show={CancelModelShowingConfirmation}
        aria-labelledby="example-modal-sizes-title-lg"
        className="cancel-Exceptions"
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure that you want to{" "}
            {row.IsCancelled === true ? "UnCancel" : "Cancel"} this Shipment ?
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setModelShowingConfirmationCancel(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <label>
            {row.IsCancelled === true ? "UnCancel" : "Cancel"} Confirmation Note
          </label>
          <textarea
            className="form-control mb-5"
            rows="4"
            id="CancelNotes"
            onBlur={(e) => setNotesText(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModelShowingConfirmationCancel(false);
            }}
          >
            No
          </Button>

          <Button
            variant="secondary"
            className="btn btn-light btn-elevate ml-5"
            onClick={(e) => {
              NextStatus(`"IsCancel":true`);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* oSDcONFIRMModal */}
      <Modal
        size="lg"
        id="ConfirmCancelModal"
        show={CancelModelShowingConfirmationOsd}
        aria-labelledby="example-modal-sizes-title-lg"
        className="cancel-Exceptions"
      >
        <Modal.Header>
          <Modal.Title>Confirm Osd</Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setModelShowingConfirmationCancelOsd(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          Are you sure that you want to Confirm Osd for this Shipment?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModelShowingConfirmationCancelOsd(false);
            }}
          >
            No
          </Button>

          <Button
            variant="secondary"
            className="btn btn-light btn-elevate ml-5"
            onClick={(e) => {
              //setModelShowingConfirmationCancelOsd(false);
              //OdsShipmentSelectedId
              fetch(
                `${window.$apiurl}/shipments/ConfirmOsd/${OdsShipmentSelectedId}`,
                {
                  method: "post",
                  withCredentials: true,
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: `bearer ${db.read().getState().Token}`,
                    "X-FP-API-KEY": "iphone",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("infoClick", result[1].Message);
                      setModelShowingConfirmationCancelOsd(false);
                      document.getElementById("ReLoadTableId").click();
                    } else {
                      setModelShowingConfirmationCancelOsd(false);
                      Notification("success", "Osd Confirmed successfully");
                      document.getElementById("ReLoadTableId").click();
                    }
                  } else {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("errorClick", result[1].Message);
                      setModelShowingConfirmationCancelOsd(false);
                    } else {
                      Notification("errorClick", "invalid request");
                    }
                  }
                })
                .catch((error) => {
                  console.error(error);
                });
              //NextStatus(`"IsCancel":true`);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* End Osd Confirm Modal */}
      {/* end ConfirmModal */}
      <Model />
      {window.$ShipmentManagementStatus !== "" && (
        <>
          <li className="navi-item">
            <a
              href="javascript(0)"
              className="navi-link"
              onClick={(e) => {
                e.preventDefault();
                if (!document.getElementById(ReceiptId).checked) {
                  document.getElementById(ReceiptId).click();
                }
                if (window.$ShipmentManagementStatus === 63) {
                  NextStatus(`"IsNext":true`);
                  setModelShowingConfirmationCancel(false);
                } else {
                  setTimeout(() => {
                    for (
                      let i = 0;
                      i < document.getElementsByTagName("i").length;
                      i++
                    ) {
                      if (
                        document
                          .getElementsByTagName("i")
                          [i].classList.contains("flaticon-medical")
                      ) {
                        document
                          .getElementsByTagName("i")
                          [
                            i
                          ].parentElement.parentElement.parentElement.parentElement.parentNode.nextSibling.click();
                      }
                    }
                  }, 500);
                }
              }}
            >
              <span className="navi-icon">
                <i className="flaticon2-right-arrow text-primary"></i>
              </span>
              <span className="navi-text">Next Status</span>
            </a>
          </li>
          <li className="navi-item">
            <a
              href="javascript(0)"
              className="navi-link"
              onClick={(e) => {
                e.preventDefault();
                GetListOfPreviewsStatus();
                setModelShowing(false);
                setModelShowingConfirmation(true);
              }}
            >
              <span className="navi-icon">
                <i className="flaticon2-left-arrow text-warning"></i>
              </span>
              <span className="navi-text">Back Status</span>
            </a>
          </li>
        </>
      )}
      {window.$ShipmentManagementStatus !== "" && (
        <div className="dropdown-divider"></div>
      )}
      <li className="navi-item">
        <a
          href="javascript(0)"
          className="navi-link"
          onClick={(e) => {
            e.preventDefault();
            setModelShowing(false);
            setModelShowingConfirmationCancel(true);
          }}
        >
          <span className="navi-icon">
            <i className="fas fa-times-circle text-danger"></i>
          </span>
          <span className="navi-text">
            {row.IsCancelled === true ? "UnCancel" : "Cancel"}
          </span>
        </a>
      </li>
      {row.OsdConfirmed === true && (
        <li className="navi-item">
          <a
            href="javascript(0)"
            className="navi-link"
            onClick={(e) => {
              e.preventDefault();
              setModelShowing(false);
              setOdsShipmentSelectedId(row.Id);
              setModelShowingConfirmationCancelOsd(true);
            }}
          >
            <span className="navi-icon">
              <i className="fas fa-check text-success"></i>
            </span>
            <span className="navi-text">Confirm OSD</span>
          </a>
        </li>
      )}
    </>
  );
}

function statusBuild(Name, a) {
  if (Name === "New") return a?.StatusNewDate;
  else if (Name === "Appointed") return a?.StatusAppointedDate;
  else if (Name === "Assigned") return a?.StatusBookedDate;
  else if (Name === "Arrived Destination Hub")
    return a?.StatusArrivedDestinationHubDate;
  else if (Name === "Booked") return a?.StatusBookedDate;
  else if (Name === "Closed") return a?.StatusClosedDate;
  //else if (Name === "Delivered") return a?.StatusDeliveredDate;
  else if (Name === "StatusDeliveredToConsigneeDate")
    return a?.StatusDeliveredToConsigneeDate;
  else if (Name === "Dispatched") return a?.StatusDispatchedDate;
  else if (Name === "In Gate at Origin Hub")
    return a?.StatusInGateAtOriginHubDate;
  else if (Name === "In Transit") return a?.StatusInTransitDate;
  else if (Name === "Loaded") return a?.StatusLoadedFromOurWarehouseDate;
  else if (Name === "Loading") return a?.StatusLoadingFromOurWarehouseDate;
  else if (Name === "Picked Up") return a?.StatusPickedUpDate;
  else if (Name === "Quoted") return a?.StatusQuotedDate;
  else if (Name === "Released") return a?.StatusReleasedDate;
  else if (Name === "Sent") return a?.StatusSentDate;
  else if (Name === "Terminated") return a?.StatusTerminatedDate;
  else if (Name === "Planned") return a?.StatusPlannedDate;
  //else if (Name === "Shipping Arrival Date") return a?.ShippingArrivalDate;
  //else if (Name === "Trailler Drop Date") return a?.TraillerDropDate;
  //else if (Name === "Checked In Date") return a?.StatusCheckedInDate;
  else if (Name === "Notify Trucker") return a?.StatusNotifyTrucker;
}
