import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  ARAccount,
  FilterAccount,
  HistoricalLanes,
  PerformanceAccount,
  RevenueAccount,
} from "../widgets";
import { Row } from "react-bootstrap";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function AccountManagementDashboard() {
  const [initFilter, setInitFilter] = useState({
    Department: "",
    type: "revenue",
    AccountManager: "",
    Account: db?.read()?.getState()?.User?.IsCustomer
      ? db?.read()?.getState()?.User?.Customers?.length > 0
        ? db?.read()?.getState()?.User?.Customers[0]?.Id
        : ""
      : "",
    Mode: "",
    MTD: "Custom",
    StartDate: null,
    EndDate: null,
  });
  const [MetricsList, setMetricsList] = useState([]);
  const [LansList, setLansList] = useState([]);
  const [ReceivablesList, setReceivablesList] = useState(null);
  const [metricsData, setMetricsData] = useState(null);
  const [loadingMetrics, setloadingMetrics] = useState(false);
  const [loadingOnTime, setloadingOnTime] = useState(false);
  const [loadingAR, setloadingAR] = useState(false);
  const [loadingLanes, setloadingLanes] = useState(false);
  const [NameSelected, setNameSelected] = useState("Gross Revenue");
  async function getMetricsInfo(props) {
    setloadingOnTime(true);
    await fetch(
      `${window.$apiurl}/dashboard/performance/ontime?customerId=${props?.Account}&modeId=${props?.Mode}&departmentId=${props?.Department}&accountManagerId=${props?.AccountManager}&startDate=${props?.StartDate}&endDate=${props?.EndDate}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setMetricsList(a);
        setInitFilter(props);
        setloadingOnTime(false);
      })
      .catch((error) => {
        setloadingOnTime(false);

        console.error(error);
      });
  }
  async function getLanesInfo(props) {
    setLansList([]);
    setloadingLanes(true);
    await fetch(
      `${window.$apiurl}/dashboard/performance/lanes?customerId=${props?.Account}&modeId=${props?.Mode}&departmentId=${props?.Department}&accountManagerId=${props?.AccountManager}&startDate=${props?.StartDate}&endDate=${props?.EndDate}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setLansList(a);
        setloadingLanes(false);
      })
      .catch((error) => {
        console.error(error);
        setloadingLanes(false);
      });
  }
  async function getReceivablesInfo(props) {
    setloadingAR(true);
    await fetch(
      `${window.$apiurl}/dashboard/performance/receivables?customerId=${props?.Account}&modeId=${props?.Mode}&departmentId=${props?.Department}&accountManagerId=${props?.AccountManager}&startDate=${props?.StartDate}&endDate=${props?.EndDate}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setReceivablesList(a);
        setloadingAR(false);
      })
      .catch((error) => {
        console.error(error);
        setloadingAR(false);
      });
  }
  async function getMetricsData(props) {
    setloadingMetrics(true);
    await fetch(
      `${window.$apiurl}/api/dashboard/performance/metrics?type=${props?.type}&customerId=${props?.Account}&modeId=${props?.Mode}&departmentId=${props?.Department}&accountManagerId=${props?.AccountManager}&startDate=${props?.StartDate}&endDate=${props?.EndDate}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setMetricsData(a);
        setloadingMetrics(false);
      })
      .catch((error) => {
        console.error(error);
        setloadingMetrics(false);
      });
  }
  useEffect(() => {
    if (db?.read()?.getState()?.User?.IsCustomer) {
      getMetricsInfo(initFilter);
      getLanesInfo(initFilter);
      getReceivablesInfo(initFilter);
      getMetricsData(initFilter);
    }
  }, []);

  return (
    <>
      <div className="col-xl-12">
        <FilterAccount
          className="card-stretch gutter-b"
          setInitFilter={setInitFilter}
          initFilter={initFilter}
          getMetricsInfo={getMetricsInfo}
          getMetricsData={getMetricsData}
          getLanesInfo={getLanesInfo}
          getReceivablesInfo={getReceivablesInfo}
          setNameSelected={setNameSelected}
        />
      </div>
      {initFilter.Account === "" ? (
        <div className="col-xl-12">
          <div className="card card-custom">
            <div className="card-body d-flex flex-column">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                  <h4>Please Select Account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="col-xl-12">
            <RevenueAccount
              className="card-stretch gutter-b"
              data={metricsData}
              getApi={getMetricsData}
              initFilter={initFilter}
              setInitFilter={setInitFilter}
              loadingMetrics={loadingMetrics}
              setNameSelected={setNameSelected}
              NameSelected={NameSelected}
            />
          </div>
          <div className="col-xl-12">
            <Row>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <PerformanceAccount
                  className="card-stretch gutter-b"
                  MetricsList={MetricsList}
                  loadingOnTime={loadingOnTime}
                />
              </div>
              <div className="col-xl-2 col-lg-6 col-md-12">
                <ARAccount
                  className="card-stretch gutter-b"
                  ReceivablesList={ReceivablesList}
                  loadingAR={loadingAR}
                />
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12">
                <HistoricalLanes
                  className="card-stretch gutter-b"
                  LansList={LansList}
                  loadingLanes={loadingLanes}
                />
              </div>
            </Row>
          </div>
        </>
      )}
    </>
  );
}
