import React from 'react'
import { Form, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";

export function ShippingSetups() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <div class='row'>
                        <div class='col-12 mb-5'>

                            <h3 className="card-title font-weight-bolder">Shipping Setups</h3>



                            <span class="label label-lg label-light-primary label-inline">

                                <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Manage Ship-to Contacts</Link>
                            </span>
                            <p className='mt-2'> Maintain a list, for quick attachment to orders, of frequently used Ship-to contacts.</p>


                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="quick-panel-tooltip">Create and edit retailers for each of your customers with this Shipping Setups option. Be aware that Retailer IDs are used to designate orders created through EDI or API connections.</Tooltip>}
                            >

                                <span class="topbar-item label label-lg label-light-primary label-inline" ata-toggle="tooltip" data-placement="right">

                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Manage Retailers</Link>

                                    <div
                                        className="btn btn-icon btn-clean btn-lg mr-1"
                                        id="kt_quick_panel_toggle"
                                    >
                                        <span className="svg-icon svg-icon-xl svg-icon-primary">

                                        </span>
                                    </div>
                                </span>
                            </OverlayTrigger>
                            <p className='mt-2'>
                                Maintain a list of retailers for each customer, for quick selection in Find Orders.
                                 </p>

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="#">Manage 'MarkFor' Lists</Link>
                            </span>

                            <p className='mt-2'>Maintain, by customer, various lists of MarkFor IDs, for quick selection in Find Orders. For example, if each day of the week you ship to the same destinations for a customer, you could create five lists, one for each day of the week.</p>

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="#">Import Contacts</Link>
                            </span>
                            <p className='mt-2'>
                                Import a file of contacts. </p>

                            <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="quick-panel-tooltip">Please note that ship method mappings are used by API and EDI connections for incoming orders.</Tooltip>}
                            >

                                <span class="topbar-item label label-lg label-light-primary label-inline" ata-toggle="tooltip" data-placement="right">

                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Manage Ship Method Mappings</Link>

                                    <div
                                        className="btn btn-icon btn-clean btn-lg mr-1"
                                        id="kt_quick_panel_toggle"
                                    >
                                        <span className="svg-icon svg-icon-xl svg-icon-primary">

                                        </span>
                                    </div>
                                </span>
                            </OverlayTrigger>
                            <p className='mt-2'>
                                Maintain, by customer, a list of mappings between an external systems ship methods and the standard ship methods within WMS.</p>
                                <OverlayTrigger
                                placement="right"
                                overlay={<Tooltip id="quick-panel-tooltip">Add or modify your third-party-billing contacts with this Shipping Setups option. Please note that 3rd-Party-Billing is typically used for pallet in/out workflows and large shipments that require Bills of Lading.</Tooltip>}
                            >

                                <span class="topbar-item label label-lg label-light-primary label-inline" ata-toggle="tooltip" data-placement="right">

                                    <Link className='mt-1 font-weight-bold' data-toggle="tooltip" data-placement="right" to="#">Manage 3rd-Party-Billing Contacts</Link>

                                    <div
                                        className="btn btn-icon btn-clean btn-lg mr-1"
                                        id="kt_quick_panel_toggle"
                                    >
                                        <span className="svg-icon svg-icon-xl svg-icon-primary">

                                        </span>
                                    </div>
                                </span>
                            </OverlayTrigger>
                            <p className='mt-2'>
                                When exporting package information for UPS, FedEx and similar shippers, specify 3rd-party billing information to use for various shipper account numbers.</p>

                        </div>

                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}
