import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../../../modules/Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
      Statistiques: [],
      LocationItems: [],
    };
    this.state = { data: obj };
  }

  async componentDidMount() {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf("/") + 1);
    window.$smIdOutbound = id;
    await fetch(window.$apiurl + "/pallets/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const statusCode = response.status;
      if (statusCode !== 200) {
        Notification("error", "No data found !");
      }
      const json = await response.json();
      this.setState({ data: json?.pallet });
    });
  }

  render() {
    const formData = this.state.data;
    return (
      <>
        <Helmet>
          <title>eMPower | Pallet Manifest Form</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-0 px-0 bl-form border-0">
            <div className="card-body py-0 px-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-0 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-4">
                    <div className="col-md-12 px-0"></div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12">
                      <div className="row">
                        <table
                          className="w-50 mt-2"
                          style={{ fontSize: "22px" }}
                        >
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Pallet #</b> {window.$smIdOutbound}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Customer Name:</b> {formData?.CustomerName}
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Consignee Name:</b> {formData?.ConsigneeName}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50" style={{ fontSize: "22px" }}>
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Shipper Name:</b> {formData?.ShipperName}
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Shipper Phone:</b>{" "}
                                {formData?.ShipperPhoneNumber}
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Shipper Email:</b> {formData?.ShipperEmail}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 ">
                      {" "}
                      <div class="react-bootstrap-table table-responsive">
                        <table className="w-100" style={{ fontSize: "22px" }}>
                          <thead className="justify-content-center">
                            <tr>
                              <th className="font-weight-boldest align-text-top">
                                Container#
                              </th>
                              <th className="font-weight-boldest align-text-top">
                                PO#
                              </th>
                              <th className="font-weight-boldest align-text-top">
                                SKU
                              </th>
                              <th className="font-weight-boldest align-text-top">
                                Cartons
                              </th>
                              <th className="font-weight-boldest align-text-top pl-5">
                                Description
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.ItemsList?.length > 0 &&
                              formData.ItemsList?.map((item) => (
                                <tr>
                                  <td className="align-text-top">
                                    <b> {item?.ContainerNumber}</b>
                                  </td>
                                  <td className="align-text-top">
                                    {item.PurchaseOrder}
                                  </td>

                                  <td className="align-text-top">{item.Sku}</td>
                                  <td className="align-text-top">
                                    {item.Quantity}
                                  </td>
                                  <td className="align-text-top">
                                    {item.Description}
                                  </td>
                                </tr>
                              ))}

                            <tr>
                              <td />
                              <td />
                              <td className="font-weight-boldest  align-text-top">
                                <b>Total </b>
                              </td>
                              <td className="font-weight-boldest  align-text-top">
                                <b>
                                  {formData.ItemsList?.reduce(
                                    (a, b) => +a + +b.Quantity,
                                    0
                                  )}
                                </b>
                              </td>
                              <td />
                            </tr>
                          </tbody>
                        </table>
                      </div>{" "}
                    </div>
                    <div className="col-md-12 mt-5 pt-5">
                      <div className="row">
                        <div className="col-12 my-3 text-center">
                          <h1
                            style={{
                              fontSize: "7rem",
                              fontWeight: "bold",
                              color: "#113059",
                            }}
                          >
                            {formData?.ConsigneeComapanyName}
                          </h1>
                        </div>
                        <div className="col-12 my-3 text-center">
                          <QRCode
                            value={`Pallet ID : ${window.$smIdOutbound}`}
                            size={240}
                            fgColor="#113059"
                          />
                        </div>
                        <div className="col-12 my-7 text-center">
                          <h1
                            style={{
                              fontSize: "10rem",
                              fontWeight: "bold",
                              color: "#113059",
                            }}
                          >
                            {window.$smIdOutbound}
                          </h1>
                          <h1
                            style={{
                              fontSize: "5rem",
                              fontWeight: "bold",
                              color: "#113059",
                            }}
                          >
                            CTN:{formData.Unitcount}
                          </h1>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function TransloadPalletManifest() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden border-0 px-0">
      <div className="card-body pt-5">
        <ComponentToPrint ref={componentRef} />
        <div className="container">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print Form
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
