import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../modules/Notification";
import MockUtils from "./mock.utils";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockManageCmsNews(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  async function GetModel() {
    return await fetch(window.$apiurl + `/cms/news`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostModel(someData) {
    console.log("someData", someData);
    var inputElement1 = "";
    var inputElement2 = "";
    var formData = new FormData();

    if (someData.CategoryIds.filter((a) => a === 3).length === 0) {
      inputElement1 = document.getElementById("MainImageInput");
      inputElement2 = document.getElementById("ThumbnailImageInput");
      if (
        inputElement1.files[0] !== undefined &&
        inputElement2.files[0] !== undefined
      ) {
        someData.VideoEmbed = null;
      }
    }

    if (
      !someData.VideoEmbed &&
      someData.CategoryIds.filter((a) => a === 3).length === 0
    ) {
      if (inputElement1.files[0] !== undefined) {
        formData.append(
          "File",
          inputElement1.files[0],
          inputElement1.value.split("\\")[2]
        );
      }
      if (inputElement2.files[0] && !inputElement1.files[0]) {
        formData.append("IsMain", false);
        formData.append("IsThumbnail", true);
      }
      if (inputElement2.files[0] !== undefined) {
        formData.append(
          "File2",
          inputElement2.files[0],
          inputElement2.value.split("\\")[2]
        );
      }
    } else {
      if (someData.CategoryIds.filter((a) => a === 3).length === 0) {
        if (inputElement2.files[0] !== undefined) {
          formData.append(
            "File",
            inputElement2.files[0],
            inputElement2.value.split("\\")[2]
          );
        }
      }
    }
    if (someData.Title !== "") {
      formData.append("Title", someData.Title);
    }
    if (someData.SubTitle !== "" && someData.SubTitle !== null) {
      formData.append("SubTitle", someData.SubTitle);
    }
    if (someData.TeaserText !== "" && someData.TeaserText !== null) {
      formData.append("TeaserText", someData.TeaserText.toString());
    }
    formData.append("MenuId", someData.MenuId);

    if (someData.Body !== "" && someData.Body !== null) {
      formData.append("Body", someData.Body.toString());
    }
    if (someData.CategoryIds !== null && someData.CategoryIds !== undefined) {
      formData.append("CategoryIds", someData.CategoryIds);
    }
    if (someData.Published !== false) {
      formData.append("Published", someData.Published);
    }
    if (someData.Featured !== false) {
      formData.append("Featured", someData.Featured);
    }
    if (someData.VideoEmbed !== null && someData.VideoEmbed !== undefined) {
      formData.append("VideoEmbed", someData.VideoEmbed);
    }
    return fetch(window.$apiurl + "/cms/news", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: formData,
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        //console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/cms/news/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  mock.onPost("api/ManageCmsNews").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Title = "",
      SubTitle = "",
      TeaserText = "",
      Body = "",
      Published = false,
      Featured = false,
      CategoryIds = [],
      MainImg = null,
      VideoEmbed = null,
      MenuId = null,
    } = customer;

    const newCustomer = {
      Title,
      MainImg,
      SubTitle,
      TeaserText,
      Body,
      Published,
      Featured,
      CategoryIds,
      VideoEmbed,
      MenuId,
    };
    var postCustomerProfile = await PostModel(newCustomer);
    if (postCustomerProfile.Id !== undefined) {
      Notification("success", "Cms Article has been added successfully");
      postCustomerProfile.id = postCustomerProfile.Id;
      window.$cmsStatus = 200;
      customerTableMock.push(newCustomer);
      return [
        200,
        {
          customer: newCustomer,
          message: postCustomerProfile.Message,
          success: true,
        },
      ];
    } else {
      if (postCustomerProfile.Message) {
        Notification("error", postCustomerProfile.Message);
        window.$cmsStatus = 400;
      }
    }
  });

  mock.onPost("api/ManageCmsNews/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetModel();
    if (queryParams.filter.CategoryName) {
      customerTableMock = customerTableMock.filter(a=>{
        var listofCatgName = [];
        a.Categories.map(i=>listofCatgName.push(i.Name));
        return listofCatgName.includes(queryParams.filter.CategoryName) 
      })
    }
    if (window.$CMSManagementsearchText) {  
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Title)
            .trim()
            .toLowerCase()
            .includes(
              String(window.$CMSManagementsearchText)
                .trim()
                .toLowerCase()
            ) ||
          String(word.SubTitle)
            .trim()
            .toLowerCase()
            .includes(
              String(window.$CMSManagementsearchText)
                .trim()
                .toLowerCase()
            )||
            String(word.id)
              .trim()
              .toLowerCase()
              .includes(
                String(window.$CMSManagementsearchText)
                  .trim()
                  .toLowerCase()
              )
      );
    }
    if (queryParams.filter.IsPublished !== undefined) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.Published).includes(String(queryParams.filter.IsPublished))
      );
    }
    if (queryParams.filter.IsFeatured !== undefined) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.Featured).includes(
          String(queryParams.filter.IsFeatured || false)
        )
      );
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.CategoryName;
    delete queryParams.filter.searchText;
    delete queryParams.filter.IsPublished;
    delete queryParams.filter.IsFeatured;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageCmsNews/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });
  async function PutModel(someData, id) {
    var inputElement1 = "";
    var inputElement2 = "";
    var formData = new FormData();
    if (someData.CategoryIds.filter((a) => a === 3).length === 0) {
      inputElement1 = document.getElementById("MainImageInput");
      inputElement2 = document.getElementById("ThumbnailImageInput");
      try {
        if (
          inputElement1.files[0] !== undefined &&
          inputElement2.files[0] !== undefined
        ) {
          someData.VideoEmbed = null;
        }
      } catch (err) {}
    }

    if (
      !someData.VideoEmbed &&
      someData.CategoryIds.filter((a) => a === 3).length === 0
    ) {
      if (inputElement1.files[0] !== undefined) {
        formData.append(
          "File",
          inputElement1.files[0],
          inputElement1.value.split("\\")[2]
        );
      }
      if (inputElement2.files[0] && !inputElement1.files[0]) {
        formData.append("IsMain", false);
        formData.append("IsThumbnail", true);
      }
      if (inputElement2.files[0] !== undefined) {
        formData.append(
          "File2",
          inputElement2.files[0],
          inputElement2.value.split("\\")[2]
        );
      }
    } else {
      if (someData.CategoryIds.filter((a) => a === 3).length === 0) {
        if (inputElement2.files[0] !== undefined) {
          formData.append(
            "File",
            inputElement2.files[0],
            inputElement2.value.split("\\")[2]
          );
        }
      }
    }

    if (someData.Title !== "") {
      window.$falseModalCms = false;
      formData.append("Title", someData.Title);
    }
    if (someData.SubTitle !== "" && someData.SubTitle !== null) {
      formData.append("SubTitle", someData.SubTitle);
    }
    if (someData.TeaserText !== "" && someData.TeaserText !== null) {
      formData.append("TeaserText", someData.TeaserText.toString());
    }
    if (someData.Body !== "" && someData.Body !== null) {
      formData.append("Body", someData.Body.toString());
    }
    if (someData.CategoryIds !== null && someData.CategoryIds !== undefined) {
      formData.append("CategoryIds", someData.CategoryIds);
    }
    if (someData.Published !== null && someData.Published !== undefined) {
      formData.append("Published", someData.Published);
    }
    if (someData.Featured !== null && someData.Featured !== undefined) {
      formData.append("Featured", someData.Featured);
    }
    if (someData.VideoEmbed !== null && someData.VideoEmbed !== undefined) {
      formData.append("VideoEmbed", someData.VideoEmbed);
    }
    formData.append("MenuId", someData.MenuId);

    return await fetch(window.$apiurl + "/cms/news/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: formData,
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  async function GetUser(id) {
    return await fetch(window.$apiurl + "/cms/newsdetails/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  mock.onPost("api/ManageCmsNews/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageCmsNews\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCmsNews\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ManageCmsNews\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCmsNews\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    await PutModel(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    Notification("success", "Item CMS has been updated successfully");
    return [200];
  });

  mock.onDelete(/api\/ManageCmsNews\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCmsNews\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
