import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Notification } from "../../../../../../modules/Notification";
import * as Yup from "yup";
import Select from 'react-select';
import axios from "axios"
import {
  Input,
  CheckboxLarge,
} from "../../../../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  Name: Yup.string()
    .min(1, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("EQUIPMENTS is required"),
  EquipmentTypeId: Yup.string()
    .required("Equipment Type is required"),
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [Refrigerated, setRefrigerated] = useState(false);
  const [Status, setStatus] = useState(false);
  const [ServicesList, setServicesList] = useState([]);
  const [ServicesListOption, setServicesListOption] = useState([]);
  const [ModesList, setModesList] = useState([]);
  const [ModesListOption, setModesListOption] = useState([]);
  const [ServiceLevelsList, setServiceLevelsList] = useState([]);
  const [ServiceLevelsListOption, setServiceLevelsListOption] = useState([]);
  const [EquipmentTypesList, setEquipmentTypesList] = useState([]);
  const [EquipmentTypesListOption, setEquipmentTypesListOption] = useState([]);
  const [ModeId, setModeId] = useState(null);
  const [ServiceId, setServiceId] = useState(null);
  const [ServiceLevelId, setServiceLevelId] = useState(null);
  const [EquepmentTypeId, setEquepmentTypeId] = useState(null);

  useEffect(() => {
    setRefrigerated(customer.Refrigerated || false);
    setStatus(customer.IsActive || false);
    fetchGetCountriesAsync();
      if (customer.ServiceId !== undefined && customer.ServiceId !== "" && customer.ServiceId !== null) {
        setServiceId(customer.ServiceId);
      }
  }, [customer]);
  const fetchGetCountriesAsync = async () => {
    try {
      const result = await axios.get("api/ManageServices/GetData");
      setServicesListOption(result.data.map(suggestion => ({
        value: suggestion.id,
        label: suggestion.Name,
      })));
      setServicesList(result.data || []);
      if (customer.ModeId !== undefined && customer.ModeId !== "" && customer.ModeId !== null) {
        const dataFilter = result.data.filter(a => a.id === customer.ServiceId);
        if (dataFilter.length > 0) {
          var gg = dataFilter[0].Modes;
          setModesListOption(gg.map(suggestion => ({
            value: suggestion.Id,
            label: suggestion.Name,
          })));
          setModesList(gg);
          const tt = gg.filter(a => a.Id === customer.ModeId);
          if (tt.length > 0) {
            setServiceLevelsList(tt[0].ServiceLevels);
            setServiceLevelsListOption(tt[0].ServiceLevels.map(suggestion => ({
              value: suggestion.Id,
              label: suggestion.Name,
            })));
            const hh = tt[0].ServiceLevels.filter(a => a.Id === customer.ServiceLevelId);
            if (hh.length > 0) {
              setEquipmentTypesList(hh[0].EquipmentTypes);
              setEquipmentTypesListOption(hh[0].EquipmentTypes.map(suggestion => ({
                value: suggestion.Id,
                label: suggestion.Name,
              })));
            }
          }
          setModeId(customer.ModeId);
          setServiceLevelId(customer.ServiceLevelId);
          setEquepmentTypeId(customer.EquipmentTypeId);
        }
      }
    } catch (e) { }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12 mb-4">
                    <Field
                      name="Name"
                      component={Input}
                      placeholder="EQUIPMENTS"
                      label="EQUIPMENTS"
                      required={true}
                    />
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      {ServicesListOption && (
                        <>
                          <p className="mb-2">Service</p>
                          <Select
                            label="Service"
                            name={`ServiceId`}
                            id={`ServiceId`}
                            options={ServicesListOption}
                            value={ServicesListOption.filter(a => a.value === ServiceId)}
                            onChange={(event) => {
                              setServiceId(event.value);
                              setFieldValue("ServiceId", event.value);
                              setFieldValue("ModeId", null);
                              setFieldValue("ServiceLevelId", null);
                              const dataFilter = ServicesList.filter(a => a.id === event.value);
                              console.log(dataFilter);
                              if (dataFilter[0].Modes.length > 0) {
                                setModesList(dataFilter[0].Modes);
                                setModesListOption(dataFilter[0].Modes.map(suggestion => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                })));
                              } else {
                                setModesList([]);
                                setModeId(null);
                                setServiceLevelId(null);
                                setServiceLevelsList([]);
                                setModesListOption([]);
                                setServiceLevelsListOption([]);
                                Notification("warning", "this Service doesn't have any mode");
                              }
                            }}
                          >
                          </Select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      {
                        ModesListOption && (
                          <>
                            <p className="mb-2">Mode</p>

                            <Select
                              label="Mode"
                              name={`ModeId`}
                              id={`ModeId`}
                              options={ModesListOption}
                              value={ModesListOption.filter(a => a.value === ModeId)}

                              onChange={(event) => {
                                setModeId(event.value);
                                setFieldValue("ModeId", event.value);
                                setFieldValue("ServiceLevelId", null);
                                const dataFilter = ModesList.filter(a => a.Id === event.value);
                                if (dataFilter[0].ServiceLevels.length > 0) {
                                  setServiceLevelsList(dataFilter[0].ServiceLevels);
                                  setServiceLevelsListOption(dataFilter[0].ServiceLevels.map(suggestion => ({
                                    value: suggestion.Id,
                                    label: suggestion.Name,
                                  })))
                                } else {
                                  setServiceLevelId(null);
                                  setServiceLevelsList([]);
                                  setModesList([]);
                                  setServiceLevelsListOption([]);
                                  setModesListOption([]);
                                  Notification("warning", "this Mode doesn't have any Service Level");
                                }
                              }}
                            >
                            </Select>

                          </>
                        )
                      }
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="field-description">
                      <p className="mb-2">Service Level</p>

                      <Select
                        label="Service Level"
                        name={`ServiceLevelId`}
                        id={`ServiceLevelId`}
                        value={ServiceLevelsListOption.filter(a => a.value === ServiceLevelId)}
                        options={ServiceLevelsListOption}
                        onChange={(event) => {
                          setServiceLevelId(event.value);
                          setFieldValue("ServiceLevelId", event.value);
                          const dataFilter = ServiceLevelsList.filter(a => a.Id === event.value);
                          if (dataFilter[0].EquipmentTypes.length > 0) {
                            setEquipmentTypesList(dataFilter[0].EquipmentTypes);
                            setEquipmentTypesListOption(dataFilter[0].EquipmentTypes.map(suggestion => ({
                              value: suggestion.Id,
                              label: suggestion.Name,
                            })))
                          } else {
                            setEquepmentTypeId("");
                            setFieldValue("EquipmentTypeId", null);
                            setServiceLevelsListOption([]);
                            setEquipmentTypesList([]);
                            setEquipmentTypesListOption([]);
                            Notification("warning", "this Mode doesn't have any Service Level");
                          }
                        }}
                      >
                      </Select>

                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="field-description">
                      <p className="mb-2">Equipment Type</p>

                      <Select
                        label="Equipment Type"
                        name={`EquipmentTypeId`}
                        id={`EquipmentTypeId`}
                        value={EquipmentTypesListOption.filter(a => a.value === EquepmentTypeId)}
                        options={EquipmentTypesListOption}
                        onChange={(event) => {
                          setEquepmentTypeId(event.value);
                          setFieldValue("EquipmentTypeId", event.value);
                        }}
                      >
                      </Select>
                      {errors.EquipmentTypeId && touched.EquipmentTypeId ? (
                        <div style={{ color: "red", fontSize: "12px" }}>{errors.EquipmentTypeId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <h5 className="text-primary font-weight-bold mb-4">
                  This Fields Accepts Up to 3 Decimal Points
                </h5>
                <div className="form-group row ">
                  <div className="col-lg-3">
                    <Field
                      name="MaxCbm"
                      component={Input}
                      placeholder="MAX CBM"
                      label="MAX CBM"
                      min="0"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="MaxKgs"
                      component={Input}
                      placeholder="MAX KGS"
                      label="MAX KGS"
                      min="0"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="MaxCft"
                      component={Input}
                      placeholder="MAX CFT"
                      label="MAX CFT"
                      min="0"
                      type="number"
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="MaxLbs"
                      component={Input}
                      placeholder="MAX LBS"
                      label="MAX LBS"
                      min="0"
                      type="number"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 px-4">
                    <CheckboxLarge
                      checked={Refrigerated}
                      label=" "
                      text="REFRIGERATED"
                      name="Refrigerated"
                      id="Refrigeratedld"
                      onChange={(event) => {
                        setFieldValue("Refrigerated", event.target.checked);
                        setRefrigerated(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 px-4">
                    <CheckboxLarge
                      checked={Status}
                      label=" "
                      text="Active"
                      name="IsActive"
                      id="IsActive"
                      onChange={(event) => {
                        setFieldValue("IsActive", event.target.checked);
                        setStatus(event.target.checked);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
