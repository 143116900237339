/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import moment from "moment";
import React, { useEffect } from "react";
const colors = [
  "#187DE4",
  "#1BC5BD",
  "#FFA800",
  "#EEE5FF",
  "#4ca1afad",
  "#d0d8e0",
  "#7e8299",
  "#F64E60",
  "#F64E60",
];
export function DollarTab({ BalanceList, className, loadingData }) {
  useEffect(() => {
    if (BalanceList.length === 0) {
      return;
    }
    const element = document.getElementById("kt_performance_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(BalanceList);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [BalanceList, loadingData]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">$</h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-lg-12 text-center">
            {loadingData === true ? (
              <h4>Loading ...</h4>
            ) : BalanceList.length === 0 ? (
              <h4>There is no data at the moment</h4>
            ) : (
              <div
                id="kt_performance_widget_7_chart"
                className="card-rounded-bottom"
                style={{ height: "350px" }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(BalanceList) {
  const options = {
    series: [
      {
        name: "Intermodal",
        data:
          BalanceList?.map((a) => (a.Intermodal === null ? 0 : a.Intermodal)) ||
          [],
      },
      {
        name: "Ltl",
        data: BalanceList?.map((a) => (a.Ltl === null ? 0 : a.Ltl)) || [],
      },
      {
        name: "OTR",
        data: BalanceList?.map((a) => (a.OTR === null ? 0 : a.OTR)) || [],
      },
    ],
    colors: colors,
    chart: {
      height: 350,
      type: "area",
    },
    legend: {
      show: true,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories:
        BalanceList.map((a) =>
          moment(
            (a.Month.length === 1 ? "0" + a.Month : a.Month) + "-01-" + a.Year
          ).format("MM-DD-YYYY")
        ) || [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return value + "$";
        },
      },
    },
  };
  return options;
}
