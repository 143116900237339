import React, { useState, useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/AllOrdersBeta/customersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../_metronic/_helpers";
import $ from "jquery";
import { ModelManagment, ModelManagmentFirst } from "./ModelShipmentManagment";
import { OneMoveMaster } from "../../../../eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import {
  Pagination,
  CheckboxLargeWB,
} from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import { Modal, Spinner } from "react-bootstrap";
import {
  toAbsoluteUrl,
  SimpleZoom,
} from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import "./Style.css";
import DynamicReport from "../../../../../pages/DynamicReport/DyanmicReport";
import { BLFORM } from "../../../../../pages/BL/BLFORM";
import { OutboundManifest } from "../../../../../pages/SCENTSY/OutboundManifest";
import { PLFORM } from "../../../../../pages/PL/PLFORM";
import { DRAYAGEFORM } from "../../../../../pages/Drayage/DRAYAGEFORM";
import { TRCFORM } from "../../../../../pages/TRC/TRCFORM";
import { LTLFORM } from "../../../../../pages/LTL/LTLFORM";
var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY hh:mm A";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return (
    <span className="label label-lg label-light-primary label-inline">
      {moment(d).format(format)}
    </span>
  );
};

export function AllOrderTableTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);
  useEffect(() => {
    setstatusId(window.$ShipmentManagementStatus);
  }, [window.$ShipmentManagementStatus]);
  const [OneMoveMasterShowLink, setOneMoveMasterShowLink] = useState(false);
  const [OneMoveMasterShowId, setOneMoveMasterShowId] = useState(false);
  const [showInboundManifest, setOutboundManifest] = useState(false);
  const [showDispatchReport, setRateConfirmation] = useState(false);
  const [showPl, setPL] = useState(false);
  const [showBol, setBol] = useState(false);
  const [rowItem, setRow] = useState(null);
  const [modeItem, setModeItem] = useState(null);
  const [statusId, setstatusId] = useState(0);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    $(".ShipmentManagment input:checked").prop("checked", false);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);
  // Table columns
  const columnsFirst = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        const actions = {
          openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
          openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
          setOutboundManifest: setOutboundManifest,
          setRateConfirmation: setRateConfirmation,
          setBol: setBol,
          setPL: setPL,
          setRowItem: setRow,
          setModeItem: setModeItem,
        };
        return <ModelManagment cell={cell} row={row} modalActions={actions} />;
      },
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <ModelManagmentFirst row={row} />;
      },
    },
    {
      dataField: "EquipmentNumber",
      text: "Trailer Number",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CustomerCode !== null &&
          row.CustomerCode !== "" &&
          row.CustomerCode !== undefined
        ) {
          return (
            <>
              {row.CustomerCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CustomerName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "ShipperName",
      text: "Shipper Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.OriginCompanyName !== null ? row.OriginCompanyName : "") +
          "/" +
          row.FromCity +
          (row.FromState !== null && row.FromState !== ""
            ? ", " + row.FromState
            : "");
        if (
          row.OriginCompanyName !== null &&
          row.OriginCompanyName !== "" &&
          row.OriginCompanyName !== undefined
        ) {
          return (
            <>
              {row.OriginCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "ConsigneeName",
      text: "Consignee Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.DestinationCompanyName !== null
            ? row.DestinationCompanyName
            : "") +
          "/" +
          row.ToCity +
          (row.ToState !== null && row.ToState !== ""
            ? ", " + row.ToState
            : "");
        if (
          row.DestinationCompanyName !== null &&
          row.DestinationCompanyName !== "" &&
          row.DestinationCompanyName !== undefined
        ) {
          return (
            <>
              {row.DestinationCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "FreightCharge",
      text: "Freight Charge",
      sort: true,
      sortCaret: sortCaret,
      hidden: statusId !== 2 && statusId !== 7 ? true : false,
    },
    {
      dataField: "DeliveryEta",
      text: "Delivery ETA",
      sort: true,
      hidden: statusId !== 7 && statusId !== 9 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.DeliveryEta)}</>;
      },
    },
    {
      dataField: "StatusDeliveredDate",
      text: "Actual Delivered Date",
      sort: true,
      hidden: statusId !== 12 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.StatusDeliveredDate)}</>;
      },
    },
    {
      dataField: "RequestedPickUpDate",
      text: "PICKUP ready DATE/Time",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.RequestedPickUpDate)}</>;
      },
    },
    {
      dataField: "PickupAppointmentDate",
      text: "PickUp by Date/Time",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.PickupAppointmentDate)}</>;
      },
    }, //LastShipDate
    {
      dataField: "LastShipDate",
      text: "Last Ship Date",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.LastShipDate)}</>;
      },
    },
    {
      dataField: "DeliveryAppointmentDate",
      text: "Delivery Appointment",
      sort: true,
      hidden: statusId === 2 || statusId === 12 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.DeliveryAppointmentDate)}</>;
      },
    },

    {
      dataField: "LoadingType",
      text: "Loading Type",
      hidden: statusId !== 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Weight",
      text: "Weight",
      hidden: statusId !== 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{row.Weight}</>;
      },
    },
    {
      dataField: "Commodity",
      text: "Commodity",
      sort: true,
      sortCaret: sortCaret,
      hidden: statusId !== 2 ? true : false,
    },
    {
      dataField: "CarrierName",
      text: "Carrier",
      hidden: statusId === 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CarrierName !== null &&
          row.CarrierName !== "" &&
          row.CarrierName !== undefined
        ) {
          return (
            <>
              {row.CarrierCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CarrierName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "CarrierPhoneNumber",
      text: "Carrier Phone#",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "EquipmentType",
      text: "Equipment Type",
      hidden: statusId === 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Alerts",
      text: "Alerts",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.AlertsColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      id: "actionsShipmentsTab",
      classes: "text-center px-0",
      headerClasses: "text-center pr-3",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];
  (JSON.parse(localStorage.getItem("ColumnVisibility_SM")) || []).forEach(
    (x) => {
      const a = columnsFirst.find(
        (y) => y?.dataField + y?.text === x?.dataField + x?.text
      );
      if (a) a.hidden = x?.hidden || false;
    }
  );
  const [columns, setcolumns] = useState(columnsFirst);
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: customersUIProps.queryParams.pageSize,
    page: customersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <Modal
        size="xl"
        show={OneMoveMasterShowLink}
        /* onHide={() => {
        setOneMoveMasterShowLink(false);
      }} */
      >
        <OneMoveMaster
          Show={setOneMoveMasterShowLink}
          statSHipmentId={OneMoveMasterShowId}
        />
      </Modal>
      <SimpleZoom
        titleButton={
          <>
            Column visibility <i class="fas fa-columns text-white ml-5" />
          </>
        }
        body={
          <div className="row">
            {columns.map((x) => (
              <div className="col-3">
                <CheckboxLargeWB
                  className="font-size-md"
                  text={x?.text}
                  checked={!x?.hidden}
                  onChange={() => {
                    let a = columns;
                    a.forEach((y) => {
                      if (y?.dataField + y?.text === x?.dataField + x?.text) {
                        y.hidden = !y.hidden;
                      }
                    });
                    setcolumns([]);
                    setTimeout(() => {
                      setcolumns(a);
                      localStorage.setItem(
                        "ColumnVisibility_SM",
                        JSON.stringify(a)
                      );
                    }, 1);
                  }}
                />
              </div>
            ))}
            {/* <div className="col-3">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setcolumns(columnsFirst.concat([]));
                }}
              >
                Rest
              </Button>
            </div> */}
            {columns.length === 0 && (
              <Spinner
                id="InboundLoading"
                animation="grow"
                role="status"
                variant="primary"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </div>
        }
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                noDataIndication={
                  <h4
                    className="text-muted my-5"
                    style={{ textAlign: "center" }}
                  >
                    There is no data available at the moment.
                  </h4>
                }
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center smTable"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  customersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: customersUIProps.ids,
                  setIds: customersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>

      {/* ************************ Reports RM *************************** */}
      <DynamicReport
        setShowModal={setBol}
        showModal={showBol}
        id={rowItem}
        title={`BOL Shipment ID#: ${rowItem}`}
        reportContent={<BLFORM id={rowItem} />}
      />
      <DynamicReport
        setShowModal={setRateConfirmation}
        showModal={showDispatchReport}
        id={rowItem}
        title={`Rate Confirmation Shipment ID#: ${rowItem}`}
        reportContent={
          modeItem === "LTL" ? (
            <LTLFORM id={rowItem} />
          ) : modeItem === "Intermodal Rail (COFC)" ? (
            <TRCFORM id={rowItem} />
          ) : modeItem === "OTR (Over The Road)" ? (
            <TRCFORM id={rowItem} />
          ) : (
            <DRAYAGEFORM id={rowItem} />
          )
        }
      />
      <DynamicReport
        setShowModal={setPL}
        showModal={showPl}
        id={rowItem}
        title={`P & L Shipment ID#: ${rowItem}`}
        reportContent={<PLFORM id={rowItem} />}
      />
      <DynamicReport
        setShowModal={setOutboundManifest}
        showModal={showInboundManifest}
        id={rowItem}
        title={`Shipment Manifest Shipment ID#: ${rowItem}`}
        reportContent={<OutboundManifest smId={rowItem} />}
      />
      {/* **************************************************************** */}
    </>
  );
}
