import React from "react";
export function BooleanColumnFormatter(cellContent, row) {
  //var elapsed = new Date(row.CreatedAt).toLocaleString();
  var newValue = "";
  if(cellContent !== null)
  {
    newValue = cellContent.toString().toLowerCase();
    console.log(newValue);
    if(newValue === "true") {
      newValue = "yes";
    } else if (newValue === "false") {
      newValue = "no";
    }
  }
  return (
    <>
     <span>{newValue}</span>
    </>
  );
}
