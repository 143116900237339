import React from "react";
export function DateColumnFormatterEnd(cellContent, row) {
  var re = new RegExp("/", "g");
  let elapsed;
  if(row.DateEnd){
    elapsed = new Date(row.DateEnd).toLocaleString().replace(re, "-");
  }else{
    elapsed = "Empty";
  }
  return (
    <>
      <span class="label label-lg label-light-primary label-inline">
          {elapsed}
      </span>
    </>
  );
}
