import React from 'react'
import { Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

export function billingWizard() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <div class='row'>
                        <div class='col-12 mb-5'>

                            <br />

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/TransactionBillingSelect">Per Transaction Billing Setup</Link>
                            </span>

                            <p className='mt-2'>The system can assist you in calculating charges for each transaction. When creating or editing a Shipping Order, a Receiver, a Return, or an Adjustment, a "Price" tab contains calculators configured with your rates.</p>
                            <br />
                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/SystemCalculated">System-Calculated Storage and Handling Charge Setup</Link>
                            </span>

                            <p className='mt-2'>
                                The system can assist you in calculating storage charges for each Receiver and for subsequent billing periods.<br />
                                - When creating or editing a Receiver, the "Price" tab contains a calculator for system-calculated storage.<br />
                                - Then, at the end of each billing period, choose Admin--Period Close--Generate Recurring Storage Charges to see the proposed charges with your rates for the inventory on hand.
                                <br /><br />
                                The system can also assist you in calculating handling charges for Receivers and Shipping.<br />
                                - When confirming a Receiver or Shipping, the "Price" tab contains a calculator for system-calculated handling.<br /><br />
                                The system can <b>automatically</b> fill in these proposed charges. Check the "Auto-fill" box on the Manage Customer page.
                            </p>
                            <br />

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/ZoneBilling">Zone Billing</Link>
                            </span>

                            <p className='mt-2'>The system can assist you in calculating shipping charges based on the zip code zone orders are being shipped to</p>

                            <br />
                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/BillingRatesExport">Billing Rates Export</Link>
                            </span>

                            <br />
                            <br />
                            <br />
                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="/ManageRates">Manage Rates</Link>
                            </span>
                        </div>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}
