import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import Select from "react-select";
import { momentDate, PostMomentDate, timeList } from "../../../_helpers/ATHelpers";

function minOr30(h) {
  try {
    let a = h?.split(":");
    let b = parseInt(a[1]);
    let c = 15 < b && b < 45 ? "30" : "00";
    return `${a[0]}:${c}:00`;
  } catch (err) {
    return "00:00:00";
  }
}

function twoDigit(number) {
  var twodigit = number >= 10 ? number : "0" + number.toString();
  return twodigit;
}

export function DateTime30({
  field,
  label,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  IdName = "",
  required,
  ClassLabel,
  setFieldValue,
  defaultvalue,
  Time = true,
  ...props
}) {
  const { name, disabled, idInput, onChange } = props;
  const [valueSelect, setvalueSelect] = useState("00:00:00");
  const [valueDate, setvalueDate] = useState();

  useEffect(() => {
    if (defaultvalue) {
      var curr = new Date(momentDate(defaultvalue));
      var date = `${curr.getFullYear()}-${twoDigit(
        curr.getMonth() + 1
      )}-${twoDigit(curr.getDate())}`;
      var date2 = twoDigit(curr.getHours()) + ":" + twoDigit(curr.getMinutes());
      date2 = minOr30(date2);
      if (date) $(`#${name + IdName}DateId`).val(date);
      if (date2) setvalueSelect(timeList.find((x) => x?.value === date2));
      if (idInput) $(`#${idInput}`).val(curr);
    }
  }, [defaultvalue]);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  return (
    <>
      <div className="row">
        <div className={"col-lg-6 " + (!Time && " col-lg-12")}>
          {label && (
            <label className={ClassLabel}>
              {label} {required && <RequiredHtml />}
              {span && (
                <small className="alert alert-warning text-dark p-0 pl-2 pr-2">
                  {" "}
                  {span}
                </small>
              )}
            </label>
          )}
          <TextField
            id={name + IdName + "DateId"}
            type={"date"}
            className="form-control"
            InputLabelProps={{
              shrink: true,
            }}
            {...field}
            {...props}
            onChange={(event) => {
              const b = valueSelect?.value ? valueSelect?.value : "12:00";
              const value = event.target.value + "T" + b;
              const valid = Date.parse(value);
              const date = valid ? value : "";
              setvalueDate(date);
              setFieldValue(name, PostMomentDate(date));
              if (onChange) onChange(event);
            }}
          />
        </div>
        {idInput && <input type="hidden" id={idInput} value={valueDate} />}
        <div className={"col-lg-6 " + (!Time && " d-none")}>
          {label && <label className={ClassLabel}>Time</label>}
          <Select
            isSearchable={true}
            isClearable
            isDisabled={disabled}
            value={valueSelect}
            onChange={(newValue) => {
              var a = $(`#${name + IdName}DateId`).val();
              const value = a.split("T")[0] + "T" + newValue?.value;
              setvalueSelect(
                timeList.find((x) => x?.value === newValue?.value)
              );
              const valid = Date.parse(value);
              const date = valid ? value : "";
              setvalueDate(date);
              setFieldValue(name, PostMomentDate(date));

              if (newValue?.value ?? true) $(`#${name + IdName}DateId`).val("");
            }}
            options={timeList}
          />
        </div>
      </div>
    </>
  );
}
