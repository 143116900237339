import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { Fragment, useRef } from "react";
import { Helmet } from "react-helmet";
import { useReactToPrint } from "react-to-print";
import {
  currencyFormat,
  momentDateOnly,
  momentTime
} from "../../../_metronic/_helpers/ATHelpers";
import { CheckboxLargeWB } from "../../../_metronic/_partials/controls";
import { Notification } from "../../modules/Notification";
import { CheckboxLargeWBFob } from "./CheckboxLargeWBFob";

const adapter = new LocalStorage("db");
const db = low(adapter);
function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("-");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "--/--/----";
  }
}

class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj , stops : [] };
  }

  async componentDidMount() {
    await fetch(window.$apiurl + "/ShipmentBilling/BOLV2/" + this.props.id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", "No data found !");
        }
        const json = await response.json();
        this.setState({ data: json?.ViewModel , stops : json?.Stops});
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const formData = this.state.data;
    const stopsItems = this.state.stops;
    if (formData.IsHeavy) {
      Notification("warning", "This Load is Heavy");
    }
    //Freight
    if (formData.FreightChargesterms === "Prepaid") {
      document.getElementById("PrepaidCheck").checked = true;
    }
    if (formData.FreightChargesterms === "Collect") {
      document.getElementById("CollectCheck").checked = true;
    }
    if (formData.FreightChargesterms === "Third Party Billing") {
      document.getElementById("PartyCheck").checked = true;
    }
    return (
      <>
        <Helmet>
          <title>eMPower | BILL OF LADING </title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="col-md-12">
            <div class="d-flex justify-content-between pt-md-5 flex-column flex-md-row">
              <div class="d-flex flex-column align-items-md-start px-0">
                <a href={() => false} class="mb-5 max-w-100px">
                  <span class="svg-icon svg-icon-full">
                    <img
                      src="/media/logos/Mitco.svg"
                      className="w-100"
                      alt="img"
                    />
                  </span>
                </a>
                {/* <span class="d-flex flex-column align-items-md-start font-size-h5 font-weight-bold text-muted">
                                <span className="font-weight-normal">Cecilia Chapman, 711-2880 Nulla St, Mankato</span>
                                <span className="font-weight-normal">Mississippi 96522</span>
                            </span> */}
              </div>
            </div>
          </div>
          <div className="card py-5 px-0 bl-form border-0">
            <div className="card-body p-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-1 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-0">
                    <div className="col-md-12 px-0">
                      <table className="w-100">
                        <tr>
                          <td className="border-0 font-weight-boldest w-25">
                            {" "}
                            Date: {formatDate(formData.BOLDate)}
                          </td>
                          <td className="border-0 text-center display-4 font-weight-boldest">
                            BILL OF LADING
                          </td>
                          <td className="border-0 text-right font-weight-boldest w-25">
                            Page
                          </td>
                        </tr>
                        <tr>
                          <td
                            className="border-0 font-weight-boldest pb-3"
                            style={{ width: "28%" }}
                          >
                            {" "}
                            {formData.PuAppointment && (
                              <>
                                PickupAppointment Date:{" "}
                                {momentDateOnly(formData.PuAppointment)}{" "}
                                {`(${momentTime(formData.PuAppointment)} -
                                  ${momentTime(formData.PuAppointmentTo)})`}
                              </>
                            )}
                          </td>
                          <td className="border-0 text-center display-4 font-weight-boldest"></td>
                          <td className="border-0 text-right font-weight-boldest w-25"></td>
                        </tr>
                        <tr>
                          <td
                            className="border-0 font-weight-boldest pb-2"
                            style={{ width: "28%" }}
                          >
                            {" "}
                            {formData.AppointmentDate && (
                              <>
                                Appointment Date :{" "}
                                {momentDateOnly(formData.AppointmentDate)}{" "}
                                {`(${momentTime(formData.AppointmentDate)} -
                                  ${momentTime(formData.AppointmentDateTo)})`}
                              </>
                            )}
                          </td>
                          <td className="border-0 text-center display-4 font-weight-boldest"></td>
                          <td className="border-0 text-right font-weight-boldest w-25"></td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="d-flex justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              SHIP FROM
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="w-50 font-weight-boldest">
                              Name:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipFromAddress?.Name}
                              </span>
                            </td>
                            <td
                              rowspan="5"
                              className="font-weight-boldest align-top"
                            >
                              Bill of Lading Number:
                              <span className="font-weight-normal">
                                {formData.BolNumber}
                              </span>{" "}
                              <br></br>
                              <span>Customer Ref # :</span>
                              <span className="font-weight-normal">
                                {formData.CustomerRefNumber}
                              </span>{" "}
                              <br></br> <br></br>
                              <p className="text-muted text-center font-weight-boldest"></p>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Address:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipFromAddress?.Street}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              City/State/Zip:{" "}
                              <span className="font-weight-normal">
                                {IsValidAddress(
                                  formData.ShipFromAddress?.City?.replace(
                                    / /g,
                                    ""
                                  ),
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.ShipFromAddress?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.ShipFromAddress?.ZipCode,
                                    false
                                  )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Phone:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipFromAddress?.Phone}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              SID#:{" "}
                              <span className="font-weight-normal">
                                {formData?.ShippersRefNo}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="mb-4">
                              <CheckboxLargeWBFob
                                style={{ float: "right" }}
                                label=""
                                text="FOB"
                                name=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="d-flex justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              SHIP TO{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest w-50">
                              Name:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipToAddress?.Name}
                              </span>
                            </td>
                            <td className="font-weight-boldest">
                              CARRIER NAME:{" "}
                              <span className="font-weight-normal">
                                {formData.Carriers?.length > 0 &&
                                formData.Carriers?.length > 0
                                  ? formData.Carriers[0]?.CompanyName
                                  : ""}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Address:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipToAddress?.Street}
                              </span>
                            </td>
                            <td className="font-weight-boldest">
                              Trailer number:{" "}
                              <span className="font-weight-normal">
                                {formData.EquipmentIdNumber}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              City/State/Zip:{" "}
                              <span className="font-weight-normal">
                                {IsValidAddress(
                                  formData.ShipToAddress?.City?.replace(
                                    / /g,
                                    ""
                                  ),
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.ShipToAddress?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.ShipToAddress?.ZipCode,
                                    false
                                  )}
                              </span>
                            </td>
                            <td className="font-weight-boldest">
                              Seal number(s):{" "}
                              <span className="font-weight-normal">
                                {formData.SealNumber}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Phone:{" "}
                              <span className="font-weight-normal">
                                {formData.ShipToAddress?.Phone}
                              </span>
                            </td>
                            <td className="font-weight-boldest">
                              SCAC:{" "}
                              <span className="font-weight-normal">
                                {formData.Carriers?.length > 0
                                  ? formData.Carriers[0]?.SCAC
                                  : ""}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">Location#: </td>
                            <td className="font-weight-boldest">
                              Pro number:{" "}
                              <span className="font-weight-normal">
                                {formData.ProNumber !== null &&
                                formData.ProNumber !== undefined &&
                                formData.ProNumber !== ""
                                  ? formData.ProNumber
                                  : this.props.id}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              CID#:{" "}
                              <span className="font-weight-normal">
                                {formData?.ConsigneRefNo}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="mb-4">
                              <CheckboxLargeWBFob
                                style={{ float: "right" }}
                                label=""
                                text="FOB"
                                name=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="d-flex justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              THIRD PARTY FREIGHT CHARGES BILL TO:{" "}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest">
                              Name:{" "}
                              <span className="font-weight-normal">
                                {formData.ThirdPartyAddress?.Name}
                              </span>
                            </td>
                            <td rowspan="3" className="font-weight-boldest">
                              Freight Charge Terms:<br></br>
                              <small className="italic">
                                (freight charges are prepaid unless marked
                                collect)
                              </small>
                              <br></br>
                              <div className="d-flex">
                                <CheckboxLargeWB
                                  Id="PrepaidCheck"
                                  label=""
                                  text="Prepaid"
                                  name=""
                                  checked={
                                    formData.FreightChargesterms === "Prepaid"
                                      ? true
                                      : false
                                  }
                                />
                                <CheckboxLargeWB
                                  Id="CollectCheck"
                                  label=""
                                  text="Collect"
                                  name=""
                                  checked={
                                    formData.FreightChargesterms === "Collect"
                                      ? true
                                      : false
                                  }
                                />
                                <CheckboxLargeWB
                                  Id="PartyCheck"
                                  label=""
                                  text="3rd Party"
                                  name=""
                                  checked={
                                    formData.FreightChargesterms ===
                                    "Third Party Billing"
                                      ? true
                                      : false
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Address:{" "}
                              <span className="font-weight-normal">
                                {formData.ThirdPartyAddress?.Street}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              City/State/Zip:{" "}
                              <span className="font-weight-normal">
                                {IsValidAddress(
                                  formData.ThirdPartyAddress?.City?.replace(
                                    / /g,
                                    ""
                                  ),
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.ThirdPartyAddress?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.ThirdPartyAddress?.ZipCode,
                                    false
                                  )}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest">
                              Phone:{" "}
                              <span className="font-weight-normal">
                                {formData.ThirdPartyAddress?.Phone}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="font-weight-boldest w-50">
                              SPECIAL INSTRUCTIONS:{" "}
                              <p className="form-control border-0 h-auto">
                                {formData.Carriers?.length > 0 &&
                                  (formData.Carriers[0]?.SpecialInstructions ===
                                  null
                                    ? ""
                                    : formData.Carriers[0]
                                        ?.SpecialInstructions)}
                              </p>
                              <span
                                style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  padding: "1.2rem",
                                }}
                              >
                                {formData.HZName !== null
                                  ? formData.HZName !== false
                                    ? formData.HZName
                                    : ""
                                  : ""}
                              </span>
                            </td>
                            <td className="align-top">
                              <CheckboxLargeWB
                                label=""
                                text="Master Bill of Lading: with attached
                                                                underlying Bills of Lading"
                                name=""
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="10" className="text-center">
                              CUSTOMER ORDER INFORMATION
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest text-center">
                              CUSTOMER ORDER NUMBER{" "}
                            </td>
                            <td className="font-weight-boldest text-center">
                              # PKGS{" "}
                            </td>
                            <td className="font-weight-boldest text-center">
                              WEIGHT
                            </td>
                            <td
                              colspan="2"
                              className="font-weight-boldest text-center"
                            >
                              PALLET/SLIP
                            </td>
                            <td className="font-weight-boldest text-center">
                              ADDITIONAL SHIPPER INFO
                            </td>
                          </tr>
                          {formData.CustomerORders?.length > 0 &&
                            formData.CustomerORders &&
                            formData.CustomerORders?.map((item, index) => (
                              <tr>
                                <td className="text-center">
                                  {item.CustomerOrderNUmber}
                                </td>
                                <td className="text-center">
                                  {(item.PKGS !== null &&
                                  item.PKGS !== undefined
                                    ? item.PKGS
                                    : "") +
                                    " " +
                                    (item.PKGSType || "")}
                                </td>
                                <td className="text-center">
                                  {(item.Weight !== null &&
                                  item.Weight !== undefined
                                    ? item.Weight.toFixed(2)
                                    : "") +
                                    " " +
                                    (item.WeightType !== null &&
                                    item.WeightType !== undefined
                                      ? item.WeightType
                                      : "")}
                                </td>
                                <td>
                                  <CheckboxLargeWB
                                    checked={
                                      item.IsPallet !== null &&
                                      item.IsPallet !== false
                                        ? true
                                        : false
                                    }
                                    label=""
                                    text="Y"
                                    name="PALLET_SLIP1"
                                  />
                                </td>
                                <td>
                                  <CheckboxLargeWB
                                    checked={
                                      item.IsSplit !== null &&
                                      item.IsSplit !== false
                                        ? true
                                        : false
                                    }
                                    label=""
                                    text="N"
                                    name="PALLET_SLIP1"
                                  />
                                </td>
                                <td></td>
                              </tr>
                            ))}
                          {formData.CustomerORders?.length > 0 && (
                            <tr>
                              <td
                                class="font-weight-boldest"
                                style={{
                                  backgroundColor: "rgb(17, 48, 89)",
                                  color: "rgb(255, 255, 255)",
                                }}
                              >
                                GRAND TOTAL
                              </td>
                              <td className="text-center">
                                {formData.TotalPKGS === undefined
                                  ? ""
                                  : formData.TotalPKGS +
                                    " " +
                                    (formData.CustomerORders?.length > 0 &&
                                      (formData.CustomerORders[0]?.PKGSType !==
                                      null
                                        ? formData.CustomerORders[0]?.PKGSType
                                        : ""))}
                              </td>
                              <td className="text-center">
                                {formData.TotalOrderWeight === undefined
                                  ? ""
                                  : formData.TotalOrderWeight.toFixed(2) +
                                    " " +
                                    (formData.CustomerORders?.length > 0 &&
                                      (formData.CustomerORders[0]
                                        ?.WeightType !== null
                                        ? formData.CustomerORders[0]?.WeightType
                                        : ""))}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div> */}
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      {formData.CustomerId === 2271 &&
                      formData.IsTransportation === false &&
                      formData.PalletCount !== null ? (
                        <>
                          <table className="w-100 footer-bol">
                            <thead className="justify-content-center">
                              <tr>
                                <th colspan="14" className="text-center">
                                  CARRIER INFORMATION
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  ITEM NO
                                </td>
                                <td
                                  colspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  PACKAGE{" "}
                                </td>
                                <td
                                  rowspan="2"
                                  colspan="1"
                                  className="font-weight-boldest text-center"
                                >
                                  WEIGHT (LBS)
                                </td>
                                <td
                                  rowspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  H.M.(X)
                                </td>
                                <td
                                  rowspan="2"
                                  colspan="3"
                                  className="font-weight-boldest text-center"
                                >
                                  COMMODITY DESCRIPTION<br></br>
                                  <small>
                                    Commodities requiring special or additional
                                    care or attention in handling or stowing{" "}
                                  </small>
                                  <br></br>
                                  <small>
                                    must be so marked and packaged as to ensure
                                    safe transportation with ordinary care.{" "}
                                  </small>
                                  <br></br>
                                  <small className="font-weight-boldest">
                                    See Section 2(e) of NMFC Item 360
                                  </small>
                                </td>
                                <td
                                  colspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  LTL ONLY{" "}
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-boldest text-center">
                                  QTY
                                </td>
                                <td className="font-weight-boldest text-center">
                                  Type
                                </td>
                                <td className="font-weight-boldest text-center">
                                  NMFC #{" "}
                                </td>
                                <td className="font-weight-boldest text-center">
                                  CLASS
                                </td>
                              </tr>
                              <tr>
                                <td className="text-center space-bol"></td>
                                <td className="text-center">
                                  {formData.PalletCount}
                                </td>
                                <td className="text-center">Pallet</td>
                                <td className="text-center" colspan="1">
                                  {formData.TotalWeight === undefined
                                    ? ""
                                    : currencyFormat(formData.TotalWeight)}
                                </td>
                                {/* HZ CHECK */}
                                <td className="text-center">
                                  <CheckboxLargeWB
                                    checked={false}
                                    label=""
                                    text=""
                                    name=""
                                    className=""
                                  />
                                </td>
                                <td className="text-center" colspan="3">
                                  FAK
                                </td>
                                <td className="text-center"></td>
                                <td className="text-center"></td>
                              </tr>
                              <tr>
                                <td
                                  className="font-weight-boldest"
                                  style={{
                                    backgroundColor: "rgb(17, 48, 89)",
                                    color: "rgb(255, 255, 255)",
                                  }}
                                >
                                  GRAND TOTAL
                                </td>
                                <td
                                  className="text-center font-weight-bolder"
                                  style={{ backgroundColor: "aliceblue" }}
                                >
                                  {formData.PalletCount}
                                </td>
                                <td
                                  class="font-weight-boldest"
                                  style={{
                                    backgroundColor: "rgb(17, 48, 89)",
                                    color: "rgb(255, 255, 255)",
                                  }}
                                >
                                  GRAND TOTAL
                                </td>
                                <td
                                  colspan="1"
                                  className="text-center font-weight-bolder"
                                  style={{ backgroundColor: "aliceblue" }}
                                >
                                  {formData.TotalWeight === undefined
                                    ? ""
                                    : currencyFormat(formData.TotalWeight)}
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          {formData.Items?.length > 0 &&
                            formData.Items?.length <= 50 && (
                              <table className="w-100 footer-bol">
                                <thead className="justify-content-center">
                                  <tr>
                                    <th colspan="14" className="text-center">
                                      CARRIER INFORMATION
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td
                                      rowspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      ITEM NO
                                    </td>
                                    <td
                                      colspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      PACKAGE{" "}
                                    </td>
                                    <td
                                      rowspan="2"
                                      colspan="1"
                                      className="font-weight-boldest text-center"
                                    >
                                      WEIGHT (LBS)
                                    </td>
                                    <td
                                      rowspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      H.M.(X)
                                    </td>
                                    <td
                                      rowspan="2"
                                      colspan="3"
                                      className="font-weight-boldest text-center"
                                    >
                                      COMMODITY DESCRIPTION<br></br>
                                      <small>
                                        Commodities requiring special or
                                        additional care or attention in handling
                                        or stowing{" "}
                                      </small>
                                      <br></br>
                                      <small>
                                        must be so marked and packaged as to
                                        ensure safe transportation with ordinary
                                        care.{" "}
                                      </small>
                                      <br></br>
                                      <small className="font-weight-boldest">
                                        See Section 2(e) of NMFC Item 360
                                      </small>
                                    </td>
                                    <td
                                      colspan="2"
                                      className="font-weight-boldest text-center"
                                    >
                                      LTL ONLY{" "}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="font-weight-boldest text-center">
                                      QTY
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      Type
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      NMFC #{" "}
                                    </td>
                                    <td className="font-weight-boldest text-center">
                                      CLASS
                                    </td>
                                  </tr>
                                  {formData.Items?.length > 0 &&
                                    formData.Items.map((item, index) => (
                                      <tr>
                                        <td className="text-center space-bol">
                                          {item.ItemNo}
                                        </td>
                                        <td className="text-center">
                                          {item.PackageQty}
                                        </td>
                                        <td className="text-center">
                                          {item.PackageType}
                                        </td>
                                        <td className="text-center" colspan="1">
                                          {currencyFormat(item.Weight)}
                                        </td>
                                        {/* HZ CHECK */}
                                        <td className="text-center">
                                          <CheckboxLargeWB
                                            checked={
                                              item.HZ !== null &&
                                              item.HZ !== false
                                                ? true
                                                : false
                                            }
                                            label=""
                                            text=""
                                            name=""
                                            className=""
                                          />
                                        </td>
                                        <td className="text-center" colspan="3">
                                          {item.CommodityDescription === ""
                                            ? item.ItemDescription
                                            : item.CommodityDescription}
                                        </td>
                                        <td className="text-center">
                                          {item.NMFC}
                                        </td>
                                        <td className="text-center">
                                          {item.Class}
                                        </td>
                                      </tr>
                                    ))}
                                  <tr>
                                    <td
                                      className="font-weight-boldest"
                                      style={{
                                        backgroundColor: "rgb(17, 48, 89)",
                                        color: "rgb(255, 255, 255)",
                                      }}
                                    >
                                      GRAND TOTAL
                                    </td>
                                    <td
                                      className="text-center font-weight-bolder"
                                      style={{ backgroundColor: "aliceblue" }}
                                    >
                                      {formData.TotalPackages}
                                    </td>
                                    <td
                                      class="font-weight-boldest"
                                      style={{
                                        backgroundColor: "rgb(17, 48, 89)",
                                        color: "rgb(255, 255, 255)",
                                      }}
                                    >
                                      GRAND TOTAL
                                    </td>
                                    <td
                                      colspan="1"
                                      className="text-center font-weight-bolder"
                                      style={{ backgroundColor: "aliceblue" }}
                                    >
                                      {formData.TotalWeight === undefined
                                        ? ""
                                        : currencyFormat(formData.TotalWeight)}
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                        </>
                      )}
                      {formData.Items?.length > 50 && (
                        <>
                          <table className="w-100 footer-bol">
                            <thead className="justify-content-center">
                              <tr>
                                <th colspan="14" className="text-center">
                                  CARRIER INFORMATION
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td
                                  rowspan="2"
                                  className="font-weight-boldest text-center space-bol"
                                >
                                  ITEM NO
                                </td>
                                <td
                                  rowspan="2"
                                  colspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  HANDLING
                                </td>
                                <td
                                  colspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  PACKAGE{" "}
                                </td>
                                <td
                                  rowspan="2"
                                  colspan="1"
                                  className="font-weight-boldest text-center"
                                >
                                  WEIGHT
                                </td>
                                <td
                                  rowspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  H.M.(X)
                                </td>
                                <td
                                  rowspan="2"
                                  colspan="3"
                                  className="font-weight-boldest text-center"
                                >
                                  COMMODITY DESCRIPTION<br></br>
                                  <small>
                                    Commodities requiring special or additional
                                    care or attention in handling or stowing{" "}
                                  </small>
                                  <br></br>
                                  <small>
                                    must be so marked and packaged as to ensure
                                    safe transportation with ordinary care.{" "}
                                  </small>
                                  <br></br>
                                  <small className="font-weight-boldest">
                                    See Section 2(e) of NMFC Item 360
                                  </small>
                                </td>
                                <td
                                  colspan="2"
                                  className="font-weight-boldest text-center"
                                >
                                  LTL ONLY{" "}
                                </td>
                              </tr>
                              <tr>
                                <td className="font-weight-boldest text-center">
                                  QTY
                                </td>
                                <td className="font-weight-boldest text-center">
                                  Type
                                </td>
                                <td className="font-weight-boldest text-center">
                                  NMFC #{" "}
                                </td>
                                <td className="font-weight-boldest text-center">
                                  CLASS
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td colspan="2"></td>
                                <td className="text-center">
                                  {formData.TotalPackages}
                                </td>
                                <td></td>
                                <td className="text-center" colspan="1">
                                  {formData.TotalWeight === undefined
                                    ? ""
                                    : currencyFormat(formData.TotalWeight) +
                                      " " +
                                      (formData.Items?.length > 0 &&
                                        formData.Items[0]?.WeightType)}
                                </td>
                                <td></td>
                                <td
                                  colspan="3"
                                  className="font-weight-boldest text-center"
                                >
                                  GRAND TOTAL
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                          <h4 className="mt-5 mb-5">
                            There are {formData.Items?.length} items on this BOL
                            - See packing list below
                          </h4>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Stops Table */}
                  {stopsItems?.length > 0 && (
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="justify-content-center">
                            <tr>
                              <th colspan="14" className="text-center">
                                Stops
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest text-left">
                                Id
                              </td>
                              <td className="font-weight-boldest text-left">
                                Name
                              </td>
                              <td className="font-weight-boldest text-left">
                                Type
                              </td>
                              <td className="font-weight-boldest text-left">
                                Full Address
                              </td>
                            </tr>
                            {stopsItems?.length > 0 &&
                              stopsItems.map((item, index) => (
                                <Fragment>
                                  <tr>
                                    <td>{item.Id}</td>
                                    <td>{item.Name}</td>
                                    <td>{item.Type}</td>
                                    <td style={{ display: "grid" }}>
                                      {item.Street !== null &&
                                      item.Street !== undefined &&
                                      item.Street !== "" ? (
                                        <span>{`${item.Street}`}</span>
                                      ) : (
                                        "Empty"
                                      )}

                                      <span>{`${item.City ??
                                        "-"} ${item.State ??
                                        "-"} ${item.ZipCode ?? "-"}`}</span>
                                    </td>
                                  </tr>
                                </Fragment>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  {/* Notes */}
                  {formData.Notes?.length > 0 && (
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="justify-content-center">
                            <tr>
                              <th colspan="14" className="text-center">
                                SHIPMENT NOTES
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest text-left">
                                Note
                              </td>
                              <td className="font-weight-boldest text-left">
                                Created
                              </td>
                            </tr>
                            {formData.Notes?.length > 0 &&
                              formData.Notes.map((item, index) => (
                                <tr>
                                  <td>{item.Body}</td>
                                  <td>{formatDate(item.CreatedAt)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td colspan="5" className="align-top">
                              Where the rate is dependent on value, shippers are
                              required to state specifically in writing the
                              agreed or declared value of the property as
                              follows: Noting a value is not a request for
                              Additional Cargo Liability under OD Rules 100,
                              Item 574. COD Amount: $ ______________________
                              “The agreed or declared value of the property is
                              specifically stated by the shipper to be not
                              exceeding<br></br>
                              _________________ per ___________________.”
                            </td>
                            <td colspan="4" className="font-weight-boldest">
                              COD Amount: $ {formData.CodAmount}
                              <br></br>
                              <label className="font-weight-boldest mr-2">
                                {" "}
                                Fee Terms:
                              </label>
                              <div className="d-flex">
                                <CheckboxLargeWB
                                  id="prepaidFeeCheck"
                                  label=""
                                  text="Prepaid"
                                  name=""
                                  checked={false}
                                />
                                <CheckboxLargeWB
                                  id="collectFeeCheck"
                                  label=""
                                  text="Collect"
                                  name=""
                                  checked={false}
                                />
                                <CheckboxLargeWB
                                  Id="acceptableCheck"
                                  label=""
                                  text="Personal/company check NOT acceptable: "
                                  name=""
                                  checked={false}
                                />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td
                              colspan="9"
                              className="font-weight-boldest text-danger"
                            >
                              {formData.IsHeavy === true
                                ? "NOTE - This Load is Heavy"
                                : ""}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td colspan="4" className="font-smaller align-top">
                              RECEIVED, subject to the rates, classifications
                              and rules that have been established by the
                              Carrier and are available on request to the
                              Shipper (Shipper defined in 49 U.S.C.A. §
                              13102(13)(c)), and to all applicable state and
                              federal regulations. Shipper 1) warrants it has
                              read all applicable contract(s) or Carrier’s
                              applicable tariff(s) and the limitation of
                              liability provisions set forth therein; and 2) has
                              actual knowledge of and accepts the applicable
                              contract or tariff terms, including the limits on
                              carrier liability. Carriers’ tariff(s), including
                              OD Rules 100, take precedence in the event of any
                              terms or conditions conflicts.
                            </td>
                            <td colspan="5" className="">
                              The carrier shall not make delivery of this
                              shipment without payment of freight and all other
                              lawful charges.<br></br>
                              <label className="font-weight-boldest mr-2">
                                {" "}
                                ______________________ <br></br>
                                Shipper Signature
                              </label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-top">
                              SHIPPER SIGNATURE / DATE
                              <p>
                                <small>
                                  This is to certify that the above named
                                  materials are properly classified, described,
                                  packaged, marked and labeled, and are in
                                  proper condition for transportation according
                                  to the applicable regulations of the U.S. DOT
                                </small>
                              </p>
                            </td>
                            <td
                              className="align-top border-right-0"
                              colspan="2"
                            >
                              <label className="font-weight-boldest">
                                Trailer Loaded
                              </label>
                              <CheckboxLargeWB
                                label=""
                                text="By Shipper"
                                name=""
                              />
                              <CheckboxLargeWB
                                label=""
                                text="By Driver"
                                name=""
                              />
                            </td>
                            <td className="align-top border-left-0 w-25">
                              <label className="font-weight-boldest">
                                Freight Counted:
                              </label>
                              <CheckboxLargeWB
                                label=""
                                text="By Shipper"
                                name=""
                              />
                              <CheckboxLargeWB
                                label=""
                                text="By Driver/pallets said to contain"
                                name=""
                              />
                              <CheckboxLargeWB
                                label=""
                                text="By Driver/Pieces"
                                name=""
                              />
                            </td>
                            <td className="font-weight-boldest align-top">
                              CARRIER SIGNATURE / PICKUP DATE <br></br>
                              <p>
                                <small>
                                  Carrier acknowledges receipt of packages and
                                  required placards. Carrier certifies emergency
                                  response information was made available and/or
                                  carrier has the U.S. DOT emergency response
                                  guidebook or equivalent documentation in the
                                  vehicle.
                                </small>
                              </p>
                              <br></br>
                              <p>
                                <small className="font-weight-boldest">
                                  <em>
                                    Property described above is received in good
                                    order, except as noted
                                  </em>
                                </small>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-top marginPrint">
                              CUSTOMER SIGNATURE / DATE
                              <p>
                                <small>{""}</small>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      {formData.Items?.length > 50 && (
                        <table className="w-100 footer-bol">
                          <thead className="justify-content-center">
                            <tr>
                              <th colspan="14" className="text-center">
                                CARRIER INFORMATION
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td
                                rowspan="2"
                                className="font-weight-boldest text-center space-bol"
                              >
                                ITEM NO
                              </td>
                              <td
                                rowspan="2"
                                colspan="2"
                                className="font-weight-boldest text-center"
                              >
                                HANDLING
                              </td>
                              <td
                                colspan="2"
                                className="font-weight-boldest text-center"
                              >
                                PACKAGE{" "}
                              </td>
                              <td
                                rowspan="2"
                                colspan="1"
                                className="font-weight-boldest text-center"
                              >
                                WEIGHT
                              </td>
                              <td
                                rowspan="2"
                                className="font-weight-boldest text-center"
                              >
                                H.M.(X)
                              </td>
                              <td
                                rowspan="2"
                                colspan="3"
                                className="font-weight-boldest text-center"
                              >
                                COMMODITY DESCRIPTION<br></br>
                                <small>
                                  Commodities requiring special or additional
                                  care or attention in handling or stowing{" "}
                                </small>
                                <br></br>
                                <small>
                                  must be so marked and packaged as to ensure
                                  safe transportation with ordinary care.{" "}
                                </small>
                                <br></br>
                                <small className="font-weight-boldest">
                                  See Section 2(e) of NMFC Item 360
                                </small>
                              </td>
                              <td
                                colspan="2"
                                className="font-weight-boldest text-center"
                              >
                                LTL ONLY{" "}
                              </td>
                            </tr>
                            <tr>
                              <td className="font-weight-boldest text-center">
                                QTY
                              </td>
                              <td className="font-weight-boldest text-center">
                                Type
                              </td>
                              <td className="font-weight-boldest text-center">
                                NMFC #{" "}
                              </td>
                              <td className="font-weight-boldest text-center">
                                CLASS
                              </td>
                            </tr>
                            {formData.Items?.length > 0 &&
                              formData.Items.map((item, index) => (
                                <tr>
                                  <td>{item.ItemNo}</td>
                                  <td colspan="2">{item.Handling}</td>
                                  <td>{item.PackageQty}</td>
                                  <td>{item.PackageType}</td>
                                  <td colspan="1">
                                    {item.Weight + " " + item.WeightType}
                                  </td>
                                  {/* HZ CHECK */}
                                  <td className="">
                                    <CheckboxLargeWB
                                      checked={
                                        item.HZ !== null && item.HZ !== false
                                          ? true
                                          : false
                                      }
                                      label=""
                                      text=""
                                      name=""
                                      className=""
                                    />
                                  </td>
                                  <td colspan="3">
                                    {item.CommodityDescription === ""
                                      ? item.ItemDescription
                                      : item.CommodityDescription}
                                  </td>
                                  <td>{item.NMFC}</td>
                                  <td>{item.Class}</td>
                                </tr>
                              ))}
                            <tr>
                              <td></td>
                              <td colspan="2"></td>
                              <td>{formData.TotalPackages}</td>
                              <td></td>
                              <td colspan="1">
                                {formData.TotalWeight === undefined
                                  ? ""
                                  : formData.TotalWeight +
                                    " " +
                                    (formData.Items?.length > 0 &&
                                      formData.Items[0]?.WeightType)}
                              </td>
                              <td></td>
                              <td
                                colspan="3"
                                className="font-weight-boldest text-center"
                              >
                                GRAND TOTAL
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function BLFORM({id}) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden">
      <div className="card-body p-0">
        <div className="container mb-md-5 mt-4">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print BLForm
              </button>
              {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
      </div>
    </div>
  );
}
