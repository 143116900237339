import React, { useMemo, useState } from "react";
import { useCustomersUIContext } from "../CustomersUIContext";
import ShippingBulkEmail from "./ShippingBulkEmail";
export function CustomersGrouping() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      openDeleteCustomersDialog: customersUIContext.openDeleteCustomersDialog,
      openFetchCustomersDialog: customersUIContext.openFetchCustomersDialog,
      openUpdateCustomersStatusDialog:
        customersUIContext.openUpdateCustomersStatusDialog,
    };
  }, [customersUIContext]);
  const [OpenEmailBulkPage, setOpenEmailBulkPage] = useState(false);
  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{customersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
      {window.$ShipmentManagementStatusName === "Loaded" &&
        customersUIProps.ids.length > 0 && (
          <div className="form-group row">
            <div className="col-xl-4">
              <button
                type="button"
                className="btn btn-delete btn-primary mb-2"
                onClick={() => {
                  setOpenEmailBulkPage(true);
                }}
              >
                Send Bulk Email
              </button>
            </div>
          </div>
        )}
      {OpenEmailBulkPage && (
        <ShippingBulkEmail rows={customersUIProps.ids} show={OpenEmailBulkPage} onHide={setOpenEmailBulkPage.bind(false)} />
      )}
    </div>
  );
}
