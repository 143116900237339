import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { Row, Table } from "react-bootstrap";
import moment from "moment";
import { currencyFormat } from "../../../_helpers/ATHelpers";

export function PerformanceAccount({ className, MetricsList, loadingOnTime }) {
  console.log("MetricsList", MetricsList);

  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(MetricsList);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [loadingOnTime]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          On-Time Performance
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        {loadingOnTime ? (
          <h4 className="text-center">Loading ...</h4>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              <div
                id="kt_performance_widget_7_chart"
                className="card-rounded-bottom"
                style={{ height: "150px" }}
              ></div>
            </div>
            <div className="col-lg-12 text-center">
              <Table
                responsive
                className="table-head-custom table-head-bg table-borderless table-vertical-center"
              >
                <tbody>
                  <tr>
                    <td className="border-0">
                      <span class="label label-xl label-dot label-primary mr-2"></span>
                      <a
                        href={() => false}
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        Picked Up
                      </a>
                      <div>
                        <a
                          className="text-muted font-weight-bold text-hover-primary font-size-lg"
                          href={() => false}
                        >
                          {MetricsList.reduce(
                            (a, b) => +a + +b.PickupSuccessCount,
                            0
                          )}{" "}
                          Load
                        </a>
                      </div>
                    </td>
                    <td className="border-0">
                      <span class="label label-xl label-dot label-success mr-2"></span>
                      <a
                        href={() => false}
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        Delivered
                      </a>
                      <div>
                        <a
                          className="text-muted font-weight-bold text-hover-primary font-size-lg"
                          href={() => false}
                        >
                          {MetricsList.reduce(
                            (a, b) => +a + +b.DeliverySuccessCount,
                            0
                          )}{" "}
                          Load
                        </a>
                      </div>
                    </td>
                    <td className="border-0">
                      <span class="label label-xl label-dot label-warning mr-2"></span>
                      <a
                        href={() => false}
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        On-Time
                      </a>
                      <div>
                        <a
                          className="text-muted font-weight-bold text-hover-primary font-size-lg"
                          href={() => false}
                        >
                          {currencyFormat(
                            MetricsList.reduce(
                              (a, b) => +a + +b.OnTimeSuccessCount,
                              0
                            ) / MetricsList?.length
                          )}
                          %
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "Picked Up",
        data: layoutProps?.map((a) => {
          return {
            x: String(moment(a.Month).format("MM-YYYY")),
            y: a.PickupChartPurcentage,
            goals: [
              {
                name: "Expected",
                value: a.PickupPurcentage,
                Failed: a.PickupFaillureCount,
                success: a.PickupSuccessCount,
                margin: a.PickupChartPurcentage,
                strokeColor: "#775DD0",
              },
            ],
          };
        }),
      },
      {
        name: "Delivered",
        data: layoutProps?.map((a) => {
          return {
            x: String(moment(a.Month).format("MM-YYYY")),
            y: a.DeliveryChartPurcentage,
            goals: [
              {
                name: "Expected",
                value: a.DeliveryPurcentage,
                Failed: a.DeliveryFaillureCount,
                success: a.DeliverySuccessCount,
                margin: a.DeliveryChartPurcentage,
                strokeColor: "#1BC5BD",
              },
            ],
          };
        }),
      },
      {
        name: "On-Time",
        data: layoutProps?.map((a) => {
          return {
            x: String(moment(a.Month).format("MM-YYYY")),
            y: a.OnTimeChartPurcentage,
            goals: [
              {
                name: "Expected",
                value: a.OnTimePurcentage,
                Failed: a.OnTimeFaillureCount,
                success: a.OnTimeSuccessCount,
                margin: a.OnTimeChartPurcentage,
                strokeColor: "#ffa800",
              },
            ],
          };
        }),
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "normal",
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        console.log("data", data);
        return (
          '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">Date: ' +
          data.x +
          "</div>" +
          '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color: #1BC5BD"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-y-label">Success:</span>' +
          '<span class="apexcharts-tooltip-text-y-value">' +
          data?.goals[0]?.success +
          "</span>" +
          '<div class="apexcharts-tooltip-goals-group">' +
          '<span class="apexcharts-tooltip-text-goals-label"></span>' +
          '<span class="apexcharts-tooltip-text-goals-value"></span>' +
          "</div>" +
          '<div class="apexcharts-tooltip-z-group">' +
          '<span class="apexcharts-tooltip-text-z-label"></span>' +
          '<span class="apexcharts-tooltip-text-z-value"></span>' +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>" +
          '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
          '<span class="apexcharts-tooltip-marker" style="background-color: #F64E60"></span>' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-y-label">Failed:</span>' +
          '<span class="apexcharts-tooltip-text-y-value">' +
          data?.goals[0]?.Failed +
          "</span>" +
          '<div class="apexcharts-tooltip-goals-group">' +
          '<span class="apexcharts-tooltip-text-goals-label"></span>' +
          '<span class="apexcharts-tooltip-text-goals-value"></span>' +
          "</div>" +
          '<div class="apexcharts-tooltip-z-group">' +
          '<span class="apexcharts-tooltip-text-z-label"></span>' +
          '<span class="apexcharts-tooltip-text-z-value"></span>' +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>" +
          '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">' +
          '<div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
          '<div class="apexcharts-tooltip-y-group">' +
          '<span class="apexcharts-tooltip-text-y-label">Success %:</span>' +
          '<span class="apexcharts-tooltip-text-y-value">' +
          data?.goals[0]?.value +
          "%</span>" +
          '<div class="apexcharts-tooltip-goals-group">' +
          '<span class="apexcharts-tooltip-text-goals-label"></span>' +
          '<span class="apexcharts-tooltip-text-goals-value"></span>' +
          "</div>" +
          '<div class="apexcharts-tooltip-z-group">' +
          '<span class="apexcharts-tooltip-text-z-label"></span>' +
          '<span class="apexcharts-tooltip-text-z-value"></span>' +
          "</div>" +
          "</div>" +
          "</div>" +
          "</div>"
        );
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        dataLabels: {
          orientation: "vertical",
        },
      },
    },

    dataLabels: {
      position: "top",
      enabled: true,
      style: {
        fontSize: "7pt",
      },
      offsetX: 0,
      horizontal: false,
      dropShadow: {
        enabled: false,
      },
      formatter: function(val, opts) {
        const seriesIndex = opts.seriesIndex;
        const dataPointIndex = opts.dataPointIndex;
        const w = opts.w;
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return data?.goals[0]?.value + "%";
      },
    },
    // dataLabels: {
    //   offsetY: 15,

    // },
    yaxis: {
      show: false,
    },
  };
  return options;
}
