// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Notification } from "../../../../../modules/Notification";
import * as Yup from "yup";
import Select from 'react-select';
import axios from "axios"
import {
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
// Validation schema
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: '2rem'
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),

      ModeId: Yup.string()
        .required("Mode is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  const [ServicesList, setServicesList] = useState([]);
  const [ServicesListOption, setServicesListOption] = useState([]);
  const [ServiceId, setServiceId] = useState(null);
  const [ModesList, setModesList] = useState([]);
  const [ModesListOption, setModesListOption] = useState([]);
  const [ModeId, setModeId] = useState(null);

  useEffect(() => {
    fetchGetCountriesAsync();
      if (customer.Service !== undefined && customer.Service !== "" && customer.Service !== null) {
        setServiceId(customer.Service);
      }
  }, [customer]);
  const fetchGetCountriesAsync = async () => {
    try {
      const result = await axios.get("api/ManageServices/GetData");
      setServicesListOption(result.data.map(suggestion => ({
        value: suggestion.id,
        label: suggestion.Name,
      })));
      setServicesList(result.data || []);
      if (customer.ModeId !== undefined && customer.ModeId !== "" && customer.ModeId !== null) {
        const dataFilter = result.data.filter(a => a.Name === customer.Service);
        if (dataFilter.length > 0) {
          var gg = dataFilter[0].Modes;
          setModesListOption(gg.map(suggestion => ({
            value: suggestion.Id,
            label: suggestion.Name,
          })));
          setModesList(dataFilter[0].Modes);
          setModeId(customer.ModeId);
        }
      }
    } catch (e) { }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <div className="code-description mb-4">
                      <Field
                        name="Name"
                        label="Name"
                        component={Input}
                        placeholder="Name"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      <Field
                        name="Description"
                        label="Description"
                        component={Input}
                        placeholder="Description"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      {ServicesListOption && (
                        <>
                          <p className="mb-2">Service</p>

                          <Select
                            label="Service"
                            name={`ServiceId`}
                            id={`ServiceId`}
                            options={ServicesListOption}
                            value={ServicesListOption.filter(a => a.label === ServiceId)}
                            onChange={(event) => {
                              setServiceId(event.label);
                              setFieldValue("ServiceId", event.value);
                              setFieldValue("ModeId", null);
                              const dataFilter = ServicesList.filter(a => a.id === event.value);
                              console.log(dataFilter);
                              if (dataFilter[0].Modes.length > 0) {
                                setModesList(dataFilter[0].Modes);
                                setModesListOption(dataFilter[0].Modes.map(suggestion => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                })));
                              } else {
                                setModesList([]);
                                setModeId(null);
                                setModesListOption([]);
                                Notification("warning", "this Service doesn't have any mode");
                              }
                            }}
                          >
                          </Select>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      {
                        ModesListOption && (
                          <>
                            <p className="mb-2">Mode</p>
                            <Select
                              label="Mode"
                              name={`ModeId`}
                              id={`ModeId`}
                              options={ModesListOption}
                              value={ModesListOption.filter(a => a.value === ModeId)}

                              onChange={(event) => {
                                setModeId(event.value);
                                setFieldValue("ModeId", event.value);
                              }}
                            >
                            </Select>
                            {errors.ModeId && touched.ModeId ? (
                              <div style={{ color: "red", fontSize: "12px" }}>{errors.ModeId}</div>
                            ) : null}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
