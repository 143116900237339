import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
export default async function mockImportContacts(mock) {
  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Customers", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function ImportImportContacts(someData) {
    return fetch(`${window.$apiurl}/contacts/import`, {
      method: "post",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        console.log(response);
        const statusCode = response.status;
        let data = {};
        if (statusCode !== 200) data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  mock.onGet("api/purchaseorders/getcustomers").reply(async () => {
    const obj = await GetCustomers();
    return [200, obj];
  });

  mock.onPost("api/contacts/import").reply(async ({ data }) => {
    const contacts = JSON.parse(data);
    var result = await ImportImportContacts(contacts);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
