import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { Link } from "react-router-dom";
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export function CustomersCard() {
  const classes = useStyles();
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="Zone Billing">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-success btn-shadow-hover mr-2"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Add
          </button>
          <Link to="/ImportZoneBilling"  className="btn btn-success btn-shadow-hover mr-2">Import</Link>
          {/* <div>
            <CustomerDialogForm />
          </div> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
      </CardBody>
      <CardFooter>
        <a
          type="button"
          className="btn btn-success btn-shadow-hover mr-2"
          href="/billingWizard"
        >
          Close
          </a>
      </CardFooter>
    </Card>
  );
}