import React, { Fragment, useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import {
  Card,
  CardHeader,
  CardHeaderToolbar,
  CardBody,
  CheckboxLargeWB,
} from "../../../../../../_metronic/_partials/controls";
import CreatableSelect from "react-select/creatable";
import { DateTime30GL } from "../../../../../../_metronic/_helpers/ATHelpers";
import moment from "moment";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import axios from "axios";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function MultiStopsIndexShipping(props) {
  const [modalAddress, setmodalAddresses] = useState(null);
  const [countryValue, setCountryValue] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingPosition, setLoadingPosition] = useState(false);
  const [showModalPosition, setShowModalConfirmationPosition] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [states, setStates] = useState([]);
  //functions
  async function PostStop(data, isPut, id) {
    return await fetch(
      window.$apiurl + `/ShipmentStop/` + (isPut ? id : props?.So),
      {
        method: !isPut ? "Post" : "Put",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          props.setLoaderStops(true);
          Notification(
            "success",
            `Stop has been ${isPut ? "updated" : "created"} successfully`
          );
        }
        const data = await response.json();
        if (data !== null && data !== undefined && data?.Message) {
          Notification("errorClick", data?.Message);
        }
        return Promise.all([statusCode, data]);
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteStop(id) {
    return await fetch(
      window.$apiurl + `/ShipmentStop/` + id,
      {
        method: "Delete",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        }
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          props.setLoaderStops(true);
          setModalDelete(false);
          Notification(
            "success",
            `Stop has been deleted successfully`
          );
          setLoadingDelete(false);
        }
        const data = await response.json();
        if (data !== null && data !== undefined && data?.Message) {
          Notification("errorClick", data?.Message);
          setLoadingDelete(false);
        }
        return Promise.all([statusCode, data]);
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
        setLoadingDelete(false);
      });
  }
  const handleCountryChange = async (event) => {
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
              return null;
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
  };
  function FetchValueItem(column, valueColumn, index) {
    try {
      let currentValue = [...props?.list];
      currentValue[index][column] = valueColumn;
      currentValue[index].IsEdited = true;
      props.setMultiStopsData(currentValue);
    } catch (err) {
      console.log(err);
    }
  }
  async function GetShipperAddress(id) {
    return await fetch(window.$apiurl + `/Tms/Address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          return result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function fetchSelectedAddress(id, isShipper,index) {
    try {
      const result = await GetShipperAddress(id);
      let currentAddress = [...props?.list];
      currentAddress[index].StreetAddress = result?.Address ?? "";
      currentAddress[index].ContactName = result?.ContactName ?? "";
      currentAddress[index].Phone = result?.PhoneNumber ?? "";
      currentAddress[index].Fax = result?.Fax ?? "";
      currentAddress[index].City = result?.City ?? "";
      currentAddress[index].State = result?.State ?? "";
      currentAddress[index].ZipCode = result?.ZipCode ?? "";
      // currentAddress[index].Country = result?.Address;
      currentAddress[index].Email = result?.Email ?? "";
      props.setMultiStopsData(currentAddress);
    } catch (err) {
      console.log(err);
    }
  }
  async function SavePositionsReceivingShipping(isReceiving,id) {
    return await fetch(
      window.$apiurl +
        `/ShipmentStop/MarkStopMain/${id}?isReceiving=${isReceiving}&isShipping=${!isReceiving}`,
      {
        method: "PUT",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        }
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          props.setLoaderStops(true);
          props.setLoaderSoStop(true);
          setLoadingPosition(false);
          setShowModalConfirmationPosition(false);
          Notification(
            "success",
            `Position of Stops has been updated successfully`
          );
        }
        const data = await response.json();
        if (data !== null && data !== undefined && data?.Message) {
          Notification("errorClick", data?.Message);
          setLoadingPosition(false);
        }
        return Promise.all([statusCode, data]);
      })
      .then((res) => {})
      .catch((error) => {
        console.error(error);
        setLoadingPosition(false);
      });
  }
  return (
    <Fragment>
      {/* Modal Delete */}
      <Modal
        show={modalDelete}
        size={"lg"}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {rowItem?.Type} Stop Delete #: {rowItem?.Id} | Address :{" "}
            {rowItem?.StreetAddress}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>Are you sure to permanently delete selected Stop ?</h6>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setModalDelete(false)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              disabled={loadingDelete}
              onClick={async () => {
                setLoadingDelete(true);
                await DeleteStop(rowItem?.Id);
              }}
              className={`${
                loadingDelete ? "spinner spinner-white spinner-right" : ""
              } btn btn-danger btn-elevate`}
            >
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal Confirmation */}
      <Modal
        show={showModalPosition}
        size={"lg"}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>
            Are you sure to mark this stop as{" "}
            {rowItem?.Type === "Pick" ? "Shipping" : "Receiving"} Detail ?
          </h6>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setShowModalConfirmationPosition(false)}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              disabled={loadingPosition}
              onClick={async () => {
                setLoadingPosition(true);
                await SavePositionsReceivingShipping(
                  rowItem?.Type === "Pick" ? false : true,
                  rowItem?.Id
                );
              }}
              className={`${
                loadingDelete ? "spinner spinner-white spinner-right" : ""
              } btn btn-light-primary btn-elevate`}
            >
              Set Position
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Fetch List */}
      {props?.list !== undefined &&
        props?.list !== null &&
        props?.list.length > 0 &&
        props?.list.map((item, index) => (
          <Fragment key={index}>
            {item?.Type === "Pick" ? (
              <div className="pl-5 pr-5">
                <Card className={item?.ClassName}>
                  <CardHeader title={item?.Title}>
                    <CardHeaderToolbar>
                      {/* Arrows */}
                      {!item?.IsNew && (
                        <Fragment>
                          {index === 0 ? (
                            <Fragment>
                              {props?.list?.filter((x) => !x.IsNew)?.length >
                                1 && (
                                <button
                                  className="btn btn-light-warning py-3 pl-5 pr-4"
                                  onClick={() => {
                                    try {
                                      props.setShowSaveButton(true);
                                      let data = [...props?.list];
                                      data[index].Position =
                                        data[index].Position + 1;
                                      data[index + 1].Position =
                                        data[index].Position - 1;
                                      props.setMultiStopsData(
                                        data.sort(
                                          (a, b) =>
                                            parseFloat(a.Position) -
                                            parseFloat(b.Position)
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-down"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              )}
                            </Fragment>
                          ) : index === props?.list?.length - 1 ? (
                            <Fragment>
                              {props?.list?.filter((x) => !x.IsNew)?.length >
                                1 && (
                                <button
                                  className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                                  onClick={() => {
                                    props.setShowSaveButton(true);
                                    let data = [...props?.list];
                                    data[index].Position =
                                      data[index].Position - 1;
                                    data[index - 1].Position =
                                      data[index].Position + 1;
                                    props.setMultiStopsData(
                                      data.sort(
                                        (a, b) =>
                                          parseFloat(a.Position) -
                                          parseFloat(b.Position)
                                      )
                                    );
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-up"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              )}
                            </Fragment>
                          ) : (
                            props?.list?.filter((x) => !x.IsNew)?.length >
                              1 && (
                              <Fragment>
                                <button
                                  className="btn btn-light-warning py-3 pl-5 pr-4"
                                  onClick={() => {
                                    try {
                                      props.setShowSaveButton(true);
                                      let data = [...props?.list];
                                      data[index].Position =
                                        data[index].Position + 1;
                                      data[index + 1].Position =
                                        data[index].Position - 1;
                                      props.setMultiStopsData(
                                        data.sort(
                                          (a, b) =>
                                            parseFloat(a.Position) -
                                            parseFloat(b.Position)
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-down"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                                  onClick={() => {
                                    props.setShowSaveButton(true);
                                    let data = [...props?.list];
                                    data[index].Position =
                                      data[index].Position - 1;
                                    data[index - 1].Position =
                                      data[index].Position + 1;
                                    props.setMultiStopsData(
                                      data.sort(
                                        (a, b) =>
                                          parseFloat(a.Position) -
                                          parseFloat(b.Position)
                                      )
                                    );
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-up"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              </Fragment>
                            )
                          )}
                        </Fragment>
                      )}

                      <span className="border-right ml-5 mr-5"></span>
                      {/* ************* */}
                      <button
                        className={`${
                          item?.IsEdited ? "" : "disabledStop"
                        } btn btn-light-primary py-3 pl-5 pr-4 postStop`}
                        onClick={async () => {
                          try {
                            FetchValueItem("IsNew", false, index);
                            const checkItem = props?.list[index];
                            if (!checkItem?.AddressId) {
                              Notification("errorClick", "Company is required");
                              return false;
                            }
                            if (item.Id) {
                              await PostStop(props?.list[index], true, item.Id);
                            } else {
                              await PostStop(props?.list[index], false, 0);
                            }
                            props.setShowSaveButton(false);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        <i class="fas fa-save" style={{ fontSize: "18px" }}></i>
                      </button>
                      <button
                        className="btn btn-light-danger py-3 pl-5 ml-5"
                        onClick={async () => {
                          try {
                            let values = [...props?.list];
                            if (item.Id) {
                              setModalDelete(true);
                              setRowItem(item);
                            } else {
                              values = values?.filter(
                                (x) => x !== values[index]
                              );
                              props.setMultiStopsData(values);
                            }
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        <i
                          class="fas fa-times"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                        onClick={() => {
                          try {
                            let values = [...props?.list];
                            values[index].IsCollapsed = item?.IsCollapsed
                              ? false
                              : true;
                            props.setMultiStopsData(values);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        {item.IsCollapsed ? (
                          <i
                            class="fas fa-chevron-up"
                            style={{ fontSize: "18px" }}
                          ></i>
                        ) : (
                          <i
                            class="fas fa-chevron-down"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                      </button>
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody className={item?.IsCollapsed ? "d-none" : ""}>
                    <div className="row mb-5">
                      <Col xl="12" lg="12" md="12" className="mb-5">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Company Name :
                            </a>
                            <span className="font-weight-bold">
                              <CreatableSelect
                                className="mt-4"
                                isClearable
                                name="ShipperId"
                                id="ShipperId"
                                label="Company"
                                value={props?.ViewModelOptions?.Shippers.filter(
                                  (a) => a.Id === item?.AddressId
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={props?.ViewModelOptions?.Shippers.map(
                                  (a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  }
                                )}
                                onChange={async (newValue) => {
                                  if (newValue !== null) {
                                    if (newValue.__isNew__) {
                                      setmodalAddresses(true);
                                      setCountryValue({
                                        value: "US",
                                        label: "United States",
                                      });
                                      handleCountryChange("US");
                                    } else {
                                      const currentObject = props?.ViewModelOptions?.Shippers?.find(
                                        (x) => x.Id === newValue?.value
                                      );
                                      FetchValueItem(
                                        "AddressId",
                                        currentObject?.Id,
                                        index
                                      );
                                    }
                                  } else {
                                    FetchValueItem("AddressId", "", index);
                                  }
                                  fetchSelectedAddress(
                                    newValue !== null ? newValue.value : null,
                                    true,
                                    index
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <div className="col-xl col-lg col-md-5 border-right">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Street Address :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperAddress"
                                disabled={true}
                                id="ShipperAddress"
                                value={item?.StreetAddress}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              City :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperCity"
                                disabled={true}
                                id="ShipperCity"
                                value={item?.City}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              State :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperState"
                                disabled={true}
                                id="ShipperState"
                                value={item?.State}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Zip :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperZipCode"
                                id="ShipperZipCode"
                                disabled={true}
                                value={item?.ZipCode}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg col-md-5 border-right">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Name :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ShipperContactName"
                                disabled={true}
                                id="ShipperContactName"
                                label="Contact"
                                value={item?.ContactName}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Phone :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperPhoneNumber"
                                id="ShipperPhoneNumber"
                                disabled={true}
                                value={item?.Phone}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Fax :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperFax"
                                id="ShipperFax"
                                disabled={true}
                                value={item?.Fax}
                                label="Fax"
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Email :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="email"
                                className="form-control mt-4"
                                name="ShipperEmail"
                                disabled={true}
                                id="ShipperEmail"
                                value={item?.Email}
                                label="Email"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg col-md-5 border-right">
                        <div className=" flex-wrap align-items-center pb-3">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                            >
                              Req. Ship Date:
                            </a>
                            <span className="text-muted font-weight-bold">
                              <TextField
                                id="ShipDate"
                                type="date"
                                value={String(
                                  item?.ShipDate
                                    ? moment(item?.ShipDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""
                                )}
                                onChange={(e) => {
                                  FetchValueItem(
                                    "ShipDate",
                                    e.target.value,
                                    index
                                  );
                                }}
                                className="form-control mt-4"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              PU # :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="PickupNumber"
                                id="PickupNumber"
                                value={item?.PickupNumber}
                                onChange={(e) => {
                                  FetchValueItem(
                                    "PickupNumber",
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Seal # :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="SealNumber"
                                id="SealNumber"
                                value={item?.SealNumber}
                                onChange={(e) => {
                                  FetchValueItem(
                                    "SealNumber",
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Special Req :
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isMulti
                                className="basic-multi-select mt-4"
                                classNamePrefix="select"
                                name="ShipperSpecialReq"
                                id="ShipperSpecialReq"
                                value={props?.ViewModelOptions?.SpecialReqs.filter(
                                  function(el) {
                                    return (
                                      item?.SpecialReq?.indexOf(el.Name) >= 0
                                    );
                                  }
                                ).map((a) => {
                                  return {
                                    value: a.Name,
                                    label: a.Name,
                                  };
                                })}
                                options={props?.ViewModelOptions?.SpecialReqs.map(
                                  (a) => {
                                    return {
                                      value: a.Name,
                                      label: a.Name,
                                    };
                                  }
                                )}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    FetchValueItem(
                                      "SpecialReq",
                                      newValue.map((a) => a.value),
                                      index
                                    );
                                  } else {
                                    FetchValueItem("SpecialReq", [], index);
                                  }
                                }}
                                label="Load Board"
                                inputId="react-select-single"
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <CheckboxLargeWB
                              className="font-size-md"
                              text="APPT"
                              name="IsAppointed"
                              id="IsAppointed"
                              checked={item?.IsAppointed}
                              onChange={(event) => {
                                FetchValueItem(
                                  "IsAppointed",
                                  event.target.checked,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row className="pt-5  mb-5">
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                            >
                              PU APPT Date :
                              <br />
                            </a>
                            <span className="font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.AppointmentDateFrom ?? ""}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "AppointmentDateFrom",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="2" lg="2" md="3" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                            >
                              PU APPT to Time :
                              <br />
                            </a>
                            <span className="font-weight-bold">
                              <input
                                className={`form-control mt-4`}
                                value={
                                  item?.AppointmentDateTo === null ||
                                  item?.AppointmentDateTo === ""
                                    ? ""
                                    : item?.AppointmentDateTo?.length > 4
                                    ? moment(item?.AppointmentDateTo).format(
                                        "HH:mm"
                                      )
                                    : item?.AppointmentDateTo
                                }
                                placeholder="00:00"
                                onKeyUp={(e) => {
                                  if (e.keyCode === 8) {
                                    FetchValueItem(
                                      "AppointmentDateTo",
                                      null,
                                      index
                                    );
                                    return;
                                  }
                                }}
                                onChange={(e) => {
                                  var value = e.target?.value;
                                  if (!value || value === "") {
                                    FetchValueItem(
                                      "AppointmentDateTo",
                                      null,
                                      index
                                    );
                                  }
                                  if (value.length === 2) value += ":";
                                  if (value.length > 5) value = value[5];
                                  if (value.length > 4) {
                                    FetchValueItem(
                                      "AppointmentDateTo",
                                      moment().format("YYYY-MM-DDT") + value,
                                      index
                                    );
                                  } else {
                                    FetchValueItem(
                                      "AppointmentDateTo",
                                      value,
                                      index
                                    );
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="3">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="AppointmentDateEntredBy"
                              id="AppointmentDateEntredBy"
                              disabled={true}
                              value={item?.AppointmentDateEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <CheckboxLargeWB
                              className="font-size-md"
                              name="IsDropRecovery"
                              text="D/T"
                              id="IsDropRecovery"
                              checked={item?.IsDropRecovery}
                              onChange={(event) => {
                                FetchValueItem(
                                  "IsDropRecovery",
                                  event.target.checked,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      {item?.IsDropRecovery === true ? (
                        <>
                          <Col xl="4" lg="4" md="4" className="SO-Date">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                                >
                                  Empty Date:
                                  <br />
                                </a>
                                <span className="font-weight-bold">
                                  <DateTime30GL
                                    defaultvalue={item?.DropDate ?? ""}
                                    onChange={(newValue) => {
                                      try {
                                        FetchValueItem(
                                          "DropDate",
                                          newValue,
                                          index
                                        );
                                      } catch (err) {
                                        console.log(err);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          {/* <Col xl="4" lg="4" md="4" className="SO-Date">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-3 font-size-lg mr-3"
                                >
                                  Recovery Date :
                                  <br />
                                </a>
                                <span className="font-weight-bold">
                                  <DateTime30GL
                                    defaultvalue={item?.RecoveryDate}
                                    onChange={(newValue) => {
                                      try {
                                        FetchValueItem(
                                          "RecoveryDate",
                                          newValue,
                                          index
                                        );
                                      } catch (err) {
                                        console.log(err);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col> */}
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row className=" mb-5">
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Arrival Date :
                              <br />
                            </a>
                            <span className="font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.ActualArrivalDate}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "ActualArrivalDate",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="EnteredBy"
                              id="EnteredBy"
                              disabled={true}
                              value={item?.ActualArrivalDateEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entry Date/Time :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperArrivalDateEnteredDate"
                                id="ShipperArrivalDateEnteredDate"
                                disabled={true}
                                value={
                                  item?.ActualArrivalDateEntredDate === null ||
                                  item?.ActualArrivalDateEntredDate === "" ||
                                  item?.ActualArrivalDateEntredDate ===
                                    undefined
                                    ? ""
                                    : moment(
                                        item?.ActualArrivalDateEntredDate
                                      ).format("MM-DD-YYYY, h:mm A")
                                }
                                label="Entered By"
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className=" mb-5">
                      <Col xl="4" lg="4" md="4" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Departure Date :
                              <br />
                            </a>
                            <span className="font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.DepartureDate ?? ""}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "DepartureDate",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="EnteredBy"
                              id="EnteredBy"
                              disabled={true}
                              value={item?.DepartureDateEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entry Date/Time :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ShipperDepartureDateEnteredDate"
                                id="ShipperDepartureDateEnteredDate"
                                disabled={true}
                                value={
                                  item?.DepartureDateEntredDate === null ||
                                  item?.DepartureDateEntredDate === undefined ||
                                  item?.DepartureDateEntredDate === ""
                                    ? ""
                                    : moment(
                                        item?.DepartureDateEntredDate
                                      ).format("MM-DD-YYYY, h:mm A")
                                }
                                label="Entered By"
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div className="pl-5 pr-5">
                <Card className={item?.ClassName}>
                  <CardHeader title={item?.Title}>
                    <CardHeaderToolbar>
                      {/* Arrows */}
                      {!item?.IsNew && (
                        <Fragment>
                          {index === 0 ? (
                            <Fragment>
                              {props?.list?.filter((x) => !x.IsNew)?.length >
                                1 && (
                                <button
                                  className="btn btn-light-warning py-3 pl-5 pr-4"
                                  onClick={() => {
                                    try {
                                      props.setShowSaveButton(true);
                                      let data = [...props?.list];
                                      data[index].Position =
                                        data[index].Position + 1;
                                      data[index + 1].Position =
                                        data[index].Position - 1;
                                      props.setMultiStopsData(
                                        data.sort(
                                          (a, b) =>
                                            parseFloat(a.Position) -
                                            parseFloat(b.Position)
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-down"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              )}
                            </Fragment>
                          ) : index === props?.list?.length - 1 ? (
                            <Fragment>
                              {props?.list?.filter((x) => !x.IsNew)?.length >
                                1 && (
                                <button
                                  className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                                  onClick={() => {
                                    props.setShowSaveButton(true);
                                    let data = [...props?.list];
                                    data[index].Position =
                                      data[index].Position - 1;
                                    data[index - 1].Position =
                                      data[index].Position + 1;
                                    props.setMultiStopsData(
                                      data.sort(
                                        (a, b) =>
                                          parseFloat(a.Position) -
                                          parseFloat(b.Position)
                                      )
                                    );
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-up"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              )}
                            </Fragment>
                          ) : (
                            props?.list?.filter((x) => !x.IsNew)?.length >
                              1 && (
                              <Fragment>
                                <button
                                  className="btn btn-light-warning py-3 pl-5 pr-4"
                                  onClick={() => {
                                    try {
                                      props.setShowSaveButton(true);
                                      let data = [...props?.list];
                                      data[index].Position =
                                        data[index].Position + 1;
                                      data[index + 1].Position =
                                        data[index].Position - 1;
                                      props.setMultiStopsData(
                                        data.sort(
                                          (a, b) =>
                                            parseFloat(a.Position) -
                                            parseFloat(b.Position)
                                        )
                                      );
                                    } catch (err) {
                                      console.log(err);
                                    }
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-down"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                                <button
                                  className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                                  onClick={() => {
                                    props.setShowSaveButton(true);
                                    let data = [...props?.list];
                                    data[index].Position =
                                      data[index].Position - 1;
                                    data[index - 1].Position =
                                      data[index].Position + 1;
                                    props.setMultiStopsData(
                                      data.sort(
                                        (a, b) =>
                                          parseFloat(a.Position) -
                                          parseFloat(b.Position)
                                      )
                                    );
                                  }}
                                >
                                  <i
                                    class="fas fa-arrow-circle-up"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                </button>
                              </Fragment>
                            )
                          )}
                        </Fragment>
                      )}
                      <span className="border-right ml-5 mr-5"></span>
                      {/* ************* */}
                      <button
                        className={`${
                          item?.IsEdited ? "" : "disabledStop"
                        } btn btn-light-primary py-3 pl-5 pr-4 postStop`}
                        onClick={async () => {
                          try {
                            FetchValueItem("IsNew", false, index);
                            if (item.Id) {
                              await PostStop(props?.list[index], true, item.Id);
                            } else {
                              await PostStop(props?.list[index], false, 0);
                            }
                            props.setShowSaveButton(false);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        <i class="fas fa-save" style={{ fontSize: "18px" }}></i>
                      </button>
                      <button
                        className="btn btn-light-danger py-3 pl-5 ml-5"
                        onClick={async () => {
                          try {
                            let values = [...props?.list];
                            if (item.Id) {
                              setModalDelete(true);
                              setRowItem(item);
                            } else {
                              values = values?.filter(
                                (x) => x !== values[index]
                              );
                              props.setMultiStopsData(values);
                            }
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        <i
                          class="fas fa-times"
                          style={{ fontSize: "18px" }}
                        ></i>
                      </button>
                      <button
                        className="btn btn-light-info py-3 pl-5 pr-4 ml-5"
                        onClick={() => {
                          try {
                            let values = [...props?.list];
                            values[index].IsCollapsed = item?.IsCollapsed
                              ? false
                              : true;
                            props.setMultiStopsData(values);
                          } catch (err) {
                            console.log(err);
                          }
                        }}
                      >
                        {item.IsCollapsed ? (
                          <i
                            class="fas fa-chevron-up"
                            style={{ fontSize: "18px" }}
                          ></i>
                        ) : (
                          <i
                            class="fas fa-chevron-down"
                            style={{ fontSize: "18px" }}
                          ></i>
                        )}
                      </button>
                    </CardHeaderToolbar>
                  </CardHeader>
                  <CardBody className={item?.IsCollapsed ? "d-none" : ""}>
                    <div className="row mb-5">
                      <Col xl="12" lg="12" md="12">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Company Name :
                            </a>
                            <span className="font-weight-bold">
                              <CreatableSelect
                                isClearable
                                className="mt-4"
                                name="ConsigneeId"
                                id="ConsigneeId"
                                value={props?.ViewModelOptions?.Consignees.filter(
                                  (a) => a.Id === item?.AddressId
                                ).map((a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                })}
                                options={props?.ViewModelOptions?.Consignees.map(
                                  (a) => {
                                    return {
                                      value: a.Id,
                                      label: a.Name,
                                    };
                                  }
                                )}
                                onChange={async (newValue) => {
                                  if (newValue !== null) {
                                    if (newValue.__isNew__) {
                                      setmodalAddresses(true);
                                      setCountryValue({
                                        value: "US",
                                        label: "United States",
                                      });
                                      handleCountryChange("US");
                                    } else {
                                      const currentObject = props?.ViewModelOptions?.Consignees?.find(
                                        (x) => x.Id === newValue?.value
                                      );
                                      FetchValueItem(
                                        "AddressId",
                                        currentObject?.Id,
                                        index
                                      );
                                    }
                                  } else {
                                    FetchValueItem("AddressId", "", index);
                                  }
                                  fetchSelectedAddress(
                                    newValue !== null ? newValue.value : null,
                                    true,
                                    index
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <div className="col-xl col-lg col-md-4 border-right">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Street Address :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeStreet"
                                id="ConsigneeStreet"
                                disabled={true}
                                label="Street"
                                value={item?.StreetAddress}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              City :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeCity"
                                disabled={true}
                                id="ConsigneeCity"
                                value={item?.City}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              State :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeState"
                                id="ConsigneeState"
                                disabled={true}
                                value={item?.State}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Zip :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeZipCode"
                                id="ConsigneeZipCode"
                                disabled={true}
                                value={item?.ZipCode}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg col-md-4 border-right">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Name :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeContactName"
                                disabled={true}
                                value={item?.ContactName}
                                id="ConsigneeContactName"
                                label="Contact"
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Phone :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ConsigneePhoneNumber"
                                disabled={true}
                                id="ConsigneePhoneNumber"
                                value={item?.Phone}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Fax :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ConsigneeFax"
                                id="ConsigneeFax"
                                disabled={true}
                                value={item?.Fax}
                                label="Fax"
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Contact Email :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="email"
                                className="form-control mt-4"
                                name="EmailText"
                                id="EmailText"
                                disabled={true}
                                value={item?.Email}
                                label="Email"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl col-lg col-md-4 border-right">
                        <div className=" flex-wrap align-items-center pb-3">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary font-size-lg mr-3"
                            >
                              MABD:
                            </a>
                            <span className="text-muted font-weight-bold">
                              <TextField
                                id="MustArriveByDate"
                                type="date"
                                value={String(
                                  moment(item?.MustArriveByDate).format(
                                    "YYYY-MM-DD"
                                  )
                                )}
                                onChange={(e) => {
                                  FetchValueItem(
                                    "MustArriveByDate",
                                    e.target.value,
                                    index
                                  );
                                }}
                                className="form-control mt-4"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              DEL # :
                            </a>
                            <span className="text-muted font-weight-bold">
                              {" "}
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="ConsigneeNumber"
                                id="ConsigneeNumber"
                                value={item?.DeliveryNumber}
                                label="PU"
                                onChange={(e) => {
                                  FetchValueItem(
                                    "DeliveryNumber",
                                    e.target.value,
                                    index
                                  );
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Special Req :
                            </a>
                            <span className="font-weight-bold">
                              <Select
                                isMulti
                                className="basic-multi-select mt-4"
                                classNamePrefix="select"
                                name="ConsigneeSpecialReq"
                                id="ConsigneeSpecialReq"
                                value={props?.ViewModelOptions?.SpecialReqs.filter(
                                  function(el) {
                                    return (
                                      item?.SpecialReq?.indexOf(el.Name) >= 0
                                    );
                                  }
                                ).map((a) => {
                                  return {
                                    value: a.Name,
                                    label: a.Name,
                                  };
                                })}
                                options={props?.ViewModelOptions?.SpecialReqs.map(
                                  (a) => {
                                    return {
                                      value: a.Name,
                                      label: a.Name,
                                    };
                                  }
                                )}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    FetchValueItem(
                                      "SpecialReq",
                                      newValue.map((a) => a.value),
                                      index
                                    );
                                  } else {
                                    FetchValueItem("SpecialReq", [], index);
                                  }
                                }}
                                label="Load Board"
                                inputId="react-select-single"
                              />
                            </span>
                          </div>
                        </div>
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <CheckboxLargeWB
                              className="font-size-md"
                              text="APPT"
                              name="IsAppointed"
                              id="IsAppointed"
                              checked={item?.IsAppointed}
                              onChange={(event) => {
                                FetchValueItem(
                                  "IsAppointed",
                                  event.target.checked,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Row className="pt-5  mb-5">
                      <Col xl="4" lg="4" md="4" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              DEL Date :
                            </a>
                            <span className=" font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.DeliveryAppointmentFromDate}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "DeliveryAppointmentFromDate",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="2" lg="2" md="3" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              DEL to Time :
                            </a>
                            <span className=" font-weight-bold">
                              <input
                                className={`form-control mt-4`}
                                value={
                                  item?.DeliveryAppointmentToDate === null ||
                                  item?.DeliveryAppointmentToDate === ""
                                    ? ""
                                    : item?.DeliveryAppointmentToDate?.length >
                                      4
                                    ? moment(
                                        item?.DeliveryAppointmentToDate
                                      ).format("HH:mm")
                                    : item?.DeliveryAppointmentToDate
                                }
                                placeholder="00:00"
                                onKeyUp={(e) => {
                                  if (e.keyCode === 8) {
                                    FetchValueItem(
                                      "DeliveryAppointmentToDate",
                                      null,
                                      index
                                    );
                                    return;
                                  }
                                }}
                                onChange={(e) => {
                                  var value = e.target?.value;
                                  if (!value || value === "") {
                                    FetchValueItem(
                                      "DeliveryAppointmentToDate",
                                      "",
                                      index
                                    );
                                  }
                                  if (value.length === 2) value += ":";
                                  if (value.length > 5) value = value[5];
                                  if (value.length > 4) {
                                    FetchValueItem(
                                      "DeliveryAppointmentToDate",
                                      moment().format("YYYY-MM-DDT") + value,
                                      index
                                    );
                                  } else {
                                    FetchValueItem(
                                      "DeliveryAppointmentToDate",
                                      value,
                                      index
                                    );
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="3">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="EnteredBy"
                              id="EnteredBy"
                              disabled={true}
                              value={item?.DeliveryAppointmentEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="2" lg="2" md="2" className="mt-5 pt-5">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <CheckboxLargeWB
                              className="font-size-md"
                              name="ConsigneeDt"
                              text="D/T"
                              id="ConsigneeDt"
                              checked={item?.IsDropRecovery}
                              onChange={(e) => {
                                FetchValueItem(
                                  "IsDropRecovery",
                                  e.target.checked,
                                  index
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      {item?.IsDropRecovery === true ? (
                        <>
                          <Col xl="4" lg="4" md="4" className="SO-Date">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Empty Date:
                                </a>
                                <span className=" font-weight-bold">
                                  <DateTime30GL
                                    defaultvalue={item?.DropDate}
                                    onChange={(newValue) => {
                                      try {
                                        FetchValueItem(
                                          "DropDate",
                                          newValue,
                                          index
                                        );
                                      } catch (err) {
                                        console.log(err);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col>
                          {/* <Col xl="4" lg="4" md="4" className="SO-Date">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Recovery Date :
                                </a>
                                <span className=" font-weight-bold">
                                  <DateTime30GL
                                    defaultvalue={item?.RecoveryDate}
                                    onChange={(newValue) => {
                                      try {
                                        FetchValueItem(
                                          "RecoveryDate",
                                          newValue,
                                          index
                                        );
                                      } catch (err) {
                                        console.log(err);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                          </Col> */}
                        </>
                      ) : (
                        ""
                      )}
                    </Row>
                    <Row className=" mb-5">
                      <Col xl="4" lg="4" md="4" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Arrival Date :
                            </a>
                            <span className="font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.ActualArrivalDate}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "ActualArrivalDate",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="ActualArrivalDateEntredBy"
                              id="ActualArrivalDateEntredBy"
                              disabled={true}
                              value={item?.ActualArrivalDateEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entry Date/Time :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ConsigneeArrivalDateEnteredDate"
                                id="ConsigneeArrivalDateEnteredDate"
                                disabled={true}
                                value={
                                  item?.ActualArrivalDateEntredDate === null ||
                                  item?.ActualArrivalDateEntredDate ===
                                    undefined ||
                                  item?.ActualArrivalDateEntredDate === ""
                                    ? ""
                                    : moment(
                                        item?.ActualArrivalDateEntredDate
                                      ).format("MM-DD-YYYY, h:mm A")
                                }
                                label="Entered By"
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className=" mb-5">
                      <Col xl="4" lg="4" md="4" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Departure Date :
                            </a>
                            <span className="font-weight-bold">
                              <DateTime30GL
                                defaultvalue={item?.DepartureDate}
                                onChange={(newValue) => {
                                  try {
                                    FetchValueItem(
                                      "DepartureDate",
                                      newValue,
                                      index
                                    );
                                  } catch (err) {
                                    console.log(err);
                                  }
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entered By :
                            </a>
                            <input
                              type="tel"
                              className="form-control mt-4"
                              name="EnteredBy"
                              id="EnteredBy"
                              disabled={true}
                              value={item?.DepartureDateEntredBy}
                              label="Entered By"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" lg="4" md="4">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              Entry Date/Time :
                            </a>
                            <span className="text-muted font-weight-bold">
                              <input
                                type="tel"
                                className="form-control mt-4"
                                name="ConsigneeDepartureDateEnteredDate"
                                id="ConsigneeDepartureDateEnteredDate"
                                disabled={true}
                                value={
                                  item?.DepartureDateEntredDate === null ||
                                  item?.DepartureDateEntredDate === undefined ||
                                  item?.DepartureDateEntredDate === ""
                                    ? ""
                                    : moment(
                                        item?.DepartureDateEntredDate
                                      ).format("MM-DD-YYYY, h:mm A")
                                }
                                label="Entered By"
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row className=" mb-5">
                      <Col xl="4" lg="4" md="4" className="SO-Date">
                        <div className=" flex-wrap align-items-center pb-5">
                          <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                            <a
                              href={() => false}
                              className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                            >
                              POD :
                            </a>
                            <span className="font-weight-bold">
                              <input
                                type="text"
                                className="form-control mt-4"
                                name="Pod"
                                id="Pod"
                                value={item?.Pod}
                                label="Pod"
                                onChange={(e) => {
                                  FetchValueItem("Pod", e.target.value, index);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
          </Fragment>
        ))}
    </Fragment>
  );
}
