/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import AllShipment from "./AllShipment";

const colors = [
  "#187DE4",
  "#1BC5BD",
  "#FFA800",
  "#EEE5FF",
  "#4ca1afad",
  "#d0d8e0",
  "#7e8299",
  "#F64E60",
  "#F64E60",
];

export function FuelSurchargeTab({
  className,
  FuelSurchargeList,
  loadingDataFuelSurcharge,
  initFilter,
}) {
  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_8_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(FuelSurchargeList);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [FuelSurchargeList, loadingDataFuelSurcharge]);
  FuelSurchargeList.forEach((element) => {
    console.log(
      "moment",
      moment(
        (element.Month.length === 1 ? "0" + element.Month : element.Month) +
          "-01-" +
          element.Year
      ).format("MM-DD-YYYY")
    );
  });
  return (
    <>
      <div className={`card card-custom ${className}`}>
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Fuel Surcharge
          </h3>
        </div>
        <div className="card-body d-flex flex-column">
          <div className="row">
            <div className="col-lg-12 text-center">
              {loadingDataFuelSurcharge === true ? (
                <h4>Loading ...</h4>
              ) : FuelSurchargeList.length === 0 ? (
                <h4>There is no data at the moment</h4>
              ) : (
                <div
                  id="kt_performance_widget_8_chart"
                  className="card-rounded-bottom"
                  style={{ height: "350px" }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AllShipment initFilter={initFilter} />
    </>
  );
}

function getChartOption(FuelSurchargeList) {
  const options = {
    series: [
      {
        name: "Intermodal",
        data:
          FuelSurchargeList?.map((a) =>
            a.Intermodal === null ? 0 : a.Intermodal
          ) || [],
      },
      {
        name: "Ltl",
        data: FuelSurchargeList?.map((a) => (a.Ltl === null ? 0 : a.Ltl)) || [],
      },
      {
        name: "OTR",
        data: FuelSurchargeList?.map((a) => (a.OTR === null ? 0 : a.OTR)) || [],
      },
    ],
    colors: colors,
    chart: {
      height: 350,
      type: "area",
    },
    legend: {
      show: true,
    },

    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories:
        FuelSurchargeList.map((a) =>
          moment(
            (a.Month.length === 1 ? "0" + a.Month : a.Month) + "-01-" + a.Year
          ).format("MM-DD-YYYY")
        ) || [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    yaxis: {
      labels: {
        formatter: function(value) {
          return value + "$";
        },
      },
    },
  };
  return options;
}
