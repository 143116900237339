import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

let commodityTableMock;
export default async function mockCommodities(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetCommodities() {
    return await fetch(window.$apiurl + "/commodities", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCommodity(someData) {
    return fetch(window.$apiurl + "/commodities", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PutCommodity(someData, id) {
    let userDate = someData["customer"];
    return fetch(`${window.$apiurl}/commodities/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDate),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteCommodity(id) {
    return await fetch(`${window.$apiurl}/commodities/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------

  mock.onPost("api/commodities").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { NMFC = "", Class = "", Description = "" } = customer;
    const newCommodity = {
      NMFC,
      Class,
      Description,
    };
    var postCommodity = await PostCommodity(newCommodity);
    newCommodity.id = postCommodity.Id;
    commodityTableMock.push(newCommodity);
    return [200, { customer: newCommodity }];
  });

  mock.onPost("api/commodities/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    commodityTableMock = await GetCommodities();
    const filterdCommodities = mockUtils.baseFilter(
      commodityTableMock,
      queryParams
    );
    return [200, filterdCommodities];
  });

  mock.onGet(/api\/commodities\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/commodities\/(\d+)/)[1];
    const commodity = commodityTableMock.find((el) => el.id === +id);
    if (!commodity) {
      return [400];
    }
    return [200, commodity];
  });

  mock.onPut(/api\/commodities\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/commodities\/(\d+)/)[1];
    const Putcommodity = JSON.parse(config.data);
    const { commodity } = Putcommodity;
    var putCommodity = await PutCommodity(Putcommodity, id);
    if (!putCommodity) {
      return false;
    }
    const index = commodityTableMock.findIndex((el) => el.id === +id);
    if (index === -1) return [400];
    commodityTableMock[index] = { ...commodity };
    return [200];
  });

  mock.onDelete(/api\/commodities\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/commodities\/(\d+)/)[1];
    const index = commodityTableMock.findIndex((el) => el.id === +id);
    if (index === -1) return [400];
    var deleteCommodity = await DeleteCommodity(id);
    if (!deleteCommodity) return [400];
    commodityTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
