import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col, Dropdown, Alert } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Notification } from "../../modules/Notification";
import { ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";

export function ImportZoneBilling() {
    const useStyles = makeStyles(theme => ({
        root: {
            width: "100%",
        },
        paper: {
            marginTop: theme.spacing(3),
            width: "100%",
            overflowX: "auto",
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 650,
        },
        row: {
            height: 50,
        },
        rowBody: {
            height: 50,
            fontWeight: "bold",
        },
        button: {
            margin: theme.spacing(1),
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
    }));
    const classes = useStyles();
    const [rowsState, setRowsState] = useState([]);
    const [rowsStatBool, setRowsStatBool] = useState(false);
    const [rowsEmpty, setRowsEmpty] = useState(true);
    const [importInProgress, setImportInProgress] = useState(false);

    const fileHandlerOnclick = () => {
        if (rowsEmpty) {
            Notification("error", "Please select a valid file to import.");
            setRowsStatBool(false);
            return false;
        }
        setRowsStatBool(true);
    };

    const fileHandler = (event) => {
        setRowsStatBool(false);
        setRowsState([]);
        setRowsEmpty(false);
        let fileObj = event.target.files[0];
        console.log('fileObj');
        console.log(fileObj);
        ExcelRenderer(fileObj, (err, resp) => {
            if (err || resp.rows.length < 4) {
                setRowsEmpty(true);
                return false;
            } else {
                var a = resp.rows[4].indexOf("CustomerName");
                var b = resp.rows[4].indexOf("WarehouseName");
                var c = resp.rows[4].indexOf("Carrier");
                var d = resp.rows[4].indexOf("ServiceLevel");
                var e = resp.rows[4].indexOf("ZoneName");
                var f = resp.rows[4].indexOf("Weight");
                var g = resp.rows[4].indexOf("Rate");
                if (
                    a === -1 ||
                    b === -1 ||
                    c === -1 ||
                    d === -1 ||
                    e === -1 ||
                    f === -1 ||
                    g === -1 
                ) {
                    setRowsEmpty(true);
                    return false;
                }

                let rows = [];
                resp.rows.slice(5, resp.rows.length).forEach((element, index) => {
                    var empty = 1;
                    var error = "";
                    if (element[a] === undefined ||
                        element[b] === undefined ||
                        element[c] === undefined ||
                        element[d] === undefined ||
                        element[e] === undefined ||
                        element[f] === undefined ||
                        element[g] === undefined
                        )
                    {
                        empty = 0;
                        error = "Please check the required fields*."
                    }
                    rows.push(
                        createData(
                            empty,
                            index + 1,
                            element[a],
                            element[b],
                            element[c],
                            element[d],
                            element[e],
                            element[f],
                            element[g],
                            error
                        )
                    );
                });
                console.log('rows');
                console.log(rows);
                setRowsState(rows);
                setRowsEmpty(false);
            }
        });
    };

    function createData(
        Empty,
        Index,
        CustomerName,
        WarehouseName,
        Carrier,
        ServiceLevel,
        ZoneName,
        Weight,
        Rate,
        Error
    ) {
        return {
            Empty,
            Index,
            CustomerName,
            WarehouseName,
            Carrier,
            ServiceLevel,
            ZoneName,
            Weight,
            Rate,
            Error
        };
    }

    const fileUploadeOnclick = async () => {
        setImportInProgress(true);
        if (rowsStatBool) {
            if (rowsState.length > 0) {
                var result = await axios.post("api/ZoneBilling/import", { Zones: rowsState });
                if (result.data.success === true) {
                    Notification("success", "Import done successfully.");
                }
                else {
                    Notification("error", result.data.Message);
                }
            }
            else {
                Notification("error", "Please select a valid file to import.");
            }
        }
        else {
            Notification("error", "Please select a valid file to import.");
        }
        setImportInProgress(false);
    };

    const fileClear = (event) => {
        setRowsState([]);
        event.target.value = "";
    }

    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                    <Link to="/ZoneBilling" className="btn btn-light mb-5">
                        <i className="fa fa-arrow-left"></i>
                        Back
                    </Link>
                        <div className= "col-12 mb-5">
                            <h3 className="font-weight-bold text-primary">IMPORT FILE ZONE BILLING</h3>
                            <hr className="font-weight-bold text-primary"></hr>
                        </div>

                        <div className='col-12 mb-5'>
                            <Alert variant="info " className="w-50">
                                Use the 'Browse...' button to select a zone billing import file.
                            </Alert>
                        </div>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Import </Form.Label>
                            <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                                <Form.Control
                                    type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={fileHandler.bind(this)}
                                    onClick={fileClear.bind(this)}
                                />
                            </Card>
                        </Form.Group>


                        <div className='col-12 mb-5'>
                            <Alert variant="info " className="w-50">
                                Then press the 'Import' button to read the file.
                            </Alert>
                        </div>

                        <div className='col-12 mt-5 row'>
                            <div className='col-3'>
                            <Button as="input" type="submit" color="secondary" readOnly value="Import"
                                className="btn btn-primary btn-shadow-hover mr-2"
                                onClick={() => {
                                    fileHandlerOnclick();
                                }}
                            />
                            </div>
                        </div>
                        
                    </Form.Row>
                    {rowsStatBool && (
                        <Form.Row className="mt-5 mb-5 pb-5">
                            <Paper className={classes.paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className="bg-dark">
                                        <TableRow className={classes.row}>
                                            <TableCell className="text-white">Is Valid</TableCell>
                                            <TableCell className="text-white">Line</TableCell>
                                            <TableCell className="text-white">CustomerName*</TableCell>
                                            <TableCell className="text-white">WarehouseName*</TableCell>
                                            <TableCell className="text-white">Carrier*</TableCell>
                                            <TableCell className="text-white">ServiceLevel*</TableCell>
                                            <TableCell className="text-white">ZoneName*</TableCell>
                                            <TableCell className="text-white">Weight*</TableCell>
                                            <TableCell className="text-white">Rate*</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsState.map((row) => (
                                            <TableRow className={classes.rowBody} key={row.Index}>
                                                <TableCell>
                                                    {row.Empty == 1 && (
                                                        <i data-toggle="tooltip" title="Valid" className="fa fa-check text-success icon-xxl"></i>
                                                    )}
                                                    {row.Empty == 0 && (
                                                        <i data-toggle="tooltip" title={row.Error} className="fa fa-times-circle text-danger icon-xxl"></i>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.Index}</TableCell>
                                                <TableCell>{row.CustomerName}</TableCell>
                                                <TableCell>{row.WarehouseName}</TableCell>
                                                <TableCell>{row.Carrier}</TableCell>
                                                <TableCell>{row.ServiceLevel}</TableCell>
                                                <TableCell>{row.ZoneName}</TableCell>
                                                <TableCell>{row.Weight}</TableCell>
                                                <TableCell>{row.Rate}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                            <div className="text-center col-12"> <b>{rowsState.length > 0 ? rowsState.length + " Zone Billing (s)" : "No Zone Billing was imported"}.</b></div>
                        </Form.Row>
                    )}{" "}

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label className="text-dark font-weight-bold mt-3">Create</Form.Label>
                            <div className="MuiPaper-root font-size-lg mt-2">
                                <p>Press the 'Create' button to create the new zone Billings.</p>
                            </div>

                            <Button as="input" value={importInProgress ? "Importing...": "Create"} type="submit" readOnly disabled={importInProgress} className="btn btn-success btn-shadow-hover mr-2"
                                onClick={() => {
                                    fileUploadeOnclick();
                                }} />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
