import customerTableMock from "./ManageTPLScaleTableMock";
import MockUtils from "./mock.utils";
let carTableMock;
export default function mockManageTPLScale(mock) {
  async function GetTPLScales() {
    return await fetch(window.$apiurl+"/TPLScales")
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostTPLScales(someData) {
  
    return fetch(window.$apiurl+"/TPLScales", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    }).then(async (response) => {
      const data = await response.json();
      return Promise.all([data]);
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.error(error);
    });
  }


  async function PutTPLScales(someData,id) {
    // let userDate = someData["customer"];
    // console.log("userDate")
    // console.log(userDate);
    return fetch(window.$apiurl+"/TPLScales/"+id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
      })
      .catch((err) => {
      });
  }

  async function DeleteTPLScales(id) {
    return await fetch(window.$apiurl+"/TPLScales/"+id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
      })
      .catch((err) => {
      });
  }
  //--------------------------------------------mock------------------------
  mock.onPost("api/ManageTPLScale").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Id=0,
        Description = ""
    } = customer;

    const newCustomer = {
      Id,
      Description
    };
   const dataw=await PostTPLScales(newCustomer);
   newCustomer.Id=dataw[0].Id;
   newCustomer.id=dataw[0].Id;
   carTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageTPLScale/find").reply(async config => {
 
    const data = await GetTPLScales();
    carTableMock=data;
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdCustomers = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageTPLScale/deleteCustomers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageTPLScale/updateStatusForCustomers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageTPLScale\/\d+/).reply(config => {
    const id = config.url.match(/api\/ManageTPLScale\/(\d+)/)[1];
    const customer = carTableMock.find(el => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/ManageTPLScale\/\d+/).reply(async config => {
    const id = config.url.match(/api\/ManageTPLScale\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    await PutTPLScales(customer,id);

    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageTPLScale\/\d+/).reply(async config => {
    const id = config.url.match(/api\/ManageTPLScale\/(\d+)/)[1];
    await DeleteTPLScales(id);
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
function generateUserId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
