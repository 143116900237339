
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Card, Col, Alert } from "react-bootstrap";
import { ExcelRenderer } from "react-excel-renderer";
import axios from "axios";
import { Notification } from "../../modules/Notification";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        marginTop: theme.spacing(3),
        width: "100%",
        overflowX: "auto",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    row: {
        height: 50,
    },
    rowBody: {
        height: 50,
        fontWeight: "bold",
    },
}));

export function ImportOrders() {
    const classes = useStyles();
    const [warehousesList, setWarehousesList] = useState([]);
    const [customersList, setCustomersList] = useState([]);
    const [rowsStat, setRowsStat] = useState([]);
    const [rowsStatBool, setRowsStatBool] = useState(false);
    const [rowsStatVlue, setrowsStatVlue] = useState([]);
    const [rowsEmpty, setRowsEmpty] = useState(true);

    useEffect(() => {
        fetchUsersAsync();
        fetchUsersAsync2();
    }, []);

    const fetchUsersAsync = async () => {
        try {
            const result = await axios.get("api/Warehouses/GetWarehouses");
            setWarehousesList(result.data["GetWarehouses"]);
        } catch (e) { }
    };

    const fetchUsersAsync2 = async () => {
        try {
            const result = await axios.get("api/ManageItems/Filter");
            setCustomersList(result.data["customersList"]);
        } catch (e) { }
    };

    function createData(
        empty,
        ReferenceNumber,
        PurchaseOrderNumber,
        ShipCarrier,
        ShipService,
        ShipBilling,
        ShipAccount,
        ShipDate,
        CancelDate,
        Notes,
        ShipToName,
        ShipToCompany,
        ShipToAddress1,
        ShipToAddress2,
        ShipToCity,
        ShipToState,
        ShipToZip,
        ShipToCountry,
        ShipToPhone,
        ShipToFax,
        ShipToEmail,
        ShipToCustomerID,
        ShipToDeptNumber,
        RetailerID,
        SKU,
        Quantity,
        UseCOD,
        UseInsurance,
        SavedElements,
        OrderItemSavedElements,
        CarrierNotes,
         

    ) {
        return {
            empty,
            ReferenceNumber,
            PurchaseOrderNumber,
            ShipCarrier,
            ShipService,
            ShipBilling,
            ShipAccount,
            ShipDate,
            CancelDate,
            Notes,
            ShipToName,
            ShipToCompany,
            ShipToAddress1,
            ShipToAddress2,
            ShipToCity,
            ShipToState,
            ShipToZip,
            ShipToCountry,
            ShipToPhone,
            ShipToFax,
            ShipToEmail,
            ShipToCustomerID,
            ShipToDeptNumber,
            RetailerID,
            SKU,
            Quantity,
            UseCOD,
            UseInsurance,
            SavedElements,
            OrderItemSavedElements,
            CarrierNotes,
        };
    }

    const fileHandler = (event) => {
        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            if (err) {
                setRowsEmpty(true);
                return false;
            } else {
                //Fill the fields of table
                var a = resp.rows[1].indexOf("ReferenceNumber");
                var b = resp.rows[1].indexOf("PurchaseOrderNumber");
                var c = resp.rows[1].indexOf("ShipCarrier");
                var d = resp.rows[1].indexOf("ShipService");
                var e = resp.rows[1].indexOf("ShipBilling");
                var f = resp.rows[1].indexOf("ShipAccount");
                var g = resp.rows[1].indexOf("ShipDate");
                var h = resp.rows[1].indexOf("CancelDate");
                var i = resp.rows[1].indexOf("Notes");
                var i2 = resp.rows[1].indexOf("ShipTo Name");
                var i3 = resp.rows[1].indexOf("ShipToCompany");
                var i4 = resp.rows[1].indexOf("ShipToAddress1");
                var i5 = resp.rows[1].indexOf("ShipToAddress2");
                var i6 = resp.rows[1].indexOf("ShipToCity");
                var i7 = resp.rows[1].indexOf("ShipToState");
                var i8 = resp.rows[1].indexOf("ShipToZip");
                var i9 = resp.rows[1].indexOf("ShipToCountry");
                var i10 = resp.rows[1].indexOf("ShipToPhone");
                var i11 = resp.rows[1].indexOf("ShipToFax");
                var i12 = resp.rows[1].indexOf("ShipToEmail");
                var i13 = resp.rows[1].indexOf("ShipToCustomerID");
                var i14 = resp.rows[1].indexOf("ShipToDeptNumber");
                var i15 = resp.rows[1].indexOf("RetailerID");
                var i16 = resp.rows[1].indexOf("SKU");
                var i17 = resp.rows[1].indexOf("Quantity");
                var i18 = resp.rows[1].indexOf("UseCOD");
                var i19 = resp.rows[1].indexOf("UseInsurance");
                var i20 = resp.rows[1].indexOf("Saved Elements");
                var i21 = resp.rows[1].indexOf("Order Item Saved Elements");
                var i22 = resp.rows[1].indexOf("Carrier Notes");

                const warehouseId = document.getElementById("warehouseId").value;
                const CustomerId = document.getElementById("CustomerId").value;


                if (
                    a === -1 ||
                    b === -1 ||
                    c === -1 ||
                    d === -1 ||
                    e === -1 ||


                    i3 === -1 ||
                    i4 === -1 ||
                    i5 === -1 ||
                    i6 === -1 ||
                    i7 === -1 ||
                    i8 === -1 ||
                    i9 === -1 ||

                    i16 === -1 ||
                    i17 === -1

                ) {
                    setRowsEmpty(true);
                    return false;
                }

                let rows = [];
                let rows2 = [];
                resp.rows.slice(2, resp.rows.length).forEach((element, index) => {
                    if (element.length === 0) return false;
                    var empty = 1;
                    if (
                        //important fields
                        element[a] === undefined ||
                        element[b] === undefined ||
                        element[c] === undefined ||
                        element[d] === undefined ||
                        element[e] === undefined ||

                        element[i3] === undefined ||
                        element[i4] === undefined ||
                        element[i5] === undefined ||
                        element[i6] === undefined ||
                        element[i7] === undefined ||
                        element[i8] === undefined ||
                        element[i9] === undefined ||
                        element[i16] === undefined ||
                        element[i17] === undefined


                    )
                        empty = 0;
                    rows.push(
                        createData(
                            empty,
                            element[a],
                            element[b],
                            element[c],
                            element[d],
                            element[e],
                            element[f],
                            element[g],
                            element[h],
                            element[i],
                            element[i2],
                            element[i3],
                            element[i4],
                            element[i5],
                            element[i6],
                            element[i7],
                            element[i8],
                            element[i9],
                            element[i10],
                            element[i11],
                            element[i12],
                            element[i13],
                            element[i14],
                            element[i15],
                            element[i16],
                            element[i17],
                            element[i18],
                            element[i19],
                            element[i20],
                            element[i21],
                            element[i22],
                            warehouseId,
                            CustomerId,
                            1,
                            true
                        )
                    );

                    if(empty == 1) 
                    rows2.push({
                        warehouseId :warehouseId,
                        CustomerId:CustomerId,
                        ReferenceNumber:element[a]
                    })
                });
                setrowsStatVlue(rows2);
                setRowsStat(rows);
                setRowsEmpty(false);
            }
        });
    };

    const fileHandlerOnclick = () => {
        if (rowsEmpty) {
            Notification("error", "error");
            setRowsStatBool(false);
            return false;
        }
        Notification("success", "Data Imported successfully");
        setRowsStatBool(true);
    };

    const fileUploadeOnclick = () => {
        if (!rowsEmpty) {
            axios.post("/api/orders/import", { data: rowsStatVlue });
        }
    };

    return (
        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <div className="col-6">
                            <label className="form-label" for="formGridPassword">
                                Customer{" "}
                            </label>
                            <select
                                className="form-control  is-valid-select"
                                name="CustomerId"
                                id="CustomerId"
                            >
                                {customersList.map((item, index) => (
                                    <option key={index} value={item.Id}>
                                        {item.CompanyName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-6">
                            <label className="form-label" for="formGridPassword">
                                warehouse{" "}
                            </label>
                            <select
                                className="form-control  is-valid-select"
                                name="warehouseId"
                                id="warehouseId"
                            >
                                {warehousesList.map((item, index) => (
                                    <option key={index} value={item.id}>
                                        {item.Name}
                                    </option>
                                ))}
                            </select>
                        </div>



                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Import </Form.Label>
                            <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                                <Form.Control
                                    type="file"
                                    placeholder="Password"
                                    onChange={fileHandler.bind(this)}
                                />
                            </Card>
                        </Form.Group>
                    </Form.Row>
                    {rowsStatBool && (
                        <Form.Row className="mt-5 mb-5 pb-5">
                            <Paper className={classes.paper}>
                                <Table className={classes.table} size="small">
                                    <TableHead className="bg-dark">
                                        <TableRow className={classes.row}>
                                            <TableCell className="text-white">IsValid</TableCell>
                                            <TableCell className="text-white">ReferenceNumber</TableCell>
                                            <TableCell className="text-white">PurchaseOrderNumber</TableCell>
                                            <TableCell className="text-white">ShipCarrier</TableCell>
                                            <TableCell className="text-white">ShipService</TableCell>
                                            <TableCell className="text-white">ShipBilling</TableCell>
                                            <TableCell className="text-white">ShipAccount</TableCell>
                                            <TableCell className="text-white">ShipDate</TableCell>
                                            <TableCell className="text-white">CancelDate</TableCell>
                                            <TableCell className="text-white">Notes</TableCell>

                                            <TableCell className="text-white">ShipTo Name</TableCell>

                                            <TableCell className="text-white">ShipToCompany</TableCell>

                                            <TableCell className="text-white">ShipToAddress1</TableCell>
                                            <TableCell className="text-white">ShipToAddress2</TableCell>
                                            <TableCell className="text-white">ShipToCity</TableCell>
                                            <TableCell className="text-white">ShipToState</TableCell>
                                            <TableCell className="text-white">ShipToZip</TableCell>
                                            <TableCell className="text-white">ShipToCountry</TableCell>
                                            <TableCell className="text-white">ShipToPhone</TableCell>
                                            <TableCell className="text-white">ShipToFax</TableCell>
                                            <TableCell className="text-white">ShipToEmail</TableCell>
                                            <TableCell className="text-white">ShipToCustomerID</TableCell>
                                            <TableCell className="text-white">ShipToDeptNumber</TableCell>
                                            <TableCell className="text-white">RetailerID</TableCell>
                                            <TableCell className="text-white">SKU</TableCell>
                                            <TableCell className="text-white">Quantity</TableCell>
                                            <TableCell className="text-white">UseCOD</TableCell>
                                            <TableCell className="text-white">UseInsurance</TableCell>
                                            <TableCell className="text-white"> Saved Elements</TableCell>
                                            <TableCell className="text-white">Order Item Saved Elements</TableCell>
                                            <TableCell className="text-white">Carrier Notes</TableCell>





                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rowsStat.map((row) => (
                                            <TableRow className={classes.rowBody} key={row.name}>
                                                <TableCell>
                                                    {row.empty == 1 && (
                                                        <i className="fa fa-check text-success icon-xxl"></i>
                                                    )}
                                                    {row.empty == 0 && (
                                                        <i className="fa fa-times-circle text-danger icon-xxl"></i>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.rowBody}
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {row.ReferenceNumber}
                                                </TableCell>
                                                <TableCell>{row.PurchaseOrderNumber}</TableCell>
                                                <TableCell>{row.ShipCarrier}</TableCell>
                                                <TableCell>{row.ShipServic}</TableCell>
                                                <TableCell>{row.ShipBilling}</TableCell>
                                                <TableCell>{row.ShipAccount}</TableCell>
                                                <TableCell>{row.ShipDate}</TableCell>
                                                <TableCell>{row.CancelDate}</TableCell>
                                                <TableCell>{row.Notes}</TableCell>
                                                <TableCell>{row.ShipToName}</TableCell>
                                                <TableCell>{row.ShipToCompany}</TableCell>
                                                <TableCell>{row.ShipToAddress1}</TableCell>
                                                <TableCell>{row.ShipToAddress2}</TableCell>
                                                <TableCell>{row.ShipToCity}</TableCell>
                                                <TableCell>{row.ShipToState}</TableCell>
                                                <TableCell>{row.ShipToZip}</TableCell>
                                                <TableCell>{row.ShipToCountry}</TableCell>
                                                <TableCell>{row.ShipToPhone}</TableCell>
                                                <TableCell>{row.ShipToFax}</TableCell>
                                                <TableCell>{row.ShipToEmail}</TableCell>
                                                <TableCell>{row.ShipToCustomerID}</TableCell>
                                                <TableCell>{row.ShipToDeptNumber}</TableCell>
                                                <TableCell>{row.RetailerID}</TableCell>
                                                <TableCell>{row.SKU}</TableCell>
                                                <TableCell>{row.Quantity}</TableCell>
                                                <TableCell>{row.UseCOD}</TableCell>
                                                <TableCell>{row.UseInsurance}</TableCell>
                                                <TableCell>{row.SavedElements}</TableCell>
                                                <TableCell>{row.OrderItemSavedElements}</TableCell>
                                                <TableCell>{row.CarrierNotes}</TableCell>



                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Form.Row>
                    )}{" "}
                    <Form.Row>
                        <Alert variant="info " className="w-50">
                            Use the 'Browse...' button to select a Order import file
                          <p></p>
              Then press the 'Import Orders' button to read the file.
            </Alert>
                        <Alert className="w-50"></Alert>
                        <Button
                            as="input"
                            type="submit"
                            value="Import Order"
                            onClick={() => {
                                fileHandlerOnclick();
                            }}
                        />
                    </Form.Row>
                    <br />
                    <br />
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label className="w-100">
                                Orders To Be Created{" "}
                            </Form.Label>
                            <Alert variant="info " className="w-50">
                                After previewing the imported data for accuracy
                <p></p>
                Press the 'Create Order' button to create the new Order.
              </Alert>
                            <Button
                                as="input"
                                type="submit"
                                value="Create Order"
                                onClick={() => {
                                    fileUploadeOnclick();
                                }}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    );
}
