/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, createContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./RemarksUIHelper";

const RemarksUIContext = createContext();

export function useRemarksUIContext() {
  return useContext(RemarksUIContext);
}

export const RemarksUIConsumer = RemarksUIContext.Consumer;

export function RemarksUIProviderCustomField({ currentPurchaseOrderId, children }) {
  const [purchaseOrderId, setPurchaseOrderId] = useState(currentPurchaseOrderId);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [showEditRemarkDialog, setShowEditRemarkDialog] = useState(false);
  const initRemark = {
    Id: undefined,
    PurchaseOrderId: purchaseOrderId,
    ItemId: "",
    QualifierId: null,
    Quantity: 0,
    WarehouseId: "",
    ExcpectedDate: "2017-05-24T10:30",
    CanceledDate: "2017-05-24T10:30",
    Notes: ""
  };
  useEffect(() => {
    initRemark.purchaseOrderId = currentPurchaseOrderId;
    setPurchaseOrderId(currentPurchaseOrderId);
  }, [currentPurchaseOrderId]);
  const openNewRemarkDialog = () => {
    setSelectedId(undefined);
    setShowEditRemarkDialog(true);
  };
  const openEditRemarkDialog = id => {
    setSelectedId(id);
    setShowEditRemarkDialog(true);
  };
  const closeEditRemarkDialog = () => {
    setSelectedId(undefined);
    setShowEditRemarkDialog(false);
  };
  const [showDeleteRemarkDialog, setShowDeleteRemarkDialog] = useState(false);
  const openDeleteRemarkDialog = id => {
    setSelectedId(id);
    setShowDeleteRemarkDialog(true);
  };
  const closeDeleteRemarkDialog = () => {
    setSelectedId(undefined);
    setShowDeleteRemarkDialog(false);
  };

  const [showDeleteRemarksDialog, setShowDeleteRemarksDialog] = useState(false);
  const openDeleteRemarksDialog = () => {
    setShowDeleteRemarksDialog(true);
  };
  const closeDeleteRemarksDialog = () => {
    setShowDeleteRemarksDialog(false);
  };

  const [showFetchRemarksDialog, setShowFetchRemarksDialog] = useState(false);
  const openFetchRemarksDialog = () => {
    setShowFetchRemarksDialog(true);
  };
  const closeFetchRemarksDialog = () => {
    setShowFetchRemarksDialog(false);
  };

  const value = {
    ids,
    setIds,
    productId: purchaseOrderId,
    setProductId: setPurchaseOrderId,
    queryParams,
    setQueryParams,
    initRemark,
    selectedId,
    showEditRemarkDialog,
    openNewRemarkDialog,
    openEditRemarkDialog,
    closeEditRemarkDialog,
    showDeleteRemarkDialog,
    openDeleteRemarkDialog,
    closeDeleteRemarkDialog,
    showDeleteRemarksDialog,
    openDeleteRemarksDialog,
    closeDeleteRemarksDialog,
    openFetchRemarksDialog,
    closeFetchRemarksDialog,
    showFetchRemarksDialog
  };

  return (
    <RemarksUIContext.Provider value={value}>
      {children}
    </RemarksUIContext.Provider>
  );
}
