import React, { useEffect } from "react";
import $ from "jquery";

function minOr30(h) {
  try {
    let a = h?.split(":");
    let b = parseInt(a[1]);
    let c = 15 < b < 45 ? "30" : "00";
    return `${a[0]}:${c}:00`;
  } catch (err) {
    return "00:00:00";
  }
}
export function DateTime12({
  field,
  label,
  dateNow,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  required,
  ClassLabel,
  setFieldValue,
  defaultvalue,
  ...props
}) {
  const { name, disabled } = props;
  useEffect(() => {
    if(dateNow){
      var curr1 = new Date(dateNow);
      var date1 = curr1?.toISOString()?.substr(0, 10) || false;
      $(`#${name}DateId`).val(date1);
    }
    if (defaultvalue) {
      var curr = new Date(defaultvalue);
      curr.setHours(curr.getHours() + 1);
      var date = curr?.toISOString()?.substr(0, 10) || false;
      var date2 = curr?.toISOString()?.substr(11, 8) || false;
      date2 = minOr30(date2);
      if (date) $(`#${name}DateId`).val(date);
      if (date2) $(`#${name}TimeId`).val(date2);
    }
  }, [defaultvalue]);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          {label && (
            <label className={ClassLabel}>
              {label} {required && <RequiredHtml />}
              {span && (
                <small className="alert alert-warning text-dark p-0 pl-2 pr-2">
                  {" "}
                  {span}
                </small>
              )}
            </label>
          )}
          <input
            id={name + "DateId"}
            type={"date"}
            className="form-control"
            onBlur={(event) => {
              if (setFieldValue)
                setFieldValue(name, event.target.value + "T00:30");
            }}
          />
        </div>
        <div className="col-lg-6">
          {label && <label className={ClassLabel}>Time</label>}
          <select
            id={name + "TimeId"}
            className="form-control"
            disabled={disabled}
            defaultValue="00:00:00"
            onChange={(e) => {
              var a = $(`#${name}DateId`).val();
              if (a) {
                if (setFieldValue)
                  setFieldValue(name, a.split("T")[0] + "T" + e.target.value);
              }
            }}
          >
            <option value="0">Select</option>
            <option value="00:00:00">12.00 AM</option>
            <option value="00:30:00">12.30 AM</option>
            <option value="01:00:00">01.00 AM</option>
            <option value="01:30:00">01.30 AM</option>
            <option value="02:00:00">02.00 AM</option>
            <option value="02:30:00">02.30 AM</option>
            <option value="03:00:00">03.00 AM</option>
            <option value="03:30:00">03.30 AM</option>
            <option value="04:00:00">04.00 AM</option>
            <option value="04:30:00">04.30 AM</option>
            <option value="05:00:00">05.00 AM</option>
            <option value="05:30:00">05.30 AM</option>
            <option value="06:00:00">06.00 AM</option>
            <option value="06:30:00">06.30 AM</option>
            <option value="07:00:00">07.00 AM</option>
            <option value="07:30:00">07.30 AM</option>
            <option value="08:00:00">08.00 AM</option>
            <option value="08:30:00">08.30 AM</option>
            <option value="09:00:00">09.00 AM</option>
            <option value="09:30:00">09.30 AM</option>
            <option value="10:00:00">10.00 AM</option>
            <option value="10:30:00">10.30 AM</option>
            <option value="11:00:00">11.00 AM</option>
            <option value="11:30:00">11.30 AM</option>
            <option value="12:00:00">12.00 PM</option>
            <option value="12:30:00">12.30 PM</option>
            <option value="13:00:00">01.00 PM</option>
            <option value="13:30:00">01.30 PM</option>
            <option value="14:00:00">02.00 PM</option>
            <option value="15:00:00">03.00 PM</option>
            <option value="15:30:00">03.30 PM</option>
            <option value="16:00:00">04.00 PM</option>
            <option value="16:30:00">04.30 PM</option>
            <option value="17:00:00">05.00 PM</option>
            <option value="17:30:00">05.30 PM</option>
            <option value="18:00:00">06.00 PM</option>
            <option value="18:30:00">06.30 PM</option>
            <option value="19:00:00">07.00 PM</option>
            <option value="19:30:00">07.30 PM</option>
            <option value="20:00:00">08.00 PM</option>
            <option value="20:30:00">08.30 PM</option>
            <option value="21:00:00">09.00 PM</option>
            <option value="21:30:00">09.30 PM</option>
            <option value="22:00:00">10.00 PM</option>
            <option value="22:30:00">10.30 PM</option>
            <option value="23:00:00">11.00 PM</option>
            <option value="23:30:00">11.30 PM</option>
          </select>
        </div>
      </div>
    </>
  );
}
