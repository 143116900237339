import React, { useMemo } from "react";
import { useRemarksUIContext } from "./RemarksUIContext";
const prepareFilter = (queryParams, values) => {
  const { searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.text = searchText;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function RemarksFilter() {
  // Remarks UI Context
  const remarksUIContext = useRemarksUIContext();
  const remarksUIProps = useMemo(() => {
    return {
      setQueryParams: remarksUIContext.setQueryParams,
      openNewRemarkDialog: remarksUIContext.openNewRemarkDialog,
      queryParams: remarksUIContext.queryParams,
    };
  }, [remarksUIContext]);

  return (
    <>
      <div className="form-filtration">
        <div className="row">
          <div className="col-md-2 margin-bottom-10-mobile">
            <button
              type="button"
              className="btn btn-success btn-shadow-hover mr-2"
              onClick={() => remarksUIProps.openNewRemarkDialog()}
            >
              Add
          </button>
          </div>
        </div>
      </div>
    </>
  );
}
