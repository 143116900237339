/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import React, { useEffect } from "react";
import { Fragment } from "react";
const colors = [
  "#187DE4",
  "#1BC5BD",
  "#FFA800",
  "#EEE5FF",
  "#4ca1afad",
  "#d0d8e0",
  "#7e8299",
  "#F64E60",
  "#F64E60",
];
export function Pickedup({
  className,
  PerformanceList,
  loadingDataPerformance,
}) {
  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_10_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(PerformanceList);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [PerformanceList, loadingDataPerformance]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Performance Picked Up
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-12 col-12 text-center">
            {loadingDataPerformance === true ? (
              <h4>Loading ...</h4>
            ) : PerformanceList.length === 0 ? (
              <h4>There is no data at the moment</h4>
            ) : (
              <div
                id="kt_performance_widget_10_chart"
                className="card-rounded-bottom"
                style={{ height: "180px" }}
              ></div>
            )}
          </div>

          <div className="col-xl-5 col-lg-5 col-md-12 col-12 align-self-center">
            {PerformanceList?.map((item, index) => (
              <Fragment key={index}>
                <div class="d-flex justify-content-between">
                  <h4
                    className="font-weight-bold"
                    style={{ color: colors[index] }}
                  >
                    Mode: ({item.ModeNam===null ?"Other":item.ModeNam})
                  </h4>
                </div>
                <div class="d-flex justify-content-between">
                  <h4 className="text-muted font-weight-bold">Loads</h4>
                  <h4 className="text-dark-75 font-weight-bold">
                    {item.PickedUp}
                  </h4>
                </div>
                <div class="separator separator-dashed separator-border-2 mb-3"></div>
                <div class="d-flex justify-content-between">
                  <h4 className="text-muted font-weight-bold">On Time</h4>
                  <h4 className="text-dark-75 font-weight-bold">
                    {item.PickedUpOnTimeCount}
                  </h4>
                </div>
                <div className="separator separator-solid separator-border-3  mb-5"></div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(PerformanceList) {
  const options = {
    series: PerformanceList?.map((item, index) =>
      ((item.PickedUpOnTimeCount / item.PickedUp) * 100 || 0).toFixed(2)
    ),
    colors: colors,
    legend: {
      show: true,
    },
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#E1F1FB",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
        },
      },
    },
    grid: {
      padding: {
        top: -20,
      },
    },
    labels: PerformanceList?.map((item, index) =>
      item.ModeNam === null ? "Other" : item.ModeNam
    ),
  };
  return options;
}
