/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};
function generateIcone(Entity) {
  var classe = "";
  switch (Entity) {
    case "Items":
      classe = "flaticon2-box-1";
      break;
    case "Orders":
      classe = "flaticon2-supermarket";
      break;
    case "Customers":
      classe = "flaticon-users";
      break;
    case "Vessels":
      classe = "flaticon2-send-1";
      break;
    case "Shipments":
      classe = "flaticon2-shopping-cart-1";
      break;
    case "Trackings":
      classe = "flaticon2-map";
      break;
    case "Receipts":
      classe = "flaticon-price-tag";
      break;
    default:
      classe = "flaticon2-supermarket";
  }
  return classe;
}
export function SearchResult({ data, dataSearch }) {
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No record found</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
          <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Transload
          </div>
          <div className="mb-10">
            {data
              .filter((character) => character.Type === "Transload")
              .map((item, index) => (
                <div className="d-flex align-items-center flex-grow-1 mb-2">
                  <div className="symbol symbol-45 symbol-circle flex-shrink-0">
                    <div className="symbol-label">
                      <i
                        className={`${generateIcone(item.Entity)} text-primary`}
                      ></i>
                    </div>
                  </div>
                  <div className="d-flex flex-column ml-3 mt-2 mb-2">
                    <Link
                      className="font-weight-bold text-dark text-hover-primary"
                      onClick={()=>{
                        window.ExistUrl = `/General/Search?type=${item.Type}&entity=${item.Entity}&key=${dataSearch}`;
                        document.getElementById("ShowGlobalTab").click();
                      }}
                      to={`/General/Search?type=${item.Type}&entity=${item.Entity}&key=${dataSearch}`}
                    >
                      {item.Entity} ({item.Number} results)
                    </Link>
                  </div>
                </div>
              ))}
          </div>
        </div>        
      </PerfectScrollbar>
    </div>
  );
}
