import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../Notification";
import MockUtils from "./mock.utils";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockTriniumCodes2(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetUsersData() {
    return await fetch(window.$apiurl + "/Trinium/SSline", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCustomerProfileRating(someData) {
    return fetch(window.$apiurl + "/Trinium/SSline", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutUser(someData, id) {
    return fetch(window.$apiurl + "/Trinium/SSline/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/Trinium/SSline/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  }
  mock.onPost("api/TriniumCodes2").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const start = Date.now();
    const dateNow = formatDate(start);
    const {
      mBOLPrefiex = "",
      steamshipline = "",
      triniumCode = "",
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      mBOLPrefiex,
      steamshipline,
      triniumCode,
    };
    var postCustomerProfile = await PostCustomerProfileRating(newCustomer);
    if (postCustomerProfile.Id != undefined) {
      Notification("success", "Trinium code has been added successfully");
      postCustomerProfile.id = postCustomerProfile.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newCustomer,
          message: postCustomerProfile.Message,
          success: true,
        },
      ];
    } else {
      if (postCustomerProfile.Message) {
        Notification("error",postCustomerProfile.Message);
        window.$tripsMngStatus = 400;
        window.$tripMngLoading = false;
      } else {
        Notification("success", "Trinium code has been added successfully");
        document.getElementById("ReLoadTableId").click();
        return [
          200,
          {
            customer: newCustomer,
            message: postCustomerProfile.Message,
            success: true,
          },
        ];
      }
    }
  });

  mock.onPost("api/TriniumCodes2/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetUsersData();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/TriniumCodes2/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/TriniumCodes2/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  async function GetUser(id) {
    return await fetch(window.$apiurl + "/Trinium/SSline/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet(/api\/TriniumCodes2\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TriniumCodes2\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/TriniumCodes2\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TriniumCodes2\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var  putuser = await PutUser(customer, id);
   // const index = customerTableMock.findIndex((el) => el.id === +id);
    if (putuser.Message) {
      Notification("error", putuser.Message);
      window.$tripsMngStatus = 400;
      window.$tripMngLoading = false;
    } else {
      Notification("success", "Trinium code has been updated successfully");
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          message: putuser.Message,
          success: true,
        },
      ];
    }  
  });

  mock.onDelete(/api\/TriniumCodes2\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TriniumCodes2\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
