import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Card, Col, Alert } from "react-bootstrap";
import { ExcelRenderer } from "react-excel-renderer";
import axios from "axios";
import { Notification } from "../../modules/Notification";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  row: {
    height: 50,
  },
  rowBody: {
    height: 50,
    fontWeight: "bold",
  },
}));

function IconFont() {
  return (
    <i
      className="fa fa-star-of-life text-danger icon-l"
      style={{ fontSize: "10px" }}
    ></i>
  );
}

export function ImportItems() {
  const classes = useStyles();
  const [customersList, setCustomersList] = useState([]);
  const [rowsStat, setRowsStat] = useState([]);
  const [rowsStatValid, setRowsStatValid] = useState([]);
  const [rowsStatBool, setRowsStatBool] = useState(false);
  const [rowsEmpty, setRowsEmpty] = useState(true);

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/ManageItems/Filter");
      setCustomersList(result.data["customersList"]);
    } catch (e) {}
  };

  function createData(
    empty,
    SKU,
    Description,
    Description2,
    Min,
    Max,
    CycleCount,
    ReorderQty,
    InventoryMethod,
    Temperature,
    Cost,
    UPC,
    TrackLot,
    TrackSerial,
    TrackExpDate,
    PrimaryInventoryUnitOfMeasureId,
    PackagingUnitOfMeasureId,
    PackingUOMQty,
    Length,
    Width,
    Height,
    Weight,
    Qualifiers,
    StorageSetup,
    VariableSetup,
    NMFC,
    LotNumberRequired,
    SerialNumberRequired,
    SerialNumber,
    ExpDateReqRequired,
    EnableCost,
    CostRequired,
    HazardousMaterial,
    HazMatID,
    HazMatShippingName,
    HazMatHazardClass,
    HazMatPackingGroup,
    HazMatFlashPoint,
    HazMatLabelCode,
    HazMatFlag,
    ImageURL,
    StorageCountScriptTemplateID,
    StorageRates,
    OutboundMobileSerializationBehavior,
    Price,
    TotalQty,
    UnitType
  ) {
    return {
      empty,
      SKU,
      Description,
      Description2,
      Min,
      Max,
      CycleCount,
      ReorderQty,
      InventoryMethod,
      Temperature,
      Cost,
      UPC,
      TrackLot,
      TrackSerial,
      TrackExpDate,
      PrimaryInventoryUnitOfMeasureId,
      PackagingUnitOfMeasureId,
      PackingUOMQty,
      Length,
      Width,
      Height,
      Weight,
      Qualifiers,
      StorageSetup,
      VariableSetup,
      NMFC,
      LotNumberRequired,
      SerialNumberRequired,
      SerialNumber,
      ExpDateReqRequired,
      EnableCost,
      CostRequired,
      HazardousMaterial,
      HazMatID,
      HazMatShippingName,
      HazMatHazardClass,
      HazMatPackingGroup,
      HazMatFlashPoint,
      HazMatLabelCode,
      HazMatFlag,
      ImageURL,
      StorageCountScriptTemplateID,
      StorageRates,
      OutboundMobileSerializationBehavior,
      Price,
      TotalQty,
      UnitType,
    };
  }

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setRowsEmpty(true);
        return false;
      } else {
        var a = resp.rows[1].indexOf("SKU");
        var b = resp.rows[1].indexOf("Description");
        var c = resp.rows[1].indexOf("Description2");
        var d = resp.rows[1].indexOf("Min");
        var e = resp.rows[1].indexOf("Max");
        var f = resp.rows[1].indexOf("Cycle Count");
        var j = resp.rows[1].indexOf("ReorderQty");
        var h = resp.rows[1].indexOf("Inventory Method");
        var i = resp.rows[1].indexOf("Temperature");
        var g = resp.rows[1].indexOf("Cost");
        var k = resp.rows[1].indexOf("UPC");
        var m = resp.rows[1].indexOf("Track Lot");
        var aa = resp.rows[1].indexOf("Track Serial");
        var ab = resp.rows[1].indexOf("Track ExpDate");
        var ac = resp.rows[1].indexOf("Primary Unit of Measure");
        var ad = resp.rows[1].indexOf("Packaging Unit");
        var ae = resp.rows[1].indexOf("Packing UOM Qty");
        var af = resp.rows[1].indexOf("Length");
        var aj = resp.rows[1].indexOf("Width");
        var ah = resp.rows[1].indexOf("Height");
        var ai = resp.rows[1].indexOf("Weight");
        var ag = resp.rows[1].indexOf("Qualifiers");
        var ak = resp.rows[1].indexOf("Storage Setup");
        var am = resp.rows[1].indexOf("Variable Setup");
        var ba = resp.rows[1].indexOf("NMFC#");
        var bb = resp.rows[1].indexOf("Lot Number Required");
        var bc = resp.rows[1].indexOf("Serial Number Required");
        var bd = resp.rows[1].indexOf("Serial Number (must be unique)");
        var be = resp.rows[1].indexOf("Exp Date Req");
        var bf = resp.rows[1].indexOf("Enable Cost");
        var bj = resp.rows[1].indexOf("Cost Required");
        var bh = resp.rows[1].indexOf("IsHazMat");
        var bi = resp.rows[1].indexOf("HazMatID");
        var bg = resp.rows[1].indexOf("HazMatShippingName");
        var bk = resp.rows[1].indexOf("HazMatHazardClass");
        var bm = resp.rows[1].indexOf("HazMatPackingGroup");
        var ca = resp.rows[1].indexOf("HazMatFlashPoint");
        var cb = resp.rows[1].indexOf("HazMatLabelCode");
        var cc = resp.rows[1].indexOf("HazMatFlag");
        var cd = resp.rows[1].indexOf("Image URL");
        var ce = resp.rows[1].indexOf("StorageCountScriptTemplateID");
        var cf = resp.rows[1].indexOf("StorageRates");
        var cj = resp.rows[1].indexOf("OutboundMobileSerializationBehavior");
        var ch = resp.rows[1].indexOf("Price");
        var ci = resp.rows[1].indexOf("TotalQty");
        var cg = resp.rows[1].indexOf("UnitType");

        if (
          a === -1 ||
          b === -1 ||
          m === -1 ||
          aa === -1 ||
          ab === -1 ||
          ac === -1 ||
          ad === -1 ||
          ae === -1 ||
          af === -1 ||
          aj === -1 ||
          ah === -1 ||
          ai === -1 ||
          bb === -1 ||
          bc === -1 ||
          bd === -1 ||
          be === -1 ||
          bf === -1 ||
          bj === -1
        ) {
          setRowsEmpty(true);
          return false;
        }

        let rows = [];
        let rows2 = [];
        resp.rows.slice(2, resp.rows.length).forEach((element, index) => {
          if (element.length === 0) return false;
          var empty = 1;
          if (
            element[a] === undefined ||
            element[b] === undefined ||
            element[m] === undefined ||
            element[aa] === undefined ||
            element[ab] === undefined ||
            element[ac] === undefined ||
            element[ad] === undefined ||
            element[ae] === undefined ||
            element[af] === undefined ||
            element[aj] === undefined ||
            element[ah] === undefined ||
            element[ai] === undefined ||
            element[bb] === undefined ||
            element[bc] === undefined ||
            element[bd] === undefined ||
            element[be] === undefined ||
            element[bf] === undefined ||
            element[bj] === undefined
          )
            empty = 0;
          rows.push(
            createData(
              empty,
              element[a],
              element[b],
              element[c],
              element[d],
              element[e],
              element[f],
              element[j],
              element[h],
              element[i],
              element[g],
              element[k],
              element[m],
              element[aa],
              element[ab],
              element[ac],
              element[ad],
              element[ae],
              element[af],
              element[aj],
              element[ah],
              element[ai],
              element[ag],
              element[ak],
              element[am],
              element[ba],
              element[bb],
              element[bc],
              element[bd],
              element[be],
              element[bf],
              element[bj],
              element[bh],
              element[bi],
              element[bg],
              element[bk],
              element[bm],
              element[ca],
              element[cb],
              element[cc],
              element[cd],
              element[ce],
              element[cf],
              element[cj],
              element[ch],
              element[ci],
              element[cg]
            )
          );

          rows2.push(
            createData(
              empty,
              element[a],
              element[b],
              element[c],
              element[d],
              element[e],
              element[f],
              element[j],
              element[h],
              element[i],
              element[g],
              element[k],
              element[m],
              element[aa],
              element[ab],
              element[ac],
              element[ad],
              element[ae],
              element[af],
              element[aj],
              element[ah],
              element[ai],
              element[ag],
              element[ak],
              element[am],
              element[ba],
              element[bb],
              element[bc],
              element[bd],
              element[be],
              element[bf],
              element[bj],
              element[bh],
              element[bi],
              element[bg],
              element[bk],
              element[bm],
              element[ca],
              element[cb],
              element[cc],
              element[cd],
              element[ce],
              element[cf],
              element[cj],
              element[ch],
              element[ci],
              element[cg]
            )
          );
        });

        setRowsStat(rows);
        setRowsStatValid(rows2);
        setRowsEmpty(false);
      }
    });
  };

  const fileHandlerOnclick = () => {
    if (rowsEmpty) {
      Notification("error", "error");
      setRowsStatBool(false);
      return false;
    }
    Notification("success", "Import is success");
    setRowsStatBool(true);
  };

  const fileUploadeOnclick = () => {
    const customerId = document.getElementById("warehouseId").value;
    const data = {
      CustomerId: customerId,
      ImportItemBindingViewModel: rowsStatValid,
    };
    console.log(JSON.stringify(data));
    if (!rowsEmpty) {
      //axios.post("api/item/import", { data: rowsStatValid });
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Form.Row>
            <div className="col-6">
              <label className="form-label" for="formGridPassword">
                Choose a Customer
              </label>
              <select
                className="form-control form-control-solid is-valid-select"
                name="warehouseId"
                id="warehouseId"
              >
                {customersList.map((item, index) => (
                  <option key={index} value={item.Id}>
                    {item.CompanyName}
                  </option>
                ))}
              </select>
              {customersList.length === 0 && <LinearProgress />}
            </div>

            <Form.Group as={Col} controlId="formGridPassword">
              <Form.Label>Import </Form.Label>
              <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                <Form.Control
                  type="file"
                  placeholder="Password"
                  onChange={fileHandler.bind(this)}
                />
              </Card>
            </Form.Group>
          </Form.Row>
          {rowsStatBool && (
            <Form.Row className="mt-5 mb-5 pb-5">
              <div className="col-2">
                <i className="fa fa-star-of-life text-danger icon-xl mr-5" />{" "}
                Field required
              </div>
              <div className="col-2">
                <i className="fa fa-check text-success icon-xl mr-5" /> import
                row success
              </div>
              <div className="col-2">
                <i className="fa fa-times-circle text-danger icon-xl mr-5" />{" "}
                import row failed
              </div>
              <Paper className={classes.paper}>
                <Table className={classes.table} size="small">
                  <TableHead className="bg-dark">
                    <TableRow className={classes.row}>
                      <TableCell className="text-white">is Valid</TableCell>
                      <TableCell className="text-white">
                        SKU <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Description <IconFont />
                      </TableCell>
                      <TableCell className="text-white">Description2</TableCell>
                      <TableCell className="text-white">Min</TableCell>
                      <TableCell className="text-white">Max</TableCell>
                      <TableCell className="text-white">Cycle Count</TableCell>
                      <TableCell className="text-white">ReorderQty</TableCell>
                      <TableCell className="text-white">
                        Inventory Method
                      </TableCell>
                      <TableCell className="text-white">Temperature</TableCell>
                      <TableCell className="text-white">Cost</TableCell>
                      <TableCell className="text-white">UPC</TableCell>
                      <TableCell className="text-white">
                        Track Lot <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Track Serial <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Track ExpDate <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Primary Unit of Measure <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Packaging Unit <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Packing UOM Qty <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Length <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Width <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Height <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Weight <IconFont />
                      </TableCell>
                      <TableCell className="text-white">Qualifiers</TableCell>
                      <TableCell className="text-white">
                        Storage Setup
                      </TableCell>
                      <TableCell className="text-white">
                        Variable Setup
                      </TableCell>
                      <TableCell className="text-white">NMFC#</TableCell>
                      <TableCell className="text-white">
                        Lot Number Required <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Serial Number Required <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Serial Number (must be unique) <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Exp Date Req <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Enable Cost <IconFont />
                      </TableCell>
                      <TableCell className="text-white">
                        Cost Required <IconFont />
                      </TableCell>
                      <TableCell className="text-white">IsHazMat</TableCell>
                      <TableCell className="text-white">HazMatID</TableCell>
                      <TableCell className="text-white">
                        HazMatShippingName
                      </TableCell>
                      <TableCell className="text-white">
                        HazMatHazardClass
                      </TableCell>
                      <TableCell className="text-white">
                        HazMatPackingGroup
                      </TableCell>
                      <TableCell className="text-white">
                        HazMatFlashPoint
                      </TableCell>
                      <TableCell className="text-white">
                        HazMatLabelCode
                      </TableCell>
                      <TableCell className="text-white">HazMatFlag</TableCell>
                      <TableCell className="text-white">Image URL</TableCell>
                      <TableCell className="text-white">
                        StorageCountScriptTemplateID
                      </TableCell>
                      <TableCell className="text-white">StorageRates</TableCell>
                      <TableCell className="text-white">
                        OutboundMobileSerializationBehavior
                      </TableCell>
                      <TableCell className="text-white">Price</TableCell>
                      <TableCell className="text-white">TotalQty</TableCell>
                      <TableCell className="text-white">UnitType</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowsStat.map((row) => (
                      <TableRow className={classes.rowBody} key={row.name}>
                        <TableCell>
                          {row.empty == 1 && (
                            <i className="fa fa-check text-success icon-xxl"></i>
                          )}
                          {row.empty == 0 && (
                            <i className="fa fa-times-circle text-danger icon-xxl"></i>
                          )}
                        </TableCell>
                        <TableCell
                          className={classes.rowBody}
                          component="th"
                          scope="row"
                        >
                          {row.SKU}
                        </TableCell>
                        <TableCell>{row.Description}</TableCell>
                        <TableCell>{row.Description2}</TableCell>
                        <TableCell>{row.Min}</TableCell>
                        <TableCell>{row.Max}</TableCell>
                        <TableCell>{row.CycleCount}</TableCell>
                        <TableCell>{row.ReorderQty}</TableCell>
                        <TableCell>{row.InventoryMethod}</TableCell>
                        <TableCell>{row.Temperature}</TableCell>
                        <TableCell>{row.Cost}</TableCell>
                        <TableCell>{row.UPC}</TableCell>
                        <TableCell>{row.TrackLot}</TableCell>
                        <TableCell>{row.TrackSerial}</TableCell>
                        <TableCell>{row.TrackExpDate}</TableCell>
                        <TableCell>{row.PrimaryInventoryUnitOfMeasureId}</TableCell>
                        <TableCell>{row.PackagingUnitOfMeasureId}</TableCell>
                        <TableCell>{row.PackingUOMQty}</TableCell>
                        <TableCell>{row.Length}</TableCell>
                        <TableCell>{row.Width}</TableCell>
                        <TableCell>{row.Height}</TableCell>
                        <TableCell>{row.Weight}</TableCell>
                        <TableCell>{row.Qualifiers}</TableCell>
                        <TableCell>{row.StorageSetup}</TableCell>
                        <TableCell>{row.VariableSetup}</TableCell>
                        <TableCell>{row.NMFC}</TableCell>
                        <TableCell>{row.LotNumberRequired}</TableCell>
                        <TableCell>{row.SerialNumberRequired}</TableCell>
                        <TableCell>{row.SerialNumber}</TableCell>
                        <TableCell>{row.ExpDateReqRequired}</TableCell>
                        <TableCell>{row.EnableCost}</TableCell>
                        <TableCell>{row.CostRequired}</TableCell>
                        <TableCell>{row.HazardousMaterial}</TableCell>
                        <TableCell>{row.HazMatID}</TableCell>
                        <TableCell>{row.HazMatShippingName}</TableCell>
                        <TableCell>{row.HazMatHazardClass}</TableCell>
                        <TableCell>{row.HazMatPackingGroup}</TableCell>
                        <TableCell>{row.HazMatFlashPoint}</TableCell>
                        <TableCell>{row.HazMatLabelCode}</TableCell>
                        <TableCell>{row.HazMatFlag}</TableCell>
                        <TableCell>{row.ImageURL}</TableCell>
                        <TableCell>
                          {row.StorageCountScriptTemplateID}
                        </TableCell>
                        <TableCell>{row.StorageRates}</TableCell>
                        <TableCell>
                          {row.OutboundMobileSerializationBehavior}
                        </TableCell>
                        <TableCell>{row.Price}</TableCell>
                        <TableCell>{row.TotalQty}</TableCell>
                        <TableCell>{row.UnitType}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Form.Row>
          )}

          <Form.Row>
            <div className="col-lg-4">
              <FormLabel component="legend">
                Dimensions and weight in the input file are{" "}
              </FormLabel>
              <RadioGroup aria-label="position" name="position" row>
                <FormControlLabel
                  value="US/Imperial"
                  control={<Radio color="primary" />}
                  label="US/Imperial"
                  labelPlacement="US/Imperial"
                />
                <FormControlLabel
                  value="Metric"
                  control={<Radio color="primary" />}
                  label="Metric"
                  labelPlacement="Metric"
                />
              </RadioGroup>
            </div>
          </Form.Row>

          <Form.Row>
            <Alert variant="info " className="w-50">
              Use the 'Browse...' button to select a item import file
              <p></p>
              Then press the 'Import Items' button to read the file.
            </Alert>
            <Alert className="w-50"></Alert>
            <Button
              as="input"
              type="submit"
              value="Import Items"
              onClick={() => {
                fileHandlerOnclick();
              }}
            />
            <Button
              as="input"
              type="submit"
              value="Create Items"
              className="ml-5"
              onClick={() => {
                fileUploadeOnclick();
              }}
            />
          </Form.Row>
        </Form>
      </Card.Body>
    </Card>
  );
}
