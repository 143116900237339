import React, { useEffect, useState } from "react";
import Item from "./Item";
import Draggable from "react-draggable";
import { Spinner } from "react-bootstrap";
import { Notification } from "../../../../../modules/Notification";
import SelectPagination from "../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import { ScrollingMenuReceipt } from "./ScrollingMenuReceipt";
import low from "lowdb";
import { default as JsNative } from "jquery";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  Shipment,
  ReloadPallets,
  setSearch,
  Search,
  OrdersIds = [],
  Container,
  setPalletsFun,
  LinePath,
  Locations,
  setLoading,
  setContainersSelect,
  ContainersSelect,
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);
  const [CheckboxListIds, setCheckboxListIds] = useState([]);
  const [Search2, setSearch2] = useState("");

  useEffect(() => {
    setContainersSelect(Pallets);
  }, [Pallets]);

  useEffect(() => {
    GetContainers(ContainersSelect, Pallets, GetPalletsFunction);
  }, [ContainersSelect]);

  async function GetPalletsFunction(id) {
    setLoading(true);

    var el1 = document.querySelector(
      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
    );
    var el2 = el1?.parentElement?.getAttribute("id");

    let pallets = await GetItems(id, Shipment);
    if (pallets) {
      pallets.progress = ProgressBar2(pallets, pallets?.leftQuantity);
      var a = Pallets.findIndex((x) => x?.Id === id);

      (pallets?.LineItems ?? []).forEach((x) => {
        x.search =
          x?.Id +
          "|" +
          x?.ReceiptId +
          "|" +
          x?.PurchaseOrder +
          "|" +
          x?.LeftQuantity +
          "|" +
          x?.Sku;
      });

      if (a > -1) {
        Pallets[a] = pallets;
        setPallets([]);
        setTimeout(() => {
          setPallets(Pallets);
        }, 1);
      } else {
        setPallets((x) => [...x, pallets]);
      }
    }

    setTimeout(() => {
      setShow(true);
      var el3 = document.getElementById(el2);
      if (el2 && el3) el3.click();
      setLoading(false);
      return true;
    }, 1000);
  }

  JsNative(document).scroll(function() {
    var element = document.getElementById("TableComponent");
    var rect = element?.getBoundingClientRect();
    if (rect && 150 > rect.bottom) {
      JsNative("#TablePalletFixed").fadeIn();
    } else {
      JsNative("#TablePalletFixed").fadeOut();
    }
  });

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) GetPalletsFunction(DeletRowId);
  }, [DeletRowId]);

  var tableElement, offset;
  function GetTale() {
    tableElement = JsNative(".TableComponent");
    offset = tableElement.offset();
  }

  function handleDrag(e) {
    GetTale();
    var pageY = e.pageY;
    if (e.changedTouches?.length > 0) pageY = e.changedTouches[0].pageY;

    const style = "background: #E5EAEE !important";
    if (offset.top < pageY && pageY < offset.top + tableElement.height()) {
      tableElement.find("th").attr("style", style);
    } else {
      tableElement.find("th").removeAttr("style");
    }
  }

  function handleStop(e) {
    GetTale();
    var pageY = e.pageY;
    if (e.changedTouches?.length > 0) pageY = e.changedTouches[0].pageY;

    if (offset.top < pageY && pageY < offset.top + tableElement.height()) {
      PostPallets(CheckboxListIds, Shipment);
      tableElement.find("th").removeAttr("style");
    }
  }

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }

    setCheckboxListIds([...array]);
  }

  async function PostPallets(ItemsId, PalletId) {
    // return await fetch(`${window.$apiurl}/transload/receipt/shipitems`, {
    //   method: "post",
    //   withCredentials: true,
    //   headers: {
    //     Accept: "application/json, text/plain, */*",
    //     Authorization: `bearer ${db.read().getState().Token}`,
    //     "X-FP-API-KEY": "iphone",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     Items: ItemsId,
    //     ShipmentId: PalletId,
    //   }),
    // }).then(async (response) => {
    //   if (response.status === 200) {
    Notification("success", "success : " + ItemsId.join(", "));
    ItemsId.forEach((x) => {
      var a = Pallets.findIndex((y) => x === y.Id);
      SetTableShipment(Pallets[a], Pallets[a]?.LeftQuantity);
    });
    setCheckboxListIds([]);
    ReloadPallets();
    //   } else {
    //     var msg = response.status !== 200 ? await response.json() : "";
    //     Notification("error", msg?.toString());
    //   }
    // });
  }

  function POChaekced(id, bool) {
    var list = [];
    Pallets.forEach((x) => {
      if (+x?.PurchaseOrder === +id && +x?.Id !== 0) list.push(+x?.Id);
    });

    if (bool) {
      setCheckboxListIds((x) => [...x, ...list]);
    } else {
      var b = CheckboxListIds;
      list.forEach((x) => {
        b = b.filter((y) => +y !== +x);
      });
      setCheckboxListIds(b);
    }
  }

  function ProgressBar(id, Quantity) {
    setPallets((x) => {
      var cont = x.find((y) => y.Id === id);
      var p = (cont.Cube / cont.Cartons) * Quantity;
      cont.progress -= percentage(p, cont.Cube);
      if (cont.progress < 0) cont.progress = 0;
      return x;
    });
    var btn = document.querySelector(".menu-item-wrapper.active .OrdersClass");
    if (btn) btn.click();
  }

  function ProgressBar2(cont, Quantity) {
    var p = (cont.Cube / cont.Cartons) * Quantity;
    cont.progress -= percentage(p, cont.Cube);
    if (cont.progress < 0) cont.progress = 0;
    return cont.progress;
  }

  function percentage(partialValue, totalValue) {
    var i = (100 * partialValue) / totalValue;
    if (Object.is(i, NaN)) return 100;
    return (100 * partialValue) / totalValue;
  }

  function ClickBtn(value) {
    setShow(false);
    var el1 = document.querySelector(
      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
    );
    var el2 = el1?.parentElement?.getAttribute("id");
    setSearch2(value);
    setTimeout(() => {
      var el3 = document.getElementById(el2);
      if (el2 && el3) el3.click();
      setShow(true);
    }, 1000);
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-4 col-lg-4 col-md-4 ml-auto my-5" />
        <div className="col-xl-4 col-lg-4 col-md-4 ml-auto my-5">
          <div className={`input-group mb-3`}>
            {Container?.length > 0 ? (
              <SelectPagination
                isSearchable={true}
                onCreateOption={undefined}
                placeholder="Find By Container"
                createOptionPosition="first"
                isClearable
                onChange={(newValue) => {
                  var id = newValue?.value;
                  if (id) GetPalletsFunction(id);
                }}
                options={Container.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.EquipmentRef,
                  };
                })}
                name="PickupCarrierId"
                id="PickupCarrierId"
                className="w-100 mr-2"
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Find By Container"
              />
            )}
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-4 ml-auto my-5">
          <input
            type="text"
            placeholder="Filter by all fields"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                ClickBtn(e.target.value);
              }
            }}
            onBlur={(e) => {
              ClickBtn(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className={"col-12 my-2 "}>
          {CheckboxListIds?.length > 0 && (
            <Draggable
              axis="y"
              handle=".handle"
              position={{ x: 0, y: 0 }}
              grid={[1, 1]}
              scale={1}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <div className="handle">
                <button
                  type="button"
                  className={`btn btn-light-danger bg-light-danger text-danger w-100 p-0`}
                  style={{ height: "41px" }}
                >
                  {htmlP(CheckboxListIds, "Items Selected")}
                </button>
              </div>
            </Draggable>
          )}
        </div>
        <div className={"col-12 my-2  " + (!Show ? "d-none" : "")}>
          {Pallets?.length > 0 ? (
            <>
              <PalletsFun
                Search={Search2}
                item={Pallets}
                Shipment={Shipment}
                SetTableShipment={SetTableShipment}
                CheckboxList={CheckboxList}
                CheckboxListIds={CheckboxListIds}
                POChaekced={POChaekced}
                ProgressBar={ProgressBar}
                setPalletsFun={setPalletsFun}
                LinePath={LinePath}
                Locations={Locations}
                setLoading={setLoading}
                Search2={Search2}
                setContainersSelect={setContainersSelect}
                ContainersSelect={ContainersSelect}
              />
            </>
          ) : (
            <Nodatafound title="Please Select Container" />
          )}
        </div>

        <div className={"col-12 my-2  " + (Show ? "d-none" : "")}>
          {!Show && <Nodatafound title="Please Select Container" />}
        </div>
      </div>
    </>
  );
}

function PalletsFun({
  Search,
  item,
  Shipment,
  SetTableShipment,
  CheckboxList,
  CheckboxListIds,
  POChaekced,
  ProgressBar,
  setPalletsFun,
  LinePath,
  Locations,
  setLoading,
  Search2,
}) {
  const [selectedId, setselectedId] = useState(false);
  const [Receipts, setReceipts] = useState([]);

  useEffect(() => {
    setReceipts([]);
    setTimeout(() => {
      setReceipts(item);
    }, 1);
  }, [item, Search]);

  return (
    <>
      <ScrollingMenuReceipt
        list={Receipts}
        setselectedId={setselectedId}
        Color="primary"
        Title="Containers"
        selectedId={selectedId}
      />

      <div className="row">
        {Receipts?.filter((x) => x?.Id === +selectedId).length > 0 && (
          <>
            <div className="col-xl-6 col-lg-6 col-md-12 my-2">
              <div className="btn btn-light-primary bg-primary text-white w-100 py-3 mt-3">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    {htmlP2("PO#")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Sku")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Qty")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Ref N.")}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    {htmlP2("DC Location")}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 my-2">
              <div className="btn btn-light-primary bg-primary text-white w-100 py-3 mt-3">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    {htmlP2("PO#")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Sku")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Qty")}
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2">
                    {htmlP2("Ref N.")}
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3">
                    {htmlP2("DC Location")}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {Receipts?.filter((x) => x?.Id === +selectedId).map((x, i) => (
          <>
            {x?.LineItems?.filter(
              (x) => x?.LeftQuantity !== 0 && x?.search?.includes(Search2)
            )?.map((x1, i1) => (
              <Item
                item={x1}
                key={i1}
                SetTableShipment={SetTableShipment}
                ShipmentID={Shipment}
                CheckboxList={CheckboxList}
                CheckboxListIds={CheckboxListIds}
                POChaekced={POChaekced}
                ProgressBar={ProgressBar}
                ParentId={x?.Id}
                setPalletsFun={setPalletsFun}
                LinePath={LinePath}
                Locations={Locations}
                setLoading={setLoading}
              />
            ))}

            {x?.LineItems?.filter(
              (x) => x?.LeftQuantity !== 0 && x?.search?.includes(Search2)
            )?.length === 0 && (
              <Nodatafound title="There are no More POs left in the container for this DC" />
            )}
          </>
        ))}
      </div>
    </>
  );
}

async function GetItems(id, palletId) {
  return await fetch(
    `${window.$apiurl}/Shipments/GetShipmentReceipts?id=${id}&palletId=${palletId}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

function Nodatafound({ title = "" }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 1000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              {title}
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

function htmlP(list, text) {
  return (
    <>
      {text}
      {list.map((x) => (
        <p type="button" className={`btn btn-danger px-1 py-0 mt-3 ml-1 mr-1`}>
          {x}
        </p>
      ))}
    </>
  );
}
function htmlP2(text) {
  return (
    <>
      <b className={` px-1 py-0 mt-3 w-100`} style={{ fontSize: "11px" }}>
        {text}
      </b>
    </>
  );
}

async function GetContainers(ContainersSelect, Pallets, GetPalletsFunction) {
  if (ContainersSelect?.length > 0 && Pallets?.length === 0) {
    for (const x of ContainersSelect) {
      const contents = await GetPalletsFunction(x?.Id);
    }
  }
}
