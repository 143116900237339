import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import { Notification } from "../../../../modules/Notification";
import { ScrollingMenuReceipt } from "./ScrollingMenuReceipt";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({
  iShipmentsID = [],
  IsClosed,
  setLoading,
  setShipmentsIds,
}) {
  const [Table, setTable] = useState([]);
  const [DeletRowId, setDeletRowId] = useState({ id: 0, number: 0 });
  const [ShipmentsID, setShipmentsID] = useState(iShipmentsID);
  const [selectedShip, setselectedShip] = useState(false);
  const [Search, setSearch] = useState("");
  const [InTable, setInTable] = useState({ id: 0, number: 0 });

  useEffect(() => {
    setShipmentsID(iShipmentsID);
  }, [iShipmentsID]);

  function SetTableShipment(row, Quantity) {
    setInTable((x) => {
      return { id: row?.ShipID, number: x.number + 1 };
    });
  }

  async function DeleteShipmentRow(id, IdShip) {
    return await RemovePallet(id, IdShip).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        setTimeout(() => {
          setDeletRowId((x) => {
            return { id: IdShip, number: x.number + 1 };
          });
        }, 1);
        return true;
      } else {
        Notification("error", Message?.toString());
        return false;
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          {ShipmentsID.length > 0 && (
            <IndexPalletsList
              SetTableShipment={SetTableShipment}
              DeletRowId={DeletRowId}
              iShipmentsID={iShipmentsID}
              setLoading={setLoading}
              setShipmentsIds={setShipmentsIds}
            />
          )}
          {IsClosed ? <Nodatafound /> : <></>}
        </div>
        <div className="col-6">
          <ShipmentFun
            Table={Table}
            ShipmentsID={ShipmentsID}
            setselectedShip={setselectedShip}
            DeleteShipmentRow={DeleteShipmentRow}
            InTable={InTable}
            setDeletRowId={setDeletRowId}
            setLoading={setLoading}
          />
        </div>
      </div>
    </>
  );
}

async function RemovePallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/deletepalletfromshipment`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ShipmentId: ShipmentID,
      PalletId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Shipment Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}

function ShipmentFun({
  Table = [],
  ShipmentsID,
  setselectedShip,
  DeleteShipmentRow,
  InTable,
  setDeletRowId,
  setLoading,
}) {
  const [selectedId, setselectedId] = useState(false);

  useEffect(() => {
    setselectedShip(selectedId);
  }, [selectedId]);

  return (
    <div className="sticky-po">
      <div className="row">
        <div className="col-12 alert alert-info" role="alert">
          <b>Shipments</b>
        </div>

        {ShipmentsID.map((x, i) => (
          <TableComponent
            key={i}
            list={Table}
            ShipmentID={x}
            hidden={+selectedId !== +x}
            selectedShip={+selectedId}
            DeleteShipmentRow={DeleteShipmentRow}
            InTable={InTable}
            setDeletRowId={setDeletRowId}
            setLoading={setLoading}
          />
        ))}
      </div>
    </div>
  );
}
