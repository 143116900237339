// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import CreatableSelect from "react-select/creatable";
import { LinearProgress } from "@material-ui/core";
// Validation schema
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";

const adapter = new LocalStorage("db");
const db = low(adapter);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: "2rem",
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {

  const [DepartementsTypeList, setDepartementsTypeList] = useState([]);
  const [DepartementsType, setDepartementsType] = useState({
    value: null,
    label: "Select",
  });

  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  useEffect(() => { 
    if (customer.DepartementId) {
      var a = customer.DepartementId;
      var b = DepartementsTypeList?.find((x) => x.Id === a);
      setDepartementsType({
        value: b?.Id,
        label: b?.Name,
      });
    }
  }, [customer]);

  async function handleGetViewModel() {
    return await fetch(window.$apiurl + "/Departements/GetDepartements", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }

  useEffect(() => {
    handleGetViewModel().then((res) => {
      if (res) {
        setDepartementsTypeList(res);
      }
    });
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-5">
                    <div className="code-description mb-4">
                      <Field
                        name="Name"
                        label="Name"
                        component={Input}
                        placeholder="Name"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="field-description">
                      <Field
                        name="Description"
                        label="Description"
                        component={Input}
                        placeholder="Description"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="d-flex align-items-center mt-8">
                      <span className="bg-success align-self-stretch"></span>
                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                        <input type="checkbox" name="IsActive" checked={values.IsActive} onChange={(e) => {
                          setFieldValue("IsActive", e.target.checked);
                        }} />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">IsActive</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-5">
                    <label>Departement</label>
                    <CreatableSelect
                      isClearable
                      required={true}
                      value={DepartementsType}
                      onChange={(newValue) => {
                        try {
                          if (
                            newValue === null ||
                            newValue?.value === null
                          ) {
                            setFieldValue("DepartementId", null);
                            setDepartementsType({
                              value: null,
                              label: "All",
                            });
                          } else {
                            setFieldValue(
                              "DepartementId",
                              newValue?.value
                            );
                            var a = newValue?.value;
                            var b = DepartementsTypeList?.find(
                              (x) => x.Id === a
                            );
                            setDepartementsType({
                              value: b?.Id,
                              label: b?.Name,
                            });
                          }
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                      options={DepartementsTypeList?.map((x) => {
                        return { value: x?.Id, label: x?.Name };
                      })}
                      name="DepartementId"
                      id="DepartementId"
                      className="w-100 mr-2"
                    />
                    {DepartementsTypeList.length === 0 && (
                      <LinearProgress />
                    )}
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
