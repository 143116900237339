/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import moment from "moment";
import React, { useEffect } from "react";
const colors = ["#187DE4", "#1BC5BD", "#FFA800"];
export function VolumeTrendsTab({ className, VolumeList, loadingDataVolume }) {
  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_9_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(VolumeList);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [VolumeList, loadingDataVolume]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Volume Trends
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-lg-12 text-center">
            {loadingDataVolume === true ? (
              <h4>Loading ...</h4>
            ) : VolumeList.length === 0 ? (
              <h4>There is no data at the moment</h4>
            ) : (
              <div
                id="kt_performance_widget_9_chart"
                className="card-rounded-bottom"
                style={{ height: "350px" }}
              ></div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(VolumeList) {
  const options = {
    series: [
      {
        name: "Intermodal",
        data:
          VolumeList?.map((a) => (a.Intermodal === null ? 0 : a.Intermodal)) ||
          [],
      },
      {
        name: "Ltl",
        data: VolumeList?.map((a) => (a.Ltl === null ? 0 : a.Ltl)) || [],
      },
      {
        name: "OTR",
        data: VolumeList?.map((a) => (a.OTR === null ? 0 : a.OTR)) || [],
      },
    ],
    colors: colors,
    chart: {
      height: 350,
      type: "bar",
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories:
        VolumeList.map((a) =>
          moment(
            (a.Month.length === 1 ? "0" + a.Month : a.Month) + "-01-" + a.Year
          ).format("MM-DD-YYYY")
        ) || [],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 5,
        columnWidth: "35%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
  };
  return options;
}
