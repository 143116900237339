/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../layout";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
export function Crosspartner({ className }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Cross Partner
          </span>
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="col-lg-12">
          <div className="row border-bottom">
            <div className="col-lg-7 mb-10">
              <p className="font-size-h4 font-weight-bolder">Partner Transaction</p>
              <input
                type="text"
                className="form-control"
                name="searchText"
                id="searchGlobalText"
                placeholder="Partner Name"
                label="Date"
              />
            </div>
            <div className="col-md-1 mt-11 mb-10">
              <button
                id="submitSearchDashBoard"
                className="btn btn-primary font-weight-bolder font-size-sm"
                onClick
              >
                View
              </button>
            </div>
          </div>
          <div className="row mt-10">
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Partner Name
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-dark-50 font-weight-bolder font-size-h4 mt-6">
                Meijer
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Total Transactions
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-primary font-size-h4 mt-6">
                20
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Inbound Transactions
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-info font-size-h4 mt-6">
                11
              </p>
              {/*end::Desc*/}
            </div>
            <div className="flex-grow-1 pb-5 col-md-3 text-center">
              <a
                href="#"
                className="text-dark font-weight-normal text-hover-primary font-size-md"
              >
                Outbound Transactions
              </a>
              {/*end::Link*/}
              {/*begin::Desc*/}
              <p className="text-primary-50 font-weight-bolder text-success font-size-h4 mt-6">
                5
              </p>
              {/*end::Desc*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
