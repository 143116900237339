export default [
    {
        id: 1,
        freeze :"",
        warehouse :"Mitco, Ltd",
        lastFrozen :"2014/03/30",
        oldestOpenReceiver :"2020/05/06",
        oldestOpenOrder :"2020/01/28",
        oldestOpenAssembly :"2016/12/27",
    },
    {
        id: 2,
        freeze :"",
        warehouse :"Mitco, Ltd",
        lastFrozen :"2014/03/30",
        oldestOpenReceiver :"2020/05/06",
        oldestOpenOrder :"2020/01/28",
        oldestOpenAssembly :"2016/12/27",
    },
  ];
  