import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { Fragment, useRef } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { currencyFormat } from "../../../_metronic/_helpers/ATHelpers";
import { Notification } from "../../modules/Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);
function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function calculateTotalItems(list, column) {
  try {
    let result = 0;
    if (list !== null && list !== undefined && list?.length > 0) {
      list.forEach((item) => {
        result += item[column];
      });
    }
    return currencyFormat(result);
  } catch (err) {
    console.log(err);
  }
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: []
    };
    this.state = { data: obj, loader: true };
  }
  async componentDidMount() {
    await fetch(
      window.$apiurl + "/ShipmentBilling/Outbound/reportMultiPage/" + this.props.id,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", "No data found !");
        }
        const json = await response.json();
        this.setState({ data: json, loader: false });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const formData = this.state.data;
    const loader = this.state.loader;
    return (
      <>
        <Helmet>
          <title>eMPower | Rate Confirmation</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-5 bl-form border-0">
            <div className="card-body p-0">
              {loader ? (
                <div className="row mb-md-10 mt-md-10">
                  <div className="col" style={{ textAlignLast: "center" }}>
                    <h2 className="text-muted">Loading...</h2>
                  </div>
                </div>
              ) : (
                <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                  <div className="col-md-12">
                    <div className="row pb-10">
                      <div className="col-md-8 px-0 mr-auto">
                        <table className="w-100">
                          <tbody className="border-0">
                            <tr>
                              <td className="border-0">
                                <b className="h6">{formData.Title}</b>
                                <br></br>
                                {formData.BillTo?.Name}
                                <br></br>
                                {formData.BillTo?.Address1 === undefined
                                  ? " "
                                  : formData.BillTo?.Address1}
                                {(formData.BillTo?.Address2 === undefined) |
                                (formData.BillTo?.Address2 === null)
                                  ? " "
                                  : formData.BillTo?.Address2}
                                <br></br>
                                {formData.BillTo?.City === undefined
                                  ? " "
                                  : formData.BillTo?.City}
                                ,{" "}
                                {formData.BillTo?.State === undefined
                                  ? " "
                                  : formData.BillTo?.State}{" "}
                                {formData.BillTo?.ZipCode === undefined
                                  ? " "
                                  : formData.BillTo?.ZipCode}
                                <br></br>
                                {formData.BillTo?.Phone === undefined
                                  ? " "
                                  : formData.BillTo?.Phone}
                                <br></br>
                                {formData.BillTo?.Reference === undefined
                                  ? " "
                                  : formData.BillTo?.Reference}
                                <br></br>
                              </td>
                              <td className="border-0 text-left">
                                <Link to="#" class="mb-5 max-w-100px">
                                  <span class="svg-icon svg-icon-full">
                                    <img
                                      src="/media/logos/Mitco.svg"
                                      className="w-75"
                                      alt="mitco logo"
                                    />
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* Table Infos */}
                    <div className="row pb-3">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="justify-content-center">
                            <tr>
                              <th className="text-center">Carrier Name</th>
                              <th className="text-center">PickUp Date </th>
                              <th className="text-center">Delivery Date</th>
                              <th className="text-center">Shipment Id</th>
                              <th className="text-center">Equipment Number</th>
                              <th className="text-center">Shipper Ref</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.CarrierName}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.ReadyDate
                                  ? moment(formData?.ReadyDate).format(
                                      "MM-DD-YYYY"
                                    )
                                  : ""}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.DateNeeded
                                  ? moment(formData?.DateNeeded).format(
                                      "MM-DD-YYYY"
                                    )
                                  : ""}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.LoadNumber}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.Equipment}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData?.ShipperRef}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* Table Mitco - Service ... */}
                    <div className="row pb-3">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead className="justify-content-center">
                            <tr>
                              <th className="text-center">Mitco ID #</th>
                              <th className="text-center">Service</th>
                              <th className="text-center">Mode</th>
                              <th className="text-center">Service Level</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="font-weight-boldest align-text-top text-center">
                                {this.props.id}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData.Service}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData.Mode}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {formData.ServiceLevel}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* Shipper Information */}
                    <div className="row pb-md-5 pt-md-2 itemPick">
                      <div className="col-md-12">
                        <div className="row" style={{ placeContent: "center" }}>
                          <table style={{ width: "67%" }}>
                            <thead>
                              <tr>
                                <th colspan="10" className="text-center">
                                  Shipper Information - Stop 1
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="second-bg2">
                                <td className="" colspan="4">
                                  Name:
                                </td>
                                <td className="">
                                  {formData?.Shipper?.ShipperAddress?.Name}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Address:</td>
                                <td>
                                  {IsValidAddress(
                                    formData?.Shipper?.ShipperAddress?.Address1,
                                    true
                                  ) +
                                    IsValidAddress(
                                      formData?.Shipper?.ShipperAddress?.City?.replace(
                                        / /g,
                                        ""
                                      ),
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.Shipper?.ShipperAddress?.State,
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.Shipper?.ShipperAddress
                                        ?.ZipCode,
                                      false
                                    )}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Contact Name:</td>
                                <td>
                                  {" "}
                                  {formData?.Shipper?.Contact
                                    ? formData?.Shipper?.Contact
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Contact Phone: </td>
                                <td>
                                  {" "}
                                  {formData?.Shipper?.Phone &&
                                  formData?.Shipper?.Phone !== "NULL"
                                    ? formData?.Shipper?.Phone
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Pickup Appointment :</td>
                                <td>
                                  {formData?.Shipper?.Date
                                    ? moment(formData?.Shipper?.Date).format(
                                        "MM-DD-YYYY hh:mm A"
                                      )
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Pickup #:</td>
                                <td>
                                  {formData?.Shipper?.PickupNumber
                                    ? formData?.Shipper?.PickupNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Shipper Notes:</td>
                                <td>
                                  {formData?.Shipper?.Note
                                    ? formData?.Shipper?.Note
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/*  Stops */}
                    {formData !== null &&
                    formData !== undefined &&
                    formData?.Stops?.length > 0
                      ? formData?.Stops.map((item, index) => (
                          <Fragment>
                            {item.Type === "Pick" ? (
                              <div className="row pb-md-5 pt-md-2 itemPick">
                                <div className="col-md-12">
                                  <div
                                    className="row"
                                    style={{ placeContent: "center" }}
                                  >
                                    <table style={{ width: "67%" }}>
                                      <thead>
                                        <tr>
                                          <th
                                            colspan="10"
                                            className="text-center"
                                          >
                                            Shipper Information – Stop{" "}
                                            {item?.Position + 1}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="second-bg2">
                                          <td className="" colspan="4">
                                            Name:
                                          </td>
                                          <td className="">{item.Name}</td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Address:</td>
                                          <td>
                                            {IsValidAddress(
                                              item?.Street,
                                              true
                                            ) +
                                              IsValidAddress(
                                                item?.City?.replace(/ /g, ""),
                                                false
                                              ) +
                                              IsValidAddress(
                                                item?.State,
                                                false
                                              ) +
                                              IsValidAddress(
                                                item?.ZipCode,
                                                false
                                              )}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Contact Name:</td>
                                          <td>
                                            {" "}
                                            {item?.Contact
                                              ? item?.Contact
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Contact Phone: </td>
                                          <td>
                                            {" "}
                                            {item?.Phone &&
                                            item?.Phone !== "NULL"
                                              ? item?.Phone
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">
                                            Pickup Appointment :
                                          </td>
                                          <td>
                                            {item?.Date
                                              ? moment(item?.Date).format(
                                                  "MM-DD-YYYY hh:mm A"
                                                )
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Pickup #:</td>
                                          <td>
                                            {item?.ReferenceNumber
                                              ? item?.ReferenceNumber
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Shipper Notes:</td>
                                          <td>
                                            {item?.Note ? item?.Note : "-"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="row pb-md-5 pt-md-2 itemDrop">
                                <div className="col-md-12">
                                  <div
                                    className="row"
                                    style={{ placeContent: "center" }}
                                  >
                                    <table style={{ width: "67%" }}>
                                      <thead>
                                        <tr>
                                          <th
                                            colspan="12"
                                            className="text-center"
                                          >
                                            Receiver Information - Stop{" "}
                                            {item?.Position + 1}
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr className="second-bg2">
                                          <td className="" colspan="4">
                                            Name:
                                          </td>
                                          <td className="">{item?.Name}</td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Address:</td>
                                          <td>
                                            {IsValidAddress(
                                              item?.Street,
                                              true
                                            ) +
                                              IsValidAddress(
                                                item?.City?.replace(/ /g, ""),
                                                false
                                              ) +
                                              IsValidAddress(
                                                item?.State,
                                                false
                                              ) +
                                              IsValidAddress(
                                                item?.ZipCode,
                                                false
                                              )}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Contact Name:</td>
                                          <td>
                                            {" "}
                                            {item?.Contact
                                              ? item?.Contact
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Contact Phone: </td>
                                          <td>
                                            {" "}
                                            {item?.Phone &&
                                            item.Phone !== "NULL"
                                              ? item?.Phone
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">
                                            Delivery Appointment :
                                          </td>
                                          <td>
                                            {item?.Date
                                              ? moment(item?.Date).format(
                                                  "MM-DD-YYYY hh:mm A"
                                                )
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Delivery #:</td>
                                          <td>
                                            {item?.ReferenceNumber
                                              ? item?.ReferenceNumber
                                              : "-"}
                                          </td>
                                        </tr>
                                        <tr className="second-bg2">
                                          <td colspan="4">Delivery Notes:</td>
                                          <td>
                                            {item?.Note ? item?.Note : "-"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))
                      : ""}
                    {/* Delivery Information  */}
                    <div className="row pb-md-5 pt-md-2 itemDrop">
                      <div className="col-md-12">
                        <div className="row" style={{ placeContent: "center" }}>
                          <table style={{ width: "67%" }}>
                            <thead>
                              <tr>
                                <th colspan="12" className="text-center">
                                  Receiver Information - Stop{" "}
                                  {formData?.Stops?.length > 0
                                    ? formData?.Stops?.length + 2
                                    : 2}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="second-bg2">
                                <td className="" colspan="4">
                                  Name:
                                </td>
                                <td className="">
                                  {formData?.Consignee?.ShipperAddress?.Name}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Address:</td>
                                <td>
                                  {IsValidAddress(
                                    formData?.Consignee?.ShipperAddress
                                      ?.Address1,
                                    true
                                  ) +
                                    IsValidAddress(
                                      formData?.Consignee?.ShipperAddress?.City?.replace(
                                        / /g,
                                        ""
                                      ),
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.Consignee?.ShipperAddress
                                        ?.State,
                                      false
                                    ) +
                                    IsValidAddress(
                                      formData?.Consignee?.ShipperAddress
                                        ?.ZipCode,
                                      false
                                    )}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Contact Name:</td>
                                <td>
                                  {" "}
                                  {formData?.Consignee?.Contact
                                    ? formData?.Consignee?.Contact
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Contact Phone: </td>
                                <td>
                                  {" "}
                                  {formData?.Consignee?.Phone &&
                                  formData?.Consignee?.Phone !== "NULL"
                                    ? formData?.Consignee?.Phone
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Delivery Appointment :</td>
                                <td>
                                  {formData?.Consignee?.Date
                                    ? moment(formData?.Consignee?.Date).format(
                                        "MM-DD-YYYY hh:mm A"
                                      )
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Delivery #:</td>
                                <td>
                                  {formData?.Consignee?.DeliveryNumber
                                    ? formData?.Consignee?.DeliveryNumber
                                    : "-"}
                                </td>
                              </tr>
                              <tr className="second-bg2">
                                <td colspan="4">Delivery Notes:</td>
                                <td>
                                  {formData?.Consignee?.Note
                                    ? formData?.Consignee?.Note
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {/* Items */}
                    <div className="row pb-4">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th colspan="10" className="text-center">
                                Shipment Items
                              </th>
                            </tr>
                          </thead>
                          <thead className="justify-content-center">
                            <tr className="second-bg">
                              <th className="text-center">Commodity</th>
                              <th className="text-center">Handling Amount</th>
                              <th className="text-center">Packaging</th>
                              <th className="text-center">Pieces</th>
                              <th className="text-center">Weight</th>
                              <th className="text-center">Shipper Stop</th>
                              <th className="text-center">Receiver Stop</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData !== null &&
                            formData !== undefined &&
                            formData?.ShipmentItems?.length > 0
                              ? formData?.ShipmentItems.map((item, index) => (
                                  <tr key={index}>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item?.CommodityDescription}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {currencyFormat(item?.HandlingAmount)}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item?.PackageType}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {currencyFormat(item?.PackageQty)}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {currencyFormat(item?.Weight)}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item?.ShipperStopPosition
                                        ? item?.ShipperStopPosition + 1
                                        : 1}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item?.ReceiverStopPosition
                                        ? item?.ReceiverStopPosition + 1
                                        : formData?.Stops?.length > 0
                                        ? formData?.Stops?.length + 2
                                        : 2}
                                    </td>
                                  </tr>
                                ))
                              : ""}
                            <tr className="second-bg3">
                              <td className="font-weight-boldest align-text-top text-center">
                                Total :
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {calculateTotalItems(
                                  formData?.ShipmentItems,
                                  "HandlingAmount"
                                )}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                -
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {calculateTotalItems(
                                  formData?.ShipmentItems,
                                  "PackageQty"
                                )}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                {calculateTotalItems(
                                  formData?.ShipmentItems,
                                  "Weight"
                                )}
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                -
                              </td>
                              <td className="font-weight-boldest align-text-top text-center">
                                -
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Vendors */}
                    <div className="row pb-0">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th colspan="10" className="text-center">
                                Freight Charges
                              </th>
                            </tr>
                          </thead>
                          <thead className="justify-content-center">
                            <tr className="second-bg">
                              <th className="text-center">Charge Type</th>
                              <th className="text-center">Rate</th>
                              <th className="text-center">Quantity</th>
                              <th className="text-center">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formData.Vendors?.length > 0 &&
                              formData.Vendors?.map(
                                (item, index) =>
                                  item.Payables?.length > 0 &&
                                  item.Payables?.map((itemX, indexX) => (
                                    <tr key={indexX}>
                                      <td className="font-weight-boldest align-text-top text-center">
                                        {itemX?.Name ? itemX.Name : "-"}
                                      </td>
                                      <td className="font-weight-boldest align-text-top text-center">
                                        ${currencyFormat(itemX.Rate)}
                                      </td>
                                      <td className="font-weight-boldest align-text-top text-center">
                                        {itemX.Quantity}
                                      </td>
                                      <td className="font-weight-boldest align-text-top text-center">
                                        ${currencyFormat(itemX.Total)}
                                      </td>
                                    </tr>
                                  ))
                              )}
                            {formData.Vendors?.length > 0 &&
                              formData.Vendors?.map((item, index) => (
                                <tr key={index} className="second-bg3">
                                  <td
                                    className="font-weight-boldest align-text-top text-center"
                                    colspan="3"
                                  >
                                    Total :
                                  </td>
                                  <td
                                    className="font-weight-boldest align-text-top text-center"
                                    colspan="1"
                                  >
                                    ${currencyFormat(item.Total)}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody className="border-0">
                            <tr>
                              <td className="align-text-top border-0">
                                <small className="text-muted">
                                  This confirmation governs the movement of the
                                  above-referenced freight as of the specified
                                  and hereby amends, is incorporated by
                                  reference and becomes a part of the certain
                                  transportation contract by and between
                                  “Broker” and “Contract carrier”. Carrier
                                  Agrees to sign the confirmation and return it
                                  to the broker via email and carrier shall be
                                  conclusively presumed and compensatory that
                                  the freight would not have been tendered to
                                  Carrier at higher rates and that not shipments
                                  handled under such rates will subsequently be
                                  subject to a later claim for undercharges. IF
                                  AGREED SERVICES ARE FULFILLED, RATES ARE NOT
                                  NEGOTIABLE.Carrier MUST email
                                  tracing@mitcoglobal.com For any pickup and
                                  delivery appointments that are required.
                                  Failure to comply with appointments will
                                  result in a penalty of $150.00 Per
                                  Appointment.
                                </small>
                                <br></br>
                                <small className="text-muted">
                                  The undersigned accepts the referenced
                                  shipment on behalf of the carrier and
                                  acknowledge as correct the information
                                  contained herein, the carrier agrees to the
                                  terms of the Master agreement previously
                                  executed between our companies. Invoicing by
                                  the carrier and payment by Mitco Ltd.,
                                  constitutes acceptance of this agreement and
                                  creates a valid contract for carriage
                                  shipment.
                                </small>
                                <br></br>
                                <small className="text-muted">
                                  When loading, the driver must count and
                                  inspect his/her load. The Driver/Carrier is
                                  responsible for piece count and condition of
                                  load at time of delivery.{" "}
                                  <b className="text-dark">
                                    For payment of freight charges, we must
                                    receive original and signed Bill of lading
                                    and Proof of Delivery with a carrier invoice
                                  </b>
                                  . Payment will be made 30 days after all
                                  required paperwork is received at Mitco Ltd.,
                                  facilities. Carrier will send a POD for load
                                  to tracing@mitcoglobal.com within 48 hours of
                                  delivery or carrier will be assessed a $50
                                  late fee per day until received at that email.
                                  Carrier will send the invoice and POD to
                                  accounting@mitcoglobal.com. We are not
                                  responsible for Overweight. If Dimensions,
                                  Weight, Quantity or type of commodity are
                                  different than those consigned in our Load
                                  Confirmation Agreement, the carrier or Broker
                                  contracted MUST notify Mitco Ltd. Before
                                  picking up and request a WRITTEN
                                  AUTHORIZATION. Mitco Ltd. will not pay any
                                  extra charges without AUTHORIZATION, and will
                                  only approve changes to charges via revised
                                  rate confirmation. In and out times must be
                                  provided on POD for all pickup and deliveries.{" "}
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody className="border-0">
                            <tr>
                              <td className="align-text-top text-center border-0 font-weight-bold">
                                Phone: (253) 245-2102 | Fax: (253) 891-0601
                                <br></br>
                                Please sign and return via email to ensure
                                payment
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody className="border-0">
                            <tr>
                              <td className="align-text-top border-0 pb-5">
                                <b>Carrier Signature:</b>
                                ________________________
                              </td>
                              <td className="align-text-top border-0 text-right pb-5">
                                <b>Driver Name:</b>{" "}
                                <span>
                                  {formData.Vendors?.length === 0 ||
                                  formData.Vendors === undefined ||
                                  formData.Vendors[0].DriverName === "" ||
                                  formData.Vendors[0].DriverName === null
                                    ? "________________________"
                                    : formData.Vendors[0].DriverName}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td className="align-text-top border-0">
                                <b>Signature Date:</b>________________________
                              </td>
                              <td className="align-text-top border-0 text-right">
                                <b>Driver Phone#:</b>{" "}
                                <span>
                                  {formData.Vendors?.length === 0 ||
                                  formData.Vendors === undefined ||
                                  formData.Vendors[0].DriverPhone === "" ||
                                  formData.Vendors[0].DriverPhone === null
                                    ? " ________________________"
                                    : formData.Vendors[0].DriverPhone}
                                </span>
                              </td>
                            </tr>
                            <br />
                            <tr>
                              <td className="align-text-top border-0">
                                <b>MC#:</b> <span>{formData.MCNumber}</span>
                              </td>
                              <td className="align-text-top border-0 text-right">
                                <b>Truck Number:</b>{" "}
                                <span>
                                  {formData.Vendors?.length === 0 ||
                                  formData.Vendors === undefined ||
                                  formData.Vendors[0].TruckNumber === "" ||
                                  formData.Vendors[0].TruckNumber === null
                                    ? " ________________________"
                                    : formData.Vendors[0].TruckNumber}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <div className="col-md-12 px-0 ">
                        <table className="w-100">
                          <tbody className="border-0">
                            <tr>
                              <td className="align-text-top text-center border-0 font-weight-bold">
                                <small>
                                  Please call (253) 245-2102 immediately with
                                  any questions, concerns, or problems!<br></br>
                                  Send Invoicing to: Mitco Limited Logistics. |
                                  2302 B Street NW #101 | Auburn, WA. 98001
                                </small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function RateConfirmationBeta({ id }) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden border-0">
      <div className="card-body p-0">
        <div className="container mb-md-5 mt-4">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print
              </button>
              {/* <button
                type="button"
                className="btn btn-light-primary font-weight-bolder"
                onClick={handlePrint}
              >
                Download
              </button> */}
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
      </div>
    </div>
  );
}
