//import carTableMock from "./carTableMock";
import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

let carTableMock;
let resultPost;
export default function mockFindReceipts(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", "Error Occured");
      return false;
    }
  }

  //Get All Receipts
  async function GetAllReceipts() {
    return await fetch(window.$apiurl + "/receipts", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetReceiptById(id) {
    return await fetch(window.$apiurl + "/receipts/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Orders/GetCustomersForOptions", {
      method: "get",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //Get View Model
  async function GetViewModel(id) {
    return await fetch(window.$apiurl + "/receipts/getviewmodel/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }
  async function GetQualifiersByItemId(id) {
    return await fetch(window.$apiurl + "/Receipts/GetReceiptQualifier/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }
  //Get View Model Receipt
  async function GetViewModelItemReceipt(id) {
    return await fetch(
      window.$apiurl + "/receipts/getreceiptItemviewmodel/" + id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }
  //Get Model
  async function GetModel(customerId, warehouseId) {
    return await fetch(
      `${window.$apiurl}/receipts/${customerId}/${warehouseId}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetWarhouses() {
    return await fetch(window.$apiurl + "/Orders/GetWarehousesForOptions", {
      method: "get",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //GetCarriers
  async function GetCarriers() {
    return await fetch(window.$apiurl + "/carriers", {
      method: "get",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetUnitOfMeasures() {
    return await fetch(window.$apiurl + "/Orders/GetUnitOfMeasure", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function PostReceipt(someData) {
    return fetch(window.$apiurl + "/receipts", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function DeleteOrder(id) {
    return await fetch(window.$apiurl + "/Orders/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //---------------------------------------------------------------------

  //mock Post Receipt
  mock.onPost("api/FindReceipts").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const { CustomerId = 0, WarehouseId = 0, ReferenceNumber = "" } = product;

    const id = generateProductId();
    const newReceipt = {
      id,
      CustomerId,
      WarehouseId,
      ReferenceNumber,
    };
    var postReceipt = await PostReceipt(newReceipt);
    //Manage Data Result
    if (postReceipt.Code == "200") {
      postReceipt.id = postReceipt.Id;
      carTableMock.push(newReceipt);
      window.$ManageReceiptPost = 200;
      window.$ManageReceiptPostMessage = postReceipt.Message;
      return [
        200,
        { product: newReceipt, message: postReceipt.Message, success: true },
      ];
    } else {
      window.$ManageReceiptPost = 400;
      window.$ManageReceiptPostMessage = postReceipt.Message;
      return [
        200,
        { product: newReceipt, message: postReceipt.Message, success: false },
      ];
    }
  });
  // mock Find Receipts
  mock.onPost("api/FindReceipts/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);

    const a = queryParams.filter.FirstTransactionId;
    const b = queryParams.filter.LastTransactionId;
    delete queryParams.filter.FirstTransactionId;
    delete queryParams.filter.LastTransactionId;

    const c = queryParams.filter.StartDate;
    const d = queryParams.filter.EndDate;
    const dateFilter = queryParams.filter.DateFilter;
    delete queryParams.filter.StartDate;
    delete queryParams.filter.EndDate;
    delete queryParams.filter.DateFilter;

    carTableMock = await GetAllReceipts();
    const filter = queryParams.filter;
    //Filter By Customer ID / Warehouse ID
    if (filter.CustomerId !== undefined && filter.WarehouseId !== undefined) {
      carTableMock = await GetModel(filter.CustomerId, filter.WarehouseId);
      delete queryParams.filter.CustomerId;
      delete queryParams.filter.WarehouseId;
    }
    const filteredReceipts = mockUtils.baseFilter(carTableMock, queryParams);
    if (a != undefined && b != undefined) {
      var list = [];
      filteredReceipts.entities.map((x) => {
        if (a <= x.TransactionId && x.TransactionId <= b) list.push(x);
      });
      filteredReceipts.entities = list;
    }

    //Filter By Date Filter
    if (c != undefined && d != undefined && dateFilter != undefined) {
      var listDate = [];
      switch (dateFilter) {
        case "Created":
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.Created).getTime() &&
              new Date(x.Created).getTime() <= d
            )
              listDate.push(x);
          });
          break;

        case "ExcpectedDate":
          var listDate = [];
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.ExcpectedDate).getTime() &&
              new Date(x.ExcpectedDate).getTime() <= d
            )
              listDate.push(x);
          });
          break;

        case "ArrivalDate":
          var listDate = [];
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.ArrivalDate).getTime() &&
              new Date(x.ArrivalDate).getTime() <= d
            )
              listDate.push(x);
          });
          break;
      }
      filteredReceipts.entities = listDate;
    }
    return [200, filteredReceipts];
  });

  mock.onPost("api/FindReceipts/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);

    const a = queryParams.filter.FirstTransactionId;
    const b = queryParams.filter.LastTransactionId;
    delete queryParams.filter.FirstTransactionId;
    delete queryParams.filter.LastTransactionId;

    const c = queryParams.filter.StartDate;
    const d = queryParams.filter.EndDate;
    const dateFilter = queryParams.filter.DateFilter;
    delete queryParams.filter.StartDate;
    delete queryParams.filter.EndDate;
    delete queryParams.filter.DateFilter;

    let carTableMock2 = await GetAllReceipts();
    const filter = queryParams.filter;
    //Filter By Customer ID / Warehouse ID
    if (filter.CustomerId !== undefined && filter.WarehouseId !== undefined) {
      carTableMock = await GetModel(filter.CustomerId, filter.WarehouseId);
      delete queryParams.filter.CustomerId;
      delete queryParams.filter.WarehouseId;
    }
    const filteredReceipts = mockUtils.baseFilter(carTableMock2, queryParams);
    if (a != undefined && b != undefined) {
      var list = [];
      filteredReceipts.entities.map((x) => {
        if (a <= x.TransactionId && x.TransactionId <= b) list.push(x);
      });
      filteredReceipts.entities = list;
    }

    //Filter By Date Filter
    if (c != undefined && d != undefined && dateFilter != undefined) {
      var listDate = [];
      switch (dateFilter) {
        case "Created":
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.Created).getTime() &&
              new Date(x.Created).getTime() <= d
            )
              listDate.push(x);
          });
          break;

        case "ExcpectedDate":
          var listDate = [];
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.ExcpectedDate).getTime() &&
              new Date(x.ExcpectedDate).getTime() <= d
            )
              listDate.push(x);
          });
          break;

        case "ArrivalDate":
          var listDate = [];
          filteredReceipts.entities.map((x) => {
            if (
              c <= new Date(x.ArrivalDate).getTime() &&
              new Date(x.ArrivalDate).getTime() <= d
            )
              listDate.push(x);
          });
          break;
      }
      filteredReceipts.entities = listDate;
    }
    return [200, filteredReceipts];
  });

  mock.onPost("api/FindReceipts/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/FindReceipts/updateStatusForProducts").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/FindReceipts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindReceipts\/(\d+)/)[1];
    var product = await GetReceiptById(id);
    if (!product) {
      return [400];
    }
    return [200, product];
  });

  //mock viewModel
  mock.onPost("api/FindReceipt/getViewModel").reply(async (data) => {
    const product = await GetReceiptById(data.data);
    const result = await GetViewModel(product.CustomerId);
    const resultItemReceipt = await GetViewModelItemReceipt(product.CustomerId);
    const resultUnits = await GetUnitOfMeasures();
    window.$fetchViewModel = result;
    window.$fetchViewModelSku = resultItemReceipt.Items;
    window.$fetchViewModelItemReceipt = resultItemReceipt;
    window.$fetchViewModelReasonsHold = resultItemReceipt.ReasonsForHold;
    window.$fetchViewModelSuppliers = resultItemReceipt.Suppliers;
    window.$fetchUnits = resultUnits;
    return [200, result];
  });
  mock.onGet("api/receipts/getQualifiers").reply(async () => {
    const result = await GetQualifiersByItemId(window.$itemIdW);
    return [200, result];
  });
  mock.onGet("api/FindReceipt/GetAllApi").reply(async () => {
    var customers = {};
    customers["customersList"] = await GetCustomers();
    customers["warhousesList"] = await GetWarhouses();
    customers["carriersList"] = await GetCarriers();
    customers["unitOfMeasures"] = window.$fetchUnits;
    customers["viewModelData"] = window.$fetchViewModel;
    customers["viewModelDataItemReceiptSku"] = window.$fetchViewModelSku;
    customers["viewModelDataItemReceipt"] = window.$fetchViewModelItemReceipt;
    customers["viewModelDataReasonsHold"] = window.$fetchViewModelReasonsHold;
    customers["viewModelDataSuppliers"] = window.$fetchViewModelSuppliers;
    return [200, customers];
  });

  //Put Function
  async function PutReceipt(someData, id) {
    return fetch(window.$apiurl + "/receipts/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          return res[0];
        } else {
          return res[1];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //mock on Put
  mock.onPut(/api\/FindReceipts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindReceipts\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    var putReceipt = await PutReceipt(product, id);
    //Manage Data Result
    if (putReceipt === 200) {
      window.$ManageReceiptPut = 200;
      const index = carTableMock.findIndex((el) => el.id === +id);
      if (!index) {
        return [400];
      }
      carTableMock[index] = { ...product };
      document.getElementById("ReLoadTableId").click();
      return [200];
    } else {
      window.$ManageReceiptPut = 400;
      window.$ManageReceiptPutMessage = putReceipt.Message;
      return [400];
    }
  });

  mock.onDelete(/api\/FindReceiptss\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindReceiptss\/(\d+)/)[1];
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (index === -1) return [400];
    var deleteUser = await DeleteOrder(id);
    if (!deleteUser) return [400];
    carTableMock.splice(index, 1);
    return [200];
  });
}

function generateProductId() {
  const ids = carTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
