import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockZoneBilling(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", "Error Occured");
      return false;
    }
  }
  //API on Get By Id
  //Get {id}
  async function GetZoneBillingById(id) {
    return await fetch(window.$apiurl + "/zonebilling/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  //Put
  async function PutZoneBilling(someData, id) {
    return fetch(window.$apiurl + "/zonebilling/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  //Post Zone Billing API Call
  async function PostZoneBilling(someData) {
    console.log(someData);
    return fetch(window.$apiurl + "/zonebilling", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        console.log(data);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Get Customers List
  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Orders/GetCustomersForOptions", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }

  //Get Warhouses List
  async function GetWarhouses() {
    return await fetch(window.$apiurl + "/Orders/GetWarehousesForOptions", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return false;
      });
  }

  //Get on Find Data
  async function GetZoneBilling(customerId, warehouseId) {
    return await fetch(
      window.$apiurl + "/zonebilling/" + customerId + "/" + warehouseId,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Delete Zone Billing
  async function DeleteZoneBilling(id) {
    return await fetch(window.$apiurl + "/zonebilling/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //Fill Select
  mock.onGet("api/ZoneBilling/GetAllApi").reply(async () => {
    var customers = {};
    customers["customersList"] = await GetCustomers();
    customers["warhousesList"] = await GetWarhouses();
    return [200, customers];
  });

  mock.onPost("api/ZoneBilling").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    console.log(customer);
    const {
      CustomerId = undefined,
      WarehouseId = undefined,
      Carrier = "",
      ServiceLevel = "",
      ZoneName = "",
      Weight = undefined,
      Rate = undefined,
    } = customer;

    let id;
    try {
      id = generateUserId();
    } catch (error) {
      id = 0;
    }
    const newZoneBilling = {
      id,
      CustomerId,
      WarehouseId,
      Carrier,
      ServiceLevel,
      ZoneName,
      Weight,
      Rate,
    };
    var postZoneBilling = await PostZoneBilling(newZoneBilling);
    //Manage Data Result
    if (postZoneBilling.Code == "200") {
      postZoneBilling.id = postZoneBilling.Id;
      document.getElementById("ReLoadTableId").click();
      customerTableMock.push(newZoneBilling);
      window.$ManageZoneBillingPost = 200;
      window.$ManageZoneBillingPostMessage = postZoneBilling.Message;
      return [
        200,
        {
          customer: newZoneBilling,
          message: postZoneBilling.Message,
          success: true,
        },
      ];
    } else {
      window.$ManageZoneBillingPost = 400;
      window.$ManageZoneBillingPostMessage = postZoneBilling.Message;
      return [400, { message: postZoneBilling.Message, success: false }];
    }
  });

  async function ImportZoneBilling(someData) {
    return fetch(`${window.$apiurl}/zonebilling/Import`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        console.log(response);
        const statusCode = response.status;
        let data = {};
        if (statusCode !== 200) data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  mock.onPost("api/ZoneBilling/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filter = queryParams.filter;
    if (filter.CustomerId === undefined || filter.WarehouseId === undefined) {
      filter.CustomerId = 0;
      filter.WarehouseId = 0;
    }
    if (filter.CustomerId !== 0 && filter.WarehouseId !== 0) {
      customerTableMock = await GetZoneBilling(
        filter.CustomerId,
        filter.WarehouseId
      );
      delete queryParams.filter.CustomerId;
      delete queryParams.filter.WarehouseId;
      delete queryParams.filter.ReLoadTable;
    }
    const filteredZoneBilling = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filteredZoneBilling];
  });

  mock.onPost("api/ZoneBilling/deleteZoneBilling").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ZoneBilling/updateStatusForZoneBilling").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  //mock Import ZoneBilling
  mock.onPost("api/ZoneBilling/import").reply(async ({ data }) => {
    console.log("data");
    const zonebilling = JSON.parse(data);
    console.log(zonebilling);
    var result = await ImportZoneBilling(zonebilling);
    console.log(result);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });
  mock.onGet(/api\/ZoneBilling\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ZoneBilling\/(\d+)/)[1];
    var resultById = await GetZoneBillingById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/ZoneBilling\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ZoneBilling\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutZoneBilling(customer, id);
    if (!putObj) {
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }

    //Manage Data Result
    if (window.$statusCodePut === 200) {
      console.log(customer);
      window.$ManageZoneBillingPut = 200;
      customerTableMock[index] = { ...customer };
      return [200];
    } else {
      window.$ManageZoneBillingPut = 400;
      window.$ManageZoneBillingPutMessage = putObj.Message;
      return [400];
    }
  });

  mock.onDelete(/api\/ZoneBilling\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ZoneBilling\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteZoneBilling(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  if (customerTableMock.length > 0 && customerTableMock === undefined) {
    const ids = customerTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  } else {
    return 0;
  }
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
