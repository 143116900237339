import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import $ from "jquery";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockManageQuotes(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetUsersData(link) {
    return await fetch(window.$apiurl + "/quoting?" + link, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        window.$AllCountQuote = data.AllCount;
        const listStatus = [
          {
            Id: 45,
            Name: "Draft",
            Count: data.DraftCount,
          },
          {
            Id: 46,
            Name: "New",
            Count: data.NewCount,
          },
          {
            Id: 47,
            Name: "Pending",
            Count: data.PendingCount,
          },
          {
            Id: 48,
            Name: "Accepted",
            Count: data.AcceptedCount,
          },
          {
            Id: 49,
            Name: "Rejected",
            Count: data.RejectedCount,
          },
        ];
        window.$statusList = listStatus;
        return Promise.all([statusCode, data.data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostCustomerProfileRating(someData) {
    return fetch(window.$apiurl + "/quoting?IsQuote=true", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  async function PostCloneRate(id) {
    return fetch(window.$apiurl + "/rating/clone/" + id, {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  async function PutUser(someData, id) {
    return fetch(window.$apiurl + "/quoting/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/quoting/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewModel() {
    return await fetch(window.$apiurl + "/quoting/viewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetWarehousesFetch(obj) {
    return await fetch(window.$apiurl + "/warehousesForCustomer", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/ManageCustomerQuotes/GetAllApi").reply(async () => {
    const obj = {};
    obj["viewModel"] = await GetViewModel();
    return [200, obj];
  });
  mock.onGet("api/ManageCustomerQuotes/CloneRate").reply(async () => {
    const id = window.$RateId;
    await PostCloneRate(id);
    document.getElementById("ReLoadTableId").click();
    Notification("success", "Your rate has been cloned successfully.");
  });
  mock.onGet("api/ManageCustomerQuotes/fetchWarehouses").reply(async () => {
    const obj = {};
    obj["warehousesListFetch"] = await GetWarehousesFetch(window.$objCustomers);
    return [200, obj];
  });
  mock.onPost("api/ManageCustomerQuotes").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Name = "",
      Description = "",
      StatusId = "",
      ValidFrom = "",
      ValidTo = "",
      QuoteTypeId=null,
      CustomerIds = [],
      Rates = [],
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      id,
      Name,
      Description,
      StatusId,
      ValidFrom,
      QuoteTypeId,
      ValidTo,
      CustomerIds,
      Rates,
    };
    var postCustomerProfile = await PostCustomerProfileRating(newCustomer);
    if (postCustomerProfile.Id != undefined) {
      Notification("success", "Quote has been added successfully");
      postCustomerProfile.id = postCustomerProfile.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      $(".CustomerQuotesCard").show();
      window.$QuoteCustomer400 = false;
      return [
        200,
        {
          customer: newCustomer,
          message: postCustomerProfile.Message,
          success: true,
        },
      ];
    } else {
      if (postCustomerProfile.Message) {
        document.getElementById("ReLoadTableId").click();
        Notification("error", postCustomerProfile.Message);
        window.$QuoteCustomer400 = true;
        $(".CustomerQuotesCard").hide();
        return [
          400,
          {
            customer: newCustomer,
            message: postCustomerProfile.Message,
            success: false,
          },
        ];
      } else {
        Notification("success", "Quote has been added successfully");
        document.getElementById("ReLoadTableId").click();
        $(".CustomerQuotesCard").show();
        window.$QuoteCustomer400 = false;
        return [
          200,
          {
            customer: newCustomer,
            message: postCustomerProfile.Message,
            success: true,
          },
        ];
      }
    }
  });

  mock.onPost("api/ManageCustomerQuotes/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const _filter = queryParams;
    var carrierId = "carrierId=" + (_filter.filter.CarrierId || "");
    var customerId = "&customerId=" + (_filter.filter.CustomerId || "");
    var statusId = "&statusId=" + (window.$QuotesManagmentStatu || "");
    var name = "&name=" + (_filter.filter.Name || "");
    var validFrom = "&validFrom=" + (_filter.filter.ValidFrom || "");
    var validTo = "&validTo=" + (_filter.filter.ValidTo || "");
    var expired = "&expired=" + (_filter.filter.Expired);
    var isQuote = "&IsQuote=true";
    var link =
      carrierId +
      customerId +
      statusId +
      name +
      validFrom +
      validTo +
      validTo +
      expired +
      isQuote;
    customerTableMock = await GetUsersData(link);
    delete _filter.filter.CarrierId;
    delete _filter.filter.CustomerId;
    delete _filter.filter.StatusId;
    delete _filter.filter.ValidFrom;
    delete _filter.filter.ValidTo;
    delete _filter.filter.ReLoadTable;
    delete _filter.filter.Expired;
    delete _filter.filter.Name;

    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageCustomerQuotes/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageCustomerQuotes/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  async function GetUser(id) {
    return await fetch(window.$apiurl + "/quoting/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet(/api\/ManageCustomerQuotes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomerQuotes\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ManageCustomerQuotes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomerQuotes\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const putQuote = await PutUser(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    if(putQuote.Message){
      Notification("error", putQuote.Message);
      return false;
    }else{
      Notification("success", "Quote has been updated successfully");
      document.getElementById("ReLoadTableId").click();
      $(".CustomerQuotesCard").show();
      return [200];  
    }
  });

  mock.onDelete(/api\/ManageCustomerQuotes\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageCustomerQuotes\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    document.getElementById("ReLoadTableId").click();
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
