import React, { useState } from "react";
import { Route } from "react-router-dom";
import { ProductsLoadingDialog } from "./products-loading-dialog/ProductsLoadingDialog";
import { ProductDeleteDialog } from "./product-delete-dialog/ProductDeleteDialog";
import { ProductsDeleteDialog } from "./products-delete-dialog/ProductsDeleteDialog";
import { ProductsFetchDialog } from "./products-fetch-dialog/ProductsFetchDialog";
import { ProductsUpdateStatusDialog } from "./products-update-status-dialog/ProductsUpdateStatusDialog";
import { ProductsCard } from "./ProductsCard";
import { ProductsUIProvider } from "./ProductsUIContext";
import { ProductTMSDetail } from "./product-detail/ProductDetail";
import { ProductMODetail } from "./master-order-detail/ProductDetail";
import { OrderCreation } from "./Order-Creation/OrderCreation";
import { ProductTMSEdit } from "./product-edit/ProductEdit";

export function ProductsPage({ history }) {
  const [NewModal, setNewModal] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [MODetail, setMODetail] = useState(false);
  const [LoadBoardEdit, setLoadBoardEdit] = useState(false);
  const [LoadBoardGrid, setLoadBoardGrid] = useState(true);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [OrderCreationModal, setOrderCreationModal] = useState(false);

  const productsUIEvents = {
    // newProductButtonClick: () => {
    //   history.push("/TransportManagementSystems/new");
    // },
    // newCreateOrderButtonClick: (MO) => {
    //   history.push(`/TransportManagementSystems/${MO}/OrderCreation`);
    // },
    // openEditProductPage: (id) => {
    //   setEditModal(id);
    // },
    // openDetailProductPage: (SO) => {
    //   history.push(`/TransportManagementSystems/SoDetail/${SO}`);
    // },
    // openMoProductPage: (MO) => {
    //   history.push(`/TransportManagementSystems/${MO}/MOEdit`);
    // },
    // openDeleteProductDialog: (id) => {
    //   history.push(`/TransportManagementSystems/${id}/delete`);
    // },
    // openDeleteProductsDialog: () => {
    //   history.push(`/TransportManagementSystems/deleteProducts`);
    // },
    // openFetchProductsDialog: () => {
    //   history.push(`/TransportManagementSystems/fetch`);
    // },
    // openUpdateProductsStatusDialog: () => {
    //   history.push("/TransportManagementSystems/updateStatus");
    // },
  };

  return (
    <ProductsUIProvider productsUIEvents={productsUIEvents}>
      <input
        type="hidden"
        id="openMODetailPage"
        onClick={(e) => {
          setEditModal(false);
          setOrderCreationModal(false);
          setLoadBoardEdit(false);
          setLoadBoardGrid(false);
          setMODetail(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="openTMSDetailPage"
        onClick={(e) => {
          setMODetail(false);
          setOrderCreationModal(false);
          setLoadBoardEdit(false);
          setLoadBoardGrid(false);
          setEditModal(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="OrderCreationModal"
        onClick={(e) => {
          setMODetail(false);
          setEditModal(false);
          setLoadBoardGrid(false);
          setLoadBoardEdit(false);
          setOrderCreationModal(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="LoadBoardEdit"
        onClick={(e) => {
          setMODetail(false);
          setEditModal(false);
          setLoadBoardGrid(false);
          setOrderCreationModal(false);
          setLoadBoardEdit(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="LoadBoardGrid"
        onClick={(e) => {
          setMODetail(false);
          setEditModal(false);
          setLoadBoardGrid(true);
          setOrderCreationModal(false);
          setLoadBoardEdit(false);
        }}
      />
      <ProductsLoadingDialog />

      {LoadBoardEdit && <ProductTMSEdit id={LoadBoardEdit} />}

      {EditModal && (
        <ProductTMSDetail SO={EditModal} setEditModal={setEditModal} />
      )}

      {OrderCreationModal && <OrderCreation MO={0} Mode={0} />}

      {MODetail && <ProductMODetail MO={MODetail} setMODetail={setMODetail} />}

      {!EditModal && !MODetail && !OrderCreationModal && !LoadBoardEdit && (
        <ProductsCard />
      )}

      {/* <Route path="/TransportManagementSystems/deleteProducts">
        {({ history, match }) => (
          <ProductsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/TransportManagementSystems");
            }}
          />
        )}
      </Route>
      <Route path="/TransportManagementSystems/:id/delete">
        {({ history, match }) => (
          <ProductDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/TransportManagementSystems");
            }}
          />
        )}
      </Route>
      <Route path="/TransportManagementSystems/fetch">
        {({ history, match }) => (
          <ProductsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/TransportManagementSystems");
            }}
          />
        )}
      </Route>
      <Route path="/TransportManagementSystems/updateStatus">
        {({ history, match }) => (
          <ProductsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/TransportManagementSystems");
            }}
          />
        )}
      </Route> */}
    </ProductsUIProvider>
  );
}
