import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { useParams } from "react-router-dom";
import axios from "axios";
import { shallowEqual, useSelector } from "react-redux";
import { useSubheader } from "../../../_metronic/layout";
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Notification } from "../../modules/Notification";
import LinearProgress from "@material-ui/core/LinearProgress";

import { RemarksUIProvider } from "../../modules/eMPower/pages/OrderReceiver/RemarksUIContext";
import { Remarks } from "../../modules/eMPower/pages/OrderReceiver/Remarks";
import { RemarksUIProviderManual } from "../../modules/eMPower/pages/BillingManual/RemarksUIContext";
import { RemarksManual } from "../../modules/eMPower/pages/BillingManual/Remarks";
import { RemarksUIProviderLocStorageRates } from "../../modules/eMPower/pages/LocStorageRates/RemarksUIContext";
import { RemarksLocStorageRates } from "../../modules/eMPower/pages/LocStorageRates/Remarks";

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));

//Export Function
export function SystemCalculated() {
    const { id } = useParams("id");
    const [OrderReceiverList, setOrderReceiverList] = useState([]);
    const [RecurStorageList, setRecurStorageList] = useState([]);
    const [ManualList, setManualList] = useState([]);
    const [LocStorageRatesList, setLocStorageRatesListList] = useState([]);
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [values, setValues] = React.useState({
        name: '',
        age: '',
        multiline: '',
        currency: '',
    });
    const [checked, setChecked] = React.useState(false);
    function handleChanges() {
        setChecked(prev => !prev);
    }
    const HandleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };
    function handleChange(event, newValue) {
        setValue(newValue);
    }
    function handleChangess(event) {
        setValue(event.target.value);
    }
    function handleChangeIndex(index) {
        setValue(index);
    }

    useEffect(() => {
        fetchBillingViewModelAsync();
    }, []);
    const suhbeader = useSubheader();
    const [title, setTitle] = useState("");
    const [viewModelOrderReceiverOptions, setViewModelOrderReceiverOptions] = useState([]);
    const [viewModelBillingPeriodOptions, setViewModelBillingPeriodOptions] = useState([]);
    const [viewModelInventoryOptions, setViewModelInventoryOptions] = useState([]);

    const [viewModelCustomers, setViewModelCustomers] = useState([]);
    const [viewModelWarehouses, setViewModelWarehouses] = useState([]);
    const [ReccuringAcct, setDataReccuringAcct] = useState([]);
    const [MinStorage, setDataMinStorage] = useState([]);

    useEffect(() => {
        fetchOrderAsync();
    }, []);

    const fetchOrderAsync = async () => {
        try {
            const result = await axios.get("api/OrderReceiver/GetAllApi");
            setViewModelOrderReceiverOptions(result.data["OrderReceiverOptions"].OrderReceiverOptions);
            setViewModelBillingPeriodOptions(result.data["BillingPeriodOptions"].BillingPeriodOptions);
            setViewModelInventoryOptions(result.data["InventoryOptions"].InventoryOptions);

            setViewModelCustomers(result.data["customersList"]);
            setViewModelWarehouses(result.data["warhousesList"]);
        } catch (e) { }
    };
    const { actionsLoading, productForEdit } = useSelector(
        (state) => ({
            actionsLoading: state.products.actionsLoading,
            productForEdit: state.products.productForEdit,
        }),
        shallowEqual
    );
    useEffect(() => {
        let _title = id ? "" : "System-Calculated Storage and Handling Charge Setup";
        if (productForEdit && id) {
            _title = `'${productForEdit.Sku}'`;
        }

        setTitle(_title);
        suhbeader.setTitle(_title);
    }, [productForEdit, id]);
    const fetchBillingViewModelAsync = async () => {
        setOrderReceiverList([]);
        try {
            const result = await axios.get(`api/SystemCalculated/${id}`);
            if (result.status === 200) {
                result.data[1].Charges.map(function (obj) {
                    switch (obj.Name) {
                        case 'Order/Receiver':
                            setOrderReceiverList(obj.Calculators);
                            break;
                        case 'Recur. Storage':
                            setRecurStorageList(obj.Calculators);
                            break;
                        case 'Manual':
                            setManualList(obj.Calculators);
                            break;
                        case 'Loc Storage Rates':
                            setLocStorageRatesListList(obj.Calculators);
                            break;
                        default:
                            setLocStorageRatesListList(obj.Calculators);
                    }
                });
            }
        } catch (e) {
        }
    };
    const [showProgress, setshowProgress] = useState(false);
    const handleCustomerChange = async (event) => {
        setshowProgress(true);
        setViewModelWarehouses([]);
        if (event.target.value !== "" && event.target.value !== null) {
            const result = await axios.get(`api/receipts/getwarehouses/${event.target.value}`);
            if (result.status === 200) {
                if (result.data.length > 0)
                    setViewModelWarehouses(result.data);
                else
                    Notification("error", "This Customes doesn't have access to any warehouse.");
            }
        }
        setshowProgress(false);
    }
    const fetchDataTable = async () => {
        const customerId = document.getElementById("CustomerId").value;
        const warehouseId = document.getElementById("WarehouseId").value;
        if (customerId && warehouseId) {
            var result = await axios.post("api/OrderReceiver/fetchData", { customerId: parseInt(customerId), warehouseId: parseInt(warehouseId) });
            var fetchData = await axios.post("api/OrderReceiver/Find", result);
            setDataReccuringAcct(result.data.RecurringStorageGlAcct);
            setDataMinStorage(result.data.MinStorageCharge);
        }
    }
    return (
        <div className={classes.root}>
            <div className="col-md-12 my-6">
                <form onSubmit className="form form-label-right">
                    <div className="form-group row pt-5">
                        <div className="col-lg-6">
                            <select
                                id="CustomerId"
                                className="form-control"
                                name="CustomerId"
                                placeholder="Filter by Customer"
                                onChange={handleCustomerChange.bind(this)} onClick={() => { }}
                            >
                                <option value="">Select</option>
                                {viewModelCustomers.map((item, index) => (
                                    <option key={index} value={item.Id}>{item.Name}</option>
                                ))}
                            </select>
                            <small className="form-text text-muted">
                                Choose a Customer
                            </small>
                        </div>
                        <div className="col-lg-6">
                            <select
                                id="WarehouseId"
                                className="form-control"
                                name="WarehouseId"
                                placeholder="Filter by Warehouse"
                                onChange={() => { fetchDataTable(); }}

                            >
                                <option value="">Select</option>
                                {viewModelWarehouses.map((item, index) => (
                                    <option key={index} value={item.Id}>{item.Name}</option>
                                ))}
                            </select>
                            {showProgress && <LinearProgress />}
                            <small className="form-text text-muted">
                                Choose a Warehouse
                            </small>
                        </div>
                    </div>
                </form>

                <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                    The system can assess storage and handling charges by volume, weight, or different packaging, providing you tell the system about your inventory
                </p>
                <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                    and rates. It may not be able to help with certain indeterminable cases, such as square footage, but it can remind you to bill for them.
                    </p>
            </div>
            <AppBar position="static" color="white">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Order/Receiver" />
                    <Tab label="Recur. Storage" />
                    <Tab label="Manual" />
                    <Tab label="Loc Storage Rates" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {/* First Tab */}
                <TabContainer dir={theme.direction}>
                    <div className="col-md-12">
                        <h4 className="card-title font-weight-bolder ">SPLIT PERIOD (AT TIME OF RECEIPT)</h4>
                        <div className="bg-light-primary  checkbox-lg checkbox-light-primary px-5 pb-5 border mb-5">
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                If you allow "free days", the system will charge storage for inventory remaining after the free days. But the system needs to know
                        </p>
                            <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                                which split rate, half or full, to use when the free days expire.
                        </p>
                        </div>
                        <div className="px-5 pb-5 border">
                            <form className={classes.container} noValidate autoComplete="off">
                                <div className="form-group">
                                    <label className="mt-4">DETERMINE RATE (FULL OR HALF) AS OF</label>
                                    <div class="radio-inline">
                                        {viewModelOrderReceiverOptions.map((item, index) => (
                                            <label className="radio" key={index} value={item.Name}>
                                                <input type="radio" name="radios2" />
                                                <span></span>
                                                {item.Name}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                                <div className="form-group d-flex">
                                    <TextField
                                        id="standard-name"
                                        label="SPLIT PERIOD STORAGE GL ACCT #	"
                                        className={`col-md-3 mr-5 mt-0 ${classes.textField}`}
                                        value={values.name}
                                        onChange={HandleChange('name')}
                                        margin="normal"
                                    />
                                    <TextField
                                        id="standard-uncontrolled"
                                        label="SPLIT PERIOD DAY"
                                        defaultValue=""
                                        className={`col-md-3 mt-0 ${classes.textField}`}
                                        margin="normal"
                                    />
                                </div>
                            </form>
                        </div>
                        <h4 className="card-title font-weight-bolder mt-5">HANDLING (AT TIME OF CONFIRMATION)</h4>
                        <div className="px-5 pb-5 border">
                            <form className={classes.container} noValidate autoComplete="off">
                                <div className="form-group d-flex">
                                    <TextField
                                        id="standard-name"
                                        label="RECEIVER HANDLING GL ACCT #"
                                        className={`col-md-3 mr-5 ${classes.textField}`}
                                        value={values.name}
                                        onChange={HandleChange('name')}
                                        margin="normal"
                                    />
                                    <TextField
                                        id="standard-uncontrolled"
                                        label="SHIPPING HANDLING GL ACCT #	"
                                        defaultValue=""
                                        className={`col-md-3 ${classes.textField}`}
                                        margin="normal"
                                    />
                                </div>
                            </form>
                        </div>
                        <div class="bg-light-primary  checkbox-lg checkbox-light-primary px-5 pb-5 border my-5">
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                <b>Default Unit:</b> This is the most common unit for this customer's order/receiver charges, such as pallet or cubic feet. When creating new items, the system automatically fills in the unit, but you can change it.
                        </p>
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                <b> Rates:</b> Fill in the grid with default rates for your default unit. Add additional unit rates for this customer, if any. You can override these rates by editing individual items.
                        </p>
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                <b>Split period rates:</b> If you charge for storage upon receipt, you can charge differently depending on whether the receipt was made in the first or second half of the period. This is referred to as split period storage charges.
                        </p>
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                <b>Handling rates:</b> The system can automatically charge for handling of Receivers and Shipping.
                        </p>
                            <p className="text-danger m-0 pt-5 font-weight-normal ">
                                Items will not have order/receiver storage and handling billed unless they've been set up in the Edit Item Page Unit Of Measure Tab.
                        </p>
                        </div>
                        <form onSubmit className="form form-label-right">
                            <div className="form-group row pt-5">
                                <div className="col-md-5">
                                    <label className="mt-4">DEFAULT UNIT FOR ORDER/RECEIVER STORAGE AND HANDLING CHARGES</label>
                                </div>
                                <div className="col-lg-2">
                                    <select
                                        className="form-control"
                                        name="status"
                                        placeholder="Filter by Status"
                                    >
                                        <option value="">Each</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                        <RemarksUIProvider currentProductId={id}>
                            <Remarks />
                        </RemarksUIProvider>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
                        </button>
                        <> </>
                        <button
                            type="submit"
                            className="btn btn-success btn-elevate"
                        >
                            Save
                        </button>
                    </div>
                </TabContainer>

                {/* Second Tab */}
                <TabContainer dir={theme.direction}>
                    <div className="col-md-12">
                        <div className="bg-light-primary  checkbox-lg checkbox-light-primary px-5 pb-5 border mb-5">
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                The system can automatically prepare recurring storage charges. Tell the system: 1) how often you bill (monthly, semi-monthly, or weekly), 2) when each period ends, and 3) on which days you want the system to count inventory within each period.
                        </p>
                            <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                                Enter a minimum storage charge to have the system adjust this customer's invoices accordingly.
                        </p>
                        </div>
                        <form className={classes.container} noValidate autoComplete="off">
                            <div className="form-group d-flex">
                                <TextField
                                    id="standard-name"
                                    label="RECURRING STORAGE GL ACCT #"
                                    className={`col-md-3 mr-5 mt-0 ${classes.textField}`}
                                    value={ReccuringAcct}
                                    onChange={HandleChange('name')}
                                    margin="normal"
                                />
                                <TextField
                                    id="standard-uncontrolled"
                                    label="MINIMUM STORAGE CHARGE"
                                    value={MinStorage}
                                    className={`col-md-3 mt-0 ${classes.textField}`}
                                    margin="normal"
                                />
                            </div>
                        </form>
                        <h4 className="card-title font-weight-bolder mt-5">STORAGE BILLING, FOR THIS CUSTOMER, SAME FOR ALL WAREHOUSES</h4>
                        <div className="px-5 pb-5 border">
                            <form className={classes.container} noValidate autoComplete="off">
                                <div className="form-group d-flex">
                                    <div className={classes.root}>
                                        <div className="col-md-5">
                                            <label className="mt-4">BILLING PERIOD</label>
                                        </div>
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender2"
                                            className={classes.group}
                                            value={value}
                                            aria-label="position" name="position"
                                            value={value}
                                            onChange={handleChangess}
                                            row
                                        >
                                            {viewModelBillingPeriodOptions.map((item, index) => (
                                                <FormControlLabel
                                                    id={item.Id}
                                                    value={item.Name}
                                                    control={<Radio color="primary" />}
                                                    label={item.Name}
                                                    labelPlacement="start"
                                                />
                                            ))}
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-5">
                                        <label className="mt-4">DAYS TO WAIT AFTER PERIOD END TO AUTOMATICALLY GENERATE BILL</label>
                                    </div>
                                    <div className="col-lg-1">
                                        <TextField
                                            id="outlined-dense"
                                            label=""
                                            className={clsx(classes.textField, classes.dense)}
                                            margin="dense"
                                            variant="outlined"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <h4 className="card-title font-weight-bolder mt-5">INVENTORY COUNTS ON EACH SEMI-MONTHLY BILL</h4>
                        <div className="px-5 pb-5 border mb-3">
                            <form className={classes.container} noValidate autoComplete="off">
                                <div className="form-group d-flex">
                                    <div className={classes.root}>
                                        <div className="col-md-5">
                                            <label className="mt-4">On Specific Days: </label>
                                        </div>
                                        <RadioGroup
                                            aria-label="gender"
                                            name="gender2"
                                            className={classes.group}
                                            value={value}
                                            aria-label="position" name="position"
                                            value={value}
                                            onChange={handleChangess}
                                        >
                                            {viewModelInventoryOptions.map((item, index) => (
                                                <FormControlLabel
                                                    id={item.Id}
                                                    value={item.Name}
                                                    control={<Radio color="primary" />}
                                                    label={item.Name}
                                                />
                                            ))}
                                            <div className="form-group row mb-0">
                                                <div className="col-md-4">
                                                </div>
                                                <div className="col-lg-3">
                                                    <select
                                                        className="form-control"
                                                        name="status"
                                                        placeholder="Filter by Status"
                                                    >
                                                        <option value="">Sunday</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <label className="mt-4">On Each Receiver's Anniversary Day:</label>

                                        </RadioGroup>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
                         </button>
                        <> </>
                        <button
                            type="submit"
                            className="btn btn-success btn-elevate"
                        >
                            Save
                        </button>
                    </div>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="col-md-12">
                        <div className="bg-light-primary  checkbox-lg checkbox-light-primary px-5 pb-5 border mb-5">
                            <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                                Each time you tell the system to generate recurring storage charges, the system can remind you to manually calculate specific
                        </p>
                            <p class="text-dark-50 m-0 pt-5 font-weight-normal">
                                additional charges. These charges can be related to items, such as square footage, that the system cannot calculate. Additionally,
                        </p>
                            <p class="text-dark-50 m-0 pt-5 font-weight-normal">the charges can be unrelated to items, such as monthly storage of vehicles.</p>
                        </div>
                        <RemarksUIProviderManual currentProductId={id}>
                            <RemarksManual />
                        </RemarksUIProviderManual>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
              </button>
                        <> </>
                        <button
                            type="submit"
                            className="btn btn-success btn-elevate"
                        >
                            Save
              </button>
                    </div>

                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <div className="Col-md-12">
                        <RemarksUIProviderLocStorageRates currentProductId={id}>
                            <RemarksLocStorageRates />
                        </RemarksUIProviderLocStorageRates>
                        <button
                            type="button"
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
              </button>
                        <> </>
                        <button
                            type="submit"
                            className="btn btn-success btn-elevate"
                        >
                            Save
              </button>
                    </div>
                </TabContainer>
            </SwipeableViews>
        </div>
    );
}
