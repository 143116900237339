import React, { useState, useEffect } from "react";
import { Notification } from "../../../../app/modules/Notification";
import { Card } from "react-bootstrap";
import $ from "jquery";
import { CreatableSelect2 } from "../../../_partials/controls";
import { momentDate } from "../../../_helpers/ATHelpers";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function NotesComponentDashboard({ id,message, get, post, put, Categories ,publicNotes}) {
  const [NotesList, setNotesList] = useState([]);
  const [CategorysList, setCategorysList] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [NewText, setNewText] = useState("");
  const [isSave, setisSave] = useState("");
  const [Action, setAction] = useState("");
  const [ActionPut, setActionPut] = useState("");
  const [isEdit, setEditNote] = useState(false);
  const [titleBtnDeletePut, setTitleBtnDeletePut] = useState("Delete");
  useEffect(() => {
    if (get && id) {
      async function fetchMyAPI() {
        getNots();
      }
      fetchMyAPI();
    }
  }, [get, id]);

  useEffect(() => {
    setCategorysList(Categories);
  }, [Categories]);

  async function GetNotes() {
    return await fetch(`${window.$apiurl}${get}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostNote(bodytext, cat, accessLevelId) {
    bodytext += " | Actions : " + Action;
    return await fetch(`${window.$apiurl}${post}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Body: bodytext,
        CategoryId: cat,
        Container: id,
        AccessLevelId: accessLevelId,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note has been saved");
      })
      .catch((error) => {
        Notification("success", "Note has been saved");
      });
  }

  async function DeleteNote(id) {
    return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      })
      .catch((error) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      });
  }
  function CardFunction({ x, index }) {
    const [Showing, setShowing] = useState(false);
    const [Text, setText] = useState("");
    const [Category, setCategory] = useState("");
    async function PutNote(putId, bodytext, CategoryId) {
      if(window.$actionPutNote !== undefined){
        bodytext += " | Actions : " + window.$actionPutNote;
      }
      return await fetch(`${window.$apiurl}${put}${putId}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: bodytext, CategoryId: CategoryId }),
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then(async (res) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        })
        .catch((error) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        });
    }

    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <span className="mb-2 d-inline alert alert-light-info px-2 py-1 mt-3 text-primary bg-light">
              {momentDate(x?.CreatedAt)}
              {/* <span className="mb-2 ml-2 text-muted d-inline">
                {x?.Created !== null &&
                  ` - edited (${momentDate(x?.Created)})`}
              </span> */}
            </span>
            <br />
            {x?.CategoryId !== null &&
              CategorysList.find((y) => y.Id === x?.CategoryId)?.Name !==
                undefined && (
                <div className="mb-2 mt-3 text-muted">
                  <i class="fas fa-book mr-2"></i>{" "}
                  {CategorysList.find((y) => y.Id === x?.CategoryId)?.Name}
                </div>
              )}
            <Card.Text className="mt-5 ml-5">
              {!Showing && (!Text ? x?.Body : Text)}
              {Showing && (
                <>
                  <div className="form-group row mb-4">
                    <div className="col-lg-12">
                      <CreatableSelect2
                        name="Action"
                        label="Actions"
                        options={[
                          { value: "No Action", label: "No Action" },
                          {
                            value: "OTR (Over The Road)",
                            label: "OTR (Over The Road)",
                          },
                          {
                            value: "Intermodal Rail (COFC)",
                            label: "Intermodal Rail (COFC)",
                          },
                        ]}
                        onChange={(newValue) => {
                          window.$actionPutNote = newValue.value;
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <div className="col-lg-12">
                      <CreatableSelect2
                        name="CategoryId"
                        valueSelect={x?.CategoryId || ""}
                        label="Select Category"
                        options={CategorysList.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(newValue) => {
                          var a = newValue?.value;
                          setCategory(a);
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-lg-12">
                      <textarea
                        className="form-control mb-5 mt-5"
                        rows="4"
                        defaultValue={!Text ? x?.Body : Text}
                        onBlur={(event) => {
                          setText(event.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </>
              )}
            </Card.Text>
            {db.getState().User?.Email === x?.UserEmail && (
              <div
                className="text-right"
                style={{ position: "absolute", bottom: "20px", right: "10px" }}
              >
                <button
                  type="button"
                  class="btn btn-light-primary mr-2"
                  onClick={async () => {
                    if (Showing) {
                      await PutNote(
                        x?.Id,
                        Text === "" ? x?.Body : Text,
                        Category
                      );
                      getNots();
                    } else {
                      window.$actionPutNote = undefined;
                      setShowing(true);
                      // if(!isEdit){
                      //   setEditNote(true);
                      // }
                    }
                  }}
                >
                  {!Showing && (
                    <>
                      <i class="fas fa-edit mr-2"></i> Edit
                    </>
                  )}
                  {Showing && (
                    <>
                      <i class="fas fa-save mr-2"></i> Save
                    </>
                  )}
                </button>
                {Showing && (
                  <button
                    type="button"
                    class="btn btn-light-danger mr-2 ml-3"
                    onClick={async () => {
                      setShowing(false);
                    }}
                  >
                    <i class="fas fa-times"></i> Close
                  </button>
                )}
                {db.getState().User?.Email === x?.UserEmail && !Showing && (
                  <>
                    {/* Close Btn */}
                    <button
                      type="button"
                      class="btn btn-light-danger mr-2 ml-3"
                      onClick={async () => {
                        await DeleteNote(x?.Id);
                        getNots();
                      }}
                    >
                      <i class="fas fa-trash mr-2"></i> Delete
                    </button>
                  </>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getNots() {
    var a = await GetNotes();
    try{
      if(publicNotes){
        a.Notes = a?.Notes.filter(
          (word) =>
            String(word.AccessLevelId).includes(String("4")) || String(word.AccessLevelId).includes(String("null"))
        );
        setNotesList(a?.Notes);
      }else{
        setNotesList(a?.Notes || []);
      }
    }catch(err){}
    setCategorysList(a?.Categories || []);
    setAccessLevels(a?.AccessLevels || []);
  }
  return (
    <>
      <div className=" m-auto mb-5">
        <button
          type="button"
          class="btn btn-light-primary mr-2 w-100 mb-5"
          onClick={() => {
            $(".NotesId").toggle("slow");
          }}
        >
          <i class="fas fa-plus mr-2"></i> Add New Note
        </button>
      </div>

      <div className=" m-auto mb-5 NotesId" style={{ display: "none" }}>
        <label>Text</label>
        <textarea
          className="form-control mb-5"
          rows="4"
          id="NewTextNots"
          onBlur={(e) => setNewText(e.target.value)}
        ></textarea>
        <div className="row">
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="Action"
              label="Actions"
              options={[
                { value: "No Action", label: "No Action" },
                { value: "OTR (Over The Road)", label: "OTR (Over The Road)" },
                {
                  value: "Intermodal Rail (COFC)",
                  label: "Intermodal Rail (COFC)",
                },
              ]}
              onChange={(newValue) => {
                setAction(newValue?.value);
                var a = newValue?.value;
                window.$NoteCategoryId = a;
                setisSave(a);
              }}
            />
          </div>
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="CategoryId"
              label="Select Category"
              options={CategorysList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteCategoryId = a;
                setisSave(a);
              }}
            />
          </div>

          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="AccessLevelId"
              id="AccessLevelId"
              label="Access Level"
              options={AccessLevels.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteAccessLevelId = a;
                setisSave(a);
              }}
              placeholder="Access Level"
            />
          </div>
        </div>
        <div className="row">
          <div className="m-auto col-lg-12 text-center">
            <label className="text-white">.</label>
            <button
              type="button"
              class="btn btn-light-success mr-2 w-100 mb-5 m-auto"
              onClick={async () => {
                if (NewText === "") Notification("warning", "Text is Empty");
                else {
                  var a = await PostNote(
                    NewText,
                    window.$NoteCategoryId,
                    window.$NoteAccessLevelId
                  );
                  getNots();
                  setTimeout(() => {
                    setNewText("");
                    $(".NotesId").toggle("slow");
                    $("#NewTextNots").val("");
                  }, 50);
                }
              }}
            >
              <i class="fas fa-save mr-2"></i> Save
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <div className="form-group row">
        <div className="col-lg-12">
          {Action === "OTR (Over The Road)" && (
            <label style={{ fontSize: "11px" }}>{message}</label>
          )}
        </div>
      </div>
      {NotesList?.map((x, index) => (
        <CardFunction x={x} key={index} index={index} New={() => getNots()} />
      ))}
    </>
  );
}
