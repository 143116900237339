import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";

let carriersTableMock;
export default function mockServiceManageCarriers(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetServiceCarriers(carrierId, showInactive) {
    return await fetch(
      `${window.$apiurl}/api/CarrierServices?carrierId=${carrierId}&showInactive=${showInactive}`
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/api/carrierservices/${id}`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1].Id;
        res[1].IsActive = res[1].Active;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/api/carrierservices", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    let modelData = someData["customer"];
    return fetch(window.$apiurl + "/api/carrierservices/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modelData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/api/carrierservices/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCarriers() {
    return await fetch(`${window.$apiurl}/carriers?showInactive=false`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function Getexternalservices() {
    return await fetch(`${window.$apiurl}/api/carrierservices/externalservices`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet("api/ServiceManageCarriers/GetCarriers").reply(async () => {
    const obj = await GetCarriers();
    return [200, obj];
  });

  mock.onGet("api/ServiceManageCarriers/externalservices").reply(async () => {
    const obj = {};
    obj.externalservices = await Getexternalservices();
    obj.Carrier = await GetCarriers();
    return [200, obj];
  });

  mock.onPost("api/ServiceManageCarriers").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const { Name, ExternalServiceId, IsActive = false, CarrierId } = customer;
    const newCustomer = {
      Name,
      ExternalServiceId,
      IsActive,
      CarrierId,
    };
    var postModel = await PostModel(newCustomer);
    document.getElementById("ReLoadTableId").click();
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ServiceManageCarriers/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const carriersId = queryParams.filter.CarriersId;
    const showInactive = queryParams.filter.ShowInactive;
    carriersTableMock = await GetServiceCarriers(carriersId, showInactive);
    delete queryParams.filter.CarriersId;
    delete queryParams.filter.ShowInactive;
    delete queryParams.filter.ReLoadTable;
    const filterdCarriers = mockUtils.baseFilter(
      carriersTableMock,
      queryParams
    );
    return [200, filterdCarriers];
  });

  mock.onPost("api/ServiceManageCarriers/deleteCarriers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carriersTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carriersTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ServiceManageCarriers/updateStatusForCarriers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      carriersTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ServiceManageCarriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ServiceManageCarriers\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/ServiceManageCarriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ServiceManageCarriers\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = carriersTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    carriersTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ServiceManageCarriers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ServiceManageCarriers\/(\d+)/)[1];
    const index = carriersTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    carriersTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
