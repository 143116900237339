import React from "react";
export default function DateColumnFormatter2({ShipDate}) {
  var re = new RegExp("/", "g");
  var elapsed = new Date(ShipDate).toLocaleString().replace(re, "-");
  return (
    <>
      <span class="text-primary">
        {elapsed}
      </span>
    </>
  );
}
