// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import Select from "react-select";

import { makeStyles } from "@material-ui/core/styles";
// Validation schema
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: "2rem",
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Name: Yup.string()
        // .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Name is required"),

      ServiceId: Yup.string().required("Service is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  const [ServicesList, setServicesList] = useState([]);
  const [ServicesListOption, setServicesListOption] = useState([]);
  const [ServiceId, setServiceId] = useState(null);

  useEffect(() => {
    fetchGetCountriesAsync();
  }, [customer]);
  const fetchGetCountriesAsync = async () => {
    try {
      const result = await axios.get("api/ManageServices/GetData");
      setServicesListOption(
        result.data.map((suggestion) => ({
          value: suggestion.id,
          label: suggestion.Name,
        }))
      );
      setServicesList(result.data || []);
      if (
        customer.ServiceId !== undefined &&
        customer.ServiceId !== "" &&
        customer.ServiceId !== null
      ) {
        setServiceId(customer.ServiceId);
      }
    } catch (e) {}
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <div className="code-description mb-4">
                      <Field
                        name="Name"
                        label="Name"
                        component={Input}
                        placeholder="Name"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      <Field
                        name="Description"
                        label="Description"
                        component={Input}
                        placeholder="Description"
                        margin="normal"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="field-description">
                      {ServicesListOption && (
                        <>
                          <p className="mb-2">Service</p>
                          <Select
                            label="Service"
                            name={`ServiceId`}
                            id={`ServiceId`}
                            options={ServicesListOption}
                            value={ServicesListOption.filter(
                              (a) => a.value === ServiceId
                            )}
                            onChange={(event) => {
                              setServiceId(event.value);
                              setFieldValue("ServiceId", event.value);
                            }}
                          ></Select>
                          {errors.ServiceId && touched.ServiceId ? (
                            <div style={{ color: "red", fontSize: "12px" }}>
                              {errors.ServiceId}
                            </div>
                          ) : null}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="d-flex align-items-center mt-8">
                      <span className="bg-success align-self-stretch"></span>
                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                        <input
                          type="checkbox"
                          name="IsActive"
                          checked={values.IsActive}
                          onChange={(e) => {
                            setFieldValue("IsActive", e.target.checked);
                          }}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1">
                        <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                          IsActive
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
