/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useMemo } from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ModalProgressBar,
  Input,
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import { useCustomersUIContext } from "../CustomersUIContext";
import { Formik, Form, Field } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CreatableSelect from "react-select/creatable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Notification } from "../../../../Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);

export function CustomerDeleteDialog({ id, show, onHide }) {
  // Products UI Context
  const [EmailAddressList, setEmailAddressList] = useState(null);
  const [AttachementList, setAttachementList] = useState(null);
  const [ToData, setToData] = useState(null);
  const [ToDataDefeault, setToDataDefeault] = useState(null);
  const [SubjectData, setSubjectData] = useState(null);
  const [includCCData, setincludCCData] = useState(false);
  const [includBol, setincludBol] = useState(false);
  const [includOutboundDispatch, setincludOutboundDispatch] = useState(true);
  const [includShipmentManifest, setincludShipmentManifest] = useState(false);
  const [BodyData, setBodyData] = useState("");
  const [AttachmentData, setAttachmentData] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);

  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]);

  // Products Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.products.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    fetchUsersAsync();
    if (window.SendDispatchShipmentsList !== undefined) {
      setTimeout(() => {
        setSubjectData(
          `Mitco Shipment ID: ${window.SendDispatchShipmentsList?.map(function(
            item
          ) {
            return item.id;
          }).join(", ")}`
        );
        setBodyData(
          `<p>Hello,</p><p>Attached are the dispatch files for the following shipments.</p><p>Shipment# {${window.SendDispatchShipmentsList?.map(function(
            item
          ) {
            return item.id;
          }).join(", ")}}</p><p>Thanks</p><p>Mitco team</p>`
        );
      }, 500);
        
    } else {
      setTimeout(() => {
        setSubjectData("Mitco Shipment ID: " + id);
        setBodyData(
          `<p>Hello,</p><p>Attached are the dispatch files for the following shipments.</p><p>Shipment# {${id}}</p><p>Thanks</p><p>Mitco team</p>`
        );
      }, 500);
        
    }
  }, [id]);
  const fetchUsersAsync = async () => {
    try {
      if (id !== null) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var requestOptions = {
          method: "GET",
          withCredentials: true,
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(window.$apiurl + "/shipments/getsendemail/" + id, requestOptions)
          .then((response) => response.text())
          .then((result) => {
            const resulting = JSON.parse(result);
            var obj = [];
            var obj2 = [];
            resulting.Emails.map((item, index) => {
              obj.push({ value: item.Email, label: item.Email });
              obj2.push({ Email: item.Email, Name: item.Email });
            });
            setToDataDefeault(obj);
            setToData(obj2);
            setEmailAddressList(obj);
            setAttachementList(resulting.Attachments);
          })
          .catch((error) => console.log("error", error));

        fetch(window.$apiurl + "/carriers/transport/getviewmodel", {
          method: "get",
          headers: {
            Authorization: `bearer ${db.read().getState().Token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.text())
          .then((result) => {
            const objs = JSON.parse(result);
            setAccessLevels(objs.AccessLevels);
            setAttachmentTypes(objs.AttachmentTypes);
          })
          .catch((error) => console.log("error", error));
      }
    } catch (e) {}
  };
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteProduct = async () => {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};
    if (window.SendDispatchShipmentsList !== undefined) {
      data = {
        Ids: window.SendDispatchShipmentsList?.map(function(item) {
          return item.id;
        }),
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: includBol,
        OutboundDispatch: includOutboundDispatch,
        ShipmentManifest: includShipmentManifest,
      };
    } else {
      data = {
        Emails: ToData,
        Subject: SubjectData,
        Body: BodyData,
        IncludeCc: includCCData,
        Attachments: AttachmentData,
        Bol: includBol,
        OutboundDispatch: includOutboundDispatch,
        ShipmentManifest: includShipmentManifest,
      };
    }

    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "";
    if (window.SendDispatchShipmentsList !== undefined) {
      url = "/shipmentsmanagement/sendMultiEmail";
    } else {
      url = `/shipments/sendEmail/` + id;
    }
    let response = await fetch(`${window.$apiurl}${url}`, requestOptions);
    if (response.status === 200) {
      if (window.SendDispatchShipmentsList !== undefined) {
        Notification("success", `Email sent successfuly for Shipments IDs ${window.SendDispatchShipmentsList?.map(function(
          item
        ) {
          return item.id;
        }).join(", ")}`);

      }else{
        Notification("success", "Email sent successfuly for Shipment ID " + id);

      }
      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setincludBol(false);
      setincludOutboundDispatch(false);
      setincludShipmentManifest(false);
      setAttachmentData([]);
      setisLoadingSend(false);
      onHide();
    } else {
      Notification("error", "invalid data");
      setisLoadingSend(false);
      onHide();
    }
  };

  ///----------------------
  async function SaveShipementAattachement(id) {
    if (
      document.getElementById("shipmentAttachmentList").files[0] === undefined
    ) {
      Notification("error", "Please select Attachment");
      return;
    }
    setisLoadingSubmit(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachmentList");
    const AccessLevelId = document.getElementById("shipmentAccessLevelIdList")
      .value;
    const AttachmentTypeId = document.getElementById(
      "shipmentAttachmentTypeIdList"
    ).value;
    formdata.append(
      "File",
      inputElement.files[0],
      inputElement.value.split("\\")[2]
    );
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
    };
    let response = await fetch(
      `${window.$apiurl}/transload/shipments/addattachment?shipmentId=` +
        id +
        "&accessLevelId=" +
        AccessLevelId +
        "&attachmentTypeId=" +
        AttachmentTypeId,
      requestOptions
    );
    let data = await response.json();
    var inputs = document.querySelectorAll(".ShipmentsAttachmentCheckBox");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    setisLoadingSubmit(false);
    getAttachmentData();
    setAttachmentData([]);
  }

  async function getAttachmentData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/shipments/getsendemail/" + id, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        setAttachementList(resultobj.Attachments);
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="xl"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Shipment Send
        </Modal.Title>
        <IconButton aria-label="Close" onClick={onHide}>
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <span>Shipment Documents is Sending...</span>}
        <Formik>
          <Form className="form form-label-right Send-shipment">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-3">To: </p>
                  {EmailAddressList && (
                    <CreatableSelect
                      isMulti
                      name="To"
                      id="To"
                      value={ToDataDefeault}
                      options={EmailAddressList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        var obj = [];
                        var objDefeault = [];
                        if (e) {
                          e.map((item, index) => {
                            obj.push({ Email: item.value, Name: item.value });
                            objDefeault.push({
                              value: item.value,
                              label: item.value,
                            });
                          });
                        }
                        setToDataDefeault(objDefeault);
                        setToData(obj);
                      }}
                    />
                  )}

                  <CheckboxLarge
                    Size="12px"
                    name="includCC"
                    text="Include me on CC"
                    onChange={(event) => {
                      setincludCCData(event.target.checked);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Subject: </p>
                  <Field
                    name="Subject"
                    component={Input}
                    placeholder="Subject"
                    value={SubjectData}
                    onChange={(event) => {
                      setSubjectData(event.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Body: </p>

                  <CKEditor
                    name="Body"
                    Id="Body"
                    label="Body"
                    editor={ClassicEditor}
                    config={{
                      removePlugins: [
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                    data={BodyData}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setBodyData(data);
                    }}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12 mt-5">
                      <h4>Attachments</h4>
                    </div>
                    <div className="col-lg-12 row mt-5">
                      <div className="col-lg-3">
                        <CheckboxLarge
                          Size="12px"
                          name="includBol"
                          text="Include BOL"
                          onChange={(event) => {
                            setincludBol(event.target.checked);
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <CheckboxLarge
                          Size="12px"
                          name="OutboundDispatch"
                          checked={includOutboundDispatch}
                          text="Include Rate Confirmation"
                          onChange={(event) => {
                            setincludOutboundDispatch(event.target.checked);
                          }}
                        />
                      </div>
                      <div className="col-lg-3">
                        <CheckboxLarge
                          Size="12px"
                          name="ShipmentManifest"
                          text="Include Shipment Manifest"
                          onChange={(event) => {
                            setincludShipmentManifest(event.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    {AttachementList?.map((item, index) => {
                      return (
                        <div className="col-lg-12 row mt-5">
                          <div className="col-lg-12 row ml-0">
                            <CheckboxLarge
                              Size="12px"
                              name={`xxxxx${index}`}
                              value={item.Id}
                              className="ShipmentsAttachmentCheckBox"
                              onChange={(event) => {
                                var obj = AttachmentData;
                                obj.push(event.target.value);
                                setAttachmentData(obj);
                              }}
                            />
                            <a
                              className="mt-3"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`${item.Path}`}
                            >
                              {item.Name}
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mt-10">
                    <div className="col-lg-4">
                      <p>Add Attachment</p>
                      <input
                        id="shipmentAttachmentList"
                        type="file"
                        name="shipmentAttachmentList"
                        component={Input}
                        placeholder="Add Attachment"
                        label="Add Attachment"
                      />
                    </div>
                    <div className="col-lg-3">
                      <p>Attachment Type</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAttachmentTypeIdList"
                        id="shipmentAttachmentTypeIdList"
                        label="Attachment Type"
                      >
                        {AttachmentTypes.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <p>Access Level</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAccessLevelIdList"
                        id="shipmentAccessLevelIdList"
                        label="Access Level"
                      >
                        {AccessLevels.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-2 mt-8">
                      <button
                        type="button"
                        onClick={async () =>
                          await SaveShipementAattachement(id)
                        }
                        disabled={isLoadingSubmit}
                        className="btn btn-primary btn-elevate"
                      >
                        Save Attachment
                        {isLoadingSubmit && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteProduct}
            disabled={isLoadingSend}
            className="btn btn-delete btn-primary"
          >
            Send Email
            {isLoadingSend && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
