//import carTableMock from "./carTableMock";
import MockUtils from "./mock.utils";
import { Notification } from "../../Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

export default function mockFindOrders(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

async function DriveInList() {
  return await fetch(window.$apiurl + "/processes/DriveIn", {
    method: "get",
    withCredentials: true,

    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Notification("error", "err");
      return false;
    });
}

async function DriveOutList() {
  return await fetch(window.$apiurl + "/processes/DriveOut", {
    method: "get",
    withCredentials: true,

    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Notification("error", "err");
      return false;
    });
}

  async function GetOrders() {
    return await fetch(window.$apiurl + "/processes", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }


 async function PostOrder(someData) {
    return fetch(window.$apiurl + "/processes", {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PutOrder(someData, id) {
    // let userDate = someData["customer"];
    // console.log("userDate")
    // console.log(userDate);
    return fetch(window.$apiurl + "/processes/" + id, {
      method: "put",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function DeleteOrder(id) {
    return await fetch(window.$apiurl + "/processes/" + id, {
      method: "delete",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function GetOrder(id) {
    return await fetch(window.$apiurl + "/processes/" + id, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //---------------------------------------------------------------------

  mock.onGet("api/synchronizeProcess/GetAllApi").reply(async () => {
    var customers = {};
    customers["Processes"] = await GetOrders();
    customers["DriveInList"] = await DriveInList();
    customers["DriveOutList"] = await DriveOutList();

    // customers["skuList"] = await GetSkuList();
    // customers["primaryUnitList"] = await GetPrimaryUnitList();
    return [200, customers];
  });

  // mock.onPost("api/synchronizeProcess").reply(async ({ data }) => {
  //   const { customer } = JSON.parse(data);
  //   const {
  //     Id = 0,
      
  //     Name = ""
  //   } = customer;

  //   const id = generateProductId();
  //   const newCustomer = {
  //     id,
  //     Name    };
  //   var postOrder = await PostOrder(newCustomer);
  //   postOrder.id=postOrder.Id;
  //   return [200, { customer: postOrder }];
  // });

  mock.onPost("api/synchronizeProcess").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const {  Name = ""
     } = product;

    //const Id = generateProductId();
    const newProduct = {
     
      Name,
      // DriveIn,
    // DriveOut
    };
    var postOrder = await PostOrder(newProduct);

 //   postOrder.id = postOrder.Id;
    return [200, { product: newProduct }];
  });
 


  mock.onGet(/api\/synchronizeProcess\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/synchronizeProcess\/(\d+)/)[1];
    const product = await GetOrder(id);
    if (!product) {
      return [400];
    }

    return [200, product];
  });
  
  mock.onPut(/api\/synchronizeProcess\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/synchronizeProcess\/(\d+)/)[1];
    const DriveIn = config.url.match(/api\/synchronizeProcess\/(\d+)/)[6];
    const { product } = JSON.parse(config.data);
    var postOrder = await PutOrder(product, id);
    const newDriveIn = +DriveIn;
    
    const index = carTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400, {newDriveIn}];
    }

    carTableMock[index] = { ...product };
    return [200];
  });
  
  // mock.onPost(/api\/synchronizeProcess\/\d+/).reply(async (config) => {
  //   const id = config.url.match(/api\/synchronizeProcess\/(\d+)/)[1];
  //   const { product } = JSON.parse(config.data);
  //   var postOrder = await PostOrder(product);
  //   const index = carTableMock.findIndex((el) => el.id === +id);
  //   if (!index) {
  //     return [400];
  //   }

  //   carTableMock[index] = { ...product };
  //   return [200];
  // });

  mock.onDelete(/api\/synchronizeProcess\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/synchronizeProcess\/(\d+)/)[1];
    // const index = carTableMock.findIndex((el) => el.id === +id);
    // if (index === -1) return [400];
    var deleteUser = await DeleteOrder(id);
    if (!deleteUser) return [400];
    // carTableMock.splice(index, 1);
    return [200];
  });
}

function generateProductId() {
  const ids = carTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
