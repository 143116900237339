import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Form, Card, Col, Alert } from "react-bootstrap";
import { ExcelRenderer } from "react-excel-renderer";
import axios from "axios";
import { Notification } from "../../modules/Notification";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  row: {
    height: 50,
  },
  rowBody: {
    height: 50,
    fontWeight: "bold",
  },
}));

export function Importlocations() {
  const classes = useStyles();
  const [warehousesList, setWarehousesList] = useState([]);
  const [rowsStat, setRowsStat] = useState([]);
  const [rowsStatBool, setRowsStatBool] = useState(false);
  const [rowsEmpty, setRowsEmpty] = useState(true);

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/Warehouses/GetWarehouses");
      setWarehousesList(result.data["GetWarehouses"]);
    } catch (e) {}
  };

  function createData(
    empty,
    LocationId,
    Length,
    Width,
    Height,
    MaxWeight,
    Temperature,
    Description,
    WarehouseId,
    LocationTypeId,
    IsActive
  ) {
    return {
      empty,
      LocationId,
      Length,
      Width,
      Height,
      MaxWeight,
      Temperature,
      Description,
      WarehouseId,
      LocationTypeId,
      IsActive,
    };
  }

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setRowsEmpty(true);
        return false;
      } else {
        var a = resp.rows[0].indexOf("Location");
        var b = resp.rows[0].indexOf("Length");
        var c = resp.rows[0].indexOf("Width");
        var d = resp.rows[0].indexOf("Height");
        var e = resp.rows[0].indexOf("Max Weight");
        var f = resp.rows[0].indexOf("Temperature");
        var j = resp.rows[0].indexOf("Description");

        const warehouseId = document.getElementById("warehouseId").value;

        if (
          a === -1 ||
          b === -1 ||
          c === -1 ||
          d === -1 ||
          e === -1 ||
          f === -1 ||
          j === -1
        ) {
          setRowsEmpty(true);
          return false;
        }

        let rows = [];
        resp.rows.slice(1, resp.rows.length).forEach((element, index) => {
          var empty = 1;
          if (
            element[a] === undefined ||
            element[b] === undefined ||
            element[c] === undefined ||
            element[d] === undefined ||
            element[e] === undefined ||
            element[f] === undefined ||
            element[j] === undefined
          )
            empty = 0;
          rows.push(
            createData(
              empty,
              element[a],
              element[b],
              element[c],
              element[d],
              element[e],
              element[f],
              element[j],
              warehouseId,
              1,
              true
            )
          );
        });
        setRowsStat(rows);
        setRowsEmpty(false);
      }
    });
  };

  const fileHandlerOnclick = () => {
    if (rowsEmpty) {
      Notification("error", "error");
      setRowsStatBool(false);
      return false;
    }
    Notification("success", "Import is success");
    setRowsStatBool(true);
  };

  const fileUploadeOnclick = () => {
    if (!rowsEmpty) {
      axios.post("api/Locations/import", { data: rowsStat });
    }
  };

  return (
    <>
      <Helmet>
        <title>eMPower | Import Locations</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Card>
        <Card.Body>
          <Form>
            <Form.Row>
              <div className="col-6">
                <label className="form-label" for="formGridPassword">
                  warehouse{" "}
                </label>
                <select
                  className="form-control form-control-solid is-valid-select"
                  name="warehouseId"
                  id="warehouseId"
                >
                  {warehousesList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.Name}
                    </option>
                  ))}
                </select>
              </div>

              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Import </Form.Label>
                <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
                  <Form.Control
                    type="file"
                    placeholder="Password"
                    onChange={fileHandler.bind(this)}
                  />
                </Card>
              </Form.Group>
            </Form.Row>
            {rowsStatBool && (
              <Form.Row className="mt-5 mb-5 pb-5">
                <Paper className={classes.paper}>
                  <Table className={classes.table} size="small">
                    <TableHead className="bg-dark">
                      <TableRow className={classes.row}>
                        <TableCell className="text-white">isValid</TableCell>
                        <TableCell className="text-white">Location</TableCell>
                        <TableCell className="text-white">Length</TableCell>
                        <TableCell className="text-white">Width</TableCell>
                        <TableCell className="text-white">Height</TableCell>
                        <TableCell className="text-white">Max Weight</TableCell>
                        <TableCell className="text-white">
                          Temperature
                        </TableCell>
                        <TableCell className="text-white">
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsStat.map((row) => (
                        <TableRow className={classes.rowBody} key={row.name}>
                          <TableCell>
                            {row.empty === 1 && (
                              <i className="fa fa-check text-success icon-xxl"></i>
                            )}
                            {row.empty === 0 && (
                              <i className="fa fa-times-circle text-danger icon-xxl"></i>
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.rowBody}
                            component="th"
                            scope="row"
                          >
                            {row.LocationId}
                          </TableCell>
                          <TableCell>{row.Length}</TableCell>
                          <TableCell>{row.Width}</TableCell>
                          <TableCell>{row.Height}</TableCell>
                          <TableCell>{row.MaxWeight}</TableCell>
                          <TableCell>{row.Temperature}</TableCell>
                          <TableCell>{row.Description}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Form.Row>
            )}{" "}
            <Form.Row>
              <Alert variant="info " className="w-50">
                Use the 'Browse...' button to select a location import file
                <p></p>
                Then press the 'Import Locations' button to read the file.
              </Alert>
              <Alert className="w-50"></Alert>
              <Button
                as="input"
                type="submit"
                value="Import Loc's"
                onClick={() => {
                  fileHandlerOnclick();
                }}
              />
              {/* <a className="ml-5 mt-3" href={LocationsFile} download>
                Excel file example
              </a> */}
            </Form.Row>
            <br />
            <br />
            <Form.Row>
              <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label className="w-100">
                  Locations To Be Created{" "}
                </Form.Label>
                <Alert variant="info " className="w-50">
                  After previewing the imported data for accuracy
                  <p></p>
                  Press the 'Create Locations' button to create the new
                  Locations.
                </Alert>
                <Button
                  as="input"
                  type="submit"
                  value="Create Loc's"
                  onClick={() => {
                    fileUploadeOnclick();
                  }}
                />
              </Form.Group>
            </Form.Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}
