import MockUtils from "./mock.utils";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let specificationTableMock;

export default function mockOrdercharges(mock) {
  async function PostOrderCharges(id, someData) {
    return fetch(window.$apiurl + "/Receipts/Charges/" + id, {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetOrderCharges(orderId) {
    return await fetch(
      window.$apiurl + "/Receipts/Charges/" + orderId + "/" + false,
      {
        method: "get",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return ChangeId(res);
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetOrderCharge(orderId) {
    return await fetch(window.$apiurl + "/Receipts/Charges/Charge/" + orderId, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function PutOrderCharge(someData, itemId) {
    return fetch(window.$apiurl + "/Receipts/Charges/" + itemId, {
      method: "put",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteOrderCharge(orderId, itemId) {
    return await fetch(window.$apiurl + "/Receipts/Charges/" + orderId, {
      method: "delete",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  async function PostAutoCharge(postdata) {
    return fetch(
      window.$apiurl + "/Receipts/AutoCharges/" + postdata + "/" + false,
      {
        method: "get",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //-------------------------mock---------------------------
  mock.onPost("api/specificationsOrder").reply(async ({ data }) => {
    const { specification } = JSON.parse(data);
    const {
      Id = 0,
      ChargeCategoryId,
      chargeLabel,
      TypeChargePerUnit,
      ChargePerUnit,
      NumberOfUnits,
      GeneralLedgerAccount,
      ExternalItemIdentifier,
      ExternalAccountReceivable,
      ExternalItemDescription,
      IsReceipt = false,
    } = specification;
    const id = generateSpecificationId();
    const newSpecification = {
      id,
      Id,
      ChargeCategoryId,
      chargeLabel,
      TypeChargePerUnit,
      ChargePerUnit,
      NumberOfUnits,
      GeneralLedgerAccount,
      ExternalItemIdentifier,
      ExternalAccountReceivable,
      ExternalItemDescription,
      IsReceipt,
    };
    const OrderId = window.location.pathname.split("/")[2];
    const respense = await PostOrderCharges(OrderId, newSpecification);
    specificationTableMock.push(newSpecification);
    return [200, { specification: newSpecification }];
  });

  mock.onPost(/api\/specificationsOrderfind\/\d+/).reply(async (config) => {
    const urls = config.url.split("/");
    const id = urls[2];
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const obj = await GetOrderCharges(id);
    specificationTableMock = obj;
    // const specifications = specificationTableMock.filter(
    //   el => el.carId === +id
    // );
    const result = [];
    // specifications.forEach(el => result.push(wrapSpecification(el)));
    delete queryParams.filter.ReLoadTable;
    const filterdSpecifications = mockUtils.baseFilter(
      specificationTableMock,
      queryParams
    );

    return [200, filterdSpecifications];
  });

  mock
    .onPost("api/specificationsOrder/deleteSpecifications")
    .reply((config) => {
      const { ids } = JSON.parse(config.data);
      ids.forEach((id) => {
        const index = specificationTableMock.findIndex((el) => el.id === id);
        if (index > -1) {
          specificationTableMock.splice(index, 1);
        }
      });
      return [200];
    });

  mock.onGet(/api\/specificationsOrder\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/specificationsOrder\/(\d+)/)[1];
    const remark = await GetOrderCharge(id);
    remark.Id = id;
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/specificationsOrder\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/specificationsOrder\/(\d+)/)[1];
    const { specification } = JSON.parse(config.data);
    specification.IsReceipt = false;
    var postOrder = await PutOrderCharge(specification, id);
    const index = specificationTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    specificationTableMock[index] = { ...specification };
    return [200];
  });

  mock.onDelete(/api\/specificationsOrder\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/specificationsOrder\/(\d+)/)[1];
    const index = specificationTableMock.findIndex((el) => el.id === +id);
    var deleteUser = await DeleteOrderCharge(id);

    specificationTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });

  mock.onPost("api/specificationsOrder/PostAutoCharge").reply(async (data) => {
    const OrderId = window.location.pathname.split("/")[2];
    const obj = await PostAutoCharge(OrderId);
    document.getElementById("ReLoadTableId").click();
    return [200, obj];
  });
}

function generateSpecificationId() {
  return 1;
}

function wrapSpecification(specification) {
  const newSpec = { ...specification };
  newSpec.name = getSpecNameById(newSpec.specId);
  return newSpec;
}

function getSpecNameById(specId) {
  const specs = [
    "Seats",
    "Fuel Type",
    "Stock",
    "Door count",
    "Engine",
    "Transmission",
    "Drivetrain",
    "Combined MPG",
    "Warranty",
    "Wheels",
  ];
  return specs[specId];
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
