import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import {
  Input,
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import { makeStyles } from "@material-ui/core/styles";
import { Notification } from "../../../../Notification";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
}));
function createData(name, lenght, widht, Lenght, width) {
  return { name, lenght, width, Lenght, width };
}

function SetValuefunction(id, value) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
    }, 500);
}
const RequiredHtml = () => {
  return (
    <i
      class="fa fa-star-of-life text-danger icon-l ml-2"
      style={{ fontSize: "8px", position: "absolute" }}
    ></i>
  );
};
const rows = [createData("US/IMPERIAL"), createData("METRIC")];
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    name: "",
    age: "",
    multiline: "Controlled",
    currency: "EUR",
  });
  const [value, setValue] = React.useState("female");
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  useEffect(() => {
    var Description = customer.Description || false;
    if (Description) {
      SetValuefunction("DescriptionId", Description);
    }
    if (customer.NeedBill === true) {
      document.getElementById("NeedBillId").checked = true;
    }
  }, [customer]);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        onSubmit={(values) => {
          if (values.Description === "") {
            Notification("error", "fields required is empty");
            return false;
          }
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="Code"
                      component={Input}
                      placeholder="CODE"
                      label="CODE"
                      required={true}
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="SCAC"
                      component={Input}
                      placeholder="SCAC"
                      label="SCAC"
                    />
                  </div>
                  <div className="col-lg-4 align-self-center">
                    <CheckboxLarge
                      label=" "
                      text="NEEDS BILL OF LADING"
                      name="NeedBill"
                      id="NeedBillId"
                      onChange={(event) => {
                        setFieldValue("NeedBill", event.target.checked);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 mt-5">
                    <label>
                      DESCRIPTION <RequiredHtml />
                    </label>
                    <textarea
                      class="form-control"
                      id="DescriptionId"
                      rows="3"
                      name="Description"
                      onChange={(event) => {
                        setFieldValue("Description", event.target.value);
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
