import React, { useEffect, useState } from 'react'
import { Button, Form, Card, Row } from "react-bootstrap";
import { Notification } from "../../modules/Notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import {
    CreatableSelect2
} from "../../../_metronic/_partials/controls";

import axios from "axios";
export function ManagePurchaseOrders() {
    const [purchaseOrdersList, setPurchaseOrdersList] = useState([]);
    const [id, setId] = useState(0);
    const [Number, SetNumber] = useState("");

    useEffect(() => {
        fetchCustomersAsync();
    }, []);

    const fetchCustomersAsync = async () => {
        setPurchaseOrdersList([]);
        try {
            const result = await axios.get("api/purchaseorders");
            if (result.status === 200) {
                setPurchaseOrdersList(result.data);
            }
        } catch (e) { }
    };

    const SelectOnclick = () => {
        setId(0);
        SetNumber("");
        let purchaseOrderId = document.getElementById("purchaseIdById")?.value;
        if (purchaseOrderId) {
            setId(purchaseOrderId);
        }
        else {
            purchaseOrderId = document.getElementById("purchaseIdByNo")?.value;
            if (purchaseOrderId) {
                setId(purchaseOrderId);
            }
            else {
                Notification("error", "Please select a purchase order");
            }
        }
        if (purchaseOrderId) {
            let number;
            purchaseOrdersList.map(function (obj) {
                if (obj.id === purchaseOrderId) { number = obj.Number; };
                if (number !== undefined)
                    SetNumber(number);
            });
        }
    }

    const ClosePurchase = async () => {
        if (id !== 0) {
            const result = await axios.delete(`api/purhcaseorders/${id}`);
            if (result.status === 200) {
                setId(0);
                SetNumber("");
                fetchCustomersAsync();
                Notification("success", "Purchase order closed successfully.");
            }
        }
    }

    return (
        <Card>
            <Card.Body>
                <h6 className="mb-4">SELECT PURCHASE ORDER</h6>
                <Row>
                    <div className="col-lg-5">
                        <Form.Label>Purchase Order ID</Form.Label>
                        {/* <select className="form-control" id="purchaseIdById">
                            <option key="" value="" >Choose PO ID</option>
                            {purchaseOrdersList.map((item, index) => (
                                <option key={index} value={item.id}>{item.id} - {item.Customer}</option>
                            ))}
                        </select>*/}

                        <CreatableSelect2
                            name="purchaseIdById"
                            options={purchaseOrdersList.map((x) => {
                                return {
                                    value: x?.Id,
                                    label: x?.Customer,
                                };
                            })}

                        />
                        {purchaseOrdersList.length === 0 && <LinearProgress color="secondary" />}
                    </div>
                    <div className="col-lg-1 mt-10 text-center">
                        <i>- or -</i>
                    </div>
                    <div className="col-lg-5">
                        <Form.Label>Purchase Order Number</Form.Label>

                        <select className="form-control" id="purchaseIdByNo">
                            <option key="" value="" >Choose PO Number</option>
                            {purchaseOrdersList.map((item, index) => (
                                <option key={index} value={item.id}>{item.Number} - {item.Customer}</option>
                            ))}
                        </select>
                        {/*
                        <CreatableSelect2
                            name="purchaseIdByNo"
                            options={purchaseOrdersList.map((x) => {
                                return {
                                    value: x?.Id,
                                    label: x.Number + " - " + x.Customer,
                                };
                            })}

                        />
                        */}
                        {purchaseOrdersList.length === 0 && <LinearProgress color="secondary" />}
                    </div>
                    <div className="col-lg-1 mt-7">
                        <Button variant="contained" color="secondary" className="btn btn-success btn-shadow-hover mr-2"
                            onClick={() => {
                                SelectOnclick();
                            }}>
                            Select
                            </Button>
                    </div>
                </Row>
                {id !== 0 &&
                    <Row className="mt-5">
                        <div className="col-lg-6">
                            <h6 className="my-4">UPDATE PURCHASE ORDER</h6>
                            <h6><a href={`/PurchaseOrders/${id}/Edit`}>Edit Purchase Order</a> - #{Number} ({id})</h6>
                        </div>
                        <div className="col-lg-6"><h6 className="my-4">CLOSE PURCHASE ORDER</h6>
                            <Button variant="contained" className="btn btn-danger btn-shadow-hover mr-2"
                                onClick={() => { ClosePurchase() }}>
                                Close P.O
                            </Button>
                        </div>
                        <div className="col-lg-12">
                            <Row>

                                <div className="col-lg-6">
                                    <h5 className="my-4 col-lg-6">PRINT</h5>
                                    <CreatableSelect2
                                        name="PrintPurchaseOrderId"

                                        options={[{
                                            value: "",
                                            label: "Print Purchase Order",
                                        }]}

                                    />
                                </div>
                                <div className="col-lg-6">
                                    <h5 className="my-4 col-lg-6">PRINT Progress</h5>
                                    <CreatableSelect2
                                        name="PrintPurchaseOrderId"

                                        options={[{
                                            value: "",
                                            label: "Print Progress Report",
                                        }]}

                                    />
                                </div>
                            </Row>
                        </div>
                    </Row>
                }
            </Card.Body>
        </Card>
    )
}
