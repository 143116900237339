import MockUtils from "./mock.utils3";
import { Notification } from "../../Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockRegions(mock) {
  //Get Movable Units
  async function GetMovableUnitss(params) {
    return await fetch(window.$apiurl + "/rating/region" + params, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Get {id}
  async function GetMovableUnitsById(id) {
    return await fetch(window.$apiurl + "/rating/region/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  //Post Movable Units
  async function PostMovableUnit(someData) {
    return fetch(window.$apiurl + "/rating/region", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Delete
  async function DeleteMovableUnit(id) {
    return await fetch(window.$apiurl + "/rating/matrix/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) {
          return Promise.all([statusCode, ""]);
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          Notification("success", "success");
          return true;
        } else if (res[0] === 400) {
          Notification("error", res[1].Message);
          return false;
        }
      })
      .catch((err) => {
        Notification("error", "error request");
        return false;
      });
  }
  //Put
  async function PutMovableUnit(someData, id) {
    someData.ServiceType = "shipment";
    return fetch(window.$apiurl + "/rating/region/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }

  mock.onPost("api/Regions").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Name = "",
      TypeId = null,
      Description = "",
      IsActive = true,
      Items = [],
    } = customer;
    const newCustomer = {
      Name,
      TypeId,
      Description,
      IsActive,
      Items,
    };
    var postMovableUnit = await PostMovableUnit(newCustomer);
    if (postMovableUnit.Id !== undefined) {
      Notification("success", "PerMile has been added successfully");
      postMovableUnit.id = postMovableUnit.Id;
      customerTableMock.push(newCustomer);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        {
          customer: newCustomer,
          message: postMovableUnit.Message,
          success: true,
        },
      ];
    } else {
      if (postMovableUnit.Message) {
        window.$error400Gl = 400;
        Notification("error", postMovableUnit.Message);
        return [
          400,
          {
            customer: newCustomer,
            message: postMovableUnit.Message,
            success: false,
          },
        ];
      } else {
        Notification("success", "PerMile has been added successfully");
        document.getElementById("ReLoadTableId").click();
        return [
          200,
          {
            customer: newCustomer,
            message: postMovableUnit.Message,
            success: true,
          },
        ];
      }
    }
  });

  mock.onPost("api/Regions/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const params = `?isActive=${queryParams?.filter?.IsActive ?? ""}&TypeId=${
      queryParams?.filter?.TypeId
    }&pageNumber=${queryParams.pageNumber}&pageSize=${
      queryParams.pageSize
    }&search=&sortField=${queryParams.sortField}&sortOrder=${
      queryParams.sortOrder
    }&search=${queryParams?.filter?.searchText}`;
    let dataObj  = await GetMovableUnitss(params);
    customerTableMock = ChangeId(dataObj?.Matrices);
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.searchText;
    delete queryParams.filter.IsActive;
    delete queryParams.filter.TypeId;
    queryParams.TotalItems = dataObj.Pagination.TotalItemCount;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/Regions/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Regions/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });
  mock.onGet(/api\/Regions\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Regions\/(\d+)/)[1];
    var resultById = await GetMovableUnitsById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/Regions\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Regions\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutMovableUnit(customer, id);
    if (putObj.Message) {
      Notification("error", putObj.Message);
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/Regions\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Regions\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteMovableUnit(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
