/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";

import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Link } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { useTranslation } from "react-i18next";
import { CheckUrlTMS } from "../../../../app/SpecificTabs";
const adapter = new LocalStorage("db");
const db = low(adapter);

/* const urlTMS = [
  "/TransportManagementSystems",
  "/Reporting/Report",
  "/TransportManagementSystems/CapacityBoard",
  "/TMS/TrackingBoard",
  "/ShipmentsManagement",
  "/TMS/TrackingBoard",
  "/TransportManagementSystems/SoDetail",
]; */

export function SubHeader({TablsOptionsList}) {
  const { t } = useTranslation();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [Today, setToday] = useState([]);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  const CheckIsExist = (url) => {
    if (window.TabsIsExist.includes(url)) {
      return true;
    } else return false;
  };

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
    subheader.setTitle(aside.title || header.title);
    // eslint-disable-next-line
  }, [location.pathname]);
  const [state, setstate] = useState(0);
  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date();
    const date = d.getDate();
    const data =
      monthNames[d.getMonth()] +
      " " +
      date +
      " " +
      d.getFullYear() +
      " " +
      d.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    setToday(data);

    setTimeout(() => {
      setstate((x) => x + 1);
    }, 15000);
  }, [subheader, state]);

  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}

        {/* Toolbar */}
        <div
          className={`${
            CheckUrlTMS()
              ? `ml-md-auto pl-4 ${
                  !db.read().getState().User.IsCustomer ? " mr-xl-auto" : ""
                }`
              : ""
          } d-flex align-items-center flex-wrap`}
        >
          <>
            {!db.read().getState().User.IsCustomer &&
              TablsOptionsList.map((item, index) => {
                return (
                  <Link
                    to={JSON.parse(item.Options).url}
                    className="btn btn-primary btn-sm font-weight-bold mr-2 mb-4 mb-md-4 mb-xl-0 mb-lg-0 d-none d-xl-block"
                    onClick={(e) => {
                      var url = JSON.parse(item.Options).url;
                      window.CreateTabe = url;
                      document.getElementById("CreateTabe").click();
                      if (CheckIsExist(url)) {
                        e.preventDefault();
                        return false;
                      }
                    }}
                  >
                    <span
                      className="text-white font-weight-bold"
                      id="kt_dashboard_daterangepicker_title"
                    >
                      {item.Name}
                    </span>
                  </Link>
                );
              })}
          </>
          {/* <>
              <Link
                to={`${
                  db.read().getState().User.IsCustomer === true &&
                  db.read().getState().User.Customers[0]?.Name === "Lulus"
                    ? "/Dashboards/LulusDashboard"
                    : "/CustomerDashboard/GlobalDashboard"
                }`}
                className="btn btn-light btn-sm font-weight-bold mr-2 mb-5"
                id="kt_dashboard_daterangepicker"
              >
                <span
                  className="text-primary font-weight-bold"
                  id="kt_dashboard_daterangepicker_title"
                >
                  {t("Dashboard")}
                </span>
              </Link>
              <Link
                to="/HomePage"
                className="btn btn-light btn-sm font-weight-bold mr-2 mb-5"
                id="kt_dashboard_daterangepicker"
              >
                <span
                  className="text-primary font-weight-bold"
                  id="kt_dashboard_daterangepicker_title"
                >
                  {t("Home Page")}
                </span>
              </Link>
            </> */}

          <a
            href={() => false}
            className="btn btn-light btn-sm font-weight-bold mr-2"
            id="kt_dashboard_daterangepicker"
            data-toggle="tooltip"
            title="Select dashboard daterange"
            data-placement="left"
          >
            <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              {t("Today")}
            </span>
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {Today}
            </span>
          </a>
          <QuickActions />
          <Link
            to="/HomePage"
            className="btn btn-light-primary btn-sm font-weight-bold mr-2 mb-4 mb-md-4 mb-xl-0 mb-lg-0 d-none d-xl-block"
          >
            <i className="fas fa-home"></i> Home
          </Link>
        </div>
      </div>
    </div>
  );
}
