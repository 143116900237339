import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let remarkTableMock;
export default function mockReceiptItems(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", "Error Occured");
      return false;
    }
  }
  //Post Receipt Line Item
  async function PostReceiptLineItem(id, someData) {
    return fetch(window.$apiurl + "/receipts/Item/" + id, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  //Get All Receipts Items
  async function GetReceiptItems(id) {
    return await fetch(window.$apiurl + "/receipts/items/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //Delete Receipt Item
  async function DeleteReceiptItem(id) {
    return await fetch(window.$apiurl + "/receipts/Item/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function PutReceiptItem(someData, itemId) {
    return fetch(window.$apiurl + "/receipts/Item/" + itemId, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  async function GetReceiptLineItem(id) {
    return await fetch(window.$apiurl + "/receipts/Item/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  mock.onPost("api/ReceiptItems").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const {
      ItemId = 0,
      QualifierId = null,
      Quantity = 0,
      Weight = 0,
      OnHold = false,
      SupplierId = 0,
      ReasonForHold = "",
      ItemCustomFields = [],
    } = remark;
    const id = generateRemarkId();
    const newReceiptItem = {
      id,
      ItemId,
      QualifierId,
      Quantity,
      Weight,
      OnHold,
      SupplierId,
      ReasonForHold,
      ItemCustomFields,
    };
    var postReceiptLine = await PostReceiptLineItem(
      remark.ReceiptId,
      newReceiptItem
    );
    //Manage Data Result
    if (postReceiptLine.Id != undefined) {
      if (postReceiptLine.Message) {
        if (postReceiptLine.Code === "200") {
          Notification("success", postReceiptLine.Message);
        } else {
          Notification("error", postReceiptLine.Message);
        }
      } else {
        Notification(
          "success",
          "Receipt Line Item has been added successfully"
        );
      }
      postReceiptLine.id = postReceiptLine.Id;
      remarkTableMock.push(newReceiptItem);
      return [
        200,
        {
          remark: newReceiptItem,
          message: postReceiptLine.Message,
          success: true,
        },
      ];
    } else {
      if (postReceiptLine.Message) {
        Notification("error", postReceiptLine.Message);
        return [400, { message: postReceiptLine.Message, success: false }];
      } else {
        Notification("error", "Error occured");
        return [400, { message: postReceiptLine.Message, success: false }];
      }
    }
  });

  //mock on Post
  mock.onPost(/api\/ReceiptItemsfind\/\d+/).reply(async (config) => {
    const mockUtils = new MockUtils();
    const urls = config.url.split("/");
    const id = urls[2];
    let totalWeight = 0;
    const { queryParams } = JSON.parse(config.data);
    remarkTableMock = await GetReceiptItems(id);
    for (var i = 0; i < remarkTableMock.length; i++) {
      totalWeight += remarkTableMock[i].Weight;
    }
    delete queryParams.filter.ReLoadTable;
    const filterdRemarks = mockUtils.baseFilter(remarkTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/ReceiptItems/deleteReceipts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/ReceiptItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ReceiptItems\/(\d+)/)[1];
    const remark = await GetReceiptLineItem(id);
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/ReceiptItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ReceiptItems\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    var putReceiptItem = await PutReceiptItem(remark, id);
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  mock.onDelete(/api\/ReceiptItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ReceiptItems\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.Id === +id);
    var deleteObj = await DeleteReceiptItem(id);
    if (!deleteObj) {
      return [400];
    }
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function generateRemarkId() {
  const ids = remarkTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
