import React from "react";
export function DateColumnFormatter(cellContent, row) {
  console.log("row");
  console.log(row);
  var elapsed = new Date(row.ExcpectedDate).toLocaleString();
  return (
    <>
     <span>{elapsed}</span>
    </>
  );
}
