import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState, Fragment } from "react";
import { Card, Modal } from "react-bootstrap";
import Select from "react-select";
import { CustomerEditForm } from "../../../../app/modules/eMPower/pages/Notes/customer-edit-dialog/CustomerEditForm";
import { Notification } from "../../../../app/modules/Notification";
import { momentDate } from "../../../_helpers/ATHelpers";
import SelectPagination from "../../widgets/Select/SelectPagination";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function NotesComponent({
  id,
  get,
  post,
  put,
  Categories,
  isBilling,
  IsExceptionLulus,
  isLulusDashboard,
  exceptionId,
  isViewModel,
  hideExistingNotes,
  customViewModel,
  accessLevels,
  attachmentTypes,
  list
}) {
  const [NotesList, setNotesList] = useState([]);
  const [CategorysList, setCategorysList] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [NewText, setNewText] = useState("");
  const [NoteShow, setNoteShow] = useState(false);
  const [NoteShow2, setNoteShow2] = useState(false);
  const [noteToggle, setNoteToggle] = useState(false);

  useEffect(() => {
    if (get && id) {
      async function fetchMyAPI() {
        if (isLulusDashboard && exceptionId) {
          getNots(exceptionId);
        } else {
          getNots();
        }
        if(exceptionId) {
          GetExceptionsLists();
        }
      }
      fetchMyAPI();
    }
  }, [get, id, exceptionId]);

  useEffect(() => {
    setCategorysList(Categories);
  }, [Categories]);

  async function GetNotes() {
    return await fetch(`${window.$apiurl}${get}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetViewModelList() {
    return await fetch(`${window.$apiurl}/notes/GetViewModel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Exception Get List API
  const [exceptionsLists, setExceptionsList] = useState([]);
  async function GetExceptionsLists() {
    return await fetch(
      `${window.$apiurl}/sku/exceptions?id=${id}&isActive=null`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        const currentList = res[1];
        const list = [];
        currentList.map((item) => {
          list.push({
            value: item.RequestId,
            label: `Exception #: ${item.RequestId}`,
          });
          return item;
        });
        setExceptionsList(list);
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostNote(bodytext, cat, accessLevelId) {
    return await fetch(`${window.$apiurl}${post}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ExceptionId:
          exceptionId !== undefined ? exceptionId : selectedException.value,
        Body: bodytext,
        Title: document.getElementById("TitleNote")?.value,
        CategoryId: cat || (IsExceptionLulus ? 4 : ""),
        AccessLevelId: accessLevelId || (IsExceptionLulus ? 4 : ""),
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note has been saved");
      })
      .catch((error) => {
        Notification("success", "Note has been saved");
      });
  }

  function CardFunction({ x, index, setShow, setNoteId }) {
    const [Showing, setShowing] = useState(false);
    const [Text, setText] = useState("");
    const [Category, setCategory] = useState("");
    const [titleBtnDeletePut, setTitleBtnDeletePut] = useState("Delete");

    async function PutNote(id, bodytext, CategoryId) {
      return await fetch(`${window.$apiurl}${put}${id}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: bodytext, CategoryId: CategoryId }),
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then(async (res) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        })
        .catch((error) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        });
    }

    return (
      <>
        <Modal
          show={NoteShow}
          aria-labelledby="example-modal-sizes-title-lg"
          onHide={() => setNoteShow(false)}
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Assign To Containers
            </Modal.Title>
            <IconButton
              aria-label="Close"
              onClick={() => {
                setNoteShow(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className="row">
            <div className="p-3 w-100">
              Assign this Note in Multiple Containers
              <SelectPagination
                isSearchable={true}
                onCreateOption={undefined}
                isMulti={true}
                placeholder="Containers"
                createOptionPosition="first"
                isClearable
                onChange={(newValue) => {}}
                defaultValue={[
                  {
                    value: 646080,
                    label: 646080,
                  },
                ]}
                options={[646080, 646081, 646082, 646083, 646084, 646085].map(
                  (x) => {
                    return {
                      value: x,
                      label: x,
                    };
                  }
                )}
                name="PickupCarrierId"
                className="mt-3"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={() => {
                  setNoteShow(false);
                }}
                className="btn btn-light btn-elevate mr-2"
              >
                No
              </button>
              <button
                type="button"
                onClick={() => {}}
                className="btn btn-success btn-elevate"
              >
                Yes
              </button>
            </div>
          </Modal.Footer>
        </Modal>

        <Card
          className={
            "mb-2 m-auto border-secondary " +
            (index % 2 === 0 ? "bg-light" : "")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-2">
              <div
                className="label label-xl label-inline label-light-primary font-weight-bolder"
                style={{ fontSize: "12px" }}
              >
                <i className="fas fa-user mr-2 text-primary"></i> {x?.CreatedBy}
              </div>
            </Card.Title>
            <span className="mb-2 text-primary d-inline">
              <div
                className="label label-xl label-inline label-light-primary font-weight-bolder"
                style={{ fontSize: "12px" }}
              >
                {momentDate(x?.CreatedAt)}
              </div>
            </span>
            <br />
            {x?.CategoryId !== null && (
                <div
                  className="label label-xl label-inline label-light-info font-weight-bolder mb-2 mt-3"
                  style={{ fontSize: "12px" }}
                >
                  <i className="fas fa-book mr-2 text-info"></i>{" "}
                  {x?.CategoryName}
                </div>
              )}
            <Card.Text className="mt-5 ml-5">
              {!Showing &&
                (!Text ? (
                  <span dangerouslySetInnerHTML={{ __html: x?.Body }} />
                ) : (
                  Text
                ))}
              {Showing && (
                <>
                  <div className="mb-3 selectIndexZ">
                    <label>Select Category</label>
                    <Select
                      name="CategoryId"
                      label="Select Category"
                      defaultValue={
                        x?.CategoryId && x?.CategoryName
                          ? {
                              value: x?.CategoryId,
                              label: x?.CategoryName,
                            }
                          : null
                      }
                      options={CategorysList.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        setCategory(newValue?.value ?? "");
                      }}
                    />
                  </div>
                  <CKEditor
                    name="Body"
                    Id="TeaserText"
                    label="TeaserText"
                    editor={ClassicEditor}
                    config={{
                      removePlugins: [
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                    data={NewText === "" ? (!Text ? x?.Body : Text) : NewText}
                    onBlur={(event, editor) => {
                      setText(editor.getData());
                    }}
                  />
                </>
              )}
            </Card.Text>

            {x?.isMulti && (
              <div
                className="text-right"
                style={{ position: "absolute", top: "27px", right: "5px" }}
              >
                <span
                  className="btn btn-light-primary mr-2"
                  onClick={() => {
                    if (x?.Id) x.id = x?.Id;
                    setNoteId(x);
                    setShow(true);
                  }}
                >
                  <i className="fas fa-edit mr-2"></i> Edit (
                  <small>This note is already in other containers</small>)
                </span>
              </div>
            )}

            {db.getState().User?.Email === x?.UserEmail && (
              <div
                className="text-right"
                style={{ position: "absolute", top: "27px", right: "5px" }}
              >
                {!x?.isMulti && (
                  <button
                    type="button"
                    className="btn btn-light-primary mr-2"
                    onClick={async () => {
                      if (Showing) {
                        await PutNote(
                          x?.Id,
                          Text === "" ? x?.Body : Text,
                          Category === "" ? x?.CategoryId : Category
                        );
                        getNots();
                      } else {
                        setTitleBtnDeletePut("Close");
                        setShowing(true);
                      }
                    }}
                  >
                    {!Showing && (
                      <>
                        <i className="fas fa-edit mr-2"></i> Edit
                      </>
                    )}
                    {Showing && (
                      <>
                        <i className="fas fa-save mr-2"></i> Save
                      </>
                    )}
                  </button>
                )}
                {db.getState().User?.Email === x?.UserEmail && !x?.isMulti && (
                  <button
                    type="button"
                    className="btn btn-light-danger mr-2 ml-3"
                    onClick={async () => {
                      if (titleBtnDeletePut === "Delete") {
                        await DeleteNote(x?.Id);
                        getNots();
                      } else {
                        setTitleBtnDeletePut("Delete");
                        setShowing(false);
                      }
                    }}
                  >
                    {titleBtnDeletePut === "Delete" && (
                      <i className="fas fa-trash mr-2"></i>
                    )}
                    {titleBtnDeletePut === "Close" && (
                      <i className="fas fa-times"></i>
                    )}
                    {titleBtnDeletePut}
                  </button>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }

  async function getNots(exceptionId) {
    var a = await GetNotes();
    if (isLulusDashboard && exceptionId) {
      setNotesList(
        a?.Notes.filter(
          (x) => x.AccessLevelId === 4 && x.ExceptionId === exceptionId
        ) || []
      );
    } else if (isLulusDashboard && !exceptionId) {
      setNotesList([]);
    } else {
      if (isViewModel && hideExistingNotes === false) {
        setNotesList(a || []);
      } else {
        setNotesList(a?.Notes || []);
      }
    }
    if (isViewModel) {
      const result = await GetViewModelList();
      setCategorysList(result?.Categories || []);
      setAccessLevels(result?.AccessLevels || []);
    } else {
      if(!customViewModel){
        setCategorysList(a?.Categories || []);
        setAccessLevels(a?.AccessLevels || []);  
      }
    }
  }
  useEffect(() => {
    if(customViewModel) {
      setCategorysList(attachmentTypes || []);
      setAccessLevels(accessLevels || []);
    }
  },[customViewModel,attachmentTypes,accessLevels])
  async function DeleteNote(id) {
    return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      })
      .catch((error) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      });
  }
  const [selectedAccessLevel, setSelectedAccessLevel] = useState({
    value: "",
    label: "Select",
  });
  const [selectedCategory, setCategoryPost] = useState({
    value: "",
    label: "Select",
  });
  const [selectedException, setExceptionPost] = useState({
    value: "",
    label: "Select",
  });

  const [Titles, setTitles] = useState([]);
  useEffect(() => {
    if(!isBilling){
      GetAllTitles(setTitles);
    }
  }, []);

  const [Show, setShow] = useState(false);
  const [NoteId, setNoteId] = useState();
  return (
    <>
      <div className="row">
        <input type="hidden" id="getNots" onClick={() => getNots()} />
        <div className="col mb-5">
          <button
            type="button"
            className={`btn btn-light-${
              noteToggle ? "danger" : "primary"
            } mr-2 w-100 mb-5`}
            onClick={async () => {
              $(".NotesId").toggle("slow");
              setNoteToggle(noteToggle ? false : true);
              if (IsExceptionLulus && id) {
                await GetExceptionsLists();
              }
            }}
          >
            {noteToggle ? (
              <Fragment>
                <i className="fas fa-times mr-2"></i> Close Note
              </Fragment>
            ) : (
              <Fragment>
                <i className="fas fa-plus mr-2"></i> Add Note
              </Fragment>
            )}
          </button>
        </div>
        {hideExistingNotes === true ? (
          ""
        ) : (
          <div className="col-5 mb-5">
            <SelectPagination
              name="P1"
              placeholder="Select from existing Notes"
              onChange={(newValue) => {
                setNoteShow2(newValue);
              }}
              options={Titles.map((x) => {
                return {
                  value: x?.NoteId,
                  label:
                    x?.Title +
                    " : " +
                    x?.Body?.replace(/(<([^>]+)>)/gi, "")?.substr(0, 10) +
                    " ...",
                };
              })}
            />
          </div>
        )}
      </div>

      <Modal
        size="lg"
        show={NoteShow2 !== false}
        aria-labelledby="example-modal-sizes-title-lg"
        onHide={() => setNoteShow2(false)}
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Note '{NoteShow2?.label?.split(" :")[0]}' in this Container {id}
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setNoteShow2(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setNoteShow2(false);
              }}
              className="btn btn-light btn-elevate mr-2"
            >
              No
            </button>
            <button
              type="button"
              onClick={() => {
                AssignNote(NoteShow2?.value, id, setNoteShow2);
              }}
              className="btn btn-success btn-elevate"
            >
              Yes
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="m-auto mb-5 NotesId" style={{ display: "none" }}>
        {!isBilling && (
          <>
            <label>Title</label>
            <div className="col-lg-12 p-0 mb-5" placeholder="">
              <input id="TitleNote" className="form-control" />
            </div>
          </>
        )}
        <label>Body</label>
        <CKEditor
          name="Body"
          Id="TeaserText"
          label="TeaserText"
          editor={ClassicEditor}
          config={{
            removePlugins: [
              "Image",
              "ImageCaption",
              "ImageStyle",
              "ImageToolbar",
              "ImageUpload",
              "MediaEmbed",
            ],
          }}
          data={NewText}
          onBlur={(event, editor) => {
            setNewText(editor.getData());
          }}
        />
        <div className="row">
          {IsExceptionLulus && !isLulusDashboard && (
            <div
              className={
                IsExceptionLulus && !isLulusDashboard
                  ? "col-lg-3 mb-5 w-100 mt-5"
                  : "mb-5 col-lg-4 w-100 mt-5"
              }
            >
              <label>Select Exception</label>
              <Select
                name="ExceptionId"
                label="Select Exception"
                value={selectedException}
                options={exceptionsLists}
                onChange={(newValue) => {
                  setExceptionPost(newValue);
                }}
              />
            </div>
          )}
          <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "col-lg-3 mb-5 w-100 mt-5"
                : "mb-5 col-lg-4 w-100 mt-5"
            }
          >
            <label>Select Category</label>
            <Select
              name="CategoryId"
              label="Select Category"
              value={
                IsExceptionLulus && selectedCategory.value === ""
                  ? { value: 4, label: "Exception" }
                  : isBilling && selectedCategory.value === ""
                  ? { value: 5, label: "Information" }
                  : selectedCategory
              }
              options={CategorysList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteCategoryId = a;
                setCategoryPost(newValue);
              }}
            />
          </div>

          <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "col-lg-3 mb-5 w-100 mt-5"
                : "mb-5 col-lg-4 w-100 mt-5"
            }
          >
            <label>Access Level</label>
            <Select
              name="AccessLevelId"
              id="AccessLevelId"
              label="Access Level"
              options={AccessLevels.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              value={
                IsExceptionLulus && selectedAccessLevel.value === ""
                  ? { value: 4, label: "Public" }
                  : isBilling && selectedAccessLevel.value === ""
                  ? { value: 5, label: "Private" }
                  : selectedAccessLevel
              }
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteAccessLevelId = a;
                setSelectedAccessLevel(newValue);
              }}
              placeholder="Access Level"
            />
          </div>
          <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "m-auto col-lg-3 text-center"
                : "m-auto col-lg-4 text-center"
            }
          >
            <label className="text-white">.</label>
            <button
              type="button"
              className="btn btn-light-success mr-2 w-100 mb-5 m-auto"
              onClick={async () => {
                if (NewText === "") Notification("warning", "Text is Empty");
                else {
                  if (
                    isLulusDashboard &&
                    (exceptionId === "" ||
                      exceptionId === undefined ||
                      exceptionId === null)
                  ) {
                    Notification(
                      "error",
                      "You should Select Exception first to add a note"
                    );
                    return false;
                  }
                  await PostNote(
                    NewText,
                    window.$NoteCategoryId !== undefined &&
                      window.$NoteCategoryId !== null &&
                      window.$NoteCategoryId !== ""
                      ? window.$NoteCategoryId
                      : IsExceptionLulus && selectedCategory.value === ""
                      ? 4
                      : 5,
                      window.$NoteAccessLevelId !== undefined &&
                      window.$NoteAccessLevelId !== null &&
                      window.$NoteAccessLevelId !== ""
                      ? window.$NoteAccessLevelId
                      : IsExceptionLulus && selectedAccessLevel.value === ""
                      ? 4
                      : 5,
                  );
                  getNots(exceptionId);
                  setTimeout(() => {
                    setNewText("");
                    $(".NotesId").toggle("slow");
                    setNoteToggle(noteToggle ? false : true);
                    $("#NewTextNots").val("");
                  }, 50);
                }
              }}
            >
              <i className="fas fa-save mr-2"></i> Save
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
      <NotesEdit Show={Show} setShow={setShow} NoteId={NoteId} />
      {(list !== null && list !== undefined && list?.length > 0
        ? list
        : NotesList
      )?.map((x, index) => (
        <CardFunction
          x={x}
          key={index}
          index={index}
          New={() => getNots()}
          setShow={setShow}
          setNoteId={setNoteId}
        />
      ))}
    </>
  );
}

function NotesEdit({ Show, setShow, NoteId }) {
  const saveCustomer = async (customer) => {
    const result = await axios.post("api/Notes/PutModel", {
      customer,
    });
    if (result?.status === 200) setShow(false);
  };
  return (
    <Modal
      size="lg"
      show={Show}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/* {NoteId?.Id}
      <CustomerEditDialogHeader id={NoteId?.Id} /> */}
      <CustomerEditForm
        saveCustomer={saveCustomer}
        /* actionsLoading={actionsLoading} */
        customer={NoteId}
        onHide={() => setShow(false)}
      />
    </Modal>
  );
}

async function GetAllTitles(setTitles) {
  return await fetch(window.$apiurl + "/notes/GetAllTitles", {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        setTitles(res[1] ?? []);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

async function AssignNote(id, receiptid, setNoteShow2) {
  return fetch(
    window.$apiurl + `/notes/AssignNote?id=${id}&receiptId=${receiptid}`,
    {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = statusCode === 200 ? "" : await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        Notification("success", "Note has been saved");
        document.getElementById("getNots").click();
        setNoteShow2(false);
      } else {
        Notification("error", res[1]?.Message ?? "");
      }
    });
}
