/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/products/productsActions";
import CloseIcon from "@material-ui/icons/Close";
import CreatableSelect from "react-select/creatable";
import { Formik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { IconButton, makeStyles } from "@material-ui/core";
import { Notification } from "../../../../Notification";
import { RequiredHtml } from "../../../../../../_metronic/_helpers/ATHelpers";
import axios from "axios";
import { ProductMODetail } from "../master-order-detail/ProductDetail";
const adapter = new LocalStorage("db");
const db = low(adapter);

const initProduct = {
  id: undefined,
  Mo: "Auto Populate",
  CustomerId: null,
  ConsigneeId: null,
  ShipperId: null,
  BillToId: null,
  CustomerRefNumber: "",
  MustArriveByDate: "",
  RequiredShipDate: "",
  ConsigneeNumber: "",
  ShipperNumber: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export function OrderCreation({
  history,
  /* match: {
    params: { Mode, MO },
  }, */
  Mode,
  MO,
}) {
  // Subheader
  // Tabs
  const [modalAddresses, setmodalAddresses] = useState(false);
  const [IsShipperSelected, setIsShipperSelected] = useState(false);
  const [MODetail, setMODetail] = useState(false);

  const [ViewModelOptions, setViewModelOptions] = useState(null);
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);

  useEffect(() => {
    dispatch(actions.fetchProduct(MO));
  }, [MO, dispatch]);

  //GetViewModel
  function GetViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/GetViewModel`, requestOptions)
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then((result) => {
        var data = JSON.parse(result);
        setViewModelOptions(data);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetViewModel();
  }, []);
  //End Get ViewModel

  const backToProductsList = () => {
    document.getElementById("LoadBoardGrid").click();
  };

  const classes = useStyles();
  const [ShowLegs, setShowLegs] = useState();
  const [Country, setCountry] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  const [stateId, setStateId] = React.useState({
    value: "",
    label: "Select",
  });
  const [LoadingPost, setLoadingPost] = useState(false);
  const [AddressObjectPost, setAddressObjectPost] = useState({
    Name: "",
    Address: "",
    City: "",
    State: "",
    PostalCode: "",
    CountryId: "US",
    Phone: "",
    Fax: "",
    Note: "",
    AddressTypeId: null,
    IsActive: true,
    Charges: [],
    ContactName: "",
    Email: "",
    CustomerId: null,
  });
  //Fetch Dynamic Address
  const [consignee, setConsignee] = useState({
    ConsigneeId: 0,
    ConsigneeAddress: "",
    ConsigneeCity: "",
    ConsigneeState: "",
    ConsigneeZipCode: "",
    ConsigneePhoneNumber: "",
    ConsigneeFax: "",
    ConsigneeContactName: "",
    ConsigneeEmail: "",
  });
  const [shipper, setShipper] = useState({
    ShipperId: 0,
    ShipperAddress: "",
    ShipperCity: "",
    ShipperState: "",
    ShipperZipCode: "",
    ShipperPhoneNumber: "",
    ShipperFax: "",
    ShipperContactName: "",
    ShipperEmail: "",
  });
  async function fetchSelectedAddress(id, isShipper) {
    try {
      const result = await GetShipperAddress(id);
      if (isShipper) {
        let currentAddress = { ...shipper };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
        };
        setShipper(currentAddress);
      } else {
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
        };
        setConsignee(currentAddress);
      }
    } catch (err) {}
  }
  async function GetShipperAddress(id) {
    return await fetch(window.$apiurl + `/Tms/Address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          return result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //******************************************************* */
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/quoting/viewmodel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        const obj = [];
        a?.Customers.length > 0 &&
          a.Customers.map((item) => {
            obj.push({ value: item.Id, label: item.Name });
            return null;
          });
        setCustomers(obj);
        window.$customersAddress = a.Customers;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || []);
    } catch (e) {}
  };
  const handleCountryChange = async (event) => {
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
              return null;
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
  };
  useEffect(() => {
    fetchViewModelAsync();
    GetCustomers();
  }, []);

  const Services = [{ label: "Transportation" }].map((Services) => ({
    value: Services.label,
    label: Services.label,
  }));
  return (
    <Formik
      initialValues={initProduct}
      onSubmit={(values) => {
        setLoadingPost(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(values);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(`${window.$apiurl}/Tms/MasterOrders`, requestOptions)
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              Notification("success", `MO has been Aadd successfully`);
              setLoadingPost(false);
              setMODetail(result[1].Id);
            } else {
              if (
                result[1].ModelState !== null &&
                result[1].ModelState !== undefined &&
                result[1].ModelState !== ""
              ) {
                let modelState = result[1].ModelState;
                if (modelState)
                  Object.keys(modelState).forEach(function(k) {
                    modelState[k].forEach((element) => {
                      Notification("errorClick", element);
                    });
                  });
              } else if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("errorClick", result[1].Message);
              } else {
                Notification("errorClick", "invalid request");
              }
              setLoadingPost(false);
            }
          })
          .catch((error) => {
            console.log("error", error);
            Notification("errorClick", "invalid request");
            setLoadingPost(false);
          });
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        function postAddress() {
          setLoadingPost(true);
          let value = { ...AddressObjectPost };
          value.AddressTypeId = IsShipperSelected === true ? 11 : 5;
          return fetch(window.$apiurl + "/address", {
            method: "post",
            withCredentials: true,
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `bearer ${db.read().getState().Token}`,
              "X-FP-API-KEY": "iphone",
              "Content-Type": "application/json",
            },

            body: JSON.stringify(value),
          })
            .then(async (response) => {
              const statusCode = await response.status;
              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json().then((data) => [statusCode, data]);
              } else {
                return response
                  .text()
                  .then((text) => [statusCode, { Message: "" }]);
              }
            })
            .then(async (res) => {
              if (res[0] === 200) {
                Notification("success", "Address has been add successfuly");
                setmodalAddresses(false);
                setLoadingPost(false);

                await GetViewModel();
                if (IsShipperSelected) {
                  setFieldValue("ShipperId", res[1].Id);
                  await fetchSelectedAddress(res[1].Id, true);
                } else {
                  setFieldValue("ConsigneeId", res[1].Id);
                  await fetchSelectedAddress(res[1].Id, false);
                }

                setAddressObjectPost({
                  Name: "",
                  Address: "",
                  City: "",
                  State: "",
                  PostalCode: "",
                  CountryId: "US",
                  Phone: "",
                  Fax: "",
                  Note: "",
                  AddressTypeId: null,
                  IsActive: true,
                  Charges: [],
                  CustomerId: null,
                  ContactName: "",
                  Email: "",
                });
              } else {
                if (
                  res[1].Message !== null &&
                  res[1].Message !== undefined &&
                  res[1].Message !== ""
                ) {
                  Notification("errorClick", res[1].Message);
                } else {
                  Notification("errorClick", "Invalid Data");
                }
                setLoadingPost(false);
              }
            })
            .catch((error) => {
              Notification("errorClick", "Invalid Data");
              setLoadingPost(false);
            });
        }
        return (
          <>
            {MODetail && (
              <ProductMODetail MO={MODetail} setMODetail={setMODetail} />
            )}
            {!MODetail && (
              <form className="form form-label-right">
                <Modal
                  size="md"
                  show={ShowLegs}
                  onHide={setShowLegs}
                  aria-labelledby="example-modal-sizes-title-sm"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Create Service Leg
                    </Modal.Title>
                    <IconButton
                      className={classes.button}
                      aria-label="Close"
                      onClick={() => {
                        setShowLegs(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group row">
                      <div className="col">
                        <label className="mb-2">Service</label>
                        <Select
                          isClearable
                          name="Service"
                          id="Service"
                          label="Service"
                          inputId="react-select-single"
                          options={Services}
                        />
                      </div>
                      <div className="col">
                        <label>Mode </label>
                        <Select
                          isClearable
                          name="Mode"
                          id="Mode"
                          label="Mode"
                          inputId="react-select-single"
                          options={Mode}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="submit"
                      onClick={() => setShowLegs(false)}
                      className="btn btn-outline btn-elevate"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      onClick={() => {
                        history.push(`/TransportManagementSystems/MORDER/New`);
                      }}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={modalAddresses}
                  size="lg"
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Add new Address
                    </Modal.Title>
                    <IconButton
                      className="MuiButtonBase-root MuiIconButton-root jss179"
                      aria-label="Close"
                      onClick={() => {
                        setmodalAddresses(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    <form className="form form-label-right">
                      <div className="form-group row">
                        <div className={"col-lg-12"}>
                          <label>Organisation Name</label>
                          <input
                            name="Name"
                            className="form-control"
                            placeholder="Organisation Name"
                            label="Organisation Name"
                            value={AddressObjectPost.Name}
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.Name = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-lg-12 px-0">
                          <label>
                            Address <RequiredHtml />
                          </label>
                          <input
                            name="Address"
                            as="textarea"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.Address = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <label>City</label>

                          <input
                            name="City"
                            className="form-control"
                            placeholder="City"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.City = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            label="City"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Choose a state</label>
                          <Select
                            name="State"
                            label="State"
                            id="LocationState"
                            options={states}
                            value={stateId}
                            onChange={(e) => {
                              setStateId(e);
                              let value = { ...AddressObjectPost };
                              if (e !== null) {
                                value.State = e?.label;
                              } else {
                                value.State = "";
                              }
                              setAddressObjectPost(value);
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Zip Code</label>

                          <input
                            name="PostalCode"
                            className="form-control"
                            placeholder="Zip Code"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.PostalCode = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            label="Zip Code"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <label>
                            Country <RequiredHtml />
                          </label>
                          <CreatableSelect
                            name="CountryId"
                            value={
                              countryIdFetch.value === ""
                                ? { value: "US", label: "United States" }
                                : countryIdFetch
                            }
                            options={Country.map((x) => {
                              return { value: x?.Id, label: x?.Name };
                            })}
                            onChange={(newValue) => {
                              setCountryValue(newValue);
                              handleCountryChange(newValue?.value);
                              let value = { ...AddressObjectPost };
                              if (newValue !== null) {
                                value.CountryId = newValue?.value;
                              } else {
                                value.CountryId = "";
                              }
                              setAddressObjectPost(value);
                            }}
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Phone</label>

                          <input
                            name="Phone"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.Phone = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            className="form-control"
                            placeholder="Phone"
                            label="Phone"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Fax</label>

                          <input
                            name="Fax"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.Fax = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            className="form-control"
                            placeholder="Fax"
                            label="Fax"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <label>Contact Name</label>

                          <input
                            name="ContactName"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.ContactName = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            className="form-control"
                            placeholder="Contact Name"
                            label="Contact Name"
                          />
                        </div>
                        <div className="col-lg-4">
                          <label>Contact Email</label>

                          <input
                            name="Email"
                            onChange={(e) => {
                              let value = { ...AddressObjectPost };
                              value.Email = e.target.value;
                              setAddressObjectPost(value);
                            }}
                            className="form-control"
                            placeholder="Contact Email"
                            label="Contact Email"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-lg-12">
                          <label>Customer</label>
                          <Select name="CustomerId" options={customers} />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-lg-12 px-0">
                          <label>Note</label>
                          <input
                            name="Note"
                            as="textarea"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </form>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setmodalAddresses(false);
                      }}
                      className="btn btn-light btn-elevate"
                    >
                      Cancel
                    </button>
                    <> </>
                    <button
                      type="submit"
                      disabled={LoadingPost}
                      onClick={() => {
                        postAddress();
                      }}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                      {LoadingPost && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </Modal.Footer>
                </Modal>
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-4">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12">
                        <Card className="bg-transparent shadow-none header-detail">
                          <CardHeader>
                            <CardHeaderToolbar className="pl-0 align-content-end">
                              <button
                                type="button"
                                onClick={backToProductsList}
                                className="btn btn-light"
                              >
                                <i className="fa fa-arrow-left"></i>
                                Back
                              </button>
                              {`  `}
                              {`  `}
                              <button
                                type="submit"
                                disabled={LoadingPost}
                                className="btn btn-primary ml-2"
                                onClick={handleSubmit}
                              >
                                Save
                                {LoadingPost === true ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  ""
                                )}
                              </button>
                            </CardHeaderToolbar>
                          </CardHeader>
                        </Card>

                        <div className="col-xl-12 col-lg-12 col-md-12">
                          <Card>
                            <CardHeader
                              className="border-bottom-0"
                              title="Basic Info"
                            >
                              <div className="separator separator-dashed my-3"></div>
                            </CardHeader>
                            <CardBody>
                              <div className="d-flex flex-wrap align-items-center pb-5">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                  <a
                                    href="#"
                                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                  >
                                    MO #
                                  </a>
                                  <span className="text-muted font-weight-bold">
                                    <input
                                      type="text"
                                      className="form-control "
                                      defaultValue={values.Mo}
                                      disabled
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap align-items-center pb-5">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                  <a
                                    href="#"
                                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                  >
                                    Cust Ref #
                                  </a>
                                  <span className="text-muted font-weight-bold">
                                    <input
                                      type="text"
                                      className="form-control "
                                      name="CustomerRefNumber"
                                      id="CustomerRefNumber"
                                      readOnly={false}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "CustomerRefNumber",
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap align-items-center pb-5">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                  <a
                                    href="#"
                                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                  >
                                    Customer
                                  </a>
                                  <span className="font-weight-bold">
                                    <Select
                                      isClearable
                                      name="CustomerId"
                                      options={ViewModelOptions?.Customers.map(
                                        (a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Code + "-" + a.Name,
                                          };
                                        }
                                      )}
                                      onChange={(e) => {
                                        if (e !== null) {
                                          setFieldValue("CustomerId", e.value);
                                          setFieldValue(
                                            "BillToId",
                                            ViewModelOptions?.Customers?.filter(
                                              (a) => a.Id === e.value
                                            )[0]?.BillToId
                                          );
                                        } else {
                                          setFieldValue("CustomerId", null);
                                          setFieldValue("BillToId", null);
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-wrap align-items-center pb-5">
                                <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                  <a
                                    href="#"
                                    className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                                  >
                                    Bill To
                                  </a>
                                  <span className="font-weight-bold">
                                    <Select
                                      isClearable
                                      options={ViewModelOptions?.GenericAddresses.map(
                                        (a) => {
                                          return {
                                            value: a.Id,
                                            label: a.Name,
                                          };
                                        }
                                      )}
                                      value={ViewModelOptions?.GenericAddresses?.filter(
                                        (a) => a.Id === values.BillToId
                                      ).map((a) => {
                                        return {
                                          value: a.Id,
                                          label: a.Name,
                                        };
                                      })}
                                      onChange={(e) => {
                                        if (e !== null) {
                                          setFieldValue("BillToId", e.value);
                                        } else {
                                          setFieldValue("BillToId", "");
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-8">
                    <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                      <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="layout-tooltip">Back</Tooltip>}
                      >
                        <li className="nav-item mb-2" data-placement="left">
                          <button
                            type="button"
                            onClick={backToProductsList}
                            className="btn btn-sm btn-icon btn-bg-light"
                          >
                            <i className="fa fa-arrow-left"></i>
                          </button>
                        </li>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="left"
                        overlay={
                          <Tooltip id="documentations-tooltip">Save</Tooltip>
                        }
                      >
                        <li className="nav-item mb-2" data-placement="left">
                          <button
                            type="submit"
                            disabled={LoadingPost}
                            className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                            onClick={handleSubmit}
                          >
                            <i class="fas fa-save"></i>
                            {LoadingPost === true ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                          </button>
                        </li>
                      </OverlayTrigger>
                    </ul>
                    <Card className="bg-transparent shadow-none mb-0">
                      <CardBody className="px-0">
                        <div className="mt-5">
                          <Card>
                            <CardHeader title="Ultimate Shipper"></CardHeader>
                            <CardBody >
                              <div className="row">
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Company Name :
                                      </a>
                                      <span className="font-weight-bold">
                                        <CreatableSelect
                                          className="mt-4"
                                          isClearable
                                          name="ShipperId"
                                          id="ShipperId"
                                          label="Company"
                                          value={ViewModelOptions?.Shippers.filter(
                                            (a) => a.Id === values.ShipperId
                                          ).map((a) => {
                                            return {
                                              value: a.Id,
                                              label: a.Name,
                                            };
                                          })}
                                          options={ViewModelOptions?.Shippers.map(
                                            (a) => {
                                              return {
                                                value: a.Id,
                                                label: a.Name,
                                              };
                                            }
                                          )}
                                          onChange={async (newValue) => {
                                            if (newValue !== null) {
                                              if (newValue.__isNew__) {
                                                setmodalAddresses(true);
                                                setCountryValue({
                                                  value: "US",
                                                  label: "United States",
                                                });

                                                handleCountryChange("US");
                                                var value = {
                                                  ...AddressObjectPost,
                                                };
                                                value.Name = newValue.label;
                                                setAddressObjectPost(value);
                                                setIsShipperSelected(true);
                                              } else {
                                                setFieldValue(
                                                  "ShipperId",
                                                  newValue?.value
                                                );
                                                await fetchSelectedAddress(
                                                  newValue?.value,
                                                  true
                                                );
                                              }
                                            } else {
                                              setFieldValue("ShipperId", null);
                                              let values = { ...shipper };
                                              values = {
                                                ShipperId: 0,
                                                ShipperAddress: "",
                                                ShipperCity: "",
                                                ShipperState: "",
                                                ShipperZipCode: "",
                                                ShipperPhoneNumber: "",
                                                ShipperFax: "",
                                                ShipperContactName: "",
                                                ShipperEmail: "",
                                              };
                                              setShipper(values);
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Street Address :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ShipperAddress"
                                          disabled={true}
                                          id="ShipperAddress"
                                          value={shipper?.ShipperAddress}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        City :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ShipperCity"
                                          disabled={true}
                                          id="ShipperCity"
                                          value={shipper?.ShipperCity}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        State :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ShipperState"
                                          disabled={true}
                                          id="ShipperState"
                                          value={shipper?.ShipperState}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Zip :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ShipperZip"
                                          id="ShipperZip"
                                          disabled={true}
                                          value={shipper?.ShipperZipCode}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Name :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ShipperContactName"
                                          disabled={true}
                                          id="ShipperContactName"
                                          label="Contact"
                                          value={shipper?.ShipperContactName}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Phone :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="tel"
                                          className="form-control mt-4"
                                          name="ShipperPhone"
                                          id="ShipperPhone"
                                          disabled={true}
                                          value={shipper?.ShipperPhoneNumber}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Fax :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="tel"
                                          className="form-control mt-4"
                                          name="ShipperFax"
                                          id="ShipperFax"
                                          disabled={true}
                                          value={shipper?.ShipperFax}
                                          label="Fax"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Email :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="email"
                                          className="form-control mt-4"
                                          name="ShipperEmail"
                                          disabled={true}
                                          id="ShipperEmail"
                                          value={shipper?.ShipperEmail}
                                          label="Email"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardHeader title="Ultimate Receiver"></CardHeader>
                            <CardBody>
                              <div className="row">
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Company Name :
                                      </a>
                                      <span className="font-weight-bold">
                                        <CreatableSelect
                                          isClearable
                                          className="mt-4"
                                          name="ConsigneeId"
                                          id="ConsigneeId"
                                          value={ViewModelOptions?.Consignees.filter(
                                            (a) => a.Id === values.ConsigneeId
                                          ).map((a) => {
                                            return {
                                              value: a.Id,
                                              label: a.Name,
                                            };
                                          })}
                                          options={ViewModelOptions?.Consignees.map(
                                            (a) => {
                                              return {
                                                value: a.Id,
                                                label: a.Name,
                                              };
                                            }
                                          )}
                                          onChange={async (newValue) => {
                                            if (newValue !== null) {
                                              if (newValue.__isNew__) {
                                                setmodalAddresses(true);
                                                setCountryValue({
                                                  value: "US",
                                                  label: "United States",
                                                });

                                                handleCountryChange("US");
                                                var value = {
                                                  ...AddressObjectPost,
                                                };
                                                value.Name = newValue.label;
                                                setAddressObjectPost(value);
                                                setIsShipperSelected(false);
                                              } else {
                                                setFieldValue(
                                                  "ConsigneeId",
                                                  newValue?.value
                                                );
                                                await fetchSelectedAddress(
                                                  newValue?.value,
                                                  false
                                                );
                                              }
                                            } else {
                                              setFieldValue(
                                                "ConsigneeId",
                                                null
                                              );
                                              let values = { ...consignee };
                                              values = {
                                                ConsigneeId: 0,
                                                ConsigneeAddress: "",
                                                ConsigneeCity: "",
                                                ConsigneeState: "",
                                                ConsigneeZipCode: "",
                                                ConsigneePhoneNumber: "",
                                                ConsigneeFax: "",
                                                ConsigneeContactName: "",
                                                ConsigneeEmail: "",
                                              };
                                              setConsignee(values);
                                            }
                                          }}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Street Address :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ConsigneeStreet"
                                          id="ConsigneeStreet"
                                          disabled={true}
                                          label="Street"
                                          value={consignee?.ConsigneeAddress}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        City :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ConsigneeCity"
                                          disabled={true}
                                          id="ConsigneeCity"
                                          value={consignee?.ConsigneeCity}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        State :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ConsigneeState"
                                          id="ConsigneeState"
                                          disabled={true}
                                          value={consignee?.ConsigneeState}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Zip :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ConsigneeZip"
                                          id="ConsigneeZip"
                                          disabled={true}
                                          value={consignee?.ConsigneeZip}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Name :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="text"
                                          className="form-control mt-4"
                                          name="ConsigneeContactName"
                                          disabled={true}
                                          value={
                                            consignee?.ConsigneeContactName
                                          }
                                          id="ConsigneeContactName"
                                          label="Contact"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Phone :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="tel"
                                          className="form-control mt-4"
                                          name="ConsigneePhone"
                                          disabled={true}
                                          id="ConsigneePhone"
                                          value={consignee?.ConsigneePhone}
                                        />
                                      </span>
                                    </div>
                                  </div>
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Fax :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="tel"
                                          className="form-control mt-4"
                                          name="ConsigneeFax"
                                          id="ConsigneeFax"
                                          disabled={true}
                                          value={consignee?.ConsigneeFax}
                                          label="Fax"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl col-lg col-md-4 border-right">
                                  <div className=" flex-wrap align-items-center pb-5">
                                    <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                      <a
                                        href={() => false}
                                        className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                      >
                                        Contact Email :
                                      </a>
                                      <span className="text-muted font-weight-bold">
                                        <input
                                          type="email"
                                          className="form-control mt-4"
                                          name="EmailText"
                                          id="EmailText"
                                          disabled={true}
                                          value={consignee?.ConsigneeEmail}
                                          label="Email"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </CardBody>
                    </Card>
                    <Card
                      className="bg-transparent shadow-none mt-0"
                      style={{ float: "right" }}
                    >
                      <CardHeader>
                        <CardHeaderToolbar className="pl-0 align-content-end">
                          <button
                            type="button"
                            onClick={backToProductsList}
                            className="btn btn-light"
                          >
                            <i className="fa fa-arrow-left"></i>
                            Back
                          </button>
                          {`  `}
                          {`  `}
                          <button
                            type="submit"
                            disabled={LoadingPost}
                            className="btn btn-primary ml-2"
                            onClick={handleSubmit}
                          >
                            Save
                            {LoadingPost === true ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              ""
                            )}
                          </button>
                        </CardHeaderToolbar>
                      </CardHeader>
                    </Card>
                  </div>
                </div>
              </form>
            )}
          </>
        );
      }}
    </Formik>
  );
}
