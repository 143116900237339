import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import { Spinner } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import axios from "axios";
import { CreatableSelect3 } from "../../../../_metronic/_partials/controls";
import { is } from "../../../../_metronic/_helpers/ATHelpers";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { default as JsNative } from "jquery";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function TableComponent({
  list = [],
  DeleteShipmentRow,
  PalletID,
  Pallet,
  setPallet,
  isClosed = true,
  Header = true,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const classes = useStyles();

  const [Customers, setCustomers] = useState([]);
  const [warehouses, setwarehouses] = useState([]);
  const [Locations, setLocations] = useState([]);
  const [consignees, setconsignees] = useState([]);
  const [shippers, setshippers] = useState([]);
  const [values, setvalues] = useState([]);

  const fetchUsersAsync = async () => {
    try {
      const result = window.GetAllApi
        ? window.GetAllApi
        : await axios.get("api/PalletsManagment/GetAllApi");

      window.GetAllApi = result;

      setCustomers(result.data["customers"] || []);
      setwarehouses(result.data["warehouses"] || []);
      setconsignees(result.data["consignees"] || []);
      setshippers(result.data["shippers"] || []);
    } catch (e) {}
  };

  useEffect(() => {
    fetchUsersAsync();
  }, [list]);

  useEffect(() => {
    setvalues(Pallet);
  }, [Pallet]);

  return (
    <>
      <div
        className="card card-custom p-5 text-center"
        id="TablePalletFixed"
        style={{
          display: "none",
          position: "fixed",
          top: "130px",
          right: "0",
          left: "0",
          margin: "auto",
          width: "60%",
          zIndex: "97",
          opacity: "0.9",
          boxShadow: "rgb(24, 125, 228,0.4) 0px 0px 13px 1px",
        }}
      >
        Table Pallet Id : {Pallet?.Id}
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="alert alert-info" role="alert">
            <b>Pallet ID</b>
            <b className=" p-0 px-3 ml-3">{PalletID}</b>
          </div>{" "}
        </div>
        {Header && (
          <>
            <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
              <CreatableSelect3
                label="Customer"
                name="CustomerId"
                required={true}
                valueSelect={values?.CustomerId}
                options={Customers.map((x) => {
                  return {
                    value: x?.Id,
                    label: is(x?.Code) + x?.Name,
                  };
                })}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
              <CreatableSelect3
                label="To Consignee"
                name="ConsigneeId"
                valueSelect={values?.ConsigneeId}
                options={consignees.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Name,
                  };
                })}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
              <CreatableSelect3
                label="Shipper"
                name="ShipperId"
                valueSelect={values?.ShipperId}
                options={shippers.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Name,
                  };
                })}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
              <CreatableSelect3
                label="Warehouse"
                name="WarehouseId"
                valueSelect={values?.WarehouseId}
                options={warehouses.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Name,
                  };
                })}
                onChange={(e) => {
                  const a = warehouses.find((x) => x?.Id === e?.value);
                  setLocations([]);
                  setTimeout(() => {
                    setLocations(a?.Locations || []);
                  }, 1);
                }}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 mt-5">
              <CreatableSelect3
                label="Warehouse Location"
                name="LocationId"
                valueSelect={values?.LocationId}
                options={Locations.map((x) => {
                  return {
                    value: x?.Id,
                    label: x?.Description,
                  };
                })}
              />
            </div>
            <div className="col-lg-4 mt-5 pt-5">
              <button
                type="submit"
                className="btn btn-primary btn-elevate w-100 mt-3"
                onClick={() => {
                  PutModel(PalletID).then((e) => {
                    if (e === 200) {
                      setPallet(PalletID);
                    }
                  });
                }}
              >
                Save
              </button>
            </div>
          </>
        )}{" "}
      </div>
      <br />
      <div className="TableComponent p-0" id="TableComponent">
        <b>Items Count : {list.length}</b>
        {list.length > 0 ? (
          <>
            {" "}
            <Paper className={classes.paper} id={PalletID + "Id"}>
              <div className="react-bootstrap-table table-responsive TabelFilter">
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        PO#
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        UPC
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        SKU
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        Cartons
                      </TableCell>{" "}
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        Unit Count
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        Description
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        Color
                      </TableCell>
                      <TableCell className="bg-light text-dark pt-5 pb-5">
                        Size
                      </TableCell>
                      {!isClosed && (
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(list ?? []).map((item, index) => (
                      <TableTrFunction
                        item={item}
                        key={index}
                        DeleteShipmentRow={DeleteShipmentRow}
                        isClosed={isClosed}
                      />
                    ))}
                    <TableRow>
                      <TableCell />
                      <TableCell />
                      <TableCell>
                        <b>Total</b>
                      </TableCell>
                      <TableCell>
                        <b>{list.reduce((a, b) => +a + +b.Quantity, 0)}</b>
                      </TableCell>
                      <TableCell>
                        <b>{list.reduce((a, b) => +a + +b.UnitCount, 0)}</b>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      {!isClosed && <TableCell />}
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </Paper>
            <br />
          </>
        ) : (
          <Nodatafound PalletID={PalletID} />
        )}
      </div>
    </>
  );
}

const TableTrFunction = ({ item, DeleteShipmentRow, isClosed }) => {
  return (
    <TableRow key={item.Id}>
      <TableCell>{item.PurchaseOrder}</TableCell>
      <TableCell>{item.Upc}</TableCell>
      <TableCell>{item.Sku}</TableCell>
      <TableCell>{item.Quantity}</TableCell>
      <TableCell>{item.UnitCount}</TableCell>
      <TableCell>{item.Description}</TableCell>
      <TableCell>{item.Color}</TableCell>
      <TableCell>{item.Size}</TableCell>
      {!isClosed && (
        <TableCell>
          <input
            value="Delete"
            type="button"
            className="btn btn-danger text-white font-weight-bolder font-size-sm w-100 p-1"
            onClick={() => {
              DeleteShipmentRow(item.Id);
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

function Nodatafound({ PalletID }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 mt-5 pt-5 bg-light border rounded notItems">
          <div className="container-fluid row pt-5">
            <div className="col-xl-6 col-lg-6 col-md-12 mt-2 mx-auto text-center">
              <p className="h1 mt-5 h1-md" style={{ opacity: 0.3 }}>
                <i className="fas fa-pallet mr-3 h1 h1-md" />
                Drop Items In this Pallet {PalletID}
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

async function PutModel(id) {
  const parms = {
    CustomerId: JsNative('[name="CustomerId"]').val(),
    ConsigneeId: JsNative('[name="ConsigneeId"]').val(),
    ShipperId: JsNative('[name="ShipperId"]').val(),
    WarehouseId: JsNative('[name="WarehouseId"]').val(),
    LocationId: JsNative('[name="LocationId"]').val(),
  };
  return await fetch(`${window.$apiurl}/pallets/${id}`, {
    method: "PUT",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parms),
  }).then(async (response) => {
    return response.status;
  });
}
