import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Spinner } from "react-bootstrap";
import { Notification } from "../../../Notification";
import Menu2 from "@material-ui/core/Menu";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import CloseIcon from "@material-ui/icons/Close";

import { ListItemIcon, IconButton } from "@material-ui/core";
import { ListItemText, Icon, Button, MenuItem } from "@material-ui/core";
import { emphasize, withStyles, makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import {
  ActivityFunction,
  Export,
  momentDate,
} from "../../../../../_metronic/_helpers/ATHelpers";
import { Modal, ButtonToolbar } from "react-bootstrap";
import ReactSelect from "react-select";
import Swal from "sweetalert2";

const adapter = new LocalStorage("db");
const db = low(adapter);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu2
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 13,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export default function DepartmentPage() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [LoadingPageShow, setLoadingPageShow] = React.useState(false);
  const [ModalActivities, setModalActivities] = React.useState(false);
  const [LoadingPost, setLoadingPost] = React.useState(false);
  const [ModelShowingDepartment, setModelShowingDepartment] = React.useState(
    false
  );
  const [initDepartment, setIinitDepartment] = useState({
    Name: "",
    ParentId: null,
    ExternalId: "",
  });
  const [initSearch, setinitSearch] = useState({
    ParentId: null,
    SearchText: "",
  });
  const [ItemsGrid, setItemsGrid] = useState([]);
  const [ActivitiesList, setActivitiesList] = useState([]);
  const [ItemsGridFilterd, setItemsGridFilterd] = useState([]);

  function putDepartment() {
    if (
      initDepartment.Name === null ||
      initDepartment.Name === "" ||
      initDepartment.Name === undefined
    ) {
      Notification("errorClick", "please Fill Name");
      return;
    }

    setLoadingPost(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(initDepartment);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/Departements/PutDepartement/${initDepartment.Id}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Department has been Updated successfully");
          GetDepartments();

          setModelShowingDepartment(false);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
        setLoadingPost(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function postDepartment() {
    if (
      initDepartment.Name === null ||
      initDepartment.Name === "" ||
      initDepartment.Name === undefined
    ) {
      Notification("errorClick", "please Fill Name");
      return;
    }

    setLoadingPost(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(initDepartment);

    var requestOptions = {
      method: "Post",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Departements/PostDepartement`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Department has been Created successfully");
          GetDepartments();

          setModelShowingDepartment(false);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
        setLoadingPost(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }
  function GetDepartments() {
    var myHeaders = new Headers();
    setLoadingPageShow(true);
    setItemsGrid([])
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Departements/GetDepartements`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((result) => {
        if (result[0] !== 200) {
          Notification("error", "Pos Not Found");
        } else {
          var obj = result[1];
          setItemsGrid(obj);
        }
        setLoadingPageShow(false);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetDepartments();
  }, []);

  async function fetchMyAPI2(type, entityName) {
    Export(ItemsGridFilterd, type, entityName);
  }
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function filterFucntion() {
    let data = [...ItemsGrid];
    if (
      initSearch.SearchText !== null &&
      initSearch.SearchText !== undefined &&
      initSearch.SearchText !== ""
    ) {
      data = data?.filter(
        (a) =>
          String(a.Name).toLocaleLowerCase() ===
            String(initSearch.SearchText)
              .trim()
              .toLocaleLowerCase() ||
          String(a.ExternalId).toLocaleLowerCase() ===
            String(initSearch.SearchText)
              .trim()
              .toLocaleLowerCase()
      );
    }
    if (
      initSearch.ParentId !== null &&
      initSearch.ParentId !== undefined &&
      initSearch.ParentId !== ""
    ) {
      data = data?.filter((a) => a.ParentId === initSearch.ParentId);
    }
    setItemsGridFilterd(data);
  }
  useEffect(() => {
    filterFucntion();
  }, [initSearch, ItemsGrid]);

  const columns = [
    {
      text: "Id",
      dataField: "Id",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      text: "Name",
      dataField: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },

    {
      text: "External Id",
      dataField: "ExternalId",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: "Parent",
      dataField: "ParentId",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        return ItemsGrid?.find((a) => a.Id === row.ParentId)?.Name;
      },
    },

    {
      text: "Created",
      dataField: "Created",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        return (
          <>
            <span className="label label-lg label-light-primary label-inline">
              {momentDate(row?.Created) === ""
                ? "Empty"
                : momentDate(row?.Created)}
            </span>
          </>
        );
      },
    },

    {
      text: "Actions",
      dataField: "Actions",
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="specs-edit-tooltip">Edit department</Tooltip>
              }
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => {
                  var myHeaders = new Headers();
                  myHeaders.append(
                    "Authorization",
                    `Bearer ${db.read().getState().Token}`
                  );

                  var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                  };

                  fetch(
                    `${window.$apiurl}/Departements/GetDepartement/${row.Id}`,
                    requestOptions
                  )
                    .then(async (response) => {
                      const statusCode = await response.status;
                      const data = await response.json();
                      return Promise.all([statusCode, data]);
                    })
                    .then((result) => {
                      if (result[0] !== 200) {
                        Notification("error", "Pos Not Found");
                      } else {
                        var obj = result[1];
                        setIinitDepartment(obj?.departement);
                        setModelShowingDepartment(true);
                      }
                    })
                    .catch((error) => console.log("error", error));
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="documentations-tooltip">Activities</Tooltip>
              }
            >
              <button
                className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning mr-3"
                onClick={() => {
                  var myHeaders = new Headers();
                  myHeaders.append(
                    "Authorization",
                    `Bearer ${db.read().getState().Token}`
                  );

                  var requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                  };

                  fetch(
                    `${window.$apiurl}/Departements/activities/${row.Id}`,
                    requestOptions
                  )
                    .then(async (response) => {
                      const statusCode = await response.status;
                      const data = await response.json();
                      return Promise.all([statusCode, data]);
                    })
                    .then((result) => {
                      if (result[0] !== 200) {
                        Notification("error", "Pos Not Found");
                      } else {
                        var obj = result[1];
                        setActivitiesList(obj?.Activities || []);
                        setModalActivities(true);
                      }
                    })
                    .catch((error) => console.log("error", error));
                }}
              >
                <i class="fas fa-history"></i>
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="spec-delete-tooltip">Delete department</Tooltip>
              }
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-danger btn-sm  mr-3"
                onClick={() => {
                  console.log("first", row.Id);
                  Swal.fire({
                    title: "Are you sure?",
                    html: `you want to Delete Department <strong>${row.Name}</strong> ?`,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      setLoadingPost(true);
                      var myHeaders = new Headers();
                      myHeaders.append(
                        "Authorization",
                        `Bearer ${db.read().getState().Token}`
                      );
                      myHeaders.append("Content-Type", "application/json");

                      var requestOptions = {
                        method: "DELETE",
                        headers: myHeaders,
                        redirect: "follow",
                      };

                      fetch(
                        `${window.$apiurl}/Departements/DeletetDepartement/${row.Id}`,
                        requestOptions
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const contentType = response.headers.get(
                            "content-type"
                          );
                          if (
                            contentType &&
                            contentType.indexOf("application/json") !== -1
                          ) {
                            return response
                              .json()
                              .then((data) => [statusCode, data]);
                          } else {
                            return response
                              .text()
                              .then((text) => [statusCode, { Message: "" }]);
                          }
                        })
                        .then((result) => {
                          if (result[0] === 200) {
                            Notification(
                              "success",
                              "Department has been Deleted successfully"
                            );

                            GetDepartments();
                          } else {
                            if (
                              result[1].ModelState !== null &&
                              result[1].ModelState !== undefined &&
                              result[1].ModelState !== ""
                            ) {
                              let modelState = result[1].ModelState;
                              if (modelState)
                                Object.keys(modelState).forEach(function(k) {
                                  modelState[k].forEach((element) => {
                                    Notification("errorClick", element);
                                  });
                                });
                            } else if (
                              result[1].Message !== null &&
                              result[1].Message !== undefined &&
                              result[1].Message !== ""
                            ) {
                              Notification("errorClick", result[1].Message);
                            } else {
                              Notification("errorClick", "Bad Request");
                            }
                          }
                          setLoadingPost(false);
                        })
                        .catch((error) => {
                          console.log("error", error);
                        });
                    }
                  });
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Helmet>
        <title>eMPower | Departments</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Card>
        <CardHeader>
          <CardHeaderToolbar>
            <h3>Departments Management</h3>
          </CardHeaderToolbar>
          <CardHeaderToolbar>
            <ButtonToolbar className={`btn-text-primary ${classes.button}`}>
              <Button
                style={{ marginRight: "0.8rem" }}
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="outlined"
                color="primary"
                onClick={handleClick}
              >
                Export as
                <Icon className={classes.rightIcon}>save</Icon>
              </Button>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <StyledMenuItem
                  onClick={(event) => {
                    fetchMyAPI2("excel", "Depatrments");
                  }}
                >
                  <ListItemIcon>
                    <i className="far fa-file-excel"></i>
                  </ListItemIcon>
                  <ListItemText primary="Excel" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={(event) => {
                    fetchMyAPI2("csv", "Depatrments");
                  }}
                >
                  <ListItemIcon>
                    <i className="fas fa-file-csv"></i>
                  </ListItemIcon>
                  <ListItemText primary="CSV" />
                </StyledMenuItem>
                <StyledMenuItem
                  onClick={(event) => {
                    fetchMyAPI2("json", "Depatrments");
                  }}
                >
                  <ListItemIcon>
                    <i className="far fa-file-code"></i>
                  </ListItemIcon>
                  <ListItemText primary="Json" />
                </StyledMenuItem>
              </StyledMenu>
            </ButtonToolbar>
            <Button
              variant="outlinedSecondary"
              color="primary"
              onClick={() => {
                setIinitDepartment({
                  Name: "",
                  ParentId: null,
                  ExternalId: "",
                });
                setModelShowingDepartment(true);
              }}
            >
              Add New Department
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form id="FilterDepartment" className="form form-label-right">
            <div className="form-group row mt-5">
              <div className="col-xl-3 col-lg-3 col-md-3">
                <p className="mb-2">Filter by parent</p>
                <ReactSelect
                  isClearable
                  name="parent"
                  id="parent"
                  label="parent"
                  inputId="react-select-single"
                  placeholder="Select Department"
                  options={ItemsGrid?.map((suggestion) => ({
                    value: suggestion.Id,
                    label: suggestion.Name,
                  }))}
                  onChange={(e) => {
                    let obj = { ...initSearch };
                    obj.ParentId = e === null ? null : e?.value;
                    setinitSearch(obj);
                  }}
                  value={ItemsGrid?.filter(
                    (a) => a.Id === initSearch.ParentId
                  )?.map((suggestion) => ({
                    value: suggestion.Id,
                    label: suggestion.Name,
                  }))}
                />
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3">
                <p className="mb-2">Search in all Fields</p>
                <input
                  type="search"
                  className="form-control "
                  id="SearchText"
                  placeholder="Search Text"
                  value={initSearch.SearchText}
                  onChange={(e) => {
                    let obj = { ...initSearch };
                    obj.SearchText = e.target.value;
                    setinitSearch(obj);
                  }}
                />
              </div>
            </div>
          </form>
          <BootstrapTable
            keyField="Id"
            data={ItemsGridFilterd}
            columns={columns}
            noDataIndication={
              LoadingPageShow === true ? (
                <h4 style={{ textAlign: "center" }}>Loading...</h4>
              ) : (
                <h4 style={{ textAlign: "center" }}>
                  There is no data available at the moment.
                </h4>
              )
            }
            pagination={paginationFactory({
              showTotal: true,
              sizePerPageList: [
                { text: "10", value: 10 },
                { text: "25", value: 25 },
                {
                  text: "All",
                  value: ItemsGridFilterd?.length,
                },
              ],
            })}
            defaultSorted={[{ dataField: "Id", order: "desc" }]}
            wrapperClasses="table-responsive"
            classes="table table-head-custom table-vertical-center"
            bootstrap4
            bordered={false}
          />
        </CardBody>
      </Card>
      <Modal
        size="lg"
        show={ModelShowingDepartment}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>
            {initDepartment.Id !== undefined
              ? `Edit Department: ${initDepartment.Name}`
              : "Add New Department"}
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={(e) => {
              setModelShowingDepartment(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row mt-5">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <p className="mb-2">Name</p>
              <input
                type="text"
                className="form-control "
                id="Name"
                placeholder="Name"
                value={initDepartment.Name}
                onChange={(e) => {
                  let obj = { ...initDepartment };
                  obj.Name = e.target.value;
                  setIinitDepartment(obj);
                }}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <p className="mb-2">Parent</p>
              <ReactSelect
                isClearable
                name="parent"
                id="parent"
                label="parent"
                inputId="react-select-single"
                placeholder="Search parent"
                options={ItemsGrid?.map((suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                }))}
                value={ItemsGrid?.filter(
                  (a) => a.Id === initDepartment.ParentId
                )?.map((suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                }))}
                onChange={(e) => {
                  let obj = { ...initDepartment };
                  obj.ParentId = e === null ? null : e?.value;
                  setIinitDepartment(obj);
                }}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4">
              <p className="mb-2">External Id</p>
              <input
                type="text"
                className="form-control "
                id="ExternalId"
                placeholder="ExternalId"
                value={initDepartment.ExternalId}
                onChange={(e) => {
                  let obj = { ...initDepartment };
                  obj.ExternalId = e.target.value;
                  setIinitDepartment(obj);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outlined"
            color="warning"
            className="mr-3"
            onClick={() => {
              setModelShowingDepartment(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disabled={LoadingPost}
            onClick={() => {
              if (initDepartment.Id !== undefined) {
                putDepartment();
              } else {
                postDepartment();
              }
            }}
          >
            Confirm
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="xl"
        show={ModalActivities}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="row w-100">
            <div className="col-lg-6">
              <h3 className="font-weight-bolder text-dark">
                Department Activities ID:
              </h3>
            </div>
          </div>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setModalActivities(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="overlay-block">
          <ActivityFunction Activitys={ActivitiesList || []} />
        </Modal.Body>
      </Modal>
    </>
  );
}
