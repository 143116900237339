import React, { useState, useEffect } from "react";
import { Notification } from "../../../../app/modules/Notification";
import { Card } from "react-bootstrap";
import $ from "jquery";
import CreatableSelect from "react-select/creatable";
import { momentDate } from "../../../_helpers/ATHelpers";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function LulusHandlingExceptions({
  id,
  get,
  post,
  put,
  Categories,
  IsExceptionLulus,
  isLulusDashboard,
  exceptionId,
  saveNote
}) {
  const [NotesList, setNotesList] = useState([]);
  const [CategorysList, setCategorysList] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [NewText, setNewText] = useState("");
  const [isSave, setisSave] = useState("");
  useEffect(() => {
    console.log('saveNote');
    console.log(saveNote);
    if (saveNote) {
      FetchSaveNoteHandled();
    }
  }, [saveNote]);

  useEffect(() => {
    if (get && id) {
      async function fetchMyAPI() {
        if (isLulusDashboard && exceptionId) {
          getNots(exceptionId);
        } else {
          getNots();
        }
        GetExceptionsLists();
      }
      fetchMyAPI();
    }
  }, [get, id, exceptionId]);

  useEffect(() => {
    setCategorysList(Categories);
  }, [Categories]);

  async function GetNotes() {
    return await fetch(`${window.$apiurl}${get}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function FetchSaveNoteHandled() {
    console.log('NewText');
    console.log(NewText);
    if (NewText !== "") {
      if (
        isLulusDashboard &&
        (exceptionId === "" ||
          exceptionId === undefined ||
          exceptionId === null)
      ) {
        Notification(
          "error",
          "You should Select Exception first to add a note"
        );
        return false;
      }
      var a = await PostNote(
        NewText,
        window.$NoteCategoryId,
        window.$NoteAccessLevelId
      );
      getNots(exceptionId);
      setTimeout(() => {
        setNewText("");
        $("#NewTextNots").val("");
      }, 50);
    }
  }
  //Exception Get List API
  const [exceptionsLists, setExceptionsList] = useState([]);
  async function GetExceptionsLists() {
    return await fetch(
      `${window.$apiurl}/sku/exceptions?id=${id}&isActive=null`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        const currentList = res[1];
        const list = [];
        currentList.map((item) => {
          list.push({
            value: item.RequestId,
            label: `Exception #: ${item.RequestId}`,
          });
        });
        setExceptionsList(list);
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostNote(bodytext, cat, accessLevelId) {
    return await fetch(`${window.$apiurl}${post}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ExceptionId:
          exceptionId !== undefined ? exceptionId : selectedException.value,
        Body: bodytext,
        CategoryId: cat || (IsExceptionLulus ? 4 : ""),
        AccessLevelId: accessLevelId || (IsExceptionLulus ? 4 : ""),
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note has been saved");
      })
      .catch((error) => {
        Notification("success", "Note has been saved");
      });
  }

  function CardFunction({ x, index }) {
    const [Showing, setShowing] = useState(false);
    const [Text, setText] = useState("");
    const [Category, setCategory] = useState("");
    const [categoryPut, setCategoryPut] = useState({
      value: "4",
      label: "Exception",
    });

    const [titleBtnDeletePut, setTitleBtnDeletePut] = useState("Delete");

    async function PutNote(id, bodytext, CategoryId) {
      return await fetch(`${window.$apiurl}${put}${id}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: bodytext, CategoryId: CategoryId , AccessLevelId : 4 }),
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then(async (res) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        })
        .catch((error) => {
          Notification("success", "Note has been saved");
          setShowing(false);
        });
    }

    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <span className="mb-2 text-primary d-inline">
              {momentDate(x?.CreatedAt)}
            </span>
            <br />
            {x?.CategoryId !== null &&
              CategorysList.find((y) => y.Id === x?.CategoryId)?.Name !==
                undefined && (
                <div className="mb-2 mt-3 text-muted">
                  <i class="fas fa-book mr-2"></i>{" "}
                  {CategorysList.find((y) => y.Id === x?.CategoryId)?.Name}
                </div>
              )}
            <Card.Text className="mt-5 ml-5">
              {!Showing && (!Text ? x?.Body : Text)}
              {Showing && (
                <>
                  <div className="mb-5">
                    <label>Select Category</label>
                    <CreatableSelect
                      name="CategoryId"
                      label="Select Category"
                      value={categoryPut}
                      options={CategorysList.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(newValue) => {
                        var a = newValue?.value;
                        setCategory(a);
                        setCategoryPut(newValue);
                      }}
                    />
                  </div>
                  <div>
                    <label>Text</label>
                    <textarea
                      className="form-control mb-5"
                      rows="4"
                      defaultValue={!Text ? x?.Body : Text}
                      onBlur={(event) => {
                        setText(event.target.value);
                      }}
                    ></textarea>
                  </div>
                </>
              )}
            </Card.Text>
            {db.getState().User?.Email === x?.UserEmail && (
              <div
                className="text-right"
                style={{ position: "absolute", bottom: "20px", right: "10px" }}
              >
                <button
                  type="button"
                  class="btn btn-light-primary mr-2"
                  onClick={async () => {
                    if (Showing) {
                      await PutNote(
                        x?.Id,
                        Text === "" ? x?.Body : Text,
                        Category === "" ? x?.CategoryId : Category
                      );
                      getNots(exceptionId);
                    } else {
                      setTitleBtnDeletePut("Close");
                      setShowing(true);
                    }
                  }}
                >
                  {!Showing && (
                    <>
                      <i class="fas fa-edit mr-2"></i> Edit
                    </>
                  )}
                  {Showing && (
                    <>
                      <i class="fas fa-save mr-2"></i> Save
                    </>
                  )}
                </button>
                {db.getState().User?.Email === x?.UserEmail && (
                  <button
                    type="button"
                    class="btn btn-light-danger mr-2 ml-3"
                    onClick={async () => {
                      if (titleBtnDeletePut === "Delete") {
                        await DeleteNote(x?.Id);
                        Notification("success", "Note is Deleted successfully");
                        getNots(exceptionId);
                      } else {
                        setTitleBtnDeletePut("Delete");
                        setShowing(false);
                      }
                    }}
                  >
                    {titleBtnDeletePut === "Delete" && (
                      <i class="fas fa-trash mr-2"></i>
                    )}
                    {titleBtnDeletePut === "Close" && (
                      <i class="fas fa-times"></i>
                    )}
                    {titleBtnDeletePut}
                  </button>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getNots(exceptionId) {
    var a = await GetNotes();
      setNotesList(
        a?.Notes.filter(
          (x) => x.AccessLevelId === 4 && x.ExceptionId === exceptionId
        ) || []
      );
    setCategorysList(a?.Categories || []);
    setAccessLevels(a?.AccessLevels || []);
  }

  async function DeleteNote(id) {
    return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
      })
      .catch((err) => {
        console.error(err);
        // Notification("error", "Invalid request");
      });
  }
  const [selectedAccessLevel, setSelectedAccessLevel] = useState({
    value: "",
    label: "Select",
  });
  const [selectedCategory, setCategoryPost] = useState({
    value: "",
    label: "Select",
  });
  const [selectedException, setExceptionPost] = useState({
    value: "",
    label: "Select",
  });

  return (
    <>
      {/* <div className=" m-auto mb-5">
        <button
          type="button"
          class="btn btn-light-primary mr-2 w-100 mb-5"
          onClick={async () => {
            $(".NotesId").toggle("slow");
            if (IsExceptionLulus && id) {
              await GetExceptionsLists();
            }
          }}
        >
          <i class="fas fa-plus mr-2"></i> Add New Note
        </button>
      </div> */}

      <div className=" m-auto mb-5 NotesId">
        <label>Text</label>
        <textarea
          className="form-control mb-5"
          rows="4"
          id="NewTextNots"
          onBlur={(e) => setNewText(e.target.value)}
        ></textarea>
        <div className="row">
          <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "col-lg-3 mb-5 w-100 mt-5"
                : "mb-5 col-lg-6 w-100 mt-5"
            }
          >
            <label>Select Category</label>
            <CreatableSelect
              name="CategoryId"
              label="Select Category"
              value={
                IsExceptionLulus && selectedCategory.value === ""
                  ? { value: 4, label: "Exception" }
                  : selectedCategory
              }
              options={CategorysList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteCategoryId = a;
                setisSave(a);
                setCategoryPost(newValue);
              }}
            />
          </div>

          <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "col-lg-3 mb-5 w-100 mt-5"
                : "mb-5 col-lg-6 w-100 mt-5"
            }
          >
            <label>Access Level</label>
            <CreatableSelect
              name="AccessLevelId"
              id="AccessLevelId"
              label="Access Level"
              options={AccessLevels.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              value={
                IsExceptionLulus && selectedAccessLevel.value === ""
                  ? { value: 4, label: "Public" }
                  : selectedAccessLevel
              }
              onChange={(newValue) => {
                var a = newValue?.value;
                window.$NoteAccessLevelId = a;
                setisSave(a);
                setSelectedAccessLevel(newValue);
              }}
              placeholder="Access Level"
            />
          </div>
          {/* <div
            className={
              IsExceptionLulus && !isLulusDashboard
                ? "m-auto col-lg-3 text-center"
                : "m-auto col-lg-4 text-center"
            }
          >
            <label className="text-white">.</label>
            <button
              type="button"
              class="btn btn-light-success mr-2 w-100 mb-5 m-auto"
              onClick={async () => {
                if (NewText === "") Notification("warning", "Text is Empty");
                else {
                  if (
                    isLulusDashboard &&
                    (exceptionId === "" ||
                      exceptionId === undefined ||
                      exceptionId === null)
                  ) {
                    Notification("error","You should Select Exception first to add a note");
                    return false;
                  }
                  var a = await PostNote(
                    NewText,
                    window.$NoteCategoryId,
                    window.$NoteAccessLevelId
                  );
                  getNots(exceptionId);
                  setTimeout(() => {
                    setNewText("");
                    $(".NotesId").toggle("slow");
                    $("#NewTextNots").val("");
                  }, 50);
                }
              }}
            >
              <i class="fas fa-save mr-2"></i> Save
            </button>
          </div> */}
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>

      {NotesList?.map((x, index) => (
        <CardFunction x={x} key={index} index={index} New={() => getNots()} />
      ))}
    </>
  );
}
