import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import { Notification } from "../../../../modules/Notification";
import { Alert, Accordion } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import Icon from "@material-ui/core/Icon";
import clsx from "clsx";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({
  ContainerId,
  PalletsIds,
  setLoading,
  setPalletsFun,
  ClosedPallet,
  Search,
  Location,
  SavePalletInLocation,
  RemovePalletInLocation,
  PalletsinLocation,
  activeBtn,
  setSendEmailModal,
  setEditItem,
  setSKUsList,
}) {
  const [Table, setTable] = useState([]);
  const [DeletRowId, setDeletRowId] = useState({ id: 0, number: 0 });
  const [selectedShip, setselectedShip] = useState(false);
  const [InTable, setInTable] = useState({ id: 0, number: 0 });
  const [OSDList, setOSDList] = useState([]);
  const [RemoveSkuId, setRemoveSkuId] = useState();

  function RemoveSku(id) {
    setRemoveSkuId();
    setTimeout(() => {
      setRemoveSkuId(id);
    }, 1);
  }

  function SetTableShipment(id, Quantity) {
    if (id) {
      setInTable((x) => {
        return { id: +id, number: x.number + 1 };
      });
      setTimeout(() => {
        var el1 = document.querySelector(
          ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
        );
        if (el1) {
          var id = el1?.parentElement?.getAttribute("id")?.split("id")[1];
          var number = el1?.parentElement?.getAttribute("data-number");

          if (!window?.reciept) return false;

          var reciept = window.reciept?.find((x) => x?.Id === +id);
          if (reciept) {
            var a = reciept?.LineItems.filter((x) => x.Sku?.includes(Search));
            var b = a.filter((x) => x?.LeftQuantity > 0);

            if (b?.length === 0) {
              updatestatus(id);
              setSendEmailModal({ Id: id, containerNumber: number });
            }
          }
        }

        if (window.BtnSku) {
          var btnSku1 = document.getElementById(window.BtnSku + "2");
          if (btnSku1) btnSku1.click();
        }
      }, 1000);
    }
  }

  async function DeleteShipmentRow(id, IdShip, BackItem = true) {
    return await RemovePallet(id, IdShip).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        setTimeout(() => {
          setDeletRowId((x) => {
            return { id: BackItem ? IdShip : 0, number: x.number + 1 };
          });
        }, 1);
        return true;
      } else {
        Notification("error", Message?.toString());
        return false;
      }
    });
  }

  return (
    <>
      <div className="row">
        <div className={"col-6 my-5 " + (activeBtn === 0 ? "" : "d-none")}>
          {ContainerId && (
            <IndexPalletsList
              SetTableShipment={SetTableShipment}
              setLoading={setLoading}
              DeletRowId={DeletRowId}
              ContainerId={ContainerId}
              setPalletsFun={setPalletsFun}
              Search={Search}
              setSendEmailModal={setSendEmailModal}
              setEditItem={setEditItem}
              OSDList={OSDList}
              RemoveSku={RemoveSku}
              setSKUsList={setSKUsList}
            />
          )}
        </div>

        <div className="col-6 my-5">
          <ShipmentFun
            Table={Table}
            ShipmentsID={PalletsIds}
            setselectedShip={setselectedShip}
            DeleteShipmentRow={DeleteShipmentRow}
            InTable={InTable}
            setDeletRowId={setDeletRowId}
            setLoading={setLoading}
            ClosedPallet={ClosedPallet}
            SavePalletInLocation={SavePalletInLocation}
            activeBtn={activeBtn}
            setOSDList={setOSDList}
            RemoveSkuId={RemoveSkuId}
          />
        </div>
        <div className={"col-6 my-5 " + (activeBtn === 1 ? "" : "d-none")}>
          <LocationFun
            Location={Location}
            PalletsinLocation={PalletsinLocation}
            RemovePalletInLocation={RemovePalletInLocation}
          />
        </div>
      </div>
    </>
  );
}

async function RemovePallet(ItemId, PalletId) {
  return await fetch(`${window.$apiurl}/Pallets/DeleteItemFromPallet`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      PalletId: PalletId,
      ItemId: ItemId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Shipment Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}

function ShipmentFun({
  Table = [],
  ShipmentsID,
  setselectedShip,
  DeleteShipmentRow,
  InTable,
  setDeletRowId,
  setLoading,
  ClosedPallet,
  SavePalletInLocation,
  activeBtn,
  setOSDList,
  RemoveSkuId,
}) {
  const [selectedId, setselectedId] = useState(false);

  useEffect(() => {
    setselectedShip(selectedId);
  }, [selectedId]);

  return (
    <div className="sticky-po">
      <div className="row px-5">
        <div className="col-12 alert alert-info" role="alert">
          <b>Pallets</b>
        </div>

        {ShipmentsID.map((x, i) => (
          <TableComponent
            key={i}
            list={Table}
            ShipmentID={x}
            hidden={+selectedId !== +x}
            selectedShip={+selectedId}
            DeleteShipmentRow={DeleteShipmentRow}
            InTable={InTable}
            setDeletRowId={setDeletRowId}
            setLoading={setLoading}
            ClosedPallet={ClosedPallet}
            SavePalletInLocation={SavePalletInLocation}
            activeBtn={activeBtn}
            setOSDList={setOSDList}
            RemoveSkuId={RemoveSkuId}
          />
        ))}
      </div>
    </div>
  );
}

function LocationFun({ Location, PalletsinLocation, RemovePalletInLocation }) {
  return (
    <div>
      <Accordion defaultActiveKey="0">
        <div className="row px-5">
          {PalletsinLocation.map((x, i0) => (
            <>
              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey={i0}
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">
                    Warehouse Location {x?.location?.LocationId} - Count :{" "}
                    {x.pallets?.length ?? 0}
                  </h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={i0} className="w-100">
                <div
                  id={"LocationElement" + x?.location?.ConsigneeId}
                  data-id={x?.location?.Id}
                  className="col-12 alert alert-secondary text-center row ml-0"
                  role="alert"
                  style={{ minHeight: "300px" }}
                >
                  {x.pallets?.length > 0 ? (
                    <>
                      {x.pallets.map((x1, i) => (
                        <TableComponent
                          key={i}
                          list={[]}
                          ShipmentID={x1}
                          IsLocationZone={true}
                          RemovePalletInLocation={RemovePalletInLocation}
                        />
                      ))}
                    </>
                  ) : (
                    <b
                      className="text-muted"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50% , -50%)",
                        fontSize: "23px",
                      }}
                    >
                      Please Select Location
                    </b>
                  )}
                </div>
              </Accordion.Collapse>
            </>
          ))}
        </div>
      </Accordion>
    </div>
  );
}

async function updatestatus(id) {
  return await fetch(`${window.$apiurl}/receiptsmanagement/updatestatus`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ Ids: [id], StatusId: 21 }),
  }).then(async (response) => {
    if (response.status === 200) {
      Notification("success", "update status To Devannded");
    } else {
      var msg = response.status !== 200 ? await response.json() : "";
      Notification("error", msg?.toString());
    }
  });
}
