import MockUtils from "../mock.utilsDynamic";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockVesselVoyage(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (res[0] === 200) {
      window.vesselVoyageErrormsg = false;
      Notification("success", "success");
      return true;
    } else if (res[0] === 400) {
      if (
        res[1].Message !== null &&
        res[1].Message !== undefined &&
        res[1].Message !== ""
      ) {
        Notification("errorClick", res[1].Message);
      } else {
        Notification("errorClick", "Bad Request");
      }
      window.vesselVoyageErrormsg = true;
      return false;
    }
  }

  async function GetModel(
    dateFrom,
    dateTo,
    pickUpLocationId,
    vesselId,
    statusId,
    exception,
    isMerged
  ) {
    const params = `?dateFrom=${dateFrom}&dateTo=${dateTo}&pickupLocationId=${pickUpLocationId}&vesselId=${vesselId}&statusId=${
      statusId === undefined ? "" : statusId
    }&exception=${exception === undefined ? "" : exception}`;
    window.$paramsVVExceptions = params;
    return await fetch(
      `${window.$apiurl}/VoyageManagement/GetVesselVoyages?dateFrom=${dateFrom}&dateTo=${dateTo}&pickupLocationId=${pickUpLocationId}&vesselId=${vesselId}&statusId=${statusId}&exception=${exception}&isMerged=${isMerged}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/vesselvoyage/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const vesselOrders = res[1]["VoyageOrders"];
        var a = res[1]["Voyage"];
        a.VoyageOrders = vesselOrders;
        a.id = a.Id;
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(`${window.$apiurl}/vesselvoyage`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(`${window.$apiurl}/vesselvoyage/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(`${window.$apiurl}/vesselvoyage/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewModel() {
    return await fetch(`${window.$apiurl}/VoyageManagement/GetViewModel`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet("api/VesselVoyage/GetAllApi").reply(async () => {
    const obj = await GetViewModel();
    return [200, obj];
  });

  mock.onPost("api/VesselVoyage").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      VesselId = undefined,
      Name = undefined,
      EstimatedTimeArrival = undefined,
      SailDate = undefined,
      ArrivalDate = undefined,
      PickUpLocationId = null,
      TerminalCode = "",
      TerminalName = "",
      PortCode = "",
    } = customer;

    const newCustomer = {
      VesselId,
      Name,
      EstimatedTimeArrival,
      SailDate,
      TerminalCode,
      PickUpLocationId,
      TerminalName,
      PortCode,
      ArrivalDate,
    };
    var postModel = await PostModel(newCustomer);
    window.VesselVoyageData = undefined;
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/VesselVoyage/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const _filter = queryParams;
    var dateFrom = queryParams.filter.DateFrom;
    var dateTo = queryParams.filter.DateTo;
    var pickUpLocationId = queryParams.filter.PickupLocation;
    var vesselId = queryParams.filter.Vessel;
    var statusId = window.$VVStatusName;
    var exception =
      window.$VVExceptionName === undefined ? "" : window.$VVExceptionName;
    var isMerged = queryParams.filter.IsMerged;
    const result = await GetModel(
      dateFrom,
      dateTo,
      pickUpLocationId,
      vesselId,
      statusId,
      exception,
      isMerged
    );
    if (result.Voyages !== undefined) {
      try {
        if (window.$VVExceptionName === "duplicated-voyages") {
          result.Voyages.length > 0 &&
            result.Voyages.map((item, index) => {
              item.Id = index + 1;
            });
          customerTableMock = result.Voyages;
        } else {
          customerTableMock = result.Voyages;
        }
      } catch (err) {}
    }
    // window.VesselVoyageData = result;
    if (_filter.filter.id && window.$VVExceptionName != "duplicated-voyages") {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Id)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.Name)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.PortCode)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.TerminalCode)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.TerminalName)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.VesselName)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            )
      );
    } else {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.VesselId)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            ) ||
          String(word.VesselName)
            .toLowerCase()
            .trim()
            .includes(
              String(_filter.filter.id || "")
                .toLowerCase()
                .trim()
            )
      );
    }
    if (_filter.filter.PickupLocation) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.PickUpLocationId)
          .toLowerCase()
          .trim()
          .includes(
            String(_filter.filter.PickupLocation)
              .toLowerCase()
              .trim()
          )
      );
    }
    if (_filter.filter.PortCode) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.PortCode)
          .toLowerCase()
          .trim()
          .includes(
            String(_filter.filter.PortCode)
              .toLowerCase()
              .trim()
          )
      );
    }
    if (window.$statusCountAll === undefined) {
      const listStatus = result.Status;
      var countAll = 0;
      listStatus.length > 0 &&
        listStatus.map((item) => {
          countAll += item.Count;
        });
      window.$StatsListVesselVoyages = {
        CountAll: countAll,
        Status: result.Status,
      };
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.PickupLocation;
    delete queryParams.filter.VesselId;
    delete queryParams.filter.searchText;
    delete queryParams.filter.DateFrom;
    delete queryParams.filter.id;
    delete queryParams.filter.DateTo;
    delete queryParams.filter.Vessel;
    delete queryParams.filter.IsMerged;
    delete queryParams.filter.PortCode;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/VesselVoyage/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    var VesselId = queryParams.filter.VesselId;
    const _filter = queryParams;
    var dateFrom = queryParams.filter.DateFrom;
    var dateTo = queryParams.filter.DateTo;
    var pickUpLocationId = queryParams.filter.PickupLocation;
    var vesselId = queryParams.filter.Vessel;
    var statusId = window.$VVStatusName;
    var exception =
      window.$VVExceptionName === undefined ? "" : window.$VVExceptionName;
    var isMerged = queryParams.filter.IsMerged;
    const result = await GetModel(
      dateFrom,
      dateTo,
      pickUpLocationId,
      vesselId,
      statusId,
      exception,
      isMerged
    );
    customerTableMock = result.Voyages;
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.VesselId;
    delete queryParams.filter.IsMerged;
    window.VesselVoyageData = undefined;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });
  mock.onPost("api/VesselVoyage/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    window.VesselVoyageData = undefined;
    return [200];
  });

  mock.onPost("api/VesselVoyage/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    window.VesselVoyageData = undefined;
    return [200];
  });

  mock.onGet(/api\/VesselVoyage\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/VesselVoyage\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/VesselVoyage\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/VesselVoyage\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutModel(customer, id);
    if (!putObj) {
      return [400];
    }
    window.VesselVoyageData = undefined;
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/VesselVoyage\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/VesselVoyage\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    window.VesselVoyageData = undefined;
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
