/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import EnhancedTable from "./EnhancedTable";

export function ShippedOrdersComp({
  className,
  ShippedOrdersCount = 0,
  NotShippedOrdersCount = 0,
  ShippedOrders = [],
  NotShippedOrders = [],
}) {
  const [tabe, settabe] = useState(0);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_shipment_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(
      layoutProps,
      ShippedOrdersCount,
      NotShippedOrdersCount
    );
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, ShippedOrdersCount, NotShippedOrdersCount]);

  function percentage(a, b) {
    if (a + b !== 0) return ((100 * a) / (a + b)).toFixed(0);
    else return 0;
  }

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">Containers</h3>
        <div className="card-toolbar">
          <Dropdown className="dropdown-inline" alignRight>
            <Dropdown.Toggle
              className="btn btn-clean btn-hover-light-primary btn-sm btn-icon"
              variant="transparent"
              id="dropdown-toggle-top"
              as={DropdownCustomToggler}
            >
              <i className="ki ki-bold-more-hor" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
              <DropdownMenu4 />
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="card-body d-flex flex-column p-0">
        <div className="row">
          <div className="col-lg-7">
            <div
              id="kt_shipment_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "150px" }}
            ></div>
          </div>
          <div className="col-lg-5 mt-5">
            <div className="row pt-5 mt-5">
              <div className="col-lg-12">
                <div className="d-flex align-items-center mb-10">
                  <div className="symbol symbol-40 symbol-light-primary mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-primary">
                        {ShippedOrdersCount + NotShippedOrdersCount === 0
                          ? 0
                          : 100}
                        %
                      </span>
                    </span>
                  </div>

                  <div className="d-flex flex-column font-weight-bold">
                    <a
                      href="#"
                      className="text-dark text-hover-primary mb-1 font-size-lg"
                    >
                      Received
                    </a>
                    <span className="text-muted">
                      {ShippedOrdersCount + NotShippedOrdersCount}
                      {/*  Orders */}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <div className="symbol symbol-40 symbol-light-success mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-success">
                        {percentage(ShippedOrdersCount, NotShippedOrdersCount)}%
                      </span>
                    </span>
                  </div>
                  <div className="d-flex flex-column font-weight-bold">
                    <a
                      onClick={() => {
                        settabe(2);
                      }}
                      className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                    >
                      Shipped{/*  Orders */}
                    </a>
                    <span className="text-muted">
                      {ShippedOrdersCount}
                      {/*  Orders */}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <div className="symbol symbol-40 symbol-light-danger mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-danger">
                        {percentage(NotShippedOrdersCount, ShippedOrdersCount)}%
                      </span>
                    </span>
                  </div>

                  <div className="d-flex flex-column font-weight-bold">
                    <a
                      onClick={() => {
                        settabe(4);
                      }}
                      className="text-dark text-hover-primary mb-1 font-size-lg"
                    >
                      Not Shipped{/*  Orders */}
                    </a>
                    <span className="text-muted">
                      {" "}
                      {NotShippedOrdersCount}
                      {/*  Orders */}
                    </span>
                  </div>
                </div>

                <div className="d-flex align-items-center mb-10">
                  <div className="symbol symbol-40 symbol-light-warning mr-5">
                    <span className="symbol-label">
                      <span className="svg-icon svg-icon-lg svg-icon-warning">
                        0%
                      </span>
                    </span>
                  </div>

                  <div className="d-flex flex-column font-weight-bold">
                    <a
                      href="#"
                      className="text-dark text-hover-primary mb-1 font-size-lg"
                    >
                      Empty Shipped{/*  Orders */}
                    </a>
                    <span className="text-muted">0{/*  Orders */}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {tabe === 2 && ShippedOrders.length > 0 && (
            <div className="col-lg-12">
              <EnhancedTable
                title="Shipped Orders"
                color="success"
                Data={ShippedOrders}
              />
            </div>
          )}
          {tabe === 4 && NotShippedOrders.length > 0 && (
            <div className="col-lg-12">
              <EnhancedTable
                title="Not Shipped Orders"
                color="danger"
                Data={NotShippedOrders}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  function getChartOption(
    layoutProps,
    ShippedOrdersCount,
    NotShippedOrdersCount
  ) {
    const options = {
      series: [
        ShippedOrdersCount + NotShippedOrdersCount === 0 ? 0 : 100,
        percentage(ShippedOrdersCount, NotShippedOrdersCount),
        0,
        percentage(NotShippedOrdersCount, ShippedOrdersCount),
      ],
      chart: {
        height: 400,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "22px",
            },
            value: {
              fontSize: "16px",
            },
            track: {
              background: ["#fff", "#e4e4e4"],
            },
            total: {
              show: true,
              label: "Received",
              formatter: function(w) {
                // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                return ShippedOrdersCount + NotShippedOrdersCount;
              },
            },
          },
        },
      },
      labels: ["Received", "Shipped Orders", "Not Total Orders"],
    };
    return options;
  }
}
