import React from 'react'
import { Button, Form, Card, Col } from "react-bootstrap";

export function ManageCommoditiesItems() {
    return (

        <Card>
            <Card.Body>

                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">NMFC</th>
                            <th scope="col">Class</th>
                            <th scope="col">Discription</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>697300</td>
                            <td>90220</td>
                            <td>037860</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>100</td>
                            <td>50</td>
                            <td>55</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>CHOPPED CLAMS IN CANS</td>
                            <td>GARNET ABRASIVE</td>
                            <td>HARDWOOD FLOORING</td>
                        </tr>
                    </tbody>
                </table>

                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Button as="input" type="submit" value="Add" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Button as="input" type="submit" value="Import" />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Button as="input" type="submit" value="Delete" />
                        </Form.Group>
                    </Form.Row>
                </Form>

            </Card.Body>
        </Card>
    )
}
