import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import {
  Input,
  Select,
  CheckboxLarge,
} from "../../../_metronic/_partials/controls";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Notification } from "../../modules/Notification";
import { Link } from "react-router-dom";
import $ from "jquery";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
const CustomerEditSchema = Yup.object().shape({});

function SetValuefunction(id, value) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
    }, 500);
}

export default function UpdateShipmentStatus({
  Close,
  ShipmentId,
  StatusId,
  SaveDone,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose(id) {
    filterStatus(id);
    setAnchorEl(null);
  }
  const { customerForEdit, actionsLoading } = useSelector(
    (state) => ({
      customerForEdit: state.customers.customerForEdit,
      actionsLoading: state.customers.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");
  const [customer, setcustomer] = useState();
  const [ShipmentParentStatus, setShipmentParentStatus] = useState([]);
  const [ShipmentStatus, setShipmentStatus] = useState([]);
  const [ShipmentAccessLevels, setShipmentAccessLevels] = useState([]);
  const [ShipmentStatusSumParent, setShipmentStatusSumParent] = useState([]);
  const [ShipmentStatusColumn, setShipmentStatusColumn] = useState([]);
  const [StatusStatId, setStatusStatId] = useState();
  const [StatusStatId2, setStatusStatId2] = useState(-1);

  useEffect(() => {
    if (ShipmentId) {
      setTitle(ShipmentId);
      fetchShipmentDetailAsync(ShipmentId);
    }
  }, [ShipmentId]);

  useEffect(() => {
    const a =
      ShipmentStatusSumParent.filter((x) => x.Id === StatusStatId2)[0]
        ?.Columns || [];
    const b = [];
    a.forEach((element) => {
      b.push(element?.Name);
    });
    setShipmentStatusColumn(b);
  }, [StatusStatId2]);

  useEffect(() => {
    var ActualPickupDate = customer?.ActualPickupDate || false;
    if (ActualPickupDate) {
      SetValuefunction("ActualPickupDateId", ActualPickupDate);
    }
    var accessLevelId = customer?.AccessLevel || false;
    if (!accessLevelId) {
      SetValuefunction("AccessLevelId", "2");
    }
    var ProofOfDeliveryDate = customer?.ProofOfDeliveryDate || false;
    if (ProofOfDeliveryDate) {
      SetValuefunction("ProofOfDeliveryDateId", ProofOfDeliveryDate);
    }
    var statusId = customer?.StatusId || false;
    if (statusId) {
      setStatusStatId2(statusId);
    }
    var activityNotes = customer?.ActivityNotes || false;
    if (activityNotes) {
      SetValuefunction("NotesId", activityNotes);
    }
    var Arrival = customer?.Arrival || false;
    if (Arrival) {
      SetValuefunction("ArrivalId", Arrival);
    }
    var Departure = customer?.Departure || false;
    if (Departure) {
      SetValuefunction("DepartureId", Departure);
    }
    var DeliveryEstimatedDateTime =
      customer?.DeliveryEstimatedDateTime || false;
    if (DeliveryEstimatedDateTime) {
      SetValuefunction(
        "DeliveryEstimatedDateTimeId",
        DeliveryEstimatedDateTime
      );
    }
    var PickupReadyDateTime = customer?.PickupReadyDateTime || false;
    if (PickupReadyDateTime) {
      SetValuefunction("PickupReadyDateTimeId", PickupReadyDateTime);
    }
    var StatusIdValue = customer?.StatusId || false;
    if (StatusIdValue) {
      setStatusStatId2(StatusIdValue);
    }
  }, [customer]);

  const fetchShipmentDetailAsync = async (id) => {
    try {
      const result = await axios.post("api/FindShipments/ShipmentDetail", {
        data: id,
      });
      const c = result.data["Shipment"] || [];
      const shipmentParent = result.data["ShipmentParentStatus"] || [];
      const s = result.data["ShipmentStatus"] || [];
      const s1 = s.filter((x) => x.Id === c.StatusId)[0];
      const s2 = s.filter((x) => x.ParentId === shipmentParent[StatusId].Id);
      const index = s2.indexOf(s1);
      const s3 = s2.filter((x, i) => index - 2 < i && i < index + 2);
      setcustomer(c);
      setShipmentParentStatus(shipmentParent);
      setStatusStatId(shipmentParent[StatusId].Id);
      setShipmentStatus(s3);
      setShipmentStatusSumParent(s);
      setShipmentAccessLevels(result.data["ShipmentAccessLevels"] || []);

      setTimeout(() => {
        setStatusStatId2(-1);
        setStatusStatId2(c.StatusId);

        var isCHild =
          s.filter(
            (x) =>
              x.Id === c.StatusId && x.ParentId === shipmentParent[StatusId].Id
          ).length === 0;

        if (isCHild)
          $(".buttonStatusId")
            .eq(0)
            .click();
      }, 500);
    } catch (e) {}
  };

  const [StartTrackingState, setStartTrackingState] = useState(false);
  const fetchStartTracking = async (id) => {
    try {
       await axios.post("api/FindShipments/StartTracking", {
        data: id,
      });
      setStartTrackingState(true);
    } catch (e) {}
  };

  const filterStatus = (id) => {
    const s = ShipmentStatusSumParent || [];
    const a = s.filter((x) => x.Id === id)[0];
    const s2 = s.filter((x) => x.ParentId === a?.ParentId);
    const index = s2.indexOf(a);
    const s3 = s2.filter((x, i) => index - 2 < i && i < index + 2);
    setShipmentStatus(s3);
  };

  const fetchUpdateAsync = async (values) => {
    try {
     await axios.post("api/FindShipments/UpdateStatus", {
        data: values,
      });
      SaveDone();
    } catch (e) {}
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          values.ShipmentId = ShipmentId;
          if (StatusStatId2 !== -1) values.StatusId = StatusStatId2;
          var accessLevelId = values?.AccessLevelId;
          if (+accessLevelId === 0) {
            Notification("error", "Fields * is required");
            return false;
          }
          fetchUpdateAsync(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Header closeButton className="w-100">
              <div className="row w-100">
                <div className="col-lg-4">
                  <Modal.Title className="h2 mt-1">
                    Update Shipment Status : {title}
                  </Modal.Title>
                </div>
                <div className="col-lg-8 text-right">
                  <ButtonGroup
                    color="secondary"
                    size="large"
                    aria-label="Large outlined secondary button group"
                  >
                    {ShipmentParentStatus.map((item, index, arr) => (
                      <Button
                        className={
                          item.Id !== StatusStatId
                            ? ""
                            : "bg-primary text-white"
                        }
                      >
                        {item.Name}
                        {ShipmentParentStatus.length !== index + 1 && (
                          <i
                            className={`fas fa-arrow-right ml-2 text-${
                              item.Id !== StatusStatId ? "primary" : "white"
                            }`}
                          ></i>
                        )}
                      </Button>
                    ))}
                  </ButtonGroup>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row d-none">
                  <div className="col-lg-4">
                    <Field
                      name="StatusName"
                      component={Input}
                      placeholder="Driver Cell"
                      label="Driver Cell"
                      type="tel"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select name="shipment" label="Shipment Tracker">
                      <option value="1">Shipment 1</option>
                      <option value="2">Shipment</option>
                    </Select>
                  </div>
                  <div className="col-lg-4">
                    <Select name="update" label="Update Every">
                      <option value="0">Update1</option>
                      <option value="1">Update2</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row d-none">
                  <div className="col-lg-4">
                    <CheckboxLarge
                      label=" "
                      text="Require Consignee Signature"
                      name="Consignee"
                    />
                  </div>
                  <div className="col-lg-3">
                    <CheckboxLarge
                      label=" "
                      text="Require POD Image"
                      name="POD"
                    />
                  </div>
                </div>
                <div className="form-group row d-none">
                  <div className="col-lg-12">
                    <button type="button" className="btn btn-primary w-100">
                      Start Tracking
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-lg-4">
                      <p>
                        Carrier Name :{" "}
                        <Link to="#">{customer?.CarrierName | ""}</Link>
                      </p>
                    </div>
                    <div className="col-lg-4">
                      <p>Carrier Phone : </p>
                    </div>
                    {/* <button
                      type="button"
                      class="btn btn-success w-100"
                      disabled={customer?.TrackingEnabled || StartTrackingState}
                      onClick={() => {
                        fetchStartTracking(customer?.Id);
                      }}
                    >
                      Start Tracking{" "}
                      {(customer?.TrackingEnabled || StartTrackingState) &&
                        "( Enabled )"}
                    </button> */}
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className={`col-lg-${
                      ShipmentStatusColumn.includes("ProofOfDeliveryDate")
                        ? "12"
                        : "6"
                    } mt-5 `}
                  >
                    <Field
                      name="ProNumber"
                      component={Input}
                      placeholder="PRO Number"
                      label="PRO Number"
                    />
                  </div>
                  <div
                    className={`col-lg-6 mt-5 ${
                      ShipmentStatusColumn.includes(
                        "LinehaulCarrierPickupNumber"
                      )
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <Field
                      name="LinehaulCarrierPickupNumber"
                      component={Input}
                      placeholder="Lineheul Carrier Pickup Number"
                      label="Lineheul Carrier Pickup Number"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div
                    className={`col-lg-6 mt-5 ${
                      ShipmentStatusColumn.includes("ProofOfDeliveryDate")
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <label>Proof of Delivery Date</label>
                    <TextField
                      id="ProofOfDeliveryDateId"
                      type="date"
                      className={`w-100 ${classes.textField}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(event) => {
                        setFieldValue(
                          "ProofOfDeliveryDate",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  <div
                    className={`col-lg-6 mt-5 ${
                      ShipmentStatusColumn.includes("ActualPickupDate")
                        ? ""
                        : "d-none"
                    }`}
                  >
                    <label>Actual Pickup Date</label>
                    <TextField
                      id="ActualPickupDateId"
                      type="date"
                      className={`w-100 ${classes.textField}`}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(event) => {
                        setFieldValue("ActualPickupDate", event.target.value);
                      }}
                    />
                  </div>
                  <div
                    className={`mt-5 col-lg-${
                      ShipmentStatusColumn.includes("ProofOfDeliveryDate")
                        ? "6"
                        : "3"
                    } ${
                      ShipmentStatusColumn.includes("Arrival") ? "" : "d-none"
                    }`}
                  >
                    <label>Arrival</label>
                    <TextField
                      id="ArrivalId"
                      type="time"
                      className={[classes.textField, "w-100"]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                      }}
                      onBlur={(event) => {
                        setFieldValue("Arrival", event.target.value);
                      }}
                    />
                  </div>

                  <div
                    className={`mt-5 col-lg-3 ${
                      ShipmentStatusColumn.includes("Departure") ? "" : "d-none"
                    }`}
                  >
                    <label>Departure</label>
                    <TextField
                      id="DepartureId"
                      type="time"
                      className={[classes.textField, "w-100"]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                      onBlur={(event) => {
                        setFieldValue("Departure", event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="Location"
                      component={Input}
                      placeholder="Location"
                      label="Location"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 px-0">
                    <label>Activity Notes</label>
                    {/* <Field
                      name="ActivityNotes"
                      as="textarea"
                      className="form-control"
                      onChange={(event) => {
                        setFieldValue("Notes", event.target.value);
                      }}
                    /> */}
                    <textarea
                      rows="3"
                      class="form-control"
                      id="NotesId"
                      onBlur={(e) => {
                        setFieldValue("Notes", e.target.value);
                      }}
                    ></textarea>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label className="text-danger">Access Level *</label>
                    <Select name="AccessLevelId" id="AccessLevelId">
                      <option value="0">Select</option>
                      {ShipmentAccessLevels.map((item, index) => (
                        <option key={index} value={item.Id}>
                          {item.Name}
                        </option>
                      ))}
                    </Select>
                    {ShipmentAccessLevels.length === 0 && <LinearProgress />}
                  </div>
                  <div className="col-lg-4 align-self-center">
                    <label>Pickup Ready Date/ Time</label>
                    <TextField
                      id="PickupReadyDateTimeId"
                      type="datetime-local"
                      className={[classes.textField, "w-100"]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(event) => {
                        setFieldValue(
                          "PickupReadyDateTime",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-4 align-self-center">
                    <label>Delivery Estimated Date/ Time</label>
                    <TextField
                      id="DeliveryEstimatedDateTimeId"
                      type="datetime-local"
                      className={[classes.textField, "w-100"]}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onBlur={(event) => {
                        setFieldValue(
                          "DeliveryEstimatedDateTime",
                          event.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <div className="row w-100">
                <div className="col-lg-10 text-left">
                  <ButtonGroup
                    color="secondary"
                    size="large"
                    aria-label="Large outlined secondary button group"
                  >
                    {ShipmentStatus.map((item, index) => (
                      <Button
                        className={
                          item.Id !== StatusStatId2
                            ? "buttonStatusId"
                            : "bg-primary text-white buttonStatusId"
                        }
                        id="buttonStatusId"
                        onClick={() => {
                          filterStatus(item.Id);
                          setStatusStatId2(item.Id);
                          setStatusStatId(item.ParentId);
                        }}
                      >
                        {item.Abbreviation}
                      </Button>
                    ))}
                    <Button
                      className="bg-success text-white"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClick}
                    >
                      Select Other Status
                    </Button>
                  </ButtonGroup>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {ShipmentStatusSumParent.map((item, index) => (
                      <MenuItem
                        onClick={() => {
                          handleClose(item.Id);
                          setStatusStatId2(item.Id);
                          setStatusStatId(item.ParentId);
                        }}
                      >
                        {item.Name}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>

                <div className="col-lg-2 text-right">
                  <button
                    type="button"
                    onClick={Close()}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
