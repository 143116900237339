import React, { useEffect, useState } from "react";
import $ from "jquery";
import Select from "react-select";
import { Notification } from "../../../../Notification";
import BootstrapTable from "react-bootstrap-table-next";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import CloseIcon from "@material-ui/icons/Close";

import { momentDate } from "../../../../../../_metronic/_helpers/ATHelpers";
import { IconButton } from "@material-ui/core";
const adapter = new LocalStorage("db");
const db = low(adapter);
let SelectedGroupNote2 = null;

const NotesType = [
  { value: 14, label: "Tracking" },
  { value: 15, label: "Delay" },
  { value: 16, label: "Issue" },
  { value: 17, label: "Appointment" },
  { value: 18, label: "Rate" },
  { value: 19, label: "Assessorial" },
];

export function TmsNotesGroup({ id, setmodalNotes, modalNotes }) {
  const [SONotesData, setSONotesData] = useState([]);
  const [GroupNotesData, setGroupNotesData] = useState();
  const [loadingPostNote, setloadingPostNote] = useState();
  const [NewText, setNewText] = useState("");
  const [SelectedGroupNote, setSelectedGroupNote] = useState(null);
  //tableDataC
  let columns = [
    {
      dataField: "Type",
      text: "Type",
      sort: true,
      headerSortingClasses,
    },
    {
      dataField: "OpenedBy",
      text: "Opened By",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Updated",
      text: "Last Updated",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <div className="align-items-center">
              <span>{momentDate(row.Updated)}</span>
            </div>
          </>
        );
      },
    },
    {
      dataField: "UpdatedBy",
      text: "Updated By",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Note",
      text: "Note",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Actions",
      text: "Actions",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="customers-edit-tooltip">See more</Tooltip>}
            >
              <a
                href={() => false}
                onClick={() => {
                  if (SelectedGroupNote2 === row.Id) {
                    setSelectedGroupNote(null);
                    SelectedGroupNote2 = null;
                  } else {
                    getDetailGroupNote(row.Id);
                    setSelectedGroupNote(row.Id);
                    SelectedGroupNote2 = row.Id;
                  }
                  if (!$("#GroupNotesId").is(":hidden")) {
                    $("#GroupNotesId").toggle("slow");
                  }
                  if (!$("#NotesId").is(":hidden")) {
                    $("#NotesId").toggle("slow");
                  }
                }}
                className={`btn btn-icon btn-light btn-hover-success btn-sm mx-3`}
              >
                <span className={`svg-icon svg-icon-md svg-icon-success`}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];
  let columnsNote = [
    {
      dataField: "AddedBy",
      text: "AddedBy",
      sort: true,
      headerSortingClasses,
    },
    {
      dataField: "Created",
      text: "Date/Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <div className="align-items-center">
              <span>{momentDate(row.Created)}</span>
            </div>
          </>
        );
      },
    },
    {
      dataField: "Body",
      text: "Note",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Actions",
      text: "Actions",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="customers-edit-tooltip">Delete Note</Tooltip>
              }
            >
              <a
                href={() => false}
                onClick={() => {
                  DeleteNote(row.Id);
                }}
                className={`btn btn-icon btn-light btn-hover-danger btn-sm mx-3`}
              >
                <span className={`svg-icon svg-icon-md svg-icon-danger`}>
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];
  function getDetailGroupNote(GroupId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/Notes/${GroupId}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let obj = JSON.parse(result);
        setGroupNotesData(obj || []);
      })
      .catch((error) => console.log("error", error));
  }
  function getSoNotes(_id) {
    if (_id !== null && _id !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/Tms/GroupNotes/${_id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let obj = JSON.parse(result);
          setSONotesData(obj || []);
        })
        .catch((error) => console.log("error", error));
    }
  }

  function getSoNotesfirst(_id) {
    if (_id !== null && _id !== undefined) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/Tms/GroupNotes/${_id}`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let obj = JSON.parse(result);
          setSONotesData(obj || []);
          if (obj.length > 0) {
            setmodalNotes(true);
          }
        })
        .catch((error) => console.log("error", error));
    }
  }
  useEffect(() => {
    getSoNotesfirst(id);
  }, [id]);
  //End Table Data
  //Functions

  async function PostNote(id, bodytext, cat, category) {
    setloadingPostNote(true);
    return await fetch(`${window.$apiurl}/Tms/Notes`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: bodytext,
        CategoryId: cat,
        GroupId: category,
        ShipmentId: id,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        if (res?.length > 0 && res[0] === 200) {
          setTimeout(() => {
            setNewText("");
            $("#GroupNotesId").toggle("slow");
            $("#NewTextNots").val("");
          }, 50);
          getSoNotes(id);
          if (SelectedGroupNote2 !== null && SelectedGroupNote2 !== "") {
            getDetailGroupNote(SelectedGroupNote2);
          }
          Notification("success", "Note has been saved successfully");
          if (category !== "") {
            $('[name="CategoryId"]').val("");
          }
          $("#NewTextNots").val("");
        }else{
          Notification("errorClick", res[1]?.Message);
        }
        setloadingPostNote(false);
      })
      .catch((error) => {
        getSoNotes(id);
        if (SelectedGroupNote2 !== null && SelectedGroupNote2 !== "") {
          getDetailGroupNote(SelectedGroupNote2);
        }
        Notification("success", "Note has been saved successfully");
        if (category !== "") {
          $('[name="CategoryId"]').val("");
        }
        $("#NewTextNots").val("");
        setloadingPostNote(false);
      });
  }

  async function DeleteNote(_id) {
    return await fetch(`${window.$apiurl}/Tms/Notes/${_id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note is Deleted successfully");
        getSoNotes(id);
        if (SelectedGroupNote2 !== null && SelectedGroupNote2 !== "") {
          getDetailGroupNote(SelectedGroupNote2);
        }
      })
      .catch((error) => {
        Notification("success", "Note is Deleted successfully");
        getSoNotes(id);
        if (SelectedGroupNote2 !== null && SelectedGroupNote2 !== "") {
          getDetailGroupNote(SelectedGroupNote2);
        }
      });
  }

  //EndFunctions

  //htmlRenderCardFunction
  function CardFunction({ data }) {
    return (
      <>
        <BootstrapTable
          wrapperClasses="table-responsive"
          classes="table table-head-custom table-vertical-center"
          bootstrap4
          pagination={paginationFactory({
            showTotal: true,
            sizePerPageList: [
              { text: "5", value: 5 },
              { text: "10", value: 10 },
              { text: "25", value: 25 },
            ],
          })}
          bordered={false}
          keyField="Id"
          data={data?.Notes || []}
          columns={columnsNote}
        />
        <br />
      </>
    );
  }
  //htmlRenderCardFunction
  return (
    <>
      <Modal
        show={modalNotes}
        size="xl"
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Notes</Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setmodalNotes(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <form className="form form-label-right">
            <div className="form-group">
              <div className="mb-5">
                <button
                  type="button"
                  className="btn btn-light-primary mr-2 w-100 mb-5"
                  onClick={() => {
                    $('[name="CategoryId"]').val("");
                    $("#NewTextNots").val("");
                    $("#GroupNotesId").toggle("slow");
                    if (!$("#NotesId").is(":hidden")) {
                      $("#NotesId").toggle("slow");
                    }
                  }}
                >
                  <i className="fas fa-plus mr-2"></i> Add New Group
                </button>
              </div>
              <div className="mb-5">
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center"
                  bootstrap4
                  pagination={paginationFactory({
                    showTotal: true,
                    sizePerPageList: [
                      { text: "5", value: 5 },
                      { text: "10", value: 10 },
                      { text: "25", value: 25 },
                    ],
                  })}
                  bordered={false}
                  keyField="Id"
                  data={SONotesData}
                  columns={columns}
                />
              </div>

              <div
                className="mb-5"
                id="GroupNotesId"
                style={{ display: "none" }}
              >
                <label>Text</label>
                <textarea
                  className="form-control mb-5"
                  rows="4"
                  id="NewTextNots"
                  onBlur={(e) => setNewText(e.target.value)}
                ></textarea>
                <div className="row">
                  <div className="mb-5 col-lg-8 w-100 mt-5">
                    <Select
                      name="CategoryId"
                      label="Select Category"
                      options={NotesType}
                    />
                  </div>
                  <div className="m-auto col-lg-4 text-center">
                    <button
                      type="button"
                      disabled={loadingPostNote}
                      className="btn btn-light-success mr-2 w-100 mb-5 m-auto"
                      onClick={async () => {
                        if (NewText === "")
                          Notification("warning", "Text is Empty");
                        else {
                          await PostNote(
                            id,
                            NewText,
                            $('[name="CategoryId"]').val(),
                            ""
                          );
                        }
                      }}
                    >
                      <i className="fas fa-save mr-2"></i> Save
                      {loadingPostNote && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="mb-5" id="NotesId" style={{ display: "none" }}>
                <label>Text</label>
                <textarea
                  className="form-control mb-5"
                  rows="4"
                  id="NewTextNots"
                  onBlur={(e) => setNewText(e.target.value)}
                ></textarea>
                <div className="row">
                  <div className="mb-5 col-lg-8 w-100 mt-5">
                    <Select
                      name="CategoryId"
                      label="Select Category"
                      isDisabled
                      options={NotesType}
                      value={NotesType.filter(
                        (a) => a.value === GroupNotesData?.CategoryId
                      )}
                    />
                  </div>
                  <div className="m-auto col-lg-4 text-center">
                    <button
                      type="button"
                      className="btn btn-light-success mr-2 w-100 mb-5 m-auto"
                      disabled={loadingPostNote}
                      onClick={async () => {
                        if (NewText === "")
                          Notification("warning", "Text is Empty");
                        else {
                          var a = await PostNote(
                            id,
                            NewText,
                            GroupNotesData?.CategoryId,
                            SelectedGroupNote2
                          );
                          setTimeout(() => {
                            setNewText("");
                            $("#NotesId").toggle("slow");
                            $("#NewTextNots").val("");
                          }, 50);
                        }
                      }}
                    >
                      <i className="fas fa-save mr-2"></i> Save
                      {loadingPostNote && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </div>
              {SelectedGroupNote !== null ? (
                <>
                  <div className="mb-5 mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light-primary mr-2 w-100 mb-5"
                      onClick={() => {
                        $('[name="CategoryId"]').val("");
                        $("#NewTextNots").val("");
                        $("#NotesId").toggle("slow");
                        if (!$("#GroupNotesId").is(":hidden")) {
                          $("#GroupNotesId").toggle("slow");
                        }
                      }}
                    >
                      <i className="fas fa-plus mr-2"></i> Add New Note
                    </button>
                  </div>
                  <CardFunction data={GroupNotesData} />
                </>
              ) : (
                ""
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={() => {
              setmodalNotes(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Close
          </button>
          <> </>
        </Modal.Footer>
      </Modal>
    </>
  );
}
