import React from "react";
import { Notification } from "../../../../../../Notification";

export function StatusColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["success", "danger"];
  const CustomerStatusTitles = ["Active", "Inactive"];

  var isActive = row.IsActive === true ? 0 : 1;

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${CustomerStatusCssClasses[isActive]} label-inline`;
  };
  const OnClickActive = () => {
    fetch(`${window.$apiurl}/address/activate?id=${row.id}`)
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        document.getElementById("ReLoadTableId").click();
        Notification("success", "success");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <span
      className={getLabelCssClasses()}
      onClick={() => OnClickActive()}
      style={{ cursor: "pointer" }}
    >
      {CustomerStatusTitles[isActive]}
    </span>
  );
}