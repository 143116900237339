import React, { useEffect, useState } from "react";
import Index from "./AssigntoOutbound2/Index";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";
import { Notification } from "../../../modules/Notification";
import Button from "@material-ui/core/Button";
import low from "lowdb";
import SelectPagination from "../../../../_metronic/_partials/widgets/Select/SelectPagination";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";

const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export function AssigntoOutboundPage() {
  const [ShipmentIDStat, setShipmentIDStat] = useState([]);
  const [ShipmentsIds, setShipmentsIds] = useState([]);
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);
  const [IsClosed, setIsClosed] = useState(0);
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    GetShipmentsIds();
  }, []);

  async function GetShipmentsIds() {
    try {
      return await fetch(`${window.$apiurl}/shipments/GetAllShipmentsIds`, {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const statusCode = response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          setShipmentsIds(res[1]);
        });
    } catch (error) {}
  }

  async function PostNewShipment(setShipmentIDStat) {
    return await fetch(`${window.$apiurl}/transload/shipment?label=&orderId=`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        if (res[0] === 200) {
          Notification("success", "Create New Shipment ID : " + res[1]?.Id);
          setShipmentNewModelt(false);

          var ids = ShipmentsIds;
          ids.unshift({ Id: res[1]?.Id, EquipmentSize: 53 });

          var list = [];
          setShipmentsIds([]);
          setShipmentIDStat((x) => {
            list = x;
            return [];
          });
          setTimeout(() => {
            list.push({ Id: res[1]?.Id, EquipmentSize: 53 });
            setShipmentsIds(ids);
            setShipmentIDStat(list);
          }, 1000);
          return res[1];
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>
      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={async () => {
              setShipmentNewModelt(false);
              await PostNewShipment(setShipmentIDStat);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <LoadingDialog isLoading={Loading} text="Loading ..." />
      <div className="card-header font-weight-bold py-0 px-0">
        <div className="form-group row mb-5">
          <div className="col-xl-3 col-lg-6 col-sm-6  text-left" />
          <div className="col-xl-1 col-lg-0 col-sm-0  text-left mt-3" />

          <div className="col-xl-2 col-lg-0 col-sm-0 text-left" />

          <div className="col-xl-3 col-lg-6 col-sm-6 text-left">
            <button
              type="button"
              className="btn btn-light-success font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
              style={mystyleTabs}
              onClick={() => {
                setShipmentNewModelt(true);
              }}
            >
              <i className="fas fa-plus-circle"></i> New
            </button>
          </div>
          <div className="col-xl-3 col-lg-6 col-sm-6 text-left">
            {ShipmentsIds.length > 0 ? (
              <SelectPagination
                name="id"
                options={ShipmentsIds.map((x) => {
                  return {
                    value: x.Id,
                    label:
                      x.Id +
                      " - " +
                      (x?.ConsigneeCompanyName !== null
                        ? x?.ConsigneeCompanyName
                        : "Empty"),
                  };
                })}
                value={ShipmentIDStat.map((x) => {
                  return {
                    value: x.Id,
                    label: x.Id,
                  };
                })}
                isMulti
                onChange={async (newValue) => {
                  var list = [];
                  if (newValue && newValue.length > 0) {
                    for (var i of newValue) {
                      list.push(ShipmentsIds.find((x) => x.Id === i.value));
                    }
                    setTimeout(() => {
                      if (list.length > 0) setShipmentIDStat(list);
                      else setShipmentIDStat([]);
                    }, 1);
                  } else setShipmentIDStat([]);
                }}
              />
            ) : (
              <input
                className="form-control"
                disabled={true}
                placeholder="Select Shipments ..."
              />
            )}
          </div>
        </div>
      </div>
      <div className="card-body AssigntoOutboundId px-0">
        {ShipmentIDStat.length > 0 ? (
          <>
            <Index
              iShipmentsID={ShipmentIDStat}
              IsClosed={IsClosed}
              setLoading={setLoading}
              setShipmentsIds={setShipmentsIds}
            />
            <br />
          </>
        ) : (
          <Nodatafound />
        )}
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" />
      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No data found
        </p>
      </div>
      <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3" />
    </div>
  );
}
