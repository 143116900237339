import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
let warehouseList;
export default async function mockManageLocations(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetManageLocations(id) {
    return await fetch(window.$apiurl + "/locations/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetManageLocation(id) {
    return await fetch(`${window.$apiurl}/locations/${id}/Details`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1].Id;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetWarehouses() {
    return await fetch(window.$apiurl + "/warehouses", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetLocationsOption() {
    return await fetch(window.$apiurl + "/locations/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PostManageLocation(someData) {
    return fetch(window.$apiurl + "/locations", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[1]?.Code === "200") {
          Notification("success", "success");
          window.locations = 200;
        } else {
          Notification("error", res[1]?.Code + " " + res[1].Message);
          window.locations = res[1]?.Code;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PostManageLocations(someData) {
    return fetch(window.$apiurl + "/locations/locations", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutManageLocation(someData, id) {
    return fetch(window.$apiurl + "/locations/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData["customer"]),
    })
      .then(async (response) => {
        const statusCode = response.status;

        if (statusCode === 200) {
          Notification("success", "success");
          window.locations = 200;
        } else {
          const data = await response.json();
          Notification("error", statusCode + " " + data.Message);
          window.locations = statusCode;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function DeleteManageLocation(id) {
    return await fetch(window.$apiurl + "/locations/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/ManageLocations/WarehousesList").reply(async () => {
    const obj = {};
    obj["warehousesList"] = await GetWarehouses();
    return [200, obj];
  });

  mock.onGet("api/Locations/GetAllApi").reply(async () => {
    const obj = {};
    obj["LocationsList"] = await GetLocationsOption();
    return [200, obj];
  });

  mock.onPost("api/Locations/import").reply(async ({ data }) => {
    const locatioList = JSON.parse(data).data;
    await PostManageLocations(locatioList);
    return [200, "Done"];
  });

  mock.onPost("api/Locations/export").reply(async ({ data }) => {
    const warehouseId = JSON.parse(data).data;
    const getManageLocations = await GetManageLocations(warehouseId);
    return [200, getManageLocations];
  });

  mock.onPost("api/ManageLocations").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      id = 0,
      Id = 0,
      LocationId = "",
      Status = "",
      LocationTypeId = "",
      Description = "",
      Width = "",
      Length = "",
      Height = "",
      MaxWeight = "",
      MinTemperature = "",
      MinQuantity = "",
      ConsigneeId,
      IsActive = false,
      WarehouseId = document.getElementById("warehouseIdRef").value,
      AllocationPriority = "",
      DirectedPutWays,
    } = customer;

    const newCustomer = {
      Id,
      id,
      LocationId,
      Status,
      LocationTypeId,
      Description,
      ConsigneeId,
      Width,
      Length,
      Height,
      MaxWeight,
      MinTemperature,
      MinQuantity,
      AllocationPriority,
      WarehouseId,
      IsActive,
      DirectedPutWays,
    };
    await PostManageLocation(newCustomer);
    //customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageLocations/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    warehouseList = await GetWarehouses();
    if (!(warehouseList.length > 0)) {
      return [400];
    }
    const warehouseIdDefault = queryParams.filter.warehouseId;
    const warehouseId =
      warehouseIdDefault === undefined
        ? warehouseList[0].Id
        : warehouseIdDefault;
    customerTableMock = await GetManageLocations(warehouseId);
    delete queryParams.filter.warehouseId;
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageLocations/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageLocations/updateStatusForCustomers")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ManageLocations\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageLocations\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }
    var getManageLocation = await GetManageLocation(id);
    return [200, getManageLocation];
  });

  mock.onPut(/api\/ManageLocations\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageLocations\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutManageLocation(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageLocations\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageLocations\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteManageLocation(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
