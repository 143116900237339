import carTableMock from "./carTableMock";
import MockUtils from "./mock.utils";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);
function StatusMessage(res) {
  var status = res.status;
  console.log("status",status);
  if (status === 200) {
    Notification("success", "success");
    return [200];
  } else if (status === 400) {
    Notification("error", "Error Occured");
    return [400];
  }
}
async function PostLoadBoard(LoadData) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify(LoadData);
  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${window.$apiurl}/loadboard`, requestOptions)
    .then(async (response) => {
      return response;
    })
    .then((res) => {
      return StatusMessage(res);
    })
    .catch((err) => {
      return StatusMessage(err);
    });
}
async function PutLoadBoard(LoadData, id) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify(LoadData);

  var requestOptions = {
    method: "PUT",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return await fetch(`${window.$apiurl}/loadboard/${id}`, requestOptions)
    .then(async (response) => {
      return response;
    })
    .then((res) => {
      return StatusMessage(res);
    })
    .catch((err) => {
      return StatusMessage(err);
    });
}
async function GetLoadBoartEdit(id) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return await fetch(`${window.$apiurl}/loadboard/${id}`, requestOptions)
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      console.log("res", res);
      return res[1];
    })
    .catch((error) => console.log("error", error));
}

async function DeleteLoadBoard(id) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
  };

  return await fetch(`${window.$apiurl}/loadboard/${id}`, requestOptions)
    .then(async (response) => {
      return response;
    })
    .then((res) => {
      return StatusMessage(res);
    })
    .catch((err) => {
      return StatusMessage(err);
    });
}
export default function mockTMSLoadBoard(mock) {
  mock.onPost("api/TMSLoadBoard").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const {
      Name = "",
      IsPublic = false,
      Updators = [],
      Customers = [],
      Carriers = [],
      Modes = [],
    } = product;
    const newProduct = {
      Name,
      IsPublic,
      Updators,
      Customers,
      Carriers,
      Modes,
    };
    await PostLoadBoard(newProduct);
    carTableMock.push(newProduct);
    return [200, { product: newProduct }];
  });

  mock.onPost("api/TMSLoadBoard/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredProducts = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredProducts];
  });

  mock.onPost("api/TMSLoadBoard/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/TMSLoadBoard/updateStatusForProducts").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/TMSLoadBoard\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TMSLoadBoard\/(\d+)/)[1];
    const product = await GetLoadBoartEdit(id);
    return [200, product];
  });

  mock.onPut(/api\/TMSLoadBoard\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TMSLoadBoard\/(\d+)/)[1];
    const remark = JSON.parse(config.data);
    await PutLoadBoard(remark.product, id);
    return [200];
  });

  mock.onDelete(/api\/TMSLoadBoard\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/TMSLoadBoard\/(\d+)/)[1];
    await DeleteLoadBoard(id);
    window.$RefreshTMSLoadBoaed = true;
    return [200];
  });
}
