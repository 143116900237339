/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../../../../_metronic/_partials/controls";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
export function RemarkEditDialogHeader({ id, onHide }) {
  const [title, setTitle] = useState("");
  // Remarks Redux state
  const { remarkForEdit, actionsLoading } = useSelector(
    (state) => ({
      remarkForEdit: state.Receiving.remarkForEdit,
      actionsLoading: state.Receiving.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? "" : "New Item";
    if (remarkForEdit && id) {
      _title = "Edit Item ID : "+id ;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [remarkForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <IconButton
          
          aria-label="Close"
          onClick={(e) => {
            onHide(false) ;
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
    </>
  );
}
