/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useMemo } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  ModalProgressBar,
  Input,
  CheckboxLarge,
} from "../../../../../_metronic/_partials/controls";
/* import { useCustomersUIContext } from "../CustomersUIContext"; */
import { Formik, Form, Field } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CreatableSelect from "react-select/creatable";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Notification } from "../../../../modules/Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function SendEmail({ id, containerNumber, show, onHide }) {
  // Products UI Context
  const [EmailAddressList, setEmailAddressList] = useState(null);
  const [AttachementList, setAttachementList] = useState([]);
  const [ToData, setToData] = useState(null);
  const [ToDataDefeault, setToDataDefeault] = useState(null);

  const [SubjectData, setSubjectData] = useState(null);
  const [includCCData, setincludCCData] = useState(false);
  const [includDispatchReport, setincludDispatchReport] = useState(false);
  const [BodyData, setBodyData] = useState(null);
  const [AttachmentData, setAttachmentData] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [AttachmentTypes, setAttachmentTypes] = useState([]);
  const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);

  /* const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
    };
  }, [customersUIContext]); */

  // Products Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.products.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    fetchUsersAsync();
    setTimeout(() => {
      setBodyData(
        `<strong>Mitco Receipt Id-Container:</strong> ${id}-${containerNumber}`
      );
      setincludDispatchReport(false);
      setSubjectData(`Empty ${id}-${containerNumber}`);
    }, 500);
  }, [id]);
  const fetchUsersAsync = async () => {
    try {
      setAttachmentData([]);
      setAttachementList([]);
      if (id !== null) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var requestOptions = {
          method: "GET",
          withCredentials: true,
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(
          window.$apiurl + "/receiptsManagement/getsendEmail/" + id,
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            const resultobj = JSON.parse(result);
            var obj = [];
            var obj2 = [];
            resultobj.Emails.map((item, index) => {
              obj.push({ value: item.Email, label: item.Email });
              obj2.push({ Email: item.Email, Name: item.Email });
            });
            setToDataDefeault(obj);
            setToData(obj2);
            setEmailAddressList(obj);
            getAttachmentData();
          })
          .catch((error) => console.log("error", error));

        fetch(window.$apiurl + "/carriers/transport/getviewmodel", {
          method: "get",
          headers: {
            Authorization: `bearer ${db.read().getState().Token}`,
            Accept: "application/json, text/plain, */*",
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.text())
          .then((result) => {
            const objs = JSON.parse(result);
            setAccessLevels(objs.AccessLevels);
            setAttachmentTypes(objs.AttachmentTypes);
          })
          .catch((error) => console.log("error", error));
      }
    } catch (e) {}
  };
  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteProduct = async () => {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    var data = {};

    data = {
      Emails: ToData,
      Subject: SubjectData,
      Body: BodyData,
      IncludeCc: includCCData,
      Attachments: AttachmentData,
      DispatchedContainer: includDispatchReport,
    };

    var raw = JSON.stringify(data);
    console.log(raw);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    var url = "/receiptsmanagement/sendEmail/" + id;

    let response = await fetch(`${window.$apiurl}` + url, requestOptions);
    if (response.status === 200) {
      Notification("success", "Email sent successfuly for Receipt ID " + id);

      setToData(null);
      setToDataDefeault(null);
      setSubjectData(null);
      setBodyData("");
      setincludCCData(false);
      setincludDispatchReport(false);
      setAttachmentData([]);
      setisLoadingSend(false);
      onHide();
    } else {
      Notification("error", "invalid data");
      setisLoadingSend(false);
      onHide();
    }
  };

  ///----------------------
  async function SaveShipementAattachement() {
    if (
      document.getElementById("shipmentAttachmentList").files[0] === undefined
    ) {
      Notification("error", "Please select Attachment");
      return;
    }
    setisLoadingSubmit(true);
    var formdata = new FormData();
    const inputElement = document.getElementById("shipmentAttachmentList");
    const AccessLevelId = document.getElementById("shipmentAccessLevelIdList")
      .value;
    const AttachmentTypeId = document.getElementById(
      "shipmentAttachmentTypeIdList"
    ).value;

    formdata.append(
      "File",
      inputElement.files[0],
      inputElement.value.split("\\")[2]
    );
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
    };
    let response = await fetch(
      `${window.$apiurl}/transload/receipts/addattachment?receiptId=` +
        id +
        "&attachmentTypeId=" +
        AttachmentTypeId +
        "&accessLevelId=" +
        AccessLevelId,
      requestOptions
    );
    let data = await response.json();
    var inputs = document.querySelectorAll(".ReceiptAttachmentCheckBox");
    for (var i = 0; i < inputs.length; i++) {
      inputs[i].checked = false;
    }
    setisLoadingSubmit(false);
    setAttachmentData([]);
    setAttachementList([]);
    getAttachmentData();
  }

  async function getAttachmentData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + "/transload/receipts/getattachments/" + id,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        const resultobj = JSON.parse(result);
        setAttachmentData(
          resultobj
            .filter((a) => a.Type.includes("Empty Container"))
            .map((a) => a.Id)
        );

        setTimeout(() => {
          setAttachementList(resultobj || []);
        }, 100);
      })
      .catch((error) => console.log("error", error));
  }
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
      size="xl"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">
          Receipt Send
        </Modal.Title>
        <IconButton
          
          aria-label="Close"
          onClick={onHide}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <span>Receipt Documents is Sending...</span>}
        <Formik>
          <Form className="form form-label-right Send-shipment">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <p className="mb-3">To: </p>
                  {EmailAddressList && (
                    <CreatableSelect
                      isMulti
                      name="To"
                      id="To"
                      value={ToDataDefeault}
                      options={EmailAddressList}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        var obj = [];
                        var objDefeault = [];
                        if (e) {
                          {
                            e.map((item, index) => {
                              obj.push({ Email: item.value, Name: item.label });
                              objDefeault.push({
                                value: item.value,
                                label: item.value,
                              });
                            });
                          }
                        }
                        setToDataDefeault(objDefeault);
                        setToData(obj);
                      }}
                    />
                  )}

                  <CheckboxLarge
                    Size="12px"
                    name="includCC"
                    text="Include me on CC"
                    onChange={(event) => {
                      setincludCCData(event.target.checked);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Subject: </p>
                  <Field
                    name="Subject"
                    component={Input}
                    placeholder="Subject"
                    value={SubjectData}
                    onChange={(event) => {
                      setSubjectData(event.target.value);
                    }}
                  />
                </div>
                <div className="col-lg-12 mt-5">
                  <p className="mb-3">Body: </p>

                  <CKEditor
                    name="Body"
                    Id="Body"
                    label="Body"
                    editor={ClassicEditor}
                    config={{
                      removePlugins: [
                        "Image",
                        "ImageCaption",
                        "ImageStyle",
                        "ImageToolbar",
                        "ImageUpload",
                        "MediaEmbed",
                      ],
                    }}
                    data={BodyData}
                    onBlur={(event, editor) => {
                      const data = editor.getData();
                      setBodyData(data);
                    }}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="row">
                    <div className="col-lg-12 mt-5">
                      <h4>Attachments</h4>
                    </div>
                    {AttachementList.length > 0
                      ? AttachementList?.map((item, index) => {
                          return (
                            <div className="col-lg-12">
                              <div className="row">
                                <CheckboxLarge
                                  defaultChecked={
                                    AttachmentData.filter((a) =>
                                      String(a).includes(String(item.Id))
                                    ).length > 0
                                  }
                                  Size="12px"
                                  name={`xxxxx${index}`}
                                  value={item.Id}
                                  className="ReceiptAttachmentCheckBox"
                                  onChange={(event) => {
                                    var obj = AttachmentData;
                                    if (event.target.checked) {
                                      obj.push(event.target.value);
                                      setAttachmentData(obj);
                                    } else {
                                      setAttachmentData(
                                        AttachmentData.filter(
                                          (a) =>
                                            String(a) !==
                                            String(event.target.value)
                                        )
                                      );
                                    }
                                  }}
                                />
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={`${item.FilePath}`}
                                  style={{ paddingTop: "9px" }}
                                >
                                  {item.Type}
                                </a>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row mt-10">
                    <div className="col-lg-4">
                      <p>Add Attachment</p>
                      <input
                        id="shipmentAttachmentList"
                        type="file"
                        name="shipmentAttachmentList"
                        component={Input}
                        placeholder="Add Attachment"
                        label="Add Attachment"
                      />
                    </div>
                    <div className="col-lg-3">
                      <p>Attachment Type</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAttachmentTypeIdList"
                        id="shipmentAttachmentTypeIdList"
                        label="Attachment Type"
                      >
                        {AttachmentTypes.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <p>Access Level</p>
                      <select
                        className="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light"
                        name="shipmentAccessLevelIdList"
                        id="shipmentAccessLevelIdList"
                        label="Access Level"
                      >
                        {AccessLevels.map((item, index) => (
                          <option key={index} value={item.Id}>
                            {item.Name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-2 mt-8">
                      <button
                        type="button"
                        onClick={SaveShipementAattachement}
                        disabled={isLoadingSubmit}
                        className="btn btn-primary btn-elevate"
                      >
                        Save Attachment
                        {isLoadingSubmit && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteProduct}
            disabled={isLoadingSend}
            className="btn btn-delete btn-primary"
          >
            Send Email
            {isLoadingSend && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
