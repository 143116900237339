import MockUtils from "../mock.utils3";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function mockFindOrdersTransload(mock) {
  let carTableMock;
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModels(queryParams) {
    var _queryParams = { ...queryParams };
    var filter = _queryParams.filter;
    const linq = `${window.$apiurl}/transload/orders?customerId=${filter.customerId}&warehouseId=${filter.warehouseId}&isCancelled=${filter.isCancelled}&orderId=${filter.id}&pageNumber=${_queryParams.pageNumber}&pageSize=${_queryParams.pageSize}&sortField=${_queryParams.sortField}&sortOrder=${_queryParams.sortOrder}&search=${filter.searchText}`;
    window.linqExportFindOrder = `${window.$apiurl}/transload/orders?customerId=${filter.customerId}&warehouseId=${filter.warehouseId}&isCancelled=${filter.isCancelled}&orderId=${filter.id}&pageNumber=${_queryParams.pageNumber}&pageSize=1000000&sortField=${_queryParams.sortField}&sortOrder=${_queryParams.sortOrder}&search=${filter.searchText}`;
    return await fetch(linq, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        queryParams.TotalItems = res[1]?.Pagination?.TotalItemCount || 0;
        return ChangeId(res[1].Result || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetBillOfLanding(id) {
    return await fetch(window.$apiurl + "/ShipmentBilling/BOL/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.log("err");
      });
  }
  async function GetModel(id) {
    return await fetch(
      `${window.$apiurl}/transload/orders/receipts?orderId=${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    window.$OrderNewId = undefined;

    return fetch(window.$apiurl + "/transload/orders", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) Notification("success", "success");
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400) Notification("error", res[1].Message);
        window.$OrderNewId = res[1]?.Id;
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/transload/orders/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) {
          Notification("success", "success");
        }
        const data = statusCode !== 200 ? await response.json() : "";
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400) Notification("error", res[1].Message);
        return res[0] === 200;
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return fetch(window.$apiurl + "/TransloadOrders/Cancel/" + id, {
      method: "Put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/transload/orders/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //---------------------------------------------------------------------------------------
  mock.onGet("api/FindOrdersTransload/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock
    .onGet(/api\/FindOrdersTransload\/PrintBlForm\/\d+/)
    .reply(async (config) => {
      const id = config.url.match(
        /api\/FindOrdersTransload\/PrintBlForm\/(\d+)/
      )[1];
      const obj = await GetBillOfLanding(id);
      if (!obj) {
        return [400];
      }
      return [200, obj];
    });

  mock.onPost("api/FindOrdersTransload").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    const {
      CustomerId,
      MasterBill,
      ConsigneeId,
      ModeId,
      ServiceId,
      ServiceLevelId,
      ShipperId,
      VesselVoyageId,
      WarehouseId,
    } = product;

    const id = 0;
    const newProduct = {
      id,
      CustomerId,
      MasterBill,
      ConsigneeId,
      ModeId,
      ServiceId,
      ServiceLevelId,
      ShipperId,
      VesselVoyageId,
      WarehouseId,
    };
    await PostModel(product);
    carTableMock.push(newProduct);
    return [200, { product: newProduct }];
  });

  mock.onPost("api/FindOrdersTransload/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    carTableMock = await GetModels(queryParams);
    const filterdCustomers = mockUtils.baseFilter(carTableMock, queryParams);
    if (filterdCustomers?.totalCount === 0)
      Notification("error", "Data Not Found");
    return [200, filterdCustomers];
  });

  mock.onPost("api/FindOrdersTransload/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    carTableMock = await GetModels(queryParams);
    const filterdCustomers = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdCustomers];
  });

  mock.onPost("api/FindOrdersTransload/deleteProducts").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/FindOrdersTransload/updateStatusForProducts")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      carTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/FindOrdersTransload\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrdersTransload\/(\d+)/)[1];
    const product = await GetModel(id);
    product.id = product.Id;
    window.FindOrdersTransload = id;
    window.CustomerIdOrderTransl = product.CustomerId;
    if (!product) {
      return [400];
    }
    return [200, product];
  });

  mock.onPut(/api\/FindOrdersTransload\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrdersTransload\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    var putObj = await PutModel(product, id);

    if (!putObj) {
      return [400];
    }

    const index = carTableMock.findIndex((el) => el?.id === +id);

    if (!index) {
      return [400];
    }
    carTableMock[index] = { ...product };
    return [200];
  });

  mock.onDelete(/api\/FindOrdersTransload\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrdersTransload\/(\d+)/)[1];
    var deleteMasterCarrier = await DeleteModel(id);
    if (!deleteMasterCarrier) return [400];
    const index = carTableMock.findIndex((el) => el.id === id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
