import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import MockUtils from "./mock.utils3";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockReceiptsManagmentNewVersion(mock) {
  window.mockReceiptsManagmentList = [];
  window.mockReceiptsManagmentLinq = "";
  async function GetModel(linq, _filter) {
    if (window.$ItemExceptions === false) {
      window.$ItemExceptions = false;
    } else {
      window.$ItemExceptions = true;
    }
    window.mockReceiptsManagmentLinq = linq;
    // if (window.mockReceiptsManagmentLinq === linq){
    //   return window.mockReceiptsManagmentList || [];
    // }

    return await fetch(
      `${window.$apiurl}/receiptsmanagement/getreceipts/beta${linq}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //*********************** */
  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/receiptsmanagement/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  //----------------------------------------------------------------
  mock.onGet("api/ReceiptsManagementNewVersion/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/ReceiptsManagementNewVersion").reply(({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Created = "",
      Container = "",
      WHlocation = "",
      Pickuplocation = "",
      Mode = "",
      Vendor = "",
      PickupApt = "",
      PlanDate = "",
      Status = "",
    } = customer;

    const id = generateUserId();
    const newCustomer = {
      Created,
      Container,
      WHlocation,
      Pickuplocation,
      Mode,
      Vendor,
      PickupApt,
      PlanDate,
      Status,
    };
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });
  mock.onPost("api/ReceiptsManagementNewVersion/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const _filter = queryParams;
    let resultFilteredByUser;
    if (!window.$isGlobalSearch) {
      var receiptDefaultFilter = db.read().getState().User.DefaultFilter;
      receiptDefaultFilter = receiptDefaultFilter?.find((x) => x.Flow === "RM");
      resultFilteredByUser = receiptDefaultFilter;
    }
    var cstmrIds =
      window.$CustomerIdsRm !== undefined
        ? window.$CustomerIdsRm
        : window.$ReceiptManagementCustomerId !== undefined
        ? window.$ReceiptManagementCustomerId
        : "";

    window.$filterActionsRm = _filter.filter.multiCustomersGet;
    if (!_filter.filter.multiCustomersGet) {
      delete window.$CustomerIdsRm;
    }
    var VoyageId = "&voyageId=" + (_filter.filter.VoyageId || "");
    var warehouseId = "&warehouseId=" + (_filter.filter.warehouseId || "");
    var vendorId = "&vendorId=" + (_filter.filter.VendorId || "");
    var DateRangeType =
      "&filterDate=" + (_filter.filter.DateRangeType || "created");
    var startDate =
      "&startDate=" +
      (_filter.filter.startDate !== undefined &&
      _filter.filter.startDate !== "" &&
      _filter.filter.startDate !== null
        ? _filter.filter.startDate
        : window.$RmGsearchStartDate ||
          moment()
            .add(-28, "days")
            .format("YYYY-MM-DD"));
    var endDate =
      "&endDate=" +
      (_filter.filter.endDate !== undefined &&
      _filter.filter.endDate !== "" &&
      _filter.filter.endDate !== null
        ? _filter.filter.endDate
        : moment().format("YYYY-MM-DD"));

    var statusId = "&statusId=" + (window.$ReceiptsManagmentStatu || "");
    var ExceptionId =
      "&exception=" + (window.$ReceiptsManagmentExceptions || "");
    var serviceId =
      "&serviceId=" +
      (window.$ReceiptManagementServiceId === undefined
        ? ""
        : window.$ReceiptManagementServiceId);
    var modeId =
      "&modeId=" +
      (window.$ReceiptManagementModeId === undefined
        ? ""
        : window.$ReceiptManagementModeId);
    var serviceLevelId =
      "&serviceLevelId=" + (_filter.filter.serviceLevelId || "");
    var equipmentTypeId =
      "&equipmentTypeId=" + (_filter.filter.equipmentTypeId || "");
    var equipmentSizeId =
      "&equipmentSizeId=" + (_filter.filter.equipmentSizeId || "");
    var isHot = "&isHot=" + (_filter.filter.isHot || false);
    var minCube = "&minCube=" + (_filter.filter.minCube || 0);
    var maxCube = "&maxCube=" + (_filter.filter.maxCube || 0);
    var isHazmat = "&isHazmat=" + (_filter.filter.isHazardousMaterial || false);
    var isCancled =
      "&IsCancel=" +
      (window.$RmCanceled === undefined ? null : window.$RmCanceled);
    var pageNumber = "&pageNumber=" + _filter.pageNumber || "";
    var pageSize = "&pageSize=" + _filter.pageSize || "";
    var pageSizeExport = "&pageSize=1000000000";
    var sortField = "&sortField=" + _filter.sortField || "";
    var sortOrder = "&sortOrder=" + _filter.sortOrder || "";
    var srch = _filter.filter.Search;
    if (_filter.filter.Search === undefined) {
      srch = "";
      if (window.$RmGsearchText) {
        srch = window.$RmGsearchText;
      }
    }
    var Search = srch === undefined ? "&search=" : "&search=" + srch;

    var customerId =
      window.$CustomerIdsRm !== undefined
        ? window.$CustomerIdsRm
        : resultFilteredByUser?.DefaultCustomerId
        ? "?customerId=" + resultFilteredByUser?.DefaultCustomerId
        : window.$ReceiptManagementCustomerId !== undefined
        ? "?customerId=" + window.$ReceiptManagementCustomerId
        : "?customerId=";
    var linq =
      customerId +
      warehouseId +
      vendorId +
      VoyageId +
      DateRangeType +
      startDate +
      endDate +
      statusId +
      serviceId +
      ExceptionId +
      modeId +
      serviceLevelId +
      equipmentTypeId +
      equipmentSizeId +
      isHot +
      minCube +
      maxCube +
      isHazmat +
      isCancled +
      pageNumber +
      pageSize +
      sortField +
      sortOrder +
      Search;

    var Exportlinq =
      customerId +
      warehouseId +
      VoyageId +
      DateRangeType +
      startDate +
      endDate +
      statusId +
      serviceId +
      ExceptionId +
      modeId +
      serviceLevelId +
      equipmentTypeId +
      equipmentSizeId +
      isHot +
      minCube +
      maxCube +
      isHazmat +
      isCancled +
      pageNumber +
      pageSizeExport +
      sortField +
      sortOrder +
      vendorId +
      Search;
    //exportFileName
    window.$exportFilterRM =
      "Receipts_Status_" +
      window.$RmStatusName +
      "_Service_" +
      window.$ReceiptManagementServiceName +
      "_Mode_" +
      window.$ReceiptManagementModeName +
      "_Customer_" +
      (window.$CustomerListOverviewRm !== undefined &&
      window.$CustomerListOverviewRm !== ""
        ? window.$CustomerListOverviewRm.map((item) => item + " , ")
        : window.$CustomerListOverviewRm === ""
        ? "All"
        : window.$ReceiptManagementCustomerName) +
      startDate +
      endDate +
      isHot +
      minCube +
      maxCube +
      isHazmat +
      isCancled;

    //endExportFileName
    window.RMLinkUsed = Exportlinq;
    const gg = await GetModel(linq, _filter);
    var a = gg.Receipts || [];

    customerTableMock = ChangeId(a) || [];
    // window.$DataFilterRm = customerTableMock;
    window.$Containers_StatusCount = gg.StatusCount || false;
    // window.$filterCount = true;
    // window.$Containers_Exceptions = gg.Exceptions || false;
    // window.mockReceiptsManagmentList = customerTableMock;
    _filter.TotalItems = gg.CountAll;
    //window.ExportRMData = customerTableMock;
    const filterdCustomers = mockUtils.baseFilter(customerTableMock, _filter);
    return [200, filterdCustomers];
  });

  mock.onPost("api/AllOrderBeta/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/AllOrderBeta/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/AllOrder\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrder\/(\d+)/)[1];
    const customer = customerTableMock.find((el) => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  mock.onPut(/api\/AllOrder\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrder\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/AllOrder\/\d+/).reply((config) => {
    const id = config.url.match(/api\/AllOrder\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
  }
  return list;
}
