import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CardFooter,
} from "../../../../../_metronic/_partials/controls";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { CustomerDialogForm } from './customer-notify-dialog/CustomerDialogForm';
import {
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown,
  Row
} from "react-bootstrap";
export function CustomersCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardHeader title="CHOOSE WHICH CARRIER TO DISPLAY">
        <CardHeaderToolbar>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter /> 
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
      </CardBody>
      <CardFooter>
        <Row>
          <button
            type="button"
            className="btn btn-success btn-shadow-hover mr-2"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Add
          </button>
          <a
            type="button"
            className="btn btn-success btn-shadow-hover mr-2"
            href="/ServiceManageCarriers"
          >
            Services
          </a>
        </Row>
      </CardFooter>
    </Card>
  );
}
