import MockUtils from "./mock.utils";
import $ from "jquery";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockManageRates(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetTransactionBilling() {
    return await fetch(window.$apiurl + "/TransloadBilling", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetCustomers() {
    return await fetch(window.$apiurl + "/customers", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetTransloadBillingById(id) {
    return await fetch(window.$apiurl + "/transloadBilling/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostTransloadBilling(someData) {
    return fetch(window.$apiurl + "/transloadBilling", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        window.$statusPostRole = statusCode;
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutTransloadBilling(someData, id) {
    return fetch(window.$apiurl + "/transloadBilling/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function DeleteTransloadBilling(id) {
    return await fetch(window.$apiurl + "/transloadBilling/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetTransloadBilling(customerId, billingId) {
    return await fetch(
      window.$apiurl + "/transloadBilling/" + customerId + "/" + billingId,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Mock ---------------------------------------------------------------------------------

  //Get list Checkbox
  //api/Roles/GetAllApi
  mock.onGet("api/ManageRates/GetAllApi").reply(async () => {
    const obj = {};
    obj["Customers"] = await GetCustomers();
    return [200, obj];
  });

  // new role
  mock.onPost("api/ManageRates").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      CustomerId = 0,
      IsQuickAdd = false,
      DateStart = "",
      DateEnd = "",
      BillingType = 0,
      Rate = 0,
      Name = "",
      Code = "",
      Description = "",
    } = customer;
    const id = generateUserId();
    const newRole = {
      id,
      CustomerId,
      BillingType,
      DateStart,
      DateEnd,
      IsQuickAdd,
      Rate,
      Name,
      Code,
      Description,
    };
    newRole.BillingType = customer.BillingTypeId;
    var postRole = await PostTransloadBilling(newRole);
    //Manage Data Result
    if (postRole != undefined) {
      Notification("success", "Rate has been added successfully");
      postRole.id = postRole.Id;
      customerTableMock.push(newRole);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        { customer: newRole, message: postRole.Message, success: true },
      ];
    } else {
      if (postRole.Message) {
        Notification("error", postRole.Message);
        document.getElementById("ReLoadTableId").click();
        return [400, { message: postRole.Message, success: false }];
      } else {
        Notification("error", "Error occured");
        document.getElementById("ReLoadTableId").click();
        return [400, { message: postRole.Message, success: false }];
      }
    }
  });
  //get roles list
  mock.onPost("api/ManageRates/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    if (
      ((queryParams.filter.CustomerId != "" + queryParams.filter.CustomerId) !=
        undefined + queryParams.filter.CustomerId) !=
        null ||
      ((queryParams.filter.BillingTypeId !=
        "" + queryParams.filter.BillingTypeId) !=
        undefined + queryParams.filter.BillingTypeId) !=
        null
    ) {
      if (queryParams.filter.CustomerId != undefined) {
        if (queryParams.filter.BillingTypeId != undefined) {
          customerTableMock = await GetTransloadBilling(
            queryParams.filter.CustomerId,
            queryParams.filter.BillingTypeId
          );
        }
      }
    }
    if (
      queryParams.filter.CustomerId === "" ||
      queryParams.filter.BillingTypeId === undefined
    ) {
      customerTableMock = await GetTransactionBilling();
    }
    if (!customerTableMock) {
      customerTableMock = await GetTransactionBilling();
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.CustomerId;
    delete queryParams.filter.BillingTypeId;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageRates/deleteCustomer").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageRates/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ManageRates\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageRates\/(\d+)/)[1];
    const customer = await GetTransloadBillingById(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  //edit
  mock.onPut(/api\/ManageRates\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageRates\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    customer.BillingType = customer.BillingTypeId;
    if ($("#IsQuickAdd").is(":checked")) {
      customer.IsQuickAdd = true;
    } else {
      customer.IsQuickAdd = false;
    }
    var putObj = await PutTransloadBilling(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customer.id = customer.Id;
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    Notification("success", "success");
    return [200];
  });

  //delet
  mock.onDelete(/api\/ManageRates\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageRates\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteTransloadBilling(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
