/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import ApexCharts from "apexcharts";
import objectPath from "object-path";
import React, { useEffect, useMemo, useState } from "react";
import Chart2 from "../../../../app/modules/eMPower/pages/PerformanceGraph/ChartZoomBar";
import { useHtmlClassService } from "../../../layout";
const localStorageActiveTabKey = "builderActiveTab";

export function AR({ className }) {
    const activeTab = localStorage.getItem(localStorageActiveTabKey);
    const [key, setKey] = useState(activeTab ? +activeTab : 0);
    const saveCurrentTab = (_tab) => {
        localStorage.setItem(localStorageActiveTabKey, _tab);
    }
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            colorsGrayGray500: objectPath.get(
                uiService.config,
                "js.colors.gray.gray500"
            ),
            colorsGrayGray200: objectPath.get(
                uiService.config,
                "js.colors.gray.gray200"
            ),
            colorsGrayGray300: objectPath.get(
                uiService.config,
                "js.colors.gray.gray300"
            ),
            colorsThemeBaseSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.base.success"
            ),
            colorsThemeLightSuccess: objectPath.get(
                uiService.config,
                "js.colors.theme.light.success"
            ),
            fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
        };
    }, [uiService]);

    useEffect(() => {
        const element = document.getElementById("kt_performance_widget_13_chart");
        if (!element) {
            return;
        }
        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);

    useEffect(() => {
        const element1 = document.getElementById("kt_performance_widget_14_chart");

        if (!element1) {
            return;
        }
        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element1, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);
    useEffect(() => {
        const element2 = document.getElementById("kt_performance_widget_15_chart");


        if (!element2) {
            return;
        }
        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element2, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);
    useEffect(() => {
        const element3 = document.getElementById("kt_performance_widget_16_chart");

        if (!element3) {
            return;
        }
        const options = getChartOption(layoutProps);
        const chart = new ApexCharts(element3, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [layoutProps]);
    return (
        <div className={`card card-custom ${className}`}>
            <div className="card-header border-0">
                <h3 className="card-title font-weight-bolder text-dark">
                    A/R 
                </h3>
                <div className="card-toolbar">
                    <ul
                        className="nav nav-dark nav-bold nav-tabs nav-tabs-line"
                        data-remember-tab="tab_id"
                        role="tablist"
                    >
                        <li className="nav-item">
                            <a
                                href={() => false()}
                                className={`nav-link font-size-h5 ${key === 0 ? "active font-weight-bolder" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setKey(0);
                                    saveCurrentTab(0);
                                }}
                            >
                                Current
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href={() => false()}
                                className={`nav-link font-size-h5 ${key === 1 ? "active font-weight-bolder" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setKey(1);
                                    saveCurrentTab(1);
                                }}
                            >
                                30
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href={() => false()}
                                className={`nav-link font-size-h5 ${key === 2 ? "active font-weight-bolder" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setKey(2);
                                    saveCurrentTab(2);
                                }}
                            >
                                60
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                href={() => false()}
                                className={`nav-link font-size-h5 ${key === 3 ? "active font-weight-bolder" : ""}`}
                                data-toggle="tab"
                                onClick={() => {
                                    setKey(3);
                                    saveCurrentTab(3);
                                }}
                            >
                                +
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="card-body d-flex flex-column">
                <div className="tab-content pt-3 bg-transparent">
                    <div className={`tab-pane ${key === 0 ? "active" : ""}`}>
                        <div className="col-12">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div
                                    id="kt_performance_widget_13_chart"
                                    className="card-rounded-bottom"
                                    style={{ height: "180px" }}
                                ></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-info mb-2 text-center font-weight-bold">Total AR</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$63,240.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-success mb-2 text-center font-weight-bold">Available balance </h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$8,500.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-warning mb-2 text-center font-weight-bold">Approved Lane</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">2,600</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane ${key === 1 ? "active" : ""}`}>
                        <div className="col-12">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div
                                    id="kt_performance_widget_14_chart"
                                    className="card-rounded-bottom"
                                    style={{ height: "180px" }}
                                ></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-info mb-2 text-center font-weight-bold">Total AR</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$63,240.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-success mb-2 text-center font-weight-bold">Aiablable balance </h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$8,500.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-warning mb-2 text-center font-weight-bold">Approved Line</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">2,600</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane ${key === 2 ? "active" : ""}`}>
                        <div className="col-12">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div
                                    id="kt_performance_widget_15_chart"
                                    className="card-rounded-bottom"
                                    style={{ height: "180px" }}
                                ></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-info mb-2 text-center font-weight-bold">Total AR</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$63,240.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-success mb-2 text-center font-weight-bold">Aiablable balance </h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$8,500.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-warning mb-2 text-center font-weight-bold">Approved Line</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">2,600</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`tab-pane ${key === 3 ? "active" : ""}`}>
                        <div className="col-12">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                <div
                                    id="kt_performance_widget_16_chart"
                                    className="card-rounded-bottom"
                                    style={{ height: "180px" }}
                                ></div>
                            </div>
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-info mb-2 text-center font-weight-bold">Total AR</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$63,240.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-success mb-2 text-center font-weight-bold">Aiablable balance </h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">$8,500.00</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-12 mb-5">
                                    <div className="card border-dashed border">
                                        <div className="card-body align-self-center py-3">
                                            <h5 className="text-warning mb-2 text-center font-weight-bold">Approved Line</h5>
                                            <h1 className="text-dakr-75 font-weight-bolder text-center">2,600</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function getChartOption(layoutProps) {
    const options = {
        series: [
            {
                name: 'AR',
                data: [199, 290, 108, 320, 200, 150, 100, 350, 302, 87, 99, 175],
            },

        ],
        colors: ["#4CB5F5"],
        chart: {
            height: 350,
            type: 'bar',
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            categories: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy'
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: "25%",
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    return value + "$";
                }
            },
        },
    };
    return options;
}
