import React, { useMemo, useState, useEffect } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useProductsUIContext } from "../ProductsUIContext";
import { RangeDatePicker } from "react-google-flight-datepicker";
import "react-google-flight-datepicker/dist/main.css";
import Select from "react-select";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import SelectMulti from "react-select";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);

var typingTimer;
const prepareFilter = (queryParams, values) => {
  const {
    LoadBoardId,
    CustomerId,
    ModeId,
    CarrierId,
    UserId,
    CapacityOperationId,
    CommercialAssigneeId,
    CommercialOperationId,
    SearchText,
    startDate,
    endDate,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by status
  filter.LoadBoardId = LoadBoardId;
  // Filter by condition
  filter.CustomerId = CustomerId;
  filter.ModeId = ModeId;
  filter.SearchText = SearchText;
  filter.CarrierId = CarrierId;
  filter.UserId = UserId;
  filter.startDate = startDate;
  filter.CapacityOperationId = CapacityOperationId;
  filter.CommercialAssigneeId = CommercialAssigneeId;
  filter.CommercialOperationId = CommercialOperationId;
  filter.endDate = endDate;
  // Filter by all fields

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ProductsFilter({ IsFilterHide }) {
  // Products UI Context
  const [Customers, setCustomers] = useState([]);
  const [CustomersList, setCustomersList] = useState([]);
  const [CarriersList, setCarriersList] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  const [ModeList, setModeList] = useState([]);

  const [LoadBoardsList, setLoadBoardsList] = useState([]);
  const productsUIContext = useProductsUIContext();
  const productsUIProps = useMemo(() => {
    return {
      setQueryParams: productsUIContext.setQueryParams,
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      productsUIProps.setQueryParams(newQueryParams);
    }
  };

  useEffect(() => {
    getDataViewModel();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/loadboard/GetLBViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setCustomersList(obj?.Customers || []);
        setCarriersList(obj?.Carriers || []);
        setUsersList(obj?.Updators || []);
        setModeList(obj.Modes || []);
      })
      .catch((error) => console.log("error", error));
  }, []);
  useEffect(() => {
    if (window.$RefreshTMSLoadBoaed) {
      getDataViewModel();
    }
    window.$RefreshTMSLoadBoaed = false;
  }, [window.$RefreshTMSLoadBoaed]);
  function getDataViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/loadboard`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setLoadBoardsList(obj || []);
      })
      .catch((error) => console.log("error", error));
  }

  return (
    <>
      <Formik
        initialValues={{
          LoadBoardId: productsUIProps?.queryParams?.filter?.LoadBoardId, // values => All=""/Selling=0/Sold=1
          CustomerId: productsUIProps?.queryParams?.filter?.CustomerId, // values => All=""/New=0/Used=1
          ModeId: productsUIProps?.queryParams?.filter?.ModeId,
          CarrierId: productsUIProps?.queryParams?.filter?.CarrierId,
          UserId: productsUIProps?.queryParams?.filter?.UserId,
          SearchText: productsUIProps?.queryParams?.filter?.SearchText,
          CapacityOperationId:
            productsUIProps?.queryParams?.filter?.CapacityOperationId,
          CommercialAssigneeId:
            productsUIProps?.queryParams?.filter?.CommercialAssigneeId,
          CommercialOperationId:
            productsUIProps?.queryParams?.filter?.CommercialOperationId,
          startDate: productsUIProps?.queryParams?.filter?.startDate,
          endDate: productsUIProps?.queryParams?.filter?.endDate,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            {IsFilterHide === false ? (
              <>
                <div className="form-group row mb-0">
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Capacity Operations</p>
                    <SelectMulti
                      isMulti
                      name="CapacityOperationId"
                      id="CapacityOperationId"
                      label="User"
                      value={values.CapacityOperationId}
                      options={UsersList.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          IsGroup: a.IsGroup,
                        };
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setFieldValue("CapacityOperationId", e);
                        } else {
                          setFieldValue("CapacityOperationId", null);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Commercial Assignment</p>
                    <SelectMulti
                      isMulti
                      name="CommercialAssigneeId"
                      id="CommercialAssigneeId"
                      label="Commercial Assignee"
                      value={values.CommercialAssigneeId}
                      options={UsersList.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          IsGroup: a.IsGroup,
                        };
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setFieldValue("CommercialAssigneeId", e);
                        } else {
                          setFieldValue("CommercialAssigneeId", null);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Commercial Operations</p>
                    <SelectMulti
                      isMulti
                      name="CommercialOperationId"
                      id="CommercialOperationId"
                      label="Commercial Operation"
                      value={values.CommercialOperationId}
                      options={UsersList.map((a) => {
                        return {
                          value: a.Id,
                          label: a.Name,
                          IsGroup: a.IsGroup,
                        };
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setFieldValue("CommercialOperationId", e);
                        } else {
                          setFieldValue("CommercialOperationId", null);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl-3 col-md-3 col-lg-3">
                    <p className="mb-2">Date Start - End</p>
                    <RangeDatePicker
                      startDatePlaceholder="Start Date"
                      endDatePlaceholder="End Date"
                      className="my-own-class-name p-0"
                      startDate={values.startDate}
                      endDate={values.endDate}
                      onChange={(startDate, endDate) => {
                        if (startDate === null) {
                          setFieldValue("startDate", "");
                        } else {
                          setFieldValue(
                            "startDate",
                            moment(startDate).format("YYYY-MM-DD")
                          );
                        }
                        if (endDate === null) {
                          setFieldValue("endDate", "");
                        } else {
                          setFieldValue(
                            "endDate",
                            moment(endDate).format("YYYY-MM-DD")
                          );
                        }

                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl col-md col-lg">
                    <p className="mb-2">Search</p>
                    <input
                      name="SearchText"
                      className="form-control"
                      placeholder="Search"
                      value={values.SearchText}
                      onChange={(e) => {
                        setFieldValue("SearchText", e.target.value);
                        clearTimeout(typingTimer);
                        typingTimer = setTimeout(function() {
                          handleSubmit();
                        }, 500);
                      }}
                    />
                  </div>
                  {values.LoadBoardId !== null && values.IsReadOnly !== true ? (
                    <div className="col-xl-1 col-lg-1 col-md-1 mt-9">
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="products-edit-tooltip">Edit</Tooltip>
                        }
                      >
                        <a
                          onClick={() => {
                            var ele1 = document.getElementById("LoadBoardEdit");
                            ele1.value = values.LoadBoardId;
                            ele1.click();
                          }}
                          href={() => false}
                          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                        >
                          <span className="svg-icon svg-icon-md svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Write.svg"
                              )}
                            />
                          </span>
                        </a>
                      </OverlayTrigger>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-group row">
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Load Board</p>
                    <Select
                      isClearable
                      name="LoadBoardId"
                      id="LoadBoardId"
                      label="Load Board"
                      inputId="react-select-single"
                      value={LoadBoardsList?.filter(
                        (a) => a.Id === values.LoadBoardId
                      ).map((a) => {
                        return { value: a.Id, label: a.Name };
                      })}
                      options={LoadBoardsList?.map((a) => {
                        return { value: a.Id, label: a.Name };
                      })}
                      onChange={(e) => {
                        var data = e;
                        if (data === null) {
                          setFieldValue("LoadBoardId", null);
                          setFieldValue("CustomerId", []);
                          setFieldValue("ModeId", "");
                          setFieldValue("CarrierId", "");
                          setFieldValue("UserId", "");
                          setFieldValue("SearchText", "");
                          setFieldValue("CapacityOperationId", []);
                          setFieldValue("CommercialAssigneeId", []);
                          setFieldValue("CommercialOperationId", []);
                          setFieldValue("IsReadOnly", false);
                          handleSubmit();
                        } else {
                          setFieldValue("LoadBoardId", data.value);
                          setFieldValue(
                            "CustomerId",
                            LoadBoardsList?.filter(
                              (a) => a.Id === data.value
                            )[0]?.Customers?.map((a) => {
                              return { value: a.Id, label: a.Name };
                            })
                          );
                          setFieldValue(
                            "ModeId",
                            LoadBoardsList?.filter(
                              (a) => a.Id === data.value
                            )[0]?.Modes?.map((a) => {
                              return { value: a.Id, label: a.Name };
                            })
                          );
                          setFieldValue(
                            "CarrierId",
                            LoadBoardsList?.filter(
                              (a) => a.Id === data.value
                            )[0]?.Carriers?.map((a) => {
                              return { value: a.Id, label: a.Name };
                            })
                          );
                          setFieldValue(
                            "IsReadOnly",
                            LoadBoardsList?.filter(
                              (a) => a.Id === data.value
                            )[0]?.ReadOnly
                          );
                          handleSubmit();
                        }
                      }}
                    />
                  </div>
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Customers</p>

                    <SelectMulti
                      id="CustomerId"
                      isMulti
                      value={values.CustomerId}
                      name="CustomerId"
                      options={CustomersList.map((a) => {
                        return { value: a.Id, label: a.Name };
                      })}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        if (e !== null) {
                          setFieldValue("CustomerId", e);
                        } else {
                          setFieldValue("CustomerId", []);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Mode</p>
                    <SelectMulti
                      isMulti
                      name="ModeId"
                      id="ModeId"
                      label="Mode"
                      value={values.ModeId}
                      options={ModeList.map((a) => {
                        return { value: a.Id, label: a.Name };
                      })}
                      onChange={(e) => {
                        if (e === null) {
                          setFieldValue("ModeId", null);
                        } else {
                          setFieldValue("ModeId", e);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                  <div className="col-xl col-md-4 mb-md-4">
                    <p className="mb-2">Carrier</p>
                    <SelectMulti
                      isMulti
                      name="CarrierId"
                      id="CarrierId"
                      label="Carrier"
                      value={values.CarrierId}
                      options={CarriersList.map((a) => {
                        return { value: a.Id, label: a.Name };
                      })}
                      onChange={(e) => {
                        if (e !== null) {
                          setFieldValue("CarrierId", e);
                        } else {
                          setFieldValue("CarrierId", null);
                        }
                        handleSubmit();
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </form>
        )}
      </Formik>
    </>
  );
}
