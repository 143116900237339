/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export function DropdownMenu1() {
    return (<>
        {/*begin::Navigation*/}
        <ul className="navi navi-hover">
            <li className="navi-header font-weight-bold py-5">
                <span className="font-size-lg">Quick Access:</span>
                <i className="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right"
                   title="Click to learn more..."></i>
            </li>
            <li className="navi-separator mb-3 opacity-70"></li>
            <li className="navi-item">
            <Link className="navi-link" to={`/transload/FindOrders`}>  <span className="navi-text">
                <span className="label label-xl label-inline label-light-primary">Find Orders</span>
            </span></Link>                
            </li>
            <li className="navi-item">
            <Link className="navi-link" to={`/ShipmentsManagement`}> <span className="navi-text">
                <span className="label label-xl label-inline label-light-danger">Shipments Management</span>
            </span></Link>
            </li>
            <li className="navi-item">
            <Link className="navi-link" to={`/ReceiptsManagement`}>  <span className="navi-text">
                <span className="label label-xl label-inline label-light-success">Receipts Management</span>
            </span></Link>                
            </li>
            <li className="navi-item">
            <Link className="navi-link" to={`/users`}> <span className="navi-text">
                <span className="label label-xl label-inline label-light-warning">Manage Users</span>
            </span></Link>
            </li>
            <li className="navi-item">
            <Link className="navi-link" to={`/ManageCustomers`}>  <span className="navi-text">
                <span className="label label-xl label-inline label-light-info">Manage Customers</span>
            </span></Link>
            </li>
            <li className="navi-item">
            <Link className="navi-link" to={`/ManageWarehouse`}>     <span className="navi-text">
                <span className="label label-xl label-inline label-light-dark">Manage Warehouse</span>
            </span></Link>
            </li>
            <li className="navi-separator mt-3 opacity-70"></li>
        </ul>
        {/*end::Navigation*/}
    </>);
}
