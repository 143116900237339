import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageServices(mock) {
  //Status Message Result
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetDataService() {
    return await fetch(window.$apiurl + "/manageservices/viewModel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Get Movable Units
  async function GetMovableUnitss() {
    return await fetch(window.$apiurl + "/services", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Get {id}
  async function GetMovableUnitsById(id) {
    return await fetch(window.$apiurl + "/services/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data;
    });
  }
  //Post Movable Units
  async function PostMovableUnit(someData) {
    return fetch(window.$apiurl + "/services", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }
  //Delete
  async function DeleteMovableUnit(id) {
    return await fetch(window.$apiurl + "/services/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 200) {
          return Promise.all([statusCode, ""]);
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          Notification("success", "success");
          return true;
        } else if (res[0] === 400) {
          Notification("error", res[1].Message);
          return false;
        }
      })
      .catch((err) => {
        Notification("error", "error request");
        return false;
      });
  }
  //Put
  async function PutMovableUnit(someData, id) {
    someData.ServiceType = "shipment";
    return fetch(window.$apiurl + "/services/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  mock.onPost("api/ManageServices").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Name = "",
      Description = "",
      IsActive = true,
      ServiceType = "shipment",
      DepartementId = null,
    } = customer;

    const id = generateUserId();
    const newCustomer = {
      Name,
      Description,
      ServiceType,
      IsActive,
      DepartementId
    };
    var postMovableUnit = await PostMovableUnit(newCustomer);
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/ManageServices/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetMovableUnitss();
    if (
      queryParams.filter.Name !== undefined &&
      queryParams.filter.Name !== null &&
      queryParams.filter.Name !== ""
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.id).includes(String(queryParams.filter.Name)) ||
          String(word.Name).includes(String(queryParams.filter.Name)) ||
          String(word.Description).includes(String(queryParams.filter.Name))
      );
    }
    customerTableMock = customerTableMock.filter(
      (word) => word.IsActive === queryParams.filter.IsActive
    );
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.Name;
    delete queryParams.filter.IsActive;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageServices/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageServices/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });
  mock.onGet("api/ManageServices/GetData").reply(async (config) => {
    var resultById = await GetDataService();
    return [200, resultById];
  });
  mock.onGet(/api\/ManageServices\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageServices\/(\d+)/)[1];
    var resultById = await GetMovableUnitsById(id);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/ManageServices\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageServices\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutMovableUnit(customer, id);
    if (!putObj) {
      return false;
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/ManageServices\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageServices\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteMovableUnit(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
