import MockUtils from "./mock.utils3";
import { Notification } from "../../Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;
let carTableMock2;
let isreload = undefined;
export default function mockManageLSPCarrier(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  async function GetCarrierList(
    DotNUmber,
    IATANumber,
    Name,
    Phone,
    Scac,
    State,
    TsaCompliance,
    Unapproved,
    IsSmartWayPartner,
    IsActive,
    VendorTypeId,
    McNumber,
    rmisId,
    pageNumber,
    pageSize,
    sortField,
    sortOrder
  ) {
    return await fetch(
      `${window.$apiurl}/carriers/transport?search=${Name}&dotNumber=${DotNUmber}&iataCode=${IATANumber}&phoneNumber=${Phone}&scacCode=${Scac}&state=${State}&tsaCompliance=${TsaCompliance}&isCertified=${Unapproved}&isSmartWayPartner=${IsSmartWayPartner}&isActive=${IsActive}&vendorTypeId=${VendorTypeId}&mcNumber=${McNumber}&rmisId=${rmisId}&pageNumber=${pageNumber}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteMasterCarrier(id) {
    return await fetch(window.$apiurl + "/carriers/" + id, {
      method: "delete",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function GetCarrier(id) {
    return await fetch(window.$apiurl + "/carriers/transport/" + id, {
      method: "get",
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PutCarrier(id, someData) {
    return fetch(window.$apiurl + "/carriers/transport/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PostCarrier(someData) {
    return fetch(window.$apiurl + "/carriers/transport", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------mock
  mock.onPost("api/ManageLSPCarrier").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    carTableMock = undefined;
    const {
      Name = null,
      ScacCode = null,
      IsIntraStateCarrier = false,
      IsSmartWayPartner = false,
      IsActive = false,
      Approved = true,
      McNumber = null,
      DotNUmber = null,
      SmartWayFleetId = null,
      IATANumber = null,
      ExternalId=null,
      TsaComplianceId = 1,
      VendorTypeId = 1308,
      CompanyName = null,
      Phone = null,
      Fax = null,
      WebUrl = null,
      TrackingUrl = null,
      Street1 = null,
      Street2 = null,
      City = null,
      State = null,
      ZipCode = null,
      Country = null,
      Borkers = [],
      Score = 0,
      ScoreText = null,
      RequiredId = true,
      TaxId = null,
      Notes = null,
      IsTruckTools = false,
      IsSmallPckage = false,
      IsLTL = false,
      IsTruckLoad = false,
      IsDomesticFreight = false,
      IsCartage = false,
      IsOther = false,
      IsInsuranceProvider = false,
      IsDrayage = false,
      IsAirHouse = false,
      IsAirMaster = false,
      IsOcean = false,
      IsOceanCombined = false,
      IsNationalCarrier = false,
      IsZone0 = false,
      IsZone1 = false,
      IsZone2 = false,
      IsZone3 = false,
      IsZone4 = false,
      IsZone5 = false,
      IsZone6 = false,
      IsZone7 = false,
      IsZone8 = false,
      IsZone9 = false,
      IsZoneCA = false,
      IsZoneMX = false,
      UseFactoringCompany = false,
      AddressToUseId = 0,
      ComanyName = null,
      PrimaryStreet1 = null,
      PrimaryStreet2 = null,
      PrimaryCity = null,
      PrimaryState = null,
      PrimaryZipCode = null,
      PrimaryCountry = null,
      AccountPhone = null,
      AccountFax = null,
      Email = null,
      NetTermsId = 0,
      PreferredPaymentTypeId = 0,
      CreditLimit = 0,
      AcceptCreditCards = false,
      CreditCardPrcessFee = 0,
      PaymentFrequencyId = 0,
      NewMaxCoveredValue = 0,
      NewBuyRate = 0,
      NewBuyMinimum = 0,
      NewSellRate = 0,
      NewSellMiniumum = 0,
      NewDisclaimer = 0,
      UsedMaxCoveredValue = 0,
      UsedBuyRate = 0,
      UsedBuyMinimum = 0,
      UsedSellRate = 0,
      UsedSellMiniumum = 0,
      UsedDisclaimer = 0,
      DotStatus = null,
      BrokerAuthority = null,
      CommonAuthority = null,
      ContractAuthority = null,
      Rating = null,
      OutOfService = false,
      Drivers = 0,
      PowerUnits = 0,
      UnsafeDriving = 0,
      HoursofService = 0,
      VehicleMaintenance = 0,
      ControlledSubstance = 0,
      DriverFitness = 0,
    } = product;
    const newProduct = {
      Name,
      ScacCode,
      IsIntraStateCarrier,
      IsSmartWayPartner,
      IsActive,
      McNumber,
      DotNUmber,
      SmartWayFleetId,
      IATANumber,
      ExternalId,
      TsaComplianceId,
      VendorTypeId,
      CompanyName,
      Approved,
      Phone,
      Fax,
      WebUrl,
      TrackingUrl,
      Street1,
      Street2,
      City,
      State,
      ZipCode,
      Country,
      Borkers,
      Score,
      ScoreText,
      RequiredId,
      TaxId,
      Notes,
      IsTruckTools,
      IsSmallPckage,
      IsLTL,
      IsTruckLoad,
      IsDomesticFreight,
      IsCartage,
      IsOther,
      IsInsuranceProvider,
      IsDrayage,
      IsAirHouse,
      IsAirMaster,
      IsOcean,
      IsOceanCombined,
      IsNationalCarrier,
      IsZone0,
      IsZone1,
      IsZone2,
      IsZone3,
      IsZone4,
      IsZone5,
      IsZone6,
      IsZone7,
      IsZone8,
      IsZone9,
      IsZoneCA,
      IsZoneMX,
      UseFactoringCompany,
      AddressToUseId,
      ComanyName,
      PrimaryStreet1,
      PrimaryStreet2,
      PrimaryCity,
      PrimaryState,
      PrimaryZipCode,
      PrimaryCountry,
      AccountPhone,
      AccountFax,
      Email,
      NetTermsId,
      PreferredPaymentTypeId,
      CreditLimit,
      AcceptCreditCards,
      CreditCardPrcessFee,
      PaymentFrequencyId,
      NewMaxCoveredValue,
      NewBuyRate,
      NewBuyMinimum,
      NewSellRate,
      NewSellMiniumum,
      NewDisclaimer,
      UsedMaxCoveredValue,
      UsedBuyRate,
      UsedBuyMinimum,
      UsedSellRate,
      UsedSellMiniumum,
      UsedDisclaimer,
      DotStatus,
      BrokerAuthority,
      CommonAuthority,
      ContractAuthority,
      Rating,
      OutOfService,
      Drivers,
      PowerUnits,
      UnsafeDriving,
      HoursofService,
      VehicleMaintenance,
      ControlledSubstance,
      DriverFitness,
    };
    var PostCarrie = await PostCarrier(newProduct);
    if (PostCarrie[0] !== 200) {
      window.$LSPCarrierMsg = [400, PostCarrie[1]];
      return [400, { product: newProduct }];
    } else {
      window.$LSPCarrierMsg = [200, PostCarrie[1]];
      carTableMock.push(newProduct);
      //carTableMock.push(newProduct);
      return [200, { product: newProduct }];
    }
  });

  mock.onPost("api/ManageLSPCarrier/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    console.log("queryParams", queryParams);
    var DotNUmber = queryParams.filter.DotNUmber || "";
    var IATANumber = queryParams.filter.IATANumber || "";
    var Name = queryParams.filter.Name || "";
    var Phone = queryParams.filter.Phone || "";
    var Scac = queryParams.filter.Scac || "";
    var State = queryParams.filter.State || "";
    var TsaCompliance = queryParams.filter.TsaCompliance || "";
    var Unapproved = queryParams.filter.Unapproved===undefined?"":queryParams.filter.Unapproved;
    var IsSmartWayPartner = queryParams.filter.IsSmartWayPartner=== undefined ?"":queryParams.filter.IsSmartWayPartner;
    var IsActive = queryParams.filter.IsActive===undefined?"":queryParams.filter.IsActive;
    var VendorTypeId = queryParams.filter.VendorTypeId || "";
    var McNumber = queryParams.filter.McNumber || "";
    var pageNumber = queryParams.pageNumber || "";
    var pageSize = queryParams.pageSize || "";
    var sortField = queryParams.sortField || "";
    var sortOrder = queryParams.sortOrder || "";
    var rmisId = queryParams.filter.rmisId || "";
    const data = await GetCarrierList(
      DotNUmber,
      IATANumber,
      Name,
      Phone,
      Scac,
      State,
      TsaCompliance,
      Unapproved,
      IsSmartWayPartner,
      IsActive,
      VendorTypeId,
      McNumber,
      rmisId,
      pageNumber,
      pageSize,
      sortField,
      sortOrder
    );

    carTableMock2 = ChangeId(data.carriers);
    delete queryParams.filter.DotNUmber;
    delete queryParams.filter.IATANumber;
    delete queryParams.filter.Name;
    delete queryParams.filter.Phone;
    delete queryParams.filter.Scac;
    delete queryParams.filter.State;
    delete queryParams.filter.TsaCompliance;
    delete queryParams.filter.Unapproved;
    delete queryParams.filter.IsSmartWayPartner;
    delete queryParams.filter.IsActive;
    delete queryParams.filter.VendorTypeId;
    delete queryParams.filter.McNumber;
    delete queryParams.filter.rmisId;
    const filteredProducts = mockUtils.baseFilter(carTableMock2, queryParams);
    filteredProducts.totalCount = data?.Pagination?.TotalItemCount ?? 0;

    return [200, filteredProducts];
  });

  mock.onPost("api/ManageLSPCarrier/deleteProducts").reply((config) => {
    carTableMock = undefined;
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = carTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/ManageLSPCarrier/updateStatusForProducts")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      carTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/ManageLSPCarrier\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageLSPCarrier\/(\d+)/)[1];
    const product = await GetCarrier(id);
    if (!product) {
      return [400];
    }

    return [200, product];
  });

  mock.onPut(/api\/ManageLSPCarrier\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageLSPCarrier\/(\d+)/)[1];
    const { product } = JSON.parse(config.data);
    product.MasterCarrierId = id;
    var postOrder = await PutCarrier(id, product);
    carTableMock = undefined;
    if (postOrder[0] !== 200) {
      window.$LSPCarrierMsg = [400, postOrder[1]];
      return [400];
    } else {
      window.$LSPCarrierMsg = [200, postOrder[1]];
      // const index = carTableMock.findIndex((el) => el.id === +id);
      // carTableMock[index] = { ...product };
      //carTableMock.push(newProduct);
      return [200];
    }
  });

  mock.onDelete(/api\/ManageLSPCarrier\/\d+/).reply(async (config) => {
    carTableMock = undefined;
    const id = config.url.match(/api\/ManageLSPCarrier\/(\d+)/)[1];
    var deleteMasterCarrier = await DeleteMasterCarrier(id);
    carTableMock = undefined;
    if (deleteMasterCarrier[0] !== 200) {
      window.$LSPCarrierMsg = [400, deleteMasterCarrier[1]];
      return [400];
    } else {
      window.$LSPCarrierMsg = [200, deleteMasterCarrier[1]];
      //carTableMock.push(newProduct);
      return [200];
    }
  });
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
