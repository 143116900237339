import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useParams,Link } from "react-router-dom";
import axios from "axios";
function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
};
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);
const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
}));
export function TransactionBilling() {
    const { id } = useParams("id");
    const [billingShippingList, setbillingShippingList] = useState([]);
    const [billingReceivingList, setbillingReceivingList] = useState([]);
    const [billingAssembiesList, setbillingAssembiesList] = useState([]);
    const [billingAdjustmentsList, setbillingAdjustmentsList] = useState([]);
    const [billingTransferInList, setbillingTransferInList] = useState([]);
    const [billingTransferOutList, setbillingTransferOutList] = useState([]);
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }
 
    useEffect(() => {
        fetchBillingViewModelAsync();
    }, []);

    const fetchBillingViewModelAsync = async () => {
        setbillingShippingList([]);
        try 
        {
            const result = await axios.get(`api/transactionbilling/${id}`);
            if (result.status === 200)
            {
                result.data[1].Charges.map(function(obj){
                    switch(obj.Name)
                    {
                        case 'Shipping' :
                            setbillingShippingList(obj.Calculators);
                            break;
                        case 'Receiving':
                            setbillingReceivingList(obj.Calculators);
                            break;
                        case 'Adjsutments':
                            setbillingAdjustmentsList(obj.Calculators);
                            break;
                        case 'TransferIn':
                            setbillingTransferInList(obj.Calculators);
                            break;
                        case 'TransferOut':
                            setbillingTransferOutList(obj.Calculators);
                            break;
                        case 'Assemblies':
                            setbillingAssembiesList(obj.Calculators);
                            break;
                    }
            });         
        }
        }catch (e)
        {
        }
    };

    return (
        <div className={classes.root}>
            <div className="col-md-12 my-6">

                <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                The Billing Wizard™ can be used during Receiving, Shipping, Assemblies and Adjusting to assist in computing charges.
                </p>
                <p className="text-dark-50 m-0 pt-5 font-weight-normal">
                Click the "Update" links to configure what appears in each Billing Wizard™.
                    </p>
            </div>
            <AppBar position="static" color="white">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Shipping" />
                    <Tab label="Receiving" />
                    <Tab label="Assemblies" />
                    <Tab label="Adjustments" />
                    <Tab label="TransferIn" />
                    <Tab label="TransferOut" />
                </Tabs>
            </AppBar>
            <SwipeableViews
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billingShippingList.map(row => (
                                <StyledTableRow key={row.Id}>
                                    <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                    <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                    <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 1;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                { billingReceivingList.map(row => (
                                    <StyledTableRow key={row.Id}>
                                        <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                        <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 2;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                    </StyledTableRow>
                                ))} 
                        </TableBody>
                    </Table>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                { billingAssembiesList.map(row => (
                                    <StyledTableRow key={row.Id}>
                                        <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                        <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 3;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                { billingAdjustmentsList.map(row => (
                                    <StyledTableRow key={row.Id}>
                                        <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                        <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 4;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                               { billingTransferInList.map(row => (
                                    <StyledTableRow key={row.Id}>
                                        <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                        <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 5;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TabContainer>
                <TabContainer dir={theme.direction}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">Calculator</StyledTableCell>
                                <StyledTableCell align="center">Description</StyledTableCell>
                                <StyledTableCell align="center"></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                 { billingTransferOutList.map(row => (
                                    <StyledTableRow key={row.Id}>
                                        <StyledTableCell align="center">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="center">{row.Description}</StyledTableCell>
                                        <StyledTableCell align="center"><Link to="/TransactionBillingShipping" onClick={()=>{
                                        window.customerId = id;
                                        window.chargeTypeId = 6;
                                        window.calculatorId = row.Id;
                                    }}>Update</Link> ({row.Count} charges)</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TabContainer>
            </SwipeableViews>
        </div>
    );
}
