import {
  Drawer,
  emphasize,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { SendEmail } from "./SendEmail";
import { TmsAttachementsStatus } from "../../Transload/FindOrders/product-edit/OrderAttachements";
import Select, { components, PlaceholderProps } from "react-select";

import { DateTime30minInterval } from "../../../../../../_metronic/_helpers/ATHelpers";
import { Notification } from "../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    position: "absolute",
  },
  isDisabled: {
    color: "currentColor",
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: "0.5",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
    justifyContent: "flex-end",
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
}));

export function ActionsStatus({
  filterOptions,
  DataWareHouse,
  GetWarehousDataPageChanges,
  paginationOptions,
  SelectedRow,
  setSelectedRow,
  SelectedRowBulk,
}) {
  const [loadingModalShow, setloadingModalShow] = useState(false);
  const [showModalSend, setshowModalSend] = useState(false);
  const [showModalPickedUp, setshowModalPickedUp] = useState(false);
  const [loadedModalShow, setloadedModalShow] = useState(false);
  const [LoadingPostStatus, setLoadingPostStatus] = useState(false);
  const [ShowModalAssigned, setShowModalAssigned] = useState(false);
  const [optionsVandorList, setoptionsVandorList] = useState([]);
  const [initAssignedStatusVendor, setinitAssignedStatusVendor] = useState({
    ShipmentIds: SelectedRow,
    VendorId: SelectedRowBulk[0]?.CarrierId || "",
    VendorTypeId: 1308,
    ShipmentStatus: "",
    ThirdPartyBillToId: "",
    FreightChargeId: 98,
  });
  const [FreightChargeList, setFreightChargeList] = useState([]);
  const [optionsVandorTypeList, setoptionsVandorTypeList] = useState([]);
  const [BillToList, setBillToList] = useState([]);

  function SubmitLoadedDetail(parameters) {
    var SealNumber = document.getElementById(`SealNumber`).value;
    if (SealNumber === "" || SealNumber === undefined || SealNumber === null) {
      Notification("error", "please fill Seal #");
      return;
    }
    setLoadingPostStatus(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentIds: SelectedRow,
      ShipmentStatus: 6,
      SealNumber: SealNumber,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Shipment has been Updated successfully");
          GetWarehousDataPageChanges(paginationOptions);
          setloadedModalShow(false);
          setSelectedRow([]);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
          setloadedModalShow(false);
        }
        setLoadingPostStatus(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPostStatus(false);
      });
  }
  function SubmitLoadingDetail() {
    var Traillerdrop = document.getElementById(`Traillerdrop`).value;
    if (
      Traillerdrop === "" ||
      Traillerdrop === undefined ||
      Traillerdrop === null
    ) {
      Notification("error", "please fill tailler");
      return;
    }
    setLoadingPostStatus(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentIds: SelectedRow,
      ShipmentStatus: 5,
      EquipmentNumber: Traillerdrop,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Shipment has been Updated successfully");
          GetWarehousDataPageChanges(paginationOptions);
          setloadingModalShow(false);
          setSelectedRow([]);
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
          setloadingModalShow(false);
        }
        setLoadingPostStatus(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPostStatus(false);
      });
  }
  function NextStatus(id, statusId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      ShipmentIds: id,
      ShipmentStatus: statusId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("infoClick", result[1].Message);
            GetWarehousDataPageChanges(paginationOptions);
          } else {
            Notification("success", "Shipment has been Updated successfully");
            GetWarehousDataPageChanges(paginationOptions);
            setSelectedRow([]);
          }
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function(k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function nextOrder(statusId) {
    const index = DataWareHouse?.ShipmentsCount?.findIndex(
      (order) => String(order.StatusId) === String(statusId)
    );
    if (index === -1 || index === DataWareHouse?.ShipmentsCount.length - 1) {
      // No such order or no next order
      return null;
    }
    return DataWareHouse?.ShipmentsCount[index + 1];
  }
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    setState({ ...state, [side]: open });
    if (open) {
      setTimeout(() => {
        document.getElementsByClassName("MuiDrawer-modal")[0].style.visibility =
          "visible";
      }, 500);
    }
    event.preventDefault();
  };
  const fullList = (side) => (
    <div className={classes.fullList} role="presentation">
      <div
        id="kt_quick_user"
        className="offcanvas offcanvas-right p-10 offcanvas-on"
      >
        {/*begin::Header*/}
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height={40}
        >
          <h3 className="font-weight-bold m-0">Select an Action For</h3>

          <Link
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
            onClick={toggleDrawer(side, false)}
          >
            <i className="ki ki-close icon-xs text-muted" />
          </Link>
        </div>
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
          style={{ height: "657px", overflow: "hidden" }}
        >
          {filterOptions.statusId !== "" && SelectedRow.length !== 0 ? (
            <div className="navi navi-spacer-x-0 p-0">
              {DataWareHouse?.ShipmentsCount?.map((item, index) => {
                return (
                  <Link
                    onClick={() => {
                      setState({
                        top: false,
                        left: false,
                        bottom: false,
                        right: false,
                      });
                      document.getElementById("kt_body").style.overflow =
                        "scroll";
                      if (index !== null) {
                        if (item.StatusId === 5) {
                          setloadingModalShow(true);
                          return;
                        }
                        if (item.StatusId === 6) {
                          setloadedModalShow(true);
                          return;
                        }
                        if (item.StatusId === 102) {
                          setshowModalSend(true);
                          return;
                        }
                        if (item.StatusId === 3) {
                          getvandorsList();
                          setinitAssignedStatusVendor({
                            ShipmentIds: SelectedRow,
                            VendorId: SelectedRowBulk[0]?.CarrierId || "",
                            VendorTypeId: 1308,
                            ShipmentStatus: 3,
                            ThirdPartyBillToId: "",
                            FreightChargeId: 98,
                          });
                          setShowModalAssigned(true);
                          return;
                        }
                        if (item.StatusId === 7) {
                          setshowModalPickedUp(true);
                          return;
                        }
                        NextStatus(SelectedRow, item.StatusId);
                      }
                    }}
                    className={`navi-item ${
                      String(nextOrder(filterOptions.statusId)?.StatusId) !==
                      String(item.StatusId)
                        ? classes.isDisabled
                        : ""
                    }`}
                    key={index}
                  >
                    <div className="navi-link">
                      <div className=" mr-3">
                        <div className="symbol-label">
                          {item.StatusId !== filterOptions.statusId ? (
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <i className="flaticon-more-v4 text-warning"></i>
                            </span>
                          ) : (
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <i class="flaticon-medical text-success"></i>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold">
                          {item.StatusName}
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              })}

              {/*end:Item*/}
            </div>
          ) : (
            "Please Select a status and Select at least one shipment"
          )}

          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: "0px", width: "0px" }}
            />
          </div>
          <div
            className="ps__rail-y"
            style={{ top: "0px", height: "657px", right: "0px" }}
          >
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: "0px", height: "300px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
  function PostAssignedVandors() {
    try {
      var VandorSelected = initAssignedStatusVendor.VendorId;
      if (
        VandorSelected === null ||
        VandorSelected === undefined ||
        VandorSelected === ""
      ) {
        Notification("errorClick", "Please select vendor");
        return;
      }
      setLoadingPostStatus(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var raw = JSON.stringify(initAssignedStatusVendor);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        window.$apiurl + `/warehouseManagement/UpdateMultipleStatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("infoClick", result[1].Message);
              setShowModalAssigned(false);
            } else {
              Notification(
                "success",
                `Status of shipments : [${initAssignedStatusVendor.ShipmentIds.map(
                  (customer) => customer + ", "
                )}] has been changed successfully`
              );
            }
            GetWarehousDataPageChanges(paginationOptions);
            setShowModalAssigned(false);
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
          }
          setLoadingPostStatus(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingPostStatus(false);
        });
    } catch (e) {}
  }
  function getvandorsList() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/vendors", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        const opt = [];
        data.Vendors.forEach((Vendor) => {
          opt.push({
            value: Vendor.Id,
            label: Vendor.Code + "-" + Vendor.CompanyName,
            color: Vendor?.IsApproved ? "black" : "red",
            CarrierId: Vendor?.CarrierId,
            IsApproved: Vendor?.IsApproved,
          });
        });
        setoptionsVandorList(opt || []);
        setFreightChargeList(data?.FreightCharges ?? []);
        setoptionsVandorTypeList(data?.VendorTypes || []);
        setBillToList(data?.BillTo || []);
      })
      .catch((error) => console.log("error", error));
  }
  const Option = (props) => {
    return (
      <div style={{ color: props.data.color }}>
        <components.Option {...props} />
      </div>
    );
  };
  return (
    <div>
      {/* start dispatched Modal */}

      <Modal
        size="xl"
        id="DispatchedDateModal"
        show={ShowModalAssigned}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Assigned Details
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => {
              setShowModalAssigned(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className=" overlay-block">
          <div className="form-group row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-3">
                  <p className="mb-3">Vendors</p>
                  <Select
                    name="vandorId"
                    components={{ Option }}
                    options={optionsVandorList.filter((a) => {
                      var obj = { ...a };
                      if (initAssignedStatusVendor.FreightChargeId === 98) {
                        if (a.IsApproved === false) {
                          return {
                            value: obj?.value,
                            label: obj?.label,
                            color: "red",
                            CarrierId: obj?.CarrierId,
                            IsApproved: obj?.IsApproved,
                          };
                        } else {
                          return {
                            value: obj?.value,
                            label: obj?.label,
                            color: "black",
                            CarrierId: obj?.CarrierId,
                            IsApproved: obj?.IsApproved,
                          };
                        }
                      } else {
                        return {
                          value: obj?.value,
                          label: obj?.label,
                          color: "black",
                          CarrierId: obj?.CarrierId,
                          IsApproved: obj?.IsApproved,
                        };
                      }
                    })}
                    value={optionsVandorList.filter(
                      (a) => a.value === initAssignedStatusVendor.VendorId
                    )}
                    onChange={(event) => {
                      var dataobj = { ...initAssignedStatusVendor };
                      dataobj.VendorId = event?.value;
                      setinitAssignedStatusVendor(dataobj);
                    }}
                  ></Select>
                  {initAssignedStatusVendor.FreightChargeId === 98 &&
                    (optionsVandorList?.filter(
                      (a) => a.value === initAssignedStatusVendor.VendorId
                    )[0]?.IsApproved === false ? (
                      <p style={{ color: "red" }}>
                        This Vendor is not Certified
                      </p>
                    ) : (
                      ""
                    ))}
                </div>
                <div className="col-lg-3">
                  <p className="mb-3">Vendor Type</p>
                  <Select
                    name="VendorTypeId"
                    value={optionsVandorTypeList
                      .filter(
                        (a) => a.Id === initAssignedStatusVendor.VendorTypeId
                      )
                      .map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                    options={optionsVandorTypeList.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Name,
                      };
                    })}
                    onChange={(event) => {
                      var dataobj = { ...initAssignedStatusVendor };
                      dataobj.VendorTypeId = event?.value;
                      setinitAssignedStatusVendor(dataobj);
                    }}
                  ></Select>
                </div>
                <div className="col-lg-3">
                  <p className="mb-3">Freight Charge</p>
                  <Select
                    name="FreightChargeId"
                    value={FreightChargeList.filter(
                      (a) => a.Id === initAssignedStatusVendor.FreightChargeId
                    ).map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Name,
                      };
                    })}
                    options={FreightChargeList.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.Name,
                      };
                    })}
                    onChange={(event) => {
                      var dataobj = { ...initAssignedStatusVendor };
                      dataobj.FreightChargeId = event?.value;
                      setinitAssignedStatusVendor(dataobj);
                      //setVandorSelectedData(null);
                    }}
                  ></Select>
                </div>
                {initAssignedStatusVendor.FreightChargeId === 100 ? (
                  <div className="col-lg-3">
                    <p className="mb-3">BillTo</p>
                    <Select
                      name="BillTo"
                      options={BillToList.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name + "-" + x?.Code,
                        };
                      })}
                      onChange={(event) => {
                        var dataobj = { ...initAssignedStatusVendor };
                        dataobj.ThirdPartyBillToId = event?.value;
                        setinitAssignedStatusVendor(dataobj);
                      }}
                    ></Select>
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="col-lg-12 mt-5">
                    {initAssignedStatusVendor.CarrierId !== null &&
                    initAssignedStatusVendor.CarrierId !== "" &&
                    initAssignedStatusVendor.CarrierId !== undefined ? (
                      <ChargesFunctionCompo
                        VandorSelectedData={initAssignedStatusVendor.CarrierId}
                        customers={[row]}
                        optionsVandorList={optionsVandorList}
                        isInbound={false}
                        linq={"transloadshipments"}
                      />
                    ) : (
                      ""
                    )}
                  </div> */}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-lg-6"></div>
            <div className="col-lg-5 ml-5"></div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="mr-3"
            onClick={() => {
              setShowModalAssigned(false);
            }}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={LoadingPostStatus}
            onClick={async () => {
              PostAssignedVandors();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save changes
            {LoadingPostStatus && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/* end dispatched modal */}
      {/* loading Modal */}
      <Modal
        size="md"
        id="AppointedDateModal"
        show={loadingModalShow}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Loading Details
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setloadingModalShow(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className=" overlay-block">
          <div className="form-group row">
            <div className="col-lg-12">
              <label>
                <b>Trailler #</b>
              </label>
              <input
                style={{ marginTop: "10px", width: "100%" }}
                id="Traillerdrop"
                name="Traillerdrop"
                className="form-control"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setloadingModalShow(false);
            }}
            className="btn btn-light btn-elevate mr-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            //disabled={LoadingLoadingStatus}
            onClick={() => {
              SubmitLoadingDetail();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save changes
            {LoadingPostStatus && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/* loaded Modal */}
      <Modal
        size="md"
        id="AppointedDateModal"
        show={loadedModalShow}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Loaded Details
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setloadedModalShow(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className=" overlay-block">
          <div className="form-group row">
            <div className="col-lg-12">
              <label>
                <b>Seal #</b>
              </label>
              <input
                style={{ marginTop: "10px", width: "100%" }}
                id="SealNumber"
                name="SealNumber"
                className="form-control"
                type="text"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => {
              setloadedModalShow(false);
            }}
            className="btn btn-light btn-elevate mr-3"
          >
            Cancel
          </button>
          <button
            type="submit"
            //disabled={LoadingLoadingStatus}
            onClick={() => {
              SubmitLoadedDetail();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save changes
            {LoadingPostStatus && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/* Picked Up Modal */}
      <Modal
        size="md"
        id="AppointedDateModal"
        show={showModalPickedUp}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Picked Up Details
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              setshowModalPickedUp(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className=" overlay-block">
          <TmsAttachementsStatus
            id={SelectedRow}
            Type="shipment"
            setshowModalPickedUp={setshowModalPickedUp}
            paginationOptions={paginationOptions}
            GetWarehousDataPageChanges={GetWarehousDataPageChanges}
            IsMultu={true}
          />
        </Modal.Body>
      </Modal>
      {/* Notify Trucker Modal */}
      {showModalSend && (
        <SendEmail
          row={SelectedRow}
          showModalSend={showModalSend}
          setshowModalSend={setshowModalSend}
          NextStatus={NextStatus}
          IsMultu={true}
          GetWarehousDataPageChanges={GetWarehousDataPageChanges}
          paginationOptions={paginationOptions}
        />
      )}
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Actions</Tooltip>}
        >
          <li className="nav-item mb-2" data-placement="right">
            <Link
              onClick={toggleDrawer("right", true)}
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            >
              <i className="flaticon2-drop h1-md" />
            </Link>
          </li>
        </OverlayTrigger>
      </ul>
      <Drawer
        anchor="right"
        open={state.right}
        //onClose={toggleDrawer("right", false)}
      >
        {fullList("right")}
      </Drawer>
    </div>
  );
}
