import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    textDecoration: {
        lineHeight: '2rem',
      },
  }));
export function CustomerDialogForm() {
    const [open, setOpen] = React.useState(false);

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const classes = useStyles();
    return (
        <div>
            <button
                type="button"
                className="btn btn-success btn-shadow-hover mr-2"
                variant="outlined" onClick={handleClickOpen}
            >
                Import
          </button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Import</DialogTitle>
                <DialogContent>
                        <div className="row">
                            <div className="col-md-12 my-5">
                                <div className="row">
                                    <div className="col-md-3">
                                        <label class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
                                            Import
                                                </label>
                                    </div>
                                    <div className="col-md-9">
                                        <input type="file" class="my-4 m-0 pt-5" />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="my-4 m-0">
                                            <Button className="bg-primary text-white">
                                                Import Zones
                                                    </Button>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <span className={classes.textDecoration}>
                                            Use the 'Browse...' button to select an zone import file
                                            Then press the 'Import Zones' button to read the file.
                                                </span>
                                    </div>
                                </div>
                                <label class="text-dark-50 m-0 pt-5 font-weight-normal">
                                    Zones To Be Updated
                                        </label>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="my-4 m-0">
                                            <Button className="bg-primary text-white">
                                                Update Zones
                                                    </Button>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <span className={classes.textDecoration}>
                                            After previewing the imported data for accuracy
                                            Press the 'Update Zones' button to update the Zones.
                                                </span>
                                    </div>
                                </div>
                                <label class="text-dark-50 m-0 pt-5 font-weight-normal">
                                    Data From Import File                            
                                </label>
                                <div className="row">
                                    <textarea class="text-dark-50 m-0 pt-5 font-weight-normal pl-5 ml-5" rows="4" cols="70"></textarea>
                                </div>

                                <label class="text-dark-50 m-0 pt-5 font-weight-normal">
                                    Data From Import File                            
                                </label>
                                <div className="row">
                                    <textarea class="text-dark-50 m-0 pt-5 font-weight-norma pl-5 ml-5" rows="4" cols="70"></textarea>
                                </div>
                            </div>
                        </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
