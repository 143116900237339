import React from "react";
import { Route } from "react-router-dom";
import { ProductsLoadingDialog } from "./receipts-loading-dialog/ReceiptsLoadingDialog";
import { ProductDeleteDialog } from "./receipt-delete-dialog/ReceiptDeleteDialog";
import { ProductsDeleteDialog } from "./receipts-delete-dialog/ReceiptsDeleteDialog";
import { ProductsFetchDialog } from "./receipts-fetch-dialog/ReceiptsFetchDialog";
import { ProductsUpdateStatusDialog } from "./receipts-update-status-dialog/ReceiptsUpdateStatusDialog";
import { ProductsCard } from "./ReceiptsCard";
import { ProductsUIProvider } from "./ReceiptsUIContext";

export function FindReceiptsPage({ history }) {
  const productsUIEvents = {
    newProductButtonClick: () => {
      history.push("/FindReceipts/new");
    },
    openEditProductPage: (id) => {
      history.push(`/FindReceipts/${id}/edit`);
    },
    openDeleteProductDialog: (id) => {
      history.push(`/FindReceipts/${id}/delete`);
    },
    openDeleteProductsDialog: () => {
      history.push(`/FindReceipts/deleteReceipts`);
    },
    openFetchProductsDialog: () => {
      history.push(`/FindReceipts/fetch`);
    },
    openUpdateProductsStatusDialog: () => {
      history.push("/FindReceipts/updateStatus");
    },
  };

  return (
    <ProductsUIProvider productsUIEvents={productsUIEvents}>
      <ProductsLoadingDialog />
      <Route path="/FindReceipts/deleteReceipts">
        {({ history, match }) => (
          <ProductsDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/FindReceipts");
            }}
          />
        )}
      </Route>
      <Route path="/FindReceipts/:id/delete">
        {({ history, match }) => (
          <ProductDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/FindReceipts");
            }}
          />
        )}
      </Route>
      <Route path="/FindReceipts/fetch">
        {({ history, match }) => (
          <ProductsFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/FindReceipts");
            }}
          />
        )}
      </Route>
      <Route path="/FindReceipts/updateStatus">
        {({ history, match }) => (
          <ProductsUpdateStatusDialog
            show={match != null}
            onHide={() => {
              history.push("/FindReceipts");
            }}
          />
        )}
      </Route>
      <ProductsCard />
    </ProductsUIProvider>
  );
}
