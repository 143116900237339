export default [
  {
    id: 1,
    So: "123456789",
    Customer: "Lulu's",
    Pu: "Downey Ca",
    Del: "Chico Ca",
    Cust: "$2185",
    Bid: "$1800",
  },
  {
    id: 2,
    So: "123456789",
    Customer: "Lulu's",
    Pu: "Downey Ca",
    Del: "Chico Ca",
    Cust: "$2185",
    Bid: "$1800",
  },
  {
    id: 3,
    So: "123456789",
    Customer: "MBG",
    Pu: "Downey Ca",
    Del: "Lebec Ca",
    Cust: "$1800",
    Bid: "$1600",
  },
  {
    id: 4,
    So: "123456789",
    Customer: "MBG",
    Pu: "Downey Ca",
    Del: "Lebec Ca",
    Cust: "$1800",
    Bid: "$1600",
  },
];
