import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default async function mockClassifications(mock) {

  async function GetCompanyInfo() {
    return await fetch(`${window.$apiurl}/companyinfo`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetCountries() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(window.$apiurl + "/countries",requestOptions)
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetStates(id) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(`${window.$apiurl}/states/${id}`,requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PutCompany(someData) {
    let userDate = someData["company"];
    return fetch(`${window.$apiurl}/companyinfo`, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDate),
    })
      .then(async (response) => {
        const statusCode = response.status;
        let data = {};
        if (statusCode === 400) data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/companyinfo").reply(async () => {
    const obj = await GetCompanyInfo();
    return [200, obj];
  });

  mock.onPut("api/companyinfo").reply(async (config) => {
    const company = JSON.parse(config.data);
    var result = await PutCompany(company);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });

  mock.onGet("api/companyinfo/getcountries").reply(async () => {
    const obj = await GetCountries();
    return [200, obj];
  });

  mock.onGet(/api\/companyinfo\/getstates\/\w+/).reply(async (config) => {
    const id = config.url.match(/api\/companyinfo\/getstates\/(\w+)/)[1];
    var getStates = await GetStates(id);
    if (!getStates) return [400];
    return [200, getStates];
  });
}
