import React, { useEffect, useState, useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import {
  CheckboxLarge,
} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";

const prepareFilter = (queryParams, values) => {
  const { ShowInactive, CarriersId, ReLoadTable } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.ReLoadTable = ReLoadTable || false;
  filter.ShowInactive = ShowInactive;
  filter.CarriersId = CarriersId;
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };
  const [Carriers, setCarriers] = useState([]);
  useEffect(() => {
    fetchUsersAsync();
  }, []);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/ServiceManageCarriers/GetCarriers");
      setCarriers(result.data);
    } catch (e) {}
  };
  return (
    <>
      <Formik
        initialValues={{
          ShowInactive: false,
          CarriersId: undefined,
          ReLoadTable: 0,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <input
              type="button"
              className="d-none"
              id="ReLoadTableId"
              onClick={(e) => {
                if (e.target.value === "0")
                  document.getElementById("ReLoadTableId").value = 1;
                else document.getElementById("ReLoadTableId").value = 0;
                setFieldValue("ReLoadTable", e.target.value);
                handleSubmit();
              }}
            />
            <div className="form-group row">
              <div className="col-lg-6">
                <select
                  className="form-control"
                  name="CarriersId"
                  placeholder="Filter by Status"
                  onChange={(e) => {
                    setFieldValue("CarriersId", e.target.value);
                    window.ServiceManageCarriers = e.target.value;
                    handleSubmit();
                  }}
                >
                  <option value="0">Select</option>
                  {Carriers.map((item, index) => (
                    <option value={item.Id} key={index}>
                      {item.Carrier}
                    </option>
                  ))}
                </select>
                {Carriers.length === 0 && <LinearProgress />}
                <small className="form-text text-muted">
                  <b>Filter</b> by Carriers
                </small>
              </div>
              <div className="col-lg-6">
                <CheckboxLarge
                  text="Show Inactives"
                  name="ShowInactive"
                  id="ShowInactiveId"
                  onChange={(event) => {
                    setFieldValue("ShowInactive", event.target.checked);
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
