import React, { useState, useEffect } from "react";
import {
  OverlayTrigger,
  Modal,
  Alert,
  Accordion,
  Tooltip,
  Tabs,
  Tab,
  Dropdown,
  Spinner,
  Row,
  Col,
} from "react-bootstrap";
import {
  momentDate,
  currencyFormat,
  momentDateOnly,
  ActivityFunction,
  DateTime30minInterval,
  GetBaseData,
  DateTime30GL,
  GetCarrierCheckedInData,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import Select, { components, PlaceholderProps } from "react-select";
import {
  OrderAttachementsV2,
  TmsAttachementsStatus,
} from "../../Transload/FindOrders/product-edit/OrderAttachements";
import { Notification } from "../../../../../modules/Notification";
import { Icon, TextField } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { RemarkEditDialog } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/RemarkEditDialog";
import { ContainersUIProvider } from "../../Transload/FindOrders/product-Containers/RemarksUIContext";
import axios from "axios";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import $ from "jquery";
import {
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Button,
  Paper,
  IconButton,
  TablePagination,
} from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import moment from "moment";
import { OneMoveMaster } from "../../../../eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import CloseIcon from "@material-ui/icons/Close";
import { Card } from "react-bootstrap";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PDFViewer from "pdf-viewer-reactjs";
import FileViewer from "react-file-viewer";
import "../../ReceiptsManagementNewVersion/customers-table/FileViewerStyle.css";
import FileSaver from "file-saver";
import { DropdownCustomTogglerYellow } from "../../../../../../_metronic/_partials/dropdowns/DropdownCustomToggler";
import { SendEmail } from "./SendEmail";
import {
  CheckboxLargeWB,
  DenseTableSm,
} from "../../../../../../_metronic/_partials/controls";
import DynamicReport from "../../../../../pages/DynamicReport/DynamicReport";
import { BLFORM } from "../../../../../pages/BL/BLFORM";
import { ChargesFunctionCompo } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
import MenuIndex from "./Menu/Index";
import Swal from "sweetalert2";
const adapter = new LocalStorage("db");
const db = low(adapter);

let statusNoteRollBack = "";
let statusIdRollBack = "";
const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
function formatDate(date) {
  if (date === null || date === undefined || date === "") {
    return "";
  }
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("-");
}
function IsValid(name, str) {
  try {
    if (
      str?.includes("null") ||
      str?.includes("undefined") ||
      str?.includes("Invalid date")
    )
      str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row w-100 my-2 ml-3">
        <div className="col-lg-4 font-weight-bold text-dark text-left pl-5">
          {name}
        </div>
        <div className="col-lg-8 text-primary">
          : {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}
function IsValidColumn(name, str) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row w-100 mb-5">
        <div className="col-lg-12 font-weight-bold text-dark text-center">
          {name}
        </div>
        <div className="col-lg-12 text-primary text-center">
          {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}

function tConvert(time) {
  if (time === undefined || time === null || time === "") {
    return "";
  } else {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
}
let checkindriver = {
  ShipmentId: "",
  DriverName: "",
  PhoneNumber: "",
  CompanyName: "",
  PickupNumber: "",
  PickupAppDate: new Date(),
  SealDroppingTrailerNumber: "",
  SealDroppingNumber: "",
  SealPickingUpTrailerNumber: "",
  SealPickingUpNumber: "",
  Type: 1,
  IsChanged: false,
};
let checkindriverOut = {
  ShipmentId: "",
  DriverName: "",
  PhoneNumber: "",
  CompanyName: "",
  PickupNumber: "",
  PickupAppDate: new Date(),
  SealDroppingTrailerNumber: "",
  SealDroppingNumber: "",
  SealPickingUpTrailerNumber: "",
  SealPickingUpNumber: "",
  Type: 2,
  IsChanged: false,
};
const Option = (props) => {
  return (
    <div style={{ color: props.data.color }}>
      <components.Option {...props} />
    </div>
  );
};
export const ModelMnagementTMSWarehouse = ({
  row,
  filterOptions,
  DataWareHouse,
  GetWarehousDataPageChanges,
  paginationOptions,
  ...props
}) => {
  function nextOrder(statusId) {
    const index = DataWareHouse?.ShipmentsCount?.findIndex(
      (order) => String(order.StatusId) === String(statusId)
    );
    if (
      index === -1 ||
      index === DataWareHouse?.ShipmentsCount.length - 2 ||
      index === DataWareHouse?.ShipmentsCount.length - 1
    ) {
      // No such order or no next order
      return null;
    }

    return DataWareHouse?.ShipmentsCount[index + 1];
  }
  function PrevOrder(statusId) {
    const index = DataWareHouse?.ShipmentsCount?.findIndex(
      (order) => String(order.StatusId) === String(statusId)
    );
    if (index === -1 || index === 0) {
      // No such order or no next order
      return null;
    }

    return DataWareHouse?.ShipmentsCount[index - 1];
  }
  const classes = useStyles();
  const [Show, setShow] = useState(false);
  const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);
  const [Issue210, setIssue210] = useState([]);
  const [Show210issue, setShow210issue] = useState(false);
  const [LoadingCarrierPost, setLoadingCarrierPost] = useState(false);
  const [loadingModalShow, setloadingModalShow] = useState(false);
  const [showModalSend, setshowModalSend] = useState(false);
  const [showModalPickedUp, setshowModalPickedUp] = useState(false);
  const [
    CancelModelShowingConfirmation,
    setModelShowingConfirmationCancel,
  ] = useState(false);
  const [rowItem, setRowItem] = useState(null);
  const [loadedModalShow, setloadedModalShow] = useState(false);
  const [NotesText, setNotesText] = useState("");
  const [DriverSheckInModal, setDriverSheckInModal] = useState(false);
  const [ShowRemarks, setShowRemarks] = useState(false);
  const [RollBackLoadingPost, setRollBackLoadingPost] = useState(false);
  const [ReceiptVendorRemoved, setReceiptVendorRemoved] = useState([]);
  const [GlobalStatusListOptions, setGlobalStatusListOptions] = useState([]);
  const [ReceiptVendorList, setReceiptVendorList] = useState([]);
  const [StatusListOptions, setStatusListOptions] = useState([]);
  const [initCarrier, setinitCarrier] = useState({
    ShipmentId: "",
    DriverName: "",
    PhoneNumber: "",
    CompanyName: "",
    PickupNumber: "",
    PickupAppDate: new Date(),
    SealDroppingTrailerNumber: "",
    SealDroppingNumber: "",
    SealPickingUpTrailerNumber: "",
    SealPickingUpNumber: "",
    Type: 1,
    IsChanged: false,
  });
  const [initCarrierOut, setinitCarrierOut] = useState({
    ShipmentId: "",
    DriverName: "",
    PhoneNumber: "",
    CompanyName: "",
    PickupNumber: "",
    PickupAppDate: new Date(),
    SealDroppingTrailerNumber: "",
    SealDroppingNumber: "",
    SealPickingUpTrailerNumber: "",
    SealPickingUpNumber: "",
    Type: 2,
    IsChanged: false,
  });
  const [isCustomerValue, setIsCustomer] = useState(false);
  const [ActivitiesCarriersCheck, setActivitiesCarriersCheck] = useState(null);

  useEffect(() => {}, [row.Id]);

  const [ModelShowingConfirmation, setModelShowingConfirmation] = useState(
    false
  );
  const Placeholder = (props: PlaceholderProps<ColourOption>) => {
    return <components.Placeholder {...props} />;
  };
  async function NextStatusCancel(text) {
    try {
      const noteValue = document.getElementById("valueNoteCancel").value;
      if (text === `"IsCancel":true`) {
        if (noteValue === "" || noteValue === undefined || noteValue === null) {
          Notification("error", "please fill cencled note");
          return;
        } else {
          await fetch(
            `${window.$apiurl}/transload/shipments/addnote/${rowItem?.Id}`,
            {
              method: "post",
              withCredentials: true,
              headers: {
                Accept: "application/json, text/plain, */*",
                Authorization: `bearer ${db.read().getState().Token}`,
                "X-FP-API-KEY": "iphone",
                "Content-Type": "application/json",
              },
              body: `{"body":"${noteValue}","CategoryId":12,"accessLevelId":5}`,
            }
          )
            .then((response) => {
              if (response.status !== 200) {
                Notification("error", "Note Note Add");
              }
            })
            .catch((error) => {
              console.error(error);
            });
          await fetch(`${window.$apiurl}/ShipmentActions/updateSingleStatus`, {
            method: "post",
            withCredentials: true,
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `bearer ${db.read().getState().Token}`,
              "X-FP-API-KEY": "iphone",
              "Content-Type": "application/json",
            },
            body: `{"Id":${rowItem?.Id},${text}}`,
          })
            .then(async (response) => {
              const statusCode = await response.status;
              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json().then((data) => [statusCode, data]);
              } else {
                return response
                  .text()
                  .then((text) => [statusCode, { Message: "" }]);
              }
            })
            .then((result) => {
              if (result[0] === 200) {
                if (
                  result[1].Message !== null &&
                  result[1].Message !== undefined &&
                  result[1].Message !== ""
                ) {
                  Notification("infoClick", result[1].Message);
                } else {
                  if (result[0] === 200) {
                    if (text.indexOf("IsNext") > -1)
                      Notification("success", "Next Status is successful");
                    else if (text.indexOf("IsRollBack") > -1)
                      Notification("success", "Roll Back Status is successful");
                    else if (text.indexOf("IsCancel") > -1)
                      Notification("success", "Cancel Order is successful");
                  }
                  document.getElementById("ReLoadTableId").click();
                }
              } else {
                if (
                  result[1].Message !== null &&
                  result[1].Message !== undefined &&
                  result[1].Message !== ""
                ) {
                  Notification("errorClick", result[1].Message);
                } else {
                  Notification("errorClick", "invalid request");
                }
              }
            })
            .catch((error) => {
              console.error(error);
            });
          document.getElementById("valueNoteCancel").value = "";
          setModelShowingConfirmationCancel(false);
        }
      } else {
        await fetch(`${window.$apiurl}/ShipmentActions/updateSingleStatus`, {
          method: "post",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
          body: `{"Id":${rowItem?.Id},${text}}`,
        })
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              if (text.indexOf("IsNext") > -1)
                Notification("success", "Next Status is successful");
              else if (text.indexOf("IsRollBack") > -1)
                Notification("success", "Roll Back Status is successful");
              else if (text.indexOf("IsCancel") > -1)
                Notification("success", "Cancel Order is successful");

              document.getElementById("ReLoadTableId").click();
            } else {
              if (result[1]?.Message !== undefined) {
                Notification("errorClick", result[1]?.Message);
              } else {
                Notification("errorClick", "Bad Request");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
        setModelShowingConfirmationCancel(false);
        return;
      }
    } catch (err) {}
  }
  useEffect(() => {
    if (DataWareHouse !== null) {
      var listonstatus = JSON.parse(
        JSON.stringify(DataWareHouse?.ShipmentsCount)
      );
      var list = DataWareHouse?.ShipmentsCount?.map((a) => String(a.StatusId));

      const index = list?.indexOf(String(filterOptions.statusId));
      if (index > -1) {
        listonstatus.splice(index, DataWareHouse?.ShipmentsCount?.length);
        setStatusListOptions(listonstatus);
      }
    }
  }, [row.Id]);
  axios.get("auth/Login").then((res) => {
    let userDefault;
    if (res.data !== undefined) {
      userDefault = {
        IsCustomer: res.data.IsCustomer,
      };
      setIsCustomer(userDefault.IsCustomer);
    }
  });
  useEffect(() => {
    window.ReceiptId = row?.ReceiptId;
    window.ReceiptManagmetShow = true;
  }, [row?.ReceiptId]);
  function Remarks() {
    return (
      <>
        <ContainersUIProvider
          showing={ShowRemarks}
          setshowing={setShowRemarks}
          id={row?.ReceiptId}
        >
          <RemarkEditDialog />
        </ContainersUIProvider>
      </>
    );
  }

  function SimplePopover() {
    //BOL dynamic report
    const [showBol, setBol] = useState(false);
    const [rowItem, setRow] = useState(null);

    const [LoadingPostStatus, setLoadingPostStatus] = useState(false);
    const [ShowModalAssigned, setShowModalAssigned] = useState(false);
    const [optionsVandorList, setoptionsVandorList] = useState([]);
    const [initAssignedStatusVendor, setinitAssignedStatusVendor] = useState({
      ShipmentIds: [row.Id],
      VendorId: row?.CarrierId,
      VendorTypeId: 1308,
      ShipmentStatus: "",
      ThirdPartyBillToId: "",
      FreightChargeId: 98,
    });
    const [FreightChargeList, setFreightChargeList] = useState([]);
    const [optionsVandorTypeList, setoptionsVandorTypeList] = useState([]);
    const [BillToList, setBillToList] = useState([]);

    const [StatusSelectedRolledBack, setStatusSelectedRolledBack] = useState(
      null
    );

    async function GetListOfPreviewsStatus(params) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${window.$apiurl}/Shipments/GetShipmentVendors/${params}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          var data = JSON.parse(result);
          setReceiptVendorList(data);
        })
        .catch((error) => console.log("error", error));
      var statusList = JSON.parse(
        JSON.stringify(DataWareHouse?.ShipmentStatus)
      );
      setGlobalStatusListOptions(DataWareHouse?.ShipmentStatus || []);

      var list = statusList.map((a) => String(a.StatusId));
      const index = list.indexOf(String(DataWareHouse?.ShipmentStatus));
      if (index > -1) {
        statusList.splice(index, statusList.length);
        setStatusListOptions(statusList);
      }
    }

    function PostAssignedVandors() {
      try {
        var VandorSelected = initAssignedStatusVendor.VendorId;
        if (
          VandorSelected === null ||
          VandorSelected === undefined ||
          VandorSelected === ""
        ) {
          Notification("errorClick", "Please select vendor");
          return;
        }
        setLoadingPostStatus(true);
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var raw = JSON.stringify(initAssignedStatusVendor);
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        fetch(
          window.$apiurl + `/warehouseManagement/UpdateMultipleStatus`,
          requestOptions
        )
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("infoClick", result[1].Message);
                setShowModalAssigned(false);
              } else {
                Notification(
                  "success",
                  `Status of shipments : [${initAssignedStatusVendor.ShipmentIds.map(
                    (customer) => customer + ", "
                  )}] has been changed successfully`
                );
              }
              GetWarehousDataPageChanges(paginationOptions);
              setShowModalAssigned(false);
            } else {
              if (
                result[1].ModelState !== null &&
                result[1].ModelState !== undefined &&
                result[1].ModelState !== ""
              ) {
                let modelState = result[1].ModelState;
                if (modelState)
                  Object.keys(modelState).forEach(function(k) {
                    modelState[k].forEach((element) => {
                      Notification("errorClick", element);
                    });
                  });
              } else if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("errorClick", result[1].Message);
              } else {
                Notification("errorClick", "Bad Request");
              }
            }
            setLoadingPostStatus(false);
          })
          .catch((error) => {
            console.log("error", error);
            setLoadingPostStatus(false);
          });
      } catch (e) {}
    }
    function NextStatus(id, statusId) {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ShipmentIds: [id],
        ShipmentStatus: statusId,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("infoClick", result[1].Message);
              if (statusId !== 102) {
                GetWarehousDataPageChanges(paginationOptions);
              }
            } else {
              Notification("success", "Shipment has been Updated successfully");
              if (statusId !== 102) {
                GetWarehousDataPageChanges(paginationOptions);
              }
            }
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

    function SubmitLoadingDetail() {
      var Traillerdrop = document.getElementById(`Traillerdrop`).value;
      if (
        Traillerdrop === "" ||
        Traillerdrop === undefined ||
        Traillerdrop === null
      ) {
        Notification("error", "please fill tailler");
        return;
      }
      setLoadingPostStatus(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ShipmentIds: [row.Id],
        ShipmentStatus: 5,
        EquipmentNumber: Traillerdrop,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("infoClick", result[1].Message);
              GetWarehousDataPageChanges(paginationOptions);
              setloadingModalShow(false);
            } else {
              Notification("success", "Shipment has been Updated successfully");
              GetWarehousDataPageChanges(paginationOptions);
              setloadingModalShow(false);
            }
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
            setloadingModalShow(false);
          }
          setLoadingPostStatus(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingPostStatus(false);
        });
    }

    function SubmitLoadedDetail(parameters) {
      var SealNumber = document.getElementById(`SealNumberLoadedDetail`).value;
      if (
        SealNumber === "" ||
        SealNumber === undefined ||
        SealNumber === null
      ) {
        Notification("error", "please fill Seal #");
        return;
      }
      setLoadingPostStatus(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        ShipmentIds: [row.Id],
        ShipmentStatus: 6,
        SealNumber: SealNumber,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${window.$apiurl}/warehouseManagement/UpdateMultipleStatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("infoClick", result[1].Message);
              GetWarehousDataPageChanges(paginationOptions);
              setloadedModalShow(false);
            } else {
              Notification("success", "Shipment has been Updated successfully");
              GetWarehousDataPageChanges(paginationOptions);
              setloadedModalShow(false);
            }
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
            setloadedModalShow(false);
          }
          setLoadingPostStatus(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingPostStatus(false);
        });
    }
    function SubmitCarrierCheckIn() {
      if (checkindriver.IsChanged) {
        if (
          checkindriver.PickupNumber === "" ||
          checkindriver.DriverName === "" ||
          checkindriver.CompanyName === ""
        ) {
          Notification(
            "errorClick",
            "Please fill Company Name, Driver Name and Pickup Number"
          );
          return;
        }
      }
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      setLoadingPostStatus(true);

      var objPosted = {
        ShipmentId: checkindriver?.ShipmentId,
        Checks: [checkindriver, checkindriverOut],
      };

      var raw = JSON.stringify(objPosted);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/driverCheckedIn`, requestOptions)
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("infoClick", result[1].Message);
              setDriverSheckInModal(false);
            } else {
              Notification("success", "Shipment has been Updated successfully");
              setDriverSheckInModal(false);
            }
            GetWarehousDataPageChanges(paginationOptions);
          } else {
            if (
              result[1].ModelState !== null &&
              result[1].ModelState !== undefined &&
              result[1].ModelState !== ""
            ) {
              let modelState = result[1].ModelState;
              if (modelState)
                Object.keys(modelState).forEach(function(k) {
                  modelState[k].forEach((element) => {
                    Notification("errorClick", element);
                  });
                });
            } else if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
            } else {
              Notification("errorClick", "Bad Request");
            }
            setDriverSheckInModal(false);
          }
          setLoadingPostStatus(false);
        })
        .catch((error) => {
          console.log("error", error);
          setLoadingPostStatus(false);
        });
    }
    function getvandorsList() {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(window.$apiurl + "/vendors", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const data = JSON.parse(result);
          const opt = [];
          data.Vendors.forEach((Vendor) => {
            opt.push({
              value: Vendor.Id,
              label: Vendor.Code + "-" + Vendor.CompanyName,
              color: Vendor?.IsApproved ? "black" : "red",
              CarrierId: Vendor?.CarrierId,
              IsApproved: Vendor?.IsApproved,
            });
          });
          setoptionsVandorList(opt || []);
          setFreightChargeList(data?.FreightCharges ?? []);
          setoptionsVandorTypeList(data?.VendorTypes || []);
          setBillToList(data?.BillTo || []);
        })
        .catch((error) => console.log("error", error));
    }
    return (
      <div className="d-flex">
        {/* start dispatched Modal */}

        <Modal
          size="xl"
          id="DispatchedDateModal"
          show={ShowModalAssigned}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Assigned Details
            </Modal.Title>
            <IconButton
              aria-label="Close"
              onClick={() => {
                setShowModalAssigned(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className=" overlay-block">
            <div className="form-group row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-3">
                    <p className="mb-3">Vendors</p>
                    <Select
                      name="vandorId"
                      components={{ Option }}
                      options={optionsVandorList.filter((a) => {
                        var obj = { ...a };
                        if (initAssignedStatusVendor.FreightChargeId === 98) {
                          if (a.IsApproved === false) {
                            return {
                              value: obj?.value,
                              label: obj?.label,
                              color: "red",
                              CarrierId: obj?.CarrierId,
                              IsApproved: obj?.IsApproved,
                            };
                          } else {
                            return {
                              value: obj?.value,
                              label: obj?.label,
                              color: "black",
                              CarrierId: obj?.CarrierId,
                              IsApproved: obj?.IsApproved,
                            };
                          }
                        } else {
                          return {
                            value: obj?.value,
                            label: obj?.label,
                            color: "black",
                            CarrierId: obj?.CarrierId,
                            IsApproved: obj?.IsApproved,
                          };
                        }
                      })}
                      value={optionsVandorList.filter(
                        (a) => a.value === initAssignedStatusVendor.VendorId
                      )}
                      onChange={(event) => {
                        var dataobj = { ...initAssignedStatusVendor };
                        dataobj.VendorId = event?.value;
                        setinitAssignedStatusVendor(dataobj);
                      }}
                    ></Select>
                    {initAssignedStatusVendor.FreightChargeId === 98 &&
                      (optionsVandorList?.filter(
                        (a) => a.value === initAssignedStatusVendor.VendorId
                      )[0]?.IsApproved === false ? (
                        <p style={{ color: "red" }}>
                          This Vendor is not Certified
                        </p>
                      ) : (
                        ""
                      ))}
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-3">Vendor Type</p>
                    <Select
                      name="VendorTypeId"
                      value={optionsVandorTypeList
                        .filter(
                          (a) => a.Id === initAssignedStatusVendor.VendorTypeId
                        )
                        .map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                      options={optionsVandorTypeList.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(event) => {
                        var dataobj = { ...initAssignedStatusVendor };
                        dataobj.VendorTypeId = event?.value;
                        setinitAssignedStatusVendor(dataobj);
                      }}
                    ></Select>
                  </div>
                  <div className="col-lg-3">
                    <p className="mb-3">Freight Charge</p>
                    <Select
                      name="FreightChargeId"
                      value={FreightChargeList.filter(
                        (a) => a.Id === initAssignedStatusVendor.FreightChargeId
                      ).map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      options={FreightChargeList.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(event) => {
                        var dataobj = { ...initAssignedStatusVendor };
                        dataobj.FreightChargeId = event?.value;
                        setinitAssignedStatusVendor(dataobj);
                        //setVandorSelectedData(null);
                      }}
                    ></Select>
                  </div>
                  {initAssignedStatusVendor.FreightChargeId === 100 ? (
                    <div className="col-lg-3">
                      <p className="mb-3">BillTo</p>
                      <Select
                        name="BillTo"
                        options={BillToList.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name + "-" + x?.Code,
                          };
                        })}
                        onChange={(event) => {
                          var dataobj = { ...initAssignedStatusVendor };
                          dataobj.ThirdPartyBillToId = event?.value;
                          setinitAssignedStatusVendor(dataobj);
                        }}
                      ></Select>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="col-lg-12 mt-5">
                    {initAssignedStatusVendor.CarrierId !== null &&
                    initAssignedStatusVendor.CarrierId !== "" &&
                    initAssignedStatusVendor.CarrierId !== undefined ? (
                      <ChargesFunctionCompo
                        VandorSelectedData={initAssignedStatusVendor.CarrierId}
                        customers={[row]}
                        optionsVandorList={optionsVandorList}
                        isInbound={false}
                        linq={"transloadshipments"}
                      />
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6"></div>
              <div className="col-lg-5 ml-5"></div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="mr-3"
              onClick={() => {
                setShowModalAssigned(false);
              }}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={LoadingPostStatus}
              onClick={async () => {
                PostAssignedVandors();
              }}
              className="btn btn-primary btn-elevate"
            >
              Save changes
              {LoadingPostStatus && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>
        {/* end dispatched modal */}
        {/* backstatus */}
        <Modal
          size="xl"
          show={ModelShowingConfirmation}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title>Roll Back Confirmation</Modal.Title>
            <IconButton
              aria-label="Close"
              onClick={(e) => {
                setStatusSelectedRolledBack(null);
                setReceiptVendorRemoved([]);
                setModelShowingConfirmation(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <label>Select Status:</label>
                <Select
                  isSearchable={true}
                  isClearable
                  id="rolbackstatusSelected"
                  components={{ Placeholder }}
                  placeholder={"Auto Roll Back"}
                  options={StatusListOptions.map((a) => {
                    return { value: a.StatusId, label: a.StatusName };
                  })}
                  onChange={(e) => {
                    statusIdRollBack = e?.value;
                    if (e !== null) {
                      setStatusSelectedRolledBack(e);
                    } else {
                      setStatusSelectedRolledBack(null);
                    }
                  }}
                />

                {GlobalStatusListOptions.filter((a) => a.Name === "Assigned")[0]
                  ?.Position >
                GlobalStatusListOptions.filter(
                  (a) => a.Name === StatusSelectedRolledBack?.label
                )[0]?.Position ? (
                  <>
                    <label className="mt-5">Select Vendors to remove</label>
                    {ReceiptVendorList.map((item, index) => (
                      <CheckboxLargeWB
                        className="font-size-md"
                        name={`Trucker${index}`}
                        text={item.VendorAddress.CompanyName}
                        onChange={(e) => {
                          var list = [];
                          list = ReceiptVendorRemoved;
                          if (e.target.checked) {
                            list.push(item.VendorId);
                            setReceiptVendorRemoved(list || []);
                          } else {
                            var index = list.indexOf(item.VendorId);
                            setReceiptVendorRemoved(
                              list.slice(index, index) || []
                            );
                          }
                        }}
                      />
                    ))}
                  </>
                ) : (
                  ""
                )}
                <br></br>
                <br></br>
                <label>Roll Back Note:</label>
                <textarea
                  className="form-control mb-5"
                  rows="4"
                  id="BackStatusNotes"
                  onBlur={(e) => (statusNoteRollBack = e.target.value)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-light"
              disabled={RollBackLoadingPost}
              onClick={(e) => {
                setModelShowingConfirmation(false);
              }}
            >
              Cancel
            </button>
            <button
              className="ml-3 btn btn-primary"
              onClick={(e) => {
                if (
                  statusNoteRollBack === null ||
                  statusNoteRollBack === "" ||
                  statusNoteRollBack === undefined
                ) {
                  Notification("error", "please fill note");
                  return;
                }
                //setRollBackLoadingPost(true);
                row = {
                  Id: row.Id,
                  StatusId:
                    StatusSelectedRolledBack === null
                      ? null
                      : StatusSelectedRolledBack.value,
                  VendorsIds: ReceiptVendorRemoved,
                  KeepChanges: false,
                  Note: statusNoteRollBack,
                };
                fetch(`${window.$apiurl}/ShipmentActions/RollBack`, {
                  method: "post",
                  withCredentials: true,
                  headers: {
                    Accept: "application/json, text/plain, */*",
                    Authorization: `bearer ${db.read().getState().Token}`,
                    "X-FP-API-KEY": "iphone",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(row),
                })
                  .then(async (response) => {
                    const statusCode = await response.status;
                    const contentType = response.headers.get("content-type");
                    if (
                      contentType &&
                      contentType.indexOf("application/json") !== -1
                    ) {
                      return response.json().then((data) => [statusCode, data]);
                    } else {
                      return response
                        .text()
                        .then((text) => [statusCode, { Message: "" }]);
                    }
                  })
                  .then((result) => {
                    setRollBackLoadingPost(false);
                    if (result[0] === 200) {
                      if (
                        result[1].Message !== null &&
                        result[1].Message !== undefined &&
                        result[1].Message !== ""
                      ) {
                        Notification("infoClick", result[1].Message);
                        setModelShowingConfirmation(false);
                      } else {
                        setModelShowingConfirmation(false);
                        Notification(
                          "success",
                          "Roll Back Status is successful"
                        );
                        GetWarehousDataPageChanges(paginationOptions);
                      }
                    } else {
                      if (
                        result[1].Message !== null &&
                        result[1].Message !== undefined &&
                        result[1].Message !== ""
                      ) {
                        Notification("errorClick", result[1].Message);
                        setModelShowingConfirmation(false);
                      } else {
                        Notification("errorClick", "invalid request");
                      }
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
            >
              Confirm
              {RollBackLoadingPost && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>

        {/* loading Modal */}
        <Modal
          size="md"
          id="AppointedDateModal"
          show={loadingModalShow}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Loading Details
            </Modal.Title>
            <IconButton
              className="MuiButtonBase-root MuiIconButton-root jss179"
              aria-label="Close"
              onClick={() => {
                setloadingModalShow(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className=" overlay-block">
            <div className="form-group row">
              <div className="col-lg-12">
                <label>Trailler #</label>
                <input
                  id="Traillerdrop"
                  name="Traillerdrop"
                  type="text"
                  className="form-control"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setloadingModalShow(false);
              }}
              className="btn btn-light btn-elevate mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={LoadingPostStatus}
              onClick={() => {
                SubmitLoadingDetail();
              }}
              className="btn btn-primary btn-elevate"
            >
              Save changes
              {LoadingPostStatus && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>
        {/* loaded Modal */}
        <Modal
          size="md"
          id="AppointedDateModal"
          show={loadedModalShow}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Loaded Details
            </Modal.Title>
            <IconButton
              className="MuiButtonBase-root MuiIconButton-root jss179"
              aria-label="Close"
              onClick={() => {
                setloadedModalShow(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className=" overlay-block">
            <div className="form-group row">
              <div className="col-lg-12">
                <label>
                  <b>Seal #</b>
                </label>
                <input
                  id="SealNumberLoadedDetail"
                  name="SealNumberLoadedDetail"
                  className="form-control"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setloadedModalShow(false);
              }}
              className="btn btn-light btn-elevate mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={LoadingPostStatus}
              onClick={() => {
                SubmitLoadedDetail();
              }}
              className="btn btn-primary btn-elevate"
            >
              Save changes
              {LoadingPostStatus && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={CancelModelShowingConfirmation}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title>
              Are you sure that you want to{" "}
              {row.IsCancelled === true ? "UnCancel" : "Cancel"} this Shipment ?
            </Modal.Title>
            <IconButton
              aria-label="Close"
              onClick={(e) => {
                setModelShowingConfirmationCancel(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body>
            <label>
              {row.IsCancelled === true ? "UnCancel" : "Cancel"} Confirmation
              Note
            </label>
            <textarea
              className="form-control mb-5"
              rows="4"
              id="valueNoteCancel"
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="btn btn-danger btn-elevate"
              onClick={(e) => {
                setModelShowingConfirmationCancel(false);
              }}
            >
              No
            </Button>

            <Button
              variant="secondary"
              className="btn btn-light btn-elevate ml-5"
              onClick={async (e) => {
                await NextStatusCancel(`"IsCancel":true`);
              }}
            >
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Picked Up Modal */}
        <Modal
          size="xl"
          id="AppointedDateModal"
          show={showModalPickedUp}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Picked Up Details
            </Modal.Title>
            <div className="ml-auto">
              <button
                className="btn btn-light-primary mr-5"
                onClick={() => {
                  setRow(row?.Id);
                  setBol(true);
                }}
              >
                Show BOL
              </button>
            </div>
            <IconButton
              aria-label="Close"
              onClick={() => {
                setshowModalPickedUp(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className=" overlay-block">
            <Row className="mb-4">
              <Col></Col>
            </Row>
            <div className="form-group row">
              <div className="col-lg-4">
                <label>Driver Name</label>
                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="DriverNameOut"
                  name="DriverNameOut"
                  type="text"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="form-control"
                  defaultValue={initCarrierOut?.DriverName}
                  onChange={(e) => {
                    checkindriverOut.DriverName = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Phone #</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="PhoneNumberOut"
                  name="PhoneNumberOut"
                  className="form-control"
                  type="text"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.PhoneNumber}
                  onChange={(e) => {
                    checkindriverOut.PhoneNumber = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4">
                <label>Company Name</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="CompanyNameOut"
                  name="CompanyNameOut"
                  className="form-control"
                  type="text"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.CompanyName}
                  onChange={(e) => {
                    checkindriverOut.CompanyName = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label>Pickup #</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="PickupNumberOut"
                  name="PickupNumberOut"
                  className="form-control"
                  type="text"
                  style={{ width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.PickupNumber}
                  onChange={(e) => {
                    checkindriverOut.PickupNumber = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5 D-margin">
                <label>Pickup Date Time</label>

                <DateTime30GL
                  defaultvalue={initCarrierOut?.PickupAppDate}
                  onChange={(newValue) => {
                    checkindriverOut.PickupAppDate = newValue;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label>Trailer # Dropping</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="SealDroppingTrailerNumberOut"
                  name="SealDroppingTrailerNumberOut"
                  style={{ width: "100%" }}
                  className="form-control"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.SealDroppingTrailerNumber}
                  onChange={(e) => {
                    checkindriverOut.SealDroppingTrailerNumber = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label>Seal # Dropping</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="SealDroppingNumberOut"
                  name="SealDroppingNumberOut"
                  style={{ width: "100%" }}
                  className="form-control"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.SealDroppingNumber}
                  onChange={(e) => {
                    checkindriverOut.SealDroppingNumber = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label>Trailer # Picking up</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="SealPickingUpTrailerNumberOut"
                  style={{ width: "100%" }}
                  name="SealPickingUpTrailerNumberOut"
                  className="form-control"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.SealPickingUpTrailerNumber}
                  onChange={(e) => {
                    checkindriverOut.SealPickingUpTrailerNumber =
                      e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
              <div className="col-lg-4 mt-5">
                <label>Seal # picking up</label>

                <input
                  // style={{ marginTop: "10px", width: "243px" }}
                  id="SealPickingUpNumberOut"
                  name="SealPickingUpNumberOut"
                  style={{ width: "100%" }}
                  className="form-control"
                  type="text"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  defaultValue={initCarrierOut?.SealPickingUpNumber}
                  onChange={(e) => {
                    checkindriverOut.SealPickingUpNumber = e.target.value;
                    checkindriverOut.IsChanged = true;
                  }}
                />
              </div>
            </div>
            <Row>
              <Col>
                <TmsAttachementsStatus
                  id={row.Id}
                  Type="shipment"
                  setshowModalPickedUp={setshowModalPickedUp}
                  paginationOptions={paginationOptions}
                  GetWarehousDataPageChanges={GetWarehousDataPageChanges}
                />
              </Col>
            </Row>
            <button
              className="d-none"
              id="SaveCarrierCheckOutPickUpDetails"
              onClick={() => {
                SubmitCarrierCheckIn();
              }}
            >
              SaveInput
            </button>
          </Modal.Body>
        </Modal>
        {/* Notify Trucker Modal */}
        {showModalSend && (
          <SendEmail
            row={row}
            showModalSend={showModalSend}
            setshowModalSend={setshowModalSend}
            NextStatus={NextStatus}
            GetWarehousDataPageChanges={GetWarehousDataPageChanges}
            paginationOptions={paginationOptions}
          />
        )}
        {/* Modal BOL */}
        <DynamicReport
          setShowModal={setBol}
          showModal={showBol}
          id={rowItem}
          title={`BOL Shipment ID#: ${rowItem}`}
          reportContent={<BLFORM id={rowItem} />}
        />
        {/* Driver Check In */}
        <Modal
          size="xl"
          id="AppointedDateModal"
          show={DriverSheckInModal}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <Modal.Title id="example-modal-sizes-title-lg">
              Drivers Details
            </Modal.Title>
            <IconButton
              className="MuiButtonBase-root MuiIconButton-root jss179"
              aria-label="Close"
              onClick={() => {
                setDriverSheckInModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>
          <Modal.Body className=" overlay-block">
            <Accordion defaultActiveKey="0">
              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="0"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">Driver Checked In</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label>Driver Name</label>
                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="DriverName"
                      name="DriverName"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="form-control"
                      defaultValue={initCarrier?.DriverName}
                      onChange={(e) => {
                        checkindriver.DriverName = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Phone #</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="PhoneNumber"
                      name="PhoneNumber"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.PhoneNumber}
                      onChange={(e) => {
                        checkindriver.PhoneNumber = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Company Name</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="CompanyName"
                      name="CompanyName"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.CompanyName}
                      onChange={(e) => {
                        checkindriver.CompanyName = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Pickup #</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="PickupNumber"
                      name="PickupNumber"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.PickupNumber}
                      onChange={(e) => {
                        checkindriver.PickupNumber = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5 D-margin">
                    <label>Pickup Date Time</label>

                    <DateTime30GL
                      defaultvalue={initCarrier?.PickupAppDate}
                      onChange={(newValue) => {
                        checkindriver.PickupAppDate = newValue;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Trailer # Dropping</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealDroppingTrailerNumber"
                      name="SealDroppingTrailerNumber"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.SealDroppingTrailerNumber}
                      onChange={(e) => {
                        checkindriver.SealDroppingTrailerNumber =
                          e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Seal # Dropping</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealDroppingNumber"
                      name="SealDroppingNumber"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.SealDroppingNumber}
                      onChange={(e) => {
                        checkindriver.SealDroppingNumber = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Trailer # Picking up</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealPickingUpTrailerNumber"
                      style={{ width: "100%" }}
                      name="SealPickingUpTrailerNumber"
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.SealPickingUpTrailerNumber}
                      onChange={(e) => {
                        checkindriver.SealPickingUpTrailerNumber =
                          e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Seal # picking up</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealPickingUpNumber"
                      name="SealPickingUpNumber"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrier?.SealPickingUpNumber}
                      onChange={(e) => {
                        checkindriver.SealPickingUpNumber = e.target.value;
                        checkindriver.IsChanged = true;
                      }}
                    />
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="1"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">Driver Checked out</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label>Driver Name</label>
                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="DriverNameOut"
                      name="DriverNameOut"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      className="form-control"
                      defaultValue={initCarrierOut?.DriverName}
                      onChange={(e) => {
                        checkindriverOut.DriverName = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Phone #</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="PhoneNumberOut"
                      name="PhoneNumberOut"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.PhoneNumber}
                      onChange={(e) => {
                        checkindriverOut.PhoneNumber = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Company Name</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="CompanyNameOut"
                      name="CompanyNameOut"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.CompanyName}
                      onChange={(e) => {
                        checkindriverOut.CompanyName = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Pickup #</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="PickupNumberOut"
                      name="PickupNumberOut"
                      className="form-control"
                      type="text"
                      style={{ width: "100%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.PickupNumber}
                      onChange={(e) => {
                        checkindriverOut.PickupNumber = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5 D-margin">
                    <label>Pickup Date Time</label>

                    <DateTime30GL
                      defaultvalue={initCarrierOut?.PickupAppDate}
                      onChange={(newValue) => {
                        checkindriverOut.PickupAppDate = newValue;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Trailer # Dropping</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealDroppingTrailerNumberOut"
                      name="SealDroppingTrailerNumberOut"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.SealDroppingTrailerNumber}
                      onChange={(e) => {
                        checkindriverOut.SealDroppingTrailerNumber =
                          e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Seal # Dropping</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealDroppingNumberOut"
                      name="SealDroppingNumberOut"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.SealDroppingNumber}
                      onChange={(e) => {
                        checkindriverOut.SealDroppingNumber = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Trailer # Picking up</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealPickingUpTrailerNumberOut"
                      style={{ width: "100%" }}
                      name="SealPickingUpTrailerNumberOut"
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.SealPickingUpTrailerNumber}
                      onChange={(e) => {
                        checkindriverOut.SealPickingUpTrailerNumber =
                          e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                  <div className="col-lg-4 mt-5">
                    <label>Seal # picking up</label>

                    <input
                      // style={{ marginTop: "10px", width: "243px" }}
                      id="SealPickingUpNumberOut"
                      name="SealPickingUpNumberOut"
                      style={{ width: "100%" }}
                      className="form-control"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      defaultValue={initCarrierOut?.SealPickingUpNumber}
                      onChange={(e) => {
                        checkindriverOut.SealPickingUpNumber = e.target.value;
                        checkindriverOut.IsChanged = true;
                      }}
                    />
                  </div>
                </div>
              </Accordion.Collapse>

              <Accordion.Toggle
                as={Button}
                className="w-100 text-left p-0 position-relative text-decoration-none"
                variant="link"
                eventKey="2"
              >
                <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                  <h5 className="font-weight-bold">Activities</h5>
                </Alert>
                <Icon
                  className={clsx(
                    "fa fa-chevron-down position-absolute text-white"
                  )}
                  style={{ fontSize: 17, right: "1rem", top: "20%" }}
                />
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                {ActivitiesCarriersCheck !== null ? (
                  <ActivityFunction Activitys={ActivitiesCarriersCheck || []} />
                ) : (
                  <div className={`h-60 card card-custom`}>
                    <div className="card-body pt-5 row now-rapTable">
                      <div
                        className="col-xl-12"
                        style={{ textAlign: "center" }}
                      >
                        Loading Data ...
                      </div>
                    </div>
                  </div>
                )}
              </Accordion.Collapse>
            </Accordion>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => {
                setDriverSheckInModal(false);
              }}
              className="btn btn-light btn-elevate mr-3"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={LoadingPostStatus}
              onClick={() => {
                SubmitCarrierCheckIn();
              }}
              className="btn btn-primary btn-elevate"
            >
              Save changes
              {LoadingPostStatus && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="md"
          show={Show210issue}
          onHide={() => {
            setShow210issue(false);
          }}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header>
            <div className="row">
              210 Issues: <strong> {row.Id}</strong>
            </div>
            <IconButton
              aria-label="Close"
              onClick={() => {
                setShow210issue(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              {Issue210.map((a) => {
                return (
                  <>
                    <div className="col-md-12 row mb-5">
                      <span className="svg-icon menu-icon pr-3 text-danger">
                        <i className="fas fa-exclamation-triangle text-danger" />
                      </span>
                      <h2 className="MuiTypography-root font-weight-bolder text-wrap MuiTypography-body2">
                        {a}
                      </h2>
                    </div>
                  </>
                );
              })}
            </div>
          </Modal.Body>
        </Modal>
        <Dropdown className="dropdown-inline position-unset pr-4" alignRight>
          <Dropdown.Toggle
            className="btn btn-icon btn-light-warning btn-hover-warning btn-sm"
            variant="transparent"
            id="dropdown-toggle-top"
            as={DropdownCustomTogglerYellow}
          >
            <i className="fas fa-cogs text-warning" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right topFixedMenu">
            <ul className="navi navi-hover py-5">
              <li className="navi-item">
                <a
                  href="javascript(0)"
                  className="navi-link"
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(true);
                  }}
                >
                  <span className="navi-icon">
                    <i className="fas fa-file-alt text-primary"></i>
                  </span>
                  <span className="navi-text">Detail</span>
                </a>
              </li>
              <li className="navi-item">
                <a
                  href="javascript(0)"
                  className="navi-link"
                  onClick={(e) => {
                    window.shipmentEditCommingFrom = "ShipmentsManagement";
                    e.preventDefault();
                    setOneMoveMasterShow(true);
                  }}
                >
                  <span className="navi-icon">
                    <i className="fas fa-pen text-success"></i>
                  </span>
                  <span className="navi-text">Edit</span>
                </a>
              </li>
              {filterOptions.exceptionsId === "210-issues" && (
                <li className="navi-item">
                  <a
                    href="javascript(0)"
                    className="navi-link"
                    onClick={async (e) => {
                      e.preventDefault();
                      await fetch(
                        window.$apiurl +
                          `/Shipments/GetShipment210Issues/${row.Id}`,
                        {
                          method: "get",
                          withCredentials: true,
                          headers: {
                            Accept: "application/json, text/plain, */*",
                            Authorization: `bearer ${
                              db.read().getState().Token
                            }`,
                            "X-FP-API-KEY": "iphone",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then(async (response) => {
                          const statusCode = await response.status;
                          const data = await response.json();
                          return Promise.all([statusCode, data]);
                        })
                        .then(async (res) => {
                          const data = res[1];
                          setIssue210(data?.Errors || []);
                          setShow210issue(true);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-bug text-danger"></i>
                    </span>
                    <span className="navi-text">210 Issues</span>
                  </a>
                </li>
              )}
              {filterOptions.statusId !== "" &&
              filterOptions.statusId !== 2 &&
              filterOptions.statusId !== 3 ? (
                <li className="navi-item">
                  <a
                    href={() => false}
                    className="navi-link"
                    onClick={async (e) => {
                      checkindriver = {
                        ShipmentId: row.Id,
                        DriverName: "",
                        PhoneNumber: "",
                        CompanyName: "",
                        PickupNumber: row.Id,
                        PickupAppDate: new Date(),
                        SealDroppingTrailerNumber: "",
                        SealDroppingNumber: "",
                        SealPickingUpTrailerNumber: "",
                        SealPickingUpNumber: "",
                        Type: 1,
                        IsChanged: false,
                      };
                      checkindriverOut = {
                        ShipmentId: row.Id,
                        DriverName: "",
                        PhoneNumber: "",
                        CompanyName: "",
                        PickupNumber: row.Id,
                        PickupAppDate: new Date(),
                        SealDroppingTrailerNumber: "",
                        SealDroppingNumber: "",
                        SealPickingUpTrailerNumber: "",
                        SealPickingUpNumber: "",
                        Type: 2,
                        IsChanged: false,
                      };
                      const resObject = await GetCarrierCheckedInData(row.Id);
                      console.log("resObject", resObject);
                      try {
                        if (resObject !== null && resObject !== undefined) {
                          console.log("resObject2", resObject);

                          const dataBased = await GetBaseData(row?.Id);
                          if (resObject?.CheckIn !== null) {
                            setinitCarrier(resObject?.CheckIn);
                            checkindriver = resObject?.CheckIn;
                          } else {
                            var newObject = {
                              ShipmentId: dataBased.Id,
                              DriverName: "",
                              PhoneNumber: "",
                              CompanyName: dataBased?.Vendor,
                              PickupNumber: dataBased.Id,
                              PickupAppDate: new Date(),
                              SealDroppingTrailerNumber: "",
                              SealDroppingNumber: "",
                              SealPickingUpTrailerNumber: dataBased.Equipment,
                              SealPickingUpNumber: dataBased.SealNumber,
                              Type: 1,
                              IsChanged: false,
                            };
                            setinitCarrier(newObject);
                            checkindriver = newObject;
                          }
                          if (resObject?.CheckOut !== null) {
                            setinitCarrierOut(resObject?.CheckOut);
                            checkindriverOut = resObject?.CheckOut;
                          } else {
                            var newObjectOut = {
                              ShipmentId: dataBased.Id,
                              DriverName: "",
                              PhoneNumber: "",
                              CompanyName: dataBased?.Vendor,
                              PickupNumber: dataBased.Id,
                              PickupAppDate: new Date(),
                              SealDroppingTrailerNumber: "",
                              SealDroppingNumber: "",
                              SealPickingUpTrailerNumber: dataBased.Equipment,
                              SealPickingUpNumber: dataBased.SealNumber,
                              Type: 2,
                              IsChanged: false,
                            };
                            setinitCarrierOut(newObjectOut);
                            checkindriverOut = newObjectOut;
                          }
                          setActivitiesCarriersCheck(
                            resObject?.Activities || []
                          );
                          setDriverSheckInModal(true);
                        }
                      } catch (err) {
                        console.log(err);
                      }
                    }}
                  >
                    <span className="navi-icon">
                      <i className="fas fa-truck text-info-warehouse"></i>
                    </span>
                    <span className="navi-text">Driver Check</span>
                  </a>
                </li>
              ) : (
                ""
              )}

              {filterOptions.statusId !== "" ? (
                <>
                  {nextOrder(filterOptions.statusId) !== null &&
                    PrevOrder(filterOptions.statusId) !== null && (
                      <div className="dropdown-divider"></div>
                    )}
                  {nextOrder(filterOptions.statusId) !== null && (
                    <li className="navi-item">
                      <a
                        href={() => false}
                        className="navi-link"
                        onClick={async (e) => {
                          let index = nextOrder(filterOptions.statusId);
                          if (index !== null) {
                            if (index.StatusId === 5) {
                              setloadingModalShow(true);
                              const dataBased = await GetBaseData(row?.Id);
                              setTimeout(() => {
                                document.getElementById("Traillerdrop").value =
                                  dataBased?.Equipment;
                              }, 50);
                              return;
                            }
                            if (index.StatusId === 6) {
                              //SealNumberLoadedDetail
                              setloadedModalShow(true);
                              const dataBased = await GetBaseData(row?.Id);
                              setTimeout(() => {
                                document.getElementById(
                                  "SealNumberLoadedDetail"
                                ).value = dataBased?.SealNumber;
                              }, 50);
                              return;
                            }
                            if (index.StatusId === 102) {
                              await fetch(
                                `${window.$apiurl}/warehouseManagement/GetShipmentCube/` +
                                  row?.Id,
                                {
                                  method: "get",
                                  withCredentials: true,
                                  headers: {
                                    Accept: "application/json, text/plain, */*",
                                    Authorization: `bearer ${
                                      db.read().getState().Token
                                    }`,
                                    "X-FP-API-KEY": "iphone",
                                    "Content-Type": "application/json",
                                  },
                                }
                              )
                                .then(async (response) => {
                                  const statusCode = await response.status;
                                  const data = await response.json();
                                  return Promise.all([statusCode, data]);
                                })
                                .then((res) => {
                                  const a = res[1];
                                  console.log("a.IsLowCube", a.IsLowCube);
                                  console.log("a.IsManager", a.IsManager);
                                  if (a.IsLowCube && a.IsManager) {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: `This shipment (${row.Id}) has a low total cube, are you sure that you want to mark it as notify trucker ?`,
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setshowModalSend(true);
                                        return;
                                      } else {
                                        return;
                                      }
                                    });
                                  } else if (!a.IsLowCube && !a.IsManager) {
                                    setshowModalSend(true);
                                    return;
                                  } else if (a.IsLowCube && !a.IsManager) {
                                    Notification(
                                      "errorClick",
                                      `Update failed, please check the total cube for this shipments (${row.Id}).`
                                    );
                                    return;
                                  } else if (!a.IsLowCube && a.IsManager) {
                                    setshowModalSend(true);
                                    return;
                                  }
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                              //setshowModalSend(true);
                              return;
                            }
                            if (index.StatusId === 7) {
                              checkindriver = {
                                ShipmentId: row.Id,
                                DriverName: "",
                                PhoneNumber: "",
                                CompanyName: "",
                                PickupNumber: row.Id,
                                PickupAppDate: new Date(),
                                SealDroppingTrailerNumber: "",
                                SealDroppingNumber: "",
                                SealPickingUpTrailerNumber: "",
                                SealPickingUpNumber: "",
                                Type: 1,
                                IsChanged: false,
                              };
                              checkindriverOut = {
                                ShipmentId: row.Id,
                                DriverName: "",
                                PhoneNumber: "",
                                CompanyName: "",
                                PickupNumber: row.Id,
                                PickupAppDate: new Date(),
                                SealDroppingTrailerNumber: "",
                                SealDroppingNumber: "",
                                SealPickingUpTrailerNumber: "",
                                SealPickingUpNumber: "",
                                Type: 2,
                                IsChanged: false,
                              };
                              var resObject = await GetCarrierCheckedInData(
                                row.Id
                              );
                              if (
                                resObject !== null &&
                                resObject !== undefined
                              ) {
                                if (resObject?.CheckIn !== null) {
                                  setinitCarrier(resObject?.CheckIn);
                                  checkindriver = resObject?.CheckIn;
                                } else {
                                  if (resObject?.CheckOut !== null) {
                                    resObject.CheckOut.Type = 1;
                                    resObject.CheckOut.IsChanged = false;

                                    setinitCarrier(resObject?.CheckOut);
                                    checkindriver = resObject?.CheckOut;
                                  } else {
                                    setinitCarrier(checkindriver);
                                  }
                                }

                                if (resObject?.CheckOut !== null) {
                                  setinitCarrierOut(resObject?.CheckOut);
                                  checkindriverOut = resObject?.CheckOut;
                                } else {
                                  if (resObject?.CheckIn !== null) {
                                    resObject.CheckIn.Type = 2;
                                    resObject.CheckIn.IsChanged = false;
                                  } else {
                                    setinitCarrierOut(checkindriverOut);
                                  }
                                }
                              }
                              setshowModalPickedUp(true);
                              return;
                            }
                            if (index.StatusId === 3) {
                              await getvandorsList();
                              setinitAssignedStatusVendor({
                                ShipmentIds: [row.Id],
                                VendorId: row?.CarrierId,
                                VendorTypeId: 1308,
                                ShipmentStatus: 3,
                                ThirdPartyBillToId: "",
                                FreightChargeId: 98,
                              });
                              setShowModalAssigned(true);
                              return;
                            }
                            NextStatus(row.Id, index.StatusId);
                          }
                        }}
                      >
                        <span className="navi-icon">
                          <i className="flaticon2-right-arrow text-primary"></i>
                        </span>
                        <span className="navi-text">Next Status</span>
                      </a>
                    </li>
                  )}
                  {PrevOrder(filterOptions.statusId) !== null &&
                  filterOptions.statusId !== -1 ? (
                    <li className="navi-item">
                      <a
                        href={() => false}
                        className="navi-link"
                        onClick={(e) => {
                          e.preventDefault();
                          //setModelShowing(false);
                          GetListOfPreviewsStatus(row.Id);
                          setModelShowingConfirmation(true);
                        }}
                      >
                        <span className="navi-icon">
                          <i className="flaticon2-left-arrow text-warning"></i>
                        </span>
                        <span className="navi-text">Back Status</span>
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {filterOptions?.statusId !== "" && (
                <div className="dropdown-divider"></div>
              )}
              <li className="navi-item">
                <a
                  href={() => false}
                  className="navi-link"
                  onClick={(e) => {
                    setModelShowingConfirmationCancel(true);
                    setRowItem(row);
                    e.preventDefault();
                  }}
                >
                  <span className="navi-icon">
                    <i className="fas fa-times-circle text-danger"></i>
                  </span>
                  <span className="navi-text">
                    {row.IsCancelled === true ? "UnCancel" : "Cancel"}
                  </span>
                </a>
              </li>
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        <MenuIndex row={row} modalActions={props?.modalActions} />
      </div>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={Show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={row?.id} setShow={setShow.bind()} isTms={true} />
      </Modal>
      {ShowRemarks && <Remarks />}
      <Modal
        size="xl"
        show={OneMoveMasterShow}
        onHide={() => {
          setOneMoveMasterShow(false);
        }}
      >
        <OneMoveMaster
          Show={setOneMoveMasterShow}
          statSHipmentId={row.id}
          isTms={true}
        />
      </Modal>
      <SimplePopover />
    </>
  );
};

export function DetailMangment({ Id, setShow, isDashboard, isTms }) {
  const classes = useStyles();
  const [Detail, setDetail] = useState();
  const [VendorsList, setVendorsList] = useState();
  const [Notes, setNotesList] = useState([]);
  const [Attachments, setAttachmentsList] = useState();
  const [DetailTrakingInfo, setDetailTrakingInfo] = useState([]);
  const [DetailTrakingGeometryInfo, setDetailTrakingGeometryInfo] = useState(
    []
  );
  const [DetailActivityRatesInfo, setDetailActivityRatesInfo] = useState(null);
  const [DetailShipmentInfo, setDetailShipmentInfo] = useState({});

  const renderMarkers = (map, maps) => {
    var data = DetailTrakingGeometryInfo.map((item) => {
      return {
        lat: Number(item.Lat),
        lng: Number(item.Lng),
        name: item.Address,
        Type: item.TraceType,
      };
    });
    const directionsService = new maps.DirectionsService();
    const directionsRenderer = new maps.DirectionsRenderer();

    calculateAndDisplayRoute(directionsService, directionsRenderer);

    function calculateAndDisplayRoute(directionsService, directionsRenderer) {
      window.gMap = map;
      for (var i = 0; i < data.length; i++) {
        if (data[i].Type === "MANUAL") {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/orange-dot.png",
            },
          });
        } else {
          new maps.Marker({
            position: data[i],
            map: map,
            title: data[i].name,
            icon: {
              url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png",
            },
          });
        }
      }
      for (var j = 0, parts = [], max = 22 - 1; j < data.length; j = j + max) {
        parts.push(data.slice(j, j + max + 1));
      }

      for (var k = 0; k < parts.length; k++) {
        // Waypoints does not include first station (origin) and last station (destination)
        var waypoints = [];
        for (var l = 1; l < parts[k].length - 1; l++) {
          waypoints.push({ location: parts[k][l], stopover: false });
        }
        // Service options
        var service_options = {
          origin: parts[k][0],
          destination: data[data.length - 1],
          waypoints: waypoints,
          travelMode: "DRIVING",
        };
        // Send request
        directionsService.route(service_options, (response, status) => {
          if (status != "OK") {
            console.log("Directions request failed due to " + status);
            return;
          }
          if (!window.gRenderers) window.gRenderers = [];
          window.gRenderers.push(directionsRenderer);
          directionsRenderer.setMap(map);
          directionsRenderer.setOptions({
            suppressMarkers: true,
            preserveViewport: true,
          });
          directionsRenderer.setDirections(response);
        });
      }
    }
    directionsRenderer.setMap(map);
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
    },
  }));
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
      onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
      onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
      onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="First Page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="Previous Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Last Page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  const [Result, setResult] = useState(null);
  useEffect(() => {
    GetModel();
  }, []);
  const [contactModal, setContactModal] = useState(false);
  const [contactsVendor, setContactsVendor] = useState([]);
  const [vendorIdContact, setVendorIdContact] = useState(0);

  useEffect(() => {
    try {
      const obj = [];
      if (Detail?.Shipment?.Vendors?.length > 0) {
        Detail.Shipment.Vendors.map((itemX) => {
          itemX.VendorName =
            itemX.VendorAddress?.CompanyName +
            " - " +
            itemX.VendorAddress?.Code;
          itemX.ContactName = (
            <a
              className="btn btn-light-primary btn-elevate py-1"
              onClick={() => {
                setVendorIdContact(itemX.Id);
                setContactsVendor(itemX.Contacts || []);
                setContactModal(true);
              }}
            >
              Show
            </a>
          );
          obj.push(itemX);
        });
        setVendorsList(obj);
      }
    } catch (err) {
      console.log(err);
    }
  }, [Detail?.Shipment]);

  function CheckStatus(Name) {
    try {
      return $(`.ShipmentStatus:contains("${Name}")`)?.length > 0;
    } catch (error) {
      return false;
    }
  }
  const [loaderData, setLoaderData] = useState(false);
  //OSD
  const [shortQuantity, setShortQuantity] = useState("");
  const [damagedQuantity, setDamagedQuantity] = useState("");
  const [overQuantity, setOverQuantity] = useState("");
  const [modalOsdEdit, showModalEditOsd] = useState(false);
  const [saveEditOsd, setSaveOsd] = useState(false);
  const [rowOsd, setRowOsd] = useState(null);

  async function PutOsd() {
    const someData = {
      OverQuantity: overQuantity ?? 0,
      ShortQuantity: shortQuantity ?? 0,
      DamagedQuantity: damagedQuantity ?? 0,
    };
    return fetch(window.$apiurl + "/Shipments/Osd/" + rowOsd?.Id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {});
  }
  const [showAttachment, setShowAttachment] = useState(false);
  const [disabledDownload, setDisabledDownload] = useState(true);

  const onError = (e) => {
    console.log(e, "error in file-viewer");
  };
  const [type, setFileType] = useState("");
  const [filePath, setFilePath] = useState("");
  return (
    <>
      <Modal.Header closeButton>
        <div className="row w-100">
          <div className="col-lg-6">
            {IsValid("Order Number", Detail?.Shipment?.OrderId)}
          </div>
          <div className="col-lg-6">
            {IsValid(
              "Shipment Number",
              Detail?.Shipment?.Id +
                (Detail?.Shipment?.Equipment
                  ? " - " + Detail?.Shipment?.Equipment
                  : "")
            )}
          </div>

          <div className="col-lg-6">
            {IsValid("Invoice No", Detail?.Shipment?.InvoiceNumber)}
          </div>
          <div className="col-lg-6">
            {IsValid("Invoice Date", momentDate(Detail?.Shipment?.InvoiceDate))}
          </div>
        </div>
        <IconButton
          className="MuiButtonBase-root MuiIconButton-root jss179"
          aria-label="Close"
          onClick={() => {
            setShow(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        <Accordion defaultActiveKey="0">
          <>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="0"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Shipment Basic</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="form-group row word-break">
                <div className="col-lg-6">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Customer
                  </div>
                  <br />
                  {IsValid(
                    "Customer Code",
                    Detail?.Shipment?.CustomerAddress?.Code
                  )}
                  {IsValid(
                    "Customer Name",
                    Detail?.Shipment?.CustomerAddress?.CompanyName
                  )}
                  {IsValid(
                    "Customer Address",
                    Detail?.Shipment?.CustomerAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.CustomerAddress?.City +
                        " , " +
                        Detail?.Shipment?.CustomerAddress?.State +
                        " , " +
                        Detail?.Shipment?.CustomerAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Customer Ref No ",
                      Detail?.Shipment?.CustomerNumber
                    )}
                  </div>
                  {/* <div>
                      {IsValid("Customer Ref No 2", Detail?.CustomerReference2)}
                    </div> */}
                </div>

                <div className="col-lg-6">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Bill To
                  </div>
                  <br />
                  {IsValid(
                    "Bill To Code",
                    Detail?.Shipment?.BillToAddress?.Code
                  )}
                  {IsValid(
                    "Bill To Name",
                    Detail?.Shipment?.BillToAddress?.CompanyName
                  )}
                  {IsValid(
                    "Bill To Address1",
                    Detail?.Shipment?.BillToAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.BillToAddress?.City +
                        " , " +
                        Detail?.Shipment?.BillToAddress?.State +
                        " , " +
                        Detail?.Shipment?.BillToAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Billing Ref No ",
                      Detail?.Shipment?.BillingNumber
                    )}
                  </div>
                  {/* <div>
                      {IsValid("Billing Ref No 2", Detail?.BillingReference2)}
                    </div> */}
                </div>

                <div className="col-lg-6 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Pickup Location
                  </div>
                  <br />
                  {IsValid(
                    "Pickup Location Code",
                    Detail?.Shipment?.ShipperAddress?.Code
                  )}
                  {IsValid(
                    "Pickup Location Name",
                    Detail?.Shipment?.ShipperAddress?.CompanyName
                  )}
                  {IsValid(
                    "Pickup Location Address1",
                    Detail?.Shipment?.ShipperAddress?.Address
                  )}
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.ShipperAddress?.City +
                        " , " +
                        Detail?.Shipment?.ShipperAddress?.State +
                        " , " +
                        Detail?.Shipment?.ShipperAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid("Shipper Ref No", Detail?.Shipment?.ShipperNumber)}
                  </div>
                  <div>
                    {IsValid(
                      "Order Created",
                      momentDate(Detail?.Shipment?.CreatedAt)
                    )}
                  </div>

                  {/* <div>
                      {IsValid(
                        "Pickup Required By Date",
                        momentDate(Detail?.PickupDate)
                      )}
                    </div>
                    <div>
                      {IsValid(
                        "Pickup Appointment",
                        momentDate(Detail?.PickupOnDate)
                      )}
                    </div> */}
                </div>

                <div className="col-lg-6 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Ship to Location
                  </div>
                  <br />
                  {IsValid(
                    "Ship to Location Code",
                    Detail?.Shipment?.ConsigneeAddress?.Code
                  )}
                  {IsValid(
                    "Ship to Location Name",
                    Detail?.Shipment?.ConsigneeAddress?.CompanyName
                  )}
                  <div>
                    {IsValid(
                      "Ship to Location Address1",
                      Detail?.Shipment?.ConsigneeAddress?.Address
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "City,State,Zip Code",
                      Detail?.Shipment?.ConsigneeAddress?.City +
                        " , " +
                        Detail?.Shipment?.ConsigneeAddress?.State +
                        " , " +
                        Detail?.Shipment?.ConsigneeAddress?.ZipCode
                    )}
                  </div>
                  <div>
                    {IsValid(
                      "Consignee Ref No",
                      Detail?.Shipment?.ConsigneeNumber
                    )}
                  </div>
                </div>

                <div className="col-lg-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Dates
                  </div>
                </div>
                <div className="col-lg-6 mt-5">
                  {isTms === true
                    ? IsValid(
                        "Planned",
                        momentDate(Detail?.Shipment?.StatusPlannedDate)
                      )
                    : ""}
                  {isTms === true
                    ? IsValid(
                        "Trailler Drop Date",
                        momentDate(Detail?.Shipment?.TraillerDropDate)
                      )
                    : ""}
                  {isTms === true
                    ? IsValid(
                        "Notify Trucker",
                        momentDate(Detail?.Shipment?.StatusNotifyTrucker)
                      )
                    : ""}
                  {isTms === true
                    ? IsValid(
                        "Checked In Date",
                        momentDate(Detail?.Shipment?.StatusCheckedInDate)
                      )
                    : ""}
                  {CheckStatus("Quoted") &&
                    IsValid(
                      "Quoted",
                      momentDate(Detail?.Shipment?.StatusQuotedDate)
                    )}
                  {CheckStatus("Booked") &&
                    IsValid(
                      "Booked",
                      momentDate(Detail?.Shipment?.StatusBookedDate)
                    )}
                  {CheckStatus("Loading") &&
                    IsValid(
                      "Loading From Our Warehouse",
                      momentDate(
                        Detail?.Shipment?.StatusLoadingFromOurWarehouseDate
                      )
                    )}
                  {CheckStatus("Picked Up") &&
                    IsValid(
                      "Picked Up",
                      momentDate(Detail?.Shipment?.StatusPickedUpDate)
                    )}
                  {CheckStatus("In Transit") &&
                    IsValid(
                      "In Transit",
                      momentDate(Detail?.Shipment?.StatusInTransitDate)
                    )}
                  {CheckStatus("Delivered to Consignee") &&
                    IsValid(
                      "Delivered To Consignee",
                      momentDate(
                        Detail?.Shipment?.StatusDeliveredToConsigneeDate
                      )
                    )}
                  {CheckStatus("Empty") &&
                    IsValid(
                      "Empty",
                      momentDate(Detail?.Shipment?.StatusEmptyDate)
                    )}
                  {IsValid(
                    "Requested Pick Up Date",
                    momentDate(Detail?.Shipment?.RequestedPickUpDate)
                  )}
                  {IsValid(
                    "Last Ship Date",
                    momentDate(Detail?.Shipment?.LastShipDate)
                  )}
                  {IsValid(
                    "P/U Appointment",
                    momentDate(Detail?.Shipment?.PuAppointment)
                  )}
                  {IsValid(
                    "P/U Appointment Start",
                    moment(
                      momentDate(Detail?.Shipment?.PuAppointment),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.PuAppointment),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "P/U Appointment To",
                    moment(
                      momentDate(Detail?.Shipment?.PuAppointmentTo),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.PuAppointmentTo),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                </div>
                <div className="col-lg-6 mt-5">
                  {CheckStatus("New") &&
                    IsValid(
                      "New Date",
                      momentDate(Detail?.Shipment?.StatusNewDate)
                    )}
                  {CheckStatus("Dispatched") &&
                    IsValid(
                      "Dispatched",
                      momentDate(Detail?.Shipment?.StatusDispatchedDate)
                    )}
                  {CheckStatus("Loaded") &&
                    IsValid(
                      "Loaded From Our Warehouse",
                      momentDate(
                        Detail?.Shipment?.StatusLoadedFromOurWarehouseDate
                      )
                    )}
                  {CheckStatus("In Gate at Origin Hub") &&
                    IsValid(
                      "In Gate At Origin Hub",
                      momentDate(Detail?.Shipment?.StatusInGateAtOriginHubDate)
                    )}
                  {CheckStatus("Arrived Destination Hub") &&
                    IsValid(
                      "Arrived Destination Hubt",
                      momentDate(
                        Detail?.Shipment?.StatusArrivedDestinationHubDate
                      )
                    )}
                  {IsValid(
                    "Delivered",
                    momentDate(Detail?.Shipment?.StatusDeliveredDate)
                  )}
                  {CheckStatus("Closed") &&
                    IsValid(
                      "Closed",
                      momentDate(Detail?.Shipment?.StatusClosedDate)
                    )}
                  <br />
                  <br />
                  {IsValid(
                    "Appointment Date",
                    momentDate(Detail?.Shipment?.AppointmentDate)
                  )}
                  {IsValid(
                    "Appointment Date Start",
                    moment(
                      momentDate(Detail?.Shipment?.AppointmentDate),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.AppointmentDate),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "Appointment Date To",
                    moment(
                      momentDate(Detail?.Shipment?.AppointmentTo),
                      "MM-DD-YYYY, hh:mm A"
                    ).format("hh:mm A") === "Invalid date"
                      ? ""
                      : moment(
                          momentDate(Detail?.Shipment?.AppointmentTo),
                          "MM-DD-YYYY, hh:mm A"
                        ).format("hh:mm A")
                  )}
                  {IsValid(
                    "E.T.A",
                    momentDate(Detail?.Shipment?.EstimatedArrivalDate)
                  )}
                  {IsValid("RDD", momentDateOnly(Detail?.Shipment?.Rdd))}
                </div>
                <div className="col-lg-12 mt-5">
                  <div
                    className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                    style={{ fontSize: "15px" }}
                  >
                    Basics
                  </div>
                </div>
                <div className="col-lg-6 mt-5">
                  {IsValid("Commodity Id", Detail?.Shipment?.CommodityId)}
                  {IsValid("Entered By", Detail?.Shipment?.EnteredBy)}
                  {IsValid(
                    "Order Created",
                    momentDate(Detail?.Shipment?.CreatedAt)
                  )}
                  {IsValid("Order No", Detail?.Shipment?.OrderId)}
                  {IsValid("Weight", currencyFormat(Detail?.Shipment?.Weight))}
                  {IsValid("Cube", currencyFormat(Detail?.Shipment?.Cube))}
                  {IsValid("Pieces", Detail?.Shipment?.Pieces)}
                </div>

                <div className="col-lg-6 mt-5">
                  {IsValid("Service", Detail?.Shipment?.ServiceName)}
                  {IsValid("Mode", Detail?.Shipment?.ModeName)}
                  {IsValid("Service Level", Detail?.Shipment?.ServiceLevelName)}
                  {IsValid(
                    "Equipment Type",
                    Detail?.Shipment?.EquipmentTypeName
                  )}
                  {IsValid(
                    "Equipment Size",
                    Detail?.Shipment?.EquipmentSizeName
                  )}
                  {IsValid("Load Type", Detail?.Shipment?.PalletType)}
                  {Detail?.Shipment?.PalletType === "Palletized" && (
                    <>
                      {IsValid("Pallet Type", Detail?.Shipment?.PalletLoadType)}
                      {IsValid("Pallet Count", Detail?.Shipment?.PalletCount)}
                    </>
                  )}

                  {IsValid("Tracking Device", Detail?.Shipment?.DeviceName)}
                  {IsValid("Freight Charge", Detail?.Shipment?.FreightCharge)}
                </div>
                {Detail?.ServiceName === "Drayage" && (
                  <>
                    <div className="col-lg-12 mt-5">
                      <div
                        className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                        style={{ fontSize: "15px" }}
                      >
                        Oder Details - Drayage
                      </div>
                    </div>
                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Mode Name", Detail?.ModeName)}
                      {IsValidColumn("Vessel", Detail?.Vessel)}
                      {IsValidColumn("Seal Number", Detail?.SealNumber)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                      {IsValidColumn("Voyage Number", Detail?.VesselVoyage)}
                      {IsValidColumn("Cartons", Detail?.Cartons)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                      {IsValidColumn("ETA", Detail?.Eta)}
                      {IsValidColumn("Weight", Detail?.TotalWeight1)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Number", Detail?.EquipmentRef)}
                      {IsValidColumn("MBL", Detail?.MasterBill)}
                      {IsValidColumn(
                        "Cube",
                        currencyFormat(Detail?.TotalCube1)
                      )}
                    </div>
                  </>
                )}

                {Detail?.ServiceName === "Domestic Transportation" && (
                  <div className="col-lg-12 form-group row">
                    <div className="col-lg-12 mt-5">
                      <div
                        className="label label-lg label-light label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                        style={{ fontSize: "15px" }}
                      >
                        Order Details - LTL
                      </div>
                    </div>
                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Mode Name", Detail?.ModeName)}{" "}
                      {IsValidColumn("Cartons", Detail?.Cartons)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Service Level", Detail?.ServiceLevel)}
                      {IsValidColumn("Pallets", Detail?.Pallets)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn("Equipment Type", Detail?.EquipmentType)}
                      {IsValidColumn("Weight", Detail?.TotalWeight1)}
                    </div>

                    <div className="col-lg-3 mt-5">
                      {IsValidColumn(
                        "Equipment Number",
                        Detail?.EquipmentTypeId
                      )}
                      {IsValidColumn(
                        "Cube",
                        currencyFormat(Detail?.TotalCube1)
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="5"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">TRACKING</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse className="mb-5" eventKey="5">
              <div className="row">
                <div className="col-lg-6 ">
                  <div className={`card card-custom  card-stretch gutter-b`}>
                    {/* Header */}
                    <div className="card-header align-items-center border-0">
                      <h3 className="card-title align-items-start flex-column">
                        <span class="font-weight-bolder text-dark text-uppercase">
                          BASIC DETAILS
                        </span>
                      </h3>
                    </div>

                    {/* Body */}
                    <div className="card-body pt-10">
                      <div className="row">
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Trailer Number
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment?.Equipment}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Seal Number
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment?.SealNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Customer
                              </a>
                              <span className="text-primary">
                                {
                                  DetailShipmentInfo?.Shipment?.CustomerAddress
                                    ?.CompanyName
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="d-flex  mb-10">
                            <span
                              className={`label label-dot label-primary mr-2 mt-2`}
                            ></span>
                            <div className="d-flex flex-column font-weight-bold">
                              <a
                                href="#"
                                className="text-dark text-hover-primary mb-1 font-size-lg"
                              >
                                Carrier
                              </a>
                              <span className="text-primary">
                                {DetailShipmentInfo?.Shipment !== null
                                  ? DetailShipmentInfo?.Shipment?.Vendors
                                      ?.length > 0 &&
                                    DetailShipmentInfo?.Shipment?.Vendors[0]
                                      ?.VendorAddress?.CompanyName
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Current Location
                          </a>
                          <span className="text-primary">
                            {Result?.CurrentEvent +
                              " - " +
                              Result?.CurrentCity +
                              ", " +
                              Result?.CurrentState +
                              " - " +
                              formatDate(Result?.CurrentDate) +
                              ", " +
                              tConvert(Result?.CurrentTime)}
                          </span>
                        </div>
                      </div>

                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Shipment Status
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment?.StatusName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div class="card card-custom  card-stretch gutter-b">
                    <div class="card-header align-items-center border-0">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="font-weight-bolder text-dark text-uppercase">
                          SHIPMENT OVERVIEW
                        </span>
                      </h3>
                    </div>
                    <div class="card-body pt-10">
                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Ship From
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment?.ShipperAddress?.Code}
                            {"-"}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.CompanyName
                            }
                            :{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.Address
                            }
                            ,{" "}
                            {DetailShipmentInfo?.Shipment?.ShipperAddress?.City}
                            ,{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.State
                            }{" "}
                            {
                              DetailShipmentInfo?.Shipment?.ShipperAddress
                                ?.ZipCode
                            }
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Ship To
                          </a>
                          <span className="text-primary">
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.Code
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.CompanyName !== null
                              ? "-"
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.CompanyName
                            }
                            {DetailShipmentInfo?.DestinationInformation
                              ?.Address !== null
                              ? ": "
                              : ""}
                            {
                              DetailShipmentInfo?.DestinationInformation
                                ?.Address
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.City !== null
                              ? ", "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.City
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.State !== null
                              ? ", "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.State
                            }
                            {DetailShipmentInfo?.Shipment?.ConsigneeAddress
                              ?.ZipCode !== null
                              ? " "
                              : ""}
                            {
                              DetailShipmentInfo?.Shipment?.ConsigneeAddress
                                ?.ZipCode
                            }
                          </span>
                        </div>
                      </div>
                      <div className="d-flex mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Pickup Estimated Date
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment
                              ?.StatusInTransitDate !== null
                              ? new Date(
                                  DetailShipmentInfo?.Shipment?.StatusInTransitDate
                                ).toLocaleString()
                              : ""}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex  mb-10">
                        <span
                          className={`label label-dot label-primary mr-2 mt-2`}
                        ></span>
                        <div className="d-flex flex-column font-weight-bold">
                          <a
                            href="#"
                            className="text-dark text-hover-primary mb-1 font-size-lg"
                          >
                            Delivery Estimated Date
                          </a>
                          <span className="text-primary">
                            {DetailShipmentInfo?.Shipment
                              ?.EstimatedArrivalDate !== null
                              ? new Date(
                                  DetailShipmentInfo?.Shipment?.EstimatedArrivalDate
                                ).toLocaleString()
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="6"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">TRACKING DETAILS</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse className="mb-5" eventKey="6">
              <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                <Tab eventKey="home" title="Map View">
                  <div className="row">
                    <div className="col-lg-8 " style={{ height: "500px" }}>
                      {DetailTrakingGeometryInfo ? (
                        DetailTrakingGeometryInfo.length !== 0 ? (
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: window.$GoogleMapsKey,
                            }}
                            defaultCenter={{
                              lat: 37.923984,
                              lng: -97.539071,
                            }}
                            defaultZoom={4}
                            onGoogleApiLoaded={({ map, maps }) =>
                              renderMarkers(map, maps)
                            }
                          ></GoogleMapReact>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-4 trucking-scroll">
                      <div
                        className={`card card-custom`}
                        style={{ height: "500px" }}
                      >
                        {/* Header */}
                        <div className="card-header align-items-center border-bottom">
                          <h3 className="card-title align-items-start flex-column">
                            <span className="font-weight-bolder text-dark">
                              TRACKING
                            </span>
                          </h3>
                        </div>
                        {/* Body */}
                        <div className="card-body py-5">
                          <div className="timeline timeline-5 my-5">
                            <div className="timeline-item align-items-start">
                              <div className="timeline-badge">
                                <i className="fa fa-genderless text-warning icon-xxl"></i>
                              </div>

                              <div className="timeline-content d-block">
                                <span className="mr-4 font-weight-bolder text-dark">
                                  {
                                    DetailShipmentInfo?.Shipment?.ShipperAddress
                                      ?.City
                                  }
                                  ,{" "}
                                  {
                                    DetailShipmentInfo?.Shipment?.ShipperAddress
                                      ?.State
                                  }
                                </span>
                                <div className="timeline-content text-primary my-3 mx-0">
                                  Start Tracking:{" "}
                                  {DetailShipmentInfo?.Shipment
                                    ?.StatusInTransitDate !== null
                                    ? new Date(
                                        DetailShipmentInfo?.Shipment?.StatusInTransitDate
                                      ).toLocaleString()
                                    : ""}
                                </div>
                              </div>
                            </div>
                            {DetailTrakingInfo
                              ? DetailTrakingInfo.map((item, index) => (
                                  <div
                                    key={index}
                                    className="timeline-item align-items-start"
                                  >
                                    <div className="timeline-badge">
                                      <i
                                        className={
                                          item.TraceType === "MANUAL"
                                            ? "fa fa-genderless text-warning icon-xxl"
                                            : "fa fa-genderless text-success icon-xxl"
                                        }
                                      ></i>
                                    </div>

                                    <div className="timeline-content d-block">
                                      <span className="mr-4 font-weight-bolder text-dark">
                                        {item.LocationCity},{" "}
                                        {item.LocationState}
                                      </span>
                                      <div className="timeline-content text-primary my-3 mx-0">
                                        {item.EventName}:{" "}
                                        {item.TraceDate !== null
                                          ? formatDate(item.TraceDate) +
                                            ", " +
                                            tConvert(item.TraceTime)
                                          : ""}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="profile" title="Table View">
                  <Table className={`mt-5 ${classes.table}`}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-light">Trace Type</TableCell>
                        <TableCell className="bg-light">Trace Date</TableCell>
                        <TableCell className="bg-light">Location</TableCell>
                        <TableCell className="bg-light">Event Name</TableCell>
                        <TableCell className="bg-light">Eta Date</TableCell>
                        <TableCell className="bg-light">Created By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {DetailTrakingInfo !== undefined &&
                        DetailTrakingInfo.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {item.TraceType}
                            </TableCell>
                            <TableCell>
                              {item.TraceDate !== null
                                ? formatDate(item.TraceDate) +
                                  ", " +
                                  tConvert(item.TraceTime)
                                : ""}
                            </TableCell>
                            <TableCell>
                              {item.LocationCity +
                                " " +
                                item.LocationState +
                                ", " +
                                item.LocationCountry}
                            </TableCell>
                            <TableCell>{item.EventName}</TableCell>
                            <TableCell>
                              {item.EtaDate !== null
                                ? formatDate(item.EtaDate) +
                                  ", " +
                                  tConvert(item.EtaTime)
                                : ""}
                            </TableCell>
                            <TableCell>{item.CreatedBy}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Tab>
              </Tabs>
            </Accordion.Collapse>

            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="12"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Shipment Items</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="12">
              <div className="form-group">
                <div className="col-lg-12 mt-5">
                  <div className="react-bootstrap-table table-responsive">
                    <Table className={classes.table} size="normal">
                      <TableHead className="mb-4">
                        <TableRow>
                          <TableCell className="bg-light">
                            Purchase Order
                          </TableCell>
                          <TableCell className="bg-light">
                            Unit Of Measure
                          </TableCell>
                          <TableCell className="bg-light">Location</TableCell>
                          <TableCell className="bg-light">Sku</TableCell>
                          <TableCell className="bg-light">UPC</TableCell>
                          <TableCell className="bg-light">Container</TableCell>
                          <TableCell className="bg-light">
                            Description
                          </TableCell>
                          <TableCell className="bg-light">UnitCount</TableCell>
                          <TableCell className="bg-light">Weight</TableCell>
                          <TableCell className="bg-light">Cube</TableCell>
                          <TableCell className="bg-light">IsHazmat</TableCell>
                          <TableCell className="bg-light">IsHot</TableCell>
                          <TableCell className="bg-light">
                            ShortQuantity
                          </TableCell>
                          <TableCell className="bg-light">
                            OverQuantity
                          </TableCell>
                          <TableCell className="bg-light">
                            DamagedQuantity
                          </TableCell>

                          <TableCell className="bg-light">
                            Ship Short Qte.
                          </TableCell>
                          <TableCell className="bg-light">
                            Ship Over Qte.
                          </TableCell>
                          <TableCell className="bg-light">
                            Ship Damaged Qte.
                          </TableCell>
                          <TableCell className="bg-light">Case Pack</TableCell>
                          {/* {isDashboard && ( */}
                          <TableCell className="bg-light">Actions</TableCell>
                          {/*  )} */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Detail?.Shipment?.ShipmentItems.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((item) => (
                          <TableRow>
                            <TableCell>{item.PurchaseOrder}</TableCell>
                            <TableCell>{item.UnitOfMeasure}</TableCell>
                            <TableCell>
                              {
                                <>
                                  {item.Location}
                                  {item.LocationCode && (
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip id="products-edit-tooltip">
                                          {item.LocationCode}
                                        </Tooltip>
                                      }
                                    >
                                      <a className="btn btn-icon btn-light btn-hover-light btn-sm">
                                        <span className="svg-icon svg-icon-md svg-icon-info">
                                          <SVG
                                            src={toAbsoluteUrl(
                                              "/media/svg/icons/Code/Info-circle.svg"
                                            )}
                                          />
                                        </span>
                                      </a>
                                    </OverlayTrigger>
                                  )}
                                </>
                              }
                            </TableCell>
                            <TableCell>{item.Sku}</TableCell>
                            <TableCell>{item.Upc}</TableCell>
                            <TableCell>{item.Container}</TableCell>
                            <TableCell>{item.Description}</TableCell>
                            <TableCell>{item.Quantity}</TableCell>
                            <TableCell>{currencyFormat(item.Weight)}</TableCell>
                            <TableCell>{currencyFormat(item.Cube)}</TableCell>
                            <TableCell>
                              {" "}
                              {item.IsHazmat && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="products-edit-tooltip">
                                      Hazardous Material
                                    </Tooltip>
                                  }
                                >
                                  <a className="btn btn-icon btn-light btn-hover-light btn-sm mr-2">
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/296395.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.IsHot && (
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip id="products-edit-tooltip">
                                      Hot
                                    </Tooltip>
                                  }
                                >
                                  <a className="btn btn-icon btn-light btn-hover-light btn-sm mr-2">
                                    <span className="svg-icon svg-icon-md svg-icon-danger">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Communication/fire.svg"
                                        )}
                                      />
                                    </span>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </TableCell>
                            <TableCell>{item.ShortQuantity}</TableCell>
                            <TableCell>{item.OverQuantity}</TableCell>
                            <TableCell>{item.DamagedQuantity}</TableCell>
                            <TableCell>{item.ShipmentShortQuantity}</TableCell>
                            <TableCell>{item.ShipmentOverQuantity}</TableCell>
                            <TableCell>
                              {item.ShipmentDamagedQuantity}
                            </TableCell>
                            <TableCell>{item.CasePack}</TableCell>

                            {/*     {isDashboard && ( */}
                            <TableCell>
                              <a
                                title="Edit OSD"
                                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                                onClick={() => {
                                  showModalEditOsd(true);
                                  setRowOsd(item);
                                  setSaveOsd(false);
                                  setOverQuantity(item.ShipmentOverQuantity);
                                  setShortQuantity(item.ShipmentShortQuantity);
                                  setDamagedQuantity(
                                    item.ShipmentDamagedQuantity
                                  );
                                }}
                              >
                                <span className="svg-icon svg-icon-md svg-icon-primary">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Communication/Write.svg"
                                    )}
                                  />
                                </span>
                              </a>
                              <> </>
                            </TableCell>
                            {/*     )} */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Modal
                      size="xl"
                      show={modalOsdEdit}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <Modal.Title>
                          Edit OSD Item / PO# : {rowOsd?.PurchaseOrder}
                        </Modal.Title>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={() => {
                            showModalEditOsd(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>Over Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Over Quantity"
                              min="0"
                              defaultValue={overQuantity}
                              onChange={(e) => {
                                setOverQuantity(e.target.value);
                              }}
                            />
                          </div>

                          <div className="col-lg-6">
                            <label>Short Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Short Quantity"
                              min="0"
                              defaultValue={shortQuantity}
                              onChange={(e) => {
                                setShortQuantity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-6">
                            <label>Damaged Quantity</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Damaged Quantity"
                              min="0"
                              defaultValue={damagedQuantity}
                              onChange={(e) => {
                                setDamagedQuantity(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn btn-light btn-elevate"
                          onClick={(e) => {
                            showModalEditOsd(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          disabled={saveEditOsd}
                          className={`btn btn-primary btn-elevate ${saveEditOsd &&
                            `spinner spinner-right spinner-white pr-15`}`}
                          onClick={async (e) => {
                            try {
                              setSaveOsd(true);
                              await PutOsd(rowOsd.Id);
                              showModalEditOsd(false);
                              await GetModel();
                              Notification("success", "Success");
                              setSaveOsd(false);
                            } catch (err) {}
                          }}
                        >
                          Save
                        </button>
                      </Modal.Footer>
                    </Modal>

                    {Detail?.Shipment?.ShipmentItems && (
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        colSpan={3}
                        count={Detail?.Shipment?.ShipmentItems.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { "aria-label": "Rows per page" },
                          native: true,
                        }}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </div>
                </div>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="1"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Transportation Items</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div className="form-group row">
                <div className="col-lg-12 mt-5">
                  <DenseTableSm
                    rowsName={[
                      { Name: "PurchaseOrder", Title: "Purchase Order" },
                      { Name: "UnitOfMeasure", Title: "Unit Of Measure" },
                      { Name: "MiffId", Title: "Miff Id" },
                      { Name: "Sku", Title: "Sku" },
                      { Name: "Description", Title: "Description" },
                      { Name: "Quantity", Title: "UnitCount" },
                      { Name: "Weight", Title: "Weight" },
                      { Name: "Cube", Title: "Cube" },
                      {
                        Name: "IsHazmat",
                        Title: "Is Hazardous Material",
                      },
                      {
                        Name: "IsHot",
                        Title: "IsHot",
                      },
                      { Name: "ShortQuantity", Title: "ShortQuantity" },
                      { Name: "OverQuantity", Title: "OverQuantity" },
                      { Name: "DamagedQuantity", Title: "DamagedQuantity" },
                    ]}
                    rows={Detail?.Shipment?.TransportationItems || []}
                  />
                </div>
              </div>
            </Accordion.Collapse>
            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="10"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">Vendors</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="10">
                  <>
                    <div className="form-group row">
                      <div className="col-lg-12 mt-5">
                        <DenseTableSm
                          rowsName={[
                            { Name: "Id", Title: "Id" },
                            { Name: "VendorName", Title: "Vendor" },
                            {
                              Name: "FreightChargeName",
                              Title: "Freight Charge",
                            },
                            { Name: "ContactName", Title: "Contacts" },
                            { Name: "VendorNumber", Title: "Vendor Number" },
                            { Name: "DriverName", Title: "Driver Name" },
                            { Name: "DriverPhone", Title: "Driver Phone" },
                            { Name: "TruckNumber", Title: "Truck Number" },
                            {
                              Name: "VendorInstructions",
                              Title: "Vendor Instructions",
                            },
                            { Name: "Description", Title: "Description" },
                            { Name: "Rate", Title: "Rate" },
                          ]}
                          rows={VendorsList || []}
                        />
                      </div>
                    </div>
                    <Modal show={contactModal} size="xl">
                      <Modal.Header>
                        <Modal.Title>
                          Vendor #: {vendorIdContact} Contacts
                        </Modal.Title>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={(e) => {
                            setContactModal(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group row">
                          <div className="col-lg-12 mt-5">
                            <DenseTableSm
                              rowsName={[
                                { Name: "ContactId", Title: "Id" },
                                { Name: "ContactName", Title: "Name" },
                                { Name: "Email", Title: "Email" },
                              ]}
                              rows={contactsVendor || []}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          onClick={() => {
                            setContactModal(false);
                          }}
                          className="btn btn-light btn-elevate w-25"
                        >
                          Close
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </>
                </Accordion.Collapse>
              </div>
            )}
            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="2"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">Rates</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`h-60 card card-custom`}>
                        {/* Body */}
                        <div className="card-body pt-5 row">
                          <Table className={classes.table} size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>Charge Code</TableCell>
                                <TableCell>Charge Description</TableCell>
                                <TableCell>Freight Charge</TableCell>
                                <TableCell>Bill Amount</TableCell>
                                <TableCell>Payable Amount</TableCell>
                                <TableCell>Margin</TableCell>
                                <TableCell>Margin%</TableCell>
                                <TableCell>Invoice#</TableCell>
                                <TableCell>Invoice Date</TableCell>
                                <TableCell>Vendor Name</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Detail?.Shipment?.CalculatedCharges.map(
                                (row) => (
                                  <TableRow>
                                    <TableCell>{row.Code}</TableCell>
                                    <TableCell>
                                      {row.ChargeDescription}
                                    </TableCell>
                                    <TableCell>
                                      {row?.FreightChargeName ?? "-"}
                                    </TableCell>
                                    <TableCell>{row.BillAmount}</TableCell>
                                    <TableCell>{row.PayableAmount}</TableCell>
                                    <TableCell>{row.Margin}</TableCell>
                                    <TableCell>
                                      {row.MarginPercentage}
                                    </TableCell>
                                    <TableCell>{row.InvoiceNumber}</TableCell>
                                    <TableCell>
                                      {row.InvoiceDate !== null
                                        ? new Date(
                                            row.InvoiceDate
                                          ).toLocaleString()
                                        : ""}
                                    </TableCell>
                                    <TableCell>{row.VendorName}</TableCell>
                                  </TableRow>
                                )
                              )}
                              <TableRow>
                                <TableCell></TableCell>
                                <TableCell />
                                <TableCell>
                                  <strong>Totals</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalReceivable}
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalPayable}
                                  </strong>
                                </TableCell>
                                <TableCell>
                                  <strong>{Detail?.Shipment?.Revenue}</strong>
                                </TableCell>
                                <TableCell>
                                  <strong>
                                    {Detail?.Shipment?.TotalReceivable === 0
                                      ? 0
                                      : (
                                          (Detail?.Shipment?.Revenue /
                                            Detail?.Shipment?.TotalReceivable) *
                                          100
                                        ).toFixed(2)}
                                  </strong>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Collapse>
              </div>
            )}
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="3"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Notes</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <>
                <div className="form-group row">
                  <div className="col-lg-12">
                    {isDashboard === true ? (
                      <DenseTableSm
                        rowsName={[
                          { Name: "Id", Title: "#" },
                          { Name: "Body", Title: "Body" },
                          { Name: "UserEmail", Title: "User Email" },
                          { Name: "CreatedBy", Title: "Created By" },
                          { Name: "CategoryName", Title: "Category" },
                          { Name: "CreatedAt", Title: "Created" },
                        ]}
                        rows={
                          Notes?.filter((word) => word.AccessLevelId === 4) ||
                          []
                        }
                      />
                    ) : (
                      <div className={classes.root}>
                        <Paper className={classes.paper}>
                          <Table
                            className={`LineItems ${classes.table}`}
                            size="small"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell align="left">Id</TableCell>
                                <TableCell align="left">Body</TableCell>
                                <TableCell align="left">UserEmail</TableCell>
                                <TableCell align="left">CreatedBy</TableCell>
                                <TableCell align="left">CategoryName</TableCell>
                                <TableCell align="left">CreatedAt</TableCell>
                                <TableCell align="left">Access Level</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Notes?.map((row) => (
                                <TableRow key={row.name}>
                                  <TableCell align="left">{row.Id}</TableCell>
                                  <TableCell align="left">{row.Body}</TableCell>
                                  <TableCell align="left">
                                    {row.UserEmail}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CreatedBy}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CategoryName}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.CreatedAt}
                                  </TableCell>
                                  <TableCell align="left">
                                    {row.AccessLevelId === 5
                                      ? "Private"
                                      : row.AccessLevelId === 4
                                      ? "Public"
                                      : ""}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey="4"
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Attachments</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <>
                <div className="form-group row">
                  <div className="col-lg-12 mt-5">
                    {/* Header */}
                    <div className="card-header border-bottom">
                      <h4 className="card-title font-weight-bolder text-dark">
                        Shipments Attachments
                      </h4>
                    </div>
                    {isDashboard === true ? (
                      <OrderAttachementsV2
                        id={Id}
                        isDashboard={true}
                        AttachmentList={Detail?.Shipment?.Attachments}
                        Type="shipment"
                      />
                    ) : (
                      Attachments?.map((x, index) => (
                        <CardFunction
                          x={x}
                          key={index}
                          index={index}
                          id={Detail?.Shipment?.Id}
                        />
                      ))
                    )}
                    {}
                  </div>
                </div>

                {/* Modal Attachment Viewer */}
                <Modal
                  size="xl"
                  show={showAttachment}
                  onHide={() => {
                    setShowAttachment(false);
                  }}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header>
                    <Modal.Title>
                      Attachment Viewer for SM #: {Detail?.Shipment?.Id}
                    </Modal.Title>
                    <IconButton
                      className="MuiButtonBase-root MuiIconButton-root jss179"
                      aria-label="Close"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    <>
                      <div className="form-group row mb-5">
                        <div className="col-lg-8"></div>
                        <div
                          className="col-lg-4"
                          style={{ textAlignLast: "right" }}
                        >
                          <button
                            type="submit"
                            disabled={disabledDownload}
                            className="btn btn-primary btn-elevate"
                            onClick={() => {
                              const fileName = filePath.replace(
                                /^.*[\\\/]/,
                                ""
                              );
                              FileSaver(filePath, fileName);
                            }}
                          >
                            Download
                          </button>{" "}
                        </div>
                      </div>
                      {type === "pdf" ? (
                        <div className="form-group row" key={Math.random()}>
                          <div
                            className="col-lg-12 pdfViewer"
                            key={Math.random()}
                          >
                            <PDFViewer
                              document={{
                                url: filePath,
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="form-group row" key={Math.random()}>
                          <div className="col-lg-12" key={Math.random()}>
                            <FileViewer
                              fileType={type}
                              filePath={filePath}
                              onError={onError}
                              className="w-100"
                            />
                          </div>
                        </div>
                      )}
                    </>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={() => {
                        setShowAttachment(false);
                      }}
                      className="btn btn-light btn-elevate w-25"
                    >
                      Close
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </Accordion.Collapse>

            {!isDashboard && (
              <div>
                <Accordion.Toggle
                  as={Button}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey="7"
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                    <h5 className="font-weight-bold">ACTIVITIES</h5>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse className="mb-5" eventKey="7">
                  {DetailActivityRatesInfo !== null ? (
                    <ActivityFunction
                      Activitys={DetailActivityRatesInfo || []}
                    />
                  ) : (
                    <div className={`h-60 card card-custom`}>
                      <div className="card-body pt-5 row now-rapTable">
                        <div
                          className="col-xl-12"
                          style={{ textAlign: "center" }}
                        >
                          Loading Data ...
                        </div>
                      </div>
                    </div>
                  )}
                </Accordion.Collapse>
              </div>
            )}
          </>
        </Accordion>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          className="btn btn-light btn-elevate w-25"
        >
          Close
        </button>
      </Modal.Footer>
    </>
  );

  async function GetModel() {
    await fetch(`${window.$apiurl}/transload/shipment/` + Id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setDetail(a);
        setDetailShipmentInfo(a);
        setAttachmentsList(a.Shipment.Attachments);
        var b = a.Shipment.Notes;
        b.forEach((x) => {
          x.CreatedAt = momentDate(x?.CreatedAt);
        });
        setNotesList(b);
      })
      .catch((error) => {
        console.error(error);
      });
    setDetailActivityRatesInfo(null);
    fetch(window.$apiurl + `/tracking/${Id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setResult(obj);
        setDetailTrakingInfo(obj.Tracking);
        setDetailTrakingGeometryInfo(obj.Polyline);

        obj.Activities.sort(function(a, b) {
          return new Date(b.Created) - new Date(a.Created);
        });
        obj.Activities.forEach((x, i) => {
          x.id = i;
        });
        setDetailActivityRatesInfo(obj?.Activities || []);
      })
      .catch((error) => console.log("error", error));
  }

  function getExtension(path) {
    var basename = path.split(/[\\/]/).pop(),
      pos = basename.lastIndexOf(".");
    if (basename === "" || pos < 1) return "";
    return basename.slice(pos + 1);
  }
  async function SecureFileFetch(receiptId, attachmentId) {
    return await fetch(
      window.$apiurl +
        `/generic/attachment/secureFile/${receiptId}/${attachmentId}?isReceipt=false&isQuote=false`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        try {
          const data = res[1];
          const fileType = getExtension(data.AttachmentFile);
          setFileType(fileType.toLowerCase());
          setFilePath(data.AttachmentFile);
          setDisabledDownload(false);
        } catch (err) {}
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function CardFunction({ x, index, New, id }) {
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <p className="mb-2 d-inline card-subtitle text-primary ">
              {new Date(x?.Created).toLocaleString()}
            </p>
            <Card.Text className="mt-5 ml-5">
              <b className="text-primary mr-5 ">Download :</b>
              <b>
                <a
                  onClick={async () => {
                    setShowAttachment(true);
                    await SecureFileFetch(id, x.Id);
                  }}
                  href={() => false}
                  // target="_blank"
                  role="button"
                  className="btn btn-light-primary btn-elevate py-1"
                >
                  {x?.FileName}
                </a>
              </b>

              {/* Detail Attachments */}
              <div className={"row mt-md-7"}>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Attachment Type :</b>{" "}
                  <b> {x?.Type}</b>
                </div>
                <div className="col-lg-6">
                  <b className="text-primary mr-5">Access Level :</b>{" "}
                  <b> {x?.AccessLevel}</b>
                </div>
              </div>
            </Card.Text>
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
}
