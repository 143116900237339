import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock = [];

export default async function mockPoBillingManagement(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(customerIdApi) {
    return await fetch(
      `${window.$apiurl}/Pickup/List?statusId=&type=&isClosed=false&pickupLocationId=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].pallets = ChangeId(res[1].Pickups);
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/pallets/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].pallet.id = res[1].pallet.Id;
        return res[1].pallet;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/pallets", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/pallets/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData["customer"]),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/pallets/" + id, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/pallets/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res ?? [];
      })
      .catch(() => {
        Notification("error", "GetViewmodel not working");
        return false;
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/PoBillingManagement/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/PoBillingManagement").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    await PostModel(customer);
    customerTableMock.push(customer);
    return [200, { customer: customer }];
  });

  mock.onPost("api/PoBillingManagement/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);

    const filter = queryParams.filter;
    /* var model = await GetModel(filter.customerIdApi); */

    var model = [
      {
        ReceivedDate: null,
        CustomerName: "Meijer, Inc.",
        CustomerCode: "MEIMI",
        AdjustedPieces: 0,
        id: 399940,
        MiffId: null,
        Location: null,
        PurchaseOrder: "3673861",
        Sku: "01516",
        Pieces: 1,
        UnitOfMeasureId: 10,
        UnitOfMeasure: "Each",
        UnitCount: 225,
        Description: "PREMIUM RABBIT HUTCH-LG",
        Weight: 4612.0,
        WeightType: "KGS",
        Cube: 28.0,
        CubeType: "CBM",
        Pallets: null,
        OverQuantity: 0,
        ShortQuantity: 0,
        DamagedQuantity: 0,
        AstrayQuantity: null,
        Shipped: 225,
        ReceiptId: 636659,
        IsHot: false,
        IsHazardousMateriel: false,
        UnNumber: null,
        PackagingGroup: null,
        HazardClass: null,
        PieceDescription: null,
        EmergencyContactNumber: null,
        LBSWeight: null,
        CFTCube: null,
        Upc: "791611015162",
        BuyerId: null,
        Department: null,
        PoExpectedDate: null,
        ThirdPartyQuantity: null,
        ThirdPartyName: null,
        ReferenceNumber: null,
        PoEta: null,
        Size: null,
        Color: null,
        StatusId: null,
        ReceivedQuantity: 0,
        ProcDate: null,
        ProcessedDate: null,
        QuarantinedQuantity: null,
        DestinationId: null,
        Destination: "",
        Created: "06-30-2021, 4:51 PM",
      },
      {
        ReceivedDate: null,
        CustomerName: "Meijer, Inc.",
        CustomerCode: "MEIMI",
        AdjustedPieces: 0,
        id: 399941,
        MiffId: null,
        Location: null,
        PurchaseOrder: "3673861",
        Sku: "01556",
        Pieces: 1,
        UnitOfMeasureId: 10,
        UnitOfMeasure: "Each",
        UnitCount: 80,
        Description: "MEDIUM RABBIT HUTCH 36",
        Weight: 1360.0,
        WeightType: "KGS",
        Cube: 7.54,
        CubeType: "CBM",
        Pallets: null,
        OverQuantity: 0,
        ShortQuantity: 0,
        DamagedQuantity: 0,
        AstrayQuantity: null,
        Shipped: 80,
        ReceiptId: 636659,
        IsHot: false,
        IsHazardousMateriel: false,
        UnNumber: null,
        PackagingGroup: null,
        HazardClass: null,
        PieceDescription: null,
        EmergencyContactNumber: null,
        LBSWeight: null,
        CFTCube: null,
        Upc: "791611015568",
        BuyerId: null,
        Department: null,
        PoExpectedDate: null,
        ThirdPartyQuantity: null,
        ThirdPartyName: null,
        ReferenceNumber: null,
        PoEta: null,
        Size: null,
        Color: null,
        StatusId: null,
        ReceivedQuantity: 0,
        ProcDate: null,
        ProcessedDate: null,
        QuarantinedQuantity: null,
        DestinationId: null,
        Destination: "",
        Created: "06-30-2021, 4:51 PM",
      },
      {
        ReceivedDate: null,
        CustomerName: "Meijer, Inc.",
        CustomerCode: "MEIMI",
        AdjustedPieces: 0,
        id: 399942,
        MiffId: null,
        Location: null,
        PurchaseOrder: "3673861",
        Sku: "14064",
        Pieces: 1,
        UnitOfMeasureId: 10,
        UnitOfMeasure: "Each",
        UnitCount: 100,
        Description: "WARE MFG. LITTLE RED HEN BARN",
        Weight: 3300.0,
        WeightType: "KGS",
        Cube: 22.3,
        CubeType: "CBM",
        Pallets: null,
        OverQuantity: 0,
        ShortQuantity: 0,
        DamagedQuantity: 0,
        AstrayQuantity: null,
        Shipped: 100,
        ReceiptId: 636659,
        IsHot: false,
        IsHazardousMateriel: false,
        UnNumber: null,
        PackagingGroup: null,
        HazardClass: null,
        PieceDescription: null,
        EmergencyContactNumber: null,
        LBSWeight: null,
        CFTCube: null,
        Upc: "791611140642",
        BuyerId: null,
        Department: null,
        PoExpectedDate: null,
        ThirdPartyQuantity: null,
        ThirdPartyName: null,
        ReferenceNumber: null,
        PoEta: null,
        Size: null,
        Color: null,
        StatusId: null,
        ReceivedQuantity: 0,
        ProcDate: null,
        ProcessedDate: null,
        QuarantinedQuantity: null,
        DestinationId: null,
        Destination: "",
        Created: "06-30-2021, 4:51 PM",
      },
    ];

    delete queryParams.filter.customerIdApi;
    customerTableMock = window.Status === 84 ? [] : model;

    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    filterdCustomers.statuses = [
      { Id: 81, Name: "Ready for Approval", Count: 20 },
      { Id: 82, Name: "Approved for Invoicing", Count: 1 },
      { Id: 84, Name: "Billed", Count: 0 },
    ] /* model.statuses */;
    return [200, filterdCustomers];
  });

  mock
    .onPost("api/PoBillingManagement/deleteCustomers")
    .reply(async (config) => {
      const { ids } = JSON.parse(config.data);
      ids.forEach((id) => {
        const index = customerTableMock.findIndex((el) => el.id === id);
        if (index > -1) {
          customerTableMock.splice(index, 1);
        }
      });
      return [200];
    });

  mock
    .onPost("api/PoBillingManagement/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/PoBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PoBillingManagement\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/PoBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PoBillingManagement\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/PoBillingManagement\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/PoBillingManagement\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
