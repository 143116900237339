/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt */
import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  headerSortingClasses, sortCaret
} from "../../../_helpers";
export function ItemTablesWidget({ className }) {
  const ExceptionColums = [
    {
      dataField: "items",
      text: "items",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "DateException",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "description",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  const ExceptionsData = [
    {
      id: 1,
      items: "72642",
      DateException: "9/04/2021",
      description: "Missing 4 Cases",
    },
  ];
  return (
    <>
      
        <div className={`card card-custom ${className}`}>
          {/* Head */}
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Missing Box
              </span>
              <span className="text-muted mt-3 font-weight-bold font-size-sm">
                4 PO's 0 Total Items
              </span>
            </h3>
          </div>
          {/* Body */}
          <div className="card-body pt-0 pb-3">
            <BootstrapTable
              id="id"
              keyField="id"
              data={ExceptionsData}
              columns={ExceptionColums}
              pagination={paginationFactory()}
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              remote
              bordered={false}
            />
          </div>
        </div>
    </>
  );
}
