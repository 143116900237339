import { IconButton, LinearProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { currencyFormat, ReturnEmptyOrString } from "../../../../../_metronic/_helpers/ATHelpers";
import { Notification } from "../../../Notification";
import $ from "jquery";
const adapter = new LocalStorage("db");
const db = low(adapter);
//************************ FUNCTIONS *************************************/
   function CalculateAmount({
    Rate = 0,
    Quantity = 0,
    rateType,
    MinCharge = 0,
    MaxCharge = 0,
    FreeTime = 0,
  }) {
    let amount = 0;
    switch (rateType) {
      case "Days":{ 
        if (FreeTime > 0) {
         amount = Quantity < FreeTime ? 0 : (Quantity - FreeTime) * Rate;
       } else {
         amount = Rate * Quantity;
       }
       break;
      }
      case "Hours": { 
          if (FreeTime > 0) {
           amount = Quantity < FreeTime ? 0 : (Quantity - FreeTime) * Rate;
         } else {
           amount = Rate * Quantity;
         }
         break;
        }
      case "Weight":
        amount = Rate * Quantity;
        break;
      case "Volume":
        amount = Rate * Quantity;
        break;
      case "Quantity":
        amount = Rate * Quantity;
        break;
      case "Distance":
        amount = Rate * Quantity;
        break;
      case "Revenue":
        amount = Rate * Quantity;
        break;
      case "Cost":
        amount = Rate * Quantity;
        break;
      case " Dsp Time":
        amount = Rate * Quantity;
        break;
      default:
        amount = Rate * Quantity;
        break;
    }
    if (MinCharge > 0 && amount < MinCharge && Quantity > 0) amount = MinCharge;
    if (MaxCharge > 0 && amount > MaxCharge) amount = MaxCharge;
    return amount;
  }
//*************************************************************/
export function BillToRating({
  objectTms,
  setCharges,
  charges = []
}) {
  const catchError = [];
  // const [Charges, setCharges] = useState(catchError);
  const [ChargesModal, setChargesAdded] = useState(catchError);
  const [noteModal, setNoteModal] = useState({ Show: false });
  const [modalCharges, setModalCharges] = useState(false);
  const [loadingCharges, setLoaderCharges] = useState(false);
  const [shipmentDetail, setShipmentDetail] = useState(null);
  const [totalRates, setTotalRates] = useState(0.00);

 //************************ USE EFFECT *************************************/
  useEffect(() => {
    try {
      setShipmentDetail(objectTms);
      if(objectTms?.Id !== undefined){
        if (objectTms?.Charges?.length > 0) {
          const currentCharges = objectTms?.Charges;
          currentCharges?.length > 0 &&
            currentCharges.map((x) => {
              x.TotalRate = CalculateAmount({
                Rate: x.Rate,
                Quantity: x.Quantity,
                rateType: x.RateType,
                MinCharge: x.MinCharge,
                MaxCharge: x.MaxCharge,
                FreeTime: x.FreeTime,
              });
            });
          setCharges(currentCharges);
        } else {
          if(objectTms?.IsReloadCharges){
            const result = GetCalculated();
            try {
              if (
                charges.every(
                  (x) =>
                    x.Quantity === 0 ||
                    x?.Quantity === undefined ||
                    x?.Quantity === "0"
                )
              ) {
                setCharges(result);
              } else {
                let values = [...charges];
                values = values?.filter(
                  (x) =>
                    x.Quantity !== 0 ||
                    x?.Quantity === "0" ||
                    x?.Quantity === undefined
                );
                setCharges(values);
              }
            } catch (err) {}  
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  },[objectTms]);
  useEffect(() => {
    let total = 0;
    charges?.length > 0 &&
      charges.map((x) => {
        total += x?.TotalRate ?? 0;
      });
    setTotalRates(currencyFormat(total));
  }, [charges, ChargesModal]);

  useEffect(() => {
    if (!Array.isArray(charges)) setCharges([]);
  }, [charges]);  
  useEffect(() => {
    if (ChargesModal?.length === 0) setModalCharges(false);
  }, [ChargesModal]);
  //*************************************************************/
  //************************API*************************************/
  const paramsAddresses = `&fromCity=${shipmentDetail?.ShipperCity}&fromAddress=${shipmentDetail?.ShipperAddress}&fromState=${shipmentDetail?.ShipperState}&fromZipCode=${shipmentDetail?.ShipperZipCode}&toCity=${shipmentDetail?.ConsigneeCity}&toAddress=${shipmentDetail?.ConsigneeAddress}&toState=${shipmentDetail?.ConsigneeState}&toZipCode=${shipmentDetail?.ConsigneeZipCode}`;
  async function GetCalculated() {
    return await fetch(
      `${window.$apiurl}/ordersRating/GetCalculatedCharges?customerId=${shipmentDetail?.CustomerId}&serviceId=${shipmentDetail?.ServiceId}&serviceLevelId=&ModeId=${shipmentDetail?.ModeId}&isInbound=false&isPayable=false&carrierId=&equipmentTypeId=&equipmentSizeId=${paramsAddresses}&date=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        return result;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetCharges() {
    return await fetch(
      `${window.$apiurl}/ordersRating/GetCharges?customerId=${shipmentDetail?.CustomerId}&serviceId=${shipmentDetail?.ServiceId}&serviceLevelId=&ModeId=${shipmentDetail?.ModeId}&isInbound=false&isPayable=false&carrierId=&equipmentTypeId=&equipmentSizeId=${paramsAddresses}&date=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        return result;
      })
      .catch((error) => {
        console.error(error);
      });
  }
//*************************************************************/

  return (
    <div className="row RatesParent">
      {loadingCharges && (
        <div className="col-lg-12">
          <LinearProgress />
        </div>
      )}
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                <th>Code</th>
                <th>Name</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Note</th>
                <th>Remove</th>
              </tr>
            </thead>

            <tbody className="border-0">
              {charges?.length > 0 &&
                charges.map((x, index) => (
                  <>
                    {x?.Invoice !== undefined && x.Invoice !== "" && (
                      <>
                        <tr className="mt-5">
                          <td className={`border-0`}>
                            <b className="pr-3">Invoice :</b>
                            <b>
                              <span className="rounded-pill bg-dark text-white px-2 py-1 ml-1 Charges">
                                {x?.Invoice ?? "Empty"}
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr
                      className={`${
                        x?.Invoice !== "" ? "border-left" : " "
                      } border-light-dark`}
                    >
                      <td className={`border-0`}>
                        <div className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-md-6 my-5 justify-content-start">
                          {x?.Code}
                        </div>
                      </td>
                      <td className={`border-0`}>
                        <div className="label label-lg label-light-info label-inline border-0 w-100 font-weight-bold text-center p-md-6 my-5 justify-content-start">
                          {x?.Name}
                        </div>
                      </td>
                      <td className={`border-0 Rate`}>
                        {x?.IsVariable}
                        <input
                          className={`form-control ${
                            !x?.IsEdited ? "bg-light" : ""
                          } ${x?.IsVariable ? "border-primary" : ""}`}
                          value={x?.Rate}
                          readOnly={!x?.IsEdited}
                          onDoubleClick={(e) => {
                            try {
                              if (x?.IsVariable) {
                                e.target.readOnly = false;
                                let values = [...charges];
                                values[index].IsEdited = true;
                                setCharges(values);
                              }
                            } catch (err) {}
                          }}
                          onChange={(e) => {
                            try {
                              const value = e.target.value;
                              let values = JSON.parse(JSON.stringify(charges));
                              if (index > -1) {
                                values[index].Rate = value;
                                values[index].TotalRate = CalculateAmount({
                                  Rate: value,
                                  Quantity: x?.Quantity,
                                  rateType: x?.RateType,
                                  MinCharge: x?.MinCharge,
                                  MaxCharge: x?.MaxCharge,
                                  FreeTime: x?.FreeTime,
                                });
                                setCharges(values);
                              }
                            } catch (err) {}
                          }}
                        />
                      </td>
                      <td className={`border-0`}>
                        <input
                          type="number"
                          className={`form-control ${(x?.Quantity ===
                            undefined ||
                            x?.Quantity === 0 ||
                            x?.Quantity === "0") &&
                            `border ${
                              (objectTms?.ModeName === "OTR (Over The Road)" ||
                              objectTms?.ModeName === "Intermodal Rail (COFC)")
                                ? " border-danger"
                                : ""
                            }`}`}
                          disabled={
                            x?.IsReadOnly || objectTms?.ModeName === "LTL"
                          }
                          value={x?.Quantity ?? 0}
                          onChange={(e) => {
                            const value = e.target.value;
                            var values = JSON.parse(JSON.stringify(charges));
                            if (index > -1) {
                              values[index].Quantity = value;
                              const total = CalculateAmount({
                                Rate: x?.Rate,
                                Quantity: value,
                                rateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                              });
                              values[index].TotalRate = total;
                              setCharges(values);
                            }
                          }}
                        />
                      </td>
                      <td className={`border-0`}>
                        <input
                          className="form-control"
                          disabled={true}
                          value={
                            x?.TotalRate ? currencyFormat(x?.TotalRate) : 0
                          }
                        />
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          className="btn btn-light-primary w-100"
                          onClick={() => {
                            setNoteModal({
                              Show: true,
                              Id: x?.ChargeId,
                              Index: index,
                              Code: x?.Code,
                              Note: x?.Note,
                              index: index,
                            });
                          }}
                        >
                          <i
                            className={`${
                              x?.Note ? "fas" : "far"
                            } fa-sticky-note fa-sm`}
                          ></i>
                        </button>
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          className="btn btn-light-danger w-100"
                          disabled={x?.IsReadOnly}
                          onClick={() => {
                            try {
                              let chargesList = [...charges];
                              let chargesModalList = [...ChargesModal];
                              chargesList.splice(index, 1);
                              // if (x?.OriAddSup !== 2)
                              chargesModalList.push({
                                Id: x?.ChargeId,
                                ChargeId: x?.Id,
                                Rate: x?.Rate,
                                Name: x?.Name,
                                Code: x?.Code,
                                Quantity: 1,
                                RateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                                IsVariable: x?.IsVariable || false,
                                Invoice: x?.Invoice,
                                InvoiceId: x?.InvoiceId,
                                OriAddSup: x?.OriAddSup,
                                IsReadOnly: x?.IsReadOnly || false,
                              });
                              setTimeout(() => {
                                setCharges(chargesList);
                                setChargesAdded(chargesModalList);
                              }, 1);
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                        >
                          <i className={`fas fa-times fa-sm`}></i>
                        </button>
                      </td>
                    </tr>
                    {(x?.FreeTime || x?.MinCharge || x?.MaxCharge) && (
                      <tr
                        className={`${
                          x?.Invoice !== "" ? "border-left " : ""
                        } border-light-dark`}
                      >
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className="row mb-0 border-0 place-content-center py-0">
                          {(x?.FreeTime || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-primary text-center btn-sm p-2"
                            >
                              FT : {ReturnEmptyOrString(x?.FreeTime)}
                            </button>
                          )}
                          {(x?.MinCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-info text-center mx-2 btn-sm p-2"
                            >
                              Min : {ReturnEmptyOrString(x?.MinCharge)}
                            </button>
                          )}
                          {(x?.MaxCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-warning text-center btn-sm p-2"
                            >
                              Max : {ReturnEmptyOrString(x?.MaxCharge)}
                            </button>
                          )}
                        </td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
            <tbody className="border-0">
              {charges?.length > 0 && (
                <tr>
                  <td className="text-right border-0" colSpan="4">
                    <b
                      className="label label-light-info label-inline mr-2 p-4"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      Total :
                    </b>
                  </td>
                  <td className={`border-0 RateAllTotal`}>
                    <span
                      className="label label-light-primary label-inline mr-2 p-4"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {totalRates}
                    </span>
                  </td>
                  <td className="border-0"></td>
                </tr>
              )}
              {(objectTms?.ModeName === "OTR (Over The Road)" ||
                objectTms?.ModeName === "Intermodal Rail (COFC)") && (
                  <tr>
                    <td
                      colSpan="12"
                      className="border-0"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        className="btn btn-light-success w-40"
                        onClick={async () => {
                          try {
                            setLoaderCharges(true);
                            const result = await GetCharges();
                            if (result.length > 0) {
                              var tempList = result;
                              console.log(charges);
                              const listChargesModal = Array.isArray(charges)
                                ? [...charges]
                                : [];
                              listChargesModal !== undefined &&
                                listChargesModal?.length > 0 &&
                                listChargesModal.map((item) => {
                                  tempList = tempList?.filter(
                                    (y) => y?.Id !== item?.ChargeId
                                  );
                                });
                              if (tempList?.length > 0) {
                                setChargesAdded(tempList);
                                setModalCharges(true);
                              }
                            } else {
                              Notification(
                                "info",
                                "There is no charges to add"
                              );
                            }
                            setLoaderCharges(false);
                          } catch (err) {
                            console.log(err);
                            setLoaderCharges(false);
                          }
                        }}
                      >
                        <i className="fas fa-plus fa-sm"></i> Add Charges
                      </button>
                      <button
                        className="btn btn-light-info w-40 ml-5"
                        onClick={async () => {
                          try {
                            setLoaderCharges(true);
                            const result = await GetCalculated();
                            if (result?.length > 0) {
                              if (charges?.length === 0) {
                                result.map((item) => (item.ChargeId = item.Id));
                                setCharges(result);
                              } else {
                                let calculatedCharges = charges;
                                result.map((item, index) => {
                                  const currentCharge = charges.find(
                                    (x) => x.ChargeId === item.Id
                                  );
                                  if (
                                    currentCharge === null ||
                                    currentCharge === undefined
                                  ) {
                                    item.ChargeId = item.Id;
                                    calculatedCharges.push(item);
                                  }
                                  // else{
                                  //   if (
                                  //     currentCharge.Quantity !== 0 ||
                                  //     currentCharge.Quantity !== undefined ||
                                  //     currentCharge.Quantity !== null
                                  //   ) {
                                  //   }
                                  // }
                                });
                                setCharges(calculatedCharges);
                              }
                            }
                          } catch (err) {
                            console.log(err);
                            setLoaderCharges(false);
                          }
                          setLoaderCharges(false);
                        }}
                      >
                        <i class="fas fa-square-root-alt"></i> Calculated
                      </button>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal Add Rate */}
      <Modal
        id="modalCharges"
        show={modalCharges}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ marginRight: "18rem" }}
          >
            Customer Charges
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              try {
                setModalCharges(false);
              } catch (err) {}
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row bg-light">
            <div className={`col-lg-6 mt-5 mb-5`}>Name</div>
            <div className={`col-lg-3 mt-5 mb-5`}>Rate</div>
            <div className={`col-lg-3 mt-5 mb-5`}>Add</div>
          </div>
          {ChargesModal &&
            ChargesModal.length > 0 &&
            ChargesModal.map((x, index) => (
              <div className="row">
                <div className={`col-lg-6 mt-5 mb-5`}>
                  <b className={`text-black title mr-2`}>{x?.Name}</b>
                </div>
                <div className={`col-lg-3 mt-5 mb-5`}>
                  <span
                    className="label label-light-primary label-inline mr-2 p-4"
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  >
                    {x?.Rate}
                  </span>
                </div>
                <div className={`col-lg-3 mt-2`}>
                  <button
                    id="addChargeBtn"
                    type="button"
                    class="btn btn-light-success w-100"
                    onClick={() => {
                      try {
                        let valuesCharges = [...charges];
                        let values = [...ChargesModal];
                        let b;
                        let c;
                        b = values?.find((y) => y?.Id === x?.Id);
                        c = values?.filter((y) => y?.Id !== x?.Id);
                        if (b) {
                          var color = 0;
                          valuesCharges.push({
                            Id: b?.Id,
                            ChargeId: b?.Id,
                            Rate: b?.Rate,
                            Name: b?.Name,
                            Code: b?.Code,
                            Quantity: 0,
                            RateType: b?.RateType,
                            MinCharge: b?.MinCharge,
                            MaxCharge: b?.MaxCharge,
                            FreeTime: b?.FreeTime,
                            IsVariable: x?.IsVariable || false,
                            Invoice: "",
                            OriAddSup: color,
                            IsReadOnly: false,
                          });
                        }
                        //fetchDataList
                        setCharges(valuesCharges);
                        setChargesAdded(c);
                      } catch (err) {}
                    }}
                  >
                    <i className={`fas fa-plus fa-sm`}></i>
                  </button>
                </div>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                try {
                  setModalCharges(false);
                } catch (err) {}
              }}
              className="btn btn-light btn-elevate"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Modal Notes */}
      <Modal
        show={noteModal?.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Note : {noteModal?.Code}
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => setNoteModal({ Show: false })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">
          <textarea
            id="NoteCharges"
            className="form-control"
            rows="3"
            defaultValue={noteModal?.Note}
            onChange={(e) => {
              try {
                let values = { ...noteModal };
                values.Note = e.target.value;
                setNoteModal(values);
              } catch (err) {}
            }}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setNoteModal({ Show: false })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                try {
                  let values = [...charges];
                  values[noteModal?.Index].Note = noteModal?.Note;
                  setCharges(values);
                  setNoteModal({ Show: false });
                } catch (err) {}
              }}
              className="btn btn-primary btn-elevate ml-2"
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export function VendorRating({
  objectTms,
  setVendorsPayables,
  vendorPayables = []
}) {
  const catchError = [];
  const [ChargesModal, setChargesAdded] = useState(catchError);
  const [shipmentDetail, setShipmentDetail] = useState(null);
  const [noteModal, setNoteModal] = useState(false);
  const [modalCharges, setModalCharges] = useState(false);
  const [totalRates, setTotalRates] = useState(0);
  const [loadingCharges, setLoaderCharges] = useState(false);
  //************************ USE EFFECT *************************************/
  useEffect(() => {
    try {
      if (objectTms?.Id !== undefined) {
        setShipmentDetail(objectTms);
        console.log("🚀 ~ file: Charges.js ~ line 747 ~ useEffect ~ objectTms", objectTms)
        const currentCharges = objectTms?.Vendors[0]?.Payables;
        if (currentCharges !== undefined && currentCharges?.length > 0) {
          currentCharges?.length > 0 &&
            currentCharges.forEach((x) => {
              x.TotalRate = CalculateAmount({
                Rate: x.Rate,
                Quantity: x.Quantity,
                rateType: x.RateType,
                MinCharge: x.MinCharge,
                MaxCharge: x.MaxCharge,
                FreeTime: x.FreeTime,
              });
            });
          setVendorsPayables(currentCharges);
        } else {
          if (objectTms?.IsReloadPayables) {
            const result = GetCalculated();
            try {
              if (
                vendorPayables.every(
                  (x) =>
                    x.Quantity === 0 ||
                    x?.Quantity === undefined ||
                    x?.Quantity === "0"
                )
              ) {
                setVendorsPayables(result);
              } else {
                let values = [...vendorPayables];
                values = values?.filter(
                  (x) =>
                    x.Quantity !== 0 ||
                    x?.Quantity === "0" ||
                    x?.Quantity === undefined
                );
                setVendorsPayables(values);
              }
            } catch (err) {}
            setVendorsPayables(result);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [objectTms]);
  useEffect(() => {
    if (!Array.isArray(vendorPayables)) setVendorsPayables([]);
  }, [vendorPayables]);
  useEffect(() => {
    if (objectTms?.Vendors === undefined || objectTms?.Vendors?.length === 0){ 
      setVendorsPayables([]);
    }
  }, [objectTms?.Vendors]);
  useEffect(() => {
    if (ChargesModal?.length === 0) setModalCharges(false);
  }, [ChargesModal]);
  useEffect(() => {
    try {
      let total = 0;
      vendorPayables !== undefined &&
        vendorPayables.length > 0 &&
        vendorPayables.map((x) => {
          total += x?.TotalRate ?? 0;
        });
      setTotalRates(currencyFormat(total));
    } catch (err) {
      console.log(err);
    }
  }, [vendorPayables, ChargesModal]);

  //*************************************************************/
  //************************API*************************************/
  const paramsAddresses = `&fromCity=${shipmentDetail?.ShipperCity}&fromAddress=${shipmentDetail?.ShipperAddress}&fromState=${shipmentDetail?.ShipperState}&fromZipCode=${shipmentDetail?.ShipperZipCode}&toCity=${shipmentDetail?.ConsigneeCity}&toAddress=${shipmentDetail?.ConsigneeAddress}&toState=${shipmentDetail?.ConsigneeState}&toZipCode=${shipmentDetail?.ConsigneeZipCode}`;
  async function GetCalculated() {
    return await fetch(
      `${window.$apiurl}/ordersRating/GetCalculatedCharges?customerId=${shipmentDetail?.CustomerId}&serviceId=${shipmentDetail?.ServiceId}&serviceLevelId=&ModeId=${shipmentDetail?.ModeId}&isInbound=false&isPayable=true&carrierId=${shipmentDetail?.Vendors[0]?.CarrierId ?? null}&equipmentTypeId=&equipmentSizeId=${paramsAddresses}&date=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        return result;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetCharges() {
    return await fetch(
      `${window.$apiurl}/ordersRating/GetCharges?customerId=${shipmentDetail?.CustomerId}&serviceId=${shipmentDetail?.ServiceId}&serviceLevelId=&ModeId=${shipmentDetail?.ModeId}&isInbound=false&isPayable=true&carrierId=${shipmentDetail?.Vendors[0]?.CarrierId ?? null}&equipmentTypeId=&equipmentSizeId=${paramsAddresses}&date=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        return result;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //*************************************************************/

  return (
    <div className="row RatesParent">
      {loadingCharges && (
        <div className="col-lg-12">
          <LinearProgress />
        </div>
      )}
      <div className="col-md-12">
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">
                <th>Code</th>
                <th>Name</th>
                <th>Rate</th>
                <th>Quantity</th>
                <th>Total</th>
                <th>Note</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {vendorPayables?.length > 0 &&
                vendorPayables.map((x, index) => (
                  <>
                    {x?.Invoice !== undefined && x.Invoice !== "" && (
                      <>
                        <tr className="mt-5">
                          <td className={`border-0`}>
                            <b className="pr-3">Invoice :</b>
                            <b>
                              <span className="rounded-pill bg-dark text-white px-2 py-1 ml-1 Charges">
                                {x?.Invoice ?? "Empty"}
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr
                      className={`${
                        x?.Invoice !== "" ? "border-left" : " "
                      } border-light-dark`}
                    >
                      <td className={`border-0`}>
                        <div className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-md-6 my-5 justify-content-start">
                          {x?.Code}
                        </div>
                      </td>
                      <td className={`border-0`}>
                        <div className="label label-lg label-light-info label-inline border-0 w-100 font-weight-bold text-center p-md-6 my-5 justify-content-start">
                          {x?.Name}
                        </div>
                      </td>
                      <td className={`border-0 Rate`}>
                        {x?.IsVariable}
                        <input
                          className={`form-control ${
                            !x?.IsEdited ? "bg-light" : ""
                          } ${x?.IsVariable ? "border-primary" : ""}`}
                          value={x?.Rate}
                          disabled={objectTms?.ModeName === "LTL"}
                          readOnly={!x?.IsEdited}
                          onDoubleClick={(e) => {
                            try {
                              if (x?.IsVariable) {
                                e.target.readOnly = false;
                                let values = [...vendorPayables];
                                values[index].IsEdited = true;
                                setVendorsPayables(values);
                              }
                            } catch (err) {}
                          }}
                          onChange={(e) => {
                            try {
                              let values = JSON.parse(
                                JSON.stringify(vendorPayables)
                              );
                              if (index > -1) {
                                values[index].Rate = e.target.value;
                                values[index].TotalRate = CalculateAmount({
                                  Rate: e.target.value,
                                  Quantity: x?.Quantity,
                                  rateType: x?.RateType,
                                  MinCharge: x?.MinCharge,
                                  MaxCharge: x?.MaxCharge,
                                  FreeTime: x?.FreeTime,
                                });
                                setVendorsPayables(values);
                              }
                            } catch (err) {}
                          }}
                        />
                      </td>
                      <td className={`border-0`}>
                        <input
                          type="number"
                          className={`form-control ${(x?.Quantity ===
                            undefined ||
                            x?.Quantity === 0 ||
                            x?.Quantity === "0") &&
                            `border ${
                              (objectTms?.ModeName === "OTR (Over The Road)" ||
                              objectTms?.ModeName === "Intermodal Rail (COFC)")
                                ? " border-danger"
                                : ""
                            }`}`}
                          disabled={
                            x?.IsReadOnly || objectTms?.ModeName === "LTL"
                          }
                          value={x?.Quantity ?? 0}
                          onChange={(e) => {
                            var values = JSON.parse(
                              JSON.stringify(vendorPayables)
                            );
                            if (index > -1) {
                              values[index].Quantity = e.target.value;
                              const total = CalculateAmount({
                                Rate: x?.Rate,
                                Quantity: e.target.value,
                                rateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                              });
                              values[index].TotalRate = total;
                              setVendorsPayables(values);
                            }
                          }}
                        />
                      </td>
                      <td className={`border-0`}>
                        <input
                          className="form-control"
                          disabled={true}
                          value={
                            x?.TotalRate ? currencyFormat(x?.TotalRate) : 0
                          }
                        />
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          className="btn btn-light-primary w-100"
                          onClick={() => {
                            setNoteModal({
                              Show: true,
                              Id: x?.ChargeId,
                              Index: index,
                              Code: x?.Code,
                              Note: x?.Note,
                              index: index,
                            });
                          }}
                        >
                          <i
                            className={`${
                              x?.Note ? "fas" : "far"
                            } fa-sticky-note fa-sm`}
                          ></i>
                        </button>
                      </td>
                      <td className={`border-0`}>
                        <button
                          type="button"
                          className="btn btn-light-danger w-100"
                          disabled={x?.IsReadOnly}
                          onClick={() => {
                            try {
                              let a = [...vendorPayables];
                              let b = [...ChargesModal];
                              a = a?.filter((y) => y !== x);
                              if (x?.OriAddSup !== 2)
                                b.push({
                                  Id: x?.ChargeId,
                                  Rate: x?.Rate,
                                  Name: x?.Name,
                                  Code: x?.Code,
                                  Quantity: 1,
                                  RateType: x?.RateType,
                                  MinCharge: x?.MinCharge,
                                  MaxCharge: x?.MaxCharge,
                                  FreeTime: x?.FreeTime,
                                  IsVariable: x?.IsVariable || false,
                                  Invoice: x?.Invoice,
                                  InvoiceId: x?.InvoiceId,
                                  OriAddSup: x?.OriAddSup,
                                  IsReadOnly: x?.IsReadOnly || false,
                                });
                              setVendorsPayables(a);
                              setChargesAdded(b);
                            } catch (err) {
                              console.log(err);
                            }
                          }}
                        >
                          <i className={`fas fa-times fa-sm`}></i>
                        </button>
                      </td>
                    </tr>
                    {(x?.FreeTime || x?.MinCharge || x?.MaxCharge) && (
                      <tr
                        className={`${
                          x?.Invoice !== "" ? "border-left " : ""
                        } border-light-dark`}
                      >
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className="row mb-0 border-0 place-content-center py-0">
                          {(x?.FreeTime || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-primary text-center btn-sm p-2"
                            >
                              FT : {ReturnEmptyOrString(x?.FreeTime)}
                            </button>
                          )}
                          {(x?.MinCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-info text-center mx-2 btn-sm p-2"
                            >
                              Min : {ReturnEmptyOrString(x?.MinCharge)}
                            </button>
                          )}
                          {(x?.MaxCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-warning text-center btn-sm p-2"
                            >
                              Max : {ReturnEmptyOrString(x?.MaxCharge)}
                            </button>
                          )}
                        </td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                      </tr>
                    )}
                  </>
                ))}
            </tbody>
            <tbody className="border-0">
              {vendorPayables?.length > 0 && (
                <tr>
                  <td className="text-right border-0" colSpan="4">
                    <b
                      className="label label-light-info label-inline mr-2 p-4"
                      style={{ fontSize: "13px", fontWeight: "700" }}
                    >
                      Total :
                    </b>
                  </td>
                  <td className={`border-0 RateAllTotal`}>
                    <span
                      className="label label-light-primary label-inline mr-2 p-4"
                      style={{ fontSize: "12px", fontWeight: "600" }}
                    >
                      {totalRates}
                    </span>
                  </td>
                  <td className="border-0"></td>
                </tr>
              )}
              {(objectTms?.ModeName === "OTR (Over The Road)" || 
                objectTms?.ModeName === "Intermodal Rail (COFC)") && (
                <tr>
                  <td
                    colSpan="12"
                    className="border-0"
                    style={{ textAlign: "right" }}
                  >
                    <button
                      className="btn btn-light-success w-40"
                      onClick={async () => {
                        try {
                          if (objectTms?.Vendors?.length > 0) {
                            setLoaderCharges(true);
                            const result = await GetCharges();
                            if (result.length > 0) {
                              var tempList = result;
                              const listChargesModal = Array.isArray(
                                vendorPayables
                              )
                                ? [...vendorPayables]
                                : [];
                              listChargesModal.map((item) => {
                                tempList = tempList?.filter(
                                  (y) => y?.Id !== item?.ChargeId
                                );
                              });
                              if (tempList?.length > 0) {
                                setChargesAdded(tempList);
                                setModalCharges(true);
                              }
                            } else {
                              Notification(
                                "info",
                                "There is no charges to add"
                              );
                            }
                            setLoaderCharges(false);
                          } else {
                            Notification(
                              "info",
                              "Please Select a Vendor First"
                            );
                            document
                              .getElementById("Mode")
                              .scrollIntoView({ behavior: "smooth" });
                          }
                        } catch (err) {
                          console.log(err);
                          setLoaderCharges(false);
                        }
                      }}
                    >
                      <i className="fas fa-plus fa-sm"></i> Add Charges
                    </button>
                    <button
                      className="btn btn-light-info w-40 ml-5"
                      onClick={async () => {
                        try {
                          if (objectTms?.Vendors?.length > 0) {
                            const result = await GetCalculated();
                            if (result?.length > 0) {
                              if (vendorPayables?.length === 0) {
                                result.map((item) => (item.ChargeId = item.Id));
                                setVendorsPayables(result);
                              } else {
                                let calculatedCharges = vendorPayables;
                                result.map((item, index) => {
                                  const currentCharge = vendorPayables.find(
                                    (x) => x.ChargeId === item.Id
                                  );
                                  if (
                                    currentCharge === null ||
                                    currentCharge === undefined
                                  ) {
                                    item.ChargeId = item.Id;
                                    calculatedCharges.push(item);
                                  }
                                });
                                setVendorsPayables(calculatedCharges);
                              }
                            }
                          } else {
                            Notification(
                              "info",
                              "Please Select a Vendor First"
                            );
                            document
                              .getElementById("Mode")
                              .scrollIntoView({ behavior: "smooth" });
                          }
                        } catch (err) {
                          console.log(err);
                          setLoaderCharges(false);
                        }
                        setLoaderCharges(false);
                      }}
                    >
                      <i class="fas fa-square-root-alt"></i> Calculated
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Modal Add Rate */}
      <Modal
        id="modalCharges"
        show={modalCharges}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            style={{ marginRight: "18rem" }}
          >
            Carrier Charges
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => {
              try {
                setModalCharges(false);
              } catch (err) {}
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row bg-light">
            <div className={`col-lg-6 mt-5 mb-5`}>Name</div>
            <div className={`col-lg-3 mt-5 mb-5`}>Rate</div>
            <div className={`col-lg-3 mt-5 mb-5`}>Add</div>
          </div>
          {ChargesModal &&
            ChargesModal.length > 0 &&
            ChargesModal.map((x, index) => (
              <div className="row">
                <div className={`col-lg-6 mt-5 mb-5`}>
                  <b className={`text-black title mr-2`}>{x?.Name}</b>
                </div>
                <div className={`col-lg-3 mt-5 mb-5`}>
                  <span
                    className="label label-light-primary label-inline mr-2 p-4"
                    style={{ fontSize: "12px", fontWeight: "600" }}
                  >
                    {x?.Rate}
                  </span>
                </div>
                <div className={`col-lg-3 mt-2`}>
                  <button
                    id="addChargeBtn"
                    type="button"
                    class="btn btn-light-success w-100"
                    onClick={() => {
                      try {
                        let valuesCharges = [...vendorPayables];
                        let values = [...ChargesModal];
                        let b;
                        let c;
                        b = values?.find((y) => y?.Id === x?.Id);
                        c = values?.filter((y) => y?.Id !== x?.Id);
                        if (b) {
                          var color = 0;
                          valuesCharges.push({
                            Id: b?.Id,
                            ChargeId: b?.Id,
                            Rate: b?.Rate,
                            Name: b?.Name,
                            Code: b?.Code,
                            Quantity: 0,
                            RateType: b?.RateType,
                            MinCharge: b?.MinCharge,
                            MaxCharge: b?.MaxCharge,
                            FreeTime: b?.FreeTime,
                            IsVariable: x?.IsVariable || false,
                            Invoice: "",
                            OriAddSup: color,
                            IsReadOnly: false,
                          });
                        }
                        //fetchDataList
                        setVendorsPayables(valuesCharges);
                        setChargesAdded(c);
                      } catch (err) {}
                    }}
                  >
                    <i className={`fas fa-plus fa-sm`}></i>
                  </button>
                </div>
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                try {
                  setModalCharges(false);
                } catch (err) {}
              }}
              className="btn btn-light btn-elevate"
            >
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Modal Notes */}
      <Modal
        show={noteModal?.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Note : {noteModal?.Code}
          </Modal.Title>
          <IconButton
            className="MuiButtonBase-root MuiIconButton-root jss179"
            aria-label="Close"
            onClick={() => setNoteModal({ Show: false })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">
          <textarea
            id="NoteCharges"
            className="form-control"
            rows="3"
            defaultValue={noteModal?.Note}
            onChange={(e) => {
              try {
                let values = { ...noteModal };
                values.Note = e.target.value;
                setNoteModal(values);
              } catch (err) {}
            }}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setNoteModal({ Show: false })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => {
                try {
                  let values = [...vendorPayables];
                  values[noteModal?.Index].Note = noteModal?.Note;
                  setVendorsPayables(values);
                  setNoteModal({ Show: false });
                } catch (err) {}
              }}
              className="btn btn-primary btn-elevate ml-2"
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}