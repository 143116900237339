import React, { useEffect, useState } from 'react'
import { Button, Form, Card } from "react-bootstrap";
import axios from "axios";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
    Input,
    Select
} from "../../../_metronic/_partials/controls";
import { Notification } from "../../modules/Notification";
import LinearProgress from "@material-ui/core/LinearProgress";


export function CreatePurchaseOrder() {
    const [customersList, setCustomersList] = useState([]);
    const purchaseOrderInitial = { CustomerId: "", PurchaseOrderNumber: "" };
    const purchaseOrderSchema = Yup.object().shape({
        CustomerId: Yup.string()
            .required("Customer is required"),
        PurchaseOrderNumber: Yup.string()
            .required("P.O Number is required")
    });

    useEffect(() => {
        fetchCustomersAsync();
    }, []);

    const fetchCustomersAsync = async () => {
        try {
            const result = await axios.get("api/purchaseorders/getcustomers");
            if (result.status === 200) {
                setCustomersList(result.data);
            }
        } catch (e) { }
    };
    const savePurchaseOrder = async (purchaseOrder) => {
        const result = await axios.post(`api/purchaseorders`, { purchaseOrder });
        if (result.data.success === true) {
            Notification("success", "Purchase order created successfully.");
            window.location = `/PurchaseOrders/${result.data.data.Id}/Edit`;
        }
        else {
            const msg = result.data.Message === undefined ? "Something went wrong, please try later or contact the support." : result.data.Message;
            Notification("error", msg);
        }
    };
    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={purchaseOrderInitial}
                    validationSchema={purchaseOrderSchema}
                    onSubmit={(values) => {
                        savePurchaseOrder(values);
                    }}>
                    {({ handleSubmit, setFieldValue }) => (
                        <>
                            <Form>
                                <Form.Row>
                                    <div className="col-lg-6">
                                        <Form.Label>Customer</Form.Label>
                                        <Select className="form-control" name="CustomerId" id="CustomerId" onChange={(event) => {
                                            setFieldValue("CustomerId", event.target.value)
                                        }}>
                                            <option component={Input} key="" value="" >Choose a customer</option>
                                            {customersList.map((item, index) => (
                                                <option key={index} value={item.id}>{item.CompanyName}</option>
                                            ))}
                                        </Select>
                                        {customersList.length === 0 && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className="col-lg-4">
                                        <Form.Label>P.O.#</Form.Label>
                                        <div className="input-group">
                                            <Field component={Input} type="text" name="PurchaseOrderNumber" placeholder="P.O #" />
                                        </div>
                                    </div>
                                    <div className="col-lg-2 mt-8">
                                        <Button as="input" type="submit" onClick={() => handleSubmit()} className="btn-success btn-shadow-hover mr-2" readOnly value="Save" />
                                    </div>
                                </Form.Row>
                            </Form>
                        </>
                    )}
                </Formik>
            </Card.Body>
        </Card>
    )
}