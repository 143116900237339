// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SaveIcon from "@material-ui/icons/Save";
import { Link } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { OneMoveMaster } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import {
  currencyFormat,
  Export,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import { Notification } from "../../../../Notification";
import { SendEmail } from "./SendEmail";
import { SendEmailfromGrid } from "./SendEmailfromGrid";
import { ChargesModel } from "../../Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
const adapter = new LocalStorage("db");
const db = low(adapter);
export function ActionsFormatter(props) {
  const SendEmailFunction = () => {
    const [showModalSend, setshowModalSend] = useState(false);
    return (
      <>
      {showModalSend && (
          <SendEmail
            row={props?.row}
            showModalSend={showModalSend}
            setshowModalSend={setshowModalSend}
          />
        )}
        <OverlayTrigger
          overlay={
            <Tooltip id="products-delete-tooltip">Send Documents</Tooltip>
          }
        >
          <a
            className="btn btn-icon btn-light btn-hover-primary btn-sm  ml-1"
            onClick={() => {
              setshowModalSend(true);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      </>
    );
  };
  const OneMoveMasterFunction = () => {
    const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);
    return (
      <>
        <Modal
          size="xl"
          show={OneMoveMasterShow}
          onHide={() => {
            setOneMoveMasterShow(false);
          }}
        >
          <OneMoveMaster
            Show={setOneMoveMasterShow}
            statSHipmentId={props?.row?.id}
          />
        </Modal>
      </>
    );
  };
  async function GetShipmentManifest(link) {
    return await fetch(window.$apiurl + link, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  let outboundUrl;
  switch (props?.row?.Mode) {
    case "LTL": {
      outboundUrl = "/LTL/Outbound/report/" + props?.row?.id;
      break;
    }
    case "Intermodal Rail (COFC)": {
      outboundUrl = "/TRC/Outbound/report/" + props?.row?.id;
      break;
    }
    case "OTR (Over The Road)": {
      outboundUrl = "/TRC/Outbound/report/" + props?.row?.id;
      break;
    }
    default:
      outboundUrl = "/DRAYAGE/Outbound/report/" + props?.row?.id;
  }
  return (
    <>
      <ChargesModel id={props?.row?.id} path={"shipments"} />
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">BOL</Tooltip>}
      >
        <a
          href={() => false}
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          onClick={() => {
            props.setRow(props?.row?.id);
            props.setBol(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Devices/profitloss.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">P & L</Tooltip>}
      >
        <a
          href={() => false}
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          role="button"
          onClick={() => {
            props.setRow(props?.row?.id);
            props.setPL(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Devices/profitloss.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
      {/* Export */}
      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">
            Export Shipment Manifest (XLSX)
          </Tooltip>
        }
      >
        <Link
          className="btn btn-icon btn-light btn-hover-success btn-sm ml-2"
          onClick={async () => {
            try {
              let data = [];
              const currentList = [];
              if (props?.row?.CustomerName !== "Lulus") {
                data = await GetShipmentManifest(
                  `/Shipments/OutboundManifest/${props?.row?.id}`
                );
                if (data.ShipItems.length > 0) {
                  data.ShipItems.map((item) => {
                    currentList.push({
                      PO: item.PurchaseOrder,
                      SKU: item.Sku,
                      Description: item.Description,
                      CasePack: item.CasePack,
                      ShipQTY: item.ShipQuantity,
                      ShipUOM: item.ShipUom,
                      Weight_LBS: currencyFormat(item.Weight),
                      Cube_CBM: currencyFormat(item.Cube),
                      ItemQty: item.Pieces,
                      Container: item.EquipmentRef,
                    });
                    return item;
                  });
                  Export(
                    currentList,
                    "excel",
                    `Shipment_Manifest#_${props?.row?.id}`
                  );
                } else {
                  Notification(
                    "warning",
                    `No Data Items on this Shipment# ${props?.row?.id}`
                  );
                }
              } else {
                data = await GetShipmentManifest(
                  `/pallets/GetPalletByShipment/${props?.row?.id}`
                );
                data.pallets?.length > 0 &&
                  data.pallets.map((itemX) => {
                    itemX.ItemsList.length > 0 &&
                      itemX.ItemsList.map((itemY) => {
                        itemY.PalletId = itemX.Id;
                        currentList.push(itemY);
                        return itemY;
                      });
                    return itemX;
                  });
                if (data.pallets?.length > 0) {
                  Export(
                    currentList,
                    "excel",
                    `Shipment_Manifest#_${props?.row?.id}`
                  );
                } else {
                  Notification(
                    "warning",
                    `No Data Items on this Shipment# ${props?.row?.id}`
                  );
                }
              }
            } catch (err) {
              console.log(err);
            }
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-success">
            <SaveIcon color="#1BC5BD" />
          </span>
        </Link>
      </OverlayTrigger>
      {/* Export */}
      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">
            Export Shipment Manifest (CSV)
          </Tooltip>
        }
      >
        <Link
          className="btn btn-icon btn-light btn-hover-info btn-sm ml-2"
          onClick={async () => {
            try {
              let data = [];
              const currentList = [];
              if (props?.row?.CustomerName !== "Lulus") {
                data = await GetShipmentManifest(
                  `/Shipments/OutboundManifest/${props?.row?.id}`
                );
                if (data.ShipItems.length > 0) {
                  data.ShipItems.forEach((item) => {
                    currentList.push({
                      PO: item.PurchaseOrder,
                      SKU: item.Sku,
                      Description: item.Description,
                      CasePack: item.CasePack,
                      ShipQTY: item.ShipQuantity,
                      ShipUOM: item.ShipUom,
                      Weight_LBS: currencyFormat(item.Weight),
                      Cube_CBM: currencyFormat(item.Cube),
                      ItemQty: item.Pieces,
                      Container: item.EquipmentRef,
                    });
                  });
                  Export(
                    currentList,
                    "csv",
                    `Shipment_Manifest#_${props?.row?.id}`
                  );
                } else {
                  Notification(
                    "warning",
                    `No Data Items on this Shipment# ${props?.row?.id}`
                  );
                }
              } else {
                data = await GetShipmentManifest(
                  `/pallets/GetPalletByShipment/${props?.row?.id}`
                );
                data.pallets?.length > 0 &&
                  data.pallets.forEach((itemX) => {
                    itemX.ItemsList.length > 0 &&
                      itemX.ItemsList.forEach((itemY) => {
                        itemY.PalletId = itemX.Id;
                        currentList.push(itemY);
                      });
                  });
                if (data.pallets?.length > 0) {
                  Export(
                    currentList,
                    "csv",
                    `Shipment_Manifest#_${props?.row?.id}`
                  );
                } else {
                  Notification(
                    "warning",
                    `No Data Items on this Shipment# ${props?.row?.id}`
                  );
                }
              }
            } catch (err) {
              console.log(err);
            }
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <SaveIcon color="#1BC5BD" />
          </span>
        </Link>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">Shipment Manifest</Tooltip>
        }
      >
        <a
          href={() => false}
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-2"
          onClick={() => {
            props.setRow(props?.row?.id);
            props.setOutboundManifest(true);
            localStorage.setItem(
              "SmManifestCustomer",
              JSON.stringify(props?.row?.CustomerName)
            );
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Incoming-box.svg"
              )}
            />
          </span>
        </a>
      </OverlayTrigger>
      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">Rate Confirmation</Tooltip>
        }
      >
        <a
          href={() => false}
          className="btn btn-icon btn-light btn-hover-primary btn-sm ml-1"
          onClick={() => {
            props.setRow(props?.row?.id);
            props.setModeItem(props?.row?.Mode);
            props.setRateConfirmation(true);
          }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl(
                "/media/svg/icons/Communication/Outgoing-box.svg"
              )}
            />
          </span>
        </a>
      </OverlayTrigger>
      <OneMoveMasterFunction />
      {/* <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">New Note</Tooltip>}
      >
        <a
          title="New Note"
          className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1"
          onClick={() => openEditCustomerDialog(row.id)}
        >
          <span className="svg-icon svg-icon-md svg-icon-warning">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}
            />
          </span>
        </a>
      </OverlayTrigger> */}
      <> </>
      <SendEmailFunction />
    </>
  );
}
