import React, { useMemo,useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { Form, Modal, Col, Tabs, Tab, Table, Spinner, OverlayTrigger, Tooltip, ButtonToolbar } from "react-bootstrap";
import { Export } from "../../../../../../../_metronic/_helpers/ATHelpers";
import axios from "axios";

//Export Filter

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
const prepareFilter = (queryParams, values) => {
  const { searchText, ReLoadTable } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.ReLoadTable = ReLoadTable || false;
  if (searchText) {
    filter.Address = searchText;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const [QueryFilter, setQueryFilter] = useState("");

  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      setQueryFilter(newQueryParams);
      
      customersUIProps.setQueryParams(newQueryParams);
    }
  };
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  async function fetchMyAPI2(type, entityName) {
    var filter = QueryFilter;
    if (filter) {
      filter.pageSize=100000;
      const result = await axios.post(`api/ManageContacts/filter`, { "queryParams": filter });
      Export(result.data.entities, type, entityName);
    } else {
      const result = await axios.post(`api/ManageContacts/filter`, { "queryParams": { "filter": {"ReLoadTable": false }, "sortOrder": "asc", "sortField": "id", "pageNumber": 1, "pageSize": 100000 } });
      Export(result.data.entities, type, entityName);
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <input
                type="button"
                className="d-none"
                id="ReLoadTableId"
                onClick={(e) => {
                  if (e.target.value == "0")
                    document.getElementById("ReLoadTableId").value = 1;
                  else document.getElementById("ReLoadTableId").value = 0;
                  setFieldValue("ReLoadTable", e.target.value);
                  handleSubmit();
                }}
              />
              <div className="form-group row">
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Search</b> in all fields
                </small>
                </div>
                <div className="col-lg-4">

                </div>
                <div className="col-lg-4">
                  <ButtonToolbar className="float-right btn-text-primary">
                    <Button style={{ height: "38px" }}

                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      variant="outlined" color="primary"
                      onClick={handleClick}
                    >
                      Export as
                                                    <Icon className={classes.rightIcon} >save</Icon>

                    </Button>
                    <StyledMenu
                      id="customized-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("excel", "Contacts");

                      }}>
                        <ListItemIcon>
                          <i class="far fa-file-excel"></i>
                        </ListItemIcon>
                        <ListItemText primary="Excel"
                        />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("csv", "Contacts");
                      }} >
                        <ListItemIcon>
                          <i class="fas fa-file-csv"></i>
                        </ListItemIcon>
                        <ListItemText primary="CSV"
                        />
                      </StyledMenuItem>
                      <StyledMenuItem onClick={(event) => {
                        fetchMyAPI2("json", "Contacts");
                      }}>
                        <ListItemIcon>
                          <i class="far fa-file-code"></i>

                        </ListItemIcon>
                        <ListItemText primary="Json" />
                      </StyledMenuItem>
                    </StyledMenu>
                    {/* <Button variant="primary" type="submit">Search</Button> */}
                  </ButtonToolbar>
                </div>
              </div>
            </form>
          )}
      </Formik>
    </>
  );
}
