import moment from "moment";
export const CustomerStatusCssClasses = ["danger", "success", "info", ""];
export const CustomerStatusTitles = ["Suspended", "Active", "Pending", ""];
export const CustomerTypeCssClasses = ["success", "primary", ""];
export const CustomerTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];
export const initialFilter = {
  filter: {
    CustomerId: window.$ShipmentManagementCustomerId,
    DateRangeType: "created",
    startDate:(window.$SmGsearchStartDate),
    endDate:moment().format("YYYY-MM-DD"),
    shipmentTypeId: "",
    Filter: window.$ShipmentManagementStatus,
  },
  sortOrder: "desc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10,
};
