import React, { useEffect, useState } from "react";
import { Modal, Accordion, Alert } from "react-bootstrap";
import { Formik, Form } from "formik";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import * as Yup from "yup";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import { AssignToOutboundHeaderForm } from "./AssignToOutboundHeaderForm";
import "./SplitCss.css";
import { ScrollingMenu } from "./ScrollingMenu";
import { ScrollingMenuReceipt } from "./ScrollingMenuReceipt";
import { makeStyles } from "@material-ui/core/styles";
import CreatableSelect from "react-select/creatable";
import { TryList } from "../../../../../../../../_metronic/_helpers/ATHelpers";
import SelectPagination from "../../../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import { OneMoveMaster } from "./OneMoveMaster";
import $ from "jquery";
import {
  CreatableSelect3,
  Checkbox,
} from "../../../../../../../../_metronic/_partials/controls";
import * as FileSaver from "file-saver";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Notification } from "../../../../../../Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let Draggable = require("react-draggable");

const catchError = [];
const mystyleTabs = {
  fontSize: "13px",
};

const PositionDrag = { x: 0, y: 0 };
export function AssignToOutbound({ ShipmentID, Warehouse }) {
  const [ModelShow, setModelShow] = useState(false);
  const [ModelShowAll, setModelShowAll] = useState(false);
  const [ModelShowMultiple, setModelShowMultiple] = useState(false);
  const [ModelShowNotSHipment, setModelShowNotSHipment] = useState(false);
  const [TabHtml, setTabHtml] = useState();
  const [TabHtml2, setTabHtml2] = useState(catchError);
  const [TabHtml3, setTabHtml3] = useState(catchError);
  const [LineItems, setLineItems] = useState(catchError);
  const [Consignees, setConsignees] = useState(catchError);
  const [Shippers, setShippers] = useState(catchError);
  const [Services, setServices] = useState(catchError);
  const [TrackingDevices, setTrackingDevices] = useState(catchError);
  const [OrdersIdsList, setOrdersIdsList] = useState(catchError);
  const [Customers, setCustomers] = useState(catchError);
  const [DistributionWarehouses, setDistributionWarehouses] = useState(
    catchError
  );
  const [Vendors, setVendors] = useState(catchError);
  const [statSHipment, setstatSHipment] = useState(catchError);
  const [statSHipmentId, setstatSHipmentId] = useState(ShipmentID);
  const [FreightCharges, setFreightCharges] = useState(catchError);
  useEffect(() => {
    window.ShipmentId = undefined;
    window.reciptsBtn = undefined;
    window.FindOrderId = undefined;
    window.FindOrderSearch = "orderId=";
    window.multipleBtnList = [];
    fetchContainersAsync();
  }, []);

  useEffect(() => {
    try {
      setTabs();
    } catch (e) {}
  }, [
    ModelShow,
    statSHipmentId,
    ModelShowAll,
    ModelShowMultiple,
    LineItems,
    ModelShowNotSHipment,
  ]);

  $(document).scroll(function() {
    var element = document.getElementById("ShipmentsTable");
    if ($(".TabelFilter:not(.d-none)")?.length !== 0) {
      var rect = element.getBoundingClientRect();
      if (statSHipmentId) {
        if (150 > rect.bottom) {
          $("#TableSHipmentFixed").fadeIn();
        } else {
          $("#TableSHipmentFixed").fadeOut();
        }
      }
    }
  });
  $(document).ready(function() {
    $(".itemPo").dblclick(function() {});
  });

  const fetchTabeAsync = async (b) => {
    $(".LoadingId").fadeIn();
    document.getElementById("Loading").classList.remove("d-none");
    return fetch(
      window.$apiurl + "/transload/shipment?orderId=" + UrlId() + "&label=" + b,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response.json();
      })
      .then((res) => {
        window.ShipmentId = res["Id"];
        fetchContainersAsync();
        return res["Id"];
      })
      .catch((err) => {});
  };

  function UrlId() {
    var urlstring = window.location.pathname;
    return urlstring.split("/")[3];
  }

  async function GetOrdersIds() {
    return await fetch(`${window.$apiurl}/transload/getordersids`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        var a = res[1];
        var b = [];
        a.forEach((x) => {
          b.push({ value: x, label: x });
        });
        return b;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetShipmentId(id) {
    return await fetch(
      `${window.$apiurl}/TransloadShipment/GetShipmentItems?shipmentId=${statSHipmentId}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        var receipts = [];
        var dis = {};
        var disList = [];

        res[1]["Items"].map(async (x) => {
          if (dis[x.TransloadOrderId] === undefined) {
            dis[x.TransloadOrderId] = x.TransloadOrderId;
            disList.push(x.TransloadOrderId);
          }
        });

        if (disList.length > 0)
          await new Promise(function(resolve) {
            disList.map(async (x, i) => {
              await GetFindOrder(x, false).then((x) => {
                receipts.push(x);
              });
              if (disList.length === receipts.length) resolve();
            });
          });

        (window.$ReceiptsAssigntoOutbound || []).forEach((x) => {
          receipts.push(x);
        });

        var shipment = res[1]["Shipment"];
        (window.$ShipmentsAssigntoOutbound || []).forEach((x, i) => {
          if (x?.Id === statSHipmentId) {
            window.$ShipmentsAssigntoOutbound.splice(i, 1);
          }
        });

        const a = {
          Receipts: receipts,
          Shipments: [
            ...(window.$ShipmentsAssigntoOutbound || []),
            {
              ...shipment,
              Id: statSHipmentId,
              ConsigneeId: shipment?.ConsigneeId || 0,
              ServiceId: shipment?.ServiceId || 0,
              ServiceLevelId: shipment?.ServiceLevelId || 0,
              ModeId: shipment?.ModeId || 0,
              EquipmentTypeId: shipment?.EquipmentTypeId || 0,
              EquipmentSizeId: shipment?.EquipmentSizeId || 0,
              VendorId: shipment?.VendorId,
              ShipperId: shipment?.ShipperId,
              WarehouseId: shipment?.WarehouseId || 0,
              Equipment: shipment?.Equipment,
              SealNumber: shipment?.SealNumber,
              CustomerId: shipment?.CustomerId || 0,
              RequestedPickUpDate: shipment?.RequestedPickUpDate,
              PalletType: shipment?.PalletType,
              PalletLoadType: shipment?.PalletLoadType,
              PalletCount: shipment?.PalletCount,
              LastShipDate: shipment?.LastShipDate,
              PickupAppointmentDate: shipment?.PickupAppointmentDate,
              PickupAppointmentToDate: shipment?.PickupAppointmentToDate,
              FreightChargeId: shipment?.FreightChargeId || 98,
              Label: shipment.Label,
              SumQuantity: shipment?.SumQuantity,
              SumWeight: shipment?.SumWeight,
              SumCube: shipment?.SumCube,
              DeviceId: shipment?.DeviceId,
              IsOutbound210Generated: false,
              ShipmentItems: res[1]["Items"],
            },
          ],
          Vendors: res[1]["Vendors"],
          Consignees: res[1]["Consignees"],
          Customers: res[1]["Customers"],
          Shippers: res[1]["Shippers"],
          Modes: res[1]["Modes"],
          Services: res[1]["Services"],
          FreightCharges: res[1]["FreightCharges"],
          TrackingDevices: res[1]["TrackingDevices"],
        };
        window.$AssigntoOutboundServices = res[1]["Services"];
        a.Shipments = a?.Shipments?.reverse() || [];
        window.$ShipmentsAssigntoOutbound = a.Shipments || [];
        window.$ReceiptsAssigntoOutbound = a.Receipts || [];

        var id2 = window.FindOrderId;
        if (id2) {
          var b = await GetFindOrder(id2, true);
          if (b !== 400) a.FindOrder = b;
          else a.FindOrder = 400;
        }
        return { data: a };
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostShipmentAssigntoWareHouse(id, b) {
    return await fetch(
      `${window.$apiurl}/transload/AssignToWarehouse/addshipment?orderId=${
        window.location.pathname.split("/")[3]
      }&WarehouseId=${id}&label=${b}`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        window.ShipmentId = res[1]?.Id;
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        fetchContainersAsync();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function DeleteShipmentAssigntoWareHouse(id) {
    return await fetch(
      `${window.$apiurl}/TransloadWarehouse/DeleteShipment/${id}`,
      {
        method: "Delete",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        window.ShipmentId = undefined;
        Notification("success", "Delete Shipment ID : " + id);
        fetchContainersAsync();
      })
      .catch((error) => {
        window.ShipmentId = undefined;
        Notification("success", "Delete Shipment ID : " + id);
        fetchContainersAsync();
        console.error(error);
      });
  }

  async function GetFindOrder(id, close) {
    return await fetch(
      window.$apiurl +
        "/transload/shipment/receipts?" +
        window.FindOrderSearch +
        id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400 || res[0] === 500) return 400;
        return { OrderId: res[1].OrderId, Receipts: res[1], Close: close };
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetShipmentData() {
    if (ShipmentID) {
      return await GetShipmentId(ShipmentID);
    } else {
      return await axios.get("api/FindOrderContainers/AssignToOutbound");
    }
  }

  async function GetShipmentDataTable(result) {
    const isTrue = result === "1";
    if (isTrue) result = await GetShipmentData();
    let shipments = false;
    let nameShipment = Warehouse ? "WarehouseShipments" : "Shipments";

    setstatSHipment(result.data[nameShipment] || catchError);
    shipments = result.data[nameShipment] || false;

    setTabHtml();
    setTabHtml2([]);
    setTabHtml3([]);

    if (shipments) {
      let z = [];
      shipments.map((item, index) => {
        let s = 0;
        item.ShipmentItems.map((item2, index2) => {
          if (item2?.Weight) s += item2?.Weight;
        });
        z.push({ id: item.Id, count: s });
        setTabHtml((x) => (
          <>
            {x}
            <TabeButtonFunction
              id={`Table${item.Id}`}
              shipmentId={item.Id}
              key={index}
            />
          </>
        ));
        setTabHtml2((x) => [...x, { name: item.Id, label: item.Label }]);

        setTabHtml3((x) => [...x, { name: item.Id, label: item.Label }]);
      });
      window.OneMoveMasterWeight = z;
    }

    if (isTrue) {
      await LineItemsFun();
    }

    setTabs();
  }

  async function LineItemsFun() {
    $(".LoadingId").fadeIn();
    let lineItems = LineItems;

    let result = await GetShipmentData();
    var lineItemsFirst = (await result.data["Receipts"]) || catchError;
    if (lineItems.length === 0) lineItems = lineItemsFirst;
    var findOrder = result.data?.FindOrder || false;
    if (findOrder && findOrder !== 400) {
      lineItems.push(findOrder);
    }
    if (findOrder && findOrder === 400) {
      Notification("error", "Order Not found");
    }
    let ordersId = [];
    let ordersList = [];
    lineItems.forEach((x) => {
      if (ordersId.indexOf(x.OrderId + "") === -1) {
        var a = lineItemsFirst.find((y) => x.OrderId + "" === y.OrderId + "");
        if (a) ordersList.push(a);
        else ordersList.push(x);
        ordersId.push(x.OrderId + "");
      }
    });
    ordersList.forEach((x, i) => {
      var a = ordersList[i];
      var c = IsNull(a?.OrderId + "");
      (a?.Receipts?.Receipts || a?.Receipts || []).forEach((y) => {
        c += IsNull(y?.id) + IsNull(y?.EquipmentNumber);
        y.LineItems.forEach((z) => {
          c += IsNull(z?.PurchaseOrder);
        });
      });
      a.filter = c;
    });
    setLineItems(ordersList);
    return result;
  }

  function IsNull(str) {
    if (!str || str === null || str === undefined) return "";
    return "|" + str.toString().toLowerCase();
  }

  const fetchContainersAsync = async () => {
    try {
      let result = await LineItemsFun();
      var s = window.$AssigntoOutboundServices;
      setConsignees(result.data["Consignees"] || catchError);
      setShippers(result.data["Shippers"] || catchError);
      setTrackingDevices(result.data["TrackingDevices"] || catchError);
      setServices(s || catchError);
      setVendors(result.data["Vendors"] || catchError);
      setFreightCharges(result.data["FreightCharges"] || catchError);
      if (ShipmentID) {
        setCustomers(result.data["Customers"] || catchError);
      }
      setDistributionWarehouses(
        result.data["DistributionWarehouses"] || catchError
      );
      setOrdersIdsList(await GetOrdersIds());
      await GetShipmentDataTable(result);
    } catch (e) {}
  };

  const getData210 = (id) => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/TwoTen/GetOutbound/` + id, requestOptions)
      .then((response) => {
        const statusCode = response.status;
        const data = response.json();
        return Promise.all([statusCode, data]);
      })
      .then((result) => {
        if (result[0] === 200) {
          if (result[1].Success) {
            var date = new Date(Date.now());
            var dateformat =
              ("0" + (date.getMonth() + 1)).slice(-2) +
              "" +
              date.getDate() +
              "" +
              date.getFullYear() +
              "" +
              date.getHours() +
              "" +
              date.getMinutes() +
              "" +
              date.getSeconds();

            const fileType = "text/plain";
            const fileExtension = ".edi";
            const data = new Blob([result[1].File], { type: fileType });
            FileSaver.saveAs(
              data,
              "Shipment_" + id + "_" + dateformat + ".210" + fileExtension
            );
            Notification(
              `infoClick`,
              `The 210 EDI file has been generated successfully`
            );
          } else {
            Notification("errorClick", result[1].ErrorMessage);
          }
        } else {
          Notification("errorClick", result[1].ExceptionMessage);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const PostShipment = async (Quantity) => {
    try {
      $(".LoadingId").fadeIn();
      var postData = {
        ShipmentId: window.ShipmentId,
        ReceiptItemId: window.ReceiptItemId,
        Quantity: Quantity,
        Warehouse: Warehouse,
      };
      await axios
        .post("api/FindOrderContainers/PostShipment", {
          data: postData,
        })
        .then((e) => {
          if (e?.status === 200) $("#GetShipmentDataTable").trigger("click");
        });
    } catch (e) {}
  };

  const PostAllShipments = async () => {
    try {
      $(".LoadingId").fadeIn();
      var postData = {
        ShipmentId: document.getElementById("ShipmentId").value,
        RecvId: document.getElementById("RecvId").value,
        Warehouse: { Warehouse },
      };
      const result = await axios
        .post("api/FindOrderContainers/PostAllShipments", {
          data: postData,
        })
        .then((e) => {
          if (e?.status === 200) $("#GetShipmentDataTable").trigger("click");
        });
    } catch (e) {}
  };

  const PostMultipleShipments = async (SHipmentId) => {
    try {
      $(".LoadingId").fadeIn();
      var postData = {
        ShipmentId: SHipmentId,
        Items: window.multipleBtnList,
      };
      const result = await axios
        .post("api/FindOrderContainers/PostMultipleShipments", {
          data: postData,
        })
        .then((e) => {
          if (e?.status === 200) {
            window.multipleBtnList = [];
            $("#GetShipmentDataTable").trigger("click");
          }
        });
      const a = await result;
    } catch (e) {}
  };

  const tabsActive = (shipmentId) => {
    $(`.Tabs-Btn`).removeClass("active");
    setTimeout(() => {
      $(`#TabsBtn${shipmentId}`).addClass("active");
    }, 20);
  };

  const TabeButtonFunction = ({ id, shipmentId }) => {
    return (
      <div className="col-lg-1 pt-1 pb-1 pr-1 pl-0">
        <input
          type="button"
          className="btn btn-light-info font-weight-bolder font-size-sm w-100 tabsClick pl-0 pr-0 text-center Tabs-Btn"
          id={`TabsBtn${shipmentId}`}
          style={mystyleTabs}
          value={"Shipment " + shipmentId}
          onClick={() => {
            tabsActive(shipmentId);
            setstatSHipmentId(shipmentId);
            window.ShipmentId = shipmentId;
            return false;
          }}
        />
      </div>
    );
  };

  const setTabs = () => {
    if (window.ShipmentId !== undefined) {
      tabsActive(window.ShipmentId);
      $(`.N${window.ShipmentId}`).click();
    } else
      $(".ScrollingMenu-item.Shipment")
        .eq(0)
        .click();

    if (window.reciptsBtn !== undefined) {
      setTimeout(() => {
        $(window.reciptsBtn).click();
      }, 1);
    }
  };

  const [IsClosed, setIsClosed] = useState(true);

  return (
    <>
      <div
        className="card card-custom p-5 text-center"
        id="TableSHipmentFixed"
        style={{
          display: "none",
          position: "fixed",
          top: "130px",
          right: "0",
          left: "130px",
          margin: "auto",
          width: "700px",
          zIndex: "97",
          opacity: "0.9",
          boxShadow: "rgb(24, 125, 228,0.4) 0px 0px 13px 1px",
        }}
      >
        Table Shipment Id : {statSHipmentId}
      </div>
      <div
        className="p-3 text-center LoadingId"
        style={{
          position: "fixed",
          top: "350px",
          right: "0",
          left: "130px",
          margin: "auto",
          width: "100px",
          zIndex: "97",
          borderRadius: "4px",
        }}
      >
        <CircularProgress color="secondary" className="text-center m-auto" />
      </div>
      <Modal show={ModelShow}>
        <Modal.Header closeButton>
          <Modal.Title>How Many ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-4 text-left">
                <label>
                  Maximum Quantity
                  <span className="IncludesOS1" style={{ display: "none" }}>
                    {" "}
                    (Includes <b className="text-danger">O</b>/S)
                  </span>
                  <span className="IncludesOS2" style={{ display: "none" }}>
                    {" "}
                    (Includes O/<b className="text-danger">S</b>)
                  </span>
                  <span className="IncludesOS3" style={{ display: "none" }}>
                    {" "}
                    (Includes <b className="text-danger">O/S</b>)
                  </span>
                </label>
              </div>
              <div className="col-lg-8">
                <input
                  id="QuantitytoshipIdMax"
                  min="0"
                  className="form-control text-center"
                  disabled
                />
              </div>
              <div className="col-lg-4 mt-5 text-left">
                <label className="pt-3">Quantity to ship</label>
              </div>
              <div className="col-lg-8 mt-5">
                <input
                  id="QuantitytoshipId"
                  min="0"
                  className="form-control text-center"
                  defaultValue="0"
                  type="number"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShow(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              var Quantity = document.querySelectorAll(
                "#ModelShipment input"
              )[1].value;
              var QuantityMax = $("#QuantitytoshipIdMax").val();
              if (+QuantityMax < +Quantity) {
                Notification(
                  "warning",
                  "Oops , Maxminum Quantity : " + QuantityMax
                );
                return false;
              }

              if (+Quantity != 0) {
                document.getElementById("Loading").classList.remove("d-none");
                PostShipment(Quantity);
                setModelShow(false);
              } else Notification("warning", "min number (1)");
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={ModelShowNotSHipment}>
        <Modal.Body>
          <div className="row mt-5 pt-5">
            <div className="col-lg-1" />
            <div className="col-lg-2 text-right">
              <i
                className="fas fa-quote-left"
                style={{ fontSize: "70px", opacity: 0.3 }}
              />
            </div>
            <div className="col-lg-8 mt-2">
              <p className="h1 mt-5" style={{ opacity: 0.3 }}>
                No Shipment found
              </p>
            </div>
            <div className="col-lg-1" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShowNotSHipment(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={ModelShowAll}>
        <Modal.Header closeButton>
          <Modal.Title>All items For Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-4 text-left">
                <label className="pt-3">Shipment Id</label>
              </div>
              <div className="col-lg-8">
                <input
                  id="ShipmentId"
                  className="form-control text-center"
                  type="number"
                  disabled
                />
              </div>
              <div className="col-lg-4 mt-5 text-left">
                <label className="pt-3">Recv. id</label>
              </div>
              <div className="col-lg-8 mt-5">
                <input
                  id="RecvId"
                  className="form-control text-center"
                  defaultValue="0"
                  type="number"
                  disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShowAll(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              PostAllShipments();
              setModelShowAll(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={ModelShowMultiple}>
        <Modal.Header closeButton>
          <Modal.Title>Ship multiple items</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-4 text-left">
                <label className="pt-3">Shipment Id</label>
              </div>
              <div className="col-lg-8">
                <input
                  id="ShipmentId"
                  className="form-control text-center"
                  type="number"
                  disabled
                />
              </div>
              <div className="col-lg-4 mt-5 text-left">
                <label className="pt-3">Items Ids</label>
              </div>
              <div className="col-lg-8 mt-5">
                <input
                  id="RecvMultiple"
                  className="form-control text-center"
                  defaultValue="0"
                  type="text"
                  disabled
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              window.multipleBtnList = [];
              setModelShowMultiple(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              PostMultipleShipments();
              setModelShowMultiple(false);
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {LineItems.length === 0 && <LinearProgress className="mb-5 LoadingId" />}
      <div id="Loading" className="d-none">
        <LinearProgress className="mb-5" />
      </div>
      {/* <SplitPane split="horizontal"> */}
      <div className="col-lg-12 row mb-0">
        <TableHeaderFunction
          fetchTabeAsync={fetchTabeAsync}
          DistributionWarehouses={DistributionWarehouses}
          Warehouse={Warehouse}
          PostShipmentAssigntoWareHouse={PostShipmentAssigntoWareHouse}
          ShipmentID={ShipmentID}
          DeleteShipmentAssigntoWareHouse={DeleteShipmentAssigntoWareHouse}
          TabHtml2={TabHtml2}
          setTabHtml3={setTabHtml3}
          statSHipmentId={statSHipmentId}
          Consignees={Consignees}
          Vendors={Vendors}
          TabHtml3={TabHtml3}
          Shippers={Shippers}
          statSHipment={statSHipment}
          setstatSHipment={setstatSHipment}
          Customers={Customers}
          Services={Services}
          TrackingDevices={TrackingDevices}
          setLineItems={setLineItems}
          LineItems={LineItems}
          FreightCharges={FreightCharges}
          fetchContainersAsync={fetchContainersAsync}
          GetShipmentData={GetShipmentData}
          TabHtml={TabHtml}
        />
      </div>
      <br />

      <div className={IsClosed && "d-none"}>
        <div className="form-group row mb-0 mr-5 ml-1">
          <div className="col-lg-4 text-left mt-5 p-2">
            <div className="text-danger font-weight-bold mb-5 pt-5">
              <i className="fas fa-warehouse mr-2 text-danger"></i> Assigned
              container to warehouse
            </div>
          </div>
          <div className="col-lg-4  pt-2 ">
            <label>
              <i className="fas fa-search mr-2 text-primary"></i> Find Order Or
              Container
            </label>
            <div className={`mb-3 w-100`}>
              <SelectPagination
                options={OrdersIdsList}
                name="id"
                searchBy={true}
                onChange={(e) => {
                  var a = OrdersIdsList.find(
                    (x) => x.value?.toString() === e?.value?.toString()
                  );
                  window.FindOrderSearch =
                    a === undefined ? "search=" : "orderId=";
                  window.FindOrderId = e?.value || undefined;
                  LineItemsFun();
                }}
              />

              {/* <CreatableSelect
                isSearchable={true}
                onCreateOption={undefined}
                createOptionPosition="first"
                isClearable
                onChange={(newValue) => {
                  var a = OrdersIdsList.find(
                    (x) => x.value?.toString() === newValue?.value?.toString()
                  );
                  window.FindOrderSearch =
                    a === undefined ? "search=" : "orderId=";
                  window.FindOrderId = newValue?.value || undefined;
                  LineItemsFun();
                }}
                options={OrdersIdsList}
                name="PickupCarrierId"
                id="PickupCarrierId"
                className="w-100 mr-2"
              /> */}
            </div>
          </div>
          <div className="col-lg-4 pt-2  mb-4">
            <label>
              <i className="fas fa-search mr-2 text-primary"></i> filter by
              OrderId Or Container
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Search"
              onChange={(e) => {
                var a = e.target.value.toLowerCase();
                if (a === "") {
                  $(".OrderDIv").show();
                } else {
                  $(".OrderDIv").hide();
                  $(".OrderDIv").each((y) => {
                    if (
                      $(".OrderDIv")
                        .eq(y)
                        .attr("data-filter")
                        ?.indexOf(a) > -1
                    ) {
                      var c = $(".OrderDIv").eq(y);
                      c.show();

                      var g = !c.find(".OrderFilter").hasClass("show");
                      if (g) c.find(".OrderFilter").click();
                    }
                  });

                  $(".POFilter").each((y) => {
                    if (
                      $(".POFilter")
                        .eq(y)
                        .attr("data-filter")
                        ?.indexOf(a) > -1
                    ) {
                      $(".POFilter")
                        .eq(y)
                        .click();
                    }
                  });
                }
              }}
            />
          </div>
        </div>
        <Accordion defaultActiveKey="index0">
          {LineItems.map((x, index) => (
            <>
              <div className="OrderDIv" key={index} data-filter={x?.filter}>
                <div className="row mr-2">
                  <div
                    className={`col-lg-1${x?.Close ? "1" : "2"} m-0 p-0 pl-3`}
                  >
                    <Accordion.Toggle
                      as={Button}
                      className="w-100 text-left p-0 position-relative text-decoration-none OrderFilter"
                      variant="link"
                      eventKey={"index" + index}
                      data-filter={x.OrderId}
                    >
                      <Alert
                        variant="primary "
                        className="pt-3 pl-3 pb-0 w-100"
                      >
                        <h5 className="font-weight-bold">
                          {x.OrderId !== undefined
                            ? `Order ID : ${x.OrderId}`
                            : `Receipts for Shipment Id : ${ShipmentID}`}
                        </h5>
                        <Icon
                          className={clsx("fa fa-chevron-down text-white")}
                          style={{
                            fontSize: 17,
                            right: "1rem",
                            top: "30%",
                            position: "absolute",
                          }}
                        />
                      </Alert>
                    </Accordion.Toggle>
                  </div>
                  {x?.Close && (
                    <div className="col-lg-1 m-0 pl-5 pr-1">
                      <div className="text-white w-100">
                        <button
                          type="button"
                          className="btn btn-light-danger w-100"
                          onClick={() => {
                            var a = LineItems.filter(
                              (y) => y.OrderId !== x.OrderId
                            );
                            setLineItems(a);
                          }}
                        >
                          <i className="fa fa-times-circle text-white mr-2"></i>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <Accordion.Collapse
                  eventKey={"index" + index}
                  className="OrderFilter"
                  data-filter={x.OrderId}
                >
                  <MultipleRecipt
                    LineItems={x}
                    key={index}
                    PostMultipleShipments={PostMultipleShipments}
                    setModelShowNotSHipment={setModelShowNotSHipment}
                    statSHipmentId={statSHipmentId}
                    setModelShow={setModelShow}
                    setModelShowAll={setModelShowAll}
                  />
                </Accordion.Collapse>
                <br />
              </div>
            </>
          ))}
        </Accordion>
      </div>

      <br />
      <br />
      <Modal.Footer>
        {/* {statSHipment.IsClosed?.toString()} */}
        <OneMoveMasterFunction
          setIsClosed={setIsClosed}
          statSHipment={statSHipment}
          statSHipmentId={statSHipmentId}
          IsClosed={IsClosed}
        />
      </Modal.Footer>
    </>
  );
}

const MultipleRecipt = ({
  LineItems,
  statSHipmentId,
  PostMultipleShipments,
  setModelShowNotSHipment,
  setModelShow,
  setModelShowAll,
}) => {
  const receipts = LineItems?.Receipts?.Receipts || LineItems?.Receipts || [];
  const [ListLineItem0, setListLineItem0] = useState(receipts);
  const [ListLineItem, setListLineItem] = useState([]);
  const [ListLineItem2, setListLineItem2] = useState([]);

  useEffect(() => {
    receipts.map((item) => {
      var a =
        item.EquipmentNumber !== null && item.EquipmentNumber !== "N/A"
          ? item.EquipmentNumber
          : "BJ#" + item.CustomerRefNumber2;
      setListLineItem((x) => [...x, { name: a, id: item.Id }]);
      setListLineItem2((x) => [
        ...x,
        { name: a, id: item.Id, wareHouse: item.WarehouseId !== null },
      ]);
    });
  }, []);

  const TabsHtml = () => {
    return (
      <>
        {TryList(ListLineItem0).map((item, index) => (
          <b
            key={index}
            id={"Receipt" + item.Id}
            onClick={() => {
              $(".itemFilter" + LineItems?.OrderId).addClass("d-none");
              document
                .getElementById(`item${item.Id}`)
                .classList.remove("d-none");
              window.reciptsBtn = `#ReceiptId${item.Id}`;
            }}
            className="ReceiptTab m-5"
          >{`${item.EquipmentNumber || "Receipt. " + item.Id}`}</b>
        ))}
      </>
    );
  };
  return (
    <div>
      <div className="d-none">
        <TabsHtml />
      </div>
      <br />
      <div className="col-lg-12">
        <div className="row">
          <div className={`col-lg-5 filterReceipt${LineItems?.OrderId} d-none`}>
            <div
              className={`input-group mb-3 filterReceipt${LineItems?.OrderId} d-none`}
            >
              <input
                type="text"
                className="form-control ScrollingMenuApp "
                id={"FilterItemInput" + LineItems?.OrderId}
                onKeyPress={(e) => {
                  if (e.keyCode === 13) e.preventDefault();
                }}
                placeholder="Filter by Item Id, Sku or Purchase Order Number"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    const inputSearch = $(
                      "#FilterItemInput" + LineItems?.OrderId
                    )
                      .val()
                      ?.toLowerCase();

                    $(".ItemsClass" + LineItems?.OrderId).removeClass("d-none");
                    if (inputSearch === "") return false;

                    const a = $(".ItemsClass" + LineItems?.OrderId).each(
                      (x) => {
                        const b = $(".ItemsClass" + LineItems?.OrderId)
                          .eq(x)
                          ?.attr("data-filter")
                          ?.toLowerCase()
                          ?.includes(inputSearch);
                        if (!b)
                          $(".ItemsClass" + LineItems?.OrderId)
                            .eq(x)
                            .addClass("d-none");
                      }
                    );
                  }}
                >
                  <i className="fas fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div className={`col-lg-5 filterReceipt${LineItems?.OrderId} d-none`}>
            <div
              className={`input-group mb-3 filterReceipt${LineItems?.OrderId} d-none`}
            >
              <input
                type="text"
                className="form-control ScrollingMenuApp "
                id="FilterReceiptInput"
                placeholder="Filter by Container Id or Equipment #"
                onKeyPress={(e) => {
                  if (e.keyCode === 13) e.preventDefault();
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    const inputSearch = $("#FilterReceiptInput").val();
                    const a = ListLineItem.filter((x) =>
                      x.name?.toString().includes(inputSearch)
                    );
                    var b = [];
                    a.forEach((element) => {
                      b.push({
                        name: element.name,
                        id: element.id,
                      });
                    });
                    setListLineItem2(b);
                  }}
                >
                  <i className="fas fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-2 ml-auto mb-4 ">
            <button
              type="button"
              className="btn btn-light-info font-weight-bolder font-size-sm w-100 pt-4 pb-5"
              style={mystyleTabs}
              onClick={() => {
                const a = $(".filterReceipt" + LineItems?.OrderId);
                if (a.hasClass("d-none")) a.removeClass("d-none");
                else a.addClass("d-none");
              }}
            >
              <i className="fas fa-filter"></i> Filter
            </button>
          </div>
          <div className="col-lg-12 pt-1 pb-1 pr-1 pl-0">
            {ListLineItem2.length > 0 && (
              <ScrollingMenuReceipt list={ListLineItem2} />
            )}
          </div>
          <div
            className={`col-lg-2 pt-1 pb-1 pr-1 pl-0 filterReceipt${LineItems?.OrderId} d-none`}
          />
          <div
            className={`col-lg-2 pt-1 pb-1 pr-1 pl-0 filterReceipt${LineItems?.OrderId} d-none`}
          />
        </div>
      </div>

      <br />
      {TryList(ListLineItem0).map((item, index) => (
        <div
          key={index}
          className={`form-group itemFilter${LineItems?.OrderId}  mb-0 d-none`}
          id={`item${item.Id}`}
        >
          <DraggableFunctionALL
            ItemId={item.Id}
            Name={`${item.EquipmentNumber || "Receipt. " + item.Id}`}
            LineItems={item["LineItems"] || []}
            DraggableFunctionALL={DraggableFunctionALL}
            statSHipmentId={statSHipmentId}
            setModelShowAll={setModelShowAll}
          />
          <DraggableFunctionMultiple
            ItemId={item.Id}
            Name={`${item.EquipmentNumber || "Receipt. " + item.Id}`}
            LineItems={item["LineItems"] || []}
            OrderId={LineItems?.OrderId}
            PostMultipleShipments={PostMultipleShipments}
            setModelShowNotSHipment={setModelShowNotSHipment}
            statSHipmentId={statSHipmentId}
            setModelShow={setModelShow}
          />
        </div>
      ))}
    </div>
  );
};

const DraggableFunctionALL = ({
  ItemId,
  Name,
  LineItems,
  setModelShowAll,
  statSHipmentId,
}) => {
  const [Message, setMessage] = useState(false);
  useEffect(() => {
    const a = LineItems.filter((x) => x.LeftQuantity > 0).length > 0;
    setMessage(!a);
  });

  const handleDrag = () => {
    $("#ShipmentsTable").css("background-color", "#FFE2E5");
  };

  const handleStop = (event, ItemId) => {
    var element = document.getElementById("ShipmentsTable");
    if ($(".TabelFilter:not(.d-none)")?.length !== 0) {
      var rect = element.getBoundingClientRect();
      if (rect.top <= event.clientY && event.clientY <= rect.bottom) {
        var el = document.querySelector("div.TabelFilter:not(.d-none)");
        if (el != undefined && el != null) {
          setModelShowAll(true);
          element.style.background = "#FFE2E5";
          document.getElementById("ShipmentId").value = statSHipmentId;
          document.getElementById("RecvId").value = ItemId;
        }
      }
    }
    element.style.background = "transparent";
  };

  return (
    <div className={`col-lg-${Message ? "12" : "12"} m-0 p-0 mb-5`}>
      {!Message && (
        <Draggable
          axis="y"
          handle=".handle"
          position={PositionDrag}
          grid={[1, 1]}
          scale={1}
          onDrag={handleDrag.bind()}
          onStop={(event) => handleStop(event, ItemId)}
        >
          <div className="handle text-center">
            <button className="col-lg-12 w-100 text-left POCheckbox alert alert-danger w-100 text-center m-0 p-4">
              All items For {Name}
            </button>
          </div>
        </Draggable>
      )}

      {Message && (
        <div className="row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              No data found
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      )}
    </div>
  );
};

const DraggableFunction = ({
  Id,
  ItemId,
  PurchaseOrder,
  LeftQuantity,
  UnitOfMeasure,
  Sku,
  onChangeFunction,
  Location,
  OrderId,
  TotalWeight,
  TotalCube,
  ShortQuantity,
  OverQuantity,
  DamagedQuantity,
  PostMultipleShipments,
  setModelShowNotSHipment,
  statSHipmentId,
  setModelShow,
  item,
  LineItems,
}) => {
  const [ModelShowCheckService, setModelShowCheckService] = useState(false);
  const OSD = ShortQuantity > 0 || OverQuantity > 0 || DamagedQuantity > 0;

  const handleDrag = (event) => {
    var element = document.getElementById("ShipmentsTable");
    var element2 = document.getElementById("TableSHipmentFixed");
    element.style.background = "#EEE5FF";
    element2.style.background = "#187DE4";
    element2.style.color = "#ffffff";

    var e = $(event.target.closest(".ItemsClassFIlter"));
    var eIsChecked = e.find("input[type='checkbox']").is(":checked");
    if (window.multipleBtnList && eIsChecked) {
      $(".ItemsClassFIlter input:checked")
        .not(event)
        .closest(".ItemsClassFIlter")
        .attr("style", "opacity: 0;");
      $(e).attr("style", "opacity: 1;");
    }
  };
  const handleStop = (
    event,
    Id,
    ItemId,
    PurchaseOrder,
    LeftQuantity,
    UnitOfMeasure,
    ShortQuantity,
    OverQuantity,
    isTrue
  ) => {
    var element = document.getElementById("ShipmentsTable");
    var element2 = document.getElementById("TableSHipmentFixed");
    /* if ($(".TabelFilter:not(.d-none)")?.length !== 0) { */
    if (statSHipmentId) {
      var rect = element.getBoundingClientRect();
      var rect2 = element2.getBoundingClientRect();
      if (
        (rect.top <= event.clientY && event.clientY <= rect.bottom) ||
        (rect2.top <= event.clientY && event.clientY <= rect2.bottom) ||
        isTrue
      ) {
        var el = document.querySelector("div.TabelFilter:not(.d-none)");
        if (el != undefined && el != null) {
          //check by modeId
          var modeId = $(`[name="ModeId"]`).val();
          var serviceLevelId = $(`[name="ServiceLevelId"]`).val();

          var isSameSevice = false;

          if (window.multipleBtnList?.length > 0)
            window.multipleBtnList.forEach((element) => {
              var item = LineItems.find((x) => x.Id === element);
              if (
                !!item?.ModeId &&
                !!item?.ServiceLevelId &&
                !!modeId &&
                !!serviceLevelId &&
                (item?.ModeId !== +modeId ||
                  item?.ServiceLevelId !== +serviceLevelId)
              )
                isSameSevice = true;
            });
          else if (
            !!item?.ModeId &&
            !!item?.ServiceLevelId &&
            !!modeId &&
            !!serviceLevelId &&
            (item?.ModeId !== +modeId ||
              item?.ServiceLevelId !== +serviceLevelId)
          )
            isSameSevice = true;

          if (isSameSevice) setModelShowCheckService(true);
          else MoveItemtoList();
        }
      }
    } else {
      setModelShowNotSHipment(true);
    }

    $(".ItemsClassFIlter").attr("style", "opacity: 1;");
    element.style.background = "transparent";
    element2.style.background = "#fff";
    element2.style.color = "#000";
  };

  function MoveItemtoList() {
    if (window.multipleBtnList && window.multipleBtnList.length > 0) {
      PostMultipleShipments(statSHipmentId);
    } else {
      setModelShow(true);
      if (OverQuantity > 0 && ShortQuantity > 0) $(".IncludesOS3").show();
      else if (OverQuantity > 0) $(".IncludesOS1").show();
      else if (ShortQuantity > 0) $(".IncludesOS2").show();
      setTimeout(() => {
        document.querySelector("#ModelShipment input").value = LeftQuantity;
        document.querySelector("#QuantitytoshipId").value = LeftQuantity;
        window.ReceiptItemId = Id;
        window.ItemId = ItemId;
        window.PurchaseOrder = PurchaseOrder;
        window.LeftQuantity = LeftQuantity;
      }, 50);
    }
  }

  return (
    <div
      className={"col-lg-6 mb-5 ItemsClassFIlter ItemsClass" + OrderId}
      data-filter={`${PurchaseOrder}_${Id}_${Sku}`}
      onDoubleClick={(event) =>
        handleStop(
          event,
          Id,
          ItemId,
          PurchaseOrder,
          LeftQuantity,
          UnitOfMeasure,
          ShortQuantity,
          OverQuantity,
          true
        )
      }
    >
      <Draggable
        axis="both"
        handle=".handle"
        position={PositionDrag}
        grid={[1, 1]}
        scale={1}
        onDrag={handleDrag.bind(this)}
        onStop={(event) =>
          handleStop(
            event,
            Id,
            ItemId,
            PurchaseOrder,
            LeftQuantity,
            UnitOfMeasure,
            ShortQuantity,
            OverQuantity
          )
        }
      >
        <div className="handle row mx-1">
          <button
            className={`col-lg-12 w-100 text-left POCheckbox alert ${
              OSD ? "alert-warning" : "alert-primary"
            } w-100 text-center m-0 p-2`}
            id={`id${PurchaseOrder}_${ItemId}_${Id}`}
            style={{ fontSize: "12px" }}
          >
            <button
              className="mr-2 pt-1"
              style={{
                background: "transparent",
                border: "none",
              }}
              onClick={(event) =>
                handleStop(
                  event,
                  Id,
                  ItemId,
                  PurchaseOrder,
                  LeftQuantity,
                  UnitOfMeasure,
                  ShortQuantity,
                  OverQuantity,
                  true
                )
              }
            >
              <i className="fas fa-plus-circle text-light"></i>
            </button>
            <div
              style={{
                position: "absolute",
                left: "10px",
                top: "3.5px",
              }}
            >
              <Checkbox
                label=" "
                color="secondary"
                marginTop=" "
                onChange={(event) => {
                  window.multipleBtn = { Id: Id, Bool: event.target.checked };
                  onChangeFunction();
                }}
              />
            </div>
            PO: {PurchaseOrder} - Sku : {Sku || "(Sku Not Found)"} -
            <b className="Quantity mx-2">{LeftQuantity}</b>
            {UnitOfMeasure +
              (Location !== null ? " - Location:" + Location : "")}{" "}
            - Cub:{TotalCube} - Weight:{TotalWeight}
          </button>
        </div>
      </Draggable>

      <Modal show={ModelShowCheckService}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 text-left mt-5 h6">
                The shipment and the item mode/service level are different.
                Would you like to proceed with this action?
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setModelShowCheckService(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              setModelShowCheckService(false);
              MoveItemtoList();
            }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const DraggableFunctionMultiple = ({
  ItemId,
  Name,
  LineItems,
  OrderId,
  PostMultipleShipments,
  setModelShowNotSHipment,
  statSHipmentId,
  setModelShow,
}) => {
  const [Message, setMessage] = useState(false);
  const [disabledBtnMul, setdisabledBtnMul] = useState(true);
  const [MultipleSelectNumber, setMultipleSelectNumber] = useState(0);
  const [statSHipmentMulitple, setstatSHipmentMulitple] = useState(catchError);

  useEffect(() => {
    const a = LineItems.filter((x) => x.LeftQuantity > 0).length > 0;
    setMessage(!a);
  });

  let onChangeFunction = () => {
    var multipleBtn = window.multipleBtn;
    let a = window.multipleBtnList || [];
    if (multipleBtn?.Bool && a?.indexOf(multipleBtn?.Id) === -1) {
      a.push(multipleBtn?.Id);
    } else {
      var index = a.indexOf(multipleBtn?.Id);
      a.splice(index, 1);
    }
    setstatSHipmentMulitple(a);
    const b = a.length === 0;
    window.multipleBtnList = a;
    setMultipleSelectNumber(a.length);
    setdisabledBtnMul(b);
  };
  return (
    <>
      <div className="row">
        {LineItems.filter((x) => x.LeftQuantity > 0).map((item2, index2) => (
          <>
            <DraggableFunction
              Id={item2.Id}
              Sku={item2.Sku}
              ItemId={ItemId}
              PurchaseOrder={item2.PurchaseOrder}
              LeftQuantity={item2.LeftQuantity}
              UnitOfMeasure={item2.UnitOfMeasure}
              onChangeFunction={() => onChangeFunction()}
              OrderId={OrderId}
              Location={item2?.Location}
              key={index2}
              TotalWeight={item2.TotalWeight}
              TotalCube={item2.TotalCube}
              OverQuantity={item2.OverQuantity}
              ShortQuantity={item2.ShortQuantity}
              PostMultipleShipments={PostMultipleShipments}
              setModelShowNotSHipment={setModelShowNotSHipment}
              statSHipmentId={statSHipmentId}
              setModelShow={setModelShow}
              item={item2}
              LineItems={LineItems}
            />
          </>
        ))}
      </div>
    </>
  );
};

const TableHeaderFunction = ({
  fetchTabeAsync,
  DistributionWarehouses,
  Warehouse,
  PostShipmentAssigntoWareHouse,
  ShipmentID,
  DeleteShipmentAssigntoWareHouse,
  TabHtml2,
  setTabHtml3,
  statSHipmentId,
  Consignees,
  Vendors,
  TabHtml3,
  Shippers,
  statSHipment,
  setstatSHipment,
  Customers,
  Services,
  TrackingDevices,
  setLineItems,
  LineItems,
  FreightCharges,
  fetchContainersAsync,
  GetShipmentData,
  TabHtml,
}) => {
  const [WareHouseModal, setWareHouseModal] = useState(false);
  const [WareHouseList, setWareHouseList] = useState([]);
  const [stateAOHeader, setstateAOHeader] = useState(false);

  useEffect(() => {
    $("#AssignToOutboundHeaderForm").slideUp();
    setTimeout(() => {
      setstateAOHeader(false);
      setTimeout(() => {
        setstateAOHeader(true);
      }, 1);
    }, 500);
  }, [statSHipmentId]);

  $(document).ready(function() {
    var a = 0;
    var myVar = setInterval(() => {
      if ($("#AssigntoWareHouseHeader").length > 0 || a === 1) {
        $(".LoadingId").fadeOut();
        $("#Loading").addClass("d-none");
        if (window.reciptsBtn === undefined)
          $(".ScrollingMenu-item.Recipt:eq(0)").click();
        clearInterval(myVar);
      }
      setTimeout(() => {
        a = 1;
      }, 10000);
    }, 1000);
  });

  useEffect(() => {
    setWareHouseList(DistributionWarehouses);
  }, [DistributionWarehouses]);
  return (
    <>
      <Modal show={WareHouseModal}>
        <Modal.Header closeButton>
          <Modal.Title>New Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment">
            <div className="form-group row">
              {Warehouse && (
                <div className="col-lg-12 mt-5 wherehouse">
                  <CreatableSelect3
                    id="WareHouseListId"
                    name="ConsigneeId"
                    label="WareHouse List"
                    options={WareHouseList.map((x) => {
                      return {
                        value: x?.Id,
                        label: x?.CompanyName,
                      };
                    })}
                  />
                </div>
              )}
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Shipment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setWareHouseModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={() => {
              $(".LoadingId").fadeIn();
              var b = $("#LabelForShipmentID").val();
              if (Warehouse) {
                var a = $("#WareHouseListId").val();
                PostShipmentAssigntoWareHouse(a, b);
              } else {
                $("#GetShipmentDataTable").trigger("click");
                fetchTabeAsync(b);
              }
              setWareHouseModal(false);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-lg-12 p-0">
        <div className="row m-auto">
          <div
            className={
              Warehouse
                ? "col-lg-6 filterShipment mb-4 d-none"
                : "col-lg-8 filterShipment mb-4 d-none "
            }
          >
            <div className="input-group filterShipment mt-1 d-none">
              <input
                type="text"
                className="form-control ScrollingMenuApp "
                id="FilterShipmentInput"
                placeholder="Filter by Shipment Id"
              />
              <div className="input-group-append">
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => {
                    const inputSearch = $("#FilterShipmentInput").val();
                    const a = TabHtml2.filter((x) =>
                      x.name?.toString().includes(inputSearch)
                    );
                    var b = [];
                    a.forEach((element) => {
                      b.push({ name: element.name, label: element.label });
                    });
                    setTabHtml3(b);
                    setTimeout(() => {
                      /* 0219; */
                      //setTabs();
                      $("#FilterShipmentInput").val(inputSearch);
                    }, 50);
                  }}
                >
                  <i className="fas fa-filter"></i>
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              "col-lg-2 mb-4 ml-auto " + (ShipmentID !== undefined && "d-none")
            }
          >
            <button
              type="button"
              className="btn btn-light-info font-weight-bolder font-size-sm w-100 pt-4 pb-5"
              style={mystyleTabs}
              onClick={() => {
                const a = $(".filterShipment");
                if (a.hasClass("d-none")) a.removeClass("d-none");
                else a.addClass("d-none");
              }}
            >
              <i className="fas fa-filter"></i> Filter
            </button>
          </div>
          <div
            className={
              "col-lg-2 mb-4 " + (ShipmentID !== undefined && "d-none")
            }
          >
            <button
              type="button"
              className="btn btn-light-success font-weight-bolder font-size-sm w-100 pt-4 pb-5"
              style={mystyleTabs}
              onClick={() => {
                setWareHouseModal(true);
              }}
            >
              <i className="fas fa-plus-circle"></i> New
            </button>
          </div>
          {Warehouse && (
            <div className={"col-lg-2 mb-4 "}>
              <button
                type="button"
                className="btn btn-light-danger font-weight-bolder font-size-sm w-100 pt-4 pb-5"
                style={mystyleTabs}
                onClick={() => {
                  $(".LoadingId").fadeIn();
                  DeleteShipmentAssigntoWareHouse(statSHipmentId);
                }}
              >
                <i className="fas fa-trash"></i> Delete
              </button>
            </div>
          )}

          <div className={(Warehouse ? "col-lg-12" : "col-lg-12 ") + " px-0 "}>
            {TabHtml3.length > 0 && (
              <ScrollingMenu list={TabHtml3} id="AssigntoWareHouseHeader" />
            )}
          </div>
          <br />

          <div
            className="col-lg-12 pt-1 pb-1 pr-1 pl-0"
            id="AssignToOutboundHeaderForm"
          >
            <br />

            {stateAOHeader && (
              <AssignToOutboundHeaderForm
                Consigneesf={Consignees}
                Vendorsf={Vendors}
                Shippersf={Shippers}
                Customersf={Customers}
                Servicesf={Services}
                SHipmentId={statSHipmentId}
                SHipment={statSHipment}
                TrackingDevicesf={TrackingDevices}
                setstatSHipment={setstatSHipment}
                setLineItems={setLineItems}
                LineItems={LineItems}
                FreightChargesf={FreightCharges}
                fetchContainersAsync={fetchContainersAsync}
                Warehouse={Warehouse}
                GetShipmentData={GetShipmentData}
              />
            )}
          </div>
        </div>

        <div className="d-none">{TabHtml}</div>
      </div>
    </>
  );
};

async function PosttoClosed(PalletsIDStat, setIsClosed) {
  return await fetch(
    `${window.$apiurl}/transload/closeshipment/${PalletsIDStat}`,
    {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      setIsClosed((x) => !x);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}

const OneMoveMasterFunction = ({
  setIsClosed,
  statSHipment,
  statSHipmentId,
  IsClosed,
}) => {
  const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);

  useEffect(() => {
    var a = statSHipment.find((x) => x.Id === statSHipmentId)?.IsClosed;
    if (a !== undefined) {
      setIsClosed(a);
    }
  }, [statSHipment, statSHipmentId]);

  return (
    <>
      <Modal
        size="xl"
        show={OneMoveMasterShow}
        onHide={() => {
          setOneMoveMasterShow(false);
        }}
      >
        <OneMoveMaster
          Show={setOneMoveMasterShow}
          statSHipmentId={statSHipmentId}
        />
      </Modal>
      <button
        type="button"
        id="ShipmentDetailsBtn"
        className={
          "btn btn-danger btn-elevate " /*  + (IsClosed && "d-none") */
        }
        onClick={() => {
          PosttoClosed(statSHipmentId, setIsClosed);
        }}
        disabled={statSHipmentId === undefined}
      >
        <i className={`fas fa-${!IsClosed ? "times-circle" : "lock-open"}`}></i>
        {!IsClosed ? "Close Shipment" : "UnClose Shipment"}
      </button>
      <button
        type="button"
        id="ShipmentDetailsBtn"
        className="btn btn-primary btn-elevate"
        onClick={() => {
          window.shipmentEditCommingFrom = "AssigntoOutbound";
          setOneMoveMasterShow(true);
        }}
        disabled={statSHipmentId === undefined}
      >
        <i className="fas fa-edit"></i>
        Shipment Details
      </button>
    </>
  );
};
