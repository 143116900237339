import moment from "moment";
export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "desc" }];
export const sizePerPageList = [
  { text: "4", value: 4 },
  { text: "8", value: 8 },
  { text: "15", value: 15 },
];

export const initialFilter = {
  filter: {
    LoadBoardId: "", // values => All=""/Selling=0/Sold=1
    CustomerId: "", // values => All=""/New=0/Used=1
    ModeId: "",
    CarrierId: "",
    SearchText: "",
    CapacityOperationId: "",
    CommercialAssigneeId: "",
    CommercialOperationId: "",
    startDate: moment(moment(), "DD-MM-YYYY")
      .add(-28, "days")
      .format("YYYY-MM-DD"),
    endDate: moment(moment(), "DD-MM-YYYY")
    .add(14, "days")
    .format("YYYY-MM-DD"),
  },
  sortOrder: "asc", // asc||desc
  sortField: "VINCode",
  pageNumber: 1,
  pageSize: 4,
};
export const AVAILABLE_COLORS = [
  "Red",
  "CadetBlue",
  "Eagle",
  "Gold",
  "LightSlateGrey",
  "RoyalBlue",
  "Crimson",
  "Blue",
  "Sienna",
  "Indigo",
  "Green",
  "Violet",
  "GoldenRod",
  "OrangeRed",
  "Khaki",
  "Teal",
  "Purple",
  "Orange",
  "Pink",
  "Black",
  "DarkTurquoise",
];

export const AVAILABLE_MANUFACTURES = [
  "Pontiac",
  "Kia",
  "Lotus",
  "Subaru",
  "Jeep",
  "Isuzu",
  "Mitsubishi",
  "Oldsmobile",
  "Chevrolet",
  "Chrysler",
  "Audi",
  "Suzuki",
  "GMC",
  "Cadillac",
  "Infinity",
  "Mercury",
  "Dodge",
  "Ram",
  "Lexus",
  "Lamborghini",
  "Honda",
  "Nissan",
  "Ford",
  "Hyundai",
  "Saab",
  "Toyota",
];
