import React from 'react'
import { Form, Card, Col, Dropdown } from "react-bootstrap";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 200px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

export function CreateReceiptsPage() {
    return (
        <Card>
            <Card.Body>
                <Form>

                    <div className="col-12 mb-4">
                        <div title="Manage Roles">
                            <button
                                type="button"
                                className="btn btn-success btn-shadow-hover mr-2"
                            >
                                Save
                                    </button>
                            <button
                                type="button"
                                className="btn btn-secondary btn-shadow-hover mr-2"
                            >
                                Exit
                                    </button>
                        </div>
                    </div>

                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">

                            <Form.Label>Customer*</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Choose a Customer
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Epic Drops, LLC</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Loud Bags Inc</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Ornative Inc</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">PANACHE INTERNATIONAL LLC (c5937)</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">PhatMojo, LLC</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Pro-Spin Sports</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Reliable of Milwaukee</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">X Chair LLC</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Warehouse*</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Choose a Warehouse
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Warehouse X</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridEmail">
                            <div class="col-6">
                                <label class="form-label">Reference ID*</label>
                                <input placeholder="Reference ID" type="text" class="form-control" />
                            </div>
                        </Form.Group>
                    </Form.Row>

                </Form>
            </Card.Body>
        </Card>
    )
}
