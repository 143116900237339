import React, { useEffect, useState } from "react";
import TableComponent from "./TableComponent";
import IndexPalletsList from "./Pallets/IndexPalletsList";
import low from "lowdb";
import { Notification } from "../../../modules/Notification";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Index({ PalletsIDStat = [], setPallet }) {
  const [Table, setTable] = useState([]);
  const [DeletRowId, setDeletRowId] = useState(0);
  const [PalletID, setPalletID] = useState({});
  const [ReloadPalletsList, setReloadPalletsList] = useState(true);

  function ReloadPallets() {
    setReloadPalletsList(false);
    setTimeout(() => {
      setReloadPalletsList(true);
    }, 1);
  }

  const [Search, setSearch] = useState("");

  useEffect(() => {
    setPalletID(PalletsIDStat[0]);
    if (PalletsIDStat[0]?.Id) GetItemsForPallet(PalletsIDStat[0].Id, setTable);
  }, [PalletsIDStat]);

  function SetTableShipment(row, Quantity) {
    var table = Table;
    var a = table.findIndex((x) => x?.Id === row?.Id);
    if (a > -1) {
      table[a].Quantity += Quantity;
      setTable([...table]);
    } else {
      row.Quantity = Quantity;
      setTable((x) => [...x, row]);
    }
  }

  function DeleteShipmentRow(id) {
    RemovePallet(id, PalletID.Id).then(({ status, Message }) => {
      if (200 === status) {
        Notification("success", "success : " + id);
        var a = Table.filter((x) => x?.Id !== id);
        var b = Table.find((x) => x?.Id === id);
        setTable([]);
        setDeletRowId(0);
        setTimeout(() => {
          setTable(a);
          setDeletRowId(b);
        }, 1);
      } else {
        Notification("error", Message?.toString());
      }
    });
  }

  return (
    <>
      <TableComponent
        list={Table}
        PalletID={PalletID?.Id}
        Pallet={PalletID}
        DeleteShipmentRow={DeleteShipmentRow}
        ReloadPallets={ReloadPallets}
        setPallet={setPallet}
        isClosed={PalletID?.Status === "Closed"}
      />

      {ReloadPalletsList && PalletID?.Status !== "Closed" && (
        <IndexPalletsList
          SetTableShipment={SetTableShipment}
          DeletRowId={DeletRowId}
          Pallet={PalletID}
          ReloadPallets={ReloadPallets}
          setSearch={setSearch}
          Search={Search}
        />
      )}
      {PalletID?.Status === "Closed" && <Nodatafound />}
    </>
  );
}
async function RemovePallet(PalletId, ShipmentID) {
  return await fetch(`${window.$apiurl}/pallets/DeleteItemFromPallet`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      PalletId: ShipmentID,
      ItemId: PalletId,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    var a = { status: response.status, Message: msg?.Message ?? "" };
    return a;
  });
}

async function GetItemsForPallet(id, setTable) {
  return await fetch(`${window.$apiurl}/pallets/${id}`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      var a = res[1]?.pallet.ItemsList ?? [];
      a.forEach((x) => {
        x.Id = x.ItemId;
      });
      setTable(a);
    });
}

function Nodatafound() {
  return (
    <>
      <div className="col-lg-12 row mt-5 pt-5">
        <div className="col-lg-4" />
        <div className="col-lg-1 text-right">
          <i
            className="fas fa-quote-left"
            style={{ fontSize: "70px", opacity: 0.3 }}
          />
        </div>
        <div className="col-lg-4 mt-2">
          <p className="h1 mt-5" style={{ opacity: 0.3 }}>
            Pallet Is Closed
          </p>
        </div>
        <div className="col-lg-3" />
      </div>
    </>
  );
}
