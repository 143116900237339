// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../_redux/Transload/ManageAddress/customersActions";
import {
  getSelectRow,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../../_metronic/_helpers";
import * as uiHelpers from "../CustomersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useCustomersUIContext } from "../CustomersUIContext";

export function CustomersTable() {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      setIds: customersUIContext.setIds,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      openEditCustomerDialog: customersUIContext.openEditCustomerDialog,
      openDeleteCustomerDialog: customersUIContext.openDeleteCustomerDialog,
    };
  }, [customersUIContext]);

  // Getting curret state of customers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.customers }),
    shallowEqual
  );
  const { totalCount, entities } = currentState;

  // Customers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    customersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchCustomers(customersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Code",
      text: "ADDRESS CODE",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "Name",
      text: "ORGANISATION NAME",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "State",
      text: "STATE",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "Type",
      text: "ORG TYPE",
      sort: true,
      sortCaret: sortCaret,
    },   
     {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            className={"label label-lg label-light-primary label-inline"}
            style={{ cursor: "pointer" }}
          >
            {row?.CustomerName ?? "Empty"}
          </span>
        );
      },
    },
    {
      dataField: "IsActive",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCustomerDialog: customersUIProps.openEditCustomerDialog,
        openDeleteCustomerDialog: customersUIProps.openDeleteCustomerDialog,
      },
      classes: "text-center pr-0",
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center"
        bootstrap4
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        pagination={paginationFactory({
          showTotal: true,
          sizePerPageList: [
            { text: "10", value: 10 },
            { text: "25", value: 25 },
            { text: "50", value: 50 },
            {
              text: "All",
              value: entities?.length,
            },
          ],
        })}
        selectRow={getSelectRow({
          entities,
          ids: customersUIProps.ids,
          setIds: customersUIProps.setIds,
        })}
      />
    </>
  );
}
