/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../../_metronic/_partials/controls";

export function RemarkEditDialogHeader({ id, onHide }) {
  const [title, setTitle] = useState("");
  // Remarks Redux state
  const { remarkForEdit, actionsLoading } = useSelector(
    (state) => ({
      remarkForEdit: state.Receiving.remarkForEdit,
      actionsLoading: state.Receiving.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? "" : "New Receipt Item";
    if (remarkForEdit && id) {
      _title = "Edit Receipt Item #: " + id;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [remarkForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        <IconButton
          
          aria-label="Close"
          onClick={(e) => {
            try {
              onHide(false);
            } catch (error) {}
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
    </>
  );
}
