import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import Select from "react-select";
import { AR } from './component/Ar';
import { Volume } from './component/Volume';
export function UserDashboardIndex() {

    return (
        <>
            <div className='flex-row-auto offcanvas-mobile col-xl-12 col-lg-12 col-12'>
                <div className='card mb-5'>
                    <div className='card-header'>
                        <div className='card-title'>
                            <h3 className="card-label">User Dashboard</h3>
                        </div>
                        <form className="form d-flex align-items-md-center flex-sm-row flex-column flex-grow-1 flex-sm-grow-0">
                            {/*begin::Input*/}
                            <div className='col-5'>
                                <div class="form-group">
                                    <label className="font-weight-bolder">Customer</label>
                                    <Select
                                        isSearchable={true}
                                        isClearable
                                    />
                                </div>

                            </div>
                            <span className="bullet bullet-ver h-25px d-none d-sm-flex mr-2" />
                            <div className='col-6'>
                                <div class="form-group">
                                    <label className="font-weight-bolder">Date</label>
                                    <RangeDatePicker
                                        startDate={new Date(2020, 0, 15)}
                                        endDate={new Date(2020, 1, 1)}
                                    />
                                </div>

                            </div>
                            {/*end::Input*/}

                            <button type="submit" className="btn btn-link-dark font-weight-bold mt-3 mt-sm-0 px-7">
                                <span className="svg-icon svg-icon-lg">
                                    {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/General/Search.svg*/}
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                            <rect x={0} y={0} width={24} height={24} />
                                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                        </g>
                                    </svg>
                                    {/*end::Svg Icon*/}
                                </span>
                            </button>
                        </form>
                    </div>
                </div>
                <Row>
                    <Col xl="4" lg="4" md="12" xs="12">
                        <Volume className="card-stretch gutter-b" />
                    </Col>
                    <Col xl="8" lg="8" md="12" xs="12">
                        <AR className="card-stretch gutter-b" />
                    </Col>
                </Row>
            </div>

        </>
    )
}
