/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import axios from "axios";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { toAbsoluteUrl } from "../../../_helpers";
let userDefault = {
  FullName: "",
  Email: "",
  ProfilePicture: "",
};
export function QuickUserToggler() {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);
  const [userLogin, setuserLogin] = useState(userDefault);
  axios.get("auth/Login").then((res) => {
    if (res.data !== undefined) {
      userDefault = {
        FullName: res.data.FullName,
        Email: res.data.Email,
        ProfilePicture: res.data.ProfilePicture,
      };
      setuserLogin(userDefault);
    }
  });
  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                  Hi,
                </span>
                <span
                  className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
                  id="FullUserNameProfile2"
                >
                  {userLogin.FullName}
                </span>
                <span className="symbol symbol-35 symbol-light-success">
                  <span
                    className="symbol-label font-size-h5 font-weight-bold"
                    id="FirstCharFullNameNameProfile"
                    style ={{backgroundImage: userLogin.ProfilePicture!== null && userLogin.ProfilePicture!== undefined ? ("url('"+userLogin?.ProfilePicture+"')"): (`url(${toAbsoluteUrl("/media/users/blank.png")}`)}}
                  >
                    {userLogin?.ProfilePicture === undefined &&
                      userLogin?.FullName !== undefined ? userLogin?.FullName[0] : ""}
                    <span className="d-none" id="ProfilePictureID">
                        {userLogin?.FullName !== undefined ? userLogin?.FullName[0] : ""}
                    </span>
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
