export default [
  {
    id: 1,
    CompanyName:"",
    InsuranceType:"",
    PolicyNumber:"",
    SourceType:"",
    EffectiveDate:"",
    ExpirationDate:"",
    InsuredAmount:"",
    InsuredPerPound:"",
    Phone:"",				
  },
 ];
