import React, { useState, useEffect } from "react";
import { AssignToWareHouse } from "./AssignToWareHouse";
import { AssignToWareHousePage } from "./AssignToWareHousePage";
import { AssigntoOutboundPage } from "./AssigntoOutboundPage";
import { Helmet } from "react-helmet";

export function GlobalTransload() {
  const [Select, setSelect] = useState(1);
  return (
    <>
      <Helmet>
        <title>Assign to Warehouse | eMPower Data Logistics</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>

      <div className="card">
        <div className="form-group row m-5">
          <div className="col-lg-6">
            <button
              type="button"
              className={
                "btn w-100 btn-" + (Select === 1 ? "primary" : "light")
              }
              onClick={() => {
                document.title = "Assign to Warehouse | eMPower Data Logistics";
                setSelect(1);
              }}
            >
              Assign to Warehouse
            </button>
          </div>
          <div className="col-lg-6">
            <button
              type="button"
              className={
                "btn w-100 btn-" + (Select === 2 ? "primary" : "light")
              }
              onClick={() => {
                document.title = "Assign to Outbound | eMPower Data Logistics";
                setSelect(2);
              }}
            >
              Assign to Outbound
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className={Select === 1 ? "" : "d-none"}>
            <AssignToWareHousePage />
          </div>
          <div className={Select === 2 ? "" : "d-none"}>
            <AssigntoOutboundPage />
          </div>
        </div>
      </div>
    </>
  );
}
