import React from 'react'
import { Button, Form, Card, Dropdown, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export function ManageClassificationsItems() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">CREATE</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>

                        <div class=' col-4'>
                            <Form.Label>Parent</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-4'>
                            <Form.Label>Title </Form.Label>
                            <Form.Control type="text" placeholder="Title" />
                        </div>

                        <div class='col-4'>
                            <Form.Label>Description </Form.Label>
                            <Form.Control type="text" placeholder="Description" />
                        </div>

                        <div class='col-12 mt-5'>
                            <Button as="input" type="submit" value="Save" />
                        </div>

                    </Form.Row>

                    <br /><br />
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">UPDATE</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>

                        <div class='col-12 mb-5'>
                            <Form.Label>Classification to update</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-4'>
                            <Form.Label>New Parent</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-4'>
                            <Form.Label>New Title </Form.Label>
                            <Form.Control type="text" placeholder="New Title" />
                        </div>

                        <div class='col-4'>
                            <Form.Label>New Description </Form.Label>
                            <Form.Control type="text" placeholder="New Description" />
                        </div>

                        <div class='col-12 mt-5'>
                            <Button as="input" type="submit" value="Update" />
                        </div>
                    </Form.Row>


                    <br /><br />
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">DELETE</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>
                        <div class='col-4'>
                            <Form.Label>Classification</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-4 mt-5 pl-5'>
                            <Form.Label></Form.Label>
                            <div className="d-flex align-items-center">
                            <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                            <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                              <input type="checkbox" name="" onChange={() => { }} value="1" />
                              <span></span>
                            </label>
                            <div className="d-flex flex-column flex-grow-1">
                              <a href="#" className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Delete Children</a>
                            </div>
                          </div>
                        </div>

                        <div class='col-12 mt-5'>
                            <Button as="input" type="submit" value="DELETE" />
                        </div>
                    </Form.Row>


                    <br /><br />
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">EXISTING</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>
                        <div class='col-12 mt-5'>
                            <p>Tree View </p>
                            <Alert variant="info " className="w-50">
                                Hover over a class for a description.
                            </Alert>

                            <ul className="w-50 list-group">
                                <li class="list-group-item">
                                    <Link to="">ISO</Link></li>
                                <li class="list-group-item">
                                    <Link to="">Thermax</Link>
                                </li>
                            </ul>
                        </div>
                    </Form.Row>

                </Form>
            </Card.Body>
        </Card>
    )
}
