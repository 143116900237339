import { Button, Icon, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import $ from "jquery";
import { Form, Formik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Modal, Spinner } from "react-bootstrap";
import * as Yup from "yup";
import { Notification } from "../../../../../../Notification";
import { ChargesFunction, VendorListComp } from "./RemarkEditForm";
import {
  ChargesOriAddSup,
  NigativePositiveStop,
  LockPayblesButton,
} from "./ChargesFunction";
const adapter = new LocalStorage("db");
const db = low(adapter);
// Validation schema
const catchError = [];

export function ChargesOnly({ actionsLoading, onHide, isId = false, path }) {
  const [Model, setModel] = useState({});
  const [Vendors, setVendors] = useState(catchError);
  const [ReloadCalc, setReloadCalc] = useState(0);
  const [ChargesFunctionOne, setChargesFunctionOne] = useState(false);
  const [ChargesFunctionModel, setChargesFunctionModel] = useState(false);
  const [FreightChargeId, setFreightChargeId] = useState("");
  const [AutoQuantity, setAutoQuantity] = useState("");

  let RemarkEditSchema = Yup.object().shape({
    OrderId: Yup.string().required("Order Option is required"),
    CustomerId: Yup.string().required("Customer Option is required"),
  });

  function setReloadCalcF() {
    setReloadCalc((y) => y + 1);
  }

  useEffect(() => {
    GetViewmodel();
    window.ContainerSaveClose = false;
    window.OneMoveMasterVendors = false;

    // Auto Rate
    window.ARCartons = undefined;
    window.ARTotalWeight1 = undefined;
    window.ARTotalWeight2 = undefined;
    window.ARTotalCube1 = undefined;
  }, []);

  function SetFieldValueFunction(setFieldValue) {
    window.setFieldValue = setFieldValue;
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/vendors", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        setAutoQuantity(window.$Containers_AutoChares);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        GetData(isId);

        const baseData = res[1];
        const vendorList = baseData["Vendors"] || catchError;

        vendorList.forEach((x) => {
          let c = "#000000";
          if (x?.AlertType === "Danger") c = "#ff5555";
          else if (x?.AlertType === "Warning") c = "#bbb000";
          x.Color = c;
        });

        window.FreightCharges = baseData["FreightCharges"] || catchError;
        window.VendorTypeListOption = baseData["VendorTypes"] || catchError;
        window.BillToOptionsList = baseData["BillTo"] || catchError;

        setVendors(vendorList);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetData(isId) {
    return await fetch(window.$apiurl + "/" + path + "/charges/" + isId, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          window.C_ChargesReadOnly =
            res[1]?.Receipt?.AreChargesReadOnly || false;
          window.C_PayablesReadOnly =
            res[1]?.Receipt?.ArePayablesReadOnly || false;

          if (path === "transloadreceipts") {
            res[1].Charges = res[1].Receipt.Charges;
            res[1].Vendors = res[1].Receipt.Vendors;
            res[1].FreightChargeId = res[1].Receipt?.FreightChargeId;
            res[1].ArePayablesReadOnly = res[1].Receipt?.ArePayablesReadOnly;
            res[1].Id = isId;
          } else {
            res[1].Charges = res[1].ViewModel.Charges;
            res[1].Vendors = res[1].ViewModel.Vendors;
            res[1].FreightChargeId = res[1].ViewModel?.FreightChargeId;
            res[1].ArePayablesReadOnly = res[1].ViewModel?.ArePayablesReadOnly;
            res[1].Id = isId;
          }

          window.AutoRating = res[1]?.AutoRating;
          window.ContainerIsHot = res[1]?.IsHot ?? false;
          setModel(res[1]);
          setChargesFunctionOne(true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PutData(isId, viewModal, onHide, hide) {
    return await fetch(window.$apiurl + "/" + path + "/charges/" + isId, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(viewModal),
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          ClosePopup();
          Notification("success", "success");
          if (hide) onHide(false);
        } else Notification("error", "error");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function ClosePopup() {
    const href = window.location.href;
    if (href.indexOf("Billing") > -1) {
      if (
        document.getElementsByClassName("text-info")[0]?.parentElement
          ?.nodeName === "LI"
      ) {
        document.getElementsByClassName("text-info")[0].parentElement.click();
      } else if (
        document.getElementsByClassName("text-info")[1]?.parentElement
          ?.nodeName === "LI"
      ) {
        document.getElementsByClassName("text-info")[0].parentElement.click();
      }
    } else if (href.indexOf("ReceiptsManagement") > -1) {
      document.getElementById("ReLoadTableId").click();
      document.getElementById("refreshReceiptException").click();
    } else if (href.indexOf("ShipmentsManagement") > -1) {
      document.getElementById("ReLoadTableId").click();
      document.getElementById("refreshShipmentsException").click();
    }
  }

  function handleSubmit2(values, hide) {
    if (NigativePositiveStop(values.Charges)) return false;
    values.Charges = ChargesOriAddSup(values.Charges);

    var viewModal = { Charges: values?.Charges, Vendors: values?.Vendors };
    PutData(isId, viewModal, onHide, hide);
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={Model}
        validationSchema={RemarkEditSchema}
        onSubmit={(values) => {}}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg" className=" w-50">
                {path === "transloadreceipts" ? "Container" : "Shipment"} id:{" "}
                {isId}
              </Modal.Title>
              <IconButton
                className="MuiButtonBase-root MuiIconButton-root jss179"
                aria-label="Close"
                onClick={(e) => {
                  onHide(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body>
              {SetFieldValueFunction(setFieldValue)}

              <Accordion defaultActiveKey="0">
                <Form className="form form-label-right">
                  <input
                    type="hidden"
                    id="C_CustomerId"
                    value={values?.CustomerId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_ServiceId"
                    value={values?.ServiceId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_ModeId"
                    value={values?.ModeId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_ServiceLevelId"
                    value={values?.ServiceLevelId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_EquipmentTypeId"
                    value={values?.EquipmentTypeId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_PickedUpDate"
                    value={values?.PickupDate ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_TerminatedDate"
                    value={values?.TerminatedDate ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_EquipmentSizeId"
                    value={values?.EquipmentSizeId ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_ReceivedDate"
                    value={values?.Date}
                  />
                  <input
                    type="hidden"
                    id="C_PuAppointment"
                    value={values?.Date}
                  />
                  <input
                    type="hidden"
                    id="C_fromCity"
                    value={values?.FromCity ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_fromAddress"
                    value={values?.FromAddress ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_fromState"
                    value={values?.FromState ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_fromZipCode"
                    value={values?.FromZipcode ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_toCity"
                    value={values?.ToCity ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_toAddress"
                    value={values?.ToAddress ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_toState"
                    value={values?.ToState ?? ""}
                  />
                  <input
                    type="hidden"
                    id="C_toZipCode"
                    value={values?.ToZipcode ?? ""}
                  />
                  <Modal
                    show={ChargesFunctionModel}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Charges
                      </Modal.Title>
                      <IconButton
                        className="MuiButtonBase-root MuiIconButton-root jss179"
                        aria-label="Close"
                        onClick={() => {
                          setChargesFunctionModel(false);
                          setReloadCalcF();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Modal.Header>
                    <Modal.Body className="row">
                      Do you want to remove the rates?
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setChargesFunctionModel(false);
                          }}
                          className="btn btn-light btn-elevate mr-2"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setChargesFunctionModel(false);
                            setFieldValue("Charges", []);
                            setReloadCalcF();
                          }}
                          className="btn btn-success btn-elevate"
                        >
                          Yes
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {((path !== "transloadreceipts" &&
                    values?.FreightChargeId === 98) ||
                    path === "transloadreceipts") && (
                    <>
                      <Accordion.Toggle
                        as={Button}
                        className="w-100 text-left p-0 position-relative text-decoration-none ChargesBtn"
                        variant="link"
                        eventKey="0"
                      >
                        <Alert
                          variant="primary"
                          className="pt-3 pl-3 pb-0 w-100"
                        >
                          <h5 className="font-weight-bold">
                            Receivables{" "}
                            {!values?.CustomerId && (
                              <small className="mx-5">
                                * Note : Please Select Customer
                              </small>
                            )}
                            {!ChargesFunctionOne && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="p-2 ml-5"
                              />
                            )}
                          </h5>
                        </Alert>
                        <Icon
                          className={clsx(
                            "fa fa-chevron-down position-absolute text-white"
                          )}
                          style={{ fontSize: 17, right: "1rem", top: "20%" }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse
                        eventKey="0"
                        className="ChargesBtnDiv"
                      >
                        <>
                          <div className="form-group row">
                            <div className="col-lg-12 mt-5">
                              {ChargesFunctionOne &&
                              values?.Charges?.length > -1 &&
                              values?.CustomerId ? (
                                <ChargesFunction
                                  isInbound={path === "transloadreceipts"}
                                  isPayable={false}
                                  type="Charges"
                                  autoQuantity={AutoQuantity}
                                  list={values?.Charges || []}
                                  ReloadCalc={ReloadCalc}
                                  setFieldValue={setFieldValue}
                                />
                              ) : (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  role="status"
                                  aria-hidden="true"
                                  className="p-2 ml-5"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      </Accordion.Collapse>
                    </>
                  )}
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="422"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">
                        Vendors List
                        {values?.Id && (
                          <LockPayblesButton
                            Id={values?.Id}
                            IsReceipt={path === "transloadreceipts"}
                            FreightChargeId={values?.FreightChargeId}
                            setReloadCalcF={setReloadCalcF}
                            ArePayablesReadOnly={values?.ArePayablesReadOnly}
                            setArePayablesReadOnly={(e) => {
                              window.C_PayablesReadOnly = e;
                              window.ChargesReadOnly = e;
                              setFieldValue("ArePayablesReadOnly", e);
                              var eles = document.querySelectorAll(
                                ".setChargesReadOnly"
                              );
                              eles.forEach((ele) => {
                                ele.click();
                              });
                            }}
                          />
                        )}
                        {!values?.CustomerId && (
                          <small className="mx-5">
                            * Note : Please Select Customer
                          </small>
                        )}
                        {!ChargesFunctionOne && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="p-2 ml-5"
                          />
                        )}
                      </h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="422">
                    {ChargesFunctionOne &&
                    values?.Charges?.length > -1 &&
                    values?.CustomerId ? (
                      <VendorListComp
                        ChargesFunctionOne={ChargesFunctionOne}
                        ReloadCalc={ReloadCalc}
                        setReloadCalcF={setReloadCalcF}
                        CustomVendors={Vendors}
                        PayablesReadOnly={values?.ArePayablesReadOnly}
                        Vendors={values?.Vendors || []}
                        FreightChargeId={FreightChargeId}
                        isInbound={path === "transloadreceipts"}
                      />
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        className="p-2 ml-5"
                      />
                    )}
                  </Accordion.Collapse>
                </Form>
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  if (window.ReceiptManagmetShow) {
                    $("#ReLoadTableId").trigger("click");
                  }
                  onHide();
                  if (window.location.href.indexOf("Billing") > -1) {
                    document
                      .getElementsByClassName("text-info")[0]
                      .parentElement.click();
                  }
                }}
                className="btn btn-light btn-elevate"
              >
                Close
              </button>
              <button
                type="submit"
                disabled={!ChargesFunctionOne}
                aria-disabled="true"
                onClick={(e) => {
                  if (!e.target.classList.contains("disabled")) {
                    handleSubmit2(values, false);
                  }
                }}
                id="AddContainerID"
                className="btn btn-primary btn-elevate SaveDisabled disabled"
              >
                Save
              </button>
              <button
                type="submit"
                disabled={!ChargesFunctionOne}
                onClick={(e) => {
                  if (!e.target.classList.contains("disabled")) {
                    handleSubmit2(values, true);
                  }
                }}
                className="btn btn-primary btn-elevate SaveDisabled disabled"
              >
                Save & Close
                {!ChargesFunctionOne && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
