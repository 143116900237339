import axios from "axios";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import {
  is,
  IsValidMessage
} from "../../../../../../_metronic/_helpers/ATHelpers";
import { CreatableSelect3 } from "../../../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  defaultValue = false,
}) {
  const [Customers, setCustomers] = useState([]);
  const [warehouses, setwarehouses] = useState([]);
  const [Locations, setLocations] = useState([]);
  const [consignees, setconsignees] = useState([]);
  const [shippers, setshippers] = useState([]);
  const [shipperIds, setshipperIds] = useState();
  const [CustomerIdDisabled, setCustomerIdDisabled] = useState(false);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/PalletsManagment/GetAllApi");
      setCustomers(result.data["customers"] || []);
      setwarehouses(result.data["warehouses"] || []);
      setconsignees(result.data["consignees"] || []);
      setshippers(result.data["shippers"] || []);
    } catch (e) {}
  };

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  useEffect(() => {
    if (customer?.CustomerId) setCustomerIdDisabled(true);
  }, [customer?.CustomerId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          window.IsValid = false;
          IsValidMessage("Customer", values?.CustomerId);
          IsValidMessage("ConsigneeId", values?.ConsigneeId);
          IsValidMessage("Warehouse", values?.WarehouseId);
          IsValidMessage("Shipper", values?.ShipperId);
          IsValidMessage("Location", values?.LocationId);
          if (window.IsValid) return false;
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overlay">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect3
                      label="Customer"
                      name="CustomerId"
                      setFieldValue={setFieldValue}
                      required={true}
                      isDisabled={CustomerIdDisabled}
                      valueSelect={values?.CustomerId}
                      options={Customers.map((x) => {
                        return {
                          value: x?.Id,
                          label: is(x?.Code) + x?.Name,
                        };
                      })}
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect3
                      label="To Consignee"
                      required={true}
                      name="ConsigneeId"
                      setFieldValue={setFieldValue}
                      valueSelect={values?.ConsigneeId}
                      options={consignees.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect3
                      label="Shipper"
                      required={true}
                      name="ShipperId"
                      setFieldValue={setFieldValue}
                      /* valueSelect={shipperIds} */
                      valueSelect={values?.ShipperId}
                      options={shippers.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(e) => {
                        setshipperIds(e?.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect3
                      label="Warehouse"
                      required={true}
                      name="WarehouseId"
                      setFieldValue={setFieldValue}
                      valueSelect={values?.WarehouseId}
                      options={warehouses.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      onChange={(e) => {
                        const a = warehouses.find((x) => x?.Id === e?.value);
                        setLocations([]);
                        setTimeout(() => {
                          setLocations(a?.Locations || []);
                        }, 1);
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mt-5">
                    <CreatableSelect3
                      label="Warehouse Location"
                      name="LocationId"
                      setFieldValue={setFieldValue}
                      required={true}
                      valueSelect={values?.LocationId}
                      options={Locations.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Description,
                        };
                      })}
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
