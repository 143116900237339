import React, { useMemo } from "react";
import {
  Card,
  CardBody,
} from "../../../../../_metronic/_partials/controls";
import { CustomersTable } from "./customers-table/CustomersTable";
import { useCustomersUIContext } from "./CustomersUIContext";

export function CustomersCard() {
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  return (
    <Card>
      <CardBody>
        
            <CustomersTable />
      </CardBody>
    </Card>
  );
}
