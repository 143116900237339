import React from 'react';
import clsx from 'clsx';
import {
  Button,
  TextField,
  withStyles,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}))(TableCell);
const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);
const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(auth, id, description) {
    return { auth, id, description };
}

const rows = [
    createData("no", 0, "Standar Template"),
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));

export function PackingSlipTemplates() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
    }
    const [state, setState] = React.useState({
        checkedA: true,
        checkedB: true,
        checkedF: true,
        checkedG: true,
    });

    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };
    return (

        <Paper className={classes.root}>
            <div className="col-lg-3 my-5">
                <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                // TODO: Change this code
                >
                    <option value="">Barkman Honey</option>
                    <option value="0">BASF</option>
                    <option value="1">DDP Specialty Electronic</option>
                </select>
                <small className="form-text text-muted">
                    <b>Choose</b> a Customer
            </small>
            </div>
            <Table className={`mx-4 col-md-8 mb-5 ${classes.table}`}>
                <TableHead>
                    <TableRow>
                        <StyledTableCell >Auth</StyledTableCell>
                        <StyledTableCell >ID</StyledTableCell>
                        <StyledTableCell >Description</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell>{row.auth}</StyledTableCell>
                            <StyledTableCell>{row.id}</StyledTableCell>
                            <StyledTableCell>{row.description}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <div className="container  mb-5">
                <React.Fragment>
                    <Button className="bg-primary text-white" onClick={handleClickOpen}>
                        EDIT
                    </Button>
                    <Dialog
                        fullWidth={true}
                        maxWidth={'sm'}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogTitle id="max-width-dialog-title">EDIT</DialogTitle>
                        <DialogContent>

                            <form className={classes.form} noValidate>
                                <FormControl className={classes.formControl}>
                                    <TextField
                                        id="filled-dense"
                                        label="iD"
                                        className={clsx(classes.textField, classes.dense)}
                                        margin="dense"
                                        variant="filled"
                                        value="0"
                                    />
                                    <TextField
                                        id="filled-textarea"
                                        label="DESCRIPTION"
                                        placeholder="Placeholder"
                                        multiline
                                        className={classes.textField}
                                        margin="normal"
                                        variant="filled"
                                        value="Standard Template"
                                    />
                                    <FormControlLabel
                                        control={
                                            <GreenCheckbox
                                                onChange={handleChange('checkedG')}
                                                value="checkedG"
                                            />
                                        }
                                        label="Custom color"
                                    />
                                    <DialogContentText>
                                        Packing slips may be customized by specifying, among other things, two images: one at the top of each packing slip to show, for instance, your logo; and another at the foot of each packing slip for 'boiler plate' language.
                                </DialogContentText>
                                    <DialogContentText>
                                        Each image is specified by a Uniform Resource Locator (URL). For example:
                                </DialogContentText>
                                    <DialogContentText>
                                        <a
                                            href="http://www.yourdomain.com/images/MyCompanyLogo.jpg."
                                            className="font-weight-bold text-dark-75 text-hover-primary font-size-lg mb-1"
                                        >
                                            http://www.yourdomain.com/images/MyCompanyLogo.jpg.
                                </a>
                                    </DialogContentText>
                                    <TextField
                                        id="outlined-name"
                                        label="LOGO URL"
                                        className={classes.textField}
                                        onChange={handleChange('name')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                     <TextField
                                        id="outlined-name"
                                        label="FOOTER URL"
                                        className={classes.textField}
                                        onChange={handleChange('name')}
                                        margin="normal"
                                        variant="outlined"
                                    />
                                </FormControl>
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <Button color="primary">
                                save
          </Button>
                            <Button onClick={handleClose}>
                                Close
          </Button>

                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            </div>
        </Paper>
    );
}