import React, { useEffect, useState } from "react";
import { Modal, Form, Card, Alert, Accordion, Button } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
  RadioButton,
} from "../../../../../../_metronic/_partials/controls";
import CreatableSelect from "react-select/creatable";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import LinearProgress from "@material-ui/core/LinearProgress";
import moment from "moment";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
const adapter = new LocalStorage("db");
const db = low(adapter);
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  Contacts,
  show
}) {
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      Name: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Warehouse Name is required"),
      Code: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Warehouse Name is required"),
      NumberPerLocation: Yup.string().required("Warehouse Name is required"),
      Email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({
      Email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
    });
  }

  const [timeZoneInfoList, setTimeZoneInfoList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [isActive, setisActive] = useState(false);

  useEffect(() => {
    fetchUsersAsync();
  }, []);

  useEffect(() => {
    if (customer.IsActive !== undefined) {
      var a = customer.IsActive ? "true" : "false";
      setisActive(a);
    }
  }, [customer]);

  const fetchUsersAsync = async () => {
    try {
      const result = await axios.get("api/Warehouses/GetAllApi");
      setTimeZoneInfoList(result.data["TimeZoneInfo"]);
      setCountriesList(result.data["Countries"]);
    } catch (e) {}
  };

  function addClick() {
    setusers((prevState) => [
      ...prevState,
      {
        Id: 0,
        Category: "",
        CategoryId: "",
        FirstName: "",
        LastName: "",
        Name: "",
        WorkPhone: "",
        Cellular: "",
        HomePhone: "",
        Email: "",
        Fax: "",
        Birthday: "",
      },
    ]);
  }

  const [users, setusers] = useState([
    {
      Id: 0,
      Category: "",
      CategoryId: "",
      FirstName: "",
      LastName: "",
      Name: "",
      WorkPhone: "",
      Cellular: "",
      HomePhone: "",
      Email: "",
      Fax: "",
      Birthday: "",
    },
  ]);

  useEffect(() => {
    if (!!Contacts) {
      if (!!Contacts[0]?.CategoryId) {
        setusers(Contacts);
      }else{
        setusers([
          {
            Id: 0,
            Category: "",
            CategoryId: "",
            FirstName: "",
            LastName: "",
            Name: "",
            WorkPhone: "",
            Cellular: "",
            HomePhone: "",
            Email: "",
            Fax: "",
            Birthday: "",
          },
        ]);
      }
    }
  }, [Contacts]);

  function removeClickContact(i) {
    let user = [...users];
    user.splice(i, 1);
    setusers(user);
  }

  const [CategoriesList, setCategoriesList] = useState([]);

  function createUI() {
    return users.map((el, i) => (
      <div className="card mt-md-9 bg-light" key={i}>
        <div className="card-body">
          <div className="row">
            <div className="col" style={{ textAlign: "right" }}>
              <button
                type="button"
                className={"btn w-40 btnCloseBlack"}
                onClick={removeClickContact.bind(this, i)}
              >
                <i
                  class="fas fa-times"
                  style={{ paddingRight: "initial", color: "white" }}
                ></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {el?.Name !== "" && (
                <div className="form-group row">
                  <div className="col-lg-12">
                    <h2>Contact : {el?.Name}</h2>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <div className="col-lg-12">
                  <label>
                    Category{" "}
                    <span 
                      className="text-danger font-weight-bold"
                      style={{fontSize: "20px" }}
                      >*</span>
                  </label>
                  <CreatableSelect
                    name="CategoryId"
                    label="Category"
                    value={
                      CategoriesList.find((x) => x.Id === el.CategoryId) !==
                        undefined && {
                        value: el.CategoryId,
                        label: el.Category,
                      }
                    }
                    onChange={(newValue) => {
                      let values = [...users];
                      values[i].Category = newValue?.label;
                      values[i].CategoryId = newValue.value;
                      setusers(values);
                    }}
                    options={CategoriesList.map((x) => {
                      return {
                        value: x.Id,
                        label: x?.Name,
                      };
                    })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Name"
                    component={Input}
                    placeholder="First Name"
                    label="First Name"
                    required={true}
                    value={el.FirstName}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].FirstName = event.target.value;
                      if (event.target.value && values[i].LastName) {
                        values[i].Name =
                          event.target.value + " " + values[i].LastName;
                      } else {
                        values[i].Name = "";
                      }
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="LastName"
                    required={true}
                    component={Input}
                    placeholder="Last Name (Or Department)"
                    label="Last Name (Or Department)"
                    value={el.LastName}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].LastName = event.target.value;
                      if (event.target.value !== "" && values[i].FirstName) {
                        values[i].Name =
                          values[i].FirstName + " " + event.target.value;
                      } else {
                        values[i].Name = "";
                      }
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="WorkPhone"
                    component={Input}
                    placeholder="WorkPhone"
                    label="WorkPhone"
                    type="tel"
                    value={el.WorkPhone}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].WorkPhone = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="CellPhone"
                    component={Input}
                    placeholder="Cellular"
                    label="Cellular"
                    value={el.Cellular}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Cellular = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="HomePhone"
                    component={Input}
                    placeholder="HomePhone"
                    label="HomePhone"
                    type="tel"
                    value={el.HomePhone}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].HomePhone = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Email"
                    component={Input}
                    placeholder="Email"
                    required={true}
                    label="Email"
                    value={el.Email}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Email = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Fax"
                    component={Input}
                    placeholder="Fax"
                    label="Fax"
                    type="tel"
                    value={el.Fax}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Fax = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <label>Birthday</label>
                  <input
                    className="form-control"
                    type="date"
                    value={moment(el.Birthday).format("YYYY-MM-DD") || ""}
                    onChange={(e) => {
                      let values = [...users];
                      values[i].Birthday = e.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }

  const fetchCategories = async () => {
    try {
      if (CategoriesList.length === 0) {
        await fetch(window.$apiurl + "/contact/getviewmodel", {
          method: "get",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            setCategoriesList(res.ContactCategories || []);
          })
          .catch((err) => {
            return false;
          });
      }
    } catch (e) {}
  };

  useEffect(() => {
    fetchCategories();
  }, []);


  return (
    <>
      <Accordion defaultActiveKey="0">
        <Formik
          enableReinitialize={true}
          initialValues={customer}
          validationSchema={CustomerEditSchema}
          onSubmit={(values) => {
            values = JSON.parse(JSON.stringify(values));
            values.Contacts = users;
            saveCustomer(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Modal.Body>
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}

                <Form className="form form-label-right">
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="0"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CONTACT INFORMATION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className=" p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="Name"
                            component={Input}
                            placeholder="Warehouse Name"
                            label="Warehouse Name"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="Code"
                            component={Input}
                            placeholder="Warehouse Code"
                            label="Warehouse Code"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="ExternalId"
                            component={Input}
                            placeholder="ExternalId"
                            label="ExternalId"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <RadioButton
                            name="IsActive2"
                            label="Status"
                            row
                            value={isActive}
                            onChange={(e) => {
                              setisActive(e.target.value);
                              setFieldValue("IsActive", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio color="primary" />}
                              label="Activate"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio color="primary" />}
                              label="Deactivate"
                            />
                          </RadioButton>
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            name="Address1"
                            component={Input}
                            placeholder="Address1"
                            label="Address1"
                          />
                        </div>

                        <div className="col-lg-4 mt-5">
                          <Field
                            name="Address2"
                            component={Input}
                            placeholder="Address2"
                            label="Address2"
                          />
                        </div>

                        <div className="col-lg-4 mt-5">
                          <Select name="Country" label="Country">
                            <option>Select</option>
                            {countriesList.map((item, index) => (
                              <option value={item.Id}>{item.Name}</option>
                            ))}
                          </Select>
                          {countriesList.length === 0 && <LinearProgress />}
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            name="State"
                            component={Input}
                            placeholder="State/Province/Region"
                            label="State/Province/Region"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            name="City"
                            component={Input}
                            placeholder="City"
                            label="City"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            name="ZipCode"
                            component={Input}
                            placeholder="Zip/Postal"
                            label="Zip/Postal"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Select name="TimeZone" label="Time Zone">
                            <option>Select</option>
                            {timeZoneInfoList.length > 0 &&
                              timeZoneInfoList
                                .filter(
                                  (x) =>
                                    x ===
                                      "(UTC-06:00) Central Time (US & Canada)" ||
                                    x === "(UTC-06:00) Central America"
                                )
                                .map((item, index) => (
                                  <option value={item}>{item}</option>
                                ))}
                          </Select>
                          {timeZoneInfoList.length === 0 && <LinearProgress />}
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            type="number"
                            name="PhoneNumber"
                            component={Input}
                            placeholder="Phone Number"
                            label="Phone Number"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            type="number"
                            name="Fax"
                            component={Input}
                            placeholder="Fax Number"
                            label="Fax Number"
                          />
                        </div>
                        <div className="col-lg-4 mt-5">
                          <Field
                            type="email"
                            name="Email"
                            placeholder="Email"
                            component={Input}
                            label="Email"
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="1"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">ACCOUNTS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="1">
                    <Card.Body className=" p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="UpsNumber"
                            component={Input}
                            placeholder="UPS Account Number"
                            label="UPS Account Number"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="FedExNumber"
                            component={Input}
                            placeholder="Warehouse FedExAccount"
                            label="Warehouse FedExAccount"
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="2"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CUSTOMIZATIONS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>

                  <Accordion.Collapse eventKey="2">
                    <Card.Body className="p-2">
                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Field
                            name="FuelSurcharge"
                            component={Input}
                            placeholder="Fuel Surcharge"
                            label="Fuel Surcharge"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Field
                            name="FreightRate"
                            component={Input}
                            placeholder="Freight Rate Location Code"
                            label="Freight Rate Location Code"
                          />
                        </div>
                        <div className="col-lg-4">
                          <Select
                            name="NumberPerLocation"
                            label="Number of Fields Per Location"
                          >
                            <option>Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                          </Select>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-lg-4">
                          <Select name="IsMetric" label="IsMetric">
                            <option value="">Select</option>
                            <option value="false">Imperial</option>
                            <option value="true">Metric</option>
                          </Select>
                        </div>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="3"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CONTACTS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3" className="mb-4">
                    <div>
                      <div className="card">
                        <h6
                          className="p-5 text-primary"
                          style={{ marginBottom: "-2rem" }}
                        >
                          Contact Information
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div
                              className="card-body"
                              id="another-participant1"
                            >
                              {createUI()}
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col">
                              <button
                                type="button"
                                onClick={addClick.bind(this)}
                                style={{ float: "right", width: "100%" }}
                                className="btn btn-light-success w-40 mt-md-8"
                              >
                                <i
                                  className={`fas fa-plus fa-sm`}
                                  style={{ paddingRight: "initial" }}
                                ></i>{" "}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "0.1rem",
                                  }}
                                >
                                  Add
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Accordion>
    </>
  );
}
