import React from 'react';
import Button from '@material-ui/core/Button';
import {  makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    textDecoration: {
        lineHeight: '2rem',
    },
    dNone: {
        display: "none",
    }
}));
export function ManageZoneBilling() {
    const classes = useStyles();
    return (
        <Paper className={classes.root}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 my-5">
                        <div className="row">
                            <div className="col-md-2">
                                <label class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
                                    Zone Name
                                </label>
                            </div>
                            <div className="col-md-2">
                                <input type="text" class="my-4 m-0 pt-5 ml-3" />
                            </div>
                            <div className="col-md-2">
                                <label class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
                                    Zone Name
                                </label>
                            </div>
                            <div className="col-md-2">

                            </div>
                        </div>
                        <div className={classes.dNone}>
                            <div className="row">
                                <div className="col-md-3">
                                    <label class="text-dark-50 m-0 pt-5 font-weight-normal my-4">
                                        Import
                                </label>
                                </div>
                                <div className="col-md-4">
                                    <input type="file" class="my-4 m-0 pt-5" />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-3">
                                    <div className="my-4 m-0">
                                        <Button className="bg-primary text-white">
                                            Import Zones
                                    </Button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <span className={classes.textDecoration}>
                                        Use the 'Browse...' button to select an zone import file
                                        Then press the 'Import Zones' button to read the file.
                                </span>
                                </div>
                            </div>
                            <label class="text-dark-50 m-0 pt-5 font-weight-normal">
                                Zones To Be Updated
                        </label>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="my-4 m-0">
                                        <Button className="bg-primary text-white">
                                            Update Zones
                                    </Button>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <span className={classes.textDecoration}>
                                        After previewing the imported data for accuracy
                                        Press the 'Update Zones' button to update the Zones.
                                </span>
                                </div>
                            </div>
                            <label class="text-dark-50 m-0 pt-5 font-weight-normal">
                                Data From Import File
                            </label>
                            <div className="row">
                                <textarea class="text-dark-50 m-0 pt-5 font-weight-normal pl-5 ml-5" rows="4" cols="70"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Paper>
    );
}