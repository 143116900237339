/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import objectPath from "object-path";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getBreadcrumbsAndTitle, useHtmlClassService, useSubheader } from "../../..";
import { checkIsActive } from "../../../../_helpers";
import { BreadCrumbs } from "../../subheader/components/BreadCrumbs";

export function HeaderMenu({ TablsOptionsList }) {
    const subheader = useSubheader();
    const uiService = useHtmlClassService();
    const location = useLocation();
    const layoutProps = useMemo(() => {
        return {
          config: uiService.config,
          subheaderMobileToggle: objectPath.get(
            uiService.config,
            "subheader.mobile-toggle"
          ),
          subheaderCssClasses: uiService.getClasses("subheader", true),
          subheaderContainerCssClasses: uiService.getClasses(
            "subheader_container",
            true
          ),
        };
      }, [uiService]);
      useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
        subheader.setBreadcrumbs(aside.breadcrumbs || header.breadcrumbs);
        subheader.setTitle(aside.title || header.title);
        // eslint-disable-next-line
      }, [location.pathname]);

      useEffect(() => {
        
        setTimeout(() => {
          setstate((x) => x + 1);
        }, 15000);
      }, [subheader, state]);
      const [state, setstate] = useState(0);
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          {/* <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mr-5">
              <>{subheader.title}</>
              <small></small>
            </h5>
          </div> */}

          <BreadCrumbs items={subheader.breadcrumbs} />
        </div>
        {/*end::Header Nav*/}
    </div>;
}
