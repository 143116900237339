import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));

export function CustomerNotifs() {
    const classes = useStyles();

    return (

        <Paper className={classes.root}>
            <div className="col-lg-3 my-5">
                <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                // TODO: Change this code
                >
                    <option value="">Barkman Honey</option>
                    <option value="0">BASF</option>
                    <option value="1">DDP Specialty Electronic</option>
                </select>
                <small className="form-text text-muted">
                    <b>Choose</b> a Customer
            </small>
            </div>
            <div className="container  mb-5">
                <Button className="bg-primary text-white" to="/CustomerNotifsDetails">
                    Select
                </Button>
            </div>
        </Paper>
    );
}