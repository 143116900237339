export default [
    {
        id :1,
        ACCOUNTOWNER :"Mitco, Ltd",
        CARRIER :"UPS",
        ACCOUNTNICKNAME :"MITCO LTD UPS",
        ACCOUNTTYPE :"Facility",
        ACCOUNTNUMBER : "077E1W",
    },
  ];
  