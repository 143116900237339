import React, { useEffect, useState, createRef ,useMemo} from "react";import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import {
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import axios from "axios";
import {
  Select
} from "../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../Notification";
import LinearProgress from "@material-ui/core/LinearProgress";

const prepareFilter = (queryParams, values) => {
  const { ReLoadTable, customerId, warehouseId ,zoneName} = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.CustomerId = +customerId;
  filter.WarehouseId = +warehouseId;
  filter.ReLoadTable = ReLoadTable;
  if(zoneName)
  {
    filter.ZoneName = zoneName;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const [customersList, setcustomersList] = useState([]);
  const [wareHousesList, setwareHousesList] = useState([]);
  const [showProgress, setshowProgress] = useState(false);

  useEffect(() => {
    fetchOrderAsync();
  }, []);
  const fetchOrderAsync = async () => {
    try {
      const result = await axios.get("api/FindReceipt/GetAllApi");
      setcustomersList(result.data["customersList"]);
      setwareHousesList(result.data["warhousesList"]);
    } catch (e) { }
  };
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };
  //Fetsh Select
  const handleCustomerChange = async (event, setFieldValue) => {
    setshowProgress(true);
    setwareHousesList([]);
    if (event.target.value !== "" && event.target.value !== null) {
      setFieldValue("CustomerId", event.target.value)
      const result = await axios.get(`api/receipts/getwarehouses/${event.target.value}`);
      if (result.status == 200) {
        if (result.data.length > 0) {
          setwareHousesList(result.data);
        }
        else{
          setwareHousesList([]);
        }
      }
    }
    setshowProgress(false);
  }
  return (
    <>
      <Formik
        initialValues={{
          warehouseId: 0,
          customerId: 0,
          ReLoadTable: 0,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <input
              type="button"
              className="d-none"
              id="ReLoadTableId"
              onClick={(e) => {
                if (e.target.value == "0")
                  document.getElementById("ReLoadTableId").value = 1;
                else document.getElementById("ReLoadTableId").value = 0;
                setFieldValue("ReLoadTable", e.target.value);
                handleSubmit();
              }}
            />
              <div className="form-group row">
                <div className="col-lg-4">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      aria-label="Text input with dropdown button"
                      value={values.ZoneName}
                      onChange={(e) => {
                        setFieldValue("zoneName", e.target.value);
                        handleSubmit();
                      }}
                    />
                  </div>
                  <small className="form-text text-muted">
                    Zone Name
                  </small>
                </div>
                <div className="col-lg-4">
                  <Select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                    onChange={(e) => {
                      setFieldValue("customerId", e.target.value);
                      handleSubmit();
                      handleCustomerChange(e, setFieldValue);
                      if(values.CustomerId === "0")
                      {
                        values.WarehouseId = "0";
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.CustomerId}
                  >
                    <option key="" value="0">Select</option>
                    {customersList.map((item, index) => (
                        <option key={index} value={item.Id}>{item.Name}</option>
                      ))}
                  </Select>
                  <small className="form-text text-muted">
                    Choose a Customer
                  </small>
                </div>
                <div className="col-lg-4">
                  <Select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                    onChange={(e) => {
                      setFieldValue("warehouseId", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.WarehouseId}
                  >
                    <option key= "" value="0">Select</option>
                    {wareHousesList.map((item, index) => (
                        <option key={index} value={item.Id}>{item.Name}</option>
                      ))}
                  </Select>
                  {showProgress && <LinearProgress />}
                  <small className="form-text text-muted">
                    Warehouse
                  </small>
                </div>
                {/* <div className="col-lg-3">
                  <button
                    type="button"
                    className="btn btn-success btn-shadow-hover mr-2"
                  >
                    Refresh
                </button>
                </div> */}
              </div>
            </form>
          )}
      </Formik>
    </>
  );
}
