import {
  Button,
  FormControlLabel,
  Icon,
  IconButton,
  LinearProgress,
  Radio,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import $, { default as JsNative } from "jquery";
import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Alert,
  Modal,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../../_metronic/_helpers";
import Select from "react-select";
import * as Yup from "yup";
import {
  CheckDetail,
  is,
  IsValidMessage,
  ReturnEmptyOrString,
} from "../../../../../../../../_metronic/_helpers/ATHelpers";
import {
  AttachementsComponent,
  CreatableSelect3,
  DateTime30,
  DateTimeSimple,
  Input,
  NotesComponent,
  RadioButton,
} from "../../../../../../../../_metronic/_partials/controls";
import SelectPagination from "../../../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import { Notification } from "../../../../../../Notification";
import { OneMoveMasterVendors } from "../../../FindOrders/product-Containers/remark-edit-dialog/OneMoveMasterVendors.js";
import { Receiving } from "../../product-Receiving/Remarks";
import { ReceivingUIProvider } from "../../product-Receiving/RemarksUIContext";
import {
  AutoRateComponent,
  AutoRatingCharges,
  EventChangeAutoRate,
  StartAutoRate,
  AutoRatingItem,
  ChargesColor,
  ChargesColorCategory,
  FieldID,
  MenuListComposition,
  RemoveSpace,
  SortAndRename,
  SortOnlyCharge,
  Spinner2s,
  AddSpace3,
  CheckRateInCharges,
  RemoveDuplicate,
  CalculateCharge,
  SetSubTotal,
  RoundToFixed,
  RateClassName,
  isVariableOpenDefault,
  RateReadOnly,
  isChangeFunction,
  ChargesRemoveComponent,
  checkIs0,
  CheckIsNigativePositive,
  CheckIsNigativeRate,
  NigativePositiveStop,
  ChargesOriAddSup,
  GetLengthRound,
  LockPayblesButton,
  IsRounding,
  InputValueCharge,
  InvoiceorBill,
  CategoryTotalId,
  listdistinctFunction,
  ChargesFirstColumn,
  numberofSort,
  HideIsNotBill,
} from "./ChargesFunction";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
const adapter = new LocalStorage("db");
const db = low(adapter);

// Validation schema
const catchError = [];
const obj = {
  Id: 0,
  ExternalId: null,
  Name: "",
  Address: "",
  City: "",
  State: "",
  ZipCode: "",
};

const Title = ({ Str, Required = false }) => {
  return (
    <>
      <label className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-left p-5 my-5 justify-content-start">
        {Str}
        {Required && (
          <i
            class="fa fa-star-of-life text-danger icon-l ml-2"
            style={{ fontSize: "8px" }}
          ></i>
        )}
      </label>
    </>
  );
};
let vendorsOrg = [];
export function RemarkEditForm({
  saveRemark,
  remark,
  actionsLoading,
  onHide,
  isId = false,
}) {
  const [LineItems, setLineItems] = useState(window.$reciept_LineItem || []);
  const [Model, setModel] = useState({});
  const [Customers, setCustomers] = useState(catchError);
  const [BillTo, setBillTo] = useState(catchError);
  const [Shippers, setShippers] = useState(catchError);
  const [ModesStatus, setModesStatus] = useState(catchError);
  const [Consignees, setConsignees] = useState(catchError);
  const [Vendors, setVendors] = useState(catchError);
  const [GenericAddresses, setGenericAddresses] = useState(catchError);
  const [Warehouses, setWarehouses] = useState(catchError);
  const [Services, setServices] = useState(catchError);
  const [NoteCategories, setNoteCategories] = useState(catchError);
  const [ReloadCalc, setReloadCalc] = useState(0);
  const [ChargesFunctionOne, setChargesFunctionOne] = useState(false);
  const [ChargesFunctionModel, setChargesFunctionModel] = useState(false);
  const [FreightChargeId, setFreightChargeId] = useState("");
  const [AutoQuantity, setAutoQuantity] = useState("");
  useEffect(() => {
    setLineItems(window.$reciept_LineItem);
  }, [window.$reciept_LineItem]);
  const [id, setId] = useState(false);
  let RemarkEditSchema = Yup.object().shape({
    OrderId: Yup.string().required("Order Option is required"),
    CustomerId: Yup.string().required("Customer Option is required"),
  });

  function setReloadCalcF() {
    setReloadCalc((y) => y + 1);

    var eles = document.querySelectorAll(".setReloadCalcF");
    eles.forEach((ele) => {
      ele.click();
    });
  }

  useEffect(() => {
    GetViewmodel();
    window.ContainerSaveClose = false;
    window.OneMoveMasterVendors = false;

    // Auto Rate
    window.ARCartons = undefined;
    window.ARTotalWeight1 = undefined;
    window.ARTotalWeight2 = undefined;
    window.ARTotalCube1 = undefined;
  }, []);

  function SetFieldValueFunction(setFieldValue) {
    window.setFieldValue = setFieldValue;
  }

  useEffect(() => {
    if (remark?.Id) {
      window.$ItemsCusId = remark?.CustomerId;
      window.$ItemsRmId = remark?.Id;
    }
    if (remark?.Id) {
      remark = JSON.parse(JSON.stringify(remark));
      setId(remark?.Id);
      window.C_CarrierId = remark?.CarrierId || false;

      window.C_ChargesReadOnly = remark?.AreChargesReadOnly || false;
      window.C_PayablesReadOnly = remark?.ArePayablesReadOnly || false;

      remark.Cartons = remark?.Cartons?.toFixed(2);
      remark.TotalWeight1 = remark?.TotalWeight1?.toFixed(2);
      remark.TotalCube1 = remark?.TotalCube1?.toFixed(2);
    }

    if (!isId) {
      remark.ConsigneeId = window.$NewReceipts?.ConsigneeId;
      remark.CustomerId = window.$NewReceipts?.CustomerId;
      remark.MasterBill = window.$NewReceipts?.MasterBill;
      remark.CustomerRefNumber1 = window.$NewReceipts?.CustomerRefNumber;
      remark.CustomerRefNumber2 = window.$NewReceipts?.CustomerRefNumber2;
      remark.PickupLocationId = window.$NewReceipts?.ShipperId;
      remark.ServiceId = window.$NewReceipts?.ServiceId || 5;
      remark.ModeId = window.$NewReceipts?.ModeId || 7;
      remark.ServiceLevelId = window.$NewReceipts?.ServiceLevelId || 24;
      remark.FreightChargeId = "98";
    }

    var freightChargeId = remark?.FreightChargeId || false;
    if (freightChargeId) {
      setFreightChargeId(freightChargeId + "");
    }

    setModel({});
    setTimeout(() => {
      setModel(remark);
    }, 1);

    if (remark?.Vendors) vendorsOrg = remark?.Vendors;
  }, [remark]);

  function setFreightChargeIdFun(id) {
    setFreightChargeId(id);
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/transload/receipts/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        setAutoQuantity(window.$Containers_AutoChares);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const baseData = res[1]?.ViewModel;
        setCustomers(baseData["Customers"] || catchError);
        setWarehouses(baseData["Warehouses"] || catchError);

        window.FreightCharges = baseData["FreightCharges"] || catchError;
        window.VendorTypeListOption = baseData["VendorTypes"] || catchError;

        const vendorList = baseData["Vendors"] || catchError;
        vendorList.forEach((x) => {
          let c = "#000000";
          if (x?.AlertType === "Danger") c = "#ff5555";
          else if (x?.AlertType === "Warning") c = "#bbb000";
          x.Color = c;
        });
        setVendors(vendorList);

        setGenericAddresses(baseData["GenericAddresses"] || catchError);
        setBillTo(baseData["BillTo"] || catchError);
        setShippers(baseData["Shippers"] || catchError);
        setConsignees(baseData["Consignees"] || catchError);
        setServices(res[1]?.Services || catchError);
        window.UnitOfMeasures = baseData["UnitOfMeasures"] || catchError;
        window.BillToOptionsList = baseData["BillTo"] || catchError;

        setChargesFunctionOne(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={Model}
        validationSchema={RemarkEditSchema}
        onSubmit={(values) => {
          if (NigativePositiveStop(values.Charges)) return false;
          values.Charges = ChargesOriAddSup(values.Charges);

          if (CheckVandorIsDanger(Vendors, values.Vendors)) return false;

          window.IsValid = false;
          values = JSON.parse(JSON.stringify(values));
          values.OrderId = values?.OrderId || window?.FindOrdersTransload;
          values.Charges = values?.Charges || [];
          values.InvoiceNumber = undefined;
          values.InvoiceDate = undefined;

          var i = $(".ChargesBtnDiv").hasClass("show");
          if (i) $(".ChargesBtnDiv").trigger("click");

          IsValidMessage("Freight Charge", values?.FreightChargeId);
          if (window.IsValid) return false;

          (values?.Vendors ?? []).forEach((x, i) => {
            if (x === null) values.Vendors.splice(i, 1);
          });

          SaveDisabledFunction(true);
          saveRemark(values).then(() => {
            SaveDisabledFunction(false);
          });
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              {SetFieldValueFunction(setFieldValue)}

              <Accordion defaultActiveKey="0">
                <Form className="form form-label-right">
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="0"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Container Basic</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <ContainerBasicFun
                      values={values}
                      setFieldValue={setFieldValue}
                      GenericAddresses={GenericAddresses}
                      Customers={Customers}
                      BillTo={BillTo}
                      Shippers={Shippers}
                      setConsignees={setConsignees}
                      setShippers={setShippers}
                      Consignees={Consignees}
                      Vendors={Vendors}
                      Warehouses={Warehouses}
                      ModesStatus={ModesStatus}
                      setChargesFunctionModel={setChargesFunctionModel}
                      setFreightChargeId={setFreightChargeIdFun}
                      FreightChargeId={FreightChargeId}
                      isId={isId}
                      setReloadCalcF={setReloadCalcF.bind()}
                    />
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none ChargesBtn"
                    variant="link"
                    eventKey="01"
                  >
                    <Alert variant="primary" className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">Order Details</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="01" className="ChargesBtnDiv">
                    <OrderDetails
                      values={values}
                      Services={Services}
                      setFieldValue={setFieldValue}
                      setChargesFunctionModel={setChargesFunctionModel}
                      setModesStatus={setModesStatus.bind()}
                      isId={isId}
                      setReloadCalcF={setReloadCalcF.bind()}
                      LineItems={LineItems}
                    />
                  </Accordion.Collapse>
                  <Modal
                    show={ChargesFunctionModel}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Charges
                      </Modal.Title>
                      <IconButton
                        aria-label="Close"
                        onClick={() => {
                          setChargesFunctionModel(false);
                          setReloadCalcF();
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Modal.Header>
                    <Modal.Body className="row ml-1">
                      Do you want to remove the rates?
                    </Modal.Body>
                    <Modal.Footer>
                      <div>
                        <button
                          type="button"
                          onClick={() => {
                            setChargesFunctionModel(false);
                            document.getElementById("setShowMail").click();
                          }}
                          className="btn btn-light btn-elevate mr-2"
                        >
                          No
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setChargesFunctionModel(false);
                            setFieldValue("Charges", []);
                            setReloadCalcF();

                            setTimeout(() => {
                              document.getElementById("setShowMail").click();
                            }, 2000);
                          }}
                          className="btn btn-success btn-elevate"
                        >
                          Yes
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none ChargesBtn"
                    variant="link"
                    eventKey="1"
                  >
                    <Alert variant="primary" className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">
                        Receivables{" "}
                        {!values?.CustomerId && (
                          <small className="mx-5">
                            * Note : Please Select Customer
                          </small>
                        )}
                        {!ChargesFunctionOne && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="p-2 ml-5"
                          />
                        )}
                      </h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1" className="ChargesBtnDiv">
                    <>
                      <div className="form-group row">
                        <div className="col-lg-12 mt-5">
                          {ChargesFunctionOne &&
                          values?.Charges?.length > -1 &&
                          values?.CustomerId ? (
                            <>
                              <ChargesFunction
                                isInbound={true}
                                isPayable={false}
                                type="Charges"
                                autoQuantity={AutoQuantity}
                                list={values?.Charges}
                                ReloadCalc={ReloadCalc}
                                setFieldValue={setFieldValue}
                                ChargesReadOnly={values?.AreChargesReadOnly}
                                AutoRating={values?.AutoRating}
                                IsHot={values?.IsHot}
                                values={values}
                              />
                            </>
                          ) : (
                            <Spinner
                              as="span"
                              animation="border"
                              role="status"
                              aria-hidden="true"
                              className="p-2 ml-5"
                            />
                          )}
                        </div>
                      </div>
                    </>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="422"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                      <h5 className="font-weight-bold">
                        Vendors List{" "}
                        {values?.Id && (
                          <LockPayblesButton
                            Id={values?.Id}
                            IsReceipt={true}
                            FreightChargeId={values?.FreightChargeId}
                            setReloadCalcF={setReloadCalcF}
                            ArePayablesReadOnly={values?.ArePayablesReadOnly}
                            setArePayablesReadOnly={(e) => {
                              window.C_PayablesReadOnly = e;
                              window.ChargesReadOnly = e;
                              setFieldValue("ArePayablesReadOnly", e);
                              var eles = document.querySelectorAll(
                                ".setChargesReadOnly"
                              );
                              eles.forEach((ele) => {
                                ele.click();
                              });
                            }}
                          />
                        )}
                        {!values?.CustomerId && (
                          <small className="mx-5">
                            * Note : Please Select Customer
                          </small>
                        )}
                        {!ChargesFunctionOne && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="p-2 ml-5"
                          />
                        )}
                      </h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="422">
                    {ChargesFunctionOne &&
                    values?.Charges?.length > -1 &&
                    values?.CustomerId ? (
                      <VendorListComp
                        ChargesFunctionOne={ChargesFunctionOne}
                        ReloadCalc={ReloadCalc}
                        ChargesId={""}
                        setReloadCalcF={setReloadCalcF}
                        CustomVendors={Vendors}
                        Vendors={values?.Vendors || []}
                        FreightChargeId={FreightChargeId}
                        isShipment={false}
                      />
                    ) : (
                      <Spinner
                        as="span"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        className="p-2 ml-5"
                      />
                    )}
                  </Accordion.Collapse>
                  <>
                    <Accordion.Toggle
                      as={Button}
                      className="w-100 text-left p-0 position-relative text-decoration-none"
                      variant="link"
                      eventKey="2"
                    >
                      <Alert
                        variant="primary "
                        className="pt-3 pl-3 pb-0 w-100"
                      >
                        <h5 className="font-weight-bold">Receipt Items</h5>
                      </Alert>
                      <Icon
                        className={clsx(
                          "fa fa-chevron-down position-absolute text-white"
                        )}
                        style={{ fontSize: 17, right: "1rem", top: "20%" }}
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <ReceivingUIProvider currentProductId={id}>
                        <Receiving id={id} />
                      </ReceivingUIProvider>
                    </Accordion.Collapse>
                  </>
                  {isId && (
                    <>
                      <Accordion.Toggle
                        as={Button}
                        className="w-100 text-left p-0 position-relative text-decoration-none"
                        variant="link"
                        eventKey="3"
                      >
                        <Alert
                          variant="primary "
                          className="pt-3 pl-3 pb-0 w-100"
                        >
                          <h5 className="font-weight-bold">Notes</h5>
                        </Alert>
                        <Icon
                          className={clsx(
                            "fa fa-chevron-down position-absolute text-white"
                          )}
                          style={{ fontSize: 17, right: "1rem", top: "20%" }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <NotesComponent
                          id={remark?.id}
                          get={"/transload/receipts/getnotes/" + remark?.id}
                          post={"/transload/receipts/addnote/" + remark?.id}
                          put={"/generic/notes/"}
                          Categories={NoteCategories}
                        />
                      </Accordion.Collapse>

                      <Accordion.Toggle
                        as={Button}
                        className="w-100 text-left p-0 position-relative text-decoration-none"
                        variant="link"
                        eventKey="4"
                      >
                        <Alert
                          variant="primary "
                          className="pt-3 pl-3 pb-0 w-100"
                        >
                          <h5 className="font-weight-bold">Attachements</h5>
                        </Alert>
                        <Icon
                          className={clsx(
                            "fa fa-chevron-down position-absolute text-white"
                          )}
                          style={{ fontSize: 17, right: "1rem", top: "20%" }}
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <AttachementsComponent
                          id={remark?.id}
                          post={
                            "/transload/receipts/addattachment?receiptId=" +
                            remark?.id
                          }
                          get={
                            "/receiptsmanagement/getreceiptattachments/" +
                            remark?.id
                          }
                        />
                      </Accordion.Collapse>

                      <Accordion.Toggle
                        as={Button}
                        className="w-100 text-left p-0 position-relative text-decoration-none d-none"
                        variant="link"
                        eventKey="28"
                      >
                        <Alert
                          variant="primary "
                          className="pt-3 pl-3 pb-0 w-100"
                        >
                          <h5 className="font-weight-bold">
                            <i class="fas fa-user text-white mr-5"></i> PEOPLES
                          </h5>
                        </Alert>
                        <Icon
                          className={clsx(
                            "fa fa-chevron-down position-absolute text-white"
                          )}
                          style={{ fontSize: 17, right: "1rem", top: "20%" }}
                        />
                      </Accordion.Toggle>

                      <Accordion.Collapse eventKey="28">
                        <PeoplesComponent />
                      </Accordion.Collapse>
                    </>
                  )}
                </Form>
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={() => {
                  if (window.ReceiptManagmetShow) {
                    $("#ReLoadTableId").trigger("click");
                  }
                  onHide();
                  if (window.location.href.indexOf("Billing") > -1) {
                    document
                      .getElementsByClassName("text-info")[0]
                      .parentElement.click();
                  }
                }}
                className="btn btn-light btn-elevate"
              >
                Close
              </button>

              <button
                type="submit"
                disabled={!ChargesFunctionOne}
                aria-disabled="true"
                onClick={(e) => {
                  if (!values?.CustomerId)
                    Notification("error", "Customer not found");
                  if (!e.target.classList.contains("disabled")) {
                    window.ContainerSaveClose = false;
                    handleSubmit();
                  }
                }}
                id="AddContainerID"
                className="btn btn-primary btn-elevate SaveDisabled disabled"
              >
                Save
              </button>

              <button
                type="submit"
                disabled={!ChargesFunctionOne}
                onClick={(e) => {
                  if (!values?.CustomerId)
                    Notification("error", "Customer not found");
                  if (!e.target.classList.contains("disabled")) {
                    window.ContainerSaveClose = true;
                    handleSubmit();
                  }
                }}
                className="btn btn-primary btn-elevate SaveDisabled disabled"
              >
                Save & Close
                {!ChargesFunctionOne && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

function ContainerBasicFun({
  values,
  setFieldValue,
  BillTo = [],
  Customers = [],
  Shippers = [],
  setShippers,
  setConsignees,
  Consignees = [],
  Vendors = [],
  Warehouses = [],
  ModesStatus = [],
  setChargesFunctionModel,
  isId,
  setFreightChargeId,
  FreightChargeId,
  setReloadCalcF,
}) {
  const [BillToDetail, setBillToDetail] = useState(obj);
  const [CustomersDetail, setCustomersDetail] = useState(obj);
  const [WarehousesDetail, setWarehousesDetail] = useState(obj);
  const [ConsigneesDetail, setConsigneesDetail] = useState(obj);
  const [ShippersDetail, setShippersDetail] = useState();

  useEffect(() => {
    try {
      if (values?.BillToId) {
        setvalueEquipmentProviderId(values?.BillToId, "BillToId");
      }
    } catch (err) {}
  }, [values?.BillToId, BillTo]);

  useEffect(() => {
    if (values?.CustomerId) {
      setvalueEquipmentProviderId(values?.CustomerId, "CustomerId");
    }
  }, [values?.CustomerId, Customers]);

  useEffect(() => {
    if (values?.PickupLocationId) {
      setvalueEquipmentProviderId(values?.PickupLocationId, "PickupLocationId");
    }
  }, [values?.PickupLocationId, Shippers]);

  useEffect(() => {
    if (values?.ConsigneeId) {
      setvalueEquipmentProviderId(values?.ConsigneeId, "ConsigneeId");
    }
  }, [values?.ConsigneeId, Consignees]);

  async function GetAddress(id, Type) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    return fetch(
      `${window.$apiurl}/TransloadReceipts/Address/${id}?isCustomer=${
        Type === "CustomerId" ? true : false
      }&iswarehouse=${Type === "Warehouse" ? true : false}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        return obj;
      })
      .catch((error) => console.log("error", error));
  }
  async function setvalueEquipmentProviderId(Id, Type) {
    let obj = await GetAddress(Id, Type);
    if (Id === undefined) {
      alert(Type);
    }
    switch (Type) {
      case "BillToId":
        setBillToDetail(obj);
        break;
      case "CustomerId":
        setCustomersDetail(obj);
        break;
      case "PickupLocationId":
        setShippersDetail(obj);
        break;
      case "ConsigneeId":
        setConsigneesDetail(obj);
        break;

      default:
        break;
    }
  }

  function CheckStatus(Status, Name) {
    try {
      if (Status?.length === 0) return false;
      return Status.filter((x) => x?.Name === Name).length > 0;
    } catch (error) {
      return false;
    }
  }

  function OpenChargesModel() {
    var sum = 0;
    (values?.Charges || []).forEach((x) => {
      sum += x?.Quantity;
    });
    if (values?.Charges?.length > 0 && sum > 0) setChargesFunctionModel(true);
    else setReloadCalcF();
  }

  function GetShipper(field) {
    var b = Shippers.find((x) => x.Id === values?.PickupLocationId);
    if (b && b[field]) return b[field];
    return "";
  }

  function GetConsignee(field) {
    var b = Consignees.find((x) => x.Id === values?.ConsigneeId);
    if (b && b[field]) return b[field];
    return "";
  }

  return (
    <>
      <input type="hidden" id="C_CustomerId" value={values?.CustomerId ?? ""} />
      <input type="hidden" id="C_ServiceId" value={values?.ServiceId ?? ""} />
      <input type="hidden" id="C_ModeId" value={values?.ModeId ?? ""} />
      <input
        type="hidden"
        id="C_ServiceLevelId"
        value={values?.ServiceLevelId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentTypeId"
        value={values?.EquipmentTypeId ?? ""}
      />
      <input
        type="hidden"
        id="C_EquipmentSizeId"
        value={values?.EquipmentSizeId ?? ""}
      />
      <input type="hidden" id="C_ReceivedDate" value={values?.ReceivedDate} />
      <input type="hidden" id="C_PickedUpDate" value={values?.PickedUpDate} />
      <input
        type="hidden"
        id="C_TerminatedDate"
        value={values?.TerminatedDate}
      />

      <input
        type="hidden"
        id="C_fromCity"
        value={ShippersDetail?.City ?? GetShipper("City")}
      />
      <input
        type="hidden"
        id="C_fromAddress"
        value={ShippersDetail?.Address1 ?? GetShipper("Address1")}
      />
      <input
        type="hidden"
        id="C_fromState"
        value={ShippersDetail?.State ?? GetShipper("State")}
      />
      <input
        type="hidden"
        id="C_fromZipCode"
        value={ShippersDetail?.ZipCode ?? GetShipper("ZipCode")}
      />

      <input
        type="hidden"
        id="C_toCity"
        value={ConsigneesDetail?.City ?? GetConsignee("City")}
      />
      <input
        type="hidden"
        id="C_toAddress"
        value={ConsigneesDetail?.Address1 ?? GetConsignee("Address1")}
      />
      <input
        type="hidden"
        id="C_toState"
        value={ConsigneesDetail?.State ?? GetConsignee("State")}
      />
      <input
        type="hidden"
        id="C_toZipCode"
        value={ConsigneesDetail?.ZipCode ?? GetConsignee("ZipCode")}
      />

      <div className="form-group row">
        <div className="col-lg-6 mb-5">
          <Field
            type="number"
            name="OrderId"
            component={Input}
            placeholder="Order Number"
            label="Order Number"
            defaultValue={window.FindOrdersTransload}
            disabled
            required={true}
          />
        </div>
        {isId && (
          <div className="col-lg-3 mb-5">
            <Field
              type="number"
              name="Id"
              component={Input}
              placeholder="Receive Number"
              label="Receive Number"
              disabled
            />
          </div>
        )}
        <div className={`col-lg-3 mb-5 ${!isId && "col-lg-6"}`}>
          <Field
            name="EquipmentNumber"
            component={Input}
            placeholder="Container Number"
            label="Container Number"
          />
        </div>
        {isId && (
          <>
            <div className="col-lg-3 mt-5">
              <Field
                name="Vessel"
                component={Input}
                placeholder="Vessel Name"
                label="Vessel Name"
                disabled
              />
            </div>
            <div className="col-lg-3 mt-5">
              <Field
                name="VesselVoyage"
                component={Input}
                placeholder="Voyage Number"
                label="Voyage Number  "
                disabled
              />
            </div>
            <div className="col-lg-6 my-5">
              <DateTimeSimple
                name="EstimatedTimeArrival"
                label="ETA"
                valueSelect={values?.EstimatedTimeArrival}
                setFieldValue={setFieldValue}
                disabled={true}
              />
            </div>

            <div className="col-lg-3 mt-5">
              <Field
                name="InvoiceNumber"
                component={Input}
                placeholder="Invoice No"
                label="Invoice No"
              />
            </div>
            <div className="col-lg-3 mt-5">
              <Field
                name="MasterBill"
                component={Input}
                placeholder="Master bill"
                label="Master bill"
              />
            </div>
            <div className="col-lg-6 mt-5">
              <DateTime30
                label="Invoice Date"
                name="InvoiceDate"
                disabled
                defaultvalue={values?.InvoiceDate}
                setFieldValue={setFieldValue}
                className="w-100 pt-2"
              />
            </div>
          </>
        )}
        {!isId && (
          <>
            <div className="col-lg-6 mt-5">
              <Field
                name="MasterBill"
                component={Input}
                placeholder="Master bill"
                label="Master bill"
              />
            </div>
            <div className="col-lg-6 mt-5"></div>{" "}
          </>
        )}
        <div className="col-lg-6 mt-5">
          <Title Str={"Customer"} Required={true} />

          <SelectPagination
            name="CustomerId"
            value={{
              value: values?.CustomerId,
              label:
                Customers.find((x) => x?.Id === values?.CustomerId)?.Name ??
                "Select...",
            }}
            options={Customers.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              if (newValue !== null) {
                setFieldValue("CustomerId", newValue?.value);
              } else {
                setFieldValue("CustomerId", null);
                setCustomersDetail();
              }
              if (newValue?.value) OpenChargesModel();
            }}
          />
          {Customers?.length === 0 && <LinearProgress />}

          <div className="form-control h-auto mt-2">
            {CheckDetail(CustomersDetail?.Code)}
            {CheckDetail(CustomersDetail?.Name)}
            {CheckDetail(CustomersDetail?.Address)}
            {CustomersDetail?.City}, {CustomersDetail?.State},{" "}
            {CustomersDetail?.ZipCode}
          </div>
        </div>
        <div className="col-lg-6 mt-5">
          <Title Str={"Bill To"} />
          <SelectPagination
            name="BillToId"
            value={{
              value: values?.BillToId,
              label: BillTo.find((x) => x?.Id === values?.BillToId)
                ? BillTo.find((x) => x?.Id === values?.BillToId)?.Name
                : "Select...",
            }}
            options={BillTo.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              if (newValue !== null) {
                setFieldValue("BillToId", newValue?.value);
              } else {
                setFieldValue("BillToId", null);
                setBillToDetail();
              }
            }}
          />

          <div className="form-control h-auto mt-2">
            {CheckDetail(BillToDetail?.Code)}
            {CheckDetail(BillToDetail?.Name)}
            {CheckDetail(BillToDetail?.Address1)}
            {CheckDetail(BillToDetail?.Address2)}
            {BillToDetail?.City}, {BillToDetail?.State}, {BillToDetail?.ZipCode}
          </div>
        </div>
        <div className="col-lg-3 mt-5">
          <Field
            name="CustomerRefNumber1"
            component={Input}
            placeholder="Cust. Ref. No 1"
            label="Cust. Ref. No 1"
            maxlength="10"
          />
        </div>
        <div className="col-lg-3 mt-5">
          <Field
            name="CustomerRefNumber2"
            component={Input}
            placeholder="Cust. Ref. No 2"
            label="Cust. Ref. No 2"
            maxlength="10"
          />
        </div>
        <div className="col-lg-3 mt-5">
          <Field
            name="BillingReferences1"
            component={Input}
            placeholder="Cust. Ref. No 1"
            label="Bill To Ref. No 1"
          />
        </div>
        <div className="col-lg-3 mt-5">
          <Field
            name="BillingReferences2"
            component={Input}
            placeholder="Bill To  Ref. No 2"
            label="Bill To  Ref. No 2"
          />
        </div>
        <div className="col-lg-6 mt-5"> </div>
        <input type="hidden" id="FreightChargeIdClick" />
        <div className="col-lg-6 mt-5">
          <input
            type="hidden"
            id="FreightChargeIdVendor"
            value={values?.FreightChargeId}
          />
          <RadioButton
            name="FreightChargeId"
            label="Freight Charge Terms"
            row
            value={FreightChargeId}
            onChange={(e) => {
              var value = e.target.value;
              setFreightChargeId(value);
              setFieldValue("FreightChargeId", value);
            }}
          >
            <FormControlLabel
              value="98"
              control={<Radio color="primary" />}
              label="Prepaid"
            />
            <FormControlLabel
              value="99"
              control={<Radio color="primary" />}
              label="Collect"
            />
            <FormControlLabel
              value="100"
              control={<Radio color="primary" />}
              label="Third Party Billing"
            />
          </RadioButton>
        </div>

        <div className="col-lg-6 mt-5">
          <Title Str={"Pickup Location"} />
          <SelectPagination
            name="PickupLocationId"
            value={{
              value: values?.PickupLocationId,
              label:
                Shippers.find((x) => x?.Id === values?.PickupLocationId)
                  ?.Name ?? "Select...",
            }}
            onChangefirst={false}
            options={Shippers.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              if (newValue !== null) {
                setFieldValue("PickupLocationId", newValue?.value);
              } else {
                setFieldValue("PickupLocationId", null);
                setShippersDetail();
              }
              setTimeout(() => {
                if (newValue?.value) OpenChargesModel();
              }, 500);
            }}
          />
          <div className="form-control h-auto mt-2">
            {CheckDetail(ShippersDetail?.Code)}
            {CheckDetail(ShippersDetail?.Name)}
            {CheckDetail(ShippersDetail?.Address1)}
            {ShippersDetail?.City}, {ShippersDetail?.State},{" "}
            {ShippersDetail?.ZipCode}
          </div>
        </div>
        <div className="col-lg-6 mt-5">
          <Title Str={"Ship to Location"} />
          <SelectPagination
            name="ConsigneeId"
            value={{
              value: values?.ConsigneeId,
              label:
                Consignees.find((x) => x?.Id === values?.ConsigneeId)?.Name ??
                "Select...",
            }}
            onChangefirst={false}
            options={Consignees.map((x) => {
              return {
                value: x?.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              if (newValue !== null) {
                setFieldValue("ConsigneeId", newValue?.value);
              } else {
                setFieldValue("ConsigneeId", null);
                setConsigneesDetail();
              }
              setTimeout(() => {
                if (newValue?.value) OpenChargesModel();
              }, 500);
            }}
          />
          <div className="form-control h-auto mt-2">
            {CheckDetail(ConsigneesDetail?.Code)}
            {CheckDetail(ConsigneesDetail?.Name)}
            {CheckDetail(ConsigneesDetail?.Address1)}
            {ConsigneesDetail?.City}, {ConsigneesDetail?.State},{" "}
            {ConsigneesDetail?.ZipCode}
          </div>
        </div>

        <div className="col-lg-6 mt-5">
          <Title Str={"Into Warehouse"} />
          <CreatableSelect3
            name="WarehouseId"
            setFieldValue={setFieldValue}
            valueSelect={values?.WarehouseId}
            options={Warehouses.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            onChange={async (newValue) => {
              var a = newValue?.value;
              if (a) {
                setFieldValue("WarehouseId", a);
                var b = await GetAddress(a, "Warehouse");
                setWarehousesDetail(b);
              } else {
                setFieldValue("WarehouseId", null);
                setWarehousesDetail();
              }
            }}
          />
          <div className="mt-2 form-control h-auto">
            {CheckDetail(WarehousesDetail?.Code)}
            {CheckDetail(WarehousesDetail?.Name)}
            {CheckDetail(WarehousesDetail?.Address1)}
            {CheckDetail(WarehousesDetail?.Address2)}
            {WarehousesDetail?.City}, {WarehousesDetail?.State},{" "}
            {WarehousesDetail?.ZipCode}
          </div>
        </div>

        <div className="col-lg-12 mt-5">
          <Title Str={"Dates"} />
        </div>
        <div className="col-lg-6 my-5">
          <DateTimeSimple
            name="Created"
            label="Order Creation"
            valueSelect={values?.Created}
            setFieldValue={setFieldValue}
            disabled={true}
          />
        </div>
        {CheckStatus(ModesStatus, "Dispatched") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="DispatchedDate"
              label="Dispatched"
              defaultvalue={values?.DispatchedDate}
              disabled={values?.IsManager ? false : !values?.DispatchedDate}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
        {CheckStatus(ModesStatus, "Discharged") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="DischargeDate"
              label="Discharged"
              defaultvalue={values?.DischargeDate}
              disabled={values?.IsManager ? false : !values?.DischargeDate}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
        {CheckStatus(ModesStatus, "Released") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="ReleasedDate"
              label="Released"
              defaultvalue={values?.ReleasedDate}
              disabled={values?.IsManager ? false : !values?.ReleasedDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setFieldValue("StatusId", 17);
              }}
            />{" "}
          </div>
        )}

        {CheckStatus(ModesStatus, "Appointed") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="AppointedDate"
              label="Appointement Date"
              defaultvalue={values?.AppointedDate}
              disabled={values?.IsManager ? false : !values?.AppointedDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setFieldValue("StatusId", 17);
              }}
            />
          </div>
        )}

        {CheckStatus(ModesStatus, "Picked Up") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="PickedUpDate"
              label="Picked Up Date"
              defaultvalue={values?.PickedUpDate}
              disabled={values?.IsManager ? false : !values?.PickedUpDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setTimeout(() => {
                  document.getElementById("setShowChassis").click();
                }, 1000);
              }}
            />
          </div>
        )}

        {CheckStatus(ModesStatus, "Load Received") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="ReceivedDate"
              label="Received"
              defaultvalue={values?.ReceivedDate}
              disabled={values?.IsManager ? false : !values?.ReceivedDate}
              setFieldValue={setFieldValue}
            />
          </div>
        )}
        {CheckStatus(ModesStatus, "Devanned") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="DevannedDate"
              label="Devanned"
              defaultvalue={values?.DevannedDate}
              disabled={values?.IsManager ? false : !values?.DevannedDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setFieldValue("StatusId", 21);
              }}
            />
          </div>
        )}
        {CheckStatus(ModesStatus, "Terminated") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="TerminatedDate"
              label="Terminated"
              defaultvalue={values?.TerminatedDate}
              disabled={values?.IsManager ? false : !values?.TerminatedDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setFieldValue("StatusId", 22);
                setTimeout(() => {
                  document.getElementById("setShowChassis").click();
                }, 1000);
              }}
            />
          </div>
        )}
        {CheckStatus(ModesStatus, "Closed") && (
          <div className="col-lg-6 my-5">
            <DateTime30
              name="ClosedDate"
              label="Closed"
              defaultvalue={values?.ClosedDate}
              disabled={values?.IsManager ? false : !values?.ClosedDate}
              setFieldValue={setFieldValue}
              onChange={() => {
                setFieldValue("StatusId", 23);
              }}
            />
          </div>
        )}
        <div className="col-lg-6 my-5">
          <DateTime30
            name="PickedUpOnDate"
            label="Pickup Apt"
            defaultvalue={values?.PickedUpOnDate}
            disabled={!values?.IsManager}
            setFieldValue={setFieldValue}
            onChange={() => {
              setFieldValue("StatusId", 19);
            }}
          />
        </div>
        <div className="col-lg-6 my-5">
          <DateTime30
            name="DeliveryOnDate"
            label="Delivery Apt"
            defaultvalue={values?.DeliveryOnDate}
            disabled={!values?.IsManager}
            setFieldValue={setFieldValue}
          />
        </div>
        {values?.ServiceId === 6 && values?.ModeId === 31 && (
          <>
            <div className="col-lg-6 my-5">
              <DateTime30
                name="PlannedDate"
                label="Planned Date"
                defaultvalue={values?.PlannedDate}
                disabled={!values?.IsManager}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 my-5">
              <DateTime30
                name="QcDate"
                label="Qc Date"
                defaultvalue={values?.QcDate}
                disabled={!values?.IsManager}
                setFieldValue={setFieldValue}
              />
            </div>
            <div className="col-lg-6 my-5">
              <DateTime30
                name="ProcessedDate"
                label="Processed Date"
                defaultvalue={values?.ProcessedDate}
                disabled={!values?.IsManager}
                setFieldValue={setFieldValue}
              />
            </div>
          </>
        )}
      </div>
      <br />
      <br />
    </>
  );
}

function OrderDetails({
  values,
  setFieldValue,
  setChargesFunctionModel,
  Services = [],
  setModesStatus,
  setReloadCalcF,
  LineItems
}) {
  const [Modes, setModes] = useState(catchError);
  const [ServiceLevels, setServiceLevels] = useState(catchError);
  const [EquipmentTypes, setEquipmentTypes] = useState(catchError);
  const [EquipmentSizes, setEquipmentSizes] = useState(catchError);

  function OpenChargesModel() {
    var sum = 0;
    (values?.Charges || []).forEach((x) => {
      sum += x?.Quantity;
    });
    if (values?.Charges?.length > 0 && sum > 0) setChargesFunctionModel(true);
    else setReloadCalcF();
  }

  //useEffect
  useEffect(() => {
    var b = Services.find((x) => x.Id === values?.ServiceId);
    setModes([]);
    setTimeout(() => {
      setModes(b?.Modes || []);
      $("#FreightChargeIdClick").trigger("click");
    }, 1);
  }, [values?.ServiceId, Services]);

  useEffect(() => {
    var b = Modes.find((x) => x.Id === values?.ModeId);
    setServiceLevels([]);
    setTimeout(() => {
      setServiceLevels(b?.ServiceLevels || []);
      setModesStatus(b?.Status || []);
    }, 1);
  }, [values?.ModeId, Modes]);

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-4 mt-5">
          <CreatableSelect3
            label="Service"
            name="ServiceId"
            setFieldValue={setFieldValue}
            valueSelect={values?.ServiceId}
            onChangefirst={false}
            options={Services.map((x) => {
              return {
                value: x.Id,
                label: x?.Name,
              };
            })}
            onChange={(newValue) => {
              var a = newValue?.value;
              if (a) OpenChargesModel();
            }}
          />
        </div>
        <div className="col-lg-2 mt-5">
          {Modes?.length > 0 && (
            <CreatableSelect3
              label="Mode"
              name="ModeId"
              setFieldValue={setFieldValue}
              valueSelect={values?.ModeId}
              onChangefirst={false}
              options={Modes.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                if (a) OpenChargesModel();
              }}
            />
          )}
        </div>
        <div className="col-lg-2 mt-5">
          {Modes?.length > 0 && ServiceLevels?.length > 0 && (
            <CreatableSelect3
              label="Service Level"
              name="ServiceLevelId"
              setFieldValue={setFieldValue}
              valueSelect={values?.ServiceLevelId}
              options={ServiceLevels.map((x) => {
                return {
                  value: x.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
                var b = ServiceLevels.find((x) => x.Id === a);
                setEquipmentTypes([]);
                setTimeout(() => {
                  setEquipmentTypes(b?.EquipmentTypes || []);
                }, 1);
              }}
            />
          )}
        </div>
        <div className="col-lg-2 mt-5">
          {Modes?.length > 0 &&
            ServiceLevels?.length > 0 &&
            EquipmentTypes?.length > 0 && (
              <CreatableSelect3
                label="Equipment Type"
                name="EquipmentTypeId"
                setFieldValue={setFieldValue}
                valueSelect={values?.EquipmentTypeId}
                options={EquipmentTypes.map((x) => {
                  return {
                    value: x.Id,
                    label: x?.Name,
                  };
                })}
                onChange={(newValue) => {
                  var a = newValue?.value;
                  var b = EquipmentTypes.find((x) => x.Id === a);
                  setEquipmentSizes([]);
                  setTimeout(() => {
                    setEquipmentSizes(b?.EquipmentSizes || []);
                  }, 1);
                }}
              />
            )}
        </div>
        <div className="col-lg-2 mt-5">
          {Modes?.length > 0 &&
            ServiceLevels?.length > 0 &&
            EquipmentTypes?.length > 0 &&
            EquipmentSizes?.length > 0 && (
              <CreatableSelect3
                label="Equipment Size"
                name="EquipmentSizeId"
                setFieldValue={setFieldValue}
                valueSelect={values?.EquipmentSizeId}
                options={EquipmentSizes.map((x) => {
                  return {
                    value: x.Id,
                    label: is(x?.Code) + x?.Name,
                  };
                })}
              />
            )}
        </div>

        <div className="col-lg-4 mt-5">
          <Field
            name="SealNumber"
            component={Input}
            placeholder="Seal"
            label="Seal"
          />
        </div>
        <div className="col-lg-2 mt-5">
          <Field
            name="Cartons2"
            component={Input}
            placeholder="Cartons"
            label="Cartons"
            disabled
          />
        </div>
        <div className="col-lg-2 mt-5">
          <Field
            name="Pallets"
            component={Input}
            placeholder="Pallets"
            label="Pallets"
            disabled
          />
        </div>
        {LineItems?.length > 0 ? (
          <>
            <div className="col-lg-2 mt-5">
              <label>Weight</label>
              <input
                type="text"
                class="form-control"
                name="TotalWeight1"
                placeholder="Weight"
                disabled={true}
                value={values?.TotalWeight1 + " KGS"}
              />
            </div>
            <div className="col-lg-2 mt-5">
              <label>Cube</label>
              <input
                type="text"
                class="form-control"
                name="TotalCube1"
                placeholder="Cube"
                disabled={true}
                value={values?.TotalCube1 + " CBM"}
              />
            </div>
          </>
        ) : (
          <>
            <div className="col-lg-2 mt-5">
              <label>Weight Type</label>
              <Select
                options={[
                  { value: "KGS", label: "KGS" },
                  { value: "LBS", label: "LBS" },
                ]}
                value={[
                  { value: "KGS", label: "KGS" },
                  { value: "LBS", label: "LBS" },
                ].filter((a) => a.value === values.TotalWeightType2)}
                onChange={(e) => {
                  setFieldValue("TotalWeightType2", e !== null ? e?.value : "");
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-lg-2 mt-5">
              <label>Weight</label>
              <div class="input-group">
                <input
                  onChange={(e) => {
                    setFieldValue("TotalWeight2", e.target.value);
                  }}
                  value={values?.TotalWeight2}
                  type="number"
                  class="form-control"
                  name="TotalWeight1"
                  placeholder="Weight"
                  aria-describedby="basic-addon2"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    {values?.TotalWeightType2}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-2 mt-5">
              <label>Cube Type</label>
              <Select
                placeholder={"Select Cube Type"}
                options={[
                  { value: "CBM", label: "CBM" },
                  { value: "CFT", label: "CFT" },
                ]}
                value={[
                  { value: "CBM", label: "CBM" },
                  { value: "CFT", label: "CFT" },
                ].filter((a) => a.value === values.TotalCubeType2)}
                onChange={(e) => {
                  setFieldValue("TotalCubeType2", e !== null ? e?.value : "");
                }}
                classNamePrefix="react-select"
              />
            </div>
            <div className="col-lg-2 mt-5">
              <label>Cube</label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control"
                  name="TotalCube1"
                  placeholder="Cube"
                  onChange={(e) => {
                    setFieldValue("TotalCube2", e.target.value);
                  }}
                  value={values?.TotalCube2}
                  aria-describedby="basic-addon2"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    {values?.TotalCubeType2}
                  </span>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export function VendorListComp({
  ChargesFunctionOne,
  Vendors = [],
  ReloadCalc,
  ChargesId = "",
  setReloadCalcF,
  CustomVendors,
  isInbound = true,
  FreightChargeId,
  GetChargesLink,
  GetCalculatedLink,
  PayablesReadOnly,
  setFieldValue = false,
  isShipment = false,
}) {
  const [VendorListStat, setVendorListStat] = useState(catchError);
  const [OpenFirst, setOpenFirst] = useState(true);

  useEffect(() => {
    if (Vendors?.length > 0 && Vendors?.length > 0 && OpenFirst) {
      setVendorListStat([]);
      Vendors.map((item, index) => {
        setVendorListStat((x) => [
          ...x,
          <OneMoveMasterVendors
            item={item}
            countId={index}
            index={index}
            FreightChargeId={FreightChargeId}
            Vendorsf={CustomVendors}
            ReloadCalc={ReloadCalc}
            isInboundV={isInbound}
            isPayableV={true}
            S_ChargesReadOnly={PayablesReadOnly ?? window.C_PayablesReadOnly}
            isShipment={isShipment}
            ChargesId={ChargesId}
            GetChargesLink={GetChargesLink}
            GetCalculatedLink={GetCalculatedLink}
            setFieldValue={setFieldValue}
          />,
        ]);
        return item;
      });
      setOpenFirst(false);
    }
  }, [Vendors, CustomVendors]);

  return (
    <>
      <div className="form-group row">
        <div className="col-lg-12">
          <button
            type="button"
            className="btn btn-light-success mr-2 w-100 mb-5"
            onClick={() => {
              var a = VendorListStat.length;

              setReloadCalcF();
              setVendorListStat((x, index) => [
                ...x,
                <OneMoveMasterVendors
                  countId={a}
                  item={{}}
                  index={index}
                  FreightChargeId={FreightChargeId}
                  Vendorsf={CustomVendors}
                  ReloadCalc={ReloadCalc}
                  isInboundV={true}
                  isPayableV={true}
                  S_ChargesReadOnly={
                    PayablesReadOnly ?? window.C_PayablesReadOnly
                  }
                  isShipment={isShipment}
                  ChargesId={ChargesId}
                  GetChargesLink={GetChargesLink}
                  GetCalculatedLink={GetCalculatedLink}
                  setFieldValue={setFieldValue}
                />,
              ]);
            }}
          >
            <i className="fas fa-plus mr-2"></i> Add New Vendor
          </button>
        </div>
        {ChargesFunctionOne && VendorListStat.map((x) => x)}
      </div>
    </>
  );
}

export const ChargesFunction = ({
  isInbound,
  isPayable,
  type,
  ChargesId = "",
  autoQuantity,
  list = [],
  AutoRating,
  ReloadCalc,
  GetChargesLink = "/ordersRating/GetCharges",
  GetCalculatedChargesLink = "/ordersRating/GetCalculatedCharges",
  setFieldValue,
  IsHot,
  setChergesFun = false,
  setChargesTotal = false,
  IsReadOnly = false,
  ChargesReadOnly = false,
  colorTable = "bg-light",
  isVariableOpen = isVariableOpenDefault,
  BillId = false,
  setIsEditedCharges,
}) => {
  const [ChargeModel, setChargeModel] = useState({
    Show: false,
    Type: "success",
  });
  const [Charges, setCharges] = useState(list);
  const [ChargesRemove, setChargesRemove] = useState([]);
  const [ChargesN, setChargesN] = useState(catchError);
  const [loadingCharges, setloadingCharges] = useState(true);
  const [FilterCharges, setFilterCharges] = useState("");
  const [AutoRatingModal, setAutoRatingModal] = useState(false);
  const [AutoRatingCheck, setAutoRatingCheck] = useState([]);

  const [NoteModal, setNoteModal] = useState({
    Show: false,
    Id: 0,
    Code: "",
  });

  useEffect(() => {
    if (!ChargeModel?.Show) CalcTotal();
  }, [ChargeModel]);

  useEffect(() => {
    GetPayableForVendor({ button: false });
  }, [ReloadCalc]);

  const GetPayableForVendor = ({ button }) => {
    setloadingCharges(true);

    const customerId = FieldID("C_CustomerId" + ChargesId);
    const serviceId = FieldID("C_ServiceId" + ChargesId);
    const modeId = FieldID("C_ModeId" + ChargesId);
    const serviceLevelId = FieldID("C_ServiceLevelId" + ChargesId);
    const equipmentTypeId = FieldID("C_EquipmentTypeId" + ChargesId);
    const equipmentSizeId = FieldID("C_EquipmentSizeId" + ChargesId);
    const date = FieldID("C_ReceivedDate" + ChargesId);
    const CarrierId = FieldID("C_CarrierId" + ChargesId);

    const fromCity = FieldID("C_fromCity" + ChargesId);
    const fromAddress = FieldID("C_fromAddress" + ChargesId);
    const fromState = FieldID("C_fromState" + ChargesId);
    const fromZipCode = FieldID("C_fromZipCode" + ChargesId);

    const toCity = FieldID("C_toCity" + ChargesId);
    const toAddress = FieldID("C_toAddress" + ChargesId);
    const toState = FieldID("C_toState" + ChargesId);
    const toZipCode = FieldID("C_toZipCode" + ChargesId);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(
        window.$apiurl +
          `${GetChargesLink}?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}&VendorTypeId=`,
        requestOptions
      )
        .then((response) => response.json())
        .then(async (result) => {
          try {
            setChargesN([]);
            var thisList = window["List" + type + ChargesId];
            let chargesVar = thisList;

            chargesVar = CheckRateInCharges(
              chargesVar,
              result,
              ChargesReadOnly,
              setChargesRemove,
              IsHot
            );

            if (type === "Payables") {
              var findv = chargesVar.find(
                (x) => !x.IsReadOnly && x?.VendorBillId !== null
              );
              if (findv)
                window["List" + type + ChargesId + "v"] = [
                  findv.VendorBillId,
                  findv.VendorBill,
                ];
              else window["List" + type + ChargesId + "v"] = [];
            }

            let objCalc = [];
            if (button) objCalc = await GetPayableForVendorCalc();
            else
              objCalc =
                chargesVar?.length === 0 ? await GetPayableForVendorCalc() : [];

            var idv = "List" + type + ChargesId + "v";
            objCalc = RemoveDuplicate(chargesVar, objCalc, type, idv);

            chargesVar = JSON.parse(JSON.stringify(chargesVar));
            chargesVar.forEach((x) => {
              x.Id2 = x?.Id;
              x.Id = x?.ChargeId;
            });
            let allObjs = chargesVar.concat(objCalc);

            let resArr = [];
            allObjs.filter(function(item) {
              resArr.push({ ...item, ChargeId: item?.Id });
            });

            if (autoQuantity !== undefined) {
              resArr.map((a) => {
                if (a?.Quantity === undefined || a?.Quantity === 0) {
                  if (autoQuantity[a.Code] !== undefined) {
                    a.Quantity = autoQuantity[a.Code];
                  }
                }
                return a;
              });
            }

            var a = [];
            (result || []).forEach((x) => {
              var i = allObjs.find((y) => y?.Id === x?.Id);
              if (i === undefined) a.push(x);
            });

            //get auto rate
            resArr =
              (await StartAutoRate(resArr, ChargesId, AutoRating, IsHot)) ?? [];

            setCharges(resArr || []);
            setChargesN(a || []);
            setloadingCharges(false);
            SaveDisabledFunction(false);

            if (button)
              setTimeout(() => {
                document.querySelector(`.AutoRatingBtn-${ChargesId}`).click();
              }, 1000);
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (e) {}
  };

  async function GetPayableForVendorCalc() {
    if (ChargesReadOnly) return [];
    const customerId = FieldID("C_CustomerId" + ChargesId);
    const serviceId = FieldID("C_ServiceId" + ChargesId);
    const modeId = FieldID("C_ModeId" + ChargesId);
    const serviceLevelId = FieldID("C_ServiceLevelId" + ChargesId);
    const equipmentTypeId = FieldID("C_EquipmentTypeId" + ChargesId);
    const equipmentSizeId = FieldID("C_EquipmentSizeId" + ChargesId);
    const date = FieldID("C_ReceivedDate" + ChargesId);
    const CarrierId = FieldID("C_CarrierId" + ChargesId);

    const fromCity = FieldID("C_fromCity" + ChargesId);
    const fromAddress = FieldID("C_fromAddress" + ChargesId);
    const fromState = FieldID("C_fromState" + ChargesId);
    const fromZipCode = FieldID("C_fromZipCode" + ChargesId);

    const toCity = FieldID("C_toCity" + ChargesId);
    const toAddress = FieldID("C_toAddress" + ChargesId);
    const toState = FieldID("C_toState" + ChargesId);
    const toZipCode = FieldID("C_toZipCode" + ChargesId);

    return await fetch(
      window.$apiurl +
        `${GetCalculatedChargesLink}?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${isInbound}&isPayable=${isPayable}&carrierId=${CarrierId}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${fromAddress}&fromState=${fromState}&fromZipCode=${fromZipCode}&toCity=${toCity}&toAddress=${toAddress}&toState=${toState}&toZipCode=${toZipCode}&date=${date}&VendorTypeId=`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = [];
        res[1].map((x) => a.push({ ...x, Calc: true }));
        return a;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function CalcTotal() {
    var sum = 0;
    $(`.RatesTotal-${type}-${ChargesId}`).each(function() {
      var a = Number(
        $(this)
          .find("input")
          .val()
      );
      $(this)
        .find("input")
        .val(a.toFixed(2));
      sum += a;
    });

    if (setChargesTotal) setChargesTotal(sum);
    $(`.RateAllTotal-${type}-${ChargesId} span`).text(AddSpace3(sum));

    document
      .querySelectorAll(`.Charges2-${type + ChargesId} span`)
      .forEach((element) => {
        var category = element.getAttribute("data-Name");
        var invoice = element.getAttribute("data-Invoice");
        var vendorBill = element.getAttribute("data-VendorBill");
        var filter = Charges.filter(
          (x) =>
            x?.Category === category &&
            ((x?.Invoice === invoice && type === "Charges") ||
              (x?.VendorBill === vendorBill && type === "Payables"))
        );
        var a = filter.reduce(
          (total, x) =>
            CalculateCharge({
              Rate: x?.Rate,
              Quantity: RoundToFixed(
                x?.Quantity ?? 0,
                x?.Rounding,
                x?.IsRounding
              ),
              rateType: x?.RateType,
              MinCharge: x?.MinCharge,
              MaxCharge: x?.MaxCharge,
              FreeTime: x?.FreeTime,
              OriAddSup: x?.OriAddSup,
            }) + total,
          0
        );
        element.innerText = "Sub Total : " + AddSpace3(a);

        var str =
          "." + CategoryTotalId(category, invoice, vendorBill, ChargesId, type);
        SetSubTotal(a, str);
      });
  }

  useEffect(() => {
    let a = [];
    Charges.map((x) =>
      a.push({
        ...x,
        ChargeId: x?.ChargeId || x?.Id,
        Id: x?.Id,
        Code: x?.Code,
        Name: x?.Name,
        Quantity:
          RoundToFixed(x?.Quantity ?? 0, x?.Rounding, x?.IsRounding) || 0,
        Category: x?.Category,
        Rate: x?.Rate,
        Position: x?.Position,
        CategoryPosition: x?.CategoryPosition,
        OriAddSup: x?.OriAddSup,
        Invoice: x?.Invoice || "",
        InvoiceId: +x?.InvoiceId || null,
        IsReadOnly: x?.IsReadOnly || false,
        Note: x?.Note,
        MinCharge: x?.MinCharge,
        MaxCharge: x?.MaxCharge,
        FreeTime: x?.FreeTime,
        RateType: x?.RateType,
        Calcul: x?.Calcul,
        DateEnd: x?.DateEnd,
        DateStart: x?.DateStart,
        QuoteId: x?.QuoteId,
        Rounding: x?.Rounding,
        CalculName: x?.CalculName,
        RatingTable: x?.RatingTable,
      })
    );

    a.forEach((x) => {
      if (x.InvoiceId === numberofSort) x.InvoiceId = null;
      else if (x.VendorBillId === numberofSort) x.VendorBillId = null;
    });

    window["List" + type + ChargesId] = Charges;
    CalcTotal();

    if (setChergesFun) setChergesFun(a);
    if (type === "Charges" && setFieldValue) setFieldValue("Charges", a);
  }, [Charges, ChargesN]);

  function CheckHidden(param1, param2) {
    return param1?.toLowerCase()?.includes(param2?.toLowerCase())
      ? "row"
      : "d-none";
  }

  useEffect(() => {
    if (ChargeModel.Show === false) {
      setFilterCharges("");
    }
  }, [ChargeModel]);
  const headerStyle = `border-0 ${colorTable}`;

  return (
    <div className="row RatesParent">
      <Modal
        size="xl"
        show={ChargeModel.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title
            id="example-modal-sizes-title-lg"
            className="w-100 row px-5"
          >
            <div className="col-lg-4">
              {isPayable ? "Vendor" : "Customer"} Charges
            </div>
            <div className="col-lg-8">
              <input
                className="form-control"
                placeholder="Filter by Name and Code"
                onChange={(e) => setFilterCharges(e.target.value?.trim() ?? "")}
              />
            </div>
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => setChargeModel({ Show: false, Type: "success" })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {listdistinctFunction(
            ChargeModel?.Type === "info" ? Charges : ChargesN,
            ChargeModel?.Type,
            ChargeModel?.Show
          )?.map((x, index) => (
            <div
              key={x?.Code + "" + x?.Id + "" + index}
              className={CheckHidden(x?.Name + "," + x?.Code, FilterCharges)}
            >
              <AutoRatingItem
                key={index}
                x={x}
                AutoRating={AutoRating}
                IsHot={IsHot}
                index={index}
                isAddCharges={true}
                AutoRatingCheck={AutoRatingCheck}
                ChargesId={ChargesId}
              />
              <div className={`col-lg-4 mt-5 mb-5`}>
                <b className={`text-black title mr-2`}>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-edit-tooltip">{x.Code}</Tooltip>
                    }
                  >
                    <a
                      href={() => false}
                      className="btn btn-icon btn-light btn-hover-light btn-sm mr-3"
                    >
                      <span className="svg-icon svg-icon-md svg-icon-info">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Code/Info-circle.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                  {x?.Name}
                </b>
              </div>
              <div className={`col-lg-3 mt-4`}>
                <input
                  className="form-control"
                  disabled={true}
                  defaultValue={x?.Rate}
                />
              </div>
              <div className={`col-lg-3 pl-1 pr-5 mt-4`}>
                <input
                  className="form-control"
                  id={"ChargeN" + index + ChargesId}
                  defaultValue={x?.Quantity ?? 0}
                />
                <input type="hidden" id={"DateStart" + index + ChargesId} />
                <input type="hidden" id={"DateEnd" + index + ChargesId} />
              </div>
              <div className={`col-lg-2 mt-4`}>
                <button
                  type="button"
                  className={`btn btn-light-${ChargeModel?.Type} w-100`}
                  onClick={() => {
                    const isInfo = ChargeModel?.Type === "info";

                    let a = JSON.parse(JSON.stringify(Charges)) || [];
                    var listCharge = isInfo ? Charges : ChargesN;

                    var c = ChargesN?.filter((y) => y?.Id !== x?.Id);
                    var b = listCharge?.find((y) => y?.Id === x?.Id);

                    if (b || isInfo) {
                      var color = 0;
                      if (ChargeModel?.Type === "primary") color = 1;
                      else if (isInfo) color = 2;

                      var value = +document.getElementById(
                        "ChargeN" + index + ChargesId
                      ).value;
                      if (type === "Payables" && value < 0)
                        value = CheckIsNigativeRate(value, x?.OriAddSup);
                      else CheckIsNigativePositive(value, Charges, -1, 0, true);

                      if (type === "Payables") {
                        b.VendorBillId = numberofSort;
                        b.VendorBill = "Empty";

                        var find = window["List" + type + ChargesId + "v"];
                        if (find && find.length > 0) {
                          b.VendorBillId = find[0];
                          b.VendorBill = find[1];
                        }
                      }

                      a.push({
                        ...b,
                        Id: b?.Id,
                        ChargeId: b?.Id,
                        Priority: b?.Priority,
                        Rate: b?.Rate,
                        Name: b?.Name,
                        Code: b?.Code,
                        Quantity: value,
                        RateType: b?.RateType,
                        MinCharge: b?.MinCharge,
                        MaxCharge: b?.MaxCharge,
                        Position: b?.Position,
                        CategoryPosition: b?.CategoryPosition,
                        FreeTime: b?.FreeTime,
                        Category: b?.Category,
                        Calcul: b?.Calcul,
                        IsVariable: x?.IsVariable || false,
                        Invoice: "",
                        OriAddSup: color,
                        IsReadOnly: false,
                        DateEnd: FieldID("DateEnd" + index + ChargesId),
                        DateStart: FieldID("DateStart" + index + ChargesId),
                        QuoteId: b?.QuoteId,
                        Rounding: b?.Rounding,
                        CalculName: b?.CalculName,
                        RatingTable: b?.RatingTable,
                      });
                    }

                    setCharges(a);
                    if (!isInfo) setChargesN(c);

                    setTimeout(() => {
                      if (
                        (c?.length === 0 && !isInfo) ||
                        (a?.length === 0 && isInfo)
                      )
                        setChargeModel({ Show: false, Type: "success" });
                    }, 1);

                    setIsEditedCharges(true);
                  }}
                >
                  <i className={`fas fa-plus fa-sm`}></i>
                </button>
              </div>
            </div>
          ))}
          {(ChargeModel?.Type === "info" ? Charges : ChargesN)?.length ===
            0 && (
            <div class="col-lg-12 mt-2 text-center">
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{ fontSize: "25px", width: "100px", height: "100px" }}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setChargeModel({ Show: false, Type: "success" })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Auto Rating ---------------------------------------- */}
      <AutoRateComponent
        title="Receivables"
        setAutoRatingModal={setAutoRatingModal}
        setIsEditedCharges={setIsEditedCharges}
        AutoRatingModal={AutoRatingModal}
        Charges={Charges}
        AutoRating={AutoRating}
        IsHot={IsHot}
        setCharges={setCharges}
        AutoRatingCheck={AutoRatingCheck}
        setAutoRatingCheck={setAutoRatingCheck}
        IndexId={ChargesId}
      />
      {/* Auto Rating ---------------------------------------- */}
      <Modal
        show={NoteModal.Show}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Note : {NoteModal?.Code}
          </Modal.Title>
          <IconButton
            aria-label="Close"
            onClick={() => setNoteModal({ Show: false })}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body className="row">
          <textarea
            id="NoteCharges"
            className="form-control"
            rows="3"
            defaultValue={NoteModal?.Note}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => setNoteModal({ Show: false })}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            {!ChargesReadOnly && (
              <button
                type="button"
                onClick={() => {
                  var note = JsNative("#NoteCharges").val();

                  if (NoteModal?.index > -1) {
                    let c = Charges;

                    var type = 0;
                    if (!isInbound && !isPayable) type = 1;
                    else if (!isInbound && isPayable) type = 2;
                    else if (isInbound && !isPayable) type = 3;
                    else if (isInbound && isPayable) type = 4;

                    if (c[NoteModal?.index]?.IsReadOnly)
                      PostNote(c[NoteModal?.index]?.Id2, note, type);

                    c[NoteModal?.index].Note = note;
                    setCharges([]);
                    setTimeout(() => {
                      setCharges(c || []);
                      setNoteModal({ Show: false });
                    }, 1);
                    setIsEditedCharges(true);
                  }
                }}
                className="btn btn-primary btn-elevate ml-2"
              >
                OK
              </button>
            )}
          </div>
        </Modal.Footer>
      </Modal>
      {loadingCharges && (
        <div className="col-lg-12">
          <LinearProgress />
        </div>
      )}
      <EventChangeAutoRate />

      <div className="col-md-12">
        <div className="table-responsive">
          <div className="CheckCharges d-none">
            {Charges?.map((x, index) => (
              <p key={x?.ChargeId + "" + index}>{x?.Calcul}</p>
            ))}
          </div>

          <table
            className="table table-head-custom table-head-bg table-vertical-center"
            style={{ width: "95%", margin: "auto", overflow: "hidden" }}
          >
            <thead>
              <tr className="text-left text-uppercase">
                <th className={headerStyle}>Code</th>
                <th className={headerStyle}>Name</th>
                <th className={headerStyle}>Rate</th>
                <th className={headerStyle}>Quantity</th>
                <th className={headerStyle}>Total</th>
                {!IsReadOnly && (
                  <>
                    <th className={headerStyle}>Remove</th>
                    <th className={headerStyle}>Action</th>
                  </>
                )}
              </tr>
            </thead>

            {SortAndRename(Charges, type)?.map((x, index) => {
              const iiii =
                x?.ChargeId +
                "" +
                index +
                ChargesId +
                (x?.Invoice ?? "") +
                (x?.VendorBill ?? "");
              return (
                <>
                  <tbody
                    className={`border-0 ${HideIsNotBill(
                      x?.VendorBill,
                      BillId?.toString()
                    ) && "d-none"}`}
                    key={
                      x?.Code +
                      "" +
                      x?.Id +
                      "" +
                      index +
                      (x?.Invoice ?? "") +
                      (x?.VendorBill ?? "")
                    }
                  >
                    <InvoiceorBill x={x} type={type} />
                    {x?.CategoryAppend !== "" && (
                      <>
                        <tr className="mt-5 d-block CategoryClass">
                          <td
                            className={`border-0  bg-${ChargesColorCategory(
                              x?.Category
                            )} rounded-top`}
                          >
                            <b className={`text-light title mr-2`}>
                              <span
                                className={`rounded-pill px-2 py-1 ml-1 Charges2-${type +
                                  ChargesId}`}
                                style={{ fontSize: " 13px" }}
                              >
                                {x?.Category}
                                <span
                                  id={
                                    x?.Category?.replace(/ /g, "") + type + "Id"
                                  }
                                  className={`ml-2 px-2 py-1 bg-light-${ChargesColorCategory(
                                    x?.Category
                                  )} text-${ChargesColorCategory(x?.Category)}`}
                                  data-Name={x?.Category}
                                  data-Invoice={x?.Invoice}
                                  data-VendorBill={x?.VendorBill}
                                  style={{
                                    fontWeight: "600",
                                    borderRadius: "3px",
                                  }}
                                >
                                  Sub Total : 0.00
                                </span>
                              </span>
                            </b>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr className={colorTable}>
                      <td className={`border-0 position-relative`}>
                        {isChangeFunction(x)}
                        <b
                          className={`${ChargesColor(
                            x.OriAddSup ?? 0,
                            x?.Quantity ?? 0,
                            Charges
                          )} title mr-2`}
                        >
                          <ChargesFirstColumn x={x} />
                        </b>
                      </td>
                      <td className={`border-0`}>{x?.Name}</td>
                      <td className={`border-0 Rate`}>
                        <input
                          className={RateClassName(
                            ChargesReadOnly,
                            x,
                            isVariableOpen
                          )}
                          style={{ minWidth: "72px" }}
                          readonly={RateReadOnly(isVariableOpen, x?.IsVariable)}
                          defaultValue={x?.Rate}
                          disabled={
                            ChargesReadOnly ? true : x?.IsReadOnly || IsReadOnly
                          }
                          onClick={(e) => {
                            if (e.detail === 2 && x?.IsVariable === true) {
                              e.target.readOnly = false;
                              $(e.target).removeClass("bg-light");
                            }
                          }}
                          onBlur={(e) => {
                            var value = CheckIsNigativeRate(
                              +e.target.value,
                              x?.OriAddSup
                            );
                            e.target.value = value;

                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId &&
                                y?.VendorBillId === x?.VendorBillId
                            );
                            if (a > -1) {
                              b[a].Rate = value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: value,
                              Quantity: RoundToFixed(
                                x?.Quantity ?? 0,
                                x?.Rounding,
                                x?.IsRounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            setIsEditedCharges(true);
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                        />
                      </td>
                      <td className={`border-0 position-relative`}>
                        <IsRounding x={x} ChargesReadOnly={ChargesReadOnly} />
                        <input
                          type="number"
                          className={`form-control ${(x?.Quantity || 0) === 0 &&
                            "border border-danger"}`}
                          disabled={
                            ChargesReadOnly ? true : x?.IsReadOnly || IsReadOnly
                          }
                          style={{ minWidth: "72px" }}
                          id={`${iiii}Id`}
                          onBlur={(e) => {
                            var value = +e.target.value;
                            if (type === "Payables" && value < 0) {
                              value = CheckIsNigativeRate(value, x?.OriAddSup);
                              e.target.value = value;
                            } else {
                              CheckIsNigativePositive(
                                value,
                                Charges,
                                index,
                                x?.ChargeId,
                                true
                              );
                            }

                            var b = JSON.parse(JSON.stringify(Charges));
                            var a = b.findIndex(
                              (y) =>
                                y.ChargeId === x?.ChargeId &&
                                y.OriAddSup === x?.OriAddSup &&
                                y?.InvoiceId === x?.InvoiceId &&
                                y?.VendorBillId === x?.VendorBillId
                            );
                            if (a > -1) {
                              b[a].Quantity = value;
                              setCharges(b);
                            }
                            const c = CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: value,
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            });
                            setIsEditedCharges(true);
                            $(`.RatesTotal${iiii} input`).val(c.toFixed(2));
                          }}
                          onChange={(e) => {
                            if (x?.IsRounding === true)
                              GetLengthRound(e, x?.Rounding);
                          }}
                          defaultValue={InputValueCharge(x, ChargesReadOnly)}
                        />
                      </td>
                      <td
                        className={`border-0 ${!HideIsNotBill(
                          x?.VendorBill,
                          BillId?.toString()
                        ) &&
                          `RatesTotal-${type}-${ChargesId}`} RatesTotal${iiii} `}
                      >
                        <input
                          className="form-control"
                          disabled={true}
                          style={{ minWidth: "72px" }}
                          value={
                            CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: RoundToFixed(
                                checkIs0(x?.Quantity, iiii),
                                x?.Rounding,
                                x?.IsRounding
                              ),
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                              OriAddSup: x?.OriAddSup,
                            })?.toFixed(2) ?? 0
                          }
                        />
                      </td>
                      {!IsReadOnly && (
                        <>
                          <td className={`border-0`}>
                            <button
                              type="button"
                              className="btn btn-light-danger w-100"
                              disabled={ChargesReadOnly ? true : x?.IsReadOnly}
                              onClick={() => {
                                let a = Charges || [];
                                let b = ChargesN || [];
                                a = a?.filter((y) => y !== x);
                                if (x?.OriAddSup !== 2)
                                  b.push({
                                    ...x,
                                    Id: x?.ChargeId,
                                    Rate: x?.Rate,
                                    Name: x?.Name,
                                    Code: x?.Code,
                                    Quantity: 1,
                                    RateType: x?.RateType,
                                    MinCharge: x?.MinCharge,
                                    MaxCharge: x?.MaxCharge,
                                    FreeTime: x?.FreeTime,
                                    Position: x?.Position,
                                    CategoryPosition: x?.CategoryPosition,
                                    Calcul: x?.Calcul,
                                    Category: x?.Category,
                                    IsVariable: x?.IsVariable || false,
                                    Invoice: x?.Invoice,
                                    InvoiceId: x?.InvoiceId,
                                    OriAddSup: x?.OriAddSup,
                                    IsReadOnly: x?.IsReadOnly || false,
                                    DateEnd: x?.DateEnd,
                                    DateStart: x?.DateStart,
                                    QuoteId: x?.QuoteId,
                                    Rounding: x?.Rounding,
                                    CalculName: x?.CalculName,
                                    RatingTable: x?.RatingTable,
                                  });
                                setCharges(a);
                                setChargesN(b);
                                setIsEditedCharges(true);
                              }}
                            >
                              <i className={`fas fa-times fa-sm`} />
                            </button>
                          </td>
                          <td className={`border-0`}>
                            <MenuListComposition
                              Child1={
                                <button
                                  type="button"
                                  className="btn btn-light-warning w-100"
                                  onClick={() => {
                                    var autoRatingCheck = [x?.Id];
                                    setAutoRatingCheck(autoRatingCheck);

                                    var find = SortOnlyCharge(
                                      Charges,
                                      autoRatingCheck,
                                      AutoRating,
                                      IsHot
                                    ).length;

                                    if (find > 0) {
                                      setAutoRatingModal(true);
                                      setIsEditedCharges(true);
                                    } else {
                                      AutoRatingCharges({
                                        setAutoRatingModal: setAutoRatingModal,
                                        setCharges: setCharges,
                                        Charges: Charges,
                                        AutoRating: AutoRating,
                                        IsHot: IsHot,
                                        bool: true,
                                        AutoRatingCheck: AutoRatingCheck,
                                        IndexId: ChargesId,
                                      });
                                      setIsEditedCharges(true);
                                    }
                                  }}
                                >
                                  <i className={`fas fa-magic fa-sm`} />
                                </button>
                              }
                              Child2={
                                <button
                                  type="button"
                                  className="btn btn-light-primary w-100"
                                  onClick={() => {
                                    setNoteModal({
                                      Show: true,
                                      Id: x?.ChargeId,
                                      Code: x?.Code,
                                      Note: x?.Note,
                                      index: index,
                                    });
                                  }}
                                >
                                  <i
                                    className={`${
                                      x?.Note ? "fas" : "far"
                                    } fa-sticky-note fa-sm`}
                                  ></i>
                                </button>
                              }
                            />
                          </td>
                        </>
                      )}
                    </tr>
                    <tr className={colorTable}>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      <td className="row mb-0 border-0 place-content-center py-0">
                        {(x?.FreeTime || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-primary text-center btn-sm p-2"
                          >
                            FT : {ReturnEmptyOrString(x?.FreeTime)}
                          </button>
                        )}
                        {(x?.MinCharge || null) !== null && x?.OriAddSup == 0 && (
                          <button
                            type="button"
                            className="btn btn-info text-center mx-2 btn-sm p-2"
                          >
                            Min : {ReturnEmptyOrString(x?.MinCharge)}
                          </button>
                        )}
                        {(x?.MaxCharge || null) !== null && (
                          <button
                            type="button"
                            className="btn btn-warning text-center btn-sm p-2"
                          >
                            Max : {ReturnEmptyOrString(x?.MaxCharge)}
                          </button>
                        )}
                      </td>
                      <td className={`border-0`}></td>
                      <td className={`border-0`}></td>
                      {!IsReadOnly && (
                        <>
                          <td className={`border-0`} />
                          <td className={`border-0`} />
                        </>
                      )}
                    </tr>

                    {x?.SubTotal && (
                      <tr className={colorTable}>
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0`} />
                        <td className={`border-0 text-right`}>
                          <span
                            style={{ whiteSpace: "nowrap" }}
                            className={`btn p-3 text-white font-weight-bolder bg-${ChargesColorCategory(
                              x?.Category
                            )}`}
                          >
                            Sub Total
                          </span>
                        </td>
                        <td className={`border-0`}>
                          <span
                            style={{ whiteSpace: "nowrap" }}
                            className={`btn ${CategoryTotalId(
                              x?.Category,
                              x?.Invoice,
                              x?.VendorBill,
                              ChargesId,
                              type
                            )} text-${ChargesColorCategory(
                              x?.Category
                            )} bg-light-${ChargesColorCategory(x?.Category)}`}
                          />
                        </td>
                        {!IsReadOnly && (
                          <>
                            <td className={`border-0`} />
                            <td className={`border-0`} />
                          </>
                        )}
                      </tr>
                    )}
                  </tbody>
                </>
              );
            })}
            <tbody className="border-0">
              <tr>
                <td colSpan="3" className="border-0" />
                <td className="text-right border-0">
                  <span className={`btn p-3 text-dark bg-secondary`}>
                    <b>Total</b>
                  </span>
                </td>
                <td className={`border-0 RateAllTotal-${type}-${ChargesId}`}>
                  <span
                    className="btn p-3 text-black bg-light"
                    style={{ whiteSpace: "nowrap" }}
                  ></span>
                </td>
                <td className="border-0" />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {ChargesRemove?.length > 0 && (
        <ChargesRemoveComponent ChargesRemove={ChargesRemove} />
      )}
      {Charges?.length === 0 && <Spinner2s />}
      {!IsReadOnly && (
        <>
          <div className="col-lg-8 text-black p-5 font-weight-bold">
            {Charges.findIndex((x) => x?.IsReadOnly === true) === -1 && (
              <button
                type="button"
                className="btn btn-light-success p-2"
                onClick={() => {
                  setChargeModel({ Show: true, Type: "success" });
                }}
                disabled={
                  ChargesReadOnly || ChargesN?.length === 0 || loadingCharges
                }
              >
                <i className={`fas fa-plus fa-sm`}></i> Add Charge
              </button>
            )}
            {Charges.findIndex((x) => x?.IsReadOnly === true) > -1 && (
              <>
                {ChargesN && (
                  <button
                    type="button"
                    className={"btn btn-light-primary p-2 ml-2"}
                    onClick={() => {
                      setChargeModel({ Show: true, Type: "primary" });
                    }}
                    disabled={
                      ChargesReadOnly ||
                      ChargesN?.length === 0 ||
                      Charges.findIndex(
                        (x) => x?.OriAddSup === 2 && !x?.IsReadOnly
                      ) > -1
                    }
                  >
                    <i className={`fas fa-plus fa-sm`}></i> Add Additional
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-light-info p-2 ml-2"
                  onClick={() => {
                    setChargeModel({ Show: true, Type: "info" });
                  }}
                  disabled={
                    ChargesReadOnly ||
                    Charges?.length === 0 ||
                    Charges.findIndex(
                      (x) => x?.OriAddSup === 1 && !x?.IsReadOnly
                    ) > -1
                  }
                >
                  <i className={`fas fa-plus fa-sm`}></i> Add Supplemental
                </button>
              </>
            )}
          </div>
          <div className="col-lg-2 text-black p-5 font-weight-bold text-right d-none">
            <button
              type="button"
              disabled={ChargesReadOnly || loadingCharges}
              className="btn btn-light-primary p-2 w-100"
              onClick={() => {
                GetPayableForVendor({ button: true });
              }}
            >
              {loadingCharges && (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  style={{
                    fontSize: "10px",
                    width: "20px",
                    height: "20px",
                    marginRight: "3px",
                  }}
                />
              )}
              <i className={`fas fa-square-root-alt fa-sm`}></i> Calculated
            </button>
          </div>
          <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
            <button
              id="AutoRatingBtn"
              type="button"
              disabled={ChargesReadOnly || loadingCharges || loadingCharges}
              className={`btn btn-light-warning p-2 w-100 AutoRatingBtn-${ChargesId}`}
              onClick={() => {
                var list = [];
                Charges.filter((y) => !y?.IsReadOnly).forEach((x) =>
                  list.push(x?.Id)
                );
                setAutoRatingCheck(list);

                var find = SortOnlyCharge(Charges, false, AutoRating, IsHot)
                  .length;
                if (find > 0) {
                  setAutoRatingModal(true);
                  setIsEditedCharges(true);
                } else {
                  AutoRatingCharges({
                    setAutoRatingModal: setAutoRatingModal,
                    setCharges: setCharges,
                    Charges: Charges,
                    AutoRating: AutoRating,
                    IsHot: IsHot,
                    bool: true,
                    AutoRatingCheck: AutoRatingCheck,
                    IndexId: ChargesId,
                  });
                  setIsEditedCharges(true);
                }
              }}
            >
              <i className={`fas fa-magic fa-sm`}></i> Auto Rate
            </button>

            <input
              type="hidden"
              data-type=""
              className="BtnAutoRating"
              onClick={(e) => {
                //get type --------------------------------------------------
                var type = e.target.getAttribute("data-type");

                var list = [];
                Charges.filter((x) => type.includes(x.Calcul)).forEach((x) => {
                  if (type.includes("perdaychassis")) {
                    x.DateStart = FieldID("C_PickedUpDate" + ChargesId);
                    x.DateEnd = FieldID("C_TerminatedDate" + ChargesId);
                  }
                  list.push(x?.Id);
                });

                var autoRatingCheck = list;
                setAutoRatingCheck(list);

                //get type --------------------------------------------------

                var find = SortOnlyCharge(
                  Charges,
                  autoRatingCheck,
                  AutoRating,
                  IsHot
                ).length;
                if (find > 0) {
                  setAutoRatingModal(true);
                  setIsEditedCharges(true);
                } else {
                  AutoRatingCharges({
                    setAutoRatingModal: setAutoRatingModal,
                    setCharges: setCharges,
                    Charges: Charges,
                    AutoRating: AutoRating,
                    IsHot: IsHot,
                    bool: true,
                    AutoRatingCheck: AutoRatingCheck,
                    IndexId: ChargesId,
                  });
                  setIsEditedCharges(true);
                }
              }}
            />
          </div>
          <div className="col-lg-2 text-black p-5 font-weight-bold text-right">
            <button
              type="button"
              disabled={ChargesReadOnly || loadingCharges}
              className="btn btn-light-primary p-2 w-100"
              onClick={() => {
                GetPayableForVendor({ button: true });
              }}
            >
              {loadingCharges && (
                <Spinner
                  as="span"
                  animation="border"
                  role="status"
                  aria-hidden="true"
                  style={{
                    fontSize: "10px",
                    width: "20px",
                    height: "20px",
                    marginRight: "3px",
                  }}
                />
              )}
              <i className={`fas fa-square-root-alt fa-sm`}></i> Calculated
            </button>
          </div>
        </>
      )}
    </div>
  );
};

async function PostNote(id, text, type) {
  return fetch(window.$apiurl + "/Shipments/postchargesnotes", {
    method: "POST",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      Id: id,
      Note: text,
      Type: type,
    }),
  }).then(async (response) => {
    const statusCode = response.status;
    const data = await response.json();
    return Promise.all([statusCode, data]);
  });
}

function SaveDisabledFunction(bool) {
  document.querySelectorAll(".SaveDisabled").forEach((el) => {
    if (bool) el.classList.add("disabled");
    else el.classList.remove("disabled");
  });
}

function PeoplesComponent() {
  const [Selected, setSelected] = useState([]);
  const [SelectedItem, setSelectedItem] = useState(false);
  return (
    <div>
      <Select
        isSearchable={true}
        isClearable
        isMulti
        components={{ Option: CustomOption }}
        options={[
          {
            label: "Abdlhadi Tahir",
            value: 1,
            img:
              "https://ca.slack-edge.com/T0C82LU2Z-U0C86RBND-1fa410822c2b-512",
          },
          {
            label: "Zakaria Issaad",
            value: 11,
            img:
              "https://ca.slack-edge.com/T0C82LU2Z-UD72JPHRQ-ea43b29fdbc0-512",
          },
          {
            label: "Zakaria Baabit",
            value: 2,
            img:
              "https://ca.slack-edge.com/T0C82LU2Z-U76350YLV-7993a0d1a955-512",
          },
          {
            label: "yassine younsi",
            value: 3,
            img:
              "https://ca.slack-edge.com/T0C82LU2Z-U0116PFB4DU-84613914321f-512",
          },
          {
            label: "El Khaoua Karim",
            value: 4,
            img:
              "https://ca.slack-edge.com/T0C82LU2Z-UC5AGTS6T-8a8b8e186be9-512",
          },
        ]}
        onChange={(x) => {
          setSelected(x);
        }}
      />
      <br />
      <br />
      <div className="row">
        <div className="col-4">
          <div className="text-primary mb-3">
            <h6>Assignee :</h6>
          </div>
          {Selected.map((item) => (
            <PeoplesProfile
              Info={item}
              setSelectedItem={setSelectedItem}
              SelectedId={SelectedItem?.value}
            />
          ))}
        </div>

        <div className="col-8">
          {SelectedItem?.value && (
            <>
              <div className="text-primary mb-3 ml-5 pl-3">
                <h6>Detail :</h6>
              </div>
              {IsValid("Start Time", "09-06-2021, 5:57 PM")}{" "}
              {IsValid("End Time", "None")}
              {IsValid("Time remaining", "5H:30")}
              {IsValid(
                "Notes",
                "",
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  placeholder="Notes"
                ></textarea>
              )}
              {IsValid(
                "Priority",
                "",
                <Select
                  isSearchable={true}
                  isClearable
                  defaultValue={{
                    label: "Medium",
                    value: 2,
                  }}
                  options={[
                    {
                      label: "Highest",
                      value: 1,
                    },
                    {
                      label: "Medium",
                      value: 2,
                    },
                    {
                      label: "Low",
                      value: 3,
                    },
                    {
                      label: "Lowest",
                      value: 4,
                    },
                  ]}
                />
              )}
              {IsValid("Cartons Count ", "177")}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const CustomOption = (props) => {
  const [Info, setInfo] = useState({});
  useEffect(() => {
    var find = props.options.find((x) => x.value === props.value);
    setInfo(find);
  }, [props.options]);

  return !props.isDisabled ? (
    <div {...props.innerProps}>
      <PeoplesProfile Info={Info} />
    </div>
  ) : null;
};

function PeoplesProfile({ Info, setSelectedItem, SelectedId }) {
  return (
    <div
      className={
        "shadow-sm p-2 mb-5 rounded mb-5 " +
        (SelectedId === Info?.value ? "bg-primary" : "bg-white")
      }
      onClick={() => {
        if (setSelectedItem) setSelectedItem(Info);
      }}
    >
      <img
        src={Info.img}
        className="shadow p-1 bg-white"
        style={{
          height: "45px",
          borderRadius: "50%",
        }}
        alt="img"
      />
      <b
        className={
          "ml-5 " + (SelectedId === Info?.value ? "text-white" : "text-dark")
        }
        style={{ fontSize: "12px" }}
      >
        {Info.label}
      </b>
    </div>
  );
}
function IsValid(name, str, Components = false) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row w-100 my-2 ml-3">
        <div className="col-lg-4 font-weight-bold text-dark text-left pl-5">
          {name}
        </div>
        <div className="col-lg-8 text-primary">
          {Components ? (
            Components
          ) : (
            <>: {str || <span className="text-muted">Empty</span>}</>
          )}
        </div>
      </div>
    </>
  );
}

function CheckVandorIsDanger(Vendors, VendorsList = []) {
  var checkBool = false;
  VendorsList.forEach((x) => {
    var checkVendor = Vendors.find((y) => y?.Id === x?.VendorId);
    var checkOrg = vendorsOrg.find(
      (y) => y?.Id === x?.IdOrg && y?.VendorId === x?.VendorId
    );
    if (
      !checkOrg &&
      checkVendor &&
      checkVendor.AlertType === "Danger" &&
      x?.FreightChargeId === 98
    ) {
      checkBool = true;
      const alertMessage = checkVendor?.AlertMessage || [];
      alertMessage.map((x) => {
        const msgType =
          checkVendor?.AlertType === "Danger" ? "error" : "warning";
        Notification(msgType, checkVendor?.Name + " : " + x);
        return x;
      });
    }
  });
  return checkBool;
}
