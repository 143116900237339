import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import $ from "jquery";

export function CreatableSelect2({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  required,
  ...props
}) {
  const { valueSelect, options, onChange, name, setFieldValue } = props;
  const [state, setstate] = useState(0);
  const [state2, setstate2] = useState(true);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  useEffect(() => {
    if (valueSelect && valueSelect > 0 && options.length > 0 && state2) {
      var b = options.find((x) => x.value === +valueSelect);
      setstate(b);
      setTimeout(() => {
        if (onChange && typeof onChange === "function") {
          onChange(b);
        }
      }, 100);
      setstate2(false);
    }
  }, [valueSelect, options]);

  useEffect(() => {
    if (options?.length > 0 && !state) {
      $(`[name="${name}"]`).ready(function() {
        $(`[name="${name}"]`).change(function() {
          var a = $(`[name="${name}"]`).val();
          if (a && a !== "") {
            var b = options.find((x) => x.value === +a);
            setstate(b);
            setTimeout(() => {
              if (onChange && typeof onChange === "function") {
                onChange(b);
              }
            }, 100);
          }
        });
      });
    }
  }, [options]);

  return (
    <>
      {label && (
        <label>
          {label} {required && <RequiredHtml />}
        </label>
      )}
      <CreatableSelect
        isSearchable={true}
        isClearable
        {...props}
        onChange={(newValue) => {
          if (onChange) onChange(newValue);
          var a = options.find((x) => x?.value === +newValue?.value);
          setstate(a);
          if (setFieldValue) setFieldValue(name, +newValue?.value);
        }}
        value={state}
      />
    </>
  );
}
