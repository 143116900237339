import {
  LOGIN_URL,
  ME_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
} from "../_redux/authCrud";
import userTableMock from "./userTableMock";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function mockAuth(mock) {
  async function Login(userName, password) {
    const obj = {
      UserName: userName,
      Password: password,
    };
    return fetch(`${window.$apiurl}/Auth`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Allow: "POST",
      },
      body: JSON.stringify(obj),
    })
      .then(async (response) => {
        const res = await response;
        if (res.status === 200) {
          return res.json();
        }
        return false;
      })
      .then((res) => {
        if (res?.Code === "400") {
          return false;
        }
        const userDefault = {
          FullName: res.FullName,
          Email: res.Email,
          ProfilePicture: res.ProfilePicture,
          IsCustomer: res.IsCustomer,
          DefaultCustomerId: res.DefaultCustomerId,
          DefaultCustomerName: res.DefaultCustomerName,
          DefaultServiceId: res.DefaultServiceId,
          DefaultServiceName: res.DefaultServiceName,
          DefaultModeId: res.DefaultModeId,
          DefaultModeName: res.DefaultModeName,
          Customers: res.Customers,
          Warehouses: res.Warehouses,
          RoleId: res.Role.Id,
          RoleName: res.Role.Name,
        };
        db.set("Token", res.Token).write();
        db.set("User", userDefault).write();
        const user = {
          id: 1,
          username: res.UserName,
          password: undefined,
          TimeZone: res.TimeZone,
          DefaultCustomerId: res.DefaultCustomerId,
          DefaultServiceId: res.DefaultServiceId,
          DefaultModeId: res.DefaultModeId,
          email: res.Email,
          accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
          refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
          roles: [1],
          pic: "",
          fullname: res.FullName,
          occupation: "",
          companyName: "",
          phone: res.Phone,
          RoleName: res.Role.Name,
          address: {
            addressLine: "",
            city: "",
            state: "",
            postCode: "",
          },
          socialNetworks: {
            linkedIn: "",
            facebook: "",
            twitter: "",
            instagram: "",
          },
        };
        setTimeout(() => {
          localStorage.setItem(
            `UserIsResetedPassword`,
            res.IsGeneratedPassword ? true : false
          );
        }, 1);
        setTimeout(() => {
          localStorage.setItem(`ReleaseNotesModalFetchSeen-${res.Email}`, true);
        }, 1);
        db.set("user", user).write();
        return user;
      });
  }

  //Forgot Password
  async function ForgotPassword(email) {
    return fetch(window.$apiurl + `/ForgotPassword?email=${email}`, {
      method: "post",
      withCredentials: true,
      headers: {
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  mock.onGet("auth/Login").reply(() => {
    const user = db.getState().User;

    return [200, user];
  });

  mock.onPost(LOGIN_URL).reply(async ({ data }) => {
    const { email, password } = JSON.parse(data);
    if (email && password && password.length > 7) {
      const user = await Login(email, password);
      if (user) {
        return [200, { ...user, password: undefined }];
      }
    }
    return [400];
  });

  mock.onPost(REGISTER_URL).reply(({ data }) => {
    const { email, fullname, username, password } = JSON.parse(data);

    if (email && fullname && username && password) {
      const user = {
        id: generateUserId(),
        email,
        fullname,
        username,
        password,
        roles: [2],
        accessToken: "access-token-" + Math.random(),
        refreshToken: "access-token-" + Math.random(),
        pic: process.env.PUBLIC_URL + "/media/users/default.jpg",
      };

      userTableMock.push(user);

      return [200, { ...user, password: undefined }];
    }

    return [400];
  });

  mock.onPost(REQUEST_PASSWORD_URL).reply(async ({ data }) => {
    const { email } = JSON.parse(data);
    if (email) {
      const user = await ForgotPassword(email);
      console.log(user);
      if (user) {
        user.password = undefined;
        return [200, { ...user, password: undefined }];
      }
    }

    return [400];
  });

  mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
    const user = db.getState().user;
    if (user) {
      return [200, { ...user, password: undefined }];
    }
    return [401];
  });

  function generateUserId() {
    const ids = userTableMock.map((el) => el.id);
    const maxId = Math.max(...ids);
    return maxId + 1;
  }
}
