import React, { useEffect, useState } from "react";
import Index from "./AssigntoOutboundV3/Index";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { CreatableSelect3 } from "../../../_metronic/_partials/controls";
import SelectPagination from "../../../_metronic/_partials/widgets/Select/SelectPagination";
import { Notification } from "../../modules/Notification";
import Button from "@material-ui/core/Button";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";
import { OneMoveMaster } from "../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";

const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export function AssigntoOutboundV3() {
  const [ShipmentIDStat, setShipmentIDStat] = useState([]);
  const [ShipmentsIds, setShipmentsIds] = useState([]);
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);
  const [IsClosed, setIsClosed] = useState(0);
  const [CustomerName, setCustomerName] = useState("");
  const [OrdersIds, setOrdersIds] = useState([]);

  useEffect(() => {
    GetShipmentsIds();
    /* GetOrdersIds(); */
    window.$ShipmentsAssigntoOutbound = [];
    window.$ReceiptsAssigntoOutbound = [];
  }, []);

  function IsClosedFunction(params) {
    setIsClosed(params);
  }

  // async function GetOrdersIds() {
  //   return await fetch(`${window.$apiurl}/transload/getordersids`, {
  //     method: "get",
  //     withCredentials: true,
  //     headers: {
  //       Accept: "application/json, text/plain, */*",
  //       Authorization: `bearer ${db.read().getState().Token}`,
  //       "X-FP-API-KEY": "iphone",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(async (response) => {
  //       const statusCode = response.status;
  //       const data = await response.json();
  //       return Promise.all([statusCode, data]);
  //     })
  //     .then((res) => {
  //       setOrdersIds(res[1]);
  //     });
  // }

  async function GetShipmentsIds() {
    return await fetch(`${window.$apiurl}/shipments/getids`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setShipmentsIds(res[1]);
      });
  }

  async function GetShipmentDetail(id) {
    return await fetch(`${window.$apiurl}/transload/shipment/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        setCustomerName(result?.Shipment?.CustomerAddress?.CompanyName);
      });
  }
  async function PostNewShipment() {
    var label = $("#LabelForShipmentID").val() || "";
    return await fetch(
      `${window.$apiurl}/transload/shipment?label=${label}&orderId=`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        setShipmentNewModelt(false);
        GetShipmentsIds();
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <>
      <Helmet>
        <title>eMPower | Assign To Outbound</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Modal show={ShipmentNewModel}>
        <Modal.Header closeButton>
          <Modal.Title>New Shipment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Shipment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-success btn-elevate"
            onClick={async () => {
              setShipmentNewModelt(false);
              var a = await PostNewShipment();
              setShipmentIDStat([]);
              setTimeout(() => {
                if (a?.Id) setShipmentIDStat([a?.Id]);
              }, 1);
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header font-weight-bold py-0 pt-5">
          <div className="form-group row mb-0">
            <div className="col-lg-4 text-left" />
            <div className="col-lg-1 text-left mt-3" />
            {(IsClosed === 0 || ShipmentIDStat.length === 0) && (
              <div className="col-lg-2 text-left"></div>
            )}

            <div className="col-lg-2 text-left">
              <button
                type="button"
                className="btn btn-light-success font-weight-bolder font-size-sm w-100 mt-3 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  setShipmentNewModelt(true);
                }}
              >
                <i className="fas fa-plus-circle"></i> New
              </button>
            </div>
            {IsClosed !== 0 && ShipmentIDStat.length > 0 && (
              <div className="col-lg-2 text-left">
                <button
                  type="button"
                  className="btn btn-light-danger font-weight-bolder font-size-sm w-100 mt-3 pt-3 pb-2 mb-3"
                  style={mystyleTabs}
                  onClick={() => {
                    PosttoClosed(ShipmentIDStat, setIsClosed);
                  }}
                >
                  <i
                    className={`fas fa-${
                      !IsClosed ? "times-circle" : "lock-open"
                    }`}
                  ></i>{" "}
                  {!IsClosed ? "Close" : "UnClose"}
                </button>
              </div>
            )}
            <div className="col-lg-3 text-left">
              <SelectPagination
                className="w-100 mt-3"
                name="xxxx"
                options={ShipmentsIds.map((x) => {
                  return {
                    value: x,
                    label: x,
                  };
                })}
                onChange={async (newValue) => {
                  var i = newValue?.value;
                  setShipmentIDStat([]);
                  setTimeout(() => {
                    if (i) setShipmentIDStat([i]);
                  }, 1);
                  await GetShipmentDetail(i);
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-body AssigntoOutboundId">
          {ShipmentIDStat.length > 0 ? (
            <>
              <Index
                iShipmentsID={ShipmentIDStat}
                IsClosedFunction={IsClosedFunction}
                IsClosed={IsClosed}
                OrdersIds={OrdersIds}
              />
              <br />
              <br />
              <br />
              <div className="modal-footer">
                <button
                  type="button"
                  id="ShipmentDetailsBtn"
                  className="btn btn-primary btn-elevate"
                  onClick={() => {
                    window
                      .open(`/report/Outbound/${ShipmentIDStat[0]}`, "_blank")
                      .focus();
                  }}
                >
                  Shipment Manifest
                </button>
                <OneMoveMasterFunction ShipmentIDStat={ShipmentIDStat[0]} />
              </div>
            </>
          ) : (
            <Nodatafound />
          )}
        </div>
      </div>
    </>
  );
}

function Nodatafound() {
  return (
    <div className="row mt-5 pt-5">
      <div className="col-lg-4" />
      <div className="col-lg-1 text-right">
        <i
          className="fas fa-quote-left"
          style={{ fontSize: "70px", opacity: 0.3 }}
        />
      </div>
      <div className="col-lg-4 mt-2">
        <p className="h1 mt-5" style={{ opacity: 0.3 }}>
          No data found
        </p>
      </div>
      <div className="col-lg-3" />
    </div>
  );
}

const OneMoveMasterFunction = ({ ShipmentIDStat }) => {
  const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);
  return (
    <>
      <Modal
        size="xl"
        show={OneMoveMasterShow}
        onHide={() => {
          setOneMoveMasterShow(false);
        }}
      >
        <OneMoveMaster
          Show={setOneMoveMasterShow}
          statSHipmentId={ShipmentIDStat}
        />
      </Modal>
      <button
        type="button"
        id="ShipmentDetailsBtn"
        className="btn btn-primary btn-elevate"
        onClick={() => {
          window.shipmentEditCommingFrom = "AssigntoOutbound";

          setOneMoveMasterShow(true);
        }}
      >
        Shipment Details
      </button>
    </>
  );
};

async function PosttoClosed(PalletsIDStat, setIsClosed) {
  return await fetch(
    `${window.$apiurl}/transload/closeshipment/${PalletsIDStat}`,
    {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    const statusCode = response.status;
    if (statusCode === 200) {
      Notification("success", "success");
      setIsClosed((x) => !x);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}
