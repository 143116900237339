import { IconButton } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
export default function DynamicReport({
  showModal,
  setShowModal,
  id,
  title,
  reportContent
}) {
  return (
    <Modal
      id="modalReports"
      size="xl"
      show={showModal}
      fullscreen={true}
      onHide={() => {
        setShowModal(false);
      }}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <div className="row w-100">
          <div className="col-lg-6">
            <h3 className="font-weight-bolder text-dark">Preview {title}</h3>
          </div>
        </div>
        <IconButton
          className="MuiButtonBase-root MuiIconButton-root jss179"
          aria-label="Close"
          onClick={() => {
            setShowModal(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>
      <Modal.Body className="modalReportBody">{reportContent}</Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShowModal(false);
          }}
          className="btn btn-light btn-elevate"
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}