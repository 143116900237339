import React from "react";

export function IsValid(name, str, Components = false) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row my-2">
        <div className="col-xl-5 col-lg-6 col-md-6 col-6 font-weight-bold text-dark text-left">
          {name}
        </div>
        <div className="col-xl-7 col-lg-6 col-md-6 col-6 text-primary">
          {Components ? (
            Components
          ) : (
            <>: {str || <span className="text-muted">Empty</span>}</>
          )}
        </div>
      </div>
    </>
  );
}
export function IsValidColumn(name, str) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row  my-2">
        <div className="col-xl-5 col-lg-4 col-md-6 col-6 font-weight-bold text-dark text-left">
          {name}
        </div>
        <div className="col-xl-7 col-lg-8 col-md-6 col-6 text-primary">
          {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}
