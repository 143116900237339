import React, { useEffect, useState } from "react";
import Item from "./Item";
import { Spinner } from "react-bootstrap";
import { DetailMangment } from "../../../../modules/eMPower/pages/PalletsManagmentBeta/customers-table/ModelManagment";
import { Modal } from "react-bootstrap";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";

const adapter = new LocalStorage("db");
const db = low(adapter);

export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  ShipmentID,
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);
  const [Search2, setSearch2] = useState("");
  const [ShowModel, setShowModel] = useState(false);
  const [IdSelect, setIdSelect] = useState();

  function SelectIDforPallet(id) {
    setIdSelect(id);
    setShowModel(true);
  }

  useEffect(() => {
    GetPalletsFunction();
  }, []);

  async function GetPalletsFunction() {
    const pallets = await GetPallets(ShipmentID);
    (pallets ?? []).forEach((x) => {
      x.search =
        x?.Id +
        "|" +
        x?.Unitcount +
        "|" +
        x?.Location +
        "|" +
        x?.Items +
        "|" +
        x?.Weight +
        "|" +
        x?.Cube +
        "|";
    });
    setPallets(pallets);
    setShow(true);
  }

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) setPallets((x) => [...x, DeletRowId]);
  }, [DeletRowId]);

  function removeId(id) {
    var a = Pallets.filter((x) => x?.Id !== id);
    setPallets(a ?? []);
  }

  return (
    <>
      {" "}
      <Modal
        size="xl"
        show={ShowModel}
        onHide={() => {
          setShowModel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={IdSelect} setShow={setShowModel.bind()} />
      </Modal>
      <div className="row">
        <div className="col-lg-6 my-5" />
        <div className="col-lg-6 my-5">
          <input
            type="text"
            placeholder="Filter by all fields"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) setSearch2(e.target.value);
            }}
            onBlur={(e) => {
              setSearch2(e.target.value);
            }}
          />
        </div>

        {Pallets?.length > 0 ? (
          <>
            {Show &&
              Pallets.filter((x) =>
                x?.search?.includes(Search2)
              ).map((x, i) => (
                <Item
                  item={x}
                  key={i}
                  index={i}
                  SetTableShipment={SetTableShipment}
                  removeId={removeId}
                  ShipmentID={ShipmentID}
                  SelectIDforPallet={SelectIDforPallet}
                />
              ))}
          </>
        ) : (
          <Nodatafound />
        )}
      </div>
    </>
  );
}

async function GetPallets(ShipmentID) {
  return await fetch(
    `${window.$apiurl}/Pallets?shipmentId=${ShipmentID}&IsShipped=false`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res[1]?.pallets;
    })
    .catch((error) => {
      console.error(error);
    });
}

function Nodatafound() {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 7000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              No Items found
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
