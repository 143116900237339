import React, { useRef, useEffect, useState } from "react";

export function CheckboxLargeWBFob({
  field,
  label,
  required,
  text,
  color = "success",
  disabled,
  Size,
  ...props
}) {
  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };
  const inputEl = useRef(null);
  const [colorStat, setColorStat] = useState("success");
  useEffect(() => {
    setColorStat(disabled ? "secondary" : color);
  }, [disabled]);
  return (
    <>
      {label && (
        <label>
          {" "}
          {label} {required && <RequiredHtml />}
        </label>
      )}

      <div className="d-flex align-items-center my-2 mr-3" style={{float : "right"}}>
        <label
          className={`checkbox checkbox-lg checkbox-light-${colorStat} checkbox-single flex-shrink-0 m-0 mr-4`}
        >
          <input type="checkbox" {...field} {...props} ref={inputEl} />
          <span></span>
        </label>
        <div className="d-flex flex-column flex-grow-1">
          <a
            href="javascript:(void)"
            className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
            style={{ fontSize: Size }}
            onClick={() => inputEl.current.click()}
          >
            {text}
          </a>
        </div>
      </div>
    </>
  );
}
