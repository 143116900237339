// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import { Icon } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import Radio from "@material-ui/core/Radio";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import clsx from "clsx";
import { ErrorMessage, Field, Formik } from "formik";
import $ from "jquery";
import low from "lowdb";
import moment from "moment";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Button, Form, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import SelectMulti from "react-select";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { Try } from "../../../../../../_metronic/_helpers/ATHelpers";
import {
  CheckboxLarge,
  CheckboxLargeWB,
  Input,
  RadioButton,
  Select,
  CreatableSelect3,
} from "../../../../../../_metronic/_partials/controls";
import CreatableSelect from "react-select/creatable";
import { Notification } from "../../../../Notification";
import { DownshiftMultipleSelectCustom } from "./DownshiftMultipleSelectCustom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { methodOf } from "lodash";

const adapter = new LocalStorage("db");
const db = low(adapter);
function handleChangeMultiple(event) {
  if (event.target.checked) {
    for (const option of document.querySelectorAll(
      "#WarehousesAccessId option"
    )) {
      option.removeAttribute("selected");
      option.setAttribute("selected", "selected");
    }
  } else {
    for (const option of document.querySelectorAll(
      "#WarehousesAccessId option"
    )) {
      option.removeAttribute("selected");
    }
  }
}
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [ETAGrid, setETAGrid] = useState([
    {
      Id: 0,
      ServiceId: "",
      ModeId: "",
      ServiceLevelId: "",
      MilesPerDay: 0,
      FromCity: "",
      FromState: "",
      ToCity: "",
      ToState: "",
      TransitDays: 0,
    },
  ]);
  const [ServiceList, setServiceList] = useState([]);
  const [Services, setServices] = useState([
    {
      Id: 0,
      ServiceId: "",
      ModeId: "",
      ServiceLevelId: "",
      MilesPerDay: 0,
      // SlaParameter: 0,
      items: [
        {
          FromCity: "",
          FromState: "",
          ToCity: "",
          ToState: "",
          TransitDays: 0,
        },
      ],
    },
  ]);
  function hasDuplicateObjects(array) {
    const set = new Set();

    for (let i = 0; i < array.length; i++) {
      const obj = array[i];

      const values = [
        obj.ServiceId,
        obj.ModeId,
        obj.ServiceLevelId,
      ];

      if (set.has(values.join("|"))) {
        return true;
      } else {
        set.add(values.join("|"));
      }
    }

    return false;
  }




  // create function that add new row to Services
  const addNewRow = () => {
    setServices([
      ...Services,
      {
        Id: 0,
        ServiceId: "",
        ModeId: "",
        ServiceLevelId: "",
        MilesPerDay: 0,
        // SlaParameter: 0,
        Items: [
          {
            FromCity: "",
            FromState: "",
            ToCity: "",
            ToState: "",
            TransitDays: 0,
          },
        ],
      },
    ]);
  };

  const addNewRowItems = (index) => {
    const values = [...Services];
    values[index].Items = [
      ...values[index].Items,
      {
        FromCity: "",
        FromState: "",
        ToCity: "",
        ToState: "",
        TransitDays: 0,
      },
    ];
    setServices(values);
  };

  const removeRowItems = (index, index2) => {
    const values = [...Services];
    values[index].Items.splice(index2, 1);
    setServices(values);
  };

  const removeRow = (index) => {
    const values = [...Services];
    values.splice(index, 1);
    setServices(values);
  };

  const handleChangeInput = (index, event) => {
    const values = [...Services];
    if (event.target.name === "ServiceId") {
      values[index].ServiceId = event.target.value;
    } else if (event.target.name === "ModeId") {
      values[index].ModeId = event.target.value;
    } else if (event.target.name === "ServiceLevelId") {
      values[index].ServiceLevelId = event.target.value;
    } else if (event.target.name === "MilesPerDay") {
      values[index].MilesPerDay = event.target.value;
    }
    // else if (event.target.name === "SlaParameter") {
    //   values[index].SlaParameter = event.target.value;
    // }
    setServices(values);
  };

  const handleChangeInputItems = (index, index2, event) => {
    const values = [...Services];
    if (event.target.name === "FromCity") {
      values[index].Items[index2].FromCity = event.target.value;
    } else if (event.target.name === "FromState") {
      values[index].Items[index2].FromState = event.target.value;
    } else if (event.target.name === "ToCity") {
      values[index].Items[index2].ToCity = event.target.value;
    } else if (event.target.name === "ToState") {
      values[index].Items[index2].ToState = event.target.value;
    } else if (event.target.name === "TransitDays") {
      values[index].Items[index2].TransitDays = event.target.value;
    }
    setServices(values);
  };

  const handleChangeSelect = (index, name, value) => {
    const values = [...Services];
    values[index][name] = value ? value : "";
    setServices(values);
  };

  const handleChangeSelectItems = (index, index2, name, value) => {
    const values = [...Services];
    values[index].Items[index2][name] = value ? value : "";
    setServices(values);
  };


  const createServicesUI = () => {
    return Services?.map((r, index) => (
      <div
        key={index}
        style={{
          border: "1px solid rgb(221, 221, 221)",
          backgroundColor: "#f3f6f9",
          marginBottom: "20px",
          padding: "20px",
          position: "relative",
        }}
      >
        <div className="row mb-5 pb-5">
          <div className="col-lg-3">
            <p style={{ marginBottom: "8px" }}>Service</p>
            <SelectMulti
              isClearable
              name="ServiceId"
              id="ServiceId"
              label="Service"
              placeholder="Service"
              value={Service?.Services?.filter((a) => a.Id === r?.ServiceId).map(
                (suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                })
              )}
              onChange={(event) => handleChangeSelect(index, "ServiceId", event?.value)}
              options={Service?.Services?.map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))}
            />
          </div>
          <div className="col-lg-3">
            <p style={{ marginBottom: "8px" }}>Mode</p>
            <SelectMulti
              isClearable
              name="ModeId"
              id="ModeId"
              label="Mode"
              inputId="react-select-single"
              placeholder="Mode"
              value={Service?.Services?.filter((a) => a.Id === r?.ServiceId)[0]
                ?.Modes?.filter((a) => a.Id === r?.ModeId)
                .map((suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                }))}
              onChange={(event) => handleChangeSelect(index, "ModeId", event?.value)}
              options={Service?.Services?.filter(
                (a) => a.Id === r?.ServiceId
              )[0]?.Modes?.map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))}
            />
          </div>
          <div className="col-lg-3">
            <p style={{ marginBottom: "8px" }}>Service Level</p>
            <SelectMulti
              isClearable
              name="serviceLevelId"
              id="serviceLevelId"
              label="Servic Level"
              inputId="react-select-single"
              placeholder="Servic Level"
              value={Service?.Services?.filter((a) => a.Id === r?.ServiceId)[0]
                ?.Modes?.filter((a) => a.Id === r?.ModeId)[0]
                ?.ServiceLevels?.filter((a) => a.Id === r?.ServiceLevelId)
                .map((suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                }))}
              onChange={(event) => handleChangeSelect(index, "ServiceLevelId", event?.value)}
              options={Service?.Services?.filter((a) => a.Id === r?.ServiceId)[0]
                ?.Modes?.filter((a) => a.Id === r?.ModeId)[0]
                ?.ServiceLevels?.map((suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                }))}
            />
          </div>
          <div className="col-lg-3">
            <p style={{ marginBottom: "8px" }}>Miles Per Day</p>
            <input
              type="number"
              className="form-control"
              placeholder="Miles Per Day"
              name="MilesPerDay"
              label="Miles Per Day"
              value={r?.MilesPerDay}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </div>
          {/* <div className="col-lg-2">
            <p style={{ marginBottom: "8px" }}>Sla Parameter</p>
            <input
              type="number"
              className="form-control"
              placeholder="Sla Parameter"
              name="SlaParameter"
              value={r?.SlaParameter}
              onChange={(event) => handleChangeInput(index, event)}
            />
          </div> */}
          {/* <div className="col-lg-2">
            <button
              type="button"
              className="btn btn-sm btn-danger font-weight-bolder text-uppercase"
              onClick={() => removeRow(index)}
            >
              <i className="la la-trash-o"></i>Delete
            </button>
          </div> */}
          <span
            style={{
              position: "absolute",
              top: "-10px",
              left: "-10px",
            }}
            className="label label-md font-weight-bold label-rounded label-danger cursor-pointer"
            onClick={() => removeRow(index)}
          >
            X
          </span>
        </div>
        {r?.Items?.map((r2, index2) => (
          <div
            style={{
              position: "relative",
            }}
            className="row border-top"
            key={index2}
          >
            <div className="col-lg-3 my-5">
              <p style={{ marginBottom: "8px" }}>From State</p>
              <SelectMulti
                name="FromState"
                label="From State"
                options={statesList.map((item, index) => ({
                  value: item.Name,
                  label: item.Name,
                }))}
                value={statesList
                  ?.filter((a) => a.Name === r2?.FromState)
                  .map((suggestion) => ({
                    value: suggestion.Name,
                    label: suggestion.Name,
                  }))}

                onChange={(event) => handleChangeSelectItems(index, index2, "FromState", event?.value)}
                id="FromState"
              />
            </div>
            <div className="col-lg-2 my-5">
              <p style={{ marginBottom: "8px" }}>From City</p>
              <input
                className="form-control"
                placeholder="From City"
                name="FromCity"
                value={r2?.FromCity}
                onChange={(event) => handleChangeInputItems(index, index2, event)}
              />
            </div>
            <div className="col-lg-3 my-5">
              <p style={{ marginBottom: "8px" }}>To State</p>
              <SelectMulti
                name="ToState"
                options={statesList.map((item, index) => ({
                  value: item.Name,
                  label: item.Name,
                }))}
                value={statesList
                  ?.filter((a) => a.Name === r2?.ToState)
                  .map((suggestion) => ({
                    value: suggestion.Name,
                    label: suggestion.Name,
                  }))}
                onChange={(event) => handleChangeSelectItems(index, index2, "ToState", event?.value)}
              />
            </div>
            <div className="col-lg-2 my-5">
              <p style={{ marginBottom: "8px" }}>To City</p>
              <input
                className="form-control"
                placeholder="To City"
                name="ToCity"
                value={r2?.ToCity}
                onChange={(event) => handleChangeInputItems(index, index2, event)}
              />
            </div>
            <div className="col-lg-2 my-5">
              <p style={{ marginBottom: "8px" }}>Transit Days</p>
              <input
                className="form-control"
                name="TransitDays"
                label="Transit Days"
                value={r2?.TransitDays}
                onChange={(event) => handleChangeInputItems(index, index2, event)}
              />
            </div>
            {/* <button
              type="button"
              className="btn btn-sm btn-danger font-weight-bolder text-uppercase"
              onClick={() => removeRowItems(index, index2)}
            >
              <i className="la la-trash-o"></i>Delete
            </button> */}
            <span
              style={{
                position: "absolute",
                top: "-10px",
                right: "10px",
              }}
              className="label label-md font-weight-bold label-rounded label-danger cursor-pointer"
              onClick={() => removeRowItems(index, index2)}
            >
              X
            </span>
          </div>
        ))}
        {/* <button
          type="button"
          className="btn btn-sm btn-light-primary font-weight-bolder text-uppercase mr-2"
          onClick={() => addNewRowItems(index)}
          style={{ position: "absolute", bottom: "10px", right: "10px" }}
        >
          <i className="la la-plus"></i>Add
        </button> */}
        <span
          style={{ position: "absolute", bottom: "2px", right: "16px" }}
          className="label label-md font-weight-bold label-rounded label-primary cursor-pointer mt-5"
          onClick={() => addNewRowItems(index)}
        >
          +
        </span>
      </div>
    ));
  };


  const [LoacationsTransitDays, setLoacationsTransitDays] = useState([
    {
      Id: 0,
      Location: "",
      TransitDays: 0,
    },
  ]);

  const [TmsOptions, setTmsOptions] = useState([]);

  const [countryList, setcountryList] = useState([
    { firstName: "", lastName: "" },
  ]);
  const [locationOptionsList, setlocationOptionsList] = useState([]);
  const [warhousesList, setwarhousesList] = useState([]);

  const [ShipmentModeList, setShipmentModeList] = useState([]);
  const [postWarehouseList, setPostWarehouseList] = useState([]);
  const [getWarehouseList, setGetWarehouseList] = useState([]);
  const [statesList, setstatesList] = useState([]);
  const [LocationList, setLocationList] = useState([]);
  const [movableUnitOptionList, setmovableUnitOptionList] = useState([]);
  const [
    fulfillmentInvoicesOptionsList,
    setfulfillmentInvoicesOptionsList,
  ] = useState([]);
  const [carrierInfoOptionList, setcarrierInfoOptionListList] = useState([]);
  const [supplierOptionsList, setsupplierOptionsList] = useState([]);
  const [defaultInvoicesOptionsList, setdefaultInvoicesOptionsList] = useState(
    []
  );
  const [isActive, setisActive] = useState(false);
  const [InvoiceOptionId, setInvoiceOptionId] = useState([]);
  const [InvoiceGrpOptionId, setInvoiceGrpOptionId] = useState([]);
  const [TermsList, setTermsList] = useState([]);
  const [BillingAddress, setBillingAddress] = useState([]);
  const [tmsStatteOptions, settmsStatteOptions] = useState([]);
  const [BillingAddressValue, setBillingAddressValue] = useState({
    value: "",
    label: "Select",
  });
  const [PrimaryWarehouseValue, setPrimaryWarehouseValue] = useState({
    value: "",
    label: "Select",
  });
  const [PrimaryZoneValue, setPrimaryZoneValue] = useState({
    value: "",
    label: "Select",
  });
  const [Method, setMethod] = useState({
    value: "",
    label: "Select",
  });
  //ServiceId,ModeId,ServiceLevelId
  const [Service, setService] = useState([]);
  const [AllModes, setAllModes] = useState([]);
  const [UsersOtions, setUsersOtions] = useState([]);
  const [TmsMode, setTmsMode] = useState([]);
  // const [DefaultModeId, setDefaultModeId] = useState(0);

  //Billing
  const initCustomer = {
    id: undefined,
    Id: undefined,
    Code: "",
    Name: "",
    City: "",
    CountryId: "US",
    AddressTypeId: "",
    Address: "",
    IsActive: false,
  };
  const [showModal, setShowModal] = useState(false);
  const [
    modalConfirmationReasonSelected,
    setModalConfirmationSelected,
  ] = useState(false);
  const [ReasonCodeId, setReasonCodeId] = useState(null);
  const [ReasonCode, setReasonCode] = React.useState([]);
  const [showBillingModal, setShowModalBilling] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [billingId, setBillingId] = useState(0);
  const [billingData, setBillingAddressData] = useState(initCustomer);
  // Validation schema
  let CustomerEditSchema;
  if (customer.id === undefined) {
    CustomerEditSchema = Yup.object().shape({
      CompanyName: Yup.string()
        .min(3, "Minimum 8 symbols")
        .max(50, "Maximum 50 symbols")
        .required("Company Name is required"),
      FuelSurchargePercentage: Yup.number()
        .integer()
        .required("Fuel surcharge Percentage is required"),
      Email: Yup.string().email("Invalid email"),
      ContactEmail: Yup.string().email("Invalid email"),
      Country: Yup.string().required("Country is required"),
      LocationOptionId: Yup.string().required("Location Option is required"),
      MovableUnitOptionId: Yup.string().required(
        "Movable Unit Option is required"
      ),
      SupplierOptionId: Yup.string().required("Supplier Option is required"),
      InvoicingOptionId: Yup.string().required(
        "Fulfillment Invoicing Option is required"
      ),
      CarrierInfoOptionId: Yup.string().required(
        "Add Carrier Info to Orders Option is required"
      ),
      CreateInvoicesOptionId: Yup.string().required(
        "Create Invoice Defaults Option is required"
      ),
    });
  } else {
    CustomerEditSchema = Yup.object().shape({});
  }
  useEffect(() => {
    fetchDataAsync();
    setShowModal(false);
    window.states = undefined;
  }, []);
  const fetchCategories = async () => {
    try {
      if (CategoriesList.length === 0) {
        await fetch(window.$apiurl + "/contact/getviewmodel", {
          method: "get",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            setCategoriesList(res.ContactCategories || []);
          })
          .catch((err) => {
            return false;
          });
      }
    } catch (e) { }
  };
  const fetchDataAsync = async () => {
    try {
      const result = await axios.get("api/ManageCustomer/GetAllApi");
      setcountryList(result.data.country["Countries"]);
      var warehouses = result.data.warhousesList;
      var objWarehouses = [];
      {
        warehouses.map((item) => {
          objWarehouses.push({ value: item.Id, label: item.Name });
        });
      }
      const genericAddresses = result.data.country["GenericAddresses"];
      setUsersOtions(result.data.country["Users"] || []);
      window.$dataGenericAdd = genericAddresses;
      var objBlAddress = [];
      {
        genericAddresses.map((item) => {
          objBlAddress.push({ value: item.Id, label: item.CompanyName });
        });
      }

      setBillingAddress(objBlAddress);
      setwarhousesList(objWarehouses);
      setReasonCode(result.data.country["DeleteReasons"] || []);
      setstatesList(result.data.country["States"]);
      setlocationOptionsList(result.data.country["LocationOptions"]);
      setLocationList(result.data.country["Locations"] || []);
      setfulfillmentInvoicesOptionsList(
        result.data.country["FulfillmentInvoicesOptions"]
      );
      setmovableUnitOptionList(result.data.country["MovableUnitsOptions"]);
      setcarrierInfoOptionListList(result.data.country["CarrierInfoOptions"]);
      setsupplierOptionsList(result.data.country["SuppliersOptions"]);
      setdefaultInvoicesOptionsList(
        result.data.country["DefaultInvoicesOptions"]
      );
      setTermsList(result.data.country["NetTerms"]);
      setShipmentModeList(result.data.country["Modes"]);
      setInvoiceOptionId(result.data.country["InvoiceOptions"]);
      setServiceList(result.data.country["Service"]);
      window.InvoiceGrpOptions = result.data.country["InvoiceGrpOptions"];

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/billing/getreceiptsviewmodel`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          const obj = JSON.parse(result);
          setService(obj);
          setTmsMode(
            obj?.Services?.find((a) => a.Name === "Domestic Transportation")
              ?.Modes || []
          );
          var array = [];
          obj.Services.map((items, index) =>
            items.Modes.map((suggestion) =>
              array.push({
                value: suggestion.Id,
                label: items.Name + "-" + suggestion.Name,
              })
            )
          );
          setAllModes(array);
        })
        .catch((error) => console.log("error", error));

      const state = await axios.get(`api/companyinfo/getstates/US`);
      if (state.status === 200) {
        if (state.data.length > 0) {
          settmsStatteOptions(state?.data || []);
        }
      }
    } catch (e) { }
  };
  //Billing Addres by ID
  useEffect(() => {
    fetchViewModelAsync();
  }, [billingId]);
  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"]);
      window.AddressTypes = result.data["AddressTypes"] || [];
      window.Country = result.data["Countries"] || [];
    } catch (e) { }
  };
  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1]["Address"];
        a.id = a.Id;
        setBillingAddressData(a);
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  ////////////////////////
  useEffect(() => {
    if (customer.PrimaryWarehouseId) {
      setPrimaryWarehouseValue({
        value: customer.PrimaryWarehouseId,
        label: customer.PrimaryWarehouse,
      });
    }

    if (customer.ZoneName) {
      setPrimaryZoneValue({
        value: customer.PetroleumZoneId,
        label: customer.ZoneName,
      });
    }
    if (customer.CustomerServiceLevels) {
      const serviceLits = JSON.stringify(customer.CustomerServiceLevels);
      setServices(JSON.parse(serviceLits));
    }

    if (customer.FuelSurchargeMethodId) {
      setMethod({
        value: customer.FuelSurchargeMethodId,
        label: customer.MethodFuelSurchargeName,
      });
    }

    if (customer.BillingAddressId) {
      setBillingId(customer.BillingAddressId);
      setBillingAddressValue({
        value: customer.BillingAddressId,
        label: customer.BillingAddress,
      });
    }
    if (customer.InboundManifestHasUpc === true) {
      document.getElementById("InboundManifestHasUpc").checked = true;
    }
    if (customer.IsPodRequired === true) {
      document.getElementById("IsPodRequired").checked = true;
    }
    if (customer.POManage === true) {
      document.getElementById("POManage").checked = true;
    }
    if (customer.ReceiveAgaintAsn === true) {
      document.getElementById("ReceiveAgaintAsnId").checked = true;
    }
    if (customer.OneFileAtLeast === true) {
      document.getElementById("OneFileAtLeastId").checked = true;
    }
    if (customer.TotalPackageVolume === true) {
      document.getElementById("TotalPackageVolumeId").checked = true;
    }
    if (customer.TotalPackageWeight === true) {
      document.getElementById("TotalPackageWeightId").checked = true;
    }
    if (customer.AutoAllocate === true) {
      document.getElementById("AutoAllocateId").checked = true;
    }
    if (customer.SplitMovableUnit === true) {
      document.getElementById("SplitMovableUnitId").checked = true;
    }
    if (customer.SuggestedPutWay === true) {
      document.getElementById("SuggestedPutWayId").checked = true;
    }
    if (customer.UseBlindReceipt === true) {
      document.getElementById("UseBlindReceiptId").checked = true;
    }
    if (customer.DefaultQuantity === true) {
      document.getElementById("DefaultQuantityId").checked = true;
    }
    if (customer.FileImportTracking === true) {
      document.getElementById("FileImportTrackingId").checked = true;
    }
    if (customer.AllocateOrderManually === true) {
      document.getElementById("AllocateOrderManuallyId").checked = true;
    }
    if (customer.AutomaticallyTrackBy === true) {
      document.getElementById("AutomaticallyTrackById").checked = true;
    }
    if (customer.RequireScac === true) {
      document.getElementById("RequireScacId").checked = true;
    }
    if (customer.RequireCarrier === true) {
      document.getElementById("RequireCarrierId").checked = true;
    }
    if (customer.RequireLoadNumber === true) {
      document.getElementById("RequireLoadNumberId").checked = true;
    }
    if (customer.RequireLadingNumber === true) {
      document.getElementById("RequireLadingNumberId").checked = true;
    }
    if (customer.RequirePurchaseOrder === true) {
      document.getElementById("RequirePurchaseOrderId").checked = true;
    }
    if (customer.PackageCount === true) {
      document.getElementById("PackageCountId").checked = true;
    }
    if (customer.ConnectionUpdatesTracking === true) {
      document.getElementById("ConnectionUpdatesTrackingId").checked = true;
    }
    if (customer.ScannerApplication === true) {
      document.getElementById("ScannerApplicationId").checked = true;
    }
    if (customer.RequireTrackingNumber === true) {
      document.getElementById("RequireTrackingNumberId").checked = true;
    }
    if (customer.FullMovableUnits === true) {
      document.getElementById("FullMovableUnitsId").checked = true;
    }
    if (customer.LocationIdentifierReport === true) {
      document.getElementById("LocationIdentifierReportId").checked = true;
    }
    if (customer.EnableCustomFields === true) {
      document.getElementById("EnableCustomFieldsId").checked = true;
    }
    if (customer.AllowPurchaseOrder === true) {
      document.getElementById("AllowPurchaseOrderId").checked = true;
    }

    if (customer.EnableDashboard === true) {
      document.getElementById("EnableDashboardId").checked = true;
    }
    if (customer.InvoiceConfirmation === true) {
      document.getElementById("InvoiceConfirmationId").checked = true;
    }
    if (customer.UseTransactionBillNumber === true) {
      document.getElementById("UseTransactionBillNumberId").checked = true;
    }
    if (customer.DisplayVendorId === true) {
      document.getElementById("DisplayVendorId").checked = true;
    }
    if (customer.RequireOrderClosed === true) {
      document.getElementById("RequireOrderClosedId").checked = true;
    }
    if (customer.Assignments !== null && customer.Assignments !== undefined) {
      setTmsOptions(JSON.parse(JSON.stringify(customer?.Assignments)) || []);
    }
    if (customer.Contacts !== null && customer.Contacts !== undefined) {
      fetchCategories();
      try {
        let deepClone = JSON.parse(JSON.stringify(customer));
        deepClone.defaultForm = true;
        if (deepClone.Contacts.length > 0) {
          deepClone.Contacts.map((item) => {
            if (item.FirstName === null) item.FirstName = "";
            if (item.LastName === null) item.LastName = "";
            if (item.CategoryId === 0) item.CategoryId = 1;
            if (item.Category === null) item.Category = "Manager";
            item.Category = {
              value: item.CategoryId === 0 ? 1 : item.CategoryId,
              label: item.Category === null ? "Manager" : item.Category,
            };
          });
        }
        setusers(deepClone.Contacts);
      } catch (err) { }
    }
    // if (
    //   customer.DefaultModeId !== null &&
    //   customer.DefaultModeId !== undefined
    // ) {
    //   setDefaultModeId(customer.DefaultModeId);
    // }
    if (customer.Locations !== null && customer.Locations !== undefined) {
      setLoacationsTransitDays(customer.Locations);
    }
    if (
      customer.CustomerServiceLevels !== null &&
      customer.CustomerServiceLevels !== undefined
    ) {
      let deepClone2 = JSON.parse(JSON.stringify(customer));
      var arrayserviceMode = [];
      deepClone2.CustomerServiceLevels.map((objcts, index) =>
        Service.Services?.map((items) =>
          items?.Modes?.map((item) =>
            item?.ServiceLevels?.map((itm) => {
              var newobj = {};
              if (itm.Id === objcts.ServiceLevelId) {
                newobj.Id = index;
                newobj.ServiceId = items.Id;
                newobj.ModeId = item.Id;
                newobj.ServiceLevelId = itm.Id;
                newobj.MilesPerDay = objcts.MilesPerDay;
                newobj.FromCity = objcts.FromCity;
                newobj.FromState = objcts.FromState;
                newobj.ToCity = objcts.ToCity;
                newobj.ToState = objcts.ToState;
                newobj.TransitDays = objcts.TransitDays;
                arrayserviceMode.push(newobj);
              }
            })
          )
        )
      );

      if (arrayserviceMode.length > 0) {
        setETAGrid(arrayserviceMode);
      }
    }
    if (
      customer.WarehousesAccess !== null ||
      customer.WarehousesAccess !== undefined
    ) {
      var obj = [];
      {
        customer.WarehousesAccess.map((item, index) => {
          obj.push({ value: item.Id, label: item.Name });
        });
      }
      setGetWarehouseList(obj);
    }
    if (customer.IsActive !== undefined) {
      var a = customer.IsActive ? "true" : "false";
      setisActive(a);
    }

    if (customer?.InboundInvoiceOptionId === 222) {
      setInvoiceGrpOptionId(window.InvoiceGrpOptions);
    }
  }, [customer, Service]);
  //TMS OPTIONS
  function removeClickTmsOptions(i) {
    let eTAGrid = [...TmsOptions];
    eTAGrid.splice(i, 1);
    setTmsOptions(eTAGrid);
  }
  function handleChangeTmsOptionsUserId(i, event) {
    let values = [...TmsOptions];
    values[i].UserId = event === null ? null : event?.value;
    values[i].UserIsGroup = event === null ? null : event?.IsGroup;
    setTmsOptions(values);
  }
  function handleChangeTmsOptionsShippingCity(i, event) {
    let values = [...TmsOptions];
    values[i].ShippingCity = event?.target?.value;
    setTmsOptions(values);
  }
  function handleChangeTmsOptionsMode(i, event) {
    let values = [...TmsOptions];
    values[i].ModeId = event === null ? null : event?.value;
    setTmsOptions(values);
  }
  function handleChangeTmsOptionsShippingState(i, event) {
    let values = [...TmsOptions];
    values[i].ShippingState = event === null ? "" : event?.value;
    setTmsOptions(values);
  }
  function handleChangeTmsOptionsReceivingCity(i, event) {
    let values = [...TmsOptions];
    values[i].ReceivingCity = event?.target?.value;
    setTmsOptions(values);
  }
  function handleChangeTmsOptionsReceivingState(i, event) {
    let values = [...TmsOptions];
    values[i].ReceivingState = event === null ? "" : event?.value;
    setTmsOptions(values);
  }
  //END TMS OPTIONS
  function addClickETAGrid() {
    setETAGrid((prevState) => [
      ...prevState,
      {
        id: 0,
        ServiceId: "",
        ModeId: "",
        ServiceLevelId: "",
        MilesPerDay: 0,
        FromCity: "",
        FromState: "",
        ToCity: "",
        ToState: "",
        TransitDays: 0,
      },
    ]);
  }
  function addClickLoacationsTransitDays() {
    setLoacationsTransitDays((prevState) => [
      ...prevState,
      {
        id: 0,
        Location: "",
        TransitDays: 0,
      },
    ]);
  }
  function addClickTmsOptions() {
    setTmsOptions((prevState) => [
      ...prevState,
      {
        Id: 0,
        UserId: null,
        UserIsGroup: false,
        ModeId: null,
        ShippingCity: null,
        ShippingState: "",
        ReceivingCity: null,
        ReceivingState: "",
      },
    ]);
  }
  function removeClickContact(i) {
    let user = [...users];
    user.splice(i, 1);
    setusers(user);
  }
  function removeClickETAGrid(i) {
    let eTAGrid = [...ETAGrid];
    eTAGrid.splice(i, 1);
    setETAGrid(eTAGrid);
  }
  function removeClickLoacationsTransitDays(i) {
    let eTAGrid = [...LoacationsTransitDays];
    eTAGrid.splice(i, 1);
    setLoacationsTransitDays(eTAGrid);
  }

  function handleChangeEmil(i, event) {
    let values = [...users];
    values[i].Email = event.target.value;
    setusers(values);
  }
  function handleChangeService(i, event) {
    let values = [...ETAGrid];
    values[i].ServiceId = event?.value;
    setETAGrid(values);
  }
  function handleChangePhoneNumber(i, event) {
    let values = [...users];
    values[i].PhoneNumber = event.target.value;
    setusers(values);
  }
  function handleChangeMode(i, event) {
    let values = [...ETAGrid];
    values[i].ModeId = event?.value;
    setETAGrid(values);
  }
  function handleChangeName(i, event) {
    let values = [...users];
    values[i].FullName = event.target.value;
    setusers(values);
  }
  function handleChangeServiceLevel(i, event) {
    let values = [...ETAGrid];
    values[i].ServiceLevelId = event?.value;
    setETAGrid(values);
  }
  function handleChangeAverageMilesPerDay(i, event) {
    let values = [...ETAGrid];
    values[i].MilesPerDay = event.target.value;
    setETAGrid(values);
  }
  function handleChangeAverageFromCity(i, event) {
    let values = [...ETAGrid];
    values[i].FromCity = event.target.value;
    setETAGrid(values);
  }
  function handleChangeAverageFromState(i, event) {
    let values = [...ETAGrid];
    values[i].FromState = event?.value;
    setETAGrid(values);
  }

  function handleChangeLoacations(i, event) {
    let values = [...LoacationsTransitDays];
    values[i].Location = event?.value;
    setLoacationsTransitDays(values);
  }
  function handleChangeAverageToCity(i, event) {
    let values = [...ETAGrid];
    values[i].ToCity = event.target.value;
    setETAGrid(values);
  }
  function handleChangeAverageToState(i, event) {
    let values = [...ETAGrid];
    values[i].ToState = event?.value;
    setETAGrid(values);
  }
  function handleChangeAverageTransitDays(i, event) {
    let values = [...ETAGrid];
    values[i].TransitDays = event.target.value;
    setETAGrid(values);
  }
  function handleChangeLocationTransitDays(i, event) {
    let values = [...LoacationsTransitDays];
    values[i].TransitDays = event.target.value;
    setLoacationsTransitDays(values);
  }
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);
  //Contacts22222
  function addClick() {
    fetchCategories();
    setusers((prevState) => [
      ...prevState,
      {
        Id: 0,
        Category: { value: "", label: "Select" },
        CategoryId: "",
        FirstName: "",
        LastName: "",
        Name: "",
        WorkPhone: "",
        Cellular: "",
        HomePhone: "",
        Email: "",
        Fax: "",
        Birthday: "",
      },
    ]);
  }
  const [users, setusers] = useState([
    {
      Id: 0,
      Category: { value: "", label: "Select" },
      CategoryId: "",
      FirstName: "",
      LastName: "",
      Name: "",
      WorkPhone: "",
      Cellular: "",
      HomePhone: "",
      Email: "",
      Fax: "",
      Birthday: "",
    },
  ]);
  const [CategoriesList, setCategoriesList] = useState([]);
  function createUI() {
    return users.map((el, i) => (
      <div className="card mt-md-9 bg-light" key={i}>
        <div className="card-body">
          <div className="row">
            <div className="col" style={{ textAlign: "right" }}>
              <button
                type="button"
                className={"btn w-40 btnCloseBlack"}
                onClick={removeClickContact.bind(this, i)}
              >
                <i
                  class="fas fa-times"
                  style={{ paddingRight: "initial", color: "white" }}
                ></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {el?.Name !== "" && (
                <div className="form-group row">
                  <div className="col-lg-12">
                    <h2>Contact : {el?.Name}</h2>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <div className="col-lg-12">
                  <label>Category</label>
                  <CreatableSelect
                    name="CategoryId"
                    label="Category"
                    value={el?.Category || { value: "", label: "Select" }}
                    onChange={(newValue) => {
                      let values = [...users];
                      values[i].Category = newValue;
                      values[i].CategoryId = newValue.value;
                      setusers(values);
                    }}
                    options={CategoriesList.map((x) => {
                      return {
                        value: x.Id,
                        label: x?.Name,
                      };
                    })}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Name"
                    component={Input}
                    placeholder="First Name"
                    label="First Name"
                    required={true}
                    value={el.FirstName}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].FirstName = event.target.value;
                      if (event.target.value && values[i].LastName) {
                        values[i].Name =
                          event.target.value + " " + values[i].LastName;
                      } else {
                        values[i].Name = "";
                      }
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="LastName"
                    required={true}
                    component={Input}
                    placeholder="Last Name (Or Department)"
                    label="Last Name (Or Department)"
                    value={el.LastName}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].LastName = event.target.value;
                      if (event.target.value !== "" && values[i].FirstName) {
                        values[i].Name =
                          values[i].FirstName + " " + event.target.value;
                      } else {
                        values[i].Name = "";
                      }
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="WorkPhone"
                    component={Input}
                    placeholder="WorkPhone"
                    label="WorkPhone"
                    type="tel"
                    value={el.WorkPhone}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].WorkPhone = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="CellPhone"
                    component={Input}
                    placeholder="Cellular"
                    label="Cellular"
                    value={el.Cellular}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Cellular = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="HomePhone"
                    component={Input}
                    placeholder="HomePhone"
                    label="HomePhone"
                    type="tel"
                    value={el.HomePhone}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].HomePhone = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Email"
                    component={Input}
                    placeholder="Email"
                    required={true}
                    label="Email"
                    value={el.Email}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Email = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6 mt-4">
                  <Field
                    name="Fax"
                    component={Input}
                    placeholder="Fax"
                    label="Fax"
                    type="tel"
                    value={el.Fax}
                    onChange={(event) => {
                      let values = [...users];
                      values[i].Fax = event.target.value;
                      setusers(values);
                    }}
                  />
                </div>
                <div className="col-lg-6 mt-4">
                  <label>Birthday</label>
                  <input
                    className="form-control"
                    type="date"
                    defaultValue={moment(el.Birthday).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      let values = [...users];
                      values[i].Birthday = e.target.value;
                      setusers(values);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  function createETAGridUI() {
    return ETAGrid?.map((el, i) => (
      <div
        className="form-group row"
        style={{
          border: "1px solid rgb(221, 221, 221)",
          backgroundColor: "#f3f6f9",
        }}
        key={i}
      >
        <div className="col-lg-12 mt-2">
          <div style={{ float: "right" }}>
            <Button variant="danger" onClick={removeClickETAGrid.bind(this, i)}>
              X
            </Button>
          </div>
        </div>
        <div className="col-xl-3 col-lg-4 mt-xl-0 mt-lg-5 mt-md-5">
          <p style={{ marginBottom: "8px" }}>Service</p>
          <SelectMulti
            isClearable
            name="ServiceId"
            id="ServiceId"
            label="Service"
            placeholder="Service"
            value={Service?.Services?.filter((a) => a.Id === el.ServiceId).map(
              (suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              })
            )}
            onChange={handleChangeService.bind(this, i)}
            options={Service?.Services?.map((suggestion) => ({
              value: suggestion.Id,
              label: suggestion.Name,
            }))}
          />
        </div>

        <div className="col-xl-3 col-lg-4">
          <p style={{ marginBottom: "8px" }}>Mode</p>
          <SelectMulti
            isClearable
            name="ModeId"
            id="ModeId"
            label="Mode"
            inputId="react-select-single"
            placeholder="Mode"
            value={Service?.Services?.filter((a) => a.Id === el.ServiceId)[0]
              ?.Modes?.filter((a) => a.Id === el.ModeId)
              .map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))}
            onChange={handleChangeMode.bind(this, i)}
            options={Service?.Services?.filter(
              (a) => a.Id === el.ServiceId
            )[0]?.Modes?.map((suggestion) => ({
              value: suggestion.Id,
              label: suggestion.Name,
            }))}
          />
        </div>
        <div className="col-xl-3 col-lg-4">
          <p style={{ marginBottom: "8px" }}>Service Level</p>
          <SelectMulti
            isClearable
            name="serviceLevelId"
            id="serviceLevelId"
            label="Servic Level"
            inputId="react-select-single"
            placeholder="Servic Level"
            value={Service?.Services?.filter((a) => a.Id === el.ServiceId)[0]
              ?.Modes?.filter((a) => a.Id === el.ModeId)[0]
              ?.ServiceLevels?.filter((a) => a.Id === el.ServiceLevelId)
              .map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))}
            onChange={handleChangeServiceLevel.bind(this, i)}
            options={Service?.Services?.filter((a) => a.Id === el.ServiceId)[0]
              ?.Modes?.filter((a) => a.Id === el.ModeId)[0]
              ?.ServiceLevels?.map((suggestion) => ({
                value: suggestion.Id,
                label: suggestion.Name,
              }))}
          />
        </div>
        <div className="col-xl-3 col-lg-4">
          <Field
            name="AverageMilesPerDay"
            component={Input}
            type="number"
            placeholder="Average Miles Per Day"
            label="Average Miles Per Day"
            value={el.MilesPerDay || 0}
            onChange={handleChangeAverageMilesPerDay.bind(this, i)}
          />
        </div>
        <div className="col-xl-3 col-lg-4  mt-3">
          <label>From State</label>
          <SelectMulti
            name="FromState"
            label="From State"
            options={statesList.map((item, index) => ({
              value: item.Name,
              label: item.Name,
            }))}
            value={statesList
              ?.filter((a) => a.Name === el.FromState)
              .map((suggestion) => ({
                value: suggestion.Name,
                label: suggestion.Name,
              }))}
            onChange={handleChangeAverageFromState.bind(this, i)}
            id="FromState"
          />
          {/* <Field
            name="FromState"
            component={Input}
            placeholder="From State"
            label="From State"
            value={el.FromState}
            onChange={handleChangeAverageFromState.bind(this, i)}
          /> */}
        </div>
        <div className="col-xl-3 col-lg-4 mt-3">
          <Field
            name="FromCity"
            component={Input}
            placeholder="From City"
            label="From City"
            value={el.FromCity}
            onChange={handleChangeAverageFromCity.bind(this, i)}
          />
        </div>
        <div className="col-xl-3 col-lg-4  mt-3">
          <Field
            name="ToCity"
            component={Input}
            placeholder="To City"
            label="To City"
            value={el.ToCity}
            onChange={handleChangeAverageToCity.bind(this, i)}
          />
        </div>
        <div className="col-xl-3 col-lg-4 mt-3">
          <label>To State</label>
          <SelectMulti
            name="ToState"
            label="To State"
            options={statesList.map((item, index) => ({
              value: item.Name,
              label: item.Name,
            }))}
            value={statesList
              ?.filter((a) => a.Name === el.ToState)
              .map((suggestion) => ({
                value: suggestion.Name,
                label: suggestion.Name,
              }))}
            onChange={handleChangeAverageToState.bind(this, i)}
            id="ToState"
          />
          {/* <Field
            name="ToState"
            component={Input}
            placeholder="To State"
            label="To State"
            value={el.ToState}
            onChange={handleChangeAverageToState.bind(this, i)}
          /> */}
        </div>
        <div className="col-xl-3 col-lg-4 mt-3 mb-2">
          <Field
            name="TransitDays"
            component={Input}
            placeholder="Transit Days"
            label="Transit Days"
            value={el.TransitDays || 0}
            onChange={handleChangeAverageTransitDays.bind(this, i)}
          />
        </div>
      </div>
    ));
  }

  //createLoacationsTransitDaysUI
  function createLoacationsTransitDaysUI() {
    return LoacationsTransitDays?.map((el, i) => (
      <div
        className="form-group row"
        style={{
          border: "1px solid rgb(221, 221, 221)",
          backgroundColor: "#f3f6f9",
        }}
        key={i}
      >
        <div className="col-lg-12 mt-2">
          <div style={{ float: "right" }}>
            <Button
              variant="danger"
              onClick={removeClickLoacationsTransitDays.bind(this, i)}
            >
              X
            </Button>
          </div>
        </div>
        <div className="col-xl-6 col-lg-4  mt-3">
          <label>Location</label>
          <SelectMulti
            name="Location"
            label="Location"
            options={LocationList.map((item, index) => ({
              value: item,
              label: item,
            }))}
            value={LocationList?.filter((a) => a === el.Location).map(
              (suggestion) => ({
                value: suggestion,
                label: suggestion,
              })
            )}
            onChange={handleChangeLoacations.bind(this, i)}
            id="Location"
          />
        </div>
        <div className="col-xl-6 col-lg-4 mt-3 mb-2">
          <Field
            name="TransitDays"
            component={Input}
            placeholder="Transit Days"
            label="Transit Days"
            value={el.TransitDays || 0}
            onChange={handleChangeLocationTransitDays.bind(this, i)}
          />
        </div>
      </div>
    ));
  }
  function createTmsOptionsUI() {
    return TmsOptions?.map((itm, idx) => (
      <>
        <div
          className="form-group row mx-3 px-3 py-2 pb-4 pt-4"
          style={{
            borderRadius: "5px",
            backgroundColor: "white",
            boxShadow: "1px 1px 7px -4px rgb(0 0 0 / 81%)",
          }}
        >
          <div className="col-lg-12">
            <div
              style={{
                float: "right",
                position: "absolute",
                right: "-20px",
                top: "-25px",
              }}
            >
              <a
                href={() => false}
                className="btn btn-icon btn-light btn-hover-danger btn-sm ml-2"
                onClick={removeClickTmsOptions.bind(this, idx)}
                style={{
                  borderRadius: "50%",
                  height: "20px",
                  width: "20px",
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    title="Remove"
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                  />
                </span>
              </a>
            </div>
          </div>
          <div className="col-xl-2 col-lg-4">
            <label>Mode</label>
            <SelectMulti
              name="ModeId"
              id="ModeId"
              label="Mode"
              options={TmsMode.map((item, index) => ({
                value: item.Id,
                label: item.Name,
              }))}
              value={TmsMode?.filter((a) => a.Id === itm.ModeId).map(
                (suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                })
              )}
              onChange={handleChangeTmsOptionsMode.bind(this, idx)}
            />
          </div>
          <div className="col-xl-2 col-lg-4">
            <label>User</label>
            <SelectMulti
              name="UserId"
              id="UserId"
              label="User"
              options={UsersOtions.map((item, index) => ({
                value: item.Id,
                label: item.Name,
                IsGroup: item.IsGroup,
              }))}
              value={UsersOtions?.filter((a) => a.Id === itm.UserId).map(
                (suggestion) => ({
                  value: suggestion.Id,
                  label: suggestion.Name,
                  IsGroup: suggestion.IsGroup,
                })
              )}
              onChange={handleChangeTmsOptionsUserId.bind(this, idx)}
            />
          </div>
          <div className="col-xl-2 col-lg-4">
            <label>Shipping State</label>
            <SelectMulti
              name="ShippingState"
              id="ShippingState"
              label="State"
              options={tmsStatteOptions.map((item, index) => ({
                value: item.Name,
                label: item.Name,
              }))}
              value={tmsStatteOptions
                ?.filter((a) => String(a.Name) === String(itm.ShippingState))
                .map((suggestion) => ({
                  value: suggestion.Name,
                  label: suggestion.Name,
                }))}
              onChange={handleChangeTmsOptionsShippingState.bind(this, idx)}
            />
          </div>
          <div className="col-xl-2 col-lg-4">
            <Field
              name="ShippingCity"
              component={Input}
              placeholder="City"
              label="Shipping City"
              value={itm.ShippingCity}
              onChange={handleChangeTmsOptionsShippingCity.bind(this, idx)}
            />
          </div>
          <div className="col-xl-2 col-lg-4">
            <label>Receiving State</label>
            <SelectMulti
              name="ReceivingState"
              id="ReceivingState"
              label="State"
              options={tmsStatteOptions.map((item, index) => ({
                value: item.Name,
                label: item.Name,
              }))}
              value={tmsStatteOptions
                ?.filter((a) => String(a.Name) === String(itm.ReceivingState))
                .map((suggestion) => ({
                  value: suggestion.Name,
                  label: suggestion.Name,
                }))}
              onChange={handleChangeTmsOptionsReceivingState.bind(this, idx)}
            />
          </div>
          <div className="col-xl-2 col-lg-4">
            <Field
              name="ReceivingCity"
              component={Input}
              placeholder="City"
              label="Receiving City"
              value={itm.ReceivingCity}
              onChange={handleChangeTmsOptionsReceivingCity.bind(this, idx)}
            />
          </div>
        </div>
      </>
    ));
  }
  const [value, setValue] = React.useState(0);
  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: "2rem",
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
    col4Check: {
      placeSelf: "center",
      paddingTop: "1.5rem",
    },
    borderBtmCst: {
      borderBottom: "1px solid #a2a5a7 !important",
    },
    scrollHeighRates: {
      overflow: "scroll",
      height: "642px",
      width: "101%",
    },
  }));
  // Validation schema
  const CustomerEditSchema2 = Yup.object().shape({
    Name: Yup.string()
      .min(3, "Minimum 1 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Organisation Name is required"),
    Address: Yup.string()
      .min(10, "Minimum 10 symbols")
      .required("Address is required"),
    City: Yup.string().required("City is required"),
    AddressTypeId: Yup.string().required("Address Type Option is required"),
    CountryId: Yup.string().required("Country Option is required"),
  });
  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  const catchError = [];

  function CustomerBillingAddressModal({ actionsLoading, customer }) {
    const [Country, setCountry] = useState(catchError);
    const [CountryObj, setCountryObj] = useState({
      value: "",
      label: "Select",
    });
    const [AddressTypes, setAddressTypes] = useState(catchError);
    const [Status, setStatus] = useState(false);
    const [AddressTypeValue, setAddressTypeValue] = useState();
    const [states, setStates] = useState([]);
    const [showStateProgress, setShowStateProgress] = useState(false);
    const [hideModal, setHideModal] = useState(false);
    const [countryIdFetch, setCountryValue] = useState({
      value: "",
      label: "",
    });
    const [title, setTitle] = useState("");
    useEffect(() => {
      setCountry(window.Country || catchError);
      setAddressTypes(window.AddressTypes || catchError);
    }, []);
    useEffect(() => {
      if (customer.Id === undefined) {
        $("#newAddress").hide();
        $("#organisationName").removeClass("col-lg-6");
        $("#organisationName").addClass("col-lg-12");
      }
      try {
        let _title = customer.Id ? "" : "New Address";
        if (customer.Id) {
          _title = `Edit Address '${customer.Id}'`;
        }
        setTitle(_title);
      } catch (err) {
        console.log(err);
      }
    }, customer);
    useEffect(() => {
      setStatus(customer.IsActive || false);
      var countryId = customer.CountryId || false;
      if (countryId) {
        var c = Country.find((x) => x.Id == countryId) || CountryObj;
        setCountryObj(
          {
            value: c.Id,
            label: c.Name,
          } || CountryObj
        );
      }
      if (
        customer.OrganizationId !== null ||
        customer.OrganizationId !== undefined
      ) {
        setAddressTypeValue({
          value: customer.OrganizationId,
          label: customer.OrganizationName,
        });
      }
      if (customer.Id != undefined) {
        $("#newAddress").show();
        $("#organisationName").removeClass("col-lg-12");
        $("#organisationName").addClass("col-lg-6");
        $("#newAddressInpt").attr("readonly", true);
        $("#newAddressInpt").css("background-color", "#F5F5F5");
        $("#newAddressInpt").attr("disabled", true);
      }
      if (customer.Charges !== null && customer.Charges !== undefined) {
        let deepClone = JSON.parse(JSON.stringify(customer));
        deepClone.defaultForm = true;
        setusers(deepClone.Charges);
      }
      // if (customer.CountryId !== null || customer.CountryId !== undefined) {
      //   handleCountryChange(customer.CountryId);
      //   var c = Country.find((x) => x.Id == customer.CountryId);
      //   if (c === undefined) {
      //     setCountryValue({ value: "US", label: "United States" });
      //   } else {
      //     setCountryValue({ value: c?.Id, label: c?.Name });
      //   }
      // }
    }, [customer]);
    //Multi Select Vendor
    function removeClick(i) {
      let user = [...users];
      user.splice(i, 1);
      setusers(user);
    }
    function addClick() {
      setusers((prevState) => [
        ...prevState,
        {
          id: 0,
          Name: "",
          Code: "",
          Rate: 0,
          DateStart: "",
          DateEnd: "",
          Description: "",
          IsQuickAdd: false,
        },
      ]);
    }
    const [users, setusers] = useState([
      {
        Id: 0,
        Name: "",
        Rate: 0,
        Code: "",
        DateStart: "",
        DateEnd: "",
        Description: "",
        IsQuickAdd: false,
      },
    ]);
    const classes = useStyles();
    function handleChangeName(i, event) {
      let values = [...users];
      values[i].Name = event.target.value;
      setusers(values);
    }
    function handleChangeCode(i, event) {
      let values = [...users];
      values[i].Code = event.target.value;
      setusers(values);
    }
    function handleChangeRate(i, event) {
      let values = [...users];
      values[i].Rate = event.target.value;
      setusers(values);
    }
    function handleChangeDescription(i, event) {
      let values = [...users];
      values[i].Description = event.target.value;
      setusers(values);
    }
    function handleChangeDateStart(i, event) {
      let values = [...users];
      values[i].DateStart = event.target.value;
      setusers(values);
    }
    function handleChangeDateEnd(i, event) {
      let values = [...users];
      values[i].DateEnd = event.target.value;
      setusers(values);
    }
    function handleChangeIsQuick(i, event) {
      let values = [...users];
      values[i].IsQuickAdd = event.target.checked;
      setusers(values);
    }
    function createUI() {
      return users.map((el, i) => (
        <div className={"form-group row " + classes.borderBtmCst}>
          <div className="col-lg-4 mb-md-11">
            <Field
              name="NameRate"
              component={Input}
              placeholder="Name"
              label="Name"
              value={el.Name || ""}
              onChange={handleChangeName.bind(this, i)}
            />
          </div>
          <div className="col-lg-4 mb-md-11">
            <Field
              name="CodeRate"
              component={Input}
              placeholder="Code"
              label="Code"
              value={el.Code || ""}
              onChange={handleChangeCode.bind(this, i)}
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="Rate"
              component={Input}
              placeholder="Rate"
              type="number"
              label="Rate"
              value={el.Rate || ""}
              onChange={handleChangeRate.bind(this, i)}
            />
          </div>
          <div className="col-lg-4">
            <Field
              name="Description"
              component={Input}
              placeholder="Description"
              label="Description"
              value={el.Description || ""}
              onChange={handleChangeDescription.bind(this, i)}
            />
          </div>
          <div className="col-lg-4 pt-5">
            <TextField
              id="StartDate"
              name="DateStart"
              label="Date Start"
              type="datetime-local"
              defaultValue=""
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={el.DateStart || ""}
              onChange={handleChangeDateStart.bind(this, i)}
            />
          </div>
          <div className="col-lg-4 pt-5">
            <TextField
              id="EndDate"
              name="DateEnd"
              label="Date End"
              type="datetime-local"
              defaultValue=""
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              value={el.DateEnd || ""}
              onChange={handleChangeDateEnd.bind(this, i)}
            />
          </div>
          <div className="col-lg-3 pt-md-4">
            <CheckboxLargeWB
              checked={el.IsQuickAdd === true ? true : false}
              className="font-size-md"
              text="IsQuickAdd"
              name="IsQuickAdd"
              id="IsQuickAdd"
              value={el.IsQuickAdd || ""}
              onChange={handleChangeIsQuick.bind(this, i)}
            />
          </div>
          <div className="col-lg-2 mb-5">
            <Button
              style={{ marginTop: "13px" }}
              variant="danger"
              onClick={removeClick.bind(this, i)}
            >
              Remove
            </Button>
          </div>
        </div>
      ));
    }
    const handleCountryChange = async (event) => {
      if (window.states === `api/companyinfo/getstates/${event}`) return;
      setShowStateProgress(true);
      setStates([]);
      try {
        if (event !== "" && event !== null) {
          const result = await axios.get(`api/companyinfo/getstates/${event}`);
          if (result.status == 200) {
            if (result.data.length > 0) {
              window.states = `api/companyinfo/getstates/${event}`;
              setStates(result?.data || []);
            } else
              Notification("warning", "This Country doesn't have any state.");
          }
        }
      } catch (err) { }
      setShowStateProgress(false);
    };

    function StatusMessage(res) {
      var status = res.status;
      var statusText = res.statusText;
      if (status === 200) {
        Notification("success", "success");
        return true;
      } else if (status === 400) {
        Notification("error", statusText);
        return false;
      }
    }
    async function PutModel(someData, id) {
      return fetch(`${window.$apiurl}/address/${id}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
        body: JSON.stringify(someData),
      })
        .then(async (response) => {
          return response;
        })
        .then((res) => {
          fetchDataAsync();
          setBillingAddressValue({ value: someData.Id, label: someData.Name });
          return StatusMessage(res);
        })
        .catch((err) => {
          return StatusMessage(err);
        });
    }
    async function PostModel(someData) {
      return fetch(`${window.$apiurl}/address`, {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
        body: JSON.stringify(someData),
      })
        .then(async (response) => {
          return response;
        })
        .then((res) => {
          fetchDataAsync();
          return StatusMessage(res);
        })
        .catch((err) => {
          return StatusMessage(err);
        });
    }
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={customer}
          validationSchema={CustomerEditSchema2}
          onSubmit={(values) => {
            values.Charges = users;
            if (values.Id) {
              PutModel(values, values.id);
            } else {
              PostModel(values);
            }
          }}
        >
          {({ errors, touched, handleSubmit, setFieldValue }) => (
            <>
              <Modal
                id="modalAddressId"
                size="lg"
                show={showBillingModal}
                aria-labelledby="example-modal-sizes-title-lg"
              >
                <Modal.Header>
                  <Modal.Title id="example-modal-sizes-title-lg">
                    {title}
                  </Modal.Title>
                  <IconButton
                    aria-label="Close"
                    onClick={(e) => {
                      setShowModalBilling(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Modal.Header>
                <Modal.Body>
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <Form className="form form-label-right">
                    <div className="form-group row">
                      <div className="col-lg-6" id="newAddress">
                        <Field
                          name="Code"
                          id="newAddressInpt"
                          component={Input}
                          placeholder="Address Code"
                          label="Address Code"
                          required={true}
                        />
                      </div>
                      <div className="col-lg-6" id="organisationName">
                        <Field
                          name="Name"
                          component={Input}
                          placeholder="Organisation Name"
                          label="Organisation Name"
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-lg-12 px-0">
                        <label>Address</label>
                        <Field
                          name="Address"
                          as="textarea"
                          className="form-control"
                        />
                        {errors.Address && touched.Address ? (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {errors.Address}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <Field
                          name="City"
                          component={Input}
                          placeholder="City"
                          label="City"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Select name="State" label="State" id="LocationState">
                          <option key="" value="">
                            Choose a state
                          </option>
                          {states.map((item, index) => (
                            <option key={index} value={item.Name}>
                              {item.Name}
                            </option>
                          ))}
                        </Select>
                        {showStateProgress && (
                          <LinearProgress color="secondary" />
                        )}
                      </div>
                      <div className="col-lg-4">
                        <Field
                          name="PostalCode"
                          component={Input}
                          placeholder="Postal Code"
                          label="Postal Code"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-4">
                        <label>
                          Country <RequiredHtml />
                        </label>
                        <CreatableSelect
                          name="CountryId"
                          value={
                            countryIdFetch.value === ""
                              ? { value: "US", label: "United States" }
                              : countryIdFetch
                          }
                          options={Country.map((x) => {
                            return { value: x?.Id, label: x?.Name };
                          })}
                          onChange={(newValue) => {
                            setCountryValue(newValue);
                            setFieldValue("CountryId", newValue?.value);
                            handleCountryChange(newValue?.value);
                          }}
                        />
                        {errors.CountryId && touched.CountryId ? (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {errors.CountryId}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-4">
                        <Field
                          name="Phone"
                          component={Input}
                          placeholder="Phone"
                          label="Phone"
                        />
                      </div>
                      <div className="col-lg-4">
                        <Field
                          name="Fax"
                          component={Input}
                          placeholder="Fax"
                          label="Fax"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-lg-3">
                        <Field
                          name="WebPage"
                          component={Input}
                          placeholder="Tracing Web Page"
                          label="Tracing Web Page"
                        />
                      </div>
                      <div className="col-lg-3">
                        <Field
                          name="Contact"
                          component={Input}
                          placeholder="Contact"
                          label="Contact"
                        />
                      </div>
                      <div className="col-lg-3">
                        <label>
                          Type <RequiredHtml />
                        </label>
                        <DownshiftMultipleSelectCustom
                          FieldName="AddressTypeId"
                          suggestions={AddressTypes}
                          DefalutValue={AddressTypeValue}
                          setFieldValue={setFieldValue}
                        />
                        {errors.AddressTypeId && touched.AddressTypeId ? (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {errors.AddressTypeId}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-lg-3 align-self-center px-0">
                        <CheckboxLarge
                          checked={Status}
                          label=" "
                          text="Active"
                          name="IsActive"
                          id="IsActiveld"
                          onChange={(event) => {
                            setFieldValue("IsActive", event.target.checked);
                            setStatus(event.target.checked);
                          }}
                        />
                      </div>
                    </div>
                    {/* Multi Select Vendor */}
                    <div
                      className={"form-group " + classes.scrollHeighRates}
                      style={{ display: "none" }}
                      id="sectionVendorRates"
                    >
                      <div>
                        <div className="card">
                          <h6 className="p-5 text-primary">Vendor Rates</h6>
                          <div className="card-body">
                            <div className="form-group row">
                              <div id="another-participant1">
                                {createUI()}
                                <button
                                  type="button"
                                  onClick={addClick.bind(this)}
                                  className="btn btn-primary btn-elevate"
                                >
                                  Add Rate
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="col-lg-12 px-0">
                        <label>Note</label>
                        <Field
                          name="Note"
                          as="textarea"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    onClick={() => setShowModalBilling(false)}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                  <button
                    type="submit"
                    onClick={() => {
                      handleSubmit();
                      setShowModalBilling(false);
                    }}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Formik>
      </>
    );
  }

  const [states, setStates] = useState([]);
  const [showStateProgress, setShowStateProgress] = useState(false);
  const [Country, setCountry] = useState(catchError);

  const handleCountryChange2 = async (event) => {
    setShowStateProgress(true);
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status == 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
    setShowStateProgress(false);
  };

  useEffect(() => {
    if (customer.Country) {
      handleCountryChange2(customer.Country);
    }
  }, [customer]);

  return (
    <>
      {/*Selected Remove PO */}
      <Accordion defaultActiveKey="1">
        <Formik
          enableReinitialize={true}
          initialValues={customer}
          validationSchema={CustomerEditSchema}
          onSubmit={(values) => {
            let deepClone = JSON.parse(JSON.stringify(values));
            deepClone.defaultForm = true;
            deepClone.Contacts = users;
            deepClone.Assignments = TmsOptions;
            //mapping Data Contacts
            let isEmpty;
            deepClone.Contacts.length > 0 &&
              deepClone.Contacts.map((item, index) => {
                if (
                  item.FirstName === "" ||
                  item.LastName === "" ||
                  item.Email === ""
                ) {
                  if (item.FirstName === "") {
                    Notification(
                      "error",
                      `Contact ${index + 1} : First Name is Required`
                    );
                  }
                  if (item.LastName === "") {
                    Notification(
                      "error",
                      `Contact ${index + 1} : Last Name is Required`
                    );
                  }
                  if (item.Email === "") {
                    Notification(
                      "error",
                      `Contact ${index + 1} : Email is Required`
                    );
                  }
                  isEmpty = true;
                }
              });
            if (isEmpty) {
              return false;
            } else {
              users.map((item) => {
                delete item.Category;
              });
            }

            if (hasDuplicateObjects(Services)) {
              Notification("error", "Duplicate Service At RDD transit days")
              return;
            }
            deepClone.CustomerServiceLevels = Services;
            deepClone.Locations = LoacationsTransitDays;
            deepClone.WarehousesAccess = postWarehouseList;
            saveCustomer(deepClone);
          }}
        >
          {({ errors, touched, handleSubmit, setFieldValue, values }) => (
            <>
              <Modal.Body>
                <Modal
                  size="md"
                  id="ConfirmCancelModal"
                  show={modalConfirmationReasonSelected}
                  aria-labelledby="example-modal-sizes-title-lg"
                  className="cancel-Exceptions"
                >
                  <Modal.Header>
                    <Modal.Title>Reason for Hold Customer</Modal.Title>
                    <IconButton
                      aria-label="Close"
                      onClick={(e) => {
                        setModalConfirmationSelected(false);
                        setFieldValue("IsOnHold", false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Modal.Header>
                  <Modal.Body>
                    {ReasonCode.length > 0 ? (
                      <div className="col-xxl-12 mb-4">
                        <Select
                          label="Reason Code"
                          name="DeleteReasonId"
                          onChange={(e) => {
                            if (e !== null) {
                              setReasonCodeId(e.target.value);
                            } else {
                              setReasonCodeId(null);
                            }
                          }}
                        >
                          <option>Select</option>
                          {ReasonCode?.map((item, index) => (
                            <option key={index} value={item?.Id}>
                              {item?.Name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-xxl-12">
                      <label>Reason Note</label>
                      <textarea
                        className="form-control mb-5"
                        rows="4"
                        id="CancelNotes"
                      ></textarea>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      className="btn btn-danger btn-elevate"
                      onClick={(e) => {
                        setModalConfirmationSelected(false);
                        setFieldValue("IsOnHold", false);
                      }}
                    >
                      No
                    </Button>

                    <Button
                      className="btn btn-light btn-elevate ml-5"
                      onClick={() => {
                        if (
                          document.getElementById("CancelNotes").value === "" ||
                          document.getElementById("CancelNotes").value ===
                          null ||
                          document.getElementById("CancelNotes").value ===
                          undefined
                        ) {
                          Notification("error", `Please Fill Note`);
                          return;
                        }
                        if (ReasonCodeId === null) {
                          Notification("error", `Please Select Reason Code`);
                          return;
                        }
                        var myHeaders = new Headers();
                        myHeaders.append(
                          "Authorization",
                          `Bearer ${db.read().getState().Token}`
                        );
                        myHeaders.append("Content-Type", "application/json");

                        var raw = JSON.stringify({
                          Reason: document.getElementById("CancelNotes").value,
                          DeleteReasonId: ReasonCodeId,
                        });
                        var requestOptions = {
                          method: "PUT",
                          headers: myHeaders,
                          body: raw,
                          redirect: "follow",
                        };
                        fetch(
                          `${window.$apiurl}/Customers/Holding/${customer?.Id}`,
                          requestOptions
                        )
                          .then((response) => response.text())
                          .then((result) => {
                            setModalConfirmationSelected(false);
                            Notification(
                              "success",
                              `Update Customer successfully`
                            );
                            document.getElementById("ReLoadTableId").click();
                          })
                          .catch((error) => console.log("error", error));
                      }}
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Modal>

                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="1"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">CUSTOMER BASICS</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1" className="mb-4">
                    <div>
                      <div className="card">
                        <h6 className="p-5 text-primary">
                          CUSTOMER INFORMATION
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-10">
                              <RadioButton
                                name="IsActive"
                                label="Status"
                                row
                                value={isActive}
                                onChange={(e) => {
                                  setisActive(e.target.value);
                                  setFieldValue("IsActive", e.target.value);
                                }}
                              >
                                <FormControlLabel
                                  value="true"
                                  control={<Radio color="primary" />}
                                  label="Activate"
                                />
                                <FormControlLabel
                                  value="false"
                                  control={<Radio color="primary" />}
                                  label="Deactivate"
                                />
                              </RadioButton>
                            </div>
                            {customer?.Id !== undefined ? (
                              <div className="col-lg-2">
                                <div className="d-flex align-items-center mt-8">
                                  <span className="bg-success align-self-stretch"></span>
                                  <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input
                                      type="checkbox"
                                      name="IsOnHold"
                                      checked={values.IsOnHold}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "IsOnHold",
                                          e.target.checked
                                        );
                                        if (e.target.checked) {
                                          setModalConfirmationSelected(true);
                                        }
                                      }}
                                    />
                                    <span></span>
                                  </label>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1 pt-1">
                                      Is On Hold
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className="col-lg-4">
                              <Field
                                name="CompanyName"
                                component={Input}
                                placeholder="Company Name"
                                label="Company Name"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="ExternalId"
                                component={Input}
                                placeholder="Alias/External Id"
                                label="Alias/External Id"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="PhoneNumber"
                                component={Input}
                                placeholder="Phone Number"
                                label="Phone Number"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            {/* Address1 */}

                            <div className="col-lg-4">
                              <Field
                                name="Fax"
                                component={Input}
                                placeholder="Fax Number"
                                label="Fax Number"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="Website"
                                component={Input}
                                placeholder="Website"
                                label="Website"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="Email"
                                component={Input}
                                placeholder="Email"
                                label="Email"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            {/* Address1 */}

                            <div className="col-lg-4">
                              <Field
                                name="Sales"
                                component={Input}
                                placeholder="Sales"
                                label="Sales"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="CreditLimit"
                                component={Input}
                                type="number"
                                placeholder="Credit Limit"
                                label="Credit Limit"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="Balance"
                                component={Input}
                                type="number"
                                placeholder="Balance"
                                label="Balance"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            {/* Address1 */}
                            <div className="col-lg-4">
                              <label>
                                Country <RequiredHtml />
                              </label>
                              <CreatableSelect
                                name="CountryId"
                                value={
                                  Country.map((x) => {
                                    return { value: x?.Id, label: x?.Name };
                                  }
                                  )?.find(
                                    (obj) => obj.value === values.Country
                                  ) || { value: "", label: "Select" }

                                }
                                options={Country.map((x) => {
                                  return { value: x?.Id, label: x?.Name };
                                })}
                                onChange={(newValue) => {
                                  setFieldValue("Country", newValue?.value);
                                  handleCountryChange2(newValue?.value);
                                }}
                              />
                              {
                                <ErrorMessage
                                  name="Country"
                                  component="div"
                                  className="text-danger"
                                />
                              }
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="Address1"
                                component={Input}
                                placeholder="Address1"
                                label="Address1"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="Address2"
                                component={Input}
                                placeholder="Address2"
                                label="Address2"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            {/* Address1 */}
                            <div className="col-lg-4">
                              <label>
                                State
                              </label>
                              <CreatableSelect
                                name="State"
                                options={states}
                                value={
                                  states.find(
                                    (obj) => obj.label === values.State
                                  ) || { value: "", label: "Select" }

                                }
                                onChange={(newValue) => {
                                  setFieldValue("State", newValue?.label);
                                }}
                              />
                              {showStateProgress && <LinearProgress color="secondary" />}
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="City"
                                component={Input}
                                placeholder="City/Township"
                                label="City/Township"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="PostalCode"
                                placeholder="Zip/Postal"
                                component={Input}
                                label="Zip/Postal"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">PRIMARY CONTACT</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className="col-lg-4">
                              <Field
                                name="ContactName"
                                component={Input}
                                placeholder="Name"
                                label="Name"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="ContactPhone"
                                component={Input}
                                placeholder="Phone Number"
                                label="Phone Number"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="ContactEmail"
                                component={Input}
                                placeholder="Email"
                                label="Email"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">
                          PRIMARY WAREHOUSE / BILLING ADDRESS
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className={"col-lg-5"}>
                              <label>Primary Warehouse</label>
                              <CreatableSelect
                                isClearable
                                value={PrimaryWarehouseValue}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    setPrimaryWarehouseValue(newValue);
                                  } else {
                                    setPrimaryWarehouseValue({
                                      value: "",
                                      label: "Select",
                                    });
                                  }
                                  setFieldValue(
                                    "PrimaryWarehouseId",
                                    newValue?.value
                                  );
                                }}
                                options={warhousesList}
                                name="PrimaryWarehouseId"
                                className="w-100 mr-2"
                              />
                            </div>
                            <div className="col-lg-6">
                              <label>Billing Address</label>
                              <CreatableSelect
                                isClearable
                                value={BillingAddressValue}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    setBillingId(newValue?.value);
                                    setBillingAddressValue(newValue);
                                  } else {
                                    setBillingAddressValue({
                                      value: "",
                                      label: "Select",
                                    });
                                    setBillingId(0);
                                    setBillingAddressData(initCustomer);
                                  }
                                  setFieldValue(
                                    "BillingAddressId",
                                    newValue?.value
                                  );
                                }}
                                options={BillingAddress}
                                name="BillingAddressId"
                                className="w-100 mr-2"
                              />
                            </div>{" "}
                            {billingId !== 0 && (
                              <div className="col-lg-1 pt-md-9">
                                <a
                                  title="Edit customer"
                                  className="btn btn-icon btn-light btn-hover-primary btn-sm mr-2"
                                  onClick={() => {
                                    setShowModalBilling(true);
                                    GetModelId(billingId);
                                  }}
                                >
                                  <span className="svg-icon svg-icon-md svg-icon-primary">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Write.svg"
                                      )}
                                    />
                                  </span>
                                </a>
                              </div>
                            )}
                            {billingId === 0 && (
                              <div className="col-lg-1">
                                <button
                                  type="button"
                                  onClick={() => {
                                    setShowModalBilling(true);
                                  }}
                                  className="btn btn-light-success w-40 mt-md-8"
                                >
                                  <i
                                    className={`fas fa-plus fa-sm`}
                                    style={{ paddingRight: "initial" }}
                                  ></i>{" "}
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">WAREHOUSE ACCESS</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-12 mt-2">
                              {
                                <SelectMulti
                                  isMulti
                                  // value={getCustomerFalse}
                                  name="Warehouses"
                                  value={getWarehouseList}
                                  options={warhousesList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={(e) => {
                                    var post = [];
                                    if (e) {
                                      {
                                        e.map((item, index) => {
                                          post.push({
                                            Id: item.value,
                                            Name: item.label,
                                          });
                                        });
                                      }
                                    }
                                    setGetWarehouseList(e);
                                    setPostWarehouseList(post);
                                  }}
                                />
                              }
                              {warhousesList.length === 0 && <LinearProgress />}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">FUEL SURCHARGE</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-5">
                              <label>Method</label>
                              <CreatableSelect
                                isClearable
                                value={Method}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    setMethod(newValue);
                                  } else {
                                    setMethod({
                                      value: "",
                                      label: "Select",
                                    });
                                  }
                                  setFieldValue(
                                    "FuelSurchargeMethodId",
                                    newValue?.value
                                  );
                                }}
                                options={[
                                  { label: "Flat", value: 3 },
                                  { label: "Per miles", value: 1 },
                                  { label: "Percentage of total", value: 2 },
                                ]}
                                name="FuelSurchargeMethodId"
                                className="w-100 mr-2"
                              />
                            </div>

                            <div className="col-lg-6">
                              <label>Petroleum Zone</label>

                              <CreatableSelect
                                isClearable
                                value={PrimaryZoneValue}
                                onChange={(newValue) => {
                                  if (newValue !== null) {
                                    setPrimaryZoneValue(newValue);
                                  } else {
                                    setPrimaryZoneValue({
                                      value: "",
                                      label: "Select",
                                    });
                                  }
                                  setFieldValue(
                                    "PetroleumZoneId",
                                    newValue?.value
                                  );
                                }}
                                options={[
                                  { label: "West Coast", value: 1 },
                                  { label: "Gulf Coast", value: 3 },
                                  { label: "Rocky Mountain", value: 2 },
                                  { label: "Midwest", value: 4 },
                                  { label: "East Coast", value: 5 },
                                ]}
                                name="PetroleumZoneId"
                                className="w-100 mr-2"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="2"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">RECEIPT OPTION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2" className="mb-4">
                    <div>
                      <div className="card">
                        <h6 className="p-5 text-primary">OPTIONS</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className="col-lg-4">
                              <Select
                                name="LocationOptionId"
                                label="Location Options*"
                              >
                                <option value="">Select</option>
                                {locationOptionsList?.map((item, index) => (
                                  <option key={index} value={item.Id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div className="col-lg-4">
                              <Select
                                name="MovableUnitOptionId"
                                label="Movable Unit Options"
                              >
                                <option value="0">Select</option>
                                {movableUnitOptionList?.map((item, index) => (
                                  <option key={index} value={item.Id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </Select>
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="FuelSurchargePercentage"
                                component={Input}
                                value={values.FuelSurchargePercentage || 0}
                                placeholder="Fuel surcharge Percentage"
                                label="Fuel surcharge Percentage"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            {/* Address1 */}

                            <div className="col-lg-4">
                              <Select
                                name="SupplierOptionId"
                                label="Supplier Options"
                              >
                                <option>Select</option>
                                {supplierOptionsList?.map((item, index) => (
                                  <option key={index} value={item.Id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </Select>
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="WeightLimit"
                                component={Input}
                                placeholder="Weight Limit"
                                label="Weight Limit"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="TurnTimes"
                                component={Input}
                                value={values.TurnTimes || 3}
                                placeholder="Turn Times"
                                label="Turn Times"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-lg-12">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="AutoAllocateId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "AutoAllocate",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    When confirming a receipt, default to
                                    Auto-allocate to Open Orders.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="SplitMovableUnitId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "SplitMovableUnit",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    When adding receipt line items, default to
                                    Split Movable Unit.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="SuggestedPutWayId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "SuggestedPutWay",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Enable Suggested Putaway.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">
                          MOBILE RECEIVING (SMARTSCAN)
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Warehouse Name */}
                            <div className="col-lg-4">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="ReceiveAgaintAsnId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "ReceiveAgaintAsn",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Receive Against ASN
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="UseBlindReceiptId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "UseBlindReceipt",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Use Blind Receipts
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="DefaultQuantityId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "DefaultQuantity",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Default Quantity = 1
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="3"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Shipment Option</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3" className="mb-4">
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-6">
                              <label className="text-primary">
                                Outbound Weight Limit
                              </label>
                              <Field
                                name="OutboundWeightLimit"
                                component={Input}
                                placeholder="Weight Limit"
                              />
                            </div>
                            <div className="col-lg-6">
                              <label className="text-primary">Low Cube</label>
                              <Field
                                name="LowCube"
                                component={Input}
                                placeholder="Low Cube"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <br></br>
                      <div className="card">
                        <h6 className="p-5 text-primary">
                          Shipment Modes weight limit
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {ShipmentModeList?.map((item, index) => (
                              <div className="col-lg-6">
                                <Field
                                  name={`CustWeightLimit`}
                                  component={Input}
                                  placeholder="Weight Limit"
                                  defaultValue={
                                    customer.CustomerShipmentModes.find(
                                      (el) => el.ModeId === item.Id
                                    )?.WeightLimit
                                  }
                                  label={item.Name}
                                  onChange={(event) => {
                                    setFieldValue(
                                      `CustomerShipmentModes[${item.Id}].ModeId`,
                                      item.Id
                                    );
                                    setFieldValue(
                                      `CustomerShipmentModes[${item.Id}].WeightLimit`,
                                      event.target.value
                                    );
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="4"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Orders OPTION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4" className="mb-4">
                    <div className="card">
                      <h6 className="p-5 text-primary">OPTIONS</h6>
                      <div className="card-body">
                        <div className="form-group row">
                          {/* UPC Inbound Option */}
                          <div className="col-lg-12">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name="InboundManifestHasUpc"
                                  id="InboundManifestHasUpc"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "InboundManifestHasUpc",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Include UPC in Inbound Manifest
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="FileImportTrackingId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "FileImportTracking",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Close order when a file import updates
                                  tracking information.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="AllocateOrderManuallyId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "AllocateOrderManually",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Order Queue - Allocate orders manually.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="AutomaticallyTrackById"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "AutomaticallyTrackBy",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  SmartPack - Automatically populate track-by
                                  information
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="ConnectionUpdatesTrackingId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "ConnectionUpdatesTracking",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  {" "}
                                  SmartParcel - Close order when a connection
                                  updates tracking information
                                  <img
                                    data-container="body"
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    title="Please note that this option only applies to ODBC flat file orders."
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Code/Info-circle.svg"
                                    )}
                                  />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="ScannerApplicationId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "ScannerApplication",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Scanner Application - Reallocate at pick time
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireTrackingNumberId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireTrackingNumber",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require tracking number to close order
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="FullMovableUnitsId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "FullMovableUnits",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Allocate Full Movable Units
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3 row">
                            <div className="col-lg-6">
                              <Select
                                name="InvoicingOptionId"
                                label="Fulfillment Invoicing"
                              >
                                <option>Select</option>
                                {fulfillmentInvoicesOptionsList?.map(
                                  (item, index) => (
                                    <option
                                      data-container={index == 2 ? "body" : ""}
                                      data-toggle={index == 2 ? "tooltip" : ""}
                                      data-placement={index == 2 ? "left" : ""}
                                      title={
                                        index == 2
                                          ? "If you're using fulfillment invoicing, please select Fulfillment invoicing enabled instead. The 'Edit fulfillment pricing' option uses the item price and does not allow for editing line item pricing on an order. If you wish to edit an item's price, you can do so in the item setup."
                                          : ""
                                      }
                                      key={index}
                                      value={item.Id}
                                    >
                                      {item.Name}
                                    </option>
                                  )
                                )}
                              </Select>
                              {/* {errors.InvoicingOptionId && touched.InvoicingOptionId ? (
                                <div style={{ color: "red", fontSize: "12px" }}>{errors.InvoicingOptionId}</div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="form-group row">
                          <h6>Advance Shipping Notice (ASN) Settings</h6>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireScacId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireScac",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  require a SCAC.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireCarrierId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireCarrier",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require a carrier.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireLoadNumberId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireLoadNumber",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require a load number.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireLadingNumberId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireLadingNumber",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require a Bill of Lading number.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequirePurchaseOrderId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequirePurchaseOrder",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require a purchase order number.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="PackageCountId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "PackageCount",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Package count cannot equal '0'.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="RequireOrderClosedId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "RequireOrderClosed",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require the order to be Closed.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="OneFileAtLeastId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "OneFileAtLeast",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Require at least one attached file.
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="TotalPackageVolumeId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "TotalPackageVolume",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Total volume and individual package volume
                                  must be greater than '0'
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mt-3">
                            <div className="d-flex align-items-center mt-5">
                              <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                              <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                <input
                                  type="checkbox"
                                  name=""
                                  id="TotalPackageWeightId"
                                  onChange={(event) => {
                                    setFieldValue(
                                      "TotalPackageWeight",
                                      event.target.checked
                                    );
                                  }}
                                  value="1"
                                />
                                <span></span>
                              </label>
                              <div className="d-flex flex-column flex-grow-1">
                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                  Total weight and individual package weight
                                  must be greater than '0'
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="5"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Invoice OPTION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5" className="mb-4">
                    <div className="card">
                      <h6 className="p-5 text-primary">OPTIONS</h6>
                      <div className="card-body">
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <Select
                              name="InboundInvoiceOptionId"
                              label="Inbound Invoice"
                              onChange={Try((e) => {
                                const val = e.target.value === "222";
                                const wList = window.InvoiceGrpOptions || [];
                                setInvoiceGrpOptionId(val ? wList : []);
                                setFieldValue(
                                  "InboundInvoiceOptionId",
                                  e.target.value
                                );
                              })}
                            >
                              <option>Select</option>
                              {InvoiceOptionId?.map((item, index) => (
                                <option key={index} value={item?.Id}>
                                  {item?.Name}
                                </option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-lg-4">
                            <Select
                              name="InboundInvoiceGrpOptionId"
                              label="Inbound Invoice Group"
                            >
                              <option>Select</option>
                              {InvoiceGrpOptionId?.map((item, index) => (
                                <option key={index} value={item?.Id}>
                                  {item?.Name}
                                </option>
                              ))}
                            </Select>
                          </div>

                          <div className="col-lg-4">
                            <Select name="NetTermId" label="NetTermId">
                              <option>Select</option>
                              {TermsList?.map((item, index) => (
                                <option key={index} value={item.Id}>
                                  {item.Name}
                                </option>
                              ))}
                            </Select>
                          </div>
                          <div className="col-lg-4 mt-5">
                            <Field
                              name="LineHaulDefaultPercentage"
                              component={Input}
                              type="number"
                              placeholder="LineHaul Default Percentage"
                              label="LineHaul Default Percentage"
                            />
                          </div>
                          <div className="col-lg-4 mt-5">
                            <Field
                              name="FuelPercentage"
                              component={Input}
                              type="number"
                              placeholder="Fuel Default Percentage"
                              label="Fuel Default Percentage"
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <div className="col-lg-4">
                            <CheckboxLargeWB
                              className="font-size-md"
                              text="POD Required ?"
                              name="IsPodRequired"
                              id="IsPodRequired"
                              onChange={(event) => {
                                setFieldValue(
                                  "IsPodRequired",
                                  event.target.checked
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="6"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">SYSTEM OPTION</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6" className="mb-4">
                    <div>
                      <div className="card">
                        <h6 className="p-5 text-primary">SETTINGS</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="LocationIdentifierReportId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "LocationIdentifierReport",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    When using the Location Identifier report,
                                    default to exclude locations with zero
                                    inventory
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-3 row">
                              <div className="col-lg-6">
                                <div className="d-flex align-items-center mt-5">
                                  <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                  <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="EnableCustomFieldsId"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "EnableCustomFields",
                                          event.target.checked
                                        );
                                      }}
                                      value="1"
                                    />
                                    <span></span>
                                  </label>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                      Enable custom fields.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="d-flex align-items-center mt-5">
                                  <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                  <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input
                                      type="checkbox"
                                      name=""
                                      id="AllowPurchaseOrderId"
                                      onChange={(event) => {
                                        setFieldValue(
                                          "AllowPurchaseOrder",
                                          event.target.checked
                                        );
                                      }}
                                      value="1"
                                    />
                                    <span></span>
                                  </label>
                                  <div className="d-flex flex-column flex-grow-1">
                                    <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                      Allow purchase orders.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-3">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="EnableDashboardId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "EnableDashboard",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Enable dashboard.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">CARRIER ACCOUNT</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <Field
                                name="UspNumber"
                                component={Input}
                                placeholder="UPS Account Number"
                                label="UPS Account Number"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="fedExNumber"
                                component={Input}
                                placeholder="Fed-Ex Account Number"
                                label="Fed-Ex Account Number"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="CarrierPostalCode"
                                component={Input}
                                placeholder="Carrier Postal Code"
                                label="Carrier Postal Code"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            {/* Address1 */}
                            <div className="col-lg-4">
                              <Select
                                name="CarrierInfoOptionId"
                                label="Add Carrier Info to Orders"
                              >
                                <option>Select</option>
                                {carrierInfoOptionList?.map((item, index) => (
                                  <option key={index} value={item.Id}>
                                    {item.Name}
                                  </option>
                                ))}
                              </Select>
                              {/* {errors.CarrierInfoOptionId && touched.CarrierInfoOptionId ? (
                                <div style={{ color: "red", fontSize: "12px" }}>{errors.CarrierInfoOptionId}</div>
                              ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">
                          PER TRANSACTION OPTIONS
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            {/* Address1 */}
                            <div className="col-lg-4">
                              <Select
                                name="CreateInvoicesOptionId"
                                label="Create Invoice Defaults"
                              >
                                <option>Select</option>
                                {defaultInvoicesOptionsList?.map(
                                  (item, index) => (
                                    <option key={index} value={item.Id}>
                                      {item.Name}
                                    </option>
                                  )
                                )}
                              </Select>
                              {/* {errors.CreateInvoicesOptionId && touched.CreateInvoicesOptionId ? (
                                <div style={{ color: "red", fontSize: "12px" }}>{errors.CreateInvoicesOptionId}</div>
                              ) : null} */}
                            </div>

                            <div className="col-lg-4">
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        lineHeight: "1.6",
                                      }}
                                    >
                                      {
                                        "If this field does not match your customer’s name"
                                      }
                                      <u>{" exactly"}</u>
                                      {
                                        " as it is recorded in QuickBooks, you will receive an error.Please note that both entries must also contain"
                                      }
                                      <u>{" alphanumeric characters only."}</u>
                                    </p>
                                  </React.Fragment>
                                }
                              >
                                <img
                                  data-container="body"
                                  data-placement="left"
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Code/Info-circle.svg"
                                  )}
                                  style={{
                                    width: "13%",
                                    paddingRight: "0.5rem",
                                  }}
                                />
                              </HtmlTooltip>
                              <Field
                                name="AccountingName"
                                component={Input}
                                placeholder="Accounting Name"
                                label="Accounting Name"
                              />
                            </div>

                            <div className="col-lg-12 mt-5">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="InvoiceConfirmationId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "InvoiceConfirmation",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Default invoice confirmation date to the
                                    transaction confirmation date.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">EDI X12 940-945</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <Field
                                name="InterchangeId"
                                component={Input}
                                placeholder="Customer Interchange ID"
                                label="Customer Interchange ID"
                              />
                            </div>
                            <div className="col-lg-4">
                              <Field
                                name="IdQualifier"
                                component={Input}
                                placeholder="Customer ID Qualifier"
                                label="Customer ID Qualifier"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="EwmsInterchangeId"
                                component={Input}
                                placeholder="3PL Interchange ID"
                                label="3PL Interchange ID"
                              />
                            </div>

                            <div className="col-lg-4 mt-5">
                              <Field
                                name="EwmsIdQualifier"
                                component={Input}
                                placeholder="3PL ID Qualifier"
                                label="3PL ID Qualifier"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">
                          PRE-WIRED CONNECTIONS
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <Field
                                name="SpsTradingPartnerId"
                                component={Input}
                                placeholder="SPS Trading Partner ID"
                                label="SPS Trading Partner ID"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card mt-5">
                        <h6 className="p-5 text-primary">DOCUMENT SETTINGS</h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <Field
                                name="VicsBillLadingId"
                                component={Input}
                                placeholder="Next VICS Bill of Lading ID"
                                label="Next VICS Bill of Lading ID"
                              />
                            </div>

                            <div className="col-lg-4">
                              <Field
                                name="ManufacturingId"
                                component={Input}
                                placeholder="Manufacturing ID"
                                label="Manufacturing ID"
                              />
                            </div>

                            <div className="col-lg-12 mt-5">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="UseTransactionBillNumberId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "UseTransactionBillNumber",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Use Transaction-based bill of lading number
                                    format rather than VICS standard.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12 mt-5">
                              <div className="d-flex align-items-center mt-5">
                                <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                  <input
                                    type="checkbox"
                                    name=""
                                    id="DisplayVendorId"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "DisplayVendorId",
                                        event.target.checked
                                      );
                                    }}
                                    value="1"
                                  />
                                  <span></span>
                                </label>
                                <div className="d-flex flex-column flex-grow-1">
                                  <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">
                                    Display the Vendor ID instead of the
                                    Retailer ID on bills of lading.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="7"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Contact</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7" className="mb-4">
                    <div>
                      <div className="card">
                        <h6
                          className="p-5 text-primary"
                          style={{ marginBottom: "-2rem" }}
                        >
                          Contact Information
                        </h6>
                        <div className="card-body">
                          <div className="form-group row">
                            <div
                              className="card-body"
                              id="another-participant1"
                            >
                              {createUI()}
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col">
                              <button
                                type="button"
                                onClick={addClick.bind(this)}
                                style={{ float: "right", width: "100%" }}
                                className="btn btn-light-success w-40 mt-md-8"
                              >
                                <i
                                  className={`fas fa-plus fa-sm`}
                                  style={{ paddingRight: "initial" }}
                                ></i>{" "}
                                <span
                                  style={{
                                    fontSize: "15px",
                                    marginLeft: "0.1rem",
                                  }}
                                >
                                  Add
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="8"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Tracking Options</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8" className="mb-4">
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-lg-6 p-5">
                              <CheckboxLargeWB
                                className="font-size-md"
                                text="PO Manage"
                                name="POManage"
                                id="POManage"
                                onChange={(event) => {
                                  setFieldValue(
                                    "POManage",
                                    event.target.checked
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="9"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">
                        ETA Grid
                      </h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="9" className="mb-4">
                    <div>
                      <div className="row mb-5">
                        <div className="col-lg-4 my-5">
                          <p style={{ marginBottom: "8px" }}>Default Service</p>
                          <SelectMulti
                            isClearable
                            name="DefaultServiceId"
                            placeholder="Service"
                            options={ServiceList?.map((x) => {
                              return {
                                value: x.Id,
                                label: x.Name,
                              };
                            })}
                            onChange={(e) => {
                              setFieldValue("DefaultServiceId", e?.value);
                            }}
                            value={
                              ServiceList?.filter(
                                (x) => x.Id === values?.DefaultServiceId
                              ).map((x) => {
                                return {
                                  value: x.Id,
                                  label: x.Name,
                                };
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-4 my-5">
                          <p style={{ marginBottom: "8px" }}>Default Mode</p>
                          <SelectMulti
                            isClearable
                            name="DefaultModeId"
                            placeholder="Mode"
                            onChange={(e) => {
                              setFieldValue("DefaultModeId", e?.value);
                            }}
                            options={ServiceList?.find(
                              (x) => x.Id === values?.DefaultServiceId
                            )?.Modes?.map((x) => {
                              return {
                                value: x.Id,
                                label: x.Name,
                              };
                            })}

                            value={
                              ServiceList?.find(
                                (x) => x.Id === values?.DefaultServiceId
                              )?.Modes?.filter(
                                (x) => x.Id === values?.DefaultModeId
                              ).map((x) => {
                                return {
                                  value: x.Id,
                                  label: x.Name,
                                };
                              })
                            }
                          />
                        </div>
                        <div className="col-lg-4 my-5">
                          <p style={{ marginBottom: "8px" }}>Service Level</p>
                          <SelectMulti
                            isClearable
                            name="DefaultServiceLevelId"
                            placeholder="Service Level"
                            onChange={(e) => {
                              setFieldValue("DefaultServiceLevelId", e?.value);
                            }}
                            options={ServiceList?.find(
                              (x) => x.Id === values?.DefaultServiceId
                            )?.Modes?.find(
                              (x) => x.Id === values?.DefaultModeId
                            )?.ServiceLevels?.map((x) => {
                              return {
                                value: x.Id,
                                label: x.Name,
                              };
                            })}
                            value={
                              ServiceList?.find(
                                (x) => x.Id === values?.DefaultServiceId
                              )?.Modes?.find(
                                (x) => x.Id === values?.DefaultModeId
                              )?.ServiceLevels?.filter(
                                (x) => x.Id == values?.DefaultServiceLevelId
                              ).map((x) => {
                                return {
                                  value: x.Id,
                                  label: x.Name,
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="card">
                        <h6 className="pt-5 ml-5 text-primary">
                          Factor table
                        </h6>
                        <div className="card-body pt-0 pb-0 mt-0 mb-0">
                          <div className="form-group row">
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell>Factor</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    Discharge Date
                                  </TableCell>
                                  <TableCell
                                    component="th" scope="row"
                                    style={{ width: "10%" }}
                                  >
                                    <Field
                                      name="AvgDaysToDischarge"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    Release Date
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToRelease"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    Appointment Date
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToAppointment"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    Picked Up Date
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToPickup"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    Received Date
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToReceived"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    Devan Date
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToDevan"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell component="th" scope="row">
                                    Hot Flag
                                  </TableCell>
                                  <TableCell component="th" scope="row" style={{ width: "10%" }}>
                                    <Field
                                      name="AvgDaysToHotFlag"
                                      type="number"
                                      component={Input}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </div>
                        </div>

                        <div className="card-body">
                          <h6 className="pt-5 ml-5 text-primary">
                            RDD transit days
                          </h6>
                          <div className="card-body" id="another-participant1">
                            {createServicesUI()}
                            <button
                              type="button"
                              onClick={addNewRow}
                              className="btn btn-primary btn-elevate"
                            >
                              Add more
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>

                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="10"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Assignments</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="10" className="mb-4">
                    <div>
                      <div className="card">
                        <div className="card-body">
                          <div className="form-group row">
                            <div className="col-xl-3 col-lg-3  mt-3">
                              <label>Commercial Assignment</label>
                              <SelectMulti
                                name="CommercialAssigneeId"
                                id="CommercialAssigneeId"
                                label="User"
                                options={UsersOtions.map((item, index) => ({
                                  value: item.Id,
                                  label: item.Name,
                                  IsGroup: item.IsGroup,
                                }))}
                                value={UsersOtions?.filter(
                                  (a) => a.Id === values.CommercialAssigneeId
                                ).map((suggestion) => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                }))}
                                onChange={(e) => {
                                  setFieldValue(
                                    "CommercialAssigneeId",
                                    e !== null ? e.value : null
                                  );
                                  setFieldValue(
                                    "CommercialAssigneeIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3  mt-3">
                              <label>Commercial Operation</label>
                              <SelectMulti
                                name="CommercialOperationId"
                                id="CommercialOperationId"
                                label="User"
                                options={UsersOtions.map((item, index) => ({
                                  value: item.Id,
                                  label: item.Name,
                                  IsGroup: item.IsGroup,
                                }))}
                                value={UsersOtions?.filter(
                                  (a) => a.Id === values.CommercialOperationId
                                ).map((suggestion) => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                }))}
                                onChange={(e) => {
                                  setFieldValue(
                                    "CommercialOperationId",
                                    e === null ? null : e.value
                                  );
                                  setFieldValue(
                                    "CommercialOperationIsGroup",
                                    e === null ? null : e.IsGroup
                                  );
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3  mt-3">
                              <label>Capacity Operation</label>
                              <SelectMulti
                                name="CapacityOperationId"
                                id="CapacityOperationId"
                                label="User"
                                options={UsersOtions.map((item, index) => ({
                                  value: item.Id,
                                  label: item.Name,
                                  IsGroup: item.IsGroup,
                                }))}
                                value={UsersOtions?.filter(
                                  (a) => a.Id === values.CapacityOperationId
                                ).map((suggestion) => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                }))}
                                onChange={(e) => {
                                  setFieldValue(
                                    "CapacityOperationId",
                                    e !== null ? e.value : null
                                  );
                                  setFieldValue(
                                    "CapacityOperationIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                }}
                              />
                            </div>
                            <div className="col-xl-3 col-lg-3  mt-3">
                              <label>Capacity Assignment</label>
                              <SelectMulti
                                name="CapacityAssigneeId"
                                id="CapacityAssigneeId"
                                label="User"
                                options={UsersOtions.map((item, index) => ({
                                  value: item.Id,
                                  label: item.Name,
                                  IsGroup: item.IsGroup,
                                }))}
                                value={UsersOtions?.filter(
                                  (a) => a.Id === values.CapacityAssigneeId
                                ).map((suggestion) => ({
                                  value: suggestion.Id,
                                  label: suggestion.Name,
                                }))}
                                onChange={(e) => {
                                  setFieldValue(
                                    "CapacityAssigneeId",
                                    e !== null ? e.value : null
                                  );
                                  setFieldValue(
                                    "CapacityAssigneeIsGroup",
                                    e !== null ? e.IsGroup : null
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row mx-3 py-2">
                            <button
                              type="button"
                              onClick={addClickTmsOptions.bind(this)}
                              className="btn btn-success btn-elevate"
                              style={{ width: "100%" }}
                            >
                              Add New Capacity Assignment
                            </button>
                          </div>
                          {createTmsOptionsUI()}
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                  <Accordion.Toggle
                    as={Button}
                    className="w-100 text-left p-0 position-relative text-decoration-none"
                    variant="link"
                    eventKey="11"
                  >
                    <Alert variant="primary " className="pt-3 pl-3 pb-0">
                      <h5 className="font-weight-bold">Transit days by location</h5>
                    </Alert>
                    <Icon
                      className={clsx(
                        "fa fa-chevron-down position-absolute text-white"
                      )}
                      style={{ fontSize: 17, right: "1rem", top: "20%" }}
                    />
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="11" className="mb-4">
                    <div>
                      <div className="card-body">
                        <h6 className="pt-5 ml-5 text-primary">
                          Loacations Transit days
                        </h6>
                        <div className="card-body" id="another-participant1">
                          {createLoacationsTransitDaysUI()}
                          <button
                            type="button"
                            onClick={addClickLoacationsTransitDays.bind(this)}
                            className="btn btn-primary btn-elevate"
                          >
                            Add more
                          </button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Accordion>
      <CustomerBillingAddressModal
        // saveCustomer={saveCustomer}
        // actionsLoading={actionsLoading}
        customer={billingData}
      // onHide={onHide}
      />
    </>
  );
}
