import React, { useEffect, useState } from "react";
import Item from "./Item";
import { Spinner } from "react-bootstrap";
import Draggable from "react-draggable";
import { default as JsNative } from "jquery";
import { DetailMangment } from "../../../../modules/eMPower/pages/PalletsManagmentBeta/customers-table/ModelManagment";
import { Modal } from "react-bootstrap";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  ShipmentID,
  selectedShip,
  iShipmentsID = [],
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);
  const [Search2, setSearch2] = useState("");
  const [ShowModel, setShowModel] = useState(false);
  const [IdSelect, setIdSelect] = useState();
  const [CheckboxListIds, setCheckboxListIds] = useState([]);

  function SelectIDforPallet(id) {
    setIdSelect(id);
    setShowModel(true);
  }

  useEffect(() => {
    GetPalletsFunction();
  }, []);

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }
    setCheckboxListIds([...array]);
  }

  var tableElement, offset;
  function GetTale() {
    var shipID = Pallets.find((x) => x.Id === CheckboxListIds[0])?.ShipID;
    tableElement = JsNative(".TableComponent" + shipID);
    offset = tableElement.offset();

    return shipID;
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      ele.addClass("bg-light-info");
    } else ele.removeClass("bg-light-info");
    ele.addClass("border border-info border-2");
  }

  function handleStop(e) {
    var shipID = GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      PostPallets(CheckboxListIds, shipID);
    }
    ele.removeClass("bg-light-info");
    ele.removeClass("border border-info border-2");
  }

  async function PostPallets(ItemsId, PalletId) {
    // return await fetch(`${window.$apiurl}/transload/receipt/shipitems`, {
    //   method: "post",
    //   withCredentials: true,
    //   headers: {
    //     Accept: "application/json, text/plain, */*",
    //     Authorization: `bearer ${db.read().getState().Token}`,
    //     "X-FP-API-KEY": "iphone",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     Items: ItemsId,
    //     ShipmentId: PalletId,
    //   }),
    // }).then(async (response) => {
    //   if (response.status === 200) {
    //Notification("success", "success : " + ItemsId.join(", "));
    ItemsId.forEach((x) => {
      var a = Pallets.findIndex((y) => x === y.Id);
      SetTableShipment(Pallets[a]);
    });

    var find = Pallets.filter((x) => ItemsId.indexOf(x.Id) === -1);
    setPallets([]);
    setTimeout(() => {
      setPallets(find);
    }, 1);

    setCheckboxListIds([]);
    /* ReloadPallets(); */
    //   } else {
    //     var msg = response.status !== 200 ? await response.json() : "";
    //     Notification("error", msg?.toString());
    //   }
    // });
  }

  async function GetPalletsFunction() {
    //const pallets = await GetPallets(ShipmentID);
    const pallets = [
      {
        Id: 702064,
        ShipID: 812014,
        PurchaseOrder: "4500093380",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 50,
        Weight: 282.67,
        Cube: 2.13,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy ID",
        LocationCode: "SCEMER004",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 50,
        OnHand: 0,
        Shipment: "817410,817413",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 50,
      },
      {
        Id: 702063,
        ShipID: 812014,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 64,
        Weight: 361.82,
        Cube: 2.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy ID",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 64,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 50,
      },
      {
        Id: 702061,
        ShipID: 812019,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 64,
        Weight: 250.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy KY",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },

      {
        Id: 702070,
        ShipID: 812014,
        PurchaseOrder: "4500093380",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 21,
        Weight: 282.67,
        Cube: 2.13,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy ID",
        LocationCode: "SCEMER004",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 21,
        OnHand: 0,
        Shipment: "817410,817413",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 50,
      },
      {
        Id: 702071,
        ShipID: 812014,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 12,
        Weight: 361.82,
        Cube: 2.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy ID",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 64,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 50,
      },
      {
        Id: 702072,
        ShipID: 812019,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 5,
        Weight: 250.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy KY",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },

      {
        Id: 702073,
        ShipID: 812018,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 47,
        Weight: 350.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy SC",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },
      {
        Id: 702074,
        ShipID: 812018,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 50,
        Weight: 350.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy SC",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },
      {
        Id: 702075,
        ShipID: 812018,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 75,
        Weight: 12.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy SC",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },

      {
        Id: 702076,
        ShipID: 812017,
        PurchaseOrder: "4500093381",
        Sku: "44067",
        Description: "SCE PSW WILDLIFE",
        UnitCount: 15,
        Weight: 122.82,
        Cube: 21.72,
        IsHazardousMaterial: null,
        IsHot: false,
        Pieces: 12,
        ShortQuantity: null,
        OverQuantity: null,
        DamagedQuantity: null,
        Pallet: null,
        Location: "Scentsy TX",
        LocationCode: "SCELEX003",
        UPC: null,
        AstrayQuantity: null,
        PoExpectedDate: null,
        BuyerId: null,
        Department: null,
        ReferenceNumber: "WEEK 31",
        ThirdPartyName: null,
        ThirdPartyQuantity: null,
        Shipped: 12,
        OnHand: 0,
        Shipment: "817426,817424",
        Pallets: "",
        PoEta: null,
        ReceivedDate: null,
        ProcessedDate: null,
        Color: null,
        Size: null,
        ProductType: null,
        ReceivedQuantity: null,
        Items: 30,
      },
    ];

    pallets.forEach((x) => {
      x.search = x?.Id.toString();
    });

    setPallets(pallets);
    setShow(true);
  }

  /* const repeat = [];
  const CheckReapet = (id) => {
    if (repeat.indexOf(id) === -1) {
      repeat.push(id);
      return true;
    } else return false;
  }; */

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) setPallets((x) => [...x, DeletRowId]);
  }, [DeletRowId]);

  function removeId(id) {
    var a = Pallets.filter((x) => x?.Id !== id);
    setPallets(a ?? []);
  }

  function compare(a, b) {
    if (a.ShipID < b.ShipID) {
      return -1;
    }
    if (a.ShipID > b.ShipID) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Modal
        size="xl"
        show={ShowModel}
        onHide={() => {
          setShowModel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={IdSelect} setShow={setShowModel.bind()} />
      </Modal>
      <div className="row">
        <div className="col-lg-6 my-5" />
        <div className="col-lg-6 my-5">
          <input
            type="text"
            placeholder="Search Purchase order"
            className="form-control"
            onKeyDown={(e) => {
              if (e.keyCode === 13) setSearch2(e.target.value);
            }}
            onBlur={(e) => {
              setSearch2(e.target.value);
            }}
          />
        </div>
        <div className={"col-12 my-2 mx-0"}>
          {CheckboxListIds?.length > 0 && (
            <Draggable
              axis="both"
              handle=".handle"
              position={{ x: 0, y: 0 }}
              grid={[1, 1]}
              scale={1}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <div className="handle">
                <button
                  type="button"
                  className={`btn btn-light-danger bg-light-danger text-danger w-100 p-0`}
                  style={{ height: "41px" }}
                >
                  {htmlP(CheckboxListIds, "Pallets Selected")}
                </button>
              </div>
            </Draggable>
          )}
        </div>

        {Pallets?.length > 0 ? (
          <>
            {Show &&
              Pallets.filter(
                (x) =>
                  x?.search?.includes(
                    Search2
                  ) /* &&
                  iShipmentsID.find((y) => y.Id === x.ShipID) */
              )
                .sort(compare)
                .map((x, i) => (
                  <>
                    {/* {CheckReapet(x.ShipID) && (
                      <>
                        {i !== 0 && (
                          <>
                            <div className="col-12 my-5 py-2" />
                          </>
                        )}
                        <div className="col-12 mb-2 mx-0">
                          <div className="alert alert-secondary">
                            <b>
                              Pallets For {i}
                              <b className="ml-2 text-primary">
                                {x.ShipID}
                                <i class="ml-5 pl-5 fas fa-location-arrow text-primary" />
                                {x.Location}
                              </b>
                            </b>
                          </div>
                        </div>
                      </>
                    )} */}
                    <Item
                      item={x}
                      key={i}
                      index={i}
                      SetTableShipment={SetTableShipment}
                      removeId={removeId}
                      ShipmentID={ShipmentID}
                      selectedShip={selectedShip}
                      CheckboxList={CheckboxList}
                      SelectIDforPallet={SelectIDforPallet}
                      CheckboxListId={
                        CheckboxListIds.length > 0 &&
                        Pallets.find((x) => x.Id === CheckboxListIds[0])?.ShipID
                      }
                    />
                  </>
                ))}
          </>
        ) : (
          <Nodatafound />
        )}
      </div>
    </>
  );
}

function Nodatafound() {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 7000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              No Items found
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
function htmlP(list, text) {
  return (
    <>
      {text}
      {list.map((x) => (
        <p type="button" className={`btn btn-danger px-1 py-0 mt-3 ml-1 mr-1`}>
          {x}
        </p>
      ))}
    </>
  );
}
