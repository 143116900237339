import MockUtils from "../mock.utils";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import $ from "jquery";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

let remarkTableMock = [];
export default function mockContainers(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModels(id) {
    return await fetch(
      `${window.$apiurl}/transload/orders/receipts?orderId=${id}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        window.$NewReceipts = res[1];
        return ChangeId(res[1]["Receipts"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function DeleteModel(id) {
    return fetch(window.$apiurl + "/transload/receipts/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(window.$apiurl + "/transload/receipts/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetShipmentDetails() {
    return await fetch(
      window.$apiurl + "/transload/shipment/details?orderId=" + UrlId(),
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetAssignToOutbound() {
    return await fetch(
      window.$apiurl + "/transload/shipment/details?orderId=" + UrlId(),
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        var id = window.FindOrderId;
        if (id) {
          var a = await GetFindOrder(id);
          if (a !== 400) res[1].ViewModel.FindOrder = a;
          else res[1].ViewModel.FindOrder = 400;
        }
        window.$AssigntoOutboundServices = res[1]?.Services;
        return res[1]?.ViewModel;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetFindOrder(id) {
    return await fetch(
      window.$apiurl +
        "/transload/shipment/receipts?" +
        window.FindOrderSearch +
        id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400) return 400;
        res[1].Close = true;
        return res[1];
      })
      .catch((error) => {
        Notification("error", "Order Not Found");
        console.error(error);
      });
  }

  async function GetOneMoveMaster(id) {
    return await fetch(window.$apiurl + "/transload/shipment/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        window.AutoRating = res[1]?.AutoRating;
        window.ContainerIsHot = res[1]?.Shipment?.IsHot ?? false;

        res[1].Weight = res[1]?.Weight?.toFixed(2);
        res[1].Cube = res[1]?.Cube?.toFixed(2);

        res[1].Shipment.IsManager = res[1].IsManager;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/transload/receipts", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        window.$NewReceiptsID = res[1]?.Id;
        window.$NewReceipts = someData;
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PostShipment(postdata) {
    const url =
      postdata?.Warehouse !== undefined
        ? "/transload/AssignTowarehouse/shipItem"
        : "/transload/shipment/item";

    return fetch(window.$apiurl + url, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postdata),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        if (!res.ok) throw res;
        Notification("success", "success");
      })
      .catch((err) => {
        err.json().then((body) => {
          Notification("error", body?.Message);
        });
      });
  }

  async function PostShipmentWareHouse(postdata) {
    return fetch(
      `${window.$apiurl}/transload/receipts/assigntowarehouse/${postdata.ReceiptItemId}?warehouseId=${postdata.ShipmentId}`,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PostAllShipments(postdata) {
    return fetch(
      `${window.$apiurl}/transload/receipts/ship?receiptId=${postdata.RecvId}&shipmentId=${postdata.ShipmentId}`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PostMultipleShipments(postdata) {
    return fetch(`${window.$apiurl}/transload/receipt/shipitems`, {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postdata),
    }).then(async (response) => {
      var msg = response.status !== 200 ? await response.json() : "";
      if (response.status !== 200) {
        Notification("error", msg?.Message ?? "");
        return false;
      } else {
        Notification("success", "success");
        return true;
      }
    });
  }

  async function PutShipment(puttdata) {
    return fetch(window.$apiurl + "/transload/shipment/" + puttdata.id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(puttdata),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        if (!res.ok) throw res;
        Notification("success", "success");
      })
      .catch((err) => {
        err.json().then((body) => {
          Notification("error", body?.Message);
        });
      });
  }

  async function DeleteShipment(Id, Warehouse) {
    return fetch(window.$apiurl + "/TransloadWarehouse/DeleteShipItem/" + Id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteWareHouseShipment(postdata) {
    return fetch(
      `${window.$apiurl}/transload/receipts/unassignfromwarehouse/${postdata.ReceiptItemId}?warehouseId=${postdata.ShipmentId}`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    return fetch(window.$apiurl + "/transload/receipts/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          Notification("success", "success");
          return true;
        }
        const data = (await response.json()) || {};
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 400) {
          Notification("error", `${res[1].Message}`);
          window.ContainerSaveClose = false;
          return false;
        }
        return true;
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetModel(id) {
    return await fetch(window.$apiurl + "/transload/receipts/" + id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        window.$Containers_AutoChares = {
          "AR-PROCESS-ACCESSORY": res[1]?.Accessory,
          "AR-PROCESS-APPAREL": res[1]?.Apparel,
          "AR-PROCESS-EXCEPTIONS": res[1]?.Exceptions,
          "AR-PROCESS-FOOTWEAR": res[1]?.FootWear,
          "AR-PROCESS-STUDIO-PULL": res[1]?.StudioPull,
        };
        window.$Containers_BaseData = res[1]?.ViewModel?.BaseData;
        window.$Containers_Services = res[1]?.Services;

        window.$reciept_LineItem = res[1]?.ViewModel?.LineItems;
        window.$Containers_Services2 = undefined;
        if (window.$ReceiptModeId) {
          if (res[1]?.Services || false) {
            res[1].Services.filter((obj) => {
              var a = obj?.Modes.find((x) => x?.Id === window.$ReceiptModeId);
              if (a) {
                window.$Containers_Services2 = a?.Status;
              }
            });
          }
        }
        var r = res[1]?.ViewModel?.Receipt;
        r.AutoRating = res[1]?.AutoRating;

        window.AutoRating = res[1]?.AutoRating;
        window.ContainerIsHot = r?.IsHot ?? false;

        r.IsManager = res[1]?.ViewModel?.IsManager || false;
        return r;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function TabeShipment() {
    return fetch(window.$apiurl + "/transload/shipment?orderId=" + UrlId(), {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return await response.json();
      })
      .then((res) => {
        return res["Id"];
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutOneMoveMaster(data, id) {
    //data.LastShipDate = PostDateTimeZoneFormatter(data?.LastShipDate);

    return fetch(window.$apiurl + "/shipment/" + id, {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          Notification("success", "success");
        } else if (statusCode === 400) {
          window.$OneMoveMasterSaveClose = false;
          const data = await response.json();
          Notification("error", data?.Message);

          let modelState = data?.ModelState;
          if (modelState)
            Object.keys(modelState).forEach(function(k) {
              modelState[k].forEach((element) => {
                Notification("error", element);
              });
            });
        }
        return statusCode;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //------------------------------------------------------------------------------------

  mock.onGet("api/FindOrderContainers/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onGet("api/FindOrderContainers/AssignToOutbound").reply(async () => {
    const obj = await GetAssignToOutbound();
    return [200, obj];
  });

  mock.onPost("api/FindOrderContainers/OneMoveMaster").reply(async (data) => {
    var id = JSON.parse(data.data).statSHipmentId;
    const obj = await GetOneMoveMaster(id);
    return [200, obj];
  });

  mock
    .onPost("api/FindOrderContainers/PutOneMoveMaster")
    .reply(async (data) => {
      const json = JSON.parse(data.data);
      var data = json.values;
      var id = json.statSHipmentId;
      const obj = await PutOneMoveMaster(data, id);
      return [200, obj];
    });

  mock.onGet("api/FindOrderContainers/TabeShipment").reply(async () => {
    const obj = await TabeShipment();
    return [200, obj];
  });

  mock.onGet("api/FindOrderContainers/shipmentDetails").reply(async () => {
    const obj = await GetShipmentDetails();
    return [200, obj];
  });

  mock.onPost("api/FindOrderContainers/PostShipment").reply(async (data) => {
    var postdata = JSON.parse(data.data).data;
    const obj = await PostShipment(postdata);
    return [200, obj];
  });

  mock
    .onPost("api/FindOrderContainers/PostShipmentWareHouse")
    .reply(async (data) => {
      var postdata = JSON.parse(data.data).data;
      const obj = await PostShipmentWareHouse(postdata);
      return [200, obj];
    });

  mock
    .onPost("api/FindOrderContainers/PostAllShipments")
    .reply(async (data) => {
      var postdata = JSON.parse(data.data).data;
      const obj = await PostAllShipments(postdata);
      return [200, obj];
    });

  mock
    .onPost("api/FindOrderContainers/PostMultipleShipments")
    .reply(async (data) => {
      var postdata = JSON.parse(data.data).data;
      const obj = await PostMultipleShipments(postdata);
      return [200, obj];
    });

  mock.onPost("api/FindOrderContainers/PutShipment").reply(async (data) => {
    var putdata = JSON.parse(data.data).data;
    const obj = await PutShipment(putdata);
    return [200, obj];
  });

  mock.onPost("api/FindOrderContainers/DeleteShipment").reply(async (data) => {
    var { Id, Warehouse } = JSON.parse(data.data).data;
    const obj = await DeleteShipment(Id, Warehouse);
    return [200, obj];
  });

  mock
    .onPost("api/FindOrderContainers/DeleteWareHouseShipment")
    .reply(async (data) => {
      var postData = JSON.parse(data.data).data;
      const obj = await DeleteWareHouseShipment(postData);
      return [200, obj];
    });

  mock.onPost("api/FindOrderContainers").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    console.log(41214);
    console.log(remark);
    const {
      id,
      OrderId,
      ServiceId,
      ModeId,
      ServiceLevelId,
      EquipmentTypeId,
      EquipmentSizeId,
      AccessorialAmount1,
      AccessorialAmount1Calculated,
      AccessorialAmount2,
      AccessorialAmount2Calculated,
      AccessorialAmount3,
      AccessorialAmount3Calculated,
      AccessorialDescription1,
      AccessorialDescription2,
      AccessorialDescription3,
      AvailableDate,
      BillingRate,
      BillingRateCalculated,
      CustomerId,
      CustomerRefNumber1,
      DepartmentId,
      DevanDate,
      DoReceivedDate,
      EquipmentNumber,
      Fsc,
      FscCalculated,
      InvoiceDate,
      InvoiceNumber,
      LastFreeDate,
      MasterBill,
      PickupLocationId,
      ReceivedDate,
      ReleaseNumber,
      SealNumber,
      StatusId,
      TotalCube1,
      TotalCube2,
      TotalCubeType1,
      TotalCubeType2,
      TotalWeight1,
      TotalWeight2,
      TotalWeightType1,
      TotalWeightType2,
      VendorCost,
      VendorId,
      VendorReference,
      WarehouseId,
      Notes,
      Charges,
      PayableCharges,
    } = remark;
    const newRemark = {
      id,
      OrderId,
      ServiceId,
      ModeId,
      ServiceLevelId,
      EquipmentSizeId,
      AccessorialAmount1,
      AccessorialAmount1Calculated,
      AccessorialAmount2,
      AccessorialAmount2Calculated,
      AccessorialAmount3,
      AccessorialAmount3Calculated,
      AccessorialDescription1,
      AccessorialDescription2,
      AccessorialDescription3,
      AvailableDate,
      BillingRate,
      BillingRateCalculated,
      CustomerId,
      CustomerRefNumber1,
      DepartmentId,
      DevanDate,
      DoReceivedDate,
      EquipmentNumber,
      EquipmentTypeId,
      Fsc,
      FscCalculated,
      InvoiceDate,
      InvoiceNumber,
      LastFreeDate,
      MasterBill,
      PickupLocationId,
      ReceivedDate,
      ReleaseNumber,
      SealNumber,
      StatusId,
      TotalCube1,
      TotalCube2,
      TotalCubeType1,
      TotalCubeType2,
      TotalWeight1,
      TotalWeight2,
      TotalWeightType1,
      TotalWeightType2,
      VendorCost,
      VendorId,
      VendorReference,
      WarehouseId,
      Notes,
      Charges,
      PayableCharges,
    };
    await PostModel(remark);
    remarkTableMock.push(newRemark);
    return [200, { remark: newRemark }];
  });

  mock.onPost(/api\/FindOrderContainersfind\/\d+/).reply(async (config) => {
    const urls = config.url.split("/");
    const id = urls[2];
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const productRemarks = await GetModels(id);
    const filterdRemarks = mockUtils.baseFilter(productRemarks, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/FindOrderContainers/deleteRemarks").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/FindOrderContainers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrderContainers\/(\d+)/)[1];
    const remark = await GetModel(id);
    $("#ContainerNumber").text(remark.EquipmentNumber || "Empty");
    remark.id = remark.Id;
    window.ReceiptId = id;
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/FindOrderContainers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrderContainers\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    var putObj = await PutModel(remark, id);
    if (!putObj) {
      return [200];
    }

    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/FindOrderContainers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindOrderContainers\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function UrlId() {
  var urlstring = window.location.pathname;
  return urlstring.split("/")[3];
}
