// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip, ButtonToolbar } from "react-bootstrap";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../_metronic/_helpers";
import { ModelManagmentFirstDashboardSm } from "../../modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import ModelManagmentFirstReporting from "../../modules/eMPower/pages/ReceiptsManagementNewVersion/customers-table/Shipment/ModelManagmentFirstReporting";
import {
  GetIsNoteIcone,
  GetPhotoFileIcone,
  GetSlaIcone,
  GetTransloadDays,
} from "../../modules/eMPower/pages/ReportingPortView/ReportingPortView";

export function QueryTable({ ReportingData = [], selected = [] }) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (ReportingData.length === 0) return;
    var list = [];

    selected.forEach((element) => {
      list.push({
        dataField: element?.value,
        text: element?.label,
        sort: true,
        sortCaret: sortCaret,
        headerSortingClasses,
        formatter: (cell, row) => {
          return QuerFormatter(row, element);
        },
      });
    });

    setColumns(list);
  }, [ReportingData]);

  const defaultSorted = [{ dataField: "Aging", order: "desc" }];
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    {
      text: "All",
      value: ReportingData.length,
    },
  ];

  return (
    <>
      {columns.length > 0 && (
        <BootstrapTable
          keyField={"Key"}
          noDataIndication={
            <h4 style={{ textAlign: "center" }}>
              There is no data available at the moment.
            </h4>
          }
          data={ReportingData.map((item, index) => {
            var data = item;
            data.key = index;
            return data;
          })}
          columns={columns}
          defaultSorted={defaultSorted}
          pagination={paginationFactory({
            showTotal: true,
            sizePerPage:
              ReportingData.length <= 100 ? ReportingData.length : 10,
            sizePerPageList: sizePerPageList,
          })}
          wrapperClasses="table-responsive reporting"
          classes="table table-head-custom table-vertical-center"
          bootstrap4
          bordered={false}
        />
      )}
    </>
  );
}

function CheckContaint(field, txt) {
  return field
    ?.trim()
    ?.toLowerCase()
    ?.includes(txt.trim().toLowerCase());
}

export function QuerFormatter(row, y) {
  const x = y?.value;
  /**/ if (CheckContaint(x, "note")) return GetIsNoteIcone(row[x]);
  else if (CheckContaint(x, "Hot")) return GetIsHoteIcone(row[x]);
  else if (CheckContaint(x, "SLA")) return GetSlaIcone(row[x]);
  else if (CheckContaint(x, "Days")) return GetTransloadDays(row[x]);
  else if (x === "PhotoFile") return GetPhotoFileIcone(row[x]);
  else if (x === "Container") {
    if (row.ContainerId !== undefined) {
      return (
        <ModelManagmentFirstReporting
          Id={row.ContainerId}
          Container={row.Container}
          isReporting={true}
        />
      );
    } else if (row["Receive#"] !== undefined) {
      return (
        <ModelManagmentFirstReporting
          Id={row["Receive#"]}
          Container={row.Container}
          isReporting={true}
        />
      );
    } else if (row.ReceiptId !== undefined) {
      return (
        <ModelManagmentFirstReporting
          Id={row.ReceiptId}
          Container={row.Container}
          isReporting={true}
        />
      );
    }
  } else if (x === "PO") {
    if (row.ContainerId !== undefined) {
      return (
        <ModelManagmentFirstReporting
          Id={row.ContainerId}
          Container={row.PO}
          isReporting={true}
        />
      );
    } else if (row.ReceiptId !== undefined) {
      return (
        <ModelManagmentFirstReporting
          Id={row.ReceiptId}
          Container={row.PO}
          isReporting={true}
        />
      );
    }
  } else if (x === "Trailer")
    return (
      <ModelManagmentFirstDashboardSm
        trailer={row.Trailer}
        id={row.Shipment}
        isDashboard={true}
      />
    );
  else return row[x];
}

export function GetIsHoteIcone(isHot = false) {
  return (
    <>
      {isHot ? (
        <OverlayTrigger
          overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
        >
          <a className="btn btn-icon btn-light btn-hover-light btn-sm mx-3">
            <span className="svg-icon svg-icon-md svg-icon-danger">
              <i class="fab fa-hotjar text-danger"></i>
            </span>
          </a>
        </OverlayTrigger>
      ) : (
        <span className="svg-icon svg-icon-md svg-icon-muted">
          <i class="fab fa-hotjar text-muted"></i>
        </span>
      )}
    </>
  );
}
