import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import {
  CheckboxLarge,
  Input,
} from "../../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);
// Validation schema
const CustomerEditSchema = Yup.object().shape({
  Name: Yup.string()
    .min(3, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Organisation Name is required"),
  Address: Yup.string()
    .min(10, "Minimum 10 symbols")
    .required("Address is required"),
  City: Yup.string().required("City is required"),
  // AddressTypeId: Yup.string()
  //   .required("Address Type Option is required"),
  // CountryId: Yup.string()
  //   .required("Country Option is required"),
});
const RequiredHtml = () => {
  return (
    <i
      class="fa fa-star-of-life text-danger icon-l ml-2"
      style={{ fontSize: "8px", position: "absolute" }}
    ></i>
  );
};

const catchError = [];
export function CustomerEditForm({
  disabledZipCode = false,
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [Country, setCountry] = useState(catchError);
  const [CountryObj, setCountryObj] = useState({
    value: "",
    label: "Select",
  });
  const [AddressTypes, setAddressTypes] = useState(catchError);
  const [Status, setStatus] = useState(false);
  const [AddressTypeValue, setAddressTypeValue] = useState({
    value: "",
    label: "Select",
  });
  const [states, setStates] = useState([]);
  const [showStateProgress, setShowStateProgress] = useState(false);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });

  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || catchError);
      setAddressTypes(result.data["AddressTypes"] || catchError);
    } catch (e) {}
  };

  useEffect(() => {
    fetchViewModelAsync();
    GetCustomers();
  }, []);

  useEffect(() => {
    setStatus(customer?.IsActive || false);
    var countryId = customer?.CountryId || false;
    if (countryId) {
      var country = Country.find((x) => x.Id == countryId) || CountryObj;
      setCountryObj(
        {
          value: country.Id,
          label: country.Name,
        } || CountryObj
      );
    }
    if (
      customer?.OrganizationId !== null &&
      customer?.OrganizationId !== undefined
    ) {
      setAddressTypeValue({
        value: customer?.OrganizationId,
        label: customer?.OrganizationName,
      });
    }
    if (customer?.CountryId !== null && customer?.CountryId !== undefined) {
      handleCountryChange(customer?.CountryId);
      var country = Country.find((x) => x.Id == customer?.CountryId);
      if (country === undefined) {
        setCountryValue({ value: "US", label: "United States" });
      } else {
        setCountryValue({ value: country?.Id, label: country?.Name });
      }
    } else {
      setCountryValue({ value: "US", label: "United States" });
      handleCountryChange("US");
    }

    try {
      //Customer
      if (customer?.CustomerId) {
        const list = window.$customersAddress;
        const selectedItem = list?.find((x) => x.Id === customer?.CustomerId);
        setCustomerValue({ value: selectedItem.Id, label: selectedItem.Name });
      }
    } catch (err) {}
    try {
      //State
      if (customer?.State) {
        setStateId({ value: customer?.StateId, label: customer?.State });
      }
    } catch (err) {}

    //IsNewAddress
    if (customer?.Id) {
      setUpdatedAddress(true);
    }
  }, [customer]);

  useEffect(() => {
    if (customer?.AddressTypeId) {
      setAddressTypeValue({
        value: customer?.AddressTypeId,
        label: AddressTypes.find((x) => x.Id === customer?.AddressTypeId)?.Name,
      });
    }
  }, [AddressTypes]);

  const [customers, setCustomers] = React.useState([]);
  const [isUpdatedAddress, setUpdatedAddress] = React.useState(false);
  const [stateId, setStateId] = React.useState({
    value: "",
    label: "Select",
  });
  const [customerValue, setCustomerValue] = React.useState({
    value: "",
    label: "Select",
  });
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/quoting/viewmodel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        const obj = [];
        a?.Customers.length > 0 &&
          a.Customers.map((item) => {
            obj.push({ value: item.Id, label: item.Name });
          });
        setCustomers(obj);
        window.$customersAddress = a.Customers;
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const handleCountryChange = async (event) => {
    setShowStateProgress(true);
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status == 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
    setShowStateProgress(false);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          var stringfyJson = JSON.stringify(values);
          var parseJson = JSON.parse(stringfyJson);
          if (
            AddressTypeValue.value === "" ||
            AddressTypeValue.value === undefined
          ) {
            Notification("error", "Type is required.");
            return false;
          }
          parseJson.AddressTypeId = AddressTypeValue.value;
          parseJson.CustomerId = customerValue.value;
          parseJson.State = stateId.label;
          saveCustomer(parseJson);
        }}
      >
        {({ errors, touched, handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body>
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {isUpdatedAddress && (
                    <div className="col-lg-6" id="newAddress">
                      <Field
                        name="Code"
                        style={{ backgroundColor: "#F5F5F5" }}
                        component={Input}
                        disabled={true}
                        readOnly={true}
                        placeholder="Address Code"
                        label="Address Code"
                        required={true}
                      />
                    </div>
                  )}

                  <div className={isUpdatedAddress ? `col-lg-6` : `col-lg-12`}>
                    <Field
                      name="Name"
                      component={Input}
                      placeholder="Organisation Name"
                      label="Organisation Name"
                      required={true}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 px-0">
                    <label>
                      Address <RequiredHtml />
                    </label>
                    <Field
                      name="Address"
                      as="textarea"
                      className="form-control"
                    />
                    {errors.Address && touched.Address ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.Address}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <Field
                      name="City"
                      component={Input}
                      placeholder="City"
                      label="City"
                      disabled={disabledZipCode}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label>Choose a state</label>
                    <Select
                      name="State"
                      label="State"
                      id="LocationState"
                      options={states}
                      value={stateId}
                      onChange={(e) => {
                        setStateId(e);
                      }}
                      isDisabled={disabledZipCode}
                    />
                    {showStateProgress && <LinearProgress color="secondary" />}
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="PostalCode"
                      component={Input}
                      placeholder="Zip Code"
                      label="Zip Code"
                      disabled={disabledZipCode}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-4">
                    <label>
                      Country <RequiredHtml />
                    </label>
                    <CreatableSelect
                      isDisabled={disabledZipCode}
                      name="CountryId"
                      value={
                        countryIdFetch.value === ""
                          ? { value: "US", label: "United States" }
                          : countryIdFetch
                      }
                      options={Country.map((x) => {
                        return { value: x?.Id, label: x?.Name };
                      })}
                      onChange={(newValue) => {
                        setCountryValue(newValue);
                        setFieldValue("CountryId", newValue?.value);
                        handleCountryChange(newValue?.value);
                      }}
                    />
                    {/* <DownshiftMultipleSelect
                      DefalutValue={CountryObj}
                      FieldName="CountryId"
                      suggestions={Country}
                      setFieldValue={setFieldValue}
                    /> */}
                    {errors.CountryId && touched.CountryId ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.CountryId}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="Phone"
                      component={Input}
                      placeholder="Phone"
                      label="Phone"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Field
                      name="Fax"
                      component={Input}
                      placeholder="Fax"
                      label="Fax"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <Field
                      name="WebPage"
                      component={Input}
                      placeholder="Tracing Web Page"
                      label="Tracing Web Page"
                    />
                  </div>
                  <div className="col-lg-3">
                    <Field
                      name="Email"
                      component={Input}
                      placeholder="Contact"
                      label="Contact"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label>
                      Type <RequiredHtml />
                    </label>
                    {/* <DownshiftMultipleSelectCustom
                      FieldName="AddressTypeId"
                      suggestions={AddressTypes}
                      DefalutValue={AddressTypeValue}
                      setFieldValue={setFieldValue}
                    /> */}
                    <CreatableSelect
                      isDisabled={disabledZipCode}
                      isClearables
                      value={AddressTypeValue}
                      onChange={async (newValue) => {
                        try {
                          setFieldValue("AddressTypeId", newValue.value);
                          setAddressTypeValue(newValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      options={AddressTypes.map((item) => {
                        return { value: item.Id, label: item.Name };
                      })}
                      name="AddressTypeId"
                      className="w-100 mr-2"
                    />
                    {errors.AddressTypeId && touched.AddressTypeId ? (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {errors.AddressTypeId}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-lg-3 align-self-center px-0">
                    <CheckboxLarge
                      checked={Status}
                      label=" "
                      text="Active"
                      name="IsActive"
                      id="IsActiveld"
                      onChange={(event) => {
                        setFieldValue("IsActive", event.target.checked);
                        setStatus(event.target.checked);
                      }}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>Customer</label>
                    <Select
                      name="CustomerId"
                      onChange={(e) => {
                        setCustomerValue(e);
                      }}
                      value={customerValue}
                      options={customers}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 px-0">
                    <label>Note</label>
                    <Field name="Note" as="textarea" className="form-control" />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
