import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { Spinner, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import { momentDate } from "../../../../../_metronic/_helpers/ATHelpers";
import { DetailMangment } from "../../../../modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { OneMoveMaster } from "../../../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
const adapter = new LocalStorage("db");
const db = low(adapter);

const mystyleTabs = {
  fontSize: "13px",
};

export default function TableComponent({
  list = [],
  DeleteShipmentRow,
  ShipmentID,
  InTable,
  hidden,
  ReloadPallets,
  IsClosedFunction,
  GetParms,
  isClosed,
  selectedShip,
  setDeletRowId,
  setLoading,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const classes = useStyles();
  const [Model, setModel] = useState(false);
  const [DetailMangmentShow, setDetailMangmentShow] = useState(false);
  const [OnemoveMasterShow, setOnemoveMasterShow] = useState(0);
  const [ListOnly, setListOnly] = useState([]);
  const [IsClosed, setIsClosed] = useState(false);

  function removeId(id) {
    var a = ListOnly.filter((x) => x?.Id !== id);
    setListOnly(a ?? []);
  }

  useEffect(() => {
    setListOnly(list.filter((x) => x.ShipID === ShipmentID?.Id) ?? []);
  }, [ShipmentID?.Id, list]);

  useEffect(() => {
    if (InTable?.id === ShipmentID?.Id && InTable?.number !== 0)
      GetShipmentId(ShipmentID?.Id).then((e) => {
        if (e[0] === 200) {
          setListOnly(e[1]?.pallets ?? []);
        }
      });
  }, [InTable]);

  useEffect(() => {
    if (ShipmentID?.Id)
      GetShipmentId(ShipmentID?.Id).then((e) => {
        if (e && e[0] === 200) {
          setListOnly(e[1]?.pallets ?? []);
        }
      });
  }, []);

  useEffect(() => {
    if (OnemoveMasterShow === false)
      setDeletRowId((x) => {
        return { id: ShipmentID?.Id, number: x.number + 1 };
      });
  }, [OnemoveMasterShow]);

  return (
    <div
      className={
        "col-xl-6 col-lg-6 col-sm-6 TableComponent" +
        (!IsClosed ? ShipmentID.Id : "")
      }
    >
      <Modal
        size="xl"
        show={OnemoveMasterShow}
        onHide={() => {
          setOnemoveMasterShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <OneMoveMaster
          Show={setOnemoveMasterShow}
          statSHipmentId={ShipmentID?.Id}
        />
      </Modal>

      <Modal
        size="xl"
        show={DetailMangmentShow}
        onHide={() => {
          setDetailMangmentShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment
          isDashboard={false}
          Id={ShipmentID?.Id}
          setShow={setDetailMangmentShow.bind()}
        />
      </Modal>

      <Modal
        size="xl"
        show={Model}
        onHide={() => {
          setModel(false);
        }}
      >
        <Modal.Header>
          <div className="row modal-title h4 w-100">
            <div className="col-3">
              <b>Shipment ID</b>
              <b className=" p-0 px-3 ml-3">{ShipmentID?.Id}</b>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-light-danger font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  PosttoClosed(ShipmentID?.Id, setIsClosed, setLoading);
                }}
              >
                <i
                  className={`fas fa-${
                    !IsClosed ? "times-circle" : "lock-open"
                  }`}
                ></i>{" "}
                {!IsClosed ? "Close Shipment" : "UnClose Shipment"}
              </button>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  window
                    .open("/report/shipment/bol/" + ShipmentID?.Id, "_blank")
                    .focus();
                }}
              >
                <i className={`fas fa-eye`} /> Bill of lading
              </button>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm w-100 pt-3 pb-2 mb-3"
                style={mystyleTabs}
                onClick={() => {
                  window
                    .open(
                      "/TransloadShipmentManifest/" + ShipmentID?.Id,
                      "_blank"
                    )
                    .focus();
                }}
              >
                <i className={`fas fa-eye`}></i> Shipment Manifest
              </button>
            </div>
          </div>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModel(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>Pallets Count : {ListOnly.length}</b>
            {ListOnly.length > 0 ? (
              <Paper className={classes.paper} /* id={ShipmentID + "Id"} */>
                <div className="react-bootstrap-table table-responsive TabelFilter">
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Pallet Id
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Customer Name
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Carton count
                        </TableCell>{" "}
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Weight
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Weight Type
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Cube
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Cube Type
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Created
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Shipped
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Equipment
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          status
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(ListOnly ?? []).map((item, index) => (
                        <TableTrFunction
                          item={item}
                          key={index}
                          DeleteShipmentRow={DeleteShipmentRow}
                          ShipId={ShipmentID?.Id}
                          removeId={removeId}
                          IsClosed={IsClosed}
                          setLoading={setLoading}
                        />
                      ))}
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {ListOnly.reduce((a, b) => +a + +b.Unitcount, 0)}
                          </b>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            ) : (
              <Nodatafound PalletID={ShipmentID?.Id} />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div
        className={
          "shadow-sm p-0 pl-2 pt-5 mb-5 rounded " +
          (!IsClosed ? "bg-white" : "bg-secondary ")
        }
        style={{
          height: "18vw",
          maxHeight: "200px",
          cursor: "pointer",
          zIndex: 1,
        }}
        onClick={(e) => setModel(true)}
      >
        <b className="position-absolute">
          <i className="fas fa-location-arrow text-info mr-2" />{" "}
          <b id={`ShipId${ShipmentID?.Id}`}>
            {ShipmentID?.ConsigneeName ?? "Empty"}{" "}
          </b>
        </b>
        <b
          className="position-absolute p-3"
          style={{ right: "1vw", top: "6px", zIndex: 2 }}
          onClick={(e) => {
            e.preventDefault();
            setDetailMangmentShow(true);
            e.stopPropagation();
          }}
        >
          <i
            className="fas fa-info-circle text-info"
            style={{ fontSize: "20px" }}
          />
        </b>

        <b
          className="position-absolute p-3"
          style={{ right: "4vw", top: "5px", zIndex: 2 }}
          onClick={(e) => {
            window.shipmentEditCommingFrom = "AssigntoOutbound";

            e.preventDefault();
            setOnemoveMasterShow(true);
            e.stopPropagation();
          }}
        >
          <i className="fas fa-edit text-info" style={{ fontSize: "20px" }} />
        </b>

        <i
          className={"fas fa-truck-moving position-absolute text-info"}
          style={{
            fontSize: "30px",
            fontSize: "9vw",
            opacity: "0.1",
            top: "45%",
            left: "51%",
            transform: "translate(-50% , -50%)",
          }}
        />
        <b
          style={{
            fontSize: "3vw",
            color: "#000000b3",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50% , -50%)",
          }}
        >
          {ShipmentID.Id}
        </b>

        <b style={{ fontSize: "1vw", position: "absolute", bottom: "42px" }}>
          Pallets : {ListOnly.length}
        </b>
        <b
          className="position-absolute text-info"
          style={{ fontSize: "3vw", right: "20px", bottom: "33px" }}
        >
          {percentage(ListOnly.length, ShipmentID?.EquipmentSize)}%
        </b>
        <div
          className="progress position-absolute"
          style={{
            bottom: "25px",
            width: "79%",
            marginLeft: "-1px",
          }}
        >
          <div
            className="progress-bar progress-bar-striped progress-bar-animated bg-info"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{
              width:
                percentage(ListOnly.length, ShipmentID?.EquipmentSize) + "%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

function percentage(partialValue, totalValue) {
  var a = ((100 * partialValue) / EqSizeToPallet(+totalValue)).toFixed(0);
  if (isNaN(a)) return 0;
  return ((100 * partialValue) / EqSizeToPallet(+totalValue)).toFixed(0);
}

function EqSizeToPallet(number) {
  if (number === 53) return 28;
  else if (number === 28) return 24;
  else if (number === 20) return 12;
}

const TableTrFunction = ({
  item,
  DeleteShipmentRow,
  ShipId,
  removeId,
  IsClosed,
  setLoading,
}) => {
  return (
    <TableRow key={item.Id}>
      <TableCell component="th" scope="row">
        {item.Id}
      </TableCell>
      <TableCell>{item.CustomerName}</TableCell>
      <TableCell>{item.Unitcount}</TableCell>
      <TableCell>{item.Weight?.toFixed(2)}</TableCell>
      <TableCell>{item.WeightType}</TableCell>
      <TableCell>{item.Cube?.toFixed(2)}</TableCell>
      <TableCell>{item.CubeType}</TableCell>
      <TableCell>{momentDate(item.Created)}</TableCell>
      <TableCell>{momentDate(item.Shipped)}</TableCell>
      <TableCell>{item.Equipment}</TableCell>
      <TableCell>{item.status}</TableCell>
      <TableCell>
        <input
          value="Delete"
          disabled={IsClosed}
          type="button"
          className={
            "btn text-white font-weight-bolder font-size-sm w-100 p-1 btn-" +
            (!IsClosed ? "danger" : "dark")
          }
          onClick={() => {
            setLoading(true);
            DeleteShipmentRow(item.Id, ShipId).then((x) => {
              if (x) {
                removeId(item.Id);
                setLoading(false);
              }
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

async function GetShipmentId(id) {
  return await fetch(
    `${window.$apiurl}/Pallets/GetPalletsByShipment?id=${id}&isItems=false`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res;
    })
    .catch((error) => {
      Notification("error", "Shipment ID : " + id + " Not Found");
      console.error(error);
      return undefined;
    });
}

function Nodatafound({ PalletID = 0 }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 mt-5 pt-5 bg-light border rounded notItems">
          <div className="container-fluid row pt-5">
            <div className="col-lg-1" />
            <div className="col-lg-3 text-right">
              <i
                className="fas fa-truck"
                style={{ fontSize: "70px", opacity: 0.3 }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <p className="h1 mt-5" style={{ opacity: 0.3 }}>
                Pallets Not Found
              </p>
            </div>
            <div className="col-lg-2" />
            <div className="col-lg-12 my-5" />
          </div>
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

async function PosttoClosed(PalletsIDStat, setIsClosed, setLoading) {
  setLoading(true);
  return await fetch(
    `${window.$apiurl}/transload/closeshipment/${PalletsIDStat}`,
    {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  ).then(async (response) => {
    const statusCode = response.status;
    setLoading(false);
    if (statusCode === 200) {
      Notification("success", "success");
      setIsClosed((x) => !x);
    } else {
      const data = await response.json();
      Notification("error", data.Message);
    }
  });
}
