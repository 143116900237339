export default [
  {
    id: 1,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"LTL"
  },
  {
    id: 2,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"TL"
  },
  {
    id: 3,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"Intermodal"
  },
  {
    id: 4,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"LTL"
  },
  {
    id: 4,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"LTL"
  },
  {
    id: 4,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"LTL"
  },
  {
    id: 4,
    detail:"",
    So: "123456789",
    Customer: "Lulu's",
    PU: "Auburn WA",
    Del: "Dallas Tx",
    Mode:"LTL"
  },
];
