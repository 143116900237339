import { Line, Chart } from "react-chartjs-2";
import React, { useState } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import moment from "moment";

Chart.register(zoomPlugin); // REGISTER PLUGIN
const Colors = [
  "#8950FC",
  "#95371d",
  "#4ca1af",
  "#b3c43c",
  "#fe8a71",
  "#5a3662",
  "#3c78ae",
  "#a2738c",
  "#869890",
  "#ec9f0a",
];

const Chart2 = ({ props, isReporting }) => {
  console.log("props", props);
  const [randomNumber, setrandomNumber] = useState(1);

  var data = [];
  if (props.length > 0) {
    var objects = [];
    Object.keys(props[0]).map((a, i) => {
      if (i !== 0) {
        objects.push(a);
      }
    });
    data = {
      labels: props?.map((a) => {
        return moment(a[Object.keys(props[0])[0]]).format("ddd, MMM DD");
      }),
      datasets: objects.map((b, i) => ({
        type: "bar",
        label: String(b),
        backgroundColor:
          b === "Dispatched"
            ? "#8950FC"
            : b === "Picked Up"
            ? "#95371d"
            : b === "Load Received"
            ? "#4ca1af"
            : b === "QC"
            ? "#b3c43c"
            : b === "Ready to process"
            ? "#fe8a71"
            : b === "Processed"
            ? "#5a3662"
            : b === "Palletized"
            ? "#3c78ae"
            : b === "Lulu's Chico DC"
            ? "#a2738c"
            : b === "Lulu'S Pennsylvania DC"
            ? "#869890"
            : b === "OnHand"
            ? "#FFA800"
            : Colors[i],
        hidden:
          window.reportingGraph[String(b)] !== undefined
            ? window.reportingGraph[String(b)]
            : false,
        borderWidth: 2,
        fill: false,
        data: props?.map((a) => a[String(b)]),
      })),
    };
  }

  const options = {
    plugins: {
      legend: {
        onClick: function(evt, item) {
          Object.keys(props[0]).map((a) => {
            if (item.text === a) {
              window.reportingGraph[a] = !item.hidden;
            }
          });
          setrandomNumber(Math.random());
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              autoSkip: true,
              maxTicksLimit: 10,
              beginAtZero: true,
            },
            gridLines: {
              display: false,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: isReporting === true ? true : false, // SET SCROOL ZOOM TO TRUE
          },
          mode: "x",
          speed: 100,
        },
        pan: {
          enabled: true,
          mode: "x",
          speed: 100,
        },
      },
    },
  };

  return (
    <>
      {props.length > 0 ? (
        <Line
          key={randomNumber}
          type="bar"
          data={data}
          options={options}
          height={isReporting === true ? 70 : 140}
        />
      ) : (
        <h4 className="mt-3" style={{ textAlign: "center" }}>
          There is no data available at the moment
        </h4>
      )}
    </>
  );
};

export default Chart2;
