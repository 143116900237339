import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default async function mockClassifications(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetClassifications() {
    return await fetch(`${window.$apiurl}/classifications`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetClassification(Id) {
    return await fetch(`${window.$apiurl}/classifications/${Id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetAllClassifications() {
    return await fetch(`${window.$apiurl}/classifications/all`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PostClassification(someData) {
    return fetch(`${window.$apiurl}/classifications`, {
      method: "post",
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PutClassification(someData, id) {
    let userDate = someData["classification"];
    return fetch(`${window.$apiurl}/classifications/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userDate),
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteClassification(id, deleteChildren) {
    return await fetch(
      `${window.$apiurl}/classifications/${id}?deleteChildren=${deleteChildren}`,
      {
        method: "delete",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/classifications").reply(async () => {
    const obj = {};
    obj["AllClassifications"] = await GetAllClassifications();
    obj["Classifications"] = await GetClassifications();
    return [200, obj];
  });

  mock.onPost("api/classifications").reply(async ({ data }) => {
    const { classification } = JSON.parse(data);
    const { Title = "", Description = "", ParentId } = classification;
    const newClassification = {
      Title,
      ParentId,
      Description,
    };
    await PostClassification(newClassification);
    return [200, { classification: newClassification }];
  });

  mock.onGet(/api\/classifications\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/classifications\/(\d+)/)[1];

    var getClassification = await GetClassification(id);
    if (!getClassification) {
      return [400];
    }
    return [200, getClassification];
  });

  mock.onPut(/api\/classifications\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/classifications\/(\d+)/)[1];
    const Putclassification = JSON.parse(config.data);
    var putClassification = await PutClassification(Putclassification, id);
    if (!putClassification) {
      return false;
    }
    return [200];
  });

  mock.onPost(/api\/classifications\/\d+/).reply(async (config) => {
    const { classification } = JSON.parse(config.data);
    var deleteClassification = await DeleteClassification(
      classification.Id,
      classification.DeleteChildren
    );
    if (!deleteClassification) return [400];
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
