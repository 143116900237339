import MockUtils from "./mock.utils";
let customerTableMock;
export default function mockCustomLabels(mock) {

  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  //Get Custom Label
  async function GetCustomLabels() {
    return await fetch(window.$apiurl+"/customlabels")
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //Put Custom Label
  async function PutCustomLabel(someData) {
    return fetch(window.$apiurl+"/customlabels", {
      method: "put",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        console.log(response)
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  
  mock.onPost("api/CustomLabels").reply(async({ data }) => {
    const { customer } = JSON.parse(data);
    const {
        Name = "",
        Value = ""
    } = customer;

    const Id = generateUserId();
    const newCustomer = {
      Id,
      Name,
      Value
    };
    customerTableMock.push(newCustomer);
    return [200, { customer: newCustomer }];
  });

  mock.onPost("api/CustomLabels/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetCustomLabels();
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/CustomLabels/deleteCustomers").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = customerTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/CustomLabels/updateStatusForCustomers").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/CustomLabels\/\d+/).reply(config => {
    const id = config.url.match(/api\/CustomLabels\/(\d+)/)[1];
    const customer = customerTableMock.find(el => el.id === +id);
    if (!customer) {
      return [400];
    }

    return [200, customer];
  });

  //mock Put
  mock.onPut(/api\/CustomLabels\/\d+/).reply(async config => {
    const id = config.url.match(/api\/CustomLabels\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    var putObj = await PutCustomLabel(customer);
    // console.log(putObj)
    // if (!putObj) {
    //   return [400];
    // }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/CustomLabels\/\d+/).reply(config => {
    const id = config.url.match(/api\/CustomLabels\/(\d+)/)[1];
    const index = customerTableMock.findIndex(el => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}