/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { Link, useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
const adapter = new LocalStorage("db");
const db = low(adapter);

function createMarkup(dataString) {
  return { __html: dataString };
}
export function QuickUser() {
  const history = useHistory();

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  let userDefault = {
    FullName: "",
    Email: "",
    ProfilePicture: "",
  };
  const [userLogin, setuserLogin] = useState(userDefault);
  const [modalReleaseNotes, setModalReleaseNotes] = useState(false);
  const [itemRelease, setItemRelease] = useState(null);


  axios.get("auth/Login").then((res) => {
    if (res.data !== undefined) {
      userDefault = {
        FullName: res.data.FullName,
        Email: res.data.Email,
        ProfilePicture: res.data.ProfilePicture,
      };
      setuserLogin(userDefault);
    }
  });
  function getBuildDate(epoch) {
    const buildDate = moment(epoch).format("MM-DD-YYYY");
    return buildDate;
  };
  const [releaseNotes, setReleaseNotesList] = useState([]);
  useEffect(() => {
    GetReleaseNotesList();
  }, []);
  async function GetReleaseNotesList() {
    return await fetch(window.$apiurl + "/generic/releaseNotes/get", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const list = res[1];
        const cc = list.sort(function(a,b){
          return new Date(b.Created) - new Date(a.Created);
        });
        setReleaseNotesList(list || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas pr-10 py-10 pl-5"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          User Profile
          <small className="text-muted font-size-sm ml-2">12 messages</small>
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-4 align-self-baseline">
            <div
              id="BackroundUserImageProfile"
              className="symbol-label"
              style={{
                backgroundImage:
                  userLogin.ProfilePicture !== null &&
                  userLogin.ProfilePicture !== undefined
                    ? "url('" + userLogin?.ProfilePicture + "')"
                    : `url(${toAbsoluteUrl("/media/users/blank.png")}`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column w-100">
            <Link
              to={`/user/profile`}
              id="FullUserNameProfile"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-info"
            >
              {userLogin.FullName}
            </Link>
            {/* 
            <div className="text-muted mt-1">Application Developer</div> */}
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-info">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span
                    className="navi-text text-muted text-hover-info"
                    id="EmailUserNameProfile"
                  >
                    {userLogin.Email}
                  </span>
                </span>
              </a>
              <button
                className="btn btn-sm btn-light-info font-weight-bolder py-2 px-5"
                onClick={logoutClick}
              >
                Sign out
              </button>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Link className="navi-item" to={`/user/profile`}>
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">
                  Account settings and more{" "}
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
              </div>
            </div>
          </Link>

          {/* <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Shopping/Chart-bar1.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/Selected-file.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a>

          <a href="/user/profile" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Mail-opened.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}
        </div>

        <div className="separator separator-dashed my-7"></div>
        <div>
          <h5 className="mb-5">Recent Notifications</h5>
          {releaseNotes.length > 0 &&
            releaseNotes.map((item) => (
              <div className="row from-group mb-4">
                <div className="col-lg-12">
                  <div className="d-flex align-items-center bg-light-info rounded p-5">
                    <span className="svg-icon svg-icon-info mr-5">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Attachment2.svg"
                        )}
                        className="svg-icon svg-icon-lg"
                      ></SVG>
                    </span>

                    <div className="d-flex flex-column flex-grow-1 mr-2">
                      <a
                        onClick={() => {
                          // try {
                          //   window.open(
                          //     `/ReleaseNotes/${item.BuildVersion}`,
                          //     "_blank"
                          //   );
                          // } catch (err) {}
                          setItemRelease(item);
                          setModalReleaseNotes(true);
                        }}
                        role="button"
                        // target="_blank"
                        // href={`/ReleaseNotes/${window.$BuildVersion}`}
                        className="font-weight-normel text-dark-75 text-hover-primary font-size-lg mb-1"
                      >
                        Release Notes V{item.BuildVersion}
                      </a>
                      <span className="text-muted font-size-sm">
                        Build Date <strong>{getBuildDate(item.Created)}</strong>
                      </span>
                    </div>

                    <span className="font-weight-bolder text-info py-1 font-size-lg">
                      <i class="fas fa-arrow-circle-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            ))}{" "}
          {releaseNotes.length === 0 && (
            <>
              <div className="form-group row">
                <div
                  className="col-lg-12 p-5 mt-md-9"
                  style={{ textAlign: "center" }}
                >
                  <h3 className="text-muted">No Notifications yet</h3>
                </div>
              </div>
            </>
          )}
          <Modal
            show={modalReleaseNotes}
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Release Notes
              </Modal.Title>
              <IconButton
                
                aria-label="Close"
                onClick={() => {
                  setModalReleaseNotes(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <div className="col-lg-12">
                  <Card className="border-0 mb-5">
                    <div
                      className="label label-lg label-light-primary label-inline border-0 w-100 font-weight-bold text-center p-5 my-5 justify-content-start"
                      style={{ fontSize: "15px" }}
                    >
                      Build Version : {itemRelease?.Title} {"   "} | {"   "}{" "}
                      Build Date :{" "}
                      {moment(itemRelease?.Created).format("MM-DD-YYYY")}
                    </div>
                    <div className="p-3">
                      <div
                        dangerouslySetInnerHTML={createMarkup(
                          itemRelease?.Body
                        )}
                      />
                    </div>
                  </Card>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  type="button"
                  onClick={() => {
                    setModalReleaseNotes(false);
                  }}
                  className="btn btn-light btn-elevate mr-2"
                >
                  Close
                </button>
              </div>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
