import React from "react";

export function StatusColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["success", "danger"];
  const CustomerStatusTitles = ["Active", "Inactive"];

  var isActive = row.IsActive === true ? 0 : 1;

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${CustomerStatusCssClasses[isActive]} label-inline mr-3`;
  };
  return (
    <>
      <span className={getLabelCssClasses()}>
        {CustomerStatusTitles[isActive]}
      </span>
      {row.IsOnHold === true ? (
        <span class="label label-lg label-light-danger label-inline">
          is On Hold
        </span>
      ) : (
        ""
      )}
    </>
  );
}
