import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useCustomersUIContext } from "../CustomersUIContext";
import Select from "react-select";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Col, Row } from "react-bootstrap";
const adapter = new LocalStorage("db");
const db = low(adapter);

var typingTimer;
const prepareFilter = (queryParams, values) => {
  const {
    SearchText,
    CustomerId,
    ModeId,
    userAssignee,
    shipDateFilter,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.SearchText = SearchText;
  filter.userAssignee = userAssignee;
  filter.shipDateFilter = shipDateFilter;
  filter.CustomerId = CustomerId;
  filter.ModeId = ModeId;
  // Filter by type

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function CustomersFilter({
  GetDataLoadBoardFunction,
  loadingDataNew,
  loadingDataBooked,
  loadingDataAtShipper,
  LoadBoardsList,
}) {
  const listShipDates = [
    { value: null, label: "All" },
    { value: 1, label: "Today" },
    { value: 2, label: "Tomorrow " },
    { value: 3, label: "This Week" },
  ];
  // Customers UI Context
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          userAssignee: customersUIProps?.queryParams?.filter?.userAssignee,
          shipDateFilter: customersUIProps?.queryParams?.filter?.shipDateFilter,
          CustomerId: customersUIProps?.queryParams?.filter?.CustomerId,
          ModeId: customersUIProps?.queryParams?.filter?.ModeId,
          SearchText: customersUIProps?.queryParams?.filter?.SearchText,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">

              <Col lg="3" md="4">
                <label className="mb-2">Search</label>
                <input
                  type="search"
                  className="form-control"
                  name="SearchText"
                  placeholder="Search..."
                  value={values.SearchText}
                  onChange={(e) => {
                    setFieldValue("SearchText", e.target.value);
                    clearTimeout(typingTimer);
                    typingTimer = setTimeout(function () {
                      handleSubmit();
                    }, 500);
                  }}
                />
              </Col>
              <div className="col-lg-3 col-md-4">
                <label className="mb-2">Customer</label>
                <Select
                  id="CustomerId"
                  isMulti
                  value={values.CustomerId}
                  name="CustomerId"
                  options={LoadBoardsList?.Customers?.map((a) => {
                    return { value: a.Id, label: a.Code + "-" + a.Name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    if (e !== null) {
                      setFieldValue("CustomerId", e);
                    } else {
                      setFieldValue("CustomerId", []);
                    }
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4">
                <label className="mb-2">Order Assignment</label>
                <Select
                  id="userAssignee"
                  name="userAssignee"
                  options={[
                    { label: "Logged in user", value: 0 },
                    { label: "All", value: null },
                  ]}
                  value={[
                    { label: "Logged in user", value: 0 },
                    { label: "All", value: null },
                  ].filter((a) => a.value === values.userAssignee)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFieldValue("userAssignee", e.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4">
                <label className="mb-2">Ship Date</label>
                <Select
                  options={
                    listShipDates !== null && listShipDates !== null
                      ? listShipDates
                      : []
                  }
                  value={
                    values?.shipDateFilter === null ||
                      values?.shipDateFilter === undefined
                      ? { value: null, label: "All" }
                      : {
                        value: values?.shipDateFilter,
                        label: listShipDates?.find(
                          (x) => x.value === values?.shipDateFilter
                        )?.label,
                      }
                  }
                  name="shipDateFilter"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    setFieldValue("shipDateFilter", e?.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4">
                <label className="mb-2">Mode</label>
                <Select
                  id="ModeId"
                  isMulti
                  value={values.ModeId}
                  name="ModeId"
                  options={LoadBoardsList?.Modes?.map((a) => {
                    return { value: a.Id, label: a.Name };
                  })}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => {
                    if (e !== null) {
                      setFieldValue("ModeId", e);
                    } else {
                      setFieldValue("ModeId", []);
                    }
                    handleSubmit();
                  }}
                />
              </div>

            </div>

          </form>
        )}
      </Formik>
    </>
  );
}
