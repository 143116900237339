import {
  Button,
  Icon,
  IconButton,
  makeStyles,
  Popover,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Modal } from "react-bootstrap";
import {
  currencyFormat,
  momentDate,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import { EnhancedTable } from "../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
}));

export const ModelManagment = ({ row, reloadFunction }) => {
  const [Show, setShow] = useState(false);

  function SimplePopover() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);

    const [StatusShow, setStatusShow] = useState(false);
    function handleClick(event) {
      setStatusShow(window.Status !== "");
      setAnchorEl(event.currentTarget);
    }

    function handleClose() {
      setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    async function UpdateStatus(next, id) {
      return await fetch(`${window.$apiurl}/pallets/UpdateStatus`, {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Id: id,
          IsRollBack: !next,
          IsNext: next,
        }),
      }).then(async (response) => {
        const statusCode = response.status;
        if (statusCode === 200) {
          reloadFunction();
          Notification("success", "success");
        } else {
          const data = await response.json();
          Notification("error", data.Message);
        }
      });
    }

    return (
      <div>
        <div
          className="text-warning font-weight-bold w-100 p-5"
          onClick={handleClick}
          style={{ cursor: "pointer" }}
        >
          <i className="fas fa-cogs text-warning h1-md"></i>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Typography className={classes.typography}>
            <div
              className="textt-warning font-weight-bold w-100 p-2"
              onClick={() => setShow(true)}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-file-alt text-success mr-2"></i>
              Detail
            </div>
            {StatusShow && (
              <>
                <div
                  className="textt-warning font-weight-bold w-100 p-2 BackStatusId"
                  onClick={() => UpdateStatus(true, row?.id)}
                  style={{ cursor: "pointer" }}
                >
                  <i class="fas fa-arrow-circle-right text-info mr-2"></i>
                  Next Status
                </div>
                <div
                  className="textt-warning font-weight-bold w-100 p-2 BackStatusId"
                  onClick={() => UpdateStatus(false, row?.id)}
                  style={{ cursor: "pointer" }}
                >
                  <i class="fas fa-arrow-circle-left text-warning mr-2"></i>
                  Back Status
                </div>
              </>
            )}
          </Typography>
        </Popover>
      </div>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={Show}
        onHide={() => {
          setShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={row?.id} setShow={setShow.bind()} />
      </Modal>
      <SimplePopover />
    </>
  );
};

function IsValid(name, str) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row w-100 my-2 ml-3">
        <div className="col-lg-4 col-md-6 font-weight-bold text-dark text-left pl-5">
          {name}
        </div>
        <div className="col-lg-8 col-md-6 text-primary">
          : {str || <span className="text-muted">Empty</span>}
        </div>
      </div>
    </>
  );
}

export function DetailMangment({ Id, setShow }) {
  const [Detail, setDetail] = useState();

  useEffect(() => {
    GetModel();
  }, []);
  async function GetModel() {
    return await fetch(`${window.$apiurl}/pallets/` + Id, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        var a = res[1];
        var obj = a?.pallet ?? {};
        (obj.ItemsList ?? []).forEach((x) => {
          x.Weight = currencyFormat(x.Weight);
          x.Cube = currencyFormat(x.Cube);
        });
        setDetail(obj);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Modal.Header>
        <div className="row w-100">
          <div className="col-lg-6 col-md-6">{IsValid("Pallet ID", Detail?.Id)}</div>
        </div>
        <IconButton
          
          aria-label="Close"
          onClick={() => {
            setShow(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </Modal.Header>

      <Modal.Body>
        <Accordion defaultActiveKey="44">
          <>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey={"44"}
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">Pallet Basic</h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"44"}>
              <div className="form-group row word-break">
                <div className="col-lg-6">
                  {IsValid(
                    "Customer",
                    Detail?.CustomerCode + " - " + Detail?.CustomerName
                  )}
                  {IsValid("Shipment Id", Detail?.TransloadShipmentId)}
                  {IsValid("Equipment", Detail?.Equipment)}
                </div>
                <div className="col-lg-6">
                  {IsValid("Shipper Name", Detail?.ShipperName)}
                  {IsValid("Consignee Name", Detail?.ConsigneeName)}{" "}
                  {IsValid("Unit Count", Detail?.Unitcount)}
                </div>{" "}
                <div className="col-lg-6 mt-5">
                  {IsValid("Weight", Detail?.Weight?.toFixed(2))}
                  {IsValid("Weight Type", Detail?.WeightType)}{" "}
                  {IsValid("Cube", Detail?.Cube?.toFixed(2))}
                  {IsValid("Cube Type", Detail?.CubeType)}
                </div>
                <div className="col-lg-6 mt-5">
                  {IsValid("Created", momentDate(Detail?.Created))}
                  {IsValid("Shipped", momentDate(Detail?.Shipped))}
                  {IsValid("Status", Detail?.Status)}
                </div>
              </div>
            </Accordion.Collapse>
            <Accordion.Toggle
              as={Button}
              className="w-100 text-left p-0 position-relative text-decoration-none"
              variant="link"
              eventKey={"0"}
            >
              <Alert variant="primary " className="pt-3 pl-3 pb-0 w-100">
                <h5 className="font-weight-bold">
                  Line Item Detail : {Detail?.Items}
                </h5>
              </Alert>
              <Icon
                className={clsx(
                  "fa fa-chevron-down position-absolute text-white"
                )}
                style={{ fontSize: 17, right: "1rem", top: "20%" }}
              />
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"0"}>
              <div className="form-group row">
                <div className="col-lg-12 mt-5">
                  <EnhancedTable
                    rowsName={[
                      { Name: "Container", Title: "Cont#" },
                      { Name: "Upc", Title: "Upc" },
                      { Name: "PurchaseOrder", Title: "PO#" },
                      { Name: "Location", Title: "Whs" },
                      { Name: "Sku", Title: "SKU" },
                      { Name: "UnitCount", Title: "Qty" },
                      { Name: "ShortQuantity", Title: "S Qty" },
                      { Name: "Description", Title: "Description" },
                      { Name: "Cube", Title: "Cube" },
                      { Name: "Weight", Title: "Weight" },
                      { Name: "Color", Title: "Color" },
                      { Name: "Size", Title: "Size" },
                    ]}
                    rows={Detail?.ItemsList || []}
                  />
                </div>
              </div>
            </Accordion.Collapse>
          </>
        </Accordion>
      </Modal.Body>

      <Modal.Footer>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          className="btn btn-light btn-elevate w-25"
        >
          Close
        </button>
      </Modal.Footer>
    </>
  );
}
