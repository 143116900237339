import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersTable } from "./customers-table/CustomersTable";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { useCustomersUIContext } from "./CustomersUIContext";
import { CustomerDialogForm } from './customer-import-dialog/CustomerDialogForm';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  InputGroup,
  FormControl,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
}));
export function CustomersCard() {
  const classes = useStyles();
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  console.log("customer UI");
 console.log(customersUIProps);
  return (
    <Card>
      <CardHeader title="Shipping: Handling Billing Wizard">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-success btn-shadow-hover mr-2"
            onClick={customersUIProps.newCustomerButtonClick}
          >
            Add
          </button>

        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <CustomersFilter />
        {customersUIProps.ids.length > 0 && <CustomersGrouping />}
        <CustomersTable />
      </CardBody>
      <CardFooter>
        <p className="font-weight-bold">Handling Level Rate:
        <PopupState variant="popover" popupId="demo-popup-popover">
            {popupState => (
              <span>
                <i className="fas fa-exclamation-triangle mx-2" color="action" {...bindTrigger(popupState)}></i>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography variant="h6" className={classes.typography}>Adding a minimum charge amount</Typography>
                  <Typography variant="subtitle1" className={classes.typography} className="py-1 px-5">Charge Type Min is a script that allows you to add a minimum charge rate to every</Typography>
                  <Typography variant="subtitle1" className={classes.typography} className="py-1 px-5">transaction relevant to the selected billing type. If you select this script, the system </Typography>
                  <Typography variant="subtitle1" className={classes.typography} className="py-1 px-5">will automatically enable this charge even if you don't specify a charge amount.</Typography>
                </Popover>
              </span>
            )}
          </PopupState>
          <small>None Selected</small>
        </p>
        <div>
          <CustomerDialogForm />
        </div>
      </CardFooter>
      <CardFooter>
        <a
          type="button"
          className="btn btn-success btn-shadow-hover mr-2"
          href= {`/TransactionBilling/`+window.customerId} 
           >
          Back
          </a>
      </CardFooter>
    </Card>
  );
}