import React, { useEffect, useState } from "react";
import { Button, Form, Card, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Select from "react-select";
import { Notification } from "../../modules/Notification";
import { ExcelRenderer } from "react-excel-renderer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
import LinearProgress from "@material-ui/core/LinearProgress";
import { matchPath } from "react-router";
import CreatableSelect from "react-select/creatable";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);

function isValidDate(d) {
  return moment(d, "M/D/YYYY", true).isValid();
}

export function ImportOrderItemsComp({ isFromImport }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(3),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
    row: {
      height: 50,
    },
    rowBody: {
      height: 50,
      fontWeight: "bold",
    },
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
  }));
  const classes = useStyles();
  const [rowsState, setRowsState] = useState([]);
  const [rowsStatBool, setRowsStatBool] = useState(false);
  const [ServiceId, setServiceId] = useState("");
  const [ModeId, setModeId] = useState("");
  const [ServiceLevelId, setServiceLevelId] = useState("");
  const [EqTypeId, setEqTypeId] = useState("");
  const [EqSizeId, setEqSizeId] = useState("");
  const [rowsEmpty, setRowsEmpty] = useState(true);
  const [ServiceListOption, setServiceListOption] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [importInProgress, setImportInProgress] = useState(false);
  const [showProgress, setshowProgress] = useState(false);
  const [CustomerIdValue, setCustomerId] = useState({
    value: "",
    label: "Select...",
  });
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchCustomersAsync();
  }, []);
  const fetchCustomersAsync = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`${window.$apiurl}/receiptsmanagement/getviewmodel`, requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          var data = JSON.parse(result);
          setCustomersList(data.Customers);
          setServiceListOption(data.Services);
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };

  const fileHandlerOnclick = () => {
    if (rowsEmpty) {
      Notification("error", "Please select a valid file to import.");
      setRowsStatBool(false);
      return false;
    }
    setRowsStatBool(true);
  };

  const fileClear = (event) => {
    event.target.value = "";
  };

  const fileHandler = (event) => {
    setRowsStatBool(false);
    setRowsState([]);
    setRowsEmpty(false);
    let fileObj = event.target.files[0];
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        setRowsEmpty(true);
        return false;
      } else {
        if (resp.rows.length < 1) {
          setRowsEmpty(true);
          return false;
        }
        var a = resp.rows[0].indexOf("Master Bill");
        var b = resp.rows[0].indexOf("Order Date*");
        var c = resp.rows[0].indexOf("Vessel");
        var d = resp.rows[0].indexOf("Eta");
        var e = resp.rows[0].indexOf("Voyage");
        var f = resp.rows[0].indexOf("Customer Ref No");
        var g = resp.rows[0].indexOf("Customer Ref No2");
        var h = resp.rows[0].indexOf("Equipment*");
        var i = resp.rows[0].indexOf("Seal Number");
        var j = resp.rows[0].indexOf("Freight charge");
        var k = resp.rows[0].indexOf("Pickup location");
        var l = resp.rows[0].indexOf("Ship to location");
        var m = resp.rows[0].indexOf("Purchase Order*");
        var n = resp.rows[0].indexOf("Sku");
        var o = resp.rows[0].indexOf("Case Pack*");
        var p = resp.rows[0].indexOf("UnitOfMeasure*");
        var q = resp.rows[0].indexOf("UnitCount*");
        var r = resp.rows[0].indexOf("Weight*");
        var s = resp.rows[0].indexOf("WeightType*");
        var t = resp.rows[0].indexOf("Cube*");
        var u = resp.rows[0].indexOf("CubeType*");
        var v = resp.rows[0].indexOf("Description");
        var w = resp.rows[0].indexOf("OverQuantity");
        var x = resp.rows[0].indexOf("ShortQuantity");
        var y = resp.rows[0].indexOf("DamagedQuantity");
        var z = resp.rows[0].indexOf("Pallets");
        var aa = resp.rows[0].indexOf("IsHot");
        var ab = resp.rows[0].indexOf("IsHazMat");
        var ac = resp.rows[0].indexOf("Location");
        var ad = resp.rows[0].indexOf("UpcNumber");
        var ae = resp.rows[0].indexOf("Size");
        var af = resp.rows[0].indexOf("Color");
        if (
          a === -1 ||
          b === -1 ||
          c === -1 ||
          d === -1 ||
          e === -1 ||
          f === -1 ||
          g === -1 ||
          h === -1 ||
          i === -1 ||
          j === -1 ||
          k === -1 ||
          l === -1 ||
          m === -1 ||
          n === -1 ||
          o === -1 ||
          p === -1 ||
          q === -1 ||
          r === -1 ||
          s === -1 ||
          t === -1 ||
          u === -1 ||
          v === -1 ||
          w === -1 ||
          x === -1 ||
          y === -1 ||
          z === -1 ||
          aa === -1 ||
          ab === -1 ||
          ac === -1 ||
          ad === -1 ||
          ae === -1 ||
          af === -1
        ) {
          setRowsEmpty(true);
          return false;
        }

        let rows = [];
        resp.rows.slice(1, resp.rows.length).forEach((element, index) => {
          var empty = 1;
          var error = "";
          if (
            element[b] === undefined ||
            element[h] === undefined ||
            element[m] === undefined ||
            element[o] === undefined ||
            element[p] === undefined ||
            element[q] === undefined ||
            element[r] === undefined ||
            element[s] === undefined ||
            element[t] === undefined ||
            element[u] === undefined
          ) {
            empty = 0;
            error = "Please check the required fields*.";
          }

          if (TryParseInt(element[o]) < 0) {
            empty = 0;
            error = "Case pack must be a valid number";
          }
          if (TryParseInt(element[q]) < 0) {
            empty = 0;
            error = "Unit count must be a valid number";
          }
          if (TryParseFloat(element[p]) < 0) {
            empty = 0;
            error = "Unit Of Measure must be a valid number";
          }
          if (!isValidDate(element[b])) {
            empty = 0;
            error = "Order Date must be a valid Date";
          }
          if (TryParseFloat(element[r]) < 0) {
            empty = 0;
            error = "Weight must be a valid number";
          }
          if (element[b] === "" || element[b] === undefined) {
            empty = 0;
            error = "Order Date is required";
          }
          if (element[h] === "" || element[h] === undefined) {
            empty = 0;
            error = "Equipment is required";
          }
          if (element[m] === "" || element[m] === undefined) {
            empty = 0;
            error = "Purchase Order is required";
          }
          if (element[n] === "" || element[n] === undefined) {
            empty = 0;
            error = "Case Pack is required";
          }
          if (element[q] === "" || element[q] === undefined) {
            empty = 0;
            error = "Unit Count is required";
          }
          if (element[r] === "" || element[r] === undefined) {
            empty = 0;
            error = "Weight is required";
          }
          if (element[t] === "" || element[t] === undefined) {
            empty = 0;
            error = "Cube is required";
          }
          if (
            element[w] !== "" &&
            element[w] !== undefined &&
            TryParseInt(element[w]) <= 0
          ) {
            empty = 0;
            error = "Over Quantity must be a valid number";
          }
          if (
            element[x] !== "" &&
            element[x] !== undefined &&
            TryParseInt(element[x]) <= 0
          ) {
            empty = 0;
            error = "Short Quantity must be a valid number";
          }
          if (
            element[y] !== "" &&
            element[y] !== undefined &&
            TryParseInt(element[y]) <= 0
          ) {
            empty = 0;
            error = "Damaged Quantity must be a valid number";
          }
          if (
            element[z] !== "" &&
            element[z] !== undefined &&
            TryParseInt(element[z]) <= 0
          ) {
            empty = 0;
            error = "Pallets must be a valid number";
          }
          if (element[a] !== undefined)
            rows.push(
              createData(
                empty,
                index + 1,
                element[a],
                element[b],
                element[c],
                element[d],
                element[e],
                element[f],
                element[g],
                element[h],
                element[i],
                element[j],
                element[k],
                element[l],
                element[m],
                element[n],
                element[o],
                element[p],
                element[q],
                element[r],
                element[s],
                element[t],
                element[u],
                element[v],
                element[w],
                element[x],
                element[y],
                element[z],
                element[aa],
                element[ab],
                element[ac],
                element[ad],
                element[ae],
                element[af],
                error
              )
            );
        });
        setRowsState(rows.reverse());
        setRowsEmpty(false);
      }
    });
  };

  const fileUploadeOnclick = async () => {
    if (CustomerIdValue?.value === undefined || CustomerIdValue?.value === "") {
      Notification("error", "Customer Is required");
      return;
    }
    if (ServiceId?.value === undefined || ServiceId?.value === "") {
      Notification("error", "Service Is required");
      return;
    }
    if (ModeId?.value === undefined || ModeId?.value === "") {
      Notification("error", "Mode Is required");
      return;
    }
    setImportInProgress(true);
    setLoading(true);
    if (rowsStatBool) {
      if (rowsState.length > 0) {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("X-FP-API-KEY", "iphone");
        var requestOptions = {
          method: "post",
          withCredentials: true,
          headers: myHeaders,
          body: JSON.stringify({
            CustomerId: CustomerIdValue?.value,
            ServiceId: ServiceId?.value,
            ModeId: ModeId?.value,
            ServiceLevelId:
              ServiceLevelId?.value === undefined ? "" : ServiceLevelId?.value,
            EquipmentTypeId:
              EqTypeId?.value === undefined ? "" : EqTypeId?.value,
            EquipmentSizeId:
              EqSizeId?.value === undefined ? "" : EqSizeId?.value,
            Items: rowsState,
          }),
        };
        fetch(`${window.$apiurl}/orders/import`, requestOptions)
          .then(async (response) => {
            const statusCode = response.status;
            let data = {};
            if (statusCode === 200) {
              Notification("success", "Import done successfully.");
            } else {
              data = await response.json();
              const msg =
                data?.Message === undefined
                  ? "Something went wrong, please try later or contact the support."
                  : data.Message;
              Notification("error", msg);
            }
          })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Notification("error", "Please select a valid file to emport.");
      }
    } else {
      Notification("error", "Please select a valid file to import.");
    }

    setLoading(false);
    setImportInProgress(false);
  };

  function createData(
    Empty,
    Index,
    MasterBill,
    OrderDate,
    Vessel,
    Eta,
    Voyage,
    CustomerRefNo,
    CustomerRefNo2,
    Equipment,
    SealNumber,
    FreightCharge,
    PickupLocation,
    ShiptoLocation,
    PurchaseOrder,
    Sku,
    Pieces,
    UnitOfMeasure,
    UnitCount,
    Weight,
    WeightType,
    Cube,
    CubeType,
    Description,
    OverQuantity,
    ShortQuantity,
    DamagedQuantity,
    Pallets,
    IsHot,
    IsHazMat,
    Location,
    UpcNumber,
    Size,
    Color,
    Error
  ) {
    return {
      Empty,
      Index,
      MasterBill,
      OrderDate,
      Vessel,
      Eta,
      Voyage,
      CustomerRefNo,
      CustomerRefNo2,
      Equipment,
      SealNumber,
      FreightCharge,
      PickupLocation,
      ShiptoLocation,
      PurchaseOrder,
      Sku,
      Pieces,
      UnitOfMeasure,
      UnitCount,
      Weight,
      WeightType,
      Cube,
      CubeType,
      Description,
      OverQuantity,
      ShortQuantity,
      DamagedQuantity,
      Pallets,
      IsHot,
      IsHazMat,
      Location,
      UpcNumber,
      Size,
      Color,
      Error,
    };
  }

  function TryParseInt(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }

  function TryParseFloat(str) {
    var retValue = 0;
    if (typeof str === "number") return str;
    if (str !== null && str !== undefined) {
      if (str.length > 0) {
        if (!isNaN(str)) {
          retValue = parseFloat(str);
        }
      }
    }
    return retValue;
  }
  return (
    <Form>
      <Form.Row>
        <div className="col-4">
          <Form.Label>Choose a Customer</Form.Label>
          <Select
            isClearable
            value={CustomerIdValue}
            onChange={(e) => {
              if (e === null) {
                setCustomerId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setCustomerId(e);
              }
            }}
            options={customersList.map((x) => {
              return { value: x?.Id, label: x?.Name };
            })}
            name="CustomerId"
            id="CustomerId"
            className="w-100 mr-2"
          />
        </div>
        <div className="col-4">
          <p className="mb-2">Service</p>
          <Select
            isClearable
            value={ServiceId}
            onChange={(e) => {
              setModeId({
                value: "",
                label: "Select...",
              });
              setServiceLevelId({
                value: "",
                label: "Select...",
              });
              setEqSizeId({
                value: "",
                label: "Select...",
              });
              setEqTypeId({
                value: "",
                label: "Select...",
              });
              if (e === null) {
                setServiceId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setServiceId(e);
              }
            }}
            options={ServiceListOption.map((a) => ({
              value: a.Id,
              label: a.Name,
            }))}
            name="ReceiptId"
            id="ReceiptId"
            className="w-100 mr-2"
          />
          {showProgress && <LinearProgress />}
        </div>
        <div className="col-4">
          <p className="mb-2">Mode</p>
          <Select
            isClearable
            value={ModeId}
            onChange={(e) => {
              setServiceLevelId({
                value: "",
                label: "Select...",
              });
              setEqSizeId({
                value: "",
                label: "Select...",
              });
              setEqTypeId({
                value: "",
                label: "Select...",
              });
              if (e === null) {
                setModeId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setModeId(e);
              }
            }}
            options={ServiceListOption.filter(
              (a) => String(a.Id) === String(ServiceId?.value)
            )[0]?.Modes?.map((a) => ({ value: a.Id, label: a.Name }))}
            name="ModeId"
            id="ModeId"
            className="w-100 mr-2"
          />
          {showProgress && <LinearProgress />}
        </div>
        <div className="col-4 mt-5">
          <p className="mb-2">Service Level</p>
          <Select
            isClearable
            value={ServiceLevelId}
            onChange={(e) => {
              setEqSizeId({
                value: "",
                label: "Select...",
              });
              setEqTypeId({
                value: "",
                label: "Select...",
              });
              if (e === null) {
                setServiceLevelId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setServiceLevelId(e);
              }
            }}
            options={ServiceListOption.filter(
              (a) => String(a.Id) === String(ServiceId?.value)
            )[0]
              ?.Modes?.filter((a) => String(a.Id) === String(ModeId?.value))[0]
              ?.ServiceLevels?.map((a) => ({ value: a.Id, label: a.Name }))}
            name="ServiceLevelId"
            id="ServiceLevelId"
            className="w-100 mr-2"
          />
          {showProgress && <LinearProgress />}
        </div>
        <div className="col-4 mt-5">
          <p className="mb-2">Eq Type</p>
          <Select
            isClearable
            value={EqTypeId}
            onChange={(e) => {
              setEqSizeId({
                value: "",
                label: "Select...",
              });
              if (e === null) {
                setEqTypeId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setEqTypeId(e);
              }
            }}
            options={ServiceListOption.filter(
              (a) => String(a.Id) === String(ServiceId?.value)
            )[0]
              ?.Modes?.filter((a) => String(a.Id) === String(ModeId?.value))[0]
              ?.ServiceLevels?.filter(
                (a) => String(a.Id) === String(ServiceLevelId?.value)
              )[0]
              ?.EquipmentTypes?.map((a) => ({
                value: a.Id,
                label: a.Name,
              }))}
            name="EqTypeId"
            id="EqTypeId"
            className="w-100 mr-2"
          />
          {showProgress && <LinearProgress />}
        </div>
        <div className="col-4 mt-5">
          <p className="mb-2">Eq Size</p>
          <Select
            isClearable
            value={EqSizeId}
            onChange={(e) => {
              if (e === null) {
                setEqSizeId({
                  value: "",
                  label: "Select...",
                });
              } else {
                setEqSizeId(e);
              }
            }}
            options={ServiceListOption.filter(
              (a) => String(a.Id) === String(ServiceId?.value)
            )[0]
              ?.Modes?.filter((a) => String(a.Id) === String(ModeId?.value))[0]
              ?.ServiceLevels?.filter(
                (a) => String(a.Id) === String(ServiceLevelId?.value)
              )[0]
              ?.EquipmentTypes?.filter(
                (a) => String(a.Id) === String(EqTypeId?.value)
              )[0]
              ?.EquipmentSizes?.map((a) => ({
                value: a.Id,
                label: a.Name,
              }))}
            name="EqSizeId"
            id="EqSizeId"
            className="w-100 mr-2"
          />
          {showProgress && <LinearProgress />}
        </div>
        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label className="mt-5">Import </Form.Label>
          <Card className="pt-2 pb-1 pl-5 w-100 bg-secondary">
            <Form.Control
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={fileHandler.bind(this)}
              onClick={fileClear.bind(this)}
            />
          </Card>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label className="text-dark font-weight-bold">
            IMPORT FILE
          </Form.Label>
          <div className="MuiPaper-root font-size-lg mt-2">
            <p>
              Use the 'Browse...' button to select an Orders to import file.{" "}
              <a
                href="https://orders.empowerdatalogistics.com/Uploads/ImportTemplates/Import Orders Template.xlsx"
                download
                target="_blank"
              >
                Download Template
              </a>
            </p>
          </div>
          <div className="MuiPaper-root font-size-lg mt-2">
            <p>Then press the 'Import' button to read the file.</p>
          </div>

          <Button
            as="input"
            type="submit"
            color="secondary"
            readOnly
            value="Import"
            className="btn btn-primary btn-shadow-hover mr-2"
            onClick={() => {
              fileHandlerOnclick();
            }}
          />
        </Form.Group>
      </Form.Row>
      {rowsStatBool && (
        <Form.Row className="mt-5 mb-5 pb-5">
          <Paper className={classes.paper}>
            <Table className={classes.table} size="small">
              <TableHead className="bg-dark">
                <TableRow className={classes.row}>
                  <TableCell className="text-white">
                    <b>Is Valid</b>
                  </TableCell>
                  {/* <TableCell className="text-white">
                  <b>Line</b>
                </TableCell> */}
                  <TableCell className="text-white">
                    <b>Master Bill</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Order Date*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Vessel</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Eta</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Voyage</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Customer Ref No</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Customer Ref No2</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Equipment</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Seal Number</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Freight charge</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Pickup location</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Ship to location</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Purchase Order*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Sku</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Case Pack*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Unit Of Measure*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Unit Count*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Weight*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Weight Type*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Cube*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Cube Type*</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Description</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Ovr Qty</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Short Qty</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Damaged Qty</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Pallets</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Is Hot</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Is HazMat</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Location</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Upc Number</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Size</b>
                  </TableCell>
                  <TableCell className="text-white">
                    <b>Color</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsState.map((row) => (
                  <TableRow className={classes.rowBody} key={row.Index}>
                    <TableCell>
                      {row.Empty === 1 && (
                        <i
                          data-toggle="tooltip"
                          title="Valid"
                          className="fa fa-check text-success icon-xxl"
                        ></i>
                      )}
                      {row.Empty === 0 && (
                        <i
                          data-toggle="tooltip"
                          title={row.Error}
                          className="fa fa-times-circle text-danger icon-xxl"
                        ></i>
                      )}
                    </TableCell>
                    {/* <TableCell>{row.Index}</TableCell> */}
                    <TableCell>{row.MasterBill}</TableCell>
                    <TableCell>{row.OrderDate}</TableCell>
                    <TableCell>{row.Vessel}</TableCell>
                    <TableCell>{row.Eta}</TableCell>
                    <TableCell>{row.Voyage}</TableCell>
                    <TableCell>{row.CustomerRefNo}</TableCell>
                    <TableCell>{row.CustomerRefNo2}</TableCell>
                    <TableCell>{row.Equipment}</TableCell>
                    <TableCell>{row.SealNumber}</TableCell>
                    <TableCell>{row.FreightCharge}</TableCell>
                    <TableCell>{row.PickupLocation}</TableCell>
                    <TableCell>{row.ShiptoLocation}</TableCell>
                    <TableCell>{row.PurchaseOrder}</TableCell>
                    <TableCell>{row.Sku}</TableCell>
                    <TableCell>{row.Pieces}</TableCell>
                    <TableCell>{row.UnitOfMeasure}</TableCell>
                    <TableCell>{row.UnitCount}</TableCell>
                    <TableCell>{row.Weight}</TableCell>
                    <TableCell>{row.WeightType}</TableCell>
                    <TableCell>{row.Cube}</TableCell>
                    <TableCell>{row.CubeType}</TableCell>
                    <TableCell>{row.Description}</TableCell>
                    <TableCell>{row.OverQuantity}</TableCell>
                    <TableCell>{row.ShortQuantity}</TableCell>
                    <TableCell>{row.DamagedQuantity}</TableCell>
                    <TableCell>{row.Pallets}</TableCell>
                    <TableCell>{row.IsHot}</TableCell>
                    <TableCell>{row.IsHazMat}</TableCell>
                    <TableCell>{row.Location}</TableCell>
                    <TableCell>{row.UpcNumber}</TableCell>
                    <TableCell>{row.Size}</TableCell>
                    <TableCell>{row.Color}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
          <div className="text-center col-12">
            {" "}
            <b>
              {rowsState.length > 0
                ? rowsState.length + " Items(s)"
                : "No item was imported"}
              .
            </b>
          </div>
        </Form.Row>
      )}{" "}
      <Form.Row>
        <Form.Group as={Col} controlId="formGridPassword">
          <Form.Label className="text-dark font-weight-bold">Create</Form.Label>
          <div className="MuiPaper-root font-size-lg mt-2">
            <p>
              Use the 'Browse...' button to select an Orders to import file.
            </p>
          </div>
          <div className="MuiPaper-root font-size-lg mt-2">
            <p>Press the 'Create' button to create the Orders.</p>
          </div>

          <button
            type="button"
            style={{ width: "10.5%" }}
            onClick={() => {
              fileUploadeOnclick();
            }}
            disabled={importInProgress}
            className="btn btn-success btn-shadow-hover mr-2"
          >
            <span>{importInProgress ? "Importing..." : "Create"}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
          {/* <Button
          as="input"
          value={importInProgress ? "Importing..." : "Create"}
          type="submit"
          readOnly
          disabled={importInProgress}
          className="btn btn-success btn-shadow-hover mr-2"
          onClick={() => {
            fileUploadeOnclick();
          }}
        /> */}
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
