import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Formik } from "formik";
import { isEqual } from "lodash";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonToolbar } from "react-bootstrap";
import Select from "react-select";
import { Export } from "../../../../../../../_metronic/_helpers/ATHelpers";
import { useCustomersUIContext } from "../CustomersUIContext";
const adapter = new LocalStorage("db");
const db = low(adapter);

//Export Filter

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
const catchError = [];
const prepareFilter = (queryParams, values) => {
  const {
    ReLoadTable,
    typeId,
    searchText,
    organisationName,
    customer,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.typeId = typeId !== "0" ? typeId : undefined;
  filter.Code = searchText;
  filter.OrganisationName = organisationName;
  filter.customer = customer;
  filter.ReLoadTable = ReLoadTable;
  newQueryParams.filter = filter;
  return newQueryParams;
};
export function CustomersFilter({ listLoading }) {
  // Customers UI Context
  const [QueryFilter, setQueryFilter] = useState("");

  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
    };
  }, [customersUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(customersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, customersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      setQueryFilter(newQueryParams);

      customersUIProps.setQueryParams(newQueryParams);
    }
  };

  const [AddressTypes, setAddressTypes] = useState(catchError);
  const [customers, setCustomers] = useState(catchError);
  useEffect(() => {
    fetchViewModelAsync();
    GetCustomers();
  }, []);

  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      const types = result.data["AddressTypes"] || catchError;
      const list = [];
      types.length > 0 &&
        types.map((item) => {
          list.push({ value: item.Id, label: item.Name });
        });
      setAddressTypes(list || catchError);
      window.AddressTypes = result.data["AddressTypes"] || catchError;
      window.Country = result.data["Countries"] || catchError;
    } catch (e) { }
  };
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  async function fetchMyAPI2(type, entityName) {
    var filter = QueryFilter;
    if (filter) {
      filter.pageSize = 100000;
      const result = await axios.post(`api/ManageAddress/filter`, { "queryParams": filter });
      Export(result.data.entities, type, entityName);
    } else {
      const result = await axios.post(`api/ManageAddress/filter`, { "queryParams": { "filter": { "typeId": "", "ReLoadTable": false }, "sortOrder": "asc", "sortField": "id", "pageNumber": 1, "pageSize": 100000 } });
      Export(result.data.entities, type, entityName);
    }
  }
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/quoting/viewmodel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        const obj = [];
        a?.Customers.length > 0 && a.Customers.map((item) => {
          obj.push({ value: item.Id, label: item.Name });
        })
        setCustomers(obj);
        window.$customersAddress = a.Customers;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Formik
        initialValues={{
          ReLoadTable: "",
          status: "",
          typeId: "0",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <input
              type="button"
              className="d-none"
              id="ReLoadTableId"
              onClick={(e) => {
                if (e.target.value == "0")
                  document.getElementById("ReLoadTableId").value = 1;
                else document.getElementById("ReLoadTableId").value = 0;
                setFieldValue("ReLoadTable", e.target.value);
                handleSubmit();
              }}
            />

            <div className="form-group row">
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-lg-3">
                <Select
                  isClearable
                  name="customer"
                  id="customer"
                  options={customers}
                  onChange={(e) => {
                    if (e !== null) {
                      setFieldValue("customer", e.value);
                    } else {
                      setFieldValue("customer", "");
                    }
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Customer
                </small>
              </div>
              <div className="col-lg-3">
                <input
                  type="text"
                  className="form-control"
                  name="organisationName"
                  placeholder="Search"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setFieldValue("organisationName", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> by Organisation Name
                </small>
              </div>

              <div className="col-lg-3">
                <Select
                  isClearable
                  name="typeId"
                  id="typeId"
                  options={AddressTypes}
                  onChange={(e) => {
                    if (e !== null) {
                      setFieldValue("typeId", e.value);
                    } else {
                      setFieldValue("typeId", "");
                    }
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Filter</b> by Organization type
                </small>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-12">
                <ButtonToolbar className="float-right btn-text-primary">
                  <Button
                    style={{ height: "38px" }}
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={handleClick}
                  >
                    Export as
                    <Icon className={classes.rightIcon}>save</Icon>
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("excel", "Addresses");
                      }}
                    >
                      <ListItemIcon>
                        <i class="far fa-file-excel"></i>
                      </ListItemIcon>
                      <ListItemText primary="Excel" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("csv", "Addresses");
                      }}
                    >
                      <ListItemIcon>
                        <i class="fas fa-file-csv"></i>
                      </ListItemIcon>
                      <ListItemText primary="CSV" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("json", "Addresses");
                      }}
                    >
                      <ListItemIcon>
                        <i class="far fa-file-code"></i>
                      </ListItemIcon>
                      <ListItemText primary="Json" />
                    </StyledMenuItem>
                  </StyledMenu>
                  {/* <Button variant="primary" type="submit">Search</Button> */}
                </ButtonToolbar>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
