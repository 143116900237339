/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "transparent",
  },
}));
export function PerformanceGlobal({ className }) {
  const uiService = useHtmlClassService();
  const [value, onChange] = useState([new Date(), new Date()]);
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_performance_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Transload Performance Report
        </h3>
        <div className="toolbar mt-5">
          <DateRangePicker onChange={onChange} value={value} />
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-lg-12">
            <div
              id="kt_performance_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "150px" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps) {
  const options = {
    series: [
      {
        name: "ALL Containers ",
        data: [6.67, 36.67, 23.33, 33.33],
      },
      {
        name: "HOT Loads",
        data: [73.33, 26.67],
      },
    ],
    colors: ["#187DE4", "#F64E60"],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    labels: [
      "within 24 Hours",
      "within 48 Hours",
      "within 72 Hours",
      "More Than 72 Hours",
    ],
    yaxis: {
      title: {
        text: "(%)",
      },
      forceNiceScale: false,
      max: 100,
      labels: {
        formatter: (value) => value.toFixed(0) + "%",
      },
    },
    fill: {
      opacity: 1,
    },
  };
  return options;
}
