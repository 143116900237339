import MockUtils from "./mock.utils4";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default async function mockUsers(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetUsersData() {
    if (window.DataGetsValue) {
      return false;
    } else {
      return await fetch(window.$apiurl + "/users", {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      })
        .then(async (response) => {
          const statusCode = response.status;
          if (statusCode === 401) {
            try {
              document.getElementById("401Link").click();
            } catch (err) {
              console.log(err);
            }
          }
          const data = await response.json();
          const messageCompany = data;
          if (messageCompany.Message && !window.valueUsersMsg) {
            Notification("error", messageCompany.Message);
            window.valueUsersMsg = true;
          }
          return Promise.all([statusCode, data]);
        })
        .then((res) => {
          return ChangeId(res[1]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  async function PostUser(someData) {
    console.log(JSON.stringify(someData));
    return fetch(window.$apiurl + "/users", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  async function PutUser(someData, id) {
    return fetch(window.$apiurl + "/users/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function DeleteUser(id) {
    return await fetch(window.$apiurl + "/users/" + id, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetCustomers() {
    return await fetch(window.$apiurl + "/users/customers", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetRoles() {
    return await fetch(window.$apiurl + "/users/roles", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetWarehouses() {
    return await fetch(window.$apiurl + "/users/warehouses", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function Getlevels() {
    return await fetch(window.$apiurl + "/users/levels", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetAccessLevels() {
    return await fetch(window.$apiurl + "/users/getviewmodel", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetViewModelWarehouseTrue() {
    return await fetch(window.$apiurl + "/users/getviewmodel/true", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetWarehousesFetch(obj) {
    return await fetch(window.$apiurl + "/warehousesForCustomer", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function GetViewModelWarehouseFalse() {
    return await fetch(window.$apiurl + "/users/getviewmodel/false", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/Users/GetAllApi").reply(async () => {
    const obj = {};
    obj["CustomersList"] = await GetCustomers();
    obj["RolesList"] = await GetRoles();
    obj["WarehousesList"] = await GetWarehouses();
    obj["LevelsList"] = await Getlevels();
    obj["CellCarriersList"] = await GetAccessLevels();
    obj["WarehouseCheckTrue"] = await GetViewModelWarehouseTrue();
    obj["WarehouseCheckFalse"] = await GetViewModelWarehouseFalse();
    return [200, obj];
  });

  mock.onGet("api/Users/fetchWarehouses").reply(async () => {
    const obj = {};
    obj["warehousesListFetch"] = await GetWarehousesFetch(window.$objCustomers);
    return [200, obj];
  });
  mock.onPost("api/Users").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Id = 0,
      Name = "",
      UserName = "",
      Login = "",
      Email = "",
      CellCarrierId = "",
      IsActive=false,
      AccountName = "",
      PhoneNumber = "",
      CellCarrier = "",
      AccessLevelId = "",
      RoleId = "",
      RoleTypeId = "",
      Password = "",
      ConfirmPassword = "",
      Customers = [
        {
          Id: 0,
          Name: "",
        },
      ],
      Warehouses = [
        {
          Id: 1,
          Name: "",
        },
      ],
      IsGeneratedPassword = false,
      DefaultCustomerId = "",
    } = customer;
    const id = generateUserId();
    const newCustomer = {
      Id,
      id,
      Name,
      UserName,
      Login,
      Email,
      CellCarrierId,
      IsActive,
      AccountName,
      PhoneNumber,
      AccessLevelId,
      CellCarrier,
      RoleId,
      RoleTypeId,
      Password,
      ConfirmPassword,
      Customers,
      Warehouses,
      DefaultCustomerId,
      IsGeneratedPassword
    };
    newCustomer.UserName = newCustomer.Email;
    var postUser = await PostUser(newCustomer);
    if (postUser.Code === "+1") {
      window.$CodeSuccess = "+1";
      Notification("success", "User has been added successfully");
      postUser.id = postUser.Id;
      customerTableMock.push(newCustomer);
      return [
        200,
        { customer: newCustomer, message: postUser.Message, success: true },
      ];
    } else {
      if (postUser.Code === "003") {
        Notification("error", postUser.Message);
        return false;
      } else if (postUser.Code === "004") {
        Notification("error", postUser.Message);
        return false;
      } else {
        Notification("error", postUser.Message);
        return false;
      }
    }
  });

  mock.onPost("api/Users/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetUsersData(); 
    if (
      queryParams.filter.AccountName !== "" &&
      queryParams.filter.AccountName !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.AccountName)
            .toLowerCase()
            .includes(String(queryParams.filter.AccountName).toLowerCase())
      );
      console.log("🚀 ~ file: mockUsers.js ~ line 403 ~ mock.onPost ~ customerTableMock", customerTableMock)
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.AccountName;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/Users/deleteCustomers").reply((config) => {
    customerTableMock=undefined;
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/Users/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  async function GetUser(id) {
    return await fetch(window.$apiurl + "/users/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  mock.onGet(/api\/Users\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Users\/(\d+)/)[1];
    const customer = await GetUser(id);
    if (customer.Password === null || customer.ConfirmPassword === null) {
      customer.Password = "";
      customer.ConfirmPassword = "";
    }
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/Users\/\d+/).reply(async (config) => {
    customerTableMock = undefined;
    const id = config.url.match(/api\/Users\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putUserObj = {
      Id: id,
      Name: customer.Name,
      UserName: customer.UserName,
      Login: customer.Login,
      Email: customer.Email,
      CellCarrierId: customer.CellCarrierId,
      IsActive:customer.IsActive,
      AccountName: customer.AccountName,
      PhoneNumber: customer.PhoneNumber,
      AccessLevelId: customer.AccessLevelId,
      CellCarrier: customer.CellCarrier,
      RoleId: customer.RoleId,
      RoleTypeId: customer.RoleTypeId,
      Password: customer.Password,
      ConfirmPassword: customer.ConfirmPassword,
      Customers: customer.Customers,
      Warehouses: customer.Warehouses,
      IsGeneratedPassword : customer.IsGeneratedPassword,
      DefaultCustomerId: customer.DefaultCustomerId,
    };
    const putUser = await PutUser(putUserObj, id);
    if (putUser !== undefined && putUser.Message) {
      Notification("error", putUser.Message);
      return [400];
    }
    Notification("success", "User has been updated successfully");
    document.getElementById("ReLoadTableId").click();
    return [200];
  });

  mock.onDelete(/api\/Users\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/Users\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock=undefined;
    var deleteObj = await DeleteUser(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
