import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { Spinner, Modal } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import DetailMangment from "../../../modules/eMPower/pages/ReceiptsManagementNewVersion/customers-table/Shipment/DetailMangment";
import CloseIcon from "@material-ui/icons/Close";
import TableRow from "@material-ui/core/TableRow";
import { AssignToOutboundHeaderForm } from "../../../modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/AssignToOutboundHeaderForm";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function TableComponent({
  list = [],
  DeleteShipmentRow,
  ShipmentID,
  hidden,
  ReloadPallets,
  IsClosedFunction,
  GetParms,
  isClosed,
  selectedShip,
}) {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      marginTop: theme.spacing(1),
      width: "100%",
      overflowX: "auto",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 650,
    },
  }));
  const classes = useStyles();
  const [Model, setModel] = useState(false);
  const [DetailMangmentShow, setDetailMangmentShow] = useState(false);
  const [ListOnly, setListOnly] = useState([]);

  useEffect(() => {
    setListOnly(list.filter((x) => x.ShipID === ShipmentID?.Id) ?? []);
  }, [ShipmentID?.Id, list]);

  return (
    <div
      className={
        "col-xl-3 col-lg-4 col-sm-6 TableComponent" /*  + ShipmentID.Id */
      }
    >
      <Modal
        size="xl"
        show={DetailMangmentShow}
        onHide={() => {
          setDetailMangmentShow(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment
          isDashboard={false}
          Id={ShipmentID?.POs[0].OrderId}
          setShow={setDetailMangmentShow.bind()}
        />
      </Modal>

      <Modal
        size="xl"
        show={Model}
        onHide={() => {
          setModel(false);
        }}
      >
        <Modal.Header>
          <div className="row modal-title h4 w-100">
            <div className="col-8">
              <b>Location </b>
              <b className=" p-0 px-3 ml-3">{ShipmentID?.Id}</b>
            </div>
            <div className="col-2">
              {/* <input
                type="button"
                class="btn btn-light-danger font-weight-bolder font-size-sm w-100 p-2"
                value="Close Shipment"
              /> */}
            </div>
            <div className="col-2">
              {/* <input
                type="button"
                class="btn btn-light-primary font-weight-bolder font-size-sm w-100 p-2"
                value="Shipment Manifest"
                onClick={(e) => {
                  window
                    .open("/report/Outbound/" + ShipmentID?.Id, "_blank")
                    .focus();
                }}
              /> */}
            </div>
          </div>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModel(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div>
            <b>Items Count : {ListOnly.length}</b>
            {ListOnly.length > 0 ? (
              <Paper className={classes.paper} /* id={ShipmentID + "Id"} */>
                <div className="react-bootstrap-table table-responsive TabelFilter">
                  <Table className={classes.table} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          PO#
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          SKU
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Description
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Location
                        </TableCell>
                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Unit Count
                        </TableCell>

                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Weight
                        </TableCell>

                        <TableCell className="bg-light text-dark pt-5 pb-5">
                          Cube
                        </TableCell>
                        {!isClosed && (
                          <TableCell className="bg-light text-dark pt-5 pb-5">
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ListOnly.map((item, index) => (
                        <TableTrFunction
                          item={item}
                          key={index}
                          DeleteShipmentRow={DeleteShipmentRow}
                          isClosed={isClosed}
                        />
                      ))}
                      <TableRow>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                          <b>Total</b>
                        </TableCell>
                        <TableCell>
                          <b>
                            {ListOnly.reduce((a, b) => +a + +b.UnitCount, 0)}
                          </b>
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        {!isClosed && <TableCell />}
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Paper>
            ) : (
              <Nodatafound PalletID={ShipmentID?.Id} />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <div
        className="shadow-sm p-0 pl-2 pt-5 mb-5 bg-white rounded"
        style={{
          height: "200px",
          cursor: "pointer",
        }}
        onClick={(e) => setModel(true)}
      >
        {/* <b className="position-absolute">
          <i className="fas fa-location-arrow text-info mr-2" />
          {ListOnly.length > 0 && ListOnly[0].Location}
        </b> */}
        <b
          className="position-absolute"
          style={{ right: "26px" }}
          onClick={(e) => {
            e.preventDefault();
            setDetailMangmentShow(true);
            e.stopPropagation();
          }}
        >
          <i
            className="fas fa-info-circle text-info"
            style={{ fontSize: "20px" }}
          />
        </b>
        <i
          className={"fas fa-window-maximize position-absolute text-info"}
          style={{
            fontSize: "30px",
            fontSize: "170px",
            opacity: "0.1",
            top: "40%",
            left: "51%",
            transform: "translate(-50% , -50%)",
          }}
        />
        <b
          style={{
            fontSize: "32px",
            color: "#000000b3",
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50% , -50%)",
            fontWeight: "bold",
          }}
        >
          {ShipmentID.Id}
        </b>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* <b style={{ fontSize: "15px" }}>
          Cartons : 12
        </b> */}
      </div>
    </div>
  );
}

function EqSizeToPallet(number) {
  if (number === 53) return 28;
  else if (number === 28) return 24;
  else if (number === 20) return 12;
}

const TableTrFunction = ({ item, DeleteShipmentRow, isClosed }) => {
  return (
    <TableRow key={item.Id}>
      <TableCell>{item.PurchaseOrder}</TableCell>
      <TableCell>{item.Sku}</TableCell>
      <TableCell>{item.Description}</TableCell>
      <TableCell>{item.Location}</TableCell>
      <TableCell>{item.UnitCount}</TableCell>
      <TableCell>{item.Weight}</TableCell>
      <TableCell>{item.Cube}</TableCell>
      {!isClosed && (
        <TableCell>
          <input
            value="Delete"
            type="button"
            className="btn btn-danger text-white font-weight-bolder font-size-sm w-100 p-1"
            onClick={() => {
              DeleteShipmentRow(item.Id, item.IdShip);
            }}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

function AssignToOutboundHeader({
  ShipmentID,
  ReloadPallets,
  IsClosedFunction,
  GetParms,
}) {
  const [Consignees, setConsignees] = useState([]);
  const [Vendors, setVendors] = useState([]);
  const [Shippers, setShippers] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [Services, setServices] = useState([]);
  const [statSHipmentId, setstatSHipmentId] = useState([]);
  const [statSHipment, setstatSHipment] = useState([]);
  const [LineItems, setLineItems] = useState([]);
  const [FreightCharges, setFreightCharges] = useState([]);
  const [fetchContainersAsync, setfetchContainersAsync] = useState([]);
  const [Warehouse, setWarehouse] = useState([]);
  const [TrackingDevices, setTrackingDevices] = useState([]);

  useEffect(() => {
    /* GetShipmentId(ShipmentID).then((e) => {
      if (e?.length > 0 && e[0] === 200) {
        setConsignees(e[1]?.Consignees ?? []);
        setCustomers(e[1]?.Customers ?? []);
        setVendors(e[1]?.Vendors ?? []);
        setShippers(e[1]?.Shippers ?? []);
        setServices(e[1]?.Services ?? []);
        setTrackingDevices(e[1]?.TrackingDevices ?? []);
        setFreightCharges(e[1]?.FreightCharges ?? []);

        e[1].Shipment.Id = ShipmentID;
        IsClosedFunction(e[1]?.Shipment?.IsClosed);
        setstatSHipment([e[1]?.Shipment] ?? []);

        (e[1]?.Items ?? []).forEach((element) => {
          element.IdShip = element.Id;
          element.Id = element.ItemId;
        });

        var ordersIds = [];
        (e[1]?.Items ?? []).forEach((x) => {
          if (ordersIds.indexOf(x.TransloadOrderId) === -1)
            ordersIds.push(x.TransloadOrderId);
        });

        GetParms({
          ShipmentId: ShipmentID,
          IsClosed: e[1]?.Shipment?.IsClosed,
          ConsigneeId: e[1]?.Shipment?.ConsigneeId,
          CustomerId: e[1]?.Shipment?.CustomerId,
          Items: e[1]?.Items,
          ordersIds: ordersIds,
        });
      }
    }); */
  }, []);

  return (
    <AssignToOutboundHeaderForm
      Consigneesf={Consignees}
      Vendorsf={Vendors}
      Shippersf={Shippers}
      Customersf={Customers}
      Servicesf={Services}
      SHipmentId={ShipmentID}
      SHipment={statSHipment}
      setstatSHipment={setstatSHipment}
      setLineItems={setLineItems}
      LineItems={LineItems}
      TrackingDevicesf={TrackingDevices}
      FreightChargesf={FreightCharges}
      fetchContainersAsync={fetchContainersAsync}
      Warehouse={Warehouse}
      TableShow={false}
      ReloadPallets={ReloadPallets}
    />
  );
}

async function GetShipmentId(id) {
  return await fetch(
    `${window.$apiurl}/TransloadShipment/GetShipmentItems?shipmentId=${id}`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res;
    })
    .catch((error) => {
      Notification("error", "Shipment ID : " + id + " Not Found");
      console.error(error);
      return undefined;
    });
}

function Nodatafound({ PalletID = 0 }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 2000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 mt-5 pt-5 bg-light border rounded notItems">
          <div className="container-fluid row pt-5">
            <div className="col-lg-1" />
            <div className="col-lg-3 text-right">
              <i
                className="fas fa-pallet"
                style={{ fontSize: "70px", opacity: 0.3 }}
              />
            </div>
            <div className="col-lg-6 mt-2">
              <p className="h1 mt-5" style={{ opacity: 0.3 }}>
                Cartons Not Found
              </p>
            </div>
            <div className="col-lg-2" />
            <div className="col-lg-12 my-5" />
          </div>
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}
