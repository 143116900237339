// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  LoginUserName: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Login / User Name is required"),
  Password: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  ConfirmPassword: Yup.string()
    .min(8, "Minimum 8 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
  Phone: Yup.string()
    .min(7, "Minimum 7 Number")
    .max(50, "Maximum 50 Number")
    .required("Phone Number is required"),
  userName: Yup.string().required("Username is required"),
  dateOfBbirth: Yup.mixed()
    .nullable(false)
    .required("Date of Birth is required"),
  ipAddress: Yup.string().required("IP Address is required"),
});

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          saveCustomer(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="border-0 container pt-3">
                    <h5 className="card-title  text-black">Zone Billing SELECT</h5>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-3">
                    <Select name="connection" label="Facility">
                      <option value="1"></option>
                      <option value="2">Facility1</option>
                    </Select>
                  </div>
                  <div className="col-lg-3">
                    {/* ZoneName */}
                    <Field
                      type="text"
                      name="ZoneName"
                      component={Input}
                      placeholder="ZoneName"
                      label="ZoneName"
                    />
                  </div>

                  <div className="col-lg-3">
                    {/* STARTZIP */}
                    <Field
                      type="text"
                      name="ZoneName"
                      component={Input}
                      placeholder="STARTZIP"
                      label="STARTZIP"
                    />
                  </div>
                  
                  <div className="col-lg-3">
                    {/* ENDZIP */}
                    <Field
                      type="text"
                      name="ENDZIP"
                      component={Input}
                      placeholder="ENDZIP"
                      label="ENDZIP"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
