/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { Icon } from "@material-ui/core";
import clsx from "clsx";
import CreatableSelect from "react-select/creatable";
import {
  IconButton,
  Input,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  Accordion,
  Alert,
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import Select from "react-select";
import { sortCaret } from "../../../../../../_metronic/_helpers";
import { CheckboxLargeWB } from "../../../../../../_metronic/_partials/controls";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../Notification";
import { Formik } from "formik";
import {
  ActivityFunction,
  currencyFormat,
  RequiredHtml,
} from "../../../../../../_metronic/_helpers/ATHelpers";
import axios from "axios";
import { ClickFun } from "../../../../../SpecificTabs";
const adapter = new LocalStorage("db");
const db = low(adapter);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
}));

export function ProductMODetail({ history, MO, setMODetail, setMo }) {
  // Tabs
  const [initProduct, setinitProduct] = useState({
    id: undefined,
    So: "",
    MO: "",
    Customer: "",
    PU: "",
    Mode: "",
    Del: "",
  });
  const [ViewModelOptions, setViewModelOptions] = useState(null);
  const [modalAddresses, setmodalAddresses] = useState(false);
  const [Country, setCountry] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [states, setStates] = useState([]);
  const [ModalActivities, setModalActivities] = useState(false);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  const [stateId, setStateId] = React.useState({
    value: "",
    label: "Select",
  });
  //Fetch Dynamic Address
  const [consignee, setConsignee] = useState({
    ConsigneeId: 0,
    ConsigneeAddress: "",
    ConsigneeCity: "",
    ConsigneeState: "",
    ConsigneeZipCode: "",
    ConsigneePhoneNumber: "",
    ConsigneeFax: "",
    ConsigneeContactName: "",
    ConsigneeEmail: "",
  });
  const [shipper, setShipper] = useState({
    ShipperId: 0,
    ShipperAddress: "",
    ShipperCity: "",
    ShipperState: "",
    ShipperZipCode: "",
    ShipperPhoneNumber: "",
    ShipperFax: "",
    ShipperContactName: "",
    ShipperEmail: "",
  });
  async function fetchSelectedAddress(id, isShipper) {
    try {
      const result = await GetShipperAddress(id);
      if (isShipper) {
        let currentAddress = { ...shipper };
        currentAddress = {
          ShipperId: result?.Id,
          ShipperAddress: result?.Address,
          ShipperCity: result?.City,
          ShipperState: result?.State,
          ShipperZipCode: result?.ZipCode,
          ShipperPhoneNumber: result?.PhoneNumber,
          ShipperFax: result?.Fax,
          ShipperContactName: result?.ContactName,
          ShipperEmail: result?.Email,
        };
        setShipper(currentAddress);
      } else {
        let currentAddress = { ...consignee };
        currentAddress = {
          ConsigneeId: result?.Id,
          ConsigneeAddress: result?.Address,
          ConsigneeCity: result?.City,
          ConsigneeState: result?.State,
          ConsigneeZipCode: result?.ZipCode,
          ConsigneePhoneNumber: result?.PhoneNumber,
          ConsigneeFax: result?.Fax,
          ConsigneeContactName: result?.ContactName,
          ConsigneeEmail: result?.Email,
        };
        setConsignee(currentAddress);
      }
    } catch (err) {}
  }
  async function GetShipperAddress(id) {
    return await fetch(window.$apiurl + `/Tms/Address/${id}`, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const result = res[1];
          return result;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
  //******************************************************* */
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.products.actionsLoading,
      productForEdit: state.products.productForEdit,
    }),
    shallowEqual
  );
  function GetViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/GetViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log(result);
        var data = JSON.parse(result);
        setViewModelOptions(data);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetViewModel();
  }, []);

  function fetchProduct(MO) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/MasterOrders/${MO}`, requestOptions)
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then(async (result) => {
        var data = JSON.parse(result);
        await fetchSelectedAddress(data?.ShipperId, true);
        await fetchSelectedAddress(data?.ConsigneeId, false);
        setinitProduct(data);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    fetchProduct(MO);
  }, [MO]);
  const fetchViewModelAsync = async () => {
    try {
      const result = await axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || []);
    } catch (e) {}
  };
  const handleCountryChange = async (event) => {
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          const states = [];
          window.$StatesAddress = result.data;
          result.data.length > 0 &&
            result.data.map((item) => {
              states.push({ value: item.Id, label: item.Name });
              return null;
            });
          setStates(states);
        } else {
          Notification("warning", "This Country doesn't have any state.");
        }
      }
    }
  };
  useEffect(() => {
    fetchViewModelAsync();
    GetCustomers();
  }, []);
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/quoting/viewmodel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        const obj = [];
        a?.Customers.length > 0 &&
          a.Customers.map((item) => {
            obj.push({ value: item.Id, label: item.Name });
            return null;
          });
        setCustomers(obj);
        window.$customersAddress = a.Customers;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const saveProduct = (values) => {
    setLoadingPCost(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(values);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/Tms/MasterOrders/${MO}`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          Notification("success", "Master Order has been Updated successfully");
          fetchProduct(MO);
        } else {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Invalid Data");
          }
        }
        setLoadingPCost(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingPCost(false);
      });
  };

  const backToProductsList = () => {
    if (
      String(window.activeTab)
        .toLocaleLowerCase()
        .includes("CapacityBoard".toLocaleLowerCase())
    ) {
      document.getElementById("openCapacityBoardPage2").click();
    } else if (
      String(window.activeTab)
        .toLocaleLowerCase()
        .includes("TrackingBoard".toLocaleLowerCase())
    ) {
      document.getElementById("TrackingBoardGrid").click();
    } else {
      document.getElementById("LoadBoardGrid").click();
    }
  };

  const classes = useStyles();

  const columns = [
    {
      dataField: "Id",
      text: "SO",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row, rowIndex) => {
        return (
          <>
            <Link
              onClick={(e) => {
                ClickFun(e, "/TransportManagementSystems/SoDetail/", row.Id);
              }}
            >
              {row.Id}
            </Link>
          </>
        );
      },
    },
    {
      dataField: "Mode",
      text: "Mode",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Pu",
      text: "Pu Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.FromCity + ", " + row.FromState;
      },
    },
    {
      dataField: "Del",
      text: "Del Location",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row.ToCity + ", " + row.ToState;
      },
    },
    {
      dataField: "Status",
      text: "Current Status",
      sort: true,
      sortCaret: sortCaret,
    },
  ];

  const Services = [{ label: "Domestic Transportation" }].map((Services) => ({
    value: Services.label,
    label: Services.label,
  }));
  const [CreateSO, setCreateSO] = useState();
  const [CreateSOSelectedMode, setCreateSOSelectedMode] = useState(null);
  const [LoadingPost, setLoadingPCost] = useState(false);
  const [IsShipperSelected, setIsShipperSelected] = useState(false);

  const [AddressObjectPost, setAddressObjectPost] = useState({
    Name: "",
    Address: "",
    City: "",
    State: "",
    PostalCode: "",
    CountryId: "US",
    Phone: "",
    Fax: "",
    Note: "",
    AddressTypeId: null,
    IsActive: true,
    Charges: [],
    ContactName: "",
    Email: "",
    CustomerId: null,
  });
  const [itemsTms, setItemsTms] = React.useState([
    {
      UnitCount: 1,
      CubeType: "CBM",
      Cube: 1,
      WeightType: "LBS",
      PurchaseOrder: "",
      UnitValue: "in/lbs",
      IsPost: false,
      IsEditMode: false,
      Pieces: 1,
      UnitOfMeasureId: 4,
      ShipmentId: "",
      WeightMode: "each",
      WeightModeValue: { value: "each", label: "each" },
      Weight: "1",
      Length: "",
      Height: "",
      Width: "",
      FreightClass: "",
      FreightClassValue: { value: "", label: "Select" },
      Nmfc: "",
      IsHazardousMateriel: false,
      StopId: null,
      DeliveryStopId: null,
      StopValue: { value: "", label: "Select" },
      StopValueDelivery: { value: "", label: "Select" },
      UnitOfMeasureValue: { value: 4, label: "Pallet" },
    },
  ]);

  function ItemsTmsComponent(values) {
    return (
      <Accordion defaultActiveKey="0">
        {values.map((item, Index) => (
          <>
            {item?.Items?.length > 0 ? (
              <>
                <Accordion.Toggle
                  as={Button}
                  key={Index}
                  className="w-100 text-left p-0 position-relative text-decoration-none"
                  variant="link"
                  eventKey={Index}
                >
                  <Alert variant="primary " className="pt-3 pl-3 pb-0">
                    <h4>SO: {item.Id}</h4>
                  </Alert>
                  <Icon
                    className={clsx(
                      "fa fa-chevron-down position-absolute text-white"
                    )}
                    style={{ fontSize: 17, right: "1rem", top: "20%" }}
                  />
                </Accordion.Toggle>
                <Accordion.Collapse key={Index} eventKey={Index}>
                  <div>
                    {item.Items.map((el, i) => (
                      <div className="card mt-md-9 bg-light" key={i}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col">
                              <div className="row">
                                <div className="col-lg-6">
                                  <span
                                    class="label label-info label-inline mr-2 mb-3 p-4"
                                    style={{ fontSize: "14px" }}
                                  >
                                    {el?.IsPost
                                      ? "New Item"
                                      : `Item #: ${el?.Id}`}
                                  </span>
                                </div>
                              </div>

                              <div className="p-2">
                                {/* First Row */}
                                <div className="form-group row">
                                  <div className="col-lg-3">
                                    <label>PO#</label>
                                    <input
                                      component={Input}
                                      placeholder="PO#"
                                      className="form-control"
                                      value={el?.PurchaseOrder}
                                      type="text"
                                      name="PurchaseOrder"
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>SKU</label>
                                    <input
                                      component={Input}
                                      placeholder="SKU"
                                      className="form-control"
                                      value={el?.Sku}
                                      type="text"
                                      name="Sku"
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Packaging</label>
                                    <input
                                      component={Input}
                                      placeholder="Packaging"
                                      className="form-control"
                                      type="text"
                                      name="Packaging"
                                      value={el?.Packaging}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Handling Amount</label>
                                    <input
                                      component={Input}
                                      placeholder="Handling Amount"
                                      className="form-control"
                                      type="text"
                                      name="Handling Amount"
                                      value={el?.HandlingAmount}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Pieces</label>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="UnitCount"
                                      component={Input}
                                      placeholder="Unit Count"
                                      value={el?.Quantity}
                                      required={true}
                                      min="0"
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Weight Type</label>

                                    <Select
                                      name="WeightType"
                                      value={[
                                        { label: "LBS", value: "LBS" },
                                        { label: "KGS", value: "KGS" },
                                      ].filter(
                                        (a) => a.value === el.WeightType
                                      )}
                                      options={[
                                        { label: "LBS", value: "LBS" },
                                        { label: "KGS", value: "KGS" },
                                      ]}
                                    />
                                  </div>
                                  <div className="col-lg-3">
                                    <label>Weight</label>
                                    <input
                                      type="number"
                                      name="Weight"
                                      id="WeightId"
                                      className="form-control"
                                      component={Input}
                                      placeholder="Weight"
                                      value={el?.Weight}
                                    />
                                  </div>
                                </div>
                                {/* Second Row */}
                                <div className="form-group row">
                                  <>
                                    <div className="col-lg-2">
                                      <label>NMFC</label>
                                      <input
                                        component={Input}
                                        placeholder="NMFC"
                                        className="form-control"
                                        value={el?.Nmfc}
                                        type="text"
                                        name="Nmfc"
                                      />
                                    </div>
                                    <div className="col-lg-2">
                                      <label>Commodity</label>
                                      <input
                                        component={Input}
                                        placeholder="Commodity"
                                        className="form-control"
                                        value={el?.Commodity}
                                        type="text"
                                        name="Commodity"
                                      />
                                    </div>
                                    <div className="col-lg-2">
                                      <label>
                                        Space Req{" "}
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        type="text"
                                        placeholder="Space Req"
                                        className="form-control"
                                        value={el?.SpaceReq}
                                        required
                                      />
                                    </div>
                                    <div className="col-lg-2">
                                      <label>SKU</label>
                                      <input
                                        component={Input}
                                        placeholder="SKU"
                                        className="form-control"
                                        defaultValue={el?.Sku}
                                        onBlur={(e) => {
                                          try {
                                            let values = [...itemsTms];
                                            values[i].Sku = e.target.value;
                                            values[i].IsEditMode = true;
                                            setItemsTms(values);
                                          } catch (err) {}
                                        }}
                                        type="text"
                                        name="Sku"
                                      />
                                    </div>
                                    <div className="col-lg-2 mt-md-8">
                                      <CheckboxLargeWB
                                        className="font-size-md"
                                        text="Temp Control"
                                        name="TempControl"
                                        id="TempControl"
                                        checked={el?.TempControl}
                                        onChange={(e) => {
                                          try {
                                            let values = [...itemsTms];
                                            values[i].TempControl =
                                              e.target.checked;
                                            values[i].IsEditMode = true;
                                            setItemsTms(values);
                                          } catch (err) {}
                                        }}
                                      />
                                    </div>
                                    {el?.TempControl === true ? (
                                      <>
                                        <div className="col-lg-1">
                                          <label>Temp Min</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="TempMin"
                                            component={Input}
                                            placeholder="Min"
                                            required={true}
                                            value={el?.TempMin}
                                            onChange={(e) => {
                                              try {
                                                let values = [...itemsTms];
                                                values[i].TempMin =
                                                  e.target.value;
                                                values[i].IsEditMode = true;

                                                setItemsTms(values);
                                              } catch (err) {}
                                            }}
                                          />
                                        </div>
                                        <div className="col-lg-1">
                                          <label>Temp Max</label>
                                          <input
                                            type="number"
                                            className="form-control"
                                            name="TempMin"
                                            component={Input}
                                            placeholder="Max"
                                            required={true}
                                            value={el?.TempMax}
                                            onChange={(e) => {
                                              try {
                                                let values = [...itemsTms];
                                                values[i].TempMax =
                                                  e.target.value;
                                                values[i].IsEditMode = true;

                                                setItemsTms(values);
                                              } catch (err) {}
                                            }}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Accordion.Collapse>
              </>
            ) : (
              ""
            )}
          </>
        ))}
      </Accordion>
    );
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initProduct}
      onSubmit={(values) => {
        saveProduct(values);
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        function postAddress() {
          setLoadingPCost(true);

          let value = { ...AddressObjectPost };
          value.AddressTypeId = IsShipperSelected === true ? 11 : 5;
          return fetch(window.$apiurl + "/address", {
            method: "post",
            withCredentials: true,
            headers: {
              Accept: "application/json, text/plain, */*",
              Authorization: `bearer ${db.read().getState().Token}`,
              "X-FP-API-KEY": "iphone",
              "Content-Type": "application/json",
            },

            body: JSON.stringify(value),
          })
            .then(async (response) => {
              const statusCode = await response.status;
              const contentType = response.headers.get("content-type");
              if (
                contentType &&
                contentType.indexOf("application/json") !== -1
              ) {
                return response.json().then((data) => [statusCode, data]);
              } else {
                return response
                  .text()
                  .then((text) => [statusCode, { Message: "" }]);
              }
            })
            .then(async (res) => {
              if (res[0] === 200) {
                Notification("success", "Address has been add successfuly");
                setLoadingPCost(false);
                setmodalAddresses(false);
                await GetViewModel();
                if (IsShipperSelected) {
                  setFieldValue("ShipperId", res[1].Id);
                  console.log(res[1]);
                  await fetchSelectedAddress(res[1].Id, true);
                } else {
                  setFieldValue("ConsigneeId", res[1].Id);
                  await fetchSelectedAddress(res[1].Id, false);
                }

                setAddressObjectPost({
                  Name: "",
                  Address: "",
                  City: "",
                  State: "",
                  PostalCode: "",
                  CountryId: "US",
                  Phone: "",
                  Fax: "",
                  Note: "",
                  AddressTypeId: null,
                  IsActive: true,
                  Charges: [],
                  CustomerId: null,
                  ContactName: "",
                  Email: "",
                });
              } else {
                if (
                  res[1].Message !== null &&
                  res[1].Message !== undefined &&
                  res[1].Message !== ""
                ) {
                  Notification("errorClick", res[1].Message);
                } else {
                  Notification("errorClick", "Invalid Data");
                }
                setLoadingPCost(false);
              }
            })
            .catch((error) => {
              Notification("errorClick", "Invalid Data");
              setLoadingPCost(false);
            });
        }
        return (
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-12">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <Card className="bg-transparent shadow-none header-detail">
                    <CardHeader>
                      <CardHeaderToolbar className="pl-0 align-content-end">
                        <button
                          type="button"
                          onClick={() => {
                            backToProductsList();
                          }}
                          className="btn btn-light"
                        >
                          <i className="fa fa-arrow-left"></i>
                          Back
                        </button>
                        {`  `}

                        <button
                          disabled={LoadingPost}
                          type="submit"
                          className="btn btn-primary ml-2"
                          onClick={handleSubmit}
                        >
                          Save
                          {LoadingPost === true ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            ""
                          )}
                        </button>
                        <button
                          className="btn btn-warning ml-2"
                          onClick={() => {
                            setModalActivities(true);
                          }}
                        >
                          Activities
                        </button>
                      </CardHeaderToolbar>
                    </CardHeader>
                  </Card>
                </div>

                <div className="col-xl-12 col-lg-12 col-md-6">
                  <Card>
                    <CardHeader className="border-bottom-0" title="Basic Info">
                      <div className="separator separator-dashed my-3"></div>
                    </CardHeader>
                    <CardBody>
                      <div className="d-flex flex-wrap align-items-center pb-5">
                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                          <a
                            href={() => false}
                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                          >
                            MO #
                          </a>
                          <span className="text-muted font-weight-bold">
                            <input
                              type="text"
                              className="form-control "
                              value={values.Id}
                              disabled
                            />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center pb-5">
                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                          <a
                            href={() => false}
                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                          >
                            Cust Ref #
                          </a>
                          <span className="text-muted font-weight-bold">
                            <input
                              type="text"
                              className="form-control "
                              name="CustomerRefNumber"
                              id="CustomerRefNumber"
                              value={values.CustomerRefNumber}
                              onChange={(e) => {
                                setFieldValue(
                                  "CustomerRefNumber",
                                  e.target.value
                                );
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center pb-5">
                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                          <a
                            href={() => false}
                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                          >
                            Customer
                          </a>
                          <span className="font-weight-bold">
                            <Select
                              isClearable
                              name="CustomerId"
                              value={ViewModelOptions?.Customers.filter(
                                (a) => a.Id === values.CustomerId
                              ).map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Code + "-" + a.Name,
                                };
                              })}
                              options={ViewModelOptions?.Customers.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Code + "-" + a.Name,
                                };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  setFieldValue("CustomerId", e.value);
                                  setFieldValue(
                                    "BillToId",
                                    ViewModelOptions?.Customers?.filter(
                                      (a) => a.Id === e.value
                                    )[0]?.BillToId
                                  );
                                } else {
                                  setFieldValue("CustomerId", null);
                                  setFieldValue("BillToId", null);
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap align-items-center pb-5">
                        <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                          <a
                            href={() => false}
                            className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg"
                          >
                            Bill To
                          </a>
                          <span className="font-weight-bold">
                            <Select
                              isClearable
                              value={ViewModelOptions?.GenericAddresses?.filter(
                                (a) => a.Id === values.BillToId
                              ).map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                };
                              })}
                              options={ViewModelOptions?.GenericAddresses.map(
                                (a) => {
                                  return {
                                    value: a.Id,
                                    label: a.Name,
                                  };
                                }
                              )}
                              onChange={(e) => {
                                if (e !== null) {
                                  setFieldValue("BillToId", e.value);
                                } else {
                                  setFieldValue("BillToId", null);
                                }
                              }}
                            />
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-9 col-md-12">
              <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="layout-tooltip">Back</Tooltip>}
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      type="button"
                      onClick={backToProductsList}
                      className="btn btn-sm btn-icon btn-bg-light"
                    >
                      <i className="fa fa-arrow-left"></i>
                    </button>
                  </li>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="left"
                  overlay={<Tooltip id="documentations-tooltip">Save</Tooltip>}
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
                      disabled={LoadingPost}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <i class="fas fa-save"></i>
                      {LoadingPost === true ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip id="documentations-tooltip">Activities</Tooltip>
                  }
                >
                  <li className="nav-item mb-2" data-placement="left">
                    <button
                      className="btn btn-sm btn-icon btn-bg-light btn-text-warning btn-hover-warning"
                      onClick={() => {
                        setModalActivities(true);
                      }}
                    >
                      <i class="fas fa-history"></i>
                    </button>
                  </li>
                </OverlayTrigger>
              </ul>
              <Card className="bg-transparent shadow-none mb-0">
                {actionsLoading && <ModalProgressBar />}
                <CardBody>
                  <ul
                    className="nav nav-tabs nav-tabs-line "
                    role="tablist"
                  ></ul>
                  <div className="mt-5">
                    <Modal
                      size="lg"
                      show={CreateSO}
                      onHide={setCreateSO}
                      aria-labelledby="example-modal-sizes-title-sm"
                    >
                      <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-lg">
                          Baics Info
                        </Modal.Title>
                        <IconButton
                          className={classes.button}
                          aria-label="Close"
                          onClick={() => {
                            setCreateSO(false);
                            setCreateSOSelectedMode(null);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="form-group row">
                          <div className="col">
                            <label className="mb-2">Service</label>
                            <Select
                              isClearable
                              name="Service"
                              id="Service"
                              label="Service"
                              value={Services}
                              inputId="react-select-single"
                              options={Services}
                            />
                          </div>
                          <div className="col">
                            <label className="mb-2">Mode</label>
                            <Select
                              name="Mode"
                              id="Mode"
                              label="Mode"
                              options={ViewModelOptions?.Modes?.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                };
                              })}
                              onChange={(e) => {
                                setCreateSOSelectedMode(e);
                              }}
                              inputId="react-select-single"
                            />
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          disabled={LoadingPost}
                          onClick={() => {
                            setCreateSO(false);
                            setCreateSOSelectedMode(null);
                          }}
                          className="btn btn-outline btn-elevate"
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-primary btn-elevate"
                          onClick={() => {
                            if (CreateSOSelectedMode === null) {
                              Notification("errorClick", "Please Select Mode");
                              return;
                            }
                            setLoadingPCost(true);
                            var myHeaders = new Headers();
                            myHeaders.append(
                              "Authorization",
                              `Bearer ${db.read().getState().Token}`
                            );

                            var requestOptions = {
                              method: "POST",
                              headers: myHeaders,
                              redirect: "follow",
                            };
                            fetch(
                              `${window.$apiurl}/Tms/ServiceOrders?orderId=${MO}&modeId=${CreateSOSelectedMode?.value}`,
                              requestOptions
                            )
                              .then(async (response) => {
                                const statusCode = await response.status;
                                const contentType = response.headers.get(
                                  "content-type"
                                );
                                if (
                                  contentType &&
                                  contentType.indexOf("application/json") !== -1
                                ) {
                                  return response
                                    .json()
                                    .then((data) => [statusCode, data]);
                                } else {
                                  return response
                                    .text()
                                    .then((text) => [
                                      statusCode,
                                      { Message: "" },
                                    ]);
                                }
                              })
                              .then((result) => {
                                if (result[0] === 200) {
                                  Notification(
                                    "success",
                                    "Service Order has been created successfully"
                                  );
                                  setCreateSO(false);
                                  fetchProduct(MO);
                                  setCreateSOSelectedMode(null);
                                  ClickFun(
                                    "",
                                    "/TransportManagementSystems/SoDetail/",
                                    result[1].Id
                                  );
                                } else {
                                  if (
                                    result[1].Message !== null &&
                                    result[1].Message !== undefined &&
                                    result[1].Message !== ""
                                  ) {
                                    Notification(
                                      "errorClick",
                                      result[1].Message
                                    );
                                  } else {
                                    Notification("errorClick", "Invalid Data");
                                  }
                                }
                                setLoadingPCost(false);
                              })
                              .catch((error) => {
                                console.log("error", error);
                                setLoadingPCost(false);
                              });
                          }}
                        >
                          Save
                          {LoadingPost && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={modalAddresses}
                      size="lg"
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <Modal.Title id="example-modal-sizes-title-lg">
                          Create new Address
                        </Modal.Title>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={() => {
                            setmodalAddresses(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body>
                        <form className="form form-label-right">
                          <div className="form-group row">
                            <div className={"col-lg-12"}>
                              <label>Organisation Name</label>
                              <input
                                name="Name"
                                className="form-control"
                                placeholder="Organisation Name"
                                label="Organisation Name"
                                value={AddressObjectPost.Name}
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.Name = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                required={true}
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-lg-12 px-0">
                              <label>
                                Address <RequiredHtml />
                              </label>
                              <input
                                name="Address"
                                as="textarea"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.Address = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <label>City</label>

                              <input
                                name="City"
                                className="form-control"
                                placeholder="City"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.City = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                label="City"
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Choose a state</label>
                              <Select
                                name="State"
                                label="State"
                                id="LocationState"
                                options={states}
                                value={stateId}
                                onChange={(e) => {
                                  setStateId(e);
                                  let value = { ...AddressObjectPost };
                                  if (e !== null) {
                                    value.State = e?.label;
                                  } else {
                                    value.State = "";
                                  }
                                  setAddressObjectPost(value);
                                }}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Zip Code</label>

                              <input
                                name="PostalCode"
                                className="form-control"
                                placeholder="Zip Code"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.PostalCode = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                label="Zip Code"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <label>
                                Country <RequiredHtml />
                              </label>
                              <CreatableSelect
                                name="CountryId"
                                value={
                                  countryIdFetch.value === ""
                                    ? { value: "US", label: "United States" }
                                    : countryIdFetch
                                }
                                options={Country.map((x) => {
                                  return { value: x?.Id, label: x?.Name };
                                })}
                                onChange={(newValue) => {
                                  setCountryValue(newValue);
                                  handleCountryChange(newValue?.value);
                                  let value = { ...AddressObjectPost };
                                  if (newValue !== null) {
                                    value.CountryId = newValue?.value;
                                  } else {
                                    value.CountryId = "";
                                  }
                                  setAddressObjectPost(value);
                                }}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Phone</label>

                              <input
                                name="Phone"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.Phone = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                className="form-control"
                                placeholder="Phone"
                                label="Phone"
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Fax</label>

                              <input
                                name="Fax"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.Fax = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                className="form-control"
                                placeholder="Fax"
                                label="Fax"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-4">
                              <label>Contact Name</label>

                              <input
                                name="ContactName"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.ContactName = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                className="form-control"
                                placeholder="Contact Name"
                                label="Contact Name"
                              />
                            </div>
                            <div className="col-lg-4">
                              <label>Contact Email</label>

                              <input
                                name="Email"
                                onChange={(e) => {
                                  let value = { ...AddressObjectPost };
                                  value.Email = e.target.value;
                                  setAddressObjectPost(value);
                                }}
                                className="form-control"
                                placeholder="Contact Email"
                                label="Contact Email"
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-12">
                              <label>Customer</label>
                              <Select name="CustomerId" options={customers} />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="col-lg-12 px-0">
                              <label>Note</label>
                              <input
                                name="Note"
                                as="textarea"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </form>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          type="button"
                          onClick={() => {
                            setmodalAddresses(false);
                          }}
                          className="btn btn-light btn-elevate"
                        >
                          Cancel
                        </button>
                        <> </>
                        <button
                          type="submit"
                          disabled={LoadingPost}
                          onClick={() => {
                            postAddress();
                          }}
                          className="btn btn-primary btn-elevate"
                        >
                          Save
                          {LoadingPost && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          )}
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      size="xl"
                      show={ModalActivities}
                      aria-labelledby="example-modal-sizes-title-lg"
                    >
                      <Modal.Header>
                        <div className="row w-100">
                          <div className="col-lg-6">
                            <h3 className="font-weight-bolder text-dark">
                              MO Activities ID: {MO}
                            </h3>
                          </div>
                        </div>
                        <IconButton
                          className="MuiButtonBase-root MuiIconButton-root jss179"
                          aria-label="Close"
                          onClick={() => {
                            setModalActivities(false);
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Modal.Header>
                      <Modal.Body className="overlay-block">
                        <ActivityFunction
                          Activitys={values?.Activities || []}
                        />
                      </Modal.Body>
                    </Modal>
                    <Card>
                      <CardHeader title="Service Legs">
                        <CardHeaderToolbar>
                          <button
                            type="button"
                            className="btn btn-success mr-4"
                            onClick={() => {
                              setCreateSO(true);
                            }}
                          >
                            Create Service Leg
                          </button>
                        </CardHeaderToolbar>
                      </CardHeader>
                      <CardBody>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          pagination={paginationFactory({
                            showTotal: true,
                          })}
                          bordered={false}
                          remote
                          keyField="id"
                          data={values?.Shipments || []}
                          columns={columns}
                        />
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title="Ultimate Shipper"></CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Company Name :
                                </a>
                                <span className="font-weight-bold">
                                  <CreatableSelect
                                    className="mt-4"
                                    isClearable
                                    name="ShipperId"
                                    id="ShipperId"
                                    label="Company"
                                    value={ViewModelOptions?.Shippers.filter(
                                      (a) => a.Id === values.ShipperId
                                    ).map((a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    })}
                                    options={ViewModelOptions?.Shippers.map(
                                      (a) => {
                                        return {
                                          value: a.Id,
                                          label: a.Name,
                                        };
                                      }
                                    )}
                                    onChange={async (newValue) => {
                                      if (newValue !== null) {
                                        if (newValue.__isNew__) {
                                          setmodalAddresses(true);
                                          setCountryValue({
                                            value: "US",
                                            label: "United States",
                                          });

                                          handleCountryChange("US");
                                          var value = {
                                            ...AddressObjectPost,
                                          };
                                          value.Name = newValue.label;
                                          setAddressObjectPost(value);
                                          setIsShipperSelected(true);
                                        } else {
                                          setFieldValue(
                                            "ShipperId",
                                            newValue?.value
                                          );
                                          await fetchSelectedAddress(
                                            newValue?.value,
                                            true
                                          );
                                        }
                                      } else {
                                        setFieldValue("ShipperId", null);
                                        let values = { ...shipper };
                                        values = {
                                          ShipperId: 0,
                                          ShipperAddress: "",
                                          ShipperCity: "",
                                          ShipperState: "",
                                          ShipperZipCode: "",
                                          ShipperPhoneNumber: "",
                                          ShipperFax: "",
                                          ShipperContactName: "",
                                          ShipperEmail: "",
                                        };
                                        setShipper(values);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Street Address :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ShipperAddress"
                                    disabled={true}
                                    id="ShipperAddress"
                                    value={shipper.ShipperAddress}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  City :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ShipperCity"
                                    disabled={true}
                                    id="ShipperCity"
                                    value={shipper?.ShipperCity}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  State :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ShipperState"
                                    disabled={true}
                                    id="ShipperState"
                                    value={shipper?.ShipperState}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Zip :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ShipperZipCode"
                                    id="ShipperZipCode"
                                    disabled={true}
                                    value={shipper?.ShipperZipCode}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Name :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ShipperContactName"
                                    disabled={true}
                                    id="ShipperContactName"
                                    label="Contact"
                                    value={shipper?.ShipperContactName}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Phone :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="tel"
                                    className="form-control mt-4"
                                    name="ShipperPhoneNumber"
                                    id="ShipperPhoneNumber"
                                    disabled={true}
                                    value={shipper?.ShipperPhoneNumber}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Fax :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="tel"
                                    className="form-control mt-4"
                                    name="ShipperFax"
                                    id="ShipperFax"
                                    disabled={true}
                                    value={shipper?.ShipperFax}
                                    label="Fax"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Email :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="email"
                                    className="form-control mt-4"
                                    name="ShipperEmail"
                                    disabled={true}
                                    id="ShipperEmail"
                                    value={shipper?.ShipperEmail}
                                    label="Email"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title="Ultimate Receiver"></CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Company Name :
                                </a>
                                <span className="font-weight-bold">
                                  <CreatableSelect
                                    isClearable
                                    className="mt-4"
                                    name="ConsigneeId"
                                    id="ConsigneeId"
                                    value={ViewModelOptions?.Consignees.filter(
                                      (a) => a.Id === values.ConsigneeId
                                    ).map((a) => {
                                      return {
                                        value: a.Id,
                                        label: a.Name,
                                      };
                                    })}
                                    options={ViewModelOptions?.Consignees.map(
                                      (a) => {
                                        return {
                                          value: a.Id,
                                          label: a.Name,
                                        };
                                      }
                                    )}
                                    onChange={async (newValue) => {
                                      if (newValue !== null) {
                                        if (newValue.__isNew__) {
                                          setmodalAddresses(true);
                                          setCountryValue({
                                            value: "US",
                                            label: "United States",
                                          });

                                          handleCountryChange("US");
                                          var value = {
                                            ...AddressObjectPost,
                                          };
                                          value.Name = newValue.label;
                                          setAddressObjectPost(value);
                                          setIsShipperSelected(false);
                                        } else {
                                          setFieldValue(
                                            "ConsigneeId",
                                            newValue?.value
                                          );
                                          await fetchSelectedAddress(
                                            newValue?.value,
                                            false
                                          );
                                        }
                                      } else {
                                        setFieldValue("ConsigneeId", null);
                                        let values = { ...consignee };
                                        values = {
                                          ConsigneeId: 0,
                                          ConsigneeAddress: "",
                                          ConsigneeCity: "",
                                          ConsigneeState: "",
                                          ConsigneeZipCode: "",
                                          ConsigneePhoneNumber: "",
                                          ConsigneeFax: "",
                                          ConsigneeContactName: "",
                                          ConsigneeEmail: "",
                                        };
                                        setConsignee(values);
                                      }
                                    }}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Street Address :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ConsigneeStreet"
                                    id="ConsigneeStreet"
                                    disabled={true}
                                    label="Street"
                                    value={consignee?.ConsigneeAddress}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  City :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ConsigneeCity"
                                    disabled={true}
                                    id="ConsigneeCity"
                                    value={consignee?.ConsigneeCity}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  State :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ConsigneeState"
                                    id="ConsigneeState"
                                    disabled={true}
                                    value={consignee?.ConsigneeState}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Zip :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ConsigneeZipCode"
                                    id="ConsigneeZipCode"
                                    disabled={true}
                                    value={consignee?.ConsigneeZipCode}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Name :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="text"
                                    className="form-control mt-4"
                                    name="ConsigneeContactName"
                                    disabled={true}
                                    value={consignee?.ConsigneeContactName}
                                    id="ConsigneeContactName"
                                    label="Contact"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Phone :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="tel"
                                    className="form-control mt-4"
                                    name="ConsigneePhoneNumber"
                                    disabled={true}
                                    id="ConsigneePhoneNumber"
                                    value={consignee?.ConsigneePhoneNumber}
                                  />
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Fax :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="tel"
                                    className="form-control mt-4"
                                    name="ConsigneeFax"
                                    id="ConsigneeFax"
                                    disabled={true}
                                    value={consignee?.ConsigneeFax}
                                    label="Fax"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl col-lg col-md-5 border-right">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Contact Email :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  <input
                                    type="email"
                                    className="form-control mt-4"
                                    name="EmailText"
                                    id="EmailText"
                                    disabled={true}
                                    value={consignee?.ConsigneeEmail}
                                    label="Email"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader title="Commodity Details"></CardHeader>
                      <CardBody>
                        <div className="col-xl-12 col-lg-12">
                          {values?.Shipments?.length > 0
                            ? ItemsTmsComponent(values?.Shipments || [])
                            : ""}
                        </div>
                      </CardBody>
                    </Card>
                    <Card>
                      <CardHeader
                        className="border-bottom-0"
                        title="AP/Invoicing"
                      >
                        <div className="separator separator-dashed my-3"></div>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div className="col">
                            <h3>Total Bill To Rating </h3>
                            <div className="react-bootstrap-table table-responsive">
                              <Table
                                className={classes.table}
                                responsive
                                size="small"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>SO#</TableCell>
                                    <TableCell align="right">Mode</TableCell>
                                    <TableCell align="right">
                                      Total Charges
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values?.Shipments?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {row.Id}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.Mode}
                                      </TableCell>
                                      <TableCell align="right">
                                        {currencyFormat(row.TotalReceivables)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow>
                                    <TableCell
                                      rowSpan={2}
                                      className="border-0"
                                    />
                                    <TableCell
                                      rowSpan={3}
                                      align="right"
                                      className="font-weight-bolder"
                                    >
                                      Total
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="font-weight-bolder"
                                    >
                                      $
                                      {currencyFormat(
                                        values?.Shipments?.reduce(function(
                                          a,
                                          b
                                        ) {
                                          return a + b.TotalReceivables;
                                        },
                                        0)
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          </div>
                          <div className="col border-right">
                            <h3>Total Vendor Rating </h3>
                            <div className="react-bootstrap-table table-responsive">
                              <Table
                                className={classes.table}
                                responsive
                                size="small"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>SO#</TableCell>
                                    <TableCell align="right">Mode</TableCell>
                                    <TableCell align="right">
                                      Total Charges
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values?.Shipments?.map((row, index) => (
                                    <TableRow key={index}>
                                      <TableCell component="th" scope="row">
                                        {row.Id}
                                      </TableCell>
                                      <TableCell align="right">
                                        {row.Mode}
                                      </TableCell>
                                      <TableCell align="right">
                                        {currencyFormat(row.TotalPayable)}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow>
                                    <TableCell
                                      rowSpan={2}
                                      className="border-0"
                                    />
                                    <TableCell
                                      rowSpan={3}
                                      align="right"
                                      className="font-weight-bolder"
                                    >
                                      Total
                                    </TableCell>
                                    <TableCell
                                      align="right"
                                      className="font-weight-bolder"
                                    >
                                      $
                                      {currencyFormat(
                                        values?.Shipments?.reduce(function(
                                          a,
                                          b
                                        ) {
                                          return a + b.TotalPayable;
                                        },
                                        0)
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </div>
                          </div>
                          <div className="col-12 text-center mt-8">
                            <div className=" flex-wrap align-items-center pb-5">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Margin Amount :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  ${" "}
                                  {currencyFormat(
                                    values?.Shipments?.reduce(function(a, b) {
                                      return a + b.TotalReceivables;
                                    }, 0) -
                                      values?.Shipments?.reduce(function(a, b) {
                                        return a + b.TotalPayable;
                                      }, 0)
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className=" flex-wrap align-items-center pb-10">
                              <div className="flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                                <a
                                  href={() => false}
                                  className="text-dark font-weight-bold text-hover-primary mb-1 font-size-lg mr-3"
                                >
                                  Margin Percentage :
                                </a>
                                <span className="text-muted font-weight-bold">
                                  {values?.Shipments?.reduce(function(a, b) {
                                    return a + b.TotalReceivables;
                                  }, 0) === 0
                                    ? 0
                                    : currencyFormat(
                                        ((values?.Shipments?.reduce(function(
                                          a,
                                          b
                                        ) {
                                          return a + b.TotalReceivables;
                                        },
                                        0) -
                                          values?.Shipments?.reduce(function(
                                            a,
                                            b
                                          ) {
                                            return a + b.TotalPayable;
                                          },
                                          0)) /
                                          values?.Shipments?.reduce(function(
                                            a,
                                            b
                                          ) {
                                            return a + b.TotalReceivables;
                                          },
                                          0)) *
                                          100
                                      )}
                                  %
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </CardBody>
              </Card>
              <Card
                className="bg-transparent shadow-none"
                style={{ float: "right" }}
              >
                <CardHeader>
                  <CardHeaderToolbar className="pl-0 align-content-end">
                    <button
                      type="button"
                      onClick={backToProductsList}
                      className="btn btn-light"
                    >
                      <i className="fa fa-arrow-left"></i>
                      Back
                    </button>
                    {`  `}

                    <button
                      disabled={LoadingPost}
                      type="submit"
                      className="btn btn-primary ml-2"
                      onClick={handleSubmit}
                    >
                      Save
                      {LoadingPost === true ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}
                    </button>
                    <button
                      className="btn btn-warning ml-2"
                      onClick={() => {
                        setModalActivities(true);
                      }}
                    >
                      Activities
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
              </Card>
            </div>
          </div>
        );
      }}
    </Formik>
  );
}
