import {
  Button,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  ButtonToolbar,
  OverlayTrigger,
  ProgressBar,
  Tooltip,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Helmet } from "react-helmet";
import SVG from "react-inlinesvg";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  SimpleZoom,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import { Export } from "../../../../../../_metronic/_helpers/ATHelpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CheckboxLargeWB,
  Pagination,
} from "../../../../../../_metronic/_partials/controls";
import { BLFORM } from "../../../../../pages/BL/BLFORM";
import { DRAYAGEFORM } from "../../../../../pages/Drayage/DRAYAGEFORM";
import DynamicReport from "../../../../../pages/DynamicReport/DynamicReport";
import { LTLFORM } from "../../../../../pages/LTL/LTLFORM";
import { PLFORM } from "../../../../../pages/PL/PLFORM";
import { OutboundManifest } from "../../../../../pages/SCENTSY/OutboundManifest";
import { TRCFORM } from "../../../../../pages/TRC/TRCFORM";
import { Notification } from "../../../../Notification";
import * as columnFormatters from "../../NewShipmentsBeta/customers-table/column-formatters";
import { ModelManagmentFirst } from "../../NewShipmentsBeta/customers-table/ModelShipmentManagment";
import { ActionsFormatter } from "./ActionsFormatter";
import { ActionsStatus } from "./ActionsStatus";
import { Status } from "./Status";
import "./Style.css";
import { WarehouseFilter } from "./WarehouseFilter";
import { ModelMnagementTMSWarehouse } from "./ModelMnagementTMSWarehouse";
import { BlFormMulti } from "../../../../../pages/BL/BlFormMulti";
import { RateConfirmationBeta } from "../../../../../pages/Drayage/RateConfirmationBeta";

var Spinner = require("react-spinkit");
const adapter = new LocalStorage("db");
const db = low(adapter);

var totalItems = 0;

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY hh:mm A";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return (
    <span className="label label-lg label-light-primary label-inline">
      {moment(d).format(format)}
    </span>
  );
};

export function TmsWarehouseManagementPage() {
  const [LoadingGridData, setLoadingGridData] = useState(false);
  const [showModalSends, setshowModalSends] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [DataWareHouse, setDataWareHouse] = useState(null);
  const [ViewModel, setViewModel] = useState(null);
  const [ExportLoadingData, setExportLoadingData] = useState(false);
  const [SelectedRow, setSelectedRow] = useState([]);
  const [SelectedRowBulk, setSelectedRowBulk] = useState([]);
  const [SelectedRowBulkButtonShow, setSelectedRowBulkButtonShow] = useState(
    false
  );
  const [paginationOptions, setpaginationOptions] = useState({
    custom: true,
    totalSize: totalItems,
    sortField: "StatusNewDate",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
    ],
    sizePerPage: 10,
    page: 1,
  });
  const [filterOptions, setfilterOptions] = useState({
    customers: [],
    service: window.$ShipmentManagementServiceId,
    mode: window.$ShipmentManagementModeId,
    serviceLevelId: "",
    IsCanceled: null,
    search: window.$SmGsearchText,
    eqTypeId: "",
    eqSizeId: "",
    DateRangeType: "created",
    exceptionsId: "",
    ShipFrom: "",
    VendorId: "",
    startDate: new Date()
      .addDays(-28)
      .toISOString()
      .split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
    statusId: "",
    StatusName: "",
    hot: false,
    hasMat: false,
    isHeavy: false,
    OnHold: false,
    canceled: window.$ShipmentManagementCanceled,
    driverCheckedIn: "",
    closed: window.$ShipmentManagementClosed,
  });
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  useEffect(() => {
    if (window.$SmGsearchText !== filterOptions.search) {
      setfilterOptions({
        customers: [],
        service: window.$ShipmentManagementServiceId,
        mode: window.$ShipmentManagementModeId,
        serviceLevelId: "",
        IsCanceled: null,
        search: window.$SmGsearchText,
        eqTypeId: "",
        eqSizeId: "",
        DateRangeType: "created",
        exceptionsId: "",
        ShipFrom: "",
        VendorId: "",
        startDate: window.$SmGsearchStartDate,
        endDate: "",
        statusId: "",
        StatusName: "",
        hot: false,
        hasMat: false,
        OnHold: false,
        isHeavy: false,
        canceled: window.$ShipmentManagementCanceled,
        driverCheckedIn: "",
        closed: window.$ShipmentManagementClosed,
      });
    }
  }, [window.$SmGsearchText]);

  function handleClose() {
    setAnchorEl(null);
  }

  useEffect(() => {
    GetWarehousData();
  }, [filterOptions]);

  //Datafunctions
  function GetWarehousData(searchVal) {
    setLoadingGridData(true);
    var _customers = filterOptions.customers.length > 0 ? "" : "?customerId=";
    filterOptions.customers.forEach((item, index) => {
      _customers +=
        (index === 0 ? "?customerId=" : "&customerId=") + item.value;
    });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    if (searchVal !== null && searchVal !== undefined) {
      let currentFilter = { ...filterOptions };
      currentFilter.search = searchVal;
      setfilterOptions(currentFilter);
    }
    fetch(
      `${window.$apiurl}/warehouseManagement${_customers}&driverCheckedIn=${filterOptions.driverCheckedIn}&statusId=${filterOptions.statusId}&ServiceId=${filterOptions.service}&modeId=${filterOptions.mode}&serviceLevelId=${filterOptions.serviceLevelId}&equipmentTypeId=${filterOptions.eqTypeId}&equipmentSizeId=${filterOptions.eqSizeId}&shipFromId=${filterOptions.ShipFrom}&VendorId=${filterOptions.VendorId}&filterDate=${filterOptions.DateRangeType}&startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}&isHot=${filterOptions.hot}&IsHeavy=${filterOptions.isHeavy}&isHazardousMaterial=${filterOptions.hasMat}&exception=${filterOptions.exceptionsId}&IsCancel=${filterOptions.IsCanceled}&OnHold=${filterOptions.OnHold}&IsClosed=${filterOptions.closed}&pageNumber=${paginationOptions.page}&pageSize=${paginationOptions.sizePerPage}&sortField=${paginationOptions.sortField}&sortOrder=${paginationOptions.sortOrder}&search=${filterOptions.search}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dataObj = JSON.parse(result);
        setDataWareHouse(dataObj);

        //InTransit
        var _paginationOptions = { ...paginationOptions };
        _paginationOptions.totalSize = dataObj?.Pagination?.TotalItemCount;
        _paginationOptions.page = 1;
        setSelectedRow([]);
        setSelectedRowBulk([]);
        setpaginationOptions(_paginationOptions);

        setLoadingGridData(false);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingGridData(false);
      });
  }
  function GetWarehousDataPageChanges(values) {
    setLoadingGridData(true);

    var _customers = filterOptions.customers.length > 0 ? "" : "?customerId=";
    filterOptions.customers.map((item, index) => {
      _customers +=
        (index === 0 ? "?customerId=" : "&customerId=") + item.value;
    });
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/warehouseManagement${_customers}&driverCheckedIn=${filterOptions.driverCheckedIn}&statusId=${filterOptions.statusId}&ServiceId=${filterOptions.service}&modeId=${filterOptions.mode}&serviceLevelId=${filterOptions.serviceLevelId}&equipmentTypeId=${filterOptions.eqTypeId}&equipmentSizeId=${filterOptions.eqSizeId}&shipFromId=${filterOptions.ShipFrom}&VendorId=${filterOptions.VendorId}&filterDate=${filterOptions.DateRangeType}&startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}&isHot=${filterOptions.hot}&IsHeavy=${filterOptions.isHeavy}&isHazardousMaterial=${filterOptions.hasMat}&exception=${filterOptions.exceptionsId}&IsCancel=${filterOptions.IsCanceled}&OnHold=${filterOptions.OnHold}&IsClosed=${filterOptions.closed}&pageNumber=${values.page}&pageSize=${values.sizePerPage}&sortField=${values.sortField}&sortOrder=${values.sortOrder}&search=${filterOptions.search}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let dataObj = JSON.parse(result);
        let oldPaginationValue = { ...paginationOptions };
        oldPaginationValue.totalSize = dataObj?.Pagination?.TotalItemCount;
        oldPaginationValue.sortField = values.sortField;
        oldPaginationValue.sortOrder = values.sortOrder;
        oldPaginationValue.page = values.page;
        oldPaginationValue.sizePerPage = values.sizePerPage;
        setpaginationOptions(oldPaginationValue);
        setSelectedRow([]);
        setSelectedRowBulk([]);
        setLoadingGridData(false);
        setDataWareHouse(dataObj);
      })
      .catch((error) => {
        console.log("error", error);
        setLoadingGridData(false);
      });
  }
  function GetOptionsViewModel() {
    fetch(window.$apiurl + "/transportation/shipments/getSmViewModel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setViewModel(res);
      })
      .catch(() => {
        Notification("error", "err");
        return false;
      });
  }
  useEffect(() => {
    GetOptionsViewModel();
    GetWarehousData();
  }, []);
  async function fetchMyAPI2(type, entityName) {
    var _customers = filterOptions.customers.length > 0 ? "" : "?customerId=";
    filterOptions.customers.map((item, index) => {
      _customers +=
        (index === 0 ? "?customerId=" : "&customerId=") + item.value;
    });
    setExportLoadingData(true);
    return await fetch(
      `${window.$apiurl}/warehouseManagement${_customers}&driverCheckedIn=${filterOptions.driverCheckedIn}&exception=${filterOptions.exceptionsId}&statusId=${filterOptions.statusId}&ServiceId=${filterOptions.service}&modeId=${filterOptions.mode}&serviceLevelId=${filterOptions.serviceLevelId}&equipmentTypeId=${filterOptions.eqTypeId}&equipmentSizeId=${filterOptions.eqSizeId}&shipFromId=${filterOptions.ShipFrom}&VendorId=${filterOptions.VendorId}&filterDate=${filterOptions.DateRangeType}&startDate=${filterOptions.startDate}&endDate=${filterOptions.endDate}&isHot=${filterOptions.hot}&IsHeavy=${filterOptions.isHeavy}&isHazardousMaterial=${filterOptions.hasMat}&exception=&IsCancel=${filterOptions.canceled}&IsClosed=${filterOptions.closed}&pageNumber=${paginationOptions.page}&pageSize=1000000&sortField=${paginationOptions.sortField}&sortOrder=${paginationOptions.sortOrder}&search=${filterOptions.search}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        Export(res[1].Shipments, type, entityName);
        setExportLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoadingData(false);
      });
  }
  //End Data functions
  const selectRow = {
    mode: "checkbox",
    selected: SelectedRow,
    hideSelectColumn:
      filterOptions?.statusId === 102 || filterOptions?.statusId === 7
        ? true
        : false,
    onSelect: (row, isSelect, rowIndex, e) => {
      var a = [];
      var b = [];
      if (isSelect) {
        a = SelectedRow;
        b = SelectedRowBulk;
        a.push(row.Id);
        b.push(row);
        setSelectedRow(a);
        setSelectedRowBulk(b);
        if (b.length > 0) {
          setSelectedRowBulkButtonShow(true);
        } else {
          setSelectedRowBulkButtonShow(false);
        }
      } else {
        a = SelectedRow;
        b = SelectedRowBulk;
        a = a?.filter((word) => String(word) !== String(row.Id));
        b = b?.filter((word) => String(word.Id) !== String(row.Id));
        setSelectedRow(a);
        setSelectedRowBulk(b);
        if (b.length > 0) {
          setSelectedRowBulkButtonShow(true);
        } else {
          setSelectedRowBulkButtonShow(false);
        }
      }
    },
    onSelectAll: (isSelected, rows) => {
      if (isSelected) {
        setSelectedRow(rows.map((a) => a.Id));
        setSelectedRowBulk(rows);
        setSelectedRowBulkButtonShow(true);
      } else {
        setSelectedRow([]);
        setSelectedRowBulk([]);
        setSelectedRowBulkButtonShow(false);
      }
    },
  };
  const GetHandlerTableChange = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationOptions };

      if (
        values.sortField === newState.sortField &&
        values.sortOrder === newState.sortOrder
      ) {
        return;
      }
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      //setpaginationOptions(values);
      GetWarehousDataPageChanges(values);
    }
    if (type === "pagination") {
      let values = { ...paginationOptions };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      //setpaginationOptions(values);
      GetWarehousDataPageChanges(values);
    }
  };
  useEffect(() => {
    setcolumns(columnsWareHouse);
  }, [DataWareHouse]);
  useEffect(() => {
    setstatusId(filterOptions.statusId);
  }, [filterOptions.statusId]);
  //useStates Reports
  const [showInboundManifest, setOutboundManifest] = useState(false);
  const [showDispatchReport, setRateConfirmation] = useState(false);
  const [showPl, setPL] = useState(false);
  const [showBol, setBol] = useState(false);
  const [rowItem, setRow] = useState(null);
  const [modeItem, setModeItem] = useState(null);
  const [statusId, setstatusId] = useState(0);
  const columnsWareHouse = [
    {
      dataField: "Id",
      text: "#",
      formatter: (cell, row) => {
        const actions = {
          setOutboundManifest: setOutboundManifest,
          setRateConfirmation: setRateConfirmation,
          setBol: setBol,
          setPL: setPL,
          setRowItem: setRow,
          setModeItem: setModeItem,
          setshowModalSends: setshowModalSends,
          showModalSends: showModalSends,
        };
        return (
          <ModelMnagementTMSWarehouse
            cell={cell}
            row={{ ...row, id: row.Id }}
            filterOptions={filterOptions}
            DataWareHouse={DataWareHouse}
            GetWarehousDataPageChanges={GetWarehousDataPageChanges}
            paginationOptions={paginationOptions}
            modalActions={actions}
          />
        );
      },
    },
    {
      dataField: "Id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <ModelManagmentFirst row={{ ...row, id: row.Id }} isTms={true} />
        );
      },
      style: {
        minWidth: "130px",
      },
    },
    {
      dataField:
        statusId === 2
          ? "StatusNewDate"
          : statusId === 3
          ? "StatusBookedDate"
          : statusId === 5
          ? "StatusLoadingFromOurWarehouseDate"
          : statusId === 6
          ? "StatusLoadedFromOurWarehouseDate"
          : statusId === 102
          ? "StatusNotifyTrucker"
          : statusId === 7
          ? "StatusPickedUpDate"
          : statusId === -1
          ? "StatusNewDate"
          : "StatusNewDate",
      text:
        statusId === 2
          ? "Created Date"
          : statusId === 3
          ? "Assigned Date"
          : statusId === 5
          ? "Loading Date"
          : statusId === 6
          ? "Loaded Date"
          : statusId === 102
          ? "Notify Trucker Date"
          : statusId === 7
          ? "Picked Up Date"
          : statusId === -1
          ? "Created Date"
          : "Created Date",
      formatter: (cell, row) => {
        return (
          <>
            <span class="label text-primary p-1 m-0 updated">
              {statusId === 2
                ? getDate(row.StatusNewDate)
                : statusId === 3
                ? getDate(row.StatusBookedDate)
                : statusId === 5
                ? getDate(row.StatusLoadingFromOurWarehouseDate)
                : statusId === 6
                ? getDate(row.StatusLoadedFromOurWarehouseDate)
                : statusId === 102
                ? getDate(row.StatusNotifyTrucker)
                : statusId === 7
                ? getDate(row.StatusPickedUpDate)
                : statusId === -1
                ? getDate(row.StatusNewDate)
                : getDate(row.StatusNewDate)}
            </span>
          </>
        );
      },
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "EquipmentNumber",
      text: "Trailer Number",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CustomerCode !== null &&
          row.CustomerCode !== "" &&
          row.CustomerCode !== undefined
        ) {
          return (
            <>
              {row.CustomerCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CustomerName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "OriginCompanyCode",
      text: "Shipper Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.OriginCompanyName !== null ? row.OriginCompanyName : "") +
          "/" +
          row.FromCity +
          (row.FromState !== null && row.FromState !== ""
            ? ", " + row.FromState
            : "");
        if (
          row.OriginCompanyName !== null &&
          row.OriginCompanyName !== "" &&
          row.OriginCompanyName !== undefined
        ) {
          return (
            <>
              {row.OriginCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "DestinationCompanyCode",
      text: "Consignee Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.DestinationCompanyName !== null
            ? row.DestinationCompanyName
            : "") +
          "/" +
          row.ToCity +
          (row.ToState !== null && row.ToState !== ""
            ? ", " + row.ToState
            : "");
        if (
          row.DestinationCompanyName !== null &&
          row.DestinationCompanyName !== "" &&
          row.DestinationCompanyName !== undefined
        ) {
          return (
            <>
              {row.DestinationCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "FreightCharge",
      text: "Freight Charge",
      sort: true,
      sortCaret: sortCaret,
      hidden: statusId !== 2 && statusId !== 7 ? true : false,
    },
    {
      dataField: "DeliveryEta",
      text: "Delivery ETA",
      sort: true,
      hidden: statusId !== 7 && statusId !== 9 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.DeliveryEta)}</>;
      },
    },
    {
      dataField: "StatusDeliveredDate",
      text: "Actual Delivered Date",
      sort: false,
      hidden: statusId !== 12 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.StatusDeliveredDate)}</>;
      },
    },
    {
      dataField: "RequestedPickUpDate",
      text: "PICKUP ready DATE/Time",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.RequestedPickUpDate)}</>;
      },
    },
    {
      dataField: "PickupAppointmentDate",
      text: "PickUp by Date/Time",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.PickupAppointmentDate)}</>;
      },
    }, //LastShipDate
    {
      dataField: "LastShipDate",
      text: "Last Ship Date",
      sort: true,
      hidden: statusId !== 2 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.LastShipDate)}</>;
      },
    },
    {
      dataField: "DeliveryAppointmentDate",
      text: "Delivery Appointment",
      sort: true,
      hidden: statusId === 2 || statusId === 12 ? true : false,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.DeliveryAppointmentDate)}</>;
      },
    },

    {
      dataField: "LoadingType",
      text: "Loading Type",
      hidden: statusId !== 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Weight",
      text: "Weight",
      hidden: statusId !== 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{row.Weight}</>;
      },
    },
    {
      dataField: "Commodity",
      text: "Commodity",
      sort: true,
      sortCaret: sortCaret,
      hidden: statusId !== 2 ? true : false,
    },
    {
      dataField: "CarrierName",
      text: "Carrier",
      hidden: statusId === 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CarrierName !== null &&
          row.CarrierName !== "" &&
          row.CarrierName !== undefined
        ) {
          return (
            <>
              {row.CarrierCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CarrierName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "CarrierPhoneNumber",
      text: "Carrier Phone#",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "EquipmentType",
      text: "Equipment Type",
      hidden: statusId === 2 ? true : false,
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Alerts",
      text: "Alerts",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.AlertsColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      classes: "text-center px-0",
      headerClasses: "text-center pr-3",
      sortCaret: sortCaret,
      formatter: columnFormatters.StatusColumnFormatter,
    },
  ];
  const [columns, setcolumns] = useState(columnsWareHouse || []);
  //Column Visibility
  (
    JSON.parse(localStorage.getItem("ColumnVisibility_warehousemanagement")) ||
    []
  ).forEach((x) => {
    const a = columnsWareHouse.find(
      (y) => y?.dataField + y?.text === x?.dataField + x?.text
    );
    if (a) a.hidden = x?.hidden || false;
  });
  return (
    <>
      <Helmet>
        <title>eMPower | Warehouse Management</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <ActionsStatus
        filterOptions={filterOptions}
        DataWareHouse={DataWareHouse}
        GetWarehousDataPageChanges={GetWarehousDataPageChanges}
        paginationOptions={paginationOptions}
        SelectedRow={SelectedRow}
        setSelectedRow={setSelectedRow}
        SelectedRowBulk={SelectedRowBulk}
      />
      <div className="row WarehouseManagment">
        <div className="col-xl-2 col-lg-3 col-md-12">
          <Status
            DataWareHouse={DataWareHouse}
            filterOptions={filterOptions}
            setfilterOptions={setfilterOptions}
            paginationOptions={paginationOptions}
            setpaginationOptions={setpaginationOptions}
          />
        </div>
        <div className="col-xl-10 col-lg-9 col-md-12" id="WarehousePageId">
          <Card>
            {LoadingGridData && (
              <ProgressBar
                variant="success"
                animated
                now={100}
                style={{ height: "3px", width: "100%" }}
              />
            )}

            <CardHeader title="Warehouse Management">
              <ButtonToolbar className={`btn-text-primary  m-5 btn-sm`}>
                <Button
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="outlined"
                  color="primary"
                  //disabled={ExportLoadingData}
                  onClick={handleClick}
                >
                  Export as
                  <Icon>save</Icon>
                  {ExportLoadingData && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                </Button>
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem
                    onClick={(event) => {
                      fetchMyAPI2("excel", `Warehouse Management`);
                    }}
                  >
                    <ListItemIcon>
                      <i className="far fa-file-excel"></i>
                    </ListItemIcon>
                    <ListItemText primary="Excel" />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={(event) => {
                      fetchMyAPI2("csv", `Warehouse Management`);
                    }}
                  >
                    <ListItemIcon>
                      <i className="fas fa-file-csv"></i>
                    </ListItemIcon>
                    <ListItemText primary="CSV" />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={(event) => {
                      fetchMyAPI2("json", `Warehouse Management`);
                    }}
                  >
                    <ListItemIcon>
                      <i className="far fa-file-code"></i>
                    </ListItemIcon>
                    <ListItemText primary="Json" />
                  </StyledMenuItem>
                </StyledMenu>
              </ButtonToolbar>
            </CardHeader>

            <CardBody>
              <WarehouseFilter
                ViewModel={ViewModel}
                filterOptions={filterOptions}
                setfilterOptions={setfilterOptions}
                statusesList={DataWareHouse}
              />
              <SimpleZoom
                titleButton={
                  <>
                    Column visibility{" "}
                    <i class="fas fa-columns text-white ml-5" />
                  </>
                }
                body={
                  <div className="row">
                    {columns?.map((x) => (
                      <div className="col-md-3">
                        <CheckboxLargeWB
                          className="font-size-md"
                          text={x?.text}
                          checked={!x?.hidden}
                          onChange={() => {
                            let a = columns;
                            a.forEach((y) => {
                              if (
                                y?.dataField + y?.text ===
                                x?.dataField + x?.text
                              ) {
                                y.hidden = !y.hidden;
                              }
                            });
                            setcolumns([]);
                            setTimeout(() => {
                              setcolumns(a);
                              localStorage.setItem(
                                "ColumnVisibility_warehousemanagement",
                                JSON.stringify(a)
                              );
                            }, 1);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                }
              />
              {LoadingGridData === false && columns.length > 0 ? (
                <PaginationProvider
                  pagination={paginationFactory(paginationOptions)}
                >
                  {({ paginationProps, paginationTableProps }) => {
                    return (
                      <Pagination paginationProps={paginationProps}>
                        <BootstrapTable
                          wrapperClasses="table-responsive"
                          bordered={false}
                          classes="table table-head-custom table-vertical-center"
                          bootstrap4
                          remote
                          id="WareHouseTableId"
                          keyField="Id"
                          data={DataWareHouse?.Shipments || []}
                          columns={columns}
                          selectRow={selectRow}
                          defaultSorted={[
                            {
                              dataField: paginationOptions.sortField,
                              order: paginationOptions.sortOrder,
                            },
                          ]}
                          onTableChange={GetHandlerTableChange}
                          {...paginationTableProps}
                        >
                          <PleaseWaitMessage
                            entities={DataWareHouse?.Shipments}
                          />
                          <NoRecordsFoundMessage
                            entities={DataWareHouse?.Shipments}
                          />
                        </BootstrapTable>
                      </Pagination>
                    );
                  }}
                </PaginationProvider>
              ) : (
                <div
                  className="col-lg-12 col-xxl-12 row mt-2"
                  style={{ left: "44%" }}
                >
                  <Spinner name="folding-cube" color="blue" />
                </div>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
      {/* ************************ Reports RM *************************** */}
      <DynamicReport
        setShowModal={setBol}
        showModal={showBol}
        id={rowItem}
        title={`BOL Multi Shipment ID#: ${rowItem}`}
        reportContent={<BlFormMulti id={rowItem} />}
      />
      <DynamicReport
        setShowModal={setRateConfirmation}
        showModal={showDispatchReport}
        id={rowItem}
        title={`Rate Confirmation Shipment ID#: ${rowItem}`}
        reportContent={<RateConfirmationBeta id={rowItem} />}
      />
      <DynamicReport
        setShowModal={setPL}
        showModal={showPl}
        id={rowItem}
        title={`P & L Shipment ID#: ${rowItem}`}
        reportContent={<PLFORM id={rowItem} />}
      />
      <DynamicReport
        setShowModal={setOutboundManifest}
        showModal={showInboundManifest}
        id={rowItem}
        title={`Shipment Manifest Shipment ID#: ${rowItem}`}
        reportContent={<OutboundManifest smId={rowItem} />}
      />
      {/* **************************************************************** */}
    </>
  );
}
