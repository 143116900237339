// import remarkTableMock from "./remarkTableMock";
import MockUtils from "./mock.utils";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let remarkTableMock;
export default function mockRemarks(mock) {
  async function GetOrderLineItems(id) {
    return await fetch(window.$apiurl + "/Orders/GetOrderLines/" + id, {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetSkuList() {
    return await fetch(window.$apiurl + "/Orders/SkuList", {
      method: "get",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PostOrder(id, someData) {
    console.log(JSON.stringify(someData));
    return fetch(window.$apiurl + "/Orders/PostOrderLine/" + id, {
      method: "post",
      withCredentials: true,

      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetOrderLine(orderId, Itemid) {
    return await fetch(
      window.$apiurl + "/Orders/GetOrderLine/" + orderId + "/" + Itemid,
      {
        method: "get",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  async function PutOrderLine(someData, orderId, itemId) {
    // let userDate = someData["customer"];
    // console.log("userDate")
    // console.log(userDate);
    return fetch(
      window.$apiurl + "/Orders/PutOrderLine/" + orderId + "/" + itemId,
      {
        method: "put",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(someData),
      }
    )
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteOrder(orderId, itemId) {
    return await fetch(
      window.$apiurl + "/Orders/DeleteOrderLine/" + orderId + "/" + itemId,
      {
        method: "delete",
        withCredentials: true,

        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //****************************Mock************************************* */
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }
  mock.onGet("api/orderLineItems/GetAllApi").reply(async () => {
    var customers = {};
    customers["skuList"] = await GetSkuList();

    return [200, customers];
  });

  // mock.onGet("api/orderLineItems").reply(async () => {
  //   var customers = {}
  //   alert("Sss");

  //   // customers["skuList"] = await GetSkuList();
  //   // customers["primaryUnitList"] = await GetPrimaryUnitList();
  //   return [200, customers];
  // });
  mock.onPost("api/orderLineItems").reply(async ({ data }) => {
    const { remark } = JSON.parse(data);
    const { Id, Quantity = "", Weight = "", PrimaryUnit = "" } = remark;
    const id = generateRemarkId();
    const newRemark = {
      id,
      Id,
      Quantity,
      PrimaryUnit,
      Weight,
    };
    const OrderId = window.location.pathname.split("/")[2];
    const respense = await PostOrder(OrderId, newRemark);
    remarkTableMock.push(newRemark);
    return [200, { remark: newRemark }];
  });

  mock.onPost(/api\/orderLineItemsfind\/\d+/).reply(async (config) => {
    const mockUtils = new MockUtils();
    const urls = config.url.split("/");
    const id = urls[2];
    const { queryParams } = JSON.parse(config.data);
    remarkTableMock = await GetOrderLineItems(id);

    const filterdRemarks = mockUtils.baseFilter(remarkTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/orderLineItems/deleteorderLineItems").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = remarkTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        remarkTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/orderLineItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/orderLineItems\/(\d+)/)[1];
    const OrderId = window.location.pathname.split("/")[2];
    const remark = await GetOrderLine(OrderId, id);
    remark.id = id;
    if (!remark) {
      return [400];
    }
    return [200, remark];
  });

  mock.onPut(/api\/orderLineItems\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/orderLineItems\/(\d+)/)[1];
    const OrderId = window.location.pathname.split("/")[2];
    const { remark } = JSON.parse(config.data);
    var postOrder = await PutOrderLine(remark, OrderId, id);
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }
    remarkTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/orderLineItems\/\d+/).reply(async (config) => {
    const OrderId = window.location.pathname.split("/")[2];
    const id = config.url.match(/api\/orderLineItems\/(\d+)/)[1];
    const index = remarkTableMock.findIndex((el) => el.id === +id);
    if (!index === -1) {
      return [400];
    }
    var deleteUser = await DeleteOrder(OrderId, id);
    if (!deleteUser) return [400];
    remarkTableMock.splice(index, 1);
    return [200];
  });
}

function generateRemarkId() {
  const ids = remarkTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
