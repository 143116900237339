import AttachmentsTableMock from "./AttachmentsTableMock";
import LocalStorage from "lowdb/adapters/LocalStorage";
import MockUtils from "./mock.utils";
import low from "lowdb";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

async function GetCarrier(id) {
  return await fetch(window.$apiurl +"/carriers/transport/"+id, {
    method: "get",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json(); 
      return Promise.all([statusCode, data.Attachements]);
    })
    .then((res) => {
      return ChangeId(res[1]);
    })
    .catch((error) => {
      console.error(error);
    });
}
async function PostAttachment(CarrierId, AccessLevelId, AttachmentTypeId,AcceptedFiles) {
  var formdata = new FormData();
  AcceptedFiles.map((file) => {
    formdata.append("File", file, file.name);
  });
  // const inputElement = document.getElementById("carrierAttachment");
  // formdata.append("File", inputElement.files[0], inputElement .value.split("\\")[2]);
  var requestOptions = {
    method: "POST",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
    },
    body: formdata,
  };
  let response = await fetch(`${window.$apiurl}/carrier/attachments/add?carrierId=` + CarrierId + "&accessLevelId=" + AccessLevelId + "&attachmentTypeId=" + AttachmentTypeId, requestOptions);
  let data = await response.json()
  return data;
}
async function DeleteInsurInfo(id) {
  return await fetch(window.$apiurl+"/Carriers/attachment/delete/" + id, {
    method: "delete",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      return response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
//------------------------------------------------------------------------
export default function mockAttachments(mock) {
  mock.onPost("api/Attachments").reply(async ({ data }) => {
    const Data = JSON.parse(data);
    const { remark } = Data;
    const { carId = Data.remark.carId, AccessLevelId = Data.remark.AccessLevelId, AttachmentTypeId =  Data.remark.AttachmentTypeId} = remark;
    const id = generateRemarkId();
    const newRemark = {
      id,
      carId,
      AccessLevelId,
      AttachmentTypeId,
    };
    AttachmentsTableMock.push(newRemark);
    const files = window.$FilesCarriers;
    const datas = await PostAttachment(Data.remark.carId, Data.remark.AccessLevelId, Data.remark.AttachmentTypeId,files);
    if(datas=="200"){
      return [200, { remark: newRemark }];
    }else{
      return [400, { remark: newRemark }];
    }

  });

  mock.onPost(/api\/Attachmentsfind\/\d+/).reply(async config => {
    const urls = config.url.split("/");
    const id = urls[2];
    const product = await GetCarrier(id);
    window.AttachmentCarrierContract = product.filter(
      (word) =>
        String(word.Type).includes(String("Contract"))
    ).length;
    
    // window.AttachmentCarrierInsurance =product.filter(
    //   (word) =>
    //     String(word.Type).includes(String("Insurance"))
    // ).length;
    carTableMock = product;

    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdRemarks = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/Attachments/deleteRemarks").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = AttachmentsTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        AttachmentsTableMock.splice(index, 1);
      }
     
    });

    return [200];
  });

  mock.onGet(/api\/Attachments\/\d+/).reply(config => {
    const id = config.url.match(/api\/Attachments\/(\d+)/)[1];
    const remark = AttachmentsTableMock.find(el => el.id === +id);
    if (!remark) {
      return [400];
    }

    return [200, remark];
  });

  mock.onPut(/api\/Attachments\/\d+/).reply(config => {
    const id = config.url.match(/api\/Attachments\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    const index = AttachmentsTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    AttachmentsTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/Attachments\/\d+/).reply(async config => {
    const id = config.url.match(/api\/Attachments\/(\d+)/)[1];
    var deleteMasterCarrier = await DeleteInsurInfo(id);
    const index = AttachmentsTableMock.findIndex(el => el.id === +id);
    AttachmentsTableMock.splice(index, 1);
    const product = await GetCarrier(id);
    window.AttachmentCarrierContract = product.filter(
      (word) =>
        String(word.Type).includes(String("Contract"))
    ).length;
    
    // window.AttachmentCarrierInsurance =product.filter(
    //   (word) =>
    //     String(word.Type).includes(String("Insurance"))
    // ).length;
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateRemarkId() {
  const ids = AttachmentsTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}