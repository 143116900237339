import React, { useMemo, useEffect, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useProductsUIContext } from "../ReceiptsUIContext";
import axios from "axios";
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { RadioButton } from "../../../../../../_metronic/_partials/controls";
import { InputGroup } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { Notification } from "../../../../Notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import { ButtonToolbar } from "react-bootstrap";
import { Export } from "../../../../../../_metronic/_helpers/ATHelpers";
import { CreatableSelect2 } from "../../../../../../_metronic/_partials/controls";


//Export Filter

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));
export function ProductsFilter({ listLoading }) {
  // Products UI Context
  const productsUIContext = useProductsUIContext();
  const [QueryFilter, setQueryFilter] = useState("");
  const productsUIProps = useMemo(() => {
    return {
      setQueryParams: productsUIContext.setQueryParams,
      queryParams: productsUIContext.queryParams,
    };
  }, [productsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(productsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, productsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      productsUIProps.setQueryParams(newQueryParams);
    }
  };
  const [customersList, setcustomersList] = useState([]);
  const [wareHousesList, setwareHousesList] = useState([]);

  //Filter Function
  const prepareFilter = (queryParams, values) => {
    const { CustomerId,
      WarehouseId,
      datefilter,
      searchText,
      status,
      lastTransNumber,
      firstTransNumber,
      referenceNumber,
      purchaseOrderId,
      receiptAdviceNumber,
      notes,
      startDate,
      endDate,
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};
    if (CustomerId && WarehouseId) {
      filter.CustomerId = +CustomerId;
      filter.WarehouseId = +WarehouseId;
    }
    if (datefilter && startDate && endDate) {
      var startDateFormatter = new Date(startDate).toLocaleString();
      var endDateFormatter = new Date(endDate).toLocaleString();
      filter.StartDate = +new Date(startDateFormatter).getTime();
      filter.EndDate = +new Date(endDateFormatter).getTime();
      filter.DateFilter = datefilter;
    }
    filter.Status = status !== "" ? status : undefined;
    if (referenceNumber) {
      filter.ReferenceNumber = referenceNumber;
    }
    if (purchaseOrderId) {
      filter.PurchaseOrderId = purchaseOrderId;
    }
    if (receiptAdviceNumber) {
      filter.ReceiptAdviceNumber = receiptAdviceNumber;
    }
    if (notes) {
      filter.Notes = notes;
    }
    if (firstTransNumber && lastTransNumber) {
      filter.FirstTransactionId = +firstTransNumber;
      filter.LastTransactionId = +lastTransNumber;
    }
    newQueryParams.filter = filter;
    setQueryFilter(newQueryParams);
    return newQueryParams;
  };
  useEffect(() => {
    fetchOrderAsync();
  }, []);
  const fetchOrderAsync = async () => {
    try {
      setshowProgress(true);
      const result = await axios.get("api/FindReceipt/GetAllApi");


      setcustomersList([{ Id: "", Name: "Unspecified" }].concat(result.data["customersList"]));
      setwareHousesList([{ Id: "", Name: "Unspecified" }].concat(result.data["warhousesList"]));
      setshowProgress(false);
    } catch (e) { }
  };
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));
  const classes = useStyles();
  const [showProgress, setshowProgress] = useState(false);
  const [showProgressWarehouse, setshowProgressWarehouse] = useState(false);
  const handleCustomerChange = async (event, setFieldValue) => {
    setshowProgressWarehouse(true);
    setwareHousesList([]);
    if (event.target.value !== "" && event.target.value !== null) {
      setFieldValue("CustomerId", event.target.value)
      const result = await axios.get(`api/receipts/getwarehouses/${event.target.value}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          setwareHousesList(result.data);
        }
        else
          Notification("error", "This Customer doesn't have access to any warehouse.");
      }
    }
    setshowProgressWarehouse(false);
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  async function fetchMyAPI2(type, entityName) {
    var filter = QueryFilter;
    if (filter) {
      filter.pageSize = 100000;
      const result = await axios.post(`api/FindReceipts/filter`, { "queryParams": filter });
      Export(result.data.entities, type, entityName);
    } else {
      const result = await axios.post(`api/FindReceipts/filter`, { "queryParams": { "filter": { "ReceiptAdviceNumber": "", "Status": undefined }, "sortOrder": "asc", "sortField": "id", "pageNumber": 1, "pageSize": 100000 } });
      Export(result.data.entities, type, entityName);
    }
  }
  return (
    <>
      <Formik
        initialValues={{
          CustomerId: 0,
          WarehouseId: 0,
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <input
              type="button"
              className="d-none"
              id="ReLoadTableId"
              onClick={(e) => {
                if (e.target.value === "0")
                  document.getElementById("ReLoadTableId").value = 1;
                else document.getElementById("ReLoadTableId").value = 0;
                setFieldValue("ReLoadTable", e.target.value);
                handleSubmit();
              }}
            />
            <div className="form-group row">
              <div className="col-lg-12">
                <ButtonToolbar className="float-right btn-text-primary">
                  <Button style={{ height: "38px" }}

                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined" color="primary"
                    onClick={handleClick}
                  >
                    Export as
                                                    <Icon className={classes.rightIcon} >save</Icon>

                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem onClick={(event) => {
                      fetchMyAPI2("excel", "Receipts");

                    }}>
                      <ListItemIcon>
                        <i class="far fa-file-excel"></i>
                      </ListItemIcon>
                      <ListItemText primary="Excel"
                      />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={(event) => {
                      fetchMyAPI2("csv", "Receipts");
                    }} >
                      <ListItemIcon>
                        <i class="fas fa-file-csv"></i>
                      </ListItemIcon>
                      <ListItemText primary="CSV"
                      />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={(event) => {
                      fetchMyAPI2("json", "Receipts");
                    }}>
                      <ListItemIcon>
                        <i class="far fa-file-code"></i>

                      </ListItemIcon>
                      <ListItemText primary="Json" />
                    </StyledMenuItem>
                  </StyledMenu>
                  {/* <Button variant="primary" type="submit">Search</Button> */}
                </ButtonToolbar>
              </div>
            </div>
            {/* Filter By Customer / Warehouse Name */}
            <div className="form-group row">
              <div className="col-lg-6">
                {/* Filter Customer Id 
                  <Select name="CustomerId" label="Customer"
                   // TODO: Change this code
                  onChange={(event) => {
                    setFieldValue("CustomerId", event.target.value);
                    handleSubmit();
                    handleCustomerChange(event, setFieldValue);
                  } 
                  }
                  onBlur={handleBlur}
                  value={values.Id}
                  onClick={() => { }}
                  >
                    <option key="" value="">Unspecified</option>
                    {
                    customersList.map((item, index) => (
                      <option key={index} value={item.Id}>{item.Name}</option>
                    ))}
                  </Select>
                  */}
                <p class="mb-2">Customer Name</p>
                <CreatableSelect2
                  name="CustomerId"
                  options={customersList.map((x) => {
                    return {
                      value: x.Id,
                      label: x.Name,
                    };
                  })}
                  onChange={(newValue) => {
                    var i = newValue?.value;
                    setFieldValue("CustomerId", i);
                    handleSubmit();
                  }}
                />



                {showProgress && <LinearProgress />}
              </div>
              <div className="col-lg-6">
                {/* Filter Warehouse Id 
                  <Select name="WarehouseId" label="Warehouse"
                    // TODO: Change this code
                    onChange={(e) => {
                      setFieldValue("WarehouseId", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.Id}
                  >
                    <option key="" value="">Unspecified</option>
                    {wareHousesList.map((item, index) => (
                      <option key={index} value={item.Id}>{item.Name}</option>
                    ))}
                  </Select>
                  */}
                <p className="mb-2">Warehouse Name</p>
                <CreatableSelect2
                  name="WarehouseId"
                  options={wareHousesList.map((x) => {
                    return {
                      value: x?.Id,
                      label: x?.Name,
                    };
                  })}
                  onChange={(newValue) => {
                    var i = newValue?.value;
                    setFieldValue("WarehouseId", i);
                    handleSubmit();
                  }}
                />
                {showProgressWarehouse && <LinearProgress />}



              </div>
            </div>
            {/* Filter By Date */}
            <div className="form-group row">
              <div className="col-lg-6">
                <p className="mb-2">Date</p>
                <CreatableSelect2
                  name="status"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  options={[{
                    value: "Created",
                    label: "Created date",
                  }, {
                    value: "Expecteddate",
                    label: "Expected date",
                  }, {
                    value: "Arrivaldate",
                    label: "Arrival date",
                  }]}
                  onChange={(newValue) => {
                    var i = newValue?.value;
                    setFieldValue("status", i);
                    handleSubmit();
                  }}
                />
                {/*
                  <Select name="filterDate" label="Filter By"
                    // TODO: Change this code
                    onChange={(e) => {
                      setFieldValue("datefilter", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.datefilter}
                  >
                    <option value="">Select</option>
                    <option value="Created">Creation date</option>
                    <option value="ExcpectedDate">Expected date</option>
                    <option value="ArrivalDate">Arrival date</option>
                  </Select>
                  */}

              </div>
              <div className="col-lg-3">
                {/* Date Start */}
                <p className="mb-2">
                  Start Date
                </p>
                <TextField
                  id="datetime-local"

                  type="datetime-local"
                  defaultValue="--"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur}
                  value={values.startDate}
                  onChange={(e) => {
                    setFieldValue("startDate", e.target.value);
                    handleSubmit();
                  }}
                />

              </div>
              <div className="col-lg-3">
                {/* Date End */}
                <p className="mb-2">
                  End Date
                </p>
                <TextField
                  id="datetime-local2"

                  type="datetime-local"
                  defaultValue="--"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onBlur={handleBlur}
                  value={values.endDate}
                  onChange={(e) => {
                    setFieldValue("endDate", e.target.value);
                    handleSubmit();
                  }}
                />

              </div>
            </div>
            {/* Filter By Reference Id */}
            <div className="form-group row">
              <div className="col-lg-6">
                <p className="mb-2">
                  Reference ID
                </p>
                <InputGroup className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="ReferenceNumber"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.referenceNumber}
                    onChange={(e) => {
                      setFieldValue("referenceNumber", e.target.value);
                      handleSubmit();
                    }}
                  />
                </InputGroup>

              </div>
              <div className="col-lg-6">
                <p className="mb-2">
                  Purchase Order Number
                </p>
                <InputGroup className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="PurchaseOrderId"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.purchaseOrderId}
                    onChange={(e) => {
                      setFieldValue("purchaseOrderId", e.target.value);
                      handleSubmit();
                    }}
                  />
                </InputGroup>

              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6">
                <p className="mb-2">
                  Receipt Advice Number
                </p>
                <InputGroup className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="ReceiptAdviceNumber"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.receiptAdviceNumber}
                    onChange={(e) => {
                      setFieldValue("receiptAdviceNumber", e.target.value);
                      handleSubmit();
                    }}
                  />
                </InputGroup>

              </div>
              <div className="col-lg-6">
                <p className="mb-2">
                  Notes                </p>
                <InputGroup className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="Notes"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.notes}
                    onChange={(e) => {
                      setFieldValue("notes", e.target.value);
                      handleSubmit();
                    }}
                  />
                </InputGroup>

              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-6">
                {/*
                  <Select name="filterDate" label="Filter By"
                    // TODO: Change this code
                    onChange={(e) => {
                      setFieldValue("status", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.status}
                  >
                    <option value="">All</option>
                    <option value="Open">Open (all)</option>
                    <option value="Open(not complete)">Open (not Complete)</option>
                    <option value="Open(complete)">Open (Complete)</option>
                    <option value="Closed">Closed</option>
                    <option value="Cancelled">Cancelled</option>
                  </Select>
                  */}
                <p className="mb-2">
                  Receipt Status                </p>
                <CreatableSelect2
                  name="status"
                  setFieldValue={setFieldValue}
                  onBlur={handleBlur}
                  options={[{
                    value: "",
                    label: "All",
                  }, {
                    value: "Open",
                    label: "Open (all)",
                  },
                  {
                    value: "Open(not complete)",
                    label: "Open(not complete)",
                  }
                    ,
                  {
                    value: "Open (Complete)",
                    label: "Open (Complete)",
                  },
                  {
                    value: "Closed",
                    label: "Closed",
                  }
                    ,
                  {
                    value: "Canceled",
                    label: "Canceled",
                  }]}
                  onChange={(newValue) => {
                    var i = newValue?.value;
                    setFieldValue("status", i);
                    handleSubmit();
                  }}
                />

              </div>

              <div className="col-lg-6">
                <RadioButton name="ReceiptAdviceStatus" label="Receipt Advice Status" row>
                  <FormControlLabel
                    value="All"
                    name="All"
                    control={<Radio color="primary" />}
                    label="All"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="Sent"
                    name="Sent"
                    control={<Radio color="primary" />}
                    label="Sent"
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="NotSent"
                    name="NotSent"
                    control={<Radio color="primary" />}
                    label="Not Sent"
                    labelPlacement="start"
                  />
                </RadioButton>
              </div>
            </div>
            {/*  ///////////////////////// */}
            <div className="form-group row">
              <div className="col-lg-6">
                <p className="mb-2">
                  First Transaction Number               </p>
                <input
                  type="text"
                  className="form-control"
                  name="FirstTransNumber"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.firstTransNumber}
                  onChange={(e) => {
                    setFieldValue("firstTransNumber", e.target.value);
                    handleSubmit();
                  }}
                />

              </div>

              <div className="col-lg-6">
                <p className="mb-2">
                  Last Transaction Number          </p>
                <input
                  type="text"
                  className="form-control"
                  name="LastTransNumber"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.lastTransNumber}
                  onChange={(e) => {
                    setFieldValue("lastTransNumber", e.target.value);
                    handleSubmit();
                  }}
                />

              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
