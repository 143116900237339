import {
  Button, emphasize, Icon, IconButton, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, MenuList, Typography, withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useMemo, useState } from "react";
import { ButtonToolbar, Modal, Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";
import SVG from "react-inlinesvg";
import { useSubheader } from "../../../../../_metronic/layout";
import { ShipmentActions } from "../../../../../_metronic/layout/components/extras/ShipmentActionsBeta";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { Close_aside, Export } from "../../../../../_metronic/_helpers/ATHelpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar
} from "../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../modules/Notification";
import { OneMoveMaster } from "../Transload/FindOrders/product-Containers/remark-edit-dialog/OneMoveMaster";
import { CustomersFilter } from "./customers-filter/CustomersFilter";
import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
import { AllOrderTableTable } from "./customers-table/CustomersTable";
import { useCustomersUIContext } from "./CustomersUIContext";
const adapter = new LocalStorage("db");
const db = low(adapter);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "auto",
  },
  valueContainer: {
    display: "flex",
    flexWrap: "wrap",
    flex: 1,
    alignItems: "center",
    overflow: "hidden",
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === "light"
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  placeholder: {
    position: "absolute",
    left: 2,
    bottom: 6,
    fontSize: 13,
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function CustomersCard() {
  const subHeader = useSubheader();
  useEffect(() => {
    let _title = "Shipments Management";
    subHeader.setTitle(_title);
  }, [subHeader]);
  const classes = useStyles();
  const customersUIContext = useCustomersUIContext();
  const customersUIProps = useMemo(() => {
    return {
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      ids: customersUIContext.ids,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);
  const [ShipmentsCount, setShipmentsCount] = useState([]);
  const [ShipmentsException, setShipmentsException] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [ExceptionsLoading, setExceptionsLoading] = React.useState(false);
  const [ExportLoadingData, setExportLoadingData] = React.useState(false);
  const [OneMoveMasterShow, setOneMoveMasterShow] = useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const [ShipmentNewModel, setShipmentNewModelt] = useState(false);

  useEffect(() => {
    Close_aside();
    window.$ShipmentManagementStatus = "";
    $(document).ready(function() {
      $('.breadcrumb-item [href="/ShipmentsManagement"]').html(`Management`);
    });

    window.SM_GetViewModel = undefined;
  }, []);

  async function PostNewShipment() {
    var label = $("#LabelForShipmentID").val() || "";
    return await fetch(
      `${window.$apiurl}/transload/shipment?label=${label}&orderId=`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        setShipmentNewModelt(false);
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostNewShipment() {
    var label = $("#LabelForShipmentID").val() || "";
    return await fetch(
      `${window.$apiurl}/transload/shipment?label=${label}&orderId=`,
      {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Create New Shipment ID : " + res[1]?.Id);
        setOneMoveMasterShow(res[1]?.Id || false);
        setShipmentNewModelt(false);
        document.getElementById("ReLoadTableId").click();
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    var refreshIntervalId = setInterval(function() {
      var statusCount = window.shipmentCount;
      if (statusCount) {
        setShipmentsCount(window.shipmentCount || []);
        //setShipmentsException(window.shipmentExceptions || []);
        clearInterval(refreshIntervalId);
      }
    }, 500);
  }, [customersUIContext.queryParams]);
  useEffect(() => {
    setTimeout(() => {
      getExceptionAscync();
    }, 500);
  }, [
    window.$ShipmentManagementServiceLevelId,
    customersUIContext.queryParams.filter.equipmentTypeId,
    customersUIContext.queryParams.filter.equipmentSizeId,
    customersUIContext.queryParams.filter.shipFrom,
    customersUIContext.queryParams.filter.Carrier,
    customersUIContext.queryParams.filter.DateRangeType,
    customersUIContext.queryParams.filter.endDate,
    customersUIContext.queryParams.filter.isHot,
    customersUIContext.queryParams.filter.isHazardousMaterial,
    customersUIContext.queryParams.filter.IsHeavy,
    window.$SmGsearchStartDate,
    window.$ShipmentManagementModeId,
    window.$ShipmentManagementServiceId,
    window.$CustomerIdsSm,
  ]);

  async function getExceptionAscync() {
    setExceptionsLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var requestOptions = {
      method: "GET",
      withCredentials: true,
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(
      `${window.$apiurl}/transportation/getexceptions${window.SMLinkUsed}`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        setExceptionsLoading(false);
        setShipmentsException(res[1] || []);
      })
      .catch((error) => {
        console.error(error);
      });
    setExceptionsLoading(false);
  }
  async function fetchMyAPI2(type, entityName) {
    setExportLoadingData(true);
    setAnchorEl(null);
    return await fetch(`${window.$apiurl}${window.exportSMLink}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        if (statusCode === 401) {
          try {
            document.getElementById("401Link").click();
          } catch (err) {
            console.log(err);
          }
        }
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        Export(res[1].Shipments, type, entityName);
        setExportLoadingData(false);
      })
      .catch((error) => {
        console.error(error);
        setExportLoadingData(false);
      });
  }

  return (
    <>
      <Helmet>
        <title>eMPower | Shipment Managment</title>
        <meta name="description" content="This is my meta description" />
      </Helmet>
      <Modal show={ShipmentNewModel}>
        <Modal.Header>
          <Modal.Title>New Shipment</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Shipment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              setShipmentNewModelt(false);
              PostNewShipment();
            }}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={ShipmentNewModel}>
        <Modal.Header>
          <Modal.Title>New Shipment</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div id="ModelShipment" className="text-center">
            <div className="form-group row">
              <div className="col-lg-12 mt-5">
                <input
                  type="text"
                  className="form-control ScrollingMenuApp "
                  id="LabelForShipmentID"
                  placeholder="Label For Shipment"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            className="btn btn-light btn-elevate mr-2"
            onClick={() => {
              setShipmentNewModelt(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            className="btn btn-primary btn-elevate"
            onClick={() => {
              setShipmentNewModelt(false);
              PostNewShipment();
            }}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        show={OneMoveMasterShow !== false}
        onHide={() => {
          setOneMoveMasterShow(false);
        }}
      >
        <OneMoveMaster
          Show={setOneMoveMasterShow}
          statSHipmentId={OneMoveMasterShow}
        />
      </Modal>

      <ShipmentActions />
      <div className="row ShipmentManagment">
        <div className="col-xl-2 col-lg-12 col-md-12 mb-4">
          <div className="row">
            <div className="col-xl-12 col-lg-6 col-md-6 mb-md-6">
              <Card className="h-100">
                <h6 className="font-weight-bold pl-5 pt-3 mt-1">Statuses</h6>
                <MenuList>
                  <MenuItem
                    onClick={async () => {
                      window.$ShipmentManagementStatus = "";
                      window.$ShipmentManagementStatusName = "All";
                      window.$ShipmentManagementexception = "";
                      window.$ShipmentManagementHot = "false";
                      window.$ShipmentManagementHazmat = "false";
                      window.$ShipmentManagementHeavy = "false";
                      $(
                        '.breadcrumb-item [href="/ShipmentsManagement"]'
                      ).html(`Management <b class="ml-5 text-info">${""}</b>`);
                      document.getElementById("ReLoadTableId").click();
                    }}
                  >
                    <span className="svg-icon svg-icon-primary pr-3">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Dial-numbers.svg"
                        )}
                      />
                    </span>
                    <Typography
                      className={`font-weight-bolder ${
                        window.$ShipmentManagementStatus === ""
                          ? "text-info"
                          : ""
                      }`}
                      variant="body2"
                    >
                      ALL Shipments
                    </Typography>
                    <span className="navi-label ml-auto">
                      <span className="label label-light-info label-rounded font-weight-bold">
                        {ShipmentsCount.reduce(
                          (a, b) => a + (b["CountItems"] || 0),
                          0
                        )}
                      </span>
                    </span>
                  </MenuItem>
                  {ShipmentsCount.map((item, index) => (
                    <MenuItem
                      key={index}
                      onClick={async () => {
                        window.$ShipmentManagementStatus = item.StatusId;
                        window.$ShipmentManagementStatusName = item.StatusName;
                        window.$ShipmentManagementexception = "";
                        $(
                          '.breadcrumb-item [href="/ShipmentsManagement"]'
                        ).html(
                          `Management <b class="ml-5 text-info">${" > " +
                            item?.StatusName}</b>`
                        );
                        document.getElementById("ReLoadTableId").click();
                      }}
                    >
                      <span className="svg-icon svg-icon-primary pr-3">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Dial-numbers.svg"
                          )}
                        />
                      </span>
                      <Typography
                        className={`font-weight-bolder ShipmentStatus ${
                          window.$ShipmentManagementStatus === item.StatusId
                            ? "text-info"
                            : ""
                        }`}
                        variant="body2"
                      >
                        {item.StatusName}
                      </Typography>
                      <span className="navi-label ml-auto">
                        <span className="label label-light-info label-rounded font-weight-bold">
                          {item.CountItems}
                        </span>
                      </span>
                    </MenuItem>
                  ))}
                </MenuList>
              </Card>
            </div>
            <div className="col-xl-12 col-lg-6 col-md-6 mb-md-4">
              <Card className="h-100">
                <h6 className="font-weight-bold p-5">Exceptions</h6>
                <button
                  style={{ display: "none" }}
                  id="refreshShipmentsException"
                  onClick={(e) => {
                    getExceptionAscync();
                  }}
                ></button>
                {ExceptionsLoading === true ? (
                  <div className="text-center pb-5">
                    {" "}
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                    />
                  </div>
                ) : (
                  ""
                )}
                {ExceptionsLoading === false
                  ? ShipmentsException.map((item, index) => (
                      <MenuItem
                        className={`font-weight-bolder ${
                          window.$ShipmentManagementexception === item?.Id
                            ? "text-info"
                            : ""
                        }`}
                        key={index}
                        onClick={async () => {
                          window.$ShipmentManagementexception = item?.Id;
                          window.$ShipmentManagementStatus = "";
                          window.$ShipmentManagementStatusName = item?.Name;
                          document.getElementById("ReLoadTableId").click();
                        }}
                      >
                        <span className="svg-icon svg-icon-primary pr-3">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Warning-2.svg"
                            )}
                          />
                        </span>
                        <Typography
                          className="font-weight-bolder"
                          variant="body2"
                        >
                          {item?.Name}
                        </Typography>
                        <span className="navi-label ml-auto">
                          <span className="label label-light-info label-rounded font-weight-bold">
                            {item?.Count}
                          </span>
                        </span>
                      </MenuItem>
                    ))
                  : ""}
              </Card>
            </div>
          </div>
        </div>
        <div className="col-xl-10 col-lg-12 col-md-12" id="ShipmentManagmentId">
          <Card>
            <CardHeader title="Shipments Management">
              <CardHeaderToolbar>
                <ButtonToolbar
                  style={{ float: "right" }}
                  className={"btn-text-primary " + classes.button}
                >
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    disabled={ExportLoadingData}
                    onClick={handleClick}
                  >
                    Export as
                    <Icon className={classes.rightIcon}>save</Icon>
                    {ExportLoadingData && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2(
                          "excel",
                          window.$exportFilterSM.length > 150
                            ? window.$exportFilterSM.slice(0, 150)
                            : window.$exportFilterSM
                        );
                      }}
                    >
                      <ListItemIcon>
                        <i className="far fa-file-excel"></i>
                      </ListItemIcon>
                      <ListItemText primary="Excel" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2(
                          "csv",
                          window.$exportFilterSM.length > 150
                            ? window.$exportFilterSM.slice(0, 150)
                            : window.$exportFilterSM
                        );
                      }}
                    >
                      <ListItemIcon>
                        <i className="fas fa-file-csv"></i>
                      </ListItemIcon>
                      <ListItemText primary="CSV" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2(
                          "json",
                          window.$exportFilterSM.length > 150
                            ? window.$exportFilterSM.slice(0, 150)
                            : window.$exportFilterSM
                        );
                      }}
                    >
                      <ListItemIcon>
                        <i className="far fa-file-code"></i>
                      </ListItemIcon>
                      <ListItemText primary="Json" />
                    </StyledMenuItem>
                  </StyledMenu>
                </ButtonToolbar>
                <ButtonToolbar
                  style={{ float: "right" }}
                  className={"btn-text-primary " + classes.button}
                >
                  <button
                    type="button"
                    className="btn btn-light-primary font-weight-bolder font-size-sm w-100 btn-sm"
                    onClick={() => {
                      setShipmentNewModelt(true);
                    }}
                  >
                    <i className="fas fa-plus-circle"></i> New
                  </button>
                </ButtonToolbar>
              </CardHeaderToolbar>
            </CardHeader>

            <CardBody>
              <CustomersFilter />
              {customersUIProps.ids.length > 0 && <CustomersGrouping />}
              <AllOrderTableTable />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
}
