import React, { useState, useEffect } from "react";
import Draggable from "react-draggable";
import Button from "@material-ui/core/Button";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { Notification } from "../../../../../modules/Notification";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import Checkbox from "@material-ui/core/Checkbox";
import { default as JsNative } from "jquery";
import low from "lowdb";
import SVG from "react-inlinesvg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function Item({
  item,
  SetTableShipment,
  ShipmentID,
  CheckboxList,
  CheckboxListIds,
  POChaekced,
  ProgressBar,
  ParentId,
  setPalletsFun,
  LinePath,
  Locations = [],
  setLoading,
  isEditItem = false,
  setEditItem,
  OSDPallet = false,
  RemoveSku,
}) {
  const [ModelShow, setModelShow] = useState(false);
  const [NewPalletShow, setNewPalletShow] = useState(false);
  const [stateItem, setstateItem] = useState();
  const [Quantity, setQuantity] = useState(0);
  const [Color, setColor] = useState("primary");
  const [PalletsId, setPalletsId] = useState();

  var tableElement, offset;
  function GetTale() {
    if (!OSDPallet)
      tableElement = JsNative(".TableComponent" + stateItem?.ConsigneeId);
    else tableElement = JsNative(".AllTableComponent");
  }

  useEffect(() => {
    if (item?.PoBar) item.Id = 0;
    setQuantity(item?.LeftQuantity);

    setstateItem(item);
    if (
      item?.DamagedQuantity > 0 ||
      item?.OverQuantity > 0 ||
      item?.ShortQuantity > 0
    )
      setColor("warning");
    else if (item.LeftQuantity === 0) setColor("dark");
  }, [item]);

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];
    for (var item in tableElement) {
      var ele = tableElement.eq(item).find(".shadow-sm");
      var thisOffset = ele.offset();
      if (CheckPosition(thisOffset, page)) {
        ele.addClass("bg-light-info");
      } else ele.removeClass("bg-light-info");
      ele.addClass("border border-info border-2");
    }
  }

  function handleStop(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];
    for (var item in tableElement) {
      var ele = tableElement.eq(item).find(".shadow-sm");
      var thisOffset = ele.offset();
      if (CheckPosition(thisOffset, page)) {
        setPalletsId(tableElement.eq(item).attr("id"));
        setQuantity(stateItem?.LeftQuantity ?? 0);
        setModelShow(true);
      }
      ele.removeClass("bg-light-info");
      ele.removeClass("border border-info border-2");
    }
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleSave() {
    PostPallet(stateItem?.Id, PalletsId, +Quantity).then(
      ({ status, Message }) => {
        if (200 === status) {
          Notification("success", "success : " + stateItem?.Id);
          var thisItem = stateItem;
          thisItem.LeftQuantity -= +Quantity;
          thisItem.Id = stateItem?.Id;
          setstateItem();
          SetTableShipment(PalletsId, +Quantity);
          if (OSDPallet) RemoveSku(stateItem?.Id);
          else ProgressBar(stateItem?.ParentId, +Quantity);
          setTimeout(() => {
            setstateItem(thisItem);
            setQuantity(item.LeftQuantity);
            setPalletsId();
          }, 1);
        } else {
          Notification("error", Message?.toString());
        }
        if (item.LeftQuantity === 0) setColor("dark");
      }
    );
  }

  function htmlP2(text2) {
    return (
      <>
        <b style={{ fontSize: "11px" }}>{text2}</b>
      </>
    );
  }

  return (
    <>
      <>
        <Modal show={ModelShow}>
          <Modal.Header closeButton>
            <Modal.Title>Item {stateItem?.Container}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <div className="col-lg-4 text-left">
                <label>
                  Maximum Quantity
                  {Color === "warning" && (
                    <span>
                      <br />
                      (Includes
                      {stateItem?.OverQuantity > 0 && (
                        <b className="text-danger ml-2">O</b>
                      )}
                      {stateItem?.ShortQuantity > 0 && (
                        <b className="text-danger ml-2">S</b>
                      )}
                      {stateItem?.DamagedQuantity > 0 && (
                        <b className="text-danger ml-2">D</b>
                      )}
                      )
                    </span>
                  )}
                </label>
              </div>
              <div className="col-lg-8">
                <input
                  className="form-control text-center"
                  value={stateItem?.LeftQuantity}
                  disabled
                />
              </div>
              <div className="col-lg-4 mt-5 text-left">
                <label className="pt-3">Quantity to ship</label>
              </div>
              <div className="col-lg-8 mt-5">
                <input
                  id="QuantitytoshipId"
                  min="0"
                  disabled={OSDPallet}
                  max={stateItem?.LeftQuantity}
                  className="form-control text-center"
                  value={Quantity}
                  type="number"
                  onChange={(e) => {
                    var value = e.target?.value ?? "";
                    setQuantity(value);
                    if (
                      value === "" ||
                      +value === 0 ||
                      +value > stateItem?.LeftQuantity
                    ) {
                      Notification(
                        "warning",
                        "Should value between 1 to " + stateItem?.LeftQuantity
                      );
                    }
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              className="btn btn-light btn-elevate mr-2"
              onClick={() => {
                setModelShow(false);
              }}
            >
              Close
            </Button>
            <Button
              variant="primary"
              disabled={
                Quantity === "" ||
                +Quantity === 0 ||
                +Quantity > stateItem?.LeftQuantity
              }
              className="btn btn-primary btn-elevate"
              onClick={() => {
                setModelShow(false);
                handleSave();
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        {/* New Pallet */}
        {(Color !== "dark" || isEditItem) && (
          <>
            <Modal show={NewPalletShow} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>New Pallet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group row">
                  <div className="col-6">
                    {IsValid("Customer", stateItem?.CustomerName)}
                  </div>
                  <div className="col-6">
                    {IsValid("To Consignee", stateItem?.Location)}
                  </div>
                  <div className="col-6">
                    {IsValid("Shipper", stateItem?.ShipperName)}
                  </div>
                  <div className="col-6">
                    {IsValid("Warehouse", stateItem?.WarehouseName)}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  className="btn btn-light btn-elevate mr-2"
                  onClick={() => {
                    setNewPalletShow(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  variant="success"
                  className="btn btn-success btn-elevate"
                  onClick={() => {
                    setNewPalletShow(false);
                    PostModel(
                      {
                        CustomerId: stateItem?.CustomerId,
                        ConsigneeId: stateItem?.ConsigneeId,
                        ShipperId: stateItem?.ShipperId,
                        WarehouseId: stateItem?.WarehouseId,
                      },
                      setPalletsFun
                    );
                  }}
                >
                  Create
                </Button>
              </Modal.Footer>
            </Modal>

            <div
              className="col-xl-4 col-lg-4 col-md-4 my-3"
              style={{ zIndex: 2 }}
            >
              <Draggable
                axis="both"
                handle=".handle"
                position={{ x: 0, y: 0 }}
                grid={[1, 1]}
                scale={1}
                onDrag={handleDrag}
                onStop={handleStop}
              >
                <div className={Color !== "dark" && "handle"}>
                  <div
                    className={
                      "shadow-sm p-0 pl-2 pt-5 mb-5 rounded bg-" +
                      (Color === "dark" ? "secondary" : "white")
                    }
                    style={{
                      height: "12vw",
                      maxHeight: "130px",
                      cursor: "pointer",
                    }}
                  >
                    <b
                      className="position-absolute"
                      style={{
                        top: "0.8vw",
                        fontSize: "1vw",
                        fontWeight: "bold",
                      }}
                    >
                      <i
                        className="fas fa-chevron-circle-right text-info mr-1"
                        style={{
                          fontSize: "0.8vw",
                        }}
                      ></i>
                      Sku : {stateItem?.Sku ?? "(Sku Not Found)"}
                    </b>
                    <b
                      className="position-absolute pt-2"
                      style={{
                        top: "2vw",
                        fontSize: "1vw",
                        fontWeight: "bold",
                      }}
                    >
                      <i
                        className="fas fa-chevron-circle-right text-info mr-1"
                        style={{
                          fontSize: "0.8vw",
                        }}
                      ></i>
                      PO# : {stateItem?.PurchaseOrder ?? "Empty"}
                    </b>

                    {isEditItem && !OSDPallet && (
                      <b
                        className="position-absolute p-3"
                        style={{ right: "-25px", top: "-22px", zIndex: 2 }}
                        onClick={(e) => {
                          e.preventDefault();
                          GetItem(stateItem?.Id, setEditItem);
                          e.stopPropagation();
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          GetItem(stateItem?.Id, setEditItem);
                          e.stopPropagation();
                        }}
                      >
                        <i
                          className={"fas fa-edit text-warning"}
                          style={{ fontSize: "25px" }}
                        />
                      </b>
                    )}
                    {!OSDPallet && (
                      <b
                        className="position-absolute p-3"
                        style={{ right: "-25px", bottom: "-27px", zIndex: 2 }}
                        onClick={(e) => {
                          e.preventDefault();
                          setNewPalletShow(true);
                          e.stopPropagation();
                        }}
                        onTouchEnd={(e) => {
                          e.preventDefault();
                          setNewPalletShow(true);
                          e.stopPropagation();
                        }}
                      >
                        <i
                          className={"fas fa-plus-circle text-success"}
                          style={{ fontSize: "25px" }}
                        />
                      </b>
                    )}
                    <i
                      className="fas fa-cube position-absolute text-info"
                      style={{
                        fontSize: "5vw",
                        opacity: "0.1",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    ></i>
                    <b
                      style={{
                        fontSize: "4vw",
                        color: "rgba(0, 0, 0, 0.7)",
                        position: "absolute",
                        top: "70%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {stateItem?.LeftQuantity}
                    </b>
                  </div>
                </div>
              </Draggable>
            </div>
          </>
        )}
      </>
    </>
  );
}

async function PostPallet(ItemId, PalletId, UnitCount) {
  return await fetch(`${window.$apiurl}/pallets/AddCartonToPallet`, {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ItemId: ItemId,
      PalletId: PalletId,
      Quantity: UnitCount,
    }),
  }).then(async (response) => {
    var msg = response.status !== 200 ? await response.json() : "";
    return {
      status: response.status,
      Message: msg?.Message ?? "",
    };
  });
}

function IsValid(name, str, Components = false) {
  try {
    if (str?.includes("null") || str?.includes("undefined")) str = undefined;
  } catch (error) {}
  return (
    <>
      <div className="row w-100 my-2 ml-3">
        <div className="col-lg-4 font-weight-bold text-dark text-left pl-5">
          {name}
        </div>
        <div className="col-lg-8 text-primary">
          {Components ? (
            Components
          ) : (
            <>: {str || <span className="text-muted">Empty</span>}</>
          )}
        </div>
      </div>
    </>
  );
}

async function PostModel(someData, setPalletsFun) {
  return fetch(window.$apiurl + "/Pallets", {
    method: "post",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        setPalletsFun(res[1].palletVm);
        Notification("success", "Pallet is Created Success");
      }
    })
    .catch((err) => {
      Notification("error", "Oops");
    });
}

async function GetItem(id, setEditItem) {
  return fetch(
    window.$apiurl + "/transload/receipts/getlineitems?itemId=" + id,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        var el1 = document.querySelector(
          ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
        );
        window.ReceiptId = el1?.parentElement
          ?.getAttribute("id")
          ?.split("id")[1];

        setEditItem(res[1]["LineItems"][0]);
      }
    })
    .catch((err) => {
      Notification("error", "Oops");
    });
}
