import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { momentDate } from "../../../../../../../_metronic/_helpers/ATHelpers";
import { CreatableSelect2 } from "../../../../../../../_metronic/_partials/controls";
import { Notification } from "../../../../../Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function OrderNotes({ id, Type, newOnly = false }) {
  const [NotesList, setNotesList] = useState([]);
  const [CategorysList, setCategorysList] = useState([]);
  const [AccessLevels, setAccessLevels] = useState([]);
  const [ListNewOnly, setListNewOnly] = useState(false);
  const [NewText, setNewText] = useState("");
  useEffect(() => {
    if (id) {
      async function fetchMyAPI() {
        getNots();
      }
      fetchMyAPI();
    }
  }, [id]);

  async function GetNotes(id) {
    return await fetch(`${window.$apiurl}/transload/${Type}/getnotes/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostNote(id, bodytext, cat, AccessLevelId) {
    return await fetch(`${window.$apiurl}/transload/${Type}/addnote/${id}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: bodytext,
        CategoryId: cat,
        AccessLevelId: AccessLevelId,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note has been saved successfully");
      })
      .catch((error) => {
        Notification("success", "Note has been saved successfully");
      });
  }

  async function DeleteNote(id) {
    return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      })
      .catch((error) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      });
  }
  function CardFunction({ x, index }) {
    const [Showing, setShowing] = useState(false);
    const [Text, setText] = useState("");
    const [Category, setCategory] = useState("");

    async function PutNote(id, bodytext, CategoryId) {
      return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: bodytext, CategoryId: CategoryId }),
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then(async (res) => {
          Notification("success", "Note has been saved successfully");
          setShowing(false);
        })
        .catch((error) => {
          Notification("success", "Note has been saved successfully");
          setShowing(false);
        });
    }
    const [titleBtnDeletePut, setTitleBtnDeletePut] = useState("Delete");
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <span className="mb-2 text-primary d-inline">
              {momentDate(x?.CreatedAt)}
              <span className="mb-2 ml-2 text-muted d-inline">
                {x?.UpdatedAt !== null &&
                  ` - edited (${momentDate(x?.UpdatedAt)})`}
              </span>
            </span>
            <br />
            {x?.CategoryId !== null &&
              CategorysList.find((y) => y.Id === x?.CategoryId)?.Name !==
                undefined && (
                <div className="mb-2 mt-3 text-muted">
                  <i class="fas fa-book mr-2"></i>{" "}
                  {CategorysList.find((y) => y.Id === x?.CategoryId)?.Name}
                </div>
              )}
            <Card.Text className="mt-5 ml-5">
              {!Showing && (!Text ? x?.Body : Text)}
              {Showing && (
                <>
                  <select
                    defaultValue={x?.CategoryId || ""}
                    className="form-control form-control-solid border"
                    required={true}
                    onChange={(event) => {
                      setCategory(event.target.value);
                    }}
                  >
                    <option value="">Select Category</option>
                    {CategorysList.map((item, index) => (
                      <option value={item?.Id} key={index}>
                        {item?.Name}
                      </option>
                    ))}
                  </select>
                  <textarea
                    className="form-control mb-5 mt-5"
                    rows="4"
                    defaultValue={!Text ? x?.Body : Text}
                    onBlur={(event) => {
                      setText(event.target.value);
                    }}
                  ></textarea>
                </>
              )}
            </Card.Text>
            {db.getState().User?.Email === x?.UserEmail && (
              <div
                className="text-right"
                style={{ position: "relative", bottom: "3", right: "-3px" }}
              >
                <button
                  type="button"
                  class="btn btn-light-primary mr-2"
                  onClick={async () => {
                    if (Showing) {
                      await PutNote(
                        x?.Id,
                        Text === "" ? x?.Body : Text,
                        Category === "" ? x?.CategoryId : Category
                      );
                      getNots();
                    } else {
                      setTitleBtnDeletePut("Close");
                      setShowing(true);
                    }
                  }}
                >
                  {!Showing && (
                    <>
                      <i class="fas fa-edit mr-2"></i> Edit
                    </>
                  )}
                  {Showing && (
                    <>
                      <i class="fas fa-save mr-2"></i> Save
                    </>
                  )}
                </button>

                {db.getState().User?.Email === x?.UserEmail && (
                  <button
                    type="button"
                    class="btn btn-light-danger mr-2 ml-3"
                    onClick={async () => {
                      if (titleBtnDeletePut === "Delete") {
                        await DeleteNote(x?.Id);
                        getNots();
                      } else {
                        setTitleBtnDeletePut("Delete");
                        setShowing(false);
                      }
                    }}
                  >
                    {titleBtnDeletePut === "Delete" && (
                      <i class="fas fa-trash mr-2"></i>
                    )}
                    {titleBtnDeletePut === "Close" && (
                      <i class="fas fa-times"></i>
                    )}
                    {titleBtnDeletePut}
                  </button>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getNots() {
    var a = await GetNotes(id);
    var list = a?.Notes || [];

    if (ListNewOnly === false && newOnly) {
      setListNewOnly([]);
      list.forEach((x, i) => {
        setListNewOnly((y) => [...y, x.Id]);
      });
      list = [];
    } else if (newOnly) {
      list.forEach((x, i) => {
        var find = ListNewOnly.find((y) => y === x.Id);
        if (find) x.notShowing = true;
      });
    }

    setNotesList(list);
    setCategorysList(a?.Categories || []);
    setAccessLevels(a?.AccessLevels || []);
  }
  return (
    <>
      <div className=" m-auto mb-5">
        <button
          type="button"
          class="btn btn-light-primary mr-2 w-100 mb-5"
          onClick={() => {
            $(".NotesId").toggle("slow");
          }}
        >
          <i class="fas fa-plus mr-2"></i> Add New Note
        </button>
      </div>

      <div className=" m-auto mb-5 NotesId" style={{ display: "none" }}>
        <label>Text</label>
        <textarea
          className="form-control mb-5"
          rows="4"
          id="NewTextNots"
          onBlur={(e) => setNewText(e.target.value)}
        ></textarea>
        <div className="row">
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="CategoryId"
              label="Select Category"
              options={CategorysList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
            />
          </div>
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="AccessLevelId"
              id="AccessLevelId"
              label="Access Level"
              options={AccessLevels.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              placeholder="Access Level"
            />
          </div>
          <div className="m-auto col-lg-4 text-center">
            <label className="text-white">.</label>
            <button
              type="button"
              class="btn btn-light-success mr-2 w-100 mb-5 m-auto"
              onClick={async () => {
                if (NewText === "") Notification("warning", "Text is Empty");
                else {
                  await PostNote(
                    id,
                    NewText,
                    $('[name="CategoryId"]').val(),
                    $('[name="AccessLevelId"]').val()
                  );
                  getNots();
                  setTimeout(() => {
                    setNewText("");
                    $(".NotesId").toggle("slow");
                    $("#NewTextNots").val("");
                  }, 50);
                }
              }}
            >
              <i class="fas fa-save mr-2"></i> Save
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>

      {(NotesList ?? []).filter((x) => x?.notShowing !== true)?.map((x, index) => (
        <CardFunction x={x} key={index} index={index} New={() => getNots()} />
      ))}
    </>
  );
}
export function OrderNotesV2({ id, Type,notesList,AccessLevels,CategorysList, newOnly = false }) {
  const [ListNewOnly, setListNewOnly] = useState(false);
  const [NotesList, setNotesList] = useState(notesList === null ?[]:notesList);
  const [NewText, setNewText] = useState("");
  // useEffect(() => {
  //   if (id) {
  //     async function fetchMyAPI() {
  //       getNots();
  //     }
  //     fetchMyAPI();
  //   }
  // }, [id]);

  async function GetNotes(id) {
    return await fetch(`${window.$apiurl}/transload/${Type}/getnotes/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function PostNote(id, bodytext, cat, AccessLevelId) {
    return await fetch(`${window.$apiurl}/transload/${Type}/addnote/${id}`, {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        body: bodytext,
        CategoryId: cat,
        AccessLevelId: AccessLevelId,
      }),
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        Notification("success", "Note has been saved successfully");
      })
      .catch((error) => {
        Notification("success", "Note has been saved successfully");
      });
  }

  async function DeleteNote(id) {
    return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
      method: "Delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then(async (res) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      })
      .catch((error) => {
        getNots();
        Notification("success", "Note is Deleted successfully");
      });
  }
  function CardFunction({ x, index }) {
    const [Showing, setShowing] = useState(false);
    const [Text, setText] = useState("");
    const [Category, setCategory] = useState("");

    async function PutNote(id, bodytext, CategoryId) {
      return await fetch(`${window.$apiurl}/generic/notes/${id}`, {
        method: "put",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: bodytext, CategoryId: CategoryId }),
      })
        .then(async (response) => {
          const statusCode = await response.status;
          const data = await response.json();
          return Promise.all([statusCode, data]);
        })
        .then(async (res) => {
          Notification("success", "Note has been saved successfully");
          setShowing(false);
        })
        .catch((error) => {
          Notification("success", "Note has been saved successfully");
          setShowing(false);
        });
    }
    const [titleBtnDeletePut, setTitleBtnDeletePut] = useState("Delete");
    return (
      <>
        <Card
          className={
            "mb-2 m-auto border-secondary " + (index % 2 === 0 && "bg-light")
          }
        >
          <Card.Body>
            <Card.Title className="d-inline mr-5">
              <i class="fas fa-user mr-2"></i> {x?.CreatedBy}
            </Card.Title>
            <span className="mb-2 text-primary d-inline">
              {momentDate(x?.CreatedAt)}
              <span className="mb-2 ml-2 text-muted d-inline">
                {x?.UpdatedAt !== null &&
                  ` - edited (${momentDate(x?.UpdatedAt)})`}
              </span>
            </span>
            <br />
            {x?.CategoryId !== null &&
              CategorysList.find((y) => y.Id === x?.CategoryId)?.Name !==
                undefined && (
                <div className="mb-2 mt-3 text-muted">
                  <i class="fas fa-book mr-2"></i>{" "}
                  {CategorysList.find((y) => y.Id === x?.CategoryId)?.Name}
                </div>
              )}
            <Card.Text className="mt-5 ml-5">
              {!Showing && (!Text ? x?.Body : Text)}
              {Showing && (
                <>
                  <select
                    defaultValue={x?.CategoryId || ""}
                    className="form-control form-control-solid border"
                    required={true}
                    onChange={(event) => {
                      setCategory(event.target.value);
                    }}
                  >
                    <option value="">Select Category</option>
                    {CategorysList.map((item, index) => (
                      <option value={item?.Id} key={index}>
                        {item?.Name}
                      </option>
                    ))}
                  </select>
                  <textarea
                    className="form-control mb-5 mt-5"
                    rows="4"
                    defaultValue={!Text ? x?.Body : Text}
                    onBlur={(event) => {
                      setText(event.target.value);
                    }}
                  ></textarea>
                </>
              )}
            </Card.Text>
            {db.getState().User?.Email === x?.UserEmail && (
              <div
                className="text-right"
                style={{ position: "relative", bottom: "3", right: "-3px" }}
              >
                <button
                  type="button"
                  class="btn btn-light-primary mr-2"
                  onClick={async () => {
                    if (Showing) {
                      await PutNote(
                        x?.Id,
                        Text === "" ? x?.Body : Text,
                        Category === "" ? x?.CategoryId : Category
                      );
                      getNots();
                    } else {
                      setTitleBtnDeletePut("Close");
                      setShowing(true);
                    }
                  }}
                >
                  {!Showing && (
                    <>
                      <i class="fas fa-edit mr-2"></i> Edit
                    </>
                  )}
                  {Showing && (
                    <>
                      <i class="fas fa-save mr-2"></i> Save
                    </>
                  )}
                </button>

                {db.getState().User?.Email === x?.UserEmail && (
                  <button
                    type="button"
                    class="btn btn-light-danger mr-2 ml-3"
                    onClick={async () => {
                      if (titleBtnDeletePut === "Delete") {
                        await DeleteNote(x?.Id);
                        getNots();
                      } else {
                        setTitleBtnDeletePut("Delete");
                        setShowing(false);
                      }
                    }}
                  >
                    {titleBtnDeletePut === "Delete" && (
                      <i class="fas fa-trash mr-2"></i>
                    )}
                    {titleBtnDeletePut === "Close" && (
                      <i class="fas fa-times"></i>
                    )}
                    {titleBtnDeletePut}
                  </button>
                )}
              </div>
            )}
          </Card.Body>
        </Card>
        <br />
      </>
    );
  }
  async function getNots() {
    var a = await GetNotes(id);
    var list = a?.Notes || [];

    if (ListNewOnly === false && newOnly) {
      setListNewOnly([]);
      list.forEach((x, i) => {
        setListNewOnly((y) => [...y, x.Id]);
      });
      list = [];
    } else if (newOnly) {
      list.forEach((x, i) => {
        var find = ListNewOnly.find((y) => y === x.Id);
        if (find) x.notShowing = true;
      });
    }

    setNotesList(list);
  }
  return (
    <>
      <div className=" m-auto mb-5">
        <button
          type="button"
          class="btn btn-light-primary mr-2 w-100 mb-5"
          onClick={() => {
            $(".NotesId").toggle("slow");
          }}
        >
          <i class="fas fa-plus mr-2"></i> Add New Note
        </button>
      </div>

      <div className=" m-auto mb-5 NotesId" style={{ display: "none" }}>
        <label>Text</label>
        <textarea
          className="form-control mb-5"
          rows="4"
          id="NewTextNots"
          onBlur={(e) => setNewText(e.target.value)}
        ></textarea>
        <div className="row">
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="CategoryId"
              label="Select Category"
              options={CategorysList.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
              }}
            />
          </div>
          <div className="mb-5 col-lg-4 w-100 mt-5">
            <CreatableSelect2
              name="AccessLevelId"
              id="AccessLevelId"
              label="Access Level"
              options={AccessLevels.map((x) => {
                return {
                  value: x?.Id,
                  label: x?.Name,
                };
              })}
              onChange={(newValue) => {
                var a = newValue?.value;
              }}
              placeholder="Access Level"
            />
          </div>
          <div className="m-auto col-lg-4 text-center">
            <label className="text-white">.</label>
            <button
              type="button"
              class="btn btn-light-success mr-2 w-100 mb-5 m-auto"
              onClick={async () => {
                if (NewText === "") Notification("warning", "Text is Empty");
                else {
                  var a = await PostNote(
                    id,
                    NewText,
                    $('[name="CategoryId"]').val(),
                    $('[name="AccessLevelId"]').val()
                  );
                  getNots();
                  setTimeout(() => {
                    setNewText("");
                    $(".NotesId").toggle("slow");
                    $("#NewTextNots").val("");
                  }, 50);
                }
              }}
            >
              <i class="fas fa-save mr-2"></i> Save
            </button>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>

      {NotesList.filter((x) => x?.notShowing !== true)?.map((x, index) => (
        <CardFunction x={x} key={index} index={index} New={() => getNots()} />
      ))}
    </>
  );
}