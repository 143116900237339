import MockUtils from "../mock.utils";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);
let customerTableMock;
export default function mockManageContacts(mock) {
  //Status Message
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.statusText;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (status === 400) {
      Notification("error", statusText);
      return false;
    }
  }

  //Get all contacts
  async function GetAllContacts() {
    return await fetch(window.$apiurl + "/contact", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data.Contacts]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //Delete Contact
  async function DeleteContact(id) {
    return await fetch(`${window.$apiurl}/contact/${id}`, {
      method: "delete",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }
  //Get View Model
  async function GetViewModelCompanies() {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Compagnies;
      })
      .catch((err) => {
        return false;
      });
  }
  async function GetViewModelCountries() {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Countries;
      })
      .catch((err) => {
        return false;
      });
  }
  async function GetViewModelGenders() {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.Genders;
      })
      .catch((err) => {
        return false;
      });
  }
  async function GetViewModelContactCategories() {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res.ContactCategories;
      })
      .catch((err) => {
        return false;
      });
  }
  //Fill Select
  mock.onGet("api/ManageContacts/GetAllApi").reply(async () => {
    return await fetch(window.$apiurl + "/contact/getviewmodel", {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        var contacts = {};
        contacts["CompaniesList"] = res.Compagnies;
        contacts["CountriesList"] = res.Countries;
        contacts["GendersList"] = res.Genders;
        contacts["CategoriesList"] = res.ContactCategories;
        return [200, contacts];
      })
      .catch((err) => {
        return false;
      });
  });

  //Post Contact
  async function PostContact(someData) {
    return fetch(window.$apiurl + "/contact", {
      method: "post",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        window.$statusPostContact = statusCode;
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }

  //Get {id}
  async function GetContact(id) {
    return await fetch(window.$apiurl + "/contact/" + id, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    }).then(async (response) => {
      const data = await response.json();
      return data.Contact;
    });
  }

  //Put
  async function PutContact(someData, id) {
    return fetch(window.$apiurl + "/contact/" + id, {
      method: "put",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        window.$statusCodePut = statusCode;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        //console.error(error);
      });
  }
  mock.onPost("api/ManageContacts").reply(async ({ data }) => {
    const { customer } = JSON.parse(data);
    const {
      Gender = "",
      CategoryId = "",
      Name = "",
      LastName = "",
      WorkPhone = "",
      HomePhone = "",
      Fax = "",
      CellPhone = "",
      Email = "",
      BirthDate = "",
      CompanyId = "",
      Address = "",
      State = "",
      City = "",
      PostalCode = "",
      CountryId = "",
    } = customer;

    const id = generateUserId();
    const newContact = {
      Gender,
      CategoryId,
      Name,
      LastName,
      WorkPhone,
      HomePhone,
      Fax,
      CellPhone,
      Email,
      BirthDate,
      CompanyId,
      State,
      Address,
      PostalCode,
      City,
      CountryId,
    };
    var postContact = await PostContact(newContact);
    //Manage Data Result
    if (postContact.Id != undefined) {
      Notification("success", "Contact has been added successfully");
      postContact.id = postContact.Id;
      customerTableMock.push(newContact);
      document.getElementById("ReLoadTableId").click();
      return [
        200,
        { customer: newContact, message: postContact.Message, success: true },
      ];
    } else {
      if (postContact.Message) {
        document.getElementById("ReLoadTableId").click();
        Notification("error", postContact.Message);
      } else {
        Notification("error", "Error occured");
        document.getElementById("ReLoadTableId").click();
        return [400, { message: postContact.Message, success: false }];
      }
    }
  });

  mock.onPost("api/ManageContacts/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllContacts();
    //Filter
    if (
      queryParams.filter.Address !== "" &&
      queryParams.filter.Address !== undefined
    ) {
      customerTableMock = customerTableMock.filter(
        (word) =>
          String(word.Address)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.Email)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.Name)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.id)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.CompanyName)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.PhoneNumber)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase()) ||
          String(word.LastName)
            .toLowerCase()
            .includes(String(queryParams.filter.Address).toLowerCase())
      );
    }
    delete queryParams.filter.ReLoadTable;
    delete queryParams.filter.Address;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageContacts/filter").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    customerTableMock = await GetAllContacts();
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/ManageContacts/deleteCustomers").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/ManageContacts/updateStatusForCustomers").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    customerTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/ManageContacts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageContacts\/(\d+)/)[1];
    var resultById = await GetContact(id);
    console.log(resultById);
    if (!resultById) {
      return [400];
    }
    return [200, resultById];
  });

  mock.onPut(/api\/ManageContacts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageContacts\/(\d+)/)[1];
    const { customer } = JSON.parse(config.data);
    await PutContact(customer, id);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock[index] = { ...customer };
    document.getElementById("ReLoadTableId").click();
    Notification("success", "Contact has been updated successfully");
    return [200];
  });

  mock.onDelete(/api\/ManageContacts\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/ManageContacts\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    var deleteObj = await DeleteContact(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }
    return [200];
  });
}

function generateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
