// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import {
  Input,
  Select,
} from "../../../../../../_metronic/_partials/controls";
import axios from "axios";
import { Notification } from "../../../../Notification";
import LinearProgress from "@material-ui/core/LinearProgress";

// Validation schema
const CustomerEditSchema = Yup.object().shape({
  Carrier: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Carrier is required"),
  ServiceLevel: Yup.string()
    .min(3, "Minimum 3 symbols")
    .required("ServiceLevel is required"),
  ZoneName: Yup.string()
    .min(5, "Minimum 3 symbols")
    .required("ZoneName is required"),
  CustomerId: Yup.string()
    .required("ZoneName is required"),
  WarehouseId: Yup.string()
    .required("ZoneName is required"),
});
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
}));
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [customersList, setcustomersList] = useState([]);
  const [wareHousesList, setwareHousesList] = useState([]);
  const [showProgress, setshowProgress] = useState(false);
  useEffect(() => {
    fetchOrderAsync();
  }, []);
  const fetchOrderAsync = async () => {
    try {
      const result = await axios.get("api/FindReceipt/GetAllApi");
      setcustomersList(result.data["customersList"]);
      setwareHousesList(result.data["warhousesList"]);
    } catch (e) { }
  };

  const handleCustomerChange = async (event, setFieldValue) => {
    setshowProgress(true);
    setwareHousesList([]);
    if (event.target.value !== "" && event.target.value !== null) {
      setFieldValue("CustomerId", event.target.value)
      const result = await axios.get(`api/receipts/getwarehouses/${event.target.value}`);
      if (result.status === 200) {
        if (result.data.length > 0) {
          setwareHousesList(result.data);
        }
        else
        {
          Notification("error", "This Customer doesn't have access to any warehouse.");
        }
      }
    }
    setshowProgress(false);
  }
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          values.id = values.Id;
          saveCustomer(values);
        }}
      >
        {({ handleSubmit ,setFieldValue}) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-6">
                    <Select name="CustomerId" label="Choose a Customer"
                    onChange={(event) => handleCustomerChange(event, setFieldValue)}
                    >
                      <option key ="" value="0">Select</option>
                      {customersList.map((item, index) => (
                        <option key={index} value={item.Id}>{item.Name}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-lg-6">
                    <Select name="WarehouseId" label="Warehouse">
                      <option  key ="" value="">Select</option>
                      {wareHousesList.map((item, index) => (
                        <option key={index} value={item.Id}>{item.Name}</option>
                      ))}
                    </Select>
                    {showProgress && <LinearProgress />}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-2">
                    {/* Carrier */}
                    <Field
                      type="text"
                      name="Carrier"
                      component={Input}
                      placeholder="CARRIER"
                      label="CARRIER"
                    />
                  </div>

                  <div className="col-lg-4">
                    {/* ServiceLevel */}
                    <Field
                      type="text"
                      name="ServiceLevel"
                      component={Input}
                      placeholder="SERVICELEVEL"
                      label="SERVICELEVEL"
                    />
                  </div>
                  <div className="col-lg-2">
                    {/* ZoneName */}
                    <Field
                      type="text"
                      name="ZoneName"
                      component={Input}
                      placeholder="ZONENAME"
                      label="ZONENAME"
                    />
                  </div>
                  <div className="col-lg-2">
                    {/* Weight */}
                    <Field
                      type="number"
                      name="Weight"
                      component={Input}
                      placeholder="WEIGHT"
                      label="WEIGHT"
                    />
                  </div>
                  <div className="col-lg-2">
                    {/* Rate */}
                    <Field
                      type="number"
                      name="Rate"
                      component={Input}
                      placeholder="RATE"
                      label="RATE"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-success btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
