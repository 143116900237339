import React from 'react'
import { Form, Card, Col } from "react-bootstrap";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 200px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));
export function SmartParcelSettings() {
    const classes = useStyles();
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>
                        <Form.Group as={Col} controlId="formGridEmail">
                            <div title="Manage Roles">
                                <div className="mb-4">

                                    <button
                                        type="button"
                                        className="btn btn-success btn-shadow-hover mr-2"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-shadow-hover mr-2"
                                    >
                                        Exit
                                    </button>
                                </div>
                            </div>
                            <FormControl className={classes.margin}>
                                <Form.Label>Customer</Form.Label>
                                <NativeSelect
                                    input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                                >
                                    <option value="" />
                                </NativeSelect>
                            </FormControl>
                        </Form.Group>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    )
}
