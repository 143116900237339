import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(1),
    width: "100%",
    overflowX: "auto",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
}));
export function DenseTable({ rowsName, rows, lastBold = false }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              {rowsName.map((x, i) => (
                <TableCell
                  align="left" /* align={i === 0 ? "left" : "right"} */
                >
                  {x?.Title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={row.name}>
                {rowsName.map((x, i) => (
                  <TableCell
                    component={i === 0 && "th"}
                    scope={i === 0 && "row"}
                    align="left"
                    /* align={i === 0 ? "left" : "right"} */
                  >
                    {lastBold && rows.length - 1 === index ? (
                      <b>{row[x.Name]}</b>
                    ) : (
                      <>{row[x.Name]}</>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}
