/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  CheckboxLargeWB,
  Pagination,
} from "../../../../../../_metronic/_partials/controls";
import { Formik } from "formik";
import BootstrapTable from "react-bootstrap-table-next";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Select from "react-select";
import CloseIcon from "@material-ui/icons/Close";
import { RangeDatePicker } from "react-google-flight-datepicker";
import {
  ButtonToolbar,
  Modal,
  OverlayTrigger,
  ProgressBar,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  SimpleZoom,
  sortCaret,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
  Button,
  IconButton,
  Icon,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { ProductTMSDetail } from "../product-detail/ProductDetail";
import { ProductMODetail } from "../master-order-detail/ProductDetail";
import {
  DateTime30minInterval,
  Export,
  DateTime30GL,
  StatesUs,
} from "../../../../../../_metronic/_helpers/ATHelpers";

import Axios from "axios";
import { Notification } from "../../../../Notification";
import { ClickFun } from "../../../../../SpecificTabs";
import { withStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
const adapter = new LocalStorage("db");
const db = low(adapter);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);
var totalItemsNew = 0;
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
}));
var typingTimer;
const ProductStatusCssClasses = [
  "success",
  "info",
  "success",
  "warning",
  "danger",
];
const data = [
  {
    Location: "Detroit IL",
    EntredBy: "Tyler Keaton",
    leftover: "2015",
    Status: "In Transit - Location and ETA updates",
    Dates: "10/04/2021",
    time: "07:45",
    Alerts: "Weather Delay",
    Notes: "Carrier: Best Time Express",
  },
  {
    Location: "Chicago IL",
    EntredBy: "Tyler Keaton",
    leftover: "2015",
    Status: "Empty At Delivery",
    Dates: "12/04/2021",
    time: "05:45",
    Alerts: "Breakdown",
    Notes: "",
  },
  {
    Location: "Chicago IL",
    EntredBy: "Tyler Keaton",
    leftover: "2015",
    Status: "Delivered Drop",
    Dates: "14/04/2021",
    time: "08:53",
    Alerts: "None",
    Notes: "",
  },
];
export function TrackingLoadBoardPage() {
  const history = useHistory();

  const [TrackingObject, setTrackingObject] = useState({
    ShipmentId: 0,
    TraceCode: "",
    EtaDate: "",
    TimeZone: "",
    Country: "",
    City: "",
    State: "",
    Note: "",
    NextTrackingDue:
      new Date().toISOString().substr(0, 10) +
      "T" +
      new Date(new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000))
        .getHours()
        .toString()
        .padStart(2, "0") +
      ":" +
      (new Date(
        new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
      ).getMinutes() > 30
        ? "30"
        : "00") +
      ":00",
  });

  useEffect(() => {
    const element = document.querySelector(".wrapper2");
    const element2 = document.querySelector(".wrapper1");
    if (element)
      element.addEventListener("scroll", (event) => {
        element2.scrollLeft = event.target.scrollLeft;
      });
    if (element2)
      element2.addEventListener("scroll", (event) => {
        element.scrollLeft = event.target.scrollLeft;
      });
  }, []);

  const classes = useStyles();
  const [FilterTrackingBoard, setFilterTrackingBoard] = useState({
    CustomerId: [],
    CarrierId: [],
    statusId: [],
    CapacityOperationId: [],
    CommercialAssigneeId: [],
    CommercialOperationId: [],
    modeId: [],
    ShipmentTypeId: [],
    capacityAssigneeId: [],
    originCity: "",
    originState: "",
    freightChargeId: 98,
    IsCanceled: false,
    destinationCity: "",
    destinationState: "",
    ShipStartDate: "",
    ShipEndDate: "",
    DeliveryStartDate: "",
    DeliveryEndDate: "",
    SearchText: "",
    CurrentEvent: [],
  });
  useEffect(() => console.log(FilterTrackingBoard), [FilterTrackingBoard]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [CustomersList, setCustomersList] = useState([]);
  const [CarriersList, setCarriersList] = useState([]);
  const [StatusList, setStatusList] = useState([]);
  const [CurrentEventList, setCurrentEventList] = useState([]);
  const [ShipmentTypeList, setShipmentTypeList] = useState([]);
  const [freightCharges, setFreightCharges] = useState([]);
  const [ModesList, setModesList] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  const [TrackingBoard, setTrackingBoard] = useState([]);
  const [TrackingBoardExport, setTrackingBoardExport] = useState([]);
  const [LoadingData, setLoadingData] = useState(false);
  const [EditModal, setEditModal] = useState(false);
  const [MODetail, setMODetail] = useState(false);
  const [LoadBoardGrid, setLoadBoardGrid] = useState(true);
  const [Country, setCountry] = useState([]);
  const [ShowTrace, setShowTrace] = useState(false);
  const [SoSelectedId, setSoSelectedId] = useState(null);
  const [TrackingStatusOptions, setTrackingStatusOptions] = useState([]);
  const [IsFilterHide, setIsFilterHide] = useState(false);
  const [LoadingPost, setLoadingPost] = useState(false);
  const [ExportLoadingData, setExportLoadingData] = useState(false);
  const [ShowAlert, setShowAlert] = useState();
  const [ShowInfo, setShowInfo] = useState({
    ShipmentId: null,
    EventName: "",
    Note: "",
  });
  const [states, setStates] = useState([]);
  const [countryIdFetch, setCountryValue] = useState({
    value: "US",
    label: "United States",
  });
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  const columnTrackingBoard = [
    {
      dataField: "Id",
      text: "SO#",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning text-center pr-0 text-primary font-weight-bolder";
        } else {
          return "text-center pr-0 text-primary font-weight-bolder";
        }
      },
      formatter: (cell, row) => {
        return (
          <a
            href={() => false}
            title={`${row.OnHold ? "OnHold" : ""}`}
            className={`navi-link ${row.OnHold ? "text-warning" : "text-primary"}`}
            onClick={(e) => {
              ClickFun(e, `/TransportManagementSystems/SoDetail/`, row.Id);
            }}
          >
            {row.Id}
          </a>
        );
      },
      headerClasses: "text-center pr-3",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        if (
          row.CustomerName !== null &&
          row.CustomerName !== "" &&
          row.CustomerName !== undefined
        ) {
          return (
            <>
              {row.CustomerName + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CustomerCode}
                  </Tooltip>
                }
              >
                <a className="btn btn-icon btn-light btn-hover-light btn-sm">
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      hidden: false,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return row?.IsCanceled ? (
          <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Cancelled</Tooltip>}
          >
            <span
              className={`label label-lg label-light-${row.Status !== null
                  ? row?.IsCanceled === true ? "danger" : String(row.Status)
                    .toLocaleLowerCase()
                    .includes("Delivered".toLocaleLowerCase())
                    ? "success"
                    : String(row.Status)
                      .toLocaleLowerCase()
                      .includes("In Transit".toLocaleLowerCase())
                      ? "warning"
                      : String(row.Status)
                        .toLocaleLowerCase()
                        .includes("Closed".toLocaleLowerCase())
                        ? "danger"
                        : String(row.Status)
                          .toLocaleLowerCase()
                          .includes("Assigned".toLocaleLowerCase())
                          ? "primary"
                          : "info"
                  : ""
                } label-inline`}
            >
              {row.Status}
            </span>
          </OverlayTrigger>
        ) : (
          <span
            className={`label label-lg label-light-${row.Status !== null
                ? row?.IsCanceled === true ? "danger" : String(row.Status)
                  .toLocaleLowerCase()
                  .includes("Delivered".toLocaleLowerCase())
                  ? "success"
                  : String(row.Status)
                    .toLocaleLowerCase()
                    .includes("In Transit".toLocaleLowerCase())
                    ? "warning"
                    : String(row.Status)
                      .toLocaleLowerCase()
                      .includes("Closed".toLocaleLowerCase())
                      ? "danger"
                      : String(row.Status)
                        .toLocaleLowerCase()
                        .includes("Assigned".toLocaleLowerCase())
                        ? "primary"
                        : "info"
                : ""
              } label-inline`}
          >
            {row.Status}
          </span>
        );
      },
    },
    {
      text: "Next Tracking Due",
      dataField: "NextTrackingDue",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.NextTrackingDue !== null && row.NextTrackingDue !== undefined
          ? moment(row.NextTrackingDue).format("MM/DD/YYYY, hh:mm A")
          : "";
      },
    },
    {
      text: "ETA",
      dataField: "EstimatedTimeArrival",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.EstimatedTimeArrival !== null && row.EstimatedTimeArrival !== undefined
          ? moment(row.EstimatedTimeArrival).format("MM/DD/YYYY, hh:mm A")
          : "";
      },
    },
    {
      dataField: "Carrier",
      text: "Carrier",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        if (
          row.Carrier !== null &&
          row.Carrier !== "" &&
          row.Carrier !== undefined
        ) {
          return (
            <>
              {row.Carrier + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CarrierCode}
                  </Tooltip>
                }
              >
                <a className="btn btn-icon btn-light btn-hover-light btn-sm">
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      text: "Trailer #",
      dataField: "Equipment",
      sort: true,
      hidden: false,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      sortCaret: sortCaret,
    },
    {
      text: "Current Event",
      dataField: "CurrentEvent",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      dataField: "CurrentCity",
      text: "Current Loc",
      sort: true,
      hidden: true,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return (
          (row.CurrentCity !== null && row.CurrentCity !== ""
            ? row.CurrentCity
            : "") +
          (row.CurrentState !== null && row.CurrentState !== ""
            ? ", " + row.CurrentState
            : "")
        );
      },
    },
    {
      dataField: "CurrentCity",
      text: "Current Loc City",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.CurrentCity !== null && row.CurrentCity !== ""
          ? row.CurrentCity
          : "";
      },
    },
    {
      dataField: "CurrentState",
      text: "Current Loc State",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.CurrentState !== null && row.CurrentState !== ""
          ? row.CurrentState
          : "";
      },
    },
    {
      dataField: "Alert",
      text: "Alert",
      sort: true,
      hidden: false,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      style: (cell, row) => {
        if (row.Alert !== "" && row.Alert !== null) {
          return {
            background: "#f7dfaf",
          };
        }
      },
      sortCaret: sortCaret,
    },
    {
      text: "Trace Notes",
      dataField: "AlertNotes",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      style: (cell, row) => {
        if (row.Alert !== "" && row.Alert !== null) {
          return {
            background: "#f7dfaf",
          };
        }
      },
    },
    {
      text: "Last Updated",
      dataField: "LastTracking",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.LastTracking !== null && row.LastTracking !== undefined
          ? moment(row.LastTracking).format("MM/DD/YYYY, hh:mm A")
          : "";
      },
    },
    {
      text: "Updated By",
      dataField: "LastTrackingBy",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      text: "PU Date",
      dataField: "PUAppointment",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.PUAppointment !== null && row.PUAppointment !== undefined
          ? moment(row.PUAppointment).format("MM/DD/YYYY, hh:mm A")
          : "";
      },
    },
    {
      text: "Shipper",
      dataField: "FromCompanyName",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      dataField: "FromCity",
      text: "PU City",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.FromCity;
      },
    },
    {
      text: "PU State",
      dataField: "PUState",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.FromState;
      },
    },

    {
      text: "Del Date",
      dataField: "DeliveryAppointmentDate",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
      formatter: (cell, row) => {
        return row.DeliveryAppointmentDate !== null &&
          row.DeliveryAppointmentDate !== undefined
          ? moment(row.DeliveryAppointmentDate).format("MM/DD/YYYY, hh:mm A")
          : "";
      },
    },
    {
      dataField: "ToCompanyName",
      text: "Receiver",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      dataField: "ToCity",
      text: "Del City",
      hidden: false,
      sort: true,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      dataField: "ToState",
      text: "Del State",
      hidden: false,
      sort: true,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      text: "Total Receivables",
      dataField: "TotalReceivables",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      text: "Total Payables",
      dataField: "TotalPayables",
      sort: true,
      hidden: false,
      sortCaret: sortCaret,
      classes: (cell, row) => {
        if (row?.IsCanceled) {
          return "bg-light-warning";
        } else {
          return "";
        }
      },
    },
    {
      dataField: "Actions",
      text: "Actions",
      hidden: false,
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={<Tooltip id="products-edit-tooltip">Add Alert</Tooltip>}
            >
              <a
                onClick={() => {
                  setSoSelectedId(row.Id);
                  setShowInfo({
                    ShipmentId: row.Id,
                    EventName: "",
                    Note: "",
                  });
                  setShowAlert(true);
                }}
                className="btn btn-icon btn-light btn-hover-warning btn-sm"
              >
                <span className="svg-icon svg-icon-md svg-icon-warning">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Warning-2.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              className="ml-3"
              overlay={
                <Tooltip id="products-edit-tooltip">Add Trace Log</Tooltip>
              }
            >
              <a
                onClick={() => {
                  setSoSelectedId(row.Id);
                  setShowTrace(true);
                }}
                className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
              >
                <span className="svg-icon svg-icon-md svg-icon-success">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Files/File-plus.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];
  const [columns, setcolumns] = useState(columnTrackingBoard || []);
  //Column Visibility
  (
    JSON.parse(localStorage.getItem("ColumnVisibility_TrackingBoard")) || []
  ).forEach((x) => {
    const a = columnTrackingBoard.find(
      (y) => y?.dataField + y?.text === x?.dataField + x?.text
    );
    if (a) a.hidden = x?.hidden || false;
  });
  const [AddressObjectPost, setAddressObjectPost] = useState({
    Name: "",
    Address: "",
    City: "",
    State: "",
    PostalCode: "",
    CountryId: "US",
    Phone: "",
    Fax: "",
    Note: "",
    AddressTypeId: null,
    IsActive: true,
    Charges: [],
    ContactName: "",
    Email: "",
    CustomerId: null,
  });
  function handleSubmitTraceLog() {
    if (
      document.getElementById("LocationCity").value === "" ||
      document.getElementsByName("LocationState")[0].value === ""
    ) {
      Notification("error", "State and City are required");
      return;
    }
    if (document.getElementsByName("StatusCode")[0].value === "") {
      Notification("error", "Trace Code is required");
      return;
    }
    if (
      TrackingObject.EtaDate === null ||
      TrackingObject.EtaDate === undefined ||
      TrackingObject.EtaDate === ""
    ) {
      Notification("error", "EtaDate and time is required");
      return;
    }
    setLoadingPost(true);
    const StatusCode = document.getElementsByName("StatusCode")[0].value;

    var nextTracking = TrackingObject.NextTrackingDue;

    var EtaDate = moment(TrackingObject.EtaDate).format("YYYY-MM-DD");
    var EtaTime = moment(TrackingObject.EtaDate).format("HH:mm:ss");

    const LocationCity = document.getElementById("LocationCity").value;
    const LocationState = document.getElementsByName("LocationState")[0].value;
    const LocationCountry = document.getElementsByName("Countrytracking")[0]
      .value;
    const data = {
      ShipmentId: SoSelectedId,
      StatusCode: StatusCode,
      TraceDate: new Date().toISOString().substr(0, 10),
      TraceTime:
        new Date()
          .getHours()
          .toString()
          .padStart(2, "0") +
        ":" +
        new Date().getMinutes(),
      EtaDate: EtaDate,
      EtaTime: EtaTime,
      Note: TrackingObject.Note,
      LocationCity: LocationCity,
      LocationState: LocationState,
      LocationCountry: LocationCountry,
      NextTracking: nextTracking,
    };
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      withCredentials: true,
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${window.$apiurl}/tracking`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          setLoadingPost(false);
          setShowTrace(false);
          Notification(
            "success",
            "Trace Log Add Successfuly on Shipment ID " + SoSelectedId
          );
          //fetchClassificationsAsync();
          GetDataByStatus(
            FilterTrackingBoard.CustomerId,
            FilterTrackingBoard.CarrierId,
            FilterTrackingBoard.statusId,
            FilterTrackingBoard.CapacityOperationId,
            FilterTrackingBoard.CommercialAssigneeId,
            FilterTrackingBoard.CommercialOperationId,
            FilterTrackingBoard.modeId,
            FilterTrackingBoard.ShipmentTypeId,
            FilterTrackingBoard.capacityAssigneeId,
            FilterTrackingBoard.originCity,
            FilterTrackingBoard.originState,
            FilterTrackingBoard.destinationCity,
            FilterTrackingBoard.destinationState,
            FilterTrackingBoard.ShipStartDate,
            FilterTrackingBoard.ShipEndDate,
            FilterTrackingBoard.DeliveryStartDate,
            FilterTrackingBoard.DeliveryEndDate,
            paginationOptionsNew.page,
            paginationOptionsNew.sizePerPage,
            paginationOptionsNew.sortField,
            paginationOptionsNew.sortOrder,
            FilterTrackingBoard.freightChargeId,
            FilterTrackingBoard.IsCanceled,
            FilterTrackingBoard.CurrentEvent
          );
          setTrackingObject({
            ShipmentId: 0,
            TraceCode: "",
            EtaDate: "",
            TimeZone: "",
            Country: "",
            City: "",
            State: "",
            Note: "",
            NextTrackingDue:
              new Date().toISOString().substr(0, 10) +
              "T" +
              new Date(
                new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
              )
                .getHours()
                .toString()
                .padStart(2, "0") +
              ":" +
              (new Date(
                new Date().setTime(new Date().getTime() + 6 * 60 * 60 * 1000)
              ).getMinutes() > 30
                ? "30"
                : "00") +
              ":00",
          });
        } else {
          if (
            result[1].ModelState !== null &&
            result[1].ModelState !== undefined &&
            result[1].ModelState !== ""
          ) {
            let modelState = result[1].ModelState;
            if (modelState)
              Object.keys(modelState).forEach(function (k) {
                modelState[k].forEach((element) => {
                  Notification("errorClick", element);
                });
              });
          } else if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            Notification("errorClick", "Bad Request");
          }
          setLoadingPost(false);
        }
      })
      .catch((error) => {
        setShowTrace(false);
        setLoadingPost(false);
        Notification("error", error);
      });
  }
  const [paginationOptionsNew, setpaginationOptionsNew] = useState({
    custom: true,
    totalSize: totalItemsNew,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      {
        text: String(totalItemsNew),
        value: totalItemsNew,
      },
    ],
    sizePerPage: 10,
    page: 1,
    fromLoading: true,
  });
  async function fetchMyAPI2(type, entityName) {
    try {
      await GetApiExport(
        FilterTrackingBoard.CustomerId,
        FilterTrackingBoard.CarrierId,
        FilterTrackingBoard.statusId,
        FilterTrackingBoard.CapacityOperationId,
        FilterTrackingBoard.CommercialAssigneeId,
        FilterTrackingBoard.CommercialOperationId,
        FilterTrackingBoard.modeId,
        FilterTrackingBoard.ShipmentTypeId,
        FilterTrackingBoard.capacityAssigneeId,
        FilterTrackingBoard.originCity,
        FilterTrackingBoard.originState,
        FilterTrackingBoard.destinationCity,
        FilterTrackingBoard.destinationState,
        FilterTrackingBoard.ShipStartDate,
        FilterTrackingBoard.ShipEndDate,
        FilterTrackingBoard.DeliveryStartDate,
        FilterTrackingBoard.DeliveryEndDate,
        paginationOptionsNew.page,
        paginationOptionsNew.sizePerPage,
        paginationOptionsNew.sortField,
        paginationOptionsNew.sortOrder,
        FilterTrackingBoard.freightChargeId,
        FilterTrackingBoard.IsCanceled,
        FilterTrackingBoard.CurrentEvent,
        true,
        {
          Type: type,
          Entity: entityName,
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  async function GetDataByStatus(
    Customers,
    carriers,
    statusId,
    CapacityOperationId,
    CommercialAssigneeId,
    CommercialOperationId,
    modeId,
    ShipmentTypeId,
    capacityAssigneeId,
    originCity,
    originState,
    destinationCity,
    destinationState,
    ShipStartDate,
    ShipEndDate,
    DeliveryStartDate,
    DeliveryEndDate,
    page,
    sizePerPage,
    sortField,
    sortOrder,
    FreightChargeId,
    IsCanceled,
    CurrentEvent
  ) {
    if (modeId !== "" && modeId !== null && modeId !== undefined) {
      var listmodeId = modeId.map((a) => a.value);
      modeId = "&modeId=" + listmodeId.join("&modeId=");
    } else {
      modeId = "";
    }

    if (
      CurrentEvent !== "" &&
      CurrentEvent !== undefined &&
      CurrentEvent !== null
    ) {
      var trackingEvents = CurrentEvent.map((a) => a.label);
      CurrentEvent =
        "&trackingEvents=" + trackingEvents.join("&trackingEvents=");
    } else {
      CurrentEvent = "";
    }

    if (
      ShipmentTypeId !== "" &&
      ShipmentTypeId !== null &&
      ShipmentTypeId !== undefined
    ) {
      var listShipmentTypeId = ShipmentTypeId.map((a) => a.value);
      ShipmentTypeId =
        "&ShipmentTypeId=" + listShipmentTypeId.join("&ShipmentTypeId=");
    } else {
      ShipmentTypeId = "";
    }
    var listcapacityAssigneeId = "";
    if (
      capacityAssigneeId !== "" &&
      capacityAssigneeId !== null &&
      capacityAssigneeId !== undefined
    ) {
      listcapacityAssigneeId = capacityAssigneeId.map((a) => {
        if (a.IsGroup) {
          return "&capacityAssigneeGroupId=" + a.value;
        } else {
          return "&capacityAssigneeId=" + a.value;
        }
      });
    } else {
      listcapacityAssigneeId = "";
    }

    var listCapacityOperationId = "";

    if (
      CapacityOperationId !== "" &&
      CapacityOperationId !== null &&
      CapacityOperationId !== undefined
    ) {
      listCapacityOperationId = CapacityOperationId.map((a) => {
        if (a.IsGroup) {
          return "&capacityOperationGroupId=" + a.value;
        } else {
          return "&CapacityOperationId=" + a.value;
        }
      });
    } else {
      listCapacityOperationId = "";
    }

    var listCommercialAssigneeId = "";

    if (
      CommercialAssigneeId !== "" &&
      CommercialAssigneeId !== null &&
      CommercialAssigneeId !== undefined
    ) {
      listCommercialAssigneeId = CommercialAssigneeId.map((a) => {
        if (a.IsGroup) {
          return "&commercialAssigneeGroupId=" + a.value;
        } else {
          return "&CommercialAssigneeId=" + a.value;
        }
      });
    } else {
      listCommercialAssigneeId = "";
    }

    var listCommercialOperationId = "";

    if (
      CommercialOperationId !== "" &&
      CommercialOperationId !== null &&
      CommercialOperationId !== undefined
    ) {
      listCommercialOperationId = CommercialOperationId.map((a) => {
        if (a.IsGroup) {
          return "&commercialOperationGroupId=" + a.value;
        } else {
          return "&CommercialOperationId=" + a.value;
        }
      });
    } else {
      listCommercialOperationId = "";
    }
    if (statusId !== "" && statusId !== null && statusId !== undefined) {
      var liststatus = statusId.map((a) => a.value);
      statusId = "&statusId=" + liststatus.join("&statusId=");
    } else {
      statusId = "";
    }
    if (carriers !== "" && carriers !== null && carriers !== undefined) {
      var listcarriers = carriers.map((a) => a.value);
      carriers = "&CarrierId=" + listcarriers.join("&CarrierId=");
    } else {
      carriers = "";
    }
    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    } else {
      Customers = "";
    }
    setLoadingData(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${window.$apiurl}/tms/tracking?search=${FilterTrackingBoard.SearchText
      }&originCity=${originCity}&originState=${originState}&destinationCity=${destinationCity}&destinationState=${destinationState}&ShipStartDate=${ShipStartDate}&ShipEndDate=${ShipEndDate}&DeliveryStartDate=${DeliveryStartDate}&DeliveryEndDate=${DeliveryEndDate}&freightChargeId=${FreightChargeId}&IsCanceled=${IsCanceled}${listCapacityOperationId === null ? "" : listCapacityOperationId
      }${listcapacityAssigneeId === null ? "" : listcapacityAssigneeId}${modeId === null ? "" : modeId
      }
      ${CurrentEvent === null ? "" : CurrentEvent}
      ${ShipmentTypeId === null ? "" : ShipmentTypeId}${listCommercialAssigneeId === null ? "" : listCommercialAssigneeId
      }${listCommercialOperationId === null ? "" : listCommercialOperationId}${statusId === null ? "" : statusId
      }${carriers === null ? "" : carriers}${Customers === null ? "" : Customers
      }&pageNumber=${page}&pageSize=${sizePerPage}&sortField=${sortField}&sortOrder=${sortOrder}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setLoadingData(false);
        var dataObj = JSON.parse(result);
        paginationOptionsNew.totalSize =
          dataObj?.Pagination?.TotalItemCount || 0;
        paginationOptionsNew.sizePerPageList = [
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          {
            text: String(dataObj?.Pagination?.TotalItemCount || 0),
            value: dataObj?.Pagination?.TotalItemCount || 0,
          },
        ];
        setTrackingBoard(dataObj?.Shipments || []);
        return dataObj?.Shipments;
      })
      .catch((error) => console.log("error", error));
  }
  async function GetApiExport(
    Customers,
    carriers,
    statusId,
    CapacityOperationId,
    CommercialAssigneeId,
    CommercialOperationId,
    modeId,
    ShipmentTypeId,
    capacityAssigneeId,
    originCity,
    originState,
    destinationCity,
    destinationState,
    ShipStartDate,
    ShipEndDate,
    DeliveryStartDate,
    DeliveryEndDate,
    page,
    sizePerPage,
    sortField,
    sortOrder,
    FreightChargeId,
    IsCanceled,
    CurrentEvent,
    isExport = false,
    dataExport = null
  ) {
    if (modeId !== "" && modeId !== null && modeId !== undefined) {
      var listmodeId = modeId.map((a) => a.value);
      modeId = "&modeId=" + listmodeId.join("&modeId=");
    } else {
      modeId = "";
    }

    if (
      CurrentEvent !== "" &&
      CurrentEvent !== undefined &&
      CurrentEvent !== null
    ) {
      var trackingEvents = CurrentEvent.map((a) => a.label);
      CurrentEvent =
        "&trackingEvents=" + trackingEvents.join("&trackingEvents=");
    } else {
      CurrentEvent = "";
    }

    if (
      ShipmentTypeId !== "" &&
      ShipmentTypeId !== null &&
      ShipmentTypeId !== undefined
    ) {
      var listShipmentTypeId = ShipmentTypeId.map((a) => a.value);
      ShipmentTypeId =
        "&ShipmentTypeId=" + listShipmentTypeId.join("&ShipmentTypeId=");
    } else {
      ShipmentTypeId = "";
    }
    var listcapacityAssigneeId = "";
    if (
      capacityAssigneeId !== "" &&
      capacityAssigneeId !== null &&
      capacityAssigneeId !== undefined
    ) {
      listcapacityAssigneeId = capacityAssigneeId.map((a) => {
        if (a.IsGroup) {
          return "&capacityAssigneeGroupId=" + a.value;
        } else {
          return "&capacityAssigneeId=" + a.value;
        }
      });
    } else {
      listcapacityAssigneeId = "";
    }

    var listCapacityOperationId = "";

    if (
      CapacityOperationId !== "" &&
      CapacityOperationId !== null &&
      CapacityOperationId !== undefined
    ) {
      listCapacityOperationId = CapacityOperationId.map((a) => {
        if (a.IsGroup) {
          return "&capacityOperationGroupId=" + a.value;
        } else {
          return "&CapacityOperationId=" + a.value;
        }
      });
    } else {
      listCapacityOperationId = "";
    }

    var listCommercialAssigneeId = "";

    if (
      CommercialAssigneeId !== "" &&
      CommercialAssigneeId !== null &&
      CommercialAssigneeId !== undefined
    ) {
      listCommercialAssigneeId = CommercialAssigneeId.map((a) => {
        if (a.IsGroup) {
          return "&commercialAssigneeGroupId=" + a.value;
        } else {
          return "&CommercialAssigneeId=" + a.value;
        }
      });
    } else {
      listCommercialAssigneeId = "";
    }

    var listCommercialOperationId = "";

    if (
      CommercialOperationId !== "" &&
      CommercialOperationId !== null &&
      CommercialOperationId !== undefined
    ) {
      listCommercialOperationId = CommercialOperationId.map((a) => {
        if (a.IsGroup) {
          return "&commercialOperationGroupId=" + a.value;
        } else {
          return "&CommercialOperationId=" + a.value;
        }
      });
    } else {
      listCommercialOperationId = "";
    }
    if (statusId !== "" && statusId !== null && statusId !== undefined) {
      var liststatus = statusId.map((a) => a.value);
      statusId = "&statusId=" + liststatus.join("&statusId=");
    } else {
      statusId = "";
    }
    if (carriers !== "" && carriers !== null && carriers !== undefined) {
      var listcarriers = carriers.map((a) => a.value);
      carriers = "&CarrierId=" + listcarriers.join("&CarrierId=");
    } else {
      carriers = "";
    }
    if (Customers !== "" && Customers !== null && Customers !== undefined) {
      var listCustomers = Customers.map((a) => a.value);
      Customers = "&CustomerId=" + listCustomers.join("&CustomerId=");
    } else {
      Customers = "";
    }
    setLoadingData(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      `${window.$apiurl}/tms/tracking?search=${FilterTrackingBoard.SearchText
      }&originCity=${originCity}&originState=${originState}&destinationCity=${destinationCity}&destinationState=${destinationState}&ShipStartDate=${ShipStartDate}&ShipEndDate=${ShipEndDate}&DeliveryStartDate=${DeliveryStartDate}&DeliveryEndDate=${DeliveryEndDate}&freightChargeId=${FreightChargeId}&IsCanceled=${IsCanceled}${listCapacityOperationId === null ? "" : listCapacityOperationId
      }${listcapacityAssigneeId === null ? "" : listcapacityAssigneeId}${modeId === null ? "" : modeId
      }
      ${CurrentEvent === null ? "" : CurrentEvent}
      ${ShipmentTypeId === null ? "" : ShipmentTypeId}${listCommercialAssigneeId === null ? "" : listCommercialAssigneeId
      }${listCommercialOperationId === null ? "" : listCommercialOperationId}${statusId === null ? "" : statusId
      }${carriers === null ? "" : carriers}${Customers === null ? "" : Customers
      }&pageNumber=${page}&pageSize=${isExport ? "999999999" : sizePerPage
      }&sortField=${sortField}&sortOrder=${sortOrder}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        setLoadingData(false);
        var dataObj = JSON.parse(result);
        paginationOptionsNew.totalSize =
          dataObj?.Pagination?.TotalItemCount || 0;
        paginationOptionsNew.sizePerPageList = [
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          {
            text: String(dataObj?.Pagination?.TotalItemCount || 0),
            value: dataObj?.Pagination?.TotalItemCount || 0,
          },
        ];
        if (isExport) {
          if (
            dataObj?.Shipments === null &&
            dataObj?.Shipments === undefined &&
            dataObj?.Shipments?.length > 0
          ) {
            Notification("error", "Something Wrong");
            setExportLoadingData(false);
            return false;
          }
          Export(
            dataObj?.Shipments || [],
            dataExport?.Type,
            dataExport?.Entity
          );
          setExportLoadingData(false);
        }
        return dataObj?.Shipments;
      })
      .catch((error) => console.log("error", error));
  }
  function GetTrackingBoardViewModel() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/loadboard/GetLBViewModel`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        setCustomersList(obj?.Customers || []);
        setCarriersList(obj?.Carriers || []);
        setStatusList(obj?.Statuses || []);
        setCurrentEventList(obj.Events || []);
        setShipmentTypeList(obj?.ShipmentTypes || []);
        setFreightCharges(obj?.FreightCharges || []);
        setModesList(obj?.Modes || []);
        setUsersList(obj?.Updators || []);
      })
      .catch((error) => console.log("error", error));
  }
  function GetTrackingBoardData() {
    setLoadingData(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tms/tracking`, requestOptions)
      .then(async (response) => {
        if (response.status === 401) {
          history.push("/error/401");
        }
        return response.text();
      })
      .then((result) => {
        var dataObj = JSON.parse(result);
        setTrackingBoard(dataObj?.Shipments || []);
        let _paginationOptionsNew = { ...paginationOptionsNew };
        _paginationOptionsNew.fromLoading = true;
        _paginationOptionsNew.totalSize =
          dataObj?.Pagination?.TotalItemCount || 0;
        _paginationOptionsNew.sizePerPageList = [
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          {
            text: String(dataObj?.Pagination?.TotalItemCount || 0),
            value: dataObj?.Pagination?.TotalItemCount || 0,
          },
        ];
        _paginationOptionsNew.page = 1;
        setpaginationOptionsNew(_paginationOptionsNew);
        setLoadingData(false);
      })
      .catch((error) => console.log("error", error));
  }
  useEffect(() => {
    GetTrackingBoardViewModel();
    GetTrackingBoardData();
    GetTrackingStatus();
    handleCountryChange();
    fetchViewModelAsyncCountry();
  }, []);

  const fetchViewModelAsyncCountry = async () => {
    try {
      const result = await Axios.get("api/ManageAddress/GetAllApi");
      setCountry(result.data["Countries"] || []);
    } catch (e) { }
  };
  const handleCountryChange = async (event) => {
    setStates([]);
    if (event === "" || event === null || event === undefined) {
      event = "US";
    }

    const result = await Axios.get(`api/companyinfo/getstates/${event}`);
    if (result.status === 200) {
      if (result.data.length > 0) {
        const states = [];
        window.$StatesAddress = result.data;
        result.data.length > 0 &&
          result.data.map((item) => {
            states.push({ value: item.Id, label: item.Name });
          });
        setStates(states);
      } else {
        Notification("warning", "This Country doesn't have any state.");
      }
    }
  };
  async function GetTrackingStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${window.$apiurl}/tracking/statuses`, requestOptions)
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        if (res[0] === 200) {
          const a = res[1];
          setTrackingStatusOptions(a || []);
        }
      })
      .catch((error) => console.log("error", error));
  }

  const [IsCancel, setIsCancel] = React.useState({ value: 1, label: "Active" });
  const listCanceled = [
    { value: 1, label: "Active" },
    { value: 2, label: "Canceled" },
  ];
  return (
    <>
      <Modal
        size="xl"
        show={ShowTrace}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Add Trace Log
          </Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowTrace(false);
              setTrackingObject({
                ShipmentId: 0,
                TraceCode: "",
                EtaDate: "",
                TimeZone: "",
                Country: "",
                City: "",
                State: "",
                Note: "",
                NextTrackingDue:
                  new Date().toISOString().substr(0, 10) +
                  "T" +
                  new Date(
                    new Date().setTime(
                      new Date().getTime() + 6 * 60 * 60 * 1000
                    )
                  )
                    .getHours()
                    .toString()
                    .padStart(2, "0") +
                  ":" +
                  (new Date(
                    new Date().setTime(
                      new Date().getTime() + 6 * 60 * 60 * 1000
                    )
                  ).getMinutes() > 30
                    ? "30"
                    : "00") +
                  ":00",
              });
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>

        <Modal.Body>
          <div className="form-group row">
            <div className="col-lg-6">
              <label>
                <b>Shipment ID - Trailer Number</b>
              </label>
              <input
                id="ShipmentId2"
                name="ShipmentId"
                value={SoSelectedId}
                type="text"
                disabled={true}
                className="form-control"
              />
            </div>
            <div className="col-lg-6">
              <label>
                <b>Choose</b> a Trace Code
              </label>
              <Select
                name="StatusCode"
                id="StatusCode"
                defaultValue={TrackingStatusOptions.filter(
                  (a) => a.Code === "33"
                ).map((x) => {
                  return {
                    value: x.Code,
                    label: x.EventName,
                  };
                })}
                options={TrackingStatusOptions.map((x) => {
                  return {
                    value: x.Code,
                    label: x.EventName,
                  };
                })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-6 D-margin">
              <label>
                <b>Choose</b> a ETA Date and Time
              </label>
              <DateTime30GL
                defaultvalue={TrackingObject.EtaDate}
                onChange={(newValue) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.EtaDate = newValue;
                  setTrackingObject(trackingObject);
                }}
              />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a Time Zone
              </label>
              <Select
                name="TimeZone"
                id="TimeZone"
                defaultValue={{
                  value: "PST",
                  label: "PST (GMT -8)",
                }}
                inputId="react-select-single"
                placeholder="TimeZone"
                options={[
                  {
                    value: "CST",
                    label: "CST (GMT -6)",
                  },
                  {
                    value: "MST",
                    label: "MST (GMT -7)",
                  },
                  {
                    value: "PST",
                    label: "PST (GMT -8)",
                  },
                  {
                    value: "AST",
                    label: "AST (GMT -9)",
                  },
                  {
                    value: "HST",
                    label: "HST (GMT -10)",
                  },
                ]}
              />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a Country
              </label>
              <Select
                name="Countrytracking"
                id="Countrytracking"
                value={
                  countryIdFetch.value === ""
                    ? { value: "US", label: "United States" }
                    : countryIdFetch
                }
                options={Country.map((x) => {
                  return { value: x?.Id, label: x?.Name };
                })}
                onChange={(newValue) => {
                  setCountryValue(newValue);
                  handleCountryChange(newValue?.value);
                  let value = { ...AddressObjectPost };
                  if (newValue !== null) {
                    value.CountryId = newValue?.value;
                  } else {
                    value.CountryId = "";
                  }
                  setAddressObjectPost(value);
                }}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-3">
              <label>
                <b>Location City</b>
              </label>
              <input id="LocationCity" type="text" className="form-control" />
            </div>
            <div className="col-lg-3">
              <label>
                <b>Choose</b> a state
              </label>
              <Select
                name="LocationState"
                id="LocationState"
                options={states.map((x) => {
                  return {
                    value: x.label,
                    label: x.label,
                  };
                })}
              />
            </div>
            <div className="col-lg-6 D-margin">
              <label>
                <b>Choose</b> a Next Tracking Due Date and Time
              </label>
              <DateTime30GL
                defaultvalue={TrackingObject.NextTrackingDue}
                onChange={(newValue) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.NextTrackingDue = newValue;
                  setTrackingObject(trackingObject);
                }}
              />
            </div>
            <div className="col-lg-12 mt-5">
              <label>Note</label>
              <textarea
                className="form-control"
                maxlength="28"
                rows="3"
                onChange={(e) => {
                  var trackingObject = { ...TrackingObject };
                  trackingObject.Note = e.target.value;
                  setTrackingObject(trackingObject);
                }}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              handleSubmitTraceLog();
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="md"
        show={ShowAlert}
        onHide={setShowAlert}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">Add Alert</Modal.Title>
          <IconButton
            className={classes.button}
            aria-label="Close"
            onClick={() => {
              setShowInfo({ ShipmentId: null, EventName: "", Note: "" });
              setShowAlert(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <Select
              isClearable
              name="LoadBoard"
              id="Alert"
              label="Alert Name"
              defaultValue={{
                value: ShowInfo.Alerts,
                label: ShowInfo.Alerts,
              }}
              inputId="react-select-single"
              options={data.map((a) => {
                return { value: a.Alerts, label: a.Alerts };
              })}
              onChange={(e) => {
                if (e !== null) {
                  let value = { ...ShowInfo };
                  value.EventName = e.value;
                  setShowInfo(value);
                }
              }}
            />
          </div>
          <div className="form-group mb-1">
            <label for="exampleTextarea">Add Note</label>
            <textarea
              className="form-control"
              id="exampleTextarea"
              rows="3"
              value={ShowInfo.Notes}
              onChange={(e) => {
                let value = { ...ShowInfo };
                value.Note = e.target.value;
                setShowInfo(value);
              }}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={() => setShowAlert(false)}
            className="btn btn-outline btn-elevate"
          >
            Cancel
          </button>

          <button
            type="submit"
            disabled={LoadingPost}
            onClick={() => {
              setLoadingPost(true);
              var myHeaders = new Headers();
              myHeaders.append(
                "Authorization",
                `bearer ${db.read().getState().Token}`
              );
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("X-FP-API-KEY", "iphone");
              var raw = JSON.stringify(ShowInfo);
              var requestOptions = {
                method: "POST",
                withCredentials: true,
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };
              fetch(`${window.$apiurl}/Tracking/Alert`, requestOptions)
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    setLoadingPost(false);
                    setShowAlert(false);
                    Notification(
                      "success",
                      "Alert Add Successfuly on Shipment ID " + SoSelectedId
                    );
                    GetDataByStatus(
                      FilterTrackingBoard.CustomerId,
                      FilterTrackingBoard.CarrierId,
                      FilterTrackingBoard.statusId,
                      FilterTrackingBoard.CapacityOperationId,
                      FilterTrackingBoard.CommercialAssigneeId,
                      FilterTrackingBoard.CommercialOperationId,
                      FilterTrackingBoard.modeId,
                      FilterTrackingBoard.ShipmentTypeId,
                      FilterTrackingBoard.capacityAssigneeId,
                      FilterTrackingBoard.originCity,
                      FilterTrackingBoard.originState,
                      FilterTrackingBoard.destinationCity,
                      FilterTrackingBoard.destinationState,
                      FilterTrackingBoard.ShipStartDate,
                      FilterTrackingBoard.ShipEndDate,
                      FilterTrackingBoard.DeliveryStartDate,
                      FilterTrackingBoard.DeliveryEndDate,
                      paginationOptionsNew.page,
                      paginationOptionsNew.sizePerPage,
                      paginationOptionsNew.sortField,
                      paginationOptionsNew.sortOrder,
                      FilterTrackingBoard.freightChargeId,
                      FilterTrackingBoard.IsCanceled,
                      FilterTrackingBoard.CurrentEvent
                    );
                  } else {
                    if (
                      result[1].ModelState !== null &&
                      result[1].ModelState !== undefined &&
                      result[1].ModelState !== ""
                    ) {
                      let modelState = result[1].ModelState;
                      if (modelState)
                        Object.keys(modelState).forEach(function (k) {
                          modelState[k].forEach((element) => {
                            Notification("errorClick", element);
                          });
                        });
                    } else if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("errorClick", result[1].Message);
                    } else {
                      Notification("errorClick", "Bad Request");
                    }
                    setLoadingPost(false);
                  }
                })
                .catch((error) => {
                  Notification("error", error);
                });
              setShowAlert(false);
            }}
            className="btn btn-primary btn-elevate"
          >
            Save
            {LoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      <input
        type="hidden"
        id="openMODetailPage3"
        onClick={(e) => {
          setEditModal(false);
          setLoadBoardGrid(false);
          setMODetail(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="openTMSDetailPage3"
        onClick={(e) => {
          setMODetail(false);
          setLoadBoardGrid(false);
          setEditModal(e.target.value);
        }}
      />
      <input
        type="hidden"
        id="TrackingBoardGrid"
        onClick={(e) => {
          setMODetail(false);
          setEditModal(false);
          setLoadBoardGrid(true);
        }}
      />
      {EditModal && (
        <ProductTMSDetail SO={EditModal} setEditModal={setEditModal} />
      )}
      {MODetail && <ProductMODetail MO={MODetail} setMODetail={setMODetail} />}
      {!EditModal && !MODetail && (
        <>
          <Card>
            <CardHeader title="Tracking Board">
              <CardHeaderToolbar>
                <ButtonToolbar className="btn-text-primary">
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    disabled={ExportLoadingData}
                    onClick={handleClick}
                  >
                    Export as
                    <Icon className={classes.rightIcon}>save</Icon>
                    {ExportLoadingData && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                  </Button>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("excel", `Tracking_Board_`);
                      }}
                    >
                      <ListItemIcon>
                        <i className="far fa-file-excel"></i>
                      </ListItemIcon>
                      <ListItemText primary="Excel" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("csv", `Tracking_Board_`);
                      }}
                    >
                      <ListItemIcon>
                        <i className="fas fa-file-csv"></i>
                      </ListItemIcon>
                      <ListItemText primary="CSV" />
                    </StyledMenuItem>
                    <StyledMenuItem
                      onClick={(event) => {
                        fetchMyAPI2("json", `Tracking_Board_`);
                      }}
                    >
                      <ListItemIcon>
                        <i className="far fa-file-code"></i>
                      </ListItemIcon>
                      <ListItemText primary="Json" />
                    </StyledMenuItem>
                  </StyledMenu>
                </ButtonToolbar>
                <button
                  style={{ float: "right", height: "32px", paddingTop: "5px" }}
                  type="button"
                  disabled={LoadingData}
                  onClick={() => {
                    GetDataByStatus(
                      FilterTrackingBoard.CustomerId,
                      FilterTrackingBoard.CarrierId,
                      FilterTrackingBoard.statusId,
                      FilterTrackingBoard.CapacityOperationId,
                      FilterTrackingBoard.CommercialAssigneeId,
                      FilterTrackingBoard.CommercialOperationId,
                      FilterTrackingBoard.modeId,
                      FilterTrackingBoard.ShipmentTypeId,
                      FilterTrackingBoard.capacityAssigneeId,
                      FilterTrackingBoard.originCity,
                      FilterTrackingBoard.originState,
                      FilterTrackingBoard.destinationCity,
                      FilterTrackingBoard.destinationState,
                      FilterTrackingBoard.ShipStartDate,
                      FilterTrackingBoard.ShipEndDate,
                      FilterTrackingBoard.DeliveryStartDate,
                      FilterTrackingBoard.DeliveryEndDate,
                      paginationOptionsNew.page,
                      paginationOptionsNew.sizePerPage,
                      paginationOptionsNew.sortField,
                      paginationOptionsNew.sortOrder,
                      FilterTrackingBoard.freightChargeId,
                      FilterTrackingBoard?.IsCanceled,
                      FilterTrackingBoard.CurrentEvent
                    );
                  }}
                  className="btn btn-success ml-5"
                >
                  Refresh
                </button>
                <button
                  style={{ float: "right", height: "32px", paddingTop: "5px" }}
                  type="button"
                  onClick={() => {
                    document
                      .getElementById("CleareAllFilterDataButton")
                      .click();
                  }}
                  className="btn btn-warning ml-5"
                >
                  Clear Filter
                </button>
                <button
                  style={{ float: "right", height: "32px", paddingTop: "5px" }}
                  type="button"
                  onClick={() => {
                    setIsFilterHide(!IsFilterHide);
                  }}
                  className="btn btn-primary ml-5"
                >
                  {IsFilterHide === false ? "Hide" : "Show"} Filter
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{
                  SearchText: FilterTrackingBoard.SearchText,
                  CustomerId: FilterTrackingBoard.CustomerId,
                  CarrierId: FilterTrackingBoard.CarrierId,
                  statusId: FilterTrackingBoard.statusId,
                  modeId: FilterTrackingBoard.modeId,
                  ShipmentTypeId: FilterTrackingBoard.ShipmentTypeId,
                  capacityAssigneeId: FilterTrackingBoard.capacityAssigneeId,
                  originCity: FilterTrackingBoard.originCity,
                  originState: FilterTrackingBoard.originState,
                  destinationCity: FilterTrackingBoard.destinationCity,
                  destinationState: FilterTrackingBoard.destinationState,
                  ShipStartDate: FilterTrackingBoard.ShipStartDate,
                  ShipEndDate: FilterTrackingBoard.ShipEndDate,
                  DeliveryStartDate: FilterTrackingBoard.DeliveryStartDate,
                  DeliveryEndDate: FilterTrackingBoard.DeliveryEndDate,
                  CapacityOperationId: FilterTrackingBoard.CapacityOperationId,
                  CommercialAssigneeId:
                    FilterTrackingBoard.CommercialAssigneeId,
                  CommercialOperationId:
                    FilterTrackingBoard.CommercialOperationId,
                }}
                onSubmit={(values) => {
                  GetDataByStatus(
                    values.CustomerId,
                    values.CarrierId,
                    values.statusId,
                    values.CapacityOperationId,
                    values.CommercialAssigneeId,
                    values.CommercialOperationId,
                    values.modeId,
                    values.ShipmentTypeId,
                    values.capacityAssigneeId,
                    values.originCity,
                    values.originState,
                    values.destinationCity,
                    values.destinationState,
                    values.ShipStartDate,
                    values.ShipEndDate,
                    values.DeliveryStartDate,
                    values.DeliveryEndDate,
                    paginationOptionsNew.page,
                    paginationOptionsNew.sizePerPage,
                    paginationOptionsNew.sortField,
                    paginationOptionsNew.sortOrder,
                    values.freightChargeId ?? 98,
                    values?.IsCanceled,
                    values.CurrentEvent
                  );
                }}
              >
                {({ values, handleSubmit, setFieldValue }) => {
                  const CleareAllFilterData = () => {
                    setpaginationOptionsNew({
                      custom: true,
                      totalSize: totalItemsNew,
                      sortField: "Id",
                      sortOrder: "desc",
                      sizePerPageList: [
                        { text: "10", value: 10 },
                        { text: "25", value: 25 },
                        { text: "50", value: 50 },
                        {
                          text: String(totalItemsNew),
                          value: totalItemsNew,
                        },
                      ],
                      sizePerPage: 10,
                      page: 1,
                      fromLoading: true,
                    });
                    setFilterTrackingBoard({
                      CustomerId: [],
                      CarrierId: [],
                      statusId: [],
                      CapacityOperationId: [],
                      CommercialAssigneeId: [],
                      CommercialOperationId: [],
                      modeId: [],
                      capacityAssigneeId: [],
                      originCity: "",
                      originState: "",
                      freightChargeId: 98,
                      IsCanceled: false,
                      destinationCity: "",
                      destinationState: "",
                      ShipStartDate: "",
                      ShipEndDate: "",
                      DeliveryStartDate: "",
                      DeliveryEndDate: "",
                      SearchText: "",
                      CurrentEvent: [],
                    });
                    GetDataByStatus(
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      [],
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      1,
                      10,
                      "Id",
                      "desc",
                      98,
                      false,
                      []
                    );

                    setFieldValue("capacityAssigneeId", []);
                    setFieldValue("modeId", []);
                    setFieldValue("CurrentEvent", []);
                    setFieldValue("statusId", []);
                    setFieldValue("CarrierId", []);
                    setFieldValue("CustomerId", []);
                    setFieldValue("CapacityOperationId", []);
                    setFieldValue("CommercialAssigneeId", []);
                    setFieldValue("CommercialOperationId", []);
                    setFieldValue("originCity", "");
                    setFieldValue("originState", "");
                    setFieldValue("destinationCity", "");
                    setFieldValue("destinationState", "");
                    setFieldValue("SearchText", "");
                    setFieldValue("ShipStartDate", "");
                    setFieldValue("ShipEndDate", "");
                    setFieldValue("DeliveryStartDate", "");
                    setFieldValue("DeliveryEndDate", "");
                    setFieldValue("freightChargeId", "");
                    setFieldValue("IsCanceled", null);
                  };
                  const GetHandlerTableChangeNew = (type, newState) => {
                    if (type === "sort") {
                      let values = { ...paginationOptionsNew };
                      values.sortField = newState.sortField;
                      values.sortOrder = newState.sortOrder;
                      values.fromLoading = false;
                      setpaginationOptionsNew(values);
                      if (
                        newState.sortField !== paginationOptionsNew.sortField ||
                        newState.sortOrder !== paginationOptionsNew.sortOrder
                      ) {
                        GetDataByStatus(
                          FilterTrackingBoard.CustomerId,
                          FilterTrackingBoard.CarrierId,
                          FilterTrackingBoard.statusId,
                          FilterTrackingBoard.CapacityOperationId,
                          FilterTrackingBoard.CommercialAssigneeId,
                          FilterTrackingBoard.CommercialOperationId,
                          FilterTrackingBoard.modeId,
                          FilterTrackingBoard.ShipmentTypeId,
                          FilterTrackingBoard.capacityAssigneeId,
                          FilterTrackingBoard.originCity,
                          FilterTrackingBoard.originState,
                          FilterTrackingBoard.destinationCity,
                          FilterTrackingBoard.destinationState,
                          FilterTrackingBoard.ShipStartDate,
                          FilterTrackingBoard.ShipEndDate,
                          FilterTrackingBoard.DeliveryStartDate,
                          FilterTrackingBoard.DeliveryEndDate,
                          paginationOptionsNew.page,
                          paginationOptionsNew.sizePerPage,
                          newState.sortField,
                          newState.sortOrder,
                          FilterTrackingBoard.freightChargeId,
                          FilterTrackingBoard.IsCanceled,
                          FilterTrackingBoard.CurrentEvent
                        );
                      }
                    }
                    if (type === "pagination") {
                      let values = { ...paginationOptionsNew };
                      values.sizePerPage = newState.sizePerPage;
                      values.page =
                        newState.page === undefined ? 1 : newState.page;
                      values.fromLoading = false;
                      setpaginationOptionsNew(values);
                      GetDataByStatus(
                        FilterTrackingBoard.CustomerId,
                        FilterTrackingBoard.CarrierId,
                        FilterTrackingBoard.statusId,
                        FilterTrackingBoard.CapacityOperationId,
                        FilterTrackingBoard.CommercialAssigneeId,
                        FilterTrackingBoard.CommercialOperationId,
                        FilterTrackingBoard.modeId,
                        FilterTrackingBoard.ShipmentTypeId,
                        FilterTrackingBoard.capacityAssigneeId,
                        FilterTrackingBoard.originCity,
                        FilterTrackingBoard.originState,
                        FilterTrackingBoard.destinationCity,
                        FilterTrackingBoard.destinationState,
                        FilterTrackingBoard.ShipStartDate,
                        FilterTrackingBoard.ShipEndDate,
                        FilterTrackingBoard.DeliveryStartDate,
                        FilterTrackingBoard.DeliveryEndDate,
                        newState.page === undefined ? 1 : newState.page,
                        newState.sizePerPage,
                        paginationOptionsNew.sortField,
                        paginationOptionsNew.sortOrder,
                        FilterTrackingBoard.freightChargeId,
                        FilterTrackingBoard.IsCanceled,
                        FilterTrackingBoard.CurrentEvent
                      );
                    }
                  };
                  return (
                    <form
                      onSubmit={handleSubmit}
                      className="form form-label-right"
                    >
                      <div className="form-group row mb-2">
                        <div
                          className="col-lg-12"
                          style={{ float: "right" }}
                        ></div>
                      </div>
                      {!IsFilterHide && (
                        <div className="form-group row">
                          <button
                            id="CleareAllFilterDataButton"
                            className="d-none"
                            onClick={() => {
                              CleareAllFilterData();
                            }}
                          ></button>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Customers</p>
                            <Select
                              id="CustomerId"
                              isMulti
                              isClearable
                              value={FilterTrackingBoard.CustomerId}
                              name="CustomerId"
                              options={CustomersList.map((a) => {
                                return { value: a.Id, label: a.Name };
                              })}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CustomerId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.CustomerId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CustomerId", []);
                                  let val = { ...FilterTrackingBoard };
                                  val.CustomerId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Carriers</p>
                            <Select
                              isMulti
                              isClearable
                              name="CarrierId"
                              id="CarrierId"
                              label="Carrier"
                              value={FilterTrackingBoard.CarrierId}
                              options={CarriersList.map((a) => {
                                return { value: a.Id, label: a.Name };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CarrierId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.CarrierId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CarrierId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.CarrierId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Modes</p>
                            <Select
                              isMulti
                              isClearable
                              name="modeId"
                              id="modeId"
                              label="Mode"
                              value={FilterTrackingBoard.modeId}
                              options={ModesList.map((a) => {
                                return { value: a.Id, label: a.Name };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("modeId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.modeId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("modeId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.modeId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Shipment Type</p>

                            <Select
                              isMulti
                              isClearable
                              name="ShipmentTypeId"
                              id="ShipmentTypeId"
                              label="ShipmentType"
                              value={FilterTrackingBoard.ShipmentTypeId}
                              options={ShipmentTypeList.map((a) => {
                                return { value: a.Id, label: a.Name };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("ShipmentTypeId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.ShipmentTypeId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("ShipmentTypeId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.ShipmentTypeId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Statuses</p>
                            <Select
                              isMulti
                              isClearable
                              name="statusId"
                              id="statusId"
                              label="Status"
                              value={FilterTrackingBoard.statusId}
                              options={StatusList.map((a) => {
                                return { value: a.Id, label: a.Name };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("statusId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.statusId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("statusId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.statusId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Current Event</p>
                            <Select
                              isMulti
                              isClearable
                              name="CurrentEvent"
                              id="CurrentEvent"
                              label="Current Event"
                              value={FilterTrackingBoard.CurrentEvent}
                              options={CurrentEventList?.map((a) => {
                                return { value: a.Code, label: a.EventName };
                              })}
                              onChange={(e) => {
                                console.log("E", e);
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CurrentEvent", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.CurrentEvent = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CurrentEvent", []);
                                  let val = { ...FilterTrackingBoard };
                                  val.CurrentEvent = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Freight Charge</p>
                            <Select
                              options={
                                freightCharges !== undefined &&
                                  freightCharges !== null &&
                                  freightCharges?.length > 0
                                  ? freightCharges.map((item) => {
                                    return {
                                      value: item.Id,
                                      label: item.Name,
                                    };
                                  })
                                  : []
                              }
                              isClearable
                              value={
                                values?.freightChargeId
                                  ? {
                                    value: values?.freightChargeId,
                                    label: freightCharges?.find(
                                      (x) => x.Id === values?.freightChargeId
                                    )?.Name,
                                  }
                                  : {
                                    value: 98,
                                    label: freightCharges?.find(
                                      (x) => x.Id === 98
                                    )?.Name,
                                  }
                              }
                              onChange={(e) => {
                                setFieldValue(
                                  "freightChargeId",
                                  e?.value ?? null
                                );
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.freightChargeId = e?.value ?? null;
                                setFilterTrackingBoard(val);
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className={`col-xl-3 col-md-3 col-lg-3`}>
                            <label className="mb-2">Is Canceled</label>
                            <Select
                              name="Canceled"
                              onChange={(e) => {
                                let value = e?.value;
                                if (value === 1) {
                                  value = false;
                                } else if (value === 2) {
                                  value = true;
                                } else {
                                  value = "";
                                }
                                setIsCancel(e ?? null);
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                setFieldValue("IsCanceled", value ?? null);
                                let val = { ...FilterTrackingBoard };
                                val.IsCanceled = value ?? null;
                                setFilterTrackingBoard(val);
                                handleSubmit();
                              }}
                              isClearable
                              options={listCanceled}
                              value={IsCancel}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Capacity Assignment</p>
                            <Select
                              isMulti
                              isClearable
                              name="capacityAssigneeId"
                              id="capacityAssigneeId"
                              label="User"
                              value={FilterTrackingBoard.capacityAssigneeId}
                              options={UsersList.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                  IsGroup: a.IsGroup,
                                };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("capacityAssigneeId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.capacityAssigneeId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("capacityAssigneeId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.capacityAssigneeId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Capacity Operations</p>
                            <Select
                              isMulti
                              isClearable
                              name="CapacityOperationId"
                              id="CapacityOperationId"
                              label="User"
                              value={FilterTrackingBoard.CapacityOperationId}
                              options={UsersList.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                  IsGroup: a.IsGroup,
                                };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CapacityOperationId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.CapacityOperationId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CapacityOperationId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.CapacityOperationId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Commercial Assignment</p>
                            <Select
                              isMulti
                              isClearable
                              name="CommercialAssigneeId"
                              id="CommercialAssigneeId"
                              label="User"
                              value={FilterTrackingBoard.CommercialAssigneeId}
                              options={UsersList.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                  IsGroup: a.IsGroup,
                                };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CommercialAssigneeId", e);
                                  let val = { ...FilterTrackingBoard };
                                  val.CommercialAssigneeId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CommercialAssigneeId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.CommercialAssigneeId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 mb-md-3">
                            <p className="mb-2">Commercial Operations</p>
                            <Select
                              isMulti
                              isClearable
                              name="CommercialOperationId"
                              id="CommercialOperationId"
                              label="User"
                              value={FilterTrackingBoard.CommercialOperationId}
                              options={UsersList.map((a) => {
                                return {
                                  value: a.Id,
                                  label: a.Name,
                                  IsGroup: a.IsGroup,
                                };
                              })}
                              onChange={(e) => {
                                if (e !== null) {
                                  setFieldValue("CommercialOperationId", e);
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  let val = { ...FilterTrackingBoard };
                                  val.CommercialOperationId = e;
                                  setFilterTrackingBoard(val);
                                } else {
                                  let paginationOption = { ...paginationOptionsNew };
                                  paginationOption.page = 1;
                                  setpaginationOptionsNew(paginationOption)
                                  setFieldValue("CommercialOperationId", null);
                                  let val = { ...FilterTrackingBoard };
                                  val.CommercialOperationId = [];
                                  setFilterTrackingBoard(val);
                                }
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Origin City </p>
                            <input
                              name="originCity"
                              className="form-control"
                              placeholder="Origin City"
                              value={FilterTrackingBoard.originCity}
                              onChange={(e) => {
                                setFieldValue("originCity", e.target.value);
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.originCity = e.target.value;
                                setFilterTrackingBoard(val);
                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(function () {
                                  handleSubmit();
                                }, 500);
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Origin State</p>
                            <Select
                              name="originState"
                              isClearable
                              value={StatesUs.filter(
                                (a) =>
                                  a.Name === FilterTrackingBoard.originState
                              ).map((item) => {
                                return { value: item.Name, label: item.Name };
                              })}
                              options={StatesUs.map((item) => {
                                return { value: item.Name, label: item.Name };
                              })}
                              onChange={(e) => {
                                setFieldValue(
                                  "originState",
                                  e === null ? "" : e?.value
                                );
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.originState = e === null ? "" : e?.value;
                                setFilterTrackingBoard(val);

                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Destination City</p>
                            <input
                              name="destinationCity"
                              className="form-control"
                              placeholder="Destination City"
                              value={FilterTrackingBoard.destinationCity}
                              onChange={(e) => {
                                setFieldValue(
                                  "destinationCity",
                                  e.target.value
                                );
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.destinationCity = e.target.value;
                                setFilterTrackingBoard(val);

                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(function () {
                                  handleSubmit();
                                }, 500);
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Destination State</p>
                            <Select
                              name="destinationState"
                              isClearable
                              value={StatesUs.filter(
                                (a) =>
                                  a.Name ===
                                  FilterTrackingBoard.destinationState
                              ).map((item) => {
                                return { value: item.Name, label: item.Name };
                              })}
                              options={StatesUs.map((item) => {
                                return { value: item.Name, label: item.Name };
                              })}
                              onChange={(e) => {
                                setFieldValue(
                                  "destinationState",
                                  e === null ? "" : e?.value
                                );
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.destinationState =
                                  e === null ? "" : e?.value;
                                setFilterTrackingBoard(val);

                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Ship Date Start - End</p>
                            <RangeDatePicker
                              startDatePlaceholder="Start Date"
                              endDatePlaceholder="End Date"
                              className="my-own-class-name p-0"
                              startDate={values.ShipStartDate}
                              endDate={values.ShipEndDate}
                              onChange={(startDate, endDate) => {
                                if (startDate === null) {
                                  setFieldValue("ShipStartDate", "");
                                } else {
                                  setFieldValue(
                                    "ShipStartDate",
                                    moment(startDate).format("YYYY-MM-DD")
                                  );
                                }
                                if (endDate === null) {
                                  setFieldValue("ShipEndDate", "");
                                } else {
                                  setFieldValue(
                                    "ShipEndDate",
                                    moment(endDate).format("YYYY-MM-DD")
                                  );
                                }
                                let val = { ...FilterTrackingBoard };
                                val.ShipStartDate = moment(startDate).format(
                                  "YYYY-MM-DD"
                                );
                                val.ShipEndDate = moment(endDate).format(
                                  "YYYY-MM-DD"
                                );
                                setFilterTrackingBoard(val);
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Delivery Date Start - End</p>
                            <RangeDatePicker
                              startDatePlaceholder="Start Date"
                              endDatePlaceholder="End Date"
                              className="my-own-class-name p-0"
                              startDate={values.DeliveryStartDate}
                              endDate={values.DeliveryEndDate}
                              onChange={(startDate, endDate) => {
                                if (startDate === null) {
                                  setFieldValue("DeliveryStartDate", "");
                                } else {
                                  setFieldValue(
                                    "DeliveryStartDate",
                                    moment(startDate).format("YYYY-MM-DD")
                                  );
                                }
                                if (endDate === null) {
                                  setFieldValue("DeliveryEndDate", "");
                                } else {
                                  setFieldValue(
                                    "DeliveryEndDate",
                                    moment(endDate).format("YYYY-MM-DD")
                                  );
                                }
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.DeliveryStartDate = moment(
                                  startDate
                                ).format("YYYY-MM-DD");
                                val.DeliveryEndDate = moment(endDate).format(
                                  "YYYY-MM-DD"
                                );
                                setFilterTrackingBoard(val);
                                handleSubmit();
                              }}
                            />
                          </div>
                          <div className="col-xl-3 col-md-3 col-lg-3">
                            <p className="mb-2">Search</p>
                            <input
                              name="SearchText"
                              className="form-control"
                              placeholder="Search"
                              value={FilterTrackingBoard.SearchText}
                              onChange={(e) => {
                                setFieldValue("SearchText", e.target.value);
                                let paginationOption = { ...paginationOptionsNew };
                                paginationOption.page = 1;
                                setpaginationOptionsNew(paginationOption)
                                let val = { ...FilterTrackingBoard };
                                val.SearchText = e.target.value;
                                setFilterTrackingBoard(val);
                                clearTimeout(typingTimer);
                                typingTimer = setTimeout(function () {
                                  handleSubmit();
                                }, 500);
                              }}
                            />
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-12">
                          <SimpleZoom
                            titleButton={
                              <>
                                Column visibility{" "}
                                <i class="fas fa-columns text-white ml-5" />
                              </>
                            }
                            body={
                              <div className="row">
                                {columns?.map((x) => (
                                  <div className="col-md-3">
                                    <CheckboxLargeWB
                                      className="font-size-md"
                                      text={x?.text}
                                      checked={!x?.hidden}
                                      onChange={() => {
                                        let a = columns;
                                        a.forEach((y) => {
                                          if (
                                            y?.dataField + y?.text ===
                                            x?.dataField + x?.text
                                          ) {
                                            y.hidden = !y.hidden;
                                          }
                                        });
                                        setcolumns([]);
                                        setTimeout(() => {
                                          setcolumns(a);
                                          localStorage.setItem(
                                            "ColumnVisibility_TrackingBoard",
                                            JSON.stringify(a)
                                          );
                                        }, 1);
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            }
                          />
                          {LoadingData && (
                            <ProgressBar
                              variant="success"
                              animated
                              now={100}
                              style={{ height: "6px", width: "100%" }}
                            />
                          )}
                          {columns.length > 0 ? (
                            <>
                              <BootstrapTable
                                wrapperClasses="table-responsive wrapper1"
                                bordered={false}
                                classes="table table-head-custom table-vertical-center mb-0"
                                bootstrap4
                                id="AtShipper"
                                keyField="Id"
                                data={TrackingBoard}
                                columns={columns}
                                defaultSorted={[{ dataField: "Id", order: "desc" }]}
                              ></BootstrapTable>
                              <PaginationProvider
                                pagination={paginationFactory(paginationOptionsNew)}
                              >
                                {({ paginationProps, paginationTableProps }) => {
                                  return (
                                    <Pagination paginationProps={paginationProps}>
                                      <BootstrapTable
                                        wrapperClasses="table-responsive wrapper2"
                                        bordered={false}
                                        classes="table table-head-custom table-vertical-center"
                                        bootstrap4
                                        remote
                                        id="AtShipper"
                                        keyField="Id"
                                        noDataIndication={
                                          <h4 style={{ textAlign: "center" }}>
                                            There is no data available at the
                                            moment.
                                          </h4>
                                        }
                                        data={TrackingBoard}
                                        columns={columns}
                                        defaultSorted={[
                                          { dataField: "Id", order: "desc" },
                                        ]}
                                        onTableChange={GetHandlerTableChangeNew}
                                        {...paginationTableProps}
                                      >
                                        <PleaseWaitMessage
                                          entities={TrackingBoard}
                                        />
                                        <NoRecordsFoundMessage
                                          entities={TrackingBoard}
                                        />
                                      </BootstrapTable>
                                    </Pagination>
                                  );
                                }}
                              </PaginationProvider>
                            </>
                          ) : ""}

                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
}
