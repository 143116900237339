import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  Col,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import { ModelManagmentFirst } from "../../../app/modules/eMPower/pages/NewShipmentsBeta/customers-table/ModelShipmentManagment";
import SVG from "react-inlinesvg";

import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  toAbsoluteUrl,
} from "../../_helpers";
import moment from "moment";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../controls";
var Spinner2 = require("react-spinkit");
const adapter = new LocalStorage("db");
const db = low(adapter);
const ProductStatusCssClasses = ["success", "info", "success"];
var getDate = function(d) {
  if (d === null || d === "" || d === undefined) return "";
  var format = "MM/DD/YYYY hh:mm A";
  if (
    moment(d)
      .startOf("day")
      .valueOf() === moment(d).valueOf()
  ) {
    format = "MM/DD/YYYY";
  }
  return (
    <span className="label label-lg label-light-primary label-inline">
      {moment(d).format(format)}
    </span>
  );
};
export default function AllShipment({ initFilter }) {
  const [paginationFilter, setpaginationFilter] = useState({
    custom: true,
    startDate: initFilter.startDate,
    endDate: initFilter.endDate,
    customerId: initFilter.customerId,
    totalSize: 0,
    sortField: "Id",
    sortOrder: "desc",
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      {
        text: String(0),
        value: 0,
      },
    ],
    sizePerPage: 10,
    page: 1,
  });
  const [LoadingDataProgress, setLoadingDataProgress] = useState(false);
  useEffect(() => {
    GetModel({
      custom: true,
      startDate: initFilter.startDate,
      endDate: initFilter.endDate,
      customerId: initFilter.customerId,
      totalSize: 0,
      sortField: "Id",
      sortOrder: "desc",
      sizePerPageList: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        {
          text: String(0),
          value: 0,
        },
      ],
      sizePerPage: 10,
      page: 1,
    });
  }, [initFilter]);

  async function GetModel(props) {
    let customerId = props.customerId;
    if (customerId.length > 0) {
      var listcustomerId = customerId.map((a) => a.value);
      customerId = "&customerId=" + listcustomerId.join("&customerId=");
    } else {
      customerId = "";
    }
    setLoadingDataProgress(true);
    await fetch(
      `${window.$apiurl}/dashboard/shipments?startDate=${props.startDate}&endDate=${props.endDate}${customerId}&pageNumber=${props.page}&pageSize=${props.sizePerPage}&sortField=${props.sortField}&sortOrder=${props.sortOrder}&search=`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        setentities(
          a.Shipments.map((a) => {
            return { ...a, id: a.Id };
          }) || []
        );
        var objfilter = { ...paginationFilter };
        objfilter.startDate = initFilter.startDate;
        objfilter.endDate = initFilter.endDate;
        objfilter.totalSize = a?.Pagination?.TotalItemCount || 0;
        objfilter.sortField = props.sortField;
        objfilter.customerId= props.customerId;
        objfilter.sortOrder = props.sortOrder;
        objfilter.sizePerPageList = [
          { text: "10", value: 10 },
          { text: "25", value: 25 },
          { text: "50", value: 50 },
          {
            text: String(a?.Pagination?.TotalItemCount || 0),
            value: a?.Pagination?.TotalItemCount || 0,
          },
        ];
        objfilter.sizePerPage = a?.Pagination?.PageSize;
        objfilter.page = a?.Pagination?.PageNumber;
        setpaginationFilter(objfilter);
        setLoadingDataProgress(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDataProgress(false);
      });
  }

  const [entities, setentities] = useState([]);
  const columnsFirst = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      style: {
        minWidth: "120px",
      },
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <ModelManagmentFirst row={row} />;
      },
    },
    {
      dataField: "EquipmentNumber",
      text: "Trailer Number",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CustomerCode !== null &&
          row.CustomerCode !== "" &&
          row.CustomerCode !== undefined
        ) {
          return (
            <>
              {row.CustomerCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CustomerName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "ShipperName",
      text: "Shipper Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.OriginCompanyName !== null ? row.OriginCompanyName : "") +
          "/" +
          row.FromCity +
          (row.FromState !== null && row.FromState !== ""
            ? ", " + row.FromState
            : "");
        if (
          row.OriginCompanyName !== null &&
          row.OriginCompanyName !== "" &&
          row.OriginCompanyName !== undefined
        ) {
          return (
            <>
              {row.OriginCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "ConsigneeName",
      text: "Consignee Name/City State",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        var string =
          (row.DestinationCompanyName !== null
            ? row.DestinationCompanyName
            : "") +
          "/" +
          row.ToCity +
          (row.ToState !== null && row.ToState !== ""
            ? ", " + row.ToState
            : "");
        if (
          row.DestinationCompanyName !== null &&
          row.DestinationCompanyName !== "" &&
          row.DestinationCompanyName !== undefined
        ) {
          return (
            <>
              {row.DestinationCompanyCode + " "}
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">{string}</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "FreightCharge",
      text: "Freight Charge",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "DeliveryEta",
      text: "Delivery ETA",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.DeliveryEta)}</>;
      },
    },
    {
      dataField: "StatusDeliveredDate",
      text: "Actual Delivered Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.StatusDeliveredDate)}</>;
      },
    },
    {
      dataField: "RequestedPickUpDate",
      text: "PICKUP ready DATE/Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.RequestedPickUpDate)}</>;
      },
    },
    {
      dataField: "PickupAppointmentDate",
      text: "PickUp by Date/Time",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.PickupAppointmentDate)}</>;
      },
    }, //LastShipDate
    {
      dataField: "LastShipDate",
      text: "Last Ship Date",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{getDate(row.LastShipDate)}</>;
      },
    },
    {
      dataField: "DeliveryAppointmentDate",
      text: "Delivery Appointment",
      sort: true,
      sortCaret: sortCaret,
    },

    {
      dataField: "LoadingType",
      text: "Loading Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Weight",
      text: "Weight",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return <>{row.Weight}</>;
      },
    },
    {
      dataField: "Commodity",
      text: "Commodity",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "CarrierName",
      text: "Carrier",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        if (
          row.CarrierName !== null &&
          row.CarrierName !== "" &&
          row.CarrierName !== undefined
        ) {
          return (
            <>
              {row.CarrierCode + " "}
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    {row.CarrierName}
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm"
                >
                  <span className="svg-icon svg-icon-md svg-icon-info">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            </>
          );
        }
      },
    },
    {
      dataField: "CarrierPhoneNumber",
      text: "Carrier Phone#",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "EquipmentType",
      text: "Equipment Type",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Alerts",
      text: "Alerts",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <>
            {row.FfAlert && (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ pointerEvents: "none" }}
                    id="products-delete-tooltip"
                  >
                    <div dangerouslySetInnerHTML={{ __html: row.FfAlert }} />
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-warning btn-sm ml-1"
                >
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Warning-1-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.PickupAllert && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="customers-edit-tooltip">
                    The pickup date passed and no vendor has been selected
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-warning btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-warning">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Code/Warning-1-circle.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.IsDispatchReportSent && (
              <OverlayTrigger
                className="mr-2"
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    The outbound dispatch report have been sent to the carrier
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Sending mail.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.AlertHeavy && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    This Shipment is heavy
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/weight-icon.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.AlertHot && (
              <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">Hot</Tooltip>}
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-light btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/fire.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.AlertDate && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    Shipment has Pickup or Delivery Appointment Date
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                >
                  <span className="svg-icon svg-icon-md svg-icon-action">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Clock.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            {row.AlertHazardousMaterial && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">
                    Shipment contain Hazardous materials
                  </Tooltip>
                }
              >
                <a
                  href={() => false}
                  className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                  //onClick={() => openEditProductPage(row.id)}
                >
                  <span className="svg-icon svg-icon-md svg-icon-danger">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/296395.svg"
                      )}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
          </>
        );
      },
      classes: "text-center",
      headerClasses: "text-center",
      style: {
        minWidth: "100px",
      },
    },
    {
      dataField: "Status",
      text: "Status",
      id: "actionsShipmentsTab",
      classes: "text-center px-0",
      headerClasses: "text-center pr-3",
      sort: true,
      sortCaret: sortCaret,
      formatter: (cell, row) => {
        return (
          <span
            className={`label label-lg label-light-${
              ProductStatusCssClasses[row.StatusId]
            } label-inline`}
            //style={{ cursor: "pointer" }}
            //onClick={() => setshow(true)}
          >
            {row.Status}
          </span>
        );
      },
    },
  ];

  const GetHandlerTableChangeNew = (type, newState) => {
    if (type === "sort") {
      let values = { ...paginationFilter };
      values.sortField = newState.sortField;
      values.sortOrder = newState.sortOrder;
      GetModel(values);
    }
    if (type === "pagination") {
      let values = { ...paginationFilter };
      values.sizePerPage = newState.sizePerPage;
      values.page = newState.page === undefined ? 1 : newState.page;
      GetModel(values);
    }
  };
  return (
    <div className={`card card-custom `}>
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          All Shipments
        </h3>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div className="col-12">
            {LoadingDataProgress && (
              <ProgressBar
                variant="success"
                animated
                now={100}
                style={{ height: "6px", width: "100%" }}
              />
            )}
            <PaginationProvider
              pagination={paginationFactory(paginationFilter)}
            >
              {({ paginationProps, paginationTableProps }) => {
                return (
                  <Pagination paginationProps={paginationProps}>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center"
                      bootstrap4
                      remote
                      id="AtShipper"
                      keyField="Id"
                      noDataIndication={
                        LoadingDataProgress ? (
                          <Row>
                            <Col>
                              <div
                                className="pb-md-8 pt-md-8"
                                style={{
                                  textAlign: "-webkit-center",
                                }}
                              >
                                <Spinner2 name="folding-cube" color="blue" />
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          <h4
                            className="text-muted my-5"
                            style={{ textAlign: "center" }}
                          >
                            There is no data available at the moment.
                          </h4>
                        )
                      }
                      data={entities === null ? [] : entities}
                      columns={columnsFirst}
                      defaultSorted={[{ dataField: "Id", order: "desc" }]}
                      onTableChange={GetHandlerTableChangeNew}
                      {...paginationTableProps}
                    >
                      <PleaseWaitMessage
                        entities={entities === null ? [] : entities}
                      />
                      <NoRecordsFoundMessage
                        entities={entities === null ? [] : entities}
                      />
                    </BootstrapTable>
                  </Pagination>
                );
              }}
            </PaginationProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
