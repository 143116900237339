import { Button, IconButton, LinearProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SVG from "react-inlinesvg";
import Select, { components, PlaceholderProps } from "react-select";
import {
  headerSortingClasses,
  SimpleZoom,
  sortCaret,
  toAbsoluteUrl
} from "../../../../../../../_metronic/_helpers";
import { momentDate } from "../../../../../../../_metronic/_helpers/ATHelpers";
import { CheckboxLargeWB } from "../../../../../../../_metronic/_partials/controls";
import SelectPagination from "../../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import { Notification } from "../../../../../Notification";
import "../../customers-table/FileViewerStyle.css";
import { IsValid } from "../Functions/Validation";
const adapter = new LocalStorage("db");
const db = low(adapter);
export function UpdateTicketStatus({ data, handleChangeTicketObject }) {
  return (
    <Form>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridSubject">
          <Form.Label>TicketSubject</Form.Label>
          <Form.Control
            type="text"
            value={data.TicketSubject}
            onChange={(e) => {
              var value = { data };
              value.TicketSubject = e.target.value;
              handleChangeTicketObject(value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridSubject">
          <Form.Label>Owner</Form.Label>
          <Form.Control type="text" />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCategory">
          <Form.Label>Category</Form.Label>
          <Select
            isSearchable={true}
            isClearable
            isMulti
            value={{ value: data?.CategoryId, label: data?.CategoryName }}
            onChange={(e) => {
              var value = { data };
              if (e === null) {
                value.CategoryId = "";
              } else {
                value.CategoryId = e.value;
              }
              handleChangeTicketObject(value);
            }}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCategory">
          <Form.Label>Priority</Form.Label>
          <Select isSearchable={true} isClearable isMulti />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridSubject">
          <Form.Label>DueDtae</Form.Label>
          <Form.Control type="text" />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridSubject">
          <Form.Label>Team</Form.Label>
          <Form.Control type="text" />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formGridCategory">
          <Form.Label>NoteBody</Form.Label>
          <Select isSearchable={true} isClearable isMulti />
        </Form.Group>
        <Form.Group as={Col} controlId="formGridCategory">
          <Form.Label>FileAttachement</Form.Label>
          <Select isSearchable={true} isClearable isMulti />
        </Form.Group>
      </Form.Row>
    </Form>
  );
}
export function AssignComponent() {
  const [Selected, setSelected] = useState([]);
  const [SelectedItem, setSelectedItem] = useState(false);
  return (
    <div>
      <SelectPagination
        isSearchable={true}
        onCreateOption={undefined}
        placeholder="Team"
        createOptionPosition="first"
        isClearable
        onChange={(e) => {
          //      var value = { ...TicketSystemObject };
          //         value.UserId = e?.value;
          //      setTicketSystemObject(value);
        }}

        //   options={Users.map(
        //     (x) => {
        //       return {
        //         value: x?.Id,
        //         label: x?.AccountName,
        //       };
        //     }
        //   )}
        //   name="UserId"
        //   className="mt-3"
        //
      />
      <br />
      <br />
      <div className="row">
        <div className="col-4">
          <div className="text-primary mb-3">
            <h6>Team :</h6>
          </div>
          {Selected.map((item) => (
            <AssignProfile
              Info={item}
              setSelectedItem={setSelectedItem}
              SelectedId={SelectedItem?.value}
            />
          ))}
        </div>

        <div className="col-8">
          {SelectedItem?.value && (
            <>
              <div className="text-primary mb-3 ml-5 pl-3">
                <h6>Detail :</h6>
              </div>
              {IsValid("Start Time", "09-06-2021, 5:57 PM")}{" "}
              {IsValid("End Time", "None")}
              {IsValid("Time remaining", "5H:30")}
              {IsValid(
                "Notes",
                "",
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="2"
                  placeholder="Notes"
                ></textarea>
              )}
              {IsValid(
                "Priority",
                "",
                <Select
                  isSearchable={true}
                  isClearable
                  defaultValue={{
                    label: "Medium",
                    value: 2,
                  }}
                  options={[
                    {
                      label: "Highest",
                      value: 1,
                    },
                    {
                      label: "Medium",
                      value: 2,
                    },
                    {
                      label: "Low",
                      value: 3,
                    },
                    {
                      label: "Lowest",
                      value: 4,
                    },
                  ]}
                />
              )}
              {IsValid("Cartons Count ", "177")}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export const AssignCustomOption = (props) => {
  const [Info, setInfo] = useState({});
  useEffect(() => {
    var find = props.options.find((x) => x.value === props.value);
    setInfo(find);
  }, [props.options]);

  return !props.isDisabled ? (
    <div {...props.innerProps}>
      <AssignProfile Info={Info} />
    </div>
  ) : null;
};

export function AssignProfile({ Info, setSelectedItem, SelectedId }) {
  return (
    <div
      className={
        "shadow-sm p-2 mb-5 rounded mb-5 " +
        (SelectedId === Info?.value ? "bg-primary" : "bg-white")
      }
      onClick={() => {
        if (setSelectedItem) setSelectedItem(Info);
      }}
    >
      <img
        src={Info.img}
        className="shadow p-1 bg-white"
        style={{
          height: "45px",
          borderRadius: "50%",
        }}
        alt="img"
      />
      <b
        className={
          "ml-5 " + (SelectedId === Info?.value ? "text-white" : "text-dark")
        }
        style={{ fontSize: "12px" }}
      >
        {Info.label}
      </b>
    </div>
  );
}

export function PeoplesComponent() {
  const [Selected, setSelected] = useState([
    {
      label: "Abdlhadi Tahir",
      value: 1,
      img: "https://ca.slack-edge.com/T0C82LU2Z-U0C86RBND-1fa410822c2b-512",
    },
    {
      label: "Zakaria Issaad",
      value: 2,
      img: "https://ca.slack-edge.com/T0C82LU2Z-UD72JPHRQ-ea43b29fdbc0-512",
    },
  ]);
  const [SelectedItem, setSelectedItem] = useState(false);
  return (
    <div className="d-none">
      <br />
      <br />
      <div className="row">
        <div className="col-4">
          <div className="text-primary mb-3">
            <h6>Assignee :</h6>
          </div>
          {Selected.map((item) => (
            <PeoplesProfile
              Info={item}
              setSelectedItem={setSelectedItem}
              SelectedId={SelectedItem?.value}
            />
          ))}
        </div>

        <div className="col-8">
          {SelectedItem?.value && (
            <>
              <div className="text-primary mb-3 ml-5 pl-3">
                <h6>Detail :</h6>
              </div>
              {IsValid("Start Time", "09-06-2021, 5:57 PM")}{" "}
              {IsValid("End Time", "None")}
              {IsValid("Time remaining", "5H:30")}
              {IsValid(
                "Notes",
                "",
                <>
                  Note containers are flexible bounding boxes that contain the
                  notes that you type or paste on a page.
                  <br />{" "}
                </>
              )}
              {IsValid("Priority", "Medium")}
              {IsValid(
                "Progress",
                "",
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-striped progress-bar-animated"
                    role="progressbar"
                    aria-valuenow="10"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: "10%" }}
                  ></div>{" "}
                  10%
                </div>
              )}
              {IsValid("Cartons Count ", "177")}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function PeoplesProfile({ Info, setSelectedItem, SelectedId }) {
  return (
    <div
      className={
        "shadow-sm p-2 mb-5 rounded mb-5 " +
        (SelectedId === Info?.value ? "bg-primary" : "bg-white")
      }
      onClick={() => {
        if (setSelectedItem) setSelectedItem(Info);
      }}
    >
      <img
        src={Info.img}
        className="shadow p-1 bg-white"
        style={{
          height: "45px",
          borderRadius: "50%",
        }}
        alt="img"
      />
      <b
        className={
          "ml-5 " + (SelectedId === Info?.value ? "text-white" : "text-dark")
        }
        style={{ fontSize: "12px" }}
      >
        {Info.label}
      </b>
    </div>
  );
}

export function POComponent({ List = [] }) {
  const [Activities, setActivities] = useState([]);
  useEffect(() => {
    setActivities(List.reverse() ?? []);
  }, [List]);
  const columns = [
    {
      dataField: "WareHouse",
      text: "WareHouse",
      formatter: (cell, row) => {
        return (
          <>
            {(row?.Locations ?? []).map((x) => (
              <div className="my-3">
                {x?.WarehouseCode}
                <OverlayTrigger
                  overlay={
                    <Tooltip id="customers-delete-tooltip">
                      {x?.WarehouseName}
                    </Tooltip>
                  }
                >
                  <a
                    href={() => false}
                    className="btn btn-icon btn-light btn-hover-warning btn-sm ml-3"
                    onClick={() => {}}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-warning">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
                <br />
              </div>
            ))}
          </>
        );
      },
    },
    {
      dataField: "locations",
      text: "locations",
      formatter: (cell, row) => {
        return (
          <>
            {(row?.Locations ?? []).map((x) => (
              <div className="my-3">
                <b>
                  <i
                    className="fa fa-circle text-primary mr-3"
                    style={{ fontSize: "12px" }}
                  ></i>
                  {x?.LocationName}{" "}
                  <b className="text-primary">{`( ${x?.LocationType} )`}</b>
                </b>
                <br />
              </div>
            ))}
          </>
        );
      },
    },
    {
      dataField: "Date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell, row) => {
        return (
          <>
            {(row?.Locations ?? []).map((x) => (
              <div className="my-3">
                <div className="align-items-center">
                  <span className="label text-primary p-1 m-0 updated">
                    {momentDate(x?.Created)}
                  </span>
                </div>
                <br />
              </div>
            ))}
          </>
        );
      },
    },
  ];
  return (
    <>
      <div className={`h-60 card card-custom`}>
        <div className="card-body pt-5 row now-rapTable">
          <div className="col-xl-12">
            <div className="form-group row"></div>
          </div>
          <div className="col-xl-12" style={{ textAlign: "left" }}>
            {Activities.length > 0 ? (
              <BootstrapTable
                keyField="Id"
                defaultSorted={[{ dataField: "Created", order: "desc" }]}
                data={Activities}
                columns={columns}
                pagination={paginationFactory()}
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                bordered={false}
              />
            ) : (
              <strong>No data to display</strong>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export function ChangeStatus({ id, Id, Status, handleClose, row }) {
  const [ModelShowing, setModelShowing] = useState(false);
  const [ModelShowingQcVendor, setModelShowingQcVendor] = useState(false);
  const [RollBackLoadingPost, setRollBackLoadingPost] = useState(false);
  const [ModelTitle, setModelTitle] = useState("");
  const [ModelText, setModelText] = useState("");
  const [NotesText, setNotesText] = useState("");
  const [optionsVandorList, setoptionsVandorList] = useState([]);
  const [StatusListOptions, setStatusListOptions] = useState([]);
  const [GlobalStatusListOptions, setGlobalStatusListOptions] = useState([]);
  const [StatusSelectedRolledBack, setStatusSelectedRolledBack] = useState(
    null
  );
  const [ReceiptVendorList, setReceiptVendorList] = useState([]);
  const [ReceiptVendorRemoved, setReceiptVendorRemoved] = useState([]);
  const [ReceiveNowVendorId, setReceiveNowVendorId] = useState(null);
  const [SelectedRow, setSelectedRow] = useState([]);
  const [ReceiveNowLoading, setReceiveNowLoading] = useState(false);
  const [LoadReceivedItems, setLoadReceivedItems] = useState([]);
  const [ReceiveNowDataObject, setReceiveNowDataObject] = useState({
    VendorId: null,
    TotalCartons: null,
    TotalPallets: null,
  });
  const [BackStatusText, setBackStatusText] = useState("");
  const [
    CancelModelShowingConfirmation,
    setModelShowingConfirmationCancel,
  ] = useState(false);
  const [ModelShowingConfirmation, setModelShowingConfirmation] = useState(
    false
  );
  const Placeholder = (props: PlaceholderProps<ColourOption>) => {
    return <components.Placeholder {...props} />;
  };
  const columnsLoadReceivedItems = [
    {
      dataField: "Id",
      text: "Item Id",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Sku",
      text: "Sku",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Size",
      text: "Size",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "PurchaseOrder",
      text: "PO",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Location",
      text: "Location",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "Description",
      text: "Description",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "UnitCount",
      text: "Unit Count",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "OverQuantity",
      text: "O Quantity",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "ShortQuantity",
      text: "S Quantity",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "DamagedQuantity",
      text: "D Quantity",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "UnitOfMeasure",
      text: "Unit Of Measure",
      sort: true,
      sortCaret: sortCaret,
    },
  ];
  const selectRow = {
    mode: "checkbox",
    selected: SelectedRow,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        var a = [];
        a = SelectedRow;
        a.push(row.Id);
        setSelectedRow(a);
      } else {
        var a = [];
        a = SelectedRow;
        a = a?.filter((word) => String(word) !== String(row.Id));
        setSelectedRow(a);
      }
    },
    onSelectAll: (isSelected, rows) => {
      if (isSelected) {
        setSelectedRow(LoadReceivedItems.map((a) => a.Id));
      } else {
        setSelectedRow([]);
      }
    },
  };
  const [columns, setcolumns] = useState(columnsLoadReceivedItems);
  (
    JSON.parse(localStorage.getItem("ColumnVisibility_LoadReceived")) || []
  ).forEach((x) => {
    const a = columnsLoadReceivedItems.find(
      (y) => y?.dataField + y?.text === x?.dataField + x?.text
    );
    if (a) {
      a.hidden = x?.hidden || false;
    }
  });
  async function GetDataLoadReceived() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    //receiptIds=621620&receiptIds=621618
    fetch(`${window.$apiurl}/Po/Items?receiptIds=${id}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        var obj = JSON.parse(result);
        obj.length > 0 &&
          obj.map((item) => {
            if (
              item.PullQty !== null &&
              item.OriginQty === null &&
              item.PullQty > 0
            ) {
              item.UpdatedPull = true;
            } else {
              item.UpdatedPull = false;
            }
            return item;
          });
        setLoadReceivedItems(obj || []);
        setSelectedRow(obj.map((a) => a.Id));
      })
      .catch((error) => console.log("error", error));
  }
  async function GetVendorsOptions() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    await fetch(window.$apiurl + "/vendors", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        const opt = [];
        data.Vendors.forEach((Vendor) => {
          opt.push({
            value: Vendor.Id,
            label: Vendor.Code + "-" + Vendor.CompanyName,
            CarrierId: Vendor?.CarrierId,
          });
        });
        setoptionsVandorList(opt);
      })
      .catch((error) => console.log("error", error));
  }
  async function NextStatus(text) {
    if (text === `"IsCancel":true`) {
      if (NotesText === "") {
        Notification("error", "please fill cencled note");
        return;
      } else {
        await fetch(`${window.$apiurl}/transload/receipts/addnote/${id}`, {
          method: "post",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
          body: `{"body":"${NotesText}","CategoryId":12,"accessLevelId":5}`,
        })
          .then((response) => {
            if (response.status !== 200) {
              Notification("error", "Note Note Add");
            }
          })
          .catch((error) => {
            console.error(error);
          });
        await fetch(`${window.$apiurl}/receiptsManagement/updateSingleStatus`, {
          method: "post",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
          body: `{"Id":${id},${text}}`,
        })
          .then(async (response) => {
            const statusCode = await response.status;
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
              return response.json().then((data) => [statusCode, data]);
            } else {
              return response
                .text()
                .then((text) => [statusCode, { Message: "" }]);
            }
          })
          .then((result) => {
            if (result[0] === 200) {
              if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("infoClick", result[1].Message);
              } else {
                if (result[0] === 200) {
                  if (text.indexOf("IsNext") > -1)
                    Notification("success", "Next Status is successful");
                  else if (text.indexOf("IsRollBack") > -1)
                    Notification("success", "Roll Back Status is successful");
                  else if (text.indexOf("IsCancel") > -1)
                    Notification("success", "Cancel Order is successful");
                }
                document.getElementById("ReLoadTableId").click();
              }
            } else {
              if (
                result[1].Message !== null &&
                result[1].Message !== undefined &&
                result[1].Message !== ""
              ) {
                Notification("errorClick", result[1].Message);
              } else {
                Notification("errorClick", "invalid request");
              }
            }
          })
          .catch((error) => {
            console.error(error);
          });
        setNotesText("");
        setModelShowingConfirmationCancel(false);
      }
    } else {
      await fetch(`${window.$apiurl}/receiptsManagement/updateSingleStatus`, {
        method: "post",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
        body: `{"Id":${id},${text}}`,
      })
        .then((response) => {
          if (response.status === 200) {
            if (text.indexOf("IsNext") > -1)
              Notification("success", "Next Status is successful");
            else if (text.indexOf("IsRollBack") > -1)
              Notification("success", "Roll Back Status is successful");
            else if (text.indexOf("IsCancel") > -1)
              Notification("success", "Cancel Order is successful");

            document.getElementById("ReLoadTableId").click();
          }
        })
        .catch((error) => {
          console.error(error);
        });
      setModelShowingConfirmationCancel(false);
      return;
    }
  }
  async function GetListOfPreviewsStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${window.$apiurl}/receiptsManagement/GetReceiptVendors/${id}`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        var data = JSON.parse(result);
        setReceiptVendorList(data);
      })
      .catch((error) => console.log("error", error));
    var statusList = JSON.parse(JSON.stringify(window.$StatusReceiptList));
    setGlobalStatusListOptions(window.$StatusReceiptList || []);
    var list = statusList.map((a) => String(a.StatusId));
    const index = list.indexOf(String(window.$ReceiptsManagmentStatu));
    if (index > -1) {
      statusList.splice(index, statusList.length);
      setStatusListOptions(statusList);
    }
  }
  function Model() {
    return (
      <Modal
        size="sm"
        show={ModelShowing}
        onHide={() => {
          setModelShowing(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <Modal.Title>{ModelTitle}</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModelShowing(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>{ModelTitle} Confirm</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setModelShowing(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="secondary"
            onClick={(e) => {
              NextStatus(`"${ModelText}":true`);
              setModelShowing(false);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <>
      <Modal
        size="xl"
        show={ModelShowingConfirmation}
        aria-labelledby="example-modal-sizes-title-lg"
        className="cancel-Exceptions"
      >
        <Modal.Header>
          <Modal.Title>Roll Back Confirmation</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setStatusSelectedRolledBack(null);
              setReceiptVendorRemoved([]);
              setModelShowingConfirmation(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <label>Select Status:</label>
              <Select
                isSearchable={true}
                isClearable
                components={{ Placeholder }}
                placeholder={"Auto Roll Back"}
                options={StatusListOptions.map((a) => {
                  return { value: a.StatusId, label: a.Name };
                })}
                onChange={(e) => {
                  if (e !== null) {
                    setStatusSelectedRolledBack(e);
                  } else {
                    setStatusSelectedRolledBack(null);
                  }
                }}
              />
              {GlobalStatusListOptions.filter((a) => a.Name === "Dispatched")[0]
                ?.Position >
              GlobalStatusListOptions.filter(
                (a) => a.Name === StatusSelectedRolledBack?.label
              )[0]?.Position ? (
                <>
                  <label className="mt-5">Select Vendors to remove</label>
                  {ReceiptVendorList.map((item, index) => (
                    <CheckboxLargeWB
                      className="font-size-md"
                      name={`Trucker${index}`}
                      text={item.VendorAddress.CompanyName}
                      onChange={(e) => {
                        var list = [];
                        list = ReceiptVendorRemoved;
                        if (e.target.checked) {
                          list.push(item.VendorId);
                          setReceiptVendorRemoved(list || []);
                        } else {
                          var index = list.indexOf(item.VendorId);
                          setReceiptVendorRemoved(
                            list.slice(index, index) || []
                          );
                        }
                      }}
                    />
                  ))}
                </>
              ) : (
                ""
              )}

              <br></br>
              <br></br>
              <label>Roll Back Note:</label>
              <textarea
                className="form-control mb-5"
                rows="4"
                id="BackStatusNotes"
                onBlur={(e) => setBackStatusText(e.target.value)}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-light"
            disabled={RollBackLoadingPost}
            onClick={(e) => {
              setModelShowingConfirmation(false);
            }}
          >
            Cancel
          </button>
          <button
            className="ml-3 btn btn-primary"
            onClick={(e) => {
              if (
                BackStatusText === null ||
                BackStatusText === "" ||
                BackStatusText === undefined
              ) {
                Notification("error", "please fill note");
                return;
              }
              setRollBackLoadingPost(true);
              row = {
                Id: id,
                StatusId:
                  StatusSelectedRolledBack === null
                    ? null
                    : StatusSelectedRolledBack.value,
                VendorsIds: ReceiptVendorRemoved,
                KeepChanges: false,
                Note: BackStatusText,
              };
              fetch(`${window.$apiurl}/receiptsManagement/RollBack`, {
                method: "post",
                withCredentials: true,
                headers: {
                  Accept: "application/json, text/plain, */*",
                  Authorization: `bearer ${db.read().getState().Token}`,
                  "X-FP-API-KEY": "iphone",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(row),
              })
                .then(async (response) => {
                  const statusCode = await response.status;
                  const contentType = response.headers.get("content-type");
                  if (
                    contentType &&
                    contentType.indexOf("application/json") !== -1
                  ) {
                    return response.json().then((data) => [statusCode, data]);
                  } else {
                    return response
                      .text()
                      .then((text) => [statusCode, { Message: "" }]);
                  }
                })
                .then((result) => {
                  if (result[0] === 200) {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("infoClick", result[1].Message);
                      setModelShowingConfirmation(false);
                      document.getElementById("ReLoadTableId").click();
                    } else {
                      setModelShowingConfirmation(false);
                      setBackStatusText("");
                      setStatusSelectedRolledBack(null);
                      setReceiptVendorRemoved([]);
                      Notification("success", "Roll Back Status is successful");
                      document.getElementById("ReLoadTableId").click();
                    }
                    setRollBackLoadingPost(false);
                  } else {
                    if (
                      result[1].Message !== null &&
                      result[1].Message !== undefined &&
                      result[1].Message !== ""
                    ) {
                      Notification("errorClick", result[1].Message);
                      setModelShowingConfirmation(false);
                    } else {
                      Notification("errorClick", "invalid request");
                    }
                    setRollBackLoadingPost(false);
                  }
                })
                .catch((error) => {
                  console.error(error);
                  setRollBackLoadingPost(false);
                });
            }}
          >
            Confirm
            {RollBackLoadingPost && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </button>
        </Modal.Footer>
      </Modal>
      {/* ModelShowingQcVendor */}
      <Modal
        size="xl"
        id="ConfirmCancelModal"
        show={ModelShowingQcVendor}
        aria-labelledby="example-modal-sizes-title-lg"
        className="cancel-Exceptions"
      >
        <Modal.Header>
          <Modal.Title>Receive Now RM #: {id}</Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModelShowingQcVendor(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group row col-md-12" style={{ zIndex: "99999" }}>
            <div className="col-md-6">
              <label>Select Vendor</label>
              <SelectPagination
                isClearable
                options={optionsVandorList}
                onChange={(x) => {
                  let values = { ...ReceiveNowDataObject };
                  values.VendorId = x?.value !== undefined ? x?.value : null;
                  setReceiveNowDataObject(values);
                }}
              />
              {optionsVandorList?.length === 0 && <LinearProgress />}
            </div>
            <div className="col-md-3">
              <label>Total Cartons</label>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder="Total Cartons"
                  name="TotalCartons"
                  onChange={(e) => {
                    let values = { ...ReceiveNowDataObject };
                    values.TotalCartons = parseInt(e.target.value);
                    setReceiveNowDataObject(values);
                  }}
                />
              </Form.Group>
            </div>
            <div className="col-md-3">
              <label>Total Pallets</label>
              <Form.Group>
                <Form.Control
                  type="number"
                  placeholder="Total Pallets"
                  name="TotalPallets"
                  onChange={(e) => {
                    let values = { ...ReceiveNowDataObject };
                    values.TotalPallets = parseInt(e.target.value);
                    setReceiveNowDataObject(values);
                  }}
                />
              </Form.Group>
            </div>
          </div>
          <div className="form-group row">
            <SimpleZoom
              titleButton={
                <>
                  Column visibility <i class="fas fa-columns text-white ml-5" />
                </>
              }
              body={
                <div className="row">
                  {columns.map((x) => (
                    <div className="col-md-3">
                      <CheckboxLargeWB
                        className="font-size-md"
                        text={x?.text}
                        checked={!x?.hidden}
                        onChange={() => {
                          let a = columns;
                          a.forEach((y) => {
                            if (
                              y?.dataField + y?.text ===
                              x?.dataField + x?.text
                            ) {
                              y.hidden = !y.hidden;
                            }
                          });
                          setcolumns([]);
                          setTimeout(() => {
                            setcolumns(a);
                            localStorage.setItem(
                              "ColumnVisibility_LoadReceived",
                              JSON.stringify(a)
                            );
                          }, 1);
                        }}
                      />
                    </div>
                  ))}
                  {columns.length === 0 && (
                    <Spinner
                      id="InboundLoading"
                      animation="grow"
                      role="status"
                      variant="primary"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  )}
                </div>
              }
            />
            <BootstrapTable
              id="ShipmentId"
              keyField="Id"
              data={LoadReceivedItems}
              columns={columns}
              pagination={paginationFactory()}
              wrapperClasses="table-responsive"
              //rowStyle={ rowStyle }
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              bordered={false}
              selectRow={selectRow}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModelShowingQcVendor(false);
            }}
          >
            Cancel
          </Button>

          <Button
            variant="secondary"
            disabled={ReceiveNowLoading}
            className="btn btn-success btn-elevate ml-5"
            onClick={(e) => {
              if (
                ReceiveNowDataObject.VendorId === null ||
                ReceiveNowDataObject.VendorId === "" ||
                ReceiveNowDataObject.VendorId === undefined
              ) {
                Notification("error", "Please select Vendor");
                return;
              }
              setReceiveNowLoading(true);
              //new request
              var myHeaders = new Headers();
              myHeaders.append(
                "Authorization",
                `Bearer ${db.read().getState().Token}`
              );
              myHeaders.append("Content-Type", "application/json");

              var raw = JSON.stringify({
                Ids: SelectedRow,
                VendorId: ReceiveNowDataObject.VendorId,
                TotalCartons: ReceiveNowDataObject.TotalCartons,
                TotalPallets: ReceiveNowDataObject.TotalPallets,
                IsReceiveNow: true,
              });

              var requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
              };

              fetch(`${window.$apiurl}/po/Receive`, requestOptions)
                .then(async (result) => {
                  const statusCode = result.status;
                  if (statusCode === 200) {
                    setReceiveNowLoading(false);
                    setReceiveNowVendorId(null);
                    setModelShowingQcVendor(false);
                    Notification(
                      "success",
                      `Status of Receipts : [${id}] has been changed successfully`
                    );
                    $("#ReLoadTableId").trigger("click");
                    setTimeout(() => {
                      document.getElementById("kt_body").style.overflow =
                        "scroll";
                    }, 500);
                  } else {
                    try {
                      const data = await result.json();
                      if (data.Message) {
                        setReceiveNowLoading(false);
                        setModelShowingQcVendor(false);
                        Notification("error", data.Message);
                      }
                    } catch (err) {}
                  }
                })
                .catch((error) => {
                  setReceiveNowLoading(false);
                  console.log("error", error);
                });
              //End New Request
            }}
          >
            Save Changes
            {ReceiveNowLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end QC Now */}
      <Modal
        size="lg"
        id="ConfirmCancelModal"
        show={CancelModelShowingConfirmation}
        aria-labelledby="example-modal-sizes-title-lg"
        className="cancel-Exceptions"
      >
        <Modal.Header>
          <Modal.Title>
            Are you sure that you want to{" "}
            {row.IsCanceled === true ? "UnCancel" : "Cancel"} this Receipt ?
          </Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={(e) => {
              setModelShowingConfirmationCancel(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          <label>
            {row.IsCanceled === true ? "UnCancel" : "Cancel"} Confirmation Note
          </label>
          <textarea
            className="form-control mb-5"
            rows="4"
            id="CancelNotes"
            onBlur={(e) => setNotesText(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-danger btn-elevate"
            onClick={(e) => {
              setModelShowingConfirmationCancel(false);
            }}
          >
            No
          </Button>

          <Button
            variant="secondary"
            className="btn btn-light btn-elevate ml-5"
            onClick={(e) => {
              NextStatus(`"IsCancel":true`);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end ConfirmModal */}
      <Model />
      {window.$ReceiptsManagmentStatu !== "" &&
        window.$ReceiptsManagmentStatu !== undefined && (
          <>
            <li className="navi-item">
              <a
                href="javascript(0)"
                className="navi-link"
                onClick={(e) => {
                  e.preventDefault();
                  if (!document.getElementById(id).checked) {
                    document.getElementById(id).click();
                  }
                  setTimeout(() => {
                    for (
                      let i = 0;
                      i < document.getElementsByTagName("i").length;
                      i++
                    ) {
                      if (
                        document
                          .getElementsByTagName("i")
                          [i].classList.contains("flaticon-medical")
                      ) {
                        document
                          .getElementsByTagName("i")
                          [
                            i
                          ].parentElement.parentElement.parentElement.parentElement.parentNode.nextSibling.click();
                      }
                    }
                  }, 500);
                }}
              >
                <span className="navi-icon">
                  <i className="flaticon2-right-arrow text-primary"></i>
                </span>
                <span className="navi-text">Next Status</span>
              </a>
            </li>
            <li className="navi-item">
              <a
                href="javascript(0)"
                className="navi-link"
                onClick={(e) => {
                  e.preventDefault();
                  setModelShowing(false);
                  GetListOfPreviewsStatus();
                  setModelShowingConfirmation(true);
                }}
              >
                <span className="navi-icon">
                  <i className="flaticon2-left-arrow text-warning"></i>
                </span>
                <span className="navi-text">Back Status</span>
              </a>
            </li>
          </>
        )}
      <div className="dropdown-divider"></div>
      {String(window.$ReceiptManagementModeId) === "31" &&
        String(window.$ReceiptsManagmentStatu) === "15" && (
          <li className="navi-item">
            <a
              href="javascript(0)"
              className="navi-link"
              onClick={async (e) => {
                e.preventDefault();
                setModelShowing(false);
                setModelShowingQcVendor(true);
                await GetDataLoadReceived();
                await GetVendorsOptions();
              }}
            >
              <span className="navi-icon">
                <i className="fas fa-download text-info"></i>
              </span>
              <span className="navi-text">Receive Now</span>
            </a>
          </li>
        )}
      <li className="navi-item">
        <a
          href="javascript(0)"
          className="navi-link"
          onClick={(e) => {
            e.preventDefault();
            setModelShowing(false);
            setModelShowingConfirmationCancel(true);
          }}
        >
          <span className="navi-icon">
            <i className="fas fa-times-circle text-danger"></i>
          </span>
          <span className="navi-text">
            {row.IsCanceled === true ? "UnCancel" : "Cancel"}
          </span>
        </a>
      </li>
    </>
  );
}
