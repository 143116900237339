import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../modules/Notification";
import { CheckboxLargeWB } from "../../../_metronic/_partials/controls";
import { useParams } from "react-router-dom";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { currencyFormat } from "../../../_metronic/_helpers/ATHelpers";
const adapter = new LocalStorage("db");
const db = low(adapter);
function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "--/--/----";
  }
}
function tConvert(time) {
  if (time === null || time === undefined || time === "") {
    return "";
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
    };
    this.state = { data: obj };
  }
  async componentDidMount() {
    await fetch(
      window.$apiurl + "/ShipmentBilling/Outbound/report/" + this.props.id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        if (statusCode !== 200) {
          Notification("error", "No data found !");
        }
        const json = await response.json();
        this.setState({ data: json });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const formData = this.state.data;
    const start = Date.now();
    const dateNow = formatDate(start);
    console.log("object");
    console.log(formData);

    return (
      <>
        <Helmet>
          <title>eMPower | Rate Confitmation </title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-5 bl-form border-0">
            <div className="card-body p-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-10">
                    <div className="col-md-8 px-0 mr-auto">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="border-0">
                              <b className="h6">{formData.Title}</b>
                              <br></br>
                              {formData.BillTo?.Name}
                              <br></br>
                              {formData.BillTo?.Address1 === undefined
                                ? " "
                                : formData.BillTo?.Address1}
                              {(formData.BillTo?.Address2 === undefined) |
                              (formData.BillTo?.Address2 === null)
                                ? " "
                                : formData.BillTo?.Address2}
                              <br></br>
                              {formData.BillTo?.City === undefined
                                ? " "
                                : formData.BillTo?.City}
                              ,{" "}
                              {formData.BillTo?.State === undefined
                                ? " "
                                : formData.BillTo?.State}{" "}
                              {formData.BillTo?.ZipCode === undefined
                                ? " "
                                : formData.BillTo?.ZipCode}
                              <br></br>
                              {formData.BillTo?.Phone === undefined
                                ? " "
                                : formData.BillTo?.Phone}
                              <br></br>
                              {formData.BillTo?.Reference === undefined
                                ? " "
                                : formData.BillTo?.Reference}
                              <br></br>
                            </td>
                            <td className="border-0 text-left">
                              <Link to="#" class="mb-5 max-w-100px">
                                <span class="svg-icon svg-icon-full">
                                  <img
                                    src="/media/logos/Mitco.svg"
                                    className="w-75"
                                    alt="mitco logo"
                                  />
                                </span>
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">Carrier Name</th>
                            <th className="text-center">PickUp Date </th>
                            <th className="text-center">Delivery Date</th>
                            <th className="text-center">Shipment Id</th>
                            <th className="text-center">Equipment Number</th>
                            <th className="text-center">Shipper Ref</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.CarrierName}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formatDate(formData.ReadyDate)}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formatDate(formData.DateNeeded)}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.LoadNumber}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Equipment}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.ShipperRef}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-2">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">Service</th>
                            <th className="text-center">Mode</th>
                            <th className="text-center">Service Level</th>
                            <th className="text-center">Equipment Size</th>
                            <th className="text-center">Equipment Type</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Service}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Mode}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.ServiceLevel}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.EquipmentSize}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.EquipmentType}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12">
                      <div className="row">
                        <table className="w-50">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                Shipper Information
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                Name: {formData.Shipper?.ShipperAddress?.Name}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Address:{" "}
                                {IsValidAddress(
                                  formData.Shipper?.ShipperAddress?.Address1,
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.Shipper?.ShipperAddress?.City.replace(
                                      / /g,
                                      ""
                                    ),
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.Shipper?.ShipperAddress?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.Shipper?.ShipperAddress?.ZipCode,
                                    false
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>Contact: {formData.Shipper?.Contact}</td>
                            </tr>
                            <tr>
                              <td>Phone: {formData.Shipper?.Phone}</td>
                            </tr>
                            <tr>
                              <td>
                                Pick Up Time From-To:{" "}
                                {tConvert(formData.Shipper?.ReadyTime)}
                              </td>
                            </tr>
                            {(formData?.ReceivingNote !== null &&
                            formData?.ReceivingNote !== "") ||
                            (formData?.ShippingNote !== null &&
                            formData?.ShippingNote !== "")  ? (
                              <tr>
                                <td>Pickup Notes: {formData?.ShippingNote}</td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>Pickup #: {formData?.ShipperNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50">
                          <thead className="d-flex justify-content-center">
                            <tr>
                              <th colspan="10" className="text-center">
                                Consignee Information
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="">
                                Name: {formData.Consignee?.ShipperAddress?.Name}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Address:{" "}
                                {IsValidAddress(
                                  formData.Consignee?.ShipperAddress?.Address1,
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.Consignee?.ShipperAddress?.City.replace(
                                      / /g,
                                      ""
                                    ),
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.Consignee?.ShipperAddress?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.Consignee?.ShipperAddress?.ZipCode,
                                    false
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Contact:{" "}
                                {(formData.Consignee?.Contact === undefined) |
                                (formData.Consignee?.Contact === null)
                                  ? " "
                                  : formData.Consignee?.Contact}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Phone:{" "}
                                {(formData.Consignee?.Phone === undefined) |
                                (formData.Consignee?.Phone === null)
                                  ? " "
                                  : formData.Consignee?.Phone}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Delivery Time From-To:{" "}
                                {tConvert(formData.Consignee?.CloseTime)}
                              </td>
                            </tr>
                            {(formData?.ReceivingNote !== null &&
                            formData?.ReceivingNote !== "") ||
                            (formData?.ShippingNote !== null &&
                            formData?.ShippingNote !== "")   ? (
                              <tr>
                                <td>
                                  Delivery Notes: {formData?.ReceivingNote}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )}
                            <tr>
                              <td>Delivery #: {formData?.ConsigneeNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Items */}
                  <div class="d-flex justify-content-center flex-column flex-md-row">
                    <h4 class="font-weight-boldest my-3">Shipment Items</h4>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">PCS</th>
                            <th className="text-center">Package Type</th>
                            {/* <th className="text-center">Pieces</th> */}
                            <th className="text-center">HAZMAT</th>
                            {/* <th className="text-center">List of Items</th> */}
                            <th className="text-center">Total Weight (LBS)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.handlingUnit}
                            </td>
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.PackageType}
                            </td>
                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.Pieces}</td> */}
                            <td className="font-weight-boldest align-text-top text-center">
                              <CheckboxLargeWB
                                checked={
                                  formData.Items?.HasMat !== null &&
                                  formData.Items?.HasMat === true
                                    ? true
                                    : false
                                }
                                label=""
                                text=""
                                name="PALLET_SLIP1"
                              />
                            </td>
                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.ListOfItems}</td> */}
                            <td className="font-weight-boldest align-text-top text-center">
                              {formData.Items?.TotalLBSWeight !== 0
                                ? currencyFormat(formData.Items?.TotalLBSWeight)
                                : currencyFormat(
                                  formData.Items?.TotalWeight
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {/* Vendors */}
                  <div class="d-flex justify-content-center flex-column flex-md-row">
                    <h4 class="font-weight-boldest my-3">Payables</h4>
                  </div>
                  <div className="row pb-0">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th className="text-center">Vendor Name</th>
                            <th className="text-center">Vendor Reference</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Rate</th>
                            <th className="text-center">Quantity</th>
                            <th className="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {formData.Vendors?.length > 0 &&
                            formData.Vendors?.map(
                              (item, index) =>
                                item.Payables?.length > 0 &&
                                item.Payables?.map((itemX, index) => (
                                  <tr>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item.VendorAddress?.CompanyName}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {item.VendorNumber}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Name}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {currencyFormat(itemX.Rate)}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {itemX.Quantity}
                                    </td>
                                    <td className="font-weight-boldest align-text-top text-center">
                                      {currencyFormat(itemX.Total)}
                                    </td>
                                  </tr>
                                ))
                            )}
                          {formData.Vendors?.length > 0 &&
                            formData.Vendors?.map((item, index) => (
                              <tr className="border-left-0 border-bottom-0">
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td className="border-0"></td>
                                <td
                                  className="font-weight-boldest"
                                  style={{
                                    backgroundColor: "#113059",
                                    color: "#fff",
                                  }}
                                >
                                  GRAND TOTAL
                                </td>
                                <td
                                  className="text-center font-weight-bolder"
                                  style={{ backgroundColor: "aliceblue" }}
                                >
                                  {"$ " + currencyFormat(item.Total)}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top border-0">
                              <small className="text-muted">
                                This confirmation governs the movement of the
                                above-referenced freight as of the specified and
                                hereby amends, is incorporated by reference and
                                becomes a part of the certain transportation
                                contract by and between “Broker” and “Contract
                                carrier”. Carrier Agrees to sign the
                                confirmation and return it to the broker via
                                email and carrier shall be conclusively presumed
                                and compensatory that the freight would not have
                                been tendered to Carrier at higher rates and
                                that not shipments handled under such rates will
                                subsequently be subject to a later claim for
                                undercharges. IF AGREED SERVICES ARE FULFILLED,
                                RATES ARE NOT NEGOTIABLE.Carrier MUST email
                                tracing@mitcoglobal.com For any pickup and
                                delivery appointments that are required. Failure
                                to comply with appointments will result in a
                                penalty of $150.00 Per Appointment.
                              </small>
                              <br></br>
                              <small className="text-muted">
                                The undersigned accepts the referenced shipment
                                on behalf of the carrier and acknowledge as
                                correct the information contained herein, the
                                carrier agrees to the terms of the Master
                                agreement previously executed between our
                                companies. Invoicing by the carrier and payment
                                by Mitco Ltd., constitutes acceptance of this
                                agreement and creates a valid contract for
                                carriage shipment.
                              </small>
                              <br></br>
                              <small className="text-muted">
                                When loading, the driver must count and inspect
                                his/her load. The Driver/Carrier is responsible
                                for piece count and condition of load at time of
                                delivery.{" "}
                                <b className="text-dark">
                                  For payment of freight charges, we must
                                  receive original and signed Bill of lading and
                                  Proof of Delivery with a carrier invoice
                                </b>
                                . Payment will be made 30 days after all
                                required paperwork is received at Mitco Ltd.,
                                facilities. Carrier will send a POD for load to
                                tracing@mitcoglobal.com within 48 hours of
                                delivery or carrier will be assessed a $50 late
                                fee per day until received at that email.
                                Carrier will send the invoice and POD to
                                accounting@mitcoglobal.com. We are not
                                responsible for Overweight. If Dimensions,
                                Weight, Quantity or type of commodity are
                                different than those consigned in our Load
                                Confirmation Agreement, the carrier or Broker
                                contracted MUST notify Mitco Ltd. Before picking
                                up and request a WRITTEN AUTHORIZATION. Mitco
                                Ltd. will not pay any extra charges without
                                AUTHORIZATION, and will only approve changes to
                                charges via revised rate confirmation. In and
                                out times must be provided on POD for all pickup
                                and deliveries.{" "}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top text-center border-0 font-weight-bold">
                              Phone: (253) 245-2102 | Fax: (253) 891-0601
                              <br></br>
                              Please sign and return via email to ensure payment
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top border-0 pb-5">
                              <b>Carrier Signature:</b>________________________
                            </td>
                            <td className="align-text-top border-0 text-right pb-5">
                              <b>Driver Name:</b>{" "}
                              <span>
                                {formData.Vendors?.length === 0 ||
                                formData.Vendors === undefined ||
                                formData.Vendors[0].DriverName === "" ||
                                formData.Vendors[0].DriverName === null
                                  ? "________________________"
                                  : formData.Vendors[0].DriverName}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="align-text-top border-0">
                              <b>Signature Date:</b>________________________
                            </td>
                            <td className="align-text-top border-0 text-right">
                              <b>Driver Phone#:</b>{" "}
                              <span>
                                {formData.Vendors?.length === 0 ||
                                formData.Vendors === undefined ||
                                formData.Vendors[0].DriverPhone === "" ||
                                formData.Vendors[0].DriverPhone === null
                                  ? " ________________________"
                                  : formData.Vendors[0].DriverPhone}
                              </span>
                            </td>
                          </tr>
                          <br />
                          <tr>
                            <td className="align-text-top border-0">
                              <b>MC#:</b> <span>{formData.MCNumber}</span>
                            </td>
                            <td className="align-text-top border-0 text-right">
                              <b>Truck Number:</b>{" "}
                              <span>
                                {formData.Vendors?.length === 0 ||
                                formData.Vendors === undefined ||
                                formData.Vendors[0].TruckNumber === "" ||
                                formData.Vendors[0].TruckNumber === null
                                  ? " ________________________"
                                  : formData.Vendors[0].TruckNumber}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12 px-0 ">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr>
                            <td className="align-text-top text-center border-0 font-weight-bold">
                              <small>
                                Please call (253) 245-2102 immediately with any
                                questions, concerns, or problems!<br></br>
                                Send Invoicing to: Mitco Limited Logistics. |
                                2302 B Street NW #101 | Auburn, WA. 98001
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export function TRCFORM({ id }) {
  const paramId = window.location.pathname.split("/")[4];
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="card card-custom overflow-hidden border-0">
      <div className="card-body p-0">
        <div className="container mb-md-5 mt-4">
          <div className="col-md-12 mb-4">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-primary font-weight-bolder mr-3"
                onClick={handlePrint}
              >
                Print TRC Form
              </button>
              {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
            </div>
          </div>
        </div>
        <ComponentToPrint
          ref={componentRef}
          id={id === undefined ? paramId : id}
        />
      </div>
    </div>
  );
}
