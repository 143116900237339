import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function MassUpdateLocations() {
  useEffect(() => {
    fetchAsync();
  }, []);

  const [warehousesList, setWarehousesList] = useState([]);
  const [progress, setProgress] = useState(false);
  const [dataState, setdataState] = useState(
    <button class="btn btn-secondary text-dark">Export Locations</button>
  );
  const fetchAsync = async () => {
    try {
      const result = await axios.get("api/ManageLocations/WarehousesList");
      setWarehousesList(result.data["warehousesList"]);
    } catch (e) {}
  };

  const fileExport = async () => {
    setProgress(true);
    const warehouseId = document.getElementById("warehouseId").value;
    try {
      const result = await axios.post("api/Locations/export", {
        data: warehouseId,
      });
      const jsonToExcel = (
        <ExcelFile
          element={
            <button className="btn btn-primary">Export Locations</button>
          }
        >
          <ExcelSheet
            data={result.data}
            name="Employees"
            className="btn btn-primary"
          >
            <ExcelColumn label="id" value="id" />
            <ExcelColumn label="Location Id" value="LocationId" />
            <ExcelColumn label="Status" value="Status" />
            <ExcelColumn label="Description" value="Description" />
          </ExcelSheet>
        </ExcelFile>
      );
      setdataState(jsonToExcel);
      setProgress(false);
    } catch (e) {}
  };

  return (
    <Card>
      <Card.Body>
        <div className="col-6">
          <label className="form-label" for="formGridPassword">
            warehouse
          </label>
          <select
            className="form-control form-control-solid is-valid-select"
            name="warehouseId"
            id="warehouseId"
            onChange={() => {
              fileExport();
            }}
          >
            <option>Select</option>
            {warehousesList.map((item, index) => (
              <option key={index} value={item.Id}>
                {item.Name}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-5 col-6"></div>
        <div className="mt-5 col-2">{dataState}</div>
        <div className="mt-5 col-2">
          {progress && <CircularProgress disableShrink />}
        </div>
      </Card.Body>
    </Card>
  );
}
