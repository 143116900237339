import MockUtils from "./mock.utils";
let customerTableMock;
export default function mockOrderReceiver(mock) {
  //Get View Model Call API
  async function GetViewModel() {
    return await fetch(window.$apiurl + "/storagebilling/getviewmodel", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        Notification("error", "err");
      });
  }
  //Get Warhouses List
  async function GetWarhouses() {
    return await fetch(window.$apiurl + "/Orders/GetWarehousesForOptions", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  //Get on Find Data
  async function GetLocStorage(customerId, warehouseId) {
    return await fetch(
      window.$apiurl + "/storageBilling/" + customerId + "/" + warehouseId
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        console.log(data);
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  //Get Customers List
  async function GetCustomers() {
    return await fetch(window.$apiurl + "/Orders/GetCustomersForOptions", {
      method: "get",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }
  //Fill Data with Get View Model
  mock.onGet("api/OrderReceiver/GetAllApi").reply(async () => {
    var listViewModel = {};
    listViewModel["OrderReceiverOptions"] = await GetViewModel();
    listViewModel["BillingPeriodOptions"] = await GetViewModel();
    listViewModel["InventoryOptions"] = await GetViewModel();
    listViewModel["Units"] = await GetViewModel();

    //Customers & Warehouses
    listViewModel["customersList"] = await GetCustomers();
    listViewModel["warhousesList"] = await GetWarhouses();
    return [200, listViewModel];
  });

  mock.onPost("api/OrderReceiver").reply(({ data }) => {
    const { Customer } = JSON.parse(data);
    const {
      unitofmeasure = "",
      splitperiodsthalf = "",
      splitperiodndhalf = "",
      freedays = "",
      rcvhandlingrate = "",
      shphandlingrate = "",
    } = Customer;

    const id = genercvhandlingrateUserId();
    const newCustomer = {
      id,
      unitofmeasure,
      splitperiodsthalf,
      splitperiodndhalf,
      freedays,
      rcvhandlingrate,
      shphandlingrate,
    };
    customerTableMock.push(newCustomer);
    return [200, { Customer: newCustomer }];
  });

  //mock on Find Data Table
  mock.onPost("api/OrderReceiver/fetchData").reply(async ({ data }) => {
    const mockUtils = new MockUtils();
    const dataTable = JSON.parse(data);
    const { queryParams } = JSON.parse(data);

    if (
      dataTable.customerId === undefined ||
      dataTable.warehouseId === undefined
    ) {
      dataTable.customerId = 0;
      dataTable.warehouseId = 0;
    }
    if (dataTable.customerId !== 0 && dataTable.warehouseId !== 0) {
      var dataResult = await GetLocStorage(
        dataTable.customerId,
        dataTable.warehouseId
      );
      const {
        OrderReceivers = [],
        RecurringStorageGlAcct = "",
        MinStorageCharge = "",
      } = dataResult;
      const GetApiCall = {
        OrderReceivers,
        RecurringStorageGlAcct,
        MinStorageCharge,
      };
      dataResult.map((item, index) => {
        GetApiCall.OrderReceivers = item.OrderReceivers;
        GetApiCall.RecurringStorageGlAcct = item.RecurringStorageGlAcct;
        GetApiCall.MinStorageCharge = item.MinStorageCharge;
      });
      return [200, GetApiCall];
    }
  });

  mock.onPost("api/OrderReceiver/Find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const dataTable = JSON.parse(config.data);
    customerTableMock = dataTable.data.OrderReceivers;
    return [200, customerTableMock];
  });

  mock.onPost("api/OrderReceiver/deleteOrderReceiver").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/OrderReceiver/updateStatusForOrderReceiver")
    .reply((config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/OrderReceiver\/\d+/).reply((config) => {
    const id = config.url.match(/api\/OrderReceiver\/(\d+)/)[1];
    const Customer = customerTableMock.find((el) => el.id === +id);
    if (!Customer) {
      return [400];
    }

    return [200, Customer];
  });

  mock.onPut(/api\/OrderReceiver\/\d+/).reply((config) => {
    const id = config.url.match(/api\/OrderReceiver\/(\d+)/)[1];
    const { Customer } = JSON.parse(config.data);
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    customerTableMock[index] = { ...Customer };
    return [200];
  });

  mock.onDelete(/api\/OrderReceiver\/\d+/).reply((config) => {
    const id = config.url.match(/api\/OrderReceiver\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    customerTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function genercvhandlingrateUserId() {
  const ids = customerTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
