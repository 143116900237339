// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";
import {
  CreatableSelect3,
  Input,
  Select
} from "../../../../../../../_metronic/_partials/controls";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import { Notification } from "../../../../../Notification";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DateFieldFilter } from '../DateFieldFilter';
import { DownshiftMultipleSelect } from "./DownshiftMultipleSelect";
import CreatableSelect from "react-select/creatable";
import $ from "jquery";
function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  const result = [month, day, year].join('/');
  if (result !== "NaN/NaN/NaN") {
    return result;
  } else {
    return "--/--/----";
  }
}
// Validation schema
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));
let CustomerEditSchema;

CustomerEditSchema = Yup.object().shape({
  // FirstName: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Name is required"),
  // LastName: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .max(50, "Maximum 50 symbols")
  //   .required("Name is required"),
  // Address: Yup.string()
  //   .min(4, "Minimum 4 symbols")
  //   .required("Address is required"),

  // City: Yup.string()
  //   .min(3, "Minimum 3 symbols")
  //   .required("City is required"),

  // State: Yup.string()
  //   .required("State is required"),
});
function SetValuefunction(id, value) {
  var element = document.getElementById(id);
  if (element != null)
    setTimeout(() => {
      element.value = value;
    }, 500);
}
export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [showProgress, setShowProgress] = useState(false);
  const [showStateProgress, setShowStateProgress] = useState(false);
  const [companiesList, setcompaniesList] = useState([]);
  const [countriesList, setcountriesList] = useState([]);
  const [states, setStates] = useState([]);
  const [gendersList, setgendersList] = useState([]);
  const [CategoriesList, setCategoriesList] = useState([]);
  const [companyValue, setValueCompany] = useState({});
  useEffect(() => {
    fetchOrderAsync();
  }, []);
  useEffect(() => {
    if (customer.Id !== undefined) {
      const obj = {
        label: customer.CompanyCode + " - " + customer.CompanyName,
        value: customer.CompanyId
      };
      setValueCompany(obj);
    } else {
      const obj = {
        label: "Select",
        value: 0
      };
      setValueCompany(obj);
    }
    if(customer.State){
      setStateValue({value : customer.State,label : customer.State});
    }
    if(customer.Gender){
      setGenderValue({value : customer.GenderId,label : customer.Gender});
    }
    if(customer.CategoryId){
      setCategoryValue({value : customer.CategoryId,label : customer.Category});
    }
  }, [customer]);
  const fetchOrderAsync = async () => {
    try {
      setShowProgress(true);
      const result = await axios.get("api/ManageContacts/GetAllApi");
      setcompaniesList(result.data["CompaniesList"]);
      setcountriesList(result.data["CountriesList"]);
      setgendersList(result.data["GendersList"]);
      setCategoriesList(result.data["CategoriesList"]);
      if (customer.id === undefined) {
        $("#countryIdSelect option[value=US]").prop('selected', 'selected');
        handleCountryChangeUS("US");
      }
      setShowProgress(false);
    } catch (e) { }
  };
  var birthDate = customer.BirthDate || false;
  if (birthDate) {
    $("#birthDateField").val(formatDate(customer.BirthDate));
  }
  const classes = useStyles();
  const handleCountryChange = async (event) => {
    setShowStateProgress(true);
    setStates([]);
    if (event.target.value !== "" && event.target.vale !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event.target.value}`);
      if (result.status == 200) {
        if (result.data.length > 0)
          setStates(result.data);
        else
          Notification("warning", "This Country doesn't have any state.");
      }
    }
    setShowStateProgress(false);
  }
  const handleCountryChangeUS = async (event) => {
    setShowStateProgress(true);
    setStates([]);
    if (event !== "" && event !== null) {
      const result = await axios.get(`api/companyinfo/getstates/${event}`);
      if (result.status == 200) {
        if (result.data.length > 0)
          setStates(result.data);
        else
          Notification("warning", "This Country doesn't have any state.");
      }
    }
    setShowStateProgress(false);
  }
  //useStates Values Formik
  const [genderValue,setGenderValue] = useState({value : "",label : "Select"});
  const [categoryValue,setCategoryValue] = useState({value : "",label : "Select"});
  const [stateValue,setStateValue] = useState({value : "",label : "Select"});
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          if (values.Name === "") {
            Notification("error", "Name is required");
            return false;
          }
          if (values.State === "") {
            Notification("error", "State is required");
            return false;
          }
          if (values.CompanyId === undefined) {
            Notification("error", "Company is required");
            return false;
          }
          values.CountryId = document.getElementById("countryIdSelect").value;
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row mb-3">
                  {/* First Name */}
                  <div className="col-lg-6">
                    <label>Gender</label>
                    <CreatableSelect
                      name="Gender"
                      label="Gender"
                      options={gendersList.map((x) => {
                        return {
                          value: x.Id,
                          label: x?.Name,
                        };
                      })}
                      value={genderValue}
                      onChange={(newValue) => {
                        setGenderValue(newValue);
                        setFieldValue("Gender", newValue?.value);
                      }}
                    />
                    {showProgress && <LinearProgress />}
                  </div>
                  <div className="col-lg-6">
                    <label>Category</label>
                    <CreatableSelect
                      name="CategoryId"
                      label="Category"
                      options={CategoriesList.map((x) => {
                        return {
                          value: x.Id,
                          label: x?.Name,
                        };
                      })}
                      value={categoryValue}
                      onChange={(newValue) => {
                        setCategoryValue(newValue);
                        setFieldValue("CategoryId", newValue?.value);
                      }}
                    />
                    {showProgress && <LinearProgress />}
                  </div>
                  {/* Last Name */}
                  <div className="col-lg-6 mt-4">
                    <Field
                      name="Name"
                      component={Input}
                      placeholder="First Name"
                      label="First Name"
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <Field
                      name="LastName"
                      component={Input}
                      placeholder="Last Name (Or Department)"
                      label="Last Name (Or Department)"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6 px-0">
                    <div className="col-lg-12 mb-3">
                      <Field
                        name="WorkPhone"
                        component={Input}
                        placeholder="WorkPhone"
                        label="WorkPhone"
                        type="tel"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <Field
                        name="HomePhone"
                        component={Input}
                        placeholder="HomePhone"
                        label="HomePhone"
                        type="tel"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <Field
                        name="Fax"
                        component={Input}
                        placeholder="Fax"
                        label="Fax"
                        type="tel"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 px-0">
                    <div className="col-lg-12 mb-3">
                      <Field
                        name="CellPhone"
                        component={Input}
                        placeholder="Cellular"
                        label="Cellular"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <Field
                        name="Email"
                        component={Input}
                        placeholder="Email"
                        label="Email"
                      />
                    </div>
                    <div className="col-lg-12 mb-3" style={{ display: "grid" }}>
                      <DateFieldFilter
                        value={
                          customer.BirthDate !== undefined ??
                          formatDate(birthDate)
                        }
                        name="BirthDate"
                        label="Birth Date"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Company</label>
                    <DownshiftMultipleSelect
                      FieldName="CompanyId"
                      setFieldValue={setFieldValue}
                      suggestions={companiesList}
                      DefalutValue={companyValue}
                    />
                    {showProgress && <LinearProgress />}
                  </div>
                  <div className="col-lg-6">
                    <div className="col-lg-12 px-0">
                      <label>Address</label>
                      <Field
                        name="Address"
                        as="textarea"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {/* Email */}
                <div className="form-group row">
                  <div className="col-lg-3">
                    <Field
                      name="City"
                      component={Input}
                      placeholder="City"
                      label="City"
                    />
                  </div>
                  {/* Date of birth */}
                  <div className="col-lg-3">
                    <Field
                      name="PostalCode"
                      component={Input}
                      placeholder="Postal Code"
                      label="Postal Code"
                    />
                  </div>
                  {/* IP Address */}
                  <div className="col-lg-3">
                    <label>Country</label>
                    <select
                      className="form-control"
                      id="countryIdSelect"
                      name="CountryId"
                      label="Country"
                      onChange={(event) => {
                        handleCountryChange(event);
                      }}
                    >
                      <option key="" value="0">
                        Select
                      </option>
                      {countriesList.map((item, index) => (
                        <option key={index} value={item.Id}>
                          {item.Name}
                        </option>
                      ))}
                    </select>

                    {showProgress && <LinearProgress />}
                  </div>
                  <div className="col-lg-3">
                    <label>State</label>
                    <CreatableSelect
                      name="State"
                      label="State"
                      options={states.map((x) => {
                        return {
                          value: x.Name,
                          label: x?.Name,
                        };
                      })}
                      value={stateValue}
                      onChange={(newValue) => {
                        setStateValue(newValue);
                        setFieldValue("State", newValue?.value);
                      }}
                    />
                    {showStateProgress && <LinearProgress color="secondary" />}
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
