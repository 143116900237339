import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("border border-danger");
  }

  if (touched && !errors) {
    classes.push("border border-success");
  }

  return classes.join(" ");
};

export function Select({
  label,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  required,
  ...props
}) {
  const [field, meta] = useField(props);
  const { touched, error } = meta;
  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };
  return (
    <>
      {label && (
        <label>
          {label} {required && <RequiredHtml />}
        </label>
      )}
      <select
        className={getFieldCSSClasses(touched, error)}
        {...field}
        {...props}
        required={required}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
      {error && touched && (
        <div style={{ color: "red", fontSize: "12px" }}>{error}</div>
      )}
    </>
  );
}
