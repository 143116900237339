import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import {
  momentDate,
  PostMomentDate,
  timeList,
} from "../../../_helpers/ATHelpers";

function minOr30(h) {
  try {
    let a = h?.split(":");
    let b = parseInt(a[1]);
    let c = 15 < b && b < 45 ? "30" : "00";
    return `${a[0]}:${c}:00`;
  } catch (err) {
    return "00:00:00";
  }
}

function twoDigit(number) {
  var twodigit = number >= 10 ? number : "0" + number.toString();
  return twodigit;
}

const filterOption = (option, inputValue) =>
  (
    String(option.label)
      .replace(".", "")
      .match(inputValue) || []
  ).length > 0;

export function DateTime30GL({
  field,
  label,
  span,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  IdName = "",
  required,
  ClassLabel,
  setFieldValue,
  defaultvalue,
  Time = true,
  ...props
}) {
  const { name, disabled, idInput, onChange } = props;

  const [DateValue, setDateValue] = useState("");
  const [TimeValue, setTimeValue] = useState("00:00");
  const [valueDate, setvalueDate] = useState("");
  const [border, setborder] = useState("");

  useEffect(() => {
    if (DateValue !== "")
      setvalueDate(DateValue + "T" + (TimeValue + ":00" ?? "00:00:00"));
  }, [DateValue, TimeValue]);

  useEffect(() => {
    onChange(valueDate);
  }, [valueDate]);

  useEffect(() => {
    if (defaultvalue) {
      var c = new Date(momentDate(defaultvalue));

      var date = `${c.getFullYear()}-${twoDigit(c.getMonth() + 1)}-${twoDigit(
        c.getDate()
      )}`;

      var label = twoDigit(c.getHours()) + ":" + twoDigit(c.getMinutes());
      /* var value = minOr30(label); */

      setDateValue(date);
      setTimeValue(label);
    }
  }, [defaultvalue]);

  const RequiredHtml = () => {
    return (
      <i
        class="fa fa-star-of-life text-danger icon-l ml-2"
        style={{ fontSize: "8px", position: "absolute" }}
      ></i>
    );
  };

  return (
    <>
      <div className="row">
        <div className={"col-lg-6 " + (!Time && " col-lg-12")}>
          {label && (
            <label className={ClassLabel}>
              {label} {required && <RequiredHtml />}
              {span && (
                <small className="alert alert-warning text-dark p-0 pl-2 pr-2">
                  {" "}
                  {span}
                </small>
              )}
            </label>
          )}
          <TextField
            type={"date"}
            className="form-control"
            value={DateValue}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(event) => {
              setDateValue(event.target.value);
            }}
          />
        </div>
        <div className={"col-lg-6 " + (!Time && " d-none")}>
          {label && <label className={ClassLabel}>Time</label>}
          <input
            className={`form-control ${border}`}
            value={TimeValue}
            placeholder="00:00"
            onKeyUp={(e) => {
              if (e.keyCode === 8) {
                setTimeValue("");
                setborder("");
                return;
              }
            }}
            onChange={(e) => {
              var value = e.target?.value;
              if (!value || value === "") return;
              if (value.length === 2) value += ":";
              if (value.length > 5) value = value[5];
              setTimeValue(value);
              var re = /^0[0-9]|1[0-9]|2[0-3]:[0-5][0-9]$/;
              setborder(re.test(value) ? "" : "border-danger");
            }}
          />
        </div>
      </div>
    </>
  );
}
