import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { shallowEqual, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Modal, Spinner, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCustomersUIContext } from "../../../../app/modules/eMPower/pages/NewShipmentsBeta/CustomersUIContext";
import { Notification } from "../../../../app/modules/Notification";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import Select, { components } from "react-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import low from "lowdb";
import $ from "jquery";
import {
  ReturnEmptyOrString,
  currencyFormat,
  DateTime30minInterval,
} from "../../../_helpers/ATHelpers";
import { CheckboxLargeWB } from "../../../_partials/controls";
import LocalStorage from "lowdb/adapters/LocalStorage";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { ChargesFunctionCompo } from "../../../../app/modules/eMPower/pages/Transload/FindOrders/product-Containers/remark-edit-dialog/ChargesFunction";
import { CustomerDeleteDialog } from "../../../../app/modules/eMPower/pages/NewShipmentsBeta/customer-delete-dialog/CustomerDeleteDialog";
const adapter = new LocalStorage("db");
const db = low(adapter);

Date.prototype.addHours = function(h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};
function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function tConvert(time) {
  if (time === null || time === undefined || time === "") {
    return "";
  }
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time,
  ];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(""); // return adjusted time or original string
}
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "";
  }
}

const selectedCustomers = (entities, ids) => {
  const _customers = [];
  ids.forEach((id) => {
    const customer = entities.find((el) => el.id === id);
    if (customer) {
      _customers.push(customer);
    }
  });
  return _customers;
};
const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  isDisabled: {
    color: "currentColor",
    cursor: "not-allowed",
    pointerEvents: "none",
    opacity: "0.5",
  },
});
const prepareFilter = (queryParams) => {
  return queryParams;
};
export function ShipmentActions() {
  document.getElementById("kt_body").style.overflow = "scroll";
  const history = useHistory();
  const customersUIContext = useCustomersUIContext();
  const [DetailShipmentInfo, setDetailShipmentInfo] = useState([]);
  const [ShowModalDespatch, setShowModalDespatch] = useState(false);
  const [ckEditorData, setckEditorData] = useState();
  const [
    LoadingchangeDespatchStatus,
    setLoadingchangeDespatchStatus,
  ] = useState(false);
  const [ShowModalBooked, setShowModalBooked] = useState(false);
  const [ShowModalLoading, setShowModalLoading] = useState(false);
  const [ShowModalLoaded, setShowModalLoaded] = useState(false);
  const [optionsVandorList, setoptionsVandorList] = useState([]);
  const [optionsVandorTypeList, setoptionsVandorTypeList] = useState([]);
  const [VandorSelectedData, setVandorSelectedData] = useState(null);
  const [
    FreightChargeIdSelectedData,
    setFreightChargeIdSelectedData,
  ] = useState(98);
  const [BillToSelectedId, setBillToSelectedId] = useState(null);
  const [VendorTypeIdSelectedData, setVendorTypeIdSelectedData] = useState(
    1308
  );
  const [BillToList, setBillToList] = useState([]);
  const [FreightChargeList, setFreightChargeList] = useState([]);
  const [SelectShipmentIds, setSelectShipmentIds] = useState([]);
  const [ShowModalDispatched, setShowModalDispatched] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [ShipmentStatusId, setShipmentStatusId] = useState(null);
  const [ShowModalPreviewDispatched, setShowModalPreviewDispatched] = useState(
    false
  );
  const [ShowModalPickUp, setShowModalPickUp] = useState(false);
  const [ShowModalAppointed, setShowModalAppointed] = useState(false);
  const [ShowModalSentInterModal, setShowModalSentInterModal] = useState(false);
  const [formData, setformData] = useState(null);
  const Option = (props) => {
    return (
      <div style={{ color: props.data.color }}>
        <components.Option {...props} />
      </div>
    );
  };
  function getvandorsList() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${db.read().getState().Token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(window.$apiurl + "/vendors", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const data = JSON.parse(result);
        const opt = [];
        data.Vendors.forEach((Vendor) => {
          opt.push({
            Id: Vendor.Id,
            value: Vendor.Id,
            label: Vendor.Code + "-" + Vendor.CompanyName,
            color: "red",
            CarrierId: Vendor?.CarrierId,
            IsApproved: Vendor?.IsApproved,
            IsActive: Vendor?.IsActive,
          });
        });
        var vendorid = customers.filter(
          (customer) => customer?.VendorId !== null || ""
        )[0]?.VendorId;
        var v = opt.filter((x) => !!x?.IsActive || x?.Id === vendorid);

        setoptionsVandorList(v);
        setFreightChargeList(data?.FreightCharges ?? "");
        setoptionsVandorTypeList(data?.VendorTypes || []);
        setBillToList(data?.BillTo || []);
      })
      .catch((error) => console.log("error", error));
  }
  const customersUIProps = useMemo(() => {
    return {
      ids: customersUIContext.ids,
      queryParams: customersUIContext.queryParams,
      setQueryParams: customersUIContext.setQueryParams,
      newCustomerButtonClick: customersUIContext.newCustomerButtonClick,
    };
  }, [customersUIContext]);

  // Customers Redux state
  const { customers } = useSelector(
    (state) => ({
      customers: selectedCustomers(
        state.customers.entities,
        customersUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if customers weren't selected we should close modal
  useEffect(() => {
    if (!customersUIProps.ids || customersUIProps.ids.length === 0) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customersUIProps.ids]);
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [side]: open });
    if (open) {
      setTimeout(() => {
        document.getElementsByClassName("MuiDrawer-modal")[0].style.visibility =
          "visible";
      }, 500);
    }
  };

  const applyFilter = () => {
    //document.getElementById("searchTextTable").value = "";
    const newQueryParams = prepareFilter({
      filter: {
        multiCustomersGet: window.$filterActionsSm,
        //Filter: window.$ShipmentManagementMode,
      },
      sortOrder: "desc",
      sortField: "id",
      pageNumber: 1,
      pageSize: 10,
    });

    newQueryParams.pageNumber = 1;
    // update list by queryParams
    customersUIProps.setQueryParams(newQueryParams);
    // document.getElementById("ReLoadTableId").click();
  };

  useEffect(() => {
    if (window.ShipmentStatus !== undefined && window.ShipmentStatus !== "") {
      setDetailShipmentInfo(window.ShipmentStatus);
    }
  }, [window.ShipmentStatus]);

  // useEffect(() => {
  //   fetchClassificationsAsync();
  // }, [window.$ShipmentManagementModeId]);
  // useEffect(() => {
  //   fetchClassificationsAsync();
  // }, [window.$ShipmentManagementServiceLevelId]);
  const submitShipmentStatus = async (StatusId, Name) => {
    try {
      if (customers.filter((a) => a.IsOnHold === true).length > 0) {
        Notification("warning", "Customer is On Hold");
      }
      var ShipmentsId = [];
      customers.map((customer) => ShipmentsId.push(customer.id));
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var raw = JSON.stringify({
        ShipmentIds: ShipmentsId,
        ShipmentStatus: StatusId,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        window.$apiurl + `/transportation/shipment/updatestatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
              document.getElementById("ReLoadTableId").click();
            } else {
              // window.$ShipmentManagementStatus = StatusId;
              // window.$ShipmentManagementStatusName = Name;
              Notification(
                "success",
                `Status of shipments : [${customers.map(
                  (customer) => customer.id + ", "
                )}] has been changed successfully`
              );
            }
          } else {
            Notification("errorClick", "invalid request");
          }
          document.getElementById("ReLoadTableId").click();
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
          var checkboxes = new Array();
          checkboxes = document.getElementsByTagName("input");
          for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = false;
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };
  //start dispatched preview Modal
  const PostDispatchVandors = async () => {
    var ShipmentsId = [];

    window.ShpmentsIdFrowSendEmail = customers[0].id;
    //ziko
    customers.map((customer) => ShipmentsId.push(customer.id));
    setSelectShipmentIds(customers);
    var VandorSelected =
      VandorSelectedData === null
        ? customers.filter((customer) => customer?.CarrierId !== null || "")[0]
            ?.CarrierId
        : VandorSelectedData;
    if (
      VandorSelected === null ||
      VandorSelected === undefined ||
      VandorSelected === ""
    ) {
      Notification("errorClick", "Please select vendor");
      return;
    }
    setLoadingchangeDespatchStatus(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify({
      ShipmentIds: ShipmentsId,
      ShipmentStatus: ShipmentStatusId,
      VendorId:
        VandorSelectedData === null
          ? customers.filter(
              (customer) => customer?.CarrierId !== null || ""
            )[0]?.CarrierId
          : VandorSelectedData,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + `/transportation/shipment/updatestatus`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
          } else {
            setVandorSelectedData(null);
            setFreightChargeIdSelectedData(98);
            setBillToSelectedId(null);
            setVendorTypeIdSelectedData(1308);

            Notification(
              "success",
              `Status of Shipments : [${customers.map(
                (customer) => customer.id + ", "
              )}] has been changed successfully`
            );
          }
        } else {
          Notification("errorClick", "invalid request");
        }
        setLoadingchangeDespatchStatus(false);
      })
      .then(async (result) => {
        var myHeaders2 = new Headers();
        myHeaders2.append(
          "Authorization",
          `bearer ${db.read().getState().Token}`
        );
        myHeaders2.append("Content-Type", "application/json");
        myHeaders2.append("X-FP-API-KEY", "iphone");
        var requestOptions2 = {
          method: "POST",
          headers: myHeaders2,
          body: JSON.stringify({ Ids: ShipmentsId }),
        };
        await fetch(
          window.$apiurl + "/Outbound/report/multiple",
          requestOptions2
        )
          .then(async (response) => {
            const statusCode = response.status;
            if (statusCode !== 200) {
              Notification("error", "No data found !");
            }
            const json = await response.json();
            setformData(json);
            setLoadingchangeDespatchStatus(false);

            setShowModalDispatched(false);
            toggleDrawer("right", false);
            document.getElementById("ReLoadTableId").click();
            setVandorSelectedData(null);
            setTimeout(() => {
              document.getElementById("kt_body").style.overflow = "scroll";
            }, 500);
            setShowModalPreviewDispatched(true);
            setShipmentStatusId(null);
          })
          .catch((error) => {
            setLoadingchangeDespatchStatus(false);
            console.error(error);
          });
        setLoadingchangeDespatchStatus(false);
      })
      .catch((error) => console.log("error", error));
  };

  //start submit modal booked

  const PostbookedchVandors = async () => {
    try {
      customers.map((a, index) => {
        if (document.getElementById(`PickupOnTime${index}`).value === "0") {
          Notification("errorClick", "Pickup On Time is required");
          return false;
        }
        if (document.getElementById(`PickupToTime${index}`).value === "0") {
          Notification("errorClick", "Pickup To Time is required");
          return false;
        }
      });
      var ShipmentsId = [];
      var pickupToDates = [];
      var pickupOnDates = [];
      customers.map((customer, index) => {
        ShipmentsId.push(customer.id);
        pickupToDates.push(
          new Date(
            document.getElementById(`PickupOnDate${index}`).value +
              "Z" +
              document.getElementById(`PickupToTime${index}`).value
          )
        );
        pickupOnDates.push(
          new Date(
            document.getElementById(`PickupOnDate${index}`).value +
              "Z" +
              document.getElementById(`PickupOnTime${index}`).value
          )
        );
      });
      var VandorSelected =
        VandorSelectedData === null
          ? customers.filter(
              (customer) => customer?.CarrierId !== null || ""
            )[0]?.CarrierId
          : VandorSelectedData;
      if (
        VandorSelected === null ||
        VandorSelected === undefined ||
        VandorSelected === ""
      ) {
        Notification("errorClick", "Please select vendor");
        return;
      }
      setLoadingchangeDespatchStatus(true);

      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var raw = JSON.stringify({
        ShipmentIds: ShipmentsId,
        ShipmentStatus: 3,
        PickupToDate: pickupToDates[0],
        PickupOnDate: pickupToDates[0],
        PickupToDates: pickupToDates,
        PickupOnDates: pickupOnDates,
        VendorTypeId: VendorTypeIdSelectedData,
        VendorId:
          VandorSelectedData === null
            ? customers.filter(
                (customer) => customer?.CarrierId !== null || ""
              )[0]?.CarrierId
            : VandorSelectedData,

        FreightChargeId: FreightChargeIdSelectedData ?? 98,
        ThirdPartyBillToId: BillToSelectedId,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        window.$apiurl + `/transportation/shipment/updatestatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            setVandorSelectedData(null);
            setFreightChargeIdSelectedData(98);
            setBillToSelectedId(null);
            setVendorTypeIdSelectedData(1308);
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
              document.getElementById("ReLoadTableId").click();
              setShowModalBooked(false);

              setLoadingchangeDespatchStatus(false);
            } else {
              // window.$ShipmentManagementStatus = StatusId;
              // window.$ShipmentManagementStatusName = Name;
              Notification(
                "success",
                `Status of shipments : [${customers.map(
                  (customer) => customer.id + ", "
                )}] has been changed successfully`
              );
            }
            var dataRowes = JSON.parse(window.$payablesRates);
            dataRowes.FreightChargeId = FreightChargeIdSelectedData ?? 98;
            dataRowes.ThirdPartyBillToId = BillToSelectedId;
            dataRowes.VendorTypeId = VendorTypeIdSelectedData;
            dataRowes.VendorId =
              VandorSelectedData === null
                ? customers.filter(
                    (customer) => customer?.CarrierId !== null || ""
                  )[0]?.CarrierId
                : VandorSelectedData;
            try {
              // Rates Post
              fetch(`${window.$apiurl}/transloadshipments/mutlipayables`, {
                method: "post",
                withCredentials: true,
                headers: {
                  Accept: "application/json, text/plain, */*",
                  Authorization: `bearer ${db.read().getState().Token}`,
                  "X-FP-API-KEY": "iphone",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(dataRowes),
              }).then((response) => {});
            } catch (err) {
              console.log(err);
            }
          } else {
            Notification("errorClick", "invalid request");
          }
          setVandorSelectedData(null);

          setShowModalBooked(false);
          setLoadingchangeDespatchStatus(false);

          document.getElementById("ReLoadTableId").click();
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
          var checkboxes = new Array();
          checkboxes = document.getElementsByTagName("input");
          for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = false;
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };

  //start submit eqNumber

  const SubmitEqNumber = async (status) => {
    try {
      setLoadingchangeDespatchStatus(true);
      var ShipmentsId = [];
      customers.map((customer) => ShipmentsId.push(customer.id));
      const EqNumber =
        status === 5
          ? document.getElementById("EquipmentNumberInput").value
          : document.getElementById("SealNumberInput").value;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      if (status === 5) {
        var raw = JSON.stringify({
          ShipmentIds: ShipmentsId,
          ShipmentStatus: status,
          EquipmentNumber: EqNumber,
        });
      } else {
        var raw = JSON.stringify({
          ShipmentIds: ShipmentsId,
          ShipmentStatus: status,
          SealNumber: EqNumber,
        });
      }

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      fetch(
        window.$apiurl + `/transportation/shipment/updatestatus`,
        requestOptions
      )
        .then(async (response) => {
          const statusCode = await response.status;
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => [statusCode, data]);
          } else {
            return response
              .text()
              .then((text) => [statusCode, { Message: "" }]);
          }
        })
        .then((result) => {
          if (result[0] === 200) {
            if (
              result[1].Message !== null &&
              result[1].Message !== undefined &&
              result[1].Message !== ""
            ) {
              Notification("errorClick", result[1].Message);
              document.getElementById("ReLoadTableId").click();
              setLoadingchangeDespatchStatus(false);
              if (status === 5) {
                setShowModalLoading(false);
              } else {
                setShowModalLoaded(false);
              }
            } else {
              // window.$ShipmentManagementStatus = StatusId;
              // window.$ShipmentManagementStatusName = Name;
              Notification(
                "success",
                `Status of shipments : [${customers.map(
                  (customer) => customer.id + ", "
                )}] has been changed successfully`
              );
            }
          } else {
            Notification("errorClick", "invalid request");
          }
          setLoadingchangeDespatchStatus(false);
          if (status === 5) {
            setShowModalLoading(false);
          } else {
            setShowModalLoaded(false);
          }
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
          document.getElementById("ReLoadTableId").click();
          var checkboxes = new Array();
          checkboxes = document.getElementsByTagName("input");
          for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = false;
            }
          }
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };
  //start submit modal Aappointed
  const SubmitAppointedDetail = async () => {
    if (document.getElementById("DeliveryAptTime").value === "0") {
      Notification("errorClick", "Delivery Apt Time is required");
      return;
    }
    if (document.getElementById("PickupAptTime").value === "0") {
      Notification("errorClick", "Pickup Apt Time is required");
      return;
    }
    var DeliveryAptDate = new Date(
      document.getElementById("PickupAptDate").value +
        "Z" +
        document.getElementById("DeliveryAptTime").value
    );
    var PickupAptDate = new Date(
      document.getElementById("PickupAptDate").value +
        "Z" +
        document.getElementById("PickupAptTime").value
    );

    setLoadingchangeDespatchStatus(true);
    var ShipmentsId = [];
    customers.map((customer) => ShipmentsId.push(customer.id));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify({
      ShipmentIds: ShipmentsId,
      ShipmentStatus: 57,
      DeliveryOnDate: DeliveryAptDate,
      PickupOnDate: PickupAptDate,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + `/transportation/shipment/updatestatus`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 200) {
          // window.$ReceiptsManagmentStatu = 18;
          Notification(
            "success",
            `Status of Receipts : [${customers.map(
              (customer) => customer.id + ", "
            )}] has been changed successfully`
          );
          setLoadingchangeDespatchStatus(false);
        } else {
          Notification("errorClick", "invalid request");
          setLoadingchangeDespatchStatus(false);
        }
      })
      .then((result) => {
        setShowModalAppointed(false);
        toggleDrawer("right", false);
        setTimeout(() => {
          document.getElementById("kt_body").style.overflow = "scroll";
        }, 500);
        document.getElementById("ReLoadTableId").click();
      })
      .catch((error) => console.log("error", error));
  };
  const PostPickUpStatus = async () => {
    customers.map((a, index) => {
      if (document.getElementById(`ETATime${index}`).value === "0") {
        Notification("errorClick", "ETA Time is required");
        return false;
      }
    });
    var ShipmentsId = [];
    var ETADates = [];
    customers.map((customer, index) => {
      ShipmentsId.push(customer.id);
      ETADates.push(
        new Date(
          document.getElementById(`ETA${index}`).value +
            "Z" +
            document.getElementById(`ETATime${index}`).value
        )
      );
    });
    setLoadingchangeDespatchStatus(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify({
      ShipmentIds: ShipmentsId,
      ShipmentStatus: 7,
      EtaDates: ETADates,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + `/transportation/shipment/updatestatus`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 200) {
          // window.$ReceiptsManagmentStatu = 18;
          Notification(
            "success",
            `Status of Shipment : [${customers.map(
              (customer) => customer.id + ", "
            )}] has been changed successfully`
          );
          setLoadingchangeDespatchStatus(false);
        } else {
          Notification("errorClick", "invalid request");
          setLoadingchangeDespatchStatus(false);
        }
      })
      .then((result) => {
        setShowModalPickUp(false);
        toggleDrawer("right", false);
        setTimeout(() => {
          document.getElementById("kt_body").style.overflow = "scroll";
        }, 500);
        applyFilter();
      })
      .catch((error) => console.log("error", error));
  };
  //start submit modal SentInterModal
  const SubmitSentInterModalDetail = async () => {
    if (document.getElementById("DeliveryAptTime").value === "0") {
      Notification("errorClick", "Pick up Appointment Time is required");
      return;
    }
    if (document.getElementById("PickupAptTime").value === "0") {
      Notification("errorClick", "Pick up Appointment To Time is required");
      return;
    }
    var DeliveryAptDate = new Date(
      document.getElementById("PickupAptDate").value +
        "Z" +
        document.getElementById("DeliveryAptTime").value
    );
    var PickupAptDate = new Date(
      document.getElementById("PickupAptDate").value +
        "Z" +
        document.getElementById("PickupAptTime").value
    );
    setLoadingchangeDespatchStatus(true);
    var ShipmentsId = [];
    customers.map((customer) => ShipmentsId.push(customer.id));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify({
      ShipmentIds: ShipmentsId,
      ShipmentStatus: 62,
      PickupOnDate: DeliveryAptDate,
      PickupToDate: PickupAptDate,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + `/transportation/shipment/updatestatus`,
      requestOptions
    )
      .then(async (response) => {
        if (response.status === 200) {
          // window.$ReceiptsManagmentStatu = 18;
          Notification(
            "success",
            `Status of Receipts : [${customers.map(
              (customer) => customer.id + ", "
            )}] has been changed successfully`
          );
          setLoadingchangeDespatchStatus(false);
        } else {
          Notification("errorClick", "invalid request");
          setLoadingchangeDespatchStatus(false);
        }
      })
      .then((result) => {
        setShowModalSentInterModal(false);
        toggleDrawer("right", false);
        setTimeout(() => {
          document.getElementById("kt_body").style.overflow = "scroll";
        }, 500);
        document.getElementById("ReLoadTableId").click();
      })
      .catch((error) => console.log("error", error));
  };
  //SendDespatchEmail**************************
  const SendDespatchEmail = async () => {
    ///shipments/actions/senddispatchemail
    setLoadingchangeDespatchStatus(true);
    var ShipmentsId = [];
    customers.map((customer) => ShipmentsId.push(customer.id));
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("X-FP-API-KEY", "iphone");
    var raw = JSON.stringify({
      ShipmentIds: ShipmentsId,
      Notes: ckEditorData,
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(
      window.$apiurl + `/shipments/actions/senddispatchemail`,
      requestOptions
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then((result) => {
        if (result[0] === 200) {
          // window.$ShipmentManagementStatus = 4;
          // window.$ShipmentManagementStatusName = "Dispatched";
          setShowModalDespatch(false);
          toggleDrawer("right", false);
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
          document.getElementById("ReLoadTableId").click();
          var checkboxes = new Array();
          checkboxes = document.getElementsByTagName("input");
          for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
              checkboxes[i].checked = false;
            }
          }

          if (
            result[1].Message !== null &&
            result[1].Message !== undefined &&
            result[1].Message !== ""
          ) {
            Notification("errorClick", result[1].Message);
            Notification(
              "success",
              `Status of shipments : [${customers.map(
                (customer) => customer.id + ", "
              )}] has been changed successfully`
            );
          } else {
            Notification(
              "success",
              `Status of shipments : [${customers.map(
                (customer) => customer.id + ", "
              )}] has been changed successfully`
            );
          }
          setLoadingchangeDespatchStatus(false);
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
        } else {
          Notification("errorClick", "invalid request");
          setLoadingchangeDespatchStatus(false);
          setTimeout(() => {
            document.getElementById("kt_body").style.overflow = "scroll";
          }, 500);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const fullList = (side) => (
    <div
      className={classes.fullList}
      role="presentation"
      // onClick={toggleDrawer(side, false)}
      // onKeyDown={toggleDrawer(side, false)}
    >
      <div
        id="kt_quick_user"
        className="offcanvas offcanvas-right p-10 offcanvas-on"
      >
        {/*begin::Header*/}
        <div
          className="offcanvas-header d-flex align-items-center justify-content-between pb-5"
          kt-hidden-height={40}
          style={{}}
        >
          {/*begin::Header*/}

          <h3 className="font-weight-bold m-0">Select an Action For</h3>
          <a
            onClick={toggleDrawer(side, false)}
            className="btn btn-xs btn-icon btn-light btn-hover-primary"
            id="kt_quick_user_close"
          >
            <i className="ki ki-close icon-xs text-muted" />
          </a>
          {/* start preview Modal */}
          <Modal
            size="xl"
            id="DispatchedDateModal"
            show={ShowModalPreviewDispatched}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Dispatched Order
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalPreviewDispatched(false);
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                  document.getElementById("ReLoadTableId").click();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="card card-custom overflow-hidden">
                <div className="card-body p-0">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                    <div className="d-flex flex-row">
                      <div className="col-xl-3 col-lg-3 col-md-12">
                        <div className="card card-custom overflow-hidden">
                          <div className="card-body pt-4">
                            <h4 className="display-6 font-weight-bolder mb-10">
                              Shipments Number
                            </h4>
                            <Nav className="navi navi-bold navi-hover navi-active navi-link-rounded">
                              {SelectShipmentIds.map((item, index) => (
                                <div className="navi-item">
                                  <Nav.Link
                                    className="navi-link mb-4"
                                    eventKey={`${index}`}
                                  >
                                    {item?.id}
                                  </Nav.Link>
                                </div>
                              ))}
                            </Nav>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-9 col-lg-9 col-md-12">
                        <div className="card card-custom overflow-hidden">
                          <div className="card-body pt-4">
                            <Tab.Content>
                              {formData?.length > 0 &&
                                formData?.map((frmData, index) => (
                                  <Tab.Pane eventKey={`${index}`}>
                                    {formData && (
                                      <div className="container">
                                        <div className="card py-5 bl-form border-0">
                                          <div className="card-body p-0">
                                            {/* First Data */}
                                            {String(
                                              window.$ShipmentManagementModeId
                                            ) === "3" ||
                                            String(
                                              window.$ShipmentManagementModeId
                                            ) === "2" ? (
                                              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                                                <div className="col-md-12">
                                                  <div className="row pb-10">
                                                    <div className="col-md-8 px-0 mr-auto">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="border-0">
                                                              <b className="h6">
                                                                {frmData.Title}
                                                              </b>
                                                              <br></br>
                                                              {
                                                                frmData.BillTo
                                                                  ?.Name
                                                              }
                                                              <br></br>
                                                              {frmData.BillTo
                                                                ?.Address1 ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.Address1}
                                                              {(frmData.BillTo
                                                                ?.Address2 ===
                                                                undefined) |
                                                              (frmData.BillTo
                                                                ?.Address2 ===
                                                                null)
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.Address2}
                                                              <br></br>
                                                              {frmData.BillTo
                                                                ?.City ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.City}
                                                              ,{" "}
                                                              {frmData.BillTo
                                                                ?.State ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.State}{" "}
                                                              {frmData.BillTo
                                                                ?.ZipCode ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.ZipCode}
                                                              <br></br>
                                                              {frmData.BillTo
                                                                ?.Phone ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.Phone}
                                                              <br></br>
                                                              {frmData.BillTo
                                                                ?.Reference ===
                                                              undefined
                                                                ? " "
                                                                : frmData.BillTo
                                                                    ?.Reference}
                                                              <br></br>
                                                            </td>
                                                            <td className="border-0 text-left">
                                                              <a
                                                                href="#"
                                                                class="mb-5 max-w-100px"
                                                              >
                                                                <span class="svg-icon svg-icon-full">
                                                                  <img
                                                                    src="/media/logos/Mitco.svg"
                                                                    className="w-75"
                                                                  />
                                                                </span>
                                                              </a>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Carrier Name
                                                            </th>
                                                            <th className="text-center">
                                                              PickUp Date{" "}
                                                            </th>
                                                            <th className="text-center">
                                                              Delivery Date
                                                            </th>
                                                            <th className="text-center">
                                                              Shipment Id
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Number
                                                            </th>
                                                            <th className="text-center">
                                                              Shipper Ref
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.CarrierName
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                frmData.ReadyDate
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                frmData.DateNeeded
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.LoadNumber
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.Equipment
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.ShipperRef
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Service
                                                            </th>
                                                            <th className="text-center">
                                                              Mode
                                                            </th>
                                                            <th className="text-center">
                                                              Service Level
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Size
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Type
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {frmData.Service}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {frmData.Mode}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.ServiceLevel
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.EquipmentSize
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.EquipmentType
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                Shipper
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.Name
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.Address1 ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Shipper
                                                                          ?.ShipperAddress
                                                                          ?.Address1) +
                                                                    " " +
                                                                    ((frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.Address2 ===
                                                                      undefined) |
                                                                    (frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.Address2 ===
                                                                      null)
                                                                      ? " "
                                                                      : frmData
                                                                          .Shipper
                                                                          ?.ShipperAddress
                                                                          ?.Address2) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.City ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Shipper
                                                                          ?.ShipperAddress
                                                                          ?.City) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.State ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Shipper
                                                                          ?.ShipperAddress
                                                                          ?.State) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.ZipCode ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Shipper
                                                                          ?.ShipperAddress
                                                                          ?.ZipCode)
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    frmData
                                                                      .Shipper
                                                                      ?.Contact
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    frmData
                                                                      .Shipper
                                                                      ?.Phone
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Pick Up Time
                                                                From-To:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={tConvert(
                                                                    frmData
                                                                      .Shipper
                                                                      ?.ReadyTime
                                                                  )}
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                Consignee
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.Name
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.Address1 ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.ShipperAddress
                                                                          ?.Address1) +
                                                                    " " +
                                                                    ((frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.Address2 ===
                                                                      undefined) |
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.Address2 ===
                                                                      null)
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.ShipperAddress
                                                                          ?.Address2) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.City ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.ShipperAddress
                                                                          ?.City) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.State ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.ShipperAddress
                                                                          ?.State) +
                                                                    "  " +
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.ZipCode ===
                                                                    undefined
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.ShipperAddress
                                                                          ?.ZipCode)
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.Contact ===
                                                                      undefined) |
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.Contact ===
                                                                      null)
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.Contact
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.Phone ===
                                                                      undefined) |
                                                                    (frmData
                                                                      .Consignee
                                                                      ?.Phone ===
                                                                      null)
                                                                      ? " "
                                                                      : frmData
                                                                          .Consignee
                                                                          ?.Phone
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Delivery Time
                                                                From-To:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={tConvert(
                                                                    frmData
                                                                      .Consignee
                                                                      ?.CloseTime
                                                                  )}
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* Items */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-7">
                                                      Shipment Items
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              PCS
                                                            </th>
                                                            <th className="text-center">
                                                              Package Type
                                                            </th>
                                                            {/* <th className="text-center">Pieces</th> */}
                                                            <th className="text-center">
                                                              HAZMAT
                                                            </th>
                                                            {/* <th className="text-center">List of Items</th> */}
                                                            <th className="text-center">
                                                              Total Weight (LBS)
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.Items
                                                                  ?.handlingUnit
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                frmData.Items
                                                                  ?.PackageType
                                                              }
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.Pieces}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              <CheckboxLargeWB
                                                                checked={
                                                                  frmData.Items
                                                                    ?.HasMat !==
                                                                    null &&
                                                                  frmData.Items
                                                                    ?.HasMat ===
                                                                    true
                                                                    ? true
                                                                    : false
                                                                }
                                                                label=""
                                                                text=""
                                                                name="PALLET_SLIP1"
                                                              />
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.ListOfItems}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {frmData.Items
                                                                ?.TotalLBSWeight !==
                                                              0
                                                                ? frmData.Items?.TotalLBSWeight?.toFixed(
                                                                    2
                                                                  )
                                                                : (frmData.Items?.TotalWeight).toFixed(
                                                                    2
                                                                  )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>

                                                  {/* Vendors */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-7">
                                                      Payables
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Vendor Name
                                                            </th>
                                                            <th className="text-center">
                                                              Vendor Reference
                                                            </th>
                                                            <th className="text-center">
                                                              Name
                                                            </th>
                                                            <th className="text-center">
                                                              Rate
                                                            </th>
                                                            <th className="text-center">
                                                              Quantity
                                                            </th>
                                                            <th className="text-center">
                                                              Total
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {frmData.Vendors
                                                            ?.length > 0 &&
                                                            frmData.Vendors?.map(
                                                              (item, index) =>
                                                                item.Payables
                                                                  ?.length >
                                                                  0 &&
                                                                item.Payables?.map(
                                                                  (
                                                                    itemX,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item
                                                                            .VendorAddress
                                                                            ?.CompanyName
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item.VendorNumber
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Name
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {itemX.Rate.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Quantity
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {itemX.Total.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )
                                                            )}
                                                          {frmData.Vendors
                                                            ?.length > 0 &&
                                                            frmData.Vendors?.map(
                                                              (item, index) => (
                                                                <tr>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td
                                                                    className="font-weight-boldest"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#113059",
                                                                      color:
                                                                        "#fff",
                                                                    }}
                                                                  >
                                                                    GRAND TOTAL
                                                                  </td>
                                                                  <td>
                                                                    <input
                                                                      readOnly
                                                                      value={
                                                                        "$" +
                                                                        item.Total?.toFixed(
                                                                          2
                                                                        )
                                                                      }
                                                                      type="text"
                                                                      name="codamount"
                                                                      className="MuiInputBase-input MuiInput-input w-100 font-weight-boldest align-text-top text-center"
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <small className="text-muted">
                                                                This
                                                                confirmation
                                                                governs the
                                                                movement of the
                                                                above-referenced
                                                                freight as of
                                                                the specified
                                                                and hereby
                                                                amends, is
                                                                incorporated by
                                                                reference and
                                                                becomes a part
                                                                of the certain
                                                                transportation
                                                                contract by and
                                                                between “Broker”
                                                                and “Contract
                                                                carrier”.
                                                                Carrier Agrees
                                                                to sign the
                                                                confirmation and
                                                                return it to the
                                                                broker via email
                                                                and carrier
                                                                shall be
                                                                conclusively
                                                                presumed and
                                                                compensatory
                                                                that the freight
                                                                would not have
                                                                been tendered to
                                                                Carrier at
                                                                higher rates and
                                                                that not
                                                                shipments
                                                                handled under
                                                                such rates will
                                                                subsequently be
                                                                subject to a
                                                                later claim for
                                                                undercharges. IF
                                                                AGREED SERVICES
                                                                ARE FULFILLED,
                                                                RATES ARE NOT
                                                                NEGOTIABLE.Carrier
                                                                MUST email
                                                                tracing@mitcoglobal.com
                                                                For any pickup
                                                                and delivery
                                                                appointments
                                                                that are
                                                                required.
                                                                Failure to
                                                                comply with
                                                                appointments
                                                                will result in a
                                                                penalty of
                                                                $150.00 Per
                                                                Appointment.
                                                              </small>
                                                              <br></br>
                                                              <small className="text-muted">
                                                                The undersigned
                                                                accepts the
                                                                referenced
                                                                shipment on
                                                                behalf of the
                                                                carrier and
                                                                acknowledge as
                                                                correct the
                                                                information
                                                                contained
                                                                herein, the
                                                                carrier agrees
                                                                to the terms of
                                                                the Master
                                                                agreement
                                                                previously
                                                                executed between
                                                                our companies.
                                                                Invoicing by the
                                                                carrier and
                                                                payment by Mitco
                                                                Ltd.,
                                                                constitutes
                                                                acceptance of
                                                                this agreement
                                                                and creates a
                                                                valid contract
                                                                for carriage
                                                                shipment.
                                                              </small>
                                                              <br></br>
                                                              <small className="text-muted">
                                                                When loading,
                                                                the driver must
                                                                count and
                                                                inspect his/her
                                                                load. The
                                                                Driver/Carrier
                                                                is responsible
                                                                for piece count
                                                                and condition of
                                                                load at time of
                                                                delivery.{" "}
                                                                <b className="text-dark">
                                                                  For payment of
                                                                  freight
                                                                  charges, we
                                                                  must receive
                                                                  original and
                                                                  signed Bill of
                                                                  lading and
                                                                  Proof of
                                                                  Delivery with
                                                                  a carrier
                                                                  invoice
                                                                </b>
                                                                . Payment will
                                                                be made 30 days
                                                                after all
                                                                required
                                                                paperwork is
                                                                received at
                                                                Mitco Ltd.,
                                                                facilities.
                                                                Carrier will
                                                                send a POD for
                                                                load to
                                                                tracing@mitcoglobal.com
                                                                within 48 hours
                                                                of delivery or
                                                                carrier will be
                                                                assessed a $50
                                                                late fee per day
                                                                until received
                                                                at that email.
                                                                Carrier will
                                                                send the invoice
                                                                and POD to
                                                                accounting@mitcoglobal.com.
                                                                We are not
                                                                responsible for
                                                                Overweight. If
                                                                Dimensions,
                                                                Weight, Quantity
                                                                or type of
                                                                commodity are
                                                                different than
                                                                those consigned
                                                                in our Load
                                                                Confirmation
                                                                Agreement, the
                                                                carrier or
                                                                Broker
                                                                contracted MUST
                                                                notify Mitco
                                                                Ltd. Before
                                                                picking up and
                                                                request a
                                                                WRITTEN
                                                                AUTHORIZATION.
                                                                Mitco Ltd. will
                                                                not pay any
                                                                extra charges
                                                                without
                                                                AUTHORIZATION,
                                                                and will only
                                                                approve changes
                                                                to charges via
                                                                revised rate
                                                                confirmation. In
                                                                and out times
                                                                must be provided
                                                                on POD for all
                                                                pickup and
                                                                deliveries.{" "}
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              Phone: (253)
                                                              245-2102 | Fax:
                                                              (253) 891-0601
                                                              <br></br>
                                                              Please sign and
                                                              return via email
                                                              to ensure payment
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0 pb-5">
                                                              <b>
                                                                Carrier
                                                                Signature:
                                                              </b>
                                                              ________________________
                                                            </td>
                                                            <td className="align-text-top border-0 text-right pb-5">
                                                              <b>
                                                                Driver Name:
                                                              </b>{" "}
                                                              <span>
                                                                {frmData.Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                frmData.Vendors ===
                                                                  undefined ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .DriverName ===
                                                                  "" ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .DriverName ===
                                                                  null
                                                                  ? "________________________"
                                                                  : frmData
                                                                      .Vendors[0]
                                                                      .DriverName}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <b>
                                                                Signature Date:
                                                              </b>
                                                              ________________________
                                                            </td>
                                                            <td className="align-text-top border-0 text-right">
                                                              <b>
                                                                Driver Phone#:
                                                              </b>{" "}
                                                              <span>
                                                                {frmData.Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                frmData.Vendors ===
                                                                  undefined ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .DriverPhone ===
                                                                  "" ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .DriverPhone ===
                                                                  null
                                                                  ? " ________________________"
                                                                  : frmData
                                                                      .Vendors[0]
                                                                      .DriverPhone}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <br />
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <b>MC#:</b>{" "}
                                                              <span>
                                                                {
                                                                  frmData.MCNumber
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="align-text-top border-0 text-right">
                                                              <b>
                                                                Truck Number:
                                                              </b>{" "}
                                                              <span>
                                                                {frmData.Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                frmData.Vendors ===
                                                                  undefined ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .TruckNumber ===
                                                                  "" ||
                                                                frmData
                                                                  .Vendors[0]
                                                                  .TruckNumber ===
                                                                  null
                                                                  ? " ________________________"
                                                                  : frmData
                                                                      .Vendors[0]
                                                                      .TruckNumber}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              <small>
                                                                Please call
                                                                (253) 245-2102
                                                                immediately with
                                                                any questions,
                                                                concerns, or
                                                                problems!
                                                                <br></br>
                                                                Send Invoicing
                                                                to: Mitco Limited
                                                                Logistics. |2302 B Street NW #101
                                                                | Auburn, WA. 98001
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : String(
                                                window.$ShipmentManagementModeId
                                              ) === "1" ? (
                                              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                                                <div className="col-md-12">
                                                  <div className="row pb-10">
                                                    <div className="col-md-8 px-0 mr-auto">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="border-0">
                                                              <b className="h6">
                                                                {formData.Title}
                                                              </b>
                                                              <br></br>
                                                              {
                                                                formData.From
                                                                  ?.Name
                                                              }
                                                              <br></br>
                                                              {formData.From
                                                                ?.Address1 ===
                                                              null
                                                                ? " "
                                                                : formData.From
                                                                    ?.Address1}
                                                              {(formData.From
                                                                ?.Address2 ===
                                                                null) |
                                                              (formData.From
                                                                ?.Address2 ===
                                                                null)
                                                                ? " "
                                                                : formData.From
                                                                    ?.Address2}
                                                              <br></br>
                                                              {formData.From
                                                                ?.City === null
                                                                ? " "
                                                                : formData.From
                                                                    ?.City}
                                                              ,{" "}
                                                              {formData.From
                                                                ?.State === null
                                                                ? " "
                                                                : formData.From
                                                                    ?.State}{" "}
                                                              {formData.From
                                                                ?.ZipCode ===
                                                              null
                                                                ? " "
                                                                : formData.From
                                                                    ?.ZipCode}
                                                              <br></br>
                                                              {formData.From
                                                                ?.Phone === null
                                                                ? " "
                                                                : formData.From
                                                                    ?.Phone}
                                                              <br></br>
                                                              {formData.From
                                                                ?.Reference ===
                                                              null
                                                                ? " "
                                                                : formData.From
                                                                    ?.Reference}
                                                              <br></br>
                                                            </td>
                                                            <td className="border-0 text-left">
                                                              <Link
                                                                to="#"
                                                                class="mb-5 max-w-100px"
                                                              >
                                                                <span class="svg-icon svg-icon-full">
                                                                  <img
                                                                    src="/media/logos/Mitco.svg"
                                                                    className="w-75"
                                                                    alt="Logo Mitco"
                                                                  />
                                                                </span>
                                                              </Link>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              PickUp Date
                                                            </th>
                                                            <th className="text-center">
                                                              Delivery Date
                                                            </th>
                                                            <th className="text-center">
                                                              Shipment Id
                                                            </th>
                                                            <th className="text-center">
                                                              Shipment Ref{" "}
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                formData.PickUpDate
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                formData.DeliveryDate
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.ShipmentId
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.ShipmentRef
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Service
                                                            </th>
                                                            <th className="text-center">
                                                              Mode
                                                            </th>
                                                            <th className="text-center">
                                                              Service Level
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Size
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Type
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Service}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Mode}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.ServiceLevel
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.EquipmentSize
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.EquipmentType
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                PICKUP
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.Name
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.Address1 ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .PickUp
                                                                          ?.Address
                                                                          ?.Address1) +
                                                                    " " +
                                                                    ((formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.Address2 ===
                                                                      null) |
                                                                    (formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.Address2 ===
                                                                      null)
                                                                      ? " "
                                                                      : formData
                                                                          .PickUp
                                                                          ?.Address
                                                                          ?.Address2) +
                                                                    "  " +
                                                                    (formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.City ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .PickUp
                                                                          ?.Address
                                                                          ?.City) +
                                                                    "  " +
                                                                    (formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.State ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .PickUp
                                                                          ?.Address
                                                                          ?.State) +
                                                                    "  " +
                                                                    (formData
                                                                      .PickUp
                                                                      ?.Address
                                                                      ?.ZipCode ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .PickUp
                                                                          ?.Address
                                                                          ?.ZipCode)
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    formData
                                                                      .PickUp
                                                                      ?.Contact
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    formData
                                                                      .PickUp
                                                                      ?.Phone
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Pick Up Time
                                                                From-To:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={tConvert(
                                                                    formData?.PickUpReadyTime +
                                                                      " - " +
                                                                      formData?.PickUpCloseTIme
                                                                  )}
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                DELIVERY
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    formData
                                                                      .Delivery
                                                                      ?.Name
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.Address1 ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Address
                                                                          ?.Address1) +
                                                                    " " +
                                                                    ((formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.Address2 ===
                                                                      null) |
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.Address2 ===
                                                                      null)
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Address
                                                                          ?.Address2) +
                                                                    "  " +
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.City ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Address
                                                                          ?.City) +
                                                                    "  " +
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.State ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Address
                                                                          ?.State) +
                                                                    "  " +
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Address
                                                                      ?.ZipCode ===
                                                                    null
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Address
                                                                          ?.ZipCode)
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Contact ===
                                                                      undefined) |
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Contact ===
                                                                      null)
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Contact
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Phone ===
                                                                      undefined) |
                                                                    (formData
                                                                      .Delivery
                                                                      ?.Phone ===
                                                                      null)
                                                                      ? " "
                                                                      : formData
                                                                          .Delivery
                                                                          ?.Phone
                                                                  }
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Delivery Time
                                                                From-To:{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  name="codamount"
                                                                  value={tConvert(
                                                                    formData?.DeliveryReadyTime +
                                                                      " - " +
                                                                      formData?.DeliveryCloseTIme
                                                                  )}
                                                                  className=" MuiInputBase-input MuiInput-input"
                                                                />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* Items */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-7">
                                                      Shipment Items
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              PCS
                                                            </th>
                                                            <th className="text-center">
                                                              Package Type
                                                            </th>
                                                            {/* <th className="text-center">Pieces</th> */}
                                                            <th className="text-center">
                                                              HAZMAT
                                                            </th>
                                                            {/* <th className="text-center">List of Items</th> */}
                                                            <th className="text-center">
                                                              Total Weight (LBS)
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.Items
                                                                  ?.handlingUnit
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.Items
                                                                  ?.PackageType
                                                              }
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.Pieces}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              <CheckboxLargeWB
                                                                checked={
                                                                  formData.Items
                                                                    ?.HasMat !==
                                                                    null &&
                                                                  formData.Items
                                                                    ?.HasMat ===
                                                                    true
                                                                    ? true
                                                                    : false
                                                                }
                                                                label=""
                                                                text=""
                                                                name="PALLET_SLIP1"
                                                              />
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.ListOfItems}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Items
                                                                ?.TotalLBSWeight !==
                                                              0
                                                                ? formData.Items
                                                                    ?.TotalLBSWeight
                                                                : (formData.Items?.TotalWeight).toFixed(
                                                                    2
                                                                  )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>

                                                  {/* Vendors */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-7">
                                                      Payables
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Vendor Name
                                                            </th>
                                                            <th className="text-center">
                                                              Vendor Reference
                                                            </th>
                                                            <th className="text-center">
                                                              Name
                                                            </th>
                                                            <th className="text-center">
                                                              Rate
                                                            </th>
                                                            <th className="text-center">
                                                              Quantity
                                                            </th>
                                                            <th className="text-center">
                                                              Total
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {formData.Vendors
                                                            ?.length > 0 &&
                                                            formData.Vendors?.map(
                                                              (item, index) =>
                                                                item.Payables
                                                                  ?.length >
                                                                  0 &&
                                                                item.Payables?.map(
                                                                  (
                                                                    itemX,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item
                                                                            .VendorAddress
                                                                            ?.CompanyName
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item.VendorNumber
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Name
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {itemX.Rate.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Quantity
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {itemX.Total.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )
                                                            )}
                                                          {formData.Vendors
                                                            ?.length > 0 &&
                                                            formData.Vendors?.map(
                                                              (item, index) => (
                                                                <tr>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td></td>
                                                                  <td
                                                                    className="font-weight-boldest"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#113059",
                                                                      color:
                                                                        "#fff",
                                                                    }}
                                                                  >
                                                                    GRAND TOTAL
                                                                  </td>
                                                                  <td>
                                                                    <input
                                                                      readOnly
                                                                      value={
                                                                        "$" +
                                                                        item.Total?.toFixed(
                                                                          2
                                                                        )
                                                                      }
                                                                      type="text"
                                                                      name="codamount"
                                                                      className="MuiInputBase-input MuiInput-input w-100 font-weight-boldest align-text-top text-center"
                                                                    />
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <table className="w-50">
                                                          <tbody className="border-0">
                                                            <tr>
                                                              <td className="border-0">
                                                                <b>
                                                                  PICKUP
                                                                  INSTRUCTIONS:
                                                                </b>
                                                                <br></br>{" "}
                                                                <input
                                                                  readOnly
                                                                  type="text"
                                                                  value={
                                                                    formData.PickUpInstructions
                                                                  }
                                                                  name="codamount"
                                                                  className=" MuiInputBase-input MuiInput-input w-75"
                                                                />
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table className="w-50">
                                                          <tbody className="border-0">
                                                            <tr>
                                                              <td
                                                                className="pb-10 border-0"
                                                                colspan="6"
                                                              >
                                                                <b>
                                                                  DELIVERY
                                                                  INSTRUCTIONS
                                                                </b>
                                                                <br></br>
                                                                {
                                                                  formData.DeliveryInstructions
                                                                }
                                                                <br></br>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0 text-center h6">
                                                              Mitco Ltd. must be
                                                              notified of
                                                              detention 1⁄2 hour
                                                              before it starts
                                                              and documentation
                                                              within 48 hours.
                                                              BOL must have time
                                                              in and out and
                                                              facilities
                                                              signature.
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0 pb-5">
                                                              <b>
                                                                CARRIER COST:
                                                              </b>{" "}
                                                              $
                                                              {
                                                                formData.CarrierCost
                                                              }{" "}
                                                              Accepted:________________________________
                                                              Date:____________________
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              Phone: (253)
                                                              245-2102 | Fax:
                                                              (253) 891-0601
                                                              <br></br>
                                                              Please sign and
                                                              return via email
                                                              to ensure payment
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              <small>
                                                                Please call
                                                                (253) 245-2102
                                                                immediately with
                                                                any questions,
                                                                concerns, or
                                                                problems!
                                                                <br></br>
                                                                Send Invoicing
                                                                to: Mitco Limited
                                                                Logistics. |
                                                                2302 B Street NW #101
                                                                | Auburn, WA. 98001
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="row justify-content-center pt-8 px-8 pt-md-0 px-md-0 pb-md-10">
                                                <div className="col-md-12">
                                                  <div className="row pb-10">
                                                    <div className="col-md-8 px-0 mr-auto">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="border-0">
                                                              <b className="h6">
                                                                {formData.Title}
                                                              </b>
                                                              <br></br>
                                                              {
                                                                formData.BillTo
                                                                  ?.Name
                                                              }
                                                              <br></br>
                                                              {formData.BillTo
                                                                ?.Address1 ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.Address1}
                                                              {(formData.BillTo
                                                                ?.Address2 ===
                                                                undefined) |
                                                              (formData.BillTo
                                                                ?.Address2 ===
                                                                null)
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.Address2}
                                                              <br></br>
                                                              {formData.BillTo
                                                                ?.City ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.City}
                                                              ,{" "}
                                                              {formData.BillTo
                                                                ?.State ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.State}{" "}
                                                              {formData.BillTo
                                                                ?.ZipCode ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.ZipCode}
                                                              <br></br>
                                                              {formData.BillTo
                                                                ?.Phone ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.Phone}
                                                              <br></br>
                                                              {formData.BillTo
                                                                ?.Reference ===
                                                              undefined
                                                                ? " "
                                                                : formData
                                                                    .BillTo
                                                                    ?.Reference}
                                                              <br></br>
                                                            </td>
                                                            <td className="border-0 text-left">
                                                              <Link
                                                                to="#"
                                                                class="mb-5 max-w-100px"
                                                              >
                                                                <span class="svg-icon svg-icon-full">
                                                                  <img
                                                                    src="/media/logos/Mitco.svg"
                                                                    className="w-75"
                                                                    alt="mitco logo"
                                                                  />
                                                                </span>
                                                              </Link>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-2">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Carrier Name
                                                            </th>
                                                            <th className="text-center">
                                                              PickUp Date{" "}
                                                            </th>
                                                            <th className="text-center">
                                                              Delivery Date
                                                            </th>
                                                            <th className="text-center">
                                                              Shipment Id
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Number
                                                            </th>
                                                            <th className="text-center">
                                                              Shipper Ref
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.CarrierName
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                formData.ReadyDate
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formatDate(
                                                                formData.DateNeeded
                                                              )}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.LoadNumber
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.Equipment
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.ShipperRef
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-2">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Service
                                                            </th>
                                                            <th className="text-center">
                                                              Mode
                                                            </th>
                                                            <th className="text-center">
                                                              Service Level
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Size
                                                            </th>
                                                            <th className="text-center">
                                                              Equipment Type
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Service}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Mode}
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.ServiceLevel
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.EquipmentSize
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.EquipmentType
                                                              }
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-0">
                                                    <div className="col-md-12">
                                                      <div className="row">
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                Shipper
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                {
                                                                  formData
                                                                    .Shipper
                                                                    ?.ShipperAddress
                                                                    ?.Name
                                                                }
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                {IsValidAddress(
                                                                  formData
                                                                    .Shipper
                                                                    ?.ShipperAddress
                                                                    ?.Address1,
                                                                  true
                                                                ) +
                                                                  IsValidAddress(
                                                                    formData.Shipper?.ShipperAddress?.City.replace(
                                                                      / /g,
                                                                      ""
                                                                    ),
                                                                    false
                                                                  ) +
                                                                  IsValidAddress(
                                                                    formData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.State,
                                                                    false
                                                                  ) +
                                                                  IsValidAddress(
                                                                    formData
                                                                      .Shipper
                                                                      ?.ShipperAddress
                                                                      ?.ZipCode,
                                                                    false
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                {
                                                                  formData
                                                                    .Shipper
                                                                    ?.Contact
                                                                }
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                {
                                                                  formData
                                                                    .Shipper
                                                                    ?.Phone
                                                                }
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Pick Up Time
                                                                From-To:{" "}
                                                                {tConvert(
                                                                  formData
                                                                    .Shipper
                                                                    ?.ReadyTime
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                        <table className="w-50">
                                                          <thead className="d-flex justify-content-center">
                                                            <tr>
                                                              <th
                                                                colspan="10"
                                                                className="text-center"
                                                              >
                                                                Consignee
                                                                Information
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr>
                                                              <td className="">
                                                                Name:{" "}
                                                                {
                                                                  formData
                                                                    .Consignee
                                                                    ?.ShipperAddress
                                                                    ?.Name
                                                                }
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Address:{" "}
                                                                {IsValidAddress(
                                                                  formData
                                                                    .Consignee
                                                                    ?.ShipperAddress
                                                                    ?.Address1,
                                                                  true
                                                                ) +
                                                                  IsValidAddress(
                                                                    formData.Consignee?.ShipperAddress?.City.replace(
                                                                      / /g,
                                                                      ""
                                                                    ),
                                                                    false
                                                                  ) +
                                                                  IsValidAddress(
                                                                    formData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.State,
                                                                    false
                                                                  ) +
                                                                  IsValidAddress(
                                                                    formData
                                                                      .Consignee
                                                                      ?.ShipperAddress
                                                                      ?.ZipCode,
                                                                    false
                                                                  )}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Contact:{" "}
                                                                {(formData
                                                                  .Consignee
                                                                  ?.Contact ===
                                                                  undefined) |
                                                                (formData
                                                                  .Consignee
                                                                  ?.Contact ===
                                                                  null)
                                                                  ? " "
                                                                  : formData
                                                                      .Consignee
                                                                      ?.Contact}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Phone:{" "}
                                                                {(formData
                                                                  .Consignee
                                                                  ?.Phone ===
                                                                  undefined) |
                                                                (formData
                                                                  .Consignee
                                                                  ?.Phone ===
                                                                  null)
                                                                  ? " "
                                                                  : formData
                                                                      .Consignee
                                                                      ?.Phone}
                                                              </td>
                                                            </tr>
                                                            <tr>
                                                              <td>
                                                                Delivery Time
                                                                From-To:{" "}
                                                                {tConvert(
                                                                  formData
                                                                    .Consignee
                                                                    ?.CloseTime
                                                                )}
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* Items */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-3">
                                                      Shipment Items
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-0">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              PCS
                                                            </th>
                                                            <th className="text-center">
                                                              Package Type
                                                            </th>
                                                            {/* <th className="text-center">Pieces</th> */}
                                                            <th className="text-center">
                                                              HAZMAT
                                                            </th>
                                                            {/* <th className="text-center">List of Items</th> */}
                                                            <th className="text-center">
                                                              Total Weight (LBS)
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.Items
                                                                  ?.handlingUnit
                                                              }
                                                            </td>
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {
                                                                formData.Items
                                                                  ?.PackageType
                                                              }
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.Pieces}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              <CheckboxLargeWB
                                                                checked={
                                                                  formData.Items
                                                                    ?.HasMat !==
                                                                    null &&
                                                                  formData.Items
                                                                    ?.HasMat ===
                                                                    true
                                                                    ? true
                                                                    : false
                                                                }
                                                                label=""
                                                                text=""
                                                                name="PALLET_SLIP1"
                                                              />
                                                            </td>
                                                            {/* <td className="font-weight-boldest align-text-top text-center" >{item.ListOfItems}</td> */}
                                                            <td className="font-weight-boldest align-text-top text-center">
                                                              {formData.Items
                                                                ?.TotalLBSWeight !==
                                                              0
                                                                ? currencyFormat(
                                                                    formData
                                                                      .Items
                                                                      ?.TotalLBSWeight
                                                                  )
                                                                : currencyFormat(
                                                                    formData
                                                                      .Items
                                                                      ?.TotalWeight
                                                                  )}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>

                                                  {/* Vendors */}
                                                  <div class="d-flex justify-content-center flex-column flex-md-row">
                                                    <h4 class="font-weight-boldest my-3">
                                                      Payables
                                                    </h4>
                                                  </div>
                                                  <div className="row pb-0">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <thead className="justify-content-center">
                                                          <tr>
                                                            <th className="text-center">
                                                              Carrier Name
                                                            </th>
                                                            <th className="text-center">
                                                              Carrier Reference
                                                            </th>
                                                            <th className="text-center">
                                                              Name
                                                            </th>
                                                            <th className="text-center">
                                                              Rate
                                                            </th>
                                                            <th className="text-center">
                                                              Quantity
                                                            </th>
                                                            <th className="text-center">
                                                              Total
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {formData.Vendors
                                                            ?.length > 0 &&
                                                            formData.Vendors?.map(
                                                              (item, index) =>
                                                                item.Payables
                                                                  ?.length >
                                                                  0 &&
                                                                item.Payables?.map(
                                                                  (
                                                                    itemX,
                                                                    index
                                                                  ) => (
                                                                    <tr>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item
                                                                            .VendorAddress
                                                                            ?.CompanyName
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          item.VendorNumber
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Name
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {currencyFormat(
                                                                          itemX.Rate
                                                                        )}
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {
                                                                          itemX.Quantity
                                                                        }
                                                                      </td>
                                                                      <td className="font-weight-boldest align-text-top text-center">
                                                                        {currencyFormat(
                                                                          itemX.Total
                                                                        )}
                                                                      </td>
                                                                    </tr>
                                                                  )
                                                                )
                                                            )}
                                                          {formData.Vendors
                                                            ?.length > 0 &&
                                                            formData.Vendors?.map(
                                                              (item, index) => (
                                                                <tr className="border-left-0 border-bottom-0">
                                                                  <td className="border-0"></td>
                                                                  <td className="border-0"></td>
                                                                  <td className="border-0"></td>
                                                                  <td className="border-0"></td>
                                                                  <td
                                                                    className="font-weight-boldest"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "#113059",
                                                                      color:
                                                                        "#fff",
                                                                    }}
                                                                  >
                                                                    GRAND TOTAL
                                                                  </td>
                                                                  <td
                                                                    className="text-center font-weight-bolder"
                                                                    style={{
                                                                      backgroundColor:
                                                                        "aliceblue",
                                                                    }}
                                                                  >
                                                                    {"$ " +
                                                                      currencyFormat(
                                                                        item.Total
                                                                      )}
                                                                  </td>
                                                                </tr>
                                                              )
                                                            )}
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <small className="text-muted">
                                                                This
                                                                confirmation
                                                                governs the
                                                                movement of the
                                                                above-referenced
                                                                freight as of
                                                                the specified
                                                                and hereby
                                                                amends, is
                                                                incorporated by
                                                                reference and
                                                                becomes a part
                                                                of the certain
                                                                transportation
                                                                contract by and
                                                                between “Broker”
                                                                and “Contract
                                                                carrier”.
                                                                Carrier Agrees
                                                                to sign the
                                                                confirmation and
                                                                return it to the
                                                                broker via email
                                                                and carrier
                                                                shall be
                                                                conclusively
                                                                presumed and
                                                                compensatory
                                                                that the freight
                                                                would not have
                                                                been tendered to
                                                                Carrier at
                                                                higher rates and
                                                                that not
                                                                shipments
                                                                handled under
                                                                such rates will
                                                                subsequently be
                                                                subject to a
                                                                later claim for
                                                                undercharges. IF
                                                                AGREED SERVICES
                                                                ARE FULFILLED,
                                                                RATES ARE NOT
                                                                NEGOTIABLE.Carrier
                                                                MUST email
                                                                tracing@mitcoglobal.com
                                                                For any pickup
                                                                and delivery
                                                                appointments
                                                                that are
                                                                required.
                                                                Failure to
                                                                comply with
                                                                appointments
                                                                will result in a
                                                                penalty of
                                                                $150.00 Per
                                                                Appointment.
                                                              </small>
                                                              <br></br>
                                                              <small className="text-muted">
                                                                The undersigned
                                                                accepts the
                                                                referenced
                                                                shipment on
                                                                behalf of the
                                                                carrier and
                                                                acknowledge as
                                                                correct the
                                                                information
                                                                contained
                                                                herein, the
                                                                carrier agrees
                                                                to the terms of
                                                                the Master
                                                                agreement
                                                                previously
                                                                executed between
                                                                our companies.
                                                                Invoicing by the
                                                                carrier and
                                                                payment by Mitco
                                                                Ltd.,
                                                                constitutes
                                                                acceptance of
                                                                this agreement
                                                                and creates a
                                                                valid contract
                                                                for carriage
                                                                shipment.
                                                              </small>
                                                              <br></br>
                                                              <small className="text-muted">
                                                                When loading,
                                                                the driver must
                                                                count and
                                                                inspect his/her
                                                                load. The
                                                                Driver/Carrier
                                                                is responsible
                                                                for piece count
                                                                and condition of
                                                                load at time of
                                                                delivery.{" "}
                                                                <b className="text-dark">
                                                                  For payment of
                                                                  freight
                                                                  charges, we
                                                                  must receive
                                                                  original and
                                                                  signed Bill of
                                                                  lading and
                                                                  Proof of
                                                                  Delivery with
                                                                  a carrier
                                                                  invoice
                                                                </b>
                                                                . Payment will
                                                                be made 30 days
                                                                after all
                                                                required
                                                                paperwork is
                                                                received at
                                                                Mitco Ltd.,
                                                                facilities.
                                                                Carrier will
                                                                send a POD for
                                                                load to
                                                                tracing@mitcoglobal.com
                                                                within 48 hours
                                                                of delivery or
                                                                carrier will be
                                                                assessed a $50
                                                                late fee per day
                                                                until received
                                                                at that email.
                                                                Carrier will
                                                                send the invoice
                                                                and POD to
                                                                accounting@mitcoglobal.com.
                                                                We are not
                                                                responsible for
                                                                Overweight. If
                                                                Dimensions,
                                                                Weight, Quantity
                                                                or type of
                                                                commodity are
                                                                different than
                                                                those consigned
                                                                in our Load
                                                                Confirmation
                                                                Agreement, the
                                                                carrier or
                                                                Broker
                                                                contracted MUST
                                                                notify Mitco
                                                                Ltd. Before
                                                                picking up and
                                                                request a
                                                                WRITTEN
                                                                AUTHORIZATION.
                                                                Mitco Ltd. will
                                                                not pay any
                                                                extra charges
                                                                without
                                                                AUTHORIZATION,
                                                                and will only
                                                                approve changes
                                                                to charges via
                                                                revised rate
                                                                confirmation. In
                                                                and out times
                                                                must be provided
                                                                on POD for all
                                                                pickup and
                                                                deliveries.{" "}
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              Phone: (253)
                                                              245-2102 | Fax:
                                                              (253) 891-0601
                                                              <br></br>
                                                              Please sign and
                                                              return via email
                                                              to ensure payment
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top border-0 pb-5">
                                                              <b>
                                                                Carrier
                                                                Signature:
                                                              </b>
                                                              ________________________
                                                            </td>
                                                            <td className="align-text-top border-0 text-right pb-5">
                                                              <b>
                                                                Driver Name:
                                                              </b>{" "}
                                                              <span>
                                                                {formData
                                                                  .Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                formData.Vendors ===
                                                                  undefined ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .DriverName ===
                                                                  "" ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .DriverName ===
                                                                  null
                                                                  ? "________________________"
                                                                  : formData
                                                                      .Vendors[0]
                                                                      .DriverName}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <b>
                                                                Signature Date:
                                                              </b>
                                                              ________________________
                                                            </td>
                                                            <td className="align-text-top border-0 text-right">
                                                              <b>
                                                                Driver Phone#:
                                                              </b>{" "}
                                                              <span>
                                                                {formData
                                                                  .Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                formData.Vendors ===
                                                                  undefined ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .DriverPhone ===
                                                                  "" ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .DriverPhone ===
                                                                  null
                                                                  ? " ________________________"
                                                                  : formData
                                                                      .Vendors[0]
                                                                      .DriverPhone}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                          <br />
                                                          <tr>
                                                            <td className="align-text-top border-0">
                                                              <b>MC#:</b>{" "}
                                                              <span>
                                                                {
                                                                  formData.MCNumber
                                                                }
                                                              </span>
                                                            </td>
                                                            <td className="align-text-top border-0 text-right">
                                                              <b>
                                                                Truck Number:
                                                              </b>{" "}
                                                              <span>
                                                                {formData
                                                                  .Vendors
                                                                  ?.length ===
                                                                  0 ||
                                                                formData.Vendors ===
                                                                  undefined ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .TruckNumber ===
                                                                  "" ||
                                                                formData
                                                                  .Vendors[0]
                                                                  .TruckNumber ===
                                                                  null
                                                                  ? " ________________________"
                                                                  : formData
                                                                      .Vendors[0]
                                                                      .TruckNumber}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                  <div className="row pb-5">
                                                    <div className="col-md-12 px-0 ">
                                                      <table className="w-100">
                                                        <tbody className="border-0">
                                                          <tr>
                                                            <td className="align-text-top text-center border-0 font-weight-bold">
                                                              <small>
                                                                Please call
                                                                (253) 245-2102
                                                                immediately with
                                                                any questions,
                                                                concerns, or
                                                                problems!
                                                                <br></br>
                                                                Send Invoicing
                                                                to: Mitco Limited
                                                                Logistics. |2302
                                                                B Street NW #101
                                                                | Auburn, WA.
                                                                98001
                                                              </small>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Tab.Pane>
                                ))}
                            </Tab.Content>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                className="btn btn-light btn-elevate"
                onClick={() => {
                  fetch(
                    `${window.$apiurl}/ShipmentActions/updateSingleStatusMultiShipment`,
                    {
                      method: "post",
                      withCredentials: true,
                      headers: {
                        Accept: "application/json, text/plain, */*",
                        Authorization: `bearer ${db.read().getState().Token}`,
                        "X-FP-API-KEY": "iphone",
                        "Content-Type": "application/json",
                      },
                      body: `{"Ids":[${SelectShipmentIds.map(
                        (a) => a.id
                      )}],IsRollBack:true,KeepChanges:false}`,
                    }
                  )
                    .then(async (response) => {
                      const statusCode = await response.status;
                      const contentType = response.headers.get("content-type");
                      if (
                        contentType &&
                        contentType.indexOf("application/json") !== -1
                      ) {
                        return response
                          .json()
                          .then((data) => [statusCode, data]);
                      } else {
                        return response
                          .text()
                          .then((text) => [statusCode, { Message: "" }]);
                      }
                    })
                    .then((result) => {
                      if (result[0] === 200) {
                        if (
                          result[1].Message !== null &&
                          result[1].Message !== undefined &&
                          result[1].Message !== ""
                        ) {
                          Notification("errorClick", result[1].Message);
                        } else {
                          Notification(
                            "success",
                            "Roll Back Status is successful"
                          );
                          setShowModalPreviewDispatched(false);
                          setTimeout(() => {
                            document.getElementById("kt_body").style.overflow =
                              "scroll";
                          }, 500);
                          document.getElementById("ReLoadTableId").click();
                        }
                      } else {
                        if (
                          result[1].Message !== null &&
                          result[1].Message !== undefined &&
                          result[1].Message !== ""
                        ) {
                          Notification("errorClick", result[1].Message);
                          setShowModalPreviewDispatched(false);
                          setTimeout(() => {
                            document.getElementById("kt_body").style.overflow =
                              "scroll";
                          }, 500);
                        } else {
                          Notification("errorClick", "invalid request");
                          setShowModalPreviewDispatched(false);
                          setTimeout(() => {
                            document.getElementById("kt_body").style.overflow =
                              "scroll";
                          }, 500);
                        }
                      }
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                }}
              >
                Cancel changes
              </button>
              <button
                className="mr-3"
                className="btn btn-success btn-elevate"
                onClick={() => {
                  setShowModalPreviewDispatched(false);
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                Save without sending file
              </button>
              <button
                type="submit"
                className="btn btn-primary btn-elevate"
                onClick={() => {
                  window.SendDispatchShipmentsList = SelectShipmentIds;
                  setTimeout(() => {
                    setShowModalPreviewDispatched(false);
                    setShowSendEmail(true);
                    // history.push(
                    //   `/ShipmentsManagement/${window.ShpmentsIdFrowSendEmail}/SendEmail`
                    // );
                  }, 200);
                }}
              >
                Send Dispatch file to Vendor
              </button>
            </Modal.Footer>
          </Modal>

          <CustomerDeleteDialog
            id={window.ShpmentsIdFrowSendEmail}
            show={showSendEmail}
            onHide={() => setShowSendEmail(false)}
          />
          {/* end preview modal */}
          {/* appointed Modal */}
          <Modal
            size="md"
            id="AppointedDateModal"
            show={ShowModalAppointed}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Appointed Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalAppointed(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-6">
                  <TextField
                    style={{ marginTop: "10px", width: "243px" }}
                    id="PickupAptDate"
                    name="PickupAptDate"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Pickup On Date</b>
                  </small>
                </div>
                <div className="col-lg-5 ml-5">
                  <select
                    id={"PickupAptTime"}
                    name={"PickupAptTime"}
                    defaultValue={"12:00:00"}
                    className="form-control"
                  >
                    <DateTime30minInterval />
                  </select>
                  <small className="form-text text-muted">
                    <b>Choose</b> a Time
                  </small>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6">
                  <TextField
                    style={{ marginTop: "10px", width: "243px" }}
                    id="DeliveryAptDate"
                    name="DeliveryAptDate"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>Delivery On Date</b>
                  </small>
                </div>
                <div className="col-lg-5 ml-5">
                  <select
                    id={"DeliveryAptTime"}
                    name={"DeliveryAptTime"}
                    defaultValue={"12:00:00"}
                    className="form-control"
                  >
                    <DateTime30minInterval />
                  </select>
                  <small className="form-text text-muted">
                    <b>Choose</b> a Time
                  </small>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalAppointed(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={() => {
                  SubmitAppointedDetail();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>

          {/* end appointed Modal */}

          {/* Sent InterModal Modal */}
          <Modal
            size="md"
            id="AppointedDateModal"
            show={ShowModalSentInterModal}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Sent Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  ShowModalSentInterModal(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-4">
                  <TextField
                    style={{ marginTop: "10px", width: "150px" }}
                    id="PickupAptDate"
                    name="PickupAptDate"
                    type="date"
                    defaultValue={new Date().toISOString().substr(0, 10)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <small className="form-text text-muted">
                    <b>pick up Appointment Date</b>
                  </small>
                </div>
                <div className="col-lg-4">
                  <select
                    id={"PickupAptTime"}
                    name={"PickupAptTime"}
                    defaultValue={"12:00:00"}
                    className="form-control"
                  >
                    <DateTime30minInterval />
                  </select>
                  <small className="form-text text-muted">
                    <b>Choose</b> On Time
                  </small>
                </div>
                <div className="col-lg-4">
                  <select
                    id={"DeliveryAptTime"}
                    name={"DeliveryAptTime"}
                    defaultValue={"12:00:00"}
                    className="form-control"
                  >
                    <DateTime30minInterval />
                  </select>
                  <small className="form-text text-muted">
                    <b>Choose</b> To Time
                  </small>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  ShowModalSentInterModal(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={() => {
                  SubmitSentInterModalDetail();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>

          {/* end Sent InterModal Modal */}
          {/* start PickUp Modal */}

          <Modal
            size="xl"
            id="DispatchedDateModal"
            show={ShowModalPickUp}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                PickUp Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalPickUp(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-12">
                  {customers.map((a, index) => {
                    return (
                      <div className="form-group row mt-5">
                        <div className="col-lg-2 mt-4">
                          Shipment#: <strong>{a.id}</strong>
                        </div>
                        <div className="col-lg">
                          <input
                            className="form-control"
                            id={`ETA${index}`}
                            name={`ETA${index}`}
                            type="date"
                            defaultValue={new Date()
                              .toISOString()
                              .substr(0, 10)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <small className="form-text text-muted">
                            <b>ETA Date</b>
                          </small>
                        </div>
                        <div className="col-lg">
                          <select
                            id={`ETATime${index}`}
                            name={`ETATime${index}`}
                            defaultValue={"12:00:00"}
                            className="form-control"
                          >
                            <DateTime30minInterval />
                          </select>
                          <small className="form-text text-muted">
                            <b>Choose</b> From Time
                          </small>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalPickUp(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={async () => {
                  PostPickUpStatus();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
          {/* end PickUp modal */}
          {/* start dispatched Modal */}

          <Modal
            size="xl"
            id="DispatchedDateModal"
            show={ShowModalBooked}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Assigned Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalBooked(false);
                  setVandorSelectedData(null);
                  setFreightChargeIdSelectedData(98);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-12">
                  {customers.map((a, index) => {
                    return (
                      <div className="form-group row mt-5">
                        <div className="col-lg-2 mt-4">
                          Shipment#: <strong>{a.id}</strong>
                        </div>
                        <div className="col-lg">
                          <input
                            className="form-control"
                            id={`PickupOnDate${index}`}
                            name={`PickupOnDate${index}`}
                            type="date"
                            defaultValue={new Date()
                              .toISOString()
                              .substr(0, 10)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <small className="form-text text-muted">
                            <b>pick up Appointment Date</b>
                          </small>
                        </div>
                        <div className="col-lg">
                          <select
                            id={`PickupOnTime${index}`}
                            name={`PickupOnTime${index}`}
                            defaultValue={"12:00:00"}
                            className="form-control"
                          >
                            <DateTime30minInterval />
                          </select>
                          <small className="form-text text-muted">
                            <b>Choose</b> From Time
                          </small>
                        </div>
                        <div className="col-lg">
                          <select
                            id={`PickupToTime${index}`}
                            name={`PickupToTime${index}`}
                            defaultValue={"12:00:00"}
                            className="form-control"
                          >
                            <DateTime30minInterval />
                          </select>
                          <small className="form-text text-muted">
                            <b>Choose</b> To Time
                          </small>
                        </div>
                      </div>
                    );
                  })}
                  <div className="row">
                    <div className="col-lg-3">
                      <p className="mb-3">Vendors</p>
                      <Select
                        name="vandorId"
                        components={{ Option }}
                        options={optionsVandorList.filter((a) => {
                          var obj = { ...a };
                          if (FreightChargeIdSelectedData === 98) {
                            if (a.IsApproved === false) {
                              return {
                                value: obj?.value,
                                label: obj?.label,
                                color: "red",
                                CarrierId: obj?.CarrierId,
                                IsApproved: obj?.IsApproved,
                              };
                            } else {
                              return {
                                value: obj?.value,
                                label: obj?.label,
                                color: "black",
                                CarrierId: obj?.CarrierId,
                                IsApproved: obj?.IsApproved,
                              };
                            }
                          } else {
                            return {
                              value: obj?.value,
                              label: obj?.label,
                              color: "black",
                              CarrierId: obj?.CarrierId,
                              IsApproved: obj?.IsApproved,
                            };
                          }
                        })}
                        value={
                          VandorSelectedData !== null
                            ? optionsVandorList.filter(
                                (a) => a.value === VandorSelectedData
                              )
                            : optionsVandorList.filter(
                                (a) =>
                                  a.value ===
                                  customers.filter(
                                    (customer) =>
                                      customer?.CarrierId !== null || ""
                                  )[0]?.CarrierId
                              ) || ""
                        }
                        onChange={(event) => {
                          setVandorSelectedData(event?.value);
                        }}
                      ></Select>
                      {FreightChargeIdSelectedData === 98 &&
                      (optionsVandorList?.filter(
                        (a) => a.value === VandorSelectedData
                      )[0]?.IsApproved === false ||
                        optionsVandorList.filter(
                          (a) =>
                            a.value ===
                            customers.filter(
                              (customer) => customer?.CarrierId !== null || ""
                            )[0]?.CarrierId
                        )[0]?.IsApproved === false) ? (
                        <p style={{ color: "red" }}>
                          This Vendor is not Certified
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-3">Vendor Type</p>
                      <Select
                        name="VendorTypeId"
                        value={optionsVandorTypeList
                          .filter((a) => a.Id === VendorTypeIdSelectedData)
                          .map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name,
                            };
                          })}
                        options={optionsVandorTypeList.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(event) => {
                          setVendorTypeIdSelectedData(event?.value);
                        }}
                      ></Select>
                    </div>
                    <div className="col-lg-3">
                      <p className="mb-3">Freight Charge</p>
                      <Select
                        name="FreightChargeId"
                        defaultValue={{
                          value: 98,
                          label: "Prepaid",
                        }}
                        options={FreightChargeList.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Name,
                          };
                        })}
                        onChange={(event) => {
                          setFreightChargeIdSelectedData(event?.value);
                          //setVandorSelectedData(null);
                        }}
                      ></Select>
                    </div>
                    {FreightChargeIdSelectedData === 100 ? (
                      <div className="col-lg-3">
                        <p className="mb-3">BillTo</p>
                        <Select
                          name="BillTo"
                          options={BillToList.map((x) => {
                            return {
                              value: x?.Id,
                              label: x?.Name + "-" + x?.Code,
                            };
                          })}
                          onChange={(event) => {
                            setBillToSelectedId(event?.value);
                          }}
                        ></Select>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-lg-12 mt-5">
                      <ChargesFunctionCompo
                        VandorSelectedData={VandorSelectedData}
                        customers={customers}
                        optionsVandorList={optionsVandorList}
                        isInbound={false}
                        linq={"transloadshipments"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6"></div>
                <div className="col-lg-5 ml-5"></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalBooked(false);
                  setVandorSelectedData(null);
                  setFreightChargeIdSelectedData(98);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={async () => {
                  PostbookedchVandors();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
          {/* end dispatched modal */}

          {/* start dispatched Modal */}
          <Modal
            size="lg"
            id="DispatchedDateModal"
            show={ShowModalDispatched}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Dispatch Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalDispatched(false);
                  setVandorSelectedData(null);
                  setFreightChargeIdSelectedData(98);
                  setBillToSelectedId(null);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-12">
                  <p className="mb-3">Vendors</p>
                  <Select
                    name="vandorId"
                    options={optionsVandorList}
                    value={
                      VandorSelectedData !== null
                        ? optionsVandorList.filter(
                            (a) => a.value === VandorSelectedData
                          )
                        : optionsVandorList.filter(
                            (a) =>
                              a.value ===
                              customers.filter(
                                (customer) => customer?.CarrierId !== null || ""
                              )[0]?.CarrierId
                          ) || ""
                    }
                    onChange={(event) => {
                      setVandorSelectedData(event?.value);
                      GetAttachements({ VendorId: event?.value });
                    }}
                  ></Select>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6"></div>
                <div className="col-lg-5 ml-5"></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalDispatched(false);
                  setVandorSelectedData(null);
                  setFreightChargeIdSelectedData(98);
                  setBillToSelectedId(null);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={async () => {
                  PostDispatchVandors();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
          {/* end dispatched modal */}
          {/* start Loading Modal */}
          <Modal
            size="md"
            id="LoadingLoadidModal"
            show={ShowModalLoading}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Loading Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalLoading(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-12">
                  <p className="mb-3">Equipment Number</p>
                  <input
                    type="text"
                    className="form-control"
                    name="EquipmentNumberInput"
                    id="EquipmentNumberInput"
                    placeholder="Equipment Number"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6"></div>
                <div className="col-lg-5 ml-5"></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalLoading(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={async () => {
                  SubmitEqNumber(5);
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
          {/* start Loading Modal */}
          <Modal
            size="md"
            id="LoadingLoadidModal"
            show={ShowModalLoaded}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Loaded Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalLoaded(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="form-group row">
                <div className="col-lg-12">
                  <p className="mb-3">Seal Number</p>
                  <input
                    type="text"
                    className="form-control"
                    name="SealNumberInput"
                    id="SealNumberInput"
                    placeholder="Seal Number"
                  />
                </div>
              </div>

              <div className="form-group row">
                <div className="col-lg-6"></div>
                <div className="col-lg-5 ml-5"></div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalLoaded(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={async () => {
                  SubmitEqNumber(6);
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
          {/* end dispatched modal */}
          <Modal
            size="md"
            id="sendDespatchEmailModal"
            show={ShowModalDespatch}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">
                Dispatch Details
              </Modal.Title>
              <IconButton
                aria-label="Close"
                onClick={() => {
                  setShowModalDespatch(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body className=" overlay-block">
              <div className="col-lg-12 mt-5">
                <p className="mb-3">Notes</p>
                <CKEditor
                  name="despatchEmailBody"
                  Id="despatchEmailBody"
                  label="Body"
                  rows="10"
                  editor={ClassicEditor}
                  config={{
                    removePlugins: [
                      "Image",
                      "ImageCaption",
                      "ImageStyle",
                      "ImageToolbar",
                      "ImageUpload",
                      "MediaEmbed",
                    ],
                  }}
                  onBlur={(event, editor) => {
                    setckEditorData(editor.getData());
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                className="mr-3"
                onClick={() => {
                  setShowModalDespatch(false);
                  document.getElementsByClassName(
                    "MuiDrawer-modal"
                  )[0].style.visibility = "visible";
                  setTimeout(() => {
                    document.getElementById("kt_body").style.overflow =
                      "scroll";
                  }, 500);
                }}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={LoadingchangeDespatchStatus}
                onClick={() => {
                  SendDespatchEmail();
                }}
                className="btn btn-primary btn-elevate"
              >
                Save changes
                {LoadingchangeDespatchStatus && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        {/*end::Header*/}
        {/*begin::Content*/}
        <p className="font-weight-bolder font-dark-75 h6">
          {" "}
          {customers.length === 0
            ? "Please select Shipement"
            : customers.map((customer) => customer.id + ", ")}
        </p>
        <p className="font-weight-bolder font-dark-75 h6">
          {window.$ShipmentManagementStatus === ""
            ? "Please select Status"
            : ""}
        </p>
        <div
          className="offcanvas-content pr-5 mr-n5 scroll ps ps--active-y"
          style={{ overflow: "hidden" }}
        >
          <div className="navi navi-spacer-x-0 p-0">
            {/*begin::Item*/}
            {window.$ShipmentManagementStatus &&
              customers.length !== 0 &&
              DetailShipmentInfo.map((item, index) => {
                var __FOUND = customers.find(function(post, index) {
                  if (post.FreightCharge === "Prepaid") return true;
                });
                if (item.StatusId !== window.$ShipmentManagementStatus) {
                  if (
                    index > 0 &&
                    DetailShipmentInfo[index - 1].StatusId ===
                      window.$ShipmentManagementStatus
                  ) {
                    return (
                      <a
                        key={index}
                        className={"navi-item"}
                        onClick={() => {
                          if (item.Name === "Assigned") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            getvandorsList();
                            setShowModalBooked(true);
                            setTimeout(() => {
                              // document.getElementById(
                              //   "DispatchedDateModal"
                              // ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                            }, 500);
                            return;
                          }
                          if (item.Name === "Appointed") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalAppointed(true);
                            setTimeout(() => {
                              document.getElementById(
                                "AppointedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (item.Name === "Picked Up") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalPickUp(true);
                            setTimeout(() => {
                              document.getElementById(
                                "DispatchedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (item.StatusId === 62) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalSentInterModal(true);
                            setTimeout(() => {
                              document.getElementById(
                                "AppointedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (
                            (item.StatusId === 4 || item.StatusId === 60) &&
                            DetailShipmentInfo[index - 1].StatusId === 3
                          ) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("error", "Customer is On Hold");
                              return;
                            }
                            if (
                              !customers.every(
                                (v) =>
                                  v.CustomerName === customers[0].CustomerName
                              )
                            ) {
                              Notification(
                                "error",
                                "shipments dosn't have same customer"
                              );
                              return;
                            }
                            if (
                              !customers.every(
                                (v) =>
                                  v.CarrierCode === customers[0].CarrierCode
                              )
                            ) {
                              Notification(
                                "error",
                                "shipments dosn't have same Carrier"
                              );
                              return;
                            }
                            getvandorsList();
                            setShipmentStatusId(item.StatusId);
                            setShowModalDispatched(true);
                            //setShowModalDespatch(true);
                            setTimeout(() => {
                              // document.getElementById(
                              //   "DispatchedDateModal"
                              // ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                            }, 500);
                            return;
                          }
                          if (item.StatusId === 5) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            customers.map((customer) => {
                              if (
                                customer.EquipmentNumber === null ||
                                customer.EquipmentNumber === ""
                              ) {
                                setShowModalLoading(true);
                                setTimeout(() => {
                                  document.getElementById(
                                    "LoadingLoadidModal"
                                  ).parentNode.style.zIndex = "1500";
                                  document.getElementsByClassName(
                                    "MuiDrawer-modal"
                                  )[0].style.visibility = "hidden";
                                }, 500);
                                return;
                              } else {
                                submitShipmentStatus(item.StatusId, item.Name);
                                document.getElementsByClassName(
                                  "MuiDrawer-modal"
                                )[0].style.visibility = "hidden";
                                toggleDrawer(side, false);
                                setTimeout(() => {
                                  document.getElementById(
                                    "kt_body"
                                  ).style.overflow = "scroll";
                                }, 500);
                                return;
                              }
                            });
                            return;
                          }
                          if (item.StatusId === 6) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            customers.map((customer) => {
                              if (
                                customer.SealNumber === null ||
                                customer.SealNumber === ""
                              ) {
                                setShowModalLoaded(true);
                                setTimeout(() => {
                                  document.getElementById(
                                    "LoadingLoadidModal"
                                  ).parentNode.style.zIndex = "1500";
                                  document.getElementsByClassName(
                                    "MuiDrawer-modal"
                                  )[0].style.visibility = "hidden";
                                }, 500);
                                return;
                              } else {
                                submitShipmentStatus(item.StatusId, item.Name);
                                document.getElementsByClassName(
                                  "MuiDrawer-modal"
                                )[0].style.visibility = "hidden";
                                toggleDrawer(side, false);
                                setTimeout(() => {
                                  document.getElementById(
                                    "kt_body"
                                  ).style.overflow = "scroll";
                                }, 500);
                                return;
                              }
                            });
                            return;
                          }
                          submitShipmentStatus(item.StatusId, item.Name);
                          document.getElementsByClassName(
                            "MuiDrawer-modal"
                          )[0].style.visibility = "hidden";
                          toggleDrawer(side, false);
                          setTimeout(() => {
                            document.getElementById("kt_body").style.overflow =
                              "scroll";
                          }, 500);
                        }}
                      >
                        <div className="navi-link">
                          <div className=" mr-3">
                            <div className="symbol-label">
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <i class="flaticon-more-v4 text-warning"></i>
                              </span>
                            </div>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">{item.Name}</div>
                          </div>
                        </div>
                      </a>
                    );
                  } else {
                    return (
                      <a
                        key={index}
                        className={
                          "navi-item " +
                          ((window.$ShipmentManagementStatus === 4 &&
                            item.StatusId === 7) ||
                          (window.$ShipmentManagementStatus === 3 &&
                            item.StatusId === 5) ||
                          (window.$ShipmentManagementStatus === 7 &&
                            __FOUND !== undefined &&
                            (item.StatusId === 9 ||
                              item.StatusId === 11 ||
                              item.StatusId === 12 ||
                              item.StatusId === 13 ||
                              item.StatusId === 63 ||
                              item.StatusId === 64 ||
                              item.StatusId === 65 ||
                              item.StatusId === 66 ||
                              item.StatusId === 14)) ||
                          (window.$ShipmentManagementStatus === 9 &&
                            (item.StatusId === 63 || item.StatusId === 64)) ||
                          (window.$ShipmentManagementStatus === 63 &&
                            (item.StatusId === 64 ||
                              item.StatusId === 65 ||
                              item.StatusId === 66 ||
                              item.StatusId === 14))
                            ? ""
                            : classes.isDisabled)
                        }
                        onClick={() => {
                          if (item.Name === "Assigned") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            getvandorsList();
                            setShowModalBooked(true);
                            setTimeout(() => {
                              // document.getElementById(
                              //   "DispatchedDateModal"
                              // ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                            }, 500);
                            return;
                          }
                          if (item.Name === "Appointed") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalAppointed(true);
                            setTimeout(() => {
                              document.getElementById(
                                "AppointedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (item.Name === "Picked Up") {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalPickUp(true);
                            setTimeout(() => {
                              document.getElementById(
                                "DispatchedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (item.StatusId === 62) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            setShowModalSentInterModal(true);
                            setTimeout(() => {
                              document.getElementById(
                                "AppointedDateModal"
                              ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                              setTimeout(() => {
                                document.getElementById(
                                  "kt_body"
                                ).style.overflow = "scroll";
                              }, 500);
                            }, 500);
                            return;
                          }
                          if (
                            (item.StatusId === 4 || item.StatusId === 60) &&
                            DetailShipmentInfo[index - 1].StatusId === 3
                          ) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("error", "Customer is On Hold");
                              return;
                            }
                            getvandorsList();
                            setShipmentStatusId(item.StatusId);
                            setShowModalDispatched(true);
                            setTimeout(() => {
                              // document.getElementById(
                              //   "DispatchedDateModal"
                              // ).parentNode.style.zIndex = "1500";
                              document.getElementsByClassName(
                                "MuiDrawer-modal"
                              )[0].style.visibility = "hidden";
                            }, 500);
                            return;
                          }
                          if (item.StatusId === 5) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            customers.map((customer) => {
                              if (
                                customer.EquipmentNumber === null ||
                                customer.EquipmentNumber === ""
                              ) {
                                setShowModalLoading(true);
                                setTimeout(() => {
                                  document.getElementById(
                                    "LoadingLoadidModal"
                                  ).parentNode.style.zIndex = "1500";
                                  document.getElementsByClassName(
                                    "MuiDrawer-modal"
                                  )[0].style.visibility = "hidden";
                                }, 500);
                                return;
                              } else {
                                submitShipmentStatus(item.StatusId, item.Name);
                                document.getElementsByClassName(
                                  "MuiDrawer-modal"
                                )[0].style.visibility = "hidden";
                                toggleDrawer(side, false);
                                setTimeout(() => {
                                  document.getElementById(
                                    "kt_body"
                                  ).style.overflow = "scroll";
                                }, 500);
                                return;
                              }
                            });
                            return;
                          }
                          if (item.StatusId === 6) {
                            if (
                              customers.filter((a) => a.IsOnHold === true)
                                .length > 0
                            ) {
                              Notification("warning", "Customer is On Hold");
                            }
                            customers.map((customer) => {
                              if (
                                customer.SealNumber === null ||
                                customer.SealNumber === ""
                              ) {
                                setShowModalLoaded(true);
                                setTimeout(() => {
                                  document.getElementById(
                                    "LoadingLoadidModal"
                                  ).parentNode.style.zIndex = "1500";
                                  document.getElementsByClassName(
                                    "MuiDrawer-modal"
                                  )[0].style.visibility = "hidden";
                                }, 500);
                                return;
                              } else {
                                submitShipmentStatus(item.StatusId, item.Name);
                                document.getElementsByClassName(
                                  "MuiDrawer-modal"
                                )[0].style.visibility = "hidden";
                                toggleDrawer(side, false);
                                setTimeout(() => {
                                  document.getElementById(
                                    "kt_body"
                                  ).style.overflow = "scroll";
                                }, 500);
                                return;
                              }
                            });
                            return;
                          }
                          submitShipmentStatus(item.StatusId, item.Name);
                          document.getElementsByClassName(
                            "MuiDrawer-modal"
                          )[0].style.visibility = "hidden";
                          toggleDrawer(side, false);
                          setTimeout(() => {
                            document.getElementById("kt_body").style.overflow =
                              "scroll";
                          }, 500);
                        }}
                      >
                        <div className="navi-link">
                          <div className=" mr-3">
                            <div className="symbol-label">
                              <span className="svg-icon svg-icon-md svg-icon-success">
                                <i class="flaticon-more-v4 text-warning"></i>
                              </span>
                            </div>
                          </div>
                          <div className="navi-text">
                            <div className="font-weight-bold">{item.Name}</div>
                          </div>
                        </div>
                      </a>
                    );
                  }
                } else {
                  return (
                    <a
                      key={index}
                      className="navi-item"
                      style={{ pointerEvents: "none", cursor: "default" }}
                      onClick={() => {
                        submitShipmentStatus(item.StatusId, item.Name);
                      }}
                    >
                      <div className="navi-link">
                        <div className=" mr-3">
                          <div className="symbol-label">
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <i class="flaticon-medical text-success"></i>
                            </span>
                          </div>
                        </div>
                        <div className="navi-text">
                          <div className="font-weight-bold">{item.Name}</div>
                        </div>
                      </div>
                    </a>
                  );
                }
              })}
            {/*end:Item*/}
          </div>
          <div className="ps__rail-x" style={{ left: "0px", bottom: "0px" }}>
            <div
              className="ps__thumb-x"
              tabIndex={0}
              style={{ left: "0px", width: "0px" }}
            />
          </div>
          <div
            className="ps__rail-y"
            style={{ top: "0px", height: "657px", right: "0px" }}
          >
            <div
              className="ps__thumb-y"
              tabIndex={0}
              style={{ top: "0px", height: "300px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="layout-tooltip">Actions</Tooltip>}
        >
          <li className="nav-item mb-2" data-placement="right">
            <Link
              onClick={toggleDrawer("right", true)}
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            >
              <i className="flaticon2-drop h1-md" />
            </Link>
          </li>
        </OverlayTrigger>
      </ul>
      <Drawer
        anchor="right"
        style={state.right === true ? "" : { display: "none" }}
        open={true}
        onClose={toggleDrawer("right", false)}
      >
        {fullList("right")}
      </Drawer>
    </div>
  );
}

const ChargesFunction = ({ type, customers, vendorId, VendorList = [] }) => {
  const [ChargeModel, setChargeModel] = useState(false);
  const [Charges, setCharges] = useState([]);
  const [ChargesN, setChargesN] = useState([]);
  const [loadingCharges, setloadingCharges] = useState(true);

  useEffect(() => {
    const vId = vendorId || 0;
    var a = VendorList.find((x) => x.value === vId);
    if (vId && vId !== 0 && a?.CarrierId && ShipmentsId[0]) {
      GetList();
    }
  }, [vendorId, VendorList]);

  var ShipmentsId = [];
  customers.map((customer) => ShipmentsId.push(customer.id));

  const GetPayableForVendor = ({
    customerId,
    serviceId,
    modeId,
    serviceLevelId,
    equipmentTypeId,
    equipmentSizeId,
    CarrierId,
    fromCity,
    toCity,
    Inbound,
    Payable,
    Payables,
    FromAddress,
    FromState,
    FromZipcode,
    ToAddress,
    ToState,
    ToZipcode,
  }) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `bearer ${db.read().getState().Token}`);
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("X-FP-API-KEY", "iphone");
      var requestOptions = {
        method: "GET",
        withCredentials: true,
        headers: myHeaders,
        redirect: "follow",
      };
      var carrierIdSt = VendorList.find((x) => x.value === vendorId)?.CarrierId;
      fetch(
        window.$apiurl +
          `/ordersRating/GetCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${Inbound}&isPayable=${Payable}&carrierId=${carrierIdSt}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${FromAddress}&fromState=${FromState}&fromZipCode=${FromZipcode}&toCity=${toCity}&toAddress=${ToAddress}&toState=${ToState}&toZipCode=${ToZipcode}&date=`,
        //`/ordersRating/GetCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${Inbound}&isPayable=${Payable}&carrierId=${carrierIdSt}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&toCity=${toCity}`,
        requestOptions
      )
        .then((response) => response.text())
        .then(async (result) => {
          setCharges([]);
          setChargesN([]);
          setloadingCharges(true);

          let obj = JSON.parse(result) || [];
          let chargesVar = Payables || [];

          let objCalc =
            Payables.length === 0
              ? await GetPayableForVendorCalc({
                  customerId,
                  serviceId,
                  modeId,
                  serviceLevelId,
                  equipmentTypeId,
                  equipmentSizeId,
                  CarrierId,
                  fromCity,
                  toCity,
                  Inbound,
                  Payable,
                  FromAddress,
                  FromState,
                  FromZipcode,
                  ToAddress,
                  ToState,
                  ToZipcode,
                })
              : [];

          chargesVar = JSON.parse(JSON.stringify(chargesVar));
          chargesVar.forEach((x) => (x.Id = x?.ChargeId));

          let allObjs = chargesVar.concat(objCalc);

          let resArr = [];
          allObjs.filter(function(item) {
            let i = resArr.findIndex((x) => x.Id == item?.Id);
            if (i === -1) {
              resArr.push({ ...item, ChargeId: item?.Id });
            }
          });

          var a = [];
          obj.forEach((x) => {
            var i = allObjs.find((y) => y.Id === x.Id);
            if (i === undefined) a.push(x);
          });

          setCharges(resArr || []);
          setChargesN(a || []);
          setTimeout(() => {
            setloadingCharges(false);
          }, 1000);
        })
        .catch((error) => console.log("error", error));
    } catch (e) {}
  };

  async function GetPayableForVendorCalc({
    customerId,
    serviceId,
    modeId,
    serviceLevelId,
    equipmentTypeId,
    equipmentSizeId,
    CarrierId,
    fromCity,
    toCity,
    Inbound,
    Payable,
    FromAddress,
    FromState,
    FromZipcode,
    ToAddress,
    ToState,
    ToZipcode,
  }) {
    var carrierIdSt = VendorList.find((x) => x.value === vendorId)?.CarrierId;
    return await fetch(
      window.$apiurl +
        `/ordersRating/GetCalculatedCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${Inbound}&isPayable=${Payable}&carrierId=${carrierIdSt}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&fromAddress=${FromAddress}&fromState=${FromState}&fromZipCode=${FromZipcode}&toCity=${toCity}&toAddress=${ToAddress}&toState=${ToState}&toZipCode=${ToZipcode}&date=`,
      //`/ordersRating/GetCalculatedCharges?customerId=${customerId}&serviceId=${serviceId}&serviceLevelId=${serviceLevelId}&ModeId=${modeId}&isInbound=${Inbound}&isPayable=${Payable}&carrierId=${carrierIdSt}&equipmentTypeId=${equipmentTypeId}&equipmentSizeId=${equipmentSizeId}&fromCity=${fromCity}&toCity=${toCity}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = [];
        res[1].map((x) => a.push({ ...x, Calc: true }));
        return a;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetList() {
    return await fetch(
      `${window.$apiurl}/transloadshipments/payables/${ShipmentsId[0]}?vendorId=${vendorId}`,
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const a = res[1];
        GetPayableForVendor({
          customerId: a?.CustomerId,
          serviceId: a?.ServiceId,
          modeId: a?.ModeId,
          serviceLevelId: a?.ServiceLevelId,
          equipmentTypeId: a?.EquipmentTypeId,
          equipmentSizeId: a?.EquipmentSizeId,
          CarrierId: a?.CarrierId,
          fromCity: a?.FromCity,
          toCity: a?.ToCity,
          Inbound: a?.Inbound,
          Payable: a?.Payable,
          Payables: a?.Payables || [],
          FreightChargeId: a?.FreightChargeId,
          FreightChargeName: a?.FreightChargeName,
          FromAddress: a?.FromAddress,
          FromState: a?.FromState,
          FromZipcode: a?.FromZipcode,
          ToState: a?.ToState,
          ToZipcode: a?.ToZipcode,
          ToAddress: a?.ToAddress,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function CalcTotal() {
    var sum = 0;
    $(`.RatesTotal-${type}`).each(function() {
      var a = Number(
        $(this)
          .find("input")
          .val()
      );
      $(this)
        .find("input")
        .val(a.toFixed(2));
      sum += a;
    });
    $(`.RateAllTotal-${type} span`).text(sum.toFixed(2));
  }

  useEffect(() => {
    let a = [];
    Charges.map((x) =>
      a.push({
        ChargeId: x?.ChargeId || x?.Id,
        Code: x?.Code,
        Name: x?.Name,
        Quantity: x?.Quantity || 0,
        Rate: x?.Rate,
      })
    );
    window.$payablesRates = JSON.stringify({
      Ids: ShipmentsId,
      VendorId: vendorId,
      Payables: a,
    });
    CalcTotal();
  }, [Charges, ChargesN]);

  return (
    <>
      {vendorId && (
        <div className="row RatesParent">
          <Modal
            show={ChargeModel}
            style={{ zIndex: "1501" }}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Vendors Charges
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="row">
              {ChargesN &&
                ChargesN.length > 0 &&
                ChargesN.map((x) => (
                  <>
                    <div className={`col-lg-4 mt-5 mb-5`}>
                      <b className={`text-black title mr-2`}>{x?.Name}</b>
                    </div>
                    <div className={`col-lg-4 mt-2 `}>
                      <input
                        className="form-control"
                        disabled={true}
                        defaultValue={x?.Rate}
                      />
                    </div>
                    <div className={`col-lg-4 mt-2`}>
                      <button
                        type="button"
                        class="btn btn-light-success w-100"
                        onClick={(e) => {
                          let a = JSON.parse(JSON.stringify(Charges)) || [];
                          var c = ChargesN?.filter((y) => y?.Id !== x?.Id);
                          var b = ChargesN?.find((y) => y?.Id === x?.Id);
                          if (b) {
                            a.push({
                              ChargeId: b?.Id,
                              Rate: b?.Rate,
                              Name: b?.Name,
                              Code: b?.Code,
                              Quantity: 0,
                              RateType: b?.RateType,
                              MinCharge: b?.MinCharge,
                              MaxCharge: b?.MaxCharge,
                              FreeTime: b?.FreeTime,
                            });
                          }
                          setCharges([]);
                          setChargesN([]);
                          setTimeout(() => {
                            setCharges(a);
                            if (type === "Charges") window.C_Charges2 = a;
                            if (type === "Payables") window.C_Payables2 = a;
                            setChargesN(c || []);
                            if (c.length === 0) setChargeModel(false);
                          }, 1);
                        }}
                      >
                        <i className={`fas fa-plus fa-sm`}></i>
                      </button>
                    </div>
                  </>
                ))}
            </Modal.Body>
            <Modal.Footer>
              <div>
                <button
                  type="button"
                  onClick={() => setChargeModel(false)}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
              </div>
            </Modal.Footer>
          </Modal>

          {loadingCharges && (
            <div className="col-lg-12">
              <LinearProgress />
            </div>
          )}

          <div className="col-md-12 pr-0">
            <div className="table-responsive">
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th className="bg-white">Code</th>
                    <th className="bg-white">Name</th>
                    <th className="bg-white">Rate</th>
                    <th className="bg-white">Quantity</th>
                    <th className="bg-white">Total</th>
                    <th className="bg-white">Remove</th>
                  </tr>
                </thead>
                {Charges?.map((x) => (
                  <>
                    <tbody className="border-0">
                      <tr>
                        <td className="border-0">
                          <b
                            className={`${
                              x?.Calc ? "" : "text-danger"
                            } title mr-2`}
                          >
                            {x?.Code}
                          </b>
                        </td>
                        <td className="border-0">{x?.Name}</td>
                        <td className="border-0 Rate">
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={x?.Rate}
                          />
                        </td>
                        <td className="border-0">
                          <input
                            type="number"
                            className={`form-control ${(x?.Quantity || 0) ===
                              0 && "border border-danger"}`}
                            disabled={window.C_ChargesReadOnly}
                            min="1"
                            onBlur={(e) => {
                              var b = JSON.parse(JSON.stringify(Charges));
                              var a = b.findIndex(
                                (y) => y.ChargeId === x?.ChargeId
                              );
                              if (a > -1) {
                                b[a].Quantity = +e.target.value;
                                setCharges(b);
                              }
                              const c = CalculateCharge({
                                Rate: x?.Rate,
                                Quantity: e.target.value,
                                rateType: x?.RateType,
                                MinCharge: x?.MinCharge,
                                MaxCharge: x?.MaxCharge,
                                FreeTime: x?.FreeTime,
                              });
                              $(`.RatesTotal${x?.ChargeId} input`).val(
                                c.toFixed(2)
                              );
                            }}
                            defaultValue={x?.Quantity || 0}
                          />
                        </td>
                        <td
                          className={`border-0 RatesTotal-${type} RatesTotal${x?.ChargeId} `}
                        >
                          <input
                            className="form-control"
                            disabled={true}
                            defaultValue={CalculateCharge({
                              Rate: x?.Rate,
                              Quantity: x?.Quantity,
                              rateType: x?.RateType,
                              MinCharge: x?.MinCharge,
                              MaxCharge: x?.MaxCharge,
                              FreeTime: x?.FreeTime,
                            })}
                          />
                        </td>
                        <td className="border-0">
                          <button
                            type="button"
                            class="btn btn-light-danger w-100"
                            disabled={window.C_ChargesReadOnly}
                            onClick={(e) => {
                              let a = Charges || [];
                              let b = ChargesN || [];
                              a = a?.filter((y) => y?.ChargeId !== x?.ChargeId);

                              const isCharg = b?.find(
                                (y) => y?.ChargeId === x?.ChargeId
                              );
                              if (isCharg === undefined)
                                b.push({
                                  Id: x?.ChargeId,
                                  Rate: x?.Rate,
                                  Name: x?.Name,
                                  Code: x?.Code,
                                  Quantity: 1,
                                  RateType: x?.RateType,
                                  MinCharge: x?.MinCharge,
                                  MaxCharge: x?.MaxCharge,
                                  FreeTime: x?.FreeTime,
                                });

                              setCharges([]);
                              setChargesN([]);
                              setTimeout(() => {
                                setCharges(a);
                                setChargesN(b);
                              }, 1);
                            }}
                          >
                            <i className={`fas fa-times fa-sm`}></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className="row mb-0 border-0 place-content-center py-0">
                          {(x?.FreeTime || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-primary text-center btn-sm p-2"
                            >
                              FT : {ReturnEmptyOrString(x?.FreeTime)}
                            </button>
                          )}
                          {(x?.MinCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-info text-center mx-2 btn-sm p-2"
                            >
                              Min : {ReturnEmptyOrString(x?.MinCharge)}
                            </button>
                          )}
                          {(x?.MaxCharge || null) !== null && (
                            <button
                              type="button"
                              className="btn btn-warning text-center btn-sm p-2"
                            >
                              Max : {ReturnEmptyOrString(x?.MaxCharge)}
                            </button>
                          )}
                        </td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                        <td className={`border-0`}></td>
                      </tr>
                    </tbody>
                  </>
                ))}
                <tbody className="border-0">
                  <tr>
                    <td colSpan="3" className="border-0"></td>
                    <td className="text-right border-0">
                      <b>Total :</b>
                    </td>
                    <td className={`border-0 RateAllTotal-${type}`}>
                      <span className="border-1 p-4"></span>
                    </td>
                    <td className="border-0"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-8 text-black p-5 font-weight-bold">
            <button
              type="button"
              class="btn btn-light-success p-2"
              onClick={(e) => {
                setChargeModel(true);
              }}
              disabled={ChargesN?.length === 0 || window.C_ChargesReadOnly}
            >
              <i className={`fas fa-plus fa-sm`}></i> Add Charge
            </button>
            <span className="mx-4">
              {" "}
              <i class="fas fa-tint text-danger"></i> Manual
            </span>
            <span>
              {" "}
              <i class="fas fa-tint text-dark"></i> Auto
            </span>
          </div>
          <div className="col-lg-2 mt-5 pt-3 text-dark">
            <span className="AreChargesReadOnly d-none">
              <b>The receipt rates have been approved</b>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

async function GetAttachements() {
  return await fetch(
    `${window.$apiurl}/transloadshipments/payables/7839?vendorId=54491`,
    {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}

function CalculateCharge({
  Rate,
  Quantity,
  rateType,
  MinCharge,
  MaxCharge,
  FreeTime,
}) {
  var amount = 0;
  Rate = Rate ?? 0;
  Quantity = Quantity ?? 0;
  MinCharge = MinCharge ?? 0;
  MaxCharge = MaxCharge ?? 0;
  FreeTime = FreeTime ?? 0;
  switch (rateType) {
    case "Days":
    case "Hours":
      if (FreeTime > 0)
        amount = Quantity < FreeTime ? 0 : (Quantity - FreeTime) * Rate;
      else amount = Rate * Quantity;
      break;
    case "Weight":
      amount = Rate * Quantity;
      break;
    case "Volume":
      amount = Rate * Quantity;
      break;
    case "Quantity":
      amount = Rate * Quantity;
      break;
    case "Distance":
      amount = Rate * Quantity;
      break;
    case "Revenue":
      amount = Rate * Quantity;
      break;
    case "Cost":
      amount = Rate * Quantity;
      break;
    case " Dsp Time":
      amount = Rate * Quantity;
      break;
    default:
      amount = Rate * Quantity;
      break;
  }
  if (MinCharge > 0 && amount < MinCharge) amount = MinCharge;
  if (MaxCharge > 0 && amount > MaxCharge) amount = MaxCharge;
  /* if (amount < 0) amount = 0; */
  return amount;
}
