import MockUtils from "./mock.utils";
import { Notification } from "../../../modules/Notification";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

let customerTableMock;
export default async function mockFindShipments(mock) {
  function StatusMessage(res) {
    var status = res.status;
    var statusText = res.Message;
    if (status === 200) {
      Notification("success", "success");
      return true;
    } else if (res.Message) {
      Notification("error", statusText);
      return false;
    }
  }

  async function GetModel(CustomerId, ShipmentTypeId, StatusId) {
    if (CustomerId == undefined) {
      CustomerId = "";
    }
    if (ShipmentTypeId == undefined) {
      ShipmentTypeId = "";
    }
    if (StatusId == undefined) {
      StatusId = "";
    }
    return await fetch(
      `${window.$apiurl}/transportation/shipments?customerId=${CustomerId}&shipmentTypeId=${ShipmentTypeId}&statusId=${StatusId}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]["Shipments"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetModelId(id) {
    return await fetch(`${window.$apiurl}/transportation/shipments/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        res[1].id = res[1].Id;
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function PostModel(someData) {
    return fetch(window.$apiurl + "/transportation/shipments", {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        if (response.status === 400) {
          return response.json();
        } else {
          return await response;
        }
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function PutModel(someData, id) {
    let modelData = someData["product"];
    return fetch(window.$apiurl + "/transportation/shipments/" + id, {
      method: "put",
      withCredentials: true,

      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
      },
      body: JSON.stringify(modelData),
    })
      .then(async (response) => {
        if (response.status === 400) {
          return response.json();
        } else {
          return await response;
        }
      })
      .then((res) => {
        console.log("res");
        console.log(res);
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function DeleteModel(id) {
    return await fetch(window.$apiurl + "/FindShipments/" + id, {
      method: "delete",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        return response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  async function GetViewmodel() {
    return await fetch(
      window.$apiurl + "/transportation/shipments/getviewmodel",
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetShipmentsStatus(id) {
    return await fetch(
      window.$apiurl + "/transportation/shipments/getbystatus?customerId=" + id,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function GetShipmentDetail(id) {
    return await fetch(
      window.$apiurl + "/transportation/shipments/status?id=" + id,
      {
        method: "get",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return res;
      })
      .catch((err) => {
        Notification("error", "err");
        return false;
      });
  }

  async function PostStartTracking(id) {
    return await fetch(
      window.$apiurl + "/transportation/shipments/enabletracking/" + id,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return false;
      });
  }

  async function UpdateStatus(data) {
    return fetch(window.$apiurl + "/transportation/shipments/update", {
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        return await response;
      })
      .then((res) => {
        return StatusMessage(res);
      })
      .catch((err) => {
        return StatusMessage(err);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  mock.onGet("api/FindShipments/GetAllApi").reply(async () => {
    const obj = await GetViewmodel();
    return [200, obj];
  });

  mock.onPost("api/FindShipments/ShipmentDetail").reply(async (data) => {
    var id = JSON.parse(data.data).data;
    const obj = await GetShipmentDetail(id);
    return [200, obj];
  });

  mock.onPost("api/FindShipments/StartTracking").reply(async (data) => {
    var id = JSON.parse(data.data).data;
    const obj = await PostStartTracking(id);
    return [200];
  });

  mock.onPost("api/FindShipments/UpdateStatus").reply(async (data) => {
    var data = JSON.parse(data.data).data;
    const obj = await UpdateStatus(data);
    return [200, obj];
  });

  mock.onPost("api/FindShipments/GetShipmentsStatus").reply(async (data) => {
    var id = JSON.parse(data.data).data;
    const obj = await GetShipmentsStatus(id);
    return [200, obj];
  });

  mock.onPost("api/FindShipments").reply(async ({ data }) => {
    const { product } = JSON.parse(data);
    var postModel = await PostModel(product);
    document.getElementById("ReLoadTableId").click();
    customerTableMock.push(product);
    return [200, { customer: product }];
  });

  mock.onPost("api/FindShipments/find").reply(async (config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filter = queryParams.filter;
    customerTableMock = await GetModel(
      filter.CustomerId,
      filter.ShipmentTypeId,
      filter.StatusId
    );
    if (filter.id) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.id).includes(String(filter.id))
      );
    }
    if (filter.ShipmentTypeId) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.ShipmentTypeId).includes(String(filter.ShipmentTypeId))
      );
    }
    if (filter.StatusId) {
      customerTableMock = customerTableMock.filter((word) =>
        String(word.StatusId).includes(String(filter.StatusId))
      );
    }
    delete queryParams.filter.CustomerId;
    delete queryParams.filter.ShipmentTypeId;
    delete queryParams.filter.StatusId;
    delete queryParams.filter.ReLoadTable;
    const filterdCustomers = mockUtils.baseFilter(
      customerTableMock,
      queryParams
    );
    return [200, filterdCustomers];
  });

  mock.onPost("api/FindShipments/deleteCustomers").reply(async (config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = customerTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        customerTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock
    .onPost("api/FindShipments/updateStatusForCustomers")
    .reply(async (config) => {
      const { ids, status } = JSON.parse(config.data);
      customerTableMock.forEach((el) => {
        if (ids.findIndex((id) => id === el.id) > -1) {
          el.status = status;
        }
      });
      return [200];
    });

  mock.onGet(/api\/FindShipments\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindShipments\/(\d+)/)[1];
    const customer = await GetModelId(id);
    if (!customer) {
      return [400];
    }
    return [200, customer];
  });

  mock.onPut(/api\/FindShipments\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindShipments\/(\d+)/)[1];
    const Putcustomer = JSON.parse(config.data);
    const { customer } = Putcustomer;
    var putObj = await PutModel(Putcustomer, id);
    if (!putObj) {
      return [400];
    }
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    customerTableMock[index] = { ...customer };
    return [200];
  });

  mock.onDelete(/api\/FindShipments\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/FindShipments\/(\d+)/)[1];
    const index = customerTableMock.findIndex((el) => el.id === +id);
    if (index === -1) {
      return [400];
    }
    var deleteObj = await DeleteModel(id);
    if (!deleteObj) {
      return [400];
    }
    customerTableMock.splice(index, 1);
    return [200];
  });
}

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
