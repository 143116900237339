// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";

export function StatusColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["success", "danger"];
  const CustomerStatusTitles = ["Active", "Inactive"];

  var isActive = row.IsActive === true ? 0 : 1;

  const getLabelCssClasses = () => {
    return `label label-lg label-light-${CustomerStatusCssClasses[isActive]} label-inline`;
  };
  return (
    <span className={getLabelCssClasses()}>
      {CustomerStatusTitles[isActive]}
    </span>
  );
}
