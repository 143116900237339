import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
}))(TableRow);

function createData(id, num, message) {
    return { id, num, message };
}

const rows = [
    createData(),
];

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
}));

export function ClassicBOLMessages() {
    const classes = useStyles();

    return (

        <Paper className={classes.root}>
            <div className="col-lg-3 my-5">
                <select
                    className="form-control"
                    name="status"
                    placeholder="Filter by Status"
                // TODO: Change this code
                >
                    <option value="">Barkman Honey</option>
                    <option value="0">BASF</option>
                    <option value="1">DDP Specialty Electronic</option>
                </select>
                <small className="form-text text-muted">
                    <b>Choose</b> a Customer
            </small>
            </div>
            <Table className={`mx-4 col-md-8 mb-5 ${classes.table}`} >
                <TableHead>
                    <TableRow>
                        <StyledTableCell >Id</StyledTableCell>
                        <StyledTableCell >Num</StyledTableCell>
                        <StyledTableCell >Message</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell >{row.id}</StyledTableCell>
                            <StyledTableCell >{row.num}</StyledTableCell>
                            <StyledTableCell >{row.message}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            <div className="container  mb-5">
                <Button className="bg-primary text-white">
                    ADD
                </Button>
                <Button className="bg-primary text-white ml-5">
                    EDIT
                </Button>
                <Button className="bg-primary text-white ml-5">
                    DELETE
                </Button>
            </div>
        </Paper>
    );
}