import React, { useEffect, useState } from 'react';
import { Button, Form, Card } from "react-bootstrap";
import axios from "axios";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
    Input,
    Select
} from "../../../_metronic/_partials/controls";
import { Notification } from "../../modules/Notification";
import LinearProgress from "@material-ui/core/LinearProgress";

export function ManageCompanyInfo() {
    const initalizeCompany = {
        Address1: "",
        Address2: "",
        AutomaticallySetArrival: false,
        CheckAllocation: false,
        City: "",
        CompanyName: "",
        Country: "",
        DbNumber: "",
        Email: "",
        Fax: "",
        FederalId: "",
        PhoneNumber: "",
        PrimaryContactCellPhone: "",
        PrimaryContactEmail: "",
        PrimaryContactFax: "",
        PrimaryContactName: "",
        PrimaryContactPhoneNumber: "",
        PrimaryContactTitle: "",
        SecondaryContactCellPhone: "",
        SecondaryContactEmail: "",
        SecondaryContactFax: "",
        SecondaryContactName: "",
        SecondaryContactPhoneNumber: "",
        SecondaryContactTitle: "",
        State: "",
        UpsLogin: "",
        UpsPassword: "",
        WebsiteUrl: "",
        ZipCode: "",
    }
    const [companyInfos, setCompanyInfos] = useState(initalizeCompany);
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [showProgress, setshowProgress] = useState(false);
    const [showGeneralProgress, setshowGeneralProgress] = useState(true);
    const companySchema = Yup.object().shape({
        CompanyName: Yup.string()
            .required("Company name is required"),
        PrimaryContactName: Yup.string()
            .required("Primary contact name is required"),
        PrimaryContactEmail: Yup.string()
            .required("Primary contact email is required")
    });

    useEffect(() => {
        fetchDataAsync();
    }, []);

    const fetchDataAsync = async () => {
        try {
            const result = await axios.get("api/companyinfo");
            const resultCountries = await axios.get("api/companyinfo/getcountries");
            if (result.status === 200) {
                const resultStates = await axios.get(`api/companyinfo/getstates/${result.data.Country}`);
                if (resultStates.status === 200) {
                    if (resultStates.data.length > 0)
                        setStates(resultStates.data);
                }
                setCompanyInfos(result.data);
                document.getElementById("State").value = result.data.State;
                document.getElementById("CheckAllocation").checked = result.data.CheckAllocation;
                document.getElementById("AutomaticallySetArrival").checked = result.data.AutomaticallySetArrival;
                setshowGeneralProgress(false);
            }
            if (resultCountries.status === 200) {
                setCountries(resultCountries.data);
            }
        } catch (e) { }
    };

    const handleCountryChange = async (event) => {
        setshowProgress(true);
        setStates([]);
        if (event.target.value !== "" && event.target.vale !== null) {
            const result = await axios.get(`api/companyinfo/getstates/${event.target.value}`);
            if (result.status === 200) {
                if (result.data.length > 0)
                    setStates(result.data);
                else
                    Notification("warning", "This Country doesn't have any state.");
            }
        }
        setshowProgress(false);
    }


    const saveCompany = async (company) => {
        const result = await axios.put(`api/companyinfo`, { company });
        if (result.data.success === true) {
            Notification("success", "Company infos changed successfully.");
        }
        else {
            const msg = result.data.Message === undefined ? "Something went wrong, please try later or contact the support." : result.data.Message;
            Notification("error", msg);
        }
    };
    return (
        <Card>
            <Card.Body>
                <Formik
                    initialValues={companyInfos}
                    validationSchema={companySchema}
                    enableReinitialize={true}
                    onSubmit={(values) => {
                        saveCompany(values);
                    }}>
                    {({ handleSubmit, setFieldValue }) => (
                        <>
                            <Form>
                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">COMPANY NAME AND BRANDING IMAGE</h3>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>
                                    <div className='col-12 mb-5'>
                                        <div className='col-4'>
                                            <Form.Label>*COMPANY NAME</Form.Label>
                                            <Field component={Input} type="text" name="CompanyName" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                        </div>
                                        {/* <div className='col-4 mt-5'>
                                            <Button as="input" type="submit" onChange={() => {}} value="Branding" />
                                        </div> 
                                        <div className='col-12 mt-5'>
                                            <p>You may brand your 3PL Manager with your company name or by creating a unique name / logo for the software. When your customers log on to view inventory they will see your logo / brand.</p>
                                        </div>*/}
                                    </div>
                                </Form.Row>

                                <br /><br />
                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">COMPANY ADDRESS</h3>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>

                                    <div className='col-4 mb-5'>
                                        <Form.Label>ADDRESS </Form.Label>
                                        <Field component={Input} type="text" name="Address1" placeholder="" />
                                        {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>

                                    <div className='col-4'>
                                        <Form.Label>ADDRESS2</Form.Label>
                                        <Field component={Input} type="text" name="Address2" placeholder="" />
                                        {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>

                                    <div className='col-4'>
                                        <Form.Label>CITY</Form.Label>
                                        <Field component={Input} type="text" name="City" placeholder="" />
                                        {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-4 mb-5'>
                                        <Form.Label>COUNTRY</Form.Label>
                                        <Select className="form-control" name="Country" id="Country" onChange={(event) => {
                                            handleCountryChange(event);
                                            setFieldValue("Country", event.target.value)
                                        }}>
                                            <option key="" value="" >Choose a country</option>
                                            {countries.map((item, index) => (
                                                <option key={index} value={item.Iso}>{item.Name}</option>
                                            ))}
                                        </Select>
                                        {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-4'>
                                        <Form.Label>ST</Form.Label>
                                        <select className="form-control" name="State" id="State" onChange={(event) => {
                                            setFieldValue("State", event.target.value)
                                        }}>
                                            <option key="" value="" >Choose a state</option>
                                            {states.map((item, index) => (
                                                <option key={index} value={item.Name}>{item.Name}</option>
                                            ))}
                                        </select>
                                        {showProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-4'>
                                        <Form.Label>ZIP</Form.Label>
                                        <Field component={Input} type="text" name="ZipCode" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                </Form.Row>

                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">OTHER COMPANY INFO</h3>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>TELEPHONE</Form.Label>
                                        <Field component={Input} type="text" name="PhoneNumber" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>FEDERAL ID#</Form.Label>
                                        <Field component={Input} type="text" name="FederalId" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>WEBSITE URL</Form.Label>
                                        <Field component={Input} type="text" name="WebsiteUrl" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>FAX</Form.Label>
                                        <Field component={Input} type="text" name="Fax" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>D&B #</Form.Label>
                                        <Field component={Input} type="text" name="DbNumber" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>"FROM" EMAIL</Form.Label>
                                        <Field component={Input} type="text" name="Email" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>UPS LOGIN</Form.Label>
                                        <Field component={Input} type="text" name="UpsLogin" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                    <div className='col-3 mb-5'>
                                        <Form.Label>UPS PASSWORD</Form.Label>
                                        <Field component={Input} type="text" name="UpsPassword" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                    </div>
                                </Form.Row>

                                <br /><br />
                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">PRIMARY CONTACT</h3>
                                        <Form.Row>
                                            <div className='col-6 mb-5 mt-5'>
                                                <Form.Label>*NAME</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactName" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5 mt-5'>
                                                <Form.Label>PHONE</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactPhoneNumber" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>CELL PHONE</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactCellPhone" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>*EMAIL</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactEmail" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>TITLE</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactTitle" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>FAX</Form.Label>
                                                <Field component={Input} type="text" name="PrimaryContactFax" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                        </Form.Row>
                                    </div>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">SECONDARY CONTACT</h3>
                                        <Form.Row>
                                            <div className='col-6 mb-5 mt-5'>
                                                <Form.Label>NAME</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactName" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5 mt-5'>
                                                <Form.Label>PHONE</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactPhoneNumber" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>CELL PHONE</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactCellPhone" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>EMAIL</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactEmail" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>TITLE</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactTitle" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary" />}
                                            </div>
                                            <div className='col-6 mb-5'>
                                                <Form.Label>FAX</Form.Label>
                                                <Field component={Input} type="text" name="SecondaryContactFax" placeholder="" />
                                            {showGeneralProgress && <LinearProgress color="secondary"/>}
                                            </div>
                                        </Form.Row>
                                    </div>
                                </Form.Row>
                                <br /><br />
                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">ITEM ALIAS SETTINGS</h3>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>
                                    <div className='col-12 mb-5'>
                                        <div className="d-flex align-items-center">
                                            <span className="bg-success align-self-stretch"></span>
                                            <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                                <input type="checkbox" name="CheckAllocation" id="CheckAllocation" onChange={(event) => { setFieldValue("CheckAllocation", event.target.checked); }} value="1" />
                                                <span></span>
                                            </label>
                                            <div className="d-flex flex-column flex-grow-1">
                                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Check allocation on order import</p>
                                            </div>
                                        </div>
                                    </div>
                                </Form.Row>

                                <br /><br />
                                <Form.Row>
                                    <div className='col-12 mb-5'>
                                        <h3 className="font-weight-bold text-primary">RECEIVING SETTINGS</h3>
                                        <hr className="font-weight-bold text-primary"></hr>
                                    </div>
                                    <div className='col-12'>
                                        <div className="d-flex align-items-center">
                                            <span className="bg-success align-self-stretch"></span>
                                            <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                                                <input type="checkbox" name="AutomaticallySetArrival" id="AutomaticallySetArrival" onChange={(event) => { setFieldValue("AutomaticallySetArrival", event.target.checked); }} value="1" />
                                                <span></span>
                                            </label>
                                            <div className="d-flex flex-column flex-grow-1">
                                                <p className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1">Automatically set Arrival date to current date upon confirmation</p>
                                            </div>
                                        </div>
                                        <p className='mt-5'>Please note that activating this option will remove your ability to backdate receiving transactions (except Level 0 and Admin users)</p>
                                    </div>
                                </Form.Row>

                                <hr className="font-weight-bold text-primary"></hr>
                                <br /><br />

                                <div className='col-12 mt-5'>
                                    <Button as="input" type="submit" onClick={() => handleSubmit()} className="btn-success" readOnly value="Save" />
                                </div>
                            </Form>
                        </>)}
                </Formik>
            </Card.Body>
        </Card>
    )
}
