import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default async function mockPurchaseOrders(mock) {
  // async function GetClassification(Id) {
  //   return await fetch(`${window.$apiurl}/classifications/${Id}`)
  //     .then(async (response) => {
  //       const statusCode = await response.status;
  //       const data = await response.json();
  //       return Promise.all([statusCode, data]);
  //     })
  //     .then((res) => {
  //       return res[1];
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }

  // async function GetAllClassifications() {
  //   return await fetch(`${window.$apiurl}/classifications/all`, {
  //     method: "get",
  //     headers: {
  //       Accept: "application/json, text/plain, */*",
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch((err) => {
  //       Notification("error", "err");
  //       return false;
  //     });
  // }

  // async function GetAllClassifications() {
  //   return await fetch(`${window.$apiurl}/classifications/all`)
  //     .then(async (response) => {
  //       const statusCode = await response.status;
  //       const data = await response.json();
  //       return Promise.all([statusCode, data]);
  //     })
  //     .then((res) => {
  //       return ChangeId(res[1]);
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // }
  async function GetCustomers() {
    return await fetch(`${window.$apiurl}/customers`)
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetPurchaseOrders() {
    return await fetch(`${window.$apiurl}/purchaseorders`, {
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return ChangeId(res[1]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetPurchaseOrder(Id) {
    return await fetch(`${window.$apiurl}/purchaseorders/${Id}`, {
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function ClosePurchaseOrder(Id) {
    return await fetch(`${window.$apiurl}/purchaseorders/${Id}`, {
      method: "DELETE",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        return statusCode;
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function ImportPurchaseOrders(someData) {
    return fetch(`${window.$apiurl}/purchaseorders/import`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        let data = {};
        if (statusCode === 400) data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PostPurchaseOrder(someData) {
    return fetch(`${window.$apiurl}/purchaseorders`, {
      method: "post",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(someData),
    })
      .then(async (response) => {
        const statusCode = response.status;
        let data = {};
        //if (statusCode === 400)
        data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function PutPurchaseOrder(someData, id) {
    let purchaseorder = someData["purchaseOrder"];
    return fetch(`${window.$apiurl}/purchaseorders/${id}`, {
      method: "put",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(purchaseorder),
    })
      .then(async (response) => {
        const statusCode = response.status;
        let data = {};
        if (statusCode === 400) data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  async function GetSuppliers(id) {
    return await fetch(`${window.$apiurl}/suppliers/${id}`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function GetSupplier(Id) {
    return await fetch(`${window.$apiurl}/suppliers/${Id}/details`, {
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // Mock ---------------------------------------------------------------------------------
  // mock.onGet("api/classifications").reply(async () => {
  //   const obj = {};
  //   obj["AllClassifications"] = await GetAllClassifications();
  //   //obj["Classifications"] = await GetClassifications();
  //   return [200, obj];
  // });

  mock.onGet("api/purchaseorders/getcustomers").reply(async () => {
    const obj = await GetCustomers();
    return [200, obj];
  });

  mock.onGet("api/purchaseorders").reply(async () => {
    const obj = await GetPurchaseOrders();
    return [200, obj];
  });

  mock.onGet(/api\/purchaseorders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/purchaseorders\/(\d+)/)[1];
    const obj = await GetPurchaseOrder(id);
    if (!obj) {
      return [400];
    }
    return [200, obj];
  });

  mock.onPost("api/purchaseorders/import").reply(async ({ data }) => {
    const purchaseOrders = JSON.parse(data);
    var result = await ImportPurchaseOrders(purchaseOrders);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });

  mock.onPost(`api/purchaseorders`).reply(async (config) => {
    const { purchaseOrder } = JSON.parse(config.data);
    var result = await PostPurchaseOrder(purchaseOrder);
    if (result === undefined || result[0] === 200)
      return [200, { success: true, data: result[1] }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });

  mock.onDelete(/api\/purchaseorders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/purchaseorders\/(\d+)/)[1];
    const obj = await ClosePurchaseOrder(id);
    if (!obj || obj !== 200) {
      return [400];
    }
    return [200];
  });

  mock.onPut(/api\/purchaseorders\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/purchaseorders\/(\d+)/)[1];
    const purhcaseorder = JSON.parse(config.data);
    var result = await PutPurchaseOrder(purhcaseorder, id);
    if (result === undefined || result[0] === 200)
      return [200, { success: true }];
    else {
      result[1].success = false;
      return [200, result[1]];
    }
  });

  mock.onGet(/api\/purchaseorders\/suppliers\/\d+/).reply(async (config) => {
    const id = config.url.match(/api\/purchaseorders\/suppliers\/(\d+)/)[1];
    var getSuppliers = await GetSuppliers(id);
    if (!getSuppliers) return [400];
    return [200, getSuppliers];
  });

  mock
    .onGet(/api\/purchaseorders\/suppliers\/details\/\d+/)
    .reply(async (config) => {
      const id = config.url.match(
        /api\/purchaseorders\/suppliers\/details\/(\d+)/
      )[1];
      var getSupplier = await GetSupplier(id);
      if (!getSupplier) return [400];
      return [200, getSupplier];
    });
}

// mock.onGet(/api\/classifications\/\d+/).reply(async (config) => {
//   const id = config.url.match(/api\/classifications\/(\d+)/)[1];

//   var getClassification = await GetClassification(id);
//   if (!getClassification) {
//     return [400];
//   }
//   return [200, getClassification];
// });

function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}
