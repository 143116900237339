import React from 'react'
import { Button, Form, Card, Dropdown, Alert } from "react-bootstrap";

export function ImportAssembliesItems() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <Form.Row>

                        <div class=' col-6'>
                            <Form.Label>Choose a Customer	</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        <div class='col-6'>
                            <Form.Label>Warehouse</Form.Label>
                            <Dropdown className="form-control p-0">
                                <Dropdown.Toggle variant="none" id="dropdown-basic" className="w-100 text-left">
                                    Select
                            </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>




                    </Form.Row>

                    <br /><br />
                    <Form.Row>
                        <div class='col-12 mb-5'>
                            <h3 className="font-weight-bold text-primary">IMPORT FILE</h3>
                            <hr class="font-weight-bold text-primary"></hr>
                        </div>

                        <div class='col-12 mb-5'>
                            <Alert variant="info " className="w-50">
                                Use the 'Browse...' button to select an assembly import file.<p></p>
                                Files must contain no more than 2500 Serial Numbers.
                            </Alert>
                        </div>

                        <div class='col-12 mb-5'>
                            <Form.Label>Assembly File </Form.Label>
                            <Form.Control type="file" placeholder="Password" />
                        </div>


                        <div class='col-12 mb-5'>
                            <Alert variant="info " className="w-50">
                                Then press the 'Import' button to read the file and create assemblies.
                            </Alert>
                        </div>

                        <div class='col-12 mt-5 row'>
                            <div class='col-3'><Button as="input" type="submit" value="Import" /> </div>
                            <div class='col-3'><Button as="input" type="submit" value="Close" /> </div>
                        </div>
                    </Form.Row>


                </Form>
            </Card.Body>
        </Card>
    )
}
