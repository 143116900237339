// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as Yup from "yup";
import {
  Input,
  Select,
  DatePickerField,
} from "../../../../../../_metronic/_partials/controls";
import SelectPagination from "../../../../../../_metronic/_partials/widgets/Select/SelectPagination";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Notification } from "../../../../Notification";

const adapter = new LocalStorage("db");
const db = low(adapter);
// Validation schema
const CustomerEditSchema = Yup.object().shape();

export function CustomerEditForm({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
}) {
  const [ReceiptsIds, setReceiptsIds] = useState([]);
  const [Category, setCategory] = useState([]);
  const [AccessLevel, setAccessLevel] = useState([]);

  const [Selected, setSelected] = useState([]);
  const [Titles, setTitles] = useState([
    { Title: "Ship OTR" },
    { Title: "Closed area at the port" },
    { Title: "Restricted empty returns / Chassis shortage" },
    { Title: "Terminal Hold" },
    { Title: "No 856" },
    { Title: "Peel pile" },
  ]);

  useEffect(() => {
    GetReceiptsIds(setReceiptsIds);
    GetViewModel(setCategory, setAccessLevel);
  }, []);

  useEffect(() => {
    //if (!customer?.id) GetAllTitles(setTitles);
  }, [customer?.id]);

  useEffect(() => {
    customer = JSON.parse(JSON.stringify(customer));
    var a = [];
    (customer?.Containers ?? []).forEach((x) => {
      var r = ReceiptsIds.find((y) => y.Id === x);
      a.push({
        value: x,
        label: r?.Id + " - " + r?.EquipmentRef,
      });
    });
    setSelected(a);
  }, [customer, ReceiptsIds]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={customer}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          var a = [];
          (Selected ?? []).forEach((x) => {
            a.push(x.value);
          });
          values.ContainersIds = a;

          if (!values?.Title) Notification("error", "Title is Empty");
          if (!values?.Body) Notification("error", "Body is Empty");
          if (!values?.CategoryId)
            Notification("error", "Please Select Category");
          if (!values?.AccessLevelId)
            Notification("error", "Please Select Access Level");
          if (a?.length === 0)
            Notification("error", "Please Select Containers Ids");

          if (
            a?.length === 0 ||
            !values?.Title ||
            !values?.Body ||
            !values?.CategoryId ||
            !values?.AccessLevelId
          )
            return false;
          saveCustomer(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Modal.Body className="overla">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* First Name */}
                  <div className="col-lg-12 mt-5" style={{ zIndex: 9999 }}>
                    {values?.id ? (
                      <Field
                        name="Title"
                        component={Input}
                        placeholder="Title"
                        label="Title"
                      />
                    ) : Titles?.length > 0 ? (
                      <SelectPagination
                        name="P1"
                        placeholder="Select Title"
                        searchBy="Create Title : "
                        onChange={(newValue) => {
                          if (newValue?.value)
                            setFieldValue("Title", newValue?.value);
                        }}
                        options={Titles.filter(
                          (v, i, a) =>
                            a.findIndex((t) => t.Title === v.Title) === i
                        ).map((x) => {
                          return {
                            value: x?.Title,
                            label: x?.Title,
                          };
                        })}
                      />
                    ) : (
                      <input
                        className="form-control"
                        disabled={true}
                        placeholder="Select Title"
                      />
                    )}
                  </div>
                  <div className="col-lg-12 mt-5">
                    <label>Body</label>
                    <CKEditor
                      name="Body"
                      Id="TeaserText"
                      label="TeaserText"
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          "Image",
                          "ImageCaption",
                          "ImageStyle",
                          "ImageToolbar",
                          "ImageUpload",
                          "MediaEmbed",
                        ],
                      }}
                      data={values?.Body ?? ""}
                      onBlur={(event, editor) => {
                        setFieldValue("Body", editor.getData());
                      }}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  {/* Select Category */}
                  <div className="col-lg-6">
                    <label>Select Category</label>
                    <SelectPagination
                      className="w-100"
                      name="CategoryId"
                      options={Category.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      value={{
                        value: values?.CategoryId,
                        label: Category.find((x) => x.Id === values?.CategoryId)
                          ?.Name,
                      }}
                      onChange={(newValue) => {
                        setFieldValue("CategoryId", newValue?.value);
                      }}
                    />
                  </div>
                  {/* Access Level */}
                  <div className="col-lg-6">
                    <label>Access Level</label>
                    <SelectPagination
                      className="w-100"
                      name="AccessLevelId"
                      options={AccessLevel.map((x) => {
                        return {
                          value: x?.Id,
                          label: x?.Name,
                        };
                      })}
                      value={{
                        value: values?.AccessLevelId,
                        label: AccessLevel.find(
                          (x) => x.Id === values?.AccessLevelId
                        )?.Name,
                      }}
                      onChange={(newValue) => {
                        setFieldValue("AccessLevelId", newValue?.value);
                      }}
                    />
                  </div>

                  <div className="col-lg-12 mt-5">
                    <label>Containers Ids</label>
                    {ReceiptsIds?.length > 0 && (
                      <SelectPagination
                        className="w-100"
                        name="P1"
                        isMulti={true}
                        options={ReceiptsIds.map((x) => {
                          return {
                            value: x?.Id,
                            label: x?.Id + " - " + x?.EquipmentRef,
                          };
                        })}
                        value={(Selected ?? []).map((x) => {
                          return {
                            value: x?.value,
                            label: x?.label,
                          };
                        })}
                        onChange={(newValue) => {
                          var a = [];
                          (newValue ?? []).forEach((x) => {
                            a.push(x.value);
                          });
                          setFieldValue("ContainersIds", a);
                          setSelected(newValue ?? []);
                        }}
                      />
                    )}
                  </div>

                  <div className="col-lg-12 mt-5">
                    <div className="d-flex align-items-center">
                      <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                      <label className="checkbox checkbox-lg checkbox-light-success checkbox-single flex-shrink-0 m-0 mx-4">
                        <input
                          type="checkbox"
                          name="IsHot"
                          checked={values.IsHot}
                          onChange={(event) => {
                            setFieldValue("IsHot", event.target.checked);
                          }}
                        />
                        <span></span>
                      </label>
                      <div className="d-flex flex-column flex-grow-1 pt-1">
                        <a
                          href="javascript:void(0)"
                          className="text-dark-75 text-hover-primary font-weight-bold font-size-lg mb-1"
                        >
                          Is Hot
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}

async function GetReceiptsIds(setReceiptsIds) {
  var getContainersNumber = window.GetContainersNumber ?? [];
  if (getContainersNumber.length > 0) {
    setReceiptsIds(getContainersNumber);
    return;
  }

  return await fetch(
    window.$apiurl + "/receiptsmanagement/GetContainersNumber",
    {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    }
  )
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        setReceiptsIds(res[1] ?? []);
        window.GetContainersNumber = res[1] ?? [];
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

async function GetViewModel(setCategory, setAccessLevel) {
  return await fetch(`${window.$apiurl}/notes/GetViewModel`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = await response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      if (res[0] === 200) {
        setCategory(res[1]?.Categories ?? []);
        setAccessLevel(res[1]?.AccessLevels ?? []);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}

async function GetAllTitles(setTitles) {
  return await fetch(window.$apiurl + "/notes/GetAllTitles", {
    method: "GET",
    withCredentials: true,
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then((res) => {
      if (res[0] === 200) {
        setTitles(res[1] ?? []);
      }
    })
    .catch((error) => {
      console.error(error);
    });
}
