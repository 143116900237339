import React from "react";

export function StatusColumnFormatter(cellContent, row) {
  const CustomerStatusCssClasses = ["success", "danger"];
  const CustomerStatusTitles = ["Active", "Inactive"];

  var isActive = row.Active ? 0 : 1;
  const getLabelCssClasses = () => {
    return `label label-lg label-light-${CustomerStatusCssClasses[isActive]} label-inline`;
  };
  return (
    <span
      className={getLabelCssClasses()}
    >
      {CustomerStatusTitles[isActive]}
    </span>
  );
}
