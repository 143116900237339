import ContactInformationTableMock from "./ContactInformationTableMock";
import MockUtils from "./mock.utils";
import LocalStorage from "lowdb/adapters/LocalStorage";
import low from "lowdb";
const adapter = new LocalStorage("db");
const db = low(adapter);
let carTableMock;

async function GetCarrier(id) {
  return await fetch(window.$apiurl +"/carriers/transport/"+id, {
    method: "get",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json(); 
      return Promise.all([statusCode, data.Contacts]);
    })
    .then((res) => {
      return ChangeId(res[1]);
    })
    .catch((error) => {
      console.error(error);
    });
}
async function PostContactCarrier(newRemark){
  return fetch(window.$apiurl +"/carriers/transport/contacts", {
    method: "post",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newRemark),
  })
    .then(async (response) => {
      return await response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
async function GetContact(id) {
  return await fetch(window.$apiurl+"/carriers/transport/contacts/" + id, {
    method: "get",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((res) => {

      return res;
    })
    .catch((err) => {
      Notification("error", "err");
      return false;
    });
}

async function DeleteContactInfo(id) {
  return await fetch(window.$apiurl+"/carriers/transport/contacts/" + id, {
    method: "delete",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      return response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
async function PutContactCarrier(someData, id) {
  return fetch(window.$apiurl+"/carriers/transport/contacts/" + id, {
    method: "put",
    headers: {
      Authorization: `bearer ${db.read().getState().Token}`,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(someData),
  })
    .then(async (response) => {
      return await response;
    })
    .then((res) => {
    })
    .catch((err) => {
    });
}
//---------------------------------------------------------------------------
export default function mockContactInformation(mock) {
  mock.onPost("api/ContactInformation").reply(async({ data }) => {
    
    const { remark } = JSON.parse(data);
    const urls = remark.carId;
    const {
      CarrierId=urls,
      ContactName="",
      Title="",
      ContactTypeId=0,
      AirportsServiced="",
      Email="",
      DayPhone="",
      NightPhone="",
      MobilePhone="",
      Fax="",
      Address1="",
      Address2="",
      City="",
      State="",
      PostalCode="",
      Country="",
    } = remark;
    const id = generateRemarkId();
    const newRemark = {
      CarrierId,
      ContactName,
      Title,
      ContactTypeId,
      AirportsServiced,
      Email,
      DayPhone,
      NightPhone,
      MobilePhone,
      Fax,
      Address1,
      Address2,
      City,
      State,
      PostalCode,
      Country,
    };
    const postContactCarrier = await PostContactCarrier(newRemark);
    ContactInformationTableMock.push(newRemark);
    return [200, { remark: newRemark }];
  });

  mock.onPost(/api\/ContactInformationfind\/\d+/).reply(async config => {
    const urls = config.url.split("/");
    const id = urls[2];
    const product = await GetCarrier(id);
    carTableMock = product;
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdRemarks = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filterdRemarks];
  });

  mock.onPost("api/ContactInformation/deleteRemarks").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = ContactInformationTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        ContactInformationTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onGet(/api\/ContactInformation\/\d+/).reply(async config => {
    
    const id = config.url.match(/api\/ContactInformation\/(\d+)/)[1];
    const remark = await GetContact(id);
    if (!remark) {
      return [400];
    }

    return [200, remark];
  });

  mock.onPut(/api\/ContactInformation\/\d+/).reply(config => {
    const id = config.url.match(/api\/ContactInformation\/(\d+)/)[1];
    const { remark } = JSON.parse(config.data);
    const tt =PutContactCarrier(remark,id);
    const index = ContactInformationTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    ContactInformationTableMock[index] = { ...remark };
    return [200];
  });

  mock.onDelete(/api\/ContactInformation\/\d+/).reply(async config => {

    const id = config.url.match(/api\/ContactInformation\/(\d+)/)[1];
    var deleteMasterCarrier = await DeleteContactInfo(id);
    if (!deleteMasterCarrier) return [400];
    const index = ContactInformationTableMock.findIndex(el => el.id === +id);
    ContactInformationTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateRemarkId() {
  const ids = ContactInformationTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
function ChangeId(list) {
  for (var item in list) {
    list[item].id = list[item].Id;
    delete list[item].Id;
  }
  return list;
}