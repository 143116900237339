import React, { useEffect, useState } from "react";
import Item from "./Item";
import { Spinner } from "react-bootstrap";
import { Alert, Accordion } from "react-bootstrap";
import { Button, Icon } from "@material-ui/core";
import clsx from "clsx";
import { default as JsNative } from "jquery";
import { DetailMangment } from "../../../../../modules/eMPower/pages/PalletsManagmentBeta/customers-table/ModelManagment";
import { Modal } from "react-bootstrap";
import { ScrollingMenuReceipt } from "./ScrollingMenuReceipt";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import Draggable from "react-draggable";
const adapter = new LocalStorage("db");
const db = low(adapter);

export default function IndexPalletsList({
  SetTableShipment,
  DeletRowId,
  setLoading,
  ContainerId,
  setPalletsFun,
  Search,
  setSendEmailModal,
  setEditItem,
  OSDList,
  RemoveSku,
  setSKUsList,
}) {
  const [Pallets, setPallets] = useState([]);
  const [Show, setShow] = useState(false);
  const [Search2, setSearch2] = useState("");
  const [ShowModel, setShowModel] = useState(false);
  const [IdSelect, setIdSelect] = useState();
  const [CheckboxListIds, setCheckboxListIds] = useState([]);

  const [Items, setItems] = useState([]);

  function SelectIDforPallet(id) {
    setIdSelect(id);
    setShowModel(true);
  }

  useEffect(() => {
    CallFunction();
  }, [ContainerId]);

  useEffect(() => {
    if (DeletRowId?.id !== 0) {
      CallFunction();
    }
  }, [DeletRowId]);

  async function CallFunction() {
    (ContainerId ?? []).forEach(async (element, i) => {
      const contents = await GetPalletsFunction(
        element,
        ContainerId.length === i + 1
      );
    });
  }

  async function GetPalletsFunction(id, last) {
    setLoading(true);
    setShow(false);
    var el1 = document.querySelector(
      ".OrdersClass .progress[style*='background-color: rgba(54, 153, 255, 0.267);']"
    );
    var el2 = el1?.parentElement?.getAttribute("id");

    let items = await GetItems(id);
    if (items) {
      items.progress = ProgressBar2(items, items?.leftQuantity);
      var a = Items.findIndex((x) => x?.Id === id);

      (items?.LineItems ?? []).forEach((x) => {
        x.ParentId = items?.Id;
        x.search =
          x?.Id +
          "|" +
          x?.ReceiptId +
          "|" +
          x?.PurchaseOrder +
          "|" +
          x?.LeftQuantity +
          "|" +
          x?.Sku;
      });

      if (a > -1) {
        Items[a] = items;
        setItems([]);
        setTimeout(() => {
          setItems(Items);
        }, 1);
      } else {
        setItems((x) => [...x, items]);
      }
    }

    setTimeout(() => {
      if (last) {
        var el3 = document.getElementById(el2);
        if (el2 && el3) el3.click();

        setLoading(false);
        setShow(true);
        setTimeout(() => {
          if (window.BtnSku) {
            var btnSku1 = document.getElementById(window.BtnSku + "2");
            if (btnSku1) btnSku1.click();
          }
        }, 200);
      }
      return true;
    }, 200);
  }

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }
    setCheckboxListIds([...array]);
  }

  var tableElement, offset;
  function GetTale() {
    var shipID = Pallets.find((x) => x.Id === CheckboxListIds[0])?.ShipID;
    tableElement = JsNative(".TableComponent" + shipID);
    offset = tableElement.offset();

    return shipID;
  }

  const CheckPosition = (offset1, offset2) => {
    try {
      if (
        offset1.top < offset2.pageY &&
        offset2.pageY < offset1.top + tableElement.height() &&
        offset1.left < offset2.pageX &&
        offset2.pageX < offset1.left + tableElement.width()
      )
        return true;
      else return false;
    } catch (error) {
      return false;
    }
  };

  function handleDrag(e) {
    GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      ele.addClass("bg-light-info");
    } else ele.removeClass("bg-light-info");
    ele.addClass("border border-info border-2");
  }

  function handleStop(e) {
    var shipID = GetTale();
    var page = e;
    if (e.changedTouches?.length > 0) page = e.changedTouches[0];

    var ele = tableElement.find(".shadow-sm");
    if (CheckPosition(offset, page)) {
      PostPallets(CheckboxListIds, shipID);
    }
    ele.removeClass("bg-light-info");
    ele.removeClass("border border-info border-2");
  }

  function CheckboxList(check, Id) {
    var array = CheckboxListIds || [];
    var find = array?.findIndex((x) => x === Id);
    if (find > -1 && !check) {
      array.splice(find, 1);
    } else if (find === -1 && check) {
      array.push(Id);
    }
    setCheckboxListIds([...array]);
  }

  async function PostPallets(ItemsId, PalletId) {
    ItemsId.forEach((x) => {
      var a = Pallets.findIndex((y) => x === y.Id);
      SetTableShipment(Pallets[a]);
    });

    var find = Pallets.filter((x) => ItemsId.indexOf(x.Id) === -1);
    setPallets([]);
    setTimeout(() => {
      setPallets(find);
    }, 1);

    setCheckboxListIds([]);
  }

  useEffect(() => {
    if (DeletRowId && DeletRowId !== 0) setPallets((x) => [...x, DeletRowId]);
  }, [DeletRowId]);

  function ProgressBar(id, Quantity) {
    setItems((x) => {
      var cont = x.find((y) => y.Id === id);
      var p = (cont.Cube / cont.Cartons) * Quantity;
      cont.progress -= percentage(p, cont.Cube);
      if (cont.progress < 0) cont.progress = 0;
      return x;
    });
    var btn = document.querySelector(".menu-item-wrapper.active .OrdersClass");
    if (btn) btn.click();
  }

  function ProgressBar2(cont, Quantity) {
    var p = (cont.Cube / cont.Cartons) * Quantity;
    cont.progress -= percentage(p, cont.Cube);
    if (cont.progress < 0) cont.progress = 0;
    return cont.progress;
  }

  function percentage(partialValue, totalValue) {
    var i = (100 * partialValue) / totalValue;
    if (Object.is(i, NaN)) return 100;
    return (100 * partialValue) / totalValue;
  }

  function removeId(id) {
    var a = Pallets.filter((x) => x?.Id !== id);
    setPallets(a ?? []);
  }

  function compare(a, b) {
    if (a.ShipID < b.ShipID) {
      return -1;
    }
    if (a.ShipID > b.ShipID) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <Modal
        size="xl"
        show={ShowModel}
        onHide={() => {
          setShowModel(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <DetailMangment Id={IdSelect} setShow={setShowModel.bind()} />
      </Modal>
      <div className="row">
        <div className={"col-12 my-2 mx-0"}>
          {CheckboxListIds?.length > 0 && (
            <Draggable
              axis="both"
              handle=".handle"
              position={{ x: 0, y: 0 }}
              grid={[1, 1]}
              scale={1}
              onDrag={handleDrag}
              onStop={handleStop}
            >
              <div className="handle">
                <button
                  type="button"
                  className={`btn btn-light-danger bg-light-danger text-danger w-100 p-0`}
                  style={{ height: "41px" }}
                >
                  {htmlP(CheckboxListIds, "Pallets Selected")}
                </button>
              </div>
            </Draggable>
          )}
          {Items?.length > 0 ? (
            <>
              <PalletsFun
                Search={Search}
                Items={Items}
                SetTableShipment={SetTableShipment}
                ProgressBar={ProgressBar}
                setPalletsFun={setPalletsFun}
                Show={Show}
                setSendEmailModal={setSendEmailModal}
                setEditItem={setEditItem}
                OSDList={OSDList}
                RemoveSku={RemoveSku}
                setSKUsList={setSKUsList}
              />
            </>
          ) : (
            <Nodatafound title="Please Select Container" />
          )}
        </div>
      </div>
    </>
  );
}

function PalletsFun({
  Search,
  Items,
  Shipment,
  SetTableShipment,
  CheckboxList,
  CheckboxListIds,
  POChaekced,
  ProgressBar,
  setPalletsFun,
  LinePath,
  Locations,
  setLoading,
  Show,
  Search2,
  setSendEmailModal,
  setEditItem,
  OSDList = [],
  RemoveSku,
  setSKUsList,
}) {
  const [selectedId, setselectedId] = useState(false);
  const [Receipts, setReceipts] = useState([]);
  const [Receipt, setReceipt] = useState([]);

  const repeat = [];
  const CheckReapet = (id) => {
    if (repeat.indexOf(id) === -1) {
      repeat.push(id);
      return true;
    } else return false;
  };

  useEffect(() => {
    setReceipts([]);
    setTimeout(() => {
      setReceipts(Items);
    }, 1);
  }, [Items, Search]);

  useEffect(() => {
    window.reciept = Receipts;
  }, [SetTableShipment]);

  useEffect(() => {
    var a = Receipts?.find((x) => x?.Id === +selectedId);
    var b = [];
    if (a?.LineItems?.length > 0) {
      a.LineItems.forEach((element) => {
        b.push({ Sku: element?.Sku, Id: element?.Id });
      });
      setSKUsList([...new Set(b)]);
    }
  }, [selectedId]);

  return (
    <>
      <div className={"row " + (Show ? "" : "d-none")}>
        {OSDList?.map((x1, i1) => (
          <>
            {CheckReapet(x1.Location) && (
              <>
                {i1 !== 0 && (
                  <>
                    <div className="col-12 my-5 py-2" />
                  </>
                )}
                <div className="col-12 mb-2 mx-0">
                  <div
                    className={
                      "alert alert-primary p-0 " +
                      (!x1?.Location.includes("Exception")
                        ? "alert-primary"
                        : "alert-warning")
                    }
                  >
                    <b className="pt-1 ml-2 text-white">
                      <i class="pl-0 mr-3 fas fa-location-arrow text-white mt-1" />
                      {x1?.Location}
                    </b>
                  </div>
                </div>
              </>
            )}
            <Item
              item={x1}
              SetTableShipment={SetTableShipment}
              key={i1}
              setPalletsFun={setPalletsFun}
              setLoading={setLoading}
              ProgressBar={ProgressBar}
              isEditItem={x1?.Location.includes("Exception")}
              setEditItem={setEditItem}
              OSDPallet={true}
              RemoveSku={RemoveSku}
            />
          </>
        ))}
        {OSDList?.length === 0 && (
          <ScrollingMenuReceipt
            list={Receipts}
            setselectedId={setselectedId}
            Color="primary"
            Title="Containers"
            selectedId={selectedId}
          />
        )}

        {OSDList?.length === 0 &&
          Receipts?.filter((x) => x?.Id === +selectedId).map((x, i) => (
            <>
              <Accordion defaultActiveKey="1" className="w-100">
                {x?.LineItems.sort((a, b) =>
                  a.Location > b.Location ? 1 : b.Location > a.Location ? -1 : 0
                )
                  .filter((x) => x.Sku?.includes(Search))
                  ?.map((x1, i1) => (
                    <>
                      {CheckReapet(x1.Location) && (
                        <>
                          {i !== 0 && (
                            <>
                              <div className="col-12 my-5 py-2" />
                            </>
                          )}
                          <Accordion.Toggle
                            as={Button}
                            className="w-100 text-left p-0 position-relative text-decoration-none"
                            variant="link"
                            eventKey={i1}
                            id={"BtnSku" + i1 + "2"}
                            onClick={() => {
                              window.BtnSku = "BtnSku" + i1;
                            }}
                          >
                            <Alert
                              className={
                                "p-0 w-100 " +
                                (!x1?.Location.includes("Exception")
                                  ? "alert-primary"
                                  : "alert-warning")
                              }
                            >
                              <div
                                className={
                                  "alert alert-primary p-0 m-0 " +
                                  (!x1?.Location.includes("Exception")
                                    ? "alert-primary"
                                    : "alert-warning")
                                }
                              >
                                <b className="pt-1 ml-2 text-white">
                                  {x1?.Location} - Sku Count :{" "}
                                  {
                                    x?.LineItems?.filter(
                                      (z) =>
                                        z?.Location === x1?.Location &&
                                        z?.LeftQuantity > 0
                                    ).length
                                  }
                                </b>
                              </div>
                            </Alert>
                            <Icon
                              className={clsx(
                                "fa fa-chevron-down position-absolute text-white"
                              )}
                              style={{
                                fontSize: 17,
                                right: "1rem",
                                top: "20%",
                              }}
                            />
                          </Accordion.Toggle>
                          <Accordion.Collapse
                            eventKey={i1}
                            className="w-100 row BtnSku"
                            id={"BtnSku" + i1}
                          >
                            <>
                              {x?.LineItems?.filter(
                                (z) => z?.Location === x1?.Location
                              )?.map((x2, i2) => (
                                <Item
                                  item={x2}
                                  SetTableShipment={SetTableShipment}
                                  key={i2}
                                  setPalletsFun={setPalletsFun}
                                  setLoading={setLoading}
                                  ProgressBar={ProgressBar}
                                  isEditItem={x1?.Location.includes(
                                    "Exception"
                                  )}
                                  setEditItem={setEditItem}
                                />
                              ))}
                            </>
                          </Accordion.Collapse>
                        </>
                      )}
                    </>
                  ))}

                {x?.LineItems.filter((x) => x.Sku?.includes(Search))?.length ===
                  0 && (
                  <Nodatafound title="There are no More POs left in the container for this DC" />
                )}
              </Accordion>
            </>
          ))}
      </div>
    </>
  );
}

function Nodatafound({ title = "" }) {
  const [Reload, setReload] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setReload(false);
    }, 1000);
  }, []);

  return (
    <>
      {!Reload ? (
        <div className="col-lg-12 row mt-5 pt-5">
          <div className="col-lg-4" />
          <div className="col-lg-1 text-right">
            <i
              className="fas fa-quote-left"
              style={{ fontSize: "70px", opacity: 0.3 }}
            />
          </div>
          <div className="col-lg-4 mt-2">
            <p className="h1 mt-5" style={{ opacity: 0.3 }}>
              {title}
            </p>
          </div>
          <div className="col-lg-3" />
        </div>
      ) : (
        <div className="col-lg-12  text-center">
          <Spinner
            id="InboundLoading"
            animation="grow"
            role="status"
            variant="primary"
            size="lg"
            style={{ width: "200px", height: "200px" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
}

function htmlP(list, text) {
  return (
    <>
      {text}
      {list.map((x) => (
        <p type="button" className={`btn btn-danger px-1 py-0 mt-3 ml-1 mr-1`}>
          {x}
        </p>
      ))}
    </>
  );
}

async function GetItems(id) {
  return await fetch(`${window.$apiurl}/Pallets/GetItemsForShipment?id=${id}`, {
    method: "get",
    withCredentials: true,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `bearer ${db.read().getState().Token}`,
      "X-FP-API-KEY": "iphone",
      "Content-Type": "application/json",
    },
  })
    .then(async (response) => {
      const statusCode = response.status;
      const data = await response.json();
      return Promise.all([statusCode, data]);
    })
    .then(async (res) => {
      return res[1];
    })
    .catch((error) => {
      console.error(error);
    });
}
