import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Checkbox from "@material-ui/core/Checkbox";
import { green } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Skeleton from "@material-ui/lab/Skeleton";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SlideLanding from "./Slide-Landing";
const adapter = new LocalStorage("db");
const db = low(adapter);
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  button: {
    margin: theme.spacing(1),
    "&:hover": {
      color: "#fff",
    },
  },
  input: {
    display: "none",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export function Body() {
  const classes = useStyles();

  const [showContact, setShowContact] = React.useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedC: false,
    checkedD: false,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  const [dataFetch, setDataFetch] = useState([]);
  useEffect(() => {
    let _title = "Landing";
    GetDataFetch();
  }, []);
  async function GetDataFetch() {
    return await fetch(window.$apiurl + "/cms/mainnews", {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        setDataFetch(res[1].News || []);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  function Media(props) {
    const { loading = false } = props;

    return (
      <>
        {(loading ? Array.from(new Array(3)) : dataFetch).map(
          (item2, index) => (
            <div className="col-md-4">
              <Card sx={{ maxWidth: 345, m: 2 }}>
                {loading ? (
                  <Skeleton
                    height={190}
                    animation="wave"
                    variant="rectangular"
                  />
                ) : (
                  (loading
                    ? Array.from(new Array(3))
                    : dataFetch
                  ).map((item2, index) => (
                    <CardMedia
                      component="img"
                      height="140"
                      image={`${item2.ThumbnailImg}`}
                      title={`${item2.Id}`}
                      alt={`${item2.Id}`}
                    />
                  ))
                )}

                <CardContent>
                  {loading ? (
                    <React.Fragment>
                      <Skeleton
                        animation="wave"
                        height={10}
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                      />
                      <Skeleton animation="wave" height={10} width="10%" />
                    </React.Fragment>
                  ) : (
                    (loading ? Array.from(new Array(3)) : dataFetch).map(
                      (item2, index) => (
                        <div className="col-md-4">
                          <Card className={classes.card}>
                            <CardActionArea>
                              <CardMedia
                                className={classes.media}
                                image={`${item2.ThumbnailImg}`}
                                title={`${item2.Id}`}
                              />
                              <CardContent>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  {item2.Title}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  component="p"
                                  className="text-truncate"
                                >
                                  {item2.SubTitle}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            <CardActions>
                              <Link
                                to={`/News/${item2.Id}`}
                                className="font-weight-bolder font-size-sm mr-3"
                              >
                                <span>Read More</span>
                              </Link>
                            </CardActions>
                          </Card>
                        </div>
                      )
                    )
                  )}
                </CardContent>
              </Card>
            </div>
          )
        )}
      </>
    );
  }

  return (
    <>
      <div className="container-fluid px-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-40 pb-30 align-self-center">
              <h3 className="font-weight-bolder display-4 mb-0 pb-0">
                IMPROVE YOUR
              </h3>
              <h1 className="text-primary font-weight-bolder display-3 mb-0 pb-0">
                SPEED TO MARKET
              </h1>
              <h3 className="font-weight-bolder display-4">PROFITABILITY</h3>
            </div>
            <div className="col-md-6">
              <Link to="/" className="flex-column-auto mt-5">
                <img
                  alt="Logo"
                  className="pt-40 pb-30 w-100"
                  src={toAbsoluteUrl("/media/bg/5229.png")}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-8" id="Features">
        <div className="row">
          <div className="col-md-12 text-center pt-10">
            <h1 className="font-weight-bolder">Our Amazing Features</h1>
            <p className="text-muted">
              Communicate with flexible tools that go where your business goes.
            </p>
          </div>
          <div className="col-lg-12">
            <div className="card bg-transparent border-0 shadow-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6 col-xxl-3 border-right-0 border-right-md border-bottom border-bottom-xxl-0">
                    <div className="pb-15 px-5 text-center">
                      <div className="d-flex flex-center position-relative mb-15">
                        <Link
                          to="/"
                          className="flex-column-auto mt-5 text-center"
                        >
                          <img
                            alt="Logo"
                            className="py-5"
                            src={toAbsoluteUrl(
                              "/media/bg/distribution-icon.png"
                            )}
                          />
                        </Link>
                      </div>
                      <div className="h-auto">
                        <h4 className="font-size-h3 d-block d-block font-weight-bold mb-7 text-dark-75">
                          Omni-Channel Fulfillment
                        </h4>
                        <p className="mb-15 d-flex flex-column">
                          <span style={{ height: "57.6px" }}>
                            Omnichannel warehouse and distribution solutions
                          </span>
                        </p>
                        <Button
                          href="#"
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-xxl-3 border-right-0 border-right-xxl border-bottom border-bottom-xxl-0">
                    <div className="pb-15 px-5 text-center">
                      <div className="d-flex flex-center position-relative mb-15">
                        <Link
                          to="/CustomerDashboard/GlobalDashboard"
                          className="flex-column-auto mt-1 text-center"
                        >
                          <img
                            alt="Logo"
                            className="py-0"
                            src={toAbsoluteUrl(
                              "/media/bg/transloading-icon.png"
                            )}
                          />
                        </Link>
                      </div>
                      <div className="h-auto">
                        <h4 className="font-size-h3 d-block d-block font-weight-bold mb-7 text-dark-75">
                          Transloading
                        </h4>
                        <p className="mb-15 d-flex flex-column">
                          <span>
                            Customized PO management solutions that streamline
                            our clients' delivery system
                          </span>
                        </p>
                        <Button
                          href="/CustomerDashboard/GlobalDashboard"
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-3 border-right-0 border-right-md border-bottom border-bottom-md-0">
                    <div className="pb-15 px-5 text-center">
                      <div className="d-flex flex-center position-relative mb-15">
                        <Link
                          to="/TransportManagementSystem"
                          className="flex-column-auto mt-5 text-center"
                        >
                          <img
                            alt="Logo"
                            className="py-1"
                            src={toAbsoluteUrl(
                              "/media/bg/transportation-icon.png"
                            )}
                          />
                        </Link>
                      </div>
                      <div className="h-auto">
                        <h4 className="font-size-h3 d-block d-block font-weight-bold mb-7 text-dark-75">
                          Transportation
                        </h4>
                        <p className="mb-15 d-flex flex-column">
                          <span style={{ height: "57.6px" }}>
                            Delivery solutions from ship to shelf
                          </span>
                        </p>
                        <Button
                          href="/TransportManagementSystem"
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xxl-3">
                    <div className="pb-15 px-5 text-center">
                      <div className="d-flex flex-center position-relative mb-15">
                        <Link
                          to="/"
                          className="flex-column-auto mt-5 text-center"
                        >
                          <img
                            alt="Logo"
                            className="py-5"
                            src={toAbsoluteUrl("/media/bg/drayage-icon.png")}
                          />
                        </Link>
                      </div>
                      <div className="h-auto">
                        <h4 className="font-size-h3 d-block d-block font-weight-bold mb-7 text-dark-75">
                          Drayage
                        </h4>
                        <p className="mb-15 d-flex flex-column">
                          <span style={{ height: "57.6px" }}>
                            Asset based dispatch and drayage operations
                          </span>
                        </p>
                        <Button
                          href="#"
                          variant="contained"
                          color="secondary"
                          className={classes.button}
                        >
                          Learn More
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container fluid={true} className="px-0">
        <Row>
          <div className="col-7 mx-auto">
            <SlideLanding />
          </div>
        </Row>
        <Container fluid={true} className="bg-primary curve-bg"></Container>
      </Container>
      <Container fluid={true} className="bg-primary">
        <div className="row my-10 text-center text-white">
          <div className="col-6 col-md-3 border-right">
            <div className="type-large">
              <span className="font-weight-bolder display-4">12,000+</span>
            </div>
            <p className="mb-1 font-size-h4">Continer</p>
            <p className="mb-1 font-size-h4">
              <strong>Drayed</strong>
            </p>
          </div>
          <div className="col-6 col-md-3 border-right">
            <div className="type-large">
              <span className="font-weight-bolder display-4"> 7.500+</span>
            </div>
            <p className="mb-1 font-size-h4">Continers</p>
            <p className="mb-1 font-size-h4">
              <strong>Transloaded</strong>
            </p>
          </div>
          <div className="col-6 col-md-3 border-right">
            <div className="type-large">
              <span className="font-weight-bolder display-4">10.000.000+</span>
            </div>
            <p className="mb-1 font-size-h4">Unites</p>
            <p className="font-size-h4">
              <strong>Shipped</strong>
            </p>
          </div>
          <div className="col-6 col-md-3">
            <div className="type-large">
              <span className="font-weight-bolder display-4">10.000+</span>
            </div>
            <p className="mb-1 font-size-h4">Leads</p>
            <p className="mb-1 font-size-h4">
              <strong>Delivred</strong>
            </p>
          </div>
        </div>
      </Container>
      <div className="container mb-15" id="CASESTUDIES">
        <div className="col-md-12 text-center pt-20 pb-10">
          <h1 className="font-weight-bolder">CASE STUDIES & News</h1>
          <p className="text-muted">Latest news, tips and best practices.</p>
        </div>
        <div className="row">
          <Media loading={dataFetch?.length > 0 ? false : true} />
        </div>
      </div>
      <div className="container-fluid px-0 bg-gray" id="Pricing">
        <div className="col-md-12 text-center pt-20">
          <h1 className="font-weight-bolder">
            Pricing for every business, at any stage
          </h1>
          <p className="text-muted">
            All pricing packages are backed up by a 30-day money back guarantee.
          </p>
        </div>
        {/*begin::Card*/}
        <div className="card border-0 bg-transparent">
          <div className="card-body">
            <div className="row justify-content-center my-20">
              {/*begin: Pricing*/}
              <div className="col-md-4 col-xxl-3">
                <div className="pt-30 pt-md-25 pb-15 px-5 text-center">
                  {/*begin::Icon*/}
                  <div className="d-flex flex-center position-relative mb-25">
                    <span className="svg svg-fill-primary opacity-4 position-absolute">
                      <svg width={175} height={200}>
                        <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" />
                      </svg>
                    </span>
                    <span className="svg-icon svg-icon-5x svg-icon-primary">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Home/Flower3.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24" />
                          <path
                            d="M1.4152146,4.84010415 C11.1782334,10.3362599 14.7076452,16.4493804 12.0034499,23.1794656 C5.02500006,22.0396582 1.4955883,15.9265377 1.4152146,4.84010415 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                          <path
                            d="M22.5950046,4.84010415 C12.8319858,10.3362599 9.30257403,16.4493804 12.0067693,23.1794656 C18.9852192,22.0396582 22.5146309,15.9265377 22.5950046,4.84010415 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                          <path
                            d="M12.0002081,2 C6.29326368,11.6413199 6.29326368,18.7001435 12.0002081,23.1764706 C17.4738192,18.7001435 17.4738192,11.6413199 12.0002081,2 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Content*/}
                  <span className="font-size-h1 d-block d-block font-weight-boldest text-dark-75 py-2">
                    Free
                  </span>
                  <h4 className="font-size-h4 d-block d-block font-weight-bold mb-7 text-dark-50">
                    1 End Product License
                  </h4>
                  <p className="mb-15 d-flex flex-column">
                    <span>Lorem ipsum dolor sit amet edipiscing elit</span>
                    <span>sed do eiusmod elpors labore et dolore</span>
                    <span>magna siad enim aliqua</span>
                  </p>
                  <Button
                    href="/CustomerDashboard/GlobalDashboard"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Get For Free
                  </Button>
                  {/*end::Content*/}
                </div>
              </div>
              {/*end: Pricing*/}
              {/*begin: Pricing*/}
              <div className="col-md-4 col-xxl-3 border-x-0 border-x-md border-y border-y-md-0">
                <div className="pt-30 pt-md-25 pb-15 px-5 text-center">
                  {/*begin::Icon*/}
                  <div className="d-flex flex-center position-relative mb-25">
                    <span className="svg svg-fill-primary opacity-4 position-absolute">
                      <svg width={175} height={200}>
                        <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" />
                      </svg>
                    </span>
                    <span className="svg-icon svg-icon-5x svg-icon-primary">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Tools/Compass.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M7.07744993,12.3040451 C7.72444571,13.0716094 8.54044565,13.6920474 9.46808594,14.1079953 L5,23 L4.5,18 L7.07744993,12.3040451 Z M14.5865511,14.2597864 C15.5319561,13.9019016 16.375416,13.3366121 17.0614026,12.6194459 L19.5,18 L19,23 L14.5865511,14.2597864 Z M12,3.55271368e-14 C12.8284271,3.53749572e-14 13.5,0.671572875 13.5,1.5 L13.5,4 L10.5,4 L10.5,1.5 C10.5,0.671572875 11.1715729,3.56793164e-14 12,3.55271368e-14 Z"
                            fill="#000000"
                            opacity="0.3"
                          />
                          <path
                            d="M12,10 C13.1045695,10 14,9.1045695 14,8 C14,6.8954305 13.1045695,6 12,6 C10.8954305,6 10,6.8954305 10,8 C10,9.1045695 10.8954305,10 12,10 Z M12,13 C9.23857625,13 7,10.7614237 7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 C17,10.7614237 14.7614237,13 12,13 Z"
                            fill="#000000"
                            fillRule="nonzero"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Content*/}
                  <span className="font-size-h1 d-block font-weight-boldest text-dark-75 py-2">
                    XX
                    <sup className="font-size-h3 font-weight-normal pl-1">
                      $
                    </sup>
                  </span>
                  <h4 className="font-size-h4 d-block font-weight-bold mb-7 text-dark-50">
                    Business License
                  </h4>
                  <p className="mb-15 d-flex flex-column">
                    <span>Lorem ipsum dolor sit amet edipiscing elit</span>
                    <span>sed do eiusmod elpors labore et dolore</span>
                    <span>magna siad enim aliqua</span>
                  </p>
                  <Button
                    href="/CustomerDashboard/GlobalDashboard"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Purchase
                  </Button>
                  {/*end::Content*/}
                </div>
              </div>
              {/*end: Pricing*/}
              {/*begin: Pricing*/}
              <div className="col-md-4 col-xxl-3">
                <div className="pt-30 pt-md-25 pb-15 px-5 text-center">
                  {/*begin::Icon*/}
                  <div className="d-flex flex-center position-relative mb-25">
                    <span className="svg svg-fill-primary opacity-4 position-absolute">
                      <svg width={175} height={200}>
                        <polyline points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" />
                      </svg>
                    </span>
                    <span className="svg-icon svg-icon-5x svg-icon-primary">
                      {/*begin::Svg Icon | path:/metronic/theme/html/demo4/dist/assets/media/svg/icons/Shopping/Cart2.svg*/}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x={0} y={0} width={24} height={24} />
                          <path
                            d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z"
                            fill="#000000"
                            fillRule="nonzero"
                            opacity="0.3"
                          />
                          <path
                            d="M3.28077641,9 L20.7192236,9 C21.2715083,9 21.7192236,9.44771525 21.7192236,10 C21.7192236,10.0817618 21.7091962,10.163215 21.6893661,10.2425356 L19.5680983,18.7276069 C19.234223,20.0631079 18.0342737,21 16.6576708,21 L7.34232922,21 C5.96572629,21 4.76577697,20.0631079 4.43190172,18.7276069 L2.31063391,10.2425356 C2.17668518,9.70674072 2.50244587,9.16380623 3.03824078,9.0298575 C3.11756139,9.01002735 3.1990146,9 3.28077641,9 Z M12,12 C11.4477153,12 11,12.4477153 11,13 L11,17 C11,17.5522847 11.4477153,18 12,18 C12.5522847,18 13,17.5522847 13,17 L13,13 C13,12.4477153 12.5522847,12 12,12 Z M6.96472382,12.1362967 C6.43125772,12.2792385 6.11467523,12.8275755 6.25761704,13.3610416 L7.29289322,17.2247449 C7.43583503,17.758211 7.98417199,18.0747935 8.51763809,17.9318517 C9.05110419,17.7889098 9.36768668,17.2405729 9.22474487,16.7071068 L8.18946869,12.8434035 C8.04652688,12.3099374 7.49818992,11.9933549 6.96472382,12.1362967 Z M17.0352762,12.1362967 C16.5018101,11.9933549 15.9534731,12.3099374 15.8105313,12.8434035 L14.7752551,16.7071068 C14.6323133,17.2405729 14.9488958,17.7889098 15.4823619,17.9318517 C16.015828,18.0747935 16.564165,17.758211 16.7071068,17.2247449 L17.742383,13.3610416 C17.8853248,12.8275755 17.5687423,12.2792385 17.0352762,12.1362967 Z"
                            fill="#000000"
                          />
                        </g>
                      </svg>
                      {/*end::Svg Icon*/}
                    </span>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Content*/}
                  <span className="font-size-h1 d-block font-weight-boldest text-dark-75 py-2">
                    XXX
                    <sup className="font-size-h3 font-weight-normal pl-1">
                      $
                    </sup>
                  </span>
                  <h4 className="font-size-h4 d-block font-weight-bold mb-7 text-dark-50">
                    Enterprice License
                  </h4>
                  <p className="mb-15 d-flex flex-column">
                    <span>Lorem ipsum dolor sit amet edipiscing elit</span>
                    <span>sed do eiusmod elpors labore et dolore</span>
                    <span>magna siad enim aliqua</span>
                  </p>
                  <Button
                    href="/CustomerDashboard/GlobalDashboard"
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Purchase
                  </Button>
                  {/*end::Content*/}
                </div>
              </div>
              {/*end: Pricing*/}
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid px-0  py-20" id="Contact">
        <div className="container">
          <Modal show={showContact} onHide={setShowContact} size="lg">
            <Modal.Header>
              <Modal.Title>
                CHOOSE A SERVICE(S) THAT YOU WOULD LIKE TO GET A QUOTE FOR
              </Modal.Title>
              <IconButton
                
                aria-label="Close"
                onClick={(e) => {
                  setShowContact(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridFirst">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridLast">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridPhone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control type="tel" placeholder="" />
                  </Form.Group>
                </Form.Row>

                <Form.Group controlId="formGridCompany">
                  <Form.Label>Company</Form.Label>
                  <Form.Control placeholder="" />
                </Form.Group>
                <Form.Group controlId="Comments">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows="3" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                onClick={setShowContact}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="col-md-10 mx-auto">
            <div className="row">
              <div className="col-md-10">
                <p className="font-weight-bolder font-size-h1 text-white mb-0">
                  SEE WHAT EMPOWER CAN DO TO HELP YOU GROW YOUR BUSINESS
                </p>
              </div>
              <div className="col-md-2 align-self-center">
                <a
                  role="button"
                  tabindex="0"
                  aria-label="Get a Quote"
                  href="#"
                  class="btn btn-outline-white"
                  onClick={(e) => {
                    setShowContact(true);
                  }}
                >
                  Get A Quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
