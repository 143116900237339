import React from 'react'
import {Form, Card, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

export function ManageAssembliesItems() {
    return (

        <Card>
            <Card.Body>
                <Form>
                    <div class='row'>
                        <div class='col-12 mb-5'>
                            <h3 className="card-title font-weight-bolder">You have chosen "Assemblies"</h3>
                            <hr class="font-weight-bold text-primary"></hr>

                            <h4 className='mt-5 mb-5'>Please select the specific assemblies activity you would like to perform from the menu options listed in the gray sub-menu bar above</h4>
                            <p className='mt-5'>In Assemblies you can:</p>

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="#">Create a New Assembly or Kitted Item</Link>
                            </span>
                            <p className='mt-2'>Click “Create Assembly”, select the customer and choose the kitted Item number / SKU you wish to assemble. Choose the quantity you wish to assemble and add notes and instructions. You can continue and complete the assembly transaction (updating inventory) or print a bill of materials and complete the transaction (update inventory) after it is completed.</p>

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="#">Create a New Assembly or Kitted Item</Link>
                            </span>
                            <p className='mt-2'>Click “Manage Open Assemblies” and select the assembly reference number or internal transaction number you wish to manage. From there you can print BOM’s and labels, price and confirm (update inventory) and more.</p>

                            <span class="label label-lg label-light-primary label-inline">
                                <Link className='mt-1 font-weight-bold' to="#">Create a New Assembly or Kitted Item</Link>
                            </span>
                            
                            <p className='mt-2'>Click “Re-Print B O M” to re-print bills of materials for selected orders.</p>

                            <br />
                            <Alert variant="info " className="mt-5">
                                <b>Please note :</b>You must first CREATE or set-up an assembled or kitted Item in the inventory list before you can create an assembly of that item. To create an assembled SKU click on Items in the Top Menu and then Create Item in the sub-menu.
                                <p></p>
                                Required fields are marked with a red asterisk (<b>*</b>) within entry forms on this web site.
                            </Alert>
                        </div>

                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}
