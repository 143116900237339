import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Notification } from "../../../../modules/Notification";
import low from "lowdb";
import QRCode from "react-qr-code";
import LocalStorage from "lowdb/adapters/LocalStorage";
import { Helmet } from "react-helmet";
import { Modal, Spinner } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import { Formik, Form, Field } from "formik";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CreatableSelect from "react-select/creatable";
import {
  ModalProgressBar,
  Input,
  CheckboxLarge,
} from "../../../../../_metronic/_partials/controls";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import {
  momentDate,
  momentDateTimeByDay,
} from "../../../../../_metronic/_helpers/ATHelpers";

const adapter = new LocalStorage("db");
const db = low(adapter);

function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "--/--/----";
  }
}
function float2int(value) {
  return value | 0;
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
      Statistiques: [],
      LocationItems: [],
      Shipment: null,
      formData: null,
    };
    this.state = { data: obj };
  }
  async componentDidMount() {
    if (this.props.id) {
      await fetch(
        window.$apiurl + "/pallets/GetPalletByShipment/" + this.props.id,
        {
          method: "get",
          withCredentials: true,
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: `bearer ${db.read().getState().Token}`,
            "X-FP-API-KEY": "iphone",
            "Content-Type": "application/json",
          },
        }
      ).then(async (response) => {
        const statusCode = response.status;
        // if (statusCode !== 200) {
        //   Notification("error", "No data found !");
        // }
        const json = await response.json();
        this.setState({ data: { formData: json, Shipment: json.shipment } });
      });
    }
  }
  render() {
    return (
      <>
        <Helmet>
          <title>eMPower | Shipment Manifest Form</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-0 px-0 bl-form border-0">
            <div className="card-body py-0 px-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-0 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-4">
                    <div className="col-md-12 px-0">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr className="text-center">
                            <td className="border-0 pb-1" colspan="8">
                              <h3 className="font-weight-boldest mb-0">
                                Shipment Manifest
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12">
                      <div className="row">
                        <table className="w-25 mt-2">
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Trailer #</b>{" "}
                                {this.state.data.Shipment?.TrailerNumber}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Shipment #</b>{" "}
                                {this.state.data.Shipment?.ShipmentId}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Seal no:</b>{" "}
                                {this.state.data.Shipment?.SealNumber}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Date Shipped:</b>{" "}
                                {momentDateTimeByDay(
                                  this.state.data.Shipment?.DateShipped
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50"></table>
                        <table className="w-25">
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td
                                className="border-0"
                                style={{ lineHeight: "2.2" }}
                              >
                                <b>Consignee Address:</b>
                                <br />
                                {
                                  this.state.data.Shipment?.ConsigneeAddress
                                    ?.CompanyName
                                }
                                <br />
                                {
                                  this.state.data.Shipment?.ConsigneeAddress
                                    ?.Address
                                }
                                <br />
                                {IsValidAddress(
                                  this.state.data.Shipment?.ConsigneeAddress?.City.replace(
                                    / /g,
                                    ""
                                  ),
                                  true
                                ) +
                                  IsValidAddress(
                                    this.state.data.Shipment?.ConsigneeAddress
                                      ?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    this.state.data.Shipment?.ConsigneeAddress
                                      ?.ZipCode,
                                    false
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Shipment Items */}
                  <div className="row pb-5">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="13" className="text-center">
                              Cartons
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ border: "none" }}>
                          <tr>
                            <td className="font-weight-boldest  align-text-top">
                              Pallet ID
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Container#
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              PO#
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              SKU
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Cartons
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Description
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Weight
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Cube
                            </td>
                          </tr>
                          {this.state.data.formData?.pallets?.length > 0 &&
                            this.state.data.formData?.pallets?.map((x) => (
                              <>
                                <tr>
                                  <td
                                    style={{ border: "none" }}
                                    className="align-text-top"
                                  >
                                    .
                                  </td>
                                </tr>
                                {x?.ItemsList?.map((item, index) => (
                                  <tr>
                                    <td
                                      className="align-text-top"
                                      style={{
                                        backgroundColor: "#113059",
                                        color: "#fff",
                                      }}
                                    >
                                      <b> {index === 0 && x?.Id}</b>
                                    </td>
                                    <td className="align-text-top">
                                      <b> {item?.Container}</b>
                                    </td>
                                    <td className="align-text-top">
                                      {item.PurchaseOrder}
                                    </td>
                                    <td className="align-text-top">
                                      {item.Sku}
                                    </td>
                                    <td className="align-text-top">
                                      {item.Quantity}
                                    </td>
                                    <td className="align-text-top">
                                      {item.Description}
                                    </td>
                                    <td className="align-text-top">
                                      {item.Weight} ({item.WeightType})
                                    </td>
                                    <td className="align-text-top">
                                      {item.Cube} ({item.CubeType})
                                    </td>
                                  </tr>
                                ))}
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="col-md-12 mt-5">
                      <div className="text-center mt-5">
                        <QRCode
                          value={`Shipment ID : ${this.props.id}`}
                          size={240}
                          fgColor="#113059"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export function TransloadShipmentManifest(/* {id=825406} */) {
  const databody = `<p>Please find the file attached Load Number: <strong>${id}</strong></p>`;
  const componentRef = useRef();
  const [showSendManifestEmail, setshowSendManifestEmail] = useState(false);
  const [ToData, setToData] = useState(null);
  const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
  const [isLoadingSend, setisLoadingSend] = useState(false);
  const [includCCData, setincludCCData] = useState(false);

  const [id, setid] = useState();
  useEffect(() => {
    const url = window.location.pathname;
    setid(url.substring(url.lastIndexOf("/") + 1));
  }, []);

  const [SubjectData, setSubjectData] = useState(`Shipment Manifest - ${id}`);
  const [BodyData, setBodyData] = useState(null);

  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.products.actionsLoading }),
    shallowEqual
  );
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  async function SendEmail() {
    if (ToData === null) {
      Notification("error", "Please fill email Info");
      return;
    }
    // server request for deleting product by id
    setisLoadingSend(true);
    setisLoadingSubmit(true);
    const data = {
      Emails: ToData,
      Subject: SubjectData,
      Attachments: [],
      Body: BodyData || databody,
      IncludeCc: includCCData,
      Bol: false,
      OutboundDispatch: false,
      ShipmentManifest: true,
    };
    var raw = JSON.stringify(data);
    var requestOptions = {
      method: "POST",
      body: raw,
      redirect: "follow",
      withCredentials: true,
      headers: {
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    };
    let response = await fetch(
      `${window.$apiurl}/shipments/sendEmail/${id}`,
      requestOptions
    );
    if (response.status === 200) {
      Notification(
        "success",
        "Email sent successfuly for Shipment ID " + { id }
      );
      setToData(null);
      setSubjectData(`Shipment Manifest - ${id}`);
      setBodyData(
        `<p>Please find the file attached Load Number: <strong>${id}</strong></p>`
      );
      setisLoadingSend(false);
      setisLoadingSubmit(false);

      setincludCCData(false);
      setshowSendManifestEmail(false);
    } else {
      Notification("error", "invalid data");
      setisLoadingSend(false);
      setshowSendManifestEmail(false);
      setisLoadingSubmit(false);
    }
  }
  return (
    <>
      <Modal
        show={showSendManifestEmail}
        aria-labelledby="example-modal-sizes-title-lg"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            Send Shipment Manifest
          </Modal.Title>
          <IconButton
            
            aria-label="Close"
            onClick={() => {
              setshowSendManifestEmail(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Modal.Header>
        <Modal.Body>
          {isLoading && <span>Shipment Documents is Sending...</span>}
          <Formik>
            <Form className="form form-label-right Send-shipment">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="mb-3">To: </p>
                    <CreatableSelect
                      isMulti
                      name="To"
                      id="To"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => {
                        var obj = [];
                        if (e) {
                          {
                            e.map((item, index) => {
                              obj.push({
                                Email: item.value,
                                Name: item.value,
                              });
                            });
                          }
                        }
                        setToData(obj);
                      }}
                    />
                    <CheckboxLarge
                      Size="12px"
                      name="includCC"
                      text="Include me on CC"
                      onChange={(event) => {
                        setincludCCData(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 mt-5">
                    <p className="mb-3">Subject: </p>
                    <Field
                      name="Subject"
                      component={Input}
                      placeholder="Subject"
                      value={SubjectData}
                      onChange={(event) => {
                        setSubjectData(event.target.value);
                      }}
                    />
                  </div>
                  <div className="col-lg-12 mt-5">
                    <p className="mb-3">Body: </p>

                    <CKEditor
                      name="Body"
                      Id="Body"
                      label="Body"
                      data={BodyData}
                      editor={ClassicEditor}
                      config={{
                        removePlugins: [
                          "Image",
                          "ImageCaption",
                          "ImageStyle",
                          "ImageToolbar",
                          "ImageUpload",
                          "MediaEmbed",
                        ],
                      }}
                      data={databody}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        //console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        // const data = editor.getData();
                        // console.log({ event, editor, data });
                      }}
                      onBlur={(event, editor) => {
                        const data = editor.getData();
                        setBodyData(data);
                      }}
                      onFocus={(event, editor) => {
                        //console.log('Focus.', editor);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={() => {
                setshowSendManifestEmail(false);
              }}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={SendEmail}
              disabled={isLoadingSubmit}
              className="btn btn-delete btn-primary"
            >
              Send Email
              {isLoadingSubmit && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <div className="card card-custom overflow-hidden border-0 px-0">
        <div className="card-body pt-5">
          {id && <ComponentToPrint ref={componentRef} id={id} />}
          <div className="container">
            <div className="col-md-12 mb-4">
              <div className="text-right row" style={{ float: "right" }}>
                {/* <button
                  style={{ float: "right" }}
                  type="button"
                  className="btn btn-success font-weight-bolder mr-3"
                  onClick={() => {
                    setshowSendManifestEmail(true);
                  }}
                >
                  Send Email
                </button> */}
                <button
                  style={{ float: "right" }}
                  type="button"
                  className="btn btn-primary font-weight-bolder mr-3"
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Print Form
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
