import React from "react";
import moment from "moment";

export default function DateColumnFormatter({dateTime}) {
  var date = new Date(dateTime);
  var a = date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
  var b = moment(new Date(), "M/D/YYYY");
  var diffYear = b.diff(a, "year");
  var diffMonths = b.diff(a, "months");
  var diffDays = b.diff(a, "days");
  var diffHours = b.diff(a, "hours");
  var text = "";
  if (diffYear > 0) text = diffYear + " year(s) ago";
  else if (diffMonths > 0) text = diffMonths + " month(s) ago";
  else if (diffDays > 0) text = diffDays + " day(s) ago";
  else text = diffHours + " hour(s) ago";
  return (
    <>
      <span class="text-primary p-1 m-0">{text}</span>
    </>
  );
}
