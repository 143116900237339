import React, { useState, useEffect } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ShippedOrdersComp } from "../widgets";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import $ from "jquery";
import moment from "moment";
const adapter = new LocalStorage("db");
const db = low(adapter);

export function DashboardScentsy() {
  const isoWeek = moment(new Date()).isoWeek() + "";
  const [Customers, setCustomers] = useState([]);
  const [Weeks, setWeeks] = useState([]);
  const [CustomerId, setCustomerId] = useState("90");
  const [WeekId, setWeekId] = useState(isoWeek);
  const [NotShippedOrdersCount, setNotShippedOrdersCount] = useState(0);
  const [ShippedOrdersCount, setShippedOrdersCount] = useState(0);
  const [NotShippedOrders, setNotShippedOrders] = useState([]);
  const [ShippedOrders, setShippedOrders] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    getviewmodel();
    getData();
  }, []);

  useEffect(() => {
    setLoading(true);
    getData();
  }, [CustomerId, WeekId]);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 mb-5">
          <select
            id="CustomersID"
            className="form-control w-100"
            onChange={(event) => {
              setCustomerId(event.target.value);
            }}
            defaultValue="90"
          >
            <option key="" value="0">
              Select
            </option>
            {Customers.map((item, index) => (
              <option key={index} value={item?.Id}>
                {item?.Name}
              </option>
            ))}
          </select>
          <small class="form-text text-muted">
            <b>Filter</b> by Customer
          </small>
          {/* {Customers.length === 0 && <LinearProgress />} */}
        </div>

        <div className="col-lg-6 mb-5">
          <select
            id="WeeksID"
            className="form-control w-100"
            onChange={(event) => {
              setWeekId(event.target.value);
            }}
          >
            <option key="" value="0">
              Select
            </option>
            {Weeks.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <small class="form-text text-muted">
            <b>Filter</b> by Week
          </small>
          {/* {Weeks.length === 0 && <LinearProgress />} */}
        </div>

        <div className="col-lg-6 col-xxl-12">
          {Loading && <LinearProgress />}
          <ShippedOrdersComp
            className="card-stretch gutter-b"
            ShippedOrdersCount={ShippedOrdersCount}
            NotShippedOrdersCount={NotShippedOrdersCount}
            ShippedOrders={ShippedOrders}
            NotShippedOrders={NotShippedOrders}
          />
        </div>
      </div>
    </>
  );

  async function getviewmodel() {
    return await fetch(`${window.$apiurl}/reporting/dashboard/getviewmodel`, {
      method: "get",
      withCredentials: true,
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `bearer ${db.read().getState().Token}`,
        "X-FP-API-KEY": "iphone",
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        setCustomers(a?.Customers || []);
        setWeeks(a?.Weeks || []);
        $("#CustomersID").val("90");
        $("#WeeksID").val(isoWeek);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getData() {
    return await fetch(
      `${window.$apiurl}/reporting/dashboard/scentsy?weekNumber=${WeekId}&customerId=${CustomerId}`,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone",
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = await response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        var a = res[1];
        /* setNotShippedOrdersCount(a?.NotShippedOrdersCount || 0);
        setShippedOrdersCount(a?.ShippedOrdersCount || 0); */
        setNotShippedOrdersCount(a?.NotShippedContainersCount || 0);
        setShippedOrdersCount(a?.ShippedContainersCount || 0);

        setNotShippedOrders(a?.NotShippedOrders?.Orders || []);
        setShippedOrders(a?.ShippedOrders?.Orders || []);
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
