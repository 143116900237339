import $ from "jquery";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { matchPath } from "react-router";
import { useReactToPrint } from "react-to-print";
import { currencyFormat } from "../../../_metronic/_helpers/ATHelpers";
import { ShipmentManifest } from "../../modules/eMPower/pages/NewShipmentsBeta/customers-table/ShipmentManifest";
import { Notification } from "../../modules/Notification";
const adapter = new LocalStorage("db");
const db = low(adapter);
function IsValidAddress(str, isFirstItem) {
  if (str === null || str === undefined || str === "") {
    return "";
  } else {
    return isFirstItem === true ? str : ", " + str;
  }
}
function formatDate(date) {
  if (date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    const result = [month, day, year].join("/");
    if (result !== "NaN/NaN/NaN") {
      return result;
    } else {
      return "--/--/----";
    }
  } else {
    return "--/--/----";
  }
}
function TotalCurrency(list, fieldName) {
  var result = 0;
  if (list?.length > 0) {
    list.map((item) => {
      if (fieldName === "Weight") {
        result += item.Weight;
      } else if (fieldName === "ShipQuantity") {
        result += item.ShipQuantity;
      } else if (fieldName === "Pieces") {
        result += item.Pieces;
      } else {
        result += item.Cube;
      }
    });
    if (fieldName === "Weight" || fieldName === "Cube") {
      return currencyFormat(result);
    } else {
      return currencyFormat(result)?.replace(/\.00$/, "");
    }
  } else {
    return "";
  }
}
class ComponentToPrint extends React.Component {
  constructor() {
    super();
    var obj = {
      Carriers: [],
      Items: [],
      Statistiques: [],
      LocationItems: [],
    };
    this.state = { data: obj };
  }
  async componentDidMount() {
    const url = window.location.pathname;
    const id = url.substring(url.lastIndexOf("/") + 1);
    window.$smIdOutbound = id;
    await fetch(
      window.$apiurl + "/Shipments/OutboundManifest/" + this.props.row.id,
      {
        method: "get",
        withCredentials: true,
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        // if (statusCode !== 200) {
        //   Notification("error", "No data found !");
        // }
        const json = await response.json();
        this.setState({ data: json });
      })
      .then((res) => {
        return res[1];
      })
      .catch((error) => {
        console.error(error);
      });
  }
  render() {
    const formData = this.state.data;
    //minus revenue value
    if (formData.revenuValue < 0) {
      $("#revenuValue").addClass("text-danger");
    }
    return (
      <>
        <Helmet>
          <title>eMPower | Shipment Manifest Form</title>
          <meta name="description" content="This is my meta description" />
        </Helmet>
        <div className="container">
          <div className="card py-0 px-0 bl-form border-0">
            <div className="card-body py-0 px-0">
              {/* First Data */}
              <div className="row justify-content-center pt-8 px-0 pt-md-0 px-md-0 pb-md-10">
                <div className="col-md-12">
                  <div className="row pb-4">
                    <div className="col-md-12 px-0">
                      <table className="w-100">
                        <tbody className="border-0">
                          <tr className="text-center">
                            <td className="border-0 pb-1" colspan="8">
                              <h3 className="font-weight-boldest mb-0">
                                Shipment Manifest
                              </h3>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row pb-5">
                    <div className="col-md-12">
                      <div className="row">
                        <table className="w-25 mt-2">
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Trailer #</b> {formData.Equipment}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Shipment #</b> {this.props.row.id}
                                <br />
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0 pb-3">
                                <b>Shipment Date:</b>{" "}
                                {formatDate(formData.ShopDate)}
                              </td>
                            </tr>
                            <tr className="text-left">
                              <td className="border-0">
                                <b>Seal no:</b> {formData.SealNumber}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table className="w-50"></table>
                        <table className="w-25">
                          <tbody className="border-0">
                            <tr className="text-left">
                              <td
                                className="border-0"
                                style={{ lineHeight: "2.2" }}
                              >
                                <b>Ship to:</b>
                                <br />
                                {formData.ShipTo?.Name}
                                <br />
                                {formData.ShipTo?.Address1}
                                <br />
                                {IsValidAddress(
                                  formData.ShipTo?.City?.replace(/ /g, ""),
                                  true
                                ) +
                                  IsValidAddress(
                                    formData.ShipTo?.State,
                                    false
                                  ) +
                                  IsValidAddress(
                                    formData.ShipTo?.ZipCode,
                                    false
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  {/* Shipment Items */}
                  <div className="row pb-5">
                    <div className="col-md-12 ">
                      <table className="w-100">
                        <thead className="justify-content-center">
                          <tr>
                            <th colspan="12" className="text-center">
                              Shipment Items
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="font-weight-boldest  align-text-top">
                              PO#
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              SKU
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Description
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Case Pack
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Ship QTY
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Ship UOM
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Weight (LBS)
                            </td>
                            {/* <td className="font-weight-boldest  align-text-top">
                              Weight UOM
                            </td> */}
                            <td className="font-weight-boldest  align-text-top">
                              Cube (CBM)
                            </td>
                            {/* <td className="font-weight-boldest  align-text-top">
                              Cube UOM
                            </td> */}
                            <td className="font-weight-boldest  align-text-top">
                              Item QTY
                            </td>
                            <td className="font-weight-boldest  align-text-top">
                              Container
                            </td>
                          </tr>
                          {formData.ShipItems?.length > 0 &&
                            formData.ShipItems?.map((item) => (
                              <tr>
                                <td className="align-text-top">
                                  {item.PurchaseOrder}
                                </td>
                                <td className="align-text-top">{item.Sku}</td>
                                <td className="align-text-top">
                                  {item.Description}
                                </td>
                                <td className="align-text-top">
                                  {item.CasePack || ""}
                                </td>
                                <td className="align-text-top">
                                  {item.ShipQuantity}
                                </td>
                                <td className="align-text-top">
                                  {item.ShipUom}
                                </td>
                                <td className="align-text-top">
                                  {currencyFormat(item.Weight)}
                                </td>
                                {/* <td className="align-text-top">
                                  {item.WeightUom}
                                </td> */}
                                <td className="align-text-top">
                                  {currencyFormat(item.Cube)}
                                </td>
                                {/* <td className="align-text-top">CBM</td> */}
                                <td className="align-text-top">
                                  {item.Pieces}
                                </td>
                                <td className="align-text-top">
                                  {item.EquipmentRef}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td
                              className="align-text-top font-weight-boldest"
                              style={{
                                backgroundColor: "#113059",
                                color: "white",
                                whiteSpace: "nowrap",
                              }}
                            >
                              GRAND TOTAL
                            </td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top font-weight-boldest">
                              {TotalCurrency(
                                formData.ShipItems,
                                "ShipQuantity"
                              )}
                            </td>
                            <td className="align-text-top"></td>
                            <td className="align-text-top font-weight-boldest">
                              {TotalCurrency(formData.ShipItems, "Weight")}{" "}
                              {" LBS"}
                            </td>
                            <td className="align-text-top font-weight-boldest">
                              {TotalCurrency(formData.ShipItems, "Cube")}{" "}
                              {" CBM"}
                            </td>
                            <td className="align-text-top font-weight-boldest">
                              {TotalCurrency(formData.ShipItems, "Pieces")}
                            </td>
                            <td className="align-text-top"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export function OutboundManifest({history,isGlobalSearch,smCustomer,smId}) {
  let id;
  const match = matchPath(history?.location.pathname, {
    path: "/report/Outbound/:id",
  });

  try {
    if (match.params) {
      id = match.params?.id;
    }
  } catch (err) {
    console.log(err);
  }
  React.useEffect(() => {
    fetchApiOutboundReportManifest();
  }, []);
  const [customerId,setCustomerId] = React.useState("");
  const [customerName,setCustomerName] = React.useState("");
  const [isSuccess,setSuccessApi] = React.useState("");
  async function fetchApiOutboundReportManifest() {
    return await fetch(
      window.$apiurl +
        "/Shipments/OutboundManifest/" +
        (id === undefined ? smId : id),
      {
        method: "GET",
        withCredentials: true,
        headers: {
          Authorization: `bearer ${db.read().getState().Token}`,
          "X-FP-API-KEY": "iphone", //it can be iPhone or your any other attribute
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (response) => {
        const statusCode = response.status;
        const data = await response.json();
        return Promise.all([statusCode, data]);
      })
      .then((res) => {
        const result = res[1];
        if (res[0] !== 200) {
          setSuccessApi(500);
        } else {
          setSuccessApi(200);
        }
        setCustomerId(result?.CustomerId);
        setCustomerName(result?.CustomerName);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    return !isGlobalSearch && isSuccess !== "" ? (
      customerId !== 2271 ? (
        <div className="card card-custom overflow-hidden border-0 px-0">
          <div className="card-body pt-5">
            <div className="container">
              <div className="col-md-12 mb-4">
                <div className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bolder mr-3"
                    onClick={() => {
                      console.log(componentRef.current);
                      handlePrint();
                    }}
                  >
                    Print Form
                  </button>
                  {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
                </div>
              </div>
            </div>
            <ComponentToPrint
              ref={componentRef}
              row={{
                id: id || smId,
                customer: customerName,
              }}
            />
          </div>
        </div>
      ) : (
        <ShipmentManifest id={smId} />
      )
    ) : isSuccess !== "" && customerId !== 2271 ? (
      <>
        <div className="card card-custom overflow-hidden border-0 px-0 d-none">
          <div className="card-body pt-5">
            <ComponentToPrint
              ref={componentRef}
              row={{
                id: smId,
                customer: smCustomer,
              }}
            />
            <div className="container">
              <div className="col-md-12 mb-4"></div>
            </div>
          </div>
        </div>

        <div className="text-right">
          <button
            type="button"
            className="btn btn-primary font-weight-bolder mr-3"
            onClick={() => {
              console.log(componentRef.current);
              handlePrint();
            }}
          >
            Print Manifest
          </button>
          {/* <button type="button" className="btn btn-light-primary font-weight-bolder" onClick={handlePrint}>Download</button> */}
        </div>
      </>
    ) : (
      <div className="d-none">
        <ShipmentManifest id={id} />
      </div>
    );
}
