/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import { DropdownCustomToggler, DropdownMenu4 } from "../../dropdowns";
import { Dropdown } from "react-bootstrap";
import { ProgressBar } from "react-bootstrap";
import moment from "moment";

export function ShipmentChart({
  className,
  GlobalShartData,
  ChartLoadingSpinner,
}) {
  const [value, onChange] = useState([
    moment()
      .add(-7, "days")
      .format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ]);
  const [startDateValue, setStartDate] = React.useState(
    new Date()
      .addDays(-7)
      .toISOString()
      .split("T")[0]
  );
  const [endDateValue, setEndDate] = React.useState(
    new Date().toISOString().split("T")[0]
  );
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById("kt_shipment_widget_7_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps, GlobalShartData);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, GlobalShartData]);

  return (
    <div className={`card card-custom ${className}`}>
      {ChartLoadingSpinner && (
        <ProgressBar
          variant="success"
          animated
          now={100}
          style={{ height: "3px", width: "100%" }}
        />
      )}
      <div className="card-header border-0">
        <h3 className="card-title font-weight-bolder text-dark">
          Lulus workflow
        </h3>
      </div>
      <div className="card-body d-flex flex-column pt-0">
        <div className="row">
          <div className="col-xl-7 col-lg-8 col-md-8 align-self-center">
            <div
              id="kt_shipment_widget_7_chart"
              className="card-rounded-bottom"
              style={{ height: "100px" }}
            ></div>
          </div>
          <div className="col-xl-5 col-lg-4 col-md-4 mt-0">
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-primary mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-primary">
                    {GlobalShartData.filter((item) => item.Status === "Active")[0]
                      ?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Active";
                    setTimeout(() => {
                      var el = document.getElementById("Active");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Active
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Active")[0]
                      ?.Details || ""}</span>
              </div>
            </div>

            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-light-Appointed mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-Appointed">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Planned"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Planned";
                    setTimeout(() => {
                      var el = document.getElementById("Planned");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Planned
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Planned")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-warning mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-warning">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Appointed"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Appointed";
                    setTimeout(() => {
                      var el = document.getElementById("Appointed");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Appointed
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Appointed")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-info mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Dispatched"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Dispatched";
                    setTimeout(() => {
                      var el = document.getElementById("Dispatched");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Dispatched
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Dispatched")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Closed mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-Received">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Picked Up"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-info mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Picked Up";
                    setTimeout(() => {
                      var el = document.getElementById("Picked Up");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Picked Up
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Picked Up")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-light-Received mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-Received">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Load Received"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-info mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Load Received";
                    setTimeout(() => {
                      var el = document.getElementById("Load Received");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Load Received
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Load Received")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-light-QC mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-QC">
                    {GlobalShartData.filter((item) => item.Status === "QC")[0]
                      ?.Count || 0}
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "QC";
                    setTimeout(() => {
                      var el = document.getElementById("QC");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  QC
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "QC")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            {/* <div className="d-flex align-items-center mb-5 ml-15">
              <div className="symbol symbol-40 symbol-success mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-sucess">
                    0
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Pass";
                    setTimeout(() => {
                      var el = document.getElementById("Pass");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Pass
                </a>
                <span className="text-muted">15</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5 ml-15">
              <div className="symbol symbol-40 symbol-danger mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-danger">
                    0
                  </span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Fail";
                    setTimeout(() => {
                      var el = document.getElementById("Fail");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Fail
                </a>
                <span className="text-muted">15</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5 ml-30">
              <div className="symbol symbol-40 symbol-light-Rtv mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-Rtv">0</span>
                </span>
              </div>
              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark-75 text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "RTV";
                    setTimeout(() => {
                      var el = document.getElementById("RTV");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  RTV
                </a>
                <span className="text-muted">15</span>
              </div>
            </div>
             */}
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-light-Rtv mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-Rtv">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Ready to process"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-info mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Ready to process";
                    setTimeout(() => {
                      var el = document.getElementById("Ready to process");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Ready to process
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Ready to process")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Processed mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Processed"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-info mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Processed";
                    setTimeout(() => {
                      var el = document.getElementById("Processed");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Processed
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Processed")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Palletized mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Palletized"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-info mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Palletized";
                    setTimeout(() => {
                      var el = document.getElementById("Palletized");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Palletized
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Palletized")[0]
                      ?.Details || ""}</span>
              </div>
            </div>

            {/* <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Loaded mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-danger">
                  {GlobalShartData.filter(
                      (item) => item.Status === "Loaded"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Loaded";
                    setTimeout(() => {
                      var el = document.getElementById("Loaded");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Loaded
                </a>
                <span className="text-muted">15</span>
              </div>
            </div> */}

            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Shipped mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Shipped"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Shipped";
                    setTimeout(() => {
                      var el = document.getElementById("Shipped");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Shipped
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Shipped")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
            {/* <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Closed mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Closed"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Closed";
                    setTimeout(() => {
                      var el = document.getElementById("Closed");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Closed
                </a>
                <span className="text-muted">15</span>
              </div>
            </div> */}
            <div className="d-flex align-items-center mb-5">
              <div className="symbol symbol-40 symbol-Exceptions mr-5">
                <span className="symbol-label">
                  <span className="svg-icon svg-icon-lg svg-icon-info">
                    {GlobalShartData.filter(
                      (item) => item.Status === "Exception"
                    )[0]?.Count || 0}
                  </span>
                </span>
              </div>

              <div className="d-flex flex-column font-weight-bold">
                <a
                  href="#"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                  onClick={() => {
                    window.GlobalTable = "Exception";
                    setTimeout(() => {
                      var el = document.getElementById("Exception");
                      window.scroll({
                        top: el?.offsetTop - 120,
                        behavior: "smooth",
                      });
                    }, 50);
                  }}
                >
                  Exception
                </a>
                <span className="text-muted">{GlobalShartData.filter((item) => item.Status === "Exception")[0]
                      ?.Details || ""}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function getChartOption(layoutProps, GlobalShartData) {
  const options = {
    series: [
      GlobalShartData.filter((item) => item.Status === "Active")[0]?.Count || 0,
      GlobalShartData.filter((item) => item.Status === "Planned")[0]?.Count ||
        0,
      GlobalShartData.filter((item) => item.Status === "Appointed")[0]?.Count ||
        0,
      GlobalShartData.filter((item) => item.Status === "Dispatched")[0]
        ?.Count || 0,
      GlobalShartData.filter((item) => item.Status === "Picked Up")[0]?.Count ||
        0,
      GlobalShartData.filter((item) => item.Status === "Load Received")[0]
        ?.Count || 0,
      GlobalShartData.filter((item) => item.Status === "QC")[0]?.Count || 0,
      0,
      0,
      0,
      GlobalShartData.filter((item) => item.Status === "Ready to process")[0]
        ?.Count || 0,
        GlobalShartData.filter((item) => item.Status === "Processed")[0]
        ?.Count || 0,
      GlobalShartData.filter((item) => item.Status === "Palletized")[0]
        ?.Count || 0,
        // GlobalShartData.filter((item) => item.Status === "Loaded")[0]?.Count ||
        // 0,
      GlobalShartData.filter((item) => item.Status === "Shipped")[0]?.Count ||
        0,
      GlobalShartData.filter((item) => item.Status === "Exception")[0]?.Count ||
        0,
    ],
    chart: {
      type: "donut",
    },
    colors: [
      "#3699FF",
      "#869890",
      "#FFA800",
      "#8950FC",
      "#95371d",
      "#4CA1AF",
      "#b3c43c",
      "#fe8a71",
      "#F64E60",
      "#fe8a71",
      "#fe8a71",
      "#5a3662",
      "#3c78ae",
      //"#8785a2",
      "#a2738c",
      "#FF0000",
    ],
    dataLabels: {
      formatter: function(val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    labels: [
      "Active",
      "Planned",
      "Appointed",
      "Dispatched",
      "Picked up",
      "Load Received",
      "QC",
      "Pass",
      "Fail",
      "Rtv",
      "Ready to process",
      "Processed",
      "Palletized",
      //"Loaded",
      "Shipped",
      "Exception",
    ],
  };
  return options;
}
